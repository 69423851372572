import dataModel from 'data-model';
import template from './show-password-dialog-component.html';
class PasswordDialog {
    constructor({message, okBtnText = "Ok"}) {
        try {
            var dialog = this;
            dialog.okBtnText = ko.observable(okBtnText);

            dialog.onEnterKey = function() {
                dialog.ok();
            }
    
            dialog.message = message;
            dialog.password = ko.observable();
            dialog.result = new Promise(function(resolve, reject) {
                dialog.ok = function() {
                    $('#passwordDialog').modal('hide')
                    //jqueryWidgets won't update the observable until the focus leaves the textbox so a setTimeout is necessary here.
                    setTimeout(function() {
                        resolve(dialog.password());
                    }, 200)
                }
        
                dialog.cancel = function() {
                    $('#passwordDialog').modal('hide')
                    resolve(false)
                }
            })
        } catch(err) {
            if(err.responseJSON.message != "An error has occurred.") {
                dataModel.addClientSideLog(err);
            }
            console.error(err);
        }
    }
}


export default {viewModel: PasswordDialog, template: template}