import userprofile from "user-profile";
import template from "./order-entry-order-lock-component.html";
import { Computed, Observable, PureComputed, Subscription } from "knockout";
import dayjs from "dayjs";
import dataModel from "data-model";
import { showmessage } from "show-dialog-methods";
import { OrderEntryViewModel } from "../order-entry-page";

// A user may only make changes to an order that they themselves have locked.
class OrderLockComponent {
  private $parent: OrderEntryViewModel;
  orderOpenedAtTimestamp: Observable<number>;

  private unlockTime: dayjs.Dayjs;
  private updatedBy: string;
  private orderId: number;

  showThreeMinuteWarningModal: Observable<boolean> = ko.observable();
  showTimerExpiredModal: Observable<boolean> = ko.observable();

  lockOrderControls: Observable<boolean>;
  seconds: Observable<number> = ko.observable();
  showLockOrderControlsModal: Observable<boolean> = ko.observable();
  showLockOrderControlsModalCurrentUser: Observable<boolean> = ko.observable();
  intervalReference: number;
  /** The number of seconds of idle time before the order becomes locked again. */
  orderTimeLock: number;
  orderOpenedAtTimestampSubscription: Subscription;

  constructor(params) {
    params = params.data();
    this.lockOrderControls = params.lockOrderControls;
    this.$parent = params.$parent;

    this.updatedBy = params.updatedBy;
    this.orderId = params.orderId;
    this.orderOpenedAtTimestamp = params.orderOpenedAtTimestamp;

    this.orderTimeLock = params.orderTimeLock * 60;

    if (params.locked) {
      this.unlockTime = dayjs().add(params.lockDurationSeconds, "s");
      if (!params.newOrder) {
        if (
          this.updatedBy.toUpperCase() != userprofile.userName.toUpperCase()
        ) {
          this.showLockOrderControlsModal(true);
        } else {
          this.showLockOrderControlsModalCurrentUser(true);
        }
      }
      this.establishIntervalLoop();

      this.orderOpenedAtTimestampSubscription =
        this.orderOpenedAtTimestamp.subscribe((v) => {
          this.unlockTime = dayjs().add(this.orderTimeLock, "s");
        });
    }
    this.$parent.orderLockComponent(this);
    this.$parent.isLoaded(true);
  }

  threeMinuteWarningWindowShown: PureComputed<boolean> = ko.pureComputed(() => {
    return this.seconds() && this.seconds() < 180;
  });

  orderLockTimerString: Computed<string> = ko.computed(() => {
    if (this.seconds() >= 0) {
      let minutes = Math.floor(this.seconds() / 60);
      return minutes + "m " + (this.seconds() % 60) + "s ";
    } else {
      return "";
    }
  });

  establishIntervalLoop = () => {
    let inter = setInterval(() => {
      if (this.unlockTime) {
        let now = dayjs();
        this.seconds(this.unlockTime.diff(now, "seconds"));

        if (this.seconds() == 180 && !this.lockOrderControls()) {
          this.showThreeMinuteWarningModal(true);
        }
        if (!this.seconds() && !this.lockOrderControls()) {
          this.showThreeMinuteWarningModal(false);
          this.lockOrderControls(true);
          window.removeEventListener("beforeunload", this.beforeunloadFunction);
          if (this.updatedBy == userprofile.userName) {
            this.showTimerExpiredModal(true);
          }
        }
      } else {
        this.seconds(undefined);
      }
    }, 1000);
    this.intervalReference = inter;
  };

  threeMinuteNoButtonClick = () => {
    //No button - Close the order without saving.
    window.removeEventListener("beforeunload", this.beforeunloadFunction);
    this.lockOrderControls(true);
  };

  threeMinuteYesButtonClick = () => {
    //Yes button - Extend the lock
    this.extendOrderLock();
  };

  checkAndLockOrderForEdit = () => {
    this.$parent.isLoading(true);
    dataModel
      .ajaxRequest("Order/CheckAndLockOrderForEdit", "GET", {
        orderId: this.orderId,
        orderOpenedAtTimestamp: this.orderOpenedAtTimestamp(),
        extend: false,
      })
      .then((res) => {
        res = res[0];
        this.$parent.isLoading(false);
        this.lockOrderControls(false);
        this.unlockTime = dayjs().add(res.lockDurationSeconds, "s");
        this.establishIntervalLoop();
        window.addEventListener("beforeunload", this.beforeunloadFunction);
        this.orderOpenedAtTimestampSubscription =
          this.orderOpenedAtTimestamp.subscribe((v) => {
            this.unlockTime = dayjs().add(this.orderTimeLock, "s");
          });

        //this.$parent.loadOrder();
      })
      .fail((res: any) => {
        this.$parent.isLoading(false);
        if (res?.responseJSON?.message) {
          this.unlockTime = dayjs().add(
            res.responseJSON.lockDurationSeconds,
            "s"
          );
          this.establishIntervalLoop();
          showmessage(res.responseJSON.message);
        } else {
          showmessage("An error has occurred.");
        }
      });
  };

  beforeunloadFunction = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
  };

  cancelButtonClick = () => {
    this.unlockOrder();
  };

  unlockOrder() {
    if (this.orderOpenedAtTimestampSubscription) {
      this.orderOpenedAtTimestampSubscription.dispose();
    }

    this.$parent.isLoading(true);
    dataModel
      .ajaxRequest("MyAgency/UnlockOrder", "GET", { orderId: this.orderId })
      .then(() => {
        this.$parent.isLoading(false);
        this.lockOrderControls(true);
        clearInterval(this.intervalReference);
        this.seconds(undefined);
        this.unlockTime = undefined;
        window.removeEventListener("beforeunload", this.beforeunloadFunction);
      })
      .fail((x: any) => {
        if (x.message) {
          showmessage(x.message);
        } else {
          showmessage("An error has occured.");
        }
        console.error(x);
        this.$parent.isLoading(false);
      });
  }

  extendOrderLock = () => {
    dataModel
      .ajaxRequest("Order/CheckAndLockOrderForEdit", "GET", {
        orderId: this.orderId,
        orderOpenedAtTimestamp: this.orderOpenedAtTimestamp(),
        extend: true,
      })
      .then((x) => {
        x = x[0];
        this.unlockTime = dayjs().add(x.lockDurationSeconds, "s");
        this.lockOrderControls(false);
        window.addEventListener("beforeunload", this.beforeunloadFunction);
        $("#threeMinuteWarningModal").modal("hide");
        $("#orderLockedCurrentUserModal").modal("hide");
      })
      .fail((x: any) => {
        if (x && x.responseJSON && x.responseJSON.message) {
          $("#threeMinuteWarningModal").modal("hide");
          $("#orderLockedCurrentUserModal").modal("hide");
          showmessage(x.responseJSON.message);
        } else {
          showmessage("An error has occured.");
        }
        console.error(x);
      });
  };

  currentUserLockedYesBtnClick = () => {
    this.extendOrderLock();
  };

  editButtonClick = () => {
    this.checkAndLockOrderForEdit();
  };

  isEditButtonDisabled = ko.pureComputed(() => {
    return this.$parent.isLoading() || !this.$parent.orderId();
  });

  isEditButtonVisible = ko.pureComputed(() => {
    return this.lockOrderControls() && !this.$parent.readyToBill();
  });

  isSaveButtonVisible = ko.pureComputed(() => {
    return !this.isEditButtonVisible() && !this.$parent.readyToBill();
  });

  saveButtonClick = () => {
    this.$parent.initiateSaveOrderProcess(
      this.$parent.summary().status() == "Quote"
    );
  };

  isSaveButtonDisabled = ko.pureComputed(() => {
    return this.$parent.isLoading() || !this.$parent.orderId();
  });

  saveAndCloseButtonClick = () => {
    if (this.orderOpenedAtTimestampSubscription) {
      this.orderOpenedAtTimestampSubscription.dispose();
    }

    return this.$parent
      .initiateSaveOrderProcess(
        this.$parent.summary().status() == "Quote",
        false
      )
      .then(() => {
        window.removeEventListener("beforeunload", this.beforeunloadFunction);
        this.lockOrderControls(true);
        this.unlockTime = undefined;
        this.seconds(undefined);

        window.close();
      });
  };

  isSaveAndCloseButtonVisible = ko.pureComputed(() => {
    return !this.$parent.orderLockComponent().lockOrderControls();
  });

  isCancelButtonVisible = ko.pureComputed(() => {
    return !this.lockOrderControls();
  });

  isFinalizeVisible = ko.pureComputed(() => {
    if (this.lockOrderControls()) {
      return false;
    } else {
      return (
        (this.$parent.summary() &&
          this.$parent.summary().isFinalizeVisible &&
          this.$parent.summary().isFinalizeVisible()) ||
        false
      );
    }
  });
}

(OrderLockComponent.prototype as any).toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.$parent;
  return copy;
};

var OrderLockComponentModals = { template: $(template)[2].outerHTML };

export default {
  viewModel: OrderLockComponent,
  template: $(template)[0].outerHTML,
};

export { OrderLockComponent, OrderLockComponentModals };
