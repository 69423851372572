import ko from "knockout";
import dataModel from "data-model";
import template from "./order-grid-component.html";
import {
  formatPhoneNumber,
  isEmpty,
  datetimeUTC,
  mapToCityStateZipObject,
  cleanString,
  mobilecheck,
} from "global-functions";
import gridStateUtils from "jqx.grid-utils";
import GreatEdgeCustomWidgets from "ge.custom-widgets";
import GridStateComponentViewModel from "jqx.grid-state-component";
import userProfile from "user-profile";
import { showconfirm, showmessage } from "show-dialog-methods";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import router from "router";
import * as $ from "jquery";
import { OrderPlanningViewModel } from "../order-planning-page";
import storageManager from "../../../../utils/storageManager";
import jqxSliderWidget from "../../../../gridtoolbox/widgets/jqxSliderWidget";
import { cancelTender } from "../../../shared-components/tender-load-component/tender-load-component";
import broadcastAPI from "../../../../utils/broadcastAPI";
import { loadSavedSearches } from "../../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";
import RateService, { TPIRateRequest } from "../../../../services/RateService";
import { useDispatch } from "ko-data-store";
import { isLoading } from "../../../../dataStore/actions/appUI";

dayjs.extend(dayjsDuration);

export const loadGridState = (state, $grid) => {
  if (state != null && $grid != null) {
    var gridState = $grid.jqxGrid("getstate") || {};
    state.selectedrowindex = -1;
    state.selectedrowindexes = [];
    state.pagenum = 0;
    state.height = gridState.height;
    state.pagesize = gridState.pagesize;
    state.pagesizeoptions = gridState.pagesizeoptions;
    $grid.jqxGrid("loadstate", state);
  }
};

class OrderPlanningOrderGrid {
  dispatchAction = useDispatch();
  orderRadius = ko.observable(10);
  includePartnerOrders = ko.observable(false); //This is always gonna be false?
  orderGridState = {};
  orderGridFiltersLoaded = ko.observable();
  clearAssignmentConfirmationModal = ko.observable();
  assignmentCleared = ko.observable(false);
  projectPostingModal = ko.observable();
  grid = $("#jqxOrderGrid");
  gridProcessing = true;
  includeOrders = ko.observable(true);
  includeLoads = ko.observable(false);
  expired = ko.observable(false);
  expireLoadsModal = ko.observable();
  orderDetailModal = ko.observable();
  isOrderGridLoading = ko.observable(true);
  orderRadiusLocation = ko.observable("");
  isLoad = ko.observable();
  rateConfirmationModal = ko.observable();

  _orderInitLoad = true;
  expireLoadOnCreate = ko.observable(false); //Don't see where we're changing this.
  selectedLoadId = ko.observable();
  orderFromLoad = ko.observableArray();
  createOrderFromLoadShowExpireLoadBtn = ko.observable(false);
  ordersRadiusSearchModal = ko.observable();
  loadImportWindow = ko.observable();
  matchMyOrderMatchId = ko.observable();
  load = ko.observable();
  errors = ko.observableArray();
  moveTrailerModal = ko.observable();
  orderStateParameter = ko.observable();
  xorderStateParameter = ko.observable();
  incomingParameter = ko.observable();
  outgoingParameter = ko.observable();
  loadIdParameter = ko.observable();
  showTenderLoadModal = ko.observable();
  showCreateOrderFromLoadModal = ko.observable();
  lblOrderFromLoadMsg = ko.observable();
  pageName = "orderplanning";
  defaultOptions;
  myProfileDefaultOptions;
  ordersHidden = ko.observable(false);
  callLeadsModal = ko.observable();

  userRoles = ko.observableArray([]);
  clearSelectedSavedSearchDDL = ko.observable(false);
  refreshSavedSearchDDL = ko.observable("");
  initFiltersLoaded = ko.observable(false);
  searchOptionChanged = false;
  tpiViewRatesProps = ko.observable();

  /**@type { OrderPlanningViewModel } */
  $parent;
  constructor(params) {
    this.$parent = params.data;
    this.$parent.orderGridComponent = this;

    this.showOrdersGrid = params.data.showOrdersGrid;
    this.toggleOrders = params.data.toggleOrders;
    this.defaultGridToggleOptions = params.data.defaultGridToggleOptions;

    this.isLoad(this.$parent.params[0].isload == "true");
    this.includeOrders(this.$parent.params[0].includeorders == "true");
    this.includeLoads(this.$parent.params[0].includeLoads == "true");
    this.incomingParameter(this.$parent.params[0].incoming);
    this.outgoingParameter(this.$parent.params[0].outgoing);
    this.orderStateParameter(this.$parent.params[0].orderstate);
    this.xorderStateParameter(this.$parent.params[0].xorderstate);
    this.loadIdParameter(this.$parent.params[0].loadid);
    this.geCustomWidgets = new GreatEdgeCustomWidgets();

    this.includeLoads.subscribe((newVal) => {
      if (!this._orderInitLoad) {
        this.refreshOrderGrid();
      }
    });
    this.includeOrders.subscribe((newVal) => {
      if (!this._orderInitLoad) {
        this.refreshOrderGrid();
      }
    });
    this.expired.subscribe((newVal) => {
      if (!this._orderInitLoad) {
        this.refreshOrderGrid();
      }
    });

    this.isExpireLoads = ko.computed(() => {
      if (
        this.includeLoads() &&
        userProfile.isOwnerOperator == false &&
        userProfile.isUserDriver == false
      ) {
        return true;
      }
      return false;
    });

    if (mobilecheck() == false) {
      $(".hideOffPageSneakyWrapper").css({ left: "-150%" });
      $("div[aria-id='order-planning-page']").css({ "margin-top": "-40px" });

      const sliderOptions = {
        mode: "fixed",
        showTooltip: true,
        min: 0,
        max: 365,
        showTicks: false,
        width: "150px",
      };

      this.orderDateRangeSliders = {
        daysBefore: jqxSliderWidget("#jqxOrdersBeforeDateSlider")({
          ...sliderOptions,
          thumbBtnTooltip: "Day(s) before current date. (0 = Today Only)",
        }),
        daysAfter: jqxSliderWidget("#jqxOrdersAfterDateSlider")({
          ...sliderOptions,
          thumbBtnTooltip: "Days(s) after current date. (0 = Today Only)",
        }),
      };

      this.isOrderGridLoading.subscribe((val) => {
        if (val == false) {
          $("#rangeSliderRefreshButtonIcon").removeClass("rotate-center");
        }
      });
    }

    this.cancelPendingGridRefreshes = false;
    broadcastAPI.listenOn("geCurrentViewingAgency")(() => {
      this.cancelPendingGridRefreshes = true;
    });

    userProfile.roles().then((roles = []) => this.userRoles(roles));
  }

  handleOnSelected = (state) => {
    setTimeout(async () => {
      this.searchOptionChanged = true
      this.handleLoadGridFilters(state);
      this.addFilters();
      loadGridState(state.grid, this.grid);

      this.initFiltersLoaded(true);
      this.searchOptionChanged = false;
    }, 300);
  };

  canSeeCallLeadBtn = (agencyId) => {
    const roles = this.userRoles();

    if (
      roles.some((x) => x === "Administrators" || x === "Greatwide Employees")
    ) {
      return true;
    } else if (
      agencyId === userProfile.currentAgencyId() &&
      roles.some((x) => x === "Agency Manager" || x === "Agent")
    ) {
      return true;
    }

    return false;
  };

  handleRefreshGrid = (removeRanges = false) => {
    if (this.orderDateRangeSliders) {
      if (this.isOrderGridLoading() == false) {
        const { daysBefore, daysAfter } = this.orderDateRangeSliders;

        this.defaultOptions = {
          daysBefore: removeRanges ? null : daysBefore.getValue(),
          daysAfter: removeRanges ? null : daysAfter.getValue(),
        };

        this.refreshOrderGrid();

        if (removeRanges == false) {
          $("#rangeSliderRefreshButtonIcon").addClass("rotate-center");
        } else {
          daysBefore.setValue(0);
          daysAfter.setValue(0);
        }
      }
    }
  };

  handleCallLeadBtnClick = ({
    id = 0,
    orderId = 0,
    agencyId = 0,
    isLoad = undefined,
    origin = "",
    terminalCanEditCallLead = false,
  } = {}) => {
    if (
      (id > 0 || orderId > 0) &&
      (this.canSeeCallLeadBtn(agencyId) || terminalCanEditCallLead)
    ) {
      const originLoc = mapToCityStateZipObject(
        cleanString(["(", ")", ","], origin).split(" ")
      );
      const callLeadData = {
        orderId: isLoad ? null : orderId,
        loadId: isLoad ? id : null,
        originCity: originLoc.city,
        originState: originLoc.state,
        originZip: originLoc.zip,
      };
      this.callLeadsModal(callLeadData);
    }
  };

  loadOrders = ({ initHeight = 400 } = {}) => {
    //This is being called from order-planning-page.js
    return new Promise((resolve, reject) => {
      let sortInfo = {
        dataField: undefined,
        ascending: true,
      };

      var source = {
        url: "OrderPlanning/GetOrders",
        datatype: "json",
        beforeprocessing: (data) => {
          this.$parent
            .orderEntryTracking()
            .data.orderOpenedAtTimestamp(data.orderOpenedAtTimestamp);
          data.orderResult = data.orderResult || [];

          // remove timezone modifiers sent from server
          data.orderResult = data.orderResult.map((x) => {
            if (x.pickUpDate) {
              x.pickUpDate = x.pickUpDate.substr(
                0,
                x.pickUpDate.indexOf("T") + 9
              );
            }
            if (x.deliveryDate) {
              x.deliveryDate = x.deliveryDate.substr(
                0,
                x.deliveryDate.indexOf("T") + 9
              );
            }
            if (x.createdDate) {
              x.createdDate = x.createdDate.substr(
                0,
                x.createdDate.indexOf("T") + 9
              );
            }
            if (x.drayPickupDate) {
              x.drayPickupDate = x.drayPickupDate.substr(
                0,
                x.drayPickupDate.indexOf("T") + 9
              );
            }
            if (x.drayDeliveryDate) {
              x.drayDeliveryDate = x.drayDeliveryDate.substr(
                0,
                x.drayDeliveryDate.indexOf("T") + 9
              );
            }
            if (x.loadDate) {
              x.loadDate = x.loadDate.substr(0, x.loadDate.indexOf("T") + 9);
            }
            if (x.sailDate) {
              x.sailDate = x.sailDate.substr(0, x.sailDate.indexOf("T") + 9);
            }
            if (x.vesselDate) {
              x.vesselDate = x.vesselDate.substr(
                0,
                x.vesselDate.indexOf("T") + 9
              );
            }

            if (x.containerReleaseDate) {
              x.containerReleaseDate = x.containerReleaseDate.substr(
                0,
                x.containerReleaseDate.indexOf("T") + 9
              );
            }

            if (x.lastStatusUpdate) {
              x.lastStatusUpdate = x.lastStatusUpdate.substr(
                0,
                x.lastStatusUpdate.indexOf("T") + 9
              );
            }

            x.hazmat = x.hazmat == "Yes" ? true : false;
            x.ltl = x.ltl == "Yes" ? true : false;
            return x;
          });
        },
        formatdata: (data) => {
          this.gridProcessing = true;
          this.isOrderGridLoading(true);
          data = data || {};
          data.agencyId = userProfile.currentAgencyId();
          data.radiusLocation = this.orderRadiusLocation();
          data.radius = this.orderRadius();
          data.includeLoads = this.includeLoads();

          if (this.isLoad() == true) {
            //If isLoad param is passed and value is True, and this is the first load, return load data.  this.includeLoads will be set to true in "AddFilters" function, which will check the checkbox
            //If isLoad param is passed and value is True but this is not the initial load (eg. user clicked loads or orders subscriber), then data.include loads follows the this.includeLoads subscriber
            if (this._orderInitLoad) {
              data.includeLoads = true;
            }
          }
          //Include loads default case
          else {
            data.includeLoads = this.includeLoads();
          }

          if (this._orderInitLoad) {
            //gridStateUtils.loadInitGridState(data, this.orderGridState.filters, this.columns()); // applies user saved state, column ordering, etc
            data.isInitLoad = true;
          } else {
            data.isInitLoad = false;
          }

          if (this.defaultOptions) {
            data.ByDaysBefore = this.defaultOptions.daysBefore;
            data.ByDaysAfter = this.defaultOptions.daysAfter;
          } else if (this.myProfileDefaultOptions) {
            data.ByDaysBefore = this.myProfileDefaultOptions.daysBefore;
            data.ByDaysAfter = this.myProfileDefaultOptions.daysAfter;
          }

          data.expired = this.expired();
          data.loadId = this.loadIdParameter();
          if (this.includeOrders() == true) {
            data.includeOrders = true;
          } else if (this.includeOrders() == false) {
            data.includeOrders = false;
          } else {
            data.includeOrders = this.includeOrders();
          }
          data.includePartner = this.includePartnerOrders();
          data.regions = this.$parent.headerActionButtonsComponent.regions();
          data.locationRegions =
            this.$parent.headerActionButtonsComponent.locationRegions();
          data.customerRegions =
            this.$parent.headerActionButtonsComponent.customerRegions();
          if (this.$parent.matchTractorId() > 0) {
            data.matchTractorId = this.$parent.matchTractorId();
          }
          // jqx is passing in the filter groups/operators info for some reason...causing errors.
          // so removing them from being passed in our 'get' request to the api
          if (data) {
            Object.keys(data).forEach((propName) => {
              if (propName.indexOf("filter") != -1) {
                delete data[propName];
              }
              if (propName.indexOf("operator") != -1) {
                delete data[propName];
              }
            });
            if (data.groupscount) delete data.groupscount;
          }

          return data;
        },
        datafields: [
          { name: "id", type: "int" },
          { name: "distance", type: "float" },
          { name: "orderExId", type: "string" },
          { name: "orderId", type: "int" },
          { name: "status", type: "string" },
          { name: "origin", type: "string" },
          { name: "destination", type: "string" },
          { name: "pickUpDate", type: "date" },
          { name: "deliveryDate", type: "date" },
          { name: "customerExId", type: "string" },
          { name: "trailerExId", type: "string" },
          { name: "trailerType", type: "string" },
          { name: "noBroker", type: "bool" },
          { name: "container", type: "string" },
          { name: "totalCharge", type: "float" },
          { name: "miles", type: "float" },
          { name: "bolNumber", type: "string" },
          { name: "hazmat", type: "boolean" },
          { name: "brokered", type: "boolean" },
          { name: "isBrokered", type: "boolean" },
          { name: "customerReferenceNumber", type: "string" },
          { name: "chassis", type: "string" },
          { name: "bookingNumber", type: "string" },
          { name: "pickUpNumber", type: "string" },
          { name: "customerOrderNumber", type: "string" },
          { name: "manifestExId", type: "string" },
          { name: "customField1", type: "string" },
          { name: "customField2", type: "string" },
          { name: "customField3", type: "string" },
          { name: "ltl", type: "boolean" },
          { name: "recurringPost", type: "string" },
          { name: "postDaysRemaining", type: "string" },
          { name: "postingStatus", type: "string" },
          { name: "isPreassigned", type: "boolean" },
          { name: "assigned", type: "string" },
          { name: "assignedTractorId", type: "int" },
          { name: "isLoadTendered", type: "bool" },
          { name: "driverResponse", type: "string" },
          { name: "carrierId", type: "int" },
          { name: "carrierExId", type: "string" },
          { name: "carrierPayRate", type: "float" },
          { name: "carrierDriverUserId", type: "int" },
          { name: "isYsplit", type: "bool" },
          { name: "stopsCount", type: "int" },
          { name: "agencyId", type: "int" },
          { name: "agencyExId", type: "string" },
          { name: "agencyPhone", type: "string" },
          { name: "terminal", type: "string" },
          { name: "isEditable", type: "bool" },
          { name: "isLoad", type: "bool" },
          { name: "sharedOrder", type: "bool" },
          { name: "createdDate", type: "date" },
          { name: "dispatchMessages", type: "string" },
          { name: "blockDispatch", type: "bool" },
          { name: "isLockout", type: "bool" },
          { name: "userName", type: "string" },
          { name: "showDetailedGrid", type: "bool" },
          { name: "customerName", type: "string" },
          { name: "assignedTo", type: "string" },
          { name: "trlrDescr", type: "string" },
          { name: "steamShipLine", type: "string" },
          { name: "weight", type: "float" },
          { name: "drayPickupDriverExternalId", type: "string" },
          { name: "drayPickupDriverFirstName", type: "string" },
          { name: "drayPickupDriverLastName", type: "string" },
          { name: "drayPickupDate", type: "date" },
          { name: "containerReleaseDate", type: "date" },
          { name: "drayDeliveryDriverExternalId", type: "string" },
          { name: "drayDeliveryDriverFirstName", type: "string" },
          { name: "drayDeliveryDriverLastName", type: "string" },
          { name: "drayDeliveryDate", type: "date" },
          { name: "vesselDate", type: "date" },
          { name: "shipperName", type: "string" },
          { name: "consigneeName", type: "string" },
          { name: "sailDate", type: "date" },
          { name: "loadDate", type: "date" },
          { name: "operationsUser", type: "string" },
          { name: "isProjectLoad", type: "bool" },
          { name: "commodity", type: "string" },
          { name: "lastLocation", type: "string" },
          { name: "lastStatusUpdate", type: "date" },
          { name: "terminalCanEditCallLead", type: "bool" },
          { name: "carrierRelationshipUser", type: "string" },
          { name: "hasTPIRate", type: "bool" },
        ],
        loadComplete: (data) => {
          $('[data-toggle="tooltip"]').tooltip();
          this.isOrderGridLoading(false);

          this.updateStatusBar();
        },
        loadError: (error) => {
          this.isOrderGridLoading(false);
        },
        beforeLoadComplete: (records) => {
          var recordsLen = records.length;
          // for jqx grid to sort properly must be this format -> year, then month, then day.
          // To change how it's 'viewed' in the grid, change format in the cellsrendered function.
          const fmt = "YYYY/MM/DD HH:mm";

          for (var i = 0; i < recordsLen; i++) {
            records[i].isBrokered = records[i].brokered ? "Yes" : "No";
            if (records[i].carrierId > 0 && records[i].carrierPayRate > 0) {
              records[i].sendRC = 1;
            }
            if (records[i].isPreassigned > 0) {
              records[i].clearAssignment = 1;
            }
            if (records[i].isEditMode) {
              records[i].editLoad = 1;
              records[i].createOrderFromLoad = 1;
            }
            if (records[i].isLoad == false && records[i].isPreassigned) {
              if (
                (records[i].brokered == true &&
                  records[i].carrierDriverUserId != null) ||
                records[i].brokered == false
              ) {
                if (records[i].isLoadTendered) {
                  records[i].tenderLoad = 1;
                } else {
                  records[i].tenderLoad = 2;
                }
              }
            }
          }
          return records;
        },
      };
      var orderSource = dataModel.getDataAdapter(source);
      this.grid.jqxGrid({
        theme: "GWTMDark",
        width: "100%",
        height: initHeight,
        source: orderSource,
        altrows: true,
        showfilterrow: true,
        sortable: true,
        filterable: true,
        columnsreorder: true,
        enablebrowserselection: true,
        columnsmenu: false,
        columnsresize: true,
        columns: this.columns(),
        toolbarheight: 70,
        showstatusbar: true,
        statusbarheight: 25,
        renderstatusbar: (bar) => this.updateStatusBar(bar),
        showtoolbar: true,
        rendertoolbar: this.renderToolBar,
        ready: () => {
          this.addFilters();

          gridStateUtils.applyGridState("jqxOrderGrid", this.orderGridState);
          this._orderInitLoad = false;
          this.setCustomFieldNames();
        },
      });

      // sorting is being reset in UI whenever a new request is made.
      // note: using the 'sort' property/method is not working...
      this.grid.on("bindingcomplete", () => {
        if (sortInfo.dataField) {
          this.grid.jqxGrid("sortBy", sortInfo.dataField, sortInfo.ascending);
        } else {
          this.grid.jqxGrid("removesort");
        }

        this.updateStatusBar();
      });

      this.grid.on("sort", (e) => {
        const { sortinformation } = e.args || {};
        const { sortcolumns } = sortinformation || [];

        if (sortcolumns[0]) {
          sortInfo = sortcolumns[0];
        } else {
          sortInfo.dataField = undefined;
        }
      });

      this.registerGridEvents();

      resolve();
    });
  };

  updateStatusBar = ($statusbar = $()) => {
    const $el = $("#orderRowCount");
    const $orderCountText = $(`<div>`, {
      id: "orderRowCount",
      style: "padding:4px;",
    });
    $orderCountText.append(
      `Number of Records: ${this.grid.jqxGrid("getrows").length}`
    );

    if ($statusbar && $statusbar.length) {
      $statusbar.append($orderCountText);
    } else {
      $el.replaceWith($orderCountText);
    }
  };

  setCustomFieldNames = () => {
    const cf = this.$parent.customDataFields();
    if (!cf) return false;

    if (cf["customField1"])
      this.grid.jqxGrid(
        "setcolumnproperty",
        "customField1",
        "text",
        cf["customField1"]
      );
    if (cf["customField2"])
      this.grid.jqxGrid(
        "setcolumnproperty",
        "customField2",
        "text",
        cf["customField2"]
      );
    if (cf["customField3"])
      this.grid.jqxGrid(
        "setcolumnproperty",
        "customField3",
        "text",
        cf["customField3"]
      );
  };

  registerGridEvents() {
    this.grid.on("cellclick", this.gridButtonIconSelected);
    this.grid.on("bindingcomplete", (event) => {
      this.gridProcessing = false;
      var records = this.grid.jqxGrid("getrows");
      $("#orderRowCount").text("Number of Records: " + records.length);
      // override any user-defined sorting if Match My Truck or order radius search is selected as grid data will be sorted by distance
      if (
        this.$parent.matchTractorId() > 0 ||
        this.orderRadiusLocation().length > 0
      ) {
        this.grid.jqxGrid("removesort");
        this.$parent.matchTractorId(undefined);
      }
    });

    this.grid.on("filter", (event) => {
      var filter = this.grid.jqxGrid("getfilterinformation");
      //See addfilters().  If there are no filters applied, do not show the 'Load Id' column
      if (!(filter.length > 0)) {
        this.grid.jqxGrid("hidecolumn", "id");
      }
      var records = this.grid.jqxGrid("getrows");
      $("#orderRowCount").text("Number of Records: " + records.length);
    });
  }

  geDateRangeFilterWidget = (column, columnElement, widget) => {
    //set widget (class) level options
    var options = {
      gridOptions: {
        gridWidget: widget,
        gridId: "jqxOrderGrid", // required to apply gridoptions
        columnName: column.datafield, // also required, especially if this is a filter widget
        isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
        state: this.orderGridState, // can send our grid state and apply any initial filters
      },
    };
    return this.geCustomWidgets.GEDateRangePickerWidget(options); // will return widget if already exists or creates a new one;
  };

  cellclassnameb = function (row, column, value, data) {
    if (data.isLockout) {
      return "lockoutRowClass";
    }
  };

  placeHolderFilterWidget = (column, columnElement, widget) => {
    widget.jqxInput({
      placeHolder: "Type Here...",
    });
  };

  columns = () => {
    let columns = [
      {
        text: "",
        width: 100,
        datafield: "editButton",
        filterable: false,
        sortable: false,
        pinned: true,
        columnType: "button",
        buttonclick: (row) => {
          var datarow = this.grid.jqxGrid("getrowdata", row);
          this.orderDetailModal(datarow);
        },
        cellsrenderer: () => {
          return "Details";
        },
      },
      {
        text: "",
        width: 100,
        datafield: "callLeadsBtn",
        filterable: false,
        sortable: false,
        pinned: true,
        cellsrenderer: (
          rowindex,
          datafield,
          value,
          defaulthtml,
          columnprops,
          rowdata = {}
        ) => {
          const $el = $(defaulthtml);
          const { terminalCanEditCallLead, agencyId } = rowdata;

          if (this.canSeeCallLeadBtn(agencyId) || terminalCanEditCallLead) {
            const $btn = $(
              `<input type="button" value="Call Leads" class="callLeadsBtn" data-rowid="${rowindex}"/>`
            );
            $btn.jqxButton({ width: "93", height: "27" });

            $el.append($btn);
            $el.css({ padding: "0", "margin-top": "3px" });
          }

          return $el[0].outerHTML;
        },
      },
      //Load Id is also being hidden in the 'grid.on('filter') function in case the load Id is unhidden by addfilter and then the user backspace/removes the filter
      {
        text: "Load Id",
        datafield: "id",
        cellclassname: this.cellclassnameb,
        hideable: true,
        hidden: true,
        width: 100,
      },
      {
        text: "-",
        datafield: "isLoad",
        cellclassname: this.cellclassnameb,
        hideable: true,
        hidden: true,
      },
      {
        text: "Order Id",
        datafield: "orderExId",
        width: 100,
        pinned: true,
        hideable: false,
        cellclassname: this.cellclassnameb,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaultHTML,
          column,
          rowData
        ) => {
          if (defaultHTML != null) {
            var parseDeliveryDate = new Date(rowData.deliveryDate);
            var parsePickUpDate = new Date(rowData.pickUpDate);

            if (
              rowData.isLoad == false &&
              (rowData.sharedOrder == false || userProfile.isGwEmployee)
            ) {
              var cell = $(defaultHTML);
              var bColor = "";
              var bColorIE = "";
              var cColor = "";
              var d = new Date();
              d.setHours(d.getHours() + 2);
              if (!rowData.isLockout) {
                if (rowData.status === "In Progress") {
                  if (d > parseDeliveryDate) {
                    bColor = "linear-gradient(to bottom,#222222,#f08080)";
                    bColorIE = "#f08080";
                    cColor = "white";
                  }
                } else if (
                  rowData.status === "Available" ||
                  rowData.status === "Covered"
                ) {
                  if (d > parsePickUpDate) {
                    bColor = "linear-gradient(to bottom,#222222,#f08080)";
                    bColorIE = "#f08080";
                    cColor = "white";
                  } else if (rowData.isPreassigned) {
                    bColor = "linear-gradient(to bottom,#222222,#017245)";
                    bColorIE = "#017245";
                    cColor = "#06f998";
                  } else {
                    bColor = "linear-gradient(to bottom,#222222,#00529b)";
                    bColorIE = "#00529b";
                    cColor = "white";
                  }
                }
              }

              var ysplit = "";
              if (rowData.isYsplit) {
                ysplit = '<img src="./Content/Images/YSplit.png" />';
              }
              $(cell).css({
                "background-image": bColor,
                "background-color": bColorIE,
              });

              cell.addClass("jqxGridCustomCell");

              if (value != "") {
                if (userProfile.isOwnerOperator) {
                  cell.html('<style="color:' + cColor + ';">' + value + ysplit);
                } else {
                  if (cColor) {
                    cell.html(
                      '<a href="Orders/' +
                        value +
                        '" target="_blank"  style="color:' +
                        cColor +
                        ';"   >' +
                        value +
                        "</a> " +
                        ysplit
                    );
                  } else {
                    cell.html(
                      '<a href="Orders/' +
                        value +
                        '" target="_blank"  class="colorId" >' +
                        value +
                        "</a> " +
                        ysplit
                    );
                  }
                }
              }
              return cell[0].outerHTML;
            }
          }
          return defaultHTML;
        },
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Distance",
        datafield: "distance",
        cellsformat: "d2",
        cellsalign: "center",
        filtercondition: "starts_with",
        width: 80,
        hideable: true,
        hidden: true,
        cellclassname: this.cellclassnameb,
      },
      {
        text: "Status",
        datafield: "status",
        filtertype: "checkedlist",
        width: 130,
        hideable: false,
        filtercondition: "EQUAL",
        cellclassname: this.cellclassnameb,
        filteritems: [
          { value: "Available", label: "Available", html: "Available" },
          { value: "In Progress", label: "In Progress", html: "In Progress" },
          { value: "Covered", label: "Covered", html: "Covered" },
          { value: "Load", label: "Load", html: "Load" },
        ],
      },
      {
        text: "Origin",
        datafield: "origin",
        width: 200,
        cellclassname: this.cellclassnameb,
        createfilterwidget: (column, columnElement, widget) => {
          widget.jqxInput({
            placeHolder: "Enter City, State, (Zip)",
          });
        },
      },
      {
        text: "Destination",
        datafield: "destination",
        width: 200,
        cellclassname: this.cellclassnameb,
        createfilterwidget: (column, columnElement, widget) => {
          widget.jqxInput({
            placeHolder: "Enter City, State, (Zip)",
          });
        },
      },
      {
        text: "Pick Up Date & Time",
        datafield: "pickUpDate",
        width: 200,
        cellsformat: "MM/dd/yyyy HH:mm",
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.geDateRangeFilterWidget,
      },
      {
        text: "Delivery Date & Time",
        datafield: "deliveryDate",
        width: 200,
        cellsformat: "MM/dd/yyyy HH:mm",
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.geDateRangeFilterWidget,
      },
      {
        text: "Assigned",
        datafield: "assigned",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Driver Response",
        datafield: "driverResponse",
        width: 150,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Last Location",
        datafield: "lastLocation",
        minwidth: 180,
        cellclassname: this.cellclassnameb,
        filterable: false,
      },
      {
        text: "Last Status Update",
        datafield: "lastStatusUpdate",
        minwidth: 150,
        cellclassname: this.cellclassnameb,
        filterable: false,
        cellsformat: "MM/dd/yyyy HH:mm",
      },
      {
        text: "Trailer",
        datafield: "trailerExId",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Trailer Type",
        datafield: "trailerType",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Container",
        datafield: "container",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Total Charge",
        datafield: "totalCharge",
        columntype: "numberinput",
        cellsformat: "c2",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "BOL Number",
        datafield: "bolNumber",
        width: 120,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Hazmat",
        datafield: "hazmat",
        width: 100,
        cellclassname: this.cellclassnameb, //filtertype: 'checkedlist', filteritems: ["Yes", "No"]
        filtertype: "bool", // checkbox
        columntype: "checkbox",
        editable: false,
      },
      {
        text: "Brokered",
        datafield: "brokered",
        width: 100,
        cellclassname: this.cellclassnameb, //filtertype: 'checkedlist', filteritems: ["Yes", "No"], hidden: userProfile.isUserDriver
        filtertype: "bool", // checkbox
        columntype: "checkbox",
        editable: false,
        hidden: userProfile.isUserDriver,
      },
      {
        text: "Customer Reference Number",
        datafield: "customerReferenceNumber",
        width: 200,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Booking Number",
        datafield: "bookingNumber",
        width: 150,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Pickup Number",
        datafield: "pickUpNumber",
        width: 150,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Customer Order Number",
        datafield: "customerOrderNumber",
        width: 200,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "ManifestId",
        datafield: "manifestExId",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: this.$parent.customDataFields()?.customField1,
        datafield: "customField1",
        width: 120,
      },
      {
        text: this.$parent.customDataFields()?.customField2,
        datafield: "customField2",
        width: 120,
      },
      {
        text: this.$parent.customDataFields()?.customField3,
        datafield: "customField3",
        width: 120,
      },
      {
        text: "LTL",
        datafield: "ltl",
        width: 100,
        cellclassname: this.cellclassnameb, //filteritems: ["Yes", "No"],filtertype: 'checkedlist',
        filtertype: "bool", // checkbox
        columntype: "checkbox",
        editable: false,
      },
      {
        text: "Stops Count",
        datafield: "stopsCount",
        width: 120,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Recurring Post",
        datafield: "recurringPost",
        width: 120,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Post Days Remaining",
        datafield: "postDaysRemaining",
        width: 150,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Posting Status",
        datafield: "postingStatus",
        width: 120,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Posting Date",
        datafield: "createdDate",
        width: 200,
        cellsformat: "MM/dd/yyyy HH:mm",
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.geDateRangeFilterWidget,
      },
      {
        text: "Miles",
        datafield: "miles",
        cellsformat: "d2",
        cellsalign: "center",
        filterable: false,
        width: 70,
        cellclassname: this.cellclassnameb,
      },
      {
        text: "Dispatch Messages",
        datafield: "dispatchMessages",
        width: 200,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Agency",
        datafield: "agencyExId",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaultHTML,
          column,
          rowData
        ) => {
          if (defaultHTML != null) {
            if (rowData.agencyPhone) {
              return (
                '<span data-toggle="tooltip" title="' +
                formatPhoneNumber(rowData.agencyPhone) +
                '">' +
                defaultHTML +
                "</span>"
              );
            }
          }
          return defaultHTML;
        },
      },
      { text: "-", datafield: "agencyPhone", hidden: true },
      { text: "-", datafield: "blockDispatch", hidden: true },
      {
        text: "Entered By",
        datafield: "userName",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Assigned Name",
        datafield: "assignedTo",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Trlr Descr",
        datafield: "trlrDescr",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "SteamShip Line",
        datafield: "steamShipLine",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Weight",
        datafield: "weight",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Cutoff Date",
        datafield: "vesselDate",
        width: 200,
        cellsformat: "MM/dd/yyyy HH:mm",
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.geDateRangeFilterWidget,
      },
      {
        text: "Dray Pickup Driver ID",
        datafield: "drayPickupDriverExternalId",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Dray Pickup Driver First Name",
        datafield: "drayPickupDriverFirstName",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Dray Pickup Driver Last Name",
        datafield: "drayPickupDriverLastName",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Dray Pickup Date",
        datafield: "drayPickupDate",
        width: 200,
        cellsformat: "MM/dd/yyyy HH:mm",
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.geDateRangeFilterWidget,
      },
      {
        text: "Dray Delivery Driver ID",
        datafield: "drayDeliveryDriverExternalId",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Dray Delivery Driver First Name",
        datafield: "drayDeliveryDriverFirstName",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Dray Delivery Driver Last Name",
        datafield: "drayDeliveryDriverLastName",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Dray Delivery Date",
        datafield: "drayDeliveryDate",
        width: 200,
        cellsformat: "MM/dd/yyyy HH:mm",
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.geDateRangeFilterWidget,
      },
      {
        text: "Shipper Name",
        datafield: "shipperName",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Consignee Name",
        datafield: "consigneeName",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Last Free Date",
        datafield: "sailDate",
        width: 200,
        cellsformat: "MM/dd/yyyy HH:mm",
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.geDateRangeFilterWidget,
      },
      {
        text: "ERD",
        datafield: "loadDate",
        width: 200,
        cellsformat: "MM/dd/yyyy HH:mm",
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.geDateRangeFilterWidget,
      },
      {
        text: "Container Release Date",
        datafield: "containerReleaseDate",
        width: 200,
        cellsformat: "MM/dd/yyyy HH:mm",
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.geDateRangeFilterWidget,
      },
      {
        text: "Operations User",
        datafield: "operationsUser",
        width: 125,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Terminal",
        datafield: "terminal",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Commodity",
        datafield: "commodity",
        minwidth: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
      {
        text: "Carrier Relationship Manager",
        datafield: "carrierRelationshipUser",
        minwidth: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      },
    ];

    var isPinned = () => {
      return window.innerWidth > 768;
    };

    if (userProfile.isOwnerOperator == false) {
      columns.splice(2, 0, {
        text: "",
        datafield: "sendRC",
        menu: false,
        width: 30,
        resizable: false,
        hideable: false,
        filterable: false,
        pinned: isPinned(),
        cellclassname: "cellBorderNone",
        classname: "cellBorderNone",
        rendered: (columnHeaderElement) => {
          columnHeaderElement.html(
            "<img src='Content/Images/SendRC25x20.png'  />"
          );
          columnHeaderElement
            .parent()
            .attr("title", "Order By: Rate Confirmation");
        },
        cellsrenderer: this.actionRowRender,
      });
      columns.splice(3, 0, {
        text: "",
        datafield: "clearAssignment",
        filterable: false,
        menu: false,
        width: 30,
        resizable: false,
        hideable: false,
        pinned: isPinned(),
        cellclassname: "cellBorderNone",
        classname: "cellBorderNone",
        rendered: (columnHeaderElement) => {
          columnHeaderElement.html(
            "<img src='Content/Images/ClearAssignments25x20.png'/>"
          );
          columnHeaderElement.parent().attr("title", "Order By: Assignment");
        },
        cellsrenderer: this.actionRowRender,
      });
      columns.splice(4, 0, {
        text: "",
        datafield: "editLoad",
        filterable: false,
        menu: false,
        width: 30,
        resizable: false,
        hideable: false,
        pinned: isPinned(),
        cellclassname: "cellBorderNone",
        classname: "cellBorderNone",
        rendered: (columnHeaderElement) => {
          columnHeaderElement.html(
            "<img src='Content/Images/EditPosting25x20.png'  />"
          );
          columnHeaderElement.parent().attr("title", "Order By: Edit Load");
        },
        cellsrenderer: this.actionRowRender,
      });
      columns.splice(5, 0, {
        text: "",
        datafield: "createOrderFromLoad",
        filterable: false,
        menu: false,
        width: 30,
        resizable: false,
        hideable: false,
        pinned: isPinned(),
        cellclassname: "cellBorderNone",
        classname: "cellBorderNone",
        rendered: (columnHeaderElement) => {
          columnHeaderElement.html(
            "<img src='Content/Images/CreateOrderFromLoad25x20.png'  />"
          );
          columnHeaderElement
            .parent()
            .attr("title", "Order By: Create Order From Load");
        },
        cellsrenderer: this.actionRowRender,
      });
      columns.splice(6, 0, {
        text: "",
        datafield: "tenderLoad",
        filterable: false,
        menu: false,
        width: 30,
        resizable: false,
        hideable: false,
        pinned: isPinned(),
        rendered: (columnHeaderElement) => {
          columnHeaderElement.html(
            "<img src='Content/Images/AssignTractor25x20.png'  />"
          );
          columnHeaderElement.parent().attr("title", "Order By: Tender Load");
        },
        cellsrenderer: this.actionRowRender,
      });
      columns.splice(7, 0, {
        text: "",
        datafield: "hasTPIRate",
        filterable: false,
        menu: false,
        width: 30,
        resizable: false,
        hideable: false,
        pinned: isPinned(),
        rendered: (columnHeaderElement) => {
          columnHeaderElement.html(
            "<img src='Content/Images/Rates32x26.png'  />"
          );
          columnHeaderElement.parent().attr("title", "Order By: Rate");
        },
        cellsrenderer: this.actionRowRender,
      });

      columns.splice(8, 0, {
        text: "Customer",
        datafield: "customerExId",
        width: 100,
        cellclassname: this.cellclassnameb,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaultHTML,
          column,
          rowData
        ) => {
          if (defaultHTML) {
            if (rowData.isLoad) {
              if (rowData.agencyId != userProfile.currentAgencyId()) {
                var cell = $(defaultHTML);
                cell.html("");
                return cell[0].outerHTML;
              }
            }
          }
          return defaultHTML;
        },
        createfilterwidget: this.placeHolderFilterWidget,
      });
      columns.splice(9, 0, {
        text: "Customer Name",
        datafield: "customerName",
        width: 100,
        cellclassname: this.cellclassnameb,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaultHTML,
          column,
          rowData
        ) => {
          if (defaultHTML) {
            if (rowData.isLoad) {
              if (rowData.agencyId != userProfile.currentAgencyId()) {
                var cell = $(defaultHTML);
                cell.html("");
                return cell[0].outerHTML;
              }
            }
          }
          return defaultHTML;
        },
        createfilterwidget: this.placeHolderFilterWidget,
      });
      columns.splice(18, 0, {
        text: "Carrier Pay",
        datafield: "carrierPayRate",
        columntype: "numberinput",
        cellsformat: "c2",
        width: 100,
        cellclassname: this.cellclassnameb,
        createfilterwidget: this.placeHolderFilterWidget,
      });
    }

    return columns;
  };

  actionRowRender = (row, columnfield, value, defaultHTML, column, rowData) => {
    if (defaultHTML != null) {
      if (columnfield == "sendRC") {
        if (rowData.carrierId > 0) {
          if (rowData.carrierPayRate > 0) {
            if (rowData.isLockout == true) {
              return "<img src='../Content/Images/SendRC32x26.png' title='Send Rate Confirmation' style='cursor: pointer' class='lockoutRowClassNoBorder' />";
            }
            return "<img src='../Content/Images/SendRC32x26.png' title='Send Rate Confirmation' style='cursor: pointer;' />";
          }
        }
      } else if (columnfield == "clearAssignment") {
        if (rowData.isPreassigned > 0) {
          if (rowData.isLockout == true) {
            return "<img src='Content/Images/ClearAssignments32x26.png' title='Clear Assignment' style='cursor: pointer' class='lockoutRowClassNoBorder' />";
          }
          return "<img src='Content/Images/ClearAssignments32x26.png' title='Clear Assignment' style='cursor: pointer;' />";
        }
      } else if (columnfield == "editLoad") {
        if (rowData.isEditable) {
          if (rowData.isLockout == true) {
            return "<img src='Content/Images/EditPosting32x26.png' title='Edit Load' style='cursor: pointer' class='lockoutRowClassNoBorder' />";
          }
          return "<img src='Content/Images/EditPosting32x26.png' title='Edit Load' style='cursor: pointer;' />";
        }
      } else if (columnfield == "createOrderFromLoad") {
        if (rowData.isEditable) {
          if (rowData.isLockout == true) {
            return "<img src='Content/Images/CreateOrderFromLoad32x26.png' title='Create Order From Load' style='cursor: pointer' class='lockoutRowClassNoBorder' />";
          }
          return "<img src='Content/Images/CreateOrderFromLoad32x26.png' title='Create Order From Load' style='cursor: pointer;' />";
        }
      } else if (columnfield == "tenderLoad") {
        if (rowData.isLoad == false && rowData.isPreassigned) {
          if (
            (rowData.brokered == true && rowData.carrierDriverUserId != null) ||
            rowData.brokered == false
          ) {
            if (rowData.isLoadTendered) {
              if (rowData.isLockout == true) {
                return "<img src='Content/Images/TenderLoad32x26.png' title='Unassign Tender' style='cursor: pointer' class='lockoutRowClassNoBorder'/>";
              } else {
                return "<img src='Content/Images/TenderLoad32x26.png' title='Unassign Tender' style='cursor: pointer;' />";
              }
            } else {
              if (rowData.isLockout == true) {
                return "<img src='Content/Images/AssignTender32x26.png' title='Tender Load' style='cursor: pointer' class='lockoutRowClassNoBorder' />";
              } else {
                return "<img src='Content/Images/AssignTender32x26.png' title='Tender Load' style='cursor: pointer;' />";
              }
            }
          }
        }
      } else if (columnfield == "hasTPIRate") {
        // Order/Load same agency?
        const $el = $(defaultHTML);
        $el.text("");

        if (rowData.agencyId === userProfile.currentAgencyId()) {

          if(!rowData.hasTPIRate && !rowData.trailerExId) {
            return $el[0].outerHTML;
          }

          if (rowData.isLoad === false && rowData.brokered) {
            if (rowData.hasTPIRate) {
              // already has rates?
              return "<img src='Content/Images/Rates32x26.png' title='View Rates' style='cursor: pointer' style='background-color: blue'/>";
            } else if (rowData.trailerExId) {
              // need trailer to get rates
              return "<img src='Content/Images/Rates32x26.png' title='Get Rates' style='cursor: pointer;' />";
            }
          }

          if (rowData.isLoad) {
            if (rowData.hasTPIRate) {
              // already has rates?
              return "<img src='Content/Images/Rates32x26.png' title='View Rates' style='cursor: pointer' style='background-color: blue'/>";
            } else if (rowData.trailerExId) {
              // need trailer to get rates
              return "<img src='Content/Images/Rates32x26.png' title='Get Rates' style='cursor: pointer;' />";
            }
          }
        }
      }

      if (rowData.isLockout == true) {
        return "<div class='lockoutRowClassNoBorder' style='cursor: pointer; width:100%;height:100%'></div>";
      }
      return "";
    }
    return defaultHTML;
  };

  renderToolBar = (toolbar) => {
    var _jqxOrdersGridHelper =
      this.geCustomWidgets.GEWidgetsGridHelper("jqxOrderGrid");
    var vm1 = new GridStateComponentViewModel.viewModel(this.pageName);
    var orderGridTmpl = $("#orderGridToolbar");
    toolbar.append(orderGridTmpl);

    if (mobilecheck() == false) {
      const $headerBtns = $("#headerActionDiv");
      const $toolbarrow = $("#toolbarrow");
      $toolbarrow.append($headerBtns);
    }

    if (!userProfile.isUserDriver || !userProfile.isOwnerOperator) {
      vm1.actions.push("Match My Order");
    }
    if (!userProfile.isUserDriver || !userProfile.isOwnerOperator) {
      vm1.actions.push("Post New Load");
    }
    vm1.actions.push("Radius Search");
    if (!userProfile.isUserDriver || !userProfile.isOwnerOperator) {
      vm1.actions.push("Batch Load Posting");
    }
    if (!userProfile.isUserDriver || !userProfile.isOwnerOperator) {
      vm1.actions.push("Expire All Postings");
    }
    vm1.actions.push("Move Trailer");
    vm1.actions.push("View Call-in Record(s)");
    vm1.actions.push("Insert Call-in Record");
    vm1.actions.push("Export");
    vm1.actions.push("Project Posting");
    vm1.actions.push("Save Search");

    vm1.clearFilters = () => {
      if (this.orderRadiusLocation().length > 0) {
        this.orderRadiusLocation("");
        this.orderRadius(10);
        this.hideLoadIdColumn();
        this.refreshOrderGrid();
      } else {
        this.hideLoadIdColumn();

        this.includeLoads(false);
        this.includeOrders(false);
        this.expired(false);

        this.grid.jqxGrid("clearfilters");
      }
      this.clearSelectedSavedSearchDDL(true);
      // Call this last in clearfilters for this page so widgets reapply after
      // all the other logic is finished.
      _jqxOrdersGridHelper.clearDateRangeFiltersFromGrid();
    };

    vm1.setDefaultSearchOverride = async () => {
      const savedSearches = await loadSavedSearches("jqxOrderGrid");

      var filters = {
        includeOrders: this.includeOrders(),
        includeLoads: this.includeLoads(),
        expired: this.expired(),
        defaultGridToggleOptions: {
          ...this.defaultGridToggleOptions(),
          timestamp: Date.now(),
        },
        isDefault: true,
      };

      vm1.loadSaveSearchModal(
        this.grid,
        (val) => {
          if (val && val.searchname) {
            this.refreshSavedSearchDDL(val.searchname);
          }
        },
        savedSearches
          .filter((x) => x.searchName)
          .map((x) => ({ id: x.id, text: x.searchName })),
        filters,
        true
      );
    };

    vm1.loadSaveSearchModalOverride = async () => {
      const savedSearches = await loadSavedSearches("jqxOrderGrid");

      var filters = {
        includeOrders: this.includeOrders(),
        includeLoads: this.includeLoads(),
        expired: this.expired(),
        defaultGridToggleOptions: {
          ...this.defaultGridToggleOptions(),
          timestamp: Date.now(),
        },
        isDefault: false,
      };

      vm1.loadSaveSearchModal(
        this.grid,
        (val) => {
          if (val && val.searchname) {
            this.refreshSavedSearchDDL(val.searchname);
          }
        },
        savedSearches
          .filter((x) => x.searchName)
          .map((x) => ({ id: x.id, text: x.searchName })),
        filters,
        true
      );
    };

    vm1.refresh = this.refreshOrderGrid;

    // override the save method of grid show/hide/reorder modal
    vm1.overrideCustomGridModalOptionsSave = () => {
      var filters = vm1.setDefaultSearchOverride();
      vm1.saveGridFilters(this.grid, filters);
    };

    vm1.action = this.toolbarActionHandler;

    var tdOrderGridAction = orderGridTmpl.find("#tdOrderGridAction");
    tdOrderGridAction.append(GridStateComponentViewModel.template);
    ko.applyBindingsToDescendants(vm1, tdOrderGridAction[0]);
  };

  gridButtonIconSelected = (event) => {
    // event arguments.
    var args = event.args;

    // row's bound index.
    var rowBoundIndex = args.rowindex;
    // column data field.
    var dataField = args.datafield;

    if (dataField == "sendRC") {
      let rowData = this.grid.jqxGrid("getrowdata", rowBoundIndex);
      if (rowData.carrierId > 0) {
        if (rowData.carrierPayRate > 0) {
          // Display Send Rate Modal
          this.rateConfirmationModal({
            movementId: rowData.id,
            carrierId: rowData.carrierId,
          });
        }
      }
    } else if (dataField == "clearAssignment") {
      let rowData = this.grid.jqxGrid("getrowdata", rowBoundIndex);
      if (rowData.isPreassigned > 0) {
        var clearAssignmentModel = new ClearAssignmentViewModel(
          rowData.id,
          this
        );
        this.clearAssignmentConfirmationModal(clearAssignmentModel);
      }
    } else if (dataField == "editLoad") {
      let rowData = this.grid.jqxGrid("getrowdata", rowBoundIndex);
      if (rowData.isEditable && rowData.isProjectLoad == false) {
        this.$parent.isLoading(true);
        dataModel
          .ajaxRequest("OrderPlanning/GetLoad/" + rowData.id, "GET")
          .done((data, status, xhr) => {
            this.load({ load: data });
            this.$parent.isLoading(false);
          })
          .fail((error, msg, d) => {
            this.$parent.isLoading(false);
          });
      } else if (rowData.isEditable && rowData.isProjectLoad == true) {
        this.projectPostingModal({
          loadId: rowData.id,
          onLoadSave: this.onLoadSave,
        });
      }
    } else if (dataField == "createOrderFromLoad") {
      let rowData = this.grid.jqxGrid("getrowdata", rowBoundIndex);
      if (rowData.isEditable) {
        this.showCreateOrderFromLoadModal(true);
        this.lblOrderFromLoadMsg("");
        this.orderFromLoad([]);
        this.selectedLoadId(rowData.id);
        this.createOrderFromLoadShowExpireLoadBtn(false);
      }
    } else if (dataField == "tenderLoad") {
      var rowData = this.grid.jqxGrid("getrowdata", rowBoundIndex);
      if (rowData.isLoad == false && rowData.isPreassigned) {
        if (
          (rowData.brokered == true && rowData.carrierDriverUserId != null) ||
          rowData.brokered == false
        ) {
          if (rowData.isLoadTendered) {
            cancelTender({
              movementId: rowData.id,
              onResolve: () => this.refreshOrderGrid(true),
            });
          } else {
            this.showTenderLoadModal({
              movementId: rowData.id,
              onSave: () => this.refreshOrderGrid(true),
            });
          }
        }
      }
    } else if (dataField === "callLeadsBtn") {
      this.handleCallLeadBtnClick(
        this.grid.jqxGrid("getrowdata", rowBoundIndex)
      );
    } else if (dataField === "hasTPIRate") {
      const rowData = this.grid.jqxGrid("getrowdata", rowBoundIndex) || {};

      if (
        rowData.agencyId !== userProfile.currentAgencyId() ||
        (rowData.isLoad === false && rowData.brokered === false)
      ) {
        return;
      }

      if (rowData.hasTPIRate) {
        this.handleViewTPIRates({
          orderId: rowData.isLoad ? null : rowData.orderId,
          loadId: rowData.isLoad ? rowData.id : null,
        });
      } else {
        const rowId = this.grid.jqxGrid("getrowid", args.rowindex);
        this.handleGetTPIRates({ rowId, ...rowData, loadId: rowData.id });
      }
    }
  };

  addFilters = () => {
    var filter_or_operator = 1;
    var filtercondition = "contains";
    //CHECK TO SEE WHETHER THE ROW IS A LOAD OR ORDER
    //THE loadId WILL BE EQUAL TO RECORDS.ID IF ROW IS A LOAD
    //THE loadId WILL BE EQUAL TO RECORDS.ORDERID IF ROW IS AN ORDER

    //IF filterIsLoad == true (THE RECORD IS A LOAD), ADD A FILTER TO FILTER BY ID
    //IF filterIsLoad != true (THE RECORD IS AN ORDER), ADD A FILTER TO FILTER BY ORDERID
    if (this.loadIdParameter()) {
      //CREATE THE FILTER
      var filtergroup5 = new $.jqx.filter();
      var filter5 = filtergroup5.createfilter(
        "stringfilter",
        this.loadIdParameter(),
        filtercondition
      );
      filtergroup5.addfilter(filter_or_operator, filter5);
      //CHECK WHETHER ORDER OR LOAD AND APPLY FILTER ACCORDINGLY.  ALSO CLEAR USERS EXISTING FILTERS
      if (this._orderInitLoad) {
        if (this.isLoad()) {
          this.includeLoads(true);
          this.grid.jqxGrid("clearfilters");
          //Load Id' column is shown(below), but is being rehidden in the 'grid.on('filter') function if the user backspaces/removes the filter.
          //Once they remove the filter, we want rehide the column
          this.grid.jqxGrid("showcolumn", "id");
          this.grid.jqxGrid("addfilter", "id", filtergroup5);
        } else {
          this.grid.jqxGrid("clearfilters");
          this.grid.jqxGrid("addfilter", "orderExId", filtergroup5);
        }
      }
    }

    if (this.orderStateParameter()) {
      var filtergroup42 = new $.jqx.filter();
      var filter42 = filtergroup42.createfilter(
        "stringfilter",
        this.orderStateParameter() + ", " + this.orderStateParameter(),
        filtercondition
      );
      filtergroup42.addfilter(filter_or_operator, filter42);
      this.grid.jqxGrid("clearfilters");
      this.grid.jqxGrid("addfilter", "origin", filtergroup42);
    }

    if (this.xorderStateParameter()) {
      var filtergroup12 = new $.jqx.filter();
      var filter12 = filtergroup12.createfilter(
        "stringfilter",
        this.xorderStateParameter() + ", " + this.xorderStateParameter(),
        filtercondition
      );
      filtergroup12.addfilter(filter_or_operator, filter12);
      this.grid.jqxGrid("clearfilters");
      this.grid.jqxGrid("addfilter", "destination", filtergroup12);
    }
    if (this.incomingParameter()) {
      var filtergroup = new $.jqx.filter();
      var filter = filtergroup.createfilter(
        "stringfilter",
        this.incomingParameter(),
        filtercondition
      );
      filtergroup.addfilter(filter_or_operator, filter);
      this.grid.jqxGrid("addfilter", "origin", filtergroup);
    }
    if (this.outgoingParameter()) {
      var filtergroup2 = new $.jqx.filter();
      var filter2 = filtergroup2.createfilter(
        "stringfilter",
        this.outgoingParameter(),
        filtercondition
      );
      filtergroup2.addfilter(filter_or_operator, filter2);
      this.grid.jqxGrid("addfilter", "destination", filtergroup2);
    }
    if (
      this.loadIdParameter() ||
      this.incomingParameter() ||
      this.outgoingParameter() ||
      this.orderStateParameter() ||
      this.xorderStateParameter()
    ) {
      $("#jqxOrderGrid").jqxGrid("applyfilters");
    }
  };

  exportGridData = () => {
    var jsonData = gridStateUtils.formatGridHeaders("jqxOrderGrid", false);
    dataModel.exportToCSV(jsonData, "Orders", true);
  };

  toolbarActionHandler = (item) => {
    if (item === "Radius Search") {
      this.ordersRadiusSearchModal(this);
    } else if (item === "Export") {
      dataModel.addClientSideLog("Selected: Orders Export.");
      this.exportGridData();
    } else if (item === "Batch Load Posting") {
      let data = {};
      this.loadImportWindow(data);
      // Log User Action
      dataModel.addClientSideLog("Selected: Batch Load Posting");
    } else if (item === "Match My Order") {
      const matchInternalBoard = (id) => {
        this.matchMyOrderMatchId(id);
        // if a tractor radius is already specified, clear it out
        if (
          this.$parent.tractorGridComponent.tractorRadiusLocation().length > 0
        ) {
          this.$parent.tractorGridComponent.tractorRadiusLocation("");
          this.$parent.tractorGridComponent.tractorRadius(10);
        }

        this.$parent.tractorGridComponent.refreshTractorGrid();
        $("#jqxTractorGrid").jqxGrid("showcolumn", "distance");
      };

      const matchExternalBoard = (id, isLoad) => {
        const tabNames = storageManager.get("loadboardTabNames") || [];

        const route = isLoad
          ? `/ExternalBoard/v2?loadId=${id}`
          : `/ExternalBoard/v2?orderId=${id}`;
        const w = window.open(
          route,
          tabNames.length > 1 ? tabNames[tabNames.length - 1] : tabNames[0]
        );
        w.focus();
      };

      var rowindex = this.grid.jqxGrid("getselectedrowindex");

      if (rowindex > -1 == false) {
        showmessage("Please select an order");
        return false;
      }

      const { id, isLoad } = this.grid.jqxGrid("getrowdata", rowindex);

      // Only display option when the user has externalboard credentials
      if (userProfile.externalBoards.length) {
        showconfirm(
          `Do you want an external loadboard search? Selecting 'no' will match by internal trucks only.`
        ).then((doExternalSearch) => {
          // add log
          var msg =
            "Selected: Orders Matching My Order - OrderId: " +
            id +
            (doExternalSearch ? " [ExternalBoard]" : " [InternalBoard]");
          dataModel.addClientSideLog(msg);

          if (doExternalSearch) {
            matchExternalBoard(id, isLoad);
          } else {
            matchInternalBoard(id);
          }
        });
      } else {
        matchInternalBoard(id);
      }
    } else if (item === "Post New Load") {
      this.errors.removeAll();
      var rowBoundIndex = this.grid.jqxGrid("getselectedrowindex");
      if (rowBoundIndex > -1) {
        let rowData = this.grid.jqxGrid("getrowdata", rowBoundIndex);
        if (
          rowData != null &&
          rowData.isLoad == false &&
          rowData.agencyId == userProfile.currentAgencyId()
        ) {
          let data = {
            orderId: rowData.orderId,
            orderStatus: rowData.status,
            customerId: rowData.customerExId,
            trailerType: rowData.trailerType,
            originCityStateZip: rowData.origin,
            pickUpDate: rowData.pickUpDate,
            destCityStateZip: rowData.destination,
            deliveryDate: rowData.deliveryDate,
            brokered: rowData.brokered,
            hazmat: rowData.hazmat,
            loadType: "T",
            lineHaul: rowData.totalCharge, //.grossPay(),
            miles: rowData.miles,
          };
          this.load({ load: data });
        } else {
          this.load({ load: {} });
        }
      } else {
        this.load({ load: {} });
      }
    } else if (item === "Expire All Postings") {
      this.expireLoadsModal(this);
      dataModel.addClientSideLog("Selected: Orders Expire All Postings.");
    } else if (item == "Move Trailer") {
      let rowData = this.grid.jqxGrid(
        "getrowdata",
        this.grid.jqxGrid("getselectedrowindex")
      );
      var model;
      if (
        rowData &&
        (rowData.status == "In Progress" ||
          rowData.status == "Delivered" ||
          rowData.status == "Available") &&
        rowData.brokered == false
      ) {
        model = {
          trailerExId: rowData.trailerExId,
          result: ko.observable(),
        };
      } else {
        model = {
          result: ko.observable(),
        };
      }
      this.moveTrailerModal(model);
      model.result.subscribe((value) => {
        if (value.errors) {
          showmessage(value.errors);
        } else {
          this.grid.jqxGrid("updatebounddata", "data");
        }
      });
    } else if (item === "View Call-in Record(s)") {
      let rowIndex = this.grid.jqxGrid("getselectedrowindex");
      if (rowIndex > -1) {
        let dataRecord = this.grid.jqxGrid("getrowdata", rowIndex);
        if (dataRecord && dataRecord.customerExId != "PROJECT") {
          dataRecord.fromOrderPlanningOrderGrid = true;
          let obj = {
            tractorId: dataRecord.assignedTractorId,
            movementId: dataRecord.id,
            orderExId: dataRecord.orderExId,
            isBrokered: dataRecord.brokered,
            driverCarrierId: dataRecord.assigned,
            fromOrderPlanningOrderGrid: true,
          };
          this.$parent.callInListModal(obj || {});
          dataModel.addClientSideLog("Selected: Orders View Call In Records.");
        } else {
          showmessage("Please select an order");
        }
      } else {
        showmessage("Please select an order");
      }
    }
    //Driver Call-in listing
    else if (item === "Insert Call-in Record") {
      let rowIndex = this.grid.jqxGrid("getselectedrowindex");
      if (rowIndex > -1) {
        let dataRecord = this.grid.jqxGrid("getrowdata", rowIndex);
        if (dataRecord && dataRecord.customerExId != "PROJECT") {
          dataRecord.fromOrderPlanningOrderGrid = true;
          let obj = {
            tractorId: dataRecord.assignedTractorId,
            movementId: dataRecord.id,
            orderExId: dataRecord.orderExId,
            isBrokered: dataRecord.brokered,
            driverCarrierId: dataRecord.assigned,
            fromOrderPlanningOrderGrid: true,
          };
          this.$parent.newCallInModal(obj || {});
          dataModel.addClientSideLog("Selected: Orders Insert Call in Record.");
        } else {
          showmessage("Please select an order");
        }
      } else {
        showmessage("Please select an order");
      }
    } else if (item == "Project Posting") {
      this.projectPostingModal({ onLoadSave: this.onLoadSave });
    } else if (item == "Refresh") {
      this.refreshOrderGrid();
    }
  };

  handleLoadGridFilters = (data) => {
    if (
      (typeof data === "string" && data.length > 0) ||
      isEmpty(data) == false
    ) {
      var state = typeof data === "string" ? JSON.parse(data) : data;
      const merged = { additionalFilters: {}, ...state };
      const { additionalFilters } = merged;

      this.includeOrders(additionalFilters.includeOrders || false);
      this.includeLoads(additionalFilters.includeLoads || false);
      this.expired(additionalFilters.expired || false);
      this.orderGridState = state.grid;

      if (additionalFilters.defaultGridToggleOptions) {
        this.defaultGridToggleOptions({
          ...additionalFilters.defaultGridToggleOptions,
        });
      }
    } else {
      this.includeOrders(true);
      this.includeLoads(false);
      this.expired(false);
    }

    if (this.$parent.isLoadboard == true && this.includeOrders() == false) {
      this.includeLoads(true);
      this.includeOrders(false);
    }

    this.orderGridFiltersLoaded(true);

    if (this._orderInitLoad === false && !this.searchOptionChanged) {
      this.grid.jqxGrid("updatebounddata", "data");
    }
  };

  loadDefaultOptions = () => {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("UserProfile/GetUserSearchFilters", "get", {
          name: "Orderplanning/Loadboard Options",
          pageName: "MyProfile",
        })
        .done((data) => {
          if (isEmpty(data) == false) {
            this.myProfileDefaultOptions = JSON.parse(data);
          }

          resolve();
        });
    });
  };

  clearOrderGridRadiusSearch = () => {
    this.orderRadiusLocation("");
    this.orderRadius(10);
  };

  // toggleOrders = (event, args) => {
  //     var isToggled = $("#divOrderPanel").is(":visible");
  //     var $this = $(args.currentTarget);
  //     if (isToggled) {
  //         $this.find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
  //         this.$parent.tractorGridComponent.grid.jqxGrid({ height: 800 });
  //         this.ordersHidden(true)
  //     }
  //     else {
  //         $this.find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
  //         this.$parent.tractorGridComponent.grid.jqxGrid({ height: 400 });
  //         this.ordersHidden(false)
  //     }
  //     $("#divOrderPanel").animate({ height: 'toggle' }, "slow");
  // };

  // A few features on this page cause multiple refreshes to occur at same time which
  // jqx doesn't like. (i.e. page still loading and user changes the regions ddl option)
  // Here we just capture it, check if already processing data, and try the refresh request again later.
  refreshOrderGrid = (keepSelectedRow = false) => {
    let refreshTimeout;
    let selectedRow;

    const refresh = async (refreshAttempts = 0) => {
      clearTimeout(refreshTimeout);

      if (this.cancelPendingGridRefreshes) {
        this.cancelPendingGridRefreshes = false;
        return false;
      }

      if (refreshAttempts >= 100) {
        // equates to 30 secs with the 300ms timeout.
        console.error(`Exceeded refresh attempts for orders grid. Time-out`);
        return false;
      }

      if (this.gridProcessing) {
        refreshTimeout = setTimeout(() => refresh(refreshAttempts + 1), 300);
        return false;
      }

      this.gridProcessing = true;

      if (!this.myProfileDefaultOptions) {
        await this.loadDefaultOptions();
      }

      this.grid.jqxGrid("updatebounddata", "data");
      this.hideDistanceColumn();
      this.refreshOrderGridSort(selectedRow);

      $("#jqxOrderGrid").on("bindingcomplete", () => {
        this.gridProcessing = false;
      });
    };

    if (keepSelectedRow) {
      const selectedRows =
        $("#jqxOrderGrid").jqxGrid("getselectedrowindexes") || [];
      selectedRow = $("#jqxOrderGrid").jqxGrid("getrowdata", selectedRows[0]);
    }

    refresh(0);
  };
  // B/c the grids on this page are highly customized, need to add re-sorting logic. Grids are beyond using their 'default' automatic features
  // without reapplying our 'customized' stuff.
  refreshOrderGridSort = (selectedRow) => {
    const sortInfo = $("#jqxOrderGrid").jqxGrid("getsortinformation");
    const sortcolumn = sortInfo.sortcolumn;
    const { ascending } = sortInfo.sortdirection || { ascending: false };
    $("#jqxOrderGrid").jqxGrid("clearselection");

    if (sortcolumn) {
      $("#jqxOrderGrid").on("bindingcomplete", () => {
        $("#jqxOrderGrid").jqxGrid(
          "sortby",
          sortcolumn,
          ascending ? "asc" : "desc"
        );

        if (selectedRow) {
          const displayedRows =
            $("#jqxOrderGrid").jqxGrid("getdisplayrows") || [];
          // check if didn't get filtered out -> aka only viewing/filtering 'available', but is now 'covered', etc.
          // so now it's not in grid 'view'
          const row = displayedRows
            .map((x, index) => ({ id: x.id, index }))
            .find((data) => data.id === selectedRow.id);

          if (row) {
            // the boundindex isn't updated or correct in the displayedRows array, so per jqx docs:
            // grab the bound index via the array index...
            const bIndex = $("#jqxOrderGrid").jqxGrid(
              "getrowboundindex",
              row.index
            );
            $("#jqxOrderGrid").jqxGrid("ensurerowvisible", row.index); // array index here...
            $("#jqxOrderGrid").jqxGrid("selectrow", bIndex);
          }
        }

        $("#jqxOrderGrid").off("bindingcomplete");
      });
    }
  };

  hideDistanceColumn = () => {
    let column = this.grid.jqxGrid("getcolumn", "distance");
    if (column.hidden == false) {
      this.grid.jqxGrid("hidecolumn", "distance");
    }
  };

  handleCreateOrderFromLoadExpireLoad = () => {
    this.$parent.isLoading(true);
    dataModel
      .ajaxRequest("FreightBoard/ExpireLoad/" + this.selectedLoadId(), "post")
      .done(() => {
        this.refreshOrderGrid(true);
        this.$parent.isLoading(false);
        router.navigate("NewOrder", true);
      })
      .fail((err) => {
        this.refreshOrderGrid();
        this.$parent.isLoading(false);
      });
  };

  createOrderFromLoad = () => {
    this.createOrderFromLoadShowExpireLoadBtn(false);
    this.lblOrderFromLoadMsg("");

    this.$parent.isLoading(true);
    var data = {
      expireLoad: this.expireLoadOnCreate(),
    };

    dataModel
      .ajaxRequest(
        "FreightBoard/CreateOrderFromLoad/" + this.selectedLoadId(),
        "post",
        data
      )
      .done((data, status, xhr) => {
        this.orderFromLoad.push(data);
        router.navigate("NewOrder/" + data, true);
        this.$parent.isLoading(false);
      })
      .fail((error, msg, d) => {
        if (error.responseJSON != null) {
          if (
            error.responseJSON.message.indexOf("expire the posted load") > -1
          ) {
            this.createOrderFromLoadShowExpireLoadBtn(true);
          }
          this.lblOrderFromLoadMsg(error.responseJSON.message);
        }
        this.$parent.isLoading(false);
      });
  };

  hideLoadIdColumn = () => {
    var column = this.grid.jqxGrid("getcolumn", "id");
    if (column.hidden == false) {
      this.grid.jqxGrid("hidecolumn", "id");
    }
  };
  orderGridRadiusSearch = () => {
    // Log when user selects order radius and the values
    var msg = "Order Radius Search - ";
    // log action
    if (
      this.orderRadiusLocation() !== "" &&
      this.orderRadiusLocation() !== undefined
    ) {
      msg += "Location: " + this.orderRadiusLocation() + ", ";
    }
    msg += "Radius: " + this.orderRadius();
    dataModel.addClientSideLog(msg);
    this.refreshOrderGrid();
    this.grid.jqxGrid("showcolumn", "distance");
  };
  onLoadSave = () => {
    if (
      this.projectPostingModal() &&
      this.projectPostingModal().projectLoad()
    ) {
      this.projectPostingModal().projectLoad(undefined);
    }
    this.refreshOrderGrid();
  };

  handleViewTPIRates = async ({ orderId, loadId }) => {
    if (orderId > 0) {
      this.dispatchAction(isLoading(true));
      const result = await RateService.getSavedRatesForOrder(orderId);
      this.dispatchAction(isLoading(false));

      if (result.errors.length) {
        showmessage(result.errors[0]);
      } else {
        this.tpiViewRatesProps({ orderId, rates: result.rates });
      }
    }

    if (loadId > 0) {
      this.dispatchAction(isLoading(true));
      const result = await RateService.getSavedRatesForLoad(loadId);
      this.dispatchAction(isLoading(false));

      if (result.errors.length) {
        showmessage(result.errors[0]);
      } else {
        this.tpiViewRatesProps({ loadId, rates: result.rates });
      }
    }
  };

  handleGetTPIRates = async (rowData = {}) => {
    const request = TPIRateRequest();
    request.loadId = rowData.loadId > 0 ? rowData.loadId : null;
    request.orderId = rowData.orderId > 0 ? rowData.orderId : null;
    request.pickupDate = rowData.pickUpDate;
    request.trailerType = rowData.trailerExId;
    request.transportationMode = "TL";

    request.requestType = rowData.isLoad ? "LOAD" : "ORDER";

    const originCSZ = mapToCityStateZipObject(
      cleanString(["(", ")", ","], rowData.origin).split(" ")
    );

    request.originCity = originCSZ.city;
    request.originState = originCSZ.state;
    request.originZip = originCSZ.zip;

    const destinationCSZ = mapToCityStateZipObject(
      cleanString(["(", ")", ","], rowData.destination).split(" ")
    );

    request.destinationCity = destinationCSZ.city;
    request.destinationState = destinationCSZ.state;
    request.destinationZip = destinationCSZ.zip;

    request.externalBoardNames = ["DAT", "TRUCKSTOP"];

    this.dispatchAction(isLoading(true));
    const result = await RateService.getTPIRates(request);
    this.dispatchAction(isLoading(false));

    if (result.errors.length && result.rates.length === 0) {
      showmessage(result.errors.map((x) => `${x}<br/>`));
    } else {
      this.tpiViewRatesProps({
        loadId: request.loadId,
        orderId: request.orderId,
        rates: result.rates,
        errors: result.errors,
      });
      this.grid.jqxGrid("updaterow", rowData.rowId, {
        ...rowData,
        hasTPIRate: true,
      });
    }
  };
}

class ClearAssignmentViewModel {
  constructor(orderId, $parent) {
    this.orderId = orderId;
    /**@type {OrderPlanningOrderGrid} */
    this.$parent = $parent || {};
    this.$componentParent = $parent.$parent || {};

    this.$tractorGridComponent =
      this.$componentParent.tractorGridComponent || {};
    this.$orderGridComponent = this.$componentParent.orderGridComponent || {};
  }
  confirmClearAssignment = () => {
    let data = {
      orderId: this.orderId,
      OrderTracking: this.$parent.$parent.orderEntryTracking()?.data,
    };
    data.OrderTracking.viewingOrderId(this.orderId);

    dataModel
      .ajaxRequest("OrderPlanning/ClearAssignment", "post", data)
      .done((result) => {
        //$("#jqxOrderGrid").jqxGrid("updatebounddata", "cells");
        this.$orderGridComponent.refreshOrderGrid(true);
        this.$tractorGridComponent.refreshTractorGrid();

        $("#jqxOrderGrid").notify("Assignment Cleared", {
          position: "top right",
          className: "info",
          z_index: 99999,
        });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        $("#clearAssignmentBody").notify("Update Failed", {
          position: "top right",
          className: "info",
          z_index: 99999,
        });
        if (jqXHR.status == 409) {
          // Conflict
          this.$parent.$parent
            .orderEntryTracking()
            .methods()
            .displayConflictNotifyMessage(jqXHR.responseJSON);
        } else if (jqXHR.responseJSON != null) {
          showmessage(jqXHR.responseJSON.message);
        }
        this.$parent.$parent.isLoading(false);
      });
  };
}

export { OrderPlanningOrderGrid };
export default { viewModel: OrderPlanningOrderGrid, template: template };
