import userProfile from "user-profile";
import router from "router";
import {clearAutoCompleteCache} from '../ko-great-edge-components/ko.ge-auto-complete';
import {noop, GenerateGuid} from 'global-functions';
import broadcastAPI from "../../../utils/broadcastAPI";

export const currentAgencyAutocompleteViewModel = function({
    lockBodyOnOpen = true
} = {}) {
    const vm = this;

    vm.componentId = GenerateGuid(12);
    let $autoCompleteDDL = $('#' + vm.componentId);

    broadcastAPI.listenOn("geCurrentViewingAgency")((agency) => {
        setAgency(agency)
    });
    
    const getUserDefaultAgency = () => {
        return userProfile.userAgencies().find(({id}) => id == userProfile.currentAgencyId || id == userProfile.userAgencyId);
    }

    const resetProps = () => {
        vm.includeInactiveAgencies(false);
        vm.agencyFilter(undefined);
        $('#navbar-menu-collapse').removeClass('in');
        $('body').removeClass('modal-open');
        vm.activeOptionIndex(undefined);

        $('.jqx-loader-modal').remove();
        $('#' + vm.componentId + ' .agencyOptionsList li').blur();
        $('#' + vm.componentId + ' .agencyOptionsList li').removeClass('active');
        $('#' + vm.componentId + ' .current-agency-autocomplete-ddl').removeClass('open');

        $('#' + vm.componentId + ' .agencyOptionsList li').attr('tabindex',0);
    }

    const setAgency = (agency) => {
        const {id} = vm.currentAgency() || {};

        if(agency && agency.id != id) {

            localStorage.setItem("preventLogoutOnAgencyChange", true);

            vm.currentAgency(agency);
            userProfile.currentAgencyId(agency.id);
    
            clearAutoCompleteCache();
            $('.jqx-loader-modal').remove();
            router.currentRoute(router.currentRoute());
            $('.jqx-loader-modal').remove();
        }
    }

    const bindKeyNavigations = () => {
        $('#' + vm.componentId + ' .agencyOptionsList li').attr('tabindex',0)
        
        $('body').on('keydown', (e) => {
            const catchKeys = [13, 38, 40, 27];
            const key = e.which || e.keyCode;

            if($('#' + vm.componentId + ' .current-agency-autocomplete-ddl').hasClass('open') && catchKeys.indexOf(key) > -1) {
                const [ENTER, ARROWUP, ARROWDOWN, ESC] = catchKeys;

                if(key === ESC) {
                    resetProps();
                    return false;
                }

                if(key === ENTER) {
                    if(vm.activeOptionIndex() >= 0 && $('#' + vm.componentId + ' .agencyOptionsList li.dropdown-item').hasClass('active')) {
                        vm.handleAgencySelection(vm.userAgencies()[vm.activeOptionIndex()])
                    }
                    else {
                        resetProps();
                        return false;
                    }
                }

                if($('#' + vm.componentId + ' .agencyOptionsList li.dropdown-item').hasClass('active') == false) {
                    vm.activeOptionIndex(0);
                    $('#agencyOptionsList li:first').focus();
                    return false;
                }

                if(key === ARROWUP) { 
                    e.preventDefault();
                    e.stopPropagation();
                    
                    $('#' + vm.componentId + ' .agencyOptionsList li.active').prev().focus();
                    vm.activeOptionIndex(vm.activeOptionIndex() <= 0 ? 0 : vm.activeOptionIndex() - 1);
                }
                else if(key === ARROWDOWN) {
                    e.preventDefault();
                    e.stopPropagation();

                    $('#' + vm.componentId + ' .agencyOptionsList li.active').next().focus();
                    vm.activeOptionIndex(vm.activeOptionIndex() >= (vm.userAgencies().length - 1) ? (vm.userAgencies().length - 1) : vm.activeOptionIndex() + 1);
                }
            }
        })
    }

    vm.displayCurrentAgencySelectDDL = ko.pureComputed(() => (userProfile.userAgencies() || []).length > 1)
    vm.displayAgencyFilterInput = ko.pureComputed(() => (userProfile.userAgencies() || []).length > 5)
    vm.displayIncludeInactiveBtn = ko.pureComputed(() => (userProfile.userAgencies() || []).some(x => x.isActive == false))
    vm.currentAgency = ko.observable(getUserDefaultAgency());
    vm.includeInactiveAgencies = ko.observable(false);

    vm.agencyFilter = ko.observable();
    vm.agencyFilter.subscribe((val) => { 
        if($('.dropdown-item').hasClass('active')) 
        {
            vm.activeOptionIndex(val ? 0 : undefined)
        }
    })
    vm.userAgencies = ko.pureComputed(() => {
        const isActiveFiltered = (userProfile.userAgencies() || []).filter(({isActive}) => vm.includeInactiveAgencies() ? true : isActive);
        return vm.agencyFilter() ? isActiveFiltered.filter(({externalID}) => externalID.toUpperCase().startsWith(vm.agencyFilter().toUpperCase())) : isActiveFiltered;
    })

    vm.handleAgencySelection = (selectedAgency) => {
        const {id} = vm.currentAgency() || {};

        if(selectedAgency && selectedAgency.id != id) {
            setAgency(selectedAgency);

            broadcastAPI.messageTo("geCurrentViewingAgency", selectedAgency);
        }

        resetProps();
    }

    // check for any changes from outside the component in same 'tab'
    userProfile.currentAgencyId.subscribe((changedId) => {
        const { id } = vm.currentAgency() || {};
    
        if (id > 0 && changedId != id) {
            const agency = (userProfile.userAgencies() || []).find((x) => x.id == changedId);
            vm.currentAgency(agency);
        }
    });


    vm.activeOptionIndex = ko.observable()
    
    const applyBindings = () => {
        $autoCompleteDDL = $('#' + vm.componentId)
        
        $autoCompleteDDL.on('hide.bs.dropdown', resetProps);
        $(document).on('click', '#' + vm.componentId + ' .dropdown-menu :not(.dropdown-item)', (e) => e.stopPropagation());
        $autoCompleteDDL.on('shown.bs.dropdown', () => {

            if(lockBodyOnOpen) {
                $('body').addClass('modal-open'); // using this to prevent scrolling on page while ddl open
            }
            
            
            // reset a few options
            $('#' + vm.componentId + ' .agencyOptionsList').scrollTop(0);
            vm.activeOptionIndex(undefined);
            $('#' + vm.componentId + ' .agencyFilterInput').focus()
        })
        
        bindKeyNavigations();
    }

    setTimeout(() => applyBindings(), 300);
}

import template from './current-agency-autocomplete-component.html';
export default { viewModel: currentAgencyAutocompleteViewModel, template: template }