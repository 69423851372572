import { datetimeUTC } from "global-functions";
import { ITrackingStop, ValueObject } from "./types";

function TnTStopModel(initData: ITrackingStop = {}) {
  return {
    modelId: initData?.modelId ?? initData.externalId,
    actualArrival: initData.actualArrival,
    actualDeparture: initData.actualDeparture,
    address: initData.address,
    checkin: initData.checkin,
    city: initData.city,
    country: initData.country,
    referenceId: initData.referenceId,
    geoFenceRadius: initData.geoFenceRadius ?? 5,
    id: initData.id,
    externalId: initData.externalId,
    isConsignee: initData.isConsignee,
    isShipper: initData.isShipper,
    latitude: initData.latitude,
    longitude: initData.longitude,
    scheduledArrival: initData.scheduledArrival,
    scheduledDeparture: initData.scheduledDeparture,
    sequence: initData.sequence,
    state: initData.state,
    stopComment: initData.stopComment,
    stopType: initData.stopType,
    //carrierTrackingRecordId: initData.carrierTrackingRecordId,
    zipCode: initData.zipCode,
    //moveSequence: initData.moveSequence,
    movementId: initData.movementId,
    movementExId: initData.movementExId,
  };
}

export function getStopValidationErrors({
  scheduledArrival = undefined,
  isConsignee = false,
} = {}) {
  let errors: ValueObject = {};

  let today = datetimeUTC(new Date());
  today = today.hour(0);
  today = today.minute(0);
  today = today.second(0);
  today = today.millisecond(0);

  if (scheduledArrival && isConsignee) {
    const arrival = datetimeUTC(scheduledArrival);
    if (arrival < today) {
      errors.arrivalDate = `Delivery Scheduled Arrival is in the past. Date must be today or later.`;
    }
  }

  errors.count = Object.keys(errors).length;

  return errors;
}

export default TnTStopModel;
