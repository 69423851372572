import {debounceFn, formatPhoneNumber, noop} from "global-functions";
import template from "./driver-contact-list-component.html";
import TNTContext, {
  ITrackTraceMovementState,
} from "../../trackTraceOrderEntryContext";
import * as apiService from "../../services/apiService";
import {useDispatch} from "ko-data-store";
import {isLoading} from "../../../../../../dataStore/actions/appUI";
import { ValueObject } from "../../models";

class DriverContactListViewModel {
  $trackedMove: ko.PureComputed<ITrackTraceMovementState | undefined>;
  validationErrors: ko.PureComputed<ValueObject>;

  driverPhone: ko.PureComputed<any>;

  driverContacts: ko.ObservableArray<{ label: string; value: string }> =
    ko.observableArray([]);
  driver: string;
  localPhoneVal = ko.observable();

  dispatch: (x:any) => Promise<any>;

  constructor() {
    this.dispatch = useDispatch();

    this.$trackedMove = ko.pureComputed(() => {
      return TNTContext.currentMoveTracking();
    })

    this.$trackedMove.subscribe((ctx) => {
      if (this.localPhoneVal() !== ctx?.tracking.driver1Phone) {
        this.loadPhone();
      }
    });

    this.validationErrors = ko.pureComputed(() => {
      const move = this.$trackedMove();

      return move.getValidationErrorObj(
        move.tracking,
        TNTContext.getState().isBrokered
      );
    });

    this.driverPhone = ko.pureComputed({
      read: this.localPhoneVal,
      write: (value:string) => {
        const cleaned = formatPhoneNumber(value);

        this.localPhoneVal({ label: cleaned, value: cleaned });

        TNTContext.upsertTrackedMovement(this.$trackedMove()?.movementExId, {
          tracking: {
            driver1Phone: cleaned,
          },
        });
      }
    });

    this.loadContacts().then(() => {
      setTimeout(() => {
        this.loadPhone();
      }, 300);
    });
  }

  loadPhone = () => {
    const phone = this.$trackedMove()?.tracking.driver1Phone;
    const val = { label: phone, value: phone };
    this.localPhoneVal(val);
  };

  loadContacts = async () => {
    try {
      const driver = this.$trackedMove()?.tracking.driver1Name;

      this.dispatch(isLoading(true))
      const results = (await apiService.fetchDriverContactListAsync(
        driver
      )) as ValueObject[];
      this.dispatch(isLoading(false))

      const phones = results.map((x) => {
        const phone = formatPhoneNumber(x.phone);
        return { label: phone, value: phone };
      });

      this.driverContacts(phones);
    } catch (err) {
       this.dispatch(isLoading(false))
      console.error(err);
      this.driverContacts([]);
    }
  };
}

export default { viewModel: DriverContactListViewModel, template };
