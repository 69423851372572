import router from 'router';
import {scrollToRequiredField } from 'global-functions';
import dataModel from 'data-model';
import userProfile from 'user-profile';

class DriverPortalRegisterPageModel {
    constructor(x) {
        var self = this;

        self.isLoading = ko.observable(false);
        self.errors = ko.observableArray();
        self.response = ko.observable();

        self.loginType = ko.observable().extend({ required: true });

        self.callBackAction = x.callBackAction || function() { };
        self.cancel = x.cancelAction || function() { userProfile.loggedIn() ? router.navigate("/") : window.location = "/"; }

        self.customerName = ko.observable().extend({
            required: {
                message: "Customer name is required",
                onlyIf: function () {
                    if (ko.unwrap(self.loginType) == "customer") {
                        return true;
                    }
                    return false;
                }
            }
        });
        self.payeeCode = ko.observable().extend({
            required: {
                message: "Payee code is required",
                onlyIf: function () {
                    if (ko.unwrap(self.loginType) == "ownerOperator") {
                        return true;
                    }
                    return false;
                }
            }
        });
        self.domicileCode = ko.observable().extend({
            required: {
                message: "Domicile code is required",
                onlyIf: function () {
                    if (ko.unwrap(self.loginType) == "fleetDriver" || ko.unwrap(self.loginType) == "ownerOperator") {
                        return true;
                    }
                    return false;
                }
            }
        });
        self.name = ko.observable().extend({
            required: {
                minLength: 4,
                maxLength: 8,
                message: "Name is required",
                onlyIf: function () {
                    if (ko.unwrap(self.loginType) != "customer" ) {
                        return true;
                    }
                    return false;
                }
            }
        });

        self.driverCode = ko.observable();
        self.address = ko.observable().extend({ required: true });
        self.address2 = ko.observable();
        self.city = ko.observable().extend({ required: true });
        self.state = ko.observable().extend({ required: true });
        self.zip = ko.observable().extend({ required: true });
        self.phone = ko.observable().extend({ minLength: 10, required: true });
        self.fax = ko.observable().extend({ minLength: 10, required: false });
        self.email = ko.observable().extend({ required: true, email: true });
        self.password = ko.observable().extend({minLength: 4, required: true });
        self.comments = ko.observable();

        self.sendRequest = function (item) {

            self.errors.removeAll();
            self.response(undefined);

            var validationErrors = ko.validation.group(self);

            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                scrollToRequiredField();

                return false;
            }

            var data = ko.toJS(self);
            self.isLoading(true);

            dataModel.ajaxRequest("DriverPortal/Register", "post", data)
                .done(function (data, textStatus, jqXHR) {
                    self.isLoading(false);

                  //  self.response(data);

                    var div1 = $(document.createElement("div"));
                    div1.html(data);
                    div1.dialog({
                        title: "Login Request",
                        modal: true,
                        buttons: {
                            "Ok": function () {
                                $(this).dialog("close");
                                self.callBackAction();
                            }
                        },
                        close: function (event, ui) {
                            div1.dialog("destroy");
                        }
                    });


                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.responseJSON != null) {
                        var json = jqXHR.responseJSON;
                        if (json.message != null) {
                            var errors = json.message.split(";");
                            self.errors(errors);
                        }
                    }
                    self.isLoading(false);
                });



        };
    }
}

import template from './driverportal-register-page.html';
export default { viewModel: DriverPortalRegisterPageModel, template: template }