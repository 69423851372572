import dataModel from 'data-model';
import userprofile from 'user-profile';

// Action types
export const RELOAD_VENDOR_LOOKUP = "tnt/lookups/reloadVendorLookup"
export const RELOAD_PROVIDER_LOOKUP = "tnt/lookups/reloadProviderLookup"

function preFetchVendors() {
    let vendors = [];

    const load  = async () => {
        vendors = await dataModel.ajaxRequest("Lookup/TrackingVendors");
    }

    load();

    return () => vendors;
}

let vendorList;
let providerList;
userprofile.loggedIn.subscribe((yes) => {
    if(yes) {
        vendorList = preFetchVendors();
        providerList = preFetchProviders();
    }
})


export const fetchTntVendorsAsync = () => async(dispatch, state) => {
    let {vendors} = state.tntLookups;
  
    try {

        if(!(vendors || []).length) {
            vendors = vendorList();
        }
        
    }
    catch(err) {
        console.error(err);
    }
    
    return {type: RELOAD_VENDOR_LOOKUP, payload: vendors }
}

function preFetchProviders() {
    let providers = [];

    const load  = async () => {
        providers = await dataModel.ajaxRequest("Lookup/TrackingProviders");
    }

    load();

    return () => providers;
}


export const fetchTntProvidersAsync = () => async(dispatch, state) => {
    let {providers} = state.tntLookups;
  
    try {

        if(!(providers || []).length) {
            providers = providerList();
        }
        
    }
    catch(err) {
        console.error(err);
    }
    
    return {type: RELOAD_PROVIDER_LOOKUP, payload: providers }
}

// Updater / reducer
const initState = {
    vendors: [],
    providers: []
}

export default function tntLookups(state = initState, action) {
    switch(action.type) {
        case RELOAD_VENDOR_LOOKUP:
            return {...state, vendors: action.payload }
        case RELOAD_PROVIDER_LOOKUP:
                return {...state, providers: action.payload }
        default:
            return state;
    }
}

// Selectors
export const selectTntLookups = (state) => {
    const {tntLookups} = ko.toJS(state);
    return tntLookups;
}

export const selectTntVendors = (state) => {
    const {vendors} = selectTntLookups(state);
    
    return vendors || [];
}

export const selectTntProviders = (state) => {
    const {providers} = selectTntLookups(state);
    
    return providers || [];
}

