import './roadsideEntryCustomStyles.css';
import dataModel from 'data-model';
import userProfile from 'user-profile';
import {scrollToRequiredField,getQueryStringValueByName} from 'global-functions';
import router from 'router';

var NotesModel = function(note) {
    var self = this;
    self.comment = ko.observable(note.comment).extend({ required: true });
    self.enteredBy = ko.observable(note.userName || userProfile.userName);
    self.createdDate = ko.observable(note.createdDate || new Date());
    self.enteredById = ko.observable(userProfile.userId);
}

var ViolationModel = function(violation) {
    var self = this;
    self.changed = ko.observable(false);
    self.id = ko.observable(violation.id);
    self.violationCode = ko.observable(violation.violationCode).extend({required:true});
    self.description = ko.observable(violation.description).extend({required:true});
    self.basic = ko.observable(violation.basic).extend({required:true});
    self.unitType = ko.observable(violation.unitType).extend({required:true});
    self.oos = ko.observable(violation.oos == null ? false : violation.oos);
    self.citationNumber = ko.observable(violation.citationNumber);
    self.display = ko.observable(violation.display);
    self.validationError = ko.observable();

    self.unitTypeToString = function (unitType) {
        return unitType == "D" ? "Driver" : unitType == "1" ? "Tractor" : unitType == "2" ? "Trailer 1" : unitType == "3" ? "Trailer 2" : unitType;
    }
    self.unitType.subscribe(function (newVal) {
        self.unitTypeString(self.unitTypeToString(newVal));
    });
    self.unitTypeString = ko.observable(self.unitTypeToString(violation.unitType));


    self.violationType = ko.observable();
    self.violationType.subscribe(function (newValue) {
        if (newValue != null) {
            self.description(newValue.description);
            self.basic(newValue.basic);
            self.changed(true);
        }
    });
}

var InspectionModel = function(data) {
    var self = this;

    self.loading = ko.observable(false);

    self.id = ko.observable(data.inspection.id);

    // driver
    self.selectedDriver = ko.observable(data.inspection.driverExternalId).extend({ required: true });
    self.driverName = ko.observable(data.inspection.driverName);
    self.driverState = ko.observable(data.inspection.driverState);
    self.driverLicense = ko.observable(data.inspection.driverLicense);
    self.driverLicenseText = ko.observable(data.inspection.driverLicense);
    self.authorityId = ko.observable(data.inspection.authorityId == undefined ? "GWCHT" : data.inspection.authorityId);

    // tractor
    self.tractorCode = ko.observable(data.inspection.tractorCode).extend({ required: true });
    self.tractorCodeText = ko.observable(data.inspection.tractorCode)
    self.tractorVin = ko.observable(data.inspection.tractorVin);
    self.tractorLicense = ko.observable(data.inspection.tractorLicense);
    self.tractorState = ko.observable(data.inspection.tractorState);
    self.intermodalChassis = ko.observable(data.inspection.intermodalChassis == null ? false : data.inspection.intermodalChassis);
    self.intermodalVisible = ko.observable(!self.intermodalChassis());

    // trailer
    self.trailerCode = ko.observable(data.inspection.trailerCode);
    self.trailerCodeText = ko.observable(data.inspection.trailerCode);
    self.trailerVin = ko.observable(data.inspection.trailerVin);
    self.trailerLicense = ko.observable(data.inspection.trailerLicense);
    self.trailerState = ko.observable(data.inspection.trailerState);

    // supplemental
    self.supplementalCode = ko.observable(data.inspection.supplementalCode);
    self.supplementalCodeText = ko.observable(data.inspection.supplementalCode);
    self.supplementalVin = ko.observable(data.inspection.supplementalVin);
    self.supplementalLicense = ko.observable(data.inspection.supplementalLicense);
    self.supplementalState = ko.observable(data.inspection.supplementalState);

    // inspection data
    self.inspectionNumber = ko.observable(data.inspection.inspectionNumber).extend({ required: true });
    self.inspectionState = ko.observable(data.inspection.inspectionState).extend({ required: true });
    self.inspectionDate = ko.observable(data.inspection.inspectionDate).extend({ required: true });
    self.inspectionStart = ko.observable(data.inspection.inspectionStart).extend({ required: true });
    self.inspectionEnd = ko.observable(data.inspection.inspectionEnd).extend({ required: true });
    self.lockoutOverride = ko.observable(data.inspection.lockoutOverride);
    self.inspectionLevel = ko.observable(data.inspection.inspectionLevel == undefined ? 1 : data.inspection.inspectionLevel);
    self.fsmInterventionType = ko.observable(data.inspection.fsmInterventionType);
    self.cleanInspection = ko.observable(data.inspection.cleanInspection == null ? false : data.inspection.cleanInspection);
    self.originalInspection = ko.observable(data.inspection.originalInspection == null ? false : data.inspection.originalInspection);
    self.fsmIntervention = ko.observable(data.inspection.fsmIntervention == null ? false : data.inspection.fsmIntervention);
    self.proofOfRepair = ko.observable(data.inspection.proofOfRepair == null ? false : data.inspection.proofOfRepair);
    self.logReceived = ko.observable(data.inspection.logReceived == null ? false : data.inspection.logReceived);
    self.crashIndicator = ko.observable(data.inspection.crashIndicator == null ? false : data.inspection.crashIndicator);
    self.hazmat = ko.observable(data.inspection.hazmat == null ? false : data.inspection.hazmat);
    self.dvirReceived = ko.observable(data.inspection.dvirReceived == null ? false : data.inspection.dvirReceived);

    // violations
    self.violations = ko.observableArray(ko.utils.arrayMap(data.violations, function (item) { return new ViolationModel(item, self); }))
    .extend({
        liveEditor: { model: ViolationModel, params: [{}, self] }
    });
    self.violationsTmplToUse = function (item) {
        return self.violations.selectedItem() === item ? "violationEditTmpl" : "violationItemTmpl";
    };

    // notes
    self.notes = ko.observableArray(ko.utils.arrayMap(data.notes, function (item) { return new NotesModel(item); }))
    .extend({
        liveEditor: NotesModel
    });
    self.notesTmplToUse = function (item) {
        return self.notes.selectedItem() === item ? "noteNewTmpl" : "noteItemTmpl";
    };

    self.driverLicense.subscribe(function (newVal) {
        if (newVal != undefined && newVal != "" && !self.loading()) {
            self.loading(true);
            if (self.selectedDriver() == undefined) {
                var data = {
                    licenseNumber: newVal
                }
                dataModel.ajaxRequest("Driver/GetDriverByLicense", "get", data)
                .done(function (response, status, xhr) {
                    if (response != null) {

                        if (response.equipment != null) {
                            if (self.tractorCode() == undefined) {
                                self.tractorCode(response.equipment.tractorCode);
                                self.tractorVin(response.equipment.tractorVIN);
                                self.tractorLicense(response.equipment.tractorTag);
                                self.tractorState(response.equipment.tractorState);
                            }
                            
                            if (self.trailerCode() == undefined) {
                                
                                self.trailerVin(response.equipment.trailerVIN);
                                self.trailerLicense(response.equipment.trailerTag);
                                self.trailerState(response.equipment.trailerState);
                                self.trailerCode(response.equipment.trailerCode);
                            }
                        }
                        if (response.driver != null) {
                            if (self.selectedDriver() == undefined) {
                                self.selectedDriver(response.driver.externalId);
                                self.driverName(response.driver.firstName + " " + response.driver.lastName);
                                self.driverState(response.driver.state);
                            }
                        }
                        self.loading(false);
                    }
                    
                })
                .fail(function (error, msg, d) {
                    console.error(error);
                    self.loading(false);
                });
            }
            
        }
    });

    self.intermodalChassis.subscribe(function (newVal) {
        self.intermodalVisible(!newVal);
        if (newVal == true) {
            self.trailerCode("");
            self.supplementalCode("");
            self.supplementalVin("");
            self.supplementalLicense("");
            self.supplementalState("");
        }
    });

    self.trailerCode.subscribe(function (newVal) {
        if (newVal != undefined && !self.loading() && !self.intermodalChassis() && (self.trailerVin() == undefined || self.trailerVin() == "")) {
            self.loading(true);
            data = { externalId: newVal };
            dataModel.ajaxRequest("Trailer/GetForRoadside/", "GET", data)
                .done(function (response, status, xhr) {
                    self.trailerVin(response.vin);
                    self.trailerLicense(response.tagNumber);
                    self.trailerState(response.tagState);
                    self.loading(false);
                })
                .fail(function (error, msg, d) {
                    console.error(error);
                    self.loading(false);
                });
        }
    });

    self.supplementalCode.subscribe(function (newVal) {
        if (newVal != undefined && !self.loading() && !self.intermodalChassis() && (self.supplementalVin() == undefined || self.supplementalVin() == "")) {
            self.loading(true);
            data = { externalId: newVal };
            dataModel.ajaxRequest("Trailer/GetForRoadside/", "GET", data)
                .done(function (response, status, xhr) {
                    self.supplementalVin(response.vin);
                    self.supplementalLicense(response.tagNumber);
                    self.supplementalState(response.tagState);
                    self.loading(false);
                })
                .fail(function (error, msg, d) {
                    console.error(error);
                    self.loading(false);
                });
            
        }
    });

    self.tractorCode.subscribe(function (newVal) {
        if (newVal != undefined && !self.loading() && (self.tractorVin() == undefined || self.tractorVin() == "")) {
            //var data = { tractorId: newVal };
            self.loading(true);
            data = { externalId: newVal };
            dataModel.ajaxRequest("Tractor/GetForRoadside/", "GET", data)
            .done(function (response, status, xhr) {
                self.tractorVin(response.vin);
                self.tractorLicense(response.tagNumber);
                self.tractorState(response.tagState);
                self.loading(false);
            })
            .fail(function (error, msg, d) {
                console.error(error);
                self.loading(false);
            });
        }
    });

    self.selectedDriver.subscribe(function (newVal) {

        if (newVal == "" || newVal == undefined) { newVal = null }
        if (self.driverName() == null || self.driverName() == "") { self.driverName(undefined) }

        if (newVal != null && !self.loading() && self.driverName() == undefined) {
            self.loading(true);
            var data = {
                driverId: newVal
            };
            dataModel.ajaxRequest("Driver/GetDriverForRoadsideEntry", "get", data)
            .done(function (response, status, xhr) {


                // driver
                self.driverName(response.driver.firstName + " " + response.driver.lastName);
                self.driverState(response.driver.state);
                self.driverLicense(response.driver.licenseNumber);
                

                // tractor
                if (self.tractorCode() == undefined && response.equipment != null) {
                    self.tractorCode(response.equipment.tractorCode)
                    self.tractorVin(response.equipment.tractorVIN);
                    self.tractorLicense(response.equipment.tractorTag);
                    self.tractorState(response.equipment.tractorState);
                }

                // trailer
                if (self.trailerCode() == undefined && response.equipment != null) {
                    self.trailerCode(response.equipment.trailerCode);
                    self.trailerVin(response.equipment.trailerVIN);
                    self.trailerLicense(response.equipment.trailerTag);
                    self.trailerState(response.equipment.trailerState);
                }

                self.loading(false);
            })
            .fail(function (error, msg, d) {
                console.error(error);
                self.loading(false);
            });
        }
    });

    // self.changeDriverLicense = function () {
    //     console.log(self.driverLicense());
    // }

    self.clearFields = function() {
        self.selectedDriver(undefined);
        self.driverName(undefined);
        self.driverLicense(undefined);
        self.driverLiceseText(undefined);
        self.driverState(undefined);
        self.tractorCode(undefined);
        self.tractorVin(undefined);
        self.tractorLicense(undefined);
        self.tractorState(undefined);
        self.intermodalChassis(false);
        self.trailerCode(undefined);
        self.trailerVin(undefined);
        self.trailerLicense(undefined);
        self.trailerState(undefined);
        self.supplementalCode(undefined);
        self.supplementalVin(undefined);
        self.supplementalLicense(undefined);
        self.supplementalState(undefined);
        self.authorityId(undefined);
    };
}

class RoadsideEntryViewModel {
    constructor() {
        var self = this;
        
        self.isLoading = ko.observable(false);
        self.inspection = ko.observable();
        self.errors = ko.observableArray();
        self.displayNotFound = ko.observable(false);

        self.routerInfo = router.currentRoute();

        self.saveInspection = function () {
            self.errors.removeAll();

            var validationErrors = ko.validation.group(self.inspection);

            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();

                scrollToRequiredField();

                self.errors.push("Please check input and try again");

                var tractorValidation = ko.validation.group(self.inspection().tractorCode);
                if (tractorValidation().length > 0) {
                    self.errors.push("Please enter a valid tractor ID");
                }

                var driverValidation = ko.validation.group(self.inspection().selectedDriver);
                if (driverValidation().length > 0) {
                    self.errors.push("Please enter a valid driver ID");
                }

                var inspectionNumberValidation = ko.validation.group(self.inspection().inspectionNumber);
                if (inspectionNumberValidation().length > 0) {
                    self.errors.push("Please enter a valid inspection number");
                }

                var inspectionStateValidation = ko.validation.group(self.inspection().inspectionState);
                if (inspectionStateValidation().length > 0) {
                    self.errors.push("Please enter a valid inspection state");
                }

                var inspectionDateValidation = ko.validation.group(self.inspection().inspectionDate);
                if (inspectionDateValidation().length > 0) {
                    self.errors.push("Please enter a valid inspection date");
                }

                var inspectionStartValidation = ko.validation.group(self.inspection().inspectionStart);
                if (inspectionStartValidation().length > 0) {
                    self.errors.push("Please enter a valid inspection start time");
                }

                var inspectionEndValidation = ko.validation.group(self.inspection().inspectionEnd);
                if (inspectionEndValidation().length > 0) {
                    self.errors.push("Please enter a valid inspection end time");
                }                
            }

            // violation valdiation
            ko.utils.arrayForEach(self.inspection().violations(), function (violation) {
                violation.validationError(undefined);
                if ((violation.basic() == "VEHICLE MAINTENANCE" && self.inspection().inspectionLevel() == 3) || (violation.basic() != "VEHICLE MAINTENANCE" && self.inspection().inspectionLevel() == 5)) {
                    violation.validationError("Violation code does not match inspection type.");
                }
                if (violation.basic() == "HAZMAT" && self.inspection().hazmat() == false) {
                    violation.validationError("Hazmat indicator must be checked in order to add hazmat violation.");
                }
                if (violation.validationError() != undefined) {
                    self.errors.push(violation.validationError());
                }
            });

            if (self.errors().length > 0) {
                return false;
            }
            

            var data = ko.toJS(self.inspection);

            self.isLoading(true);

            if (data.id != null) {

                dataModel.ajaxRequest("Inspection/" + data.id, "put", data)
                    .done(function (data, textStatus, jqXHR) {
                        // self.loadInspection();
                        self.isLoading(false);
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        if (jqXHR.responseJSON != null) {
                            var json = jqXHR.responseJSON;
                            if (json.message != null) {
                                var errors = json.message.split(";");
                                self.errors(errors);
                            }
                        }
                        self.isLoading(false);
                    }
                );

            }
            else {

                dataModel.ajaxRequest("Inspection", "post", data)
                    .done(function (data, textStatus, jqXHR) {
                        var inspection = self.inspection();
                        inspection.id(data);
                        window.history.pushState("object or string", "Title", "/RoadsideEntry?id=" + data);
                        // self.loadInspection();
                        self.isLoading(false);
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        if (jqXHR.responseJSON != null) {
                            var json = jqXHR.responseJSON;
                            if (json.message != null) {
                                var errors = json.message.split(";");
                                self.errors(errors);
                            }
                        }
                        self.isLoading(false);
                    }
                );

            }
        };

        self.cancelEntry = function() {
            router.navigate("/ViolationLookup");
        }

        self.loadInspection = function () {

            var inspectionId = getQueryStringValueByName("id") || self.routerInfo.id;
            console.log(inspectionId);
            if (inspectionId == undefined) {
                var inspection = self.inspection();
                if (inspection != null) {
                    inspectionId = inspection.id();
                }
            }
            if (inspectionId != undefined) {
                self.isLoading(true);
                dataModel.ajaxRequest('Inspection', 'GET', {id: inspectionId}, false)
                  .done(function (data, status, xhr) {
                      self.inspection(new InspectionModel(data));
                      self.isLoading(false);
                      $('title').text($('title').text() + ' ' + inspectionId);
                  })
                 .fail(function (error, msg, d) {
                     self.displayNotFound(true);
                     //self.errors.push("Unable to load inspection");
                     self.isLoading(false);
                 });                
            }
            else {
                var data = {inspection: {id: null}, notes: {}, violations: {}}
                self.inspection(new InspectionModel(data));

            }
        };

        self.loadInspection();
    }
}


import template from './roadside-entry-page.html';
export default { viewModel: RoadsideEntryViewModel, template: template }