/// <reference path="../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/jqwidgets.d.ts" />
import template from './owner-operator-rates-grid-component.html';
import ko, { Observable } from 'knockout';
import gridStateUtils from 'jqx.grid-utils';
import GreatEdgeCustomWidgets from 'ge.custom-widgets';
import GridStateComponentViewModel from 'jqx.grid-state-component';
import { OwnerOperatorRatesPage } from '../owner-operator-rates-page';
import dataModel from 'data-model';
import $ from 'jquery';



class OwnerOperatorRatesGridComponent {

    grid: jqwidgets.jqxGrid;
    GECustomWidgets = new GreatEdgeCustomWidgets();
    #jqxGridHelper = this.GECustomWidgets.GEWidgetsGridHelper('ooRatesGrid');
    $parent: OwnerOperatorRatesPage = null;
    gridState: any;
    includeInactive: Observable<boolean> = ko.observable();

    constructor(data: any) {
        data.parent.ownerOperatorRatesGrid = this;
        this.$parent = data.parent;
        this.getSaveFilters()
        .then((x: string) => {
            if(x) {
                let obj = JSON.parse(x) || {};
                this.gridState = obj.grid;
                let hasInactiveFilter = Object.keys(this.gridState.filters).some((x) => this.gridState.filters[x] == "isActive")
                //if the isActive filter exists at all, then it's going to be true.
                this.includeInactive(hasInactiveFilter);
            }
            this.includeInactive.subscribe(this.isInactiveSubscribeFunction)
            this.buildGrid();
        })

    }

    buildGrid() {
        this.grid = jqwidgets.createInstance("#ooRatesGrid", "jqxGrid", this.gridOptions)
    }

    isInactiveSubscribeFunction = (value) => {
        if(value) {
            let filtergroup = new $.jqx.filter();
            let filter = filtergroup.createfilter("booleanfilter", !value, 'EQUAL')
            filtergroup.addfilter(1, filter)
            this.grid.addfilter("isActive", filtergroup, false);
            this.grid.applyfilters();
        } else {
            this.grid.removefilter('isActive', false)
            this.grid.applyfilters();
        }
    }

    getSaveFilters = () : Promise<string> => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", { name: "ooRatesGrid", pageName: window.location.pathname })
            .done((x) => {
                resolve(x);
            })
        })
    }

    get dataAdapter() {
        return dataModel.getDataAdapter(this.gridSource)
    }

    get gridOptions(): jqwidgets.GridOptions {
        return {
            source: this.dataAdapter,
            columns: this.columns,
            theme: 'GWTMDark',
            width: '100%',
            height: 400,
            altrows: true,
            showfilterrow: true,
            sortable: true,
            filterable: true,
            columnsreorder: true,
            columnsmenu: false,
            columnsresize: true,
            showstatusbar: true,
            statusbarheight: 25,
            pageable: true,
            showtoolbar: true,
            virtualmode: true,
            autoshowfiltericon: true,
            autoheight: true,
            rendergridrows: (obj) => {
                return obj.data;
            },
            rendertoolbar: (toolbar) => {
                let vm1 = new GridStateComponentViewModel.viewModel();
                vm1.clearFilters = () => {
                    this.#jqxGridHelper.clearDateRangeFiltersFromGrid()
                    // Must be applied in this order or jqx grid throws error
                    this.grid.clearfilters()
                    this.grid.updatebounddata('data');
                }
                vm1.initializeDefaultToolbar(toolbar);
                toolbar.append($('#toolBarAddition'))
            },
            ready: () => {
                //Below adds to the UI of the grid on initial load.  Doesn't affect what's actually going to the server.
                if(this.gridState) {
                    gridStateUtils.loadGridStateFilters($('#ooRatesGrid'), this.gridState.filters, false);
                }
                this.isInitLoad = false;
            }
        }
    }

    isInitLoad = true;
    initStateApplied = false;

    get gridSource(): jqwidgets.GridSource {
        return {
            url: 'CustomPaySolutions/GetOwnerOperatorRatesForGrid',
            datatype: 'json',
            data: {
                agencyId: dataModel.currentAgencyId()
            },
            datafields: this.datafields,
            filter: (filters, recordsArray) => {
                if(this.isInitLoad == false) {
                    this.grid.updatebounddata('filter');
                }
            },
            sort: (column, direction) => {
                if(this.isInitLoad == false) {
                    this.grid.updatebounddata('sort');
                }
            },
            processdata: (data) => {
                return data;
            },
            pager: (pagenum, pagesize, oldpagenum) => {
                if(this.isInitLoad == false) {
                    this.grid.updatebounddata('page');
                }
            },
            formatdata: (data) => {
                let filterinfo = this.grid ? this.grid.getfilterinformation() : [];
                data = gridStateUtils.formatGridFilters(filterinfo, data);  //This adds the filters to the data object
                data = this.#jqxGridHelper.addGridFiltersToDataObj(data, { dateRangePostFixes: ['Begin', 'End'] });
                if(data.defaultPayMethodId && typeof(data.defaultPayMethodId) == "string") {
                    data.defaultPayMethodId = this.$parent.defaultPayMethods.filter(x => x.description == data.defaultPayMethodId)[0].id
                }
                if (this.isInitLoad)
                {
                    if(this.initStateApplied == false) {
                        //Adds saved grid parameters to the inital request
                        data = gridStateUtils.loadInitGridState(data, this.gridState, this.columns);
                    }
                    this.initStateApplied = true
                }
                data = this.#jqxGridHelper.addGridFiltersToDataObj(data, { dateRangePostFixes: ['Begin', 'End'] });
                this.isInitLoad = false;
                if(this.includeInactive() == true) {
                    data.isActive = undefined;
                } else {
                    data.isActive = true;
                }
                return data;
            }
        }
    }

    get datafields(): jqwidgets.GridSourceDataFields[] {
        return [
            { name: "payeeId", type: 'number'},
            { name: "payeeExternalID", type: "string" },
            { name: "payeeName", type: "string" },
            { name: "agencyGridDisplay", type: "string" },
            { name: "defaultPayMethodId", type: "number" },
            { name: "loadedPayRate", type: "string" },
            { name: "emptyPayRate", type: "string" },
            { name: "isActive", type: "string" },
            { name: "hireDate", type: "date" },
            { name: "terminationDate", type: "date" },
            { name: "agencyId", type: "string" },
            { name: "agencyExternalId", type: "string" },
            { name: "agencyName", type: "string" }
        ]
    }

    geDateRangeFilterWidget = (column, columnElement, widget) => {
        //set widget (class) level options
        var options = {
            gridOptions: {
                gridWidget: widget,
                gridId: 'ooRatesGrid', // required to apply gridoptions
                columnName: column.datafield, // also required, especially if this is a filter widget
                isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
                //state: this.orderGridState // can send our grid state and apply any initial filters
            }
        }
        return this.GECustomWidgets.GEDateRangePickerWidget(options); // will return widget if already exists or creates a new one;
    };

    columns: jqwidgets.GridColumn[] = [
        { 
            text: '', pinned: true, columntype: "button", width: 100, filterable: false,
            cellsrenderer: () => { return "Edit Rates" }, 
            buttonclick: (row) => {
                let rowdata = this.grid.getrowdata(row);
                this.$parent.ownerOperatorRatesModal(rowdata);
            }
        },
        {
            text: "Payee",
            datafield: "payeeExternalID"
        },
        {
            text: "Payee Name",
            datafield: "payeeName",
            filterable: true,
            sortable: true
        },
        {
            text: "Agency",
            datafield: "agencyGridDisplay"
        },
        {
            text: "Pay Method",
            datafield: "defaultPayMethodId",
            filtertype: "list",
            width: '150px',
            filteritems: (x) => { return this.$parent.defaultPayMethods.map(x => { return { label: x.description, value: x.id }})},
            cellsrenderer: (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
                let $default = $(defaulthtml);
                let payMethod = this.$parent.defaultPayMethods.find((x) => x.id == value);
                $default.html(payMethod.description)
                return $default[0].outerHTML;
            }
        },
        {
            text: "Linehaul Rate",
            datafield: "loadedPayRate",
            width: '100px',
            filterable: false
        },
        {
            text: "Empty Rate",
            datafield: "emptyPayRate",
            width: '100px',
            filterable: false,
            hidden: true
        },
        {
            text: "Status",
            datafield: "isActive",
            filterable: false,
            sortable: true,
            cellsrenderer: (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
                let $default = $(defaulthtml);
                $default.html(value == true ? "Active" : "Inactive")
                return $default[0].outerHTML;
            }
        },
        {
            text: "Hire Date",
            datafield: "hireDate",
            width: '215px',
            filterable: true,
            sortable: true,
            editable: false,
            cellsformat: 'MM/dd/yyyy',
            createfilterwidget: this.geDateRangeFilterWidget
        },
        {
            text: "Termination Date",
            datafield: "terminationDate",
            width: '215px',
            filterable: true,
            sortable: true,
            editable: false,
            cellsformat: 'MM/dd/yyyy',
            createfilterwidget: this.geDateRangeFilterWidget
        }
    ]
}


export default {viewModel: OwnerOperatorRatesGridComponent, template: template}
export { OwnerOperatorRatesGridComponent }