import TNTContext, {
  IStopCommentState,
  ITrackTraceMovementState,
  ITrackTraceOrderEntryContextState,
  MOVEMENTSTATUS_ENUM,
} from "../../trackTraceOrderEntryContext";

class TNTStopCommentComponent {
  private movementExId: string;
  private uid: string;
  comment: ko.PureComputed<string>;
  enableStopComment: ko.PureComputed<boolean>;
  stopCommentTitle: ko.PureComputed<any>;
  $trackingContext = TNTContext;

  constructor(props) {
    const { modelId, movementExId } = ko.toJS(props);
    this.uid = modelId;
    this.movementExId = movementExId;

    this.comment = ko.pureComputed({
      read: this.getStopComment,
      write: this.updateStopComment,
    });

    this.enableStopComment = ko.pureComputed(() => {
      const trackings = TNTContext.selectSlice(
        "trackedMovements"
      ) as ITrackTraceMovementState[];

      const trackedMove = trackings.find(
        (x) => x.movementExId === this.movementExId
      );

      if (!trackedMove) {
        return false;
      }

      return (
        trackedMove.movementStatus === MOVEMENTSTATUS_ENUM.Covered ||
        trackedMove.movementStatus === MOVEMENTSTATUS_ENUM.Available ||
        trackedMove.movementStatus === MOVEMENTSTATUS_ENUM.InProgress
      );
    });

    this.stopCommentTitle = ko.pureComputed(() => {
      if (this.enableStopComment()) {
        return "Stop Comment";
      }

      if (TNTContext.getState().isBrokered) {
        return "Movement for this stop must be covered or in progress to add comment.";
      }

      return "Movement for this stop must be in available or in progress to add comment.";
    });
  }

  private getStopComment = () => {
    const comments = TNTContext.selectSlice(
      "stopComments"
    ) as IStopCommentState[];

    const sc = comments.find((x) => x.uid === this.uid);

    return sc?.comment ?? "";
  };

  private updateStopComment = (comment: string) => {
    const comments = TNTContext.selectSlice(
      "stopComments"
    ) as IStopCommentState[];

    const filtered = comments.filter((x) => x.uid !== this.uid);

    TNTContext.updateSlice({
      sliceKey: "stopComments",
      payload: [...filtered, { comment, uid: this.uid }],
    });
  };
}

import template from "./tnt-stop-comment-component.html";
export default { viewModel: TNTStopCommentComponent, template };
