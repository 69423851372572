import dataModel from 'data-model';

// Action types
//const DISPLAY_RATE_POPUP = "rates/display_rate_popup";
const ADD_LANE_RATE = "rates/add_lane_rate";
const EDIT_LANE_RATE = "rates/edit_lane_rate";
const SET_LANE_RATE_DATA = "rates/set_lane_rate_data";

const fetchLaneRateAsync = (id) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/LaneRate", "GET", {id}).done((data) => resolve(data))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while fethcing data.`));
    })
}

// Original Implementation based on ticket, but was changed to be a 'page'
// so leaving for now, incase...
// export const displayRatesPopup = (display = false) => {
    
//     // Need to lock body scrolling
//     if(display) {
//         $('body').addClass('modal-open');
//     }
//     else {
//         $('body').removeClass('modal-open');
//     }

//     return { type: DISPLAY_RATE_POPUP, payload: display }
// }

export const addLaneRate = (rateNumber, onSaveCallBackFn = function(x){}) => {
    return { type: ADD_LANE_RATE, payload: { data: {rateNumber}, onSaveHandler: onSaveCallBackFn }}
}

export const setLaneRate = (data = undefined) => {
    return { type: SET_LANE_RATE_DATA, payload: { data: data } }
}

export const editLaneRate = (rateData, onSaveCallBackFn = function(x){}) => async(dispatch, state) => {
    return { type: EDIT_LANE_RATE, payload: { data: rateData, onSaveHandler: onSaveCallBackFn } }
}

// Updater / reducer
const initState = {
    //display: false,
    laneRateEntry: {
        data: undefined,
        onSaveHandler: function(x) {}
    }
}

export default function appUI(uiState = initState, action) {
    switch(action.type) {
        // case DISPLAY_RATE_POPUP:
        //     return {...uiState, display: action.payload }
        case ADD_LANE_RATE:
            return {...uiState, laneRateEntry: action.payload }
        case EDIT_LANE_RATE:
            return {...uiState, laneRateEntry: action.payload }
        case SET_LANE_RATE_DATA:
            return {...uiState, laneRateEntry: {...uiState.laneRateEntry, ...action.payload } }
        default:
            return uiState;
    }
}