// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#aZWARimie06jPcnuFDlbIA\\=\\= .XTGjLV5VGFArYpTnUGfAeA\\=\\= {\r\n    padding: 10px;\r\n}\r\n\r\n#aZWARimie06jPcnuFDlbIA\\=\\= ._3txixVH9lqaBro-6wLOJsQ\\=\\= {\r\n    text-align: left;\r\n}\r\n\r\n#aZWARimie06jPcnuFDlbIA\\=\\= .Q08jabCInUkqDTsFi6QeNA\\=\\= {\r\n    padding: 0 !important;\r\n    margin: 0 !important;\r\n}\r\n\r\n#aZWARimie06jPcnuFDlbIA\\=\\= .YLEls4AV-U4Au-aALDbwKg\\=\\= {\r\n    padding-top: 0 !important;\r\n}\r\n\r\n#aZWARimie06jPcnuFDlbIA\\=\\= ._8cD4TgFOGat-BwVmxMp-aw\\=\\=:hover, #aZWARimie06jPcnuFDlbIA\\=\\= ._8cD4TgFOGat-BwVmxMp-aw\\=\\=:active {\r\n    opacity: .7;\r\n}", "",{"version":3,"sources":["webpack://./src/home/pages/settlements-pages/settlementsStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf","sourcesContent":["#settlements-page .col-sm-3 {\r\n    padding: 10px;\r\n}\r\n\r\n#settlements-page .form-control-custom {\r\n    text-align: left;\r\n}\r\n\r\n#settlements-page .nopadding {\r\n    padding: 0 !important;\r\n    margin: 0 !important;\r\n}\r\n\r\n#settlements-page .form-control-static {\r\n    padding-top: 0 !important;\r\n}\r\n\r\n#settlements-page .reportList:hover, #settlements-page .reportList:active {\r\n    opacity: .7;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settlements-page": "aZWARimie06jPcnuFDlbIA==",
	"col-sm-3": "XTGjLV5VGFArYpTnUGfAeA==",
	"form-control-custom": "_3txixVH9lqaBro-6wLOJsQ==",
	"nopadding": "Q08jabCInUkqDTsFi6QeNA==",
	"form-control-static": "YLEls4AV-U4Au-aALDbwKg==",
	"reportList": "_8cD4TgFOGat-BwVmxMp-aw=="
};
export default ___CSS_LOADER_EXPORT___;
