import dataModel from "data-model";
import userProfile from "user-profile";
import {
    setBtnDisabledState,
    setBtnEnabledState,
    isEmpty,
} from "global-functions";

class ChangePasswordViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};
        this.isProcessing = ko.observable(false);

        this.users = ko.observableArray();
        this.newPassword = ko.observable();
        this.passwordChangeVisible = ko.observable(false);
        this.selectedUser = ko
            .observable()
            .extend({ required: { message: "User is required" } });

        this.selectedUser.subscribe((newVal) => {
            if (isEmpty(newVal) == false) {
                this.doWhenSelectedUserChangedResetForm();
            } else {
                this.doWhenSelectedUserIsEmpty();
            }
        });
        this.isReadOnly = ko.observable(false);
        this.password = ko.validatedObservable().extend({
            required: {
                message: "Password is required",
            },
            minLength: 6,
        });
        this.validPassword = ko.observable(false);
        this.password.subscribe((newVal) => {
            this.message("");
            if (!this.password.isValid()) {
                this.confirmPassword(undefined);
                this.validPassword(false);
            } else {
                this.validPassword(true);
            }
        });

        this.message = ko.observable("");
        this.confirmPassword = ko.validatedObservable().extend({
            equal: {
                message: " ",
                params: this.password,
            },
        });
        this.confirmPassword.subscribe((newVal) => {
            this.message("");
        });

        this.changePasswordVisible = ko.pureComputed(() => {
            return this.confirmPassword.isValid() && this.password.isValid();
        });

        this.isFormValid = ko.computed(() => {
            this.errors = ko.validation.group([
                this.confirmPassword,
                this.password,
                this.selectedUser,
            ]);
            if (this.errors().length > 0) {
                this.errors.showAllMessages();
            }

            return this.errors().length == 0;
        });
    }

    doWhenSelectedUserChangedResetForm = () => {
        this.message(undefined);
        this.password(null);
        this.password.isModified(false);
        this.confirmPassword(null);
        this.confirmPassword.isModified(false);
        this.passwordChangeVisible(true);
    };

    doWhenSelectedUserIsEmpty = () => {
        this.selectedUser(null);
        this.selectedUser.isModified(false);
        this.password(null);
        this.password.isModified(false);
        this.confirmPassword(null);
        this.confirmPassword.isModified(false);
        this.passwordChangeVisible(false);
    };

    clearMsg = () => {
        this.message("");
    };

    handleChangePassword = (data, event) => {
        this.doHandleChangePassword(data, event);
    };

    async doHandleChangePassword(data, event) {
        const vm = this;

        if (vm.isFormValid() == false) return false;

        data = {
            userId: this.selectedUser(),
            password: this.password(),
            confirmPassword: this.confirmPassword(),
        };

        setBtnDisabledState(event, "Saving...");
        const result = await vm
            .http_sendSavePassword(data)
            .catch((errMsg) => vm.message(errMsg));

        setBtnEnabledState(event, "Save");
    }

    http_sendSavePassword = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/ChangePassword", "put", payload)
                .done((response) => resolve(response))
                .fail((err) => {
                    console.error(err);

                    reject(
                        (err.responseJSON && err.responseJSON.message) ||
                            "Error occurred while saving."
                    );
                });
        });
    };
}

import template from "./change-password-component.html";
export default { viewModel: ChangePasswordViewModel, template: template };
