import {GenerateGuid, mapGridDate, noop} from 'global-functions';
import {buildGrid} from '../../../../../gridtoolbox'
import dataModel from 'data-model';
import userprofile from 'user-profile';
import {useDispatch, useSelector} from 'ko-data-store';

const getColumns = ({
    $grid = undefined,
}) => {

    return [
        { text: 'Partner', datafield: 'partnerId', minwidth: 100, width: 120 },
        { text: 'Stop #', datafield: 'stopNumber', minwidth: 50, width: 60 },
        { text: 'Status', datafield: 'statusCode', minwidth: 50, width: 50 },
        { text: 'Description', datafield: 'description', minwidth: 150 },
        { text: 'Error', datafield: 'error', minwidth: 80, width: 100 },
        { text: 'Status Date', datafield: 'statusDate', minwidth: 120, width: 150, cellsformat: "MM/dd/yyyy HH:mm" },
        { text: 'City', datafield: 'city', minwidth: 150, width: 150 },
        { text: 'State', datafield: 'state', minwidth: 50, width: 50 },
        { text: 'Updated By', datafield: 'username', minwidth: 150, width: 150  },
        { text: 'Last Updated', datafield: 'updatedAt', minwidth: 120, width: 150, cellsformat: "MM/dd/yyyy HH:mm"  },
        { text: 'Delay', datafield: 'delayCode', minwidth: 100, width: 130,
            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties) => {
                const rowdata = $grid.jqxGrid('getrowdata', row) || {};
                const $defaulthtml = $(defaulthtml);
                
                if(value) {
                    const tooltip = rowdata.delayCodeDescription || "";
                    const cellTemplate = `<div style="padding: 8px;" title="${tooltip}">
                        <span>${value}</span>
                    </div>`

                    return cellTemplate;
                }

                return $defaulthtml[0].outerHTML;

            }   
        },
        { text: '', datafield: 'delayCodeDescription', hidden: true, pinned: true }, // not shown, but used as a 'tooltip' when hovering over delay code.
        
    ]
}

const getSource = ({
    stopExternalId = undefined
} = {}) => {
    const source = {
        url: "Order/GetEDIDelayStatusListForStop",
        datatype: "json",
        formatdata: () => {
            
            return {
                externalId: stopExternalId,
                agencyId: userprofile.currentAgencyId()
            }
        },
        beforeprocessing: (records = []) => {
            return records.map(record => 
                {
                    return {
                        ...record,
                        delayCode: !record.delayCode || record.delayCode === '' ? null : record.delayCode,
                        creatDate: record.createDate && mapGridDate(record.createDate),
                        statusDate: record.statusDate && mapGridDate(record.statusDate),
                        updatedAt: record.updatedAt && mapGridDate(record.updatedAt)
                    }
                }
            )
        },
        datafields: [
            { name: 'recordId', type: 'number' },
            { name: 'isShipper', type: 'bool' },
            { name: 'isConsignee', type: 'bool' },
            { name: 'partnerId', type: 'string' },
            { name: 'stopNumber', type: 'string' },
            { name: 'statusCode', type: 'string' },
            { name: 'description', type: 'string' },
            { name: 'error', type: 'string' },
            { name: 'statusDate', type: 'date' },
            { name: 'createDate', type: 'date' },
            { name: 'city', type: 'string' },
            { name: 'state', type: 'string' },
            { name: 'delayCode', type: 'string' },
            { name: 'delayCodeDescription', type: 'string' },
            { name: 'username', type: 'string' },
            { name: 'updatedAt', type: 'date' },
        ],
    }

    const adapter = dataModel.getDataAdapter(source);
    return adapter;
}

function OrderEntryStopsEdiDelayViewModel({
    partnerId = undefined, // edi assignments partner id
    stopExternalId = undefined // 
} = {}) {
    const vm = this;
    const dispatch = useDispatch();

    stopExternalId = ko.toJS(stopExternalId);
    partnerId = ko.toJS(partnerId);
    
    vm.ediDelayGridId = `${GenerateGuid(5) + Date.now()}`;
    let $gridEl;

    const init = async () => {
        const {$grid} = buildGrid({$grid: $('#' + vm.ediDelayGridId), 
        options: {
            filterable: false,
            sortable: true,
            width: '100%',
            height: 200,
            source: getSource({stopExternalId}),
            columns: getColumns({$grid: $('#' + vm.ediDelayGridId)}),
            altrows: true,
            pagesize: 5,
            columnsresize: true,
            columnsreorder: false,
            enablebrowserselection: true,
            columnsmenu: false,
            showfilterrow: false,
            showtoolbar: false,
            virtualmode: false,
            selectionmode: 'singlecell',
            rendergridrows: function (obj) {
                return obj.data;
            },
        }})([]); // no hooks, so pass empty array to get the returned $grid element. <- (Curry function) gridToolBox uses them and makes chaining in a pipe function, setup, etc easier...

        $gridEl = $grid;    
        
        $gridEl.on("bindingcomplete", () => {
            const count = ($gridEl.jqxGrid('getrows') || []).length;

            $gridEl.jqxGrid({'autoheight': count <= 5});
        })
    }

    setTimeout(() => init(), 50);
}

import template from './order-entry-stops-edi-delay-component.html';
export default { viewModel: OrderEntryStopsEdiDelayViewModel, template: template };