

import dataModel from 'data-model';

class AuditLogViewModel {
    constructor() {
        var self = this;
        self.logMessage = ko.observable("No records to display.");

        self.isLoading = ko.observable(false);
        self.isLoading(false);

        self.auditType = ko.observable('ORDER_HISTORY');
        // self.auditType = ko.observable('CARD_HISTORY')

        self.orders = ko.observable();
        self.selectedOrder = ko.observable();
        

        self.creditAudits = ko.observable();
        self.selectedCreditAudit = ko.observable();

        self.results = ko.observableArray();
        self.resultsCard = ko.observableArray();
        self.showingResults = ko.observable(false);

        self.changeAutocomplete = function () {
            self.showingResults(false);
            self.orders(null);
            self.creditAudits(null);
        }

        self.searchOrderHistory = function() {
            if(!self.selectedOrder()) {
               self.showingResults(false) ;
               self.logMessage("No results found.");
               self.isLoading(false);
            } else {
                var data = self.selectedOrder().code;
                var route = "Order/AuditLog/" + data.replace(/^[ ]+|[ ]+$/g, '');
                dataModel.ajaxRequest(route)
                    .done(function (data, status, xhr) {
                        if (data.length > 0) {
                            self.results(data);
                            self.showingResults(true);
                            self.logMessage("");
                        } else {
                            self.showingResults(false);
                            self.logMessage("No results found.");
                        }
                        self.isLoading(false);
                    })
                .fail(function (error, msg, d) {
                    self.logMessage("Something went wrong.");
                    self.isLoading(false);
                });
            }
        }

        self.searchCardHistory = function () {
            
            (!self.selectedCreditAudit && self.orders.length == 1)
            if (!self.selectedCreditAudit()) {
                self.isLoading(false);
                self.showingResults(false);
                self.logMessage("No results found.");
            } else {
                var data = self.selectedCreditAudit().id;
                var route = "ComdataCard/GetCardAuditHistory/" + data.replace(/^[ ]+|[ ]+$/g,'');
                dataModel.ajaxRequest(route, "GET", data)
                    .done(function (data, status, xhr) {
                        if (data.length > 0) {
                            self.resultsCard(data);
                            self.showingResults(true);
                            self.logMessage("");
                        } else {
                            self.showingResults(false);
                            self.logMessage("No results found.");
                        }
                        self.isLoading(false);
                    })
                    .fail(function (error, msg, d) {
                        self.logMessage("Something went wrong.");
                        self.isLoading(false);
                    }
                );
            }
        }

        self.search = function () {
            self.isLoading(true);
            if (self.auditType() == 'ORDER_HISTORY') {
                self.searchOrderHistory();
            } else if(self.auditType() == 'CARD_HISTORY') {
                self.searchCardHistory();
            }    
            
        }
    }
}

import template from './auditlog-page.html';
export default { viewModel: AuditLogViewModel, template: template }