import dataModel from "data-model";
import userProfile from "user-profile";
import {
    replaceBrTags,
    setBtnDisabledState,
    setBtnEnabledState,
    isEmpty,
} from "global-functions";

class CarrierRelationshipViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};
        this.isProcessing = ko.observable(false);
        this.CarrierRelationshipGrid = $("#jqxCarrierRelationshipGrid");
        this.displayForm = ko.observable(false);
        this.displayConfirmDelete = ko.observable(false);
        this.applyTocarrier = ko.observable(false);
        this.modelError = ko.observable();
        this.CarrierRelationshipStatusMessage = ko.observable();
        this.carrierSelectRelationship = ko.observable("");
        this.CarrierRelationships = ko.observableArray();
        this.Id = ko.observable();
        this.cruserSelectInput=ko.observable();
        this.carrierSelectedValue = ko.observable();
        this.carrierSelectInput = ko.observable();
        this.crUser = ko.observable(); 
        this.crCarrier = ko.observable(); 

        this.userSelectedValue = ko.observable();

        this.carrierSelectedValue.subscribe((val) => {
            const carrier = val || { id: null };
            this.carrierSelectRelationship("");

        });


        this.initializeComponent();
    }


    initializeComponent = () => {
        this.loadGrid();
    };

    handleLoadcarrierRelationship = () => {
        const crn = this.selectedcarrierCarrierRelationshipRelationship;
        this.Id(crn.Id);
    };

    handleResetcarrierFields = () => {
        this.Id(null);
        this.carrierSelectInput(undefined);
        this.carrierSelectedValue(undefined);
    };

    handleSaveRelationship = (x, event) => {
        this.doHandleSaveRelationship(x, event);
    };

    async doHandleSaveRelationship(x, event) {
        const vm = this;
        if (vm.validateSubmission() == false) return false;
        const data = {
            carrierId: vm.carrierSelectedValue().id,
            userId :  vm.userSelectedValue().id,
            relationshipAgencyId :  userProfile.currentAgencyId(),
        };
        vm.isProcessing(true);
        setBtnDisabledState(event, "Saving...");
        await vm
            .http_sendSaveRelationship(data)
            .catch((errMsg) => vm.modelError(errMsg));
        vm.isProcessing(false);
        setBtnEnabledState(event, "Save");
        if (!vm.modelError())
            vm.doWhenSaveSuccess("Carrier Relationship Saved Successfully.");
    }

    doWhenSaveSuccess = (msg) => {
        this.refreshGrid();
        this.CarrierRelationshipStatusMessage(msg);
        this.displayForm(false);
        setTimeout(() => {
            this.CarrierRelationshipStatusMessage("");
        }, 5000);
    };

    validateSubmission = () => {
        this.modelError("");
        const errors = ko.validation.group(this),
            isValid = errors().length == 0;
        if (isValid == false) errors.showAllMessages();

        return isValid;
    };

    handleOpenForm = (Relationship) => {
        Relationship = Relationship || this.agencyCarrierRelationship || {};
        this.modelError("");
        this.carrierSelectRelationship("");
        this.Id(Relationship.Id);
        this.applyTocarrier(Relationship.carrierId > 0);
        this.displayForm(true);
        dataModel.addClientSideLog("Selected: Add New Carrier Relationship");
    };

    handleDeleteRelationship = (carrierRelationshipId) => {
        this.doHandleDeleteRelationship(carrierRelationshipId);
    };

    async doHandleDeleteRelationship(carrierRelationshipId) {
        const vm = this;
        vm.displayConfirmDelete(false);
        const success =
            (await vm
                .http_sendDeleteRelationship(carrierRelationshipId)
                .catch((err) => console.error(err))) || false;
        if (success)
            vm.doWhenSaveSuccess("Carrier Relationship Deleted Successfully.");
            this.refreshGrid();
    }

    placeHolderFilterWidget = (column, columnElement, widget) => {
        widget.jqxInput({
            placeHolder: "Type Here...",
        });
    };

    get gridSource() {
        return {
            url: "MyAgency/GetCarrierRelationships",
            datatype: "json",
            datafields: [
                { name: "carrierId", type: "number" },
                { name: "carrierExId", type: "string" },
                { name: "userId", type: "number" },
                { name: "username", type: "string" },
                { name: "carrierRelationshipId", type: "number" },
            ],
            formatdata: (data) => {
                data = { agencyId: userProfile.currentAgencyId() };
                return data;
            },
            loadcomplete: (data) => {
                console.log(data);
            },
        };
    }

    refreshGrid = () => {
        this.CarrierRelationshipGrid.jqxGrid("updatebounddata", "data");
    };

    get agencyCarrierRelationship() {
        const vm = this,
            Relationships = vm.CarrierRelationshipGrid.jqxGrid("getboundrows") || [];

        return Relationships.find((Relationship) => Relationship.carrierId == null) || {};
    }

    get selectedcarrierCarrierRelationshipRelationship() {
        const vm = this,
            Relationships = vm.CarrierRelationshipGrid.jqxGrid("getboundrows") || [];

        return (
            (vm.carrierSelectedValue() &&
                Relationships.find(
                    (Relationship) => Relationship.carrierId == vm.carrierSelectedValue().id
                )) ||
            {}
        );
    }

    loadGrid = () => {
        const vm = this;
        if (this.CarrierRelationshipGrid.length == 0) this.CarrierRelationshipGrid = $("#jqxCarrierRelationshipGrid");

        var dataAdapter = dataModel.getDataAdapter(this.gridSource);

        const gridColumns = [
            {
                text: "",
                width: 100,
                columntype: "button",
                cellsrenderer() {
                    return "Delete";
                },
                buttonclick(row) {
                    const data = vm.CarrierRelationshipGrid.jqxGrid("getrowdata", row);
                    vm.handleDeleteRelationship(data.carrierRelationshipId);
                },
                filterable: false,
                sortable: false,
                pinned: true,
            },
            { datafield: "carrierId", hidden: true },
            { datafield: "carrierRelationshipId", hidden: true },
            { datafield: "userId", hidden: true },
            {
                text: "Carrier",
                datafield: "carrierExId",
                width: 100,
                createfilterwidget: this.placeHolderFilterWidget,
               
            },
            {
                text: "User",
                datafield: "username",
                createfilterwidget: this.placeHolderFilterWidget,
                width: 100,
            },
           
        ];
        this.CarrierRelationshipGrid.jqxGrid({
            theme: "GWTMDark",
            width: "100%",
            autoheight: false,
            source: dataAdapter,
            columns: gridColumns,
            showheader: true,
            showtoolbar: true,
            toolbarheight: "50px",
            pageable: true,
            sortable: true,
            filterable: true,
            altrows: true,
            showfilterrow: true,
            columnsmenu: false,
            enablebrowserselection: true,
            pagesize: 20,
            virtualmode: false,
            rendertoolbar: (toolbar) => {
                const rateToolbar = $("#CarrierRelationshipRelationshipToolbar");
                toolbar.append(rateToolbar);
            },
        });
    };

    http_sendSaveRelationship = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/SaveCarrierRelationship",
                    "POST",
                    payload
                )
                .done((response) => resolve(response))
                .fail((err) => {
                    console.error(err);
                    reject(
                        (err.responseJSON && err.responseJSON.message) ||
                        "Error occurred while saving."
                    );
                });
        });
    };

    http_sendDeleteRelationship = (Id) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/DeleteCarrierRelationship/" + Id,
                    "DELETE"
                )
                .done((response) => resolve(true))
                .fail((err) => reject(err));
        });
    };

  
}

import template from "./carrier-relationship-component.html";
export default { viewModel: CarrierRelationshipViewModel, template: template };
