﻿/* eslint-disable no-useless-escape */
import dayjs from "dayjs";
import _ from "lodash";

function copyLinkToClipboard(aElementId) {
  if (!aElementId) return false;

  aElementId = aElementId.indexOf("#") != -1 ? aElementId : "#" + aElementId;
  var hrefLinkToCopy = $(aElementId).attr("href");

  copyToClipboard(hrefLinkToCopy);

  return false; // prevent going to link
}

function copyToClipboard(stringToCopy) {
  if (!stringToCopy) return false;

  var $temp = $("<input>");
  $("body").append($temp);
  $temp.val(stringToCopy).select();
  document.execCommand("copy");
  $temp.remove();
}

function getUniqueArray(array) {
  return array.filter(
    (elem, index) => array.findIndex((obj) => obj.value == elem.value) === index
  );
}

function cancelBackspace() {
  if (typeof window.event != "undefined") {
    document.onkeydown = function () {
      if (
        event.keyCode == 8 ||
        (event.keyCode == 37 && event.altKey) ||
        (event.keyCode == 39 && event.altKey)
      ) {
        var doPrevent = false;
        var d = event.srcElement || event.target;
        var rx = /TEXT|PASSWORD|FILE|SEARCH|EMAIL|NUMBER|DATE|DATETIME/i;
        if (
          (d.tagName.toUpperCase() === "INPUT" &&
            rx.test(d.type.toUpperCase())) ||
          d.tagName.toUpperCase() === "TEXTAREA"
        ) {
          doPrevent = d.readOnly || d.disabled;
        } else {
          doPrevent = true;
        }
        if (doPrevent) {
          event.preventDefault();
        }
      }
    };
  }
}
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
function escapeHtml(text) {
  if (text != null) {
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }
  return text;
}

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0].toLowerCase()) == variable.toLowerCase()) {
      return decodeURIComponent(pair[1]);
    }
  }
  return "";
}
function scrollToRequiredField() {
  var firstRequiredElement = $(".validationElement:visible").first();
  if (firstRequiredElement.length > 0) {
    var scrollTop = function (el, callback) {
      $("html, body").animate(
        { scrollTop: el.offset().top - 100 },
        "slow",
        callback
      );
    };
    scrollTop(firstRequiredElement, function () {
      if (
        $.inArray(firstRequiredElement.attr("type"), [
          "number",
          "text",
          "date",
        ]) > -1
      ) {
        firstRequiredElement.focus();
      } else {
        firstRequiredElement.find("input[type=text]").focus();
      }
    });
  }
}

function createDateTimeFilterWidget(column, columnElement, widget) {
  widget.jqxDateTimeInput({
    readonly: false,
    formatString: "MM/dd/yyyy",
  });
  $(widget).find("input").attr("placeHolder", "MM/DD/YYYY");
}

function isEmpty(value) {
  return (
    (typeof value == "string" && !value.trim()) ||
    typeof value == "undefined" ||
    value === null ||
    (typeof value == "object" && Object.keys(value).length === 0)
  );
}

function loaderIcon(element, visible) {
  if (visible) {
    element.prepend(
      '<div class="jqx-loader-icon jqx-loader-icon-GreatWide" style="background-position-y: top;"></div>'
    );
  } else {
    element
      .children(".jqx-loader-icon, .jqx-loader-icon-GreatWide")
      .first()
      .remove();
  }
}

function getDateTimestamp() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return mm + dd + yyyy;
}

function GenerateGuid(len) {
  var buf = [],
    chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
    charlen = chars.length,
    length = len || 32;

  for (var i = 0; i < length; i++) {
    buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
  }

  return buf.join("");
}

function EDIAlertOfDeadline() {
  var newTender = document.getElementById("lblNewTenderCount"),
    backColorNormal = "#dde7f5",
    backColorRed = "#e70f0f",
    flip = false;
  setInterval(function () {
    newTender.style.backgroundColor = flip ? backColorNormal : backColorRed;
    newTender.style.color = flip ? "#000" : "#FFF";
    flip = !flip;
  }, 500);
}

function get_browser_info() {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE ", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
}
// Returns a boolean -> true if the user's browser is from a mobile device
function mobilecheck() {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

// Returns a boolean if the user's browser is from a mobile device or tablet
function mobileAndTabletcheck() {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

var Base64 = {
  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
  encode: function (e) {
    var t = "";
    var n, r, i, s, o, u, a;
    var f = 0;
    e = Base64._utf8_encode(e);
    while (f < e.length) {
      n = e.charCodeAt(f++);
      r = e.charCodeAt(f++);
      i = e.charCodeAt(f++);
      s = n >> 2;
      o = ((n & 3) << 4) | (r >> 4);
      u = ((r & 15) << 2) | (i >> 6);
      a = i & 63;
      if (isNaN(r)) {
        u = a = 64;
      } else if (isNaN(i)) {
        a = 64;
      }
      t =
        t +
        this._keyStr.charAt(s) +
        this._keyStr.charAt(o) +
        this._keyStr.charAt(u) +
        this._keyStr.charAt(a);
    }
    return t;
  },
  decode: function (e) {
    var t = "";
    var n, r, i;
    var s, o, u, a;
    var f = 0;
    e = e.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    while (f < e.length) {
      s = this._keyStr.indexOf(e.charAt(f++));
      o = this._keyStr.indexOf(e.charAt(f++));
      u = this._keyStr.indexOf(e.charAt(f++));
      a = this._keyStr.indexOf(e.charAt(f++));
      n = (s << 2) | (o >> 4);
      r = ((o & 15) << 4) | (u >> 2);
      i = ((u & 3) << 6) | a;
      t = t + String.fromCharCode(n);
      if (u != 64) {
        t = t + String.fromCharCode(r);
      }
      if (a != 64) {
        t = t + String.fromCharCode(i);
      }
    }
    t = Base64._utf8_decode(t);
    return t;
  },
  _utf8_encode: function (e) {
    e = e.replace(/\r\n/g, "\n");
    var t = "";
    for (var n = 0; n < e.length; n++) {
      var r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
      } else if (r > 127 && r < 2048) {
        t += String.fromCharCode((r >> 6) | 192);
        t += String.fromCharCode((r & 63) | 128);
      } else {
        t += String.fromCharCode((r >> 12) | 224);
        t += String.fromCharCode(((r >> 6) & 63) | 128);
        t += String.fromCharCode((r & 63) | 128);
      }
    }
    return t;
  },
  _utf8_decode: function (e) {
    var t = "";
    var n = 0;
    var c1;
    var c2;
    var c3;
    var r = (c1 = c2 = 0);
    while (n < e.length) {
      r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
        n++;
      } else if (r > 191 && r < 224) {
        c2 = e.charCodeAt(n + 1);
        t += String.fromCharCode(((r & 31) << 6) | (c2 & 63));
        n += 2;
      } else {
        c2 = e.charCodeAt(n + 1);
        c3 = e.charCodeAt(n + 2);
        t += String.fromCharCode(
          ((r & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        n += 3;
      }
    }
    return t;
  },
};

//Below 2 methods and the percentage bindingHandler looted from https://www.moonlightbytes.com/blog/useful-knockout-js-binding-handlers
function formatPercentage(value, precision) {
  return (value < 0 ? "-" : "") + Math.abs(value).toFixed(precision) + "%";
}

function rawNumber(val) {
  return Number(val.replace(/[^\d\.\-]/g, ""));
}

//https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport
function isElementInViewport(el) {
  // Special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}

//https://stackoverflow.com/questions/23891892/how-do-i-get-the-actual-values-for-left-right-top-bottom-of-an-absolutely-positi
function getRelativeClientRect(el) {
  var rect = el.getBoundingClientRect(),
    parentRect = el.offsetParent.getBoundingClientRect();

  return {
    bottom: parentRect.bottom - rect.bottom,
    height: rect.height,
    left: rect.left - parentRect.left,
    right: parentRect.right - rect.right,
    top: rect.top - parentRect.top,
    width: rect.width,
  };
}

// Returns a boolean if the user's browser is from a mobile device or tablet
var isDeviceMobileOrTablet = function () {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

var getShortCutsDate = function (text) {
  try {
    text = text.toLowerCase();
    var strNext = text.slice(0, 1);
    var str = "";
    if (strNext == "t") {
      //str = text.substr(1).trim()
      str = text.substr(1);
    } else if (strNext == "n") {
      str = text.substr(1);
    }
    if (str != "") {
      var days = 0;
      var hours = 0;
      var min = 0;
      var t1 = str.split(" ");
      if (t1.length > 0) {
        if (t1[0] != "") {
          days = t1[0];
        }
      }
      if (t1.length > 1) {
        var h1 = t1[1].split(":");
        if (h1.length == 2) {
          hours = h1[0];
          min = h1[1];
        } else if (h1.length == 1) {
          if (h1[0].length == 4) {
            hours = h1[0].slice(0, 2);
            min = h1[0].slice(2, 4);
          } else if (h1[0].length == 3) {
            hours = h1[0].slice(0, 1);
            min = h1[0].slice(1, 3);
          } else {
            hours = h1[0];
          }
        }
      }
      if (t1.length > 2) {
        min = t1[2];
      }

      var date = datetimeUTC(new Date());

      if (isFinite(days) == true) {
        date = date.add(days, "days");
        if (strNext == "t") {
          //date = date.startOf("day");
          date = date.hour(hours);
          date = date.minute(min);
          date = date.second(0);
          date = date.millisecond(0);
        }
        return date;
      } else {
        return null;
      }
    } else {
      if (text == "t") {
        date = dayjs().startOf("day");
        date = date.hour(0);
        date = date.minute(0);
        date = date.second(0);
        date = date.millisecond(0);
        return date;
      } else if (text == "n") {
        date = dayjs();
        date = date.second(0);
        date = date.millisecond(0);
        return date;
      }
      return null;
    }
  } catch (e) {
    console.log("Error occurred during shortcut date parsing.", e);
    return null;
  }
};

function formatPhoneNumber(val) {
  if (val != null && typeof val != "undefined") {
    val = val.replace(/[^0-9]/gi, "");
  }
  if (val != null && val.length >= 10 && typeof val != "undefined") {
    val = val.substr(0, 10);
    return (
      "(" + val.substr(0, 3) + ") " + val.substr(3, 3) + "-" + val.substr(6)
    );
  }
  return val;
}

//https://stackoverflow.com/questions/54635230/spread-operator-equivalent-in-ie-javascript
function getUniqueValues(array, prop) {
  return array
    .map(function (item) {
      return item[prop];
    })
    .filter(function (item, index, self) {
      return self.indexOf(item) === index;
    }); // distinct
}

function getUniqueValuesOfKey(array, key) {
  return array.reduce(function (carry, item) {
    if (item[key] && !~carry.indexOf(item[key])) carry.push(item[key]);
    return carry;
  }, []);
}

//Dayjs format(H:mm) for duration is not formating properly.
function formatSecondsToHoursMinutes(timeInSeconds) {
  var d = dayjs.duration(timeInSeconds, "seconds"),
    hours = Math.floor(d.asHours()),
    mins = Math.floor(d.asMinutes() - hours * 60);

  return (hours > 0 ? hours : "0") + ":" + (mins > 0 ? mins : "00");
}

function printDiv(divName) {
  var divToPrint = document.getElementById(divName);
  var popupWin = window.open("", "_blank", "width=0,height=0");
  popupWin.document.open();
  popupWin.document.write(
    '<html><title>Greatwide Truckload Management</title><br/><h2 style="text-align:center;" >Movement Report</h2><br/><body onload="window.print()">' +
      divToPrint.outerHTML +
      "</html>"
  );
  popupWin.document.close();
}

function getQueryStringValueByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function replaceBrTags(text) {
  if (text != null) {
    return text.replace(/<br\s*\/?>/gi, " ");
  }
  return text;
}

function setBtnDisabledState(event, text) {
  if (event && event.currentTarget) {
    var button = event.currentTarget;
    button.innerHTML = text || "";
    button.disabled = true;
  }
}

function setBtnEnabledState(event, text) {
  if (event && event.currentTarget) {
    var button = event.currentTarget;
    button.innerHTML = text || "";
    button.disabled = false;
  }
}

//An alternate way to map instead of using ko.toJS(obj) ... as it can be really slow at times, with large or deeply nested models
//
// NOTE: This will also remove any functions
// (optional) takeOnly -> array of string names referencing object keys -> only take these from object
// (optional) remove -> array of string names referencing object keys -> removes from object
function mapKOtoJS(obj, options) {
  options = options || {};
  let clonedObj = Object.keys(obj).length > 0 ? { ...obj } : {};
  let takeOnly = options.takeOnly || [];
  let remove = options.remove || [];

  _.unset(clonedObj, remove);

  const taking = takeOnly.length > 0 ? _.pick(clonedObj, takeOnly) : clonedObj;

  const result = _.forIn(taking, (val, key) => {
    val = ko.isObservable(val) ? ko.unwrap(val) : val;

    if (typeof val == "function") {
      delete taking[key];
    } else {
      taking[key] = val;
    }
  });

  return result;
}

/**
 * Purpose of this utility is to override / remove offsets being applied to date(s).
   to keep dates the same as what they are in the db (eastern standard time). It sets a dayjs object
   to have est timezone and proper offset when the date is parsed.
 * @param { Date | string | number | undefined } datetime
 * @returns {dayjs.Dayjs}
 */
function datetimeUTC(datetime) {
  try {
    datetime = datetime || "";
    datetime = typeof datetime === "number" ? new Date(datetime) : datetime;

    // datetime is date string format with no offset, set to Date obj so we can re-adjust time below after dayjs parsing.
    datetime =
      typeof datetime === "string" &&
      ["+", "-"].indexOf(datetime.substr(datetime.length - 6, 1)) == -1
        ? new Date(datetime)
        : datetime;
    let serverTZ = "America/New_York";

    // Since dates from server are always EST timezone, we are checking what DST (offset) we need for the date being parsed.
    const determineOffset = () => {
      const local = dayjs().tz(serverTZ);
      const date = dayjs(datetime).tz(serverTZ);

      const stdOffSet = -300; // <- the standard offset time -5 hrs when not in dst for eastern
      const isInDST = date.utcOffset() != stdOffSet; // dateUTC.utcOffset will either be -240 (-4hrs DST) or -300(-5hrs not DST)

      let offset = isInDST ? -4 : -5;

      return offset;
    };

    // setting this to server -> should parse properly when being hit in the api
    let x = dayjs(datetime).utcOffset(determineOffset());
    x = x.tz(serverTZ, true);

    // currently we need to reset everything...
    if (datetime instanceof Date) {
      x = x.year(datetime.getFullYear());
      x = x.month(datetime.getMonth());
      x = x.date(datetime.getDate());
      x = x.hour(datetime.getHours());
      x = x.minute(datetime.getMinutes());
    } else if (datetime instanceof dayjs) {
      x = x.year(datetime.year());
      x = x.month(datetime.month());
      x = x.date(datetime.date());
      x = x.hour(datetime.hour());
      x = x.minute(datetime.minute());
    }

    return x;
  } catch (e) {
    console.error("DatetimeUTC Error: ", e);
    return dayjs(null); // return invalid date for .isValid() checking.
  }
}

// Format Dates entered to have "/" or ":" if time is required. Without those the dates parse as invalid.
function formatDatetimeStr(datetimeStr) {
  if (
    typeof datetimeStr !== "string" ||
    (typeof datetimeStr === "string" && datetimeStr.length > 0 == false)
  )
    return datetimeStr;

  let dateParts = datetimeStr.split(" ");
  let date = dateParts[0];
  let time = dateParts[1];
  let AMPM = dateParts[2];

  if (date) {
    if (date.length > 0 && date.length <= 2) {
      // has format of 'DD'
      let d = datetimeUTC(new Date()).date(date);

      date = d.format("MM/DD/YYYY");
    } else if (date.length > 2 && date.indexOf("/") == -1) {
      // has format of 'MMDD' or 'MMDDYYYY' -> no '/' in date
      let pos = date.length % 2 == 0 ? 2 : 1;
      let attempts = 0;

      while (pos < 6 && attempts < 3) {
        if (date[pos + 1] == " " || date[pos + 1] == undefined) break;

        date = date.substr(0, pos) + "/" + date.substr(pos);
        pos = pos + 3; // adding 3 here since we now have '/' added to string...
        attempts = attempts + 1;
      }

      // browser default dates are set to 2001...
      // set to current year if we only have the month or month/day.
      if (date.split("/").length < 3) {
        let d = datetimeUTC(date + "/" + new Date().getFullYear());
        date = d.format("MM/DD/YYYY");
      }
    } else {
      // has format of 'MM/DD' or 'MM/DD/YYYY' -> has '/' in date
      let d = datetimeUTC(new Date(date));

      // browser default dates are set to 2001...
      // set to current year if we only have the month or month/day.
      if (date.split("/").length < 3) {
        d = d.year(dayjs().year());
      }

      date = d.format("MM/DD/YYYY");
    }
  }

  if (time && time.indexOf(":") == -1) {
    // adding a ':' after the hour; between the hour/mins
    let insertPos = time.length == 4 ? 2 : time.length == 3 ? 1 : time.length;
    time = time.substr(0, insertPos) + ":" + time.substr(insertPos);
  }

  return date + " " + (time || "") + (AMPM ? " " + AMPM.toUpperCase() : "");
}

// Recursive function
const cleanString = (charToRemoveList = [], str = "", replaceWith = "") => {
  if (!charToRemoveList) return str;
  if (charToRemoveList.length <= 0) return str;
  if (!str || str.length == 0) return "";

  const x = str.replaceAll(charToRemoveList[0], replaceWith);
  const list = charToRemoveList.filter((ch) => ch != charToRemoveList[0]);
  return cleanString(list, x, replaceWith);
};

// Example: mapToCityStateZipObject(cleanString(["(", ")", ","], yourValueString).split(" "));
// 'yourValueString' would look like this: "19067 (Yardley, PA)" --> order matters...zip > city > then state.
// This will convert to an object with { zip, city, state } properties
const mapToCityStateZipObject = (locationArr = []) => {
  const result = { zip: "", city: "", state: "" };

  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const provincees = [
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NS",
    "NT",
    "NU",
    "ON",
    "PE",
    "QC",
    "SK",
    "YT",
  ];

  const stateProvinces = [...states, ...provincees];

  result.state = [...locationArr]
    .reverse()
    .find((x) => stateProvinces.indexOf(x.toUpperCase()) > -1 && x.length == 2);

  // US
  if (states.indexOf(result.state) > -1) {
    // US Zipcodes are numeric only so test for numeric
    const zip = locationArr.find((x) => {
      try {
        return isNaN(parseInt(x)) == false;
      } catch (e) {
        return false;
      }
    });

    if (zip) {
      result.zip = zip;
      result.city = locationArr
        .filter((x) => x !== zip && x !== result.state)
        .join(" ")
        .trim();
    } else {
      // City, State
      result.city = locationArr
        .filter((x) => x !== result.state)
        .join(" ")
        .trim();
    }
  }

  // CANADA
  if (provincees.indexOf(result.state) > -1) {
    // No spaces zip -> i.e. "M9L2X6"
    const canadaZipNoSpace = /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/;

    // Spaces zip valid parts -> i.e. "M9L 2X6"
    const canadaZipPartA = /^[A-Za-z]\d[A-Za-z]$/;
    const canadaZipPartB = /^\d[A-Za-z]\d$/;

    result.zip = locationArr.find((x) => canadaZipNoSpace.test(x));

    // No result? Check if the format has spaces. i.e. "M9L 2X6"
    if (!result.zip) {
      const partA = locationArr.find((x) => canadaZipPartA.test(x));
      const partB = locationArr.find((x) => canadaZipPartB.test(x));

      if (partA && partB) {
        // Have valid zip -> Find City
        result.zip = `${partA} ${partB}`;
        result.city = locationArr
          .filter((x) => x !== partA && x !== partB && x !== result.state)
          .join(" ")
          .trim();
      }
    } else {
      // Have result -> Find City
      result.city = locationArr
        .filter((x) => x !== result.zip && x !== result.state)
        .join(" ")
        .trim();
    }

    // Build as if City, State
    if (!result.city && !result.zip && result.state) {
      result.city = locationArr
        .filter((x) => x !== result.state)
        .join(" ")
        .trim();
    }
  }

  return result;
};

const groupBy = (prop) => {
  return (items = []) => {
    const grp = items.reduce((grouping, val) => {
      const key = val[prop];
      if (!grouping[key]) grouping[key] = [];
      grouping[key].push(val);
      return grouping;
    }, {});

    return Object.keys(grp).map((x) => ({ label: x, items: grp[x] }));
  };
};

const modifyDate = (date = new Date()) => {
  const add = (amount, type) => {
    const dayjsDate = datetimeUTC(date);
    const newDate = dayjsDate.add(amount, type);
    return newDate;
  };

  const addDays = (days) => add(days, "day");
  const addWeeks = (weeks) => add(weeks, "week");
  const addMonths = (months) => add(months, "month");
  const addYears = (years) => add(years, "year");

  return {
    addDays,
    addWeeks,
    addMonths,
    addYears,
    add,
  };
};

// Strips the offsets so jqx renders dates in est.
const mapGridDate = (date = "") => {
  return date && date.length ? date.substr(0, date.indexOf("T") + 9) : "";
};

const createLocalCache = (cache = {}) => {
  let subscribers = {};

  const get = (id) => {
    const x = cache[id];

    if (!id) return { ...cache }; // return all if no id
    if (id && !x) return null; // if id and nothing found return null

    return x;
  };

  const subscribe = (id, callBackFn = function (x) {}) => {
    const current = subscribers[id];

    subscribers[id] = [...current, callBackFn];
  };

  const add = (id, data) => {
    cache[id] = data;

    if (subscribers[id]) {
      subscribers[id].forEach((fn) => fn(data));
    }
  };

  const clear = () => {
    cache = {};
    subscribers = [];
  };

  return { get, add, clear, subscribe, cache };
};

// piping (chaining) functions together
const pipeFn =
  (...fns) =>
  (arg) =>
    fns.reduce((prev, fn) => fn(prev), arg);

function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

// no operation
const noop = (...x) => {};

const allowInString = (charsAllowed = [], str = "", replaceWith = "") => {
  const execute = (chars, index) => {
    if (!charsAllowed) return chars;

    if (charsAllowed.length <= 0) return chars;

    if (index === str.length) return chars;
    const char =
      charsAllowed.indexOf(str[index]) === -1 ? replaceWith : str[index];

    return execute(chars + char, index + 1);
  };

  return execute("", 0);
};

const isValidSimpleEmail = (email) => {
  try {
    return new RegExp("^(?:(?![^@\r\n]+@[^@\r\n]+)+").test(email);
  } catch (e) {
    return false;
  }
};

const getHoursMinsFromMins = (minutes = 60) => {
  if (!minutes) return { hours: "00", mins: "00" };

  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  return {
    hours: hours < 10 ? "0" + hours : hours,
    mins: mins < 10 ? "0" + mins : mins,
  };
};

function debounceFn(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}


export {
  Base64,
  isDeviceMobileOrTablet,
  GenerateGuid,
  mobileAndTabletcheck,
  mobilecheck,
  getUniqueValues,
  getShortCutsDate,
  scrollToRequiredField,
  isElementInViewport,
  getRelativeClientRect,
  get_browser_info,
  formatPercentage,
  formatPhoneNumber,
  getQueryVariable,
  createDateTimeFilterWidget,
  getUniqueValuesOfKey,
  rawNumber,
  isEmpty,
  copyLinkToClipboard,
  copyToClipboard,
  formatSecondsToHoursMinutes,
  printDiv,
  getQueryStringValueByName,
  getDateTimestamp,
  escapeHtml,
  replaceBrTags,
  setBtnDisabledState,
  setBtnEnabledState,
  mapKOtoJS,
  datetimeUTC,
  formatDatetimeStr,
  cleanString,
  mapToCityStateZipObject,
  groupBy,
  modifyDate,
  mapGridDate,
  createLocalCache,
  pipeFn,
  arrayBufferToBase64,
  noop,
  allowInString,
  isValidSimpleEmail,
  getHoursMinsFromMins,
  debounceFn
};
