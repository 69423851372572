import dataModel from 'data-model';
import { escapeHtml } from 'global-functions';


class Account {
    constructor(accountType, agencyCode, userId, password, passwordConfirm, emailAddress) { 
        this.AccountType = accountType;
        this.AgencyCode = agencyCode;
        this.UserId = userId;
        this.Password = password;
        this.ConfirmPassword = passwordConfirm;
        this.Email = emailAddress;
    }
}

class CreateAccountViewModel {
    constructor() {
        var self = this;
        var registerUrl = "/Account/Create";

        //============================
        // UI Controls
        self.isLoading = ko.observable(false);
        self.modelErrors = ko.observableArray([]);
        self.accountTypeSelectList = ko.observableArray(['Driver', 'Agent']);
        self.accountType = ko.observable("Driver");
        self.successMsg = ko.observable();

        var patterns = {
            email: /^([\d\w-\.]+@([\d\w-]+\.)+[\w]{2,4})?$/,
            passwordComplexity: /(?=^[^\s]{6,15}$)((?=.*?\d)(?=.*?[A-Z])(?=.*?[a-z])|(?=.*?\d)(?=.*?[^\w\d\s])(?=.*?[a-z])|(?=.*?[^\w\d\s])(?=.*?[A-Z])(?=.*?[a-z])|(?=.*?\d)(?=.*?[A-Z])(?=.*?[^\w\d\s]))^.*/
        };

        //============================
        // Data
        
        self.formModel = ko.validatedObservable({
            agencyCode: ko.observable("").extend({ required: true }),
            userId: ko.observable("").extend({ required: true }),
            password: ko.observable("").extend({
                required: true,
                pattern: {
                    message: 'Password must be between 6 and 15 characters long, contain an upper case letter, a symbol, or a number.',
                    params: patterns.passwordComplexity
                }
            }),
            passwordConfirm: ko.observable("").extend({ required: true }),
            emailAddress: ko.observable("").extend({
                required: true,
                email: true
            }),
        });
                
        
        
        //=========================
        // Behaviors
        self.submitForm = function () {
            self.successMsg(false);

            if (self.isFormValid()) {
                self.modelErrors([]);
                self.isLoading(true);

                var newAccount = new Account(
                    self.accountType(),
                    escapeHtml(self.formModel().agencyCode()),
                    escapeHtml(self.formModel().userId()),
                    escapeHtml(self.formModel().password()),
                    escapeHtml(self.formModel().passwordConfirm()),
                    escapeHtml(self.formModel().emailAddress())
                );
                

                dataModel.ajaxRequest(registerUrl, 'POST', newAccount).done(function (data) {
                    self.isLoading(false);

                    // Display any errors returned
                    if (data.errors != null || data.errors != undefined) {
                        if (data.errors.length > 0) {
                            self.setModelErrors(data.errors);
                        } 
                    }
                    else
                    {
                        // Success -> Display success -> clear form
                        self.successMsg("Account created successfully.");
                    }

                 });
            }
            else
            {
                self.formModel.errors.showAllMessages();
                return false;
            }
        }

        // Set and disply model errors
        self.setModelErrors = function (errors)
        {
            if (errors != null || errors != undefined)
            {

                for (var i = 0; i < errors.length; i++)
                {
                    self.modelErrors.push({ errorMessage: errors[i] });
                }
            }
        }

        // Reset Form Fields
        self.resetForm = function () {
            self.successMsg(false);
            self.formModel().agencyCode("");
            self.formModel().userId("");
            self.formModel().password("");
            self.formModel().passwordConfirm("");
            self.formModel().emailAddress("");
        }


        // Verify passwords match
        self.verifyPasswordsMatch = function () {
            var password = self.formModel().password();
            var passwordConfirm = self.formModel().passwordConfirm();


            return (password == passwordConfirm) && (password != "" && passwordConfirm != "");
        }

        self.isFormValid = function () {
            return self.formModel.isValid() && self.verifyPasswordsMatch();
        }
    }
}


import template from './create-account-page.html';
export default { viewModel: CreateAccountViewModel, template: template }