import ko from "knockout";
import dataModel from "data-model";
import dayjs from "dayjs";
import template from "./carrier-call-in-records-page.html";
import gridStateUtils from "jqx.grid-utils";
import GreatEdgeCustomWidgets from "ge.custom-widgets";
import GridStateComponentViewModel from "jqx.grid-state-component";
import * as _ from "lodash";
import { mobileAndTabletcheck, datetimeUTC } from "global-functions";
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";

// Types
var CarrierRecordViewModel = function (data, mainModel) {
  ko.validation.rules["dateTodayOrGreater"] = {
    validator: function (arg) {
      var today = new dayjs().format("MM/DD/YYYY");
      var date = new dayjs(arg).format("MM/DD/YYYY");

      if (date < today) return false;

      return true;
    },
    message: "Date must be today or later.",
  };

  var self = this;
  mainModel = mainModel || {};
  var record = data || {};
  self.carrierNumber = ko.observable(record.carrierExId);
  self.carrierId = ko.observable(record.carrierId);
  self.orderNumber = ko.observable(record.orderId);
  self.enteredBy = ko.observable(record.enteredBy);
  self.notes = ko.observable(record.notes);
  self.temperature = ko.observable(record.temperature);
  self.moveStatus = ko.observable(record.moveStatus);
  self.moveDistance = ko.observable(record.moveDistance);

  // Access Controls
  self.allowedToEdit = ko.computed(function () {
    if (record.enteredBy == mainModel.currentUser().userName) {
      return true;
    }
    return false;
  });

  // Format dates
  self.newETA = ko.computed(function () {
    if (record.newETA != undefined) {
      var newETA = new dayjs(record.newETA);
      return newETA.format("MMMM DD, YYYY HH:mm");
    }
  });

  self.callDate = ko.computed(function () {
    if (record.callDate != undefined) {
      var callDate = new dayjs(record.callDate);
      return callDate.format("MMMM DD, YYYY HH:mm");
    }
  });

  self.arrival = ko.computed(function () {
    if (record.arrival != undefined) {
      var arrival = new dayjs(record.arrival);
      return arrival.format("MMMM DD, YYYY HH:mm");
    } else {
      return "";
    }
  });

  self.cityStateZip = ko.computed(function () {
    var city = record.city || "";
    var state = record.state || "";
    var zip = record.zip || "";
    return city + " " + state + " " + zip;
  });

  self.orderOrigin = ko.computed(function () {
    var city = record.originCity || "";
    var state = record.originState || "";
    var zip = record.originZip || "";
    return city + " " + state + " " + zip;
  });

  self.orderDest = ko.computed(function () {
    var city = record.destCity || "";
    var state = record.destState || "";
    var zip = record.destZip || "";
    return city + " " + state + " " + zip;
  });
  self.openEditRecordModal = function () {
    self.closeDetails();
    mainModel.openEditCallinModal(data);
  };
  self.closeDetails = function () {
    $("#detailsModal").modal("hide");
  };
  return self;
};

var NewCallInRecordViewModel = function (mainModel) {
  var self = this;
  mainModel = mainModel || {};
  var user = mainModel.currentUser() || {};

  self.currentUser = ko.observable(user);
  self.cityStateZip = ko.observable().extend({ required: true });
  self.newETA = ko
    .observable()
    .extend({ required: true, dateTodayOrGreater: true });

  self.orderInput = ko.observable().extend({ required: true });
  self.orderSelected = ko.observable();
  self.carrierInput = ko.observable().extend({ required: true });
  self.carrierSelected = ko.observable();

  self.callinTypesList = ko.observableArray([
    { label: "Carrier Initiated", value: "C" },
    { label: "Comment", value: "O" },
    { label: "Broker Initiated", value: "B" },
  ]);

  self.selectedCallinType = ko.observable(self.callinTypesList()[0]); // set default to 'carrier initiated'

  self.errors = ko.observableArray();
  self.notes = ko.observable();
  self.temperature = ko.observable();

  self.isProcessing = ko.observable(false);

  self.now = ko.computed(function () {
    var m = new dayjs();
    return m.format("MMMM DD, YYYY HH:mm");
  });

  self.closeModal = function () {
    $("#callInModalForm").modal("hide");
  };

  self.submitRecord = function () {
    self.errors.removeAll();
    var validationErrors = ko.validation.group(self);
    if (validationErrors().length > 0) {
      validationErrors.showAllMessages();
      return false;
    }

    var data = {
      OrderId: self.orderSelected().id,
      Notes: self.notes(),
      EnteredById: user.id,
      CallTime: self.now(),
      NewETA: self.newETA().format("MMMM DD, YYYY HH:mm"),
      CarrierId: self.carrierSelected().id,
      Temp: self.temperature(),
      CityStateZip: self.cityStateZip(),
      InitiatedType: self.selectedCallinType(),
    };

    self.isProcessing(true);

    dataModel
      .ajaxRequest("CallinRecords/CreateCarrierCallInRecord", "POST", data)
      .done(function (response) {
        if (response.success != undefined) {
          mainModel.updateGrid();
        }

        self.isProcessing(false);
        self.closeModal();
      })
      .fail(function (response) {
        if (response.responseJSON != undefined) {
          self.errors.push(response.responseJSON);
        }
        self.isProcessing(false);
      });
  };

  return self;
};

var EditCallInRecordViewModel = function (recordToEdit, mainModel) {
  var self = this;
  mainModel = mainModel || {};
  var record = recordToEdit || {};
  var callRecordId = record.id;

  self.formatCityStateZip = function () {
    return record.zip + " (" + record.city + ", " + record.state + ")";
  };

  self.enteredByName = ko.observable(record.enteredBy);
  self.cityStateZip = ko
    .observable(self.formatCityStateZip())
    .extend({ required: true });
  self.carrierId = ko.observable(record.carrierExId);

  self.callinTypesList = ko.observableArray([
    { label: "Carrier Initiated", value: "C" },
    { label: "Comment", value: "O" },
    { label: "Broker Initiated", value: "B" },
  ]);

  self.selectedCallinType = ko.observable(
    recordToEdit.initiatedType || self.callinTypesList()[0]
  ); // set default to 'carrier initiated'
  self.newETA = ko.observable(record.newETA).extend({ required: true });
  self.orderId = ko.observable(record.orderId);
  self.errors = ko.observableArray();
  self.notes = ko.observable(record.notes);
  self.temperature = ko.observable(record.temperature);

  self.isProcessing = ko.observable(false);

  self.callDate = ko.computed(function () {
    var date = new dayjs(record.callDate);
    return date.format("MMMM DD, YYYY HH:mm");
  });

  self.closeModal = function () {
    $("#editCallInModalForm").modal("hide");
  };

  self.submitRecord = function () {
    self.errors.removeAll();
    var validationErrors = ko.validation.group(self);
    if (validationErrors().length > 0) {
      validationErrors.showAllMessages();
      return false;
    }
    var data = {
      Notes: self.notes(),
      NewETA: dayjs(self.newETA()).format("MMMM DD, YYYY HH:mm"),
      Temp: self.temperature(),
      CityStateZip: self.cityStateZip(),
      InitiatedType: self.selectedCallinType(),
    };

    self.isProcessing(true);

    dataModel
      .ajaxRequest(
        "CallinRecords/EditCarrierCallInRecord/" + callRecordId,
        "PUT",
        data
      )
      .done(function (response) {
        if (response.success != undefined) {
          mainModel.updateGrid();
        }
        self.isProcessing(false);
        self.closeModal();
      })
      .fail(function (response) {
        if (response.responseJSON != undefined) {
          self.errors.push(response.responseJSON);
        }
        self.isProcessing(false);
      });
  };

  return self;
};

//=======================
// ViewModel
var CarrierCallInRecordsViewModel = function () {
  var self = this;
  var geCustomWidgets = new GreatEdgeCustomWidgets();

  //============================
  // UI Controls
  self.isLoading = ko.observable(false);
  self.isProcessing = ko.observable(false);
  self.modelErrors = ko.observableArray([]);

  var grid = $("#jqxCarrierCallinGrid");
  var gridId = "jqxCarrierCallinGrid";
  var _jqxGridHelper = geCustomWidgets.GEWidgetsGridHelper(
    "jqxCarrierCallinGrid"
  );

  self.isPageInitialized = ko.observable(false);
  var isInitLoad = true;
  self.detailsModal = ko.observable();
  self.callInModalForm = ko.observable();
  self.editCallInModalForm = ko.observable();

  //============================
  // Data

  self.driverRecords = ko.observableArray([]);
  self.currentUser = ko.observable();

  //=========================
  // Behaviors

  self.clearSelectedSavedSearchDDL = ko.observable(false);
  self.refreshSavedSearchDDL = ko.observable("");

  self.handleOnSelected = (state) => {
    self.carriersGridState = state.grid || {};
    if (isInitLoad) {
      self.buildGrid();
    } else {
      gridStateUtils.applyGridState(gridId, self.carriersGridState);
    }
  };

  self.closeCallModalForm = function () {
    $("#callInModalForm").modal("hide");
  };

  // Set and disply model errors
  self.setModelErrors = function (errors) {
    if (errors != null || errors != undefined) {
      for (var i = 0; i < errors.length; i++) {
        self.modelErrors.push({ errorMessage: errors[i] });
      }
    }
  };

  self.updateGrid = function () {
    grid.jqxGrid("updatebounddata", "data");
  };

  // Hack to enable multiple modals (modal open, then another modal opens on top)
  // by making sure the '.modal-open' class
  // is set to the <body> when there is at least one modal left in view.
  // Removing this will cause any underlying modals to not be scrollable when
  // a second or third modal is closed.
  // $('body').on('hidden.bs.modal', function () {
  //     if ($('.in').length > 0) {
  //         $('body').addClass('modal-open');
  //     } else {
  //         $('body').removeClass('modal-open');
  //     }
  // });

  //--------------------------------------------------------
  // GRID
  //=======

  self.refreshGrid = function () {
    grid.jqxGrid("clearfilters");
  };

  self.placeHolderFilterWidget = function (column, columnElement, widget) {
    widget.jqxInput({
      placeHolder: "Type Here...",
    });
  };

  // Open detail view modal
  self.viewRecordDetails = function (record) {
    if (record != undefined) {
      self.isLoading(true);
      dataModel
        .ajaxRequest("CallinRecords/GetCarrierCallInRecord/" + record.id, "GET")
        .done(function (response) {
          if (response != undefined) {
            self.detailsModal(new CarrierRecordViewModel(response, self));
          }

          self.isLoading(false);
        });
    }
  };

  // Open new call in modal
  self.openNewCallInModalForm = function () {
    if (self.isPageInitialized() == true) {
      self.callInModalForm(new NewCallInRecordViewModel(self));
    }
  };

  // Edit call in record
  self.openEditCallinModal = function (data) {
    if (self.isPageInitialized() == true) {
      self.editCallInModalForm(new EditCallInRecordViewModel(data, self));
    }
  };

  self.carriersGridState = {};

  self.buildGrid = function () {
    var source = {
      url: "CallinRecords/GetCarrierCallinRecords",
      datatype: "json",
      datafields: [
        { name: "id", type: "number" },
        { name: "carrierId", type: "string" },
        { name: "orderId", type: "string" },
        { name: "city", type: "string" },
        { name: "state", type: "string" },
        { name: "callDate", type: "date" },
        { name: "enteredBy", type: "string" },
      ],
      filter: function (filters, recordsArray) {
        if (isInitLoad == false) {
          grid.jqxGrid("updatebounddata", "filter");
        }
      },
      sort: function (column, direction) {
        if (isInitLoad == false) {
          grid.jqxGrid("updatebounddata", "sort");
        }
      },
      formatdata: function (data) {
        var filterinfo = grid.jqxGrid("getfilterinformation");
        data = gridStateUtils.formatGridFilters(filterinfo, data);
        if (isInitLoad) {
          data = gridStateUtils.loadInitGridState(
            data,
            self.carriersGridState,
            columns
          );
        }
        // Get grid filters from date picker
        data = _jqxGridHelper.addGridFiltersToDataObj(data);
        return data;
      },
      loadComplete: function (data) {
        self.currentUser(data.currentUser);
        self.isLoading(false);
      },
      beforeprocessing: function (data) {
        source.totalrecords = data.totalCount;

        data.records = data.records || [];
        data.records = data.records.map((x) => {
          if (x.callDate) {
            x.callDate = x.callDate.substr(0, x.callDate.indexOf("T") + 9);
          }

          return x;
        });
      },
      loadError: function (xhr, status, error) {},
    };

    var dataAdapter = dataModel.getDataAdapter(source);

    var geDateRangeFilterWidget = function (column, columnElement, widget) {
      //set widget (class) level options
      var options = {
        gridOptions: {
          gridWidget: widget,
          gridId: "jqxCarrierCallinGrid", // required to apply gridoptions
          columnName: column.datafield, // also required, especially if this is a filter widget
          isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
          state: self.carriersGridState, // can send our grid state and apply any initial filters
        },
      };

      return geCustomWidgets.GEDateRangePickerWidget(options); // will return widget if already exists or creates a new one;
    };

    var columns = [
      {
        text: "",
        width: 100,
        columntype: "button",
        cellsrenderer: function () {
          return "Details";
        },
        buttonclick: function (row) {
          var datarow = grid.jqxGrid("getrowdata", row);
          //self.isLoading(true);
          self.viewRecordDetails(datarow);
        },
        filterable: false,
        sortable: false,
        pinned: true,
      },
      {
        text: "Carrier",
        width: "35%",
        datafield: "carrierId",
        createfilterwidget: self.placeHolderFilterWidget,
      },
      {
        text: "Order #",
        width: "30%",
        datafield: "orderId",
        createfilterwidget: self.placeHolderFilterWidget,
      },
      {
        text: "City",
        width: 250,
        datafield: "city",
        createfilterwidget: self.placeHolderFilterWidget,
      },
      {
        text: "State",
        width: 120,
        datafield: "state",
        createfilterwidget: self.placeHolderFilterWidget,
      },
      {
        text: "Call Date",
        width: 200,
        datafield: "callDate",
        cellsformat: "MM/dd/yyyy HH:mm",
        createfilterwidget: geDateRangeFilterWidget,
      },
    ];

    grid.jqxGrid({
      width: "100%",
      source: dataAdapter,
      autoheight: true,
      altrows: true,
      sortable: true,
      pageable: true,
      pagesize: 10,
      filterable: true,
      showfilterrow: true,
      virtualmode: true,
      columnsresize: true,
      columnsreorder: true,
      enablebrowserselection: true,
      columnsmenu: false,
      columns: columns,
      showtoolbar: true,
      toolbarheight: 40,
      autoshowfiltericon: true,

      rendertoolbar: function (toolbar) {
        //var tmpl = require("text!template/gridState.html");
        //var gridState = require("tmpl/gridState");
        var vm1 = new GridStateComponentViewModel.viewModel();
        var template = GridStateComponentViewModel.template;
        var $grid = $("#jqxCarrierCallinGrid");
        vm1.clearFilters = function () {
          _jqxGridHelper.clearDateRangeFiltersFromGrid();
          self.clearSelectedSavedSearchDDL(true);
          // clear additional filters from grid
          grid.jqxGrid("clearfilters");
        };
        vm1.actions.push("Save Search");

        vm1.setDefaultSearchOverride = async () => {
          const savedSearches = await loadSavedSearches($grid.attr("id"));
          const filters = {
            isDefault: true,
          };

          vm1.loadSaveSearchModal(
            $grid,
            (val) => {
              if (val && val.searchname) {
                self.refreshSavedSearchDDL(val.searchname);
              }
            },
            savedSearches
              .filter((x) => x.searchName)
              .map((x) => ({ id: x.id, text: x.searchName })),
            filters,
            true
          );
        };

        vm1.loadSaveSearchModalOverride = async () => {
          const savedSearches = await loadSavedSearches($grid.attr("id"));
          const filters = {
            isDefault: false,
          };

          vm1.loadSaveSearchModal(
            $grid,
            (val) => {
              if (val && val.searchname) {
                self.refreshSavedSearchDDL(val.searchname);
              }
            },
            savedSearches
              .filter((x) => x.searchName)
              .map((x) => ({ id: x.id, text: x.searchName })),
            filters,
            true
          );
        };

        const $actionList = $("#carrierCallinGridAction");
        $actionList.append(template);

        toolbar.append($actionList);

        const $carrierCallinToolbar = $("#carrierCallinToolbar");
        toolbar.append($carrierCallinToolbar);

        ko.applyBindingsToDescendants(vm1, $actionList[0]);
        //vm1.initializeDefaultToolbar(toolbar);
      },

      rendergridrows: function (obj) {
        return obj.data;
      },

      ready: function () {
        gridStateUtils.loadGridStateFilters(
          grid,
          self.carriersGridState.filters
        );
        isInitLoad = false;

        self.isPageInitialized(true);
      },
    });

    // The grid is set to autoheight by default which ignores setting the grid height explicity. So what we are doing here is
    // checking if user is NOT on a mobile device and the pagesize selected is 20, then we will explicity set the grid height and
    // turn off autoheight (being set to true always overrides setting a grid height)
    // This will allow the grid to adjust for smaller or larger screens
    grid.on("pagesizechanged", function (event) {
      var args = event.args;
      var autoheight = mobileAndTabletcheck() || args.pagesize < 20;
      grid.jqxGrid({ height: screen.height - 370, autoheight: autoheight });
      grid.jqxGrid("updatebounddata", "cells");
    });
  };
};

export default { viewModel: CarrierCallInRecordsViewModel, template: template };
