import dataModel from 'data-model';
import userProfile from 'user-profile';
import {serviceResult} from './efsAdvanceServices';

/**
 * Comdata legacy support
 */
function comdataService() {
    const _comdataHttp = new comdataHTTPService();

    this.addWireToOrder = async(wireData) => {
        var result = new serviceResult();
        
        var response = await _comdataHttp.sendAddWireToOrder(wireData).catch((err) => { result.errors.push(err); return null; });
            
        if(response) {
            if(typeof response === 'string') {
                result.errors.push(response);
            }
            else {
                result.data = response;
            }
        }
        
        return result;
    }

    this.cancelWire = async({wireId, movementId, password}) => {
        var result = new serviceResult();

        if(password) {
            const payload = {
                wireId: ko.unwrap(wireId),
                movementId: ko.unwrap(movementId),
                password: password
            };
    
            var response = await _comdataHttp.sendCancelWire(payload).catch((err) => { result.errors.push(err); return null; });
    
            if(response && response.error) {
                result.errors.push(response.error);
            }
        }

        return result;
    }

    this.checkDriverWireStatus = async({orderId, driverId}) => {
        var result = new serviceResult();

        const payload = {
            orderId: orderId,
            driverId: driverId
        };

        var response = await _comdataHttp.sendCancelWire(payload).catch((err) => { result.errors.push(err); return null; });

        if(response) {
            result.data = response;
        }

        return result;
    }
}

/**
 * Comdata HTTP endpoints 
 */
function comdataHTTPService() {
    this.sendAddWireToOrder = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Dispatch/AddWireToOrder", "POST", payload).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        })
    }

    this.sendCancelWire = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Dispatch/CancelWire", "POST", payload).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        })
    }

    this.sendCheckDriverWireStatus = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Dispatch/CheckDriverWireStatus", "POST", payload).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        })
    }
}

const instance = new comdataService();
export default instance;

