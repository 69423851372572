
import mapquest from 'mapQuestModel';
import dataModel from 'data-model';
import userProfile from 'user-profile';


class TractorTrackingViewModel {
    constructor() {
        var self = this;

        self.isLoading = ko.observable(false);

        self.isOwnerOperator = ko.observable(true);

        self.tractorPoints = [];

        self.region = ko.observable();
        self.customerRegion = ko.observable();
        self.locationRegion = ko.observable();
        self.tractorRegion = ko.observable();

        self.regions = ko.observableArray(["All Regions"]);
        self.customerRegions = ko.observableArray(["All Customer Regions"]);
        self.locationRegions = ko.observableArray(["All Location Regions"]);
        self.tractorRegions = ko.observableArray(["All Tractor Regions"]);

        var isInit = true;
        self.regionSelected = function (event) {
            if (isInit == false) {
                mapquest.clearMap();
                self.loadPoints();
            }
        }

        self.tractors = ko.observableArray();

        var currentCheckedItem = null;
        self.tractorChecked = function (event) {
            var item = event.args;

            if(currentCheckedItem != item.value && isInit == false) {
                self.isLoading(true);
                //mapquest.clearMap();

                var tmpTractors = self.tractorPoints.filter(function(poi) {
                    
                    var match = self.tractors().map(function(x) { if(x.checked) return x.value }).indexOf(poi.tractorId);
                    return match > -1;
                });
    
                mapquest.addCollectionPoiToMap(tmpTractors, true).then(function(x){
                    currentCheckedItem = item.value;
                    self.isLoading(false);

                }).catch(function(err){self.isLoading(false);});
            }
            
        };
        self.showAllTractors = function () {
            self.isLoading(true);
            setTimeout(function() {
                mapquest.clearMap();

                self.tractors.removeAll();
                self.tractors.valueWillMutate();
                ko.utils.arrayForEach(self.tractorPoints, function (item) {
                    self.tractors.push({ value: item.tractorId, name: item.tractorId + " - " + item.driverName, checked: true });
                });
                self.tractors.valueHasMutated();
    
                mapquest.addCollectionPoiToMap(self.tractorPoints, true).then(function(x){
                    self.isLoading(false);
                }).catch(function(err) { self.isLoading(false); });
            },1);
            
            
        };
        self.clearAllTractors = function () {
            self.isLoading(true);
            self.tractors.removeAll();
            self.tractors.valueWillMutate();
            ko.utils.arrayForEach(self.tractorPoints, function (item) {
                self.tractors.push({ value: item.tractorId, name: item.tractorId + " - " + item.driverName, checked: false });
            });
            self.tractors.valueHasMutated();

            mapquest.clearMap();
            self.isLoading(false);
        };




        self.getRegions = function () {

            var regionDef = new $.Deferred();
            var defaultDef = new $.Deferred();

            dataModel.ajaxRequest("Region/" + userProfile.currentAgencyId())
                       .done(function (data, status, xhr) {
                           ko.utils.arrayForEach(data, function (item) {
                               if (item.type == "CUSTOMER") {
                                   self.customerRegions.push(item.name);
                               }
                               else if (item.type == "LOCATION") {
                                   self.locationRegions.push(item.name);
                               }
                               else if (item.type == "TRACTOR") {
                                   self.tractorRegions.push(item.name);
                               }
                               else {
                                   self.regions.push(item.name);
                               }
                           });
                           regionDef.resolve();
                       })
                       .fail(function (error, msg, d) {
                           regionDef.resolve();
                       });


            var region = undefined;
            var customer = undefined;
            var location = undefined;
            var tractor = undefined;

            dataModel.ajaxRequest("Region/GetDefaultRegion/" + userProfile.currentAgencyId())
            .done(function (data, status, xhr) {
                
                ko.utils.arrayForEach(data, function (item) {
                    if (item.type != null) {
                        if (item.type.toLowerCase() == "customer") {
                            customer = item.name;
                        }
                        else if (item.type.toLowerCase() == "location") {
                            location = item.name;
                        }
                        else if (item.type.toLowerCase() == "tractor") {
                            tractor = item.name;
                        }
                    }
                    else {
                        region = item.name;
                    }
                });
                defaultDef.resolve();
            })
            .fail(function (error, msg, d) {
                defaultDef.resolve();
            });


            $.when(regionDef, defaultDef)
                .done(function () {
                    self.region(region);
                    self.customerRegion(customer);
                    self.locationRegion(location);
                    self.tractorRegion(tractor);

                    self.loadPoints();
                });
        };


        self.loadPoints = function () {

            var data = {
                region: self.region() != "All Regions" ? self.region() : null,
                customerRegion: self.customerRegion() != "All Customer Regions" ? self.customerRegion() : null,
                locationRegion: self.locationRegion() != "All Location Regions" ? self.locationRegion() : null,
                tractorRegion: self.tractorRegion() != "All Tractor Regions" ? self.tractorRegion() : null
            };

            self.isLoading(true);

            dataModel.ajaxRequest("Driver/GetGeoLocation", "get", data)
                 .done(function (data, textStatus, jqXHR) {
                    
                     var details = $("#truckDetails");
                     var elemInstance = document.getElementById('truckDetails');

                     self.tractors.valueWillMutate();

                     var itemCnt = data.length;
                     for (var i = 0; i < itemCnt; i++) {
                         // Used for map popup details
                         var info = "<div style='width:180px;'><strong>Order: </strong>" + data[i].orderId + "<br />"
                                    + "<div style='height:100px; overflow-y:scroll; background-color:#DCE6F4'>"
                                    + "<strong>Tractor: </strong>" + (data[i].tractorId) + "<br />"
                                    + "<strong>Driver: </strong>" + data[i].driverId + "<br />"
                                    + "<strong>Driver Name: </strong>" + data[i].driverName + "<br />"
                                    + "<strong>Origin: </strong>" + data[i].origin + "<br />"
                                    + "<strong>PUDate: </strong>" + data[i].pickUpDate + "<br />"
                                    + "<strong>Dest: </strong>" + data[i].destination + "<br />"
                                    + "<strong>DelDate: </strong>" + (data[i].deliveryDate || "") + "<br />"
                                    + "<strong>Duty Status: </strong>" + (data[i].currentDutyStatus || "") + "<br />"
                                    + "<strong>On Duty Remaining: </strong>" + (data[i].drivingRemainingToday || "") + "<br />"
                                    + "<strong>Drive Time Remaining: </strong>" + (data[i].totalOnDutyRemaining || "") + "<br />"
                                    + "</div></div>";
                         data[i].info = info; 
                         data[i].title = "Tractor: "+ data[i].tractorId; // the title of when hovering over map pins

                         // This is the driver/tractor DDL list
                         self.tractors.push({ value: data[i].tractorId, name: data[i].tractorId + " - " + data[i].driverName, checked: true });
                     }
                     self.tractors.valueHasMutated();

                     self.tractorPoints = data;
                     if (isInit) {

                         mapquest.loadMap("map");
                         isInit = false;
                     }
                     mapquest.addCollectionPoiToMap(data, true);

                     self.isLoading(false);


                 })
                .fail(function () {
                    self.isLoading(false);
                });
        };

        self.init = function() {
            
            var _isOO = userProfile.isOwnerOperator || userProfile.isUserDriver || false;

            self.isOwnerOperator(_isOO);
            self.getRegions();
        }

        self.init();
    }
}

import template from './tractor-tracking-page.html';
export default { viewModel: TractorTrackingViewModel, template: template }