const ExternalBoardDetailsViewModel = function ({
  externalBoardDetails = ko.observable(),
  handleGetTPIMarketRates = (data = {}) => {},
} = {}) {
  const vm = this;

  vm.details = externalBoardDetails;

  vm.handleGetTPIMarketRates = () =>
    handleGetTPIMarketRates(ko.toJS(vm.details()));
};

import template from "./external-board-details-component.html";
export default { viewModel: ExternalBoardDetailsViewModel, template: template };
