import ko from 'knockout';
import dataModel from 'data-model';
import template from './equipment-issued-component.html';
import gridStateUtils from 'jqx.grid-utils'

class EquipmentIssuedViewModel {
    constructor(params)
    {
        var self = this;

        var _itemExternalId = params.itemExternalId || null;
        var _itemType = params.itemType || null;
        var _$grid = $('#jqxEquipmentIssuedGrid');
        var _gridCreated = false;

        self.getEquipmentIssued = function (optionArgs) {
            optionArgs = optionArgs || {};

            _itemExternalId = optionArgs.itemExternalId || _itemExternalId;
            _itemType = optionArgs.itemType || _itemType;
            
            if (_gridCreated) {
                _$grid.jqxGrid('updatebounddata', 'data');
            }
            else {
                _buildGrid();
            }

        }

        function _buildGrid() {
            var source = {
                url: "Equipment/Issued",
                datatype: "json",
                beforeprocessing: function (data) {
                    source.totalrecords = data.totalRecords;
                },
                sort: function (column, direction) {
                    _$grid.jqxGrid('updatebounddata', 'sort');
                },
                formatdata: function (data) {
                    var filterinfo = _$grid.jqxGrid('getfilterinformation');
                    data = gridStateUtils.formatGridFilters(filterinfo, data);
                    
                    data.itemType = _itemType;
                    data.itemExternalIds = [_itemExternalId];

                    return data;
                },
                datafields: [
                    { name: "id", type: "number" },
                    { name: "status", type: "string" },
                    { name: "memo", type: "string" },
                    { name: "quantity", type: "number" },
                    { name: "equipTypeDescription", type: "string" },
                    { name: "issuedDate", type: "date" },
                    { name: "expirationDate", type: "date" },
                    { name: "returnedDate", type: "date" },
                    { name: "comments", type: "string" }

                ],
                loadcomplete: function (data) {

                }
            };

            var dataSource = dataModel.getDataAdapter(source);

            var columns = [
                { datafield: 'id', hidden: true },
                { text: 'Status', datafield: 'status', width: 80 },
                { text: 'Memo', datafield: 'memo', width: 200 },
                { text: 'Quantity', datafield: 'quantity', width: 80 },
                { text: 'Equipment Type', datafield: 'equipTypeDescription', width: 150 },
                { text: 'Issued Date', datafield: 'issuedDate', cellsformat: 'MM/dd/yyyy', width: 120 },
                { text: 'Expiration Date', datafield: 'expirationDate', cellsformat: 'MM/dd/yyyy', width: 120 },
                { text: 'Returned Date', datafield: 'returnedDate', cellsformat: 'MM/dd/yyyy', width: 120 },
                { text: 'Comments', datafield: 'comments', width: 400 }
            ];

            _$grid.jqxGrid({
                width: '100%',
                source: dataSource,
                altrows: true,
                sortable: true,
                autoheight: true,
                pageable: true,
                pagesize: 5,
                filterable: false,
                showfilterrow: false,
                virtualmode: true,
                columnsresize: false,
                columnsreorder: false,
                enablebrowserselection: true,
                columnsmenu: false,
                columns: columns,
                autoshowloadelement: true,
                showtoolbar: true,
                rendergridrows: function (obj) {
                    return obj.data;
                },
                ready: function () {
                    _gridCreated = true;
                }
            });
        }

        function _setup() {
            // Can bind this instance to the parent via $bindComponentTo prop.
            // Allows access in parent to methods,etc here. using ko standard component loading.
            if(params.$bindComponentTo) {
                if(ko.isObservable(params.$bindComponentTo)) {
                    params.$bindComponentTo(self);
                }
                else {
                    params.$bindComponentTo = self;
                }
            }
            
            // If this prop is not set on the component binding then whenever this component loads
            // it will invoke/load grid and messages. Setting this will allow manually loading grid/messages via 'viewmodel.getMessages(optionalUnitTypeParam)'
            // Works well with the $bindToComponent to make execution calls from parent component.
            if(params.executeOnLoad != false)
                self.getEquipmentIssued();
        }

        _setup();
    }
}

export default { viewModel: EquipmentIssuedViewModel, template: template }