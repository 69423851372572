//@ts-check
/// <reference path="../../../types/jquery.plugins.d.ts" />

import ko from 'knockout';
import dataModel from 'data-model';
import { isEmpty } from 'global-functions';
import template from './post-tractor-component.html';
import userProfile from 'user-profile';
import {getUserBoards} from '../../pages/new-external-board-page/new-external-board-page';

class PostTractorModel {
    constructor(data) {
        data = data.data;
        var self = this;
        const onSuccesAction = data.onSuccessAction || function(){};

        self.id = data.id;

        self.errors = ko.observableArray();

        self.isLoading = ko.observable(false);
        self.isAutoPosting = ko.observable(data.autopostToFreightboard || false);
        self.isFreightBoard = ko.observable(data.isFreightBoardEnabled || false);
        self.isPostToLoadBoard = ko.observable(data.postToLoadBoard || false);

        self.autoPostToFreightBoard = function (item) {

            var loadBoardPrams = {
                tractorId: self.id,
                postToFreightBoard: self.isAutoPosting() == true ? false : true
            };

            dataModel.ajaxRequest("tractor/AutoPostToLoadboard", "post", loadBoardPrams)
                .done(function (result, status, xhr) {
                    var autoPost = self.isAutoPosting() == true ? false : true;
                    self.isAutoPosting(autoPost);
                    onSuccesAction(ko.toJS(self));
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.responseJSON != null) {
                        var json = jqXHR.responseJSON;
                        if (json.message != null) {
                            var errors = json.message;
                            alert(errors);
                        }
                    }
                });


        };
        self.postToFreightBoard = function (obj) {
            self.errors.removeAll();

            if (self.isPostToLoadBoard()) {
                self.isLoading(true);
                dataModel.ajaxRequest("Loadboard/UnpostTractor/" + self.tractorId + "/" + userProfile.currentAgencyId(), "post")
                     .done(function (result, status, xhr) {
                         self.isPostToLoadBoard(result);
                         self.isLoading(false);
                         onSuccesAction(ko.toJS(self));
                     })
                     .fail(function (jqXHR, textStatus, errorThrown) {
                         if (jqXHR.responseJSON != null) {
                             var json = jqXHR.responseJSON;
                             if (json.message == "This tractor does not exist on the Freightboard") {
                                 var div1 = $(document.createElement("div"));
                                 div1.html("This tractor does not exist on the Freightboard. Would you like to create it now?");
                                 div1.dialog({
                                     title: "Post Truck To Freightboard",
                                     modal: true,
                                     buttons: {
                                         "Ok": function () {
                                             $(this).dialog("close");
                                             window.open("http://www.gwtlm.com/externalloadboard/postTrucks.aspx", "_blank");
                                         },
                                         Cancel: function () {
                                             $(this).dialog("close");
                                         }
                                     },
                                     close: function (event, ui) {
                                         div1.dialog("destroy");
                                     }
                                 });


                             }
                         }
                         self.isLoading(false);
                     });
            }
            else {

                var validationErrors = ko.validation.group(self);
                if (validationErrors().length > 0) {
                    validationErrors.showAllMessages();
                    return false;
                }

                self.isLoading(true);
                var item = ko.toJS(obj);

                var postingBoards = ko.utils.arrayMap(ko.utils.arrayFilter(item.brokerageBoards, function (item) { return item.checked == true; }), function (item) { return item.label; });

                var loadBoardPrams = {
                    tractorId: self.tractorId,
                    trailerType: item.trailerType,
                    team: item.team,
                    isOverDimensional: item.isOverDimensional,
                    twic: item.twic,
                    isFull: item.isFull,
                    isPartial: item.isPartial,
                    weightCapacity: item.weightCapacity,
                    length: item.length,
                    carbCompliant: item.carbCompliant,
                    canada: item.canada,
                    originLocation: item.originLocation,
                    destinationLocations: item.statesProvinces,
                    maxPickUpRadius: item.maxPickupRadius,
                    comment: item.comment,
                    availability: item.availability,
                    goAnywhere: item.goAnywhere,
                    postingBoards: postingBoards
                };


                dataModel.ajaxRequest("Loadboard/PostTractor", "post", loadBoardPrams)
                    .done(function (result, status, xhr) {
                        self.isPostToLoadBoard(result);
                        self.isLoading(false);
                        onSuccesAction(ko.toJS(self));
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        if (jqXHR.responseJSON != null) {
                            var json = jqXHR.responseJSON;
                            if (json.message == "This tractor does not exist on the Freightboard") {
                                var div1 = $(document.createElement("div"));
                                div1.html("This tractor does not exist on the Freightboard. Would you like to create it now?");
                                div1.dialog({
                                    title: "Post Truck To Freightboard",
                                    modal: true,
                                    buttons: {
                                        "Ok": function () {
                                            $(this).dialog("close");
                                            window.open("http://www.gwtlm.com/externalloadboard/postTrucks.aspx", "_blank");
                                        },
                                        Cancel: function () {
                                            $(this).dialog("close");
                                        }
                                    },
                                    close: function (event, ui) {
                                        div1.dialog("destroy");
                                    }
                                });
                            }
                            else {
                                self.errors.push(json.message);
                            }
                        }
                        else {
                            self.errors.push("GreatEDGE cannot currently communicate with the server.");
                        }
                        self.isLoading(false);
                    });
            }

        };


        self.team = ko.observable(data.team || false);
        self.isPartial = ko.observable(data.isPartial || false);
        self.isFull = ko.observable(data.isFull || false);

        self.originLocation = ko.observable(data.originLocation).extend({ required: true });
        self.destination = ko.observable(data.desiredDestination);
        self.maxPickupRadius = ko.observable(data.maxPickupRadius).extend({ required: true });

        self.goAnywhere = ko.observable(data.goAnywhere != null ? data.goAnywhere : true);
        self.comment = ko.observable(data.comment);
        self.statesProvinces = ko.observableArray(isEmpty(data.destinations) ? [] : data.destinations.split(";"))
            .extend({
                validation: {
                    validator: function (val, options) {
                        if (self.goAnywhere() == false) {
                            if (val.length > 0) {
                                return true;
                            }
                            return false;
                        }
                        return true;
                    },
                    message: 'Please add at least one desired destination'
                }
            });

        self.weightCapacity = ko.observable(data.weightCapacity);
        self.length = ko.observable(data.length);
        self.trailerType = ko.observable(data.trailerType).extend({ required: true });

        self.brokerageBoards = ko.observableArray();

        const loadBrokerageBoards = async (truckId = 0, postedExternally = false) => {
            const getBoardsForPostedTractor = async (id) => {
                return new Promise((resolve, reject) => {
                    dataModel.ajaxRequest("FreightBoard/GetTractorExternalBoards/" + id)
                    .done((response) => resolve(response))
                })
            }
    
            try {
                const postedBoards = await getBoardsForPostedTractor(truckId);
                const defaultBoards = await getUserBoards();
                
                self.brokerageBoards((postedBoards).map(x => {
                    if(postedExternally == false && defaultBoards) {
                        if((x.name || "").toUpperCase() == 'TRUCKSTOP') {
                            x.checked = defaultBoards.truckStop;
                        }
                        if((x.name || "").toUpperCase() == 'DAT') {
                            x.checked = defaultBoards.dat;
                        }
                    }
                    
                    return {
                        label: x.name,
                        checked: x.checked,
                        items: []
                    }
                }))
            }
            catch(err) {
                self.brokerageBoards([])
            }
        }
    
        loadBrokerageBoards(self.id, self.isPostToLoadBoard());

        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var availability = undefined;
        if (data.availableFrom != null && data.availableTo != null) {
            availability = { from: data.availableFrom, to: data.availableTo };
        }
        else {
            var toDate = new Date();
            toDate.setDate(today.getDate() + 3);
            availability = { from: today, to: toDate };
        }
        self.availability = ko.observable(availability).extend({ required: true });
        self.startDay = today;


        self.tractorId = data.tractorId;
        self.equipmentType = data.equipmentType;
        self.driverId = data.driverId;
        self.driverName = data.driverName != null ? data.driverName : "";

        self.phone = data.phone;
        self.email = data.email;
        self.canada = data.canada;
    }
}


export default {viewModel: PostTractorModel, template: template}