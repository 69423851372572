import TrackingBrokerageAssignmentModel from "./TrackingBrokerageAssignment";
import TrackingTrucklineAssignmentModel from "./TrackingTrucklineAssignment";
import { ITrackingModel, ValueObject } from "./types";

function TrackingModel(initData: ITrackingModel = {}) {
  return {
    trackingRequired: initData.trackingRequired ?? false,
    agencyId: initData.agencyId,
    orderId: initData.orderId,
    orderStatus: initData.orderStatus,
    agentEmail: initData.agentEmail,
    agentPhone: initData.agentPhone,
    authEmail: initData.authEmail,
    authName: initData.authName,
    authPhone: initData.authPhone,
    agentName: initData.agentName,
    customerEmails: initData.customerEmails,
    customerExternalId: initData.customerExternalId,
    customerName: initData.customerName,
    driver1Name: initData.driver1Name,
    driver1Phone: initData.driver1Phone,
    providerId: initData.providerId,
    startTracking: initData.startTracking,
    teamTracking: initData.teamTracking ?? false,
    trackingId: initData.trackingId,
    vendorId: initData.vendorId ?? 1,
    customerTrackingId: initData.customerTrackingId,
    notifyOnLocationUpdates: initData.notifyOnLocationUpdates ?? false,
    notifyOnPickupDelivered: initData.notifyOnPickupDelivered ?? false,
    locationUpdateNotifyInterval: initData.locationUpdateNotifyInterval ?? 1,
    startTrackingMins: initData.startTrackingMins ?? 60,
    trackingStatusId: initData.trackingStatusId,
    byPassTracking: initData.byPassTracking ?? false,
    byPassTrackingUserId: initData.byPassTrackingUserId,
    byPassTrackingUserName: initData.byPassTrackingUserName,
    sorTrackingId: initData.sorTrackingId,
    externalId: initData.externalId,
    carrierAssignment:
      initData.carrierAssignment ?? TrackingBrokerageAssignmentModel(),
    trucklineAssignment:
      initData.trucklineAssignment ?? TrackingTrucklineAssignmentModel(),
  };
}

export default TrackingModel;

// Only passing fields we want to validate in UI
export function getTrackingModelValidationErrors(
  data: ITrackingModel,
  isBrokered: boolean = false
) {
  let errors: ValueObject = {};
  const phoneChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  const isValidNumber = (charsAllowed = [], str = "", replaceWith = "") => {
    const execute = (chars, index) => {
      if (!charsAllowed) return chars;

      if (charsAllowed.length <= 0) return chars;

      if (index === str.length) return chars;
      const char =
        charsAllowed.indexOf(str[index]) === -1 ? replaceWith : str[index];

      return execute(chars + char, index + 1);
    };

    const result = execute("", 0);

    return result.length === 10;
  };

  if (data.byPassTracking) {
    return errors;
  }

  if (!data.agentEmail) {
    errors.agentEmail = `This field is required.`;
  }

  if (!data.agentPhone) {
    errors.agentPhone = `This field is required.`;
  } else if (isValidNumber(phoneChars, data.agentPhone) === false) {
    errors.agentPhone = `Please enter a valid phone number.`;
  }

  if (!data.authName) {
    errors.authName = `This field is required.`;
  }

  // Not required, but still check is valid.
  if (data.authPhone && isValidNumber(phoneChars, data.authPhone) === false) {
    errors.authPhone = `Agency phone number is not valid.`;
  }

  if (isBrokered) {

    if (!data.carrierAssignment.carrierContactEmail) {
      errors.carrierContactEmail = `This field is required.`;
    }
  } else {
    if (
      data.trucklineAssignment.hasELD &&
      !data.trucklineAssignment.assignedTractor
    ) {
      errors.assignedTractor = `A tractor must be assigned.`;
    } else if (!data.trucklineAssignment.hasELD) {
      if (!data.driver1Phone) {
        errors.driver1Phone = `Driver phone number is required.`;
      } else if (
        data.driver1Phone &&
        isValidNumber(phoneChars, data.driver1Phone) === false
      ) {
        errors.driver1Phone = `Driver phone number is not valid.`;
      }
    }
  }

  if (!data.startTracking && !data.startTrackingMins) {
    errors.startTracking = `This field is required.`;
  }

  if (!data.vendorId) {
    errors.vendorId = `This field is required.`;
  }

  if (!data.customerTrackingId) {
    // load number
    errors.customerTrackingId = `This field is required.`;
  }

  if (data.notifyOnLocationUpdates && !data.locationUpdateNotifyInterval) {
    errors.locationUpdateNotifyInterval = `This field is required.`;
  }

  errors.count = Object.keys(errors).length;

  return errors;
}
