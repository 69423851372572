import dataModel from "data-model";

export function cleanContactEmail(email = "") {
  let cleanEmail = email || "";
  if (cleanEmail.indexOf(";") !== -1) {
    cleanEmail = cleanEmail.split(";")[0];
  } else if (cleanEmail.indexOf(",") !== -1) {
    cleanEmail = cleanEmail.split(",")[0];
  } else if (email.indexOf("&") !== -1) {
    cleanEmail = cleanEmail.split("&")[0];
  }

  return cleanEmail ? cleanEmail.trim() : "";
}

export const getAgencyContactDetails = async () => {
  try {
    const response = await dataModel.ajaxRequest(
      "Agency/GetAgencyContactDetails",
      "GET"
    );
    return response;
  } catch (err) {
    return null;
  }
};

// export const cleanLMEOperationUserContact = async (username: string, userEmail: string) => {
//   const { email } = await getAgencyContactDetails();
//   const agentEmail = userEmail || cleanContactEmail(email);

//   return { authName: username, agentEmail };
// };
