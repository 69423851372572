
import './trailersPageStyles.css';
import dataModel from 'data-model';
import gridState from 'jqx.grid-state-component';
import gridStateUtils from 'jqx.grid-utils'
import {isEmpty,getQueryStringValueByName, datetimeUTC} from 'global-functions';
import userProfile from 'user-profile'
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";

// INSPECTION NOTE
var csaNoteModel = function (data) {
    var self = this;
    var note = data.note || {};
    var inspectionId = data.inspectionId || 0;
    var trailerDetailVM = data.trailerDetailVM || {};

    self.isEdit = ko.observable(data.note != undefined ? true : false);
    self.comment = ko.observable(note.comment || "").extend({ required: true });
    self.modelError = ko.observable();

}

// TRAILER NOTE
var trailerNoteModel = function (data) {
    var self = this;
    var note = data.note || {};
    var trailerId = data.trailerId || 0;
    var trailerDetailVM = data.trailerDetailVM || {};
    
    self.isEdit = ko.observable(data.note != undefined ? true : false);
    self.comment = ko.observable(note.comment || "").extend({ required: true });
    self.modelError = ko.observable();
    self.displayConfirmDelete = ko.observable(false);

}

// THE MAIN PAGE FOR THE MODAL (Trailer Details)
var trailerDetailsViewModel = function (trailerId, mainModel) {
    var self = this;
    self.trailerId = ko.observable(trailerId);
    self.mainModel = mainModel || {};

    self.isInit = ko.observable(false);

    // General Page ViewModel data
    self.trailerDetails = ko.observable();
    
    self.trailerNotes = ko.observableArray();
    self.displayTrailerNoteForm = ko.observable(false);
    self.trailerNoteViewModel = ko.observable();
    self.trailerNoteNotificationMsg = ko.observable();
    self.displayRoadsideDetailsContainer = ko.observable(false);
    //self.roadsideDetailsViewModel = ko.observable();
    self.csaRPH = ko.observableArray(); // CSA Roadside Point History

    self.trailerNotesIsLoading = ko.observable(true); // Display loaders for initial load
    self.csaPanelIsLoading = ko.observable(true);
    self.trailerDetailsIsLoading = ko.observable(true);

    self.notesGrid = null; // This gets initialized and set in the load note grid method
    self.csaGrid = null; // This gets initialized and set in the load csa grid method

    self.trailerExId = ko.observable();

    self.trailerTemp = function() {
        var _data = self.trailerDetails(),
            min = _data.minTemp && _data.minTemp > 0 ? _data.minTemp : "",
            max = _data.maxTemp && _data.maxTemp > 0 ? " - " + _data.maxTemp : "";

        return min + max;
    }

    self.viewTrailerNote = function (noteData) {
        self.displayTrailerNoteForm(true);

        if (noteData != undefined) {
            var data = {
                note: noteData,
                trailerDetailVM: self,
                trailerId: self.trailerId()
            };

            var tNote = new trailerNoteModel(data);
            self.trailerNoteViewModel(tNote);
        }
    }

    self.closeViewNote = function () {
        if (self.displayTrailerNoteForm() == false) { return; }

        self.displayTrailerNoteForm(false);
        self.trailerNoteViewModel(null);
    }


    self.refreshNotesGrid = function () {
        self.notesGrid.jqxGrid("updatebounddata");
    }

    self.refreshCSAGrid = function () {
        self.csaGrid.jqxGrid("updatebounddata");
    }

    self.formatDate = function (date) {
        if (date != undefined) {
            var d = datetimeUTC(date);
            
            return d.isValid() ? d.format('MM/DD/YYYY') : "N/A";
        }
    }

    // This is fired when user selects an option in the
    // trailer details inspection DDL
    self.inspectionDetailsDDLSelected = function (viewModel, event) {
        var id = event.target.value;
        window.open("InspectionEntry/" + id, "_blank");
    }

    // Trailer Notes Grid
    self.loadNotesGrid = function () {
        self.notesGrid = $("#trailerNotesGrid");

        var source = {
            url: "Trailer/GetTrailerNotes",
            datatype: "json",
            root: "Records",
            formatdata: function (data) {
                var filterinfo = self.notesGrid.jqxGrid('getfilterinformation');
                data = gridStateUtils.formatGridFilters(filterinfo, data);

                data.trailerId = self.trailerId();
                return data;
            },
            beforeprocessing: function (data) {
                source.totalrecords = data.totalCount; // Sets the total count for pagination
            },
            datafields: [
                { name: "id", type: "number" },
                { name: "trailerId", type: "number" },
                { name: "comment", type: "string" },
            ],
            loadComplete: function () {
                self.trailerNotesIsLoading(false);
            }
        };

        var dataSource = dataModel.getDataAdapter(source);

        var columns = [
            {
                text: '', width: 80, filterable: false, columnType: "button", buttonclick: function (row) {
                    var datarow = self.notesGrid.jqxGrid('getrowdata', row);
                    //self.editTrailerNote(datarow);
                    self.viewTrailerNote(datarow);
                },
                cellsrenderer: function () {
                    return "View";
                }
            },
            { datafield: 'id', hidden: true },
            { datafield: 'trailerId', hidden: true },
            { text: 'Comment', datafield: 'comment' },
        ];

        self.notesGrid.jqxGrid({
            width: '100%',
            source: dataSource,
            altrows: true,
            sortable: false,
            autoheight: true,
            pageable: true,
            pagesize: 5,
            filterable: false,
            showfilterrow: false,
            virtualmode: true,
            columnsresize: false,
            columnsreorder: false,
            enablebrowserselection: true,
            columnsmenu: false,
            columns: columns,
            autoshowloadelement: false,
            //rowdetails: true,
            showtoolbar: true,
            rendertoolbar: function (toolbar) {
                var noteToolbar = $("#trailerNoteGridToolbar");
                toolbar.append(noteToolbar);



            },
            rendergridrows: function (obj) {
                return obj.data;
            }
        });
    }

    // CSA ROADSIDE DETAILS ==========================================================================
    self.roadsideExId = ko.observable();
    self.proofOfRepair = ko.observable(false);
    self.logReceived = ko.observable(false);
    self.originalInspection = ko.observable(false);
    self.dvirReceived = ko.observable(false);
    self.cleanInspection = ko.observable(false);
    self.fsmIntervention = ko.observable(false);
    self.fsmInterventionCompletionDate = ko.observable("To Be Determined");
    self.fsmStatus = ko.observable();
    self.startTime = ko.observable();
    self.endTime = ko.observable();

    self.roadsideNoteGrid = null;
    self.displayCSANoteForm = ko.observable(false);
    self.csaNoteViewModel = ko.observable();
    self.csaNoteNotificationMsg = ko.observable();

    var inspectionId = null;

    self.refreshCSANotesGrid = function () {
        self.roadsideNoteGrid.jqxGrid("updatebounddata");
    }

    // Trailer Roadside CSA Details
    self.displayTrailerRoadsideDetails = function (csaData) {
        if (csaData != undefined) {
            self.csaPanelIsLoading(true);
            self.displayRoadsideDetailsContainer(true);

            inspectionId = csaData.id;

            dataModel.ajaxRequest("Trailer/GetInspectionDetailsForTrailer/" + csaData.id, "GET").done(function (response) {
                if (response != undefined) {
                    // Format dates
                    if (response.fsmInterventionCompletionDate != null) {
                        var m = dayjs(response.fsmInterventionCompletionDate);
                        response.fsmInterventionCompletionDate = m.format('LLL');
                    }

                    self.roadsideExId(response.externalId);
                    self.proofOfRepair(response.proofOfRepair);
                    self.logReceived(response.logReceived);
                    self.originalInspection(response.originalInspection);
                    self.dvirReceived(response.dvirReceived);
                    self.cleanInspection(response.cleanInspection);
                    self.fsmIntervention(response.fsmIntervention);
                    self.fsmStatus(response.fsmStatus);
                    self.startTime(response.startTime);
                    self.endTime(response.endTime);

                    self.loadViolationGrid(csaData.id);
                    self.loadCSANotesGrid(csaData.id);
                }

            }).fail(function (error) {
                self.csaPanelIsLoading(false);
            });
        }
    }


    self.viewCSANote = function (noteData) {
        self.displayCSANoteForm(true);

        if (noteData != undefined) {
            var data = {
                note: noteData,
                trailerDetailVM: self,
                inspectionId: inspectionId
            };

            var csaNote = new csaNoteModel(data);
            self.csaNoteViewModel(csaNote);
        }
    }

    // CSA Notes Grid
    self.loadCSANotesGrid = function (inspectionId) {
        self.roadsideNoteGrid = $("#csaNotesGrid");

        var source = {
            url: "Trailer/GetTrailerInspectionNotesForGrid",
            datatype: "json",
            formatdata: function (data) {
                var filterinfo = self.roadsideNoteGrid.jqxGrid('getfilterinformation');
                data = gridStateUtils.formatGridFilters(filterinfo, data);

                data.inspectionId = inspectionId;
                return data;
            },
            beforeprocessing: function (data) {
                source.totalrecords = data.totalCount; // Sets the total count for pagination
            },
            datafields: [
                { name: "id", type: "number" },
                { name: "comment", type: "string" },
                { name: "enteredBy", type: "string" },
                { name: "enteredById", type: "string" },
                { name: "createdDate", type: "date" },
            ],
        };

        var dataSource = dataModel.getDataAdapter(source);

        var columns = [
            {
                text: '', width: 80, filterable: false, columnType: "button", buttonclick: function (row) {
                    var datarow = self.roadsideNoteGrid.jqxGrid('getrowdata', row);
                   //self.editCSANote(datarow);
                    self.viewCSANote(datarow);
                },
                cellsrenderer: function () {
                    return "View";
                }
            },
            { datafield: 'id', hidden: true },
            { text: 'Comment', datafield: 'comment', width: 500 },
            { text: 'Entered By', datafield: 'enteredBy', width: 200 },
            { text: 'Created Date', datafield: 'createdDate', cellsformat: 'MM/dd/yyyy - HH:ss' },
        ];

        self.roadsideNoteGrid.jqxGrid({
            width: '100%',
            source: dataSource,
            altrows: true,
            sortable: false,
            autoheight: true,
            pageable: true,
            pagesize: 5,
            filterable: false,
            showfilterrow: false,
            virtualmode: false,
            columnsresize: false,
            columnsreorder: false,
            enablebrowserselection: true,
            columnsmenu: false,
            columns: columns,
            autoshowloadelement: false,
            //rowdetails: true,
            showtoolbar: true,
            rendertoolbar: function (toolbar) {
                var noteToolbar = $("#csaNoteGridToolbar");
                toolbar.append(noteToolbar);
            }
        });
    }


    // CSA Roadside Violations Grid
    self.loadViolationGrid = function (inspectionId) {
        self.violationGrid = $("#inspectionViolationGrid");

        var source = {
            url: "Trailer/GetTrailerInspectionViolations",
            datatype: "json",
            formatdata: function (data) {
                var filterinfo = self.violationGrid.jqxGrid('getfilterinformation');
                data = gridStateUtils.formatGridFilters(filterinfo, data);

                data.trailerId = self.trailerId();
                data.inspectionId = inspectionId;
                return data;
            },
            beforeprocessing: function (data) {

            },
            loadComplete: function () {
                self.csaPanelIsLoading(false);
            },
            loadError: function(jqXHR, status, error) {
                self.csaPanelIsLoading(false);
            },
            datafields: [
                { name: "violationId", type: "number" },
                { name: "unitType", type: "string" },
                { name: "citation", type: "string" },
                { name: "basic", type: "string" },
                { name: "section", type: "string" },
                { name: "description", type: "string" },
                { name: "oos", type: "boolean" },
                { name: "severity", type: "number" },
                { name: "oosWeight", type: "number" },
                { name: "timeWeight", type: "number" },
                { name: "points", type: "number" },
                { name: "assetNumber", type: "string" },
                { name: "licenseNumber", type: "string" },
                { name: "licenseState", type: "string" },
                { name: "vin", type: "string" },
            ]
        };

        var dataSource = dataModel.getDataAdapter(source);

        var columns = [
            { datafield: 'violationId', hidden: true },
            { text: 'Unit Type', datafield: 'unitType', width: 125 },
            { text: 'Citation', datafield: 'citation', width: 150 },
            { text: 'Basic', datafield: 'basic', width: 200 },
            { text: 'Section', datafield: 'section', width: 150 },
            {
                text: 'Description', datafield: 'description', width: 500,
            },
            {
                text: 'OOS', datafield: 'oos', width: 80,
                cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                    if (defaultHTML != null) {
                        var cell = $(defaultHTML);
                        if (value == true) {
                            cell.html("<input type='checkbox' checked='checked' disabled='disabled' />");
                        } else {
                            cell.html("<input type='checkbox' disabled='disabled' />");
                        }

                        return cell[0].outerHTML;
                    }
                    return defaultHTML;
                }
            },
            { text: 'Severity', datafield: 'severity', width: 100 },
            { text: 'OOS Weight', datafield: 'oosWeight', width: 100 },
            { text: 'Time Weight', datafield: 'timeWeight', width: 100 },
            { text: 'Points', datafield: 'points', width: 100 },
            { text: 'Asset Number', datafield: 'assetNumber', width: 100 },
            { text: 'License Number', datafield: 'licenseNumber', width: 150 },
            { text: 'License State', datafield: 'licenseState', width: 100 },
            { text: 'VIN', datafield: 'vin', width: 250 },
        ];

        self.violationGrid.jqxGrid({
            width: '100%',
            source: dataSource,
            altrows: true,
            sortable: false,
            autoheight: true,
            pageable: true,
            pagesize: 5,
            filterable: false,
            showfilterrow: false,
            columnsresize: false,
            columnsreorder: false,
            enablebrowserselection: true,
            columnsmenu: false,
            columns: columns,
            autoshowloadelement: false,
            enableellipsis: false,
            autorowheight: true
        });
    }

    // CSA Roadside Information Grid
    self.loadCSAGrid = function () {
        self.csaGrid = $("#csaGrid");

        var source = {
            url: "Trailer/GetTrailerInspections",
            datatype: "json",
            formatdata: function (data) {
                var filterinfo = self.csaGrid.jqxGrid('getfilterinformation');
                data = gridStateUtils.formatGridFilters(filterinfo, data);

                data.trailerId = self.trailerId();
                return data;
            },
            datafields: [
                { name: "id", type: "number" },
                { name: "postedDate", type: "date" },
                { name: "inspectionDate", type: "date" },
                { name: "inspection", type: "string" },
                { name: "inspectionState", type: "string" },
                { name: "inspectionLevel", type: "number" },
                { name: "oos", type: "boolean" },
                { name: "totalPoints", type: "string" },
                { name: "completed", type: "string" },
            ],
            loadComplete: function () {
                self.csaPanelIsLoading(false);
            }
        };

        var dataSource = dataModel.getDataAdapter(source);

        var columns = [
            {
                text: '', width: 80, filterable: false, columnType: "button", buttonclick: function (row) {
                    var datarow = self.csaGrid.jqxGrid('getrowdata', row);
                    self.displayTrailerRoadsideDetails(datarow);
                },
                cellsrenderer: function () {
                    return "View";
                }
            },
            { datafield: 'id', hidden: true },
            {
                text: 'Posted Date', datafield: 'postedDate', width: 125,
                cellsformat: 'MM/dd/yyyy'
            },
            {
                text: 'Inspection Date', datafield: 'inspectionDate', width: 125,
                cellsformat: 'MM/dd/yyyy'
            },
            {
                text: 'Roadside', datafield: 'inspection', width: 125,
                cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                    if (defaultHTML != null) {
                        var cell = $(defaultHTML);
                        cell.html("<a href='/RoadsideEntry?id=" + rowData.id + "'" + "target='_blank'  style='color:blue;'>" + value + "</a>");
                        return cell[0].outerHTML;
                    }
                    return defaultHTML;
                }
            },
            { text: 'Inspection State', datafield: 'inspectionState', width: 125, },
            { text: 'Inspection Level', datafield: 'inspectionLevel', width: 125,},
            {
                text: 'OOS', datafield: 'oos', width: 125,
                cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                    if (defaultHTML != null) {
                        var cell = $(defaultHTML);
                        if (value == true) {
                            cell.html("<input type='checkbox' checked='checked' disabled='disabled' />");
                        } else {
                            cell.html("<input type='checkbox' disabled='disabled' />");
                        }
                        
                        return cell[0].outerHTML;
                    }
                    return defaultHTML;
                }
            },
            { text: 'Total Points', datafield: 'totalPoints', width: 125,},
            { text: 'Completed', datafield: 'completed', width: 200,},
        ];

        self.csaGrid.jqxGrid({
            width: '100%',
            source: dataSource,
            altrows: true,
            sortable: false,
            autoheight: true,
            pageable: true,
            pagesize: 5,
            filterable: false,
            showfilterrow: false,
            columnsresize: false,
            columnsreorder: false,
            enablebrowserselection: true,
            columnsmenu: false,
            columns: columns,
            autoshowloadelement: false,
        });
    }

    self.init = function () {
        if (self.trailerId() != undefined && self.trailerId() > 0) {
            //mainModel.isLoading(true);

            // Get Trailer Details
            dataModel.ajaxRequest("Trailer/GetTrailerDetails/" + self.trailerId()).done(function (response) {
                if (response != undefined) {
                    // Format tag date
                    if (response.trailer.tagExpirationDate != null) {
                        response.trailer.tagExpirationDate = self.formatDate(response.trailer.tagExpirationDate)
                    }

                    if (response.trailer.inspectionDate != null) {
                        response.trailer.inspectionDate = self.formatDate(response.trailer.inspectionDate)
                    }
                        
                    
                    self.trailerDetails(response.trailer);
                    
                    // Format our csaRPH Vehicle Maintentance Points if any are null
                    // since the result property is nullable we want to display a 0 instead
                    if (response.csaRPH != undefined && response.csaRPH.length > 0) {
                        for (var i = 0; i < response.csaRPH.length; i++) {
                            if (response.csaRPH[i].vehicleMaintenancePoints == null) {
                                response.csaRPH[i].vehicleMaintenancePoints = 0;
                            }
                        }
                    }

                    self.csaRPH(response.csaRPH); // CSA Roadside Point History
                    

                    self.trailerExId(response.trailer.trailerCode);
                        
                    self.trailerDetailsIsLoading(false);
                }
            }).then(function () {
                self.loadNotesGrid();   // Load grid notes
                self.loadCSAGrid();     // Load Trailer CSA Grid

                self.isInit(true);
                //mainModel.isLoading(false);
            }).fail(function (error) {
                //mainModel.isLoading(false);
            });
        }
    }

    self.init();
}

class TrailersPageViewModel {
    constructor() {
        var self = this;

        self.isLoading = ko.observable(false);
        var lastUpdated = Date.now();
        var updateGrid = false;
        var isInitLoad = true;
        var grid = $("#trailerGrid");

        
        self.trailerModal = ko.observable();

        self.clearSelectedSavedSearchDDL = ko.observable(false);
        self.refreshSavedSearchDDL = ko.observable("");

        self.handleOnSelected = (state) => {
            //if user has saved filters
            if (isEmpty(state) == false) {
                
                self.trailerGridState = state.grid;
            }

            if(isInitLoad) {
                self.buildGrid();
                self.loadTrailers();
            }
            else {
                self.loadGridState(grid, state.grid);
            }
                

            isInitLoad = false;
        }

        self.displayTrailerDetails = function (trailerId) {
            if (trailerId != undefined) {
                var trailerVM = new trailerDetailsViewModel(trailerId, self);
                self.trailerModal(trailerVM);
            }
        }


        self.loadGridState = function (grid, state) {
            if (state != null) {
                var gridState = grid.jqxGrid('getstate');
                state.selectedrowindex = -1;
                state.selectedrowindexes = [];
                state.pagenum = 0;
                state.height = gridState.height;
                state.pagesize = gridState.pagesize;
                state.pagesizeoptions = gridState.pagesizeoptions;

                grid.jqxGrid('loadstate', state);
            }
            else {
                grid.jqxGrid('applyfilters');
            }
        };

        
        self.trailerGridState = null;


        var initFilters = function () {
            // check if we have query param
            var trailerId = getQueryStringValueByName('trailerId');

            if (trailerId) {
                var filtergroup = new $.jqx.filter();

                var trailerfilter = filtergroup.createfilter('stringfilter', trailerId, "equal");
                filtergroup.addfilter(1, trailerfilter);

                grid.jqxGrid('addfilter', 'trailerCode', filtergroup, false);
            }
            else {
                var filtergroup1 = new $.jqx.filter();

                var filter1 = filtergroup1.createfilter('stringfilter', "Active", "equal");
                filtergroup1.addfilter(1, filter1);

                grid.jqxGrid('addfilter', 'status', filtergroup1, false);
            }

            grid.jqxGrid('refreshfilterrow');
            //grid.jqxGrid('applyfilters');
        };

        
        // THE GRID
        self.loadTrailers = function () {
            
            var source = {
                url: "Trailer/GetTrailersForGrid",
                datatype: "json",
                filter: function (filters, recordsArray) {
                    if (isInitLoad == false) {
                        grid.jqxGrid('updatebounddata', 'filter');
                    }
                    isInitLoad = false;
                },
                sort: function (column, direction) {
                    grid.jqxGrid('updatebounddata', 'sort');
                },
                beforeprocessing: function (data) {
                    source.totalrecords = data.totalCount;
                },
                formatdata: function (data) {

                    var filterinfo = grid.jqxGrid('getfilterinformation');
                    data = gridStateUtils.formatGridFilters(filterinfo, data);

                    if (updateGrid) {
                        lastUpdated = Date.now()
                    }

                    if (isInitLoad) {
                        data.status = "Active";
                        isInitLoad = false;
                    }

                    data.lastUpdated = lastUpdated;
                    return data;
                },
                datafields: [
                    { name: "trailerId", type: "number"},
                    { name: "trailerCode", type: "string" },
                    { name: "status", type: "string" },
                    { name: "modelYear", type: "number" },
                    { name: "make", type: "string" },
                    { name: "trailerGroup", type: "string" },
                    { name: "trailerType", type: "string" },
                    { name: "owner", type: "string" },
                    { name: "rating", type: "number" },
                ],

                loadComplete: function (data) {
                    //isInitLoad = false;
                },
            };

            var dataSource = dataModel.getDataAdapter(source);

            grid.jqxGrid({ source: dataSource });

            //grid.on("bindingcomplete", function (event) {
            //    if (!isInitLoad) {
            //        if (updateGrid) {
            //            updateGrid = false;
            //        };
            //    }
            //});
        };

        self.buildGrid = function () {
            var columns = [
                {
                    text: '',
                    width: 100,
                    filterable: false,
                    hideable: false,
                    pinned: true,
                    sortable: false,
                    columnType: "button", buttonclick: function (row) {
                        var datarow = grid.jqxGrid('getrowdata', row);
                        self.displayTrailerDetails(datarow.trailerId);
                    },
                    cellsrenderer: function () {
                        return "Details";
                    }
                },
                { text: 'Trailer Code', datafield: 'trailerCode', width: 200, },
                { text: 'Model Year', datafield: 'modelYear', width: 150 },
                { text: 'Make', datafield: 'make', width: 150 },
                { text: 'Group', datafield: 'trailerGroup', width: 150 },
                { text: 'Status', datafield: 'status', filtertype: "checkedlist", filtercondition: "OR", filteritems: ["Active", "Inactive"], width: 150 },
                { text: 'Owner', datafield: 'owner', width: '35%' },
                {
                    text: "Rating", datafield: "rating", width: 140, filtertype: "list",
                    cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                        var counter = 0;
                        if (defaultHTML != null) {
                            var pk = grid.jqxGrid('getcellvalue', row, "trailerId");
                            var fieldset = $("<fieldset>");
                            fieldset.addClass("star-rating");
                            fieldset.attr('id', 'jqxRating_' + counter + pk);

                            for (var i = 5; i > 0; i--) {
                                var id = 'jqxRating_' + counter + pk + i;
                                var input = $("<input>");
                                input.attr({
                                    'type': 'radio',
                                    'class': 'star' + i,
                                    'name': 'rating',
                                    'value': i,
                                });
                                input.appendTo(fieldset);
                                var label = $("<label>").text(i);
                                if (value == i) {
                                    label.attr({ for: 'star' + i })
                                }
                                label.insertAfter(input);
                                $(document).off('click', '#jqxRating_' + counter + pk).on('click', '#jqxRating_' + counter + pk, function (event) {
                                    var newRating;
                                    var previousRating;
                                    if (event.target.tagName == "LABEL") {
                                        newRating = event.target.previousSibling.value;
                                        $(event.target.parentElement.children).each(function (i) {
                                            if (this.tagName == "LABEL") {
                                                if (this.attributes.length > 0) {
                                                    if (this.attributes.for.value != null) {
                                                        previousRating = this.attributes.for.value.slice(-1);
                                                    };
                                                    if (previousRating != newRating) {
                                                        $(this).removeAttr("for");
                                                    };
                                                };
                                            };
                                        });
                                        if (previousRating != newRating) {
                                            var inputClass = event.target.previousSibling.attributes.getNamedItem('class').value;
                                            $(event.target).attr('for', inputClass);
                                            var id = event.target.parentElement.id.replace('jqxRating_' + counter, '');
                                            var params = { objectId: id, value: newRating, agencyId: userProfile.currentAgencyId(), favTable: 7 };
                                            dataModel.ajaxRequest("Rating", "post", params)
                                                .done(function (data, textStatus, jqXHR) {
                                                    //updateGrid = true;
                                                    ratingUpdated = true;
                                                    //grid.jqxGrid("refresh");
                                                })
                                                .fail(function (error, msg, d) {
                                                    self.isLoading(false);
                                                });
                                        };
                                    };
                                });
                            };
                            return fieldset[0].outerHTML;
                        }
                        counter++;
                        return defaultHTML;
                    },
                    filteritems: [{ value: 5, label: "5", html: "<img src='../Content/Images/5star.png' />" },
                    { value: 4, label: "4", html: "<img src='../Content/Images/4star.png' />" },
                    { value: 3, label: "3", html: "<img src='../Content/Images/3star.png' />" },
                    { value: 2, label: "2", html: "<img src='../Content/Images/2star.png' />" },
                    { value: 1, label: "1", html: "<img src='../Content/Images/1star.png' />" }],
                    createfilterwidget: function (column, columnElement, widget) {
                        widget.jqxDropDownList({
                            dropDownWidth: 140,
                            autoDropDownHeight: true,
                            enableBrowserBoundsDetection: true,
                            renderer: function (index, label, value) {
                                if (value != "") {
                                    return "<span style='margin-bottom:1px'><img src='../Content/Images/" + value + "star.png' /></span>";
                                }
                                else {
                                    return "Any";
                                }
                            }
                        })
                    }

                }
            ];

            
            var pxgridheight = screen.height - 355;
            var gridRows = 10;

            if (screen.height >= 800) {
                gridRows = 20;
                pxgridheight = 640;
            }
           

            grid.jqxGrid({
                width: '99%',
                altrows: true,
                sortable: true,
                height: pxgridheight + "px",//window.screen.height - 180 + "px",
                //autoheight:true,
                pageable: true,
                pagesize: gridRows,
                filterable: true,
                showfilterrow: true,
                virtualmode: true,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                columns: columns,
                showtoolbar: true,
                rendertoolbar: function (toolbar) {
                    var vm1 = new gridState.viewModel('trailers'),
                        tmpl1 = gridState.template;
                        const $grid = $("#trailerGrid");

                    vm1.clearFilters = function () {
                        $("#trailerGrid").jqxGrid("clearfilters");
                        self.clearSelectedSavedSearchDDL(true)
                    };
                        
                    vm1.refresh = function () {
                        updateGrid = true;
                        grid.jqxGrid("updatebounddata", "data"); 
                    };

                    vm1.setDefaultSearchOverride = async () => {
                        const savedSearches = await loadSavedSearches($grid.attr("id"));
                        const filters = {
                            isDefault: true
                        }
        
                        vm1.loadSaveSearchModal(
                            $grid,
                            (val) => {
                                if(val && val.searchname) {
                                    self.refreshSavedSearchDDL(val.searchname)
                                }
                            },
                            savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                            filters,
                            true
                        );
                    }

                    vm1.actions.push("Save Search");

                    vm1.loadSaveSearchModalOverride = async () => {
                        const savedSearches = await loadSavedSearches($grid.attr("id"));
                        const filters = {
                            isDefault: false
                        }
        
                        vm1.loadSaveSearchModal(
                            $grid,
                            (val) => {
                                if(val && val.searchname) {
                                    self.refreshSavedSearchDDL(val.searchname)
                                }
                            },
                            savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                            filters,
                            true
                        );
                    }

                    const $trailerToolbar = $('#trailersGridToolbar');
                    toolbar.append($trailerToolbar);

                    var tdGridAction = $trailerToolbar.find("#trailersActionList");
                    tdGridAction.append(tmpl1);
                    
                    ko.applyBindingsToDescendants(vm1, tdGridAction[0]);
                },
                rendergridrows: function (obj) {
                    return obj.data;
                },
                ready: function () {
                    initFilters();
                    self.loadGridState(grid, self.trailerGridState);
                }
            });
        }
    }
}

import template from './trailers-page.html';
export default { viewModel: TrailersPageViewModel, template: template }