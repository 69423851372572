import dataModel from 'data-model';
import gridStateModel from "jqx.grid-state-component";
import gridUtils from "jqx.grid-utils";
import { modifyDate, Base64 } from 'global-functions';
import { useState } from 'data-store';
import {useDispatch } from 'ko-data-store';
import { getUserFilters, addUserFilters } from 'dataStore-actions/userFilters';
import { isLoading } from 'dataStore-actions/appUI';
import { showmessage } from 'show-dialog-methods';
import userProfile from 'user-profile';
import logger from 'logger';
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";


const sendCheckCodeViewEmailNotificationAsync = async (username) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Wires/CCVNotification", "POST", username, true)
        .done(() => resolve(true))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred processing request.`));
    });
}

const checkCodeSecurityUtility = function(
    storageKey = "ge-ccv"
) {

    const dateOnlyAsTimestamp = (timestamp = Date.now()) => {
        const date = new Date(timestamp);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        
        return date.getTime();
    }

    const setCheckCodeViewCount = async (username) => {
        determineReset();
        const {timestamp, userCounts, sentEmails} = getCheckCodeViews();
        let addUsernameToEmailList = false;

        userCounts[username] = (userCounts[username] || 0) + 1;
        
        if(userCounts[username] >= 5 && (sentEmails || []).some(x => x === username) == false) {
            addUsernameToEmailList = await sendEmailNotificationAsync(username);
        }

        setCheckCodeStorageData({
            timestamp,
            userCounts,
            sentEmails: addUsernameToEmailList ? [...sentEmails, username] : sentEmails
        })

        return userCounts[username];
    }

    const getCheckCodeViewCount = (username) => {
        const {userCounts} = getCheckCodeViews();

        return userCounts[username] || 0;
    }


    const getCheckCodeViews = () => {
        try {       
            return JSON.parse(Base64.decode(window.localStorage[storageKey])) || {};
        } catch (e) {
            return {};
        }
    }

    const setCheckCodeStorageData = ({timestamp = Date.now(), userCounts = {}, sentEmails = []} = {}) => {
        try {
            let storeString = Base64.encode(JSON.stringify({timestamp, userCounts, sentEmails}));   
            window.localStorage.setItem(storageKey, storeString);

            return true;
        } catch (e) {
            console.error("Error occurred storing ccv data.");
            return false;
        }
    }

    const sendEmailNotificationAsync = async(username) => {
        try {
            await sendCheckCodeViewEmailNotificationAsync(username);
            return true;
        }
        catch(err) {
            console.error(err);
            return false;
        }
    }

    const determineReset = () => {
        const {timestamp} = getCheckCodeViews();
        
        if(timestamp > 0 == false || dateOnlyAsTimestamp() > dateOnlyAsTimestamp(timestamp)) {
            window.localStorage.removeItem(storageKey);
            setCheckCodeStorageData();
        }
    }

    determineReset();

    return {
        getCheckCodeViewCount,
        setCheckCodeViewCount
    }
}

const fetchChargeCodeAsync = (id) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Wires/CheckCode/"+id, "GET").done((response) => resolve(response))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred processing request.`));
    })
}


const wiresPageViewModel = function() {
    const vm = this;
    const dispatch = useDispatch();
    
    const gridId = "wires-page-grid";
    const checkCodeSecurity = checkCodeSecurityUtility();
    
    const date = modifyDate(new Date());
    
    vm.transactionDateSelectOptions = ko.observableArray([
        { label: `Current Day`, value: date.addDays(0) }, 
        { label: `Previous Day`, value: date.addDays(-1) }, 
        { label: `Last 7 Days`, value: date.addDays(-7) }, 
        { label: `Last 30 Days`, value: date.addDays(-30) }, 
        { label: `Last 2 Months`, value: date.addMonths(-1) }, 
        { label: `Last 3 Months`, value: date.addMonths(-3) }, 
        { label: `Last 6 Months`, value: date.addMonths(-6) }, 
        { label: `Last 12 Months`, value: date.addMonths(-12) }, 

        // Uncomment this to get a large listing to play with... 
        //{ label: `Awhile Back`, value: date.addMonths(-72) }, 
    ]);

    const resetTransactionDate = () => {
        return vm.transactionDateSelectOptions().find(x => x.label == 'Last 30 Days');
    }

    const [additionalFilters, setAdditionalFilters] = useState({transactionDateStart: resetTransactionDate().label});
    const [gridProcessing, setGridProcessing] = useState(true);

    let isInit = false
    vm.selectedTransactionDate = ko.observable(resetTransactionDate());
    vm.clearSelectedSavedSearchDDL = ko.observable(false);
    vm.refreshSavedSearchDDL = ko.observable("");
    vm.state = null;
    vm.handleOnSelected = (state) => {
        vm.state = state;
        const {transactionDateStart} = state.additionalFilters || {};
        let $grid = $("#wires-page-grid");
        

        if(isInit === false) {
            const { dataAdapter } = buildGrid({
                $grid: $grid, 
                columns: gridColumns($grid),
                source: gridSource($grid, additionalFilters),
                additionalFilters,
                onClearFilterFn: handleClearFilters
            });
            $grid.jqxGrid({source: dataAdapter});
        }
        else {
            gridUtils.applyGridState($grid.attr('id'), state.grid);

        }
        
        var selectedOption = findDateSelectionFromOptions(transactionDateStart);
        if(selectedOption &&  selectedOption.label != transactionDateStart) {
            vm.selectedTransactionDate(selectedOption);
        }
    }
    vm.selectedTransactionDate.subscribe(({label}) => {
        setAdditionalFilters({transactionDateStart: label})
        if(gridProcessing() == false) {
            refreshGrid();
        }
    })

    const refreshGrid = () => {
        $('#'+gridId).jqxGrid('updatebounddata', 'data');
    }

    const buildGrid = ({
        $grid, 
        columns,
        source,
        additionalFilters,
        onClearFilterFn
    }) => {

        const dataAdapter = dataModel.getDataAdapter(source);

        $grid.jqxGrid({
            theme: 'GWTMDark',
            width: "100%",
            altrows: true,
            sortable: true,
            pageable: true,
            pagesize: 20,
            columnsreorder: true,
            columnsmenu: false,
            columnsresize: true,
            enablebrowserselection: true,
            height: 600,
            autoheight: false,
            filterable: true,
            showfilterrow: true,
            showtoolbar: true,
            columns: columns,
            virtualmode: true,
            rendertoolbar: (toolbar) => {
                const gridStateVM = new gridStateModel.viewModel();
                gridStateVM.pageName = "WiresPage";
  
                const $template = gridStateModel.template;
  
                gridStateVM.clearFilters = () => {
                    onClearFilterFn($grid);
                }

                let $grid = $("#wires-page-grid");
                gridStateVM.actions.push("Save Search");
                
                gridStateVM.setDefaultSearchOverride = async () => {
                    const savedSearches = await loadSavedSearches($grid.attr("id"));
                    const filters = {
                        ...additionalFilters(), 
                        isDefault: true
                    }

                    gridStateVM.loadSaveSearchModal(
                        $grid,
                        (val) => {
                            if(val && val.searchname) {
                                vm.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
                }

                gridStateVM.loadSaveSearchModalOverride = async () => {
                    const savedSearches = await loadSavedSearches($grid.attr("id"));
                    const filters = {
                        ...additionalFilters(),
                        isDefault: false
                    }

                    gridStateVM.loadSaveSearchModal(
                        $grid,
                        (val) => {
                            if(val && val.searchname) {
                                vm.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
                    
                }

                const $actionList = $("#wiresGridAction");
                $actionList.append($template);

                toolbar.append($actionList);

                const $wiresGridToolbar = $("#wiresGridToolbar");
                toolbar.append($wiresGridToolbar);

                ko.applyBindingsToDescendants(gridStateVM, $actionList[0]);
            },
            ready: () => {
                if(vm.state) {
                    gridUtils.applyGridState($grid.attr('id'), vm.state.grid);
                }
                
                isInit = true;
            }, 
            rendergridrows: (obj) => {
                return obj.data;
            },
        });

        $grid.on('bindingcomplete', () => setGridProcessing(false));

        return { $grid, dataAdapter };
    }

    const gridSource = ($grid, additionalFilters) => {
        return {
            url: "Wires/Listing",
            type: "GET",
            filter: (filters, recordsArray) => {
                $grid.jqxGrid('updatebounddata', 'filter');
            },
            sort: (column, direction) => {
                $grid.jqxGrid('updatebounddata', 'sort');
            },
            datafields: [
                { name: 'id', type: 'string' },
                { name: 'userName', type: 'string' },
                { name: 'agencyExId', type: 'string' },
                { name: 'checkAmount', type: 'float' },
                { name: 'wireCode', type: 'string' },
                { name: 'driverId', type: 'string' },
                { name: 'driverAgency', type: 'string' },
                { name: 'payeeId', type: 'string' },
                { name: 'payeeType', type: 'string' },
                { name: 'checkNumber', type: 'string' },
                { name: 'checkCode', type: 'string' },
                { name: 'description', type: 'string' },
                { name: 'orderId', type: 'string' },
                { name: 'orderAgencyId', type: 'string' },
                { name: 'orderStatus', type: 'string' },
                { name: 'fuelCardId', type: 'string' },
                { name: 'subAccountId', type: 'string' },
                { name: 'transactionDate', type: 'date' },
                { name: 'source', type: 'string' },
                { name: 'posted', type: 'bool' },
            ],
            datatype: "json",
            formatdata: (data = {}) => {
                gridProcessing(true);

                var filterinfo = $grid.jqxGrid('getfilterinformation');
                data = gridUtils.formatGridFilters(filterinfo, data);
                
                data = Object.keys(data).reduce((newData, key) => {
                    if(["Any", "Please Choose:"].indexOf(data[key]) == -1) {
                        newData[key] = data[key];
                    }

                    return newData;
                }, {})
               
                const transactionDateStart = findDateSelectionFromOptions(additionalFilters()['transactionDateStart']);

                return { 
                    ...data, 
                    ...additionalFilters(), 
                    transactionDateStart: transactionDateStart ? transactionDateStart.value.format("MM/DD/YYYY") : null
                };
            },
        }
    }

    const findDateSelectionFromOptions = (findByLabel) => {
        return vm.transactionDateSelectOptions().find(({label}) => label === findByLabel);
    }

    const gridColumns = ($grid) => {
        const updateViewCount = async (id) => {
            const currentCount = await checkCodeSecurity.setCheckCodeViewCount(userProfile.userName);
            //logger.logToServer(`WIRES:VIEW_CHECKCODE - ${userProfile.userName} - 'Viewing Check Code' [WireId: ${id}, Overall View Count: ${currentCount}]`)
        }

        const getChargeCodeForWire = async (id) => {
            try {
                const code = await fetchChargeCodeAsync(id);
                return code;
            }
            catch(err) {
                console.error(err);
                return "N/A";
            }
        }

        return [
            { text: 'Username', dataField: 'userName', minwidth: 150 },
            { text: 'Agency', dataField: 'agencyExId', minwidth: 150 },
            { text: 'Check Code', dataField: 'checkCode', width: 100,
                resizable: false,
                filterable: false,
                columntype: "button",
                sortable: false,
                cellsrenderer: () => {
                    return "View Code";
                },
                buttonclick: (row) => {
                    const {id} = $grid.jqxGrid('getrowdata',row);

                    if(id > 0) {
                        updateViewCount(id);
                        dispatch(isLoading(true));

                        getChargeCodeForWire(id).then((checkCode) => {
                            dispatch(isLoading(false));
                            showmessage(`
                                <div style="text-align: center; padding: 10px; font-size: 16px">
                                    Check Code: 
                                    <strong style="margin-left: 10px">${checkCode || "N/A"}</strong>
                                </div>
                            `);
                        });
                    }

                    
                }
            },
            { text: 'Check Number', dataField: 'checkNumber', minwidth: 150 },
            { text: 'Check Amount', dataField: 'checkAmount', minwidth: 150, cellsformat: 'c2', filterable: false },
            { text: 'Wire Code', dataField: 'wireCode', minwidth: 150, 
                filtertype: 'list', filteritems: [{label: 'Any', html: null}, {label: 'W', html: 'W'}, {label: 'T', html: 'T'}, {label: 'F', html: 'F'}] 
            },
            { text: 'Description', dataField: 'description', minwidth: 150, filterable: false },
            { text: 'Driver', dataField: 'driverId', minwidth: 150 },
            { text: 'Driver Agency', dataField: 'driverAgency', minwidth: 150 },
            { text: 'Payee', dataField: 'payeeId', minwidth: 150 },
            { text: 'Payee Type', dataField: 'payeeType', minwidth: 150,
                filtertype: 'list', filteritems: [{label: 'Any', html: null}, {label: 'R', html: 'R'}, {label: 'A', html: 'A'}, {label: 'O', html: 'O'}]
            },
            { text: 'Fuel Card', dataField: 'fuelCardId', minwidth: 150 },
            { text: 'Sub Account', dataField: 'subAccountId', minwidth: 150 },
            { text: 'Transaction Date', dataField: 'transactionDate', minwidth: 150, filterable: false, cellsformat: 'MM/dd/yyyy' },
            { text: 'Source', dataField: 'source', minwidth: 150, 
                filtertype: 'list', filteritems: [{label: 'Any', html: null},{label: 'GE', html: 'GE'}, {label: 'DSP', html: 'DSP'}, {label: 'LME', html: 'LME'}] 
            },
            { text: 'Posted', dataField: 'posted', minwidth: 150, 
                filtertype: 'bool',
                columntype: 'checkbox',
                editable: false
            },
            { text: 'Order', dataField: 'orderId', minwidth: 150 },
            { text: 'Order Agency', dataField: 'orderAgencyId', minwidth: 150 },
            { text: 'Order Status', dataField: 'orderStatus', minwidth: 150, 
                filtertype: 'list', filteritems: [{label: 'Any', html: null },{label: 'In Progress', html: 'In Progress'}, {label: 'Delivered', html: 'Delivered'}] 
            },
        ];
    }

    const handleClearFilters = ($grid) => {
        setGridProcessing(true);
        vm.clearSelectedSavedSearchDDL(true);
        vm.selectedTransactionDate(resetTransactionDate());
        
        $grid.jqxGrid('clearfilters');
    }        
}

import template from './wires-page-component.html';
export default { viewModel: wiresPageViewModel, template: template }