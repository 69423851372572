import ko from 'knockout';
import dataModel from 'data-model';
import gridStateUtils from 'jqx.grid-utils';
import template from './comdata-card-page.html';
import _ from 'lodash';
import userProfile from 'user-profile';


var ComdataCardDetails = function (data, mainModel) {
    var self = this;
    var _data = data || {};
    mainModel = mainModel || {};
    self.card = ko.observable();
    
    self.fuelCardId = ko.observable(_data.id || 0);
    self.tractorId = ko.observable(_data.tractorId || 0);
    self.showBalance = ko.observable(_data.showBalance || false);
    self.showFundedCard = ko.observable(_data.showFundedCard || false);
    self.cardBalance = ko.observable(_data.cardBalance || 0);
    self.loadAmount = ko.observable(_data.loadAmount || 0);
    self.purchaseLimit = ko.observable(_data.purchaseLimit || 0);
    self.purchaseRenew = ko.observable(_data.purchaseRenew || "");
    self.cashLimit = ko.observable(_data.cashLimit || 0);
    self.purchaseOneTimeLimit = ko.observable(_data.purchaseOneTimeLimit || 0);
    self.purchaseUsed = ko.observable(_data.purchaseUsed || 0);
    self.cashRenew = ko.observable(_data.cashRenew || "");
    self.cashOneTimeLimit = ko.observable(_data.cashOneTimeLimit || 0);
    self.cashUsed = ko.observable(_data.cashUsed || 0);
    self.cardMsg = ko.observable(_data.cardMsg || "");

    self.getFuelCardHistory = function () {
        var fuelCardId = self.fuelCardId();
        
        mainModel.isLoading(true);
        dataModel.ajaxRequest("ComdataCard/GetFuelPurchaseHistory/" + fuelCardId)
            .done(function (resp, status, xhr) {

                if (resp.length > 0) {
                    dataModel.exportToCSV(resp, "ComdataCardHistory", true);
                    // warn user of posting delays
                    mainModel.userAlert("Please note fuel is posted Monday through Friday at 11:00 AM the next business day after the purchase. Current purchases may not be reported on the export.");
                }
                else {
                    alert("Card history is unavailable");
                }
                mainModel.isLoading(false);
            })
            .fail(function (jqXHR, textStatus, errorThrown) {

                if (jqXHR.responseJSON != null) {
                    var json = jqXHR.responseJSON;
                    if (json.message != null) {
                        var errors = json.message;
                        alert(errors);
                    }
                }
                else {
                    alert("Unknown error");
                }
                mainModel.isLoading(false);
            });
    };

};


var ComdataCardPageViewModel = function () {
    var self = this;
    self.isLoading = ko.observable(false);
    self.comdataCardModalDetails = ko.observable();
    self.userAlert = function (message) {
        $("#comdataAlertModal").modal("show");
        $("#comdataAlertMessage").html(message);
    }
    self.getAllFuelCardHistory = function () {
        self.isLoading(true);
        dataModel.ajaxRequest("ComdataCard/GetAllFuelPurchaseHistory")
        .done(function (data, status, xhr) {
            if (data.length > 0) {
                dataModel.exportToCSV(data, "FuelCardHistory", true);
                // warn user of posting delays
                self.userAlert("<strong>Please note fuel is posted Monday through Friday at 11:00 AM the next business day after the purchase. Current purchases may not be reported on the export.</strong>");
            }
            else {
                alert("Card history is unavailable");
            }
            self.isLoading(false);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.responseJSON != null) {
                var json = jqXHR.responseJSON;
                if (json.message != null) {
                    var errors = json.message;
                    alert(errors);
                }
            }
            else {
                alert("Unknown error");
            }
            self.isLoading(false);
        });
    };

    self.getFuelCardHistory = function (fuelCardId) {
        self.isLoading(true);
        dataModel.ajaxRequest("ComdataCard/GetFuelPurchaseHistory/" + fuelCardId)
        .done(function (data, status, xhr) {
            if (data.length > 0) {
                dataModel.exportToCSV(data, "FuelCardHistory", true);
                // warn user of posting delays
                self.userAlert("Please note fuel is posted Monday through Friday at 11:00 AM the next business day after the purchase. Current purchases may not be reported on the export.");
            }
            else {
                alert("Card history is unavailable");
            }
            self.isLoading(false);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.responseJSON != null) {
                var json = jqXHR.responseJSON;
                if (json.message != null) {
                    var errors = json.message;
                    alert(errors);
                }
            }
            else {
                alert("Unknown error");
            }
            self.isLoading(false);
        });
    };

    self.getFuelCards = function () {
        var initFilters = function () {
            var filtergroup1 = new $.jqx.filter();
            var filter1 = filtergroup1.createfilter('stringfilter', "Active", "equal");
            filtergroup1.addfilter(1, filter1);
            var filtergroup2 = new $.jqx.filter();
            var filter2 = filtergroup2.createfilter('stringfilter', "Active", "equal");
            filtergroup2.addfilter(1, filter2);
            $("#comdataCardGrid").jqxGrid('addfilter', 'status', filtergroup1);
            $("#comdataCardGrid").jqxGrid('addfilter', 'driverStatus', filtergroup2);
            $("#comdataCardGrid").jqxGrid('applyfilters');
        };

        var source = {
            url: "ComdataCard/GetComdataCardForGrid",
            datatype: "json",
            formatdata: function (data) {
                data.agencyId = userProfile.currentAgencyId();
                return data;
            },
            datafields: [
                { name: 'cardNumber', type: 'string' },
                { name: "status", type: "string" },
                { name: "payeeCode", type: "string" },
                { name: "payeeName", type: "string" },
                { name: "driverCode", type: "string" },
                { name: "driverName", type: "string" },
                { name: "driverStatus", type: "string" },
                { name: "agencyName", type: "string"}
            ],
            beforeLoadComplete: function (records) {
                return records;
            }
        };
        var dataSource = dataModel.getDataAdapter(source);

        $("#comdataCardGrid").jqxGrid({
            width: '100%',
            source: dataSource,
            altrows: true,
            showfilterrow: true,
            sortable: true,
            filterable: true,
            showtoolbar: true,
            pageable: true,
            pagesize: 10,
            toolbarheight: 50,
            columns: [
                {
                    text: '', width: 100, columntype: 'button',
                    cellsrenderer: function () {
                        return "Details";
                    },
                    buttonclick: function (row) {
                        var datarecord = $("#comdataCardGrid").jqxGrid('getrowdata', row);
                        self.isLoading(true);
                        dataModel.ajaxRequest("ComdataCard/" + datarecord.cardNumber)
                        .done(function (data, status, xhr) {
                            var cardDetails = new ComdataCardDetails(data, self);
                            self.comdataCardModalDetails(cardDetails);
                            self.isLoading(false);
                        })
                        .fail(function (jqXHR, textStatus, errorThrown) {
                            if (jqXHR.responseJSON != null) {
                                var json = jqXHR.responseJSON;
                                if (json.message != null) {
                                    var errors = json.message;
                                    alert(errors);
                                }
                            }
                            self.isLoading(false);
                        });
                    },
                    filterable: false,
                    sortable: false,
                    pinned: true,
                    resizable: false,
                },
                { text: 'Card Number', datafield: 'cardNumber', hidden: true },
                { text: 'Card Status', datafield: 'status', filtertype: "checkedlist", width: 150 },
                { text: 'Payee Code', datafield: 'payeeCode', width: 250  },
                { text: 'Payee Name', datafield: 'payeeName', width: 250  },
                { text: 'Driver Code', datafield: 'driverCode', width: 250  },
                { text: 'Driver Name', datafield: 'driverName', width: 250  },
                { text: 'Driver Status', datafield: 'driverStatus', filtertype: "checkedlist", width: 150 },
                { text: 'Agency', datafield: 'agencyName', width: 150 },

            ],
            rendertoolbar: function (toolbar) {
                var btnExport = $("<input type='button' value='Get Transaction History for All Drivers' data-bind='geButton:{ disabled: isLoading }', style='margin:10px' />");
                toolbar.append(btnExport);
                btnExport.jqxButton({});
                btnExport.click(function () {
                    self.isLoading(true);
                    self.getAllFuelCardHistory();
                });
            },
            ready: function () {
                initFilters();
            }
        }).bind("filter", function (event) {        // Bind to the input / select filters to capture changes
            gridStateUtils.logGridFilterResults('#comdataCardGrid');
        });
    };
    self.getFuelCards();
}


export default { viewModel: ComdataCardPageViewModel, template: template }