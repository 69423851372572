import dataModel from "data-model";
import userProfile from "user-profile";
import { isEmpty } from "global-functions";
import gridState from "jqx.grid-state-component";
import gridStateUtils from "jqx.grid-utils";

class CarrierTractorViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.carrierTractorsGridState = null;
        this.grid = $("#carrierTractorsGrid");

        this.initializeComponent();
    }

    async initializeComponent() {
        await this.setUserFilters(); // And load this, but wait here until finished, then
        this.loadGrid(); // load this
    }

    refreshGrid = () => {
        this.grid.jqxGrid("updatebounddata", "data");
        this.$emitsOnAction();
    };

    async setUserFilters() {
        let filters = await gridStateUtils
            .getUserGridFiltersAsync("carrierTractorsGrid")
            .catch((err) => console.error("Failed to retrieve user filters."));
        if (isEmpty(filters) == false) {
            let state = JSON.parse(filters);
            this.carrierTractorsGridState = state.grid;
        }
    }

    get gridSource() {
        return {
            url: "MyAgency/GetCarrierTractors",
            datatype: "json",
            formatdata(data) {
                data = data || {};
                data.agencyId = userProfile.currentAgencyId();

                return data;
            },
            datafields: [
                { name: "id", type: "int" },
                { name: "driverId", type: "string" },
                { name: "phone", type: "string" },
            ],
        };
    }

    loadGrid = () => {
        let vm = this;

        if (this.grid.length == 0) this.grid = $("#carrierTractorsGrid");

        var dataAdapter = dataModel.getDataAdapter(this.gridSource);

        this.grid
            .jqxGrid({
                theme: "GWTMDark",
                width: "100%",
                height: "330px",
                source: dataAdapter,
                altrows: true,
                showstatusbar: true,
                sortable: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                statusbarheight: 35,
                showtoolbar: true,
                rendertoolbar: (toolbar) => {
                    let vm1 = new gridState.viewModel();

                    vm1.actions = [
                        "Clear Filters",
                        "Clear Selection",
                        "Refresh",
                        "Set Default Search",
                    ];
                    vm1.initializeDefaultToolbar(toolbar);

                    vm1.notifyLocation = this.grid
                        .closest(".modal-content")
                        .find(".modal-header");
                },

                rendergridrows: (obj) => {
                    return obj.data;
                },

                columns: [
                    { text: "Id", datafield: "id", hidden: true },
                    { text: "Driver Id", datafield: "driverId", hidden: false },
                    { text: "Phone", datafield: "phone", width: 200 },

                    {
                        text: "",
                        width: 60,
                        filterable: false,
                        sortable: false,
                        columnType: "button",
                        buttonclick(row) {
                            var datarow = vm.grid.jqxGrid("getrowdata", row);
                            vm.doHandleDeleteCarrierTractor(datarow.id);
                        },
                        cellsrenderer() {
                            return "Delete";
                        },
                    },
                ],

                ready() {
                    gridStateUtils.applyGridState(
                        "carrierTractorsGrid",
                        vm.carrierTractorsGridState
                    );
                },
            })
            .bind("filter", () => {
                gridStateUtils.logGridFilterResults("#carrierTractorsGrid");
            });
    };

    handleDeleteCarrierTractor = (id) => {
        this.doHandleDeleteCarrierTractor(id);
    };

    async doHandleDeleteCarrierTractor(id) {
        await this.http_sendDeleteCarrierTractorAsync(id).catch((err) =>
            console.error("Error occurred while deleting carrier tractors.")
        );
        this.refreshGrid();
    }

    http_sendDeleteCarrierTractorAsync = (id) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/DeleteCarrierTractor", "delete", id)
                .done((data) => resolve(data))
                .fail((err) => reject(err));
        });
    };
}

import template from "./carrier-tractor-component.html";
export default { viewModel: CarrierTractorViewModel, template: template };
