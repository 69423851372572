import ko from "knockout";
import dataModel from "data-model";
import GreatEdgeCustomWidgets from "ge.custom-widgets";
import _ from "lodash";
import gridStateUtils from "jqx.grid-utils";
import GridStateComponentViewModel from "../../shared-components/jqx.grid-state-component/jqx.grid-state-component";
import router from "router";
import {
  makePagingExpandable,
  lockPageOnGridScroll,
  buildGrid,
} from "../../../gridtoolbox";
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";
import { useDispatch } from "ko-data-store";
import { isLoading } from "../../../dataStore/actions/appUI";

class DuplicateOrderModel {
  constructor(data, mainModel) {
    var self = this;
    data = data || {};
    mainModel = mainModel || {};

    self.isLoading = ko.observable(false);
    self.id = data.id;
    self.numOfOrders = ko.observable(1);
    self.isBrokered = ko.observable(data.options.isBrokered || false);
    self.noBroker = ko.observable(data.noBroker);

    self.result = ko.observable();
    self.responseMessage = ko.observable();

    self.isConfirm = ko.observable(false);

    self.checkCredit = function () {
      self.responseMessage("");
      var data = {
        id: self.id,
        numOfOrders: self.numOfOrders(),
      };

      mainModel.isLoading(true);

      dataModel
        .ajaxRequest("order/GetOrderDuplicateCredit", "get", data)
        .done((data) => {
          if (data == "") {
            self.duplicateOrder();
          } else {
            self.isConfirm(true);
            self.responseMessage(data);
            mainModel.isLoading(false);
            mainModel.doesGridNeedReloaded(true);
          }
        })
        .fail((err) => {
          alert("unknown error");
          mainModel.isLoading(false);
        });
    };

    self.duplicateOrder = function () {
      var data = {
        id: self.id,
        numOfOrders: self.numOfOrders(),
      };
      mainModel.isLoading(true);
      dataModel
        .ajaxRequest("order/DuplicateOrder", "POST", data)
        .done(function (data, textStatus, jqXHR) {
          self.result(data);
          mainModel.isLoading(false);
          mainModel.doesGridNeedReloaded(true);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (
            jqXHR.responseJSON != undefined &&
            jqXHR.responseJSON.message == "Agent Risk Blocked"
          ) {
            alert(
              "You cannot duplicate orders at agent risk.  Please contact your agency manager with the order to be duplicated."
            );
          } else {
            alert("Unable to duplicate order");
          }
          mainModel.isLoading(false);
        });
    };
  }
}

class OrdersPageViewModel {
  $grid = $();
  tpiViewRatesProps = ko.observable();

  constructor() {
    this.dispatchAction = useDispatch();

    var self = this;
    self.isLoading = ko.observable(false);
    var lastUpdated = Date.now();
    var updateGrid = false;
    var showEdit = true;
    var isInitLoad = true;
    var grid = $("#ordersGrid");

    this.$grid = grid;

    var statusTooltipMsg =
      "Selecting your filters before selecting delivered status improves the search performance";
    var GECustomWidgets = new GreatEdgeCustomWidgets();
    var _jqxGridHelper = GECustomWidgets.GEWidgetsGridHelper("ordersGrid");

    self.orderDetails = ko.observable();
    self.isOwnerOperator = ko.observable(false);
    self.isDriver = ko.observable(false);
    self.duplicateOrderModal = ko.observable();

    self.clearSelectedSavedSearchDDL = ko.observable(false);
    self.refreshSavedSearchDDL = ko.observable("");

    self.doesGridNeedReloaded = ko.observable(false);

    self.handleOnSelected = (state) => {
      self.ordersGridState = state.grid;

      if (isInitLoad) {
        self.buildGrid();
      } else {
        gridStateUtils.applyGridState(grid.attr("id"), state.grid);
      }
    };
    self.confirmVoidModal = ko.observable();
    self.showConfirmDialog = function (data) {
      var modalData = data || {};

      self.confirmVoidModal(modalData);
    };

    self.closeConfirmModal = function () {
      $("#confirmVoidModal").modal("hide");
    };

    self.closeOrderDetailsModal = function () {
      $("#orderDetailsModal").modal("hide");
    };

    self.printOrder = function () {
      var divOrders = $("#orderDetailsModal");
      divOrders.addClass("printSection");

      window.print();

      divOrders.removeClass("printSection");
    };

    self.voidOrder = function (orderData) {
      var data = orderData;

      // Verifying we can void if these conditions are met
      if (data == undefined) return false;

      self.isLoading(true);
      dataModel
        .ajaxRequest("order/VoidOrder/" + data.id, "post")
        .done(function (data, textStatus, jqXHR) {
          self.closeConfirmModal();
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.responseJSON != null) {
            var json = jqXHR.responseJSON;
            if (json.message != null) {
              var errors = json.message;
              alert(errors);
              self.closeConfirmModal();
            }
          }
          self.isLoading(false);
        })
        .then(function () {
          self.getOrderDetails(data.orderId);
          self.doesGridNeedReloaded(true);
        });
    };

    self.showDuplicateOrderModal = function (data) {
      if (data != undefined) {
        self.duplicateOrderModal(new DuplicateOrderModel(data, self));
      }
    };

    // Bind to our details modal and when it is being closed
    // update the grid only when we have changed/updated an order
    // so it is reflected in the grid
    $("#orderDetailsModal").on("hide.bs.modal", function (e) {
      if (self.doesGridNeedReloaded() == true) {
        grid.jqxGrid("updatebounddata", "cells");
        self.doesGridNeedReloaded(false);
      }
    });
    self.createInputFilterWidget = function (column, columnElement, widget) {
      widget.jqxInput({
        placeHolder: "Type Here...",
      });
    };

    self.createCityStateZipFilterWidget = function (
      column,
      columnElement,
      widget
    ) {
      widget.jqxInput({
        placeHolder: "City, State, Zip",
      });
    };

    var capitalizeFirstLetter = function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    self.getOrderDetails = function (orderExId) {
      if (orderExId != undefined) {
        self.isLoading(true);
        dataModel
          .ajaxRequest("order/" + orderExId, "GET")
          .done(function (data, status, xhr) {
            self.isLoading(false);
            self.orderDetails(data);
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.responseJSON != null) {
              var json = jqXHR.responseJSON;
              if (json.message != null) {
                var errors = json.message;
                alert(errors);
              }
            }
            self.isLoading(false);
          });
      }
    };

    self.ordersGridState = null;

    // Initial grid filters
    var initFilters = function () {
      var filtergroup1 = new $.jqx.filter();
      var filter1 = filtergroup1.createfilter(
        "stringfilter",
        "Available",
        "equal"
      );
      var filter2 = filtergroup1.createfilter(
        "stringfilter",
        "In Progress",
        "equal"
      );
      var filter3 = filtergroup1.createfilter(
        "stringfilter",
        "StatusQuote",
        "equal"
      );
      filtergroup1.addfilter(1, filter1);
      filtergroup1.addfilter(1, filter2);
      filtergroup1.addfilter(1, filter3);
      grid.jqxGrid("addfilter", "orderStatus", filtergroup1);
      grid.jqxGrid("refreshfilterrow");
    };

    self.customDataFields = ko.observableArray([]);

    // Separate grid into own function so gets loaded only once doesn't make
    // extra api calls

    self.buildGrid = function () {
      var initStateApplied = false;

      var source = {
        url: "Order/GetOrdersForGrid",
        datatype: "json",
        filter: function (filters, recordsArray) {
          if (isInitLoad == false) {
            grid.jqxGrid("updatebounddata", "filter");
          }
        },
        sort: function (column, direction) {
          if (isInitLoad == false) {
            grid.jqxGrid("updatebounddata", "sort");
          }
        },
        beforeprocessing: function (data) {
          showEdit = data.showEdit;
          data.records = data.records || [];

          data.records = data.records.map((x) => {
            if (x.deliveryDate) {
              x.deliveryDate = x.deliveryDate.substr(
                0,
                x.deliveryDate.indexOf("T") + 9
              );
            }

            if (x.pickUpDate) {
              x.pickUpDate = x.pickUpDate.substr(
                0,
                x.pickUpDate.indexOf("T") + 9
              );
            }

            if (x.scheduledDeliveryDate) {
              x.scheduledDeliveryDate = x.scheduledDeliveryDate.substr(
                0,
                x.scheduledDeliveryDate.indexOf("T") + 9
              );
            }

            if (x.scheduledShipDate) {
              x.scheduledShipDate = x.scheduledShipDate.substr(
                0,
                x.scheduledShipDate.indexOf("T") + 9
              );
            }

            return x;
          });
        },
        formatdata: function (data) {
          try {
            //formatdata that's going to the server.
            grid.jqxGrid('hideloadelement');
            self.isLoading(true);
            var filterinfo = grid.jqxGrid("getfilterinformation");
            data = gridStateUtils.formatGridFilters(filterinfo, data);

            if (updateGrid) {
              lastUpdated = Date.now();
            }

            data.lastUpdated = lastUpdated;

            if (isInitLoad) {
              if (initStateApplied == false) {
                //Adds saved grid parameters to the inital request
                data = gridStateUtils.loadInitGridState(
                    data,
                    self.ordersGridState,
                    columns,
                    grid
                );
              }

              data.orderStatus = ["Available", "In Progress", "StatusQuote"];

              //isInitLoad = false;
              initStateApplied = true;
            } else {
              // Since we set default init status filters for first grid load
              // it is setting the checklist state in opposite mode (as select-all)
              // Undefined == user checked all
              // Else -> need to uncheck all (send null or empty string works)
              if (data.orderStatus === undefined) {
                data.orderStatus = [
                  "Available",
                  "Delivered",
                  "In Progress",
                  "Voided",
                  "StatusQuote",
                ];
              } else if (data.orderStatus.length == 5) {
                data.orderStatus = null;
              }

              if (
                  data.hold != undefined &&
                  data.hold.indexOf("True") != -1 &&
                  data.hold.indexOf("False") != -1
              ) {
                data.hold = null;
              }
            }

            data = _jqxGridHelper.addGridFiltersToDataObj(data, {
              dateRangePostFixes: ["Begin", "End"],
            });
            isInitLoad = false;

            return data;
          }catch (err) {
            console.error(err);
            self.isLoading(false);
          }

        },
        datafields: [
          { name: "orderId", type: "number" },
          { name: "orderExId", type: "string" },
          { name: "orderStatus", type: "string" },
          { name: "movementStatus", type: "string" },
          { name: "pickUpDate", type: "date" },
          { name: "deliveryDate", type: "date" },
          { name: "pickup", type: "string" },
          { name: "destination", type: "string" },
          { name: "customerName", type: "string" },
          { name: "assigned", type: "string" },
          { name: "assignedCarrierName", type: "string" },
          { name: "salespersonId", type: "string" },
          { name: "terminal", type: "string" },
          { name: "bolNumber", type: "string" },
          { name: "customerReferenceNumber", type: "string" },
          { name: "customField1", type: "string" },
          { name: "customField2", type: "string" },
          { name: "customField3", type: "string" },
          { name: "container", type: "string" },
          { name: "chassis", type: "string" },
          { name: "bookingNumber", type: "string" },
          { name: "customerOrderNumber", type: "string" },
          { name: "sealNumber", type: "string" },
          { name: "vessel", type: "string" },
          { name: "erd", type: "date" },
          { name: "cutoffDate", type: "date" },
          { name: "lastFreeDate", type: "date" },
          { name: "containerReleaseDate", type: "date" },
          { name: "manifestId", type: "string" },
          { name: "scheduledShipDate", type: "date" },
          { name: "scheduledDeliveryDate", type: "date" },
          { name: "hold", type: "string" },
          { name: "apptRequired", type: "boolean" },
          { name: "apptStatus", type: "string" },
          { name: "enteredByUser", type: "string" },
          { name: "lastLocation", type: "string" },
          { name: "lastStatusUpdate", type: "date" },
          { name: "carrierRelationship", type: "string" },
          { name: "hasTPIRate", type: "boolean" },
          { name: "trailerType", type: "string" },
          { name: "isBrokered", type: "boolean" },
          //{ name: "transferToBillingDate", type: "date" },
          //{ name: "agencyExId", type: "string" }
          //{ name: "tractorId", type: "string" },
          //{ name: "driverId", type: "string" },
        ],

        downloadComplete: (edata, textStatus, jqXHR) => {
          self.isLoading(false);
        },
        loadError: function (xhr, status, error) {
          self.isLoading(false);
        },
      };

      var dataSource = dataModel.getDataAdapter(source);

      var geDateRangeFilterWidget = function (column, columnElement, widget) {
        //set widget (class) level options
        var options = {
          gridOptions: {
            gridWidget: widget,
            gridId: "ordersGrid", // required to apply gridoptions
            columnName: column.datafield, // also required, especially if this is a filter widget
            isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
            state: self.ordersGridState, // can send our grid state and apply any initial filters
          },
        };
        return GECustomWidgets.GEDateRangePickerWidget(options); // will return widget if already exists or creates a new one;
      };

      var columns = [
        {
          text: "",
          width: 100,
          filterable: false,
          sortable: false,
          pinned: true,
          resizable: false,
          columnType: "button",
          buttonclick: function (row) {
            var datarow = grid.jqxGrid("getrowdata", row);
            self.getOrderDetails(datarow.orderExId);
          },
          cellsrenderer: function () {
            return "View Details";
          },
        },
        {
          text: "",
          datafield: "hasTPIRate",
          filterable: false,
          menu: false,
          width: 30,
          resizable: false,
          hideable: false,
          pinned: true,
          sortable: false,
          cellsrenderer: (
            row,
            columnfield,
            value,
            defaultHTML,
            column,
            rowData
          ) => {
            const $el = $(defaultHTML);
            $el.text("");

            if (rowData.isBrokered) {
              if (rowData.hasTPIRate) {
                return "<img src='Content/Images/Rates32x26.png' title='View Rates' style='cursor: pointer' />";
              }

              if (rowData.trailerType) {
                return "<img src='Content/Images/Rates32x26.png' title='Get Rates' style='cursor: pointer;' />";
              }
            }

            return $el[0].outerHTML;
          },
        },
        {
          text: "Order Id",
          datafield: "orderExId",
          width: 100,
          sortable: true,
          pinned: true,
          cellsrenderer: function (
            row,
            columnfield,
            value,
            defaultHTML,
            column,
            rowData
          ) {
            if (showEdit && defaultHTML != null) {
              if (self.isDriver() == false && self.isOwnerOperator() == false) {
                var cell = $(defaultHTML);
                cell.html(
                  "<a href='/Orders/" +
                    value +
                    "' target='_blank'  style='color:blue;'>" +
                    value +
                    "</a>"
                );
                return cell[0].outerHTML;
              }
            }
            return defaultHTML;
          },
        },
        {
          text: "Status",
          datafield: "orderStatus",
          width: 100,
          filtercondition: "OR",
          filtertype: "checkedlist",
          filteritems: [
            {
              html: "<div title='" + statusTooltipMsg + "'>Available</div>",
              label: "Available",
            },
            {
              html: "<div title='" + statusTooltipMsg + "'>Delivered</div>",
              label: "Delivered",
            },
            {
              html: "<div title='" + statusTooltipMsg + "'>In Progress</div>",
              label: "In Progress",
            },
            {
              html: "<div title='" + statusTooltipMsg + "'>Voided</div>",
              label: "Voided",
            },
            {
              html: "<div title='" + statusTooltipMsg + "'>Quote</div>",
              label: "StatusQuote",
            },
          ],
        },
        {
          text: "Movement Status",
          datafield: "movementStatus",
          filtercondition: "OR",
          filtertype: "checkedlist",
          filteritems: ["Available", "In Progress", "Covered", "Delivered"],
        },
        {
          text: "Pickup Date",
          datafield: "pickUpDate",
          width: 215,
          editable: false,
          cellsformat: "MM/dd/yyyy HH:mm",
          createfilterwidget: geDateRangeFilterWidget,
        },
        {
          text: "Delivery Date",
          datafield: "deliveryDate",
          editable: false,
          cellsformat: "MM/dd/yyyy HH:mm",
          width: 215,
          createfilterwidget: geDateRangeFilterWidget,
        },
        { text: "Customer Name", datafield: "customerName", width: 250 },
        { text: "Assigned", datafield: "assigned", width: 100 },
        {
          text: "Assigned Name",
          datafield: "assignedCarrierName",
          width: 200,
        },
        { text: "Terminal", datafield: "terminal", width: 100 },
        { text: "BOL", datafield: "bolNumber", width: 150 },
        {
          text: "Customer Reference Number",
          datafield: "customerReferenceNumber",
          width: 150,
        },
        {
          text: self.customDataFields().customField1,
          datafield: "customField1",
          width: 120,
        },
        {
          text: self.customDataFields().customField2,
          datafield: "customField2",
          width: 120,
        },
        {
          text: self.customDataFields().customField3,
          datafield: "customField3",
          width: 120,
        },
        { text: "Container", datafield: "container", width: 120 },
        { text: "Chassis", datafield: "chassis", width: 120 },
        {
          text: "Booking Number",
          datafield: "bookingNumber",
          width: 120,
        },
        {
          text: "Customer Order Number",
          datafield: "customerOrderNumber",
          width: 120,
        },
        { text: "Seal Number", datafield: "sealNumber", width: 120 },
        { text: "Vessel", datafield: "vessel", width: 120 },
        {
          text: "ERD",
          datafield: "erd",
          editable: false,
          cellsformat: "MM/dd/yyyy",
          width: 215,
          createfilterwidget: geDateRangeFilterWidget,
        },
        {
          text: "Cut Off Date",
          datafield: "cutoffDate",
          editable: false,
          cellsformat: "MM/dd/yyyy",
          width: 215,
          createfilterwidget: geDateRangeFilterWidget,
        },
        {
          text: "Last Free Date",
          datafield: "lastFreeDate",
          editable: false,
          cellsformat: "MM/dd/yyyy",
          width: 215,
          createfilterwidget: geDateRangeFilterWidget,
        },
        {
          text: "Container Release Date",
          datafield: "containerReleaseDate",
          editable: false,
          cellsformat: "MM/dd/yyyy",
          width: 215,
          createfilterwidget: geDateRangeFilterWidget,
        },
        { text: "Manifest", datafield: "manifestId", width: 120 },
        {
          text: "Shipper",
          datafield: "pickup",
          width: 200,
          createfilterwidget: self.createCityStateZipFilterWidget,
        },
        {
          text: "Destination",
          datafield: "destination",
          width: 200,
          createfilterwidget: self.createCityStateZipFilterWidget,
        },
        {
          text: "Hold",
          width: 100,
          datafield: "hold",
          filtertype: "checkedlist",
          filtercondition: "OR",
          filteritems: ["True", "False"],
          initwidget: function (row, column, value, cellElement) {
            const $cellElement = $(cellElement).jqxCheckBox({
              checked: value,
              locked: true,
            });
            $cellElement.css({ top: "21%", left: "38%", cursor: "auto" });
            return $cellElement[0].outerHTML;
          },
          createwidget: function (row, column, value, cellElement) {
            const $cellElement = $(cellElement).jqxCheckBox({
              checked: value,
              locked: true,
            });
            $cellElement.css({ top: "21%", left: "38%", cursor: "auto" });
            return $cellElement[0].outerHTML;
          },
        },
        {
          text: "Scheduled Ship Date",
          datafield: "scheduledShipDate",
          width: 215,
          editable: false,
          cellsformat: "MM/dd/yyyy HH:mm",
          createfilterwidget: geDateRangeFilterWidget,
        },
        {
          text: "Scheduled Delivery Date",
          datafield: "scheduledDeliveryDate",
          width: 215,
          editable: false,
          cellsformat: "MM/dd/yyyy HH:mm",
          createfilterwidget: geDateRangeFilterWidget,
        },
        {
          text: "Appointment Required",
          datafield: "apptRequired",
          width: 200,
          filtertype: "bool", // checkbox
          columntype: "checkbox",
          editable: false,
          // filtertype: "list",
          // filteritems: [{value: "", label: "Any" },{ value: true, label: "True" }, { value: false, label: "False" }],
        },
        {
          text: "Appointment Confirmed",
          datafield: "apptStatus",
          width: 200,
          filtertype: "list",
          filteritems: [
            { value: "Any", label: "Any" },
            { value: "Scheduled", label: "Scheduled" },
            { value: "Unscheduled", label: "Unscheduled" },
            { value: "Appt Unnecessary", label: "Appt Unnecessary" },
          ],
        },
        { text: "EnteredBy", datafield: "enteredByUser", width: 100 },
        {
          text: "Last Location",
          datafield: "lastLocation",
          minwidth: 150,
          filterable: false,
        },
        {
          text: "Last Status Update",
          datafield: "lastStatusUpdate",
          minwidth: 100,
          filterable: false,
          cellsformat: "MM/dd/yyyy HH:mm",
        },
        {
          text: "Carrier Relationship Manager",
          datafield: "carrierRelationship",
          width: 100,
        },
      ];

      var pxgridheight = 400;
      var gridRows = 10;

      if (screen.height >= 800) {
        gridRows = 20;
        pxgridheight = window.innerHeight - 250;
      }

      const { pagesize } = self.ordersGridState || {};

      const renderGridWithPaging = makePagingExpandable(grid, {
        pagesize: pagesize || gridRows,
      });
      renderGridWithPaging(
        {
          theme: "GWTMDark",
          width: "100%",
          source: dataSource,
          altrows: true,
          sortable: true,
          pagesize: gridRows,
          filterable: true,
          height: pxgridheight, //window.screen.height - 180 + "px",
          // autoheight: screen.height >= 800,
          showfilterrow: true,
          virtualmode: true,
          columnsresize: true,
          columnsreorder: true,
          enablebrowserselection: true,
          columnsmenu: false,
          columns: columns,
          showtoolbar: true,
          autoshowfiltericon: true,
          rendertoolbar: function (toolbar) {
            var gridStateViewModel =
              new GridStateComponentViewModel.viewModel();

            gridStateViewModel.clearFilters = function () {
              _jqxGridHelper.clearDateRangeFiltersFromGrid();
              // Must be applied in this order or jqx grid throws error
              isInitLoad = true;
              grid.jqxGrid("clearfilters");
              initFilters();
              grid.jqxGrid("updatebounddata", "data");
              self.clearSelectedSavedSearchDDL(true);
            };

            gridStateViewModel.refresh = function () {
              updateGrid = true;
              grid.jqxGrid("updatebounddata", "data");
            };

            gridStateViewModel.actions.push("Save Search");

            gridStateViewModel.setDefaultSearchOverride = async () => {
              const savedSearches = await loadSavedSearches(grid.attr("id"));
              const filters = {
                isDefault: true,
              };

              gridStateViewModel.loadSaveSearchModal(
                grid,
                (val) => {
                  if (val && val.searchname) {
                    self.refreshSavedSearchDDL(val.searchname);
                  }
                },
                savedSearches
                  .filter((x) => x.searchName)
                  .map((x) => ({ id: x.id, text: x.searchName })),
                filters,
                true
              );
            };

            gridStateViewModel.loadSaveSearchModalOverride = async () => {
              const savedSearches = await loadSavedSearches(grid.attr("id"));
              const filters = {
                isDefault: false,
              };

              gridStateViewModel.loadSaveSearchModal(
                grid,
                (val) => {
                  if (val && val.searchname) {
                    self.refreshSavedSearchDDL(val.searchname);
                  }
                },
                savedSearches
                  .filter((x) => x.searchName)
                  .map((x) => ({ id: x.id, text: x.searchName })),
                filters,
                true
              );
            };

            const $actionList = $("#tdOrderGridAction");
            $actionList.append(GridStateComponentViewModel.template);

            toolbar.append($actionList);

            const $wiresGridToolbar = $("#orderGridToolbar");
            toolbar.append($wiresGridToolbar);

            ko.applyBindingsToDescendants(gridStateViewModel, $actionList[0]);
          },
          rendergridrows: function (obj) {
            return obj.data;
          },

          ready: function () {
            //This adds the initial filters to the UI.  Doesn't affect the server call.
            //These will be knocked out by the below method call if there are saved filters.
            initFilters();

            //Below adds to the UI of the grid on initial load.  Doesn't affect what's actually going to the server.
            gridStateUtils.loadGridStateFilters(grid, self.ordersGridState);

            isInitLoad = false;

            grid.on("cellclick", self.handleCellClick);
          },
        },
        [buildGrid, lockPageOnGridScroll]
      );
    };


    self.handleCellClick = (event) => {
      // event arguments.
      const args = event.args;

      // row's bound index.
      const rowBoundIndex = args.rowindex;
      // column data field.
      const dataField = args.datafield;

      if (dataField === "hasTPIRate") {
        const rowData = grid.jqxGrid("getrowdata", rowBoundIndex) || {};

        if (rowData.Isbrokered === false) {
          return;
        }

        if (rowData.hasTPIRate) {
          this.handleViewTPIRates(rowData.orderId);
        } else {
          const rowId = grid.jqxGrid("getrowid", args.rowindex);
          this.handleGetTPIRates({ rowId, ...rowData });
        }
      }
    };

    self.handleAddNewOrder = () => {
      if (self.isOwnerOperator() == false && self.isDriver() == false) {
        router.navigate("/neworder", true);
      }
    };

    self.loadPage = function () {
      dataModel
        .ajaxRequest("Order/GetOrdersNewPageDetails", "get")
        .done(function (data) {
          self.isOwnerOperator(data.isOwnerOperator);
          self.customDataFields(data.customDataFields);
          self.isDriver(data.isDriver);
        });
    };

    self.loadPage();
  }

  handleViewTPIRates = async (orderId) => {
    this.dispatchAction(isLoading(true));
    const result = await RateService.getSavedRatesForOrder(orderId);
    this.dispatchAction(isLoading(false));

    this.tpiViewRatesProps({
      orderId,
      rates: result.rates,
      errors: result.errors,
    });
  };

  handleGetTPIRates = async (rowData = {}) => {
    const request = TPIRateRequest();
    request.orderId = rowData.orderId;
    request.pickupDate = rowData.pickUpDate;
    request.trailerType = rowData.trailerType;
    request.transportationMode = "TL";

    request.requestType = "ORDER";

    const originCSZ = mapToCityStateZipObject(
      cleanString(["(", ")", ","], rowData.pickup).split(" ")
    );

    request.originCity = originCSZ.city;
    request.originState = originCSZ.state;
    request.originZip = originCSZ.zip;

    const destinationCSZ = mapToCityStateZipObject(
      cleanString(["(", ")", ","], rowData.destination).split(" ")
    );

    request.destinationCity = destinationCSZ.city;
    request.destinationState = destinationCSZ.state;
    request.destinationZip = destinationCSZ.zip;

    request.externalBoardNames = ["DAT", "TRUCKSTOP"];

    this.dispatchAction(isLoading(true));
    const result = await RateService.getTPIRates(request);
    this.dispatchAction(isLoading(false));

    if (result.errors.length && result.rates.length === 0) {
      showmessage(result.errors[0]);
      return;
    }

    this.tpiViewRatesProps({
      orderId: request.orderId,
      rates: result.rates,
      errors: result.errors,
    });

    this.$grid.jqxGrid("updaterow", rowData.rowId, {
      ...rowData,
      hasTPIRate: true,
    });
  };
}

import template from "./orders-page.html";
import RateService, { TPIRateRequest } from "../../../services/RateService";
import { showmessage } from "show-dialog-methods";
import { cleanString, mapToCityStateZipObject } from "global-functions";
export default { viewModel: OrdersPageViewModel, template: template };
