// Configuration for a 'global' notyf instance;
import { Notyf } from 'notyf'; // Taost notificaitons -> https://github.com/caroso1222/notyf

const toastNotify = new Notyf({
    duration: 3000,
    position: {
      x: 'right',
      y: 'top',
    },
    types: [
      {
        type: 'info',
        background: '#488bf7',
        icon: {
          className: 'glyphicon glyphicon-info-sign',
          tagName: 'i',
          color: '#fff'
        }
      }
    ]
  });

  export default toastNotify;