import dataModel from "data-model";
import userProfile from "user-profile";
import { isEmpty } from "global-functions";

class EditCustomerRequiredFieldsViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.formData = params.formData || {};

        this.errors = ko.observable();
        this.isProcessing = ko.observable(false);

        this.saveBtnText = ko.computed(() => {
            return this.isProcessing() ? "Saving..." : "Save";
        });

        this.requiredFieldId = ko.observable(this.formData.id);
        this.pageName = ko.observable(this.formData.pageId).extend({
            required: {
                message: "Please select page",
            },
        });
        this.pageNamePlaceholder = ko.observable(
            this.formData.page || "Please Choose.."
        );
        this.originalFieldName = ko.observable(this.formData.fieldNameId);
        this.fieldName = ko
            .validatedObservable(this.formData.fieldName)
            .extend({
                required: {
                    message: "Field Name is required",
                },
            });
        this.customerName = ko.observable(this.formData.customer);
        this.customerId = ko.observable(this.formData.customerId);

        this.isEdit = ko.observable(this.formData.id > 0);
        this.selectedCustomer = ko.validatedObservable().extend({
            required: {
                onlyIf: () => this.isEdit() == false,
                message: "Please select a customer",
            },
        });

        this.selectedCustomer.subscribe((val) => {
            if (val) this.customerId(val);
        });

        this.pageList = ko.observableArray();

        this.fieldnamesAutoCompleteURL = ko.computed(() => {
            return this.isEdit()
                ? "MyAgency/AutocompleteCustomerRequiredFieldsEDIT"
                : "MyAgency/AutocompleteCustomerRequiredFieldsADD";
        });

        this.initializeComponent();
    }

    initializeComponent = () => {
        this.loadRequiredPages();
    };

    async loadRequiredPages() {
        const payload = {
            customerId: this.customerId(),
            agencyId: dataModel.currentAgencyId(),
        };

        const pageData = await this.http_sendGetRequiredPagesAsync(
            payload
        ).catch((err) =>
            console.error("Retrieving Customer Required Pages failed.")
        );

        if (pageData) this.pageList(pageData.pageList);
    }

    handleSaveRequiredField = () => {
        this.doHandleSaveRequiredField();
    };

    async doHandleSaveRequiredField() {
        ko.validation.group([this.fieldName, this.selectedCustomer]);

        this.isProcessing(true);

        const payload = {
            Id: this.requiredFieldId(),
            customerId: this.formData.customerId || this.selectedCustomer(),
            fieldNameId: this.fieldName(),
            agencyId: userProfile.currentAgencyId(),
        };

        const result = await this.http_sendSaveRequiredFieldAsync(
            payload
        ).catch((msg) => this.errors(msg));
        this.isProcessing(false);

        if (result) this.$emitsOnAction();
    }

    http_sendGetRequiredPagesAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/GetCustomerRequiredPages",
                    "get",
                    payload
                )
                .done((data) => resolve(data))
                .fail((err) => reject(err));
        });
    };

    http_sendSaveRequiredFieldAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/UpsertCustomerRequiredField",
                    "post",
                    payload
                )
                .done((data) => resolve(data))
                .fail((err) => {
                    if (err.responseJSON && err.responseJSON.message)
                        reject(err.responseJSON.message);

                    console.error(err);
                });
        });
    };
}

import template from "./edit-customer-required-fields-component.html";
export default {
    viewModel: EditCustomerRequiredFieldsViewModel,
    template: template,
};
