import dataModel from 'data-model';
import zxcvbn from 'zxcvbn/dist/zxcvbn'; //https://github.com/dropbox/zxcvbn 

const _passwordStrengthScoreEnum = {
    0: 0,
    1: 25,
    2: 50,
    3: 75,
    4: 100
};

const _passwordStrengthBarTxtEnum = {
    0: "VERY WEAK",
    25: "WEAK",
    50: "GOOD",
    75: "STRONG",
    100: "VERY STRONG"
};

const _passwordStrengthBarColorEnum = {
    0: "progress-bar-danger",
    25: "progress-bar-danger",
    50:  "progress-bar-warning",
    75: "progress-bar-info",
    100: "progress-bar-success"
};

const WireSettingsPasswordModalComponent = function(params) {
    params = params || {};
    this.displayModal = params.displayModal || ko.observable(false);

    this.onClose = params.onClose && typeof params.onClose === "function"
            ? params.onClose
            : function () {};

    this.$owner = params.$owner || {};
    this.settings = params.settings || {};
    this.errorMsgs = ko.observableArray([]);
    this.isSuccess = ko.observable(false);

    $("#wire-settings-password-modal").on("hide.bs.modal", () => {   
        this.onClose(this.isSuccess());
    });

    $("#wire-settings-password-modal").on('hidden.bs.modal', (e) => {
        this.cleanUp();
    });

    this.password = ko.observable().extend({
        required: true,
        validation: {
            message: "Please enter a value greater or equal to 8",
            validator: () => {
                let pw = ko.unwrap(this.password());

                return pw ? pw.length >= 8 : false;
            }
        }
    });

    this.passwordConfirm = ko.observable().extend({ 
        required: true,
        validation: {
            message: "Passwords do not match.",
            validator: () => {
                return ko.unwrap(this.password()) == ko.unwrap(this.passwordConfirm())
            },
        },
    });

    // Percent is based on strength 'steps' 1,2,3,4 -> 25%,50%,75%,100%
    this.determinePasswordStrength = ko.pureComputed(() => {
        let percent = 0;

        if(this.password()) {
            const checkResult = zxcvbn(this.password());

            percent = _passwordStrengthScoreEnum[checkResult.score];
        }
        
        return percent;
    });

    this.passwordStrengthBarPercent = ko.pureComputed(() => {
        let percent = this.determinePasswordStrength();
        return percent + "%";
    });

    this.passwordStrengthBarTxt = ko.pureComputed(() => {
        let percent = this.determinePasswordStrength();

        return _passwordStrengthBarTxtEnum[percent];
    });

    this.passwordStrengthBarColor = ko.pureComputed(() => {
        let percent = this.determinePasswordStrength();

        return _passwordStrengthBarColorEnum[percent];
    });

    this.isSaveBtnEnabled = ko.pureComputed(() => {
        let errors = ko.validation.group(this);
        return errors().length == 0;
    });

    // NIST PW Guidelines suggest making passwords visible while user types, allowing them to
    // enter longer passwords, b/c they can see what they enter.
    let timer, waitTimer = 500;
    this.handleInputKeypress = (koContext, e) => {
        clearTimeout(timer);
        let currentTarget = e.currentTarget;
        $(currentTarget).attr({'type': 'text'});
        
        return true;
    }

    this.handleInputKeyup = (koContext, e) => {
        clearTimeout(timer);
        let currentTarget = e.currentTarget;

        timer = setTimeout(() => {
            $(currentTarget).attr({'type': 'password'});
        }, waitTimer);

        return true;
    }

    this.handleFocusout = (koContext, e) => {
        clearTimeout(timer);
        let currentTarget = e.currentTarget;

        $(currentTarget).attr({'type': 'password'});
    }

    this.handleSave = async () => {
        this.errorMsgs.removeAll();
        const errors = ko.validation.group(this);

        if(errors().length) {
            this.errorMsgs.push("Please correct any errors.");
            return false;
        }

        const payload = {
            password: this.password(),
            passwordConfirm: this.passwordConfirm()
        };

        let result = await this.http_sendSave(payload).catch((err) => this.errorMsgs.push(err));
        
        if(result && result.errors) {
            this.errorMsgs(result.errors);

            return false;
        } 

        this.isSuccess(true);
        
        $("#wire-settings-password-modal").modal('hide');
    }

    this.cleanUp = () => {
        this.passwordConfirm(null);
        this.password(null);
        this.errorMsgs.removeAll();
        this.isSuccess(false);
    }

    this.http_sendSave = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("MyProfile/UpsertWirePassword", "POST", payload).done((response) => resolve(response))
            .fail((error) => {
                reject(
                    (error.responseJSON && error.responseJSON.message) ||
                        "Error occurred sending code. Please try again or contact Help Desk."
                );
            })
        })
    }
}

import template from "./wire-settings-password-modal-component.html";
export default { viewModel: WireSettingsPasswordModalComponent, template: template }