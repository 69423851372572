import { isDeviceMobileOrTablet } from 'global-functions';
const playerViewModel = function({
    videoPlayerContent
}) {
    const vm = this;

    vm.videoPlayerContent = videoPlayerContent;
    vm.displayDataUsageConfirmation = ko.observable(isDeviceMobileOrTablet())
}

import template from './player-component.html';
export default { viewModel: playerViewModel, template: template }