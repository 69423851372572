
import {models} from 'powerbi-client' // importing from powerbi-client, also sets a built powerbi client to window object...
const powerbi = ('powerbi' in window && window['powerbi']) || {};

import ssasConfig from './config.js';
import geHTTP from 'data-model';

function validate({
    $containerEl = undefined,
    embedUrl = undefined,
    embedToken = undefined
}={}) {
    if(!$containerEl) {
        throw new Error(`$containerEl is not defined.`);
    }

    if(!embedUrl) {
        throw new Error(`Embed URL is required.`)
    }

    if(!embedToken) {
        throw new Error(`Embed Token is missing or you do not have permission.`)
    }
}

function SSASReporting() {
    
    // NOTE: Need to get a new accesstoken for each new report request...
    // The report's embed token expiration is generated based off of the sent accesstoken expiration when making the request.
    const getAccessToken = async () => {
        return new Promise((resolve, reject) => {
            geHTTP.ajaxRequest(ssasConfig.tokenURL, "GET", null, true)
                .done((token) => {
                    resolve((token.accessToken));
                })
                .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred during request.`));
            })
    }

    const getReportsAsync = async () => {
        const accessToken = await getAccessToken();

        const options = {
            method: 'get', 
            mode: 'cors',
            withCredentials: true, 
            credentials: 'include', 
            headers: { 
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': "application/json"
            }
          }
      
          const {value} = await fetch(ssasConfig.apiURL + "reports", options).then(res => res.json()).catch(() => ({ value: [] }));
          return (value || []).map(({id, name, embedUrl, datasetId, webUrl}) => ({id, name, embedUrl, datasetId, webUrl}));
          
    }


    const getReportEmbedToken = async ({
        reportId = "", 
        datasetIds = [], 
        username = "EffectiveIdentity",
        roles = []
    } = {}) => {
        const accessToken = await getAccessToken();

        const options = {
            method: 'POST', 
            mode: 'cors',
            withCredentials: true, 
            credentials: 'include', 
            headers: { 
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(
                {
                    "accessLevel": "View",
                    "allowSaveAs": "false",
                    "identities": [
                        {
                          "username": username,
                          "roles": [...roles],
                          "datasets": [...datasetIds],
                          "reports": [reportId]
                        },
                    ]
                }
            )
          }
      
          const url = `${ssasConfig.apiURL}reports/${reportId}/GenerateToken`;
          const {token} = await fetch(url, options).then(res => res.json()).catch(() => ({}));
          
          return token || "";
    }

    const embedTo = async ($containerEl, {
        embedUrl = "",
        reportId = "",
        datasetIds = [],
        username = "EffectiveIdentity",
        roles = [],
        settings = {
            filterPaneEnabled: true,
            navContentPaneEnabled: true,
            layoutType: models.LayoutType.Custom,
            customLayout: {
                displayOption: models.DisplayOption.FitToPage
            }
        }
    } = {}) => {
        
        const embedToken = await getReportEmbedToken({reportId, datasetIds, username, roles}); // lasts a few hours

        validate({$containerEl, embedToken, embedUrl});

        return powerbi.embed($containerEl[0], {
            type: 'report',
            tokenType: models.TokenType.Embed,
            accessToken: embedToken, // Report access token, not the api token
            embedUrl,
            id: reportId,
            permissions: models.Permissions.All,
            settings: settings || {}
        });
    }

    return {
        getReportsAsync,
        embedTo
    }

}

const instance = SSASReporting();
export default instance;