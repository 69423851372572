//@ts-check
/// <reference path="../../../types/jquery.plugins.d.ts" />

import dataModel from 'data-model';
import template from './footer-component.html';


class OrderPlanningFooterComponent {
    $parent
    userManuals = ko.observableArray();

    constructor(params) {
        this.$parent = params.data;
        this.$parent.footerComponent = this;
        this.$parent.loadLotsOfStuff();
    }

    loadUserManualsAndCustomDataFields = () => {
        return new Promise((resolve, reject) => {
            this.$parent.isLoading(true);
            dataModel.ajaxRequest("OrderPlanning/GetUserManualsAndCustomFieldNames", "get")
                .done((data, status, xhr) => {
                    this.userManuals(data.userManuals);
                    this.$parent.customDataFields(data.customDataFields);
                    this.$parent.isLoading(false);
                    resolve();
                })
                .fail(() => {
                    this.$parent.isLoading(false);
                    resolve();
                }
            );
        })
    };
}

export default {viewModel: OrderPlanningFooterComponent, template: template}