import dataModel from "data-model";
import ko from "knockout";
import {
  isDeviceMobileOrTablet,
  getShortCutsDate,
  mobilecheck,
  formatPercentage,
  formatPhoneNumber,
  rawNumber,
  datetimeUTC,
  formatDatetimeStr,
} from "global-functions";
import dayjs from "dayjs";
import _, { last } from "lodash";
import userProfile from "user-profile";
import "jquery-ui/ui/widgets/datepicker";
import GreatEdgeCustomWidgets from "ge.custom-widgets";
import timepicker from "timepicker"; // https://www.npmjs.com/package/timepicker/v/1.11.14 - used in geTimepicker

import "./ko.ge-mobile-select-list";
import "./ko.ge-numeric";
import "./ko.ge-window";
import "./ko.ge-checkbox";
import "./ko.ge-button";
import gePhone from "./ko.ge-phone";
("./ko.ge-phone");
gePhone(ko);

function koBindingHandlers() {
  ko.bindingHandlers.geInputWithComboBox = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      const source = valueAccessor() || ko.observableArray([]),
        allBindings = allBindingsAccessor(),
        selectedValue = allBindings.selectedValue || ko.observable(),
        checkedItems = allBindings.checkedItems || ko.observableArray(),
        valueProp = allBindings.valueProp || "value",
        filterList = allBindings.filterList || false,
        filterProp = allBindings.filterProp || "label",
        multiselect = allBindings.multiselect || false,
        placeholder = allBindings.placeholder || "Search...",
        width = allBindings.width || "150px",
        onClose = allBindings.onClose || function () {},
        onOpen = allBindings.onOpen || function () {},
        includeSelectAll = allBindings.includeSelectAll || false,
        treeOptions = allBindings.treeOptions || {};

      const flags = {
        sourceUpdated: false,
        isPopupOpen: false,
        selectedValueUpdatedByWidget: false,
        checkedValFromInput: false,
        selectAllState: false,
        isWidgetInit: false,
      };

      const $el = $(element);
      $el.css({ position: "relative", margin: "5px" });

      let id = $el.attr("id");

      const $input = $("<input>", {
        type: "text",
        id: id,
        style: `width: ${width}; text-transform: uppercase; height: 25px; min-width: 100px;border-top-left-radius: 3px;border-bottom-left-radius: 3px;padding: 5px;`,
        placeholder: placeholder,
      });
      $input.attr({ autocomplete: "off" });
      const $showAllBtn = $(
        '<button tabindex="-1" class="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-button-icon" role="button" ><span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"></span><span class="ui-button-text">&nbsp;</span></button>'
      );
      $showAllBtn.css({
        height: $input.height(),
        width: "1.5em",
        position: "absolute",
      });

      const hasThreeStates = (source = []) => {
        return source.some((x) => {
          let isArray = false;

          if (typeof x !== "string" && Object.keys(x).length > 0) {
            isArray = Object.keys(x).some((q) => Array.isArray(x));
          }

          return isArray;
        });
      };

      const getSource = (source = []) => {
        // if(includeSelectAll && multiselect) {
        //     return [{ label: "Select All", value: null }, ...source];
        // }
        // else {
        //     return source;
        // }

        return source;
      };

      const $tree = $("<div>");
      $tree.jqxTree({
        source: getSource(source()),
        hasThreeStates:
          multiselect ||
          treeOptions.checkboxes ||
          treeOptions.hasThreeStates ||
          hasThreeStates(source()),
        checkboxes: multiselect || treeOptions.checkboxes,
        allowDrag: false,
        allowDrop: false,
        width: treeOptions.width || 200,
        height: treeOptions.height || 300,
      });

      const $jqxBtn = $("<div>");
      $jqxBtn.append($tree);
      $jqxBtn.jqxDropDownButton({ animationType: "none" });
      $jqxBtn.css({
        position: "absolute",
        left: "0",
        top: "0",
        visibility: "hidden",
      });

      const submitItems = () => {
        const labels = $input
          .val()
          .split(",")
          .filter((x) => x && x.length);
        const itemObjs = $tree
          .jqxTree("getCheckedItems")
          .filter((x) => x.label != "Select All");

        flags.selectedValueUpdatedByWidget = true;
        flags.checkedValFromInput = false;

        if (multiselect == false) {
          const val = source().find((x) => x.label == labels[0]) || {};
          selectedValue(val[valueProp]);
          onClose(val);
        } else {
          const vals = filterList ? itemObjs.map((x) => x[valueProp]) : labels;
          selectedValue(vals);
          checkedItems(itemObjs);
          onClose(itemObjs, labels);
        }
      };

      const checkUncheckTypedInputItems = () => {
        const val = $input.val() || "",
          _filterProp = filterProp,
          _valueProp = valueProp;

        const findNextItemMatch = (nextItem = {}, itemsToMatch = []) => {
          if (!nextItem || Object.keys(nextItem).length == 0) return null;
          if (!itemsToMatch || itemsToMatch.length == 0 || !itemsToMatch[0])
            return null;

          if (
            itemsToMatch.some(
              (x) =>
                (nextItem[_filterProp] &&
                  x.toUpperCase() == nextItem[_filterProp].toUpperCase()) ||
                (nextItem[_valueProp] &&
                  x.toUpperCase() == nextItem[_valueProp].toUpperCase())
            )
          )
            return nextItem;
          if (!nextItem.nextItem) return null;

          return findNextItemMatch(nextItem.nextItem, itemsToMatch);
        };

        const inputVals = val.split(",");
        const items = $tree.jqxTree("getItems");
        const checkedItems = $tree.jqxTree("getCheckedItems");
        flags.checkedValFromInput = true;

        items.map((x) => {
          if (x.hasItems) {
            const match = findNextItemMatch(x.nextItem, inputVals);
            if (match) {
              $tree.jqxTree("checkItem", match.element, true);
            }
          } else {
            let check = false;
            const fp = x[filterProp];
            const vp = x[valueProp];
            if (
              inputVals.some(
                (q) =>
                  q &&
                  fp &&
                  (typeof fp === "string"
                    ? q.toUpperCase() == fp.toUpperCase()
                    : parseInt(q) == fp)
              )
            ) {
              check = true;
            } else if (
              inputVals.some(
                (q) =>
                  q &&
                  vp &&
                  (typeof vp === "string"
                    ? q.toUpperCase() == vp.toUpperCase()
                    : parseInt(q) == vp)
              )
            ) {
              check = true;
            } else if (x.label == "Select All") {
              check = items.length == checkedItems.length;
              flags.selectAllState = check;
            } else {
              check = false;
            }

            $tree.jqxTree("checkItem", x.element, check);
          }
        });

        flags.checkedValFromInput = false;
      };

      $showAllBtn.click(function () {
        if (flags.isPopupOpen) {
          $jqxBtn.jqxDropDownButton("close");
          return false;
        }

        $input.focus();
        var searchText = $input.val();
        if (searchText == "") {
          searchText = " ";
        }

        $jqxBtn.jqxDropDownButton("open");

        flags.isPopupOpen = true;
        return false;
      });

      let timer,
        waitTimer = 800;
      $input.on("keyup", function (event) {
        if (filterList == false) {
          clearTimeout(timer);

          if (event.keyCode == 13 || event.keyCode == 9) {
            submitItems();
          } else {
            timer = setTimeout(() => {
              checkUncheckTypedInputItems();
              submitItems();
            }, waitTimer);
          }
        } else {
          const filtered = source().filter(
            (x) => x[filterProp].indexOf($input.val()) > -1
          );
          $tree.jqxTree({ source: filtered });
          $tree.jqxTree("refresh");
        }
      });

      $input.on("keypress", () => clearTimeout(timer));
      $input.on("focusout", () => submitItems());

      if (filterList) {
        $input.on("focusin", function () {
          $jqxBtn.jqxDropDownButton("open");
        });
      }

      if (multiselect == false) {
        $tree.on("itemClick", function (event) {
          const args = event.args || {};
          const item = $tree.jqxTree("getItem", args.element);
          const label = item.label;

          $input.val(`${label}`);

          $jqxBtn.jqxDropDownButton("close");
        });
      } else {
        // on select here we will check the item as well.
        // the jqx widget behaves differently depending on if multiselect, etc.
        $tree.on("select", function (event) {
          const args = event.args || {};
          const item = $tree.jqxTree("getItem", args.element);

          $tree.jqxTree("checkItem", item.element, !item.checked);
        });

        $tree.on("checkChange", function (event) {
          if (flags.checkedValFromInput || filterList || flags.sourceUpdated) {
            return false;
          }

          const args = event.args || {};
          if (args.checked == undefined) return false;
          const currentItem = $tree.jqxTree("getItem", args.element);

          const items = $tree.jqxTree("getItems");
          const checkedItems = $tree.jqxTree("getCheckedItems");

          // if(currentItem && currentItem.label == 'Select All' && currentItem.checked != flags.selectAllState) {
          //     flags.selectAllState = !flags.selectAllState; // set to opposite (flipping)
          //     toggleAllItems(flags.selectAllState);
          // }
          // else if(items.length != checkedItems.length && checkedItems.length && checkedItems[0].label == 'Select All' && checkedItems[0].checked) {
          //     flags.selectAllState = false;
          //     $tree.jqxTree('uncheckItem', checkedItems[0].element);
          // }

          const itemLabels = checkedItems
            .filter((x) => x != "Select All")
            .map((x) => x[valueProp])
            .filter((x) => x && (typeof x === "string" ? x.length : true))
            .reverse();

          if (itemLabels.length > 1) {
            $input.val(itemLabels.join(","));
          } else if (itemLabels.length == 1) {
            $input.val(itemLabels[0]);
          } else {
            $input.val("");
          }
        });
      }

      const toggleAllItems = (selectAll = false) => {
        if (selectAll) {
          $tree.jqxTree("checkAll");
        } else {
          $tree.jqxTree("uncheckAll");
        }
      };

      $jqxBtn.on("close", () => {
        if ($input.is(":focus") && filterList) {
          $jqxBtn.jqxDropDownButton("open");
        } else {
          flags.isPopupOpen = false;
          $tree.jqxTree("collapseAll");
          submitItems();
        }
      });

      $jqxBtn.on("open", () => onOpen());

      $el.append($input);
      $el.append($showAllBtn);
      $el.append($jqxBtn);
      $el.wrap($("<div>"));

      if (ko.unwrap(selectedValue)) {
        const val = ko.unwrap(selectedValue);

        if (typeof val === "string") {
          $input.val(val);

          if (multiselect) {
            checkUncheckTypedInputItems();
          }
        }

        if (Array.isArray(val) && val.length) {
          const vals = val.join(",");
          $input.val(vals);

          if (multiselect) {
            checkUncheckTypedInputItems();
          }
        }
      }

      if (ko.isSubscribable(source)) {
        source.subscribe((val = []) => {
          flags.sourceUpdated = true;
          $tree.jqxTree({ source: getSource(val) });
          flags.sourceUpdated = false;

          if ($input.val() && $input.val().length) {
            if (multiselect) {
              checkUncheckTypedInputItems();
            }

            submitItems();
          }
        });
      }

      if (ko.isSubscribable(selectedValue)) {
        selectedValue.subscribe((val) => {
          if (flags.selectedValueUpdatedByWidget) {
            flags.selectedValueUpdatedByWidget = false;
            return false;
          }

          if (val && val.length) {
            if (typeof val === "string") {
              $input.val(val.toUpperCase());
            }

            if (Array.isArray(val) && val.length) {
              const valsStr = val
                .filter((x) => x && x.length)
                .map((x) => x.toUpperCase())
                .join(",");
              $input.val(valsStr);
            }

            if (multiselect) {
              checkUncheckTypedInputItems();
            }
          } else {
            $input.val("");
            toggleAllItems(); // uncheck all
          }
        });
      }

      $tree.jqxTree("refresh");
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  // To use: <div data-bind="debug"></div>
  // ...will display ko context in a readable JSON format.
  // ...order of context -> $data if available -> div's $parent context
  ko.bindingHandlers.debug = {
    init: function init(
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      let el = $(element),
        context =
          (bindingContext && bindingContext.$data) ||
          bindingContext.$parent ||
          null,
        params = allBindingsAccessor();

      let jsonData = ko.toJSON(context, null, 2);

      let pre = $("<pre></pre>", { text: jsonData, style: "padding: 10px" });
      el.append(pre);
    },
    //update: function update(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {}
  };

  ko.bindingHandlers.stripe = {
    update: function (element, valueAccessor, allBindingsAccessor) {
      var value = ko.utils.unwrapObservable(valueAccessor()),
        allBindings = allBindingsAccessor(),
        even = allBindings.evenClass,
        odd = allBindings.oddClass,
        $el = $(element);

      //update odd rows
      $el.children(":nth-child(odd)").addClass(odd).removeClass(even);
      //update even rows
      $el.children(":nth-child(even)").addClass(even).removeClass(odd);
    },
  };
  ko.bindingHandlers.stopBinding = {
    init: function () {
      return { controlsDescendantBindings: true };
    },
  };
  ko.bindingHandlers.enterkey = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var allBindings = allBindingsAccessor();
      $(element).on("keypress", function (e) {
        var keyCode = e.which || e.keyCode;
        if (keyCode != 13) {
          return true;
        }
        var target = e.target;
        target.blur();
        allBindings.enterkey.call(viewModel, viewModel, target, element);
        return false;
      });
    },
  };

  ko.bindingHandlers.slideVisible = {
    init: function (element, valueAccessor) {
      // Initially set the element to be instantly visible/hidden depending on the value
      var value = valueAccessor();
      $(element).toggle(ko.unwrap(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
    },
    update: function (element, valueAccessor) {
      // Whenever the value subsequently changes, slowly fade the element in or out
      var value = valueAccessor();
      ko.unwrap(value) ? $(element).slideDown() : $(element).slideUp();
    },
  };

  ko.bindingHandlers.hidden = {
    update: function (element, valueAccessor) {
      var value = ko.utils.unwrapObservable(valueAccessor());
      ko.bindingHandlers.visible.update(element, function () {
        return !value;
      });
    },
  };

  ko.bindingHandlers.geListBox = {
    init: function init(
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var div = $(element);
      var selectedValue = allBindingsAccessor().selectedValue;
      div.jqxListBox({
        source: valueAccessor()(),
        width: div[0].attributes.width ? div[0].attributes.width.value : 200,
        height: div[0].attributes.width ? div[0].attributes.width.value : 200,
        theme: "GreatWide",
      });
      valueAccessor().subscribe(function (value) {
        $(element).jqxListBox("clear");
        value.forEach(function (item, index, array) {
          $(element).jqxListBox("addItem", {
            label: item.label,
            value: item.value,
          });
        });
      });
      div.on("select", function (event) {
        if (selectedValue) {
          selectedValue({
            value: event.args.item.value,
            label: event.args.item.label,
          });
        }
      });
    },
    update: function update(
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geDualSelect = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var values = ko.unwrap(valueAccessor());
      var allBindings = allBindingsAccessor();
      var availableProp = allBindings.availableProp;
      var selectedProp = allBindings.selectedProp;
      var valueProp = allBindings.valueProp;
      var textProp = allBindings.textProp || valueProp;
      var dataAttributes = allBindings.dataAttributes;
      var moveToAvailableHandler = allBindings.moveToAvailableHandler;
      var moveToSelectedHandler = allBindings.moveToSelectedHandler;

      if (!valueProp) {
        console.error(
          "No value property has been specified for the object to be used with the geDualSelect control."
        );
        return;
      }

      if (values) {
        var option = undefined;
        $.each(values[availableProp], function (i, v) {
          option = $("<option>").val(v[valueProp]).text(v[textProp]);

          if (dataAttributes)
            for (var attribute in dataAttributes)
              if (dataAttributes[attribute])
                $(option).attr(
                  "data-" + attribute,
                  v[dataAttributes[attribute]]
                );

          $(element).append(option);
        });

        $.each(values[selectedProp], function (i, v) {
          option = $("<option>").val(v[valueProp]).text(v[textProp]);

          if (dataAttributes)
            for (var attribute in dataAttributes)
              if (dataAttributes[attribute])
                $(option).attr(
                  "data-" + attribute,
                  v[dataAttributes[attribute]]
                );

          $(element.id + "_to").append(option);
        });
      }

      $(element).multiselect({
        moveToRight: moveToSelectedHandler,
        moveToLeft: moveToAvailableHandler,
      });
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var values = ko.unwrap(valueAccessor());
      var allBindings = allBindingsAccessor();
      var availableProp = allBindings.availableProp;
      var selectedProp = allBindings.selectedProp;
      var valueProp = allBindings.valueProp;
      var textProp = allBindings.textProp || valueProp;
      var dataAttributes = allBindings.dataAttributes;
      var moveToAvailableHandler = allBindings.moveToAvailableHandler;
      var moveToSelectedHandler = allBindings.moveToSelectedHandler;

      if (!valueProp) {
        console.error(
          "No value property has been specified for the object to be used with the geDualSelect control."
        );
        return;
      }

      if (values) {
        var option = undefined;
        $.each(values[availableProp], function (i, v) {
          option = $("<option>").val(v[valueProp]).text(v[textProp]);

          if (dataAttributes)
            for (var attribute in dataAttributes)
              if (dataAttributes[attribute])
                $(option).attr(
                  "data-" + attribute,
                  v[dataAttributes[attribute]]
                );

          $(element).append(option);
        });

        $.each(values[selectedProp], function (i, v) {
          option = $("<option>").val(v[valueProp]).text(v[textProp]);

          if (dataAttributes)
            for (var attribute in dataAttributes)
              if (dataAttributes[attribute])
                $(option).attr(
                  "data-" + attribute,
                  v[dataAttributes[attribute]]
                );

          $("#" + element.id + "_to").append(option);
        });
      }

      $(element).multiselect({
        moveToRight: moveToSelectedHandler,
        moveToLeft: moveToAvailableHandler,
      });
    },
  };

  ko.bindingHandlers.geOptGroupDualSelect = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var values = ko.unwrap(valueAccessor());
      var allBindings = allBindingsAccessor();
      var availableProp = allBindings.availableProp;
      var selectedProp = allBindings.selectedProp;
      var optGroupValueProp = allBindings.optGroupValueProp;
      var optGroupTextProp = allBindings.optGroupTextProp || optGroupValueProp;
      var valueProp = allBindings.valueProp;
      var textProp = allBindings.textProp || valueProp;
      var moveToAvailableHandler = allBindings.moveToAvailableHandler;
      var moveToSelectedHandler = allBindings.moveToSelectedHandler;

      if (!valueProp) {
        console.error(
          "No value property has been specified for the object to be used with the geDualSelect control."
        );
        return;
      }

      $("#" + element.id + ",#" + element.id + "_to").empty();

      if (values) {
        var optgroup = undefined;
        if (values[availableProp].length) {
          $.each(values[availableProp], function (i, av) {
            if (av.length) {
              optgroup = $("<optgroup>")
                .val(av[0][optGroupValueProp])
                .text(av[0][optGroupTextProp]);
              $(element).append(optgroup);
              $.each(av, function (i, v) {
                $(optgroup).append(
                  $("<option>")
                    .val(v[valueProp])
                    .text(v[textProp])
                    .data([optGroupValueProp], av[0][optGroupValueProp])
                );
              });
            }
          });
        }

        if (values[selectedProp].length) {
          $.each(values[selectedProp], function (i, sv) {
            if (sv.length) {
              optgroup = $("<optgroup>")
                .val(sv[0][optGroupValueProp])
                .text(sv[0][optGroupTextProp]);
              $("#" + element.id + "_to").append(optgroup);
              $.each(sv, function (i, v) {
                $(optgroup).append(
                  $("<option>")
                    .val(v[valueProp])
                    .text(v[textProp])
                    .data([optGroupValueProp], sv[0][optGroupValueProp])
                );
              });
            }
          });
        }
      }

      $(element).multiselect({
        moveToRight: moveToSelectedHandler,
        moveToLeft: moveToAvailableHandler,
      });
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var values = ko.unwrap(valueAccessor());
      var allBindings = allBindingsAccessor();
      var availableProp = allBindings.availableProp;
      var selectedProp = allBindings.selectedProp;
      var optGroupValueProp = allBindings.optGroupValueProp;
      var optGroupTextProp = allBindings.optGroupTextProp || optGroupValueProp;
      var valueProp = allBindings.valueProp;
      var textProp = allBindings.textProp || valueProp;
      var moveToAvailableHandler = allBindings.moveToAvailableHandler;
      var moveToSelectedHandler = allBindings.moveToSelectedHandler;

      if (!valueProp) {
        console.error(
          "No value property has been specified for the object to be used with the geDualSelect control."
        );
        return;
      }

      $("#" + element.id + ",#" + element.id + "_to").empty();

      if (values) {
        var optgroup = undefined;
        if ([].concat.apply([], values[availableProp]).length) {
          $.each(values[availableProp], function (i, av) {
            if (av.length) {
              optgroup = $("<optgroup>").attr({
                label: av[0][optGroupTextProp],
                "data-id": av[0][optGroupValueProp],
              });
              $(element).append(optgroup);
              $.each(av, function (i, v) {
                var option = $("<option>")
                  .val(v[valueProp])
                  .text(v[textProp])
                  .attr(
                    "data-" + [optGroupValueProp],
                    av[0][optGroupValueProp]
                  );
                $(optgroup).append(option);
              });
            }
          });
        }

        if ([].concat.apply([], values[selectedProp]).length) {
          $.each(values[selectedProp], function (i, sv) {
            if (sv.length) {
              optgroup = $("<optgroup>").attr({
                label: sv[0][optGroupTextProp],
                "data-id": sv[0][optGroupValueProp],
              });
              $("#" + element.id + "_to").append(optgroup);
              $.each(sv, function (i, v) {
                var option = $("<option>")
                  .val(v[valueProp])
                  .text(v[textProp])
                  .attr(
                    "data-" + [optGroupValueProp],
                    sv[0][optGroupValueProp]
                  );
                $(optgroup).append(option);
              });
            }
          });
        }
      }

      $(element).multiselect({
        moveToRight: moveToSelectedHandler,
        moveToLeft: moveToAvailableHandler,
      });
    },
  };

  ko.bindingHandlers.slideVisible = {
    init: function (element, valueAccessor) {
      var value = valueAccessor();
      $(element).css("display", ko.unwrap(value) ? "initial" : "none");
    },
    update: function (element, valueAccessor) {
      var value = valueAccessor();
      ko.unwrap(value) ? $(element).slideDown() : $(element).slideUp();
    },
  };

  ko.bindingHandlers.jqCheckListBox = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var values = valueAccessor();
      var allBindings = allBindingsAccessor();
      var checkboxes = allBindings.checkboxes || false;
      var width = allBindings.width || "200";
      var height = allBindings.height || "250px";
      $(element).jqxListBox({
        source: ko.unwrap(values),
        checkboxes: checkboxes,
        width: width,
        height: height,
      });
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var values = valueAccessor();
      var allBindings = allBindingsAccessor();
      var checkboxes = allBindings.checkboxes || false;
      var width = allBindings.width || "200";
      var height = allBindings.height || "250px";
      $(element).jqxListBox({
        source: ko.unwrap(values),
        checkboxes: checkboxes,
        width: width,
        height: height,
      });
    },
  };

  ko.bindingHandlers.jqAuto = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var options = valueAccessor() || {},
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        modelValue = allBindings.jqAutoValue,
        source = allBindings.jqAutoSource,
        query = allBindings.jqAutoQuery,
        valueProp = allBindings.jqAutoSourceValue,
        inputValueProp = allBindings.jqAutoSourceInputValue || valueProp,
        labelProp = allBindings.jqAutoSourceLabel || inputValueProp,
        isReadOnly = allBindings.isReadOnly || ko.observable(false);

      function writeValueToModel(valueToWrite) {
        if (ko.isWriteableObservable(modelValue)) {
          modelValue(valueToWrite);
        } else {
          //write to non-observable
          if (
            allBindings["_ko_property_writers"] &&
            allBindings["_ko_property_writers"]["jqAutoValue"]
          ) {
            allBindings["_ko_property_writers"]["jqAutoValue"](valueToWrite);
          }
        }
      }
      // handle the select event of the autocomplete
      //on a selection write the proper value to the model
      options.select = function (event, ui) {
        writeValueToModel(ui.item ? ui.item.actualValue : null);
        //some way here to trigger
      };
      //on a change, make sure that it is a valid value or clear out the model value
      options.change = function (event, ui) {
        //skip clearing of values if not auto select
        if (!options.autoSelect) {
          return;
        }
        var currentValue = $(element).val();
        var matchingItem = ko.utils.arrayFirst(unwrap(source), function (item) {
          return (
            unwrap(inputValueProp ? item[inputValueProp] : item) ===
            currentValue
          );
        });
        if (!matchingItem) {
          writeValueToModel(null);
        }
      };

      //hold the autocomplete current response
      var currentResponse = null;

      //handle the choices being updated in a DO, to decouple value updates from source (options) updates
      var mappedSource = ko.dependentObservable({
        read: function () {
          var mapped = ko.utils.arrayMap(unwrap(source), function (item) {
            var result = {};
            result.label = labelProp
              ? unwrap(item[labelProp])
              : unwrap(item).toString(); //show in pop-up choices
            result.value = inputValueProp
              ? unwrap(item[inputValueProp])
              : unwrap(item).toString(); //show in input box
            result.actualValue = valueProp ? unwrap(item[valueProp]) : item; //store in model
            return result;
          });
          return mapped;
        },

        write: function (newValue) {
          source(newValue); //update the source observableArray, so our mapped value (above) is correct
          if (currentResponse) {
            currentResponse(mappedSource());
          }
        },
        disposeWhenNodeIsRemoved: element,
      });

      if (query) {
        options.source = function (request, response) {
          currentResponse = response;
          query.call(this, request.term, mappedSource);
        };
      } else {
        //whenever the items that make up the source are updated, make sure that autocomplete knows it
        mappedSource.subscribe(function (newValue) {
          $(element).autocomplete("option", "source", newValue);
        });
        options.source = mappedSource();
      }

      //initialize autocomplete
      $(element).autocomplete(options).css("width", "auto");
    },

    update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      //update value based on a model change
      var allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        modelValue = unwrap(allBindings.jqAutoValue) || "",
        valueProp = allBindings.jqAutoSourceValue,
        inputValueProp = allBindings.jqAutoSourceInputValue || valueProp;
      if (Object.prototype.toString.call(modelValue) === "[object Date]") {
        modelValue = dayjs(modelValue).format("MM/DD/YYYY");
      }

      //if we are writing a different property to the input than we are writing to the model, then locate the object
      if (valueProp && inputValueProp !== valueProp) {
        var source = unwrap(allBindings.jqAutoSource) || [];
        modelValue =
          ko.utils.arrayFirst(source, function (item) {
            return unwrap(item[valueProp]) === modelValue;
          }) || {};
      }

      //update the element with the value that should be shown in the input
      $(element).val(
        modelValue && inputValueProp !== valueProp
          ? unwrap(modelValue[inputValueProp])
          : modelValue.toString()
      );
    },
  };

  ko.bindingHandlers.geCityStateZip = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        selectedValue = allBindings.selectedValue || ko.observable(),
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        source = allBindings.jqAutoSource || ko.observableArray(),
        onChanged = allBindings.onChanged,
        route = "CityStateZip",
        unwrap = ko.utils.unwrapObservable;

      var numOfItems = 10;
      var style = allBindings.style || {};

      var initValue = unwrap(modelValue);

      var container = $(element);
      var divSearch = $("<div>");
      var divInput = $("<div>");
      var showAllBtnWrap = $("<div>");
      var divComboBox = $("<div>");
      var divAuto = $("<div>");
      var input = $("<input>", {
        type: "text",
        placeHolder: "Enter City, State Zip",
        value: initValue,
      });
      var readOnlyText = $("<span>", { style: "float:left" });
      readOnlyText.html(initValue);

      var wasOpen = false;
      var showAllBtn = $(
        '<button tabindex="-1" class="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-button-icon" role="button" ><span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"></span><span class="ui-button-text">&nbsp;</span></button>'
      );
      showAllBtn.click(function () {
        numOfItems = 10;
        input.focus();
        if (wasOpen) {
          return false;
        }
        var searchText = input.val();
        if (searchText == "") {
          searchText = " ";
        }
        input.autocomplete("search", searchText);
        input.focus();
        return false;
      });
      showAllBtn.mousedown(function () {
        wasOpen = input.autocomplete("widget").is(":visible");
      });
      if (style.width) {
        divSearch.width(style.width);
      }

      divAuto.addClass("geAutoComplete");
      divComboBox.addClass("geAuto-comboBox");
      container.addClass("geAuto-container");
      divInput.addClass("geAuto-search");
      showAllBtnWrap.addClass("geAuto-all");

      divInput.append(input);
      showAllBtnWrap.append(showAllBtn);
      readOnlyText.insertAfter(container);
      container.wrap(divAuto);
      container.wrap(divSearch);
      container.wrap(divComboBox);
      container.append(divInput);
      container.append(showAllBtnWrap);

      var isTextChanged = false;

      var getMatchingItem = function (item, currentValue) {
        if (item.zip === currentValue.toLowerCase()) {
          return item;
        } else if (
          (item.zip + " (" + item.city + ", " + item.state + ")")
            .trim()
            .toLowerCase() === currentValue.toLowerCase()
        ) {
          return item;
        } else if (
          (item.city + ", " + item.state).trim().toLowerCase() ===
          currentValue.toLowerCase()
        ) {
          return {
            city: item.city,
            state: item.state,
          };
        }
        return null;
      };

      function cityStateZip(item) {
        if (item.zip != null) {
          return item.zip + " (" + item.city + ", " + item.state + ")";
        } else if (item.city != null && item.state != null) {
          return item.city + ", " + item.state;
        }
        return item;
      }

      var isChanged = false;
      function writeValueToModel(valueToWrite) {
        if (ko.isWriteableObservable(modelValue)) {
          isChanged = true;
          if (valueToWrite == undefined || valueToWrite == "") {
            modelValue(undefined);
            input.val("");
            readOnlyText.html("");
          } else {
            var textToWrite = cityStateZip(valueToWrite);
            modelValue(textToWrite);

            if (ko.isWriteableObservable(selectedValue)) {
              selectedValue(valueToWrite);
            }

            input.val(textToWrite);
            readOnlyText.html(textToWrite);
          }
          if (onChanged != null) {
            if (typeof valueToWrite == "string") {
              onChanged(undefined);
            } else {
              onChanged(valueToWrite);
            }
          }
        } else {
          if (
            allBindings["_ko_property_writers"] &&
            allBindings["_ko_property_writers"]["jqAutoValue"]
          )
            allBindings["_ko_property_writers"]["jqAutoValue"](valueToWrite);
        }
      }

      var query = function (searchTerm, sourceArray, takeFirstMatch) {
        var filterResponse = function (response) {
          if (isTextChanged || takeFirstMatch) {
            var matchItem = ko.utils.arrayFirst(response, function (item) {
              var selectedItem = getMatchingItem(item, searchTerm);
              if (selectedItem != null) {
                writeValueToModel(selectedItem);
                return true;
              }
              return false;
            });

            if (!matchItem) {
              writeValueToModel(searchTerm);
            }
          }
          isTextChanged = false;
          sourceArray(response);
        };

        var data = {
          searchText: searchTerm,
        };

        dataModel
          .ajaxRequest(route, "get", data)
          .done(function (response, status, xhr) {
            if (onChanged != null || takeFirstMatch) {
              filterResponse(response);
            } else {
              sourceArray(response);
            }
          })
          .fail(function (error, msg, d) {});
      };

      var options = {
        autoFocus: false,
        minLength: 2,
        delay: 400,
        height: 200,
      };
      options.position = {
        collision: "flip",
      };

      options.select = function (event, ui) {
        setTimeout(function () {
          showAllBtn.focus();
          input.blur();
        }, 1);
      };

      options.change = function (event, ui) {
        var currentValue = input.val().trim();
        var matchingItem = ko.utils.arrayFirst(unwrap(source), function (item) {
          var selectedItem = getMatchingItem(item, currentValue);
          if (selectedItem != null) {
            writeValueToModel(selectedItem);
            return true;
          }
          return false;
        });

        if (!matchingItem) {
          if (onChanged != null) {
            if (currentValue != "") {
              isTextChanged = true;
              input.autocomplete("search", currentValue);
              input.autocomplete("close");
            } else {
              writeValueToModel(undefined);
            }
          } else {
            writeValueToModel(currentValue);
          }
        }
      };
      options.open = function (event, ui) {
        $(this).autocomplete("widget").css("z-index", 999999);
      };

      //hold the autocomplete current response
      var currentResponse = null;

      //handle the choices being updated in a DO, to decouple value updates from source (options) updates
      var mappedSource = ko.dependentObservable({
        read: function () {
          var mapped = ko.utils.arrayMap(unwrap(source), function (item) {
            var result = {};
            if (item != null) {
              var val = cityStateZip(item);
              result.label = val;
              result.value = val;
              result.actualValue = item;
            }
            return result;
          });
          return mapped;
        },
        write: function (newValue) {
          source(newValue); //update the source observableArray, so our mapped value (above) is correct
          if (currentResponse) {
            currentResponse(mappedSource());
          }
        },
        disposeWhenNodeIsRemoved: element,
      });

      if (query) {
        options.source = function (request, response) {
          currentResponse = response;
          query.call(this, request.term, mappedSource);
        };
      } else {
        //whenever the items that make up the source are updated, make sure that autocomplete knows it
        mappedSource.subscribe(function (newValue) {
          input.autocomplete("option", "source", newValue);
        });

        options.source = mappedSource();
      }

      input.autocomplete(options);
      input.autocomplete("instance")._renderItem = function (ul, item) {
        var txt = $("<li>", {
          style: "text-align:left; white-space:nowrap;",
        });
        txt.append(item.value);
        txt.appendTo(ul);
        return txt;
      };

      const isZipCode = (checkStr) => {
        try {
          if (!checkStr || checkStr.length < 5) {
            return false;
          }

          return isNaN(checkStr) === false;
        } catch (err) {
          return false;
        }
      };

      // Usecase: User enters only a zip and quickly tabs or focuses out.
      $(input).on("focusout", (event) => {
        const val = $(event.currentTarget).val();

        // only want to grab first result if any when we have a valid zipcode
        if (
          ((modelValue() == null && val) || modelValue() != val) &&
          isZipCode(val ?? "")
        ) {
          query.call(this, val, mappedSource, true);
        }
      });

      modelValue.subscribe(function (newValue) {
        input.val(newValue || "");
        readOnlyText.html(newValue);
        if (onChanged != null) {
          if (isChanged == false) {
            options.change();
          }
          isChanged = false;
        }
      });

      if (ko.isObservable(isReadOnly)) {
        isReadOnly.subscribe(function (newValue) {
          if (newValue) {
            readOnlyText.show();
            readOnlyText.prev().hide();
          } else {
            readOnlyText.hide();
            readOnlyText.prev().show();
          }
        });
      }
      if (unwrap(isReadOnly)) {
        readOnlyText.prev().hide();
        readOnlyText.show();
      } else {
        readOnlyText.prev().show();
        readOnlyText.hide();
      }
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.loadingPanel = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var allBindings = allBindingsAccessor(),
        text = allBindings.text,
        isModal = allBindings.isModal == false ? false : true;

      var loader = $(element);

      loader.css("z-index", "999999"); //999999999999999
      loader.on("create", function (event) {
        $("#" + event.target.id + "Modal").css("z-index", "999999");
      });

      if (text != null) {
        loader.jqxLoader({
          imagePosition: "top",
          isModal: isModal,
          width: 100,
          height: 60,
          text: text,
        });
      } else {
        loader.jqxLoader({
          imagePosition: "top",
          isModal: isModal,
          width: 100,
          height: 60,
        });
      }
      if (isModal == false) {
        var parentElement = loader.parent();
        var parentBackground = $("<div>");
        var id = loader.attr("id");
        parentBackground.attr("id", "modal" + id);
        parentElement.append(parentBackground);
      }
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var allBindings = allBindingsAccessor(),
        isModal = allBindings.isModal == false ? false : true;
      var valueUnwrapped = ko.utils.unwrapObservable(valueAccessor()) || false;
      var loader = $(element);

      if (valueUnwrapped) {
        if (isModal) {
          loader.css("position", "fixed");
        } else {
          let id = loader.attr("id");
          let parentElement = loader.parent();
          let parentBackground = $("#modal" + id);
          parentElement.addClass("geLoadingPanel");
          parentBackground.addClass("geLoadingBackground");
        }
        loader.jqxLoader("open");
      } else {
        loader.jqxLoader("close");
        if (isModal == false) {
          let id = loader.attr("id");
          let parentElement = loader.parent();
          let parentBackground = $("#modal" + id);
          parentElement.removeClass("geLoadingPanel");
          parentBackground.removeClass("geLoadingBackground");
        }
      }
    },
  };

  ko.bindingHandlers.confirmClick = {
    init: function (element, valueAccessor, viewModel) {
      var value = valueAccessor();
      var message = ko.unwrap(value.message);
      var title = ko.unwrap(value.title);
      var click = value.click;

      ko.applyBindingsToNode(
        element,
        {
          click: function () {
            var div1 = $(document.createElement("div"));
            div1.html(message);
            div1.dialog({
              title: title,
              modal: true,
              buttons: {
                Ok: function () {
                  $(this).dialog("close");
                  click.apply(this, Array.prototype.slice.apply(arguments));
                },
                Cancel: function () {
                  $(this).dialog("close");
                },
              },
              close: function (event, ui) {
                div1.dialog("destroy");
              },
            });
          },
        },
        viewModel
      );
    },
  };

  ko.bindingHandlers.geEditor = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor(),
        valueUnwrapped = ko.utils.unwrapObservable(value) || false,
        allBindings = allBindingsAccessor(),
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        boundProperty = allBindings.boundProperty,
        options = allBindings.properties || {},
        editor = $(element);

      var geEditor = editor.jqxEditor(options);
      geEditor.val(valueUnwrapped);

      if (typeof isReadOnly === "function") {
        ko.computed(function () {
          var isDisabled = isReadOnly();
          geEditor.jqxEditor({
            disabled: isDisabled,
            height: 300,
          });
        });
      } else {
        var isDisabled = isReadOnly;
        geEditor.jqxEditor({
          disabled: isDisabled,
          height: 300,
        });
      }

      $(element)
        .siblings("iframe")
        .first()
        .contents()
        .find("body")
        .on("keyup change", function () {
          bindingContext.$data[boundProperty](geEditor.val());
        });
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var allBindings = allBindingsAccessor(),
        boundProperty = allBindings.boundProperty;
      $(element)
        .siblings("iframe")
        .first()
        .contents()
        .find("body")
        .on("keyup change", function () {
          bindingContext.$data[boundProperty]($(this).html());
        });
    },
  };

  ko.bindingHandlers.geToggleButton = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var options = valueAccessor() || {},
        allBindings = allBindingsAccessor(),
        onClick = allBindings.onClick,
        toggle = allBindings.toggle,
        value = allBindings.value;
      options.toggled = true;

      var toggleButton = $(element);
      toggleButton.jqxToggleButton(options);

      if (typeof options.disabled === "function") {
        ko.computed(function () {
          var isDisabled = options.disabled();
          toggleButton.jqxToggleButton({
            disabled: isDisabled,
          });
        });
      }

      toggleButton.on("click", function () {
        var toggled = toggleButton.jqxToggleButton("toggled");

        if (toggled) {
          toggleButton[0].value = value;
        } else {
          toggleButton[0].value = toggle;
        }

        if (onClick != undefined) {
          onClick(toggled);
        }
      });
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var g = "";
    },
  };

  ko.bindingHandlers.geExpander = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var options = valueAccessor() || {};
      var jqxExpander = $(element);
      jqxExpander.jqxExpander(options);
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var g = "";
    },
  };

  ko.bindingHandlers.geWindowJqx = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var options = valueAccessor(),
        allBindings = allBindingsAccessor(),
        parentValue = allBindings.parentValue;

      var modalWindow = $(element);
      options.autoOpen = false;
      options.isModal = true;
      options.resizable = false;
      options.maxWidth = 1200;
      options.maxHeight = 1400;

      if (options.width == null) {
        options.width = 700;
      }
      if (options.height == null) {
        options.height = 500;
      }
      modalWindow.on("created", function (event) {
        var content = modalWindow.find(".jqx-window-content");
        content.attr("style", "margin:5px;");
        content.hide();
      });

      modalWindow.jqxWindow(options);

      modalWindow.on("close", function (event) {
        if (ko.isObservable(parentValue)) {
          parentValue(undefined);
        }
        modalWindow.jqxWindow("destroy");
      });

      modalWindow.on("open", function (event) {
        modalWindow.find(".jqx-window-content").show();
        ko.applyBindingsToDescendants(bindingContext, element);
      });
      return {
        controlsDescendantBindings: true,
      };
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.placeholder = {
    init: function (element, valueAccessor, allBindingsAccessor) {
      var underlyingObservable = valueAccessor();
      ko.applyBindingsToNode(element, {
        attr: { placeholder: underlyingObservable },
      });
    },
  };

  ko.bindingHandlers.geInput = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        visible = allBindings.visible || true,
        disableChar = allBindings.disableChar || [],
        allowChar = allBindings.allowChar || [],
        alphaNumeric = allBindings.alphaNumeric || false,
        numericOnly = allBindings.numericOnly || false,
        immediateUpdate = allBindings.immediateUpdate || false,
        options = allBindings.properties || {},
        emailCharacters = allBindings.email ?? false,
          regExpattern = allBindings.pattern || null,
        cleanseSpecialCharacters =
          allBindings.cleanseSpecialCharacters ?? false;

      if (options.height == undefined) {
        options.height = 25;
      }

      var inputBox = $(element);
      inputBox.css("padding-left", "0px");
      inputBox.css("padding-right", "0px");
      inputBox.css("min-width", "30px");

      var div1 = $("<div>", {
        style: "display:inline-block;  max-width:300px;",
      });
      if (options.width != null) {
        div1.css("width", options.width + "px");
      } else {
        div1.css("width", "100%");
      }
      inputBox.wrap(div1);

      var readOnlyText = $("<div>", {});
      readOnlyText.insertAfter(inputBox);
      readOnlyText.html(unwrap(modelValue));
      var txtInput = inputBox.jqxInput();
      txtInput.val(unwrap(modelValue));
      txtInput.jqxInput(options);

      txtInput.on("change", function (event) {
        var currentValue = ko.utils.unwrapObservable(modelValue) || "";
        var newValue = inputBox.val();

        for (var i = 0; i < disableChar.length; i++) {
          var regExp = new RegExp(disableChar[i], "gi");
          newValue = newValue.replace(regExp, "");
        }

        if (newValue != currentValue) {
          modelValue(newValue);
        }
      });

      if (options.width == null) {
        txtInput.css("width", "100%");
      }

      ko.utils.registerEventHandler(txtInput, "keydown", function (event) {
        var char = String.fromCharCode(event.which);
        var unwantedChar = disableChar;
        if (unwantedChar.indexOf(char) > -1) {
          return false;
        }
      });
      if (immediateUpdate) {
        ko.utils.registerEventHandler(txtInput, "keyup", function (event) {
          var inputValue = $(this).val();
          modelValue(inputValue);
        });
      }

      if (alphaNumeric || cleanseSpecialCharacters || emailCharacters || regExpattern || numericOnly) {
        $(txtInput).on("keypress", function (event) {
          var char = String.fromCharCode(event.which);
          let pattern = regExpattern ?? /[^A-Za-z0-9]/gi; // alphaNumeric no

          if (cleanseSpecialCharacters) {
            pattern = /[^A-Za-z0-9\s]/gi;
          }

          if (emailCharacters) {
            pattern = /[^A-Za-z0-9!@#$%^&*=_.-]/gi;
          }

          if(numericOnly) {
            // These inputs are string representations of numbers.
            return ['0','1','2','3','4','5','6','7','8','9'].some(c => (c)?.toString().toUpperCase() === (char)?.toString().toUpperCase());
          }

          if (char != null && allowChar.indexOf(char) === -1) {
            var _val = char.replace(pattern, "");
            if (_val == "" || _val == undefined) {
              return false;
            }
          }
        });

        $(txtInput).on("blur", function (event) {
          var val = event.target.value;
          let pattern = regExpattern ?? /[^A-Za-z0-9]/gi; // alphaNumeric no

          if (cleanseSpecialCharacters) {
            pattern = /[^A-Za-z0-9\s]/gi;
          }

          if (emailCharacters) {
            pattern = /[^A-Za-z0-9!@#$%^&*=_.-]/gi;
          }

          if (val != null) {
            const _val = Object.keys(val).reduce((x, index) => {
              const char = val[index];

              if(numericOnly && ['0','1','2','3','4','5','6','7','8','9'].some(c => (c)?.toString().toUpperCase() === (char)?.toString().toUpperCase()) === false) {
                return x;
              }

              if (char && allowChar.indexOf(char) === -1) {
                x = x + char.replace(pattern, "") ?? "";
              } else {
                x = x + char;
              }

              return x;
            }, "");
            $(txtInput).val(_val);
          }
        });
      }

      modelValue.subscribe(function (newValue) {
        readOnlyText.html(newValue);
      });

      var readOnly = function (val) {
        if (ko.unwrap(visible)) {
          if (val) {
            inputBox.hide();
            readOnlyText.show();
          } else {
            inputBox.show();
            readOnlyText.hide();
          }
        } else {
          readOnlyText.hide();
        }
      };

      if (ko.isObservable(isReadOnly)) {
        isReadOnly.subscribe(function (newValue) {
          readOnly(newValue);
        });
      }
      readOnly(unwrap(isReadOnly));

      if (ko.isObservable(visible)) {
        visible.subscribe(function (newValue) {
          var divWrap = inputBox.parent();
          newValue == false ? divWrap.hide() : divWrap.show();
        });
      }
      ko.unwrap(visible) == false ? div1.hide() : div1.show();

      if (allBindingsAccessor().enterKeyFunction) {
        $(element).keypress(function (event) {
          var keyCode = event.which ? event.which : event.keyCode;
          if (keyCode === 13) {
            allBindingsAccessor().enterKeyFunction();
            return false;
          }
          return true;
        });
      }
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor(),
        allBindings = allBindingsAccessor(),
        valueUnwrapped = ko.utils.unwrapObservable(value) || "",
        allowChar = allBindings.allowChar || [],
        setUpperCase =
          allBindingsAccessor().setUpperCase != null
            ? allBindingsAccessor().setUpperCase
            : true,
        cleanseSpecialCharacters = allBindings.cleanseSpecialCharacters;

      var inputBox = $(element);
      var txtInput = inputBox.jqxInput();

      // if (cleanseSpecialCharacters) {
      //   let specialCharacters = [
      //     "/[!@#$%^&()+={}\\|:;?//]/",
      //     "/*/",
      //     "/_/",
      //     "/[[]]/",
      //     "/[,.]/",
      //     "/'/",
      //     "/&quot;/",
      //     "/-/",
      //     "/[<>]/",
      //     "/[`~]/",
      //     "/[\r\t\n]/",
      //   ];

      //   for (var i = 0; i < specialCharacters.length; i++) {
      //     var regExp = new RegExp(specialCharacters[i], "gim");
      //     valueUnwrapped = valueUnwrapped.replace(regExp, "");
      //   }
      // }

      if (cleanseSpecialCharacters) {
        const pattern = /[^A-Za-z0-9\s]/gi;

        if (valueUnwrapped != null) {
          const _val = Object.keys(valueUnwrapped).reduce((x, index) => {
            const char = valueUnwrapped[index];

            if (char && allowChar.indexOf(char) === -1) {
              x = x + char.replace(pattern, "") ?? "";
            } else {
              x = x + char;
            }

            return x;
          }, "");

          txtInput.val(_val);
        }
      }

      if (setUpperCase && typeof valueUnwrapped == "string") {
        txtInput.val(valueUnwrapped.toUpperCase());
      } else {
        txtInput.val(valueUnwrapped);
      }
    },
  };

  ko.bindingHandlers.geTextArea = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        options = allBindings.properties || {};

      if (options.height == undefined) {
        options.height = 50;
      }

      if (options.width == undefined) {
        options.width = "100%";
      }

      var div = $(element);

      var textArea = $("<textarea>");
      var readOnlyText = $("<div>");
      readOnlyText.html(unwrap(modelValue));
      div.append(textArea);
      div.append(readOnlyText);

      if (!unwrap(isReadOnly)) {
        textArea.jqxTextArea();
        textArea.val(unwrap(modelValue));
        textArea.jqxTextArea(options);
      }

      textArea.on("change", function (event) {
        modelValue(textArea.val());
      });

      modelValue.subscribe(function (newValue) {
        readOnlyText.html(newValue);
      });

      if (ko.isObservable(isReadOnly)) {
        isReadOnly.subscribe(function (newValue) {
          if (newValue) {
            textArea.hide();
            readOnlyText.show();
          } else {
            textArea.show();
            readOnlyText.hide();
          }
        });
      }

      if (unwrap(isReadOnly)) {
        textArea.hide();
        readOnlyText.show();
      } else {
        textArea.show();
        readOnlyText.hide();
      }
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor();
      var valueUnwrapped = ko.utils.unwrapObservable(value);

      var div = $(element);
      var textArea = div.find("textarea");
      textArea.val(valueUnwrapped);
    },
  };
  ko.bindingHandlers.geTextArea2 = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        options = allBindings.properties || {};
      if (options.height == undefined) {
        options.height = 150;
      }
      if (options.width == undefined) {
        options.width = "100%";
      }

      var div = $(element);

      var textArea = $("<textarea>");
      var readOnlyText = $("<div>");
      readOnlyText.html(unwrap(modelValue));
      div.append(textArea);
      div.append(readOnlyText);
      textArea.jqxTextArea();
      textArea.val(unwrap(modelValue));
      textArea.jqxTextArea(options);
      textArea.on("change", function (event) {
        modelValue(textArea.val());
      });
      modelValue.subscribe(function (newValue) {
        readOnlyText.html(newValue);
      });
      if (ko.isObservable(isReadOnly)) {
        isReadOnly.subscribe(function (newValue) {
          if (newValue) {
            div.hide();
            readOnlyText.show();
          } else {
            div.show();
            readOnlyText.hide();
          }
        });
      }
      if (unwrap(isReadOnly)) {
        div.hide();
        readOnlyText.show();
      } else {
        div.show();
        readOnlyText.hide();
      }
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor();
      var valueUnwrapped = ko.utils.unwrapObservable(value);
      var div = $(element);
      var textArea = div.find("textarea");
      textArea.val(valueUnwrapped);
    },
  };

  // Uses jqxNumber Input Field
  // See here for options:
  // https://www.jqwidgets.com/jquery-widgets-demo/demos/jqxnumberinput/index.htm#demos/jqxnumberinput/numberinputapi.htm
  ko.bindingHandlers.geNumberInput = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor(),
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        valueUnwrapped = ko.utils.unwrapObservable(value),
        className = allBindings.className || null,
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        disable =
          allBindings.disable != undefined ? allBindings.disable : undefined,
        options = allBindings.properties || {};

      options.inputMode =
        options.inputMode != null ? options.inputMode : "simple";
      options.decimalDigits =
        options.decimalDigits != null ? options.decimalDigits : 0;
      options.spinButtonsStep =
        options.spinButtonsStep != null ? options.spinButtonsStep : 0; //default: disable spin
      options.spinMode = options.spinMode != null ? options.spinMode : "none";
      options.decimal =
        options.decimal != null
          ? options.decimal
          : valueUnwrapped == undefined
          ? null
          : valueUnwrapped; //set default value
      options.width = options.width != null ? options.width : 180;

      var numberInput = $(element);
      numberInput.jqxNumberInput(options);
      var input = numberInput.find("input");

      let ownerId = numberInput.attr("id");
      if (ownerId) {
        input.attr("aria-owner-id", ownerId);
      }

      if (className && typeof className === "string") {
        $(input).addClass(className);
      }

      if (disable != undefined) {
        let val = ko.unwrap(disable);
        val = typeof val === "boolean" ? val : false;

        disable = ko.isObservable(disable) ? disable : ko.observable();

        disable.subscribe((val) => {
          if (typeof val === "boolean") {
            handleDisable(val);
          }
        });

        handleDisable(val);
      }

      function handleDisable(val) {
        numberInput.jqxNumberInput({ disabled: val });
        numberInput.prop("cursor", val ? "pointer" : "not-allowed");
      }

      numberInput.on("change", function (event) {
        if (event.args.value == null) {
          value(undefined);
        } else {
          var inputValue = parseFloat(event.args.value);
          value(inputValue);
        }
      });

      ko.utils.registerEventHandler(input, "keydown", function (event) {
        var key = event.keyCode;
        let node;
        if (key == 46) {
          value(undefined);
          numberInput.jqxNumberInput("val", null);
        } else if (key == 8) {
          if (numberInput.val() == 0) {
            value(undefined);
            numberInput.jqxNumberInput("val", null);
          }
        } else if (key == 110 || key == 48) {
          var inputVal = input.val();
          node = input.get(0);
          if (node.selectionStart > 0) {
            var g = "";
          }

          if (inputVal == "") {
            numberInput.jqxNumberInput("val", 0);
            node = input.get(0);
            var pos = 2;
            if (node.setSelectionRange) {
              node.focus();
              node.setSelectionRange(pos, pos);
            } else if (node.createTextRange) {
              var range = node.createTextRange();
              range.collapse(true);
              range.moveEnd("character", pos);
              range.moveStart("character", pos);
              range.select();
            }
          }
        }
      });
      var div1 = $("<div>");
      div1.attr("style", "display:inline-block; ");
      numberInput.wrap(div1);
      var readOnlyText = $("<div>", {
        style: "display:none;",
      });
      readOnlyText.insertAfter(numberInput);

      var setReadOnlyText = function (newValue) {
        if (newValue != undefined) {
          var strNewValue = "";
          var decimalDigits = options.decimalDigits;

          if (options.decimalDigits > 0) {
            strNewValue =
              Math.floor(newValue * Math.pow(10, options.decimalDigits)) /
              Math.pow(10, options.decimalDigits);
          } else {
            strNewValue = Math.floor(newValue);
          }
          var symbol = options.symbol;
          if (symbol != undefined) {
            var symbolPosition = options.symbolPosition;
            if (symbolPosition == "right") {
              strNewValue = strNewValue + symbol;
            } else {
              strNewValue = symbol + strNewValue;
            }
          }
          readOnlyText.html(strNewValue);
        } else {
          readOnlyText.html("");
        }
      };

      value.subscribe(function (newValue) {
        setReadOnlyText(newValue);
        if (newValue == undefined) {
          numberInput.jqxNumberInput("val", null);
        } else {
          numberInput.val(newValue);
        }
      });

      var readOrWrite = function (val) {
        if (val) {
          readOnlyText.show();
          numberInput.hide();
        } else {
          readOnlyText.hide();
          numberInput.show();
        }
      };
      if (ko.isObservable(isReadOnly)) {
        isReadOnly.subscribe(function (newValue) {
          readOrWrite(newValue);
        });
      }
      readOrWrite(unwrap(isReadOnly));
      setReadOnlyText(valueUnwrapped);
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geNumeric3 = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor(),
        allBindings = allBindingsAccessor(),
        decimal = allBindings.decimal != null ? allBindings.decimal : 3, // ko.bindingHandlers.geNumeric.decimal,
        prefix = allBindings.prefix || "",
        suffix = allBindings.suffix || "",
        min = allBindings.min,
        max = allBindings.max,
        width = allBindings.width,
        visible = allBindings.visible || true,
        format = allBindings.format != null ? allBindings.format : true,
        isReadOnly = allBindings.isReadOnly || ko.observable(false);

      var input = $(element);

      if (prefix != "") {
        input.click(function () {
          $(this).select();
        });
      }

      var div1 = $("<div>", {
        style: "display:inline-block;",
      });
      input.wrap(div1);

      input.attr("autocomplete", "off");
      input.css("text-align", "right");

      if (width != null) {
        input.css("width", width);
        div1.css("width", width);
      } else {
        input.css("width", "100%");
        div1.css("width", "100%");
      }

      input.css("height", "25px");
      input.css("max-width", "300px");
      var readOnlyText = $("<span>", {
        style: "display:none;",
      });
      readOnlyText.insertAfter(input);

      if (ko.isObservable(value)) {
        var target = value;
        var result = ko
          .pureComputed({
            read: target, //always return the original observables value
            write: function (newValue) {
              var current = target(),
                valueToWrite = newValue;
              var inputVal = "";
              if (valueToWrite != null) {
                if (min != null && min > valueToWrite) {
                  valueToWrite = min;
                }
                if (max != null && max < valueToWrite) {
                  valueToWrite = max;
                }

                inputVal = valueToWrite.toFixed(decimal);
                if (format) {
                  inputVal = inputVal
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                    .toString();
                }
                if (valueToWrite < 0) {
                  inputVal = "-" + prefix + inputVal.replace("-", "") + suffix;
                } else {
                  inputVal = prefix + inputVal + suffix;
                }
              }
              input.val(inputVal);
              readOnlyText.html(inputVal);

              //only write if it changed
              if (valueToWrite !== current) {
                target(valueToWrite);
              } else {
                //if the rounded value is the same, but a different value was written, force a notification for the current field
                if (newValue !== current) {
                  target.notifySubscribers(valueToWrite);
                }
              }
            },
          })
          .extend({
            notify: "always",
          });
        result(target());
        value.subscribe(function (newValue) {
          result(newValue);
        });
      }

      ko.utils.registerEventHandler(input, "keydown", function (event) {
        var val = event.currentTarget.value;
        if (
          event.shiftKey == true &&
          (event.keyCode == 190 || event.keyCode == 189)
        ) {
          event.preventDefault();
        }
        if (
          val.indexOf(".") > -1 &&
          (event.keyCode == 110 || event.keyCode == 190)
        ) {
          event.preventDefault(); //disable double dot
        }

        // Allow: backspace, delete, tab, escape, enter and .
        if (
          $.inArray(event.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
          // Allow: Ctrl+A, Command+A
          (event.keyCode == 65 &&
            (event.ctrlKey === true || event.metaKey === true)) ||
          // Allow : Ctrl+C, Ctrl+V, Ctrl+X
          ((event.keyCode == 67 ||
            event.keyCode == 88 ||
            event.keyCode == 86) &&
            (event.ctrlKey === true || event.metaKey === true)) ||
          // Allow: home, end, left, right, down, up
          (event.keyCode >= 35 && event.keyCode <= 40)
        ) {
          // let it happen, don't do anything
          return;
        }

        // Ensure that it is a number and stop the keypress
        if (
          (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
          (event.keyCode < 96 || event.keyCode > 105) &&
          // allow negative
          event.keyCode !== 109 &&
          event.keyCode !== 189
        ) {
          event.preventDefault();
        }

        //disable '-' if not first char
        if (event.keyCode === 109 || event.keyCode === 189) {
          if (event.currentTarget.selectionStart !== 0) {
            event.preventDefault();
          }
        }
      });

      ko.utils.registerEventHandler(input, "change", function (event) {
        var val = event.currentTarget.value
          .replace(prefix, "")
          .replace(suffix, "")
          .replace(/,/g, "");
        var num = parseFloat(val);
        var newValue = isNaN(num) ? undefined : num;
        if (ko.isObservable(value)) {
          value(newValue);
        }
      });

      var readOnly = function (val) {
        if (ko.unwrap(visible)) {
          if (val) {
            input.hide();
            readOnlyText.show();
          } else {
            input.show();
            readOnlyText.hide();
          }
        }
      };

      if (ko.isObservable(isReadOnly)) {
        isReadOnly.subscribe(function (newValue) {
          readOnly(newValue);
        });
      }
      readOnly(ko.unwrap(isReadOnly));

      if (ko.isObservable(visible)) {
        visible.subscribe(function (newValue) {
          var divWrap = input.parent();
          newValue == false ? divWrap.hide() : divWrap.show();
        });
      }
      ko.unwrap(visible) == false ? div1.hide() : div1.show();
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geMaskedInput = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var modelValue = valueAccessor(),
        valueUnwrapped = ko.utils.unwrapObservable(modelValue) || null,
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        options = allBindings.properties || {};

      var readOnlyText = $("<div>", {
        style: "",
      });

      var maskedInput = $(element);
      readOnlyText.insertAfter(maskedInput);
      options.width = options.width != null ? options.width : 120;
      options.value = valueUnwrapped;
      maskedInput.jqxMaskedInput(options);
      maskedInput.on("change", function (event) {
        var args = event.args;
        if (args) {
          var inputValue = event.args.value;
          var text = event.args.text;

          var length = inputValue.length;
          for (var i = 0; i < length; i++) {
            inputValue = inputValue.replace("_", "");
          }
          if (ko.isObservable(modelValue)) {
            if (inputValue == "") {
              modelValue(undefined);
            } else {
              modelValue(inputValue);
            }
          } else {
          }
        }
      });
      readOnlyText.html(maskedInput.val());
      if (ko.isObservable(modelValue)) {
        modelValue.subscribe(function (newValue) {
          readOnlyText.html(maskedInput.val());
        });
      } else {
      }

      if (ko.isObservable(isReadOnly)) {
        isReadOnly.subscribe(function (newValue) {
          if (newValue) {
            readOnlyText.show();
            maskedInput.hide();
          } else {
            readOnlyText.hide();
            maskedInput.show();
          }
        });
      }
      if (unwrap(isReadOnly)) {
        readOnlyText.show();
        maskedInput.hide();
      } else {
        readOnlyText.hide();
        maskedInput.show();
      }
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor();
      var valueUnwrapped = ko.utils.unwrapObservable(value);
      var isReadOnly = allBindingsAccessor().isReadOnly || false;
      var maskedInput = $(element);
      maskedInput.jqxMaskedInput({
        value: valueUnwrapped,
      });
    },
  };

  ko.bindingHandlers.geDateTime = {
    init: (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) => {
      var allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        isReadOnly = allBindings.isReadOnly
          ? typeof allBindings.isReadOnly == "function"
            ? allBindings.isReadOnly
            : ko.observable(allBindings.isReadOnly)
          : ko.observable(false),
        showTime = allBindings.showTime || false,
        format = allBindings.format || "MM/DD/YYYY",
        options = allBindings.properties || {};

      var modelValue = valueAccessor().extend({ validDate: true });
      var textInput = $(element);
      options.constrainInput = false;

      if (format.toUpperCase() == "MM/YYYY") {
        options.changeMonth = true;
        options.changeYear = true;
        options.showButtonPanel = true;

        options.onClose = function (dateText, inst) {
          modelValue(new Date(inst.selectedYear, inst.selectedMonth, 1));
        };
      }

      // Datechange -> reset time to 00:00
      let inputReset = false;
      textInput.on("keyup", function (e) {
        let elem = e.target;
        let skipKeys = [8, 46]; // backspace = 8, delete = 46
        let val = e.currentTarget.value;

        const setCursorPos = (pos) => {
          if (elem.setSelectionRange) {
            // Modern browsers
            elem.focus();
            elem.setSelectionRange(pos, pos);
          } else if (elem.createTextRange) {
            // IE8 and below

            var range = elem.createTextRange();
            range.collapse(true);
            range.moveEnd("character", pos);
            range.moveStart("character", pos);
            range.select();
          }
        };

        // If val && it's not the delete or backspace key
        if (
          val &&
          skipKeys.indexOf(e.which) == -1 &&
          skipKeys.indexOf(e.keyCode) == -1
        ) {
          if (modelValue() && inputReset == false) {
            let cursorPos = elem.selectionStart;

            //This is cutting the time off of date & times when pasting.
            // I haven't notice any issues without it, so keeping it just in case.
            // let parts = val.split(" ");
            // textInput.val(parts[0]);

            // Above moves cursor to end of text input, so now
            // reset the cursor position to where the user was typing
            setCursorPos(cursorPos);

            inputReset = true;
          }
        }

        if (e.which == 13 || e.keyCode == 13) {
          let val = e.currentTarget.value;
          val = val ? val.toLowerCase() : "";

          if (val && (val.indexOf("t") != -1 || val.indexOf("n") != -1)) {
            let date = getShortCutsDate(val);

            if (date != null && date.isValid()) {
              let val = datetimeUTC(date.format());

              val = val.hour(0);
              val = val.minute(0);

              modelValue(val);

              e.preventDefault();
              e.stopImmediatePropagation();

              textInput.datepicker("hide");
            }
          }
        } else {
          // reset the input if the user 'deletes' the date/text so it parses correctly later on....
          let val = e.currentTarget.value;
          if (!val || val.length == 0) modelValue(undefined);
        }

        return true;
      });

      textInput.datepicker(options);

      if (format.toUpperCase() == "MM/YYYY") {
        $(".ui-datepicker").addClass("datePickerMonthYear");
      }
      textInput.addClass("dateTimePicker");
      textInput.attr("autocomplete", "off");

      var container = $("<div>", {
        class: "geDateTimeContainer"
      });
      var divDatePicker = $("<div>", {
        class: "geDateTimeDatePicker"
      });
      textInput.before(container);
      container.append(divDatePicker);
      divDatePicker.prepend(textInput);

      var timeContainer = $("<div>", {
        style: "",
        class: "geDateTimeTimeContainer"
      });

      if (showTime) {
        textInput.css("width", options.width ? options.width : "120px");

        textInput.css("border-right", "none");
        textInput.attr("placeholder", userProfile.dateTimeFormat);

        var formatString = "HH:mm";
        if (userProfile.dateTimeFormat.indexOf("A") > -1) {
          formatString = "hh:mm tt";
        }

        var timeInput = $("<div>");
        timeInput.jqxDateTimeInput({
          //value: undefined,
          width: 23,
          formatString: formatString,
          showTimeButton: true,
          showCalendarButton: false,
        });

        timeContainer.append(timeInput);
        setTimeout(function () {
          timeContainer.insertAfter(textInput);

          timeInput.attr("tabIndex", -1);

          timeInput.find("input").attr("tabindex", -1);
        }, 0);
        timeInput.on("change", function (event) {
          var jsDate = event.args.date; //This will contain the time entered but will be set to today's date
          var hours = jsDate.getHours();
          var minutes = jsDate.getMinutes();

          let val = modelValue();

          if (!val) {
            let d = datetimeUTC(jsDate);
            val = dayjs.tz(d, "America/New_York");
          } else if (val instanceof dayjs == false) {
            val = datetimeUTC(val);
          }

          if (val.isValid() == false) modelValue(null);

          val = val.millisecond(0);
          val = val.second(0);
          val = val.minute(minutes);
          val = val.hour(hours);

          modelValue(val);

          // When user pressed ENTER key to make the change, the input shows.
          // Need to re-hide the input portion of the datetimepicker time selection
          $(timeInput).find('input').hide();
        });
      } else {
        container.css("min-width", "100px");
        textInput.css("width", options.width ? options.width : "90px");
        textInput.attr("placeholder", format);
      }

      //handle the field changing
      ko.utils.registerEventHandler(element, "change", function (event) {
        var text = event.currentTarget.value;

        var textDate = getShortCutsDate(event.currentTarget.value);

        if (textDate != null && textDate.isValid()) {
          let val = datetimeUTC(textDate.format());

          val = val.hour(showTime ? textDate.hour() : 0);
          val = val.minute(showTime ? textDate.minute() : 0);

          modelValue(val);
        } else if (text) {
          //== Parse / Get a valid time format
          let formatText = formatDatetimeStr(text);
          let inputDate = datetimeUTC(new Date(formatText));

          modelValue(inputDate);
        } else {
          modelValue(undefined);
        }
      });

      let focusTimeout;
      ko.utils.registerEventHandler(element, "focus", function (event) {
        clearTimeout(focusTimeout);
        const $target = $(event.currentTarget);

        focusTimeout = setTimeout(() => {
          $target.select();
        }, 200);
      });

      ko.utils.registerEventHandler(element, "focusout", function (event) {
        clearTimeout(focusTimeout);
      });

      //handle disposal (if KO removes by the template binding)
      ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        textInput.datepicker("destroy");
      });
      var readOnlyText = $("<div>", {
        style: "display:none",
        class: "readOnlyText",
      });
      readOnlyText.insertBefore(textInput);

      var readWriteDateTime = function (val) {
        if (val) {
          readOnlyText.show();
          textInput.hide();
          timeContainer.hide();
        } else {
          readOnlyText.hide();
          textInput.show();
          timeContainer.show();
        }
      };

      function formatDateText(val) {
        if (!val) return "";
        let fmt = showTime ? userProfile.dateTimeFormat : format;

        if (val instanceof dayjs) {
          return val.isValid() ? val.format(fmt) : "";
        }
      }

      modelValue.subscribe(function (newValue) {
        inputReset = false;
        readOnlyText.html(formatDateText(newValue));
        textInput.val(formatDateText(newValue));
      });

      if (ko.isObservable(isReadOnly)) {
        isReadOnly.subscribe(function (newValue) {
          readWriteDateTime(newValue);
        });
      }

      let val = ko.unwrap(modelValue);

      if (val) modelValue(datetimeUTC(val));

      readWriteDateTime(unwrap(isReadOnly));
    },
  };

  ko.bindingHandlers.geDateStr = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var value = valueAccessor(),
        allBindings = allBindingsAccessor();
      var valueUnwrapped = ko.utils.unwrapObservable(value);
      var displayBlankWhenNull = allBindings.displayBlankWhenNull || false;
      var alt = allBindings.alt || "N/A";
      var pattern = "MM/DD/YYYY";
      if (allBindings.datePattern != null) {
        pattern = allBindings.datePattern;
      } else if (allBindings.showTime) {
        pattern = userProfile.dateTimeFormat;
      }

      var date = datetimeUTC(valueUnwrapped);

      $(element).text(
        valueUnwrapped && date.isValid()
          ? date.format(pattern)
          : displayBlankWhenNull
          ? ""
          : alt
      );

      if (valueUnwrapped) $(element).css("white-space", "nowrap");
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
      var value = valueAccessor(),
        allBindings = allBindingsAccessor();
      var valueUnwrapped = ko.utils.unwrapObservable(value);
      var displayBlankWhenNull = allBindings.displayBlankWhenNull || false;
      var alt = allBindings.alt || "N/A";
      var pattern = "MM/DD/YYYY";
      if (allBindings.datePattern != null) {
        pattern = allBindings.datePattern;
      } else if (allBindings.showTime) {
        pattern = userProfile.dateTimeFormat;
      }

      var date = datetimeUTC(valueUnwrapped);

      $(element).text(
        valueUnwrapped && date.isValid()
          ? date.format(pattern)
          : displayBlankWhenNull
          ? ""
          : alt
      );
    },
  };

  ko.bindingHandlers.currency = {
    init: function (
      element,
      valueAccessor,
      allBindings,
      viewModel,
      bindingContext
    ) {},
    update: function (element, valueAccessor, allBindingsAccessor) {
      var value = parseFloat(ko.utils.unwrapObservable(valueAccessor())),
        precision =
          ko.utils.unwrapObservable(allBindingsAccessor().precision) ||
          ko.bindingHandlers.currency.defaultPrecision,
        formattedValue =
          isNaN(value) == false
            ? (value < 0 ? "($" : "$") +
              value
                .toFixed(precision)
                .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                .toString()
                .replace("-", "") +
              (value < 0 ? ")" : "")
            : "";

      ko.bindingHandlers.text.update(element, function () {
        return formattedValue || "N/A";
      });
    },
    defaultPrecision: 2,
  };

  ko.bindingHandlers.slideVisible = {
    init: function (
      element,
      valueAccessor,
      allBindings,
      viewModel,
      bindingContext
    ) {
      var value = ko.unwrap(valueAccessor()); // Get the current value of the current property we're bound to
      $(element).toggle(value);
    },
    update: function (element, valueAccessor, allBindings) {
      // First get the latest data that we're bound to
      var value = valueAccessor();

      // Next, whether or not the supplied model property is observable, get its current value
      var valueUnwrapped = ko.unwrap(value);

      // Grab some more data from another binding property
      var duration = allBindings.get("slideDuration") || 400; // 400ms is default duration unless otherwise specified

      // Now manipulate the DOM element
      if (valueUnwrapped == true)
        $(element).slideDown(duration); // Make the element visible
      else $(element).slideUp(duration); // Make the element invisible
    },
  };

  ko.bindingHandlers.geGrid = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor(),
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        columns = allBindings.columns,
        cellclick = allBindings.cellclick,
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        options = allBindings.properties || {},
        sortcolumn = allBindings.sortcolumn,
        sortdirection = allBindings.sortdirection || "asc";
      var grid = $(element);
      var source = {
        localdata: value,
        datatype: "obserableArray",
        sortcolumn: sortcolumn,
        sortdirection: sortdirection,
      };
      var dataAdapter = new $.jqx.dataAdapter(source);
      options.columns = columns;
      options.selectionmode = "none";
      options.enablehover = false;
      options.autoheight = true;
      options.altrows = true;
      options.columnsautoresize = true;
      options.columnsresize = true;

      options.width = options.width != null ? options.width : "100%";
      grid.jqxGrid(options);

      grid.on("cellclick", function (event) {
        if (cellclick != undefined) {
          cellclick(event);
        }
      });

      setTimeout(function () {
        grid.jqxGrid({
          source: dataAdapter,
        });
      }, 1000);

      //return ko.bindingHandlers.init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geDropDownList = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        sourceData = allBindings.source,
        selectedValue = allBindings.selectedValue,
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        options = allBindings.properties || {},
        onChecked = allBindings.onChecked,
        onSelected = allBindings.onSelected,
        isCheckAllDefault = allBindings.checkAllDefault || false,
        isGridToolbarChecklistOption =
          allBindings.isGridToolbarChecklistOption || false,
        initialRender = true,
        componentId = allBindings.componentId,
        getOriginalValueObj = allBindings.getOriginalValueObj || false,
        tooltip = allBindings.tooltip,
        onOpen = allBindings.onOpen || function () {},
        unwrap = ko.utils.unwrapObservable;

      if (options.dropDownHeight == undefined) {
        options.autoDropDownHeight = true;
      }

      options.displayMember =
        options.displayMember != undefined ? options.displayMember : "text";
      options.valueMember =
        options.valueMember != undefined ? options.valueMember : "value";
      options.enableBrowserBoundsDetection = true;
      var container = $(element);
      container.css("display", "inline-block");

      var ddList = $("<div>", {
        style: "position:relative",
      });

      if (tooltip) ddList.attr("title", tooltip);

      var readOnlyText = $("<div>");
      container.append(ddList);
      container.append(readOnlyText);

      var id = container.attr("id");

      if (id != null) {
        container.removeAttr("id");
        ddList.attr("id", id);
      } else if (componentId) {
        ddList.attr("id", componentId);
      }

      if (options.checkboxes) {
        var source = {
          localdata: modelValue,
          datatype: "obserableArray",
        };
        var dataAdapter = new $.jqx.dataAdapter(source);
        options.source = dataAdapter;
        options.animationType = "none";
        ddList.jqxDropDownList(options);

        // If widget is part of grid toolbar then the widgets 'bindingComplete' doesn't get called/works b/c
        // of the grid rendering process.
        if (isGridToolbarChecklistOption) {
          let skipModelUpdate = false;

          ddList.on("checkChange", function (event) {
            if (event.args) {
              var item = event.args.item;
              var tmpItems = unwrap(modelValue);
              if (tmpItems != undefined) {
                $.each(tmpItems, function (index) {
                  if (this[options.valueMember] != null) {
                    if (this[options.valueMember] == item.value) {
                      this.checked = item.checked;
                    }
                  } else {
                    if (this.label == item.label) {
                      this.checked = item.checked;
                    }
                  }
                });
              }

              if (skipModelUpdate === false) {
                if (ko.isWriteableObservable(modelValue)) {
                  modelValue(tmpItems);
                }

                if (onChecked != null) {
                  onChecked(event);
                }
              }
            }
          });

          modelValue.subscribe((newValue = []) => {
            skipModelUpdate = true;
            newValue.map((x, index) => {
              const item =
                ddList.jqxDropDownList("getItemByValue", x.value) || {};
              if (x.checked) {
                ddList.jqxDropDownList("checkIndex", item.index);
              } else {
                ddList.jqxDropDownList("uncheckIndex", item.index);
              }
            });

            skipModelUpdate = false;
          });
        } else {
          let updatedFromSubscribable = false;
          let internalChange = false;
          ddList.on("bindingComplete", function (event) {
            if (isCheckAllDefault) {
              ddList.jqxDropDownList("checkAll");
              var tmpItems = unwrap(modelValue);
              if (tmpItems != undefined) {
                $.each(tmpItems, function (index) {
                  this.checked = true;
                });
              }

              if (ko.isWriteableObservable(modelValue)) {
                modelValue(tmpItems);
              }
            }
          });

          ddList.on("checkChange", function (event) {
            if (event.args && updatedFromSubscribable == false) {
              var item = event.args.item;

              var tmpItems = unwrap(modelValue);

              if (tmpItems != undefined) {
                tmpItems.map(function (x) {
                  if (
                    x[options.valueMember] != null &&
                    x[options.valueMember] == item.value
                  ) {
                    x.checked = item.checked;
                  } else if (x.label == item.label) {
                    x.checked = item.checked;
                  }

                  return x;
                });
              }

              if (ko.isWriteableObservable(modelValue)) {
                internalChange = true;
                modelValue(tmpItems);
              }
              if (onChecked != null) {
                onChecked(event);
              }
            }
          });

          modelValue.subscribe(function (newValue = []) {
            if (internalChange) {
              internalChange = false;
              return false;
            }

            updatedFromSubscribable = true;
            if (newValue.length) {
              newValue.map((x, index) => {
                const item =
                  ddList.jqxDropDownList("getItemByValue", x.value) || {};
                if (x.checked) {
                  ddList.jqxDropDownList("checkIndex", item.index);
                } else {
                  ddList.jqxDropDownList("uncheckIndex", item.index);
                }
              });
            } else {
              ddList.jqxDropDownList("uncheckAll");
            }

            updatedFromSubscribable = false;
          });
        }
      } else {
        var dataType = ko.isObservable(sourceData)
          ? "observableArray"
          : "array";

        source = {
          localdata: sourceData,
          datatype: dataType,
        };
        dataAdapter = new $.jqx.dataAdapter(source);

        if (ko.isObservable(sourceData)) {
          sourceData.subscribe(function (newValue) {
            dataAdapter.dataBind(); // fix for update array
          });
        }
        options.source = dataAdapter;

        ddList.on("select", function (event) {
          if (event.args) {
            var item = event.args.item;
            var itemValue = item.value;
            var label = item.label;

            readOnlyText.html(label);
            if (ko.isWriteableObservable(modelValue)) {
              if (getOriginalValueObj) {
                const selectedItem = ddList.jqxDropDownList("getSelectedItem")
                  ? ddList.jqxDropDownList("getSelectedItem").originalItem
                  : null;
                modelValue(selectedItem);
              } else {
                modelValue(itemValue);

                if (ko.isWriteableObservable(selectedValue)) {
                  const selectedItem = ddList.jqxDropDownList("getSelectedItem")
                    ? ddList.jqxDropDownList("getSelectedItem").originalItem
                    : null;
                  selectedValue(selectedItem);
                }
              }
            }

            if (onSelected) {
              onSelected(event);
            }
          }
        });

        ddList.on("open", function (event) {
          if (typeof onOpen === "function") onOpen(event);
        });

        if (ko.isWriteableObservable(modelValue)) {
          modelValue.subscribe(function (newValue) {
            if (newValue) {
              ddList.jqxDropDownList("selectItem", newValue);
            }
          });
        }

        options.animationType = "none";
        ddList.jqxDropDownList(options);
        ddList.jqxDropDownList("val", unwrap(modelValue));

        if (ko.isObservable(isReadOnly)) {
          isReadOnly.subscribe(function (newValue) {
            if (newValue) {
              readOnlyText.show();
              ddList.hide();
            } else {
              readOnlyText.hide();
              ddList.show();
            }
          });
        }
        if (unwrap(isReadOnly)) {
          readOnlyText.show();
          ddList.hide();
        } else {
          readOnlyText.hide();
          ddList.show();
        }
      }

      // Hack for jqx/chrome viewport mobile issues
      if (isDeviceMobileOrTablet()) {
        ddList.on("open", function (event) {
          var _el = event.owner.container[0];
          if (_el == undefined) return;
          var el = $(_el);
          // sets the ddl box to be displayed properly on modals
          el.css({ "z-index": 9999999 });
        });
      }
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geMobileDropDownMenu = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var listitems = valueAccessor()();
      var container = $(element);
      var ddList = $('<div class="dropdown">');
      var button = $(
        '<button style="width: 100%; padding: 4px 6px 4px 3px;" class="btn btn-default dropdown-toggle geDropDownMenuButton" type="button" id="' +
          element.id +
          'Button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">'
      );
      button.append(
        "<span>" + container.text().trim() != ""
          ? container.text()
          : "Please Choose" + ": </span>"
      );
      container.text("");
      var caret = $('<span class="caret">');
      button.append(caret);
      ddList.append(button);
      container.append(ddList);
      var ul = $('<ul class="dropdown-menu" aria-labelledby="dropdownMenu1">');
      ko.utils.arrayForEach(listitems, function (listItemValue) {
        var li = $(
          '<li><a href="#" style="cursor: pointer;">' +
            listItemValue +
            "</a></li>"
        );
        li.click(function (event) {
          if (allBindingsAccessor().onSelect) {
            allBindingsAccessor().onSelect(listItemValue);
          }
        });
        ul.append(li);
      });

      valueAccessor().subscribe(function (newListItems) {
        ul.empty();
        ko.utils.arrayForEach(newListItems, function (listItemValue) {
          var li = $(
            '<li><a href-"#" style="cursor: pointer;">' +
              listItemValue +
              "</a></li>"
          );
          li.click(function (event) {
            if (allBindingsAccessor().onSelect) {
              allBindingsAccessor().onSelect(listItemValue);
            }
          });
          ul.append(li);
        });
      });

      ddList.append(ul);
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geComboBox = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor(),
        allBindings = allBindingsAccessor(),
        sourceData = allBindings.source,
        selectedValue = allBindings.selectedValue || ko.observable(),
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        allowManual = allBindings.allowManual || false,
        options = allBindings.properties || {};
      var comboBox = $(element);

      var dataType = ko.isObservable(sourceData) ? "observableArray" : "array";
      const valueType = Array.isArray(ko.unwrap(value))
        ? "observableArray"
        : "observable";

      var source = {
        localdata: sourceData,
        datatype: dataType,
      };

      var dataAdapter = new $.jqx.dataAdapter(source);
      options.source = dataAdapter;

      comboBox.jqxComboBox(options);

      var isSelectionValid = true;
      let internalChange = false;
      if (!options.checkboxes) {
        comboBox.on("change", function (event) {
          var args = event.args;
          if (args != null) {
            var item = args.item;
            if (item != null) {
              value(item.value);
            } else {
              isSelectionValid = false;

              let text = comboBox.jqxComboBox("val");
              value(text);
            }
          } else {
            isSelectionValid = false;

            let text = comboBox.jqxComboBox("val");
            value(text);
          }
        });

        comboBox.jqxComboBox("selectItem", ko.unwrap(value));

        value.subscribe(function (newValue) {
          if (newValue && isSelectionValid) {
            comboBox.jqxComboBox("selectItem", newValue);
          }

          if (
            Object.keys(newValue).length &&
            "value" in newValue &&
            comboBox.jqxComboBox("val") !== newValue.value
          ) {
            comboBox.val(newValue.value);
          }

          isSelectionValid = true;
        });
      } else {
        // checkbox type selections
        comboBox.on("checkChange", function (event) {
          const args = event.args || {};
          const item = args.item;

          if (item) {
            internalChange = true;
            if (valueType == "observableArray") {
              const checkedItems = comboBox.jqxComboBox("getCheckedItems");
              value(checkedItems.map((x) => x.value));
            } else {
              value(item.value);
            }
          }
        });

        if (valueType === "observableArray") {
          const vals = ko.toJS(value);
          if (vals) {
            vals.map((x, index) => {
              const item = comboBox.jqxComboBox("getItemByValue", x);
              comboBox.jqxComboBox("checkIndex", item.index);
            });
          }
        }

        value.subscribe(function (newValue = []) {
          if (newValue.length && internalChange == false) {
            newValue.map((x, index) => {
              const item = comboBox.jqxComboBox("getItemByValue", x);
              comboBox.jqxComboBox("checkIndex", item.index);
            });
          } else {
            if (internalChange == false) {
              comboBox.jqxComboBox("uncheckAll");
            }
          }

          internalChange = false;
        });
      }

      var div1 = $("<div>", {
        style: "display:inline-block;",
      });

      comboBox.wrap(div1);
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.gePanel = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var content = $(element);
      var values = valueAccessor(),
        bindings = allBindingsAccessor(),
        isCollapsed =
          bindings.isCollapsed != null ? bindings.isCollapsed : true,
        isCollapsedCSS = isCollapsed == true ? "collapse" : "collapse in",
        onClick = bindings.onClick;

      var title = values.title == null ? "" : values.title;

      var collapseWrapper = $("<div>", {
        class: "panel panel-primary",
      });
      var panelHeading = $("<div>", {
        class: "panel-heading",
        style: "background-color:#2a6496;",
      });
      var panelTitle = $("<h4>", {
        class: "panel-title",
      });
      var collapseControl = $("<b>", {
        style: "font-size:12px;",
      });
      var collapseArea = $("<div>", {
        class: "panel-collapse " + isCollapsedCSS,
      });
      var panelBody = $("<div>", {
        class: "panel-body",
      });

      content.wrap(collapseWrapper);

      panelHeading.insertBefore(content);
      content.wrap(collapseArea);
      content.wrap(panelBody);

      panelHeading.append(panelTitle);
      panelTitle.append(collapseControl);
      collapseControl.append(title);

      //When they click the header call toggleGePanel on the collapseableArea
      panelHeading.on(
        "click",
        {
          collapsableArea: content.parent().parent(),
        },
        toggleGePanel
      );

      function toggleGePanel(event) {
        if (onClick != null) {
          onClick();
        }
        event.data.collapsableArea.collapse("toggle");
      }
    },
  };

  ko.bindingHandlers.geTransferGrid = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var source = valueAccessor(),
        allBindings = allBindingsAccessor(),
        columns = allBindings.columns,
        datafields = allBindings.datafields,
        filterable =
          allBindings.filterable != null ? allBindings.filterable : true,
        width = allBindings.width || "100%",
        height = allBindings.height || 200,
        sourceData = allBindings.source,
        refresh = allBindings.refresh,
        showTransferAll = allBindings.showTransferAll == false ? false : true,
        sortdirection = allBindings.sortdirection || "asc",
        sortcolumn = allBindings.sortcolumn,
        onColumnTransfer = allBindings.onColumnTransfer,
        canBulkTransfer = allBindings.canBulkTransfer || false,
        canSingleTransfer = allBindings.canSingleTransfer || false,
        unwrap = ko.utils.unwrapObservable;

      element = $(element);

      var container = $("<div>", {
        style: "padding: 10px 2px; width: 100%;",
      });

      element.append(container);

      // left grid
      var d1 = $("<div>", {
        style: "display:inline-block; float:left; width: 45%; ", // "display:inline-block; float:left; width: 42%"
      });

      // button container
      var d2 = $("<div>", {
        style: "display:inline-block; float:left; margin: 0px 5px",
      });

      // right grid
      var d3 = $("<div>", {
        style: "display:inline-block; float:left; width: 45%; ", //"display:inline-block; float:left; width: 42%"
      });
      var clearBoth = "<div style='clear:both;'></div>";
      var gridLeft = $("<div>");
      var gridRight = $("<div>");
      var btnRight = $(
        "<button type='button' class='btn btn-default btn-sm' ><span class='glyphicon glyphicon-arrow-right' ></span></button>"
      );
      var btnLeft = $(
        "<button type='button' class='btn btn-default btn-sm' ><span class='glyphicon glyphicon-arrow-left' ></span></button>"
      );
      var btnRightAll = $(
        "<button type='button' class='btn btn-default btn-sm' ><span class='glyphicon glyphicon-forward' ></span></button>"
      );
      var btnLeftAll = $(
        "<button type='button' class='btn btn-default btn-sm' ><span class='glyphicon glyphicon-backward' ></span></button>"
      );
      container.append(d1);
      container.append(d2);
      container.append(d3);
      d1.append(gridLeft);
      d2.append(btnRight);
      d2.append(clearBoth);
      d2.append(btnLeft);
      d2.append(clearBoth);
      if (showTransferAll) {
        d2.append(btnRightAll);
        d2.append(clearBoth);
        d2.append(btnLeftAll);
      }
      d3.append(gridRight);
      container.append(clearBoth);
      var gridColumns = [];
      var dataFields = [];

      var dataType = ko.isObservable(source.left) ? "observableArray" : "array";

      if (datafields != null) {
        dataFields = datafields;
      }

      if (columns != null) {
        gridColumns = columns;
      } else {
        var firstLetterUpperCase = function (str) {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };

        var obj = {};
        if (dataType == "array") {
          obj = source.left[0] || source.right[0];
        } else {
          obj = source.left()[0] || source.right()[0];
        }

        for (var prop in obj) {
          var text = firstLetterUpperCase(prop);
          var columntype =
            typeof obj[prop] == "number" ? "numberinput" : "textbox";
          gridColumns.push({
            text: text,
            datafield: prop,
            columntype: columntype,
          });
          if (datafields == null) {
            dataFields.push({
              name: prop,
              type: typeof obj[prop],
            });
          }
        }
      }
      var leftSource = {
        localdata: source.left,
        datafields: dataFields,
        datatype: dataType,
      };
      var rightSource = {
        localdata: source.right,
        datafields: dataFields,
        datatype: dataType,
      };
      var adapterLeft = new $.jqx.dataAdapter(leftSource);
      var adapterRight = new $.jqx.dataAdapter(rightSource);
      var options = {};
      options.columns = gridColumns;
      options.selectionmode = "multiplerows";
      options.showfilterrow = filterable;
      options.filterable = filterable;
      options.sortable = true;
      options.altrows = false;
      options.columnsautoresize = true;
      options.columnsresize = false;
      options.width = width;
      options.height = height;
      options.source = adapterLeft;
      gridLeft.jqxGrid(options);
      options.source = adapterRight;
      gridRight.jqxGrid(options);

      if (refresh != null && ko.isObservable(refresh)) {
        refresh.subscribe(function (value) {
          if (value) {
            adapterLeft.dataBind();
            adapterRight.dataBind();
            refresh(false);
          }
        });
      }

      var transferItems = function (selectedItems, origin, destination) {
        if (dataType == "observableArray") {
          var removeItems = ko.utils.arrayFilter(origin(), function (item) {
            for (var i = 0; i < selectedItems.length; i++) {
              if (origin().indexOf(item) == selectedItems[i]) {
                return true;
              }
            }
            return false;
          });
          ko.utils.arrayPushAll(destination, removeItems);
          origin.removeAll(removeItems);
        } else {
          selectedItems = selectedItems.sort(function (a, b) {
            return a - b;
          });
          for (var j = selectedItems.length - 1; j >= 0; j--) {
            destination.push(origin[selectedItems[j]]);
            origin.splice(selectedItems[j], 1);
          }

          var alphabeticalSort = function (a, b) {
            var nameA = a[sortcolumn].toLowerCase(),
              nameB = b[sortcolumn].toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          };

          if (sortcolumn) {
            destination.sort(alphabeticalSort);
            origin.sort(alphabeticalSort);
          }
          adapterLeft.dataBind();
          adapterRight.dataBind();
        }

        if (onColumnTransfer != null) {
          onColumnTransfer.call();
        }
      };
      var trackSingle = true;
      var trackBulk = true;

      var buttonStatus = function (isDisabled) {
        if (trackSingle) {
          btnRight.prop("disabled", isDisabled);
          btnLeft.prop("disabled", isDisabled);
        }

        if (trackBulk) {
          btnRightAll.prop("disabled", isDisabled);
          btnLeftAll.prop("disabled", isDisabled);
        }
      };

      if (canBulkTransfer == false) {
        trackBulk = false;
        btnRightAll.prop("disabled", true);
        btnLeftAll.prop("disabled", true);
      }

      if (canSingleTransfer == false) {
        trackSingle = false;
        btnRight.prop("disabled", true);
        btnLeft.prop("disabled", true);
      }

      gridRight.on("filter", function (value) {
        gridRight.jqxGrid("clearselection");
      });

      gridLeft.on("filter", function (value) {
        gridLeft.jqxGrid("clearselection");
      });

      btnRight.click(function () {
        buttonStatus(true);
        var rowindexes = gridLeft.jqxGrid("getselectedrowindexes");
        transferItems(rowindexes, source.left, source.right);
        gridLeft.jqxGrid("clearselection");
        this.blur();
        buttonStatus(false);
      });
      btnLeft.click(function () {
        buttonStatus(true);
        var rowindexes = gridRight.jqxGrid("getselectedrowindexes");
        transferItems(rowindexes, source.right, source.left);
        gridRight.jqxGrid("clearSelection");
        this.blur();
        buttonStatus(false);
      });
      btnRightAll.click(function () {
        buttonStatus(true);
        if (dataType == "observableArray") {
          source.right.valueWillMutate();
          ko.utils.arrayPushAll(source.right(), source.left());
          source.right.valueHasMutated();
          source.left.removeAll();
        } else {
          self.rowindexes = [];
          self.rowindexes = gridLeft.jqxGrid("getrows");
          $.merge(source.right, self.rowindexes);
          self.rowindexes = self.rowindexes.map(function (item) {
            return item.dataindex;
          });

          for (var j = self.rowindexes.length - 1; j >= 0; j--) {
            source.left.splice(self.rowindexes[j], 1);
          }
          adapterLeft.dataBind();
          adapterRight.dataBind();
        }
        this.blur();
        buttonStatus(false);

        gridLeft.jqxGrid("clearselection");
        gridLeft.jqxGrid("clearfilters");
        gridRight.jqxGrid("clearselection");

        if (onColumnTransfer != null) {
          onColumnTransfer.call();
        }
      });
      btnLeftAll.click(function () {
        buttonStatus(true);
        if (dataType == "observableArray") {
          source.left.valueWillMutate();
          ko.utils.arrayPushAll(source.left(), source.right());
          source.left.valueHasMutated();
          source.right.removeAll();
        } else {
          self.rowindexes = [];
          self.rowindexes = gridRight.jqxGrid("getrows");
          $.merge(source.left, self.rowindexes);

          self.rowindexes = self.rowindexes.map(function (item) {
            return item.dataindex;
          });

          for (var j = self.rowindexes.length - 1; j >= 0; j--) {
            source.right.splice(self.rowindexes[j], 1);
          }
          adapterLeft.dataBind();
          adapterRight.dataBind();
        }
        this.blur();
        buttonStatus(false);

        gridLeft.jqxGrid("clearselection");
        gridRight.jqxGrid("clearselection");
        gridRight.jqxGrid("clearfilters");

        if (onColumnTransfer != null) {
          onColumnTransfer.call();
        }
      });
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geTimePicker = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var source = valueAccessor(),
        allBindings = allBindingsAccessor(),
        columns = allBindings.columns,
        filterable =
          allBindings.filterable != null ? allBindings.filterable : true,
        sourceData = allBindings.source,
        unwrap = ko.utils.unwrapObservable;

      var timePicker = $(element);

      timePicker.attr("autocomplete", "off");
      timePicker.css("width", "100px");
      timePicker.css("border-radius", "3px");
      timePicker.css("color", "#000");
      timePicker.css("font-size", "13px");
      timePicker.css("height", "25px");
      timePicker.css("font-family", "Verdana");
      timePicker.timepicker();

      timePicker.on("change", function (event) {
        source(timePicker.val());
      });
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor(),
        valueUnwrapped = ko.utils.unwrapObservable(value) || "";

      var timePicker = $(element);
      timePicker.timepicker();
      timePicker.val(valueUnwrapped);
    },
  };

  ko.bindingHandlers.geTree = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        setContent = allBindings.setContent || "",
        onClose = allBindings.onClose,
        unwrap = ko.utils.unwrapObservable,
        options = allBindings.properties || {},
        width = allBindings.width || "220px",
        height = allBindings.height || "30px",
        treeWidth = allBindings.treeWidth || "220px",
        treeHeight = allBindings.treeHeight || "300px",
        displayInline = allBindings.displayInline || true,
        onCheckChange = allBindings.onCheckChange || function (...x) {},
        setContentSelectedText = allBindings.contentSelectedText || "LABEL";

      var ddButton = $(element);
      var wrapDiv = $("<div>");
      var tree = $("<div>");

      ddButton.append(tree);

      if (displayInline) {
        wrapDiv.css("display", "inline-block");
      } else {
        wrapDiv.css("display", "block");
      }

      ddButton.wrap(wrapDiv);
      var id = ddButton.attr("id");
      if (id != null) {
        ddButton.removeAttr("id");
        tree.attr("id", id);
      }

      ddButton.jqxDropDownButton({
        width: width,
        height: height,
        animationType: "none",
      });

      ddButton.on("close", function () {
        if (onClose != null) {
          onClose();
        }
      });

      tree.on("checkChange", function (event) {
        var dropDownContent = "";
        var items = tree.jqxTree("getCheckedItems");

        onCheckChange({
          sourceData: ko.toJS(modelValue),
          checkedItems: items || [],
        });

        if (setContentSelectedText.toUpperCase() == "VALUE") {
          for (var i = 0; i < items.length; i++) {
            if (items[i].value != undefined) {
              dropDownContent += items[i].value + ",";
            }
          }
        } else {
          for (let i = 0; i < items.length; i++) {
            dropDownContent += items[i].label + ",";
          }
        }

        if (items.length > 0) {
          dropDownContent = dropDownContent.slice(0, -1);

          dropDownContent =
            '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
            dropDownContent +
            "</div>";
          ddButton.jqxDropDownButton("setContent", dropDownContent);
        } else {
          ddButton.jqxDropDownButton(
            "setContent",
            '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
              setContent +
              "</div>"
          );
        }
      });

      modelValue.subscribe(function (newVal) {
        tree.jqxTree({
          source: newVal,
        });

        tree.jqxTree("refresh");
      });
      var items = ko.toJS(modelValue);

      tree.jqxTree({
        source: items,
        hasThreeStates: true,
        checkboxes: true,
        allowDrag: false,
        allowDrop: false,
        width: 300,
        height: 300,
      });

      if (isDeviceMobileOrTablet()) {
        // Hack to update the positioning since it gets set way off
        // on mobile android devices
        ddButton.on("open", function (event) {
          var parent = ddButton;
          var el = $("#" + parent.attr("aria-owns")); // This is the property jq uses to assign which calendar picker belongs to the input

          // Get the current position of the parent container relative to the window
          var containerElPos =
            $(parent).offset().top -
            (window.scrollY || window.pageYOffset || document.body.scrollTop);

          // update the positioning to be under parent container element
          el.css({
            position: "fixed",
            top: containerElPos + 20,
            "overflow-x": "hidden",
          });
        });
      }
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  var geRangePickerLastUpdateInfo = { target: null, value: null }; // prevent update endless loops
  ko.bindingHandlers.geRangePicker = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        options = allBindings.properties || {};
      var $dateRange = $(element);
      options.width = options.width != null ? options.width : "200px";
      options.height = "25px";
      options.selectionMode = "range";
      options.formatString = "MM/dd/yyyy";
      options.value = undefined;
      options.placeHolder = "mm/dd/yyyy-mm/dd/yyyy";
      $dateRange.jqxDateTimeInput(options);
      var valueUnwrapped = unwrap(modelValue);
      if (valueUnwrapped != null) {
        setDates(valueUnwrapped);
      }

      modelValue.subscribe(function (x) {
        if (x == null) {
          $dateRange.val(null);
        } else if (typeof x == "object" && Object.keys(x).length > 1) {
          setDates(x);
        }
      });
      $dateRange.on("valueChanged", function (event) {
        var jsDate = event.args.date;

        // prevent infinite update loops
        if (
          geRangePickerLastUpdateInfo.target == event.currentTarget &&
          geRangePickerLastUpdateInfo.value == event.currentTarget.value
        )
          return;

        if (jsDate.from != null) {
          geRangePickerLastUpdateInfo.target = event.currentTarget;
          geRangePickerLastUpdateInfo.value = event.currentTarget.value;

          modelValue(jsDate);
        } else {
          geRangePickerLastUpdateInfo.target = null;
          geRangePickerLastUpdateInfo.value = null;

          modelValue(undefined);
        }
      });

      function setDates(dateObj) {
        var from = null;
        var to = null;
        try {
          from = new Date(dateObj.from);
          to = new Date(dateObj.to);
        } catch (error) {}

        $dateRange.jqxDateTimeInput("setRange", from, to);
      }
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geCustomDateRangePicker = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        options = allBindings.properties || {},
        initialFromDate = options.initialFromDate || null,
        initialToDate = options.initialToDate || null,
        defaultFromDate = options.defaultFromDate || null,
        defaultToDate = options.defaultToDate || null,
        onValueChangeHook = allBindings.onValueChange || function () {},
        width = options.width || null;

      var $masterEl = $(element);
      var valueUnwrapped = unwrap(modelValue);

      if (valueUnwrapped != null) {
        var from = null;
        var to = null;
        try {
          from = new Date(valueUnwrapped.from);
          to = new Date(valueUnwrapped.to);
        } catch (error) {}
        initialFromDate = from;
        initialToDate = to;
      }

      var dateRangePicker =
        new GreatEdgeCustomWidgets().GEDateRangePickerWidget({
          bindToElement: $masterEl,
          initialFromDate: initialFromDate,
          initialToDate: initialToDate,
          minAllowedYear: 1990,
          width: width,
          valueChangeCallBackFn: function (changedValObj) {
            var from =
              changedValObj.from != null ? new Date(changedValObj.from) : null;
            var to =
              changedValObj.to != null ? new Date(changedValObj.to) : null;
            var _date;
            if (from == null && defaultFromDate) {
              _date = new Date(defaultFromDate);
              _date.setHours(0, 0, 0, 0);
              from = _date;
            }

            if (to == null && defaultToDate) {
              _date = new Date(defaultToDate);
              _date.setHours(0, 0, 0, 0);
              to = _date;
            }

            if (from != null) {
              from.setHours(0, 0, 0, 0);
            }

            if (to != null) {
              to.setHours(0, 0, 0, 0);
            }

            if (from != null || to != null) {
              modelValue({ from: from, to: to });
            } else {
              modelValue(null);
            }

            onValueChangeHook({ from, to });
          },
        });
      modelValue.subscribe(function (x) {
        if (x == null) {
          dateRangePicker.resetInputFields();
        } else if (typeof x == "object") {
          dateRangePicker.setDates(x);
        }
      });
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geStatesDDL = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        isReadOnly = allBindings.isReadOnly || false,
        userOptions = allBindings.properties || {};

      var $el = $(element);

      if (modelValue() == undefined && isReadOnly) {
        return false;
      }
      var source = {
        datatype: "json",
        url: "Location/GetStates/Extended",
        datafields: [{ name: "id" }, { name: "code" }, { name: "displayName" }],
        formatdata: function (data) {
          return data;
        },
        beforeLoadComplete: function (data) {
          data.unshift({ id: 0, displayName: "Select State", code: null });
          return data;
        },
        loadComplete: function (data) {
          if (modelValue() != undefined) {
            if (data.length > 0) {
              $.each(data, function (index, item) {
                if (item.code != null && item.code == modelValue()) {
                  var _gridItemObj = $el.jqxDropDownList(
                    "getItemByValue",
                    item.code
                  );
                  $el.jqxDropDownList("selectIndex", _gridItemObj.index);
                }
              });
            }
          } else {
            $el.jqxDropDownList("selectIndex", 0);
          }

          if (isReadOnly) {
            $el.jqxDropDownList({ disabled: true });
          }
        },
      };

      var dataAdapter = dataModel.getDataAdapter(source);

      var _options = {
        checkboxes: false,
        placeHolder: "Select State",
        source: dataAdapter,
        displayMember: "displayName",
        valueMember: "code",
        width: 200,
        height: 25,
        autoDropDownHeight: false,
        filterable: true,
        dropDownHeight: 200,
        dropDownWidth: 300,
        animationType: "none",
        searchMode: "containsignorecase",
      };

      $el.jqxDropDownList(_options);

      if (userOptions) {
        $el.jqxDropDownList(userOptions);
      }

      $el.on("select", function (event) {
        var args = event.args;
        if (args) {
          var index = args.index;
          var item = args.item;
          var label = item.label;
          var value = item.value;
          modelValue(value);
        }
      });
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geStateProvinces = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        //  sourceValue = allBindings.sourceValue || "value",
        setContent = allBindings.setContent || "State/Province",
        onClose = allBindings.onClose,
        unwrap = ko.utils.unwrapObservable,
        showCanada = _.isEmpty(allBindings.showCanada)
          ? true
          : allBindings.showCanada,
        selectedValues = allBindings.selectedValues || false,
        btnOptions = allBindings.btnOptions || {},
        options = allBindings.properties || {};

      var ddButton = $(element);
      var wrapDiv = $("<div>");
      var tree = $("<div>");
      ddButton.append(tree);
      var id = ddButton.attr("id");

      if (id != null) {
        ddButton.removeAttr("id");
        tree.attr("id", id);
        wrapDiv.attr("id", id + "-widget");
      }

      ddButton.wrap(wrapDiv);

      ddButton.jqxDropDownButton({
        width: btnOptions.width || 150,
        animationType: "none",
        dropDownHeight: 300,
      });
      dataModel
        .ajaxRequest("location/GetStatesAndProvinces")
        .done(function (result, status, xhr) {
          var detailModel = function (item, items) {
            var self = this;
            self.label = item.name;
            self.checked = item.checked || false;
            self.expanded = false;
            self.value = item.code;
            self.items = items;
            self.id = item.id;
          };
          var source = [];
          var usa = [];
          var canada = [];
          var dropDownContent = "";
          for (var i = 0; i < result.length; i++) {
            var matchingItem = ko.utils.arrayFirst(
              modelValue(),
              function (item) {
                return item.toUpperCase() == result[i].code.toUpperCase();
              }
            );
            if (matchingItem) {
              result[i].checked = true;
              dropDownContent += result[i].name + ",";
            }

            if (result[i].country == "USA") {
              usa.push(new detailModel(result[i]));
            } else if (result[i].country == "Canada") {
              canada.push(new detailModel(result[i]));
            }
          }

          let isAllUSAChecked = usa.every((x) => x.checked);
          let isAllCanadaChecked = canada.every((x) => x.checked);

          source.push(
            new detailModel(
              { name: "USA", code: "USA", checked: isAllUSAChecked },
              usa
            )
          );
          if (showCanada) {
            source.push(
              new detailModel(
                { name: "Canada", code: "Canada", checked: isAllCanadaChecked },
                canada
              )
            );
          }
          tree.jqxTree({
            source: source,
          });

          if (dropDownContent != "") {
            dropDownContent = dropDownContent.slice(0, -1);
            dropDownContent =
              '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
              dropDownContent +
              "</div>";
            ddButton.jqxDropDownButton("setContent", dropDownContent);
          }
        })
        .fail(function (jqXHR, textStatus, errorThrown) {});

      // Hack to update the positioning since it gets set way off
      // on mobile android devices
      if (isDeviceMobileOrTablet()) {
        ddButton.on("open", function (event) {
          // Get the current position of the parent container relative to the window
          var containerElPos =
            $(ddButton).offset().top -
            (window.scrollY || window.pageYOffset || document.body.scrollTop);

          // grab our jqx element
          var el = $("#" + id).parent();

          // update the positioning to be under parent container element
          el.css("position", "fixed").css("top", containerElPos + 20);
        });
      }
      ddButton.on("close", function () {
        if (onClose != null) {
          onClose();
        }
      });

      let updatedFromObservable = false;
      tree.on("checkChange", function (event) {
        if (updatedFromObservable) {
          if (!modelValue() || !modelValue().length) {
            ddButton.jqxDropDownButton(
              "setContent",
              "<div style='position: relative; margin-left: 3px; margin-top: 5px; font-style: italic;'>" +
                setContent +
                "</div>"
            );
          }

          return false;
        }

        var args = event.args;
        var checkedItem = tree.jqxTree("getItem", args.element);
        if (checkedItem != null) {
          if (checkedItem.value != null) {
            //Adding/Removing it to the observable...
            if (checkedItem.label != "USA" && checkedItem.label != "Canada") {
              if (checkedItem.checked) {
                modelValue.pushIfNotExist(checkedItem.value);
                if (selectedValues) {
                  selectedValues.pushIfNotExist(checkedItem);
                }
              } else {
                modelValue.remove(checkedItem.value);
                if (selectedValues) {
                  selectedValues.remove(checkedItem);
                }
              }
            } else {
              args.owner.source
                .find((x) => x.value == checkedItem.value)
                .items.forEach((x) => {
                  if (checkedItem.checked) {
                    modelValue.pushIfNotExist(x.value);
                    if (selectedValues) {
                      selectedValues.pushIfNotExist(x.value);
                    }
                  } else {
                    modelValue.remove(x.value);
                    if (selectedValues) {
                      selectedValues.remove(x.value);
                    }
                  }
                });
            }

            //Change the text on the dropdown...
            var dropDownContent = "";
            var items = tree.jqxTree("getCheckedItems");
            for (var i = 0; i < items.length; i++) {
              if (items[i].label != "USA" && items[i].label != "Canada") {
                dropDownContent += items[i].label + ",";
              }
            }
            if (items.length > 0) {
              dropDownContent = dropDownContent.slice(0, -1);
              dropDownContent =
                '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
                dropDownContent +
                "</div>";
              ddButton.jqxDropDownButton("setContent", dropDownContent);
            } else {
              ddButton.jqxDropDownButton(
                "setContent",
                "<div style='position: relative; margin-left: 3px; margin-top: 5px; font-style: italic;'>" +
                  setContent +
                  "</div>"
              );
            }
          }
        } else {
          modelValue.removeAll();
          ddButton.jqxDropDownButton(
            "setContent",
            "<div style='position: relative; margin-left: 3px; margin-top: 5px; font-style: italic;'>" +
              setContent +
              "</div>"
          );
        }
      });

      tree.jqxTree({
        hasThreeStates: false,
        allowDrag: false,
        allowDrop: false,
        checkboxes: true,
        width: 220,
        height: 300,
      });

      modelValue.refresh = function () {
        var items = tree.jqxTree("getItems");
        for (var i = 0; i < items.length; i++) {
          var item = items[i];
          if (item.value != "USA" && item.value != "Canada") {
            var matchingItem = ko.utils.arrayFirst(
              modelValue(),
              function (tmp) {
                return tmp
                  ? tmp.toUpperCase() == item.value.toUpperCase()
                  : false;
              }
            );
            if (matchingItem != null) {
              if (item.checked == false) {
                tree.jqxTree("checkItem", item, true);
              }
            } else {
              if (item.checked) {
                tree.jqxTree("checkItem", item, false);
              }
            }
          }
        }
      };

      modelValue.subscribe(function (x) {
        if (x && x.length) {
          modelValue.refresh();
        } else {
          updatedFromObservable = true;
          tree.jqxTree("uncheckAll");
          updatedFromObservable = false;
        }
      });

      ddButton.jqxDropDownButton(
        "setContent",
        "<div style='position: relative; margin-left: 3px; margin-top: 5px; font-style: italic;'>" +
          setContent +
          "</div>"
      );
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };
  ko.bindingHandlers.percentage = {
    precision: ko.observable(2),
    init: function (element, valueAccessor, allBindingsAccessor) {
      //only inputs need this, text values don't write back
      if ($(element).is("input") === true) {
        var underlyingObservable = valueAccessor(),
          interceptor = ko.computed({
            read: underlyingObservable,
            write: function (value) {
              if (value === "") {
                underlyingObservable(null);
              } else {
                underlyingObservable(rawNumber(value));
              }
            },
          });
        ko.bindingHandlers.value.init(
          element,
          function () {
            return interceptor;
          },
          allBindingsAccessor
        );
      }
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
      var precision = ko.unwrap(
          allBindingsAccessor().precision !== undefined
            ? allBindingsAccessor().precision
            : ko.bindingHandlers.percentage.precision
        ),
        value = ko.unwrap(valueAccessor());
      if ($(element).is("input") === true) {
        //leave the boxes empty by default
        value =
          value !== null && value !== undefined && value !== ""
            ? formatPercentage(parseFloat(value), precision)
            : "";
        $(element).val(value);
      } else {
        //text based bindings its nice to see a 0 in place of nothing
        value = value || 0;
        $(element).text(formatPercentage(parseFloat(value), precision));
      }
    },
  };

  ko.bindingHandlers.geRegions = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        setContent = allBindings.setContent || "Regions",
        onClose = allBindings.onClose,
        unwrap = ko.utils.unwrapObservable,
        options = allBindings.properties || {};

      var ddButton = $(element);
      var wrapDiv = $("<div>");
      var tree = $("<div>");

      ddButton.append(tree);
      wrapDiv.css("display", "inline-block");
      ddButton.wrap(wrapDiv);
      var id = ddButton.attr("id");
      if (id != null) {
        ddButton.removeAttr("id");
        tree.attr("id", id);
      }

      ddButton.jqxDropDownButton({ width: 150, height: 25 });

      dataModel
        .ajaxRequest("Region")
        .done(function (result, status, xhr) {
          var detailModel = function (item, items) {
            var self = this;
            self.label = item.name;
            self.checked = item.checked || false;
            self.expanded = false;
            self.value = item.code;
            self.items = items;
          };

          var source = [];
          var dropDownContent = "";
          for (var i = 0; i < result.length; i++) {
            var matchingItem = ko.utils.arrayFirst(
              modelValue(),
              function (item) {
                return item.toUpperCase() == result[i].code.toUpperCase();
              }
            );
            if (matchingItem) {
              result[i].checked = true;
              dropDownContent += result[i].code + ",";
            }
            source.push(new detailModel(result[i]));
          }
          tree.jqxTree({
            source: source,
          });
          if (dropDownContent != "") {
            dropDownContent = dropDownContent.slice(0, -1);
            dropDownContent =
              '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
              dropDownContent +
              "</div>";
            ddButton.jqxDropDownButton("setContent", dropDownContent);
          }
        })
        .fail(function (jqXHR, textStatus, errorThrown) {});

      ddButton.on("close", function () {
        if (onClose != null) {
          onClose();
        }
      });

      let updatedFromObservable = false;
      tree.on("checkChange", function (event) {
        if (updatedFromObservable) {
          if (!modelValue() || !modelValue().length) {
            ddButton.jqxDropDownButton(
              "setContent",
              "<div style='position: relative; margin-left: 3px; margin-top: 5px; font-style: italic;'>" +
                setContent +
                "</div>"
            );
          }

          return false;
        }

        var args = event.args;
        if (args != null) {
          var checkedItem = tree.jqxTree("getItem", args.element);
          if (checkedItem != null) {
            if (checkedItem.value != null) {
              if (checkedItem.checked) {
                modelValue.pushIfNotExist(checkedItem.value);
              } else {
                modelValue.remove(checkedItem.value);
              }
              var dropDownContent = "";
              var items = tree.jqxTree("getCheckedItems");
              for (var i = 0; i < items.length; i++) {
                dropDownContent += items[i].value + ",";
              }
              if (items.length > 0) {
                dropDownContent = dropDownContent.slice(0, -1);

                dropDownContent =
                  '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' +
                  dropDownContent +
                  "</div>";
                ddButton.jqxDropDownButton("setContent", dropDownContent);
              } else {
                ddButton.jqxDropDownButton(
                  "setContent",
                  "<div style='position: relative; margin-left: 3px; margin-top: 5px; font-style: italic;'>" +
                    setContent +
                    "</div>"
                );
              }
            }
          } else {
            modelValue.removeAll();
            ddButton.jqxDropDownButton(
              "setContent",
              "<div style='position: relative; margin-left: 3px; margin-top: 5px; font-style: italic;'>" +
                setContent +
                "</div>"
            );
          }
        }
      });

      tree.jqxTree({
        source: [],
        hasThreeStates: true,
        checkboxes: true,
        width: "350px",
        height: "300px",
      });
      modelValue.refresh = function () {
        var items = tree.jqxTree("getItems");
        for (var i = 0; i < items.length; i++) {
          var item = items[i];
          var matchingItem = ko.utils.arrayFirst(modelValue(), function (tmp) {
            return tmp.toUpperCase() == item.value.toUpperCase();
          });
          if (matchingItem != null) {
            if (item.checked == false) {
              tree.jqxTree("checkItem", item, true);
            }
          } else {
            if (item.checked) {
              tree.jqxTree("checkItem", item, false);
            }
          }
        }
      };

      modelValue.subscribe(function (x) {
        if (x && x.length) {
          modelValue.refresh();
        } else {
          updatedFromObservable = true;
          tree.jqxTree("uncheckAll");
          updatedFromObservable = false;
        }
      });

      ddButton.jqxDropDownButton(
        "setContent",
        "<div style='position: relative; margin-left: 3px; margin-top: 5px; font-style: italic;'>" +
          setContent +
          "</div>"
      );
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.bootstrapModal = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var value = valueAccessor();
      $(element)
        .addClass("modal")
        .addClass("fade")
        .addClass("in")
        .modal({ keyboard: false, show: ko.unwrap(value) });
    },
    update: function (element, valueAccessor) {
      var value = valueAccessor();
      ko.unwrap(value) ? $(element).modal("show") : $(element).modal("hide");
    },
  };
  ko.bindingHandlers.messagedialog = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };
}

function koValidation() {
  ko.validation.rules["validDate"] = {
    validator: function (val, param) {
      if (param && (val === undefined || val === null) == false) {
        let d = dayjs(val);
        if (
          d.isValid() &&
          d.isAfter(new Date(1950, 1, 1)) &&
          d.isBefore(new Date(2100, 1, 1))
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
    message: "Please enter a date in dd/mm/yyyy format",
  };

  ko.validation.rules["phoneLength"] = {
    validator: function (val, expectedLength) {
      var input = val === undefined ? val : val.replace(/\D/g, "");
      return (
        input === undefined || input === "" || input.length === expectedLength
      );
    },
    message: "Please enter a valid phone number",
  };

  ko.validation.rules["faxLength"] = {
    validator: function (val, expectedLength) {
      var input = val === undefined ? val : val.replace(/\D/g, "");
      return (
        input === undefined || input === "" || input.length === expectedLength
      );
    },
    message: "Please enter a valid fax number",
  };

  ko.validation.rules["minArrayLength"] = {
    validator: function (val, params) {
      var minLength = params.minLength || 1;
      if (val.length >= minLength) {
        return true;
      }
      return false;
    },
    message: "At least one item is required",
  };

  ko.validation.makeBindingHandlerValidatable("jqAuto");
  ko.validation.makeBindingHandlerValidatable("geCityStateZip");
  ko.validation.makeBindingHandlerValidatable("geTimePicker");
  ko.validation.makeBindingHandlerValidatable("geTree");
  ko.validation.makeBindingHandlerValidatable("geRangePicker");
  ko.validation.makeBindingHandlerValidatable("geCustomDateRangePicker");
  ko.validation.makeBindingHandlerValidatable("geStatesDDL");
  ko.validation.makeBindingHandlerValidatable("geStateProvinces");
  ko.validation.makeBindingHandlerValidatable("geRegions");
  ko.validation.makeBindingHandlerValidatable("geInput");
  ko.validation.makeBindingHandlerValidatable("geNumberInput");
  ko.validation.makeBindingHandlerValidatable("gePhone");
  ko.validation.makeBindingHandlerValidatable("geMaskedInput");
  ko.validation.makeBindingHandlerValidatable("geDateTime");
  ko.validation.makeBindingHandlerValidatable("geDropDownList");
  ko.validation.makeBindingHandlerValidatable("geComboBox");
  ko.validation.makeBindingHandlerValidatable("geTextArea");
  ko.validation.makeBindingHandlerValidatable("geEditor");
  ko.validation.makeBindingHandlerValidatable("currency");
  ko.validation.makeBindingHandlerValidatable("geNumeric");
}

function koExtenders() {
  // Uses:
  // nodeModel: class used to create new item.  No additional parameters passed to object constructor.
  // .extend({ liveEditor: noteModel });

  // noteModel: class used to create new item.
  // params: Parameters passed to object constructor.  Must be an array.  The 1st array element will be the 1st parameter
  //         passed to object constructor, 2nd element = 2nd parameter, 3rd = 3rd... ect.
  // .extend({ liveEditor: { model: noteModel,
  //     params: [{
  //         driverId: data.id,
  //         driverExternalId: data.code
  //     }]
  // }});
  ko.extenders.liveEditor = function (target, options) {
    var model = function () {};
    var params = [{}];
    if (typeof options == "function") {
      model = options;
    } else {
      model = options.model;
      params = options.params;
    }
    ko.utils.extend(target, {
      selectedItem: ko.observable(),
      itemForEditing: ko.observable(),
      mode: ko.observable(),
      addNewItem: function () {
        if (target.mode() == "edit") {
          var index = target.indexOf(target.selectedItem);
          if (index > -1) target.splice(index, 1, target.itemForEditing()); // reset edited item
        }
        if (target.mode() != "insert") {
          var newItem = Object.create(model.prototype);
          model.apply(newItem, params);
          target.push(newItem);
          target.selectedItem(newItem);
          target.mode("insert");
        }
      },
      edit: function (item) {
        if (target.mode() == "insert") {
          target.pop();
        }
        var tmpParam = [{}];
        if (typeof options == "function") {
          tmpParam = $.extend(true, [{}], params);
        } else {
          tmpParam = $.extend(true, [], params);
        }
        tmpParam[0] = ko.toJS(item);

        target.selectedItem(item);

        var tmpItem = Object.create(model.prototype);
        model.apply(tmpItem, tmpParam);
        target.itemForEditing(tmpItem);

        target.mode("edit");
      },
      delete: function (item) {
        target.remove(item);
        if (item.deletedItem != null) {
          item.deletedItem(item);
        }
      },
      accept: function (item) {
        var validationErrors;
        if (item.contactModel === true) {
          validationErrors = ko.validation.group(target.selectedItem);
        } else {
          validationErrors = ko.validation.group(item);
        }

        if (validationErrors().length > 0) {
          validationErrors.showAllMessages();
          return false;
        }
        //clear selected item
        target.selectedItem(undefined);
        target.itemForEditing(undefined);
        target.mode(undefined);

        if (item.acceptedItem != null) {
          item.acceptedItem(item);
        }
      },
      reset: function (item) {
        if (target.mode() == "insert") {
          target.remove(item);
        } else if (target.mode() === "edit") {
          var index = target.indexOf(item);
          target.splice(index, 1, target.itemForEditing()); // reset edited item
        }
        target.itemForEditing(undefined);
        target.selectedItem(undefined);
        target.mode(undefined);
      },
    });
    return target;
  };

  // Sets the observable to be readonly.
  // Prevents input disable state from being changed in devtools, etc allowing a
  // user to change the input value.
  ko.extenders.readOnly = function (target, isReadOnly) {
    let _canWrite = ko.observable(true);
    var computed = ko.computed({
      read: target,
      write: function (value) {
        if (_canWrite()) target(value);
      },
    });

    _canWrite(!isReadOnly);
    return computed;
  };

  ko.validation.registerExtenders();
}

function koFunctions() {
  ko.observableArray.fn.inArray = function (comparer) {
    var array = this();
    for (var i = 0; i < array.length; i++) {
      if (comparer(array[i])) return true;
    }
    return false;
  };
  ko.observableArray.fn.pushIfNotExist = function (element, comparer) {
    if (comparer != null) {
      if (!this.inArray(comparer)) {
        this.push(element);
      }
    } else {
      if (this.indexOf(element) < 0) {
        this.push(element);
      }
    }
  };

  //http://www.knockmeout.net/2011/06/lazy-loading-observable-in-knockoutjs.html
  //an observable that retrieves its value when first bound
  ko.onDemandObservable = function (callback, target) {
    var _value = ko.observable(); //private observable
    var result = ko.dependentObservable({
      read: function () {
        //if it has not been loaded, execute the supplied function
        if (!result.loaded()) {
          callback.call(target);
        }
        //always return the current value
        return _value();
      },
      write: function (newValue) {
        //indicate that the value is now loaded and set it
        result.loaded(true);
        _value(newValue);
      },
      deferEvaluation: true, //do not evaluate immediately when created
    });

    //expose the current state, which can be bound against
    result.loaded = ko.observable();
    //load it again
    result.refresh = function () {
      result.loaded(false);
    };

    return result;
  };

  ko.protectedObservable = function (initialValue) {
    //private variables
    var _temp = initialValue;
    var _actual = ko.observable(initialValue);

    var result = ko
      .computed({
        read: _actual,
        write: function (newValue) {
          _temp = newValue;
        },
      })
      .extend({ notify: "always" }); //needed in KO 3.0+ for reset, as computeds no longer notify when value is the same
    //commit the temporary value to our observable, if it is different
    result.commit = function () {
      if (_temp !== _actual()) {
        _actual(_temp);
      }
    };
    //notify subscribers to update their value with the original
    result.reset = function () {
      _actual.valueHasMutated();
      _temp = _actual();
    };

    return result;
  };

  ko.dirtyFlag = function (root, isInitiallyDirty) {
    var result = function () {},
      _initialState = ko.observable(ko.toJSON(root)),
      _isInitiallyDirty = ko.observable(isInitiallyDirty);

    result.isDirty = ko.computed(function () {
      return _isInitiallyDirty() || _initialState() !== ko.toJSON(root);
    });

    result.reset = function () {
      _initialState(ko.toJSON(root));
      _isInitiallyDirty(false);
    };

    return result;
  };
}

function geKnockout() {
  koBindingHandlers();
  koValidation();
  koExtenders();
  //koBindingProvider() // this is now provided in ko-dataStore provider (./dataStore/index.js)
  koFunctions();
}
geKnockout();
