import dataModel from 'data-model';
import userProfile from 'user-profile';
import {mapKOtoJS} from "global-functions";

class TwoFactorSettingsViewModel {
    constructor(params) {

        params = params || {};
        this.onSettingsUpdate = params.onSettingsUpdate && typeof params.onSettingsUpdate === "function"
        ? params.onSettingsUpdate
        : function () {};

        this.settings = ko.observable(ko.unwrap(params.settings) || {});
        this.formData = ko.observable();

        this.carrierGatewayList = ko.observableArray([]);
        this.methodOptions = ko.observableArray([
            {value: 1, text: "EMAIL"},
            {value: 2, text: "PHONE"}
        ])

        this.display2FAConfirm = ko.observable(false);
        this.saveSuccess = ko.observable(false);
        
        this.enableSaveBtn = ko.pureComputed(() => {
            let errors = ko.validation.group(this.formData());

            return this.display2FAConfirm() == false && errors().length == 0;
        });

        this.resendLimitMsg = "You have reached the resend limit. Please go back and try using a different phone number or email.";
        this.maxAttemptsExceededMsg = "You have too many failed entry attempts. Resend a new code if available or try a different email / phone number.";

        this.initalizeComponent();
    }

    initalizeComponent = async () => {
        
        await this.loadCarrierGateways().catch((err) => console.error(err));

        this.formData(new FormModel(this.settings()));
        this.applyBindings();
    }

    applyBindings = () => {
        $("#setup-modal").on("hide.bs.modal", () => {
            this.onSettingsUpdate(this.saveSuccess());
        });
    }

    loadCarrierGateways = async () => {
        return this.http_fetchCarrierGateways()
        .then((gatewayList) => this.carrierGatewayList(gatewayList));
    }

    handleSaveSettings = () => {
        var result = this.formData().validate();

        if(result.isValid == false) return false;

        let payload = mapKOtoJS(this.formData(), { takeOnly: ["id", "contact", "method"]});
        payload.userId = userProfile.userId;
        this.settings(payload);

        this.display2FAConfirm(true);
    }

    handle2FAConfirmation = async (verified) => {
        if(verified) await this.saveSettings();
    }

    saveSettings = async () => {
        await this.http_postTwoFactorSettings(this.settings()).catch((err) => this.formData().validationErrors.push(err));

        if(this.formData().validationErrors.length == 0) {
            this.saveSuccess(true);
            this.onSettingsUpdate(this.saveSuccess());
        }
    }

    http_fetchCarrierGateways = () => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Lookup/MobileCarrierGateways", "GET").done((response) => resolve(response))
            .fail((error) => reject(error));
        })
    }

    http_postTwoFactorSettings = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("User/UpsertTwoFactorSettings", "POST", payload).done((response) => resolve(response))
            .fail((error) => {
                reject(
                    (error.responseJSON && error.responseJSON.message) ||
                        "An unexpected error occurred. Please try again or contact Help Desk."
                );
            });  
        });
    }
}

export const FormModel = function({id, contact, method}) {

    let phone = contact && method == MethodsEnum.PHONE ? contact.substring(0, contact.indexOf("@")) : null;
    let gateway = contact && method == MethodsEnum.PHONE ? contact.substring(contact.indexOf("@") + 1) : null;

    this.id = ko.observable(id);
    this.method = ko.observable(method).extend({required: true});
    this.contact = ko.pureComputed(() => {
        return this.method() == MethodsEnum.EMAIL ? this.email() : 
            this.method() == MethodsEnum.PHONE ? this.normalizePhoneNumber(this.phone()) + "@" + this.mobileCarrierGateway() : null;
    });

    this.phone = ko.observable(phone).extend({ 
        phoneUS: true,
        required: { 
            message: "Phone number is required.", 
            onlyIf: () => { return ko.unwrap(this.method) == MethodsEnum.PHONE }
        }
    });

    this.email = ko.observable(contact).extend({
        email: true,
        required: { 
            message: "Email is required.", 
            onlyIf: () => { return ko.unwrap(this.method) == MethodsEnum.EMAIL }
        }
    });

    this.mobileCarrierGateway = ko.observable(gateway).extend({
        required: { 
            message: "Mobile Carrier is required.", 
            onlyIf: () => { return ko.unwrap(this.method) == MethodsEnum.PHONE }
        }
    });

    this.method.subscribe((val) => {
        if(val == MethodsEnum.EMAIL && val != method) {
            this.email(null);
        }
        else if(val == MethodsEnum.PHONE && val != method) {
            this.mobileCarrierGateway(null);
            this.phone(null);
        }
    })

    this.validationErrors = ko.observableArray([]);
    this.validate = () => {
        this.validationErrors.removeAll();
        var result = { isValid: true, errors: this.validationErrors() };

        var validationErrors = ko.validation.group(this);

        if (validationErrors().length > 0) {
            validationErrors.showAllMessages();
            this.validationErrors.push("Please check your entries are valid.");
        }

        if(!this.contact()) {
            this.validationErrors.push("Email or phone number are required.");
        }

        result.isValid = this.validationErrors().length == 0;
        return result;
    }


    this.normalizePhoneNumber = (phone) => {
        phone = phone || this.phone() || "";

        return phone.replace(new RegExp("/s/", 'g'), '')
            .replace(new RegExp("/[a-zA-Z]/", 'g'), '')
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "");    
    }
}

export const MethodsEnum = {
    EMAIL: 1,
    PHONE: 2
}

import template from './two-factor-settings-component.html';
export default { viewModel: TwoFactorSettingsViewModel, template: template } 