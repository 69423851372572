import dataModel from 'data-model';
import ko from 'knockout';
import template from './carriers-send-carrier-packet-component.html';

var SendCarrierPacketViewModel = function () {
    var self = this;

    self.errors = ko.observableArray();
    self.isLoading = ko.observable(false);
    self.message = ko.observable();
    self.isEmail = ko.observable(false);
    self.isFax = ko.observable(false);

    self.packet = ko.observable().extend({ required: true });
    self.contact = ko.observable();
    self.email = ko.observable().extend({
        email: true,
        required: {
            message: 'Email is required',
            onlyIf: function () {
                if (self.isEmail() == true) {
                    return true;
                }
                return false;
            }
        }
    });
    self.fax = ko.observable().extend({
        minLength: 10,
        required: {
            message: 'Fax is required',
            onlyIf: function () {
                if (self.isFax() == true) {
                    return true;
                }
                return false;
            }
        }
    });
    self.comment = ko.observable();
    self.packets = ko.observableArray([]);
    self.sendPacket = function () {
        self.message(undefined);
        self.errors.removeAll();
        var validationErrors = ko.validation.group(self);
        if (validationErrors().length > 0) {
            validationErrors.showAllMessages();
            return false;
        }
        else if (self.isEmail() == false && self.isFax() == false) {
            self.errors.push("Please enter valid eamil or fax number");
            return false;
        }

        self.isLoading(true);
        var data = {
            packetId: self.packet(),
            fax: self.isFax() ? self.fax() : null,
            email: self.isEmail() ? self.email() : null,
            comment: self.comment()
        };

        dataModel.ajaxRequest("ExternalBoard/sendPacket", "post", data)
        .done(function (data, textStatus, jqXHR) {
            self.isLoading(false);
            self.message("The information packet was successfully sent to your recipient");
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
            self.isLoading(false);
        });
    };

    dataModel.ajaxRequest("ExternalBoard/getPackets")
    .done(function (data, textStatus, jqXHR) {
        self.packets(data);
    })
    .fail(function (jqXHR, textStatus, errorThrown) { });
}

export default { viewModel: SendCarrierPacketViewModel, template: template}