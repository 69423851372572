import template from "./tpi-view-rates-modal-component.html";

export interface TPIViewRatesModalViewModelProps {
  rates: any[];
  errors?: string[];
  loadId?: number;
  orderId?: number;
}

class TPIViewRatesModalViewModel {
  loadId?: number;
  orderId?: number;
  rates: any[] = [];
  errors?: string[] = [];

  constructor({ props }: { props: TPIViewRatesModalViewModelProps }) {
    this.loadId = props.loadId;
    this.orderId = props.orderId;
    this.rates = props.rates ?? [];

    // Remove duplicate errors
    this.errors = Array.from(new Set(props.errors ?? []));
  }
}

export default { viewModel: TPIViewRatesModalViewModel, template };
