import dayjs from "dayjs";

export class PriorityAlertModel {
  constructor(x) {
    x = x || {};

    this.id = x.id;
    this.subject = x.subject;
    this.postedDate = dayjs(x.postDate).format("MM/DD/YYYY hh:mm A");
    this.message = x.messageBody;
  }
}


export class PriorityAlertStorageManager {
    constructor() {
        this.storageKey = "priorityAlertsData";
        this._alerts = ko.observableArray(this.priorityAlertsFromStorage);

        this.priorityAlerts = ko.computed(() => this._alerts());
        this._alerts.subscribe((val) => {
            this.saveToStorage(val);
        });

        this.alertsCount = ko.computed(() => this._alerts().length);

        this.bindEvents();
    }

    bindEvents = () => {
        const vm = this;

        window.addEventListener("storage", function (e) {
            vm._alerts(vm.priorityAlertsFromStorage);
        });
    };

    get priorityAlertsFromStorage() {
        let data = [];
        try {
            let _data = localStorage.getItem(this.storageKey);
            data = _data ? JSON.parse(_data) : [];
        } catch (err) {
            console.error(`Error loading local data for Priority Alerts.`);
        }

        return data;
    }

    save = (data) => {
        if (!data) return false;

        if (Array.isArray(data)) {
            this._alerts(data);
        } else if (Object.keys(data).length > 0) {
            this._alerts.push(data);
        }
    };

    add = (data) => {
        if (!data) return false;

        if (Array.isArray(data)) {
            this._alerts([...this._alerts(), ...data]);
        } else if (Object.keys(data).length > 0) {
            this._alerts.push(data);
        }
    };

    saveToStorage = (items = []) => {
        if (!items || items.length == 0) return false;

        try {
            localStorage.setItem(this.storageKey, JSON.stringify(items));
        } catch (err) {
            console.error(`Error setting local data for Priority Alerts.`);
        }
    };

    clearAlertStorage = () => {
        try {
            this._alerts.removeAll();
            localStorage.removeItem(this.storageKey);
        } catch (err) {
            console.error(`Error removing local data for Priority Alerts.`);
        }
    };

    removeById = (id = -1) => {
        if (id <= 0) return false;

        let items = this._alerts().filter((x) => x.id !== id);

        this._alerts(items);
    };
}

const singletonInstance = new PriorityAlertStorageManager();
export default singletonInstance;