import dataModel from "data-model";
import userProfile from "user-profile";

// Controlled Component
class EditRegionManagementAssignmentsComponent {
    constructor(params) {
        params = params || {};
        this.$owner = params.$owner || {};

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.isProcessing = ko.observable(false);

        this.grid = $("#assignmentsGrid");

        this.$owner.refreshAssignmentGrid.subscribe((val) => {
            if (val) this.refreshAssignmentGrid();
        });

        this.initializeComponent();
    }

    initializeComponent = () => {
        this.loadAssignmentsGrid();
    };

    refreshAssignmentGrid = () => {
        this.grid.jqxGrid("updatebounddata", "data");
        this.$owner.refreshAssignmentGrid(false);
    };

    get gridSource() {
        const vm = this;
        return {
            url: "MyAgency/GetAssignmentList",
            datatype: "json",
            formatdata: (data) => {
                data.regionId = vm.$owner.formData.id;
                return data;
            },
            datafields: [
                { name: "id", type: "int" },
                { name: "name", type: "string" },
            ],
        };
    }

    loadAssignmentsGrid = () => {
        const vm = this;

        if (this.grid.length == 0) this.grid = $("#assignmentsGrid");

        $("#assignmentsGrid").jqxGrid("hideloadelement");

        var dataAdapter = dataModel.getDataAdapter(vm.gridSource);
        vm.grid.jqxGrid({
            theme: "GWTMDark",
            width: "100%",
            autoheight: true,
            source: dataAdapter,
            altrows: true,
            sortable: true,
            pageable: true,
            pagesize: 5,
            filterable: true,
            showfilterrow: true,
            virtualmode: false,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            rendergridrows: (obj) => {
                return obj.data;
            },

            columns: [
                {
                    text: "Id",
                    datafield: "id",
                },
                {
                    text: "Assignments",
                    datafield: "name",
                },

                {
                    text: "",
                    width: 60,
                    filterable: false,
                    sortable: false,
                    columnType: "button",
                    buttonclick: (row) => {
                        const datarow = vm.grid.jqxGrid("getrowdata", row);
                        vm.$owner.handleDeleteAssignment(datarow);
                    },
                    cellsrenderer: () => {
                        return "Delete";
                    },
                },
            ],
        });
    };
}

import template from "./assignments-component.html";
export default {
    viewModel: EditRegionManagementAssignmentsComponent,
    template: template,
};
