ko.bindingHandlers.geCheckBox = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var value = valueAccessor();
        var valueUnwrapped = ko.utils.unwrapObservable(value) || false;
        var allBindings = allBindingsAccessor(),
            isReadOnly = allBindings.isReadOnly || ko.observable(false),
            enable = allBindings.enable || ko.observable(true),
            change = allBindings.change;

        var options = allBindings.properties || {};

        var checkBox = $(element);
        checkBox.css("display", "inline-block");
        checkBox.css("white-space", "normal");

        options.checked = ko.unwrap(value);
        var geCheckBox = checkBox.jqxCheckBox(options);

        ko.utils.registerEventHandler(geCheckBox, "change", function (event) {
            let args = event.args;
            if (args != null) {
                var checked = args.checked;
                value(checked);
            }
            if (change != undefined) {
                change(event);
            }
        });
        let isDisabled;
        if (typeof isReadOnly === "function") {
            ko.computed(function () {
                isDisabled = isReadOnly();
                geCheckBox.jqxCheckBox({
                    disabled: isDisabled
                });
            });
        }
        else {
            isDisabled = isReadOnly;
            geCheckBox.jqxCheckBox({
                disabled: isDisabled
            });
        }

        if (!geCheckBox.jqxCheckBox("disabled")) {
            if (typeof enable === "function") {
                ko.computed(function () {
                    isDisabled = !enable();
                    geCheckBox.jqxCheckBox({
                        disabled: isDisabled
                    });
                });
            } else {
                isDisabled = !enable;
                geCheckBox.jqxCheckBox({
                    disabled: isDisabled
                });
            }
        }
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var value = valueAccessor(),
            valueUnwrapped = ko.utils.unwrapObservable(value);
        var checkBox = $(element);
        if(checkBox.jqxCheckBox('val') != valueUnwrapped) {
            checkBox.jqxCheckBox('val', valueUnwrapped);
        }
    }
};