import ko from 'knockout';
import dataModel from 'data-model';
import $ from 'jquery';
import template from './customer-orders-page.html';
import {createDateTimeFilterWidget, formatPhoneNumber} from 'global-functions'
import GridStateComponentViewModel from 'jqx.grid-state-component';
import gridUtils from 'jqx.grid-utils'
import GreatEdgeCustomWidgets from 'ge.custom-widgets';
import userProfile from 'user-profile'
import {showmessage} from 'show-dialog-methods'


class CustomerOrdersViewModel {

    constructor() {
        this.isLoading = ko.observable(false);
        this.orderDetailsObject = ko.observable();
        this.customerId = ko.observable();
        this.selectedCustomer = ko.observable().extend({ rateLimit: 100 });
        this.gridLoaded = false;
        this.getSavedGridState();

        this.isGridRendered = ko.observable(false);
        this.selectedCustomer.subscribe(x => {
            if(x) {
                if(this.gridLoaded == false) {
                    this.gridLoaded = true;
                    this.isGridRendered(true);
                    this.loadCustomerOrdersGrid();
                } else {
                    let dataSource = dataModel.getDataAdapter(this.getOrderGridSource());
                    $("#jqxOrderGrid").jqxGrid('source', dataSource);
                }
            }
        })

        
    }

    excelExportClick() {
        let rowdata = $("#jqxOrderGrid").jqxGrid('getrows').map((item) => {
            return {
                OrderId : item.externalId,
                Status : item.status,
                Origin : item.origin,
                Destination : item.destination,
                PickUpDate : item.pickUp,
                Delivery : item.delivery,
                TotalCharge : item.totalCharge,
                BOLNumber : item.bolNumber,
                CustomerReferenceNumber : item.customerReferenceNumber,
                Container : item.container,
                Chassis : item.chassis,
                BookingNumber : item.bookingNumber,
                PickUpNumber : item.pickUpNumber,
                CustomerOrderNumber : item.customerOrderNumber,
                TractorId : item.tractorExId,
                UNNumber : item.unNumber,
                OrderedDate : item.orderedDate,
                CommodityCode : item.commodityExId,
                BillingDistance : item.billingDistance,
                SailDate : item.sailDate,
                Rate : item.rate,
                SealNumber : item.sealNumber,
                LoadDate : item.loadDate,
                CutoffDate : item.cutoffDate
            }
        })
        
        dataModel.exportToCSV(rowdata, this.selectedCustomer().code + "Orders.csv", true)
    }

    getSavedGridState() {
        return dataModel.sjaxRequest('UserProfile/GetUserSearchFilters', 'GET', { name: 'jqxOrderGrid', pageName: window.location.pathname }).done((results) => {
            if (results) {
                var state = JSON.parse(results);
                if (state['grid']) {
                    this.gridState = state.grid;
                    console.log(state)
                }
            }
        });
    }

    getOrderGridSource() {
        return {
            url: "OrderCustomer/GetCustomerOrders",
            datatype: "json",
            sortdirection: "asc",
            data: {
                agencyId: userProfile.currentAgencyId(),
                customerId : this.selectedCustomer().code,
            },
            datafields: [
                { name: 'externalId', type: 'string' },
                { name: 'orderStatusId', type: 'int' },
                { name: 'status', type: 'string' },
                { name: 'customerId', type: 'string' },
                { name: 'origin', type: 'string' },
                { name: 'destination', type: 'string' },
                { name: 'pickUp', type: 'date' },
                { name: 'delivery', type: 'date' },
                { name: 'totalCharge', type: 'float' },
                { name: 'bolNumber', type: 'string' },
                { name: 'customerReferenceNumber', type: 'string' },
                { name: 'container', type: 'string' },
                { name: 'chassis', type: 'string' },
                { name: 'bookingNumber', type: 'string' },
                { name: 'pickUpNumber', type: 'string' },
                { name: 'customerOrderNumber', type: 'string' },
                { name: 'amountDue', type: 'string' },
                { name: 'agencyId', type: 'int' },
                { name: 'orderid', type: 'int' },
                { name: 'movementid', type: 'int' },
                { name: 'phone', type: 'string' },
                { name: 'tractorExId', type: 'string'},
                { name: 'unNumber', type: 'string'},
                { name: 'orderedDate', type: 'string'},
                { name: 'commodityExId', type: 'string'},
                { name: 'billingDistance', type: 'string'},
                { name: 'sailDate', type: 'string'},
                { name: 'rate', type: 'string'},
                { name: 'sealNumber', type: 'string'},
                { name: 'loadDate', type: 'date' },
                { name: "cutoffDate", type: 'date' },
                { name: "containerId", type: 'string' }
            ]
        }
    }

    
    loadCustomerOrdersGrid() {
        var geGECustomWidgets = new GreatEdgeCustomWidgets();
        var jqxEDIWidgetsGridHelper = geGECustomWidgets.GEWidgetsGridHelper('jqxEDI');

        let dataSource = dataModel.getDataAdapter(this.getOrderGridSource());

        $("#jqxOrderGrid").jqxGrid({
            source: dataSource,
            width: '100%',
            selectionmode: 'singlecell',
            sortable: true,
            pageable: true,
            filterable: true,
            showfilterrow: true,
            editable: true,
            autoheight: true,
            columnsmenu: false,
            columnsheight: 50,
            showtoolbar: true,
            columnsreorder: true,
            showstatusbar: true,
            statusbarheight: 40,
            rendertoolbar: function (toolbar) {
                let vm1 = new GridStateComponentViewModel.viewModel();
                vm1.initializeDefaultToolbar(toolbar);

                vm1.clearFilters = function () {
                    jqxEDIWidgetsGridHelper.clearDateRangeFiltersFromGrid();
                    $('#jqxOrderGrid').jqxGrid('clearfilters');
                }
            },
            columnsresize: true,
            columns: this.orderGridColumns(),
            ready: () => {
                if(this.gridState) {
                    gridUtils.loadGridStateFilters($("#jqxOrderGrid"), this.gridState.filters, true)
                }
            }
        });
    }

    orderGridColumns() {
        return [
            {
                text: '',
                width: 100,
                columnType: "button",
                filterable: false,
                sortable: false,
                pinned: true,
                buttonclick: (row) => {
                    var datarow = $("#jqxOrderGrid").jqxGrid('getrowdata', row);
                    this.orderDetailsObject(datarow);
                },
                cellsrenderer: function () {
                    return "View Details";
                }
            }, 
            {
                text: 'Order Id', datafield: 'externalId', width: 100, pinned: true, hideable: false,
                cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {

                    if (rowData.isLoad == false) {
                        var cell = $(defaultHTML);

                        var bColor = "";
                        var d = new Date();
                        d.setHours(d.getHours() + 2);

                        if (rowData.status == "In Progress") {
                            if (d > rowData.deliveryDate) {
                                bColor = "LightCoral";
                            }
                        }
                        else if (rowData.status == "Available" || rowData.status == "Covered") {
                            if (d > rowData.pickUpDate) {
                                bColor = "LightCoral";
                            }
                            else if (rowData.isPreassigned) {
                                bColor = "Yellow";
                            }
                            else {
                                bColor = "LightGreen";
                            }
                        }
                        var ysplit = "";
                        if (rowData.isYsplit) {
                            ysplit = "<img src=\"./Images/YSplit.png\" />";
                        }
                        cell.css("background-color", bColor);

                        if (value != "") {
                            cell.html("<a href=\"Orders/" + value + "\" target=\"_blank\"  style=\"color:blue; \"  >" + value + "</a>" + ysplit);
                        }
                        return cell[0].outerHTML;
                    }
                    return defaultHTML;
                },
                createfilterwidget: self.createfilterwidget
            },
            { text: 'Status', datafield: 'status', filtertype: 'checkedlist', width: 130, hideable: false }, //, filteritems: status },
            {
                text: 'Origin', datafield: 'origin', width: 200,
                createfilterwidget: function (column, columnElement, widget) {
                    widget.jqxInput({
                        placeHolder: "Enter City, State, (Zip)"
                    });
                }
            },
            {
                text: 'Destination', datafield: 'destination', width: 200,
                createfilterwidget: function (column, columnElement, widget) {
                    widget.jqxInput({
                        placeHolder: "Enter City, State, (Zip)"
                    });

                }
            },
            { text: 'Pick Up Date & Time', datafield: 'pickUp', filtertype: 'date', cellsformat: userProfile.jqxTimeFormat, width: 150, createfilterwidget: createDateTimeFilterWidget },
            { text: 'Delivery Date & Time', datafield: 'delivery', filtertype: 'date', cellsformat: userProfile.jqxTimeFormat, width: 150, createfilterwidget: createDateTimeFilterWidget },
            { text: 'Total Charge', datafield: 'totalCharge', columntype: 'numberinput', cellsformat: 'c2', width: 100, createfilterwidget: self.createfilterwidget },
            { text: 'BOL Number', datafield: 'bolNumber', width: 120, createfilterwidget: self.createfilterwidget },
            { text: 'Customer Reference Number', datafield: 'customerReferenceNumber', width: 200, createfilterwidget: self.createfilterwidget },
            { text: 'Container', datafield: 'container', width: 100, createfilterwidget: self.createfilterwidget },
            { text: 'Chassis', datafield: 'chassis', width: 100, createfilterwidget: self.createfilterwidget },
            { text: 'Booking Number', datafield: 'bookingNumber', width: 150, createfilterwidget: self.createfilterwidget },
            { text: 'Pickup Number', datafield: 'pickUpNumber', width: 150, createfilterwidget: self.createfilterwidget },
            { text: 'Customer Order Number', datafield: 'customerOrderNumber', width: 200, createfilterwidget: self.createfilterwidget },
            { text: 'Phone', datafield: 'phone', width: 100, createfilterwidget: self.createfilterwidget }
        ]
    }

    createfilterwidget(column, columnElement, widget) {
        widget.jqxInput({
            placeHolder: "Type Here..."
        });
    }
}

export default {viewModel: CustomerOrdersViewModel, template: template }