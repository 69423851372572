
//==============
// Dependencies
import dataModel from 'data-model';
import template from './order-entry-order-stops-arrangement-component.html';
import {GenerateGuid, datetimeUTC} from '../../../shared-components/global-functions';
import {showmessage} from 'show-dialog-methods';
import ko from 'knockout';
import { OrderEntryViewModel } from '../order-entry-page';

class OrderStopsValidator {
    constructor() {
    
        var self = this;

        // These validation fields are either required to update the stop or 
        // required by the grid for certain 'checks'
        self.validateStopObj = function (stopObj) {
            var isValid = true;

            if (stopObj == null || typeof stopObj !== 'object') {
                isValid = false;
            }
            else if (stopObj['cityStateZip'] == null) {
                isValid = false;
            }
            //else if (stopObj['name'] == null) {
            //    isValid = false;
            //}
            else if (stopObj['id'] == null) {
                isValid = false;
            }
            else if (stopObj['earliestArrival'] == null) {
                isValid = false;
            }
            //else if (stopObj['latestArrival'] == null) {
            //    isValid = false;
            //}
            else if (stopObj['sequence'] == null) {
                isValid = false;
            }
            else if (stopObj['movementSequence'] == null) {
                isValid = false;
            }
            else if (stopObj['isShipper'] == null) {
                isValid = false;
            }
            else if (stopObj['isConsignee'] == null) {
                isValid = false;
            }
            else if (stopObj['status'] == null) {
                isValid = false;
            }
            return isValid;
        }
    }
}


class StopArrivalDateEditor {
    constructor(params) {
        var self = this;
    
        var date = params.date;
        var callBackFn = params.callBackFn || null;
    
        self.stopArrivalDateVal = ko.observable(date);
    
        self.submit = function () {
            if (callBackFn && typeof callBackFn === 'function' && self.stopArrivalDateVal()) {
                callBackFn(self.stopArrivalDateVal());
            }
        }
    }
}

class OrderStopsArrangementModel {
    constructor(params) {
        var self = this;
        var _validator = new OrderStopsValidator();
        
        self.data = params.data || {};
        /** @type { OrderEntryViewModel } */
        self.$componentParent = params.$componentParent || {};
        
        var _guid = GenerateGuid(4);
        self.componentId = 'orderStopsArrangementModalContainer_' + _guid;
        self.stopArrangementGridWrapperId = 'orderStopsArrangementGridWrapper_' + _guid;
        self.errorMsg = ko.observable();
        self.isProcessing = ko.observable(false);
        self.orderExId = ko.observable();
        self.isLoading = ko.observable(false);
        self.helperText = ko.observable();
        self.stopArrivalDateEditor = ko.observable();
        self.hasCustomBillingDistance = ko.observable();
    
        self.showMessageBlock = ko.computed(function () {
            return self.errorMsg() || self.helperText();
        });
    
        // locals, jquery bindings -> these are not part of the dom tree yet until the modal is rendered so set/bind in the applyBindingsToDom function
        // class level properties are prefixed with _ to denote their scope
        var $modal = null;
        var $alertModal = null;
        var _isInit = false;
        var _isDataLoaded = false;
        var $gridWrapper = null;
        var $gridEl = null;
        var _localGridData = new Array();
        var _onSaveCallBackFn = null;
        var _movementIdStopsCountKeyValObj = {}
        var _rearrangementErrors = {
            'firstStopSequenceError': 'The first stop of a movement must be a pickup.',
            'lastStopSequenceError': 'The final stop of a movement must be a delivery.',
            'stopIsDelieveredNotice': 'You cannot re-arrange the order of stops that have already been delivered.',
            'cannotChangeSplitPoints': 'Split points cannot be re-arranged.',
            'arrivalDateIssuesNotice': 'Please correct arrival date issues by re-arranging the stop(s) or editing the date(s).'
        };
    
        self.openModal = function () {
            if (_isDataLoaded) {
                $modal.modal('show');
    
                renderGrid();
                
            }
        }
    
        self.closeModal = function () {
            $modal.modal('hide');
        }
    
        self.saveChanges = function (callBackFn) {
            var rows = $gridEl.jqxGrid('getboundrows');
            //console.log('Bound Rows: ', rows);
    
            if (rows.length == 0) {
                
                return false;
            }
    
            // Validate client side
            for (var i = 0; i < rows.length; i++) {
                var sequenceValidationResult = validateStopSequence(rows[i]);
                var dateValidationResult = validateStopDate(rows[i]);
    
                if (sequenceValidationResult.isValid == false) {
                    self.errorMsg(sequenceValidationResult.errorMsg);
                    return false;
                }
                else if (dateValidationResult.isValid == false) {
                    self.errorMsg(_rearrangementErrors.arrivalDateIssuesNotice);
                    self.helperText('You can edit the date by clicking on the grid cell.');
    
                    return false;
                }
            }
    
            self.isProcessing(true);
    
            var stopArrangementObjModels = rows.map(function (currentVal, index) {
                return {
                    stopId: currentVal.stopId,
                    movementId: currentVal.movementId,
                    stopSequence: currentVal.stopSequence,
                    originalSequence: currentVal.originalSequence,
                    orderExId: currentVal.orderExId,
                    stopType: currentVal.stopType,
                    earliestArrivalDate: datetimeUTC(currentVal.earliestArrival),
                    stopStatusCode: currentVal.stopStatus,
                }
            });

            var apiPayload = {
                orderExId: self.orderExId(),
                hasCustomBillingDistance: self.hasCustomBillingDistance(),
                stopArrangementObjModels: stopArrangementObjModels,
                orderTracking : self.$componentParent.orderEntryTracking().data.toJSON()
            }
       
            dataModel.ajaxRequest("Order/UpdateOrderStopSequences", "POST", apiPayload, false).done(function (resp) {
                self.isProcessing(false);
    
                if (resp.success == false) {
                    if (resp.errorMsg)
                        self.errorMsg(resp.errorMsg);
    
                    if (resp.stops && resp.stops.length > 0) {
    
                        $.each(resp.stops, function (stopIndex, stop) {
    
                            // Update our local data
                            $.each(_localGridData, function (dataIndex, data) {
                                if (data.stopId == stop.id && data.stopStatus != stop.stopStatusCode) {
                                    data.stopStatus = stop.stopStatusCode;
                                }
                            });
                        });
    
                        // Re-sort to original sequence
                        _localGridData.sort(function (a, b) {
                            // sort by movement -> then by stopSequence
                            return a['movementId'] - b['movementId'] || a['originalSequence'] - b['originalSequence']
                        });
                        
                        $gridEl.jqxGrid('updatebounddata', 'data'); // reload our updated localGridData
                        applyCustomGridCellBindings(); // need to rebind our btn events
                    }
    
                    return false;
                }
                self.$componentParent.orderEntryTracking().methods().saveUpdateTrackingAndRefreshTimestamp();
    
                // Perform clean up - close modal.
                self.closeModal();
    
            }).fail(function (error, jqXHR) {
                self.isProcessing(false);

                if (error.status == 400 && error.responseJSON) {
                    if (error.responseJSON.message && error.responseJSON.message.indexOf("EXCEPTION") == -1) {
                        self.errorMsg(error.responseJSON.message);
                    }
                }
                else if (error.status == 409 && error.responseJSON) {
                    self.closeModal();
                    self.$componentParent.orderEntryTracking().methods().displayConflictNotifyMessage(error.responseJSON);
                }
                else if (error.status == 412) // Order Locked
                {
                    showmessage(error.responseJSON.message);
                } 
                else {
                    console.error(error.responseText);
                }
                self.$componentParent.orderEntryTracking().methods().saveUpdateTrackingAndRefreshTimestamp();

            }).then(function (x) {
                if (x && x.success != false) {
                    if (_onSaveCallBackFn && typeof _onSaveCallBackFn === 'function') {
                        _onSaveCallBackFn();
                    }
                    else if (callBackFn && typeof callBackFn === 'function') {
                        callBackFn();
                    }
                }
                
            });
            
        }
    
        // Custom grid move up/down btns
        function cellButtonRenderer(direction, row) {
            var datarow = $gridEl.jqxGrid('getrowdata', row);
            var $btnEl = $('<input />', {
                type: 'button',
                value: 'Move ' + direction,
                style: 'margin: 2px;',
                id: direction + 'Row' + row + 'Btn',
                class: 'gridOrderingBtn',
    
            }).attr({ 'data-row': row, 'data-direction': direction });
    
            $btnEl.jqxButton({ width: '98%' });
    
            var disableBtn = false;
            if (datarow['stopStatus'] == "D") { // disable if stop is delivered
                disableBtn = true;
                $btnEl.attr('title', 'Stop is already delivered.');
            }
            else if (datarow['stopSequence'] == 1 && direction.toUpperCase() == "UP") { // disable if its first sequence and btn is move up
                disableBtn = true;
            }
            else if (datarow['stopSequence'] >= _movementIdStopsCountKeyValObj[datarow['movementId']] && direction.toUpperCase() == "DOWN") { // disable if stop is last sequence and btn is move down
                disableBtn = true;
            }
            else if (_movementIdStopsCountKeyValObj[datarow['movementId']] <= 2) { // disable if there is only two stops for a movement.
                disableBtn = true;
            }
            else if (datarow['stopType'] == "SP" || datarow['stopType'] == "SD") {
                disableBtn = true;
            }   
    
            // validate what the move action will be and prevent it if invalid
            var resultObj = validateStopMoveAction(datarow, direction);
            if (resultObj.isValid == false) {
                disableBtn = true;
            }
    
            if (disableBtn) {
                $btnEl.jqxButton('disabled');
                $btnEl.css('color', '#888').attr('disabled', true);
            }
    
            return $btnEl[0].outerHTML;
            
        }
    
        // Custom cell renderer
        function defaultCellRenderer(row, columnfield, defaulthtml) {
            var datarow = $gridEl.jqxGrid('getrowdata', row);
            var $el = $(defaulthtml).css({ margin: '0px', padding: '6px 2px', 'width': '100%' });
    
            if (columnfield == 'stopType' && datarow['stopStatus'] != 'D') {
                let resultObj = validateStopSequence(datarow);
    
                if (resultObj.isValid == false) {
                    $el.css({ 'font-weight': 'bold', 'color': 'red' });
                    $el.attr('title', resultObj.errorMsg);
                }
            }
            else if (columnfield == 'earliestArrival' && datarow['stopStatus'] != 'D') {
                $el.css('cursor', 'pointer');
                let resultObj = validateStopDate(datarow);
    
                if (resultObj.isValid == false) {
                    $el.css({ 'font-weight': 'bold', 'color': 'red' });
                    let $editIcon = $('<span style="margin-left: 5px; font-size: 12px;"><i class="glyphicon glyphicon-pencil"></i></span>');
                    $el.append($editIcon);
    
                } else {
                    $el.addClass('editable');
                    let $editIcon = $('<span style="margin-left: 5px; font-size: 12px; visibility: hidden;"><i class="glyphicon glyphicon-pencil"></i></span>');
                    $el.append($editIcon);
                }
    
                
            }
            else if (datarow['stopStatus'] == 'D') {
                $el.css({ 'color': '#ccc' }).attr('title', 'Stop is already delivered.');
            }
            
            return $el[0].outerHTML;
        }
    
        // Build and render jqx grid
        function renderGrid() {
            var columns = [
                {
                    text: '',
                    width: 100,
                    filterable: false,
                    hideable: false,
                    pinned: true,
                    sortable: false,
                    editable: false,
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                        
                        var customBtnHtml = cellButtonRenderer('Up', row);
                        return customBtnHtml;
                    }
                },
                {
                    text: '',
                    width: 100,
                    filterable: false,
                    hideable: false,
                    pinned: true,
                    sortable: false,
                    editable: false,
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                        var customBtnHtml = cellButtonRenderer('Down', row);
                        return customBtnHtml;
                    }
                },
                { text: 'Movement', datafield: 'movementId', hidden: true, editable: false }, // needed for grouping stops into same movement
                {
                    text: 'Order Number', datafield: 'orderExId', width: 150, editable: false,
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                        var customCellHtml = defaultCellRenderer(row, columnfield, defaulthtml);
                        return customCellHtml;
                    }
                },
                {
                    text: 'Stop Type', datafield: 'stopType', editable: false, width: 75,
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                        var customCellHtml = defaultCellRenderer(row, columnfield, defaulthtml);
                        return customCellHtml;
                    }
                },
                {
                    text: 'Stop Name', datafield: 'stopName', width: 350, editable: false,
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                        var customCellHtml = defaultCellRenderer(row, columnfield, defaulthtml);
                        return customCellHtml;
                    }
                },
                {
                    text: 'Stop City/State/Zip', datafield: 'cityStateZip', width: 220, editable: false,
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                        var customCellHtml = defaultCellRenderer(row, columnfield, defaulthtml);
                        return customCellHtml;
                    }
                },
                {
                    text: 'Earliest Arrival', datafield: 'earliestArrival', width: 165, cellsformat: 'MM/dd/yyyy HH:mm',
                    editable: false,
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
    
                        var customCellHtml = defaultCellRenderer(row, columnfield, defaulthtml);
                        return customCellHtml;
                    },
                    
                },
                {
                    text: 'Latest Arrival', datafield: 'latestArrival', width: 165, editable: false,
                    cellsformat: 'MM/dd/yyyy HH:mm',
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                        var customCellHtml = defaultCellRenderer(row, columnfield, defaulthtml);
                        return customCellHtml;
                    }
                    
                },
            ];
    
            var source = {
    
                datatype: "array",
                datafields: [
                    { name: "stopId", type: "number" },
                    { name: "movementId", type: "number" },
                    { name: "stopSequence", type: "number" },
                    { name: "originalSequence", type: "number" },
                    { name: "orderExId", type: "string" },
                    { name: "stopType", type: "string" },
                    { name: "stopName", type: "string" },
                    { name: "cityStateZip", type: "string" },
                    { name: "earliestArrival", type: "date", format: "dd-MM-yyyy" },
                    { name: "latestArrival", type: "date", format: "dd-MM-yyyy" },
                    { name: "stopStatus", type: "string" },
                    { name: "isShipper", type: "boolean" },
                    { name: "isConsignee", type: "boolean" }
                ],
                localdata: _localGridData,
                beforeprocessing: (records) => {
                    (records || []).map((stop) => {
                        if(stop.earliestArrival) {
                            stop.earliestArrival = stop.earliestArrival.format("MM/DD/YYYY HH:mm");
                        }

                        if(stop.latestArrival) {
                            stop.latestArrival = stop.latestArrival.format("MM/DD/YYYY HH:mm");
                        }

                        return stop;
                    })
                }
            };
    
            var dataSource = new $.jqx.dataAdapter(source);
    
            // dynamically set the grid height based on records count
            var gridHeight = _localGridData.length > 10 ? 400 : null;
    
            $gridEl.jqxGrid({
                width: '100%',
                source: dataSource,
                altrows: true,
                sortable: true,
                sorttogglestates: 0, // sets sorting through api only
                autoheight: gridHeight == null, // set auto if gridHeight is null
                height: gridHeight,
                pageable: false,
                editable: true,
                selectionmode: 'singlecell',
                //pagesize: 5,
                filterable: false,
                showfilterrow: false,
                columnsresize: true,
                columnsreorder: false,
                enablebrowserselection: true,
                columnsmenu: false,
                columns: columns,
                autoshowloadelement: false,
                enableellipsis: true,
                autorowheight: false,
                groupable: true,
                groups: ['movementId'],
                showgroupsheader: false,
                groupsexpandedbydefault: true,
                ready: function () {
                    //$gridEl.jqxGrid('expandallgroups');
                    applyCustomGridCellBindings();
                    
                }
            });
    
            // Cell click event handler for diplaying arrival date editor
            $gridEl.on('cellselect', function (event) {
    
                var args = event.args;
                var dataField = event.args.datafield;
                var rowBoundIndex = event.args.rowindex;
                var value = $gridEl.jqxGrid('getcellvalue', rowBoundIndex, dataField);
                var datarow = $gridEl.jqxGrid('getrowdata', rowBoundIndex);
    
                if (dataField == 'earliestArrival' && (datarow['stopStatus'] != 'D')) {
                    self.stopArrivalDateEditor(new StopArrivalDateEditor({
                        date: value,
                        callBackFn: function (dateVal) {
                            self.errorMsg('');
                            self.helperText('');
                            
                            $gridEl.jqxGrid('setcellvalue', rowBoundIndex, dataField, dateVal ? dateVal.format("MM/DD/YYYY HH:mm") : null);
                            
                            applyCustomGridCellBindings();
                            $('#dateEditorModal').modal('hide');
                            self.stopArrivalDateEditor(undefined);
                        }
                    }));
                }
    
            });
        }
    
        // sorts the grid by movement group -> stop sequence
        function sortGrid() {
            var rows = $gridEl.jqxGrid('getboundrows');
            
            // sort by movementid then by stopsequence
            rows.sort(function (a, b) {
                return a['movementId'] - b['movementId'] || a['stopSequence'] - b['stopSequence']
            });
    
            $gridEl.jqxGrid('refreshdata'); // refresh the grid with update sequence sorting            
            //$gridEl.jqxGrid('expandallgroups');
    
            applyCustomGridCellBindings();
        }
    
        // Util to apply - reapply our custom grid btn click event handlers
        function applyCustomGridCellBindings() {
            // since we are refreshing the grid we need to reapply bindings to our custom buttons
            $('.gridOrderingBtn').on('click', function (event) {
    
                // get clicked row.
                var rowIndex = parseInt(event.target.getAttribute('data-row'));
                var direction = event.target.getAttribute('data-direction');
                if (isNaN(rowIndex)) {
    
                    return;
                }
    
                var datarow = $gridEl.jqxGrid('getrowdata', rowIndex);
                reorderGridSequencing(datarow, direction);
            });
    
            // UX Icon Helper
            $('.editable').mouseenter(function (event) {
                var $el = $(this);
                var $icon = $el.find('span').first();
                
                if ($icon.length) {
                    $icon.css('visibility', 'visible');
                }
            });
    
            $('.editable').mouseleave(function (event) {
                var $el = $(this);
                var $icon = $el.find('span').first();
                
                if ($icon.length) {
                    $icon.css('visibility', 'hidden');
                }
            });
        }
    
        // The rearrangement logic function, provides validation on a move up/down action
        function reorderGridSequencing(selectedRow, moveDirection) {
            self.errorMsg('');
            self.helperText('');
    
            if (selectedRow['stopStatus'] === "D") { self.errorMsg(_rearrangementErrors.stopIsDelieveredNotice); return false; }
    
            var resultObj = validateStopMoveAction(selectedRow, moveDirection);
    
            if (resultObj.isValid == false) {
                self.errorMsg(resultObj.errorMsg);
                return false;
            }
    
            resultObj.conflictedRow['stopSequence'] = resultObj.conflictedRowNewSequence;
            selectedRow['stopSequence'] = resultObj.selectedRowNewSequence;
                
            sortGrid();
        }
    
        // Get any conflicting rows when a selected row has been moved up or down
        // (stop is the same stopSequence as part of same movementId group)
        function getConflictedRow(rowToCheckAgainst, sequence) {
            
            if (rowToCheckAgainst == null || typeof rowToCheckAgainst !== 'object' || isNaN(sequence) || sequence == null) return null;
    
            var rows = $gridEl.jqxGrid('getRows');
            return rows.filter(function (item) {
                if (item['stopSequence'] == sequence && (item['uid'] != rowToCheckAgainst['uid'] && rowToCheckAgainst['movementId'] == item['movementId'])) {
                    return item;
                }
            })[0];
        }
    
        // Get a valid move up sequence number
        // Since sequences should be 1 or greater we will ensure that here.
        function validateMoveUpSequence(sequence) {
            return sequence == 0 || isNaN(sequence) ? 1 : sequence;
        }
    
        // Get a valid move down sequence
        // We need to determine if the sequence number (arg) is not greater than
        // the actual number of stops for a particular movement. 
        function validateMoveDownSequence(sequence, movementId) {
            var stopCount = getStopCountForMovementId(movementId);
    
            var _max = stopCount > 0 ? stopCount : _localGridData.length;
            return sequence > _max || isNaN(sequence) ? _max : sequence;
        }
    
        // Util to get the the stop count for movement id group
        function getStopCountForMovementId(movementId) {
            
            var _movementId = movementId || 0;
            
    
            if (_movementIdStopsCountKeyValObj[_movementId] == null) {
                getMovementStopCounts();
            }
    
            return _movementIdStopsCountKeyValObj[_movementId];
        }
    
        // Util to set a key/value obj of Movements and their stop counts
        function getMovementStopCounts() {
            
            var _movementId = 0;
    
            for (var i = 0; i < _localGridData.length; i++) {
                if (_localGridData[i]['movementId'] == _movementId) {
                    _movementIdStopsCountKeyValObj[_movementId] = (_movementIdStopsCountKeyValObj[_movementId] + 1);
                } else {
                    _movementId = _localGridData[i]['movementId'];
                    _movementIdStopsCountKeyValObj[_movementId] = 1;
                }
            }
    
            return _movementIdStopsCountKeyValObj;
        }
    
        // validate stop sequence that is being move up/down actions
        // Optiona arg -> restrictToOnlyShipperConsigneeMoveActions (boolean) which is more of a relaxed version
        // of validation move actions.
        function validateStopMoveAction(selectedRow, moveDirection) {
            var resultObj = {
                errorMsg: null,
                conflictedRow: null,
                conflictedRowNewSequence: null,
                selectedRowNewSequence: null,
                isValid: false
            };
    
            var moveDirectionUpperCaseVariant = (typeof moveDirection == 'string' && moveDirection != null) ? moveDirection.toUpperCase() : "";
            
            var newSequence = moveDirectionUpperCaseVariant == "UP" ? validateMoveUpSequence((selectedRow.stopSequence - 1)) : (moveDirectionUpperCaseVariant == "DOWN" ? validateMoveDownSequence(selectedRow.stopSequence + 1, selectedRow['movementId']) : null);
            var conflictedRow = getConflictedRow(selectedRow, newSequence);
    
            // No move changes (already 1st stop sequence so exit)
            if (selectedRow['stopSequence'] == newSequence) return resultObj;
    
            if (moveDirectionUpperCaseVariant == "UP" && (selectedRow['stopType'] == "SO" || selectedRow['stopType'] == "SD") && newSequence <= 1) {
                resultObj.errorMsg = _rearrangementErrors.firstStopSequenceError;
    
            }
            else if (moveDirectionUpperCaseVariant == "DOWN" && (selectedRow['stopType'] == "PU" || selectedRow['stopType'] == "SP") && newSequence >= _movementIdStopsCountKeyValObj[selectedRow['movementId']]) {
                resultObj.errorMsg = _rearrangementErrors.lastStopSequenceError;
    
            }
            else if (conflictedRow != null && moveDirectionUpperCaseVariant == "UP") {
                var sequence = (conflictedRow['stopSequence'] + 1); // what the conflicted row new sequence would be (need to validate this sequence as well)
                resultObj.conflictedRowNewSequence = sequence;
    
                if ((conflictedRow['stopType'] == "PU" || conflictedRow['stopType'] == "SP") && sequence >= _movementIdStopsCountKeyValObj[selectedRow['movementId']]) { //_localGridData.length
                    resultObj.errorMsg = _rearrangementErrors.lastStopSequenceError;
    
                }
                else if (conflictedRow['stopStatus'] === "D") {
                    resultObj.errorMsg = _rearrangementErrors.stopIsDelieveredNotice;
                }
                else if (conflictedRow['stopType'] == "SD" || conflictedRow['stopType'] == "SP") {
                    resultObj.errorMsg = _rearrangementErrors.cannotChangeSplitPoints;
                }
                
            }
            else if (conflictedRow != null && moveDirectionUpperCaseVariant == "DOWN") {
                let sequence = validateMoveUpSequence((conflictedRow.stopSequence - 1)); // what the conflicted row new sequence would be (need to validate this sequence as well)
                resultObj.conflictedRowNewSequence = sequence;
    
                if ((conflictedRow['stopType'] == "SO" || conflictedRow['stopType'] == "SD") && sequence == 1) {
                    resultObj.errorMsg = _rearrangementErrors.firstStopSequenceError;
    
                }
                else if (conflictedRow['stopStatus'] === "D") {
                    resultObj.errorMsg = _rearrangementErrors.stopIsDelieveredNotice;
                }
                else if (conflictedRow['stopType'] == "SD" || conflictedRow['stopType'] == "SP") {
                    resultObj.errorMsg = _rearrangementErrors.cannotChangeSplitPoints;
                }
            }
    
            resultObj.isValid = resultObj.errorMsg == null;
            resultObj.conflictedRow = conflictedRow;
            resultObj.selectedRowNewSequence = newSequence;
    
            return resultObj;
        }
    
        // validate stops current sequence if passes 'business' rules
        function validateStopSequence(selectedRow) {
            var resultObj = {
                isValid: true,
                errorMsg: null
            }
            
    
            if ((selectedRow['stopType'] == "SO" || selectedRow['stopType'] == "SD") && selectedRow['stopSequence'] <= 1) {
                resultObj.isValid = false;
                resultObj.errorMsg = _rearrangementErrors.firstStopSequenceError;
            }
            else if ((selectedRow['stopType'] == "PU" || selectedRow['stopType'] == "SP") && selectedRow['stopSequence'] >= _movementIdStopsCountKeyValObj[selectedRow['movementId']]) {
                resultObj.isValid = false;
                resultObj.errorMsg = _rearrangementErrors.lastStopSequenceError;
            }
    
            return resultObj;
        }
    
        // util that validates a stop arrival date is part of chronological ordering
        function validateStopDate(selectedRow) {
            var resultObj = {
                isValid: true,
                errorMsg: null
            }
    
            var rows = $gridEl.jqxGrid('getboundrows');
            var dateToValidate = new Date(selectedRow['earliestArrival']);
            var nextSequence = validateMoveUpSequence((selectedRow['stopSequence'] - 1)); //validateMoveDownSequence((selectedRow['stopSequence'] + 1), selectedRow['movementId']);
    
            for (var i = 0; i < rows.length; i++) {
                
                if (selectedRow['stopSequence'] < rows[i]['stopSequence'] && dateToValidate > new Date(rows[i]['earliestArrival']) && selectedRow['movementId'] == rows[i]['movementId']) {
                    resultObj.isValid = false;
                }
                else if (nextSequence == rows[i]['stopSequence'] && dateToValidate < new Date(rows[i]['earliestArrival']) && selectedRow['movementId'] == rows[i]['movementId']) {
                    resultObj.isValid = false;
                }
                
            }
    
            if (resultObj.isValid == false)
                resultObj.errorMsg = 'One or more stops have earlier arrival dates than stop ' + selectedRow['stopSequence'];
    
            return resultObj;
        }
    
        // Load Data -> then call Apply Bindings -> then open modal
        // Data: obj { orderExId, stops: arrayOfObjs, _onSaveCallBackFn: function (optional) }
        // stops-> {movementId, id (stopId), movementSequence, sequence (stop sequence), isShipper, isConsignee, status (stop status code), isYSplit, stopTypeCode, cityStateZip, earliestArrival,latestArrival,name }
        function onDataLoad(data) {
            //debugger;
            self.isLoading(true);
            
            if (data['orderExId'] == null) { console.error('No order number was provided.'); return false; }
            if (data['stops'] == null || data['stops'].length == 0) { console.error('No stops was provided for order.'); return false; }
            
            // set ref to order id
            self.orderExId(data.orderExId);
            _onSaveCallBackFn = data.onSaveCallBackFn || null;
    
            var hasCustomBillingDistance = data.hasCustomDistance != null && typeof data.hasCustomDistance === 'boolean' ? data.hasCustomDistance : false;
            self.hasCustomBillingDistance(hasCustomBillingDistance);
    
            // Validate order is not delievered status
            dataModel.ajaxRequest("Order/GetCurrentOrderStatus/" + data['orderExId'], "GET", null, true).done(function (status) {
    
                if (status == 'D') {
                    displayAlertAndResetData('You cannot re-arrange the order of stops that have already been delivered.');
    
                    return false;
                }
    
                var stops = ko.toJS(data.stops);
    
                // If we only have two stops (should be a 'pickup' and a 'delievery'), then no point/can't rearrange based on the
                // 'business rules'
                if (stops.length < 3) { displayAlertAndResetData('Re-arrangement works best with 3 or more stops.'); return false; }
                
                var invalidStops = 0;
                for (var i = 0; i < stops.length; i++) {
                    var _temp = stops[i];
    
                    // Validate we have a valid stop object
                    // Required: Id, CityStateZip, Stop Sequence, MovementSequence, isShipper, isConsignee, status (stop status code)
                    var isStopValid = _validator.validateStopObj(_temp);
                    if (isStopValid == false) { invalidStops = (invalidStops + 1); continue; }
    
                    _localGridData.push({
                        'orderExId': data.orderExId,
                        'movementId': _temp['movementId'],
                        'stopId': _temp['id'],
                        'moveSequence': _temp['movementSequence'],
                        'stopSequence': _temp['sequence'],
                        'originalSequence': _temp['sequence'],
                        'isShipper': _temp['isShipper'],
                        'isConsignee': _temp['isConsignee'],
                        'isYSplit': _temp['isYsplit'],
                        'stopType': _temp['stopTypeCode'],
                        'cityStateZip': _temp['cityStateZip'],
                        'earliestArrival': _temp['earliestArrival'],
                        'latestArrival': _temp['latestArrival'],
                        'stopName': _temp['name'],
                        'stopStatus': _temp['status']
                    });
                }
                
    
                // If we didn't validate stops that user added to the order entry, when they select rearrange stops
                // before saving the order we end up with stops without ids, possibility of invalid sequences.
                // i.e. USE CASE EX: User adds stops (doesn't save order), selects rearranges stops -> rearranges the stops -> clicks save (modal) -> now we have 'ghost' stops 
                // in our api model (stops that haven't been saved yet/added to order in db--no stop.Id)
                // The api then updates all valid stop sequences (stops with stop.Id from db), but cannot update the 'ghost' stops. User then removes that stop from the (UI) or leaves page without saving order
                // -> now order has weird sequencing in the db....1,2,...,4,5,6
                if (invalidStops > 0) {
                    displayAlertAndResetData('Please save any stop entry that was added or is currently being filled out.');
                    return false;
                }
                
                // Apply initial sorting so that each row's boundindex reflects correct sequence order
                // especially when using the grid 'sort' since boundindex doesn't change.
                _localGridData.sort(function (a, b) {
                    // sort by movement -> then by stopSequence
                    return a['movementId'] - b['movementId'] || a['stopSequence'] - b['stopSequence']
                });
    
                applyBindingsToDom();
                getMovementStopCounts();
    
                _isDataLoaded = true;
                self.isLoading(false);
    
                self.openModal();
            }).fail(function (error) {
                self.isLoading(false);
            });
    
        }
    
        // Once data is loaded, modal is rendered in the dom tree, this will set property refs and apply bindings to the dom
        function applyBindingsToDom() {
            $modal = $('#' + self.componentId).find('div.modal[aria-type="mainModal"]').first();
            
            $gridWrapper = $('#' + self.stopArrangementGridWrapperId);
    
            // create a new dom element each time as the jqx grid element gets destroyed during 'cleanup'
            $gridEl = $('<div/>', { id: self.stopArrangementGridWrapperId + '_jqxGrid' });
            $gridWrapper.append($gridEl);
    
            // Dispose and reset data anytime user closes the modal
            $modal.on('hide.bs.modal', function (e) {
                resetData();
            });
    
            _isInit = true;
        }
    
        function displayAlertAndResetData(message) {
            if (message) {
                showmessage(message);
    
                resetData();
            }
        }
    
        // Reset properties to default - dispose
        function resetData() {
            self.data = null;
            _localGridData = [];
            self.orderExId(null);
            self.errorMsg("");
            _movementIdStopsCountKeyValObj = {};
            self.stopArrivalDateEditor(null);
    
            if($gridEl != null)
                $gridEl.jqxGrid('destroy');
    
            self.isLoading(false);
            $('#orderStopsArrangementModal').modal('hide');
        }
       
        onDataLoad(self.data);
    }
}


export default {viewModel: OrderStopsArrangementModel, template: template}