import dataModel from 'data-model';
import gridStateModel from "jqx.grid-state-component";
import gridUtils from "jqx.grid-utils";
import { setBtnDisabledState, setBtnEnabledState } from 'global-functions';
import { GreatEdgeCustomWidgets, createGeDateRangeFilterWidget } from 'ge.custom-widgets';
import {useDispatch, useSelector } from 'ko-data-store';
import { getUserFilters, addUserFilters } from '../../../../dataStore/actions/userFilters';
import { isLoading } from 'dataStore-actions/appUI';
import toast from 'geNotyf';

const sendSaveTieredStopAsync = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/TieredStops", "POST", payload, true).done((response) =>  resolve(response))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while saving.`));
    })
}

const saveTieredStopAsync = async ({
    id = undefined,
    tableId = undefined,
    description = undefined,
}) => await sendSaveTieredStopAsync({
    id,
    tableId,
    description
});

const sendSaveChargeAsync = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/TieredStopCharges", "POST", payload, true).done((response) =>  resolve(response))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while saving.`));
    })
}

const saveChargeAsync = async ({
    id = undefined,
    tableId = undefined,
    externalId = undefined,
    chargeType = undefined,
    chargeCode = undefined,
    description = undefined,
    numberOfStops = undefined,
    amount = undefined
}) => await sendSaveChargeAsync({
    id,
    tableId,
    externalId,
    chargeType,
    chargeCode,
    description,
    numberOfStops,
    amount
});



const fetchTieredStopsAsync = () => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/TieredStops", "GET", null, true).done((response) => resolve(response))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while fetching data.`));
    })
}

const getTieredStopsAsync = async() => await fetchTieredStopsAsync().catch(() => []);

const extraActionHandlers = {
    addNewRate: (tieredStop = ko.observable(), onAddFn) => {
        return {
            action: "Add New Rate",
            handler: () => {
                onAddFn(new chargeModel({tableId: tieredStop().tableId()}))
            }
        }
    },
    updateRate: ($gridEl, onEditFn) => {
        return {
            action: "Update Rate",
            handler: () => {
                const rowIndex = $gridEl.jqxGrid('getselectedrowindex');

                if((rowIndex >= 0) == false) {
                    alert(`Please select a row from the grid.`);
                    return false;
                }

                onEditFn(new chargeModel($gridEl.jqxGrid('getrowdata', rowIndex)));
            }
        }
    }
}

const tieredStopModel = function({
    id = undefined,
    tableId = undefined,
    description = undefined,
    isActive = undefined,
    charges = []
} = {}) {
    this.id = ko.observable(id);
    this.tableId = ko.observable(tableId).extend({required: true, minLength: 1, maxLength: 7, notify: 'always'});
    this.description = ko.observable(description).extend({maxLength: 28});
    this.isActive = ko.observable(isActive || true);

    this.charges = ko.observableArray(charges); // chargeModels

    this.modelErrors = () => {
        const validationGrp = ko.validation.group(this);
        return validationGrp;
    }

    this.isValid = () => this.modelErrors()().length == 0;
}

const chargeModel = function({
    id = undefined,
    tableId = undefined,
    externalId = undefined,
    sequence = undefined,
    chargeType = undefined,
    chargeCode = undefined,
    description = undefined,
    numberOfStops = undefined,
    amount = undefined
} = {}) {
    this.id = ko.observable(id);
    this.tableId = ko.observable(tableId);
    this.externalId = ko.observable(externalId).extend({required: true}); // charge name
    this.sequence = ko.observable(sequence);
    this.chargeType = ko.observable(chargeType).extend({required: true});
    this.chargeCode = ko.observable(chargeCode).extend({required: true});
    this.description = ko.observable(description).extend({required: true});
    this.numberOfStops = ko.observable(numberOfStops).extend({required: true});
    this.amount = ko.observable(amount).extend({required: true});

    this.modelErrors = () => {
        const validationGrp = ko.validation.group(this);
        return validationGrp;
    }

    this.isValid = () => this.modelErrors()().length == 0;
}


const tieredStopsPanelComponent = function() {
    const vm = this;
    const dispatch = useDispatch();
    
    const gridId = "rates-tiered-stops-grid";
    let $grid = $('#'+gridId);

    vm.tieredStopForm = ko.observable(new tieredStopModel());
    vm.error = ko.observable();
    vm.upsertChargeModal = ko.observable();

    const flags = {
        gridInit: false
    }

    // activeRateNumber.subscribe((val) => {
    //     // only refresh when active rate number is updated outside this current view. ie a new Rate Entry was saved.
    //     if(val > 0 && flags.gridInit && activePanel() != 'Rating') $grid.jqxGrid('updatebounddata', 'data');
    // })

    vm.handleSaveTieredStop = async (x, event) => {
        vm.error("");

        if(vm.tieredStopForm().isValid() == false) {
            vm.tieredStopForm().modelErrors().showAllMessages();
            return false;
        }

        setBtnDisabledState(event, `Saving...`);
        
        try {
            const id = await saveTieredStopAsync(ko.toJS(vm.tieredStopForm));

            vm.tieredStopForm().id(id);

            if(vm.tableIdNamesDDLOptions().find(x => x.id == id)) {
                loadTieredStopsDDL(); // refresh our list
            }
            else {
                vm.tableIdNamesDDLOptions.push({...ko.toJS(vm.tieredStopForm()), id: id});
            }

            setBtnEnabledState(event, `Save`);

            toast.open({type: 'info', message: `Saved Tiered Stop Successfully.`});

            refreshGrid();
        }
        catch(err) {
            setBtnEnabledState(event, `Save`);
            vm.error(err);
        }
    }

    vm.tableIdNamesDDLOptions = ko.observableArray([]);
    vm.selectedTableOption = ko.observable();
    vm.selectedTableOption.subscribe((val) => {
        if(val) {
            vm.tieredStopForm(new tieredStopModel(val));
        }

        refreshGrid();
    })

    vm.handleNew = () => {
        vm.tieredStopForm(new tieredStopModel());
        vm.selectedTableOption(null);
        $grid.jqxGrid('clear');
    }

    vm.chargesPlaceholderText = ko.pureComputed(() => {
        return vm.tableIdNamesDDLOptions().length > 1 ? `Save a new table/name or select one from the list to add charges.` : `Enter and save a new table before adding charges.`;
    })

    const handleAddOrEditCharge = (model = new chargeModel()) => {
        const inlineModel = function() {
            const v = this;
            v.id = model.id;
            v.externalId = model.externalId; // charge name -> user is allowed to enter a value for the externalId
            v.tableId = model.tableId;
            v.amount = model.amount;
            v.chargeCode = model.chargeCode;
            v.chargeType = model.chargeType;
            v.description = model.description;
            v.numberOfStops = model.numberOfStops;
            v.typeOptions = ko.observableArray([
                {label: "Both", value: "B"},
                {label: "Stop off", value: "S"},
                {label: "Pickup", value: "P"},
            ])

            v.selectedChargeCode = ko.observable();

            v.selectedChargeCode.subscribe((val) => {
                if(val) {
                    v.chargeCode(val.code);
                }
                else {
                    v.chargeCode(undefined);
                }
            })

            v.error = ko.observable();

            v.handleSave = async (x, event) => {
                v.error("");

                if(model.isValid() == false) {
                    model.modelErrors().showAllMessages();
                    return false;
                }

                try {
                    setBtnDisabledState(event, 'Saving...');
                    const id = await saveChargeAsync(ko.toJS(model));
                    setBtnEnabledState(event, 'Save');

                    model.id(id);

                    toast.open({type: 'info', message: `Tiered Charge saved successfully.`});
                    vm.upsertChargeModal(null);
                    $grid.jqxGrid('updatebounddata', 'data');
                }
                catch(err) {
                    setBtnEnabledState(event, 'Save');
                    v.error(err)
                }

            }
        }

        vm.upsertChargeModal(new inlineModel());

    }

    const refreshGrid = () => {
        if(vm.tieredStopForm().id() > 0 == false) {
            $grid.jqxGrid('clear');
            flags.gridInit = false;
        }
        else if(flags.gridInit) {
            $grid.jqxGrid('updatebounddata', 'data');
        }
        else {
            dispatch(getUserFilters(gridId, "RatesWindow-TieredStops")).then(({userFilters}) => buildGrid(gridId, gridSource(), gridColumns(), userFilters[gridId]));
        }
    }
   
    const buildGrid =  (gridId, source = {}, columns = [], userGridState = {}) => {
        $grid = $('#'+gridId);

        const dataAdapter = dataModel.getDataAdapter(source);

        $grid.jqxGrid({
            theme: 'GWTMDark',
            width: "100%",
            source: dataAdapter,
            altrows: true,
            sortable: true,
            pageable: true,
            pagesize: 20,
            columnsreorder: true,
            columnsmenu: false,
            columnsresize: true,
            enablebrowserselection: true,
            height: 400,
            autoheight: false,
            filterable: true,
            showfilterrow: true,
            showtoolbar: true,
            columns: columns,
            virtualmode: false,
            filter: (cellValue, rowData, dataField, filterGroup, defaultFilterResult) => {
                if (filterGroup == null && cellValue == null) return defaultFilterResult;

                const filters = filterGroup.getfilters();
                const validValues = ["Any", "Please Choose:"];
                let result = filters.some(x => validValues.indexOf(x.value) != -1) || defaultFilterResult;
                

                return result;
            },
            rendertoolbar: (toolbar) => {
                const $toolbar = $(toolbar);
                const gridStateVM = new gridStateModel.viewModel();
                gridStateVM.pageName = "RatesWindow-TieredStops";
                
                const actions = [...gridStateVM.actions, "Add New Rate", "Update Rate"];
                gridStateVM.actions = actions;

                const $template = gridStateModel.template;
                
                
                gridStateVM.extraActionHandlers = [
                    extraActionHandlers.addNewRate(vm.tieredStopForm, handleAddOrEditCharge),
                    extraActionHandlers.updateRate($grid, handleAddOrEditCharge),
                ];

                gridStateVM.overrideCustomGridModalOptionsSave = () => {
                    dispatch(addUserFilters($grid.attr('id'), gridStateVM.saveGridFilters($grid)))
                }

                $toolbar.append($template);
                ko.applyBindingsToDescendants(gridStateVM, $toolbar[0]);
            },
            ready: () => {
                gridUtils.applyGridState($grid.attr('id'), userGridState.grid);
                flags.gridInit = true;
            }, 
            rendergridrows: (obj) => {
                return obj.data;
            },
        });

        return $grid;
    }

    const gridSource = () => {
        return {
            url: "Rates/TieredStopCharges",
            type: "GET",
            datafields: [
                { name: "id", type: "int" },
                { name: "tableId", type: "string" },
                { name: "externalId", type: "string" },
                { name: "sequence", type: "number" },
                { name: "chargeType", type: "string" },
                { name: "chargeCode", type: "string" },
                { name: "description", type: "string" },
                { name: "numberOfStops", type: "number" },
                { name: "amount", type: "number" }
            ],
            datatype: "json",
            formatdata: (data = {}) => {
                data.tableId = vm.tieredStopForm().tableId();
                return data;
            },
        };
    }

    const gridColumns = () => {
        return [
            { text: "Sequence", datafield: "sequence", minwidth: 100 },
            { text: "Type", datafield: "chargeType", minwidth: 150, filtertype: 'list', 
                filteritems: [{html:"Both", label: "B"}, {html:"Stop off", label: "S" }, {html: "Pickup", label: "P"}], 
                cellsrenderer: (row = {}, columnfield, value, defaulthtml, columnproperties) => {
                    const $defaultHtml = $(defaulthtml);

                    switch(value) {
                        case 'S':
                            $defaultHtml.text('Stop off');
                            break;
                        case 'B':
                            $defaultHtml.text('Both');
                            break;
                        case 'P':
                            $defaultHtml.text('Pickup');
                            break;
                        default:
                            break;
                    }

                    return $defaultHtml[0].outerHTML;
                }
            },
            { text: "Code", datafield: "chargeCode", minwidth: 150 },
            { text: "Description", minwidth: 200, datafield: "description" },
            { text: "Number", minwidth: 150, datafield: "numberOfStops" },
            { text: "Amount", datafield: "amount", minwidth: 150, cellsformat: 'c2' },
        ];
    }

    const loadTieredStopsDDL = async() => {
        const result = await getTieredStopsAsync();
        
        const item = vm.selectedTableOption();
        vm.tableIdNamesDDLOptions(result);

        if(item) {
            vm.selectedTableOption(result.find(x => x.id == item.id));
        }
        
    }

    const init = () => {
        loadTieredStopsDDL();
    }

    init();
}

import template from './tiered-stops-panel-component.html';
export default { viewModel: tieredStopsPanelComponent, template: template }