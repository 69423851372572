import * as ebCommon from '../externalBoardCommon';
import dataModel from 'data-model';
import userProfile from 'user-profile';


const ExternalBoardRecentSearchesViewModel = function({ 
    recentSearches, 
    savedSearches, 
    onViewMore = function() {},
    onShareSearch = function(x) {},
    onRecentSearchSelected = function(x) {},
    onRemoveRecentSearch = function(x) {},
    onRemoveShareSearch = function(x) {},
    onViewMoreShareSearches = function() {}
}) {

    const vm = this;
    vm.recentSearches = recentSearches;
    vm.savedSearches = savedSearches;
    //vm.viewAllRecentSearches = ko.observableArray([]);
    vm.viewAllSavedSearches = ko.observableArray([]);
    vm.isUserDriver = ko.observable(userProfile.isUserDriver);
    
    vm.showCount = ko.observable(window.innerWidth < 992 ? 1000 : 5);
    
    $(window).on("resize", () => {
        vm.showCount(window.innerWidth < 992 ? 1000 : 5)
    })

    vm.handleClearAllRecentSearches = () => {
        recentSearches(recentSearches().filter(x => x.userId != userProfile.userId));
    }

    vm.handleRemoveRecentSearch = (itemIndex) => onRemoveRecentSearch(itemIndex);

    vm.handleSearch = ($data) => onRecentSearchSelected($data);

    vm.handleShareSearch = (searchData) => onShareSearch(searchData);

    vm.handleRemoveNamedSearch = async (itemIndex) => onRemoveShareSearch(itemIndex);

    vm.handleViewAllRecentSearches = () => onViewMore();

    vm.handleViewAllSavedSearches = () => onViewMoreShareSearches();
   
}

import template from './external-board-recent-searches-component.html';
export default { viewModel: ExternalBoardRecentSearchesViewModel, template: template }