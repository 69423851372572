import ko from 'knockout';
import dataModel from 'data-model';
import template from './unit-messages-component.html';
import gridStateUtils from 'jqx.grid-utils';

class UnitMessagesViewModel {
    constructor(params){
        var self = this;
        params = params.params ? params.params() : params;
        self.equipmentGroupId = params.equipmentGroupId;
        self.unitId = params.unitId || 0;
        self.unitType = params.unitType || null;
        self.useDispatchRecords = params.useDispatchRecords || false;

        self.isLoading = ko.observable(false);
        self.viewMessageObj = ko.observable({
            priority: '',
            message: null,
            date: ''
        });

        //On Dispatch, using "#jqxUnitMessagesGrid" is causing double requests on one of the grids(when there are multiple unit-messages components on the same page),
        //and then a blank grid for the first one.
        self.gridId = self.equipmentGroupId ? "#jqxUnitMessagesGrid" + self.equipmentGroupId.toString() : "#jqxUnitMessagesGrid"
        var $grid = $(self.gridId)

        var gridCreated = false;
        self.getMessages = function(optionArgsObj) {
            optionArgsObj = optionArgsObj || {};

            self.unitType = optionArgsObj.unitType || self.unitType;
            self.unitId = optionArgsObj.unitId || self.unitId;
            self.useDispatchRecords = optionArgsObj.useDispatchRecords || self.useDispatchRecords;
            
            if (gridCreated) {
                $grid.jqxGrid('updatebounddata', 'data');
            }
            else {
                _buildGrid();
            }
        }

        params.getMessages = self.getMessages;

        function _buildGrid() {
            if($grid.length == 0)
                $grid = $("#jqxUnitMessagesGrid");

            var source = {
                url: "EquipmentGroup/UnitMessagesGrid",
                datatype: "json",
                beforeprocessing: function (data) {
                    source.totalrecords = data.totalRecords;
                    data = data.records
                },
                sort: function (column, direction) {
                    $grid.jqxGrid('updatebounddata', 'sort');
                },
                formatdata: function (data) {
                    var filterinfo = $grid.jqxGrid('getfilterinformation');
                    data = gridStateUtils.formatGridFilters(filterinfo, data);
                    data.equipmentGroupId = self.equipmentGroupId;
                    data.unitId = self.unitId;
                    data.unitType = self.unitType;
                    data.dispatchEquipmentOnly = self.useDispatchRecords;

                    return data;
                },
                datafields: [
                    { name: "id", type: "number" },
                    { name: "message", type: "string" },
                    { name: "messageDate", type: "date" },
                    { name: "priority", type: "string" }
                ],
                loadcomplete: function (data) {

                }
            };

            var dataSource = dataModel.getDataAdapter(source);

            var columns = [
                {
                    text: '', width: 80, filterable: false, columnType: "button", buttonclick: function (row) {
                        var datarow = $grid.jqxGrid('getrowdata', row);
                        self.viewMessageObj({
                            message: datarow.message,
                            date: datarow.messageDate,
                            priority: datarow.priority
                        });
                    },
                    cellsrenderer: function () {
                        return "View";
                    }
                },
                { datafield: 'id', hidden: true },
                { text: 'Date', datafield: 'messageDate', cellsformat: 'MM/dd/yyyy', width: 120 },
                { text: 'Message', datafield: 'message' },
                {
                    text: 'Priority', datafield: 'priority', width: 100,
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {

                        if (value == 'U') {
                            var $html = $(defaulthtml).css({ 'color': 'red', 'font-weight': 'bold' });
                            return $html[0].outerHTML;
                        }
                    }
                },
            ];

            $grid.jqxGrid({
                width: '100%',
                source: dataSource,
                altrows: true,
                sortable: true,
                autoheight: true,
                pageable: true,
                pagesize: 5,
                filterable: false,
                showfilterrow: false,
                virtualmode: true,
                columnsresize: false,
                columnsreorder: false,
                enablebrowserselection: true,
                columnsmenu: false,
                columns: columns,
                autoshowloadelement: true,
                showtoolbar: true,
                rendergridrows: function (obj) {
                    return obj.data;
                },
                ready: function() {
                    gridCreated = true;
                }
            });
        }

        self.resestViewMessageObj = function () {
            self.viewMessageObj({
                priority: '',
                message: null,
                date: ''
            });
        }

        function _setup() {
            // Can bind this instance to the parent via $bindComponentTo prop.
            // Allows access in parent to methods,etc here. using ko standard component loading.
            if(params.$bindComponentTo) {
                if(ko.isObservable(params.$bindComponentTo)) {
                    params.$bindComponentTo(self);
                }
                else {
                    params.$bindComponentTo = self;
                }
            }
            
            // If this prop is not set on the component binding then whenever this component loads
            // it will invoke/load grid and messages. Setting this will allow manually loading grid/messages via 'viewmodel.getMessages(optionArgsObj)'
            // Works well with the $bindToComponent to make execution calls from parent component.
            if(params.executeOnLoad != false)
                self.getMessages();
        }

        _setup();
    }
}

export default { viewModel: UnitMessagesViewModel, template: template }