import dataModel from "data-model";
import template from "./order-entry-geo-locations.component.html";
import { showmessage } from "show-dialog-methods";
import { datetimeUTC, mobilecheck } from "global-functions";
import config from "config";
///import L from 'mapquest';   //Defined in externals in webpack.config.js
import dayjs from "dayjs";
import MapQuestHelper from "../../../../utils/MapQuestHelper";

class OrderEntryGeoLocationsViewModel {
  constructor(params) {
    params = params.data;
    this.id = params.orderId;
    this.isLoading = params.isLoading;
    this.pingFrequency = params.pingFrequency;

    this.pingLocations = ko.observableArray(); //It doesn't look like we're using this anywhere.
    var data = {
      orderId: this.id,
      frequency: this.pingFrequency,
    };
    $("#geoLocationsModal").modal("show");

    this.isLoading(true);

    dataModel
      .ajaxRequest("order/UserGeoLocation", "GET", data)
      .done((data, textStatus, jqXHR) => {
        var mapModel = new MapQuestHelper();
        // show map
        mapModel.loadMap("ordersMap", {
          zoom: 5,
          center: [35.64701, -95.39425],
          useFreeLayers: true,
        });
        mapModel.refreshCollection(data);
        if (mobilecheck()) {
          $("#ordersMap").css("height", $(window).height() - 230);
        }

        // show list of stops and pings
        var loc = [];
        var index = data.length;
        var sequence = "";
        var recordedAt = "";
        for (var i = 0; i < data.length; i++) {
          // keep track of which movement and stop (if any) this is part of
          sequence = data[i].movementSequence;
          if (data[i].stopSequence > 0) sequence += "-" + data[i].stopSequence;
          // the max date of 12/31/9999 is a placeholder (to help us in sorting) and denotes it has not arrived yet
          // Display it as TBD instead of a 9999 date
          if (dayjs(data[i].recordedAt).isSameOrAfter("9999-12-31")) {
            recordedAt = "TBD";
          } else {
            recordedAt = datetimeUTC(data[i].recordedAt).format(
              "dddd, MMMM DD YYYY, h:mm:ss a"
            );
          }
          loc.push({
            number: data.length - i,
            address: data[i].address,
            recordedAt: recordedAt,
            type: data[i].geoType,
            sequence: sequence,
          });
        }
        this.pingLocations(loc);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        showmessage("Unable to retrieve geo location data");
      })
      .always(() => {
        this.isLoading(false);
      });
  }
}

export default {
  viewModel: OrderEntryGeoLocationsViewModel,
  template: template,
};
