import router from 'router';

class NotFoundViewModel {
    constructor() {
        var self = this;
        var routeInfo = router.currentRoute() || {};

        // This page should be from a redirect, grabbing the incorrect url (route)
        // for display purposes
        self.notFoundRoute = ko.observable(routeInfo.request_);
    }
}

import template from './not-found-page.html';
export default { viewModel: NotFoundViewModel, template: template }