

const close = (callBack, modalId = "") => {
    return () => {
        $(modalId).removeClass('fade in show');
        const $backdrop = $('div.modal-backdrop.in');

        if($backdrop) $backdrop.remove();
        if(typeof callBack === 'function') callBack();
    }
}

function RadiusSearch({showSearchOptions = false, onSearchCallBackFn}) {
    const vmBindings = this;
console.log(showSearchOptions)
    vmBindings.showSearchOptions = ko.observable(showSearchOptions);
    vmBindings.radiusSearchType = ko.observable(showSearchOptions ? "destination" : undefined);
    vmBindings.radiusLocation = ko.observable();
    vmBindings.radius = ko.observable(10);

    vmBindings.handleSearch = () => {
        onSearchCallBackFn({radiusLocation: vmBindings.radiusLocation(), radius: vmBindings.radius(), type: vmBindings.radiusSearchType()});
        vmBindings.handleClose();
    }

    vmBindings.reset = () => {
        //vmBindings.showSearchOptions(false);
        vmBindings.radiusSearchType(undefined);
        vmBindings.radiusLocation(undefined);
        vmBindings.radius(10);
    }

    vmBindings.handleClose = close(() => {
        vmBindings.reset();
    }, "#jqxLoadsRadiusSearchModal");

    const open = () => $('#jqxLoadsRadiusSearchModal').modal('show');

    open();
}

import template from './radius-search-component.html';
export default {viewModel: RadiusSearch, template: template}