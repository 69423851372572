const _compare = (a, b) => {
    const stateAbbreviationA = a.toUpperCase();
    const stateAbbreviationB = b.toUpperCase();
    let comparison = 0;
    if (stateAbbreviationA > stateAbbreviationB) {
        comparison = 1;
    } else if (stateAbbreviationA < stateAbbreviationB) {
        comparison = -1;
    }
    return comparison;
};

// Controlled Component
class EditRegionManagementStatesComponent {
    constructor(params) {
        params = params || {};
        this.$owner = params.$owner || {};

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.isProcessing = ko.observable(false);

        this.leftSource = params.$owner.leftSource;
        this.rightSource = params.$owner.rightSource;
        this.showStateMessage = params.$owner.showStateMessage;
        this.showAddAssignmentSaveButton =
            params.$owner.showAddAssignmentSaveButton;

        this.initializeComponent();
    }

    initializeComponent = () => {
        this.handleColumnTransfer();
    };

    refreshGrid = () => {};

    get fields() {
        return [
            { name: "abbr", type: "string" },
            { name: "name", type: "string" },
        ];
    }

    get columns() {
        return [
            {
                text: "abbr",
                datafield: "abbr",
                hidden: true,
                columnType: "textbox",
            },
            { text: "Name", datafield: "name", columnType: "textbox" },
        ];
    }

    handleColumnTransfer = () => {
        this.$owner.showStateMessage(false);
        this.$owner.editRegionMessage("");

        this.$owner.availableStates(this.leftSource.map((x) => x.abbr));
        this.$owner.currentStates(this.rightSource.map((x) => x.abbr));

        const originalArraySorted = this.$owner.originalArray.sort(_compare);
        const availableSorted = this.$owner.availableStates().sort(_compare);
        this.$owner.arrayMatch(
            JSON.stringify(originalArraySorted) ==
                JSON.stringify(availableSorted)
        );
    };
}

import template from "./states-component.html";
export default {
    viewModel: EditRegionManagementStatesComponent,
    template: template,
};
