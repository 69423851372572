import dataModel from 'data-model';

class TwoFactorViewModel {
    constructor(params) {

        this.params = params || {};
        
        this.onConfirm = params.onConfirm && typeof params.onConfirm === "function"
            ? params.onConfirm
            : function () {};

        this.onSettingsUpdate = params.onSettingsUpdate && typeof params.onSettingsUpdate === "function"
            ? params.onSettingsUpdate
            : function () {};

        this.settings = ko.observable();

        this.display = params.displayModal || ko.observable(false);

        this.display.subscribe((value) => {
            if(value) this.initialize();
            if(value == false) this.cleanUp();
        });

        this.displaySettings = ko.observable(params.displaySettings || false);
        this.displayConfirm = ko.observable(params.displayConfirm || false);
    }

    initialize = async () => {
        await this.retrieveUserSettings();

        if(!this.displaySettings() && !this.displayConfirm())
            this.determineModalToDisplay();
    }

    determineModalToDisplay = () => {
        this.displaySettings(!this.settings());
        this.displayConfirm(this.settings());
    }

    retrieveUserSettings = async () => {
        let settings = await this.http_fetchUserSettings().catch((err) => console.error(err));
        
        this.settings(settings);
    }

    handleOnSettingsUpdate = (success) => {
        success = success || false;
        this.onSettingsUpdate(success);
    }

    cleanUp = () => {
        this.displaySettings(false);
        this.displayConfirm(false);
        this.settings(null);

        $(".modal-backdrop.in").remove();
    }

    handleOnConfirmation = (isVerified) => {
        this.onConfirm(isVerified);
    }

    http_fetchUserSettings = () => {
        return new Promise((resolve, reject) => {
           dataModel.ajaxRequest("User/TwoFactorSettings", "GET").done((response) => resolve(response))
           .fail((err) => reject(err));
        })
    }

}

import template from './two-factor-component.html';
export default { viewModel: TwoFactorViewModel, template: template }