import template from './order-lock-component.html';
import ko, { Observable, PureComputed } from 'knockout';
import dataModel from 'data-model';
import { showconfirm, showmessage } from 'show-dialog-methods';
import { datetimeUTC } from 'global-functions';
import { isNumber } from 'lodash';

class MyAgencyOrderLockComponent {

    orderLock: Observable<boolean> = ko.observable();
    orderTimeLock: Observable<number> = ko.observable();
    isLoading: Observable<boolean> = ko.observable<boolean>(false);
    isGWAdmin: Observable<boolean> = ko.observable();
    grid: jqwidgets.jqxGrid;

    constructor(data) {
        this.getOrderLockSettings()
    }

    getOrderLockSettings():void{
        dataModel.ajaxRequest("MyAgency/getOrderLockSettings", "GET")
        .then((x) => {
            this.orderLock(x.orderLock);
            this.orderTimeLock(x.orderTimeLock);
            this.isGWAdmin(x.isGWAdmin);
            if(x.orderLock && x.orderTimeLock) {
                this.buildGrid()
            }
            this.orderLock.subscribe(this.onOptionsChange)
            this.orderTimeLock.subscribe(this.onOptionsChange)
        })
    }

    disableControls: PureComputed<boolean> = ko.pureComputed(() => {
        return !this.isGWAdmin();
    })

    showGrid: PureComputed<boolean> = ko.pureComputed(() => {
        return this.orderLock() && this.orderTimeLock() > 0
    })

    onOptionsChange = () => {
        if(this.orderLock() == true && this.orderTimeLock() && !this.grid) {
            this.buildGrid();
        }
    }

    saveOrderLockSettings():void{
        this.isLoading(true);
        dataModel.ajaxRequest("MyAgency/saveOrderLockSettings", "GET", { orderLock: this.orderLock(), orderTimeLock: this.orderTimeLock()})
        .then((x) => {
            this.isLoading(false);
        })
        .fail((x) => {
            console.error(x);
            this.isLoading(false);
        })
    }

    buildGrid() {
        this.grid = jqwidgets.createInstance("#jqxOrderLockGrid", "jqxGrid", this.gridOptions)
    }

    get gridOptions(): jqwidgets.GridOptions {
        return {
            source: this.dataAdapter,
            columns: this.columns(),
            theme: 'GWTMDark',
            width: '100%',
            altrows: true,
            sortable: true,
            columnsresize: true,
            showstatusbar: true,
            statusbarheight: 25,
            pageable: true,
            autoheight: true,
            enabletooltips: true,
            ready: () => {
                ko.applyBindingsToDescendants(this.timerObservables, $("#jqxOrderLockGrid")[0])
                $('[title="Unlock"]').attr('title', "Unlocking this order will prevent the user from saving any changes they have made to the order.");
            }
        }
    }
    datafields: jqwidgets.GridSourceDataFields[] = [
        { name: "id", type: "number" },
        { name: "externalId", type: "string" },
        { name: "updatedBy", type: 'string'},
        { name: "updatedAt", type: "date" },
        { name: "timeToUnlock", type: "date"}
    ]

    gridSource: jqwidgets.GridSource = {
        url: 'MyAgency/GetLockedOrdersForGrid',
        datatype: 'json',
        data: {
            agencyId: dataModel.currentAgencyId()
        },
        datafields: this.datafields
    }
    get dataAdapter() {
        return dataModel.getDataAdapter(this.gridSource)
    }

    timerObservables = {};

    unlockOrder(orderId: number) {
        this.isLoading(true);
        dataModel.ajaxRequest("MyAgency/UnlockOrder", "GET", {orderId: orderId})
        .then(() => {
            this.grid.updatebounddata('data');
            this.isLoading(false);
        })
        .fail((x : JQuery.jqXHR) => {
            if(x?.responseJSON?.message) {
                showmessage(x?.responseJSON?.message);
            } else {
                showmessage("An error has occured.");
            }
            console.error(x);
            this.isLoading(false);
        })
    }
    
    columns = (): jqwidgets.GridColumn[] => {
        return [
            {
                text: '', pinned: true, columntype: "button", width: 100, filterable: false, classname: 'unlockColumn', enabletooltips: true, hidden: this.isGWAdmin() == false,
                cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                    let btn = $(defaulthtml)
                    btn.html("Unlock");
                    return btn[0].innerHTML;
                }, 
                buttonclick: (row) => {
                    showconfirm("Unlocking this order will prevent the user from saving any changes they have made to the order.  Are you sure?")
                    .then((x) => {
                        if(x) {
                            let data = this.grid.getrowdata(row);
                            this.unlockOrder(data.id);
                        }
                    })
                }
            },
            { text: "Username", datafield: "updatedBy" },
            { text: "Order Id", datafield: "externalId" },
            { text: "Time Locked", datafield: "updatedAt", cellsformat: 'MM/dd/yyyy HH:mm' },
            { text: "Time to Unlock",
                cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                    let obser = ko.observable();
                    let unlockTime = datetimeUTC(rowdata.timeToUnlock);
                    let now = datetimeUTC(new Date());
                    let seconds = unlockTime.diff(now, 'seconds');
                    if(seconds >= 0) {
                        let minutes = Math.floor(seconds / 60);
                        obser(minutes + "m " + (seconds % 60) + "s ")
                    }
                    setInterval(function() {
                        now = datetimeUTC(new Date());
                        let seconds = unlockTime.diff(now, 'seconds');
                        if(seconds >= 0) {
                            let minutes = Math.floor(seconds / 60);
                            obser(minutes + "m " + (seconds % 60) + "s ")
                        }
                        else {
                            obser('Unlocked');
                        }
                    }, 1000)
                    let dht = $(defaulthtml);

                    //EDI Orders have ExternalIds beginning with a number, which is NOT valid javascript.  Appending a 'G' to all the identifiers.
                    this.timerObservables['G' + rowdata.externalId] = obser;
                    dht.html("<div data-bind='text: G" + rowdata.externalId + "'></div>")
                    
                    return dht[0].outerHTML;
                } 
            }
        ]
    }
}

export default {viewModel: MyAgencyOrderLockComponent, template: template}