const jqxSliderWidget = function(selectorId = "") {
    const $widget = selectorId.indexOf('#') > -1 ? $(selectorId) : $('#' + selectorId);
    const noop = (...x) => {};

    const applyEventBindings = ({
        $widget = $(),
        onChange = noop,
        onSlide = noop,
        onSlideStart = noop,
        onSlideEnd = noop
    } = {}) => {
        $widget.on('change', (event = {}) => {
            const args = event.args || {};
            onChange({
                event,
                value: args.value,
                changeType: args.type
            })
        });

        $widget.on('slide', (event = {}) => {  
            const args = event.args || {};
            onSlide({
                event,
                value: args.value
            })
        }); 

        $widget.on('slideStart', (event = {}) => {  
            const args = event.args || {};
            onSlideStart({
                event,
                value: args.value
            })
        }); 

        $widget.on('slideEnd', (event = {}) => {  
            const args = event.args || {};
            onSlideEnd({
                event,
                value: args.value
            })
        }); 

        return $widget;
    }

    const addButtonTooltip = ({
        $widget = $(),
        thumbBtnTooltip = undefined
    } = {}) => {
        const btns = $widget.find('div.jqx-slider-slider') || [];

        if(thumbBtnTooltip && typeof thumbBtnTooltip === 'string' && btns.length) {
            const thumbBtn = btns[btns.length - 1];
            $(thumbBtn).attr({title: thumbBtnTooltip});
        }

        return $widget;
    }

    return ({
        theme = 'summer',
        min = 0,
        max = 365,
        value = 0,
        showTicks = true,
        showTickLabels = false,
        mode = 'default',
        showTooltip = false,
        width = '300px',
        tooltipHookFn = undefined,
        thumbBtnTooltip = "",
        showSideBtns = true,
        onChange = noop,
        onSlide = noop,
        onSlideStart = noop,
        onSlideEnd = noop
    } = {}) => {

        $widget.jqxSlider({
            theme, 
            min,
            max,
            value, 
            rangeSlider: false,
            showTickLabels,
            mode,
            tooltip: showTooltip,
            width,
            showTicks,
            tooltipFormatFunction: tooltipHookFn,
            showButtons: showSideBtns
        });

        if(thumbBtnTooltip) {
            addButtonTooltip({$widget, thumbBtnTooltip});
        }
        
        applyEventBindings({$widget, onChange, onSlide, onSlideStart, onSlideEnd});

        return {
            $widget,
            getValue: () => $widget.jqxSlider('getValue'),
            setValue: (val) => $widget.jqxSlider('setValue', val),
            disabled: (yes = true) => yes ? $widget.jqxSlider('disable') : $widget.jqxSlider('enable')
        }
    }

}

export default jqxSliderWidget