import dataModel from "data-model";
import toast from "geNotyf";
import * as ebCommon from "../externalBoardCommon";
import gridState from "jqx.grid-state-component";
import gridUtils from "jqx.grid-utils";
import {
  mapGridDate,
  formatPhoneNumber,
  isEmpty,
  cleanString,
  datetimeUTC,
  mapToCityStateZipObject,
  getHoursMinsFromMins,
  noop,
} from "global-functions";
import userProfile from "user-profile";
import { buildGrid, lockPageOnGridScroll } from "../../../../gridtoolbox";
import storageManager from "../../../../utils/storageManager";
import { loadSavedSearches } from "../../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";

const sendSearchResults = (payload) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("ExternalBoard/Search/V2", "POST", payload)
      .done((result) => resolve(result))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const getUserFilters = async (stateName) => {
  let filters = await gridUtils
    .getUserGridFiltersAsync(stateName)
    .catch((err) => console.error("Failed to retrieve user filters."));
  if (isEmpty(filters) == false) {
    let state = JSON.parse(filters);
    return state;
  }

  return {};
};

// Might use again in future?
const getSearchResults = async ({
  searchType,
  boards,
  trailerType,
  equipmentClass,
  originType,
  origin,
  originRadius,
  originRegions,
  originStateProvince,
  destinationType,
  destination,
  destinationRadius,
  destinationRegions,
  destinationStateProvince,
  pickupFrom,
  pickupTo,
}) =>
  await sendSearchResults({
    searchType,
    boards,
    trailerType,
    equipmentClass,
    originType,
    origin,
    originRadius,
    originRegions,
    originStateProvince,
    destinationType,
    destination,
    destinationRadius,
    destinationRegions,
    destinationStateProvince,
    pickupFrom,
    pickupTo,
  }).catch(() => {});

const weightFilterVM = (addFilterFn, removeFilterFn) =>
  function (currentVal) {
    const vm = this;
    vm.minWeight = ko.observable().extend({ min: 0, max: 999999 });
    vm.maxWeight = ko.observable().extend({ min: 0, max: 999999 });
    vm.handleReset = () => {
      vm.minWeight(null);
      vm.maxWeight(null);
    };
    vm.handleApply = (x, event) => {
      if (!vm.minWeight() && !vm.maxWeight()) {
        removeFilterFn("weight");
      } else {
        const validationErrors = ko.validation.group(vm);
        if (validationErrors().length > 0) {
          validationErrors.showAllMessages();
          return false;
        }

        currentVal(
          `Weight (Min: ${vm.minWeight() ? vm.minWeight() : "Any"} - Max: ${
            vm.maxWeight() ? vm.maxWeight() : "Any"
          })`
        );

        const filterVals = [];

        if (vm.minWeight()) {
          filterVals.push({
            filtertype: "numericfilter",
            value: vm.minWeight(),
            filtercondition: "GREATER_THAN_OR_EQUAL",
          });
        }

        if (vm.maxWeight()) {
          filterVals.push({
            filtertype: "numericfilter",
            value: vm.maxWeight(),
            filtercondition: "LESS_THAN_OR_EQUAL",
          });
        }

        addFilterFn("weight", filterVals, 0);
      }

      const $modal = $(event.currentTarget).closest(".modal");

      $modal.modal("hide");
    };
  };

const lengthFilterVM = (addFilterFn, removeFilterFn) =>
  function (currentVal) {
    const vm = this;
    vm.minLength = ko.observable().extend({ min: 0, max: 999 });
    vm.maxLength = ko.observable().extend({ min: 0, max: 999 });
    vm.handleReset = () => {
      vm.minLength(null);
      vm.maxLength(null);
    };
    vm.handleApply = (x, event) => {
      if (!vm.minLength() && !vm.maxLength()) {
        removeFilterFn("length");
      } else {
        const validationErrors = ko.validation.group(vm);
        if (validationErrors().length > 0) {
          validationErrors.showAllMessages();
          return false;
        }

        currentVal(
          `Length (Min: ${vm.minLength() ? vm.minLength() : "Any"} - Max: ${
            vm.maxLength() ? vm.maxLength() : "Any"
          })`
        );
        const filterVals = [];

        if (vm.minLength()) {
          filterVals.push({
            filtertype: "numericfilter",
            value: vm.minLength(),
            filtercondition: "GREATER_THAN_OR_EQUAL",
          });
        }

        if (vm.maxLength()) {
          filterVals.push({
            filtertype: "numericfilter",
            value: vm.maxLength(),
            filtercondition: "LESS_THAN_OR_EQUAL",
          });
        }

        addFilterFn("feet", filterVals, 0);
      }

      const $modal = $(event.currentTarget).closest(".modal");

      $modal.modal("hide");
    };
  };

const distanceFilterVM = (addFilterFn, removeFilterFn) =>
  function (currentVal) {
    const vm = this;
    vm.minDistance = ko.observable().extend({ min: 0, max: 9999 });
    vm.maxDistance = ko.observable().extend({ min: 0, max: 9999 });
    vm.handleReset = () => {
      vm.minDistance(null);
      vm.maxDistance(null);
    };
    vm.handleApply = (x, event) => {
      if (!vm.minDistance() && !vm.maxDistance()) {
        removeFilterFn("distance");
      } else {
        const validationErrors = ko.validation.group(vm);
        if (validationErrors().length > 0) {
          validationErrors.showAllMessages();
          return false;
        }

        currentVal(
          `Distance (Min: ${
            vm.minDistance() ? vm.minDistance() : "Any"
          } - Max: ${vm.maxDistance() ? vm.maxDistance() : "Any"})`
        );
        const filterVals = [];

        if (vm.minDistance()) {
          filterVals.push({
            filtertype: "numericfilter",
            value: vm.minDistance(),
            filtercondition: "GREATER_THAN_OR_EQUAL",
          });
        }

        if (vm.maxDistance()) {
          filterVals.push({
            filtertype: "numericfilter",
            value: vm.maxDistance(),
            filtercondition: "LESS_THAN_OR_EQUAL",
          });
        }

        addFilterFn("miles", filterVals, 0);
      }

      const $modal = $(event.currentTarget).closest(".modal");

      $modal.modal("hide");
    };
  };

const companyFilterVM = (addFilterFn, removeFilterFn) =>
  function (currentVal, companySource = []) {
    const vm = this;

    vm.companySource = ko.observableArray(companySource);
    vm.selectedCompanies = ko.observableArray([]);
    vm.filterTextInput = ko.observable();

    const isSelectAll = ko.observable(false);
    vm.selectUnSelectAllText = ko.pureComputed(() =>
      isSelectAll() ? "Unselect All" : "Select All"
    );

    vm.filterTextInput.subscribe((val) => {
      if (val) {
        const filtered = companySource.filter(
          (x) => x.toUpperCase().indexOf(val.toUpperCase()) > -1
        );
        vm.companySource(filtered);
      } else {
        vm.companySource(companySource);
      }
    });

    vm.handleSelectUnSelectAll = () => {
      vm.selectedCompanies(isSelectAll() ? [] : [...vm.companySource()]);
      isSelectAll(!isSelectAll());
    };

    vm.handleReset = () => {
      vm.selectedCompanies([]);
      vm.filterTextInput(null);
      isSelectAll(false);
    };

    vm.handleApply = (x, event) => {
      if (!vm.selectedCompanies().length) {
        removeFilterFn("companyName");
      } else {
        const validationErrors = ko.validation.group(vm);
        if (validationErrors().length > 0) {
          validationErrors.showAllMessages();
          return false;
        }

        currentVal(`Company - ${vm.selectedCompanies()}`);

        const filtersVals = vm.selectedCompanies().map((x) => ({
          filtertype: "stringfilter",
          value: x,
          filtercondition: "STARTS_WITH",
        }));

        addFilterFn("companyName", filtersVals);
      }

      const $modal = $(event.currentTarget).closest(".modal");

      $modal.modal("hide");
    };
  };

const boardSourceFilterVM = (addFilterFn, removeFilterFn) =>
  function (currentVal, boardSource = []) {
    const vm = this;

    vm.boardSource = ko.observableArray(boardSource);
    vm.selectedBoards = ko.observableArray([]);

    vm.handleReset = () => {
      vm.selectedBoards([]);
    };
    vm.handleApply = (x, event) => {
      if (!vm.selectedBoards().length) {
        removeFilterFn("boardName");
      } else {
        const validationErrors = ko.validation.group(vm);
        if (validationErrors().length > 0) {
          validationErrors.showAllMessages();
          return false;
        }

        currentVal(`Source - ${vm.selectedBoards()}`);

        const filtersVals = vm.selectedBoards().map((x) => ({
          filtertype: "stringfilter",
          value: x == "Internal" ? "GE" : x,
          filtercondition: "STARTS_WITH",
        }));

        addFilterFn("boardName", filtersVals);
      }

      const $modal = $(event.currentTarget).closest(".modal");

      $modal.modal("hide");
    };
  };

const filterrow = ($filterbar) => {
  let gridProcessing = false;
  //const searchId = $filterbar.attr('aria-search-id');
  const actionlistVM = new gridState.viewModel(); //"externalboard-search-" + searchId
  const clearSelectedSavedSearchDDL = ko.observable(false);
  const refreshSavedSearchDDL = ko.observable("");

  return ($gridEl) => {
    // Call back hooks when an action item is selected.
    let actionCallBackHooks = {
      onClearFilters: function () {},
      onClearSelection: function () {},
      onRefresh: function () {},
    };

    const render = (extraActionItems = []) => {
      actionlistVM.$gridEl = $gridEl;
      const tmpl = gridState.template;
      const $firstChild = $filterbar.find("div.inner").children()[0];

      actionlistVM.clearFilters = () => {
        filterData.company.value(null);
        filterData.distance.value(null);
        filterData.eqLength.value(null);
        filterData.shipment.value(null);
        filterData.weight.value(null);
        filterData.board.value(null);
        filterData.hazmatTeamMisc.value([]);

        $gridEl.jqxGrid("clearfilters");
        clearSelectedSavedSearchDDL(true);
        actionCallBackHooks.onClearFilters();
      };

      actionlistVM.clearSelection = () => {
        $gridEl.jqxGrid("clearselection");
        actionCallBackHooks.onClearSelection();
      };

      actionlistVM.refresh = () => {
        $gridEl.jqxGrid("updatebounddata", "data");
        actionCallBackHooks.onRefresh();
        gridProcessing = true;
      };

      $gridEl.on("bindingcomplete", () => {
        gridProcessing = false;
      });

      actionlistVM.setDefaultSearchOverride = async () => {
        const savedSearches = await loadSavedSearches($gridEl.attr("id"));
        const filters = {
          companyName: filterData.company.value(),
          weight: filterData.weight.value(),
          length: filterData.eqLength.value(),
          distance: filterData.distance.value(),
          shipment: filterData.shipment.value(),
          boardName: filterData.board.value(),
          hazmatTeamMisc: filterData.hazmatTeamMisc.value(),
          isDefault: true,
        };

        actionlistVM.loadSaveSearchModal(
          $gridEl,
          (val) => {
            if (val && val.searchname) {
              refreshSavedSearchDDL(val.searchname);
            }
          },
          savedSearches
            .filter((x) => x.searchName)
            .map((x) => ({ id: x.id, text: x.searchName })),
          filters,
          true
        );
      };

      actionlistVM.loadSaveSearchModalOverride = async () => {
        const savedSearches = await loadSavedSearches($gridEl.attr("id"));
        const filters = {
          companyName: filterData.company.value(),
          weight: filterData.weight.value(),
          length: filterData.eqLength.value(),
          distance: filterData.distance.value(),
          shipment: filterData.shipment.value(),
          boardName: filterData.board.value(),
          hazmatTeamMisc: filterData.hazmatTeamMisc.value(),
          isDefault: false,
        };

        actionlistVM.loadSaveSearchModal(
          $gridEl,
          (val) => {
            if (val && val.searchname) {
              refreshSavedSearchDDL(val.searchname);
            }
          },
          savedSearches
            .filter((x) => x.searchName)
            .map((x) => ({ id: x.id, text: x.searchName })),
          filters,
          false
        );
      };

      actionlistVM.actions.push("Save Search");

      if (extraActionItems.length) {
        extraActionItems.forEach(({ action }) =>
          actionlistVM.actions.push(action)
        );
        actionlistVM.extraActionHandlers = extraActionItems;
      }

      $($firstChild).append(tmpl);
      ko.applyBindingsToDescendants(actionlistVM, $firstChild);

      $(".checkbox-menu").on("change", "input[type='checkbox']", function () {
        const $item = $(this);
        const isChecked = this.checked;
        const name = $item[0] && $item[0]["name"];
        const currentFilters = filterData.hazmatTeamMisc.value();

        $item.closest("li").toggleClass("active", isChecked);

        if (isChecked) {
          filterData.hazmatTeamMisc.value([
            ...currentFilters.filter((x) => x !== name),
            name,
          ]);
        } else {
          filterData.hazmatTeamMisc.value([
            ...currentFilters.filter((x) => x !== name),
          ]);
        }
      });

      $(document).on("click", ".allow-focus", function (e) {
        e.stopPropagation();
      });
    };

    const applyGridStateValues = ({
      companyName = "",
      weight = "",
      length = "",
      distance = "",
      shipment = "",
      boardName = "",
      hazmatTeamMisc = [],
    } = {}) => {
      filterData.company.value(companyName);
      filterData.weight.value(weight);
      filterData.eqLength.value(length);
      filterData.distance.value(distance);
      filterData.shipment.value(shipment);
      filterData.board.value(boardName);
      filterData.hazmatTeamMisc.value(hazmatTeamMisc);
    };

    const setActionsHooks = (actionHooks = {}) => {
      actionCallBackHooks = { ...actionCallBackHooks, ...actionHooks };
    };

    let addFilterTimeout;
    const addFilter = (datafield = "", addFilters = [], andOrOperator = 1) => {
      clearTimeout(addFilterTimeout);
      if (!addFilters || addFilters.length == 0) return false;

      if (gridProcessing) {
        addFilterTimeout = setTimeout(
          () => addFilter(datafield, addFilters, andOrOperator),
          300
        );
        return false;
      }

      const filtergroup = new $.jqx.filter();

      const filters = addFilters.reduce((grp, fltr) => {
        grp.push(
          filtergroup.createfilter(
            fltr.filtertype || "stringfilter",
            fltr.value,
            fltr.filtercondition || "STARTS_WITH"
          )
        );
        return grp;
      }, []);

      filters.map((x) => filtergroup.addfilter(andOrOperator, x));

      $gridEl.jqxGrid("addfilter", datafield, filtergroup);
      $gridEl.jqxGrid("applyfilters");
    };

    const applyFilters = () => {
      $gridEl.jqxGrid("applyfilters");
    };

    const clearFilters = () => {
      $gridEl.jqxGrid("clearfilters");
    };

    const removeFilter = (datafield = "") => {
      $gridEl.jqxGrid("removefilter", datafield);
    };

    const getFilterData = (
      addFilterFn = function (x, v, y) {},
      removeFilterFn = function (x, v, y) {}
    ) => {
      const data = {
        company: {
          value: ko.observable(),
          modalVM: companyFilterVM(addFilterFn, removeFilterFn),
          displayModal: ko.observable(),
          modalHeader: "Company",
          source: [],
          datafield: "companyName",
        },
        weight: {
          value: ko.observable(),
          modalVM: weightFilterVM(addFilterFn, removeFilterFn),
          displayModal: ko.observable(),
          modalHeader: "Weight",
        },
        eqLength: {
          value: ko.observable(),
          modalVM: lengthFilterVM(addFilterFn, removeFilterFn),
          displayModal: ko.observable(),
          modalHeader: "Length",
          datafield: "feet",
        },
        distance: {
          value: ko.observable(),
          modalVM: distanceFilterVM(addFilterFn, removeFilterFn),
          displayModal: ko.observable(),
          modalHeader: "Distance",
          datafield: "miles",
        },
        shipment: {
          value: ko.observable(),
          modalHeader: "Shipment",
          listener: (val) => {
            if (val) {
              addFilterFn("shipment", [
                {
                  filtertype: "stringfilter",
                  value: val.toLowerCase(),
                  filtercondition: "STARTS_WITH",
                },
              ]);
            } else {
              removeFilterFn("shipment");
            }
          },
        },
        board: {
          value: ko.observable(),
          modalVM: boardSourceFilterVM(addFilterFn, removeFilterFn),
          displayModal: ko.observable(),
          modalHeader: "Source",
          source: [],
          datafield: "boardName",
        },
        hazmatTeamMisc: {
          value: ko.observableArray([]).extend({ notify: "always" }),
          modalHeader: "Include Hazmat, Etc",
          listener: (val) => {
            if (val && val.length) {
              val.forEach((name) =>
                addFilterFn(name, [
                  {
                    filtertype: "booleanfilter",
                    value: "true",
                    filtercondition: "EQUAL",
                  },
                ])
              );
            } else {
              ["hazmat", "teams"].forEach((name) => removeFilterFn(name));
            }
          },
        },
      };

      return data;
    };

    const handleOnSelected = (state) => {
      gridUtils.applyGridState($gridEl.attr("id"), state.grid);
      applyGridStateValues(state.additionalFilters);
    };

    const filterData = getFilterData(addFilter, removeFilter);
    Object.keys(filterData).map((key) => {
      if (filterData[key]["listener"] && filterData[key]["value"]) {
        filterData[key]["value"].subscribe((val) =>
          filterData[key]["listener"](val)
        );
      }
    });

    return {
      render,
      applyFilters,
      clearFilters,
      applyGridStateValues,
      setActionsHooks,
      handleOnSelected,
      filterData,
      clearSelectedSavedSearchDDL,
      refreshSavedSearchDDL,
      gridId: $gridEl.attr("id"),
      onFilterBtnClick: (filterName = "") => {
        const filter = filterData[filterName];

        if (!filter) return false;

        if (filter.value() != null) {
          filter.displayModal(null);
          filter.value(null);
          removeFilter(filter.datafield || filterName);
        } else if (filter["displayModal"]) {
          filter.displayModal(new filter.modalVM(filter.value, filter.source));
        }
      },
    };
  };
};

const ExternalBoardSearchResultsViewModel = function ({
  parentId, // observable
  searchParams = {
    id: undefined,
    searchType: undefined,
    boards: [],
    trailerType: undefined,
    equipmentClass: [],
    originType: undefined,
    origin: undefined,
    originRadius: undefined,
    originRegions: [],
    originStateProvince: [],
    destinationType: undefined,
    destination: undefined,
    destinationRadius: undefined,
    destinationRegions: [],
    destinationStateProvince: [],
    pickupFrom: undefined,
    pickupTo: undefined,
    saveSearchName: undefined,
    locationStrings: {},
    isMatchMyTruck: false,
    selectedItemTabInfo: ko.observable(),
    includeHazmat: false,
    includeTeams: false,
  },
  onRowSelect = function (x, cb) {},
  onShareSearch = function (x) {},
  handleCallNoteEdit = function (x, cb) {},
  onCallLeadEntry = function (x) {},
}) {
  const vm = this;
  vm.isUserDriver = ko.observable(userProfile.isUserDriver);

  const $parentEl = $("#" + parentId());
  const $countEl = $("#" + parentId() + "-count");
  const $gridContainer = $parentEl.find(".grid-container");
  const $filterbar = $parentEl.find(".filterrow");

  $filterbar.attr("aria-search-id", searchParams.id);

  const $gridId =
    "active-search-" + $filterbar.attr("aria-search-id") + "-grid";

  const $grid = $("<div>", { id: $gridId });
  $gridContainer.append($grid);

  vm.vmFlags = {
    gridLoaded: ko.observable(false),
  };

  const resizeObserver = new ResizeObserver(() => {
    if (vm.vmFlags.gridLoaded() && $grid.length) {
      $grid.jqxGrid("render");
    }
  });

  if ($gridContainer && $gridContainer[0]) {
    resizeObserver.observe($gridContainer[0]);
  }

  vm.filterbar = filterrow($filterbar)($grid);
  vm.filterbar.setActionsHooks({
    onClearSelection: () => {
      searchParams.selectedItemTabInfo(null);
      onRowSelect(null);
    },
    onRefresh: () => ebCommon.boardDetailsCache.clear(),
  });

  let searchResultError;

  vm.handleShareSearch = () => {
    onShareSearch(searchParams);
  };

  vm.handleToggleMobileFilterBar = (x, event) => {
    const $bar = $(event.currentTarget)
      .closest(".filterrow")
      .find(".filter-list-mobile");
    const $backdrop = $(event.currentTarget)
      .closest(".filterrow")
      .find(".mobile-filter-backdrop");

    if ($bar.length) {
      if ($bar.hasClass("open")) {
        $backdrop.hide();
        $bar.animate({ top: "-200%" }, "fast", () => $bar.removeClass("open"));
      } else {
        $bar.animate({ top: 50 }, "fast", () => {
          $bar.addClass("open");
          $backdrop.show();
        });
      }
    }
  };

  const refreshStatusBar = ($statusBar, searchResultError) => {
    if ($statusBar && $grid.length) {
      const count = $grid.jqxGrid("getrows") || [];
      $statusBar.html(`${count.length} result(s) found.`);
    }
  };

  const gridSource = () => {
    return {
      url: "ExternalBoard/Search/V2",
      type: "POST",
      datafields: [
        { name: "age", type: "int" },
        { name: "boardName", type: "string" },
        { name: "companyName", type: "string" },
        { name: "dateAvailable", type: "date" },
        { name: "destination", type: "string" },
        { name: "destinationRadius", type: "int" },
        { name: "feet", type: "int" },
        { name: "loadType", type: "string" },
        { name: "origin", type: "string" },
        { name: "originRadius", type: "int" },
        { name: "phone", type: "string" },
        { name: "trailerType", type: "string" },
        { name: "weight", type: "int" },

        { name: "miles", type: "int" },
        { name: "referenceId", type: "string" },
        { name: "comments", type: "string" },
        { name: "count", type: "int" },
        { name: "rate", type: "float" },
        { name: "carrierMcNumber", type: "string" },
        { name: "brokerMcNumber", type: "string" },
        { name: "carrierId", type: "int" },
        { name: "cwStatus", type: "string" },
        { name: "dotNumber", type: "string" },
        { name: "id", type: "string" },

        { name: "shipment", type: "string" },
        { name: "previouslyViewed", type: "boolean" },
        { name: "customerExId", type: "string" },
        { name: "customerStatus", type: "string" },
        { name: "creditBalance", type: "int" },
        { name: "creditLimit", type: "int" },
        { name: "netUnposted", type: "int" },
        { name: "trip", type: "int" },
        { name: "driver1", type: "string" },
        { name: "driver1ExId", type: "string" },
        { name: "driverPhone", type: "string" },
        { name: "tractorExId", type: "string" },
        { name: "tractorStatus", type: "string" },
        { name: "favoriteRating", type: "string" },

        { name: "loadOrderDeliveryDate", type: "date" },
        { name: "isGEOrder", type: "boolean" },
        { name: "callNotes", type: "string" },
        // Computed in client (rate / miles)
        { name: "rpm", type: "float" },
        { name: "tractorAvailableNow", type: "boolean" },
        { name: "hazmat", type: "boolean" },
        { name: "teams", type: "boolean" },
      ],
      datatype: "json",
      formatdata: () => {
        // in case(s) we have a dayjs object
        // if you get any jqx 'utc' errors this is why...the date needs to be string format.
        if (
          searchParams.pickupFrom &&
          typeof searchParams.pickupFrom !== "string" &&
          Object.keys(searchParams.pickupFrom).length
        ) {
          searchParams.pickupFrom =
            searchParams.pickupFrom.format("MM/DD/YYYY HH:mm");
        }

        if (
          searchParams.pickupTo &&
          typeof searchParams.pickupTo !== "string" &&
          Object.keys(searchParams.pickupTo).length
        ) {
          searchParams.pickupTo =
            searchParams.pickupTo.format("MM/DD/YYYY HH:mm");
        }

        if (searchParams.searchType == "T") {
          const includeTractorsAvailableNow = storageManager.get(
            "GE_IncludeTractorsAvailableNow"
          );
          searchParams.includeTractorsAvailableNow =
            includeTractorsAvailableNow != null
              ? includeTractorsAvailableNow
              : true;
        }

        return { ...searchParams, agencyId: userProfile.currentAgencyId() };
      },
      loadError: (err) => {
        const errObj =
          err && err.responseText ? JSON.parse(err.responseText) : null;

        if (errObj && errObj.message) {
          $grid.on("bindingcomplete", () =>
            $grid.jqxGrid("localizestrings", {
              emptydatastring: errObj.message,
            })
          );
        }
      },
      loadComplete: () => {
        $grid.jqxGrid("localizestrings", {
          emptydatastring: "No result(s) found.",
        });
        refreshStatusBar($countEl);
      },
      beforeLoadComplete: (x, recordData = {}) => {
        const geMappings = recordData.geResults.map((x) => {
          if (x.dateAvailable) {
            x.dateAvailable = mapGridDate(x.dateAvailable);
          }

          if (
            x.boardName == "GE" &&
            x.cwStatus &&
            searchParams.searchType == "T"
          ) {
            x.companyName = x.cwStatus;
          } else if (x.customerExId && x.boardName == "GE") {
            x.companyName = `${x.customerExId} - ${x.companyName}`;
          }

          if (x.weight) {
            x.weight = Number(x.weight);
          }
          if (x.feet) {
            x.feet = Number(x.feet);
          }

          return x;
        });

        const data = [...geMappings, ...recordData.externalResults];

        const companyListed = data
          .filter((x) => x.companyName)
          .map((x) => x.companyName)
          .reduce((x, key) => {
            if (x.indexOf(key) == -1) x.push(key);
            return x;
          }, []);

        const boardResults = data
          .filter((x) => x.boardName)
          .map((x) => {
            switch (x.boardName) {
              case "GE":
                return "Internal";
              case "DAT 360":
                return "DAT";
              case "TruckStop":
                return "TruckStop";
              default:
                return x.boardName;
            }
          })
          .reduce((x, key) => {
            if (x.indexOf(key) == -1) x.push(key);
            return x;
          }, []);

        vm.filterbar.filterData.company.source = companyListed;
        vm.filterbar.filterData.board.source = boardResults;

        data.map((result) => {
          const { rate, miles } = result;

          if (rate > 0 && miles > 0) {
            result.rpm =
              `$` +
              new Number(Math.round((rate / miles) * 100) / 100).toFixed(2);
          }

          try {
            result.age = parseInt(result.age);
          } catch (e) {
            result.age = 0;
          }

          if (result.weight) {
            result.weight = Number(result.weight);
          }

          if (result.feet) {
            result.feet = Number(result.feet);
          }

          return result;
        });

        return data;
      },
    };
  };

  const gridColumns = () => {
    const hideColumnProps = () =>
      searchParams.searchType == "T"
        ? { pinned: true, hidden: true, sortable: false, filterable: true }
        : {};

    return [
      {
        text: "",
        datafield: "miles",
        pinned: true,
        hidden: true,
        sortable: false,
        filterable: true,
      }, // need so can filter 'distance', but do not show in grid.
      {
        text: "",
        datafield: "shipment",
        pinned: true,
        hidden: true,
        sortable: false,
        filterable: true,
      }, // need so can filter 'shipment', but do not show in grid.
      {
        text: "",
        datafield: "hazmat",
        pinned: true,
        hidden: true,
        sortable: false,
        filterable: true,
      }, // need so can filter 'hazmat', but do not show in grid.
      {
        text: "",
        datafield: "teams",
        pinned: true,
        hidden: true,
        sortable: false,
        filterable: true,
      }, // need so can filter 'teams', but do not show in grid.
      {
        text: "",
        datafield: "callNotes",
        pinned: true,
        sortable: false,
        filterable: false,
        minwidth: 80,
        maxwidth: 80,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties
        ) => {
          const $defaulHtml = $(defaulthtml);
          const $div = $("<div>", {
            style: "cursor: pointer",
            title: "View/Edit Call Notes",
          });

          $defaulHtml.css({ "text-align": "center" });
          const $icon = $("<i>", { class: "glyphicon glyphicon-edit" });

          if (value) {
            $icon.css({ color: "blue" });
          }

          $div.append($icon);

          $defaulHtml.html($div[0].outerHTML);

          return $defaulHtml[0].outerHTML;
        },
      },
      {
        text: "Previously Viewed",
        datafield: "previouslyViewed",
        width: 70,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties
        ) => {
          const $defaulHtml = $(defaulthtml);
          $defaulHtml.css({ "text-align": "center" });
          const $icon = $("<i>", {
            class: !value
              ? "glyphicon glyphicon-ok-circle"
              : "glyphicon glyphicon-ban-circle",
          });
          $icon.css({ color: !value ? "green" : "red" });

          $defaulHtml.html($icon[0].outerHTML);

          return $defaulHtml[0].outerHTML;
        },
      },
      {
        text: "Age",
        datafield: "age",
        minwidth: 60,
        width: 60,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties
        ) => {
          const $defaulHtml = $(defaulthtml);
          $defaulHtml.css({ "text-align": "center" });
          let txt = "00:00";

          if (value) {
            const { hours, mins } = getHoursMinsFromMins(value);
            txt = `${hours}:${mins}`;
          }

          $defaulHtml.text(txt);

          return $defaulHtml[0].outerHTML;
        },
      },
      {
        text: "Company Name",
        datafield: "companyName",
        minwidth: 200,
        width: searchParams.searchType == "T" ? 320 : 270,
        cellsrenderer: function (
          row,
          columnfield,
          value,
          defaultHTML,
          column,
          rowData
        ) {
          const displayCarrierInfo = searchParams.searchType == "T"; // tractor search

          if (defaultHTML != null) {
            const $cell = $(defaultHTML);
            let displayText = rowData.companyName;

            if (displayCarrierInfo && rowData.cwStatus) {
              const linkColor =
                rowData.cwStatus.indexOf("Inactive") > -1 ? "red" : "blue";
              displayText =
                rowData.carrierId && rowData.boardName != "GE"
                  ? `<a href="/CarrierEntry/${rowData.carrierId}" target="_blank" style="color:${linkColor}">${rowData.cwStatus}</a>`
                  : `${rowData.cwStatus}`;
            } else if (rowData.customerExId) {
              const linkColor =
                rowData.customerStatus.indexOf("Inactive") > -1
                  ? "red"
                  : "blue";
              displayText =
                rowData.boardName == "GE"
                  ? `${rowData.companyName}`
                  : `<a href="/CustomerCredit?mcNumber=${rowData.brokerMcNumber}" target="_blank" style="color:${linkColor}">${rowData.companyName} (${rowData.customerStatus})</a>`;
            }

            $cell.html(displayText);
            return $cell[0].outerHTML;
          }

          return defaultHTML;
        },
      },
      {
        text: "Rate",
        datafield: "rate",
        minwidth: 80,
        width: 80,
        cellsformat: "c2",
        ...hideColumnProps(),
      },
      {
        text: "RPM",
        datafield: "rpm",
        minwidth: 80,
        width: 80,
        cellsformat: "c2",
        ...hideColumnProps(),
      },
      { text: "Equipment", datafield: "trailerType", minwidth: 90, width: 100 },
      {
        text: searchParams.searchType == "T" ? "Available" : "Pickup",
        datafield: "dateAvailable",
        minwidth: 90,
        width: 90,
        cellsformat: "MM/dd/yyyy",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowData = {}
        ) => {
          const $defaulHtml = $(defaulthtml);

          if (value) {
            const date = datetimeUTC(value);

            $defaulHtml.text(
              date.isValid() ? date.format("MM/DD/YYYY") : "N/A"
            );
          } else if (!value && rowData.tractorStatus == "Available") {
            let today = datetimeUTC(new Date());
            today = today.set("hour", 0);
            today = today.set("minute", 0);

            $defaulHtml.text(today.format("MM/DD/YYYY"));
          }

          if (
            searchParams.searchType == "T" &&
            rowData != null &&
            rowData.tractorAvailableNow
          ) {
            const icon = `<span style="padding: 0 10px; font-size: 12px; color: blue""><i class="glyphicon glyphicon-exclamation-sign"></i> Available Now!</span>`;
            $defaulHtml.append(icon);
          }

          return $defaulHtml[0].outerHTML;
        },
      },
      {
        text: "Origin",
        datafield: "origin",
        minwidth: 170,
        width: 250,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties
        ) => {
          const $defaulHtml = $(defaulthtml).css({
            "text-transform": "uppercase",
          });

          return $defaulHtml[0].outerHTML;
        },
      },
      {
        text: "Destination",
        datafield: "destination",
        minwidth: 170,
        width: 250,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties
        ) => {
          const $defaulHtml = $(defaulthtml).css({
            "text-transform": "uppercase",
          });

          return $defaulHtml[0].outerHTML;
        },
      },
      { text: "DH-O", datafield: "originRadius", minwidth: 70, width: 70 },
      { text: "DH-D", datafield: "destinationRadius", minwidth: 70, width: 70 },
      {
        text: "Source",
        datafield: "boardName",
        minwidth: 80,
        width: 80,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties
        ) => {
          const $defaulHtml = $(defaulthtml);

          if (value) {
            switch (value) {
              case "GE":
                $defaulHtml.text(`Internal`);
                break;
              case "DAT 360":
                $defaulHtml.text(`DAT`);
                break;
              case "TruckStop":
                $defaulHtml.text(`TruckStop`);
                break;
              default:
                $defaulHtml.text(``);
                break;
            }
          }

          return $defaulHtml[0].outerHTML;
        },
      },
      { text: "Weight", datafield: "weight", minwidth: 70, width: 70 },
      { text: "Length", datafield: "feet", minwidth: 70, width: 70 },
      {
        text: "Favorites",
        datafield: "favoriteRating",
        minwidth: 90,
        width: 100,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties
        ) => {
          const $defaulHtml = $(defaulthtml);
          const val = value > 0 ? value : 0;

          const starRating = (amount = 0, starTemplate, rating = "") => {
            if (amount <= 0) return rating;
            if (!starTemplate) return rating;

            const temp = rating + starTemplate;
            return starRating(amount - 1, starTemplate, temp);
          };

          const starIconFilled = `<i class="glyphicon glyphicon-star"></i>`;
          const starIconEmpty = `<i class="glyphicon glyphicon-star-empty"></i>`;

          const rating = starRating(val, starIconFilled); //starRating((5 - val), starIconEmpty, starRating(val, starIconFilled));

          $defaulHtml.html(`<span>${rating}</span>`);

          return $defaulHtml[0].outerHTML;
        },
      },
    ];
  };

  const renderGrid = (source = {}, columns = []) => {
    const dataAdapter = dataModel.getDataAdapter(source);

    buildGrid({
      $grid,
      options: {
        theme: "light-blue-theme-v2",
        width: "100%",
        source: dataAdapter,
        altrows: true,
        sortable: true,
        columnsreorder: true,
        columnsmenu: false,
        columnsresize: true,
        enablebrowserselection: true,
        height: window.innerHeight - 300,
        autoheight: false,
        filterable: true,
        showfilterrow: false,
        showtoolbar: false,
        columns: columns,
        virtualmode: false,
        ready: () => {
          vm.vmFlags.gridLoaded(true);

          $grid.on("cellclick", (event) => {
            const { datafield, originalEvent, rowindex } = event.args || {};
            const rowdata = $grid.jqxGrid("getrowdata", rowindex);
            const { localName, attributes } = originalEvent.target || {};

            if (localName == "a") {
              const href = attributes.href;
              if (href) {
                router.navigate(href.value, true);
              }
            } else if (datafield == "callNotes") {
              handleCallNoteEdit(
                {
                  tractorOrLoadId: rowdata.id,
                  boardName: rowdata.boardName,
                },
                (note) => {
                  $grid.jqxGrid("updaterow", rowdata.uid, {
                    ...rowdata,
                    callNotes: note,
                  });
                }
              );
            } else {
              let age = "00:00";

              if (rowdata.age) {
                const { hours, mins } = getHoursMinsFromMins(rowdata.age);
                age = `${hours}:${mins}`;
              }

              onRowSelect(
                { ...rowdata, searchType: searchParams.searchType, age },
                () => {
                  $grid.jqxGrid("updaterow", rowdata.uid, {
                    ...rowdata,
                    previouslyViewed: true,
                  });
                }
              );

              $('div[role="row"]')
                .css({ cursor: "pointer" })
                .attr({ title: "Click to view details..." });
            }
          });

          $grid.on("filter", () => {
            refreshStatusBar($countEl, searchResultError);
          });

          $grid.on("bindingcomplete", () => {
            refreshStatusBar($countEl, searchResultError);
          });

          const extraActions = [];
          if (searchParams.searchType == "L") {
            extraActions.push({
              action: "Call Leads",
              handler: () => {
                const data = $grid.jqxGrid(
                  "getrowdata",
                  $grid.jqxGrid("getselectedrowindex")
                );
                if (data) {
                  const { id, origin, isGEOrder, boardName } = data;
                  const originLoc = mapToCityStateZipObject(
                    cleanString(["(", ")", ","], origin).split(" ")
                  );
                  const callLeadData = {
                    orderId: isGEOrder ? id : null,
                    loadId: isGEOrder == false && boardName == "GE" ? id : null,
                    externalPostingLoadId:
                      isGEOrder == false && boardName != "GE" ? id : null,
                    originCity: originLoc.city,
                    originState: originLoc.state,
                    originZip: originLoc.zip,
                  };

                  onCallLeadEntry(callLeadData);
                } else {
                  showmessage(`Please select an item from the result grid.`);
                }
              },
            });
          }

          vm.filterbar.render(extraActions);
        },
      },
    })([]);

    $grid.on("bindingcomplete", () => {
      $('div[role="row"]').on("mouseenter", (e) => {
        $(e.currentTarget)
          .css({ cursor: "pointer" })
          .attr({ title: "Click to view details..." });
      });

      $('div[role="row"]').on("mouseleave", (e) => {
        $(e.currentTarget).css({ cursor: "auto" }).removeAttr("title");
      });

      refreshStatusBar($countEl, searchResultError);
    });

    return $grid;
  };

  renderGrid(gridSource(), gridColumns());
};

import template from "./external-board-search-results-component.html";
import router from "router";
import { showmessage } from "show-dialog-methods";
export default {
  viewModel: ExternalBoardSearchResultsViewModel,
  template: template,
};
