import ko from "knockout";
import dataModel from "data-model";
import userProfile from "user-profile";
import router from "router";

class AgedARreportModel {
  constructor(mainModel) {
    var self = this;
    mainModel = mainModel || {};

    self.isLoading = mainModel.isLoading || ko.observable(false);
    self.isProcessing = ko.observable(false);
    self.reportBy = ko.observable("Bill Date");
    self.reportType = ko.observable("Detailed");
    self.sortByOption = ko.observable("Customer Code");
    self.sortBy = ko.observable("Value");

    self.cutOffDate = ko
      .observable(new Date())
      .extend({ required: { message: "Cutoff & Age date is required" } });
    self.errors = ko.observableArray([]);
    self.selectedItems = ko.observableArray([]);

    // Passing in the values the transfer list component looks for
    self.sortByOption.subscribe(function (sortBy) {
      switch (sortBy) {
        case "Customer Code":
          self.sortBy("Value");
          break;
        case "Customer Name":
          self.sortBy("DisplayText");
          break;
      }
    });

    self.url = "Customer/AgencyCustomers/" + userProfile.currentAgencyId();

    self.getPDF = function (obj) {
      return self.agedReportSubmit(obj, "pdf");
    };

    self.getExcel = function (obj) {
      return self.agedReportSubmit(obj, "xls");
    };

    self.getCSV = function (obj) {
      return self.agedReportSubmit(obj, "csv");
    };

    self.agedReportSubmit = function (obj, format) {
      self.errors.removeAll();

      var validationErrors = ko.validation.group([self.cutOffDate]);

      if (validationErrors().length > 0) {
        validationErrors.showAllMessages();
        return false;
      }

      if (self.selectedItems().length == 0) {
        self.errors.push("Please select at least one customer");
        return false;
      }

      var customers = [];

      ko.utils.arrayMap(self.selectedItems(), function (tmp) {
        customers.push(tmp);
      });

      var data = {
        agencyId: userProfile.currentAgencyId(),
        reportBy: self.reportBy,
        reportType: self.reportType,
        sortBy: self.sortBy,
        cutOffDate: self.cutOffDate,
        customers: customers,
        format: format,
      };

      self.isLoading(true);

      dataModel
        .downloadFile("Report/AgedAR", "POST", data, "AgedAR." + format)
        .then(function () {
          self.isLoading(false);
          mainModel.closeModal();
        })
        .catch((err) => {
          self.isLoading(false);

          const msg =
            err === "Gateway Timeout"
              ? `The report has failed. Please reduce the size of the request and try again.`
              : `Error occurred during request.`;

          showmessage(msg);
        });
    };
  }
}

class BrokerageRevenuModel {
  constructor(mainModel) {
    var self = this;
    mainModel = mainModel || {};
    self.isLoading = mainModel.isLoading || ko.observable(false);

    self.reportBy = ko.observable("Ship Date");
    self.reportType = ko.observable("Detailed");
    self.startDate = ko.observable();
    self.endDate = ko.observable();
    self.customerId = ko.observable();

    self.getPDF = function (obj) {
      return self.getReport(obj, "pdf");
    };
    self.getExcel = function (obj) {
      return self.getReport(obj, "xls");
    };
    self.getReport = function (obj, format) {
      self.isLoading(true);
      var item = ko.toJS(obj);

      var data = {
        agencyId: userProfile.currentAgencyId(),
        reportBy: item.reportBy,
        reportType: item.reportType,
        startDate: item.startDate,
        endDate: item.endDate,
        customerId: item.customerId,
        format: format,
      };

      dataModel
        .downloadFile(
          "Report/BrokerageRevenue",
          "POST",
          data,
          "BrokerageRevenue." + format
        )
        .then(function () {
          self.isLoading(false);
          mainModel.closeModal();
        })
        .catch((err) => {
          self.isLoading(false);

          const msg =
            err === "Gateway Timeout"
              ? `The request is taking longer than usual, please refine your search critieria or try reloading the page.`
              : `Error occurred during request.`;

          showmessage(msg);
        });
    };
  }
}

class UnbilledOrdersModel {
  constructor(mainModel) {
    var self = this;
    mainModel = mainModel || {};

    self.isLoading = mainModel.isLoading || ko.observable(false);

    self.reportBy = ko.observable("Ship Date");
    self.cutOffDate = ko.observable();
    self.customerId = ko.observable();

    self.getPDF = function (obj) {
      return self.getReport(obj, "pdf");
    };
    self.getExcel = function (obj) {
      return self.getReport(obj, "xls");
    };
    self.getReport = function (obj, format) {
      self.isLoading(true);

      var item = ko.toJS(obj);
      var data = {
        agencyId: userProfile.currentAgencyId(),
        reportBy: item.reportBy,
        cutOffDate: item.cutOffDate,
        customerId: item.customerId,
        format: format,
      };

      dataModel
        .downloadFile(
          "Report/UnbilledOrders",
          "POST",
          data,
          "UnbilledOrders." + format
        )
        .then(function () {
          self.isLoading(false);
          mainModel.closeModal();
        })
        .catch((err) => {
          self.isLoading(false);

          const msg =
            err === "Gateway Timeout"
              ? `The report has failed. Please reduce the size of the request and try again.`
              : `Error occurred during request.`;

          showmessage(msg);
        });
    };
  }
}

class AgencyRevenueModel {
  constructor(mainModel) {
    var self = this;
    mainModel = mainModel || {};

    self.reportBy = ko.observable("Ship Date");
    self.reportType = ko.observable("Detailed");
    self.startDate = ko.observable();
    self.endDate = ko.observable();
    self.customerId = ko.observable();
    self.isLoading = mainModel.isLoading || ko.observable(false);

    self.getPDF = function (obj) {
      return self.getReport(obj, "pdf");
    };
    self.getExcel = function (obj) {
      return self.getReport(obj, "xls");
    };
    self.getReport = function (obj, format) {
      self.isLoading(true);
      var item = ko.toJS(obj);

      var data = {
        agencyId: userProfile.currentAgencyId(),
        reportBy: item.reportBy,
        reportType: item.reportType,
        startDate: item.startDate,
        endDate: item.endDate,
        customerId: item.customerId,
        format: format,
      };

      dataModel
        .downloadFile(
          "Report/AgencyRevenue",
          "POST",
          data,
          "AgencyRevenueReport." + format
        )
        .then(function () {
          self.isLoading(false);
          mainModel.closeModal();
        })
        .catch((err) => {
          self.isLoading(false);

          const msg =
            err === "Gateway Timeout"
              ? `The report has failed. Please reduce the size of the request and try again.`
              : `Error occurred during request.`;

          showmessage(msg);
        });
    };
  }
}

class CustomerListModel {
  constructor(mainModel) {
    var self = this;
    mainModel = mainModel || {};
    self.isLoading = mainModel.isLoading || ko.observable(false);

    self.getPDF = function (obj) {
      return self.getReport(obj, "pdf");
    };
    self.getExcel = function (obj) {
      return self.getReport(obj, "xls");
    };
    self.getReport = function (obj, format) {
      self.isLoading(true);

      var data = {
        agencyId: userProfile.currentAgencyId(),
        format: format,
      };

      dataModel
        .downloadFile(
          "Report/CustomerList",
          "POST",
          data,
          "CustomerListReport." + format
        )
        .then(function () {
          self.isLoading(false);
          mainModel.closeModal();
        })
        .catch((err) => {
          self.isLoading(false);

          const msg =
            err === "Gateway Timeout"
              ? `The report has failed. Please reduce the size of the request and try again.`
              : `Error occurred during request.`;

          showmessage(msg);
        });
    };
  }
}

class ReportViewModel {
  constructor() {
    var self = this;

    // UI CONTROLS
    self.isLoading = ko.observable(false);
    self.isPageInit = ko.observable(false);
    self.revenueVisible = ko.observable(false);
    self.operationsVisible = ko.observable(false);
    self.safetyVisible = ko.observable(false);

    self.ssasReportsVisible = ko.observable(false);

    self.closeModal = function () {
      $(".modal").modal("hide");
    };

    self.downloadStart = function () {
      $("#lblStatus").html("Downloading...");
      self.isLoading(true);
      return true;
    };
    self.downloadEnd = function () {
      self.isLoading(false);
      return false;
    };

    self.getDriverCSAReport = function () {
      self.isLoading(true);
      dataModel
        .downloadFile(
          "Report/AgencyDriversCSA",
          "GET",
          null,
          "DriverReport.zip"
        )
        .then(function () {
          self.isLoading(false);
        })
        .catch((err) => {
          self.isLoading(false);

          const msg =
            err === "Gateway Timeout"
              ? `The report has failed. Please reduce the size of the request and try again.`
              : `Error occurred during request.`;

          showmessage(msg);
        });
    };

    self.ssasPreview = ko.observable();
    self.getSSASPreview = function () {
      self.ssasPreview(new SSASPreviewModel(self));
    };

    self.agedARreport = ko.observable();
    self.showAgedARreport = function () {
      self.agedARreport(new AgedARreportModel(self));
    };

    self.brokerageRevenue = ko.observable();
    self.showBrokerageRevenue = function () {
      self.brokerageRevenue(new BrokerageRevenuModel(self));
    };

    self.unbilledOrders = ko.observable();
    self.showUnbilledOrders = function () {
      self.unbilledOrders(new UnbilledOrdersModel(self));
    };

    self.agencyRevenue = ko.observable();
    self.showAgencyRevenue = function () {
      self.agencyRevenue(new AgencyRevenueModel(self));
    };

    self.viewMovementReport = function () {
      router.navigate("MovementReport");
    };

    self.customerList = ko.observable();
    self.showCustomerList = function () {
      self.customerList(new CustomerListModel(self));
    };

    self.init = function () {
      self.isLoading(true);

      dataModel
        .ajaxRequest("Report/GetPageAccessRights", "GET")
        .done(function (response) {
          self.isLoading(false);

          self.revenueVisible(response.revenueVisible);
          self.operationsVisible(response.operationsVisible);
          self.safetyVisible(response.safetyVisible);
          self.ssasReportsVisible(response.ssasReportsVisible);

          self.isPageInit(true);
        });
    };

    self.init();
  }
}

import template from "./reporting-page.html";
import { showmessage } from "show-dialog-methods";
export default { viewModel: ReportViewModel, template: template };
