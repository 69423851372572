
import ko from 'knockout';
import dataModel from 'data-model';
import userProfile from 'user-profile';
import router from 'router';

// import '../../../../../Content/fullcalendar.css';

class EmployeeCalendarViewModel {
    constructor() {
        var self = this;

        self.isLoading = ko.observable(false);
        self.isLoading(false);
        self.selectedUser = ko.observable();
        self.selectedUserSubscription = self.selectedUser.subscribe(function(value) {
            self.calendar.fullCalendar('refetchEvents');
        }); 

        self.calendarUsers = ko.observableArray();

        self.calendar = $('#calendar');
        self.events = [];

        self.calendarEvent = ko.observable();
        
        self.calendarEventModel = function (event) {
            event = event || {};
            this.id = ko.observable(event.id || null);
            this.newSubject = ko.observable(event.title || '').extend({required: true});
            this.newStartTime = ko.observable(event.start || null).extend({required: true});
            this.newEndTime = ko.observable(event.end || event.start).extend({required: true});
            this.newDescription = ko.observable(event.description || '');
            this.newDisplayImage = ko.observable(event.displayImage || false);
            this.newSendReminder = ko.observable(event.sendReminder || false);
            this.newAllDayEvent = ko.observable(event.allDay || false);
            this.fileUpload = ko.observable(event.imageURL);
            this.modifiedby = ko.observable(event.modifiedby || null);

            this.createNewEvent = function(event) {
                let errors = ko.validation.group(event);
                if(errors().length > 0) {
                    errors.showAllMessages();
                } else {
                    let imageURL = $('#fileUpload').val();

                    let newEvent = {
                        id: event.id(),
                        subject: event.newSubject(),
                        title: event.newSubject(),
                        start: event.newStartTime(),
                        end: event.newEndTime(),
                        description: event.newDescription(),
                        sendReminder: event.newSendReminder(),
                        allDayEvent: event.newAllDayEvent(),
                        displayImage: event.newDisplayImage(),
                        imageURL: imageURL != '' ? imageURL.replace('C:\\fakepath\\', '') : this.fileUpload(),
                        modifiedby: event.modifiedby(),
                        backgroundColor: '#0d8037'
                    };
                    if(newEvent.id) {
                        self.editEvent(newEvent);
                    } else {
                        self.addEvent(newEvent);
                    }
                    if(imageURL != '' && this.newDisplayImage()) {
                        self.addOrUpdateDocument();
                    }
                    $('.close').click();                
                }
            };
        };

        self.addOrUpdateDocument = function () {

            // self.errors.removeAll();
            self.isLoading(true);

            var files = $("#fileUpload").get(0).files;
            var formData = new FormData();

            // file selected by user - upload file and then add/update document data
            var file = files[0];
            formData.append('UploadedFile', file);

            dataModel.ajaxUploadRequest("EmployeeCalendar/UploadFile", "POST", formData)
                .done(function (uploadedFileName, status, xhr) {
        
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    self.errors.push(textStatus);
                    self.isLoading(false);
                }
            );
            self.isLoading(false);
        };


        self.editEvent = function(event) {
            //If both the event end and start are the same
            //FullCalendar sets end = null, then throws errors 
            //downstream when end = null.
            dataModel.ajaxRequest('EmployeeCalendar/AddEvent', 'POST', event)
                .done(function(value) {
                    event.lastupdatedby = value.userId;
                    event.imageURL = value.imageURL;
                    let ei = self.events.findIndex(function(element) { return element.id == event.id; });
                    self.events[ei] = event;
                    self.calendar.refresh();
                }
            );
        };

        self.editEventForDrop = function(event) {
            let newEvent = {
                id : event.id,
                subject: event.title,
                start: event.start ? event.start.toISOString() : '',
                end: event.end ? event.end.toISOString() : '',
                allDayEvent: event.allDay || false,
                description: event.description,
                sendReminder: event.sendReminder || false,
                displayImage: event.displayImage || false,

            };
            dataModel.ajaxRequest('EmployeeCalendar/AddEvent', 'POST', newEvent)
            .done(function(value) {
                // self.events = self.events.filter(function(item, index, arr) { return item.id != curApptId; });
            });
        };

        self.addEvent = function(event) {
            dataModel.ajaxRequest('EmployeeCalendar/AddEvent', 'POST', event)
                .done(function(value) {
                    let newEvent = {
                        id: value.id,
                        allDay: value.allDayEvent,
                        description: value.description || '',
                        start: value.start,
                        end: value.end,
                        displayImage : value.displayImage || false,
                        modifiedby: value.userId,
                        slotLabelFormat: "HH(:mm)t",
                        title: value.subject,
                        backgroundColor: '#0d8037',
                        imageURL: value.imageURL
                    };
                    self.events.push(newEvent);
                    self.calendar.refresh();
                }
            );
        };


        self.showNewCalandarEventModal = function() {
            self.calendarEvent(new self.calendarEventModel());
        };

        // self.loadCalendarb = function (selectedUser) {
        //     $('#calendar').fullCalendar('refetchEvents');
        // };
  
        self.loadCalendar = function () {
            self.isLoading(true);
            
            self.calendar.fullCalendar({

                eventRender: self.eventRender,
                events: self.eventGetter,
                eventDrop: self.eventDrop,

                header: { left: 'prev, next today', center: 'title', right: 'month, basicWeek' },
                defaultView: 'month',
                editable: true,
                selectable: true,
                dayClick: self.dayClick,                       
                timeFormat: 'h(:mm)t',
            });     
        };  

        self.eventDrop = function(event, delta, revertFunc) {
            self.editEventForDrop(event);
        };
        
        self.dayClick = function(date, jsEvent, view) {
            $('#calendar').fullCalendar('gotoDate', date);
            $('#calendar').fullCalendar('changeView', 'basicDay');
            $(document.body).animate({ scrollTop: 0 }, "slow");
        };

        self.calendar.refresh = function() {
            let cal = self.calendar.fullCalendar('getCalendar');
            cal.removeEvents();
            cal.removeEventSources();
            cal.addEventSource(self.events);
        };

        self.startDate = ko.observable();
        self.endDate = ko.observable();

        self.eventGetter = function (starttime, endtime, timezone, callback) {   
            var d = new Date(starttime);
            var startDate = $.datepicker.formatDate('yy-mm-dd', d);
            self.startDate(startDate);

            var d2 = new Date(endtime);
            var endDate = $.datepicker.formatDate('yy-mm-dd', d2);
            self.endDate(endDate);
            
            var route = "EmployeeCalendar/GetCalendarEvents";
            var data = {
                start: startDate,
                end: endDate,
                selectedUser: self.selectedUser,

            };
            self.isLoading(true);
            dataModel.ajaxRequest(route, "GET", data)
                .done(function (data, status, xhr) {  
                    self.events = [];
                    $.each(data, function (index, value) {
                        self.events.push({
                            id: value.id,
                            lastUpdatedAt: value.lastUpdatedAt,
                            description: value.description == null ? '' : value.description,
                            modifiedby: value.userName,
                            title: value.subject,
                            start: new Date(value.start),
                            end: new Date(value.end),
                            allDayEvent: value.allDayEvent || false,
                            slotLabelFormat: 'HH(:mm)t',
                            displayEventEnd: true,
                            editable: value.userName == dataModel.userName,
                            startEditable: value.userName == dataModel.userName,
                            durationEditable: value.userName == dataModel.userName,
                            displayImage: value.displayImage || false,
                            imageURL: value.imageURL,
                            sendReminder: value.sendReminder || false,
                            backgroundColor: value.userName == dataModel.userName ? '#0d8037' : '#3a87ad'
                        });               
                    });
                    callback(self.events);
                    self.getCalendarUsers();
                    self.isLoading(false);
                })
                .fail(function (error, msg, d) {
                    self.isLoading(false);
                }
            );
        };

        self.getCalendarUsers = function() {
            var route = "EmployeeCalendar/GetCalendarUsers";
            var data = {
                start: self.startDate(),
                end: self.endDate()
            };

            dataModel.ajaxRequest(route, "GET", data)
            .done(function (data, status, xhr) {
                let newCalUsers = [];
                newCalUsers.push("Select User");
                $.each(data, function (key, value) {
                    let i = newCalUsers.indexOf(value);
                    if(i == -1) {
                        newCalUsers.push(value);
                    }
                });
                self.calendarUsers(newCalUsers);
            })
            .fail(function (error, msg, d) {
                self.isLoading(false);
            });

        };

        self.eventRender = function(event, element) {    
            element.find(".fc-bg").css("pointer-events", "none");
            element.removeClass("fc button").append("<button type='button' id='btnDeleteEvent' class='btn btn-block btn-info btn-flat small-btn'>x</button>");     
            if(event.modifiedby == dataModel.userName) {
                element.find("#btnDeleteEvent").click(function (e) {
                    e.stopPropagation();
                    $('#subjectb').html('<b> Subject: </b>' + event.title);
                    let eventstart = event.start != null ? event.start.format('MMM Do h:mm A') : '';
                    let eventend = event.end != null ? event.start.format('MMM Do h:mm A') : '';
                    $("#startTimeb").html('<b> Start: </b>' + eventstart);
                    $("#endTimeb").html('<b> End: </b>' + eventend);
                    $("#eventLinkb").prop('id', 'deleteclick');
                    $("#eventDelete").dialog({
                        modal: true,
                        title: "Delete Event",
                        width: '100%',
                        height: 280,
                        resize: "auto",
                        open: function() { $(".ui-dialog").css("box-shadow","#000 5px 5px 5px"); },
                        buttons: [            
                            {
                                text: "Confirm",                                              
                                buttonIcons: "ui-icon-gear",
                                click: function () {
                                    var curApptId = event.id;
                                    var data = null;
                                    dataModel.ajaxRequest("EmployeeCalendar/DeleteAppointment/" + curApptId, "POST", data)
                                    .done(function (data, status, xhr) {
                                        self.isLoading(false);
                                        self.events = self.events.filter(function(item, index, arr) { return item.id != curApptId; });
                                        $('#calendar').fullCalendar('removeEvents', event.id);
                                        $('#eventDelete').dialog('close');
                                    })
                                    .fail(function (error, msg, d) {
                                        self.isLoading(false);                                                
                                        $("#errorMessage").dialog({
                                            modal: true,
                                            title: "Error",
                                            open: function() {
                                                var errorMessage = 'You can only delete your own events';
                                                $(this).html(errorMessage);
                                                $(".ui-dialog").css("box-shadow", "#000 5px 5px 5px");
                                            },
                                            width: '100%',
                                            height: 250,
                                            show: { effect: "puff", duration: 500 },
                                            hide: { effect: "slide", duration: 500 }
                                        });
                                        
                                        $('#eventDelete').dialog('close');
                                    });
    
                                }
                            }
                        ],
                        show: {
                            effect: "puff",
                            duration: 500
                        },
                        hide: {
                            effect: "slide",
                            duration: 500
                        }
                    });
                    
                });
            } else {
                element.find("#btnDeleteEvent").hide();
            }
            element.css('height', '35px');
            element.attr('href', '#');
            element.attr('onclick', 'return false;');
            element.click(function () {   
                $("#subject").html('<b> Subject: </b>' + event.title);
                if(event.allDay) {
                    $("#startTime").html('<b> All Day Event </b>');
                    $("#endTime").html('');                             
                } else {
                    if(event.start) {
                        $("#startTime").html('<b> Start: </b>' + event.start.format('MMM Do h:mm A'));
                    }
                    if(event.end) {
                        $("#endTime").html('<b> End: </b>' + event.end.format('MMM Do h:mm A'));                             
                    }
                }
                $("#modifiedBy").html('<b>Last Modified By: </b>' + event.modifiedby);
                
                if (event.modified != null)
                {
                    $("#modified").html('On: ' + moment.parseZone(event.modified).format('MMM Do h:mm A'));
                }
                else
                {
                    $("#modified").html('');
                }
                if(event.imageURL && event.displayImage) {
                    $('#eventImage').html('<img src="' + encodeURI(event.imageURL) + '" height="150" width="150">');
                }
                $("#eventInfo").html('<b>Description </b> <br />' + event.description + '<br /><br />');
                
                $("#eventContent").dialog({
                    modal: true, title: "Appointment Details",
                    width: '100%',
                    height: 'auto',
                    resize: 'auto',
                    open: function () { $(".ui-dialog").css("box-shadow", "#000 5px 5px 5px"); },
                    show: {
                        effect: "puff",
                        duration: 500
                    },
                    hide: {
                        effect: "slide",
                        duration: 500
                    }
                });

                if(dataModel.userName == event.modifiedby) {
                    $("#eventLink").show();
                    $("#eventLink").click(function() {
                        $(this).closest('.ui-dialog-content').dialog('close'); 
                        self.calendarEvent(new self.calendarEventModel(event));
                        $(this).off('click');
                    });
                } else {
                    $("#eventLink").hide();
                }
            });
        };

        self.loadCalendar();
        self.getCalendarUsers();
    }
}

import template from './employee-calendar-page.html';
export default { viewModel: EmployeeCalendarViewModel, template: template };