export default function hookFns(...params) {
    return (hookFns = []) => {
        if(hookFns.length) {
            try {
                hookFns.forEach(fn => fn(...params));
            }
            catch(err) {
                console.error(err);
            }
        }
    }
}