import ko from "knockout";
import dataModel from "data-model";
import dayjs from "dayjs";
import template from "./driver-call-in-records-page.html";
import gridStateUtils from "jqx.grid-utils";
import GreatEdgeCustomWidgets from "ge.custom-widgets";
import GridStateComponentViewModel from "jqx.grid-state-component";
import * as _ from "lodash";
import { mobileAndTabletcheck, datetimeUTC } from "global-functions";
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";

// Types
var DriverRecordViewModel = function (data, mainModel) {
  var self = this;
  mainModel = mainModel || {};
  data = data || {};
  var record = data.record || {};
  var order = data.currentOrder || {};

  self.tractorNumber = ko.observable(record.tractorId);
  self.orderNumber = ko.observable(record.orderExId);
  self.driverId = ko.observable(record.driverExId);
  self.driverName = ko.observable(record.driverName);
  self.enteredBy = ko.observable(record.enteredBy);
  self.notes = ko.observable(record.notes);
  self.temperature = ko.observable(record.temperature);

  self.moveStatus = ko.observable(order.moveStatus);
  self.moveDistance = ko.observable(order.moveDistance);

  // Access Controls
  self.allowedToEdit = ko.computed(function () {
    if (record.enteredBy == mainModel.currentUser().userName) {
      return true;
    }
    return false;
  });

  // Format dates
  self.newETA = ko.computed(function () {
    if (record.newETA != undefined) {
      var newETA = new dayjs(record.newETA);
      return newETA.format("MMMM DD, YYYY HH:mm");
    }
  });

  self.callDate = ko.computed(function () {
    if (record.callDate != undefined) {
      var callDate = new dayjs(record.callDate);
      return callDate.format("MMMM DD, YYYY HH:mm");
    }
  });

  self.arrival = ko.computed(function () {
    if (order.arrival != undefined && order.arrival != null) {
      var arrival = new dayjs(order.arrival);
      return arrival.format("MMMM DD, YYYY HH:mm");
    } else {
      return "";
    }
  });

  self.cityStateZip = ko.computed(function () {
    var city = record.address.city || "";
    var state = record.address.state || "";
    var zip = record.address.zip || "";

    return city + " " + state + " " + zip;
  });

  self.orderOrigin = ko.computed(function () {
    if (order.origin != undefined) {
      var city = order.origin.city || "";
      var state = order.origin.state || "";
      var zip = order.origin.zip || "";

      return city + " " + state + " " + zip;
    }
  });

  self.orderDest = ko.computed(function () {
    if (order.destination != undefined) {
      var city = order.destination.city || "";
      var state = order.destination.state || "";
      var zip = order.destination.zip || "";

      return city + " " + state + " " + zip;
    }
  });

  self.openEditRecordModal = function () {
    if (self.allowedToEdit() == true) {
      self.closeDetails();
      mainModel.openEditCallinModal(data);
    }
  };

  self.closeDetails = function () {
    $("#detailsModal").modal("hide");
  };
  return self;
};

var NewCallInRecordViewModel = function (mainModel) {
  var self = this;
  mainModel = mainModel || {};
  var user = mainModel.currentUser() || {};

  self.currentUser = ko.observable(user);
  self.cityStateZip = ko.observable().extend({ required: true });
  self.newETA = ko
    .observable()
    .extend({ required: true, dateTodayOrGreater: true });
  self.pta = ko
    .observable()
    .extend({ required: true, dateTodayOrGreater: true });

  self.orderInput = ko.observable().extend({ required: true });
  self.orderSelected = ko.observable();
  self.tractorInput = ko.observable().extend({ required: true });
  self.tractorSelected = ko.observable();
  self.driverInput = ko.observable().extend({ required: true });
  self.driverSelected = ko.observable();

  self.errors = ko.observableArray();
  self.notes = ko.observable();
  self.temperature = ko.observable();

  self.isProcessing = ko.observable(false);

  self.now = ko.computed(function () {
    var m = new dayjs();
    return m.format("MMMM DD, YYYY HH:mm");
  });

  // Watch the tractor selection DDL and populate the driver DDL select with associated driver
  self.tractorSelected.subscribe(function (val) {
    if (val != undefined) {
      dataModel
        .ajaxRequest(
          "EquipmentGroup/CurrentEquipmentByTractorId/" + val.id,
          "GET"
        )
        .done(function (response) {
          if (response != undefined) self.driverInput(response.driver1Id);
        });
    }
  });

  self.closeModal = function () {
    $("#callInModalForm").modal("hide");
  };

  self.pta.subscribe(function (val) {
    self.errors.removeAll();
    if (val != undefined && self.newETA() != undefined) {
      var eta = self.newETA();
      eta = eta.second(0);
      eta = eta.millisecond(0);

      var pta = self.pta();
      pta = pta.second(0);
      pta = pta.millisecond(0);

      if (pta.isBefore(eta))
        self.errors.push({
          message: "The PTA has to be same or greater than the New ETA.",
        });
    }
  });

  // Validate that we have proper date selections
  // I.e. New ETA is today or greater
  // PTA is greater than New ETA and date is today or later
  self.validateDateSelections = function () {
    if (self.newETA() == undefined) {
      self.errors.push({ message: "Please enter a New ETA." });
    }

    if (self.pta() == undefined) {
      self.errors.push({ message: "Please enter the PTA." });
    }

    if (self.errors().length > 0) return false;

    var today = new dayjs();
    today = today.hour(0);
    today = today.minute(0);
    today = today.second(0);
    today = today.millisecond(0);

    var eta = self.newETA();
    eta = eta.second(0);
    eta = eta.millisecond(0);

    var pta = self.pta();
    pta = pta.second(0);
    pta = pta.millisecond(0);

    if (eta.isBefore(today)) {
      self.errors.push({ message: "New ETA has to be today or later." });
    }

    if (pta.isBefore(today)) {
      self.errors.push({ message: "PTA has to be today or later." });
    }

    if (pta.isBefore(eta)) {
      self.errors.push({
        message: "The PTA has to be same or greater than the New ETA.",
      });
    }
  };

  self.submitRecord = function () {
    self.errors.removeAll();
    var validationErrors = ko.validation.group(self);
    if (validationErrors().length > 0) {
      validationErrors.showAllMessages();
      return false;
    }

    self.validateDateSelections();

    if (self.errors().length > 0) return false;

    var data = {
      OrderId: self.orderSelected().id,
      Notes: self.notes(),
      EnteredById: user.id,
      CallTime: self.now(),
      NewETA: self.newETA().format("MMMM DD, YYYY HH:mm"),
      DriverId: self.driverSelected().id,
      TractorId: self.tractorSelected().id,
      Temp: self.temperature(),
      CityStateZip: self.cityStateZip(),
      PTA: self.pta().format("MMMM DD, YYYY HH:mm"),
    };

    self.isProcessing(true);

    dataModel
      .ajaxRequest("CallinRecords/CreateCallInRecord", "POST", data)
      .done(function (response) {
        if (response.success != undefined) {
          mainModel.updateGridData();
        }

        self.isProcessing(false);
        self.closeModal();
      })
      .fail(function (response) {
        if (response.responseJSON != undefined) {
          self.errors.push(response.responseJSON);
        }
        self.isProcessing(false);
      });
  };

  return self;
};

var EditCallInRecordViewModel = function (recordToEdit, mainModel) {
  var self = this;
  mainModel = mainModel || {};
  var record = recordToEdit.record || {};
  var callRecordId = record.id;
  var eta = record.newETA || "";
  var pta = record.ptaDate || "";

  self.formatDateTime = function (datetime) {
    if (datetime != undefined) {
      return dayjs(datetime).format(datetime, "MM/DD/YYYY HH:mm");
    }
    return dayjs().format("MM/DD/YYYY HH:mm");
  };

  self.formatCityStateZip = function (address) {
    if (address != undefined) {
      var zip = address.zip || "";
      var city = address.city || "";
      var state = address.state || "";
      return zip + " (" + city + ", " + state + ")";
    }
  };

  self.enteredByName = ko.observable(record.enteredBy);
  self.cityStateZip = ko
    .observable(self.formatCityStateZip(record.address))
    .extend({ required: true });
  self.tractorId = ko.observable(record.tractorId);
  self.driverId = ko.observable(record.driverExId);
  self.newETA = ko
    .observable(self.formatDateTime(eta))
    .extend({ required: true });
  self.pta = ko.observable(self.formatDateTime(pta)).extend({ required: true });
  self.orderId = ko.observable(record.orderExId);
  self.errors = ko.observableArray();
  self.notes = ko.observable(record.notes);
  self.temperature = ko.observable(record.temperature);

  self.isProcessing = ko.observable(false);

  self.callDate = ko.computed(function () {
    var date = new dayjs(record.callDate);
    return date.format("MMMM DD, YYYY HH:mm");
  });

  self.closeModal = function () {
    $("#editCallInModalForm").modal("hide");
  };

  self.pta.subscribe(function (val) {
    self.errors.removeAll();
    if (val != undefined && self.newETA() != undefined) {
      var eta = self.newETA();
      eta = eta.second(0);
      eta = eta.millisecond(0);

      var pta = self.pta();
      pta = pta.second(0);
      pta = pta.millisecond(0);

      if (pta.isBefore(eta, "minute")) {
        self.errors.push({
          message: "The PTA has to be same or greater than the New ETA.",
        });
      }
    }
  });

  // Validate that we have proper date selections
  // I.e. New ETA is today or greater
  // PTA is greater than New ETA and date is today or later
  self.validateDateSelections = function () {
    if (self.newETA() == undefined) {
      self.errors.push({ message: "Please enter a New ETA." });
    }

    if (self.pta() == undefined) {
      self.errors.push({ message: "Please enter the PTA." });
    }

    if (self.errors().length > 0) return false;

    var eta = self.newETA();
    eta = eta.second(0);
    eta = eta.millisecond(0);

    var pta = self.pta();
    pta = pta.second(0);
    pta = pta.millisecond(0);

    if (pta.isBefore(eta, "minute")) {
      self.errors.push({
        message: "The PTA has to be same or greater than the New ETA.",
      });
    }
  };

  self.submitRecord = function () {
    self.errors.removeAll();

    var validationErrors = ko.validation.group(self);

    if (validationErrors().length > 0) {
      validationErrors.showAllMessages();
      return false;
    }

    // Make sure we have valid dates selected
    self.validateDateSelections();

    if (self.errors().length > 0) return false;

    var data = {
      callRecordId: callRecordId,
      Notes: self.notes(),
      CallTime: self.callDate(),
      NewETA: dayjs(self.newETA()).format("MMMM DD, YYYY HH:mm"),
      DriverCode: self.driverId(),
      TractorId: self.tractorId(),
      Temperature: self.temperature(),
      CityStateZip: self.cityStateZip(),
      PTA: dayjs(self.pta()).format("MMMM DD, YYYY HH:mm"),
    };

    self.isProcessing(true);

    dataModel
      .ajaxRequest("CallinRecords/EditCallInRecord", "PUT", data)
      .done(function (response) {
        if (response.success != undefined) {
          ko.utils.arrayMap(mainModel.driverRecords(), function (item) {
            if (item.id == callRecordId) {
              item.city = response.address.city;
              item.state = response.address.state;
            }
          });

          $("#driverCallinGrid").jqxGrid("updatebounddata", "cells");
        }

        self.isProcessing(false);
        self.closeModal();
      })
      .fail(function (response) {
        if (response.responseJSON != undefined) {
          self.errors.push(response.responseJSON);
        }
        self.isProcessing(false);
      });
  };

  return self;
};

//=======================
// ViewModel
var DriverCallInRecordsViewModel = function () {
  var self = this;
  ko.validation.rules["dateTodayOrGreater"] = {
    validator: function (arg) {
      var today = new dayjs().format("L");
      var date = new dayjs(arg).format("L");
      if (date < today) return false;
      return true;
    },
    message: "Date must be today or later.",
  };
  //============================
  // UI Controls
  self.isLoading = ko.observable(false);
  self.isProcessing = ko.observable(true);
  self.modelErrors = ko.observableArray([]);
  self.isPageInitialized = ko.observable(false);
  self.dateRangeSelected = ko.observable();
  var dateRangeOptions = [
    { id: 1, text: "Last 30 days", value: -30 },
    { id: 2, text: "Last 90 days", value: -90 },
    { id: 3, text: "Last year", value: -365 },
    { id: 4, text: "All time", value: -2550 }, // 7 years
  ];
  self.dateRangeSelect = ko.observableArray(dateRangeOptions);

  var grid = $("#driverCallinGrid");
  var gridId = "driverCallinGrid";

  self.detailsModal = ko.observable();
  self.callInModalForm = ko.observable();
  self.editCallInModalForm = ko.observable();
  self.clearSelectedSavedSearchDDL = ko.observable(false);
  self.refreshSavedSearchDDL = ko.observable("");

  self.handleOnSelected = (state) => {
    self.driverGridState = state.grid || {};
    if (isInitLoad) {
      self.buildGrid();
    } else {
      gridStateUtils.applyGridState(gridId, self.driverGridState);
    }
  };

  let isInitLoad = true;

  var geCustomWidgets = new GreatEdgeCustomWidgets();
  var _jqxGridHelper = geCustomWidgets.GEWidgetsGridHelper("driverCallinGrid");

  //============================
  // Data

  self.driverRecords = ko.observableArray([]);
  self.currentUser = ko.observable();

  //=========================
  // Behaviors

  self.dateRangeSelected.subscribe(function () {
    if (self.isProcessing() == false && self.isPageInitialized() == true) {
      grid.jqxGrid("updatebounddata", "data");
    }
  });

  self.closeCallModalForm = function () {
    $("#callInModalForm").modal("hide");
  };

  // Set and disply model errors
  self.setModelErrors = function (errors) {
    if (errors != null || errors != undefined) {
      for (var i = 0; i < errors.length; i++) {
        self.modelErrors.push({ errorMessage: errors[i] });
      }
    }
  };

  //--------------------------------------------------------
  // GRID
  //=======

  self.placeHolderFilterWidget = function (column, columnElement, widget) {
    widget.jqxInput({
      placeHolder: "Type Here...",
    });
  };

  self.updateGridData = function () {
    grid.jqxGrid("updatebounddata", "data");
  };

  self.driverGridState = {};
  self.buildGrid = function () {
    var geDateRangeFilterWidget = function (column, columnElement, widget) {
      //set widget (class) level options
      var options = {
        gridOptions: {
          gridWidget: widget,
          gridId: "driverCallinGrid", // required to apply gridoptions
          columnName: column.datafield, // also required, especially if this is a filter widget
          isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
          state: self.driverGridState, // can send our grid state and apply any initial filters
        },
      };
      return geCustomWidgets.GEDateRangePickerWidget(options); // will return widget if already exists or creates a new one;
    };

    var source = {
      url: "CallinRecords/GetAllCallInRecordsSP",
      datatype: "json",
      datafields: [
        { name: "id", type: "number" },
        { name: "tractorId", type: "string" },
        { name: "driverName", type: "string" },
        { name: "orderId", type: "string" },
        { name: "city", type: "string" },
        { name: "state", type: "string" },
        { name: "callDate", type: "date" },
        { name: "enteredBy", type: "string" },
      ],
      beforeprocessing: function (data) {
        source.totalrecords = data.totalCount;

        data.records = data.records || [];
        data.records = data.records.map((x) => {
          if (x.callDate) {
            x.callDate = x.callDate.substr(0, x.callDate.indexOf("T") + 9);
          }

          return x;
        });
      },
      filter: function (filters, recordsArray) {
        if (isInitLoad == false) {
          grid.jqxGrid("updatebounddata", "filter");
        }
      },
      sort: function (column, direction) {
        if (isInitLoad == false) {
          grid.jqxGrid("updatebounddata", "sort");
        }
      },
      formatdata: function (data) {
        var filterinfo = grid.jqxGrid("getfilterinformation");
        data = gridStateUtils.formatGridFilters(filterinfo, data);

        if (isInitLoad) {
          data = gridStateUtils.loadInitGridState(
            data,
            self.driverGridState,
            columns
          );
        }

        data = _jqxGridHelper.addGridFiltersToDataObj(data);

        if (data.callDateBegin == null || data.callDateEnd == null) {
          data.daysBack = self.dateRangeSelected() || -30;
        }

        return data;
      },
      loadComplete: function (data) {
        self.currentUser(data.currentUser);
        self.isProcessing(false);
      },
      loadError: function (xhr, status, error) {},
    };

    var dataAdapter = dataModel.getDataAdapter(source);

    var columns = [
      {
        text: "",
        width: 100,
        columntype: "button",
        cellsrenderer: function () {
          return "Details";
        },
        buttonclick: function (row) {
          var item = grid.jqxGrid("getrowdata", row);
          self.viewRecordDetails(item);
        },
        filterable: false,
        sortable: false,
        pinned: true,
        resizable: false,
      },
      {
        text: "Tractor",
        width: 250,
        datafield: "tractorId",
        createfilterwidget: self.placeHolderFilterWidget,
      },
      {
        text: "Drivers",
        width: "35%",
        datafield: "driverName",
        createfilterwidget: self.placeHolderFilterWidget,
      },
      {
        text: "Order #",
        width: 250,
        datafield: "orderId",
        createfilterwidget: self.placeHolderFilterWidget,
      },
      {
        text: "City",
        width: 250,
        datafield: "city",
        createfilterwidget: self.placeHolderFilterWidget,
      },
      {
        text: "State",
        width: 110,
        datafield: "state",
        createfilterwidget: self.placeHolderFilterWidget,
      },
      {
        text: "Call Date",
        width: 200,
        datafield: "callDate",
        cellsformat: "MM/dd/yyyy HH:mm",
        createfilterwidget: geDateRangeFilterWidget,
      },
      {
        text: "Entered By",
        width: 110,
        datafield: "enteredBy",
        createfilterwidget: self.placeHolderFilterWidget,
      },
    ];

    grid.jqxGrid({
      width: "100%",
      source: dataAdapter,
      autoheight: true,
      altrows: true,
      sortable: true,
      pageable: true,
      pagesize: 10,
      filterable: true,
      showfilterrow: true,
      virtualmode: true,
      columnsresize: true,
      columnsreorder: true,
      enablebrowserselection: true,
      columnsmenu: false,
      columns: columns,
      showtoolbar: true,
      toolbarheight: 40,
      autoshowfiltericon: true,

      rendertoolbar: function (toolbar) {
        var vm1 = new GridStateComponentViewModel.viewModel();
        var template = GridStateComponentViewModel.template;
        let $grid = $("#driverCallinGrid");
        vm1.actions.push("Save Search");

        vm1.setDefaultSearchOverride = async () => {
          const savedSearches = await loadSavedSearches($grid.attr("id"));
          const filters = {
            isDefault: true,
          };

          vm1.loadSaveSearchModal(
            $grid,
            (val) => {
              if (val && val.searchname) {
                self.refreshSavedSearchDDL(val.searchname);
              }
            },
            savedSearches
              .filter((x) => x.searchName)
              .map((x) => ({ id: x.id, text: x.searchName })),
            filters,
            true
          );
        };

        vm1.loadSaveSearchModalOverride = async () => {
          const savedSearches = await loadSavedSearches($grid.attr("id"));
          const filters = {
            isDefault: false,
          };

          vm1.loadSaveSearchModal(
            $grid,
            (val) => {
              if (val && val.searchname) {
                self.refreshSavedSearchDDL(val.searchname);
              }
            },
            savedSearches
              .filter((x) => x.searchName)
              .map((x) => ({ id: x.id, text: x.searchName })),
            filters,
            true
          );
        };
        vm1.clearFilters = function () {
          _jqxGridHelper.clearDateRangeFiltersFromGrid();
          // clear additional filters from grid
          grid.jqxGrid("clearfilters");
          self.clearSelectedSavedSearchDDL(true);
        };

        const $actionList = $("#driverCallinGridAction");
        $actionList.append(template);

        toolbar.append($actionList);

        const $driverCallinToolbar = $("#driverCallinToolbar");

        toolbar.append($driverCallinToolbar);

        ko.applyBindingsToDescendants(vm1, $actionList[0]);
      },

      rendergridrows: function (obj) {
        return obj.data;
      },

      ready: function () {
        //self.loadGridState(grid, self.driverGridState);
        gridStateUtils.loadGridStateFilters(grid, self.driverGridState.filters);
        isInitLoad = false;
        self.isPageInitialized(true);
      },
    });

    // The grid is set to autoheight by default which ignores setting the grid height explicity. So what we are doing here is
    // checking if user is NOT on a mobile device and the pagesize selected is 20, then we will explicity set the grid height and
    // turn off autoheight (being set to true always overrides setting a grid height)
    // This will allow the grid to adjust for smaller or larger screens
    grid.on("pagesizechanged", function (event) {
      var args = event.args;
      var autoheight = mobileAndTabletcheck() || args.pagesize < 20;

      grid.jqxGrid({ height: screen.height - 370, autoheight: autoheight });
      grid.jqxGrid("updatebounddata");
    });
  };

  // Open detail view modal
  self.viewRecordDetails = function (record) {
    if (record != undefined) {
      self.isLoading(true);
      dataModel
        .ajaxRequest("CallinRecords/GetRecordForDriver/" + record.id, "GET")
        .done(function (response) {
          if (response != undefined) {
            self.detailsModal(new DriverRecordViewModel(response, self));
          }
          self.isLoading(false);
        });
    }
  };

  // Open new call in modal
  self.openNewCallInModalForm = function () {
    if (self.isPageInitialized() == true && self.isProcessing() == false) {
      self.callInModalForm(new NewCallInRecordViewModel(self));
    }
  };

  // Edit call in record
  self.openEditCallinModal = function (data) {
    if (self.isPageInitialized() == true && self.isProcessing() == false) {
      self.editCallInModalForm(new EditCallInRecordViewModel(data, self));
    }
  };
};

export default { viewModel: DriverCallInRecordsViewModel, template: template };
