//================================================
// ViewModel for sendRateConfirmation Component
// HTML Template: sendRateConfirmation.html

//==============
// Dependencies
import ko from "knockout";
import dataModel from "data-model";
import template from "./rate-confirmation-component.html";
import { formatPhoneNumber } from "global-functions";
import { useDispatch } from "ko-data-store";
import { isLoading } from "../../../dataStore/actions/appUI";
import { showmessage } from "show-dialog-methods";

// Removes <BR/> tags
function replaceBrTags(text) {
  if (text != null) {
    return text.replace(/<br\s*\/?>/gi, " ");
  }
  return text;
}

const fetchFraudCheckAsync = async (payload) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("CarrierContact/CheckContactPassesFraud", "GET", payload)
      .done(resolve)
      .fail((err) =>
        reject(
          (err.responseJSON && err.responseJSON.message) ||
            `An error occurred during request.`
        )
      );
  });
};

//============================
// Types

// Params: stop
class Stops {
  constructor(stop) {
    var self = this;
    self.id = ko.observable(stop.id);
    self.movementSequence = ko.observable(stop.movementSequence);

    self.stopNotes = ko.observableArray(
      ko.utils.arrayMap(stop.stopNotes, function (note) {
        return new StopNote(note);
      })
    );

    // Every stop should have at least 1 entry for extra notes
    self.stopNotes.push(
      new StopNote({ id: null, stopId: stop.id, comment: "" })
    );
  }
}

// Params: the stop note
class StopNote {
  constructor(note) {
    var self = this;
    self.id = ko.observable();
    self.stopId = ko.observable();
    self.comment = ko.observable();

    if (note != undefined) {
      self.id(note.id);
      self.stopId(note.stopId);
      self.comment(note.comment);
    }
  }
}

class ContactFraudResponseModel {
  _initMsg = `Please verify the contact before proceeding.`;

  passes = ko.observable(true);
  message = ko.observable(this._initMsg);
  checked = ko.observable(false);

  constructor({ passes = true, message = undefined } = {}) {
    this.passes(passes);
    this.message(message ?? this._initMsg);
  }
}

//====================
// Component ViewModel
// params: the movementId (int) number
class RateConfirmationViewModel {
  constructor(params) {
    var self = this;
    self.dispatchAction = useDispatch();

    self.movementId = params.data.movementId;
    self.carrierExtraPayRecordId = params.data.carrierExtraPayRecordId;
    self.carrierIdParam = params.data.carrierId;

    self.errors = ko.observableArray();
    self.isLoading = ko.observable(true);
    self.isNewContact = ko.observable(false);
    self.init = ko.observable(false);

    self.contactFraudResponse = ko.observable(
      new ContactFraudResponseModel()
    ); /** ContactFraudResponseModel */

    if (params.data.afterCloseCarrierContact) {
      self.afterCloseCarrierContact = params.data.afterCloseCarrierContact;
    }

    // =================
    // Data

    // Set to true by default
    // We will override if user has rate con preferences set in -> self.setUserRateConPref
    self.optionalFields = {
      includeBOLNumber: ko.observable(true),
      includeCustRefNum: ko.observable(true),
      includeStopNames: ko.observable(true),
      includeStopContacts: ko.observable(true),
      includeStopPhoneNums: ko.observable(true),
      includeStopAddresses: ko.observable(true),
    };

    self.isFax = ko.observable(false);
    self.isEmail = ko.observable(false);

    self.orderId = ko.observable();
    self.emailNote = ko.observable("");
    self.carrierContactId = ko.observable();
    self.contactName = ko.observable();
    self.orderExId = ko.observable();
    self.carrierId = ko.observable(self.carrierIdParam);
    self.carrierExId = ko.observable();
    self.contacts = ko.observableArray([]).extend({ notify: "always" });
    self.stops = ko.observableArray([]);
    self.lineHaulPay = ko.observable("0");
    self.totalPay = ko.observable("0"); // Generated from server as default (can use computed -> lineHaulPay + combinedOCP)
    self.combinedOCP = ko.observable("0");
    self.rateConfirmationNote = ko.observable();
    self.selectedContact = ko.observable().extend({
      required: {
        message:
          "You must select / or enter a contact to Send or Print a rate confirmation",
        onlyIf: function () {
          //if (self.isNewContact()) {  //selection required if not a new contact
          //    return false;
          //}
          return true;
        },
      },
    });
    self.phone = ko.observable(null).extend({ required: true, phoneUS: true });

    const _email = ko.observable(null);
    self.email = ko
      .pureComputed({
        read: _email,
        write: (val) => {
          if (val != _email()) {
            self.contactFraudResponse(new ContactFraudResponseModel());
          }

          _email(val);
        },
      })
      .extend({
        required: {
          message: "Email is required",
          onlyIf: function () {
            if (self.isEmail() == true) {
              return true;
            }
            return false;
          },
        },
      });

    self.fax = ko.observable(null).extend({
      phoneUS: {
        onlyIf: function () {
          if (self.isFax() == true) {
            return true;
          }
        },
      },
      required: {
        message: "Fax is required",
        onlyIf: function () {
          if (self.isFax() == true) {
            return true;
          }
          return false;
        },
      },
    });

    self.contactEmailFocused = ko.observable(false);

    self.isContactEmailChanged = ko.pureComputed(() => {
      const emailVal = self.email();
      let changed = false;

      if (self.selectedContact() == originalSelectedContactId) {
        changed = emailVal != emailOriginalVal;
      }

      if (changed) {
        self.contactFraudResponse(new ContactFraudResponseModel());
      }

      return changed;
    });

    self.displayContactFraudMessage = ko.pureComputed(() => {
      if (self.isNewContact() || self.isContactEmailChanged()) {
        if (!self.email()) return false;

        return (
          self.contactFraudResponse().message() &&
          self.contactFraudResponse().message().length > 0
        );
      }

      return false;
    });

    self.enableSendPrintBtns = ko.pureComputed(() => {
      // Need to make sure have all valid data loaded before allowing btn clicks
      if (
        (self.movementId > 0 === false &&
          self.carrierExtraPayRecordId > 0 === false) ||
        !self.carrierId() ||
        self.stops().length === 0
      ) {
        return false;
      }

      if (self.isNewContact() || self.isContactEmailChanged()) {
        return (
          self.contactFraudResponse().checked() &&
          self.contactFraudResponse().passes()
        );
      }

      return true;
    });

    //========================
    // Behaviors

    self.handleCheckContactFraud = async () => {
      try {
        self.errors.removeAll();
        self.dispatchAction(isLoading(true));
        const response = await fetchFraudCheckAsync({
          carrierId: self.carrierId(),
          movementId: self.movementId,
          emailAddress: self.email(),
        });

        self.dispatchAction(isLoading(false));
        const model = new ContactFraudResponseModel({ ...response });
        model.checked(true);
        self.contactFraudResponse(model);
      } catch (err) {
        self.dispatchAction(isLoading(false));
        console.error(err);
        // @ts-ignore
        self.errors.push(err);
      }
    };

    // Set the user's rate con preferences options
    self.setUserRateConPref = function (rateConOptions) {
      if (rateConOptions != undefined) {
        ko.utils.arrayMap(rateConOptions, function (option) {
          if (option != undefined && option.description != undefined) {
            switch (option.description) {
              case "BOL Number":
                self.optionalFields.includeBOLNumber(option.displayOnReport);
                break;
              case "Pickup Number":
                self.optionalFields.includeCustRefNum(option.displayOnReport);
                break;
              case "Stop Names":
                self.optionalFields.includeStopNames(option.displayOnReport);
                break;
              case "Stop Contacts":
                self.optionalFields.includeStopContacts(option.displayOnReport);
                break;
              case "Stop Phone Numbers":
                self.optionalFields.includeStopPhoneNums(
                  option.displayOnReport
                );
                break;
              case "Stop Addresses":
                self.optionalFields.includeStopAddresses(
                  option.displayOnReport
                );
                break;
            }
          }
        });
      }
    };

    // Close Modal
    self.closeModal = function () {
      self.clearFields();

      if (self.init() === true) {
        $(".modal").modal("hide");
      }
    };

    // New Contact btn behavior
    self.newContactClick = function () {
      if (self.isNewContact()) {
        self.isNewContact(false);
        self.isEmail(false);
      } else {
        self.isNewContact(true);
        self.isEmail(true);
        $("#contactNameInput").focus();
      }

      self.contactFraudResponse(new ContactFraudResponseModel());
      self.clearFields();
    };

    // Clear input fields
    self.clearFields = function () {
      self.selectedContact(null);
      self.phone("");
      self.email("");
      self.fax("");
    };

    // Subscribe to the validation group and clear error messages collection when form is valid
    ko.validation.group(self).subscribe(function (value) {
      if (value == 0) {
        self.errors.removeAll();
      }
    });

    // USED FOR CHANGE TRACKING / RESETTING ORIGINAL VALUES
    var emailOriginalVal = null;
    var faxOriginalVal = null;
    var phoneOriginalVal = null;
    var originalSelectedContactId = null;

    self.isContactInfoChanged = function () {
      return self.selectedContact() == originalSelectedContactId
        ? self.email() != emailOriginalVal ||
            self.phone() != phoneOriginalVal ||
            self.fax() != faxOriginalVal
        : false;
    };

    // Contact Select Behavior
    self.carrierContactSelected = ko.observable();
    self.carrierContactSelected.subscribe(function (value) {
      if (value == undefined) {
        self.clearFields();
      }

      //ignore if this is triggered by us adding a new contact
      if (!self.isNewContact()) {
        if (value) {
          self.contactName(value.name);
          self.phone(formatPhoneNumber(value.phone));
          self.email(value.email);
          self.fax(formatPhoneNumber(value.fax));
          self.selectedContact(value.id);
          self.carrierContactId(value.id);

          // Locals for change tracking
          originalSelectedContactId = value.id;
          emailOriginalVal = value.email;
          faxOriginalVal = formatPhoneNumber(value.fax);
          phoneOriginalVal = formatPhoneNumber(value.phone);
        }
      }
    });

    // User unchecked fax so reset the input if we have an original val.
    self.isFax.subscribe(function (val) {
      if (val == false && self.isContactInfoChanged()) {
        self.fax(faxOriginalVal);
      }
    });

    // User unchecked email so reset the input if we have an original val.
    self.isEmail.subscribe(function (val) {
      if (val == false && self.isContactInfoChanged()) {
        self.email(emailOriginalVal);
      }
    });

    self.selectedContact.subscribe(function (value) {
      if (self.isNewContact()) {
        self.contactName(value);
      }
    });

    //=================================
    // Add New Contact
    self.addNewContact = function (method) {
      if (self.isNewContact()) {
        var newName = self.contactName();

        if (!newName.replace(/\s/g, "")) {
          // remove any whitespace
          self.errors.push({ message: "Contact name must be specified." });
          self.dispatchAction(isLoading(false));
          return false;
        }

        // verify name is unique for this carrier
        for (let i = 0; i < self.contacts().length; i++) {
          if (newName.toLowerCase() === self.contacts()[i].name.toLowerCase()) {
            self.errors.push({ message: "New contact name must be unique." });
            self.dispatchAction(isLoading(false));
            return false;
          }
        }

        self.contactName(newName);

        var newContact = {};
        newContact.Name = self.contactName();
        newContact.Phone = self.phone();
        newContact.Email = self.email() ? self.email().replace(",", ";") : null;
        newContact.Fax = self.fax();

        // Save the new contact
        dataModel
          .ajaxRequest(
            "Carrier/AddCarrierContact/" + self.carrierId(),
            "post",
            newContact
          )
          .done(function (contactId) {
            newContact.Id = contactId;
            self.contacts.push(newContact);
            self.carrierContactId(contactId);

            // Generate the Report
            self.generateRateConfirmation(method);
            self.isNewContact(false);
          })
          .fail(function (error, msg, d) {
            self.errors.push({
              message:
                (error.responseJSON && error.responseJSON.message) ||
                "Unable to save new contact.",
            });
          });
      }
    };

    // Update contact info
    self.updateContactInfo = function (method) {
      if (self.isNewContact() == false) {
        var contactInfo = {};
        contactInfo.Id = self.carrierContactId();
        contactInfo.Name = self.contactName();
        contactInfo.Phone = self.phone();
        contactInfo.Email = self.email()
          ? self.email().replace(",", ";")
          : null;
        contactInfo.Fax = self.fax();

        // Update contact info -> this endpoint will update contact if exists
        dataModel
          .ajaxRequest(
            "Carrier/AddCarrierContact/" + self.carrierId(),
            "post",
            contactInfo
          )
          .done(function (contactId) {
            // Generate the Report
            self.generateRateConfirmation(method);
          })
          .fail(function (error, msg, d) {
            self.errors.push({
              message:
                (error.responseJSON && error.responseJSON.message) ||
                "Unable to save new contact.",
            });
          });
      }
    };

    // Build the Rate Confirmation Report and either send or print
    self.generateRateConfirmation = function (method) {
      var data = {
        contactId: self.carrierContactId(),
        emailNote: self.emailNote(),
        email: self.isEmail() == true ? self.email() : null,
        fax: self.isFax() == true ? self.fax() : null,
        phone: self.phone(),
        notes: [],
        carrierContactId: self.carrierContactId(),
        contactName: self.contactName(),
        movementId: self.movementId,
        orderExId: self.orderExId(),
        orderId: self.orderId(),
        carrierId: self.carrierId(),
        carrierExId: self.carrierExId(),
        options: ko.toJS(self.optionalFields),
        includeBOLNumber: self.optionalFields.includeBOLNumber(),
        includeCustRefNum: self.optionalFields.includeCustRefNum(),
        includeStopNames: self.optionalFields.includeStopNames(),
        includeStopContacts: self.optionalFields.includeStopContacts(),
        includeStopPhoneNums: self.optionalFields.includeStopPhoneNums(),
        includeStopAddresses: self.optionalFields.includeStopAddresses(),
        rateConfirmationNote: self.rateConfirmationNote(),
      };

      // Unwrap the stop notes and only add/send notes that are not empty
      for (var i = 0; i < self.stops().length; i++) {
        var stopNotes = ko.toJS(self.stops()[i].stopNotes());

        if (stopNotes.length > 0) {
          for (var x = 0; x < stopNotes.length; x++) {
            var note = stopNotes[x];

            if (note != undefined && note.comment != "") {
              data.notes.push({
                comment: note.comment,
                id: note.id,
                stopId: note.stopId,
              });
            }
          }
        }
      }

      // send or print based on method
      if (method.toLowerCase() === "send") {
        //------------------------
        // Send via email or fax
        self.dispatchAction(isLoading(true));

        dataModel
          .ajaxRequest("Report/SendRateConfirmationReport", "POST", data)
          .done(function (data, status, xhr) {
            self.dispatchAction(isLoading(false));
            if (self.afterCloseCarrierContact) {
              self.afterCloseCarrierContact({
                carrierContactId: self.carrierContactId(),
                name: self.contactName(),
                email: self.email(),
                fax: self.fax(),
                phone: self.phone(),
              });
            }
            self.closeModal();
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            if (
              jqXHR.responseJSON &&
              jqXHR.responseJSON.message &&
              jqXHR.responseJSON.message.indexOf("EXCEPTION") == -1
            ) {
              var response = jqXHR.responseJSON.message;
              self.errors.push({ message: response });
            } else {
              self.errors.push({
                message:
                  "Something went wrong when attempting to send the confirmation through the email server. Please select the print option to download the document and send via other means.",
              });
            }
            self.dispatchAction(isLoading(false));
          });
      } else {
        //-------------------------
        // print / preview method
        self.dispatchAction(isLoading(true));

        dataModel
          .downloadFile(
            "Report/GetRateConfirmationReportPDF",
            "POST",
            data,
            "RateConfirmation.pdf"
          )
          .then(function () {
            self.dispatchAction(isLoading(false));
            if (self.afterCloseCarrierContact) {
              self.afterCloseCarrierContact({
                carrierContactId: self.carrierContactId(),
                name: self.contactName(),
                email: self.email(),
                fax: self.fax(),
                phone: self.phone(),
              });
            }
            self.closeModal();
          });
      }
    };

    // Send Rate Confirmation
    self.sendRateConfirmation = function () {
      // Verify data has finished loading
      if (self.isLoading() == false) {
        self.errors.removeAll();

        var validationErrors = ko.validation.group(self);

        if (validationErrors().length > 0) {
          self.errors.push({
            message: "Please correct any errors before submitting the request.",
          });
          validationErrors.showAllMessages();
          return false;
        }

        if (self.isEmail() == false && self.isFax() == false) {
          self.errors.push({
            message:
              "Please enter a e-mail or fax number to send the request to.",
          });
          return false;
        }

        if (self.isNewContact()) {
          self.addNewContact("send");
        } else if (
          self.isContactInfoChanged() &&
          self.isNewContact() == false
        ) {
          self.updateContactInfo("send");
        } else {
          self.generateRateConfirmation("send");
        }
      }
    };

    // Preview - Print Rate Confirmation
    self.printRateConfirmation = function () {
      // Verify data has finished loading
      if (self.isLoading() == false) {
        self.errors.removeAll();

        var validationErrors = ko.validation.group(self);

        if (validationErrors().length > 0) {
          self.errors.push({
            message: "Please correct any errors before submitting the request.",
          });
          validationErrors.showAllMessages();
          return false;
        }

        if (self.isNewContact()) {
          self.addNewContact("print");
        } else if (
          self.isContactInfoChanged() &&
          self.isNewContact() == false
        ) {
          self.updateContactInfo("print");
        } else {
          self.generateRateConfirmation("print");
        }
      }
    };

    // Make sure component is loaded fully
    ((vm) => {
      if (vm.movementId) {
        // Get Order Details Data
        dataModel
          .ajaxRequest(
            "Report/RateConfirmationDetails",
            "get",
            { movementId: vm.movementId, carrierId: vm.carrierId() },
            true
          )
          .done(function (data) {
            vm.isLoading(false);

            var order = data.order;

            // SET DATA
            vm.contacts(order.contacts);
            vm.orderExId(order.orderExId);
            vm.orderId(order.orderId);

            vm.carrierExId(order.carrierExId);
            vm.lineHaulPay(order.lineHaulPay);

            vm.combinedOCP(data.combinedOCP);
            vm.totalPay(data.totalPay);
            vm.rateConfirmationNote(replaceBrTags(data.rateConNote)); // Remove any <br/> tags

            // Set the user's rate confirmation preference options
            vm.setUserRateConPref(data.rateConOptions);

            // Add the Order # to the Modal Subheader Section
            $("div.modal-header").append(
              "<h4 style='position:absolute; top: 6px; right: 50px;' id='modelSubHeaderText'><strong>Order Number: " +
                order.orderExId +
                "</strong></h4>"
            );

            vm.stops(
              ko.utils.arrayMap(order.stops, function (stop) {
                return new Stops(stop);
              })
            );

            vm.init(true);
          })
          .fail(function (error, msg, d) {
            var response = error.responseJSON;
            vm.errors.push({ message: response.message });
            vm.isLoading(false);
            showmessage(
              response.message ||
                `Error occurred requesting order details.  If this issue persists, please contact the GWTM Helpdesk.`
            );
            vm.init(true);
          });
      } else if (vm.carrierExtraPayRecordId) {
        dataModel
          .ajaxRequest(
            "Report/GetRCDetailsForExtraPay/" + vm.carrierExtraPayRecordId,
            "get"
          )
          .done(function (data) {
            vm.isLoading(false);

            vm.movementId = data.order.movementId;
            vm.contacts(data.order.contacts);
            vm.orderExId(data.order.orderExId);
            vm.orderId(data.order.orderId);
            vm.carrierId(data.order.carrierId);
            vm.carrierExId(data.order.carrierExId);
            vm.lineHaulPay(data.order.lineHaulPay);

            vm.combinedOCP(data.combinedOCP);
            vm.totalPay(data.totalPay);
            vm.rateConfirmationNote(replaceBrTags(data.rateConNote));

            vm.setUserRateConPref(data.rateConOptions);

            $("div.modal-header").append(
              "<h4 style='position:absolute; top: 6px; right: 50px;' id='modelSubHeaderText'><strong>Order Number: " +
                data.order.orderExId +
                "</strong></h4>"
            );

            vm.stops(
              ko.utils.arrayMap(data.order.stops, function (stop) {
                return new Stops(stop);
              })
            );

            vm.init(true);
          })
          .fail(function (error, msg, d) {
            var response = error.responseJSON;
            vm.errors.push({ message: response.message });
            vm.isLoading(false);
            showmessage(
              response.message ||
                `Error occurred requesting order details.  If this issue persists, please contact the GWTM Helpdesk.`
            );
            vm.init(true);
          });
      }
    })(self);
  }
} // END VIEWMODEL

export default { viewModel: RateConfirmationViewModel, template: template };
