import { scrollToRequiredField } from "global-functions";
import dataModel from "data-model";

class PriorityAlertEntryViewModel {
  constructor(params) {
    params = params || {};
    var alertData = params.vm();
    $("#priorityAlertEntry").keypress(function (e) {
      e.stopPropagation();
    });
    var self = this;
    self.isLoading = ko.observable(false);
    self.errors = ko.observableArray();

    self.id = ko.observable(alertData.id);
    self.expire = ko.observable(alertData.expire);
    self.subject = ko.observable(alertData.subject).extend({ required: true });
    self.messageBody = ko.observable(alertData.messageBody);

    self.isNew = ko.pureComputed(function () {
      return self.id() > 0 == false;
    });

    self.submit = function () {
      self.errors.removeAll();

      var validationErrors = ko.validation.group(self);
      if (validationErrors().length > 0) {
        validationErrors.showAllMessages();
        scrollToRequiredField();
        return false;
      }

      var data = ko.toJS(self);

      if (data.messageBody != undefined) {
        var message = $("#messageBody1")
          .siblings("iframe")
          .first()
          .contents()
          .find("body")
          .html();

        //GW Stylesheet overrides default ul markup
        var messageFormatForList = message.replace(
          "<ul>",
          '<ul style="padding-left:25px; margin-left:10px; list-style-type: circle !important">'
        );
        data.messageBody = messageFormatForList;
      }

      self.isLoading(true);
      dataModel
        .ajaxRequest("Alerts/UpdatePriorityAlert", "POST", data)

        .done(function (result, status, xhr) {
          self.isLoading(false);
          if (alertData.onAlertSave != undefined) {
            alertData.onAlertSave();
          } else {
            params.vm(undefined);
          }

          window.dispatchEvent(new Event("display-priority-alerts"));
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.responseJSON != null) {
            self.errors.push(jqXHR.responseJSON.message);
          } else {
            self.errors.push("An unknown error occurred");
          }
          self.isLoading(false);
        });
    };

    self.cancel = function () {
      params.vm(undefined);
    };

    self.expireNow = function () {
      self.errors.removeAll();

      self.isLoading(true);
      dataModel
        .ajaxRequest("Alerts/ExpireAlert/" + alertData.id, "POST", {})
        .done(function (result, status, xhr) {
          self.isLoading(false);

          if (alertData.onAlertSave != undefined) {
            alertData.onAlertSave();
          } else {
            params.vm(undefined);
          }
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.responseJSON != null) {
            self.errors.push(jqXHR.responseJSON.message);
          } else {
            self.errors.push("An unknown error occurred");
          }
          self.isLoading(false);
        });
    };
  }
}

import template from "./priority-alert-entry-component.html";
export default { viewModel: PriorityAlertEntryViewModel, template: template };
