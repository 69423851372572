import dataModel from "data-model";
import userProfile from "user-profile";
import router from "router";
import * as ebCommon from "../externalBoardCommon";
import {
  setBtnDisabledState,
  setBtnEnabledState,
  datetimeUTC,
  Base64,
  GenerateGuid,
  noop,
} from "global-functions";
import { useDispatch } from "ko-data-store";
import {
  isLoading,
  getTrailerPostingTypes,
} from "../../../../dataStore/actions/appUI";
import broadcastAPI, { commonChannels } from "../../../../utils/broadcastAPI";
import storageManager from "../../../../utils/storageManager";
import { useState } from "data-store";
import { UpdateNamedSearch } from "../externalBoardCommon";
import { showconfirm } from "show-dialog-methods";

const getLoadDetails = async (loadId) =>
  await fetchLoadDetails(loadId).catch(() => {});
const fetchLoadDetails = (loadId) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("ExternalBoard/MatchLoadDetails", "GET", { loadId })
      .done((response) => resolve(response))
      .fail(() => reject(null));
  });
};

const getValidationLocationSearchErrors = async (payload) =>
  await fetchValidationLocationSearchErrors(payload).catch((msg) => msg);
const fetchValidationLocationSearchErrors = (payload) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("ExternalBoard/VerifyLocation", "POST", payload)
      .done(() => resolve(null))
      .fail((err) =>
        reject((err.responseJSON && err.responseJSON.message) || null)
      );
  });
};

const getTractorDetails = async (tractorId) =>
  await fetchTractorDetails(tractorId).catch(() => {});
const fetchTractorDetails = (tractorId) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("ExternalBoard/MatchTractorDetails", "GET", { tractorId })
      .done((response) => resolve(response))
      .fail(() => reject(null));
  });
};

const getOrderDetails = async (orderId) => {
  try {
    const { stops } = await fetchOrderDetails(orderId);
    const { city, state, zip } = stops[0];

    return {
      originZip: zip,
      originCity: city,
      originState: state,
    };
  } catch (err) {
    return {};
  }
};
const fetchOrderDetails = (orderId) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("OrderPlanning/GetOrderDetails/" + orderId, "GET")
      .done((response) => resolve(response))
      .fail(() => reject(null));
  });
};

const setForm = (formData = {}) => {
  return (newData = {}) => {
    Object.keys(formData).map((key) => {
      try {
        if (
          newData[key] &&
          ko.isWritableObservable(formData[key]) &&
          ko.isWritableObservable(newData[key])
        ) {
          formData[key](newData[key]());
        } else if (newData[key] && ko.isWritableObservable(formData[key])) {
          formData[key](newData[key]);
        }
      } catch (err) {}
    });
  };
};

const addDaysToDate = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const sendSaveNamedSearch = (payload) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("ExternalBoard/SaveSearchTemplate", "POST", payload)
      .done(() => resolve(true))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const saveNamedSearch = async ({ name, search }) =>
  await sendSaveNamedSearch({
    name,
    search,
  }).catch(() => null);

const mapFormModelToSaveSearchModel = ({
  searchType = undefined,
  boards = [],
  id = undefined,
  trailerType = undefined,
  trailerTypeId = undefined,
  equipmentClass = [],
  originType = undefined,
  origin = undefined,
  originRadius = undefined,
  originRegions = [],
  originStateProvince = [],
  destinationType = undefined,
  destination = undefined,
  destinationRadius = undefined,
  destinationRegions = [],
  destinationStateProvince = [],
  pickupFrom = undefined,
  pickupTo = undefined,
  saveSearchName = undefined,
  includeHazmat = undefined,
  includeTeams = undefined,
  weight = undefined,
  length = undefined,
}) => {
  try {
    return {
      name: saveSearchName,
      id: id,
      search: JSON.stringify({
        searchType,
        boards,
        id,
        trailerType,
        trailerTypeId,
        equipmentClass,
        originType,
        origin,
        originRadius,
        originRegions,
        originStateProvince,
        destinationType,
        destination,
        destinationRadius,
        destinationRegions,
        destinationStateProvince,
        pickupFrom,
        pickupTo,
        includeHazmat,
        includeTeams,
        weight,
        length,
      }),
    };
  } catch (e) {
    return {
      name: undefined,
      search: undefined,
    };
  }
};

const mapMatchToSearchModel = ({
  originCity,
  originState,
  originZip,
  destinationCity,
  destinationState,
  destinationZip,
  trailerType,
  trailerTypeDescription,
  boards,
  searchType,
  equipmentClass,
}) => ({
  searchType: searchType,
  boards: boards,
  trailerType: trailerType,
  trailerTypeDescription: trailerTypeDescription,
  originType: "location",
  origin:
    originZip && originCity && originState
      ? `${originZip} (${originCity}, ${originState})`
      : originCity && originState
      ? `${originCity}, ${originState}`
      : null,
  originRadius: 50,
  destinationType: "location",
  destination:
    destinationZip && destinationCity && destinationState
      ? `${destinationZip} (${destinationCity}, ${destinationState})`
      : destinationCity && destinationState
      ? `${destinationCity}, ${destinationState}`
      : null,
  destinationRadius: 50,
  pickupFrom: datetimeUTC(new Date()),
  pickupTo: undefined,
  equipmentClass: equipmentClass || [],
});

// Get url params
const getUrlParams = (queryInfo = {}) => {
  return Object.keys(queryInfo).reduce((params, key) => {
    params[key] = queryInfo[key];
    return params;
  }, {});
};

const presentationalModel = function (formModel = {}, userBoards = []) {
  const dispatch = useDispatch();
  const externalBoardDefaultSettingsStorageKey = "externalBoardDefaultSettings";

  const getExternalBoardDefaultSettings = (key = "") => {
    try {
      const settings = window.localStorage.getItem(key);
      return JSON.parse(Base64.decode(settings)) || {};
    } catch (e) {
      return {};
    }
  };

  const setExternalBoardDefaultSettings = (
    key = externalBoardDefaultSettingsStorageKey,
    settings = {}
  ) => {
    try {
      const data = Base64.encode(JSON.stringify(settings));
      window.localStorage.setItem(key, data);
      return true;
    } catch (e) {
      return false;
    }
  };

  this.errorMsg = ko.observable();
  dispatch(getTrailerPostingTypes()).then(({ appUI }) => {
    const { trailerPostingTypes } = appUI;

    this.trailerPostingTypes(
      trailerPostingTypes.map((x) => ({ label: x.description, value: x.id }))
    );
    this.selectedTrailerPostingType(
      getExternalBoardDefaultSettings(externalBoardDefaultSettingsStorageKey)[
        "trailerPostingType"
      ]
    );
  });

  // Currently used by driver role only.
  this.trailerPostingTypes = ko.observableArray([]);
  this.selectedTrailerPostingType = ko.observable().extend({ required: true });
  this.selectedTrailerPostingType.subscribe((id) => {
    const defaultSettings = getExternalBoardDefaultSettings(
      externalBoardDefaultSettingsStorageKey
    ); // making local so always pull latest data
    if (id && id == defaultSettings["trailerPostingType"]) {
      this.isDefaultTrailerPostingType(true);

      const type = this.trailerPostingTypes().find((x) => x.value == id) || {};

      formModel.trailerPostingTypeId(id);
      formModel.trailerTypeDescription(type.label);
    } else if (id && id != defaultSettings["trailerPostingType"]) {
      const type = this.trailerPostingTypes().find((x) => x.value == id) || {};

      this.isDefaultTrailerPostingType(false);
      formModel.trailerPostingTypeId(id);
      formModel.trailerTypeDescription(type.label);
    } else {
      this.isDefaultTrailerPostingType(false);
      formModel.trailerPostingTypeId(undefined);
      formModel.trailerTypeDescription(undefined);
    }
  });

  this.isDefaultTrailerPostingType = ko.observable(false);
  this.isDefaultTrailerPostingType.subscribe((val) => {
    const defaultSettings = getExternalBoardDefaultSettings(
      externalBoardDefaultSettingsStorageKey
    ); // making local so always pull latest data
    const selectedPostingType = this.selectedTrailerPostingType();

    if (val && selectedPostingType != defaultSettings["trailerPostingType"]) {
      setExternalBoardDefaultSettings(externalBoardDefaultSettingsStorageKey, {
        ...defaultSettings,
        trailerPostingType: selectedPostingType,
      });
    } else if (
      !val &&
      selectedPostingType == defaultSettings["trailerPostingType"]
    ) {
      setExternalBoardDefaultSettings(externalBoardDefaultSettingsStorageKey, {
        ...defaultSettings,
        trailerPostingType: null,
      });
    }
  });

  this.selectedTrailerType = ko.observable().extend({ notify: "always" });
  this.selectedTrailerType.subscribe((val = {}) => {
    formModel.trailerTypeDescription(val.description);
  });

  this.boardOptions = ko
    .observableArray(userBoards)
    .extend({ notify: "always" });

  // Expanded lable is how it is set in the db (expandedtrailerclasses)
  this.equipmentClasses = ko
    .observableArray([
      {
        label: "Containers",
        value: "Containers",
        checked: false,
        expandedLabel: "Container",
      },
      {
        label: "Other Equipment",
        value: "OtherEquipment",
        checked: false,
        expandedLabel: "Other",
      },
      {
        label: "Decks Specialized",
        value: "DecksSpecialized",
        checked: false,
        expandedLabel: "Decks, Specialized",
      },
      {
        label: "Decks Standard",
        value: "DecksStandard",
        checked: false,
        expandedLabel: "Decks, Standard",
      },
      {
        label: "Dry Bulk",
        value: "DryBulk",
        checked: false,
        expandedLabel: "Other",
      },
      {
        label: "Flatbeds",
        value: "Flatbeds",
        checked: false,
        expandedLabel: "Flatbeds",
      },
      {
        label: "Hazardous Materials",
        value: "HazardousMaterials",
        checked: false,
        expandedLabel: "Hazmat",
      },
      {
        label: "Reefers",
        value: "Reefers",
        checked: false,
        expandedLabel: "Reefer",
      },
      {
        label: "Tankers",
        value: "Tankers",
        checked: false,
        expandedLabel: "Other",
      },
      {
        label: "Vans Specialized",
        value: "VansSpecialized",
        checked: false,
        expandedLabel: "Vans, Specialized",
      },
      {
        label: "Vans Standard",
        value: "VansStandard",
        checked: false,
        expandedLabel: "Vans, Standard",
      },
    ])
    .extend({ notify: "always" });

  this.equipmentClasses.subscribe((val = []) =>
    formModel.equipmentClass(val.filter((x) => x.checked).map((x) => x.value))
  );
  formModel.equipmentClass.subscribe((val = []) => {
    if (val.length == 0)
      this.equipmentClasses().map((x) => ({ ...x, checked: false }));
  });

  this.displayEquipmentClasses = ko.pureComputed(() =>
    formModel.boards().some((x) => x == "DAT")
  );
  this.displayTruckstopPickupDateMsg = ko.pureComputed(() =>
    formModel.boards().some((x) => x == "TRUCKSTOP")
  );

  // reset on when type is changed
  formModel.originType.subscribe((val) => {
    formModel.origin(undefined);
    formModel.originRadius(undefined);
    formModel.originRegions([]);
    formModel.originStateProvince([]);
  });

  formModel.destinationType.subscribe((val) => {
    formModel.destination(undefined);
    formModel.destinationRadius(undefined);
    formModel.destinationRegions([]);
    formModel.destinationStateProvince([]);
  });

  this.resetDATEquipmentOptions = () => {
    this.equipmentClasses(
      this.equipmentClasses().map((x) => ({ ...x, checked: false }))
    );
  };

  const populateFields = () => {
    const rawModel = ko.toJS(formModel);
    if (rawModel.equipmentClass.length) {
      const mappedDat = this.equipmentClasses().map((x) => {
        if (rawModel.equipmentClass.indexOf(x["value"]) > -1) {
          x.checked = true;
        }
        return x;
      });

      this.equipmentClasses(mappedDat);
    }
  };

  populateFields();
};

const ExternalBoardSearchViewModel = function ({
  userBoards, //ko.observableArray
  activeSearches,
  recentSearches,
  savedSearches,
  activeSearchTabIndex,
  mobileModalId,
  useRecentSavedTemplate,
  resetFormObserver = ko.observable(),
  refreshActiveSearches = noop,
}) {
  const vm = this;
  const urlParams = getUrlParams(router.currentRoute()["?query"]);
  const dispatch = useDispatch();
  const [windowTabName, setWindowTab] = useState(GenerateGuid(6));

  vm.isUserDriver = ko.observable(userProfile.isUserDriver);
  vm.useRecentSavedTemplate = useRecentSavedTemplate;
  const $searchMobileModal = $("#mobileModalId");
  vm.form = new ebCommon.SearchForm(
    useRecentSavedTemplate() || { boards: userBoards() }
  );
  const updateForm = setForm(vm.form);

  vm.presentational = new presentationalModel(vm.form, userBoards() || []);

  // resetting properties here so jqx subscribables can update (check/uncheck widgets, etc)
  vm.handleResetForm = () => {
    vm.presentational.errorMsg("");
    updateForm(new ebCommon.SearchForm({ boards: userBoards() }));
    vm.presentational.resetDATEquipmentOptions();

    useRecentSavedTemplate(null);
  };

  resetFormObserver.subscribe((reset) => reset && vm.handleResetForm());

  vm.handleSearch = async ($context, event) => {
    vm.presentational.errorMsg("");

    const error = vm.form.validate();

    if (error) {
      vm.presentational.errorMsg(error);
      //toast.error({message: `Search: `+ error, duration: 8000});
      window.scrollTo({ top: 0 });
      return false;
    }

    // Trailer posting type id currently is for driver role's only.
    if (userProfile.isUserDriver && !vm.form.trailerPostingTypeId()) {
      vm.presentational.errorMsg(`Trailer type is required.`);
      window.scrollTo({ top: 0 });
      return false;
    }

    const payload = ko.toJS(vm.form);

    const locationErrors = await getValidationLocationSearchErrors(payload);
    if (locationErrors) {
      vm.presentational.errorMsg(locationErrors);
      window.scrollTo({ top: 0 });
      return false;
    }

    payload.originRadius = payload.originRadius ?? 0;
    payload.destinationRadius = payload.destinationRadius ?? 0;

    if (useRecentSavedTemplate() && useRecentSavedTemplate().id) {
      let currentActiveSearchId = useRecentSavedTemplate().id;

      // DO UPDATE
      const filtered = recentSearches().filter(
        (x) => x.id !== currentActiveSearchId
      );
      recentSearches(
        ebCommon.mapToSearchListing([
          ...filtered,
          {
            ...payload,
            id: currentActiveSearchId,
            userId: userProfile.userId,
            cacheExpireDate: addDaysToDate(new Date(), 7).getTime(),
          },
        ])
      );

      const savedFiltered = savedSearches().filter(
        (x) => x.id !== currentActiveSearchId
      );

      if (payload.saveSearchName) {
        const mapping = mapFormModelToSaveSearchModel({
          ...payload,
          id: currentActiveSearchId,
        });

        try {
          // TODO: make an update api method
          const { activeSearchId, savedNameSearchId } =
            await dataModel.ajaxRequest(
              "ExternalBoard/GetExistingRecordIds",
              "GET",
              { name: payload.saveSearchName },
              true
            );
          if (activeSearchId || savedNameSearchId) {
            // Ask user if they want to save to this record...

            //if yes
            //TODO: make api call with pop up modal
            const yes = await showconfirm(
              `Are you sure you want to overwrite previously saved search?`
            );
            if (yes) {
              currentActiveSearchId = activeSearchId;
            } else {
              return;
            }
          }

          const templateId = await UpdateNamedSearch(mapping);
          savedFiltered.push({ ...payload, id: templateId });
        } catch (ex) {
          vm.presentational.errorMsg(ex);
          return;
        }
      }

      savedSearches(ebCommon.mapToSearchListing([...savedFiltered]));

      // TODO: call api update method
      try {
        await ebCommon.UpdateActiveSearch(currentActiveSearchId, {
          ...payload,
          searchName: payload.saveSearchName,
        });
      } catch (err) {
        vm.presentational.errorMsg(err);
        return;
      }

      const index = activeSearches().findIndex(
        (x) => x.id === currentActiveSearchId
      );
      if (index > -1) {
        const locationStrings = ebCommon.pipe(
          ebCommon.getOriginDestinationFromSearch,
          ebCommon.getSearchTabNames
        )(payload);
        const updatedSearch = {
          ...payload,
          id: currentActiveSearchId,
          locationStrings: locationStrings,
          searchName: payload.saveSearchName,
          selectedItemTabInfo: ko.observable(),
        };
        activeSearches.splice(index, 1);
        activeSearches.unshift(updatedSearch);
        activeSearchTabIndex(0);
      }
    } else {
      await search(payload, true);
    }

    if (ebCommon.isMobileOrTablet() == false) {
      window.scrollTo({ top: 0 });
    }

    $searchMobileModal.modal("hide");
  };

  const search = async (data, resetForm = false, isMatchMyTruck = false) => {
    recentSearches(
      ebCommon.mapToSearchListing([
        ...recentSearches(),
        {
          ...data,
          userId: userProfile.userId,
          cacheExpireDate: addDaysToDate(new Date(), 7).getTime(),
        },
      ])
    );

    if (data.saveSearchName && data.id > 0 == false) {
      const mapping = mapFormModelToSaveSearchModel(data);
      const searchId = await saveNamedSearch(mapping);

      savedSearches(
        ebCommon.mapToSearchListing([
          ...savedSearches(),
          { id: searchId, ...data },
        ])
      );
    }

    if (ebCommon.isMobileOrTablet() == false) {
      window.scrollTo({ top: 0 });
    }

    // Need id so can 'delete' tabs when opened
    const activeSearchId = await ebCommon
      .saveActiveSearch(data)
      .catch(() => setBtnEnabledState(event, "Search"));
    // location strings are used for concat + displaying the tab titles, etc
    const locationStrings = ebCommon.pipe(
      ebCommon.getOriginDestinationFromSearch,
      ebCommon.getSearchTabNames
    )(data);
    activeSearches.unshift({
      ...data,
      id: activeSearchId,
      locationStrings: locationStrings,
      searchName: data.saveSearchName,
      isMatchMyTruck,
      selectedItemTabInfo: ko.observable(),
    });
    activeSearchTabIndex(0);

    if (resetForm) {
      vm.handleResetForm();
    }

    $searchMobileModal.modal("hide");
  };

  const matchLoad = async (loadid) => {
    if (loadid > 0) {
      dispatch(isLoading(true));
      const { load, datEquipmentClass } = await getLoadDetails(loadid);
      dispatch(isLoading(false));
      if (load) {
        // Dat requires equipment class for searches.
        // Matches by the loads selected trailertype in api, if none, then pass all classes and let user sort/filter after.
        if (datEquipmentClass && datEquipmentClass.length) {
          load.equipmentClass = vm.presentational
            .equipmentClasses()
            .filter((x) =>
              datEquipmentClass.some((y) => y.indexOf(x.expandedLabel) > -1)
            )
            .map((x) => x.value);
        } else {
          load.equipmentClass = vm.presentational
            .equipmentClasses()
            .map((x) => x.value);
        }

        const data = {
          ...load,
          originCity: load.originCity,
          originState: load.originState,
          originZip: load.originZip,
          destinationCity: null,
          destinationState: null,
          destinationZip: null,
        };
        const payload = mapMatchToSearchModel({
          ...data,
          boards: userBoards(),
          searchType: "T",
        }); // match load to tractors -> 'T'

        search(payload);
      }
    }
  };

  const matchTruck = async (tractorid) => {
    if (tractorid > 0) {
      dispatch(isLoading(true));
      const { tractor, datEquipmentClass } = await getTractorDetails(tractorid);
      dispatch(isLoading(false));

      if (tractor) {
        if (userBoards().some((x) => x == "DAT")) {
          // Dat requires equipment class for searches.
          // Matches by tractor posting type in api, if none, then pass all classes and let user sort/filter after.
          if (datEquipmentClass && datEquipmentClass.length) {
            tractor.equipmentClass = vm.presentational
              .equipmentClasses()
              .filter((x) =>
                datEquipmentClass.some((y) => y.indexOf(x.expandedLabel) > -1)
              )
              .map((x) => x.value);
          } else {
            tractor.equipmentClass = vm.presentational
              .equipmentClasses()
              .map((x) => x.value);
          }
        }

        const data = {
          ...tractor,
          originCity: tractor.destinationCity,
          originState: tractor.destinationState,
          originZip: tractor.destinationZip,
          destinationCity: null,
          destinationState: null,
          destinationZip: null,
        };
        const payload = mapMatchToSearchModel({
          ...data,
          boards: userBoards(),
          searchType: "L",
        }); // match load to tractors -> 'L'

        search(payload, false, true);
      }
    }
  };

  const matchOrder = async (orderId) => {
    if (orderId > 0) {
      dispatch(isLoading(true));
      const order = await getOrderDetails(orderId);

      dispatch(isLoading(false));
      if (order) {
        order.equipmentClass = vm.presentational
          .equipmentClasses()
          .map((x) => x.value);

        const data = {
          ...order,
          originCity: order.originCity,
          originState: order.originState,
          originZip: order.originZip,
          destinationCity: null,
          destinationState: null,
          destinationZip: null,
        };
        const payload = mapMatchToSearchModel({
          ...data,
          boards: userBoards(),
          searchType: "T",
        }); // match load to tractors -> 'T'

        search(payload);
      }
    }
  };

  const setWindowTabName = (name = "") => {
    window.name = name;
    const tabNames = storageManager.get("loadboardTabNames") || [];

    storageManager.set("loadboardTabNames", [...tabNames, name]);

    return name;
  };

  const init = async () => {
    if (urlParams.loadid) {
      await matchLoad(urlParams.loadid);
      broadcastAPI.messageTo(
        commonChannels.loadboardMatchChannel,
        windowTabName()
      );
    } else if (urlParams.tractorid) {
      await matchTruck(urlParams.tractorid);
      broadcastAPI.messageTo(
        commonChannels.loadboardMatchChannel,
        windowTabName()
      );
    } else if (urlParams.orderid) {
      await matchOrder(urlParams.orderid);
      broadcastAPI.messageTo(
        commonChannels.loadboardMatchChannel,
        windowTabName()
      );
    }

    const onMessage = broadcastAPI.listenOn(
      commonChannels.loadboardMatchChannel
    );
    onMessage((sendingWindowTabName) => {
      if (sendingWindowTabName != windowTabName()) {
        refreshActiveSearches();
      }
    });

    // on refresh or close remove the tab name from storage list
    // when page loads a new tabname will be set
    window.addEventListener("beforeunload", () => {
      const tabNames = storageManager.get("loadboardTabNames") || [];
      const activetabs = tabNames.filter((x) => x != windowTabName());

      if (activetabs.length) {
        storageManager.set("loadboardTabNames", activetabs);
      } else {
        storageManager.remove("loadboardTabNames");
      }
    });

    setWindowTabName(windowTabName());
  };

  // on init we don't have any boards yet, so when we do then
  // set the form boards again.
  userBoards.subscribe((val = []) => {
    if (userProfile.isUserDriver == false) {
      vm.form.boards(vm.form.boards().length ? vm.form.boards() : val);
      vm.presentational.boardOptions(vm.form.boards());
    }
    init();
  });
};

import template from "./external-board-search-component.html";
export default { viewModel: ExternalBoardSearchViewModel, template: template };
