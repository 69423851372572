import dataModel from "data-model";
import { isEmpty, createDateTimeFilterWidget, formatPhoneNumber } from "global-functions";
import userProfile from "user-profile";
import gridState from "jqx.grid-state-component";
import dayjs from "dayjs";
import gridUtils from "jqx.grid-utils";

class ExternalBoardPageViewModel {
    constructor() {
        var self = this;
        self.hasRMISAccess = ko.observable(false);
        self.details = ko.observable("Can't find your carrier? Click here to search RMIS for pre-approval.");
        self.searchURL = ko.observable("https://evans-westcarriers.rmissecure.com/_s/reg/GeneralRequirementsV2.aspx");
        self.isLoading = ko.observable(false);
        self.errors = ko.observableArray();

        self.originType = ko.observable("location");
        self.destinationType = ko.observable("location");

        self.searchType = ko.observable("Loads");
        self.dat = ko.observable(true);
        self.truckStop = ko.observable(true);

        self.trailerType = ko.observable();
        self.pickUpFrom = ko.observable();
        self.pickUpTo = ko.observable();

        self.shipment = ko.observable("Both");
        self.weight = ko.observable();
        self.length = ko.observable();
        self.matchSpecificDates = ko.observable(false);
        self.equipmentClasses = ko
            .observableArray([
                { label: "Containers", value: "Containers", checked: false },
                { label: "Other Equipment", value: "OtherEquipment", checked: false },
                { label: "Decks Specialized", value: "DecksSpecialized", checked: false },
                { label: "Decks Standard", value: "DecksStandard", checked: false },
                { label: "Dry Bulk", value: "DryBulk", checked: false },
                { label: "Flatbeds", value: "Flatbeds", checked: false },
                { label: "Hazardous Materials", value: "HazardousMaterials", checked: false },
                { label: "Reefers", value: "Reefers", checked: false },
                { label: "Tankers", value: "Tankers", checked: false },
                { label: "Vans Specialized", value: "VansSpecialized", checked: false },
                { label: "Vans Standard", value: "VansStandard", checked: false },
            ])
            .extend({
                required: {
                    onlyIf: function () {
                        if (self.dat() == true) {
                            return true;
                        }
                        return false;
                    },
                },
            });
        self.equipmentClass = ko.computed(function () {
            return ko.utils.arrayMap(
                ko.utils.arrayFilter(self.equipmentClasses(), function (item) {
                    return item.checked;
                }),
                function (item) {
                    return item.value;
                }
            );
        });
        self.equipmentClasses.extend({
            validation: {
                message: "Please select at least one equipment class",
                validator: function () {
                    if (ko.unwrap(self.equipmentClass).length == 0 && self.dat() == true) {
                        return false;
                    }
                    return true;
                },
            },
        });

        self.origin = ko.observable();
        self.destination = ko.observable();
        self.originRegions = ko.observableArray();
        self.originStateProvince = ko.observableArray();
        self.destinationRegions = ko.observableArray();
        self.destinationStateProvince = ko.observableArray();

        self.origin.extend({
            required: {
                onlyIf: function () {
                    if (self.originType() == "location") {
                        if (self.destinationType() == "location" && self.destination() == null) {
                            return true;
                        } else if (self.destinationType() == "region" && self.destinationRegions().length == 0) {
                            return true;
                        } else if (self.destinationType() == "stateProvince" && self.destinationStateProvince().length == 0) {
                            return true;
                        }
                    }
                    return false;
                },
            },
        });
        self.destination.extend({
            required: {
                onlyIf: function () {
                    if (self.destinationType() == "location") {
                        if (self.originType() == "location" && self.origin() == null) {
                            return true;
                        } else if (self.originType() == "region" && self.originRegions().length == 0) {
                            return true;
                        } else if (self.originType() == "stateProvince" && self.originStateProvince().length == 0) {
                            return true;
                        }
                    }
                    return false;
                },
            },
        });
        self.originRegions.extend({
            required: {
                message: "At least one region is required",
                onlyIf: function () {
                    if (self.originType() == "region") {
                        if (self.destinationType() == "location" && self.destination() == null) {
                            return true;
                        } else if (self.destinationType() == "region" && self.destinationRegions().length == 0) {
                            return true;
                        } else if (self.destinationType() == "stateProvince" && self.destinationStateProvince().length == 0) {
                            return true;
                        }
                    }
                    return false;
                },
            },
        });
        self.destinationRegions.extend({
            required: {
                onlyIf: function () {
                    if (self.destinationType() == "region") {
                        if (self.originType() == "location" && self.origin() == null) {
                            return true;
                        } else if (self.originType() == "region" && self.originRegions().length == 0) {
                            return true;
                        } else if (self.originType() == "stateProvince" && self.originStateProvince().length == 0) {
                            return true;
                        }
                    }
                    return false;
                },
            },
        });
        self.originStateProvince.extend({
            required: {
                message: "At least one state/province is required",
                onlyIf: function () {
                    if (self.originType() == "stateProvince") {
                        if (self.destinationType() == "location" && self.destination() == null) {
                            return true;
                        } else if (self.destinationType() == "region" && self.destinationRegions().length == 0) {
                            return true;
                        } else if (self.destinationType() == "stateProvince" && self.destinationStateProvince().length == 0) {
                            return true;
                        }
                    }
                    return false;
                },
            },
        });
        self.destinationStateProvince.extend({
            required: {
                message: "At least one state/province is required",
                onlyIf: function () {
                    if (self.destinationType() == "stateProvince") {
                        if (self.originType() == "location" && self.origin() == null) {
                            return true;
                        } else if (self.originType() == "region" && self.originRegions().length == 0) {
                            return true;
                        } else if (self.originType() == "stateProvince" && self.originStateProvince().length == 0) {
                            return true;
                        }
                    }
                    return false;
                },
            },
        });

        self.originRadius = ko.observable();
        self.destinationRadius = ko.observable();

        self.isLoadSearch = ko.observable(true);

        // Can't find your carrier btn
        self.searchRMISRedirect = function () {
            dataModel.addClientSideLog("Selected: Can't find your carrier btn.");
            return true;
        };

        self.search = function (sender, args) {
            if (args) $(args.currentTarget).blur();

            self.errors.removeAll();

            var validationErrors = ko.validation.group(self);
            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                return false;
            }

            var data = ko.toJS(self);

            data.pickUpFrom = data.pickUpFrom ? dayjs(data.pickUpFrom).format("MM/DD/YYYY HH:mm") : null;
            data.pickUpTo = data.pickUpTo ? dayjs(data.pickUpTo).format("MM/DD/YYYY HH:mm") : null;

            self.isLoadSearch(data.searchType == "Loads");

            self.isLoading(true);

            if (self.originType() == "location") {
                delete data.originRegions;
                delete data.originStateProvince;
            } else if (self.originType() == "region") {
                delete data.origin;
                delete data.originStateProvince;
            } else if (self.originType() == "stateProvince") {
                delete data.origin;
                delete data.originRegions;
            }
            if (self.destinationType() == "location") {
                delete data.destinationRegions;
                delete data.destinationStateProvince;
            } else if (self.destinationType() == "region") {
                delete data.destination;
                delete data.destinationStateProvince;
            } else if (self.destinationType() == "stateProvince") {
                delete data.destination;
                delete data.destinationRegions;
            }

            dataModel
                .ajaxRequest("ExternalBoard/Search", "POST", data)
                .done(function (data, textStatus, jqXHR) {
                    if (self.isGridLoaded() == false) {
                        self.loadGridResult();
                        self.isGridLoaded(true);
                    }
                    self.errors(data.errors);

                    self.updateGrid(data.items);
                    self.isLoading(false);
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    self.isLoading(false);
                });
        };

        self.clearFilters = function () {
            //self.dat(false);
            //self.truckStop(true);
            self.matchSpecificDates(false);
            self.trailerType(undefined);
            self.shipment("Both");
            self.length(undefined);
            self.weight(undefined);
            self.originType("location");
            self.origin(undefined);
            $("#originStateProvince").jqxTree("uncheckAll");
            $("#originRegions").jqxTree("uncheckAll");
            // self.originStateProvince.removeAll();
            // self.originRegions.removeAll();
            self.destinationType("location");
            self.destination(undefined);
            $("#destinationStateProvince").jqxTree("uncheckAll");
            $("#destinationRegions").jqxTree("uncheckAll");
            //self.destinationStateProvince.removeAll();
            //self.destinationRegions.removeAll();
            self.pickUpFrom(undefined);
            self.pickUpTo(undefined);
            self.originRadius(undefined);
            self.destinationRadius(undefined);
        };

        self.isGridLoaded = ko.observable(false);
        var source = {
            localdata: [],
            datafields: [
                { name: "age", type: "int" },
                { name: "boardName", type: "string" },
                { name: "companyName", type: "string" },
                { name: "dateAvailable", type: "date" },
                { name: "destination", type: "string" },
                { name: "destinationRadius", type: "int" },
                { name: "feet", type: "string" },
                { name: "loadType", type: "string" },
                { name: "origin", type: "string" },
                { name: "originRadius", type: "int" },
                { name: "phone", type: "string" },
                { name: "trailerType", type: "string" },
                { name: "weight", type: "string" },

                { name: "miles", type: "int" },
                { name: "referenceId", type: "string" },
                { name: "comments", type: "string" },
                { name: "count", type: "int" },
                { name: "rate", type: "float" },
                { name: "carrierMcNumber", type: "string" },
                { name: "brokerMcNumber", type: "string" },
                { name: "carrierId", type: "int" },
                { name: "cwStatus", type: "string" },
                { name: "dotNumber", type: "string" },
                { name: "id", type: "string" },
            ],
            datatype: "json",
        };
        self.updateGrid = function (data) {
            source.localdata = data;
            $("#gridSearchResult").jqxGrid("updatebounddata");
            // $('#gridSearchResult').jqxGrid({ height: 800 });
        };

        self.comments = ko.observableArray();

        self.loadGridResult = function () {
            var initrowdetails = function (index, parentElement, gridElement, datarecord) {
                var tabsdiv = $($(parentElement).children()[0]);
                if (tabsdiv != null) {
                    tabsdiv.css("height", $(parentElement).css("height"));

                    tabsdiv.append(
                        '<div data-bind="template: { name: \'boardDetails\', data: $data }"> <div class="jqx-loader-icon jqx-loader-icon-GreatWide" style="background-position-y: top;"></div></div>'
                    );

                    var params = {};
                    if (self.isLoadSearch()) {
                        datarecord.showCredit = true;
                        datarecord.showIns = false;
                        params.type = "Load";
                        params.mcNumber = datarecord.brokerMcNumber;
                    } else {
                        datarecord.showCredit = false;
                        datarecord.showIns = true;
                        params.type = "Truck";
                        params.mcNumber = datarecord.carrierMcNumber;
                    }
                    datarecord.type = params.type;

                    params.origin = datarecord.origin;
                    params.destination = datarecord.destination;
                    params.boardName = datarecord.boardName;
                    params.id = datarecord.id;

                    dataModel
                        .ajaxRequest("ExternalBoard/GetBoardDetails", "get", params)
                        .done(function (data, status, xhr) {
                            data = data || {};
                            $.extend(datarecord, data);
                            if (params.type == "Load") {
                                datarecord.balancePlusUnposted = datarecord.balance + datarecord.netUnposted;
                            }

                            if (isEmpty(datarecord.comments) == false) {
                                datarecord.comments = datarecord.comments.split(";");
                            }
                            self.comments(datarecord.comments);

                            ko.applyBindings(datarecord, tabsdiv[0]);
                        })
                        .fail(function (error, msg, d) {
                            if (isEmpty(datarecord.comments) == false) {
                                datarecord.comments = datarecord.comments.split(";");
                            }
                            self.comments(datarecord.comments);
                            // tabsdiv.html(JSON.stringify(error, null, 4));
                            ko.applyBindings(datarecord, tabsdiv[0]);
                        });
                }
            };

            var dataAdapter = new $.jqx.dataAdapter(source);

            $("#gridSearchResult").jqxGrid({
                width: "100%",
                source: dataAdapter,
                altrows: true,
                sortable: true,
                columnsreorder: true,
                columnsmenu: false,
                columnsresize: true,
                enablebrowserselection: true,
                height: 800,
                autoheight: false,
                filterable: true,
                showfilterrow: true,
                showtoolbar: true,
                rendertoolbar: function (toolbar) {
                    var vm1 = new gridState.viewModel(),
                        tmpl1 = gridState.template;

                    vm1.refresh = function () {
                        self.search();
                    };
                    toolbar.append(tmpl1);
                    ko.applyBindingsToDescendants(vm1, toolbar[0]);
                },
                rowdetails: true,
                rowdetailstemplate: {
                    rowdetails: "<div style='margin: 15px; margin-right:30px; overflow-y:scroll;'></div>",
                    rowdetailsheight: 400,
                    rowdetailshidden: true,
                },
                initrowdetails: initrowdetails,
                ready: function () {
                    gridUtils.loadGridState("gridSearchResult");
                },
                columns: [
                    // { text: 'id', datafield: 'id', width: 90 },

                    { text: "Age", datafield: "age", width: 40 },
                    {
                        text: "Date Available",
                        datafield: "dateAvailable",
                        filtertype: "date",
                        width: 150,
                        cellsformat: userProfile.jqxTimeFormat,
                        createfilterwidget: createDateTimeFilterWidget,
                    },
                    { text: "Trailer Type", datafield: "trailerType", width: 120 },
                    { text: "Origin", datafield: "origin", width: 150 },
                    { text: "Origin Radius", datafield: "originRadius", width: 100 },
                    { text: "Destination", datafield: "destination", width: 150 },
                    { text: "Destination Radius", datafield: "destinationRadius", width: 130 },
                    { text: "Load Type", datafield: "loadType", width: 80 },
                    { text: "Weight", datafield: "weight", width: 70 },
                    { text: "Feet", datafield: "feet", width: 50 },
                    { text: "Company Name", datafield: "companyName" },
                    {
                        text: "Phone",
                        datafield: "phone",
                        width: 120,
                        cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                            if (defaultHTML != null) {
                                var cell = $(defaultHTML);
                                var phone = formatPhoneNumber(value);
                                cell.html(phone);
                                return cell[0].outerHTML;
                            }
                            return defaultHTML;
                        },
                    },
                    {
                        text: "Board",
                        datafield: "boardName",
                        filtertype: "list",
                        width: 100,
                        createfilterwidget: function (column, columnElement, widget) {
                            widget.jqxDropDownList({ enableBrowserBoundsDetection: true, dropDownWidth: 120 });
                        },
                    },
                    {
                        text: "Carrier",
                        datafield: "carrier",
                        width: 140,
                        cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                            if (defaultHTML != null) {
                                if (rowData.cwStatus != null) {
                                    var cell = $(defaultHTML);
                                    cell.html(
                                        '<a href="CarrierEntry/' +
                                            rowData.carrierId +
                                            '" target="_blank"  style="color:blue; "  >' +
                                            rowData.cwStatus +
                                            "</a>"
                                    );
                                    return cell[0].outerHTML;
                                }
                            }
                            return defaultHTML;
                        },
                    },
                    {
                        text: "DOT #",
                        datafield: "dotNumber",
                        width: 70,
                    },
                ],
            });
        };

        self.searchTemplate = ko.observable();
        self.templateName = ko.observable();
        self.saveTemplate = function () {
            if (self.templateName() == null || self.templateName().length == 0) {
                return false;
            }
            if (self.searchTemplate() == null) {
                var mapping = {
                    ignore: ["isLoading", "errors", "isGridLoaded", "templateName"],
                };
                var search = ko.toJS(self, mapping);

                search.pickUpFrom = search.pickUpFrom ? dayjs(search.pickUpFrom).format("MM/DD/YYYY HH:mm") : null;
                search.pickUpTo = search.pickUpTo ? dayjs(search.pickUpTo).format("MM/DD/YYYY HH:mm") : null;

                var data = {
                    name: self.templateName(),
                    search: JSON.stringify(search),
                };

                dataModel
                    .ajaxRequest("ExternalBoard/SaveSearchTemplate", "POST", data)
                    .done(function (data, textStatus, jqXHR) {
                        self.searchTemplate("");
                        self.searchTemplate(undefined);
                        self.isLoading(false);
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        self.isLoading(false);
                    });
            } else {
                //load template
                var json = JSON.parse(self.searchTemplate());
                
                self.searchType(json.searchType);
                self.originType(json.originType);
                self.destinationType(json.destinationType);
                self.dat(json.dat);
                self.truckStop(json.truckStop);
                self.trailerType(json.trailerType);
                self.pickUpFrom(json.pickUpFrom ? dayjs(json.pickUpFrom).format("MM/DD/YYYY HH:mm") : null);
                self.pickUpTo(json.pickUpTo ? dayjs(json.pickUpTo).format("MM/DD/YYYY HH:mm") : null);
                self.shipment(json.shipment);
                self.weight(json.weight);
                self.length(json.length);
                self.matchSpecificDates(json.matchSpecificDates);
                self.origin(json.origin);
                self.originRegions(json.originRegions);
                self.originStateProvince(json.originStateProvince);
                self.originRadius(json.originRadius);
                self.destination(json.destination);
                self.destinationRegions(json.destinationRegions);
                self.destinationStateProvince(json.destinationStateProvince);

                self.destinationRadius(json.destinationRadius);
                //self.equipmentClass(json.equipmentClass);

                ko.utils.arrayForEach(self.equipmentClasses(), function (item) {
                    var match = ko.utils.arrayFirst(json.equipmentClass, function (tmp) {
                        return tmp == item.value;
                    });
                    if (match) {
                        item.checked = true;
                    } else {
                        item.checked = false;
                    }
                });
                self.equipmentClasses.valueHasMutated();

                self.originRegions.refresh();
                self.destinationRegions.refresh();
                self.originStateProvince.refresh();
                self.destinationStateProvince.refresh();
            }
        };

        self.sendPacket = ko.observable();
        self.showSendPakcet = function () {
            self.sendPacket({});
        };

        dataModel.ajaxRequest("externalboard/GetUserBoards").done(function (data, textStatus, jqXHR) {
            self.dat(data.dat);
            self.hasRMISAccess(data.hasRMISAccess);
            self.truckStop(data.truckStop);
            if (data.dat == false && data.truckStop == false) {
                self.errors.push({
                    message:
                        "Please enter your external loadboard account information on the Settings page. If you do not have an external loadboard account please contact the Operations Department to set up account.",
                });
            }
        });
    }
}

import template from "./external-board-page.html";
export default { viewModel: ExternalBoardPageViewModel, template: template };
