import dataModel from "data-model";
import ko from 'knockout';
import template from './order-entry-invoice-and-settlement-details-component.html';

class InvoiceAndSettlementDetailsModel {
    constructor(orderId) {
        this.orderId = orderId;
        this.invoiceActivity = ko.observable();
        this.settlementActivity = ko.observable();
        this.isLoaded = ko.observable(false);
        this.showMessageInvoice = ko.observable(false);
        this.showMessageSettlement = ko.observable(false);
    
    }

    loadInvoiceAndSettlementDetails = () =>{
        if (this.isLoaded() == false) {
            this.GetOrderInvoiceDetails();
            this.GetOrderSettlementDetails();
        }
    };
    
    GetOrderInvoiceDetails = () =>{
        dataModel.ajaxRequest('order/GetOrderInvoiceDetails/' + this.orderId, 'GET')
        .done((response) => {
            const invoice = (response && response.invoiceActivity) || [];
                for (var i = 0; i < invoice.length; i++) {
                    invoice[i].amount = invoice[i].amount.toFixed(2);
                }
            
            this.invoiceActivity(response);
            this.showMessageInvoice(invoice.length === 0)
            this.isLoaded(true);
        })
    };
    
    GetOrderSettlementDetails = (orderId) =>{
        dataModel.ajaxRequest('order/GetOrderSettlementDetails/' + this.orderId, 'GET')
        .done((response) => {
            const settlements = (response && response.settlementActivity) || [];
            for (var i = 0; i < settlements.length; i++) {
                settlements[i].amount = settlements[i].amount.toFixed(2);
            }
            this.settlementActivity(response);
            this.showMessageSettlement(settlements.length === 0);
            this.isLoaded(true);
        })
    };
}

export default {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            //Don't do anything.  Use the object created in the main OrderEntry page.
        }
    }, template: template
}

export { InvoiceAndSettlementDetailsModel }