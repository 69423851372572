import ko from 'knockout'
import userProfile from 'user-profile'
import html from './main-header-component.html'
import router from 'router';

class MainHeaderComponent {
    constructor() {
        this.currentAgencyId = ko.observable(userProfile.currentAgencyId());
        this.loggedIn = userProfile.loggedIn
        this.userAgencies = ko.computed(function(x) { return userProfile.userAgencies() });
        this.errors = ko.observableArray();
        this.loading = ko.observable(false);
        this.loaded = ko.observable(true);

        
    }
}


export default { viewModel: MainHeaderComponent, template: html };
