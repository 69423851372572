import {datetimeUTC, GenerateGuid, mapGridDate, noop} from 'global-functions';
import dataModel from 'data-model';
import userprofile from 'user-profile';
import {useDispatch, useSelector} from 'ko-data-store';

export const fetchRecentAppointmentChangeByStopIdAsync = (stopId) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest(`EDI/GetRecentAppointmentChangeByStopId`, "GET", {stopId}, true)
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while updating the delay codes.`))
    })
}


export const fetchAllAppointmentChangesByStopIdAsync = (stopId) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest(`EDI/GetAllAppointmentChangesByStopId`, "GET", {stopId}, true)
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while updating the delay codes.`))
    })
}


export const sendAppointmentChangeAsync = (model) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest(`EDI/SaveAppointmentChange`, "POST", model, true)
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while updating the delay codes.`))
    })
}

export function appointmentChangeModel({
    id = 0,
    stopId = 0,
    contact = "",
    description = "",
    revisedEarliestArrival = undefined,
    revisedLatestArrival = undefined,
    originalEarliestArrival = undefined,
    originalLatestArrival = undefined,
    reasonCode = "",
    username = "",
    dateAdded = undefined,
    sequence = 0
} = {}) {
    const vm = this;
    vm.stopId = stopId;

    vm.appointmentChangeId = ko.observable(id).extend({notify: 'always'});
    vm.contact = ko.observable(contact);
    vm.description = ko.observable(description);
    vm.revisedEarliestArrival = ko.observable(revisedEarliestArrival),
    vm.revisedLatestArrival = ko.observable(revisedLatestArrival);
    vm.reasonCode = ko.observable(reasonCode).extend({notify: 'always'});

    vm.originalEarliestArrival = ko.observable(originalEarliestArrival),
    vm.originalLatestArrival = ko.observable(originalLatestArrival);

    vm.username = ko.observable(username);
    vm.sequence = ko.observable(sequence);
    vm.dateAdded = ko.observable(dateAdded);

    const errors = ko.observableArray([]);
    vm.validationErrors = ko.pureComputed(() => errors());
    
    vm.addError = (msg, refreshErrors) => (msg && (refreshErrors ? errors([msg]) : errors.push(msg)));

    vm.isValid = ko.pureComputed(() => {
        const errs = [];
        
        const orgEarliest = datetimeUTC(vm.originalEarliestArrival());
        const orgLatest = datetimeUTC(vm.originalLatestArrival())

        const redate = datetimeUTC(vm.revisedEarliestArrival());
        

        if(!vm.revisedEarliestArrival() && !vm.revisedLatestArrival()) {
            errs.push("Please enter a new earliest arrival or a new latest arrival.");
        }
        
        if(vm.revisedEarliestArrival()) {
            if(redate.isValid() === false) {
                errs.push(`New earliest arrival date is not valid.`)
            }
            // else if(orgEarliest > redate) {
            //     errs.push(`New earliest arrival date must be on or later than original earliest arrival date.}`)
            // }
            else if(redate > orgLatest && !vm.revisedLatestArrival()) {
                errs.push(`New earliest arrival date must be on or before the original latest arrival date: ${orgLatest.format("MM/DD/YYYY HH:mm")} - Change new earliest or enter a new latest.`)
            }
        }

        if(vm.revisedLatestArrival()) {    
            const rldate = datetimeUTC(vm.revisedLatestArrival());
            if(rldate.isValid() === false) {
                errs.push(`New latest arrival date is not valid.`)
            }
            else if(vm.revisedEarliestArrival() && redate > rldate) {
                errs.push(`New latest arrival must be on or later than: ${redate.format("MM/DD/YYYY HH:mm")}`)
            }
            // else if(orgLatest > rldate) {
            //     errs.push(`New latest arrival date must be on or later than original latest arrival date.`)
            // }
            else if(rldate < orgEarliest && !vm.revisedEarliestArrival()) {
                errs.push(`New latest arrival date must be on or later than original earliest arrival date: ${orgEarliest.format("MM/DD/YYYY HH:mm")} - Change new latest or enter a new earliest.`)
            }
        }
        
        if(!vm.reasonCode()) {
            errs.push(`Delay Code is required.`)
        }
        
        errors(errs);
        return errs.length === 0
    });
}

function DelayCodeEditorViewModel({
    partnerId = "",
    stopId = 0,
    originalEarliestArrival = undefined,
    originalLatestArrival = undefined,
    appointmentChanges = [],
    preventAddMore = false,
    orderId = 0,
    orderOpenedAtTimestamp = undefined,
    onClose = noop,
    onChange = noop
} = {}) {
    
    const vm = this;
    const dispatch = useDispatch();

    vm.delayGridId = `${GenerateGuid(5) + Date.now()}`;
    vm.partnerId = partnerId;
    vm.form = ko.observable(new appointmentChangeModel({stopId, originalEarliestArrival, originalLatestArrival}));
    vm.preventAddMore = ko.observable(preventAddMore || false);
    vm.isReadOnly = ko.pureComputed(() => vm.preventAddMore() || (vm.form() && vm.form().appointmentChangeId() > 0));
    vm.showSaveBtn = ko.pureComputed(() => vm.preventAddMore() === false && (vm.form() && vm.form().appointmentChangeId() > 0 === false))
    vm.openDelayCodeSearchModal = ko.observable(false);
    vm.display = ko.observable('mainForm');
    vm.appointments = ko.observableArray(ko.toJS(appointmentChanges));
    vm.delayCodes = ko.observableArray([]);
    vm.selectedReasonCode = ko.observable();

    vm.selectedReasonCode.subscribe((val) => {
        val = val ?? {};

        vm.form().reasonCode(val.delayCode || null)
    })

    vm.handleSave = async() => {
        try {
            
            if(vm.form().isValid() === false) {
                return false;
            }
            
            const formJSON = ko.toJS(vm.form);
            const payload = {...formJSON, partnerId, viewingOrderId: orderId, orderOpenedAtTimestamp };
            
            dispatch(isLoading(true));
            const id = await sendAppointmentChangeAsync(payload);
            dispatch(isLoading(false));

            onChange({stopId, revisedEarliestArrival: formJSON.revisedEarliestArrival, revisedLatestArrival: formJSON.revisedLatestArrival})

        }
        catch(err) {
            dispatch(isLoading(false));
            vm.form().addError(err, true);
        }
    }

    vm.handleMappingDelayCodes = (results = []) => results.map(x => ({...x, label: `${x.delayCode} - ${x.description}` }))

    // Wait until component loads, modal is fully open, then build grid using the dynamic ko id
    setTimeout(() => {
        (() => {
            const $grid = $('#' + vm.delayGridId);

             const source = {
                localdata: vm.appointments() || [],
                datatype: "json",
                beforeprocessing: (dataArr = []) => {
                    return dataArr.map(x => 
                        {
                            return {
                                ...x,
                                delayCode: !x.reasonCode || x.reasonCode === '' ? null : x.reasonCode,
                                revisedEarliestArrival: x.revisedEarliestArrival && mapGridDate(x.revisedEarliestArrival),
                                revisedLatestArrival: x.revisedLatestArrival && mapGridDate(x.revisedLatestArrival),
                                originalEarliestArrival: x.originalEarliestArrival && mapGridDate(x.originalEarliestArrival),
                                originalLatestArrival: x.originalLatestArrival && mapGridDate(x.originalLatestArrival),
                                dateAdded: x.dateAdded && mapGridDate(x.dateAdded),
                            }
                        }
                    )
                },
                datafields: [
                    { name: 'appointmentChangeId', type: 'number' },
                    { name: 'stopId', type: 'number' },
                    { name: 'revisedEarliestArrival', type: 'date' },
                    { name: 'revisedLatestArrival', type: 'date' },
                    { name: 'originalEarliestArrival', type: 'date' },
                    { name: 'originalLatestArrival', type: 'date' },
                    { name: 'dateAdded', type: 'date' },
                    { name: 'sequence', type: 'number' },
                    { name: 'username', type: 'string' },
                    { name: 'reasonCode', type: 'string' },
                    { name: 'description', type: 'string' },
                    { name: 'contact', type: 'string' },
                ],
            }

            const adapter = new $.jqx.dataAdapter(source);


            $grid.jqxGrid({
                filterable: false,
                sortable: true,
                width: '100%',
                height: 200,
                source: adapter,
                columns: [
                    { text: 'Reason Code', datafield: 'reasonCode', minwidth: 100, width: 100 },
                    { text: 'Original Earliest', datafield: 'originalEarliestArrival', minwidth: 120, width: 150, cellsformat: "MM/dd/yyyy HH:mm" },
                    { text: 'New Earliest', datafield: 'revisedEarliestArrival', minwidth: 120, width: 150, cellsformat: "MM/dd/yyyy HH:mm" },
                    { text: 'Original Latest', datafield: 'originalLatestArrival', minwidth: 120, width: 150, cellsformat: "MM/dd/yyyy HH:mm" },
                    { text: 'New Latest', datafield: 'revisedLatestArrival', minwidth: 120, width: 150, cellsformat: "MM/dd/yyyy HH:mm" },
                    { text: 'Description', datafield: 'description', minwidth: 150,       
                            cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties) => {
                                const $html = $(defaulthtml);

                                if(value) {
                                    $html.attr('title', value);
                                }

                                return $html[0].outerHTML;
                            } 
                    },
                    { text: 'Added At', datafield: 'dateAdded', minwidth: 120, width: 150, cellsformat: "MM/dd/yyyy HH:mm" },
                    { text: 'Updated By', datafield: 'username', minwidth: 150, width: 150  },
                ],
                altrows: true,
                pagesize: 5,
                columnsresize: true,
                columnsreorder: false,
                enablebrowserselection: true,
                columnsmenu: false,
                showfilterrow: false,
                showtoolbar: false,
                rendergridrows: function (obj) {
                    return obj.data;
                }
            });
        })()
    }, 100)
}

import template from './order-entry-stops-edi-delay-code-editor.html';
import { isLoading } from '../../../../../dataStore/actions/appUI';
export default { viewModel: DelayCodeEditorViewModel, template }