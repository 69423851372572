import dataModel from 'data-model'
import template from './role-management-page.html';
import gridStateUtils from 'jqx.grid-utils'
import GridStateComponentViewModel from 'jqx.grid-state-component';
import { useDispatch } from 'ko-data-store';
import { isLoading } from '../../../dataStore/actions/appUI';

// view model for user roles management modal
var UserRolesViewModel = function (userId, userName, userAgency, data) {
    const dispatch = useDispatch();
    var self = this;
    self.showLoadingWheel = ko.observable(false);
    self.userId = ko.observable(userId);
    self.primaryAgency = ko.observable(userAgency);
    self.userName = ko.observable(userName);
    self.effectiveAgencies = ko.observable(data.agencyHierarchy);
    self.leftSource = data.availableRoles;
    self.rightSource = data.currentRoles;
    self.columns = [{ text: "id", datafield: "id", hidden: true, columnType: 'number' }, { text: "Name", datafield: "roleName", columnType: 'textbox' }];
    self.fields = [{ name: "id", type: 'string' }, { name: "roleName", type: 'string' }];
    self.columnTransfer = function () {
        var userRoles = ko.utils.arrayMap(self.rightSource, function (item) {
            return item.id;
        });
        var data = {
            selectedUserId: self.userId(),
            roles: userRoles
        };
        
        dispatch(isLoading(true));
        dataModel.ajaxRequest("User/SetRolesForUser/", 'post', data)
        .done(function (response, status, xhr) {
        
            dispatch(isLoading(false));
        })
        .fail(function (error, msg, d) {
            dispatch(isLoading(false));
        });
    };
};

// view model for role users management modal
var RolesUserViewModel = function (roleId, roleName, data) {
    var self = this;
    self.showLoadingWheel = ko.observable(false);
    self.roleId = roleId;
    self.selectedRole = ko.observable(roleName);
    self.leftSource = data.notInRole;
    self.rightSource = data.inRole;
    self.columns = [{ text: "id", datafield: "id", hidden: true, columnType: 'number' }, { text: "Name", datafield: "name", columnType: 'textbox' }];
    self.fields = [{ name: "id", type: 'string' }, { name: "name", type: 'string' }];
    self.columnTransfer = function () {
        var usersInRole = ko.utils.arrayMap(self.rightSource, function (item) {
            return item.id;
        });
        var data = {
            selectedRoleId: self.roleId,
            users: usersInRole
        };
        self.showLoadingWheel(true);
        dataModel.ajaxRequest("User/SetUsersForRole/", "post", data)
            .done(function (response, status, xhr) {
                self.showLoadingWheel(false);
            })
            .fail(function (error, msg, d) {
                self.showLoadingWheel(false);
                console.log(error);
            });
    };
};

// view model for role agency management modal
var RolesAgencyViewModel = function (roleId, roleName, data) {
    var self = this;
    self.showLoadingWheel = ko.observable(false);
    self.roleId = roleId;
    self.selectedRole = ko.observable(roleName);
    self.leftSource = data.availableAgencies;
    self.rightSource = data.currentAgencies;
    self.columns = [{ text: "id", datafield: "id", hidden: true, columnType: 'number' }, { text: "Agency", datafield: "externalId", columnType: 'textbox' }];
    self.fields = [{ name: "id", type: 'string' }, { name: "externalId", type: 'string' }];

    self.columnTransfer = function () {
        var activeAgencyIds = ko.utils.arrayMap(self.rightSource, function (item) {
            return item.id;
        });
        var data = {
            selectedRoleId: self.roleId,
            activeAgencyIds: activeAgencyIds
        };
        self.showLoadingWheel(true);
        dataModel.ajaxRequest("Agency/SetAgenciesForRole/", "post", data)
        .done(function (response, status, xhr) {
            self.showLoadingWheel(false);
        })
        .fail(function (error, msg, d) {
            self.showLoadingWheel(false);
            console.log(error);
        });
    };
};

// view model for view page management modal
var ViewPageViewModel = function (roleId, roleName, data) {
    var self = this;
    self.showLoadingWheel = ko.observable(false);
    self.roleId = roleId;
    self.selectedRole = ko.observable(roleName);
    self.leftSource = data.disabledPages;
    self.rightSource = data.enabledPages;
    self.columns = [{ text: "id", datafield: "id", hidden: true, columnType: 'number' }, { text: "Name", datafield: "name", columnType: 'textbox' }];
    self.fields = [{ name: "id", type: 'string' }, { name: "name", type: 'string' }];

    self.columnTransfer = function () {
        var enabledPages = ko.utils.arrayMap(self.rightSource, function (item) {
            return item.id;
        });
        var data = {
            selectedRoleId: self.roleId,
            accessType: "View",
            pageIds: enabledPages,
        };
        self.showLoadingWheel(true);
        dataModel.ajaxRequest("User/SetPagesForRole/", "post", data)
        .done(function (response, status, xhr) {
            self.showLoadingWheel(false);
        })
        .fail(function (error, msg, d) {
            self.showLoadingWheel(false);
            console.log(error);
        });
    };
};

// view model for edit page management modal
var EditPageViewModel = function (roleId, roleName, data) {
    var self = this;
    self.showLoadingWheel = ko.observable(false);
    self.roleId = roleId;
    self.selectedRole = ko.observable(roleName);
    self.leftSource = data.disabledPages;
    self.rightSource = data.enabledPages;
    self.columns = [{ text: "id", datafield: "id", hidden: true, columnType: 'number' }, { text: "Name", datafield: "name", columnType: 'textbox' }];
    self.fields = [{ name: "id", type: 'string' }, { name: "name", type: 'string' }];

    self.columnTransfer = function () {
        var enabledPages = ko.utils.arrayMap(self.rightSource, function (item) {
            return item.id;
        });
        var data = {
            selectedRoleId: self.roleId,
            accessType: "Edit",
            pageIds: enabledPages,
        };
        self.showLoadingWheel(true);
        dataModel.ajaxRequest("User/SetPagesForRole/", "post", data)
        .done(function (response, status, xhr) {
            self.showLoadingWheel(false);
        })
        .fail(function (error, msg, d) {
            self.showLoadingWheel(false);
            console.log(error);
        });
    };
}

// view model for securable controls management modal
var SecurableControlsViewModel = function (roleId, roleName, data) {
    var self = this;
    self.showLoadingWheel = ko.observable(false);
    self.roleId = roleId;
    self.selectedRole = ko.observable(roleName);
    self.leftSource = data.disabledControls;
    self.rightSource = data.enabledControls;
    self.columns = [{ text: "id", datafield: "id", hidden: true, columnType: 'number' }, { text: "Page", datafield: "page", columntype: "textbox" }, { text: "Name", datafield: "name", columnType: 'textbox' }];
    self.fields = [{ name: "id", type: 'string' }, { name: "page", type: "string" }, { name: "name", type: 'string' }];

    self.columnTransfer = function () {
        var enabledControlIds = ko.utils.arrayMap(self.rightSource, function (item) {
            return item.id;
        });
        var disabledControlIds = ko.utils.arrayMap(self.leftSource, function (item) {
            return item.id;
        });
        
        var data = {
            enabledControlIds: enabledControlIds,
            disabledControlIds: disabledControlIds,
            roleId: self.roleId
        };
        self.showLoadingWheel(true);
        dataModel.ajaxRequest("User/SetSecurableControlsForRole/", "post", data)
        .done(function (response, status, xhr) {
            self.showLoadingWheel(false);
        })
        .fail(function (error, msg, d) {
            self.showLoadingWheel(false);
            console.log(error);
        });
    };
};

// main view model
var RoleManagementViewModel = function () {
    var self = this;
    self.usersRoles = ko.observable();
    self.rolesAgency = ko.observable();
    self.rolesUser = ko.observable();
    self.rolesPageAccess = ko.observable();
    self.selectedRole = ko.observable();
    self.newRoleName = ko.observable();
    self.roleViewPages = ko.observable();
    self.editPages = ko.observable();
    self.securableControls = ko.observable();
    self.showLoadingWheel = ko.observable(false);

    // retrieves page data
    self.loadPageData = function () {
        var source = {
            url: "User/GetPageList",
            datatype: "json",
            datafields: [
                { name: "id", type: "int" },
                { name: "name", type: "string" }
            ]
        };
        return dataModel.getDataAdapter(source);
    }
    self.pageDataAdapter = self.loadPageData();
    // retrieves user data, loads user grid
    self.loadUserGrid = function () {
        var grid = $("#userGrid");
        // get users
        var source = {
            url: "User/GetUsersForSecurity",
            datatype: "json",
            datafields: [
                { name: "name", type: "string" },
                { name: "terminal", type: "string" },
                { name: "active", type: "bool" },
                { name: "agency", type: "string" },
                { name: "id", type: "int" }
            ],
            id: "id",
            filter: function (filters, recordsArray) {
                grid.jqxGrid('updatebounddata', 'filter');
            },
            sort: function (column, direction) {
                grid.jqxGrid('updatebounddata', 'sort');
            },
            formatdata: function (data) {
                var filterinfo = grid.jqxGrid('getfilterinformation');
                data = gridStateUtils.formatGridFilters(filterinfo, data);
                return data;
            }
        }
        var dataAdapter = dataModel.getDataAdapter(source);
        // load grid
        grid.jqxGrid({
            source: dataAdapter,
            width: "100%",
            sortable: true,
            selectionmode: "multiplerows",
            pageable: true,
            filterable: true,
            showfilterrow: true,
            autoheight: true,
            virtualmode: true,
            columnsresize: true,
            columnsreorder: true,
            columnsmenu: false,
            altrows: true,
            pagesize: 5,
            showtoolbar: true,
            // action items toolbar
            rendertoolbar: function (toolbar) {
                var vm1 = new GridStateComponentViewModel.viewModel();
                vm1.actions = [
                    "Clear Filters",
                    "Clear Selection",
                    "Refresh",
                    "Show/Hide Columns",
                    "Activate Selected Users",
                    "Deactivate Selected Users"
                ]

                vm1.action = function(item) {
                    if(item == "Activate Selected Users" || item == "Deactivate Selected Users") {
                        var rows = grid.jqxGrid("getselectedrowindexes");
                        var ids = [];
                        rows.forEach(function(element) {   
                            var row = grid.jqxGrid("getrowdata", element);
                            console.log(row);
                            ids.push(row.id)
                        });

                        if(ids.length > 0) {
                            self.showLoadingWheel(true);
                            
                            if(item == "Activate Selected Users") {
                                dataModel.ajaxRequest("User/ActivateUsers", "post", ids)
                                .done(function (data, status, xhr) { 
                                    rows.forEach(function(element) {  
                                        grid.jqxGrid('setcellvalue', element, 'active', true);
                                    })
                                    grid.jqxGrid('clearselection');
                                    self.showLoadingWheel(false);
                                })
                                .fail(function() { self.showLoadingWheel(false); });

                            } else {
                                dataModel.ajaxRequest("User/DeactivateUsers", "post", ids)
                                .done(function (data, status, xhr) { 
                                    rows.forEach(function(element) {  
                                        grid.jqxGrid('setcellvalue', element, 'active', false);
                                    })
                                    grid.jqxGrid('clearselection');
                                    self.showLoadingWheel(false);
                                })
                                .fail(function() { self.showLoadingWheel(false); });
                            }
                        }
                    }
                }
                vm1.initializeDefaultToolbar(toolbar);
            },
            rendergridrows: function (obj) {
                return obj.data;
            },
            ready: function () {
                grid.jqxGrid('showrowdetails', 1);
                gridStateUtils.loadGridState("userGrid");
            },
            columns: [
                { text: 'Username', datafield: 'name' },
                { text: 'Terminal', datafield: 'terminal' },
                { text: 'Primary Agency', datafield: 'agency' },
                { text: 'Active', datafield: 'active', filtertype: "bool", columntype: "checkbox" },
                {
                    text: '',
                    width: 100,
                    datafield: 'userRolesButton',
                    filterable: false,
                    sortable: false,
                    columnType: 'button',
                    buttonclick: function (row) {
                        var datarow = grid.jqxGrid('getrowdata', row);
                        self.showLoadingWheel(true);
                        dataModel.ajaxRequest("User/GetRolesForUser/" + datarow.id, "GET")
                        .done(function (data, status, xhr) {
                            var userRolesModel = new UserRolesViewModel(datarow.id, datarow.name, datarow.agency, data);
                            self.usersRoles(userRolesModel);
                            self.showLoadingWheel(false);
                        })
                        .fail(function (error, msg, d) {
                            self.showLoadingWheel(false);
                        });
                    },
                    cellsrenderer: function () {
                        return "Roles";
                    }
                }
            ]
        });
    };
    self.loadUserGrid();
    // retrieves role data
    self.getRoleData = function () {
        var grid = $("#rolesGrid");
        var source = {
            url: "User/GetRoleList",
            datatype: "json",
            datafields: [
                { name: "id", type: "int" },
                { name: "name", type: "string" }
            ],
            id: "id",
            filter: function (filters, recordsArray) {
                grid.jqxGrid('updatebounddata', 'filter');
            },
            sort: function (column, direction) {
                grid.jqxGrid('updatebounddata', 'sort');
            },
            formatdata: function (data) {
                var filterinfo = grid.jqxGrid('getfilterinformation');
                data = gridStateUtils.formatGridFilters(filterinfo, data);
                return data;
            }
        };
        return dataModel.getDataAdapter(source);
    };
    self.roleDataAdapter = self.getRoleData();
    // loads role grid
    self.loadRoleGrid = function () {
        var grid = $("#rolesGrid");

        grid.jqxGrid({
            source: self.roleDataAdapter,
            selectionmode: 'none',
            width: "100%",
            sortable: true,
            pageable: true,
            filterable: true,
            showfilterrow: true,
            autoheight: true,
            virtualmode: false,
            columnsresize: true,
            columnsreorder: true,
            columnsmenu: false,
            altrows: true,
            pagesize: 5,
            showtoolbar: true,
            // action items toolbar
            rendertoolbar: function (toolbar) {
                var vm1 = new GridStateComponentViewModel.viewModel();
                vm1.actions = [
                    "Clear Filters",
                    "Clear Selection",
                    "Refresh",
                    "Show/Hide Columns"
                ]
                vm1.initializeDefaultToolbar(toolbar);
            },
            rendergridrows: function (obj) {
                return obj.data;
            },
            ready: function () {
                gridStateUtils.loadGridState("rolesGrid");
            },
            columns: [
                { text: 'Role Name', datafield: 'name' },
                // agencies
                {
                    text: '',
                    datafield: 'agenciesButton',
                    width: 100,
                    filterable: false,
                    sortable: false,
                    columnType: 'button',
                    buttonclick: function (row) {
                        // open modal for managing agencies in role
                        var datarow = grid.jqxGrid('getrowdata', row);
                        self.showLoadingWheel(true);
                        dataModel.ajaxRequest("Agency/GetAgenciesForRole/" + datarow.id, "GET")
                        .done(function (data, status, xhr) {
                            self.showLoadingWheel(false);
                            var agencyModel = new RolesAgencyViewModel(datarow.id, datarow.name, data);
                            self.rolesAgency(agencyModel);
                        })
                        .fail(function (error, msg, d) {
                            self.showLoadingWheel(false);
                        });
                    },
                    cellsrenderer: function () {
                        return "Agencies";
                    }
                },

                // users
                {
                    text: '',
                    datafield: 'usersButton',
                    width: 100,
                    filterable: false,
                    sortable: false,
                    columnType: 'button',
                    buttonclick: function (row) {
                        // open modal for managing users in role
                        var datarow = grid.jqxGrid('getrowdata', row);
                        self.showLoadingWheel(true);
                        dataModel.ajaxRequest("User/GetUsersForRole/" + datarow.id, "GET")
                        .done(function (data, status, xhr) {
                            self.showLoadingWheel(false);
                            var userModel = new RolesUserViewModel(datarow.id, datarow.name, data);
                            self.rolesUser(userModel);
                        })
                        .fail(function (error, msg, d) {
                            self.showLoadingWheel(false);
                        });
                    },
                    cellsrenderer: function () {
                        return "Users";
                    }
                },

                // view pages
                {
                    text: '',
                    datafield: 'viewPages',
                    filterable: false,
                    width: 100,
                    sortable: false,
                    columnType: 'button',
                    buttonclick: function (row) {
                        // open modal for managing a role's access to view pages
                        var datarow = grid.jqxGrid('getrowdata', row);
                        var data = {
                            RoleId: datarow.id,
                            AccessType: "View"
                        }
                        self.showLoadingWheel(true);
                        dataModel.ajaxRequest("User/GetPagesForRole/", "GET", data)
                        .done(function (data, status, xhr) {
                            self.showLoadingWheel(false);
                            var viewPages = new ViewPageViewModel(datarow.id, datarow.name, data);
                            self.roleViewPages(viewPages);
                        })
                        .fail(function (error, msg, d) {
                            self.showLoadingWheel(false);
                        });
                    },
                    cellsrenderer: function () {
                        return "View Pages";
                    }
                },
                // edit pages
                {
                    text: '',
                    datafield: 'editPages',
                    filterable: false,
                    width: 100,
                    sortable: false,
                    columnType: 'button',
                    buttonclick: function (row) {
                        // open modal for managing a role's access to edit pages
                        var datarow = grid.jqxGrid('getrowdata', row);
                        var data = {
                            RoleId: datarow.id,
                            AccessType: "Edit"
                        }
                        self.showLoadingWheel(true);
                        dataModel.ajaxRequest("User/GetPagesForRole/", "GET", data)
                        .done(function (data, status, xhr) {
                            self.showLoadingWheel(false);
                            var editPages = new EditPageViewModel(datarow.id, datarow.name, data);
                            self.editPages(editPages);
                        })
                        .fail(function (error, msg, d) {
                            self.showLoadingWheel(false);
                        });
                    },
                    cellsrenderer: function () {
                        return "Edit Pages";
                    }
                },
                {
                    text: '',
                    datafield: 'securableControl',
                    filterable: false,
                    width: 150,
                    sortable: false,
                    columnType: 'button',
                    buttonclick: function (row) {
                        // open modal for managing a role's access to securable page controls
                        var datarow = grid.jqxGrid('getrowdata', row);
                        var data = {
                            RoleId: datarow.id,
                            AccessType: "Edit"
                        }
                        self.showLoadingWheel(true);
                        dataModel.ajaxRequest("User/GetSecurableControlsForRole/" + datarow.id, "GET", data)
                            .done(function (data, status, xhr) {
                                self.showLoadingWheel(false);
                                var securableControlModel = new SecurableControlsViewModel(datarow.id, datarow.name, data);
                                self.securableControls(securableControlModel);
                            })
                            .fail(function (error, msg, d) {
                                self.showLoadingWheel(false);
                            });
                    },
                    cellsrenderer: function () {
                        return "Securable Controls";
                    }
                }
            ]
        });
    };
    self.loadRoleGrid();

    // adds a new role
    self.addRoleButtonClick = function () {
        var newRoleName = self.newRoleName() == undefined ? "" : self.newRoleName().trim();
        if (newRoleName != "") {
            var data = {
                roleName: newRoleName
            };
            self.showLoadingWheel(true);
            dataModel.ajaxRequest("User/AddRole/", 'post', data)
            .done(function (response, status, xhr) {
                self.showLoadingWheel(false);
                self.roleDataAdapter = self.getRoleData();
                if (response == true) {
                    $("#rolesGrid").jqxGrid('updatebounddata', 'cells');
                } else {
                    alert("A role with this name already exists.  Please select a new role name and try again.");
                }
            })
            .fail(function (error, msg, d) {
                self.showLoadingWheel(false);
            });
        }
    };
};

export default {viewModel: RoleManagementViewModel, template: template}