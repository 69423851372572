import ko, { pureComputed } from "knockout";
import userProfile from "user-profile";
import template from "./main-header-navigation-component.html";
import router from "router";
import AlertStorageManager from "../../main-footer-component/main-footer-priority-alert-component/priority-alert-storage-manager";
import { useDispatch, useSelector } from "ko-data-store";
import { getAdvanceAbility, sendWire } from "dataStore-actions/wireAdvances";
import { showmessage } from "show-dialog-methods";
import { SendWireData } from "../../shared-components/efs-advance-component/types";
import { openCarrierFraudContactModal } from "../../../dataStore/actions/appUI";
class MainHeaderNavigationComponent {
  constructor() {
    this.dispatch = useDispatch();
    this.dispatch(getAdvanceAbility());
    const wireAdvances = useSelector((state) => state.wireAdvances);

    wireAdvances.subscribe((val) => {
      this.determineShowSendWire(val.advanceAbility);
    });

    this.loggedIn = userProfile.loggedIn;

    this.errors = ko.observableArray();
    this.loading = ko.observable(false);
    this.loaded = ko.observable(true);

    this.alertsCount = AlertStorageManager.alertsCount;

    this.quickLinks = ko.observableArray([
      {
        type: "button",
        title: "View Fraudulent Carrier Contacts",
        icon: "glyphicon glyphicon-user",
        clickHandler: () => this.dispatch(openCarrierFraudContactModal(true)),
      },
    ]);

    this.showNavigation = pureComputed(() => {
      const route = router.currentRoute();
      return route.page != "default-page";
    });

    // This is ugly, but... Bootstrap DDL is not closing properly
    $("body").on("click", function (e) {
      if (
        !$(".dropdown-toggle").is(e.target) &&
        $(".dropdown-toggle").has(e.target).length === 0 &&
        $(".open").has(e.target).length === 0
      ) {
        $(".dropdown-toggle").parent("li").removeClass("open");
      }
    });
  }

  handleQuickLinkSelect = (clickHandlerFn = function () {}) => {
    $(".dropdown-toggle").parent("li").removeClass("open");
    clickHandlerFn();
  };

  canSendWire = (advanceAbility) => {
    return (
      advanceAbility &&
      advanceAbility.advanceAbilityEnabled &&
      advanceAbility.advanceAbilityLockedOut == false &&
      advanceAbility.aopLimit > 0
    );
  };

  determineShowSendWire = (advanceAbility) => {
    let links = this.quickLinks().filter(
      (link) => link.title !== "Wire Advance"
    );

    if (this.canSendWire(advanceAbility) && userProfile.isGwEmployee) {
      links.push({
        type: "button",
        title: "Wire Advance",
        icon: "glyphicon glyphicon-credit-card",
        clickHandler: this.handleSendWire,
      });
    }

    this.quickLinks(links);
  };

  handleSendWire = async () => {
    const { wireAdvances } = await this.dispatch(getAdvanceAbility());
    const advanceAbility = wireAdvances.advanceAbility;

    if (this.canSendWire(advanceAbility) == false) {
      if (advanceAbility.advanceAbilityLockedOut)
        showmessage(`You do not have the authority to give advances.`);
      return false;
    }

    const payload = new SendWireData({
      aopLimit: advanceAbility.aopLimit,
      fundingLimit: advanceAbility.fundingLimit,
      allowManualEntry: true,
    });

    await this.dispatch(sendWire(payload));
  };

  handleDisplayPriorityAlerts = () => {
    window.dispatchEvent(new Event("display-priority-alerts"));
  };

  toggleQuickLinks() {
    $(".dropdown-toggle").parent("li").toggleClass("open");
  }

  logout() {
    userProfile.logOut();
  }
}

export default { viewModel: MainHeaderNavigationComponent, template: template };
