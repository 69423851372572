import template from './order-entry-dispatch-tractor-preassignment-modal.html';

class TractorPreassignmentVM {
    constructor(params) {
        var self = this;
        self.result = params.params.result;
        var model = params.params.preassignments.map(function(value) { return new TractorPreassignment(value); })
        self.tractorPreassignments = ko.observableArray(model);
    
        self.ok = function() {
            var orderSequence = self.tractorPreassignments().map(function(pta) {
                return {
                    sequence: pta.sequence(),
                    movementId : pta.movementId(),
                    tractorId : pta.tractorId(),
                    driver1Id : pta.driver1Id(),
                    driver2Id : pta.driver2Id(),
                    trailerId : pta.trailerId()
                }
            })
            self.result(orderSequence);
            $('#tractorPreassignmentModal').find('.close').click();
        }
        self.moveUp = function(value) {
            var from = self.tractorPreassignments.indexOf(value);
            if(from != 0) {
                var item = self.tractorPreassignments.splice(from, 1)[0];
                self.tractorPreassignments.splice(from - 1, 0, item);
                self.tractorPreassignments()
                .map(function(value, index) { 
                    var ni = value; 
                    ni.sequence(index + 1) 
                    return ni;
                })
            }
        }
        self.moveDown = function(value) {
            var from = self.tractorPreassignments.indexOf(value);
            if(from != self.tractorPreassignments().length - 1) {
                var item = self.tractorPreassignments.splice(from, 1)[0];
                self.tractorPreassignments.splice(from + 1, 0, item);
                self.tractorPreassignments()
                .map(function(value, index) { 
                    var ni = value; 
                    ni.sequence(index + 1) 
                    return ni;
                })
            }
        }
    
        self.deletePreassignment = function(item) {
            var newArray = self.tractorPreassignments()
            .filter(function(pra) { return pra.id() != item.id() } )
            .map(function(value, index) {
                var ni = value; 
                ni.sequence(index + 1);
                return ni;
            })
            self.tractorPreassignments(newArray);
        }
    
        setTimeout(function() {  //I hate these.
            $("#tractorPreassignmentsSortable").jqxSortable();
            $('#tractorPreassignmentsSortable').on('stop', function (event) { 
                var order = $('#tractorPreassignmentsSortable').jqxSortable('toArray');
                var newArray = order.map(function(orderid, index) {
                    var item = self.tractorPreassignments().find(function(v) { return v.movementId() == orderid } )
                    item.sequence(index + 1);
                    return item;
                });
                self.tractorPreassignments([]);
                self.tractorPreassignments(newArray);
    
            });  
        
        }, 100)
    }
}
class TractorPreassignment {
    constructor(preassignment) {
        var self = this;
        preassignment = preassignment || {};
        self.movementId = ko.observable(preassignment.movementId);
        self.date = ko.observable(preassignment.date);
        self.destination = ko.observable(preassignment.destination);
        self.distance = ko.observable(preassignment.distance);
        self.id = ko.observable(preassignment.id);
        self.orderExId = ko.observable(preassignment.orderExId);
        self.orderId = ko.observable(preassignment.orderId);
        self.origin = ko.observable(preassignment.origin);
        self.sequence = ko.observable(preassignment.sequence);
        self.tractorId = ko.observable(preassignment.tractorId);
        self.driver1Id = ko.observable(preassignment.driver1Id);
        self.driver2Id = ko.observable(preassignment.driver2Id);
        self.trailerId = ko.observable(preassignment.trailerId);
    }
}


export default {viewModel: TractorPreassignmentVM, template: template}