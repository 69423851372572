import dataModel from 'data-model';
import { noop } from 'global-functions';
import { showconfirm } from 'show-dialog-methods';

const fetchContactsAsync = () => {
    return new Promise((resolve) => {
        dataModel.ajaxRequest("Contacts/Management").then(resolve).fail((err) => resolve({}));
    })
}

const deleteContactAsync = (id) => {
    return new Promise((resolve) => {
        dataModel.ajaxRequest("Contacts/"+id, "DELETE", null, true).then(resolve).fail((err) => resolve());
    })
}

const sendContactUpsertAsync = ({
    id = "",
    contact = "",
    description = "",
    phone = "",
    extension = "",
    fax = "",
    email = "",
    departmentId = "",
    companyIds = []
} = {}) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Contacts", "POST", 
        {
            id,
            contact,
            description,
            phone,
            extension,
            fax,
            email,
            departmentId,
            companyIds
        })
        .then(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred procssing request.`));
    })
}

function EditContactViewModel({
    id = "",
    contact = "",
    description = "",
    phone = "",
    extension = "",
    fax = "",
    email = "",
    departmentId = "",
    companyIds = [],
    departmentOptions = [],
    companyOptions = [],
    onClose = noop
} = {}) {
    const vm = this;
    
    vm.isLoading = ko.observable(false);
    vm.errorMsg = ko.observable("");

    vm.contact = ko.observable(contact || "").extend({required: true, maxLength: 50});
    vm.description = ko.observable(description || "");
    vm.phone = ko.observable(phone || "");
    vm.extension = ko.observable(extension || "").extend({maxLength: 10})
    vm.fax = ko.observable(fax || "");
    vm.email = ko.observable(email || "").extend({email: true});

    vm.selectedDepartmentId = ko.observable(departmentId).extend({required: true});
    vm.selectedCompantIds = ko.observableArray(companyIds).extend({required: true});

    vm.departmentOptions = ko.observableArray(departmentOptions);
    vm.companyOptions = ko.observableArray(companyOptions);

    vm.handleSubmit = () => {
        vm.errorMsg("");

        var errors = ko.validation.group(vm);
        if(errors().length) {
            errors.showAllMessages();
            vm.errorMsg("Please correct any errors.");
            return false;
        }

        const data = ko.toJS(vm);

        saveContact({...data, id, departmentId: data.selectedDepartmentId, companyIds: data.selectedCompantIds});
    }

    vm.handleCancel = () => onClose();

    const saveContact = async (payload) => {
        try {
            vm.isLoading(true);
            await sendContactUpsertAsync(payload);
            vm.isLoading(false);
            onClose(true);
        }
        catch(err) {
            vm.isLoading(false);
            vm.errorMsg(err);
        }
    }

}


function ContactListManagementPage() {
    const vm = this;
    vm.isLoading = ko.observable(false);

    vm.editContactListModal = ko.observable();
    vm.contactsList = ko.observableArray([]);
    vm.companyGroups = ko.observableArray([]);
    vm.companies = ko.observableArray([]);
    vm.activeDirectory = ko.observable({});

    vm.shownContacts = ko.observableArray([]);
    vm.contactSearch = ko.observable("");
    vm.contactSearch.subscribe((val) => handleContactSearch(val));

    vm.companyFilterOptions = ko.observableArray([]);
    vm.companyFilter = ko.observableArray([]);
    vm.companyFilter.subscribe((vals) => handleCompanySearch(vals));

    vm.handleCompanyClick = (directory) => {
        if(directory) {
            vm.activeDirectory(directory);
            displayContacts(directory.id);
        }
    }

    vm.handleAddContact = () => {
        vm.editContactListModal(new EditContactViewModel({
            companyOptions: vm.companies(),
            departmentOptions: vm.companyGroups(),
            onClose: (isSuccess) => {
                if(isSuccess) {
                    loadContacts();
                }
                
                vm.editContactListModal(null)
            }
        }))
    }

    vm.handleDelete = async ({id}) => {
        const response = await showconfirm("Are you sure you want to delete?");

        if(response) {
            await deleteContactAsync(id);
            loadContacts();
        }
    }

    vm.handleEdit = (contact) => {
        vm.editContactListModal(new EditContactViewModel({
            ...contact,
            departmentId: contact.contactGroupId,
            companyOptions: vm.companies(),
            departmentOptions: vm.companyGroups(),
            onClose: (isSuccess) => {
                if(isSuccess) {
                    loadContacts();
                }
                
                vm.editContactListModal(null)
            }
        }))
    }

    vm.getContactCountForDepartment = (id) => ko.observable(vm.contactsList().filter(x => x.contactGroupId === id).length);

    const handleContactSearch = (val) => {
        if(val != null) {
            const contacts = vm.shownContacts().filter(x => x.contact && x.contact.toUpperCase().indexOf(val.toUpperCase()) > -1);
            vm.shownContacts(contacts);
        }
        else {
            displayContacts(vm.activeDirectory().id);
        }
    }

    const handleCompanySearch = (vals = []) => {
         if(vals.length) {
            const contacts = vm.shownContacts().filter(x => {
                return x.companyExIds.some(q => vals.indexOf(q) > -1)
            });

            vm.shownContacts(contacts);
        }
        else {
            displayContacts(vm.activeDirectory().id);
        }
    }

    const displayContacts = (id) => {
        if(id != null) {
            let grpFilterOptions = [];

            const contacts = vm.contactsList().filter(x => x.contactGroupId === id);
            vm.shownContacts(contacts);

            contacts.forEach(x => {
                x.companyExIds.forEach(q => {
                    if(grpFilterOptions.indexOf(q) == -1) {
                        grpFilterOptions.push(q);
                    }
                })
            });

            vm.companyFilterOptions(grpFilterOptions.map(x => ({label: x, value: x})));
        }
        else {
            vm.shownContacts([]);
            vm.companyFilterOptions([]);
        }
    }

    
    const loadContacts = async() => {
        vm.isLoading(true);
        const result = await fetchContactsAsync();
        vm.companyGroups(result.companyGroups);
        vm.contactsList(result.contacts);
        vm.companies(result.companies);

        const viewDirectory = vm.activeDirectory() && vm.activeDirectory().id > 0 ? vm.activeDirectory() : result.companyGroups[0] || {};
        vm.activeDirectory(viewDirectory);
        displayContacts(viewDirectory.id);
        vm.isLoading(false);
    }

    loadContacts();
}

import template from './contact-list-management-page.html';
export default { viewModel: ContactListManagementPage, template }