import './ratesCustomStyles.css';
import dataModel from 'data-model';
import userProfile from 'user-profile';

class RatesViewModel {
    constructor() {

        $('body').on('hidden.bs.modal', function () {
            if ($('.modal.in.show').length > 0 == false) {
                $('body').removeClass('modal-open');
            }

            if($('div.modal-backdrop.fade.show').length > 0) {
                $('div.modal-backdrop.fade.show').remove();
            }
        });

        return {
            LogError: function (error) {
                if (error) {
                    if (error.responseJSON != null && error.responseJSON.message != null) {
                        console.error(error.responseJSON.message);
                    }
                }
            },
            RemoveUnWantedGridDataFormating: function (data) {
                if (data == null || data == undefined) return false;
    
                delete data.filterGroups;
                delete data.filterscount;
                delete data.groupscount;
                delete data.pagenum;
                delete data.pagesize;
                delete data.recordendindex;
                delete data.recordstartindex;
    
                return data;
            },
            OriginDestinationTypes: new Array(
                { value: 'C', text: 'City/State/Zip' },
                { value: 'S', text: 'State' },
                { value: 'Z', text: 'Zip Code' },
                { value: 'L', text: 'Location Code' },
                { value: 'N', text: 'County/State' },
                { value: 'O', text: 'Zone' },
            ),
            GetOriginDestinationType: function (code) {
                var _odType = {
                    value: null,
                    text: null
                };
    
                if (!code || typeof code !== "string")
                    return _odType;
    
                var _code = this.OriginDestinationTypes[this.OriginDestinationTypes.map(function (x) { return x.value }).indexOf(code)];
    
                _odType.value = _code ? _code.value : null;
                _odType.text = _code ? _code.text : null;
    
                return _odType;
            },
            RateMethods: new Array(
                { value: 'F', text: 'Flat', label: 'Flat Rate' },
                { value: 'D', text: 'Distance', label: 'Per Distance Unit Rate' },
                { value: 'P', text: 'Margin %', label: ' Percentage of Total Charge' },
            ),
            isLoading: ko.observable(false),
            GetTargetMaxPayRatesType: function (code) {
                var _tmType = {
                    value: null,
                    text: null,
                    label: null
                };
    
                if (!code || typeof code !== "string")
                    return _tmType;
    
                var _code = this.RateMethods[this.RateMethods.map(function (x) { return x.value }).indexOf(code)];
                _tmType.value = _code ? _code.value : null;
                _tmType.text = _code ? _code.text : null;
                _tmType.label = _code ? _code.label : null;
    
                return _tmType;
            },
            ConfirmationModal: ko.observable(),
            GetConfirmation: function (optionsObj) {
                var vm = this;
                
                optionsObj = optionsObj || {};
    
                var _isConfirmed = ko.observable(false);
                var buttonList = [];
                
                if (optionsObj.buttons && optionsObj.buttons.length > 0) {
                    buttonList = optionsObj.buttons;
                }
                    
    
                return new Promise(function (resolve, reject) {
    
                    // Doesn't bind unless using setTimeout
                    setTimeout(function () {
    
                        var $modal = $('#confirmationModal');
    
                        var $modalHeader = $modal.find('.modal-header');
                        var $confirmBtn = $modal.find('button[data-id="confirmBtn"]');
    
                        var theme = optionsObj.theme || "danger";
                        var modalHeaderBgColor = "bg-danger";
                        var confirmBtnColor = "btn-danger";
                        var headerColor = "#fff";
    
                        if (theme == "primary") {
                            modalHeaderBgColor = "bg-primary";
                            confirmBtnColor = "btn-primary";
                        }
                        else if (theme == "default") {
                            modalHeaderBgColor = "";
                            confirmBtnColor = "";
                            headerColor = "#333";
                        }
    
                        $modalHeader.addClass(modalHeaderBgColor).css("color", headerColor);
                        $confirmBtn.addClass(confirmBtnColor);
    
                        $modal.modal("show");
    
                        $modal.on("hide.bs.modal", function () {
                            resolve(_isConfirmed());
                        });
                    }, 0);
    
                    var model = function () {
                        var self = this;
                        self.headerText = ko.computed(function () {
                            return optionsObj.headerText ? optionsObj.headerText : "Please Confirm";
                        });
                        self.confirmBtnText = ko.computed(function () {
                            return optionsObj.confirmBtnText ? optionsObj.confirmBtnText : "Confirm";
                        });
                        self.closeBtnText = ko.computed(function () {
                            return optionsObj.closeBtnText ? optionsObj.closeBtnText : "Close";
                        });
                        self.submessage = ko.observable(optionsObj.submessage);
                        self.buttonList = ko.observableArray(buttonList);
                        self.message = ko.observable(optionsObj.message ? optionsObj.message : "Are you sure?");
                        self.description = ko.observable(optionsObj.description);
                        self.setConfirm = function (choice) {
                            choice = choice || false;
                            _isConfirmed(choice);
                        };
                    }
    
                    vm.ConfirmationModal(new model());
                });
                
            }
        }
    }
}

import template from './rates-page.html';
export default { viewModel: RatesViewModel, template: template }