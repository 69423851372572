

import { OwnerOperatorRatesPage } from '../owner-operator-rates-page'
import template from './owner-operator-rates-modal-component.html'
import ko from 'knockout';
import { Observable, ObservableArray } from 'knockout'; 
import dataModel from 'data-model';

class OwnerOperatorRatesModal {
    $parent: OwnerOperatorRatesPage;
    gridrowdata: OOPayeeDefaultRateGridData;
    defaultPayMethodId : Observable<any> = ko.observable();
    defaultLoadedPayRate: Observable<number> = ko.observable();
    defaultEmptyPayRate: Observable<number> = ko.observable();
    selectedPayeeId: number;
    agencyEarnDeductDefaultRecords: ObservableArray<OOPayeeEarnDeductDefaultRecord> = ko.observableArray([]);
    filterAlreadyExistingDeductionCodesSwitch = false;
    showLoadingPanel: Observable<boolean> = ko.observable();

    constructor(data) {
        this.$parent = data.parent;
        this.gridrowdata = data.data;
        this.initializeComponents();
        this.getAgencyEarnDeductDefaultRecords();
    }

    initializeComponents() {
        this.selectedPayeeId = this.gridrowdata.payeeId;
        this.defaultPayMethodId(this.gridrowdata.defaultPayMethodId || 4);
        this.defaultLoadedPayRate(this.gridrowdata.defaultLoadedPayRate || 75.00);
        this.defaultEmptyPayRate(this.gridrowdata.defaultEmptyPayRate || 0);
    }

    getAgencyEarnDeductDefaultRecords() {
        dataModel.ajaxRequest("CustomPaySolutions/GetPayeeEarnDeductDefaultRecords", "get", {selectedPayeeId: this.selectedPayeeId})
        .done((v) => {
            this.agencyEarnDeductDefaultRecords(v.map((x) => new OOPayeeEarnDeductDefaultRecord(x)))
            setTimeout(() => {
                this.filterAlreadyExistingDeductionCodesSwitch = true;
            }, 1000)
        })
    }

    filterAlreadyExistingDeductionCodes = (data) => {
        if(data.length > 1 && this.filterAlreadyExistingDeductionCodesSwitch == true) {
            data = data.filter(resp => {
                let doesElementExist = this.agencyEarnDeductDefaultRecords().some(existing => {
                    return resp.id == existing.deductEarningId;
                })
                return !doesElementExist;
            })
        }
        return data;
    }

    deleteRecord = (data) => {
        this.agencyEarnDeductDefaultRecords.remove(data)
    }

    addDefaultPayRate() {
        this.agencyEarnDeductDefaultRecords.push(new OOPayeeEarnDeductDefaultRecord())
    }

    savePayeeDefaultRates = () => {
        let payload = {
            selectedId: this.selectedPayeeId,
            defaultEmptyPayRate: this.defaultEmptyPayRate(),
            defaultLoadedPayRate: this.defaultLoadedPayRate(),
            defaultPayMethodId: this.defaultPayMethodId() ? this.defaultPayMethodId().value : undefined,
            agencyEarnDeductDefaultDTOs: this.agencyEarnDeductDefaultRecords()
                .map((x) => x.obj).filter((x) => x.deductEarningId)
        }

        this.showLoadingPanel(true);
        dataModel.ajaxRequest("CustomPaySolutions/SavePayeeEarnDeductDefaultRecords", "post", payload)
        .done((x) => {
            this.$parent.ownerOperatorRatesGrid.grid.updatebounddata('data');
            $('#closeButton').trigger("click");
        }).always((x) => {
            this.showLoadingPanel(false);
        })
    }
}

class OOPayeeEarnDeductDefaultRecord {
    id: number;
    defaultPct: Observable<number>;
    deductEarningId: number;
    externalID: string;
    deductionCodeInput: Observable<any>;
    description: Observable<string>;
    constructor(x?) {
        x = x || {};
        this.id = x.id;
        this.deductEarningId = x.deductEarningId;
        this.externalID = x.externalID;
        this.description = ko.observable(x.description);
        this.defaultPct = ko.observable(x.defaultPct || 0);
        this.deductionCodeInput = ko.observable(x.externalID);
    }
    onDeductionCodeSelected = (v) => {
        if(v) {
            this.deductEarningId = v.id;
            this.description(v.description);
            this.externalID = v.code
            if(v.code.toUpperCase() == "FSC") {
                this.defaultPct(100);
            }
        }
    }
    get obj() {
        return {
            deductEarningId : this.deductEarningId,
            defaultPct: this.defaultPct
        }
    }
}

class OOPayeeDefaultRateGridData {
    payeeId: number;
    externalID: string;
    name: string;
    isActive: string;
    customPay: boolean;
    contractDate: Date;
    terminationDate: Date;
    defaultPayMethodId: number;
    defaultLoadedPayRate: number;
    defaultEmptyPayRate: number;
}

export default { template: template, viewModel: OwnerOperatorRatesModal }
export { OwnerOperatorRatesModal }