import dataModel from "data-model";
import userProfile from "user-profile";
import { setBtnDisabledState, setBtnEnabledState } from "global-functions";

class CustomFieldNamesViewModel {
    constructor(params) {
        params = params || {};

        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};
        this.isProcessing = ko.observable(false);

        this.customField1 = this.parentModel.customField1 || ko.observable();
        this.customField2 = this.parentModel.customField2 || ko.observable();
        this.customField3 = this.parentModel.customField3 || ko.observable();

        this.errorMsg = ko.observable();
        this.$emitsOnAction();
    }

    handleResetCustomField = (data, event) => {
        this.doHandleResetCustomField(data, event);
    };

    async doHandleResetCustomField(data, event) {
        const id = event.currentTarget.id;
        setBtnDisabledState(event, "Reseting...");
        const result = await this.http_sendResetCustomFieldAsync(id).catch(
            (err) => console.error(err)
        );
        setBtnEnabledState(event, "Reset");

        if (result != undefined) {
            this.$emitsOnAction();
        }
    }

    handleSaveCustomField = (data, event) => {
        this.doHandleSaveCustomField(data, event);
    };

    async doHandleSaveCustomField(data, event) {
        this.errorMsg("");
        this.customFieldFocused = event.currentTarget.id;
        const payload = {
            fieldName: this.customFieldFocused,
            agency: dataModel.currentAgencyId(),
            newFieldName:
                this.customFieldFocused == "SaveCustomField1"
                    ? this.customField1()
                    : this.customFieldFocused == "SaveCustomField2"
                    ? this.customField2()
                    : this.customFieldFocused == "SaveCustomField3"
                    ? this.customField3()
                    : null,
        };

        if(payload.newFieldName && payload.newFieldName.length > 20) {
            this.errorMsg("Custom names must be 20 characters or less: (" + payload.newFieldName +")");
            return false;
        }

        setBtnDisabledState(event, "Saving...");
        await this.http_sendSaveCustomFieldAsync(payload).catch((err) =>
            console.error(err)
        );
        setBtnEnabledState(event, "Save");
    }

    http_sendResetCustomFieldAsync = (id) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/ResetAgencyCustomFields", "put", id)
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };

    http_sendSaveCustomFieldAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/SaveAgencyCustomFields", "put", payload)
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };
}

import template from "./custom-field-names-component.html";
export default { viewModel: CustomFieldNamesViewModel, template: template };
