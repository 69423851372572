import dataModel from "data-model";
import userProfile from "user-profile";
import gridState from "jqx.grid-state-component";
import { isEmpty, setBtnEnabledState, setBtnDisabledState } from "global-functions";
import gridUtils from "jqx.grid-utils";
import router from 'router';
import { showmessage, showconfirm } from "show-dialog-methods";
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";


const saveCustomerTrackingAsync = ({customerId, trackingVendorId, mappedVendorId, agencyId}) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Customer/UpdateTracking", "POST", {customerId, trackingVendorId, mappedVendorId, agencyId})
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred.`));
    })
}

// Currently these two api methods are for admin(s) only.
const deleteCustomerTrackingForAgencyAsync = ({customerId, agencyId}) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Customer/RemoveAgencyFromCustomerTracking", "POST", {customerId,agencyId})
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred.`));
    })
}

const addCustomerTrackingForAgencyAsync = ({customerId, agencyId}) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Customer/AddAgencyToCustomerTracking", "POST", {customerId,agencyId})
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred.`));
    })
}

const saveCustomerCommentAsync = ({id, comment, customerId}) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Customer/UpsertCustomerComment", "POST", {id, comment, customerId})
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred.`));
    })
}

const deleteCustomerCommentAsync = (id) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest(`Customer/DeleteCustomerComment/${id}`, "DELETE", null, true)
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred.`));
    })
}

const fetchAgencySCACList = () => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest(`Customer/GetAgencySCACList`)
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred.`));
    })
}

const postCustomerAgencySCAC = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest(`Customer/UpsertSCACOverride`, "POST", payload)
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred.`));
    })
}

const editCustomerAgencySCACViewModel = function({agencyId, customerId, defaultAgencySCAC = "", onLoadingFn = (x) => {}}) {
    const vm = this;

    //vm.agencySCACList = ko.observableArray([]);

    vm.overrideCodeInput = ko.observable(defaultAgencySCAC);
    //vm.selectedOverrideCode = ko.observable(defaultAgencySCAC || "");
    vm.error = ko.observable();

    //vm.overrideCodeInput.subscribe((val) => val && vm.selectedOverrideCode(undefined));
    //vm.selectedOverrideCode.subscribe((val) => val && vm.overrideCodeInput(""));

    vm.handleSaveSCAC = async() => {
        try {
            // if((vm.overrideCodeInput() && defaultAgencySCAC.toUpperCase() === vm.overrideCodeInput().toUpperCase()) 
            //     || (vm.selectedOverrideCode() && defaultAgencySCAC.toUpperCase() == vm.selectedOverrideCode().toUpperCase())) {
            //     return false;
            // }

            if(!vm.overrideCodeInput()) {
                vm.error(`Override SCAC is required.`);
            }
            else if(vm.overrideCodeInput().length > 4) {
                vm.error(`SCAC cannot be longer than 4 characters.`);
            }

            const data = ko.toJS(vm);

            onLoadingFn(true)
            await postCustomerAgencySCAC({agencyId, customerId, SCACOverride: data.overrideCodeInput})

            //getAgencyScacs();

            onLoadingFn(false)
        }
        catch(err) {
            onLoadingFn(false)
            vm.error(err);
        }
    }

    const getAgencyScacs = async () => {
         try {
            const result = await fetchAgencySCACList();

            vm.overrideCodeInput(result[0]);
        }
        catch(err) {
            console.error(err);
        }
    }

    getAgencyScacs();
}

const customerCommentModel = function({
    id = 0,
    comment = "",
    enteredBy = "",
    agencyId = 0,
    customerId = 0,
    canEdit = false,
    isEditMode = false
} = {}) {
    const vm = this;

    vm.id = ko.observable(id);
    vm.comment = ko.observable(comment).extend({required: true, notify: 'always'});
    vm.enteredBy = ko.observable(enteredBy);
    vm.canEdit = ko.observable(canEdit ?? false);
    vm.agencyId = ko.observable(agencyId);
    vm.customerId = ko.observable(customerId);

    vm.isEditMode = ko.observable(isEditMode ?? false);
}

var addCustomerRequiredFieldViewModel = function (parentModel) {
    var self = this;
    parentModel = parentModel || {};
    var customer = parentModel.code != undefined ? parentModel.code() : "";

    self.customerRequiredFieldsErrorMessage = ko.observable("");

    self.showFieldName = ko.observable(false);
    self.pageList = ko.observableArray();
    self.isLoading = ko.observable(false);

    self.selectedCustomer = ko.validatedObservable(customer).extend({ required: { message: "Please select a customer" } });
    self.selectedCustomer.subscribe(function (newVal) {
        if (self.selectedCustomer.isValid()) {
            self.showFieldName(true);
            self.isCustomerSelected(true);
        } else {
            self.showFieldName(false);
            self.fieldName(undefined);
            self.fieldName.isModified(false);
            self.isCustomerSelected(false);
        }
    });

    self.isCustomerSelected = ko.observable(false);
    self.CustomerSelected = function (customer) {};

    self.isFieldNameSelected = ko.observable(false);

    self.fieldName = ko.validatedObservable().extend({
        required: {
            message: "Field Name is required",
        },
    });

    self.fieldName.subscribe(function (newVal) {
        if (newVal != undefined) {
            self.isFieldNameSelected(true);
        } else {
            self.isFieldNameSelected(false);
        }
    });

    self.pageName = ko.validatedObservable().extend({
        required: {
            message: "Please select page",
        },
    });
    self.isPageNameSelected = ko.observable(false);

    self.pageName.subscribe(function (newVal) {
        self.isPageNameSelected(true);
    });

    self.isValid = ko.pureComputed(function () {
        return self.isFieldNameSelected() && self.isPageNameSelected() && self.isCustomerSelected();
    });

    self.isPageAndCustomerSelected = ko.pureComputed(function () {
        return self.isPageNameSelected() && self.isCustomerSelected();
    });

    self.isSaving = false;

    self.saveCustomerRequiredField = function (data, event) {
        //Preventing double saves on double-click
        if (!self.isSaving) {
            self.isSaving = true;
            self.customerRequiredFieldsErrorMessage("");

            self.errors = ko.validation.group([self.fieldName, self.pageName, self.selectedCustomer]);
            if (self.errors().length == 0) {
                var button = event.currentTarget;
                button.innerHTML = 'Saving <span class="spinner"></span>';
                button.disabled = true;

                var payload = {
                    customerId: data.selectedCustomer(),
                    fieldNameId: data.fieldName(),
                    agencyId: null, // This is a use case requirement. Agency Id must be null for saving items from this page (CustomerCredit)
                };

                dataModel
                    .ajaxRequest("MyAgency/SaveCustomersRequiredField", "post", payload, true)
                    .done(function (data, status, xhr) {
                        if (data.success) {
                            //getAgencyCounts();
                            setTimeout(function () {
                                button.innerHTML = "Saved";
                                button.classList.add("done");
                            }, 750);
                            setTimeout(function () {
                                button.innerHTML = "Save";
                                button.disabled = false;
                                button.classList.remove("done");
                                $("#addCustomersRequiredFieldsModal").modal("hide");
                                $("#jqxCustomerRequiredFieldsGrid").jqxGrid("updatebounddata", "data");
                            }, 1200);
                        } else {
                            if (data.errorMessage) {
                                self.customerRequiredFieldsErrorMessage(data.errorMessage);
                                button.innerHTML = "Save";
                                button.disabled = false;
                                button.classList.remove("done");
                                self.isSaving = false;
                            }
                            //mainModel.buttonFail(button);
                        }
                    })
                    .fail(function (error, msg, d) {
                        if (msg) {
                            self.customerRequiredFieldsErrorMessage(msg);
                            button.innerHTML = "Save";
                            button.disabled = false;
                            button.classList.remove("done");
                            self.isSaving = false;
                        }
                        //mainModel.buttonFail(button);
                    });
            } else {
                self.errors.showAllMessages();
            }
        }
    };

    self.initModalState = function () {
        var data = {
            agencyId: null,
            customerId: null,
        };

        dataModel
            .ajaxRequest("MyAgency/GetCustomerRequiredPages", "get", data)
            .done(function (data, status, xhr) {
                data = data || {};
                self.pageList(data.pageList);
                parentModel.isMyCustomersRequiredFieldsGridLoading(false);
            })
            .fail(function (error, msg, d) {
                parentModel.isMyCustomersRequiredFieldsGridLoading(false);
            });
    };

    self.initModalState();
};

var editCustomerRequiredFieldsViewModel = function (parentModel, datarow) {
    var self = this;
    self.customerRequiredFieldsErrorMessage = ko.observable("");
    self.showSaveButton = ko.pureComputed(function () {
        return self.isValidName() && self.hasFieldNameChanged();
    });
    self.requiredFieldId = ko.observable(datarow.id);
    self.initLoad = true;
    self.hasFieldNameChanged = ko.observable(false);
    self.pageName = ko.observable([{ id: datarow.pageId, name: datarow.page }]).extend({
        required: {
            message: "Please select page",
        },
    });

    self.pageNamePlaceholder = ko.observable(datarow.page || "Please Choose..");
    self.originalFieldName = ko.observable(datarow.fieldNameId);
    self.fieldName = ko.validatedObservable(datarow.fieldName).extend({
        required: {
            message: "Field Name is required",
        },
    });
    self.customerName = ko.observable(datarow.customer);
    self.customerId = ko.observable(datarow.customerId);
    self.fieldName.subscribe(function (newVal) {
        if (self.originalFieldName() != newVal) {
            self.hasFieldNameChanged(true);
        } else {
            self.hasFieldNameChanged(false);
        }
    });

    self.pageList = ko.observableArray();
    self.isValidName = ko.pureComputed(function () {
        return self.fieldName.isValid();
    });
    var data = {
        customerId: self.customerId(),
        agencyId: userProfile.currentAgencyId(),
    };
    dataModel
        .ajaxRequest("MyAgency/GetCustomerRequiredPages", "get", data)
        .done(function (data, status, xhr) {
            data = data || {};
            self.pageList(data.pageList);
        })
        .fail(function (error, msg, d) {
            parentModel.isMyCustomersRequiredFieldsGridLoading(false);
        });
    self.isLoading = ko.observable(false);
    self.editCustomersRequiredField = function () {
        self.customerRequiredFieldsErrorMessage("");
        var button = event.currentTarget;
        button.innerHTML = 'Saving <span class="spinner"></span>';
        button.disabled = true;

        var data = {
            Id: self.requiredFieldId(),
            customerId: datarow.customerId,
            fieldNameId: self.fieldName(),
            agencyId: null, // Use case for this page is null
        };

        dataModel
            .ajaxRequest("MyAgency/EditCustomersRequiredField", "put", data, true)
            .done(function (data, status, xhr) {
                if (data.success) {
                    setTimeout(function () {
                        button.innerHTML = "Saved";
                        button.classList.add("done");
                    }, 750);

                    setTimeout(function () {
                        button.innerHTML = "Save";
                        button.disabled = false;
                        button.classList.remove("done");
                        $("#editCustomersRequiredFieldsModal").modal("hide");
                        $("#jqxCustomerRequiredFieldsGrid").jqxGrid("updatebounddata", "data");
                    }, 1100);
                    parentModel.isMyCustomersRequiredFieldsGridLoading(false);
                } else {
                    if (data.errorMessage) {
                        self.customerRequiredFieldsErrorMessage(data.errorMessage);
                        button.innerHTML = "Save";
                        button.disabled = false;
                        button.classList.remove("done");
                    }

                    parentModel.isMyCustomersRequiredFieldsGridLoading(false);
                }
            })
            .fail(function (error, msg, d) {
                if (msg) {
                    self.customerRequiredFieldsErrorMessage(msg);
                    self.customerRequiredFieldsErrorMessage(data.errorMessage);
                    button.innerHTML = "Save";
                    button.disabled = false;
                    button.classList.remove("done");
                }

                parentModel.isMyCustomersRequiredFieldsGridLoading(false);
            });
    };
};

var customerEditViewModel = function (id, dropdownItems, mainModel) {
    var self = this;
    mainModel = mainModel || {};

    self.isLoading = ko.observable();
    self.name = ko.observable();
    self.code = ko.observable();
    self.customerId = id;
    self.creditStatus = ko.observable();
    self.creditLimit = ko.observable();
    self.balance = ko.observable();
    self.netUnpostedTotal = ko.observable();
    self.balancePlusNetUnpostedTotal = ko.observable();
    self.cannotBeBrokered = ko.observable(false);

    self.isRequiredFieldsEditor = ko.observable(false);

    self.startDate = ko.observable();
    self.chargeCodeId = ko.observable();
    self.fuelBasePrice = ko.observable();
    self.fuelPriceVariance = ko.observable();
    self.fuelSurchargeMethod = ko.observable();
    self.endDate = ko.observable();
    self.amount = ko.observable();
    self.percent = ko.observable();
    self.fuelRegion = ko.observable();
    self.readOnly = ko.observable(true);
    self.chargeCodes = ko.observable(dropdownItems.chargeCodes);
    self.fuelPriceRegions = ko.observable(dropdownItems.fuelPriceRegions);

    self.selectedChargeCode = ko.observable();

    self.selectedChargeCode.subscribe(function (newValue) {
        self.fuelSurchargeMethod(newValue.payMethodType);
    });

    self.startDate.subscribe(function () {
        if (self.endDate() < self.startDate()) {
            self.endDate(self.startDate());
        }
    });

    self.endDate.subscribe(function () {
        if (self.startDate() > self.endDate()) {
            self.startDate(self.endDate());
        }
    });


    // Customer Tracking
    self.isAgencyTracking = ko.observable(false);
    self.trackingVendorList = ko.observableArray([]);
    
    const originalCustomerTrackingVendor = ko.observable();
    const orignalMappedVendor = ko.observable();
    self.customerTrackingVendor = ko.observable();
    self.mappedVendorList = ko.observableArray([]);
    self.mappedVendor = ko.observable();
    self.trackingVendorSelectReadOnly = ko.pureComputed(() => self.isAdmin() === false && originalCustomerTrackingVendor() > 0)
    self.canViewEditTracking = ko.observable(false);
    self.canViewEditMappedVendor = ko.observable(false);
    
    self.defaultAgencySCAC = ko.observable();
    self.displayEditCustomerAgencySCACVM = ko.pureComputed(() => self.isEmployee() && originalCustomerTrackingVendor() > 0)
    self.editCustomerAgencySCACVM = ko.pureComputed(() => 
            new editCustomerAgencySCACViewModel({agencyId: userProfile.currentAgencyId(), customerId: id, defaultAgencySCAC: self.defaultAgencySCAC(), onLoadingFn: self.isLoading}));

    self.canViewEditVendors = ko.pureComputed(() => self.canViewEditMappedVendor() || self.canViewEditTracking());
    self.saveTrackingBtnTxt = ko.pureComputed(() => {
        if(self.isAdmin()) {

            if(originalCustomerTrackingVendor() > 0 === false) return 'Update & Enable Tracking';

            return 'Update';
        }

        if(originalCustomerTrackingVendor() > 0) return "Enable Tracking";
        return "Save & Enable Tracking"
    });

    self.saveTrackingBtnEnabled = ko.pureComputed(() => {
        if(self.isAdmin()) {
            return self.customerTrackingVendor() != originalCustomerTrackingVendor() || self.customerTrackingVendor() > 0 && orignalMappedVendor() != self.mappedVendor();
        } 
        
        return self.isAgencyTracking() === false ? self.customerTrackingVendor() > 0 : self.customerTrackingVendor() > 0 === false;
    });

    self.showAdminBtns = ko.pureComputed(() => self.isAdmin() && originalCustomerTrackingVendor() > 0);

    self.handleSaveTrackingVendors = async (_, event) => {
        try {

            if(self.canViewEditVendors() === false) return false;

            const trackingVendor = self.customerTrackingVendor();
            const mappedVendor = trackingVendor > 0 ? self.mappedVendor() : null;

            if(self.isAdmin()) {
                if(!trackingVendor) {
                    const answer = await showconfirm(`Setting the tracking vendor to 'None', will remove required tracking for the customer and all linked agencies. 
                                                      If you only want to disable tracking for this agency, then select 'No' and use the 'Remove Agency Tracking' option. 
                                                      Do you wish to continue?`);
                    if(!answer) {
                        self.customerTrackingVendor(originalCustomerTrackingVendor());
                        self.mappedVendor(orignalMappedVendor());
                        return false;
                    }
                }
                else if(originalCustomerTrackingVendor() > 0 && self.isAgencyTracking() === false) {
                    const answer = await showconfirm(`This will update the customer tracking and enable tracking for this agency. Do you wish to continue?`);
                    if(!answer) {
                        self.customerTrackingVendor(originalCustomerTrackingVendor());
                        self.mappedVendor(orignalMappedVendor());
                        return false;
                    }
                }
            }
            
            setBtnDisabledState(event, 'Saving...');
            await saveCustomerTrackingAsync({customerId: self.customerId, trackingVendorId: trackingVendor, mappedVendorId: mappedVendor, agencyId: userProfile.currentAgencyId() });
            setBtnEnabledState(event, self.saveTrackingBtnTxt());

            self.isAgencyTracking(trackingVendor > 0);
            originalCustomerTrackingVendor(trackingVendor);
            orignalMappedVendor(mappedVendor);
        }
        catch(err) {
            setBtnEnabledState(event, self.saveTrackingBtnTxt());
            showmessage(err);
        }
    }

    self.handleDeleteCustomerAgencyTracking = async (_, event) => {
        try {
            if(self.isAdmin() === false) return;

            setBtnDisabledState(event, 'Processing...');
            await deleteCustomerTrackingForAgencyAsync({customerId: self.customerId, agencyId: userProfile.currentAgencyId() });
            self.isAgencyTracking(false);
            setBtnEnabledState(event, 'Remove Agency Tracking');
        }
        catch(err) {
            setBtnEnabledState(event, 'Remove Agency Tracking');
            showmessage(err);
        }
    }

    self.handleAddCustomerAgencyTracking = async (_, event) => {
        try {
            if(self.isAdmin() === false) return;

            setBtnDisabledState(event, 'Processing...');
            await addCustomerTrackingForAgencyAsync({customerId: self.customerId, agencyId: userProfile.currentAgencyId() });
            self.isAgencyTracking(true);
            setBtnEnabledState(event, 'Enable Agency Tracking');
        }
        catch(err) {
            setBtnEnabledState(event, 'Enable Agency Tracking');
            showmessage(err);
        }
    }

    self.isAdmin = ko.observable(false);
    self.isEmployee = ko.observable(false);
    userProfile.roles().then((roles) => {
        self.canViewEditTracking(roles.some(x => ['Administrators', 'Agent', 'Agency Manager'].indexOf(x) > -1));
        self.canViewEditMappedVendor(roles.some(x => ['Administrators'].indexOf(x) > -1));
        self.isAdmin(roles.some(x => ['Administrators'].indexOf(x) > -1))
        self.isEmployee(self.isAdmin() || roles.some(x => ['Greatwide Employees'].indexOf(x) > -1))
    })

    // =============================================================================
    // Customer Required Fields Grid And Observable Data

    self.isMyCustomersRequiredFieldsGridLoading = ko.observable(false);
    self.myCustomersRequiredFieldsGridState = null;
    var custReqFieldgrid = null;

    self.loadDefaultFiltersMyCustomersRequiredFieldsGrid = function () {
        dataModel
            .ajaxRequest("UserProfile/GetUserSearchFilters", "get", {
                name: "myCustomersRequiredFieldsGrid",
                pageName: window.location.pathname,
            })
            .done(function (data) {
                if (isEmpty(data) == false) {
                    var state = JSON.parse(data);
                    self.myCustomersRequiredFieldsGridState = state.grid;
                    self.loadMyCustomersRequiredFieldsGrid();
                } else {
                    self.loadMyCustomersRequiredFieldsGrid();
                }
            })
            .fail(function () {
                self.loadMyCustomersRequiredFieldsGrid();
            });
    };

    self.loadMyCustomersRequiredFieldsGrid = function () {
        custReqFieldgrid = $("#jqxCustomerRequiredFieldsGrid");

        var columns = [
            {
                text: "",
                width: 60,
                filterable: false,
                sortable: false,
                columnType: "button",
                buttonclick: function (row) {
                    self.isMyCustomersRequiredFieldsGridLoading(true);
                    var datarow = $("#jqxCustomerRequiredFieldsGrid").jqxGrid("getrowdata", row);

                    var editCustomerRequiredFieldsModel = new editCustomerRequiredFieldsViewModel(self, datarow);
                    mainModel.editCustomerRequiredFieldModal(editCustomerRequiredFieldsModel);
                    self.isMyCustomersRequiredFieldsGridLoading(false);
                },
                cellsrenderer: function () {
                    return "Edit";
                },
            },
            { text: "Id", datafield: "id", hidden: true },
            { text: "Page Id", datafield: "pageId", hidden: true },
            { text: "Page", datafield: "page", hidden: true },
            { text: "Field Name", datafield: "fieldName" },
            { text: "Field Name Id", datafield: "fieldNameId", hidden: true },
            { text: "Customer Id", datafield: "customerId", hidden: true },
            { text: "Customer", datafield: "customer", hidden: true },
            { text: "Always Required", datafield: "alwaysRequired", hidden: true },
            {
                text: "",
                width: 60,
                filterable: false,
                sortable: false,
                columnType: "button",
                buttonclick: function (row) {
                    self.isMyCustomersRequiredFieldsGridLoading(true);
                    var datarow = $("#jqxCustomerRequiredFieldsGrid").jqxGrid("getrowdata", row);
                    self.deleteCustomersRequiredField(datarow);
                },
                cellsrenderer: function () {
                    return "Delete";
                },
            },
        ];

        // Remove options depending on if they have access rights or not
        if (self.isRequiredFieldsEditor() == false) {
            columns.splice(0, 1); // remove edit button
            columns.splice(columns.length - 1, 1); // remove delete button
        }

        var source = {
            url: "Customer/GetCustomerRequiredFieldsForCredit",
            datatype: "json",
            formatdata: function (data) {
                self.isMyCustomersRequiredFieldsGridLoading(true);
                var filterinfo = custReqFieldgrid.jqxGrid("getfilterinformation");
                data = gridUtils.formatGridFilters(filterinfo, data);

                data.agencyId = userProfile.currentAgencyId();
                data.customerId = self.customerId;

                return data;
            },
            datafields: [
                { name: "id", type: "int" },
                { name: "pageId", type: "int" },
                { name: "fieldName", type: "string" },
                { name: "fieldNameId", type: "int" },
                { name: "alwaysRequired", type: "bool" },
                { name: "customer", type: "string" },
                { name: "page", type: "string" },
                { name: "customerId", type: "number" },
            ],

            loadComplete: function (data) {
                self.isMyCustomersRequiredFieldsGridLoading(false);
            },
            loadError: function (error) {
                self.isMyCustomersRequiredFieldsGridLoading(false);
            },
        };

        var dataAdapter = dataModel.getDataAdapter(source);

        custReqFieldgrid.jqxGrid({
            theme: "GWTMDark",
            width: "100%",
            source: dataAdapter,
            altrows: true,
            showstatusbar: false,
            sortable: true,
            pageable: true,
            filterable: true,
            showfilterrow: true,
            virtualmode: false,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            statusbarheight: 35,
            showtoolbar: true,
            toolbarheight: window.innerWidth > 768 ? 35 : 80,
            columns: columns,
            rendertoolbar: function (toolbar) {
                var myCustomersRequiredFieldsGridTmpl = $("#myCustomersRequiredFieldsGridToolbar");
                toolbar.append(myCustomersRequiredFieldsGridTmpl);

                var vm1 = new gridState.viewModel(),
                    tmpl1 = gridState.template;

                vm1.actions.pop();
                vm1.clearFilters = function () {
                    custReqFieldgrid.jqxGrid("clearfilters");
                };

                vm1.setDefaultSearch = function () {
                    var filters = {};
                    return filters;
                };
                var tdMyCustomersRequiredFieldsGridAction = myCustomersRequiredFieldsGridTmpl.find(
                    "#tdMyCustomersRequiredFieldsGridAction"
                );
                tdMyCustomersRequiredFieldsGridAction.append(tmpl1);
                $(toolbar).append(tmpl1);
                ko.applyBindingsToDescendants(vm1, tdMyCustomersRequiredFieldsGridAction[0]);
            },
            rendergridrows: function (obj) {
                return obj.data;
            },
            ready: function () {
                self.loadMyCustomersRequiredFieldsGridState(custReqFieldgrid, self.myCustomersRequiredFieldsGridState);
                //_myCustomersRequiredFieldsInitLoad = false;
            },
        });
    };

    self.loadMyCustomersRequiredFieldsGridState = function (grid, state) {
        if (state != null) {
            var gridState = grid.jqxGrid("getstate");
            state.selectedrowindex = -1;
            state.selectedrowindexes = [];
            state.pagenum = 0;
            state.height = gridState.height;
            state.pagesize = gridState.pagesize;
            state.pagesizeoptions = gridState.pagesizeoptions;
            grid.jqxGrid("loadstate", state);
        } else {
            grid.jqxGrid("loadstate");
        }
    };

    self.refreshMyCustomersRequiredFieldsGrid = function () {
        $("#jqxCustomerRequiredFieldsGrid").jqxGrid("updatebounddata", "data");
    };

    self.deleteCustomersRequiredField = function (datarow) {
        var data = {
            Id: datarow.id,
            customerId: datarow.customerId,
            agencyId: userProfile.currentAgencyId(),
        };

        dataModel
            .ajaxRequest("MyAgency/DeleteCustomersRequiredField", "delete", data)
            .done(function (data, status, xhr) {
                $("#jqxCustomerRequiredFieldsGrid").jqxGrid("updatebounddata");
                self.isMyCustomersRequiredFieldsGridLoading(false);
            })
            .fail(function (error, msg, d) {
                self.isMyCustomersRequiredFieldsGridLoading(false);
            });
    };

    self.addCustomerRequiredField = function () {
        var addCustomerRequiredFieldModel = new addCustomerRequiredFieldViewModel(self);
        mainModel.addCustomerRequiredFieldModal(addCustomerRequiredFieldModel);
    };

    self.customerComments = ko.observableArray([]);
    self.handleAddNewComment = () => self.customerComments.push(new customerCommentModel({customerId: self.customerId, isEditMode: true, enteredBy: userProfile.userName, canEdit: true}))
    self.handleEditComment = (model) => model.isEditMode(true)
    self.handleSaveComment = async (model) => {
       try {

            const errors = ko.validation.group(model);
            if(errors().length > 0) {
                errors.showAllMessages();
                return false;
            }
            self.isLoading(true);
            const id = await saveCustomerCommentAsync(ko.toJS(model));
            self.isLoading(false);
            
            model.isEditMode(false);
            model.id(id);
       }
       catch(err) {
        self.isLoading(false);
        console.error(err);
       }
    }
    self.handleCancelEditComment = (model) => {
        if(model.id > 0 === false) {
            self.customerComments.pop();
        }
        else {
            model.isEditMode(false);
        }
    }
    self.handleDeleteComment = async({id}) => {
        try {
            if(id() > 0) {
               const response = await showconfirm(`Are you sure you want to delete the customer comment?`);

               if(response) {
                self.isLoading(true);
                await deleteCustomerCommentAsync(id());
                self.isLoading(false);

                const items = self.customerComments().filter(x => x.id() !== id());
                self.customerComments(items);
               }
               
            }
        }
        catch(err) {
            self.isLoading(false);
            console.error(err);
        }
    }

    self.loadCustomer = function () {
        self.isLoading(true);

        dataModel
            .ajaxRequest("Customer/GetForCredit/" + id, "get")
            .done(function (response, status, xhr) {
                self.name(response.customer.name);
                self.cannotBeBrokered(response.customer.noBroker);

                self.trackingVendorList((response.trackingVendors || []).filter(x => x.description === "Trucker Tools").map(x => ({id: x.id, label: x.description})));
                originalCustomerTrackingVendor(response.customer.trackingVendorId);
                self.customerTrackingVendor(response.customer.trackingVendorId)

                self.mappedVendorList((response.mappedVendors || []).map(x => ({id: x.id, label: x.description})));
                self.mappedVendor(response.customer.mappedVendorId)
                self.isAgencyTracking(response.customer.isAgencyTracking);
                self.defaultAgencySCAC(response.defaultAgencySCAC);

                const mapped = response.customerComments.map(x => new customerCommentModel(x))
                self.customerComments(mapped)

                self.code(response.customer.externalId);
                if (response.fscRecords != null) {
                    self.startDate(response.fscRecords.startDate);
                    self.endDate(response.fscRecords.endDate);
                    self.amount(response.fscRecords.fuelAmount);
                    self.fuelBasePrice(response.fscRecords.fuelBasePrice);
                    self.percent(response.fscRecords.fuelPercent);
                    self.fuelPriceVariance(response.fscRecords.variance);
                    self.fuelRegion(response.fscRecords.fuelPriceRegionId);
                    self.chargeCodeId(response.fscRecords.chargeCodeId);
                }
                self.creditLimit(response.creditInfo[0].creditLimit);
                self.creditStatus(response.creditInfo[0].creditStatus);
                self.balance(response.creditInfo[0].balance);
                self.netUnpostedTotal(response.creditInfo[0].netunposted);
                self.balancePlusNetUnpostedTotal(response.creditInfo[0].balnetunposted);
                self.readOnly(response.admin == null ? true : !response.admin);

                self.isRequiredFieldsEditor(response.isRequiredFieldEditor);

                // Load required field user filters and grid
                self.loadDefaultFiltersMyCustomersRequiredFieldsGrid();

                self.isLoading(false);
            })
            .fail(function (error, msg, d) {
                self.isLoading(false);
            });
    };

    self.saveFSCRecord = function () {
        self.isLoading(true);
        var params = {
            customerId: self.customerId,
            startDate: self.startDate(),
            endDate: self.endDate(),
            chargeCodeId: self.chargeCodeId(),
            amount: self.amount(),
            fuelBasePrice: self.fuelBasePrice(),
            percent: self.percent(),
            variance: self.fuelPriceVariance(),
            fuelRegionId: self.fuelRegion(),
        };
        dataModel
            .ajaxRequest("Customer/UpdateFSC", "post", params)
            .done(function (data, textStatus, jqXHR) {
                self.isLoading(false);
                $("#btnSaveCustomer").notify("Customer has been saved", { position: "right", className: "info" });
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                self.isLoading(false);
            });
    };
};

class CustomerCreditViewModel {
    constructor() {
        var self = this;
        self.isLoading = ko.observable(false);
        var isInitLoad = true;

        const routeInfo = router.currentRoute();
        const externalId = routeInfo['externalid'];
        const searchMcNumber = routeInfo['?query'] && routeInfo['?query']['mcnumber'];
        
        self.urlCustomerExId = externalId;

        self.customerModal = ko.observable();
        self.dropdownItems = ko.observable();
        self.isCustomerGridLoading = ko.observable(false);

        self.addCustomerRequiredFieldModal = ko.observable();
        self.editCustomerRequiredFieldModal = ko.observable();

        //SETTING THE ACTIVE/INACTIVE OBSERVABLES TO TRUE AND FALSE, SO THAT THE INCLUDE ACTIVE WILL SHOW AS CHECKED BY DEFAULT AND INVACTIVE WILL BE UNCHECKED
        self.includeActive = ko.observable(true);
        self.includeInactive = ko.observable(self.urlCustomerExId != undefined || searchMcNumber != null);

        self.clearSelectedSavedSearchDDL = ko.observable(false);
        self.refreshSavedSearchDDL = ko.observable("");
        var $grid = $("#customerCreditGrid");
        self.handleOnSelected = (state)  => {

            //CHECK TO SEE IF API RETURNED A USER'S SAVED FILTER SETTING
            if (isEmpty(state) == false) {
                //THE TWO LINES BELOW ARE NECESSARY HERE, YOU ARE GETTING THE SAVED FILTER STATE WHICH INCLUDES ACTIVE AND INACTIVE, AND SETTING THE PRE-DEFINED OBSERVABLES TO THESE VALUES.
                //WITHOUT THIS, THE CODE JUST RUNS DEFAULT VALUES EACH TIME THE PAGE IS LOADED, WHICH SETS ACTIVE CHECKED AND INACTIVE UNCHECKED (THE DEFINED VALUES).
                
                const f = state.additionalFilters || {};
                self.includeActive(f.includeActive || false);
                self.includeInactive(f.includeInactive || false);

                //SETTING THE SELF.CUSTOMERGRIDSTATE FROM NULL TO THE STATE OF THE GRID.  IT IS THEN USED AGAIN AFTER THE CALLBACK IN THE JQ READY FUNCTION
                self.customerGridState = state.grid;
            }

            if(_customerInitLoad){
                

                self.loadCustomerCreditList();
            }
            else{
                loadGridState(state.grid, $grid)
            }
        }

        //INITIALLY CUSTOMERINITLOAD SET TO TRUE.  THE JQ GRID 'READY' FUNCTION SETS IT TO FALSE
        var _customerInitLoad = true;
        var ratingUpdated = false;

        //.SUBSCRIBE - WHEN CLICKED, TRIGGERS THESE FUNCTIONS - IN THE VIEW WE ARE BINDING THE CHECKBOXES WITH THESE FUNCTIONS
        //NEWVAL WILL COME IN AS TRUE OR FALSE, BASED ON WHETHER THE BOX IS CHECKED OR NOT.  see main.js 'gecheckbox' helper method
        self.includeActive.subscribe(function (newVal) {
            if (!_customerInitLoad) {
                self.refreshCustomerGrid();
            }
        });
        self.includeInactive.subscribe(function (newVal) {
            if (!_customerInitLoad) {
                self.refreshCustomerGrid();
            }
        });

        //BUILT-IN FEATURE OF JQX THAT UPDATES THE GRID WITH YOUR DATA...DATA IN THIS CASE INCLUDES THE FILTER OR SORTING PARAMETER YOU ARE SELECTING - THIS IS DONE IN THE BACKGROUND
        self.refreshCustomerGrid = function () {
            $("#customerCreditGrid").jqxGrid("updatebounddata", "data");
        };

        //SIMPLE TOGGLE FUNCTION TO MAKE GRID APPEAR AND DISAPPEAR
        self.toggleCustomers = function (event, args) {
            var grid = $("#customerCreditGrid");

            var isToggled = $("#divCustomerPanel").is(":visible");
            var $this = $(args.currentTarget);
            if (isToggled) {
                $this.find("i").removeClass("glyphicon-chevron-up").addClass("glyphicon-chevron-down");
            } else {
                $this.find("i").removeClass("glyphicon-chevron-down").addClass("glyphicon-chevron-up");
            }

            $("#divCustomerPanel").animate({ height: "toggle" }, "slow");
        };

        self.loadChargeCodes = function () {
            dataModel
                .ajaxRequest("Customer/GetChargeCodes", "get")
                .done(function (response, status, xhr) {
                    self.dropdownItems(response);
                })
                .fail(function (error, msg, d) {});
        };
        self.loadChargeCodes();

        //STARTS OFF AS NULL BUT BECOMES THE STATE OF THE GRID IN SELF.LOADDEFAULTFILTERS
        self.customerGridState = null;

        //THE MAIN FUNCTION OF THE PAGE AS FAR AS LOADING THE GRID DATA
        self.loadCustomerCreditList = function () {
            var grid = $("#customerCreditGrid");

            //THIS COULD CREATE ACTIVE/INACTIVE DROP DOWN TO FILTER ON CLIENT SIDE IF WE WANTED TO DO IT THIS WAY
            //var activeStatus = new Array();
            //activeStatus.push("Active");
            //activeStatus.push("Inactive");

            var initFilters = function () {
                var filtergroup1 = new $.jqx.filter();
                var filter1 = filtergroup1.createfilter("stringfilter", "Active", "equals");
                filtergroup1.addfilter(1, filter1);
                //REMOVING THE LINE BELOW REMOVES "ACTIVE" FROM THE STATUS FILTER WIDGET IN THE GRID, ALLOWING FOR INACTIVE TO BE CHECKED IN TOOLBAR AND SEARCHED
                //grid.jqxGrid('addfilter', 'status', filtergroup1);
                grid.jqxGrid("applyfilters");
            };

            const filterCustomer = ({externalId, mcNumber}) => {
                var filtergroup1 = new $.jqx.filter();

                if(externalId) {
                    var filter1 = filtergroup1.createfilter("stringfilter", externalId, "STARTS_WITH");
                    filtergroup1.addfilter(1, filter1);
                    grid.jqxGrid('addfilter', 'code', filtergroup1)
                }

                if(mcNumber) {
                    var filter2 = filtergroup1.createfilter("stringfilter", mcNumber, "STARTS_WITH");
                    filtergroup1.addfilter(1, filter2);
                    grid.jqxGrid('addfilter', 'mcNumber', filtergroup1)
                }
                
                grid.jqxGrid("applyfilters");
            }

            //SOURCE IS THE DATA USED FOR API CALL
            var source = {
                //IF YOU WANT TO SORT BY A PARTICULAR COLUMN YOU CAN UTILIZE THE FOLLOWING TWO LINES. THESE WILL BE OVERRIDDEN IF THE USER SETS AND SAVES ALTERNATIVE DEFAULT SEARCH OPTIONS
                //sortcolumn: 'name',
                //sortdirection: 'asc',

                url: "Customer/GetForGrid",
                datatype: "json",
                filter: function (filters, recordsArray) {
                    if (isInitLoad == false) {
                        grid.jqxGrid('updatebounddata', 'filter');
                    }
                },
                sort: function (column, direction) {
                    if (isInitLoad == false) {
                        grid.jqxGrid('updatebounddata', 'sort');
                    }
                },
                formatdata: function (data) {
                    //MAKING THE ACTIVE/INACTIVE BUTTONS READONLY/DISABLED WHILE API CALL OCCURS
                    self.isCustomerGridLoading(true);


                    var filterinfo = grid.jqxGrid("getfilterinformation")
                    data = gridStateUtils.formatGridFilters(filterinfo, data)

                     //PASSING THE AGENCY ID FROM DATAMODEL WHICH FINDS THE CURRENT USER AND THEIR AGENCY
                    data.agencyId = userProfile.currentAgencyId();


                    //PASSING ACTIVE AND INACTIVE BOOLS TO CONTROLLER - DEFAULTS ARE TRUE AND FALSE, THEY CAN BE CHANGED BY THE USER THROUGH BINDING AND SUBSCRIBE
                    data.includeActive = self.includeActive();
                    data.includeInactive = self.includeInactive();

                    return data;
                },

                //DATAFIELDS AND THEIR TYPES (IMPORTANT FOR SORTING / FILTERING ETC) THAT ARE BEING RETURNED IN CALLBACK - THESE MAY TYPICALLY MATCH SOME KIND OF BINDING MODEL/CLASS IN THE CONTROLLER
                datafields: [
                    { name: "id", type: "int" },
                    { name: "code", type: "string" },
                    { name: "name", type: "string" },
                    { name: "city", type: "string" },
                    { name: "state", type: "string" },
                    { name: "mcNumber", type: "string" },
                    { name: "phone", type: "string" },
                    { name: "status", type: "string" },
                    { name: "rating", type: "int" },
                ],

                //THIS IS NOT NECESSARY BECAUSE ACTIVE AND INACTIVE ARE ALREADY BEING RETURNED BUT THIS ALLOWS YOU TO FORMAT DATA BEFORE COMPLETING THE LOAD IF NEEDED
                //beforeLoadComplete: function (records) {
                //    var recordsLen = records.length;
                //    for (var i = 0; i < recordsLen; i++) {
                //        records[i].status == "Active" ? "Active" : "Inactive";
                //    }
                //    return records;
                //},

                //SETTING THE ISCUSTOMERGRIDLOADING BACK TO FALSE, WHICH IS USED IN THE .ASPX PAGE TO DETERMINE WHETHER THE ACTIVE/INACTIVE CHECKBOXES ARE READONLY OR NOT
                //IT IS INITIALIZED AS TRUE DURING THE API CALL BEFORE LOADCOMPLETE TRIGGER
                loadComplete: function (data) {

                    self.isCustomerGridLoading(false);
                    isInitLoad = false;

                    return data;
                },
                loadError: function (error) {
                    self.isCustomerGridLoading(false);
                },
            };

            var dataAdapter = dataModel.getDataAdapter(source);
            //USE COLUMNS RENDERER TO ADJUST HEADER COLUMN
            //var columnsrenderer = function (value) { return '<div style="text-align: center; margin-top: 5px;">' + value + '</div>'; }
            //THIS IS SETTING THE SCREEN HEIGHT TO THE HEIGHT OF THE SCREEN MINUS 360 PX TO ACCOUNT FOR THE GREATEDGE HEADER
            //DYNAMIC NUMBER OF ROWS IN GRIDS DEPENDING ON THE SIZE SCREEN YOU ARE LOOKING AT
            //PAGING AND PAGESIZE CAN BE REMOVED OR ADDED, HEIGHT CAN BE SET TO AUTOHEIGHT:TRUE
            var gridheightraw;
            var pxgridheight;
            var gridrows;
            var getWidth = function () {
                return "100%";
            };
            var calculateGridSize = function () {
                let modifier = window.innerWidth >= 1200 ? 1.4 : window.innerWidth >= 900 ? 1.7 : 2;

                gridheightraw = Math.ceil(window.innerHeight / modifier);
                pxgridheight = gridheightraw + "px";

                if (gridheightraw >= 500) {
                    gridrows = 20;
                } else if (gridheightraw >= 450) {
                    gridrows = 15;
                } else {
                    gridrows = 10;
                }
            };
            calculateGridSize();

            grid.jqxGrid({
                theme: "GWTMDark",
                width: "100%",
                height: pxgridheight,
                source: dataAdapter,
                altrows: true,
                //autosavestate: true,
                //    autoloadstate:true,
                //editable: true,
                //selectionmode: 'singlecell',
                autoheight: false,
                showstatusbar: true,
                sortable: true,
                pageable: true,
                pagesize: gridrows,
                filterable: true,
                showfilterrow: true,
                virtualmode: true,
                filter: gridStateUtils.handleClientSideFiltering,
                //use columns height to change header row along with renderer in columns[
                //columnsheight: 40,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                //columns: columns,
                statusbarheight: 35,
                showtoolbar: true,
                toolbarheight: window.innerWidth > 768 ? 35 : 80,
                renderstatusbar: function (statusbar) {
                    var div = $("<div>", { id: "customerRowCount", style: "padding:4px;" });
                    div.append("Number of Records: " + grid.jqxGrid("getrows").length);
                    statusbar.append(div);
                },

                //RENDERTOOLBAR IS APPENDING THE TOOLBAR (A BUILT-IN JQX FEATURE) WITH THE 'ACTION ITEMS' MENU (FROM TMPL1) AND THE ACTIVE/INACTIVE BUTTONS (FROM THE .ASPX DIV)
                rendertoolbar: function (toolbar) {
                    
                    const $grid = $("#customerCreditGrid");

                    var vm1 = new gridState.viewModel(),
                        tmpl1 = gridState.template;

                    vm1.clearFilters = function () {
                        isInitLoad = true;
                        grid.jqxGrid("clearfilters");
                        self.clearSelectedSavedSearchDDL(true);
                        initFilters();

                        
                    };

                    vm1.setDefaultSearchOverride = async () => {
                        const savedSearches = await loadSavedSearches($grid.attr("id"));
                        const filters = {
                            includeActive: self.includeActive(),
                            includeInactive: self.includeInactive(),
                            isDefault: true
                        }
        
                        vm1.loadSaveSearchModal(
                            $grid,
                            (val) => {
                                if(val && val.searchname) {
                                    self.refreshSavedSearchDDL(val.searchname)
                                }
                            },
                            savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                            filters,
                            true
                        );
                    }

                    vm1.actions.push("Save Search");

                    vm1.loadSaveSearchModalOverride = async () => {
                        const savedSearches = await loadSavedSearches($grid.attr("id"));
                        const filters = {
                            includeActive: self.includeActive(),
                            includeInactive: self.includeInactive(),
                            isDefault: false
                        }

                        vm1.loadSaveSearchModal(
                            $grid,
                            (val) => {
                                if(val && val.searchname) {
                                    self.refreshSavedSearchDDL(val.searchname)
                                }
                            },
                            savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                            filters,
                            true
                        );
        
                    }

                    var tdCustomerGridAction = $("#tdCustomerGridAction");
                    tdCustomerGridAction.append(tmpl1);
                    $(toolbar).append(tdCustomerGridAction);

                    var customerGridTmpl = $("#customerGridToolbar");
                    toolbar.append(customerGridTmpl);

                    
                    ko.applyBindingsToDescendants(vm1, tdCustomerGridAction[0]);
                },
                rendergridrows: function (obj) {
                    return obj.data;
                },
                // rendergridrows: function (obj) {
                //     return obj.data;
                // },
                //ALL OF THE COLUMNS AND THEIR CORRESPONDING JQX PROPERTIES FOR THE GRID - NEW COLUMNS SUCH AS EDIT OR VIEW CAN BE INTRODUCED HERE THAT ARE NOT A SPECIFIED DATAFIELD BEING RETURN FROM API
                columns: [
                    {
                        text: "",
                        width: 100,
                        datafield: "editButton",
                        filterable: false,
                        sortable: false,
                        pinned: true,
                        columnType: "button",
                        buttonclick: function (row) {
                            var datarow = grid.jqxGrid("getrowdata", row);
                            var customerEditModel = new customerEditViewModel(datarow.id, self.dropdownItems(), self);
                            self.customerModal(customerEditModel);
                            customerEditModel.loadCustomer();
                        },
                        cellsrenderer: function () {
                            return "View";
                        },
                    },
                    { text: "Code", datafield: "code", width: 120 },
                    { text: "MC Number", datafield: "mcNumber", cellclassname: "column", width: 120 },
                    { text: "Name", datafield: "name" },
                    { text: "Status", datafield: "status", filtertype: "checkedlist", filtercondition: "OR", filteritems: ["Active", "Inactive"], width: 150 },
                    { text: "City", datafield: "city" },
                    { text: "State", datafield: "state" },
                    {
                        //refer to carriersnew.js for notation on rating function
                        text: "Rating",
                        datafield: "rating",
                        filtertype: "list",
                        minwidth: 140,
                        maxwidth: 140,
                        filteritems: [
                            { value: 5, label: "5", html: "<img src='../Content/Images/5star.png' />" },
                            { value: 4, label: "4", html: "<img src='../Content/Images/4star.png' />" },
                            { value: 3, label: "3", html: "<img src='../Content/Images/3star.png' />" },
                            { value: 2, label: "2", html: "<img src='../Content/Images/2star.png' />" },
                            { value: 1, label: "1", html: "<img src='../Content/Images/1star.png' />" },
                        ],
                        cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                            //refer to carriersnew.js for notation on rating function
                            if (defaultHTML != null) {
                                var pk = grid.jqxGrid("getcellvalue", row, "id");
                                var fieldset = $("<fieldset>");
                                fieldset.addClass("star-rating");
                                fieldset.attr("id", "jqxRating_" + pk);

                                for (var i = 5; i > 0; i--) {
                                    var id = "jqxRating_" + pk + i;
                                    var input = $("<input>");
                                    input.attr({
                                        type: "radio",
                                        id: "star" + i,
                                        name: "rating",
                                        value: i,
                                    });
                                    input.appendTo(fieldset);
                                    var label = $("<label>").text(i);
                                    if (value == i) {
                                        label.attr({ for: "star" + i });
                                    }
                                    label.insertAfter(input);
                                    $(document)
                                        .off("click", "#jqxRating_" + pk)
                                        .on("click", "#jqxRating_" + pk, function (event) {
                                            var newRating;
                                            var previousRating;
                                            if (event.target.tagName == "LABEL") {
                                                newRating = event.target.previousSibling.value;
                                                $(event.target.parentElement.children).each(function (i) {
                                                    if (this.tagName == "LABEL") {
                                                        if (this.attributes.length > 0) {
                                                            if (this.attributes.for.value != null) {
                                                                previousRating = this.attributes.for.value.slice(-1);
                                                            }
                                                            if (previousRating != newRating) {
                                                                $(this).removeAttr("for");
                                                            }
                                                        }
                                                    }
                                                });
                                                if (previousRating != newRating) {
                                                    var inputId = event.target.previousSibling.attributes.getNamedItem("id").value;
                                                    $(event.target).attr("for", inputId);
                                                    var id = event.target.parentElement.id.replace("jqxRating_", "");
                                                    var params = {
                                                        objectId: id,
                                                        value: newRating,
                                                        agencyId: userProfile.currentAgencyId(),
                                                        favTable: 15,
                                                    };
                                                    dataModel
                                                        .ajaxRequest("Rating", "post", params)
                                                        .done(function (data, textStatus, jqXHR) {
                                                            ratingUpdated = true;
                                                            self.refreshCustomerGrid();
                                                        })
                                                        .fail(function (error, msg, d) {
                                                            self.isLoading(false);
                                                        });
                                                }
                                            }
                                        });
                                }
                                return fieldset[0].outerHTML;
                            }
                            return defaultHTML;
                        },
                        createfilterwidget: function (column, columnElement, widget) {
                            widget.jqxDropDownList({
                                dropDownWidth: 140,
                                renderer: function (index, label, value) {
                                    if (value != "") {
                                        return "<img src='../Content/Images/" + value + "star.png' />";
                                    } else {
                                        return "Any";
                                    }
                                },
                            });

                            // Since the grid is not in virtual mode, it is trying to filter "Please Choose: ", which results in 0 results,
                            // ...so when it's that case, remove the filter to get "any/all"
                            $(widget).on("change", function(e){
                                var args = e.args;
                                if(args && args.item && args.item.label == "Please Choose:" && !args.item.value)
                                    $("#customerCreditGrid").jqxGrid("removefilter", "rating");
                            })
                        },
                    },
                ],

                //READY TRIGGERS UPON RETURN OF DATA FROM API AND CAN BE USED TO TRIGGER EVENTS OR UPDATE PROPERTIES
                ready: function () {
                    //PASS THE GRID (FROM VAR GRID) AND SELF.CUSTOMERGRIDSTATE (FROM SELF.LOADDEFAULTFILTERS) TO SELF.LOADGRIDSTATE.
                    self.loadGridState(grid, self.customerGridState);
                  
                    if(self.urlCustomerExId || searchMcNumber) {
                        self.includeActive(true);
                        self.includeInactive(true);
                        filterCustomer({externalId: self.urlCustomerExId, mcNumber: searchMcNumber});
                    }

                    _customerInitLoad = false;
                },
            });

            self.loadGridState = function (grid, state) {
                if (state != null) {
                    var gridState = grid.jqxGrid("getstate");
                    state.selectedrowindex = -1;
                    state.selectedrowindexes = [];
                    state.pagenum = 0;
                    state.height = gridState.height;
                    state.pagesize = gridState.pagesize;
                    state.pagesizeoptions = gridState.pagesizeoptions;
                    grid.jqxGrid("loadstate", state);
                } else {
                    grid.jqxGrid("loadstate");
                }
            };

            grid.on("bindingcomplete", function (event) {
                if (!isInitLoad) {
                    if (ratingUpdated) {
                        ratingUpdated = false;
                        $("#divCustomerPanel").notify("Rating Updated", {
                            position: "top right",
                            className: "info",
                        });
                    }
                }
                var records = grid.jqxGrid("getrows");
                $("#customerRowCount").text("Number of Records: " + records.length);
            });

            grid.bind("filter", function (event) {
                var records = grid.jqxGrid("getrows");
                $("#customerRowCount").text("Number of Records: " + records.length);
            });
        };
    }
}

import template from "./customer-credit-page.html";
import gridStateUtils from "../../shared-components/jqx.grid-utils";
import { loadGridState } from "../order-planning-page/tractor-grid-component/tractor-grid-component";
export default { viewModel: CustomerCreditViewModel, template: template };
