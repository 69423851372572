export default function gePhone(ko) {

    const formatPhoneNumber = function (val) {
        if(val != null && typeof val != "undefined") {
            val = val.replace(/[^0-9]/gi, '');
        }
        if (val != null && val.length >= 10 && typeof val != "undefined") {
            val = val.substr(0, 10)
            return "(" + val.substr(0, 3) + ") " + val.substr(3, 3) + "-" + val.substr(6);
        }
        return val;
    };

    ko.bindingHandlers.gePhone = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
            var modelValue = valueAccessor(),
                    valueUnwrapped = ko.utils.unwrapObservable(modelValue) || null,
                    allBindings = allBindingsAccessor(),
                    unwrap = ko.utils.unwrapObservable,
                    isReadOnly = allBindings.isReadOnly || ko.observable(false),
                    inputMode = allBindings.inputMode || 'jqx', // jqx input or 'standard' which only applies 'events'
                    options = allBindings.properties || {};
            var readOnlyText = $("<span>");
    
            var input = $(element);
            readOnlyText.insertAfter(input);
    
            if (valueUnwrapped != null) {
                valueUnwrapped = valueUnwrapped.replace(/\D/g, '');
            }

            readOnlyText.html(formatPhoneNumber(valueUnwrapped));
    
            if (ko.isObservable(modelValue)) {
                modelValue.subscribe(function (newValue) {
                    readOnlyText.html(formatPhoneNumber(newValue));
                });
            }
            else {
                readOnlyText.html(formatPhoneNumber(modelValue));
            }
    
            if (ko.isObservable(isReadOnly)) {
                isReadOnly.subscribe(function (newValue) {
                    if (newValue) {
                        readOnlyText.show();
                        input.hide();
                    }
                    else {
                        readOnlyText.hide();
                        input.show();
                    }
                });
            }
    
            input.val(formatPhoneNumber(valueUnwrapped));
    
            // Only apply style if no class is set
            if (input.attr('class') == undefined || input.attr('class') == "") {
                if (options.width == undefined) {
                    options.width = 110;
                }
                if(options.height == undefined) {
                    options.height = 25;
                }
            }                
            
            if (unwrap(isReadOnly)) {
                readOnlyText.show();
                input.hide();
            }
            else {
                readOnlyText.hide();

                if(inputMode == 'jqx') {
                    input.jqxInput(options);
                }

                input.show();
            }
            input.on('paste', function(event) {
                if(event.originalEvent.clipboardData) {
                    var pastedData = event.originalEvent.clipboardData.getData('text');
                    var cleansedPhoneNumber = pastedData
                        .replace(new RegExp("/s/", 'g'), '')
                        .replace(new RegExp("/[a-zA-Z]/", 'g'), '')
                        .replace("(", "")
                        .replace(")", "")
                        .replace(" ", "")
                        .replace("-", "");
                
                    setTimeout(function() {
                        input.val(cleansedPhoneNumber)
                    })
                }
            })
    
            input.focusout(function(event) {
                input.val(formatPhoneNumber(input.val()))

                if (ko.isObservable(modelValue) && inputMode == 'standard') {
                    if (!input.val()) {
                        modelValue(undefined);
                    }
                    else {
                        modelValue(input.val());
                    }
                }
            })
    
            input.on('valueChanged', function (event) {   
                var args = event.args;
                if (args) {
                    var inputValue = event.args.value;
                    var text = event.args.text;
                    
                    var length = inputValue.length;
                    for (var i = 0; i < length; i++) {
                        inputValue = inputValue.replace("_", "");
                    }
                    if (ko.isObservable(modelValue)) {
                        if (!inputValue) {
                            modelValue(undefined);
                        }
                        else {
                            modelValue(inputValue);
                        }
                    }
                }
            });
    
            input.on('change', function (event) {   
                var args = event.args;
                if (args) {
                    var inputValue = event.args.value;
                    var text = event.args.text;
                    
                    var length = inputValue.length;
                    for (var i = 0; i < length; i++) {
                        inputValue = inputValue.replace("_", "");
                    }
                    if (ko.isObservable(modelValue)) {
                        if (!inputValue) {
                            modelValue(undefined);
                        }
                        else {
                            modelValue(inputValue);
                        }
                    }
                }
            });
        },
        update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
            var value = valueAccessor();
            var valueUnwrapped = ko.utils.unwrapObservable(value);
            var isReadOnly = allBindingsAccessor().isReadOnly || false;
            var input = $(element);
            
            if (valueUnwrapped != null) {
                valueUnwrapped = valueUnwrapped.replace(/\D/g, '');
                input.val(formatPhoneNumber(valueUnwrapped));
            } else {
                valueUnwrapped = '';
                input.val("");
            }
        }
    };
}
