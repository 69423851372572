import * as tvCommon from '../tvCommon';
import { useDispatch } from 'ko-data-store';
import { isLoading } from 'dataStore-actions/appUI';

// controlled component -> all methods, etc are being handled by parent 'training-videos-component'
// this is mainly used to separate markup, plus specific items only used in component
const categoriesViewModel = function({
    activeSelectCategory,
    handleResetVideoContent,
    handleMobileMenuDisplay,
    videoContentVM,
    currentFilter,
    handleVideoEdit,
    categories,
    allowVideoEntryEdits
}) {

    const vm = this;
    const dispatch = useDispatch();
    
    vm.handleResetVideoContent = handleResetVideoContent;
    vm.activeSelectCategory = activeSelectCategory;
    vm.videoContentVM = videoContentVM;
    vm.handleMobileMenuDisplay = handleMobileMenuDisplay;
    vm.categories = categories;
    vm.handleVideoEdit = handleVideoEdit;
    vm.allowVideoEntryEdits = allowVideoEntryEdits;
    
    vm.handleLoadVideosForSubCategory = async ($data) => {
        const {id, name} = ko.toJS($data);
        if(id > 0) {
            const filter = new tvCommon.FilterableModel({id});
            currentFilter(filter);
            vm.videoContentVM(new tvCommon.VideoContentModel({subheader: name}));

            dispatch(isLoading(true));
            const videos = await tvCommon.services.getVideosBySubCategoryAsync(filter);
            dispatch(isLoading(false));

            vm.videoContentVM().videoList(videos);
        }

        determinCloseMobileMenu();
    }

    vm.handleLoadVideosForCategory = async ($data) => {
        const {id, description, subCategories } = ko.toJS($data);

        if(id > 0 && subCategories.length == 0) {
            const filter = new tvCommon.FilterableModel({id});
            currentFilter(filter);
            vm.videoContentVM(new tvCommon.VideoContentModel({subheader: description}));

            dispatch(isLoading(true));
            const videos = await tvCommon.services.getVideosByCategoryAsync(filter);
            dispatch(isLoading(false));

            vm.videoContentVM().videoList(videos);

            determinCloseMobileMenu($data);
        }

        vm.activeSelectCategory($data);
    }

    const determinCloseMobileMenu = (activeSelectCategory) => {
        const $menu =  $("#category-list-view");

        if($menu.hasClass('open')) {
            vm.activeSelectCategory(activeSelectCategory)
            $menu.animate({left: '-105%'}, 'fast', () => {
                $('body').removeClass('modal-open')
                $menu.removeClass('open')
            });
        }
    }
}

import template from './categories-component.html';
export default { viewModel: categoriesViewModel, template: template }