import processQue from "./utils/processQue";
import ko from 'knockout';

export {
    processQue
}

// This is the pure datastore, methods, utilities, etc....
// These utilities are based on 'Redux' pattern(S) for handling application state.
// As an app grows, eventually a few components will end up needing a 'slice' of shared state.
// Using a store gives a 'central' location to update app data and which can be accessed between different parts of the app.

// Combine state handlers (reducers) into one for the store.
export function combineStateUpdaters(handlerFn) {
    return (state = {}, action) => {
        return Object.keys(handlerFn).reduce((combinedState, key) => {
            combinedState[key] = handlerFn[key](state[key], action);
            return combinedState;
        },{});
    }
}


export function useKoState(initVal) {
    const _val = ko.observable(initVal);

    const setVal = val => _val(val);
    const state = ko.computed(() => _val());
    
    return [state, setVal];
}

export function useState(state) {

    if(state === undefined || state === null) {
        throw new Error(`You must set the initial state with a valid default value. Some initial default values are: ("", {}, 0, [], false) - Example: 'const [name, setName] = useState("")'`);
    }

    const getType = (value) => {
        if(value === undefined) return typeof value;
        if(value === null) return null;
        const typeConstructor = value.constructor.toString().toUpperCase();
        return typeConstructor.split(" ")[1].replace("(", "").replace(")", "");
    }

    const isTypeMatch = (value, type) => {
        if(value === undefined || value === null || type === undefined || type === null) return false;
        return getType(value).indexOf(type.toUpperCase()) > -1;
    }

    const isStateTypeOf = (type) => isTypeMatch(state, type);

    const isValueTypeOf = isTypeMatch;

    const validateValueChange = (value) => {
        let isValid = false;
        const types = ['string', 'function', 'array', 'number', 'boolean', 'object', 'date'];
        const defaultValues = {
            OBJECT: `{}`,
            STRING: `""`,
            BOOLEAN: `false`,
            NUMBER: `0`,
            ARRAY: `[]`,
            DATE: `new Date()`
        }

        if(value === undefined || value === null) {
            throw new Error(`Cannot set the value of state to ${value == null ? 'null' : 'undefined'}. If you need to reset the state, then use a default value: '${defaultValues[getType(state)]}'`);
        }

        isValid = types.some(type => {
            return isStateTypeOf(type) && isValueTypeOf(value, type);
        })

        if(isValid == false) {
            throw new Error(`Cannot change the type of state '${typeof state}' to '${typeof value}'. If you need to change the underlyning type, then create a new variable with useState.`);
        }
    }

    // Can provide a function that returns the value to set
    // Ex: setVal(() => { return "new value" });
    const setVal = (valueOrFn) => {
        let val = valueOrFn;

        if(typeof valueOrFn === 'function') {
            val = valueOrFn();
        }

        validateValueChange(val);

        switch(getType(val)) {
            case 'OBJECT':
                state = Object.keys(val).length ? { ...state, ...val } : val;
                break;
            case 'ARRAY':
                state = val.length ? val : [];
                break;
            default:
                state = val;     
        }

        return state;
    };
    
    return [() => state, setVal];
}


export function createStore(stateHandlerFn) {
    let state = {};
    let listeners = [];

    const getState = () => state;

    const dispatch = (doAction) => {
        mapPropToObservable(stateHandlerFn(ko.toJS(state), doAction));
        listeners.forEach(x => x(ko.toJS(state)));

        return ko.toJS(state);
        // Leaving for now, in case uncaught breaking change
        // Promise.resolve(action).then((doAction) => {
            
        // })
    }

    const subscribe = (listenerFn) => {
        listeners.push(listenerFn);

        // return a unsubscribe method
        // ex: const unsub = store.subscribe((state) => ...do something);
        //     unsub(); <-- ^ will no longer be called on updates.
        return () => {
            listeners = listeners.filter(x => x !== listenerFn);
        }
    }

    const mapPropToObservable = (stateToMap) => {
        Object.keys(stateToMap).forEach((key) => {
            if(state[key]) {
                state[key](stateToMap[key]);
            }
            else if(Array.isArray(stateToMap[key])) {
                state[key] = ko.observableArray(stateToMap[key]);
            }
            else if(typeof stateToMap[key] === 'object') {
                state[key] = ko.observable(stateToMap[key]);
            }
        })
    }

    dispatch({}); // set initial store state

    return { getState, dispatch, subscribe };
}