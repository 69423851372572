import dataModel from 'data-model';
import ko from 'knockout';
import template from './order-entry-order-images-component.html';

class ImageViewModel {
    constructor(id) {
        this.showLoadingWheel = ko.observable(false);
        this.id = id;
        this.images = ko.observableArray([]);
        this.errors = ko.observableArray();
        this.getImageTypes();

        this.showNoResults = ko.pureComputed(() => !this.images() || this.images().length === 0)
    }

    downloadImage = (image) => {
        var imgReq = {  
            proNumber: this.id,
            imageRequested: image.imageTypeId,
            scanDate: image.date
        }
        this.showLoadingWheel(true);
        dataModel.downloadFile("Imaging/getImageDocument", "get", imgReq, this.id + "-" + image.imageType + ".pdf")
        .then(() => {
            this.showLoadingWheel(false);
        })
        .catch(() => {
            this.showLoadingWheel(false);
            this.errors.push("GreatEDGE cannot currently communicate with the Imaging server");
        })
    }

    getImageTypes = () => {
        this.showLoadingWheel(true);
        dataModel.ajaxRequest("Imaging/getAllAvailableImages", "get", { proNumber: this.id})
        .done((data) => {
            this.showLoadingWheel(false);
            
            this.images(data ?? []);
        })
        .fail((jqXHR) => {
            this.showLoadingWheel(false);
            if (jqXHR.status == 400) {
                var response = jqXHR.responseJSON;
                this.errors.push(response.errors);
            }
            else {
                this.errors.push("GreatEDGE cannot currently communicate with the Imaging server");
            }
        });
    }
}

export default {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            //Don't do anything.  Use the object created in the main OrderEntry page.
        }
    }, template: template
}

export { ImageViewModel }
