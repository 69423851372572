// Using the 'ducks' pattern to include action types, actions/action creators, and updater (reducers) in same module
// separated by comment sections. The updater (reducer) should be the default export. And action creators exported as
// named exports.

// Action types ********************
const ADD_TEST_MESSAGE = "example/testMessageAdded"; // namespaced with 'example'


// Action creators + Actions ********************
// Actions / action creators are functions that return an action object.
// All action objects must have a 'type' property and as convention a payload of the data (state)
// you want to update.

// Ajax methods can happen here inside the function, then return the action abject when completed.

export function addTestMessage(message) {

    // ...make ajax call to api
    // ...do some work
    // ...business logic stuff, etc
    const newMessage = `Hey there! Look at this: ${message}`;

    return {type: ADD_TEST_MESSAGE, payload: newMessage}
}


// Example using the ko-data-store useDispatch utility. We can create async action creators, that also receive
// the dispatch function and app state (as raw data - no observables)
// 1. Pass in the value if any you want to update the store with.
// 2. Return an async function that accepts the dispatch function and state as args.
// 3. If we do not need to dispatch any other actions, then return an action object if need to update store.
export const addTestMessageAsync = (messageToAdd) => async(dispatch, state) => {
    // example is the store slice namespace added in ../actions/index.js
    // and we initialized it as an array
    const currentMessages = state.example; 

    // Let's only add a message if it's a string and unique
    if(typeof messageToAdd === 'string'
        && !currentMessages.some(x => x.toUpperCase() == messageToAdd.toUpperCase())
    ) {
        return {type: ADD_TEST_MESSAGE, payload: messageToAdd};
    }
}



// Updater (reducer) ********************************
// Updaters (aka 'reducers') is nothing more than pure functions that returns a new state.
// State here should NOT be modified. Only return new state. The initial state can be an array or an object.
// Use ...spread operator helps to return current state + updated state -> new state returned.

// *The current state will be passed in the first argument when the reducer is called.

export default function example(messages = [], action) {
    switch(action.type) {
        case ADD_TEST_MESSAGE:
            return [...messages, action.payload];
        default: // no action.type match then just return the current state
            return messages;
    }
}