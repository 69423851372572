import hookFns from "./hookFns";
export default function makePagingExpandable(
  $grid = $(), // grid already wrapped in jquery
  { pagesize = 5, pagesizeoptions = ["5", "10", "20", "50"] } = {}
) {
  let increment = pagesize;
  let processing = false;

  const $btn = $(`<button>`, {
    style:
      "float: right; background-color: inherit; padding: 4px; border: 1px solid #555",
    text: "Load More",
  });

  const setBtnDisableState = () => {
    $btn.css({ opacity: ".5" });
    $btn.text("Loading...");
    $btn.attr("disabled", "disabled");
  };

  const setBtnEnableState = () => {
    $btn.css({ opacity: "1" });
    $btn.text("Load More");
    $btn.removeAttr("disabled");
  };

  const paging = {
    autoheight: true,
    pageable: true,
    pagesize: pagesize,
    pagesizeoptions: pagesizeoptions,
    showstatusbar: true,
    renderstatusbar: (statusbar) => {
      const $div = $("<div>", { id: "status-bar", style: "padding:4px;" });

      $btn.on("click", () => {
        if (processing == false) {
          $grid.jqxGrid({ pagesize: $grid.jqxGrid("pagesize") + increment });
          processing = true;
          setBtnDisableState();
        }
      });

      $div.append($btn);

      statusbar.append($div);
    },
  };

  $grid.on("pagesizechanged", function (event) {
    const args = event.args;
    const pagesize = args.pagesize;

    increment = pagesize;
  });

  $grid.on("bindingcomplete", function (event) {
    processing = false;
    setBtnEnableState();

    $grid.jqxGrid({
      autoheight: false,
      height: screen.height >= 800 ? window.innerHeight - 200 : 400,
    });
  });

  return (jqxGridOptions = {}, hooks = []) => {
    const options = { ...jqxGridOptions, ...paging };

    hookFns({ $grid, options })(hooks);

    return {
      $grid,
      options,
    };
  };
}
