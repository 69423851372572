// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n\r\n    #Vp0-J7XuFcfDdo0nFvk\\+6g\\=\\= .DJf2g8viUW8Gn8ByD5Loug\\=\\= {\r\n        padding-top: 20px;\r\n    }", "",{"version":3,"sources":["webpack://./src/home/pages/route-calculator-page/routeCalculatorStyles.css"],"names":[],"mappings":";;IAEI;QACI,iBAAiB;IACrB","sourcesContent":["\r\n\r\n    #route-calculator-page .pt-1 {\r\n        padding-top: 20px;\r\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"route-calculator-page": "Vp0-J7XuFcfDdo0nFvk+6g==",
	"pt-1": "DJf2g8viUW8Gn8ByD5Loug=="
};
export default ___CSS_LOADER_EXPORT___;
