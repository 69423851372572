import ko from 'knockout';
import dataModel from 'data-model';
import * as _ from 'lodash';
import {createDateTimeFilterWidget, escapeHtml} from 'global-functions'
import gridStateUtils from 'jqx.grid-utils';
import GridStateComponentViewModel from 'jqx.grid-state-component';
import userProfile from 'user-profile';
import template from './driver-details-modal-component.html';
import {showmessage} from 'show-dialog-methods';

class DriverDetailsModalComponent {
    constructor(param) {
        this.driverID = param.driverID
        this.initializeProperties();
        $('#driverDetailsModal').modal('show');
        $('#driverDetailsModal').on("hidden.bs.modal", function () { 
            param.driverIDObsReference(undefined);
        })
        this.getDriverDetailData()
    }

    initializeProperties() {
        this.driverDetails = ko.observable();
        this.isLoading = ko.observable();
        this.roadsideDetailsModal = ko.observable();

        //These are not being used but keeping them.
        this.firstName = ko.observable();
        this.lastName = ko.observable();
        this.address = ko.observable();
        this.cityStateZip = ko.observable();
        this.code = ko.observable();
        this.type = ko.observable();
        this.agencyCode = ko.observable();
        this.qualificationDate = ko.observable();
        this.classification = ko.observable();
        this.lastCallin = ko.observable();
        this.currentDispatch = ko.observable();
        this.lockout = ko.observable();
        this.disqualificationDate = ko.observable();
        this.reasonForLeaving = ko.observable();
        this.driverOOS = ko.observable();
        this.vehicleOOS = ko.observable();
        this.hazmatOOS = ko.observable();
        this.canRehire = ko.observable();
        this.csaPoints = ko.observable();
        this.contacts = [];
        this.relationship = ko.observable();
        this.phone = ko.observable();
        this.fax = ko.observable();
        this.email = ko.observable();
        this.driverContact = ko.observable();
        this.crashes = [];
        this.dateOfCrash = ko.observable();
        this.type = ko.observable();
        this.preventable = ko.observable();
        this.dot = ko.observable();
        this.vehicleTowed = ko.observable();
        this.tractorId = ko.observable();
        this.crashLocation = ko.observable();
        this.injuries = ko.observable();
        this.reportState = ko.observable();
        this.fatalities = ko.observable();
        this.trailerId = ko.observable();
        this.cityState = ko.observable();
        this.employeeInjury = ko.observable();
        this.description = ko.observable();
    }

    getDriverDetailData() {
        this.isLoading(true);
        dataModel.ajaxRequest("driver/ByExternalId/" + this.driverID)
        .done((data, status, xhr) => {
            if(data.csaRoadsideInformation && data.csaRoadsideInformation.length > 0) {
                data.csaRoadsideInformation = data.csaRoadsideInformation.map(function(rsi) {
                    if(rsi.violations && rsi.violations.length > 0) {
                        rsi.points = rsi.violations.reduce(function(ttl, value) {return ttl + value.points}, 0)
                    }
                    return rsi;
                })
            }
            data.contacts = data.contacts || [];
            data.driverCSAreport = function () {
                // Log user clicking this btn
                dataModel.addClientSideLog('Selected: Get CSA Report.');
                var driverParams = {
                    agencyId: userProfile.currentAgencyId(),
                    driverId: data.id,
                    format: "pdf"
                };
                dataModel.downloadAttachments('Report/DriverCSA', 'POST', driverParams, 'DriverCSA-' + data.id + '-' + userProfile.currentAgencyId() + '.pdf');
            };
    
            if (typeof data.csaRoadsideInformation === "undefined") {
                data.csaRoadsideInformation = null;
            }
            if (typeof data.csaRoadsidePointHistory === "undefined") {
                data.csaRoadsidePointHistory = null;
            }
            data.notes = ko.observableArray(ko.utils.arrayMap(data.notes, function (item) { 
                return new noteModel(item);
            }))
            .extend({ liveEditor: { model: noteModel, 
                params: [{
                    driverId: data.id,
                    driverExternalId: data.code
                }]
            }});

            data.noteTmplToUse = function (item) {
                return data.notes.selectedItem() === item ? "noteEditTmpl" : "noteItemTmpl";
            };
            data.unitMessagesViewModel = new UnitMessagesViewModel(data);
            data.equipIssuedVM = new EquipmentIssuedViewModel();
            this.driverDetails(data);

            data.unitMessagesViewModel.loadGrid();
            this.createDriverInspectionGrid(data);
            data.equipIssuedVM.getEquipmentIssued({ itemExternalId: data.code, itemType: "D" });
            this.isLoading(false);
        })
        .fail((jqXHR, textStatus, errorThrown) => {
            if (jqXHR.responseJSON != null) {
                var json = jqXHR.responseJSON;
                if (json.message != null) {
                    var errors = json.message;
                    console.error(json.message)
                    showmessage("An error has occured");
                }
            }
            this.isLoading(false);
        });
    }

    createDriverInspectionGrid(data) {
        if (data.csaRoadsideInformation != null) {
            var source =
            {
                localdata: data.csaRoadsideInformation,
                datatype: "array",
                datafields:
                [
                    { name: 'id', type: 'int' },
                    { name: 'postedDate', type: 'date' },
                    { name: 'inspectionDate', type: 'date' },
                    { name: 'roadside', type: 'string' },
                    { name: 'state', type: 'string' },
                    { name: 'inspectionLevel', type: 'string' },
                    { name: 'oos', type: 'string' },
                    { name: 'points', type: 'int'}
                ]
            };

            var dataAdapter = new $.jqx.dataAdapter(source);
            $("#gridRoadside").jqxGrid({
                width: "100%",
                source: dataAdapter,
                autoheight: true,
                altrows: true,
                columnsresize: true,
                rowdetailstemplate: { rowdetails: "<div id='grid' style='margin: 10px;'></div>", rowdetailsheight: 300, rowdetailshidden: true },
                columns: [
                    {
                        text: '', filterable: false, sortable: false, menu: false, width: 100, columnType: "button",
                        buttonclick: (row) => {
                            this.isLoading(true);
                            var datarow = $("#gridRoadside").jqxGrid('getrowdata', row);
                            var inspection = ko.utils.arrayFirst(data.csaRoadsideInformation, (item) => {
                                if (item.id === datarow.id) {
                                    this.isLoading(false);
                                    return true;
                                }
                            })
                            this.roadsideDetailsModal(inspection);
                            var violationsGrid = $("#gridViolatons");
                            var nestedSource =
                            {
                                localdata: inspection.violations,
                                datatype: "array",
                                datafields:
                                    [
                                        { name: 'unitType', type: 'string' },
                                        { name: 'citation', type: 'string' },
                                        { name: 'basic', type: 'string' },
                                        { name: 'section', type: 'string' },
                                        { name: 'description', type: 'string' },
                                        { name: 'oos', type: 'string' },
                                        { name: 'severity', type: 'string' },
                                        { name: 'oosWeight', type: 'string' },
                                        { name: 'timeWeight', type: 'string' },
                                        { name: 'assetNumber', type: 'string' },
                                        { name: 'licenseNumber', type: 'string' },
                                        { name: 'licenseState', type: 'string' },
                                        { name: 'vin', type: 'string' },
                                        { name: 'points', type: 'string' },

                                    ]
                            };
                            var nestedDataAdapter = new $.jqx.dataAdapter(nestedSource);

                            if (violationsGrid != null) {
                                violationsGrid.jqxGrid({
                                    width: "100%",
                                    source: nestedDataAdapter,
                                    autoheight: true,
                                    altrows: true,
                                    columnsresize: true,
                                    columns: [
                                        { text: 'Unit Type', datafield: 'unitType', width: 70 },
                                        { text: 'Citation', datafield: 'citation', width: 70 },
                                        { text: 'Basic', datafield: 'basic', width: 100 },
                                        { text: 'Section', datafield: 'section', width: 100 },
                                        { text: 'Description', datafield: 'description', width: 150 },
                                        { text: 'OOS', datafield: 'oos', width: 40 },
                                        { text: 'Severity', datafield: 'severity', width: 70 },
                                        { text: 'OOS Weight', datafield: 'oosWeight', width: 90 },
                                        { text: 'Time Weight', datafield: 'timeWeight', width: 90 },
                                        { text: 'Asset Number', datafield: 'assetNumber', width: 100 },
                                        { text: 'License Number', datafield: 'licenseNumber', width: 120 },
                                        { text: 'License State', datafield: 'licenseState', width: 110 },
                                        { text: 'VIN', datafield: 'vin', width: 100 },
                                        { text: 'Points', datafield: 'points', width: 50 },
                                    ]
                                });
                            }
                        },
                        cellsrenderer: function () {
                            return "Details";
                        }
                    },
                { text: 'Post Date', datafield: 'postedDate', filtertype: "date", cellsformat: 'MM/dd/yyyy', width: 120, createfilterwidget: createDateTimeFilterWidget },
                { text: 'Inspection Date', datafield: 'inspectionDate', filtertype: "date", cellsformat: 'MM/dd/yyyy', width: 120, createfilterwidget: createDateTimeFilterWidget },
                {
                        text: 'Roadside', datafield: 'roadside', width: 200,
                    cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                        if (defaultHTML != null) {
                            var cell = $(defaultHTML);
                            cell.html("<a href='/RoadsideEntry?id=" + rowData.id + "' target='_blank'  style='color:blue; '  >" + value + "</a>");
                            return cell[0].outerHTML;
                        }
                        return defaultHTML;
                    }
                },
                    { text: 'Inspection State', datafield: 'state', width: 200 },
                    { text: 'Inspection Level', datafield: 'inspectionLevel', width: 200 },
                    { text: 'OOS', datafield: 'oos', width: 200 },
                    { text: 'Points', datafield: 'points', width: 200}
                ]
            });
        }
    }
}

var noteModel = function(item) {
    var self = this;
    self.id = ko.observable(item.id);
    self.comment = ko.observable(item.comment);
    self.driverId = ko.observable(item.driverId);
    self.driverExternalId = ko.observable(item.driverExternalId)
    self.acceptedItem = function (item) {
        var noteData = ko.toJS(item);
        if (noteData.id != null) {
            dataModel.ajaxRequest("Driver/EditNote", "post", noteData)
            .done(function (data, textStatus, jqXHR) {  })
            .fail(function (jqXHR, textStatus, errorThrown) { showmessage("An error has occured.") });
        }
        else {
            dataModel.ajaxRequest("Driver/AddNote", "post", noteData)
            .done(function (data, textStatus, jqXHR) { })
            .fail(function (jqXHR, textStatus, errorThrown) { showmessage("An error has occured.") });
        }
    };
    self.deletedItem = function (item) {
        var id = item.id();
        dataModel.ajaxRequest("Driver/DeleteNote/" + id, "post")
        .done(function (data, textStatus, jqXHR) { })
        .fail(function (jqXHR, textStatus, errorThrown) { showmessage("An error has occured.") });
    };
};

class UnitMessagesViewModel {
    constructor(data) {
        var self = this;
        self.driverId = data.id;
        self.isLoading = ko.observable(false);
        self.viewMessageObj = ko.observable({
            priority: '',
            message: null,
            date: ''
        });
    
        self.resestViewMessageObj = function () {
            self.viewMessageObj({
                priority: '',
                message: null,
                date: ''
            });
        }
    
        self.loadGrid = function () {
            var grid = $("#unitMessagesGrid");
            var source = {
                url: "Driver/GetDriverUnitMessagesForGrid",
                datatype: "json",
                beforeprocessing: function (data) {
                    source.totalrecords = data.totalRecords;
                },
                sort: function (column, direction) {
                    grid.jqxGrid('updatebounddata', 'sort');
                },
                formatdata: function (data) {
                    var filterinfo = grid.jqxGrid('getfilterinformation');
                    data = gridStateUtils.formatGridFilters(filterinfo, data);
                    data.driverId = self.driverId;
                    return data;
                },
                datafields: [
                    { name: "id", type: "number" },
                    { name: "message", type: "string" },
                    { name: "messageDate", type: "date" },
                    { name: "priority", type: "string" }
                ],
                loadcomplete: function (data) {
    
                }
            };
            var dataSource = dataModel.getDataAdapter(source);
            var columns = [
                {
                    text: '', width: 80, filterable: false, columnType: "button", buttonclick: function (row) {
                        var datarow = grid.jqxGrid('getrowdata', row);
                        self.viewMessageObj({
                            message: datarow.message,
                            date: datarow.messageDate,
                            priority: datarow.priority
                        });
                    },
                    cellsrenderer: function () {
                        return "View";
                    }
                },
                { datafield: 'id', hidden: true },
                { text: 'Date', datafield: 'messageDate', cellsformat: 'MM/dd/yyyy', width: 120 },
                { text: 'Message', datafield: 'message' },
                {
                    text: 'Priority', datafield: 'priority', width: 100,
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
    
                        if (value == 'U') {
                            var $html = $(defaulthtml).css({ 'color': 'red', 'font-weight': 'bold' });
                            return $html[0].outerHTML;
                        }
                    }
                },
            ];
    
            grid.jqxGrid({
                width: '100%',
                source: dataSource,
                altrows: true,
                sortable: true,
                autoheight: true,
                pageable: true,
                pagesize: 5,
                filterable: false,
                showfilterrow: false,
                virtualmode: true,
                columnsresize: false,
                columnsreorder: false,
                enablebrowserselection: true,
                columnsmenu: false,
                columns: columns,
                autoshowloadelement: true,
                showtoolbar: true,
                rendergridrows: function (obj) {
                    return obj.data;
                }
            });
        }
    }
}

var EquipmentIssuedViewModel = function () {
    var self = this;
    var _itemExternalId = null;
    var _itemType = null;
    var _gridCreated = false;
    var _$grid;

    self.getEquipmentIssued = function (equipmentItemObj) {
        if (equipmentItemObj == null || typeof equipmentItemObj !== 'object') return false;
        _itemExternalId = equipmentItemObj.itemExternalId || null;
        _itemType = equipmentItemObj.itemType || null;
        if (_gridCreated) {
            _$grid.jqxGrid('updatebounddata', 'data');
        }
        else {
            _buildGrid();
        }
    }

    function _buildGrid() {
        _$grid = $('#jqxEquipmentIssuedGrid');
        var source = {
            url: "Equipment/Issued",
            datatype: "json",
            beforeprocessing: function (data) {
                source.totalrecords = data.totalRecords;
            },
            sort: function (column, direction) {
                _$grid.jqxGrid('updatebounddata', 'sort');
            },
            formatdata: function (data) {
                var filterinfo = _$grid.jqxGrid('getfilterinformation');
                data = gridStateUtils.formatGridFilters(filterinfo, data);
                data.itemType = _itemType;
                data.itemExternalIds = [_itemExternalId];
                return data;
            },
            datafields: [
                { name: "id", type: "number" },
                { name: "status", type: "string" },
                { name: "memo", type: "string" },
                { name: "quantity", type: "number" },
                { name: "equipTypeDescription", type: "string" },
                { name: "issuedDate", type: "date" },
                { name: "expirationDate", type: "date" },
                { name: "returnedDate", type: "date" },
                { name: "comments", type: "string" }
            ],
            loadcomplete: function (data) {
            }
        };
        var dataSource = dataModel.getDataAdapter(source);
        var columns = [
            { datafield: 'id', hidden: true },
            { text: 'Status', datafield: 'status', width: 80 },
            { text: 'Memo', datafield: 'memo', width: 200 },
            { text: 'Quantity', datafield: 'quantity', width: 80 },
            { text: 'Equipment Type', datafield: 'equipTypeDescription', width: 150 },
            { text: 'Issued Date', datafield: 'issuedDate', cellsformat: 'MM/dd/yyyy', width: 120 },
            { text: 'Expiration Date', datafield: 'expirationDate', cellsformat: 'MM/dd/yyyy', width: 120 },
            { text: 'Returned Date', datafield: 'returnedDate', cellsformat: 'MM/dd/yyyy', width: 120 },
            { text: 'Comments', datafield: 'comments', width: 400 }
        ];

        _$grid.jqxGrid({
            width: '100%',
            source: dataSource,
            altrows: true,
            sortable: true,
            autoheight: true,
            pageable: true,
            pagesize: 5,
            filterable: false,
            showfilterrow: false,
            virtualmode: true,
            columnsresize: false,
            columnsreorder: false,
            enablebrowserselection: true,
            columnsmenu: false,
            columns: columns,
            autoshowloadelement: true,
            showtoolbar: true,
            rendergridrows: function (obj) {
                return obj.data;
            },
            ready: function () {
                _gridCreated = true;
            }
        });
    }
}



export default { viewModel: DriverDetailsModalComponent, template: template }