/// <reference path="../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/jqwidgets.d.ts" />
/// <reference path="../../../../node_modules/@types/jqueryui/index.d.ts" />
import * as ko from "knockout";
import 'jqwidgets-scripts/jqwidgets/globalization/globalize';
import dataModel from "data-model";
import * as $ from "jquery";
import userProfile from '../../../user-profile'
import gridStateUtils from 'jqx.grid-utils';
import template from './imaging-page.html'
import {showmessage} from 'show-dialog-methods';
import {isEmpty, datetimeUTC} from 'global-functions';
import GridStateComponentViewModel from "../../shared-components/jqx.grid-state-component/jqx.grid-state-component";
import { GreatEdgeCustomWidgets, createGeDateRangeFilterWidget } from 'ge.custom-widgets';

class ImagingViewModel {
    geDateWidget = new GreatEdgeCustomWidgets();
    orderImagingWindow = ko.observable();
    grid: jqwidgets.jqxGrid | undefined;
    lastUpdated;
    initialGridLoad = true
    gridState;

    constructor() {
        this.init();
    }

    init = async() => {
        await this.loadUserSavedFilters();
        this.initializeGrid();
    }

    loadUserSavedFilters = async() => {
        let filters = await gridStateUtils.getUserGridFiltersAsync("jqxImages").catch((err) => { console.error("Failed to retrieve user filters."); return null});
        
        if (isEmpty(filters) == false) {
            let state = JSON.parse(filters);
            this.gridState = state.grid;
        }
    }

    initializeGrid = () => {
        const vm = this;

        let source = {
            url: "Order/GetOrdersForImagingGrid",
            datatype: "json",
            sortcolumn: "externalId",
            sortdirection: "desc",
            totalrecords: 0,
            data: {
                agencyId: userProfile.currentAgencyId()
            },
            filter: (items) => {
                this.grid!.updatebounddata("filter");
       
            },
            sort: (column, sortOrder) => {
                this.grid!.updatebounddata("sort");
            },
            beforeprocessing: function (data) {
                this.totalrecords = data.totalrecords;
            },

            formatdata: (data) => {
                if(this.initialGridLoad) {
                    //Add default filters here
                    data.orderStatus = [1, 2, 3];
                    this.initialGridLoad = false;
                } else {
                    let filterinfo = this.grid!.getfilterinformation();
                    data = gridStateUtils.formatGridFilters(filterinfo, data);
                }
                
                return data;
            },
            datafields: this.datafields()
        };

        let dataAdapter = dataModel.getDataAdapter(source);

            let gridOptions : jqwidgets.GridOptions = {
            width: '100%',
            source: dataAdapter,
            selectionmode: 'single',
            sortable: true,
            pageable: true,
            filterable: true,
            showfilterrow: true,
            autoheight: true,
            virtualmode: true,
            columnsmenu: false,
            rendergridrows: function (obj) {
                return obj.data;
            },
            columnsreorder: true,
            columnsresize: true,
            columns: this.columns(),
            showtoolbar: true,
            rendertoolbar: (toolbar) => {
                if (!toolbar.isLoaded) {
                    const jqxGridHelper = this.geDateWidget.GEWidgetsGridHelper('jqxImages');
                    let gridStateViewModel = new GridStateComponentViewModel.viewModel();
                    
                    gridStateViewModel.clearFilters = () => {
                        
                        jqxGridHelper.clearDateRangeFiltersFromGrid();
                        this.grid!.clearfilters();
                    }

                    $(toolbar).append(GridStateComponentViewModel.template);
                    toolbar.isLoaded = true;
                    ko.applyBindingsToDescendants(gridStateViewModel, toolbar[0]);
                }
            },
            ready: () => {
                this.initFilterRow();
                gridStateUtils.applyGridState("jqxImages",vm.gridState);
            }
        }
        
        this.grid = jqwidgets.createInstance("#jqxImages", "jqxGrid", gridOptions)
    }

    initFilterRow() : any {
        var filtergroup1 = new $.jqx.filter();
        var filter1 = filtergroup1.createfilter('stringfilter', "1", "equal");
        var filter3 = filtergroup1.createfilter('stringfilter', "2", "equal");
        var filter2 = filtergroup1.createfilter('stringfilter', "3", "equal");
        filtergroup1.addfilter(1, filter1);
        filtergroup1.addfilter(1, filter2);
        filtergroup1.addfilter(1, filter3);
        this.grid!.addfilter('orderStatus', filtergroup1, false);
        this.grid!.refreshfilterrow();
    }

    datafields() : any {
        return [
            { name: 'id', type: 'int' },
            { name: 'externalId', type: 'string' },
            { name: 'orderStatus', type: 'string' },
            { name: 'deliveryDate', type: 'dateTime' },
            { name: 'driver1ExternalId', type: 'string' },
            { name: 'driver2ExternalId', type: 'string' },
            { name: 'billDate', type: 'dateTime' },
            { name: 'readyToBill', type: 'boolean' },
        ]
    } 

    createDateTimeFilterWidget(column, columnElement, widget) : any {
        widget.jqxDateTimeInput({
            readonly: false,
            formatString: "MM/dd/yyyy"
        });
        $(widget).find("input").attr("placeHolder", "MM/DD/YYYY");
    }

    columns() : jqwidgets.GridColumn[] {
        let orderImagingWindow = this.orderImagingWindow;
        
        let minWidth = window.innerWidth < 800 ? '25%' : null;

        let columns: jqwidgets.GridColumn[] = [
            {
                text: '', width: 120, columntype: "button", buttonclick: function (row) {
                    var datarow = ($("#jqxImages") as any).jqxGrid('getrowdata', row);
                    var param =
                    {
                        pronumber: datarow.externalId
                    };
                    dataModel.ajaxRequest("Imaging/getAllAvailableImages", "GET", param)
                    .done((data, status, xhr) => {
                        orderImagingWindow(new OrderImageViewModel(data, datarow.externalId));
                    })
                    .fail(function (error, msg, d) {
                        console.error(error, msg, d)
                        showmessage('An error has occured.')
                    });
                },
                cellsrenderer: function () {
                    return "View Images";
                },
                filterable: false,
                pinned: true,
                sortable: false

            },

            { text: 'Order Id', datafield: 'externalId', width: minWidth, 
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    const $el = $(defaulthtml!);
                    
                    if(value) {
                        const link = `<a href="/Orders/${value}" target="_blank" style="color:blue">${value}</a>`;
                        $el.html(link);
                    }
                    
                    return $el[0].outerHTML;
                } 
            },
            {
                text: 'Status', datafield: 'orderStatus',width: minWidth,  filtercondition: "OR",
                filtertype: "checkedlist",
                filteritems: [{ html: "Available", label: 1 }, { html: "Delivered", label: 2 }, { html: "In Progress", label: 3 }, { html: "Voided", label: 4 }, { html: "Quote", label: 5 }]
            },
            { text: 'Bill Date', datafield: 'billDate',width: 200, cellsformat: 'MM/dd/yyyy', createfilterwidget: createGeDateRangeFilterWidget(this.geDateWidget, "jqxImages"),
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    var cell = $(defaulthtml!);
                    cell[0].innerText = value ? datetimeUTC(value).format("MM/DD/YYYY") : null;
                    return cell[0].outerHTML;
                }
            },
            { text: 'Billed', datafield: 'readyToBill', width: minWidth,
                filtercondition: "OR",
                filtertype: "list",
                filteritems: [{ html: "Yes", label: true }, { html: "No", label: false }],
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    var cell = $(defaulthtml!);
                    cell[0].innerText = value ? "Y" : "N";
                    return cell[0].outerHTML;
                }
            },
            { text: 'Delivery Date', datafield: 'deliveryDate',width: 200, cellsformat: 'MM/dd/yyyy', createfilterwidget: createGeDateRangeFilterWidget(this.geDateWidget, "jqxImages"),
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    var cell = $(defaulthtml!);
                    cell[0].innerText = value ? datetimeUTC(value).format("MM/DD/YYYY") : null;
                    return cell[0].outerHTML;
                }
            },
            { text: 'Driver 1', datafield: 'driver1ExternalId',width: minWidth },
            { text: 'Driver 2', datafield: 'driver2ExternalId',width: minWidth }
        ];
        return columns;
    }
}

class OrderImageViewModel {
    orderExtId = ko.observable();
    imageList = ko.observableArray();
    errorOccured = ko.observable(false);
    showLoadingPanel = ko.observable(false);

    constructor(model, orderExtId) {
        this.orderExtId(orderExtId);
        this.imageList(ko.utils.arrayMap(model, function (image) { return new Image(image); }))
    }

    viewImage = (item) => {
        item = item || {};
        let data = {
            proNumber: this.orderExtId(),
            imageRequested: item.imageTypeId(),
            scanDate: item.date()
        };
        let imageName = this.orderExtId() + " - " + item.imageType() + ".pdf";
        this.showLoadingPanel(true);
        dataModel.downloadFile("Imaging/getImageDocument", "get", data, imageName)
        .then(() => {
            this.showLoadingPanel(false);
        })
        .catch((x) => {
            this.errorOccured(true);
            this.showLoadingPanel(false);
        })
    };
}

class Image {
        imageTypeId = ko.observable();
        imageType = ko.observable();
        date = ko.observable();
        orderNumber = ko.observable();
    constructor(model) {
        model = model || {};
        this.imageTypeId(model.imageTypeId);
        this.imageType(model.imageType)
        this.date(model.date);
        this.orderNumber(model.orderNumber);
    }
}

export default {viewModel: ImagingViewModel, template: template}