import * as wireTypes from '../../types';
import efsAdvanceService from '../../efsAdvanceServices';
import {useDispatch, useSelector} from 'ko-data-store';
import {isLoading} from 'dataStore-actions/appUI';
import userProfile from 'user-profile';
import {processQue} from 'data-store';

/**
 * @param {object} params - KO component parameters
 * @param {wireTypes.AdvanceRequestModel} params.formData
 * @param {function} params.displayErrorMsg - sets the error message to be displayed
 * @param {boolean} params.allowManualEntry - flag that sets the form to be editable no matter what, as long as wireId > 0 == false
 * @param {ko.Observable<boolean>} params.canWireAdvance - used to determine if inputs are readonly.
 * @param {ko.Observable<object>} params.resetFormElements - when true will reset the form elements, not part of the form data model
 * @param {object} params.sendWireData
 * @param {function} params.updateForm -- callback fn that maps form props to new state
 * @param {ko.Observable<object>} params.initFormData
 * @param {function} params.fetchWires
 */
export const efsEntryFormViewModel = function({formData, 
    displayErrorMsg, 
    allowManualEntry = undefined, 
    canWireAdvance = ko.observable(true),
    resetFormElements = ko.observable(),
    sendWireData = {},
    updateForm = function() {},
    initFormData = ko.observable(),
    fetchWires = function() {}
}) {

    const dispatch = useDispatch();
    this.updateQue = new processQue();
    //this.updateQue.debug(); // turns on console log tracing
    
    this.updateQue.onComplete((mergedState) => {
        updateForm(mergedState).then((state) => fetchWires());
    });


    this.formData = formData;
    this.fuelCardsSelectOptions = ko.observableArray([]);
    this.canWireAdvance = canWireAdvance;
    this.payeeFullName = ko.observable();

    this.isModalTypeAOP = ko.observable(sendWireData.advanceType() !== wireTypes.ADVANCE_FUND);

    this.isReadOnly = ko.computed(() => {
        let _isReadOnly = this.formData.wireId() != undefined; //|| this.canWireAdvance() == false;
        return _isReadOnly;
    });

    this.payeeDDLReadonly = ko.computed(() => this.isReadOnly() || sendWireData.advanceType() === wireTypes.ADVANCE_FUND);
    this.driverDDLReadonly = ko.computed(() => this.isReadOnly() || sendWireData.advanceType() === wireTypes.ADVANCE_FUND);
    this.orderDDLReadonly = ko.computed(() => this.formData.wireId() > 0);

    this.isFuelCardSelectVisible = ko.computed(() => {
        return this.isReadOnly() == false && sendWireData.advanceType() == wireTypes.ADVANCE_WIRE && this.fuelCardsSelectOptions().length > 0;
    });

    this.fundAvailableAmountText = ko.computed(() => {
        let max = this.formData.maxAllowed() ?? 0;
        return max.toFixed(2);
    });

    resetFormElements.subscribe((data) => {
        if(data) {
            
            resetFields(data);

            resetFormElements(null);
        }
    });

    const resetFields = ({
        payeeFullName = undefined,
        keepCards = false
    } = {}) => {
        
        if(isFormAopWire(sendWireData.advanceType()) && keepCards == false) {
            this.fuelCardsSelectOptions.removeAll();
            this.selectedFuelCard(null);
        }
        
        this.payeeFullName(payeeFullName);
    }

    this.getFuelCardWithPolicyNameText = (fuelCardInfo) => {
        if(!fuelCardInfo) return "";

        let text = fuelCardInfo.fuelCardId;

        if(fuelCardInfo.fuelInterfaceId == "H") {
            text += ` - Policy ${fuelCardInfo.subAccount}`;
        }

        return text;
    }

    // Add policy # if EFS
    this.fuelCardTextDisplay = ko.computed(() => { 
        let text = formData.fuelCardId();
        let subAccount = formData.subAccount(); 
        
        if(subAccount) { // comdata should be null, only populating if EFS from api.
            text += " - Policy " + subAccount;
        }
         
        return text;
    });

    this.handleOrderSelection = ko.pureComputed({
        read: () => this.formData.orderExId(),
        write: (val) => {
            if(this.orderDDLReadonly()) {
                this.updateQue.remove('orderDLL');
                return false; 
            }

            if(typeof val === 'string') {
                this.formData.orderExId(val);
                return false;
            }

            if(this.isModalTypeAOP()) {
                doWhenModalIsAopForOrderDDL(val);
            }
            else {
                doWhenModalIsFundForOrderDDL(val);
            }
        }
    })

    
    // html => autocomplete onRequest() param
    this.orderDDLOnRequest = () => {
        this.updateQue.waitOnTask('orderDDL');

        if(this.isModalTypeAOP() == false && orderDDLInit == false) {
            dispatch(isLoading(true));
        }
        
    }

    // Info coming from OrdersList (OrdersDDL)
    const doWhenModalIsAopForOrderDDL = (val) => {
        if(val) {
            let data = { orderId: val.id, movementId: val.movementId };
            this.updateQue.taskFinished('orderDDL', data);
        }
        else {
            this.updateQue.taskFinished('orderDDL', {orderId: null, orderExId: null, movementId: null});
        }

        orderDDLInit = true;
    }

    // Used for 'FUND' modal advance type
    // Since orderDDL triggers data when 'FUND'
    let orderDDLInit = false;
    const doWhenModalIsFundForOrderDDL = async(val) => {
        resetFields();
        let data = {};

        if(val) {

            dispatch(isLoading(true));
            const result = await efsAdvanceService.getFundInfo(val.code);
            const fundInfo = result.data;
            const fundAmounts = await efsAdvanceService.getFundAmounts(val.id);
            dispatch(isLoading(false));

            const {fuelCard, payeeFullName, canAdvanceOrder} = fundInfo;

            if(canAdvanceOrder == false) {
                displayErrorMsg("The selected order must be in progress status to issue an advance.");

                let advanceType = wireTypes.ADVANCE_FUND;
                data = new wireTypes.AdvanceRequestModel({driverExId: fundInfo.driverExId, payeeExId: fundInfo.payeeExId, advanceType, orderExId: val.code, orderId: val.id, wireId: "InvalidOrder"}).toJS();
                this.updateQue.taskFinished('orderDDL', data);

                return false;
            }

            if(!fuelCard) {
                
                let errorMsg = `The driver/payee for selected order does not have an active fuel card.`;
                displayErrorMsg(errorMsg);

                let advanceType = wireTypes.ADVANCE_FUND;
                data = new wireTypes.AdvanceRequestModel({driverExId: fundInfo.driverExId, payeeExId: fundInfo.payeeExId, advanceType, orderExId: val.code, orderId: val.id, wireId: "InvalidOrder"}).toJS();
                
                if(orderDDLInit == false) {
                    initFormData({...data, errorMsg});
                }

                this.updateQue.taskFinished('orderDDL', data);

                return false;
            }
            else {
                if(fuelCard.isBalanceBased && fuelCard.maxAdvPct == 0) {
                    let errorMsg = `The fuel card assigned to the driver does not have a preset percent to advance the card. <br/>
                        Please contact <a style="color: blue" href="mailto:card.services@greatwide-tm.com">card.services@greatwide-tm.com</a> to have this issue corrected.`;

                    displayErrorMsg(errorMsg);

                    let advanceType = wireTypes.ADVANCE_FUND;
                    data = new wireTypes.AdvanceRequestModel({driverExId: fundInfo.driverExId, payeeExId: fundInfo.payeeExId, advanceType, orderExId: val.code, orderId: val.id, wireId: "InvalidOrder"}).toJS();
    
                    if(orderDDLInit == false) {
                        initFormData({...data, errorMsg});
                    }
                    
                    this.updateQue.taskFinished('orderDDL', data);
    
                    return false;
                }
                else if(fundAmounts.available <= 0.00) {
                    let errorMsg = `The selected order has reached or exceeded amount available to advance. Total Advanced: $${fundAmounts.currentTotal}. Available: ${fundAmounts.available}`;
                    displayErrorMsg(errorMsg);
    
                    let advanceType = wireTypes.ADVANCE_FUND;
                    data = new wireTypes.AdvanceRequestModel({driverExId: fundInfo.driverExId, payeeExId: fundInfo.payeeExId, advanceType, orderExId: val.code, orderId: val.id, wireId: "InvalidOrder"}).toJS();
    
                    if(orderDDLInit == false) {
                        initFormData({...data, errorMsg});
                    }
                    
                    this.updateQue.taskFinished('orderDDL', data);
    
                    return false;
                }
            }

            displayErrorMsg("");

            data.wireId = undefined;
            data.orderId = fundInfo.id;
            
            this.payeeFullName(payeeFullName);

            data.payeeId = fundInfo.payeeId;
            data.payeeExId = fundInfo.payeeExId;
            data.driverId = fundInfo.driverId;
            data.driverExId = fundInfo.driverExId;
            data.movementId = fundInfo.movementId;

            data.fuelCardId = fuelCard.fuelCardId;
            data.vendor = fuelCard.isComdataCard ? 'C' : 'H';
            data.wireCode = fuelCard.isComdataCard ? 'X' : 'F';
            data.subAccount = fuelCard.isEFSCard ? fuelCard.subAccount : undefined;
            
            data.description = `Advance of ${fundAmounts.maxAdvPerc}% of order.`;
            data.maxAllowed = fundAmounts.available;
            data.advLimit = fundAmounts.available;
            data.amount = fundAmounts.available;

            if(orderDDLInit == false) {
                const x = {...initFormData(), ...data, payeeFullName };
                initFormData(x);
            }
            
        }
        else {
            displayErrorMsg("A valid order is required to fund driver.");
            let advanceType = wireTypes.ADVANCE_FUND;
            data = new wireTypes.AdvanceRequestModel({orderId: null, movementId: null, advanceType, wireId: "InvalidOrder"}).toJS(); // setting wireId to string to trigger the readonly computed...
        }
       
        this.updateQue.taskFinished('orderDDL', data);

        orderDDLInit = true;
    }

    this.handlePayeeSelection = ko.pureComputed({
        read: () => this.formData.payeeExId(),
        write: (val = {}) => {

            const vendor = this.formData.advanceType() !== wireTypes.ADVANCE_EXPRESS ? null : "H";
            const wireCode = this.formData.advanceType() !== wireTypes.ADVANCE_EXPRESS ? null : "T";

            const payeeInfo = updatePayee(val)({...this, resetFields})({vendor, wireCode});
            this.updateQue.taskFinished('payeeDDL', payeeInfo);
            this.payeeFullName(val.name);

            if(isFormAopWire(sendWireData.advanceType()) && this.payeeDDLReadonly() == false) {
               filterFuelCards();
            }
        }
    });

    this.handleDriverSelection = ko.pureComputed({
        read: () => this.formData.driverExId(),
        write: (val = {}) => {           
            const driverInfo = updateDriver(val)({...this, resetFields})();
            this.updateQue.taskFinished('driverDDL', driverInfo);
            
            if(isFormAopWire(sendWireData.advanceType()) && this.driverDDLReadonly() == false) {
                filterFuelCards();
            }
        }
    });

    const filterFuelCards = () => {
        const {payeeId, driverId } = this.formData.toJS();
        this.fuelCardsSelectOptions.removeAll();
        this.selectedFuelCard(null);

        fuelCards(payeeId).then((cards) => {
            const filteredCards = cards.filter(q => (!driverId || q.driverId == driverId) && (!payeeId || q.payeeId == payeeId));

            if(filteredCards.length > 1 || (filteredCards.length == 1 && !driverId && filteredCards[0].driverId)) {
                const x = [{fuelCardId: "Please Choose...", isDummyOption: true}, ...filteredCards];
                this.fuelCardsSelectOptions(x);
                this.selectedFuelCard(x[0]);
            }
            else {
                this.fuelCardsSelectOptions(filteredCards);
                this.selectedFuelCard(filteredCards[0]);
            }
        });
    }
    

    this.selectedFuelCard = ko.observable();

    this.selectedFuelCard.subscribe((val) => {
        const isFocused = isElementFocused();
    
        // If user is focused in driver or payee ddl, prevent the fuelcard
        // from updating that input until they leave.
        if(isFocused(['#advanceDriverDDL', '#advancePayeeDDL']) == false) {
            this.updateQue.waitOnTask('fuelCardSelected');
            const vendor = val && val.isComdataCard ? wireTypes.VENDOR_COMDATA : wireTypes.VENDOR_EFS;
            const wireCode = val && val.isComdataCard ? wireTypes.AOP_COMDATA : wireTypes.AOP_WIRE;
    
            const fuelCardInfo = updateFuelCard(val)(this)({vendor, wireCode});
    
            this.updateQue.taskFinished('fuelCardSelected', fuelCardInfo);
        }
    });
    
    const isElementFocused = () => {
        return (elementIds = []) => {
            return elementIds.some(elementId => {
                const $el = $(elementId);

                return $el.length && $el.is(':focus');
            });
           
        }
    }

    const updatePayee = (newPayee) => {
        return ({formData, payeeDDLReadonly, resetFields}) => {
            return ({vendor, wireCode}) => {
                const { payeeId, payeeExId, driverExId, driverId } = formData.toJS();

                if(payeeDDLReadonly()) {
                    return {};
                }
                else if(typeof newPayee === 'string' && newPayee.length) {
                    return { payeeExId: newPayee };
                }
                else if(!newPayee || Object.keys(newPayee).length == 0) {
                    resetFields();
                    return { payeeId: null, payeeExId: null, driverId: undefined, driverExId: undefined, vendor: vendor, wireCode: wireCode };
                }
                else if(newPayee.id == payeeId) {
                    return {};
                }
                else if (driverExId && newPayee.driverExIds.some(x => x == driverExId) == false) {
                    resetFields();
                    return { payeeId: newPayee.id, payeeExId: newPayee.code, driverId: undefined, driverExId: undefined };
                }
                else {
                    resetFields();
                    return { payeeId: newPayee.id };
                }
            }
        }
    }

    // Returns ...() => {}
    const updateDriver = (newDriver) => {
        return ({formData, driverDDLReadonly, resetFields}) => {
            return () => {
                const { payeeId, payeeExId, driverId } = formData.toJS();

                if(driverDDLReadonly()) {
                    return {};
                }
                else if(typeof newDriver === 'string' && newDriver.length) {
                    return { driverExId: newDriver };
                }
                else if(!newDriver || Object.keys(newDriver).length == 0) {
                    return { driverId: null, driverExId: null };
                }
                else if(newDriver.id == driverId) {
                    return {};
                }
                else if(newDriver.payeeId && newDriver.payeeExId && (payeeExId == undefined || payeeId != newDriver.payeeId)) {
                    resetFields();
                    return {driverId: newDriver.id, driverExId: newDriver.code, payeeId: newDriver.payeeId, payeeExId: newDriver.payeeExId };
                }
                else {
                    return { driverId: newDriver.id };
                }
            }
        }
    }

    const updateFuelCard = (fuelCard) => {
        return ({formData, isReadOnly}) => {
            return ({vendor, wireCode}) => {
                const { driverExId, driverId, fuelCardId } = formData.toJS();
                
                if(isReadOnly()) {
                    return {};
                }
                else if(!fuelCard) {
                    return {fuelCardId: null, vendor: null, wireCode: null};
                }
                else if(fuelCard.isDummyOption) {
                    return {fuelCardId: null, vendor: null, wireCode: null};
                }
                else if(fuelCard.fuelCardId == fuelCardId) {
                    return {vendor: vendor, wireCode: wireCode};
                }
                else if((!driverExId && !driverId) || (driverExId != fuelCard.driverExId)) {
                    return { 
                        driverId: fuelCard.driverId, 
                        driverExId: fuelCard.driverExId,
                        fuelCardId: fuelCard.fuelCardId, 
                        vendor: vendor,
                        wireCode: wireCode
                    };
                }
                else {
                    return { 
                        fuelCardId: fuelCard.fuelCardId, 
                        vendor: vendor,
                        wireCode: wireCode
                    };
                }
            }
        }
    }

    // Returns ...() => {} || null
    const driverFuelCard = (driverExId) => {
        return (cards) => {
            if(!cards || !driverExId) return null;
            return cards.find(x => x.driverExId == driverExId);
        }
    }

    // (async) Returns []
    const getFuelCards = (fuelCards) => {
        let isProcessing = false;
        return async (payeeId) => {
            const fetchCards = () => {
                if(isProcessing) return false;
                if(!payeeId) return false;

                return fuelCards.some(q => q.payeeId == payeeId) == false;
            }
    
            if(fetchCards()) {
                isProcessing = true;
                const result = await efsAdvanceService.getFuelCards({payeeId, canReceiveAdvance: this.isReadOnly() == false ? true : null });
                //let cards = result.data.filter(x => fuelCards.some(q => q.fuelCardId == x.fuelCardId) == false);
                fuelCards = result.data;
                isProcessing = false;
            }
           
            return fuelCards.filter((card) => card.payeeId == payeeId);
        }
    }

    // (async) Returns []
    const fuelCards = getFuelCards([]); // init a scoped function to keep inner state.

    const isFormAopWire = (advanceType) => {
        return advanceType === wireTypes.ADVANCE_WIRE;
    }

    const initialize = async () => {
        const {advanceType, payeeId, driverId} = sendWireData;

        if(isFormAopWire(advanceType())) {
            if(payeeId) {
                fuelCards(payeeId).then((cards) => {
                    if(driverId) {
                        let card = cards.find(q => q.driverId == driverId);
                        this.fuelCardsSelectOptions([card]);
                        this.selectedFuelCard(card);
                    }
                    else {
                        this.fuelCardsSelectOptions(cards);
                        this.selectedFuelCard(cards[0]);
                    }
                })
            }
        }
    }

    initialize();
}


import template from './efs-entry-form-component.html';
export default { viewModel: efsEntryFormViewModel, template: template }