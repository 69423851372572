/// <reference path="../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/jqwidgets.d.ts" />
/// <reference path="../../../../node_modules/@types/jqueryui/index.d.ts" />
import { Observable, ObservableArray, Computed, SubscribableFunctions, ObservableExtenderOptions } from "knockout";

import * as ko from "knockout";
import dataModel from "data-model";
import GridStateComponentViewModel from "../../shared-components/jqx.grid-state-component/jqx.grid-state-component";
import { GenerateGuid } from "global-functions";
import { showconfirm } from "show-dialog-methods";
import router from "router";

const overrideTempFilterName = "SearchFilterData";

const fetchSearchFiltersAsync = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("UserProfile/GetUserSearchFilterList", "GET", payload)
        .done(resolve)     
        .fail((err) => reject((err.responseJSON && err.responseJSON.message) || `An error occurred during request.`))   
    })
}

const convertJsonStringToObj = (data = "") => {
    try {
        const x = JSON.parse(data);
        return x;
    }
    catch {
        return {}
    }
}

//{name: this.gridId, pageName: this.currentUrl}
export const loadSavedSearches = async (name, currentUrl) => {
    currentUrl = currentUrl || window.location.pathname;
    return await fetchSearchFiltersAsync({name, pageName: currentUrl}) as Array<any>;
}

interface SearchListModelInterface {
    label?: string;
    value?: unknown;
}

class SearchListModel implements SearchListModelInterface {
    
    value;
    label;

    constructor(x) {
        
        this.value = x.value;
        this.label = x.label;
    }
}

export class SearchFilterSavesViewModel {
    prevSelected = new SearchListModel({});
    
    _selectedSavedSearchId: Observable<SearchListModelInterface | undefined> = ko.observable();
    selectedSavedSearchId: ko.PureComputed<SearchListModelInterface | undefined> = ko.pureComputed({
        read: this._selectedSavedSearchId,
        write: (val) => {
            this._selectedSavedSearchId(val);
        }
    });
    savedSearches: Observable<any[]> = ko.observableArray([]);
    selectedSavedSearch: Observable<any> = ko.observable();

    currentUrl = window.location.pathname;
    clearFilter;
    refreshSearchList;
    handleOnSelected;
    gridId;
    $grid;
    dropdownListId = GenerateGuid(6);
    showNewTabPopup = false;
    
    constructor({gridId, onClearFilter, onRefreshSearchList, onSelected}) {
        console.log(`saved search loaded`, gridId)
        this.gridId = gridId;
        this.$grid = $(`#${gridId}`);
        this.showNewTabPopup = false;

        this.clearFilter = onClearFilter || ko.observable(false);
        this.refreshSearchList = onRefreshSearchList || ko.observable("");
        this.handleOnSelected = onSelected;

        this.selectedSavedSearch.subscribe((data) => {
            
            if(data && (data.label !== this.prevSelected.label)) { 
                this.applyGridFilters(data.value, data.label, this.showNewTabPopup);
            }
            
            if(data == null) {
                this.prevSelected = new SearchListModel({});
            }

            this.showNewTabPopup = true;
        });    
        
        //const query = this.getURLSearchQuery();
        const query = this.getLoadSearchOverride();
        this.getSearchFilters(query.gridId === gridId ? query.searchName : "");

        this.clearFilter.subscribe((yes) => yes && this.handleResetSelection())
        this.refreshSearchList.subscribe((searchName) => {
            if(searchName && searchName.length > 0) {
                this.refreshSearchList("");
                this.getSearchFilters(searchName);
            }
        })
    }

    getLoadSearchOverride = () => {
        try {
            const dataStr = window.sessionStorage.getItem(overrideTempFilterName);
    
            if(dataStr) {
                const data = JSON.parse(dataStr);
                window.sessionStorage.removeItem(overrideTempFilterName);

                return data;
            }
            
            return {};
        }
        catch(err) {
            console.error(err);
            return {};
        }
        
    }

    getURLSearchQuery = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const searchname = urlParams.get('searchName');
        const gridId = urlParams.get('gridId');
    
        return { searchname, gridId };
    }

    getSearchFilters = async (defaultSelectedName = "") => {
        const response = await loadSavedSearches(this.gridId, this.currentUrl);
        const defaultSearch = response.find(x => x.isDefault);
        this.showNewTabPopup = (defaultSelectedName == null || defaultSearch == null);

        const mapped = response.filter(x => x.searchName !== "").map(x => new SearchListModel({ label: x.searchName, value: x.search })) as Array<any>;
        this.savedSearches(mapped);
        
        let item;
        if(defaultSelectedName) {
            item = mapped.find(x => x.label === defaultSelectedName);
        }
        else if(defaultSearch) {
            item = mapped.find(x => x.label === defaultSearch.searchName);
        }

        if(item) {
            this.showNewTabPopup = false;
            this.selectedSavedSearchId(item);
        }
        else {
            this.applyGridFilters("");
        }
    }

    handleResetSelection = () => {
        ($(`#${this.dropdownListId}`) as any).jqxDropDownList("clearSelection");
        this.selectedSavedSearchId(null);
        this.selectedSavedSearch(null);
        this.clearFilter(false);
    }

    applyGridFilters = async (stateStr = "", searchName = "", showNewTab = false) => {
        if(searchName && showNewTab) {
            const yes = await showconfirm(`Do you want to open the saved search in a new tab?`)

            if(yes) {
                //router.navigate(`${this.currentUrl}?searchName=${searchName}&gridId=${this.gridId}`, true);

                const tab = window.open(window.location.href, "_blank");

                if(tab) {
                    tab.sessionStorage.setItem(overrideTempFilterName, JSON.stringify({searchName, gridId: this.gridId}));
                }


                if(this.prevSelected && this.prevSelected.label) {
                    const item = this.savedSearches().find(x => x.label === this.prevSelected.label);
                    if(item) {
                        this.selectedSavedSearchId(item);
                    }
                    
                }
                else {
                    this.handleResetSelection();
                }
                
                return false;
            }
            
        }
        const searchData = convertJsonStringToObj(stateStr);

        if(searchData.userSearchName !== this.prevSelected.label) {
            this.prevSelected = new SearchListModel({label: searchData.userSearchName, value: stateStr});
        }

        if(this.handleOnSelected) {
            this.handleOnSelected(searchData);
        }
        // else {
        //     console.log(`Laod Default State`)
        //     //this.grid.jqxGrid('loadstate', searchData);
        // }
        
    }
    //loadSaveSearchModalOverride;



//var saveSearchModalCallback = null;
        // loadSaveSearchModal = function (grid, callback, selectList, additionalFilters) {

        //     saveSearchModalCallback = typeof callback == 'function' ? callback : function() {};
        //     selectList = selectList || [];

        //     let modalId = grid.attr("id") + "SaveSOSearch";

        //     // remove existing so we can rebuild modal
        //     $("#" + modalId).remove();

        //     let div = $("<div>", { id: modalId});
        //     div.addClass("modal fade in");
        //     $("body").append(div);

        //     let inputPlaceholderTest = "Enter a new name...";
        //     let $form = $("<div></div>", {class: 'form-group'});
        //     let inputBox = $("<input>", { type: "text", id: "savedSearchInputBox", class: 'form-control', placeholder: inputPlaceholderTest}).wrap($form);
        //     //inputBox.jqxInput();
            

        //     let modalHeader = $("<div>", { class: "modal-header" });
        //     let modalBody = $("<div>", { class: "modal-body" });
        //     let modalContent = $("<div>", { class: "modal-content" });
        //     let modalDialog = $("<div>", { class: "modal-dialog" });
        //     modalHeader.append('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>');
        //     modalHeader.append("<strong>Save Search Name</strong>");
        //     modalBody.css("overflow-y", "auto");
        //     div.css("overflow-x", "auto");
        //     modalDialog.css("width", "300px");
        //     div.wrapInner(modalBody);
        //     div.prepend(modalHeader);
        //     div.wrapInner(modalContent);
        //     div.wrapInner(modalDialog);
        //     div.modal('id-name'); //{ show: true, backdrop: "static" }

        //     let $errorMsg = $('<p></p>', { class: 'text-danger', style: 'font-size: 12px; padding: 3px;' });


        //     function optionTemplate(x) {
        //         return `<option value="${x.id}">${x.text}</option>`;
        //     }

        //     function resetForm() {
        //         inputBox.val("");
        //         inputBox.attr('placeholder', inputPlaceholderTest);
        //         $("#savedSearchSelectList").val('0').change();
        //     }

        //     if(selectList.length) {

        //         let $selectDDL = $(`<div class="form-group">
        //             <select id="savedSearchSelectList" class="form-control">
        //                 <option selected value="0">Select Existing...</option>
        //                 ${selectList.map((x) => optionTemplate(x))}
        //             </select>
        //         </div>
        //         <div>
        //             <p class="divider-with-text">OR</p>
        //         </div>
        //         `);

        //         modalBody.append($selectDDL);

        //         $("#savedSearchSelectList").on("change", function(e) {
        //             $errorMsg.text("");
        //             if(e.currentTarget.value != "") {
        //                 inputBox.val("");
        //                 inputBox.attr('placeholder', inputPlaceholderTest);
        //             }

        //         });

        //     }

        //     // let gridStateViewModel = new GridStateComponentViewModel.viewModel();
        //     // gridStateViewModel.actions = [
        //     //     "Clear Filters",
        //     //     "Clear Selection",
        //     //     "Refresh",
        //     //     "Save Search",
        //     //     "Show/Hide/Reorder Columns",
        //     //     "Match My Truck",
        //     //     "Post Truck",
        //     //     "Advanced Alert Settings",
        //     //     "Radius Search",
        //     //     "Insert Call-in Record",
        //     //     "Driver Call-in Listing",
        //     //     "Export All Tractors"

        //     // ];
    

        // }    
}

import template from './SearchFilter-Saves-Component.html';
import { over } from "lodash";
export default { viewModel: SearchFilterSavesViewModel, template: template };
