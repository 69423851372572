import { isLoading } from 'dataStore-actions/appUI';
import dataModel from 'data-model';
import { useDispatch } from 'ko-data-store';
import {cleanString, setBtnDisabledState, setBtnEnabledState} from 'global-functions';
import toast from 'geNotyf';
import userProfile from 'user-profile';

const sendSaveRateUploadAsync = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/LaneRateList", "POST", payload).done((response) => resolve(response))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while saving.`))
    })
}

const mapImportData = (rateNumber, data = {}) => {
    const d = {...data, rateNumber, agencyId: userProfile.currentAgencyId() }

    return Object.keys(d).reduce((x, key) => {
        const keyClean = cleanString([" "], key);
        
        switch(keyClean) {
            case 'OriginCode':
                x['OrgCode'] = d[key];
                break;
            case 'DestinationCode':
                x['DestCode'] = d[key];
                break;
            case 'PttoPt':
                x['PointToPoint'] = d[key];
                break;
            default:
                x[keyClean] = d[key];
                break;
        }

        if(keyClean == 'Rate') {
            x['RateTableId'] = d['Method'] == 'R' ? d['Rate'] : null;
            x['Rate'] = d['Method'] != 'R' ? d['Rate'] : null;
        }
        
        return x;
    }, {})
}

export const batchLaneRateImportViewModel = function({
    rateNumber = undefined,
    onSave = function() {},
    editMode = false
} = {}) {
    const vm = this;
    const dispatch = useDispatch();

    vm.display = ko.observable(false);
    vm.errors = ko.observableArray([]);
    vm.isProcessing = ko.observable(false);
    vm.editMode = ko.observable(editMode);

   
    $('#rate-batch-lanerate-import-modal').on('hidden.bs.modal', () => {
        vm.display(false);
        vm.editMode(false);
    });

    vm.handleUpload = () => {
        vm.errors.removeAll();
        
        const files = $('#jqxLaneRateFileInput')[0].files;
        if (files.length > 0) {
            const csvFile = files[0];  // only allowing one file to be chosen
            parseCSVFile(csvFile);
        }
    }

    const parseCSVFile = function (file) {
        if (file) {
            const reader = new FileReader();
            
            reader.onload = () => {
                const result = $.csv.toObjects(reader.result, {}, saveRates);

            };

            reader.readAsText(file);
        }

        return file;
    };

    const saveRates = async (err, rateTables = []) => {
        $('#jqxLaneRateFileInput').val("");
        const mappedData = rateTables.map(x => mapImportData(rateNumber, x));

        dispatch(isLoading(true));
        try {
            const result = await sendSaveRateUploadAsync(mappedData);
            dispatch(isLoading(false));
            
            toast.open({type: 'info', message:  `Successfully saved ${result.length} rate table(s).`});

            onSave();

        }
        catch(err) {
            dispatch(isLoading(false));
            vm.errors.push(err);
        }
    }

    const init = () => {
        if(rateNumber > 0) {
            vm.display(true);
        }
    }

    init();
}

import template from './batch-lanerate-import-component.html';
export default { viewModel: batchLaneRateImportViewModel, template: template }