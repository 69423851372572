import dataModel from 'data-model';
import gridStateModel from "jqx.grid-state-component";
import gridUtils from "jqx.grid-utils";
import userProfile from "user-profile";
import { GreatEdgeCustomWidgets, createGeDateRangeFilterWidget } from 'ge.custom-widgets';
import {useDispatch, useSelector } from 'ko-data-store';
import { getUserFilters, addUserFilters } from '../../../dataStore/actions/userFilters';
import gridStateUtils from "jqx.grid-utils";
import { datetimeUTC,mapGridDate } from 'global-functions';
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";

const extraActionHandlers = {
    export: (getDataFn, handlerFn) => {
        return {
            action: "Export",
            handler: () => handlerFn(getDataFn())
        }
    }
}

const tendersPanelComponentViewModel = function({showGrid = ko.observable(false)}) {
    const vm = this;
    const dispatch = useDispatch();
    
    const gridId = "rates-tenders-panel-grid";
    let $grid = $('#'+gridId);
    const geDateWidget = new GreatEdgeCustomWidgets();
    const jqxWidgetsGridHelper = geDateWidget.GEWidgetsGridHelper(gridId);
    vm.clearSelectedSavedSearchDDL = ko.observable(false);
    vm.refreshSavedSearchDDL = ko.observable("");
    vm.initGridState = null;
    let auctionTypeList = [{label: null, html: 'Any'}];
    let statusList = [{label: null, html: 'Any'}];
    let equipmentTypeList = [{label: null, html: 'Any'}];

    const flags = {
        gridInit: false
    }

    vm.handleOnSelected = (state) => {
        vm.initGridState = state.grid || {};

        if(showGrid() === false) return;

        if(flags.gridInit == false){
            buildGrid(gridId, gridSource(), gridColumns(gridId, state))
        }
        else {
            gridStateUtils.applyGridState("rates-tenders-panel-grid", state.grid);
        }
    }

    const handleExport = (data) => {
        if(data) {
            dataModel.exportToCSV(data, `Tenders - Exported ${datetimeUTC(new Date()).format("MM-DD-YYYY-HHmmss")} `, true);
        }
    }

    const buildGrid =  (gridId, source = {}, columns = []) => {
        $grid = $('#'+gridId);

        const dataAdapter = dataModel.getDataAdapter(source);

        $grid.jqxGrid({
            theme: 'GWTMDark',
            width: "100%",
            source: dataAdapter,
            altrows: true,
            sortable: true,
            pageable: true,
            pagesize: 20,
            columnsreorder: true,
            columnsmenu: false,
            columnsresize: true,
            enablebrowserselection: true,
            height: 500,
            autoheight: true,
            filterable: true,
            showfilterrow: true,
            showtoolbar: true,
            columns: columns,
            virtualmode: true,
            rendertoolbar: (toolbar) => {
                const gridStateVM = new gridStateModel.viewModel();
                gridStateVM.pageName = "RatesWindow-Tenders";
                var tmpl1 = gridStateModel.template;
                 
                const actions = [...gridStateVM.actions, "Export"];
                gridStateVM.actions = actions;
                gridStateVM.actions.push("Save Search");
                //const jqxGridHelper = geDateWidget.GEWidgetsGridHelper($grid.attr('id'), { validFilterValues: ["Both", "Please Choose:", "Any"] });
                
                
                gridStateVM.extraActionHandlers = [
                    extraActionHandlers.export(() => gridStateUtils.formatGridHeaders(gridId, false), handleExport),
                ];

                gridStateVM.setDefaultSearchOverride = async () => {
                    const savedSearches = await loadSavedSearches($grid.attr("id"));
                    const filters = {
                        isDefault: true
                    }
    
                    gridStateVM.loadSaveSearchModal(
                        $grid,
                        (val) => {
                            if(val && val.searchname) {
                                vm.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
                }

                gridStateVM.loadSaveSearchModalOverride = async () => {
                    const savedSearches = await loadSavedSearches($grid.attr("id"));
                    const filters = {
                        isDefault: false
                    }
    
                    gridStateVM.loadSaveSearchModal(
                        $grid,
                        (val) => {
                            if(val && val.searchname) {
                                vm.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
                }

                gridStateVM.clearFilters = () => {
                    //jqxGridHelper.clearDateRangeFiltersFromGrid(true);

                    $grid.jqxGrid('clearfilters');
                    vm.clearSelectedSavedSearchDDL(true);
                }

                var tendersGridAction = $("#tendersGridAction");
                tendersGridAction.append(tmpl1);
                toolbar.append(tendersGridAction);
                var tendersGridTmpl = $("#tendersGridToolbar");
                toolbar.append(tendersGridTmpl);

                

                // tendersGridTmpl.append(tendersGridAction);
                
                ko.applyBindingsToDescendants(gridStateVM, tendersGridAction[0]);
            },
            ready: () => {
                gridUtils.applyGridState($grid.attr('id'), vm.initGridState);
                flags.gridInit = true;
            }, 
            rendergridrows: (obj) => {
                return obj.data;
            },
        });

        $grid.on('bindingcomplete', () => {
            $grid.jqxGrid('refreshfilterrow');
        })

        return $grid;
    }

    const gridSource = () => {
        return {
            url: "Rates/TendersByFilters",
            type: "GET",
            datafields: [
                { name: "id", type: "int" },
                { name: "customer", type: "string" },
                { name: "auctionNumber", type: "string" },
                { name: "mode", type: "string" },
                { name: "auctionType", type: "string" },
                { name: "status", type: "string" },
                { name: "bidRate", type: "number" }, // $$
                { name: "bidStatus", type: "boolean" },
                { name: "timeLeft", type: "string" },
                { name: "startDate", type: "date" },
                { name: "hazmat", type: "boolean" },
                { name: "tankerEndorsement", type: "boolean" },
                { name: "originName", type: "string" },
                { name: "origin", type: "string" },
                { name: "pickupDate", type: "date" },
                { name: "destName", type: "string" },
                { name: "destination", type: "string" },
                { name: "deliveryDate", type: "date" },
                { name: "stops", type: "int" },
                { name: "equipmentType", type: "string" },
                { name: "distance", type: "number" },
                { name: "weight", type: "number" },
                { name: "orderExId", type: "string" },
                { name: "apiBid", type: "number" }, // $$
            ],
            datatype: "json",
            filter: function (cellValue, rowData, dataField, filterGroup, defaultFilterResult) {
                if(flags.gridInit) {
                    $grid.jqxGrid('updatebounddata', 'filter');
                }
                
            },
            sort: function (column, sortOrder) {
                if(flags.gridInit) {
                    $grid.jqxGrid('updatebounddata', 'sort');
                }
            },
            formatdata: (data = {}) => {
                const x = $grid.jqxGrid('getfilterinformation');
                const filters = gridStateUtils.formatGridFilters(x || [], data);
                //const dateFilters = jqxWidgetsGridHelper.addGridFiltersToDataObj(data) || {};
                
                return { ...filters };
            },
            beforeLoadComplete: (x, recordData) => {
                recordData = recordData || { tenders: [] };
                
                return recordData.tenders.map(x => {
                    if(x.startDate) {
                        const d = datetimeUTC(mapGridDate(x.startDate));
                        x.startDate = d.isValid() ? d.format("MM/DD/YYYY") : null;
                    }
                    if(x.pickupDate) {
                        const d = datetimeUTC(mapGridDate(x.pickupDate));
                        x.pickupDate = d.isValid() ? d.format("MM/DD/YYYY") : null;
                    }
                    if(x.deliveryDate) {
                        const d = datetimeUTC(mapGridDate(x.deliveryDate));
                        x.deliveryDate = d.isValid() ? d.format("MM/DD/YYYY") : null;
                    }

                    if(x.bidStatus != null) {
                        x.bidStatus = x.bidStatus ? 'Yes' : 'No';
                    }

                    if(x.hazmat != null) {
                        x.hazmat = x.hazmat ? 'Yes' : 'No';
                    }

                    if(x.tankerEndorsement != null) {
                        x.tankerEndorsement = x.tankerEndorsement ? 'Yes' : 'No';
                    }

                    if(x.auctionType && auctionTypeList.some(q => q.html === x.auctionType) === false) {
                        auctionTypeList.push({html: x.auctionType, label: x.auctionType})
                    }

                    if(x.status && statusList.some(q => q.html === x.status) === false) {
                        statusList.push({html: x.status, label: x.status})
                    }

                    if(x.equipmentType && equipmentTypeList.some(q => q.html === x.equipmentType) === false) {
                        equipmentTypeList.push({html: x.equipmentType, label: x.equipmentType})
                    }

                    return x;
                })

            }
        };
    }

    const gridColumns = (gridName = "", initGridState = {}) => {
        return [
            { text: "Customer", datafield: "customer", minwidth: 200 },
            { text: "Auction Number", datafield: "auctionNumber", minwidth: 150 },
            { text: "Mode", datafield: "mode", minwidth: 100, filtertype: 'list', filteritems: [{html: "Both", label: "Both"},{html: "LTL", label: "LTL"}, {html: "TL", label: "TL"}] },
            { text: "Auction Type", datafield: "auctionType", minwidth: 100, filtertype: 'list', filteritems: auctionTypeList},
            { text: "Status", datafield: "status", minwidth: 150, filtertype: 'list', filteritems: statusList},
            { text: "Current Bid", datafield: "bidRate", minwidth: 150, cellsformat: 'c2' },
            { text: "Submitted Bid", datafield: "apiBid", minwidth: 150, cellsformat: 'c2' },
            { text: "Bid Status", datafield: "bidStatus", minwidth: 150, filtertype: 'list', filteritems: [{html: "Yes", label: "Yes"},{html: "No", label: "No"}], },
            { text: "Hazmat", datafield: "hazmat", minwidth: 100, filtertype: 'list', filteritems: [{html: "Yes", label: "Yes"},{html: "No", label: "No"}] },
            { text: "Tanker Endorsement", datafield: "tankerEndorsement", minwidth: 100, filtertype: 'list', filteritems: [{html: "Yes", label: "Yes"},{html: "No", label: "No"}] },
            { text: "Origin Name", datafield: "originName", minwidth: 200 },
            { text: "Origin", datafield: "origin", minwidth: 150 },
            { text: "Pickup Date", datafield: "pickupDate", minwidth: 200, createfilterwidget: createGeDateRangeFilterWidget(geDateWidget, gridName, initGridState.grid) },
            { text: "Destination Name", datafield: "destName", minwidth: 200 },
            { text: "Destination", datafield: "destination", minwidth: 150 },
            { text: "Delivery Date", datafield: "deliveryDate", minwidth: 200,createfilterwidget: createGeDateRangeFilterWidget(geDateWidget, gridName, initGridState.grid) },
            { text: "Equipment Type", datafield: "equipmentType", minwidth: 150, filtertype: 'list', filteritems: equipmentTypeList},
            { text: "Distance", datafield: "distance", minwidth: 100, filterable: false },
            { text: "Weight", datafield: "weight", minwidth: 100, filterable: false },
            { text: "Order Id", datafield: "orderExId", minwidth: 150 }
        ];
    }
    
}

import template from './tenders-panel-component.html';
export default { viewModel: tendersPanelComponentViewModel, template: template }