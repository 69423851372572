import { ITrackingBrokerageAssignment } from "./types";

function TrackingBrokerageAssignmentModel(
  initData: ITrackingBrokerageAssignment = {}
) {
  return {
    carrierContactEmail: initData.carrierContactEmail,
    carrierMC: initData.carrierMC,
    carrierPhone: initData.carrierPhone,
    carrierTractor: initData.carrierTractor,
    carrierTrailer: initData.carrierTrailer,
  };
}

export default TrackingBrokerageAssignmentModel;
