import * as ebCommon from '../../externalBoardCommon';
import { useDispatch } from 'ko-data-store';
import { isLoading, getTrailerTypes } from 'dataStore-actions/appUI';

const saveCallNoteAndViewRecord = async ({tractorOrLoadId, board, callNotes}) => ebCommon.sendSaveViewRecord({loadId: tractorOrLoadId, board, callNotes})

export const callNoteModel = function ({
    tractorOrLoadId = undefined,
    callNotes = undefined
} = {}) {

    this.tractorOrLoadId = ko.observable(tractorOrLoadId);
    this.callNotes = ko.observable(callNotes);
}

const callNotesViewModel = function({
    tractorOrLoadId = undefined,
    board = undefined,
    callNotes = undefined,
    onSave = function(x) {}
} = {}) {
    const vm = this;
    const dispatch = useDispatch();

    vm.form = new callNoteModel({tractorOrLoadId, callNotes});
    vm.error = ko.observable();

    vm.handleSave = async () => {
       const {callNotes} = ko.toJS(vm.form);
       dispatch(isLoading(true));
       try {
        
           await saveCallNoteAndViewRecord({tractorOrLoadId, board, callNotes});
           onSave(callNotes);
       }
       catch(err) {
           vm.error(err);
       }
       dispatch(isLoading(false));
    }
}

import template from './call-notes.html';
export default { viewModel: callNotesViewModel, template: template }