import dataModel from 'data-model';

// Action types
export const REFRESH_EDI_DELAY_CODES = "apiLookups/refreshEDIDelayCodes";

function createLookupCache(cache = {}) {

    const remove = (key) => {

        delete cache[key];

        return key;

    }


    const add = (key, result = [], timelimit) => {

        cache[key] = { result, cacheTimestamp: Date.now(), timelimit: timelimit && (Date.now() + timelimit) };

        return result;

    }


    const get = (key, forceRefresh) => {

        const {result, cacheTimestamp, timelimit} = cache[key] || {};

        if((cacheTimestamp < timelimit) || forceRefresh) {

            if(forceRefresh) {

                remove(key);

            }


            return [];

        }

        return result || [];

    }

    return {
        get,
        add
    }

}

export const fetchEDIDelayCodes = ({partnerId}) => async(dispatch, state) => {
    
    let {ediDelayCodes} = state.apiLookups;
    let results = [];

    try {
        results = ediDelayCodes[partnerId];

        if(!results || !results.length) { 
            results = await dataModel.ajaxRequest("Lookup/DelayCodes", "GET", {partnerId}, true);
        }

    }
    catch(err) {
        console.error(err);
    }
    
    return {type: REFRESH_EDI_DELAY_CODES, payload: results, meta: { key: partnerId } }
}




// Updater / reducer
const initState = {
    ediDelayCodes: {}
}

export default function apiLookups(state = initState, action) {
    switch(action.type) {
        case REFRESH_EDI_DELAY_CODES:
            return { ...state, ediDelayCodes: { ...state.ediDelayCodes, [action.meta.key]: action.payload } }
        default:
            return state;
    }
}


// SELECTORS
export const selectEDIDelayCodesForPartnerId = ({apiLookups}, partnerId) => {
    return ko.pureComputed(() => {
        const {ediDelayCodes} = (apiLookups && apiLookups()) || {};
        const codes = ediDelayCodes[partnerId];
        return codes || [];
    })
}

