import dataModel from "data-model";
import "./my-agency-component.css";

class MyAgencyViewModel {
    constructor() {
        this.componentView = ko.observable();
        this.canChangeAgencyPasswords = ko.observable(false);
        this.numberOfAgencyFavorites = ko.observable();
        this.numberOfAgencyUsers = ko.observable();
        this.numberOfAgencyRequiredFields = ko.observable();
        this.numberOfCustomerRequiredFields = ko.observable();
        this.numberOfOrderTemplates = ko.observable();
        this.numberOfCarrierTractors = ko.observable();
        this.numberOfPendingRequests = ko.observable();
        this.numberOfRegions = ko.observable();
        this.numberOfOrderEntryArrangements = ko.observable();

        this.agencyExId = ko.observable();
        this.customField1 = ko.observable();
        this.customField2 = ko.observable();
        this.customField3 = ko.observable();
        this.agencyRateConfirmationNote = ko.observable();
        this.agencyPhone = ko.validatedObservable().extend({
            phoneLength: 10,
        });
        this.agencyFax = ko.validatedObservable().extend({
            faxLength: 10,
        });
        this.shareOrders = ko.observable();
        this.shareTractors = ko.observable();
        this.restrictLoadboardDrivers = ko.observable();
        this.isGwEmployee = ko.observable(false);
        this.canEditLoadboardOptions = ko.observable(false);
        this.rateConfirmationEditor = ko.observable(false);
        this.canChangeAgencyPasswords = ko.observable(false);
        this.rateConfirmationId = ko.observable();
        this.agencyPhoneError = ko.observable();
        this.agencyFaxError = ko.observable();

        this.modals = {
            MYFAVORITES: "MY FAVORITES",
            AGENCYUSERS: "AGENCY USERS",
            AGENCYREQUIREDFIELDS: "AGENCY REQUIRED FIELDS",
            CUSTOMERREQUIREDFIELDS: "CUSTOMER REQUIRED FIELDS",
            REGIONMANAGEMENT: "REGION MANAGEMENT",
            ORDERTEMPLATES: "ORDER TEMPLATES",
            CARRIERTRACTORS: "CARRIER TRACTORS",
            EDIPREFERENCES: "EDI PREFERENCES",
            EDITENDERSETUP: "EDI TENDER SETUP",
            PENDINGPARTNERREQUESTS: "PENDING PARTNER REQUESTS",
            ORDERSCREENCUSTOMFIELDNAMES: "ORDER SCREEN CUSTOM FIELD NAMES",
            AGENCYCONTACT: "AGENCY CONTACT",
            LOADBOARDOPTIONS: "LOADBOARD OPTIONS",
            DEFAULTORDEROPTIONS: "DEFAULT ORDER OPTIONS",
            CHANGEPASSWORDS: "CHANGE PASSWORD",
            RATECONFIRMATION: "RATE CONFIRMATION",
            ORDERENTRYARRANGEMENT: "ORDER ENTRY ARRANGEMENT",
            OrderLock: "Order Lock",
            CARRIERRELATIONSHIP: "CARRIER RELATIONSHIP",
            DELETEGRIDFILTER: "DELETE GRID FILTER"
        };

        this.initializeComponent();
    }

    initializeComponent = () => {
        this.refreshCommonFields();
        this.getAgencyCounts();
    };

    handleOpenModal = (modelName) => {
        this.componentView(null); // need to reset
        this.componentView(modelName);
        dataModel.addClientSideLog(`Selected: ${modelName}`);
    };

    getAgencyCounts = () => {
        dataModel
            .ajaxRequest("MyAgency/GetAgencyCounts", "get")
            .done((data) => {
                $(".badgeLoading").removeClass("badgeLoader");
                $(".badgeLoading").addClass("badgeNew");
                this.numberOfAgencyFavorites(data.agencyFavoritesCount);
                this.numberOfAgencyUsers(data.agencyUsersCount);
                this.numberOfAgencyRequiredFields(
                    data.agencyRequiredFieldsCount
                );
                this.numberOfCustomerRequiredFields(
                    data.customerRequiredFieldsCount
                );
                this.numberOfOrderTemplates(data.orderTemplateCount);
                this.numberOfCarrierTractors(data.carrierTractorsCount);
                this.numberOfPendingRequests(data.partnerRequestCount);
                this.numberOfRegions(data.regionCount);
                this.numberOfOrderEntryArrangements(
                    data.orderEntryArrangementCount
                );
            })
            .fail(() => {
                $(".badgeLoading").removeClass("badgeLoader");
                $(".badgeLoading").addClass("badgeNew");
            });
    };

    refreshCommonFields = () => {
        dataModel
            .ajaxRequest("MyAgency/GetAgencyCommonFields", "get")
            .done((data) => {
                this.rateConfirmationId(data.rateConfirmationNoteId);
                this.rateConfirmationEditor(data.canEditRateConfirmationNote);
                this.agencyRateConfirmationNote(
                    data.agencyRateConfirmationNote
                );
                this.customField1(data.field1);
                this.customField2(data.field2);
                this.customField3(data.field3);
                this.agencyFax(data.agencyFax);
                this.agencyPhone(data.agencyPhone);
                this.shareOrders(data.shareOrders || false);
                this.shareTractors(data.shareTractors || false);
                this.restrictLoadboardDrivers(
                    data.restrictLoadboardDrivers || false
                );
                this.isGwEmployee(data.isGwEmployee || false);
                this.canEditLoadboardOptions(
                    data.canEditLoadboardOptions || false
                );
                this.canChangeAgencyPasswords(
                    data.canChangeAgencyPasswords || false
                );
                this.agencyExId(data.agencyExId);
            });
    };
}

import template from "./my-agency-component.html";
export default { viewModel: MyAgencyViewModel, template: template };
