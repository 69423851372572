import dataModel from "data-model";

class EditAgencyUsersModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.formData = params.formData || {};

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.userName = this.formData.userName;
        this.agencyUser = ko.observable(this.formData.userName);
        this.savedDefaultRegion = ko.observable("");
        this.defaultRegions = ko.observableArray([
            { id: "x", name: "None Selected", type: null },
        ]);

        this.savedCustomerRegion = ko.observable("");
        this.customerRegions = ko.observableArray([
            { id: "x", name: "None Selected", type: "CUSTOMER" },
        ]);

        this.savedLocationRegion = ko.observable("");
        this.locationRegions = ko.observableArray([
            { id: "x", name: "None Selected", type: "LOCATION" },
        ]);

        this.savedTractorRegion = ko.observable("");
        this.tractorRegions = ko.observableArray([
            { id: "x", name: "None Selected", type: "TRACTOR" },
        ]);
        this.savedAgentRisk = ko.observable(false);
        this.savedAgentRiskSubscriber = ko.observable(false);
        this.savedTerminalUsersAlerts = ko.observable(false);
        this.savedAgentRiskEmail = ko.observable("");
        this.userAgency = ko.observable();

        this.isProcessing = ko.observable(false);

        this.saveBtnText = ko.computed(() => {
            return ko.unwrap(this.isProcessing) ? "Saving..." : "Save";
        });

        this.loadAgencyUserDetails();
    }

    handleSaveUserDefaults = () => {
        this.doHandleSaveUserDefaults();
    };

    async doHandleSaveUserDefaults() {
        const data = {
            username: this.userName,
            defaultRegion: this.savedDefaultRegion(),
            customerRegion: this.savedCustomerRegion(),
            locationRegion: this.savedLocationRegion(),
            tractorRegion: this.savedTractorRegion(),
            agentRisk: this.savedAgentRisk(),
            agentRiskSubscriber: this.savedAgentRiskSubscriber(),
            agentRiskEmail: this.savedAgentRiskEmail(),
            terminalAlerts: this.savedTerminalUsersAlerts(),
        };

        this.isProcessing(true);
        const success =
            (await this.http_sendSaveAgencyUserDeatilsAsync(data).catch((err) =>
                console.error("Error saving user details.")
            )) || false;
        this.isProcessing(false);

        if (success) this.$emitsOnAction();
    }

    async loadAgencyUserDetails() {
        const data = await this.http_sendGetAgencyUserDetailsAsync().catch(
            (err) => console.error("Failed to get agency user details.")
        );

        data.agencyRegions = data.agencyRegions || [];
        data.savedUserRegions = data.savedUserRegions || [];

        data.agencyRegions.filter((agency) => {
            if (!agency) return false;

            agency.map((region) => {
                if (region.type == null || region.type == "STATE REGION") {
                    this.defaultRegions.push(region);
                    if (data.savedUserRegions.indexOf(region.id) !== -1) {
                        this.savedDefaultRegion(region.id);
                    }
                } else if (region.type == "CUSTOMER") {
                    this.customerRegions.push(region);
                    if (data.savedUserRegions.indexOf(region.id) !== -1) {
                        this.savedCustomerRegion(region.id);
                    }
                } else if (region.type == "LOCATION") {
                    this.locationRegions.push(region);
                    if (data.savedUserRegions.indexOf(region.id) !== -1) {
                        this.savedLocationRegion(region.id);
                    }
                } else if (region.type == "TRACTOR") {
                    this.tractorRegions.push(region);
                    if (data.savedUserRegions.indexOf(region.id) !== -1) {
                        this.savedTractorRegion(region.id);
                    }
                }
            });
        });

        this.savedAgentRisk(data.agentRisk);
        this.savedAgentRiskSubscriber(data.agentRiskSubscriber);
        this.savedAgentRiskEmail(data.agentRiskEmail);
        this.savedTerminalUsersAlerts(data.userTerminalAlerts);
    }

    http_sendGetAgencyUserDetailsAsync = () => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/GetAgencyUserDetails/" + this.userName)
                .done((data) => resolve(data))
                .fail((err) => reject(err));
        });
    };

    http_sendSaveAgencyUserDeatilsAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/SaveUserDefaultRegions", "POST", payload)
                .done(() => resolve(true))
                .fail((err) => reject(err));
        });
    };
}

import template from "./edit-agency-users-component.html";

export { EditAgencyUsersModel };
export default { viewModel: EditAgencyUsersModel, template: template };
