import ko from 'knockout';
import {TrackingModel} from './order-entry-container-tracking-component/order-entry-container-tracking-component';
import template from './order-entry-container-component.html';

import { useSelector, useDispatch} from 'ko-data-store';
import { selectTrackedMovements } from '../../../../dataStore/actions/TNT/orderEntry';

class ContainerModel {
    constructor(item, mainModel) {
        this.dispatchAction = useDispatch();

        const trackedMovements = useSelector(selectTrackedMovements);

        this.requiredFields = mainModel.requiredFields;
        this.item = item || {};
        this.orderExternalId = window.location.pathname.split("/")[2];
    
        this.container = ko.observable(item.container).extend({
            required: {
                message: "The required field Container has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputContainer");
                }
            }
        });
        this.chassis = ko.observable(item.chassis).extend({
            required: {
                message: "The required field Chassis has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputChassis");
                }
            }
        });
        this.bookingNumber = ko.observable(item.bookingNumber).extend({
            required: {
                message: "The required field Booking Number has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputBookingNumber");
                }
            }
        });
        
        this.pickUpNumber = ko.observable(item.pickUpNumber).extend({
            required: {
                message: "The required field Pickup Number has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputPickupNumber");
                }
            }
        });

        this.customerOrderNumber = ko.observable(item.customerOrderNumber).extend({
            required: {
                message: "The required field Customer Order Number has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputCustomerOrderNumber");
                }
            }
        });

        this.sealNumber = ko.observable(item.sealNumber).extend({
            required: {
                message: "The required field Seal Number has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputSealNumber");
                }
            }
        });
        this.loadPort = ko.observable(item.loadPort).extend({
            required: {
                message: "The required field Load Port has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("autoLoadPort");
                }
            }
        });
        this.deliveryPort = ko.observable(item.deliveryPort).extend({
            required: {
                message: "The required field Port of Delivery has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("autoPortOfDelivery");
                }
            }
        });
        this.steamshipLine = ko.observable(item.steamshipLine).extend({
            required: {
                onlyIf: () => {
                    return this.requiredFields.isRequired("autoSteamshipLine");
                }
            }
        });
        this.tir1Number = ko.observable(item.tir1Number).extend({
            required: {
                message: "The required field TIR 1 Number has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputTIR1Number");
                }
            }
        });
        this.tir2Number = ko.observable(item.tir2Number).extend({
            required: {
                message: "The required field TIR 2 Number has not been entered.  Please enter the required information.", 
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputTIR2Number");
                }
            }
        });
    
        this.cutoffDate = ko.observable(item.cutoffDate).extend({
            required: {
                message: "The required field Cutoff Date has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("datePickerCutoffDate");
                }
            }
        });
    
        this.sailDate = ko.observable(item.sailDate).extend({
            required: {
                message: "The required field Sail Date has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("datePickerSailDate");
                }
            }
        })
    
        this.loadDate = ko.observable(item.loadDate).extend({
            required: {
                message: "The required field Load Date has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("datePickerLoadDate");
                }
            }
        });
        this.vessel = ko.observable(item.vessel).extend({
            required: {
                message: "The required field Vessel has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputVessel");
                }
            }
        });
        this.forwardLocation = ko.observable(item.forwardLocation).extend({
            required: {
                message: "The required field Forwarder has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("autoForwarder");
                }
            }
        });
        this.drayPickUpDriver = ko.observable(item.drayPickUpDriver).extend({
            required: {
                message: "The required field Pickup Driver has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("autoPickupDriver");
                }
            }
        });
        this.drayPickupDate = ko.observable(item.drayPickupDate).extend({
            required: {
                message: "The required field Pickup Date has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("datePickerPickupDate");
                }
            }
        });
        this.drayPickupPay = ko.observable(item.drayPickupPay).extend({
            message: "The required field Pickup Pay has not been entered.  Please enter the required information.",
            onlyIf: () => {
                return this.requiredFields.isRequired("inputPickupPay")
            }
        });
        this.containerReleaseDate = ko.observable(item.containerReleaseDate);
        this.drayDeliveryDriver = ko.observable(item.drayDeliveryDriver).extend({
            required: {
                message: "The required field Delivery Driver has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("autoDeliveryDriver");
                }
            }
        });
        this.drayDeliveryDate = ko.observable(item.drayDeliveryDate)
            .extend({
                validation: {
                    message: "Delivery Date must be after Pick Up Date ",
                    validator: () =>{
                        if (ko.unwrap(this.drayDeliveryDate) != null && ko.unwrap(this.drayPickupDate) != null) {
                            if (ko.unwrap(this.drayPickupDate) > ko.unwrap(this.drayDeliveryDate)) {
                                return false;
                            }
                        }
                        return true;
                    }
                },
                required: {
                    message: "The required field Delivery Date has not been entered.  Please enter the required information.",
                    onlyIf: () => {
                        return this.requiredFields.isRequired("datePickerDeliveryDate");
                    }
                }
            });
        this.drayDeliveryPay = ko.observable(item.drayDeliveryPay).extend({ max: 1000000 });
    
        this.isDrayPuTransfered = item.isDrayPuTransfered;
        this.isDrayDelTransfered = item.isDrayDelTransfered;
    
        this.trackingLoadedEmptyOptions = [
            {
                label: 'Loaded',
                value: true
            },
            {
                label: 'Empty',
                value: false
            }
        ];
    
        this.tracking = ko.observable();
    }

    trackingClick = (vm, e) => {
        let context = ko.contextFor(e.target);
        let orderId = context.$parents[1].orderId();
        var newModal = new TrackingModel(this.item, orderId);
        this.tracking(newModal);
        $('input[type="text"][data-bind*="geInput"]').parent('div').addClass('mar-left-neg-3');
        newModal.startTracking();
    }

    validationObject = () => {
        return {
            container : this.container,
            chassis : this.chassis,
            bookingNumber : this.bookingNumber,
            pickUpNumber : this.pickUpNumber,
            customerOrderNumber : this.customerOrderNumber,
            sealNumber : this.sealNumber,
            loadPort : this.loadPort,
            deliveryPort : this.deliveryPort,
            steamshipLine : this.steamshipLine,
            tir1Number : this.tir1Number,
            tir2Number : this.tir2Number,
            cutoffDate : this.cutoffDate,
            sailDate : this.sailDate,
            loadDate : this.loadDate,
            vessel : this.vessel,
            forwardLocation : this.forwardLocation,
            drayPickUpDriver : this.drayPickUpDriver,
            drayPickupDate : this.drayPickupDate,
            drayPickupPay : this.drayPickupPay,
            drayDeliveryDriver : this.drayDeliveryDriver,
            drayDeliveryDate : this.drayDeliveryDate,
            drayDeliveryPay : this.drayDeliveryPay,
        }
    }
}

var OrderEntryContainerComponent = {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            //Don't do anything.  Use the object created in the main OrderEntry page.
        }
    }, template: template
}

export { ContainerModel }

export default OrderEntryContainerComponent