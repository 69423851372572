import dataModel from 'data-model';
import userProfile from 'user-profile';
import { isDeviceMobileOrTablet, cleanString, datetimeUTC, Base64, createLocalCache, pipeFn, getHoursMinsFromMins } from 'global-functions';

export const isMobileOrTablet = () => isDeviceMobileOrTablet();

const isZipOnly = (zip) => {
    try {
        return isNaN(zip) === false
    }
    catch(err) {
        return false;
    }
}

export const SearchForm = function({
    searchType = "L",
    boards = [],
    trailerType = undefined,
    trailerTypeDescription = undefined,
    trailerPostingTypeId = undefined, // currently trailerpostingtype is only used by driver role
    equipmentClass = [],
    originType = "location",
    origin = undefined,
    originRadius = 5,
    originRegions = [],
    originStateProvince = [],
    destinationType = "location",
    destination = undefined,
    destinationRadius = 5,
    destinationRegions = [],
    destinationStateProvince = [],
    pickupFrom = undefined,
    pickupTo = undefined,
    saveSearchName = undefined,
    includeTractorsAvailableNow = true,
    weight = undefined,
    length = undefined
} = {}) 
{
    this.searchType = ko.observable(searchType);
    this.boards = ko.observableArray(boards).extend({notify: 'always'});
    this.saveSearchName = ko.observable(saveSearchName);
    this.trailerType = ko.observable(trailerType);
    this.trailerTypeDescription = ko.observable(trailerTypeDescription);
    this.trailerPostingTypeId = ko.observable(trailerPostingTypeId);
    this.equipmentClass = ko.observableArray(equipmentClass);
    this.originType = ko.observable(originType ? originType.toUpperCase() :  null);
    this.origin = ko.observable(origin);
    this.originRadius = ko.observable(originRadius);
    this.originRegions = ko.observableArray(originRegions);
    this.originStateProvince = ko.observableArray(originStateProvince);
    this.destinationType = ko.observable(destinationType ? destinationType.toUpperCase() : null);
    this.destination = ko.observable(destination);
    this.destinationRadius = ko.observable(destinationRadius);
    this.destinationRegions = ko.observableArray(destinationRegions);
    this.destinationStateProvince = ko.observableArray(destinationStateProvince);
    this.pickupFrom = ko.observable(pickupFrom);
    this.pickupTo = ko.observable(pickupTo);
    this.includeTractorsAvailableNow = ko.observable(includeTractorsAvailableNow);
    this.weight = ko.observable(weight);
    this.length = ko.observable(length);
    
    this.validate = () => {
        this.validationProp(undefined);

        const isOriginSet = () => {
            return ((this.originType() == "LOCATION" && this.origin() != null) 
            || (this.originType() == "REGION" && this.originRegions().length > 0)
            || (this.originType() == "STATEPROVINCE" && this.originStateProvince().length > 0))
        }

        const isDestinationSet = () => {
            return ((this.destinationType() == "LOCATION" && this.destination() != null) 
            || (this.destinationType() == "REGION" && this.destinationRegions().length > 0)
            || (this.destinationType() == "STATEPROVINCE" && this.destinationStateProvince().length > 0))
        }

        if(isOriginSet() == false && isDestinationSet() == false) {
            this.validationProp('originDestination');
            return "Please enter either the Origin or Destination - Location, State(s), or Region(s)";
        }

        if(this.boards().indexOf('DAT') > -1 && this.equipmentClass().length == 0) {
            this.validationProp('datEquipmentClass');
            return "An equipment class is required when using DAT.";
        }

        if(!this.pickupFrom()) {
            this.validationProp('pickupDate');
            return "Please enter the 'pickup (from)' date.";
        }

        if(this.originType() === "LOCATION" && this.origin()) {
            if(isZipOnly(this.origin()) && this.origin().length < 5) {
                return `Please enter a valid zip code for origin location.`;
            }
        }

        if(this.destinationType() === "LOCATION" && this.destination()) {
            if(isZipOnly(this.destination()) && this.destination().length < 5) {
                return `Please enter a valid zip code for destination location.`;
            }
        }
    }

    this.validationProp = ko.observable();
}

export const saveToLocalStorageRecentSearchCache = (searchForms = []) => {
    try {
        const json = searchForms.length > 0 ? Base64.encode(JSON.stringify(searchForms)) : JSON.stringify(searchForms);
        window.localStorage.setItem("externalBoardRecentSearchesCache", json);
        return true;
    }
    catch(e) {
        return false;
    }
}

export const getRecentSearchCacheFromLocalStorage = (localStorageString = "") => {
    try {
        return JSON.parse(Base64.decode(localStorageString)) || [];
    }
    catch(e) {
        return [];
    }
}

export const pipe = pipeFn;

export const cleanupRecentSearchCache = (currentSearches = []) => currentSearches.filter(x => x.cacheExpireDate > new Date().getTime() && x.userId == userProfile.userId);

const formatTrailerDescription = (trailerDescription) => {
    
    if(!trailerDescription || !trailerDescription.length) return "Any Equipment";

    const datInLabelIndex = trailerDescription.indexOf('DAT');
    if(datInLabelIndex > -1) {
        return cleanString(["DAT", "(", ")"], trailerDescription);
    }

    return trailerDescription;
}

// These are items displayed on the tabs
export const getSearchTabNames = ({searchType = "", trailerTypeDescription = "", origin = [], destination = []}) => {
    const strings = {
        searchType: searchType == "L" ? "Load" : searchType == "T" ? "Tractors" : "",
        origin: origin.length > 0 ? origin.join(",") : origin[0] || "Anywhere",
        destination: destination.length > 0 ? destination.join(",") : destination[0] || "Anywhere",
        trailerTypeDescription: formatTrailerDescription(trailerTypeDescription),
        searchName: ""
    }

    strings.searchName = `${strings.searchType} - ${strings.trailerTypeDescription ? '[' + strings.trailerTypeDescription + ']' : ""} ${strings.origin} to ${strings.destination}`;

    return strings;
}

export const getOriginDestinationFromSearch = (orgDestInfo = {}) => {
    
    const originType = orgDestInfo.originType && orgDestInfo.originType.toLowerCase();
    const destinationType = orgDestInfo.destinationType && orgDestInfo.destinationType.toLowerCase();

    return {
        searchType: orgDestInfo.searchType,
        origin: originType == "location" ? [orgDestInfo.origin || "Anywhere"] : 
                originType == "stateprovince" ? orgDestInfo.originStateProvince : orgDestInfo.originRegions,
        destination: destinationType == "location" ? [orgDestInfo.destination || "Anywhere"] : 
                destinationType == "stateprovince" ? orgDestInfo.destinationStateProvince : orgDestInfo.destinationRegions,
        trailerTypeDescription: formatTrailerDescription(orgDestInfo.trailerTypeDescription),
    }
}

export const mapToSearchListing = (searches) => {
    return searches.map(x => {
        const loc = getOriginDestinationFromSearch(x);
 
        return {...x, locationStrings: getSearchTabNames(loc), selectedItemTabInfo: ko.observable() }       
    })
}

export const sendSaveActiveSearch = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("ExternalBoard/ActiveSearch", "POST", payload).done((result) => resolve(result))
        .fail((error) => reject(error.responseJSON && error.responseJSON.message || "An error occurred during request."))
    })
}

export const CheckIfUpdate = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Externalboard/CheckIfUpdate", "GET", payload).done(result => resolve(result))
        .fail(error => reject(error.responseJSON && error.responseJSON.message || "An error occurred during request."));
    })
}

export const UpdateNamedSearch = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Externalboard/UpdateNamedSearch", "POST", payload).done(result => resolve(result))
        .fail(error => reject(error.responseJSON && error.responseJSON.message || "An error occurred during request."));
    })
}

export const saveSearch = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("ExternalBoard/saveSearch", "POST", payload).done((result) => resolve(result))
        .fail((error) => reject(error.responseJSON && error.responseJSON.message || "An error occurred during request."))
    })
}

export const UpdateActiveSearch = (id, payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest(`Externalboard/UpdateActiveSearch/${id}`, "PUT", payload).done(result => resolve(result))
        .fail(error => reject(error.responseJSON && error.responseJSON.message || "An error occurred during request."));
    })
}


export const sendSaveViewRecord = ({loadId = undefined, board = undefined, callNotes = undefined}) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Externalboard/SaveViewRecord", "POST", {loadId, board, callNotes}).done(() => resolve(true))
        .fail(() => reject(false));
    })
}


export const saveActiveSearch = async ({
    searchType,
    boards,
    trailerType,
    trailerPostingTypeId,
    equipmentClass,
    originType,
    origin,
    originRadius,
    originRegions,
    originStateProvince,
    destinationType,
    destination,
    destinationRadius,
    destinationRegions,
    destinationStateProvince,
    pickupFrom,
    pickupTo,
    saveSearchName,
    includeHazmat = undefined,
    includeTeams = undefined,
    length,
    weight
}) => await sendSaveActiveSearch({
        searchType,
        boards,
        trailerType,
        trailerPostingTypeId,
        equipmentClass,
        originType,
        origin,
        originRadius,
        originRegions,
        originStateProvince,
        destinationType,
        destination,
        destinationRadius,
        destinationRegions,
        destinationStateProvince,
        pickupFrom,
        pickupTo,
        searchName: saveSearchName,
        includeHazmat,
        includeTeams,
        length,
        weight
}).catch(() => null);


export const LoadTractorDetails = function({
    searchType,
    age,
    boardName,
    companyName,
    dateAvailable,
    destination,
    destinationRadius,
    feet,
    loadType,
    origin,
    originRadius,
    phone,
    trailerType,
    weight,
    miles,
    referenceId,
    comments,
    count,
    rate,
    carrierMcNumber,
    brokerMcNumber,
    carrierId,
    dotNumber,
    id,
    shipment,
    previouslyViewed = false,
    customerStatus,
    customerName,
    creditBalance,
    creditLimit,
    netUnposted,
    liabilityAmount,
    liabilityExpirationDate,
    cargoInsuranceRenewalDate,
    cargoInsuranceAmount,
    driver1,
    driver1ExId,
    tractorExId,
    tractorStatus,
    driverPhone,
    tractorAvailableNow,
    hazmat,
    teams
}) {

    let today = datetimeUTC(new Date());
    today = today.set('hour', 0);
    today = today.set('minute', 0);

    this.id = ko.observable(id);
    this.searchType = ko.observable(searchType);
    this.age = ko.observable(age);
    this.boardName = ko.observable(boardName);
    this.companyName = ko.observable(companyName);
    this.dateAvailable = ko.observable(dateAvailable ? datetimeUTC(dateAvailable) : (tractorStatus == 'Available' ? today : null));
    this.destination = ko.observable(destination);
    this.destinationRadius = ko.observable(destinationRadius);
    this.length = ko.observable(feet);
    this.loadType = ko.observable(loadType);
    this.origin = ko.observable(origin);
    this.originRadius = ko.observable(originRadius);
    this.phone = ko.observable(phone);
    this.trailerType = ko.observable(trailerType);
    this.weight = ko.observable(weight);
    this.miles = ko.observable(miles);
    this.referenceId = ko.observable(referenceId);
    this.comments = ko.observable(comments);
    this.count = ko.observable(count);
    this.rate = ko.observable(rate);
    this.carrierMcNumber = ko.observable(carrierMcNumber);
    this.brokerMcNumber = ko.observable(brokerMcNumber);
    this.carrierId = ko.observable(carrierId);
    this.dotNumber = ko.observable(dotNumber);
    this.shipment = ko.observable(shipment);
    this.previouslyViewed = ko.observable(previouslyViewed);
    this.customerStatus = ko.observable(customerStatus);
    this.customerName = ko.observable(customerName);
    this.creditBalance = ko.observable(creditBalance);
    this.creditLimit = ko.observable(creditLimit);
    this.netUnposted = ko.observable(netUnposted);
    this.balancePlusUnposted = ko.observable(creditBalance + netUnposted)
    this.liabilityAmount = ko.observable(liabilityAmount);
    this.liabilityExpirationDate = ko.observable(datetimeUTC(liabilityExpirationDate));
    this.cargoInsRenewalDate = ko.observable(datetimeUTC(cargoInsuranceRenewalDate));
    this.cargoInsuranceAmount = ko.observable(cargoInsuranceAmount);
    this.tractorAvailableNow = ko.observable(tractorAvailableNow);
    this.hazmat = ko.observable(hazmat);
    this.teams = ko.observable(teams);
    
    this.rpm = ko.pureComputed(() => {
        if(this.rate() && this.miles() > 0) {
            return new Number(Math.round((this.rate() / this.miles()) * 100) / 100).toFixed(2);
        }
    });

    this.formattedPhone = ko.pureComputed(() => {
        if(this.phone()) {
            const phone = cleanString(["(", ")", " ", "-"], this.phone(), "");
            const areaCode = phone.slice(0,3);
            const prefix = phone.slice(3,6);
            const number = phone.slice(6);

            return `(${areaCode}) ${prefix}-${number}`;
        }
    });

    this.driverPhone = ko.observable(driverPhone);
    this.driverFormattedPhone = ko.pureComputed(() => {
        if(this.driverPhone()) {
            const phone = cleanString(["(", ")", " ", "-"], this.driverPhone(), "");
            const areaCode = phone.slice(0,3);
            const prefix = phone.slice(3,6);
            const number = phone.slice(6);

            return `(${areaCode}) ${prefix}-${number}`;
        }
    })

    this.driver1 = ko.observable(driver1);
    this.driver1ExId = ko.observable(driver1ExId);
    this.tractorExId = ko.observable(tractorExId);
    this.tractorStatus = ko.observable(tractorStatus);
}

const boardDetailsCache = createLocalCache();
export { boardDetailsCache };