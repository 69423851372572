import dataModel from "data-model";
import userProfile from "user-profile";
import {
    replaceBrTags,
    setBtnDisabledState,
    setBtnEnabledState,
    isEmpty,
} from "global-functions";

class RateConfirmationViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};
        this.isProcessing = ko.observable(false);

        this.rateGrid = $("#jqxRateConGrid");
        this.isEdit = ko.observable(false);
        this.displayForm = ko.observable(false);
        this.editText = ko.observable().extend({ required: true });
        this.applyToCustomer = ko.observable(false);
        this.modelError = ko.observable();
        this.rateConStatusMessage = ko.observable();
        this.customerSelectNote = ko.observable("");
        this.rateConNotes = ko.observableArray();
        this.noteId = ko.observable();
        this.displayConfirmDelete = ko.observable(false);
        this.customerSelected = ko.observable();
        this.customerSelectInput = ko.observable().extend({
            required: {
                onlyIf: () => {
                    return this.applyToCustomer();
                },
                message: "Please select a customer from the list.",
            },
        });
        this.customerEditIdTemp = ko.observable(); // Needed to check against customerSelection change when user is editing

        // this.isCustomerConflict = ko.computed(() => {
        //     return (
        //         this.customerEditIdTemp() &&
        //         this.customerSelected() &&
        //         this.customerEditIdTemp() != this.customerSelected().id
        //     );
        // });

        // Watch this and check if the user selected a customer that they already have a note for.
        this.customerSelected.subscribe((val) => {
            const customer = val || { id: null };
            this.customerSelectNote("");

            this.checkCustomerAgencyAlreadyHasNote(customer.id);
        });

        this.applyToCustomer.subscribe((val) => {
            // If agency is selected and we have a note display agency note
            this.editText(
                (this.isEdit() == false &&
                    val == false &&
                    this.agencyRateConNote.note) ||
                    ""
            );

            if (this.isEdit() == false) this.handleResetCustomerFields();
        });

        this.initializeComponent();
    }

    initializeComponent = () => {
        this.loadGrid();
    };

    handleLoadCustomerNote = () => {
        const crn = this.selectedCustomerRateConNote;

        this.editText(crn.note || "");
        this.noteId(crn.noteId);
    };

    handleResetCustomerFields = () => {
        this.noteId(null);
        this.customerSelectInput(undefined);
        this.customerSelected(undefined);
    };

    handleSaveNote = (x, event) => {
        this.doHandleSaveNote(x, event);
    };

    async doHandleSaveNote(x, event) {
        const vm = this;
        if (vm.validateSubmission() == false) return false;

        const data = {
            NoteId: vm.noteId(),
            Note: replaceBrTags(vm.editText()),
        };

        data.CustomerId =
            vm.customerSelected() && vm.applyToCustomer()
                ? vm.customerSelectInput() //id
                : null;

        vm.isProcessing(true);

        setBtnDisabledState(event, "Saving...");

        await vm
            .http_sendSaveNote(data)
            .catch((errMsg) => vm.modelError(errMsg));

        vm.isProcessing(false);
        setBtnEnabledState(event, "Save");

        if (!vm.modelError())
            vm.doWhenSaveSuccess("Rate Confirmation Note Saved Successfully.");
    }

    doWhenSaveSuccess = (msg) => {
        this.refreshGrid();

        this.rateConStatusMessage(msg);

        this.displayForm(false);

        setTimeout(() => {
            this.rateConStatusMessage("");
        }, 5000);
    };

    validateSubmission = () => {
        this.modelError("");
        const errors = ko.validation.group(this),
            isValid = errors().length == 0;

        if (isValid == false) errors.showAllMessages();

        return isValid;
    };

    handleOpenForm = (note, isEdit) => {
        note = note || this.agencyRateConNote || {};
        isEdit = isEdit || false;

        this.modelError("");
        this.customerSelectNote("");

        this.noteId(note.noteId);
        this.isEdit(isEdit);

        this.applyToCustomer(note.customerId > 0);

        // ** Ordering is important for these next items in the call chain *****
        this.customerSelectInput(note.customerExId); // 1
        this.customerEditIdTemp(note.customerId); // 2
        this.customerSelected(note.customerExId); // 3
        // ============================ End ===================================

        this.editText(note.note);
        this.displayForm(true);

        // Add a log if they selected new note
        if (this.isEdit() == false)
            dataModel.addClientSideLog(
                "Selected: Add New Rate Confirmation Note"
            );
    };

    handleDeleteNote = () => {
        this.doHandleDeleteNote();
    };

    async doHandleDeleteNote() {
        const vm = this;
        vm.displayConfirmDelete(false);

        const success =
            (await vm
                .http_sendDeleteNote(vm.noteId())
                .catch((err) => console.error(err))) || false;

        if (success)
            vm.doWhenSaveSuccess(
                "Rate Confirmation Note Deleted Successfully."
            );
    }

    placeHolderFilterWidget = (column, columnElement, widget) => {
        widget.jqxInput({
            placeHolder: "Type Here...",
        });
    };

    get gridSource() {
        return {
            url: "MyAgency/GetRateConfirmationNotes",
            datatype: "json",
            datafields: [
                { name: "noteId", type: "number" },
                { name: "customerId", type: "number" },
                { name: "customerExId", type: "string" },
                { name: "note", type: "string" },
            ],
            formatdata: (data) => {
                data = { agencyId: userProfile.currentAgencyId() };

                return data;
            },
            loadcomplete: (data) => {
                console.log(data);
            },
        };
    }

    refreshGrid = () => {
        this.rateGrid.jqxGrid("updatebounddata", "data");
    };

    get agencyRateConNote() {
        const vm = this,
            notes = vm.rateGrid.jqxGrid("getboundrows") || [];

        return notes.find((note) => note.customerId == null) || {};
    }

    get selectedCustomerRateConNote() {
        const vm = this,
            notes = vm.rateGrid.jqxGrid("getboundrows") || [];

        return (
            (vm.customerSelected() &&
                notes.find(
                    (note) => note.customerId == vm.customerSelected().id
                )) ||
            {}
        );
    }

    loadGrid = () => {
        const vm = this;
        if (this.rateGrid.length == 0) this.rateGrid = $("#jqxRateConGrid");

        var dataAdapter = dataModel.getDataAdapter(this.gridSource);

        const gridColumns = [
            {
                text: "",
                width: 100,
                columntype: "button",
                cellsrenderer() {
                    return "Edit";
                },
                buttonclick(row) {
                    const data = vm.rateGrid.jqxGrid("getrowdata", row);
                    vm.handleOpenForm(data, true);
                },
                filterable: false,
                sortable: false,
                pinned: true,
            },
            { datafield: "noteId", hidden: true },
            { datafield: "customerId", hidden: true },
            {
                text: "Customer",
                datafield: "customerExId",
                createfilterwidget: this.placeHolderFilterWidget,
                width: 200,
                cellsrenderer: (
                    row,
                    columnfield,
                    value,
                    defaulthtml,
                    columnproperties
                ) => {
                    value = isEmpty(value)
                        ? `AGENCY "${userProfile.agencyExternalId}" NOTE`
                        : value;

                    return `<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">
                        ${value}
                    </div>`;
                },
            },
            {
                text: "Note",
                datafield: "note",
                createfilterwidget: this.placeHolderFilterWidget,
                width: 550,
            },
        ];

        this.rateGrid.jqxGrid({
            theme: "GWTMDark",
            width: "100%",
            autoheight: false,
            source: dataAdapter,
            columns: gridColumns,
            showheader: true,
            showtoolbar: true,
            toolbarheight: "50px",
            pageable: true,
            sortable: true,
            filterable: true,
            altrows: true,
            showfilterrow: true,
            columnsmenu: false,
            enablebrowserselection: true,
            pagesize: 20,
            virtualmode: false,
            rendertoolbar: (toolbar) => {
                const rateToolbar = $("#rateConNoteToolbar");
                toolbar.append(rateToolbar);
            },
        });
    };

    // Loops through the notes that we already have and checks for a match and triggers displaying
    // a warning to the user that if they save the note it will write over the one they
    // currently have.
    checkCustomerAgencyAlreadyHasNote = (customerId) => {
        const notes = this.rateGrid.jqxGrid("getboundrows") || [],
            exists = notes.some(
                (note) =>
                    customerId &&
                    note.customerId == customerId &&
                    customerId != this.customerEditIdTemp()
            );

        if (exists)
            this.customerSelectNote(
                "A note already exists. Changes here will save over the current Rate Confirmation Note for the selection."
            );
    };

    http_sendSaveNote = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/SaveRateConfirmationNote",
                    "POST",
                    payload
                )
                .done((response) => resolve(response))
                .fail((err) => {
                    console.error(err);
                    reject(
                        (err.responseJSON && err.responseJSON.message) ||
                            "Error occurred while saving."
                    );
                });
        });
    };

    http_sendDeleteNote = (noteId) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/DeleteRateConfirmationNote/" + noteId,
                    "DELETE"
                )
                .done((response) => resolve(true))
                .fail((err) => reject(err));
        });
    };
}

import template from "./rate-confirmation-component.html";
export default { viewModel: RateConfirmationViewModel, template: template };
