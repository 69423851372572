import { Observable } from 'knockout';
import ko from 'knockout';
import template from './inspection-locations-page.html';

class InspectionLocationsPage {

    locationId: Observable<number | undefined> = ko.observable();
    showEditPage: Observable<boolean> = ko.observable(false);

    constructor(params) {
        this.locationId(params[0]?.id)
        this.showEditPage(params[0]?.id != undefined || false)
    }
}


export default { viewModel: InspectionLocationsPage, template: template }