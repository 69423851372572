const appId = "1279d125-b4da-4bb5-b774-84b035813f04";
const resourceAPI = "https://analysis.windows.net/powerbi/api";
const tenant = "9d4f2539-4612-46fd-ab90-b196c5661074"; // GWTM
const tokenURL = `Report/ADToken`;
const workspaceId = `a91d37bf-4c1c-4232-b8df-18e2dee929ef`; // Aka 'Group' or 'GroupId'
const apiURL = `https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/`;

export default {
    appId,
    tenant,
    tokenURL,
    workspaceId,
    apiURL,
    resourceAPI
}