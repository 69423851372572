import ko from 'knockout';
import dataModel from 'data-model';
import template from './expire-loads-component.html';
import {showmessage} from 'show-dialog-methods';
import * as $ from 'jquery';
import { useDispatch } from 'ko-data-store';
import { isLoading } from 'dataStore-actions/appUI';

class ExpireLoadsViewModel {
    constructor(mainModel) {
        var self = this;
        const onExpireCallBack = mainModel.onExpireCallBack || function() {};
        mainModel = mainModel.data;
        
        self.expireLoadsConfirmationModal = ko.observable();
        self.cancelExpire = function () {
            $('#expireLoadsPopupWindow').modal('hide');
        };
        self.expireLoadsConfirmationModal = ko.observable();
        self.cancelConfirmation = function (param) {
            var expireLoadsModel = new ExpireLoadsConfirmationViewModel(param, mainModel, onExpireCallBack);
            self.expireLoadsConfirmationModal(expireLoadsModel);
            $('#expireLoadsPopupWindow').modal('hide');
            $('#expireLoadsConfirmationWindow').modal('show');
        };
        $('#expireLoadsPopupWindow').modal('show');
    }
}

class ExpireLoadsConfirmationViewModel {
    constructor(param, mainModel, onExpireCallBack) {
        var self = this;
        const dispatch = useDispatch();
        onExpireCallBack = onExpireCallBack || function() {};

        self.expireAgencyorUser = ko.observable(param);

        //used for visibility binding in view
        self.isAgencyOrder = ko.observable(false);
        if (param == "ExpireAgencyLoads") {
            self.isAgencyOrder(true)
        }
        
        self.expireLoads = function () {
            dispatch(isLoading(true));
            $('#expireLoadsConfirmationWindow').modal('hide');
            var data = {
                expire: self.expireAgencyorUser(),
            };
            dataModel.ajaxRequest("OrderPlanning/ExpireLoads", "GET", data)
            .done(function (record, status, xhr) {
                dispatch(isLoading(false));
                showmessage("Your loads have been expired.");
                $("#confirmationModal").modal('toggle');

                onExpireCallBack();
            })
            .fail(function (error, msg, d) {
                showmessage("Your load expiration has failed. Please go back and try again.");
                $("#confirmationModal").modal('toggle');
                if (error.responseJSON != null) {
                    showmessage(error.responseJSON.message);
                }
                dispatch(isLoading(false));
            });
        };
    }
}

export default { viewModel: ExpireLoadsViewModel, template: template }
