import dataModel from "data-model";
import userprofile from "user-profile";

import { noop, datetimeUTC } from "global-functions";
import TNTService, { GetTransitStatus } from "../../../services/TNTService";
import { mapToCityStateZipObject, cleanString } from "global-functions";
import tntStopModel from "../../models/tntStopModel";
import tntCarrierTrackingModel from "../../models/tntCarrierTrackingModel";
import LoadCommentModel from "../../models/loadCommentModel";
import { getValidationErrors } from "../../models/tntCarrierTrackingModel";
import { getStopValidationErrors } from "../../models/tntStopModel";
import { isLoading } from "../appUI";
import { showconfirm } from "show-dialog-methods";
export const models = {
  tntStopModel,
  tntCarrierTrackingModel,
  movementStatus: [
    "IndexPlaceholder",
    "Available",
    "Covered",
    "Delivered",
    "In Progress",
    "Voided",
  ], // the indexes should map to movementStatus.Id
  trackingStatus: {
    Pending: 1,
    Activated: 2,
    Cancelled: 3,
  },
};

const preloadUserRoles = () => {
  let roles = [];

  const load = async () => {
    roles = await userprofile.roles();
  };

  load();

  return () => roles;
};

let getUserRoles = () => [];
userprofile.loggedIn.subscribe((yes) => {
  if (yes) {
    getUserRoles = preloadUserRoles();
  }
});

// Action types
const SET_TRACKING_STOPS = "tnt/orderEntry/setTrackingStops";
const REFRESH_INITIAL_STATE = "tnt/orderEntry/refreshInitialState";
const UPDATE_TRACKING_STOP = "tnt/orderEntry/updateTrackingStop";
const UPDATE_CARRIER_TRACKINGS = "tnt/orderEntry/updateCarrierTrackings";
const UPDATE_CARRIER_TRACKING_FOR_MOVEMENT =
  "tnt/orderEntry/updateCarrierTrackingForMovement";
const UPDATE_MOVEMENT_STATUS = "tnt/orderEntry/updateMovementStatus";
const UPDATE_LOAD_COMMENTS = "tnt/orderEntry/updateLoadComments";
const ADD_TRACKING_MOVEMENT = "tnt/orderEntry/addTrackingMovement";
const SET_MOVEMENT_ERRORS = "tnt/orderEntry/setMovementErrors";
const SET_MOVEMENT_INFO_MSG = "tnt/orderEntry/setMovementInfoMsg";
const UPDATE_MOVEMENT_IS_LOADING = "tnt/orderEntry/updateMovementIsLoading";
const UPDATE_STOP_COMMENT = "tnt/orderEntry/updateStoppingComment";
const SET_DEFAULT_PROVIDER_ID = "tnt/orderEntry/setDefaultProviderId";
const SET_MOVEMENT_GEORADIUS = "tnt/orderEntry/setMovementGeoRadius";
const SET_IS_TRACKING_ON = "tnt/orderEntry/setIsTrackingOn";
const SET_STOP_COMMENTS = "tnt/orderEntry/setStopComments";
const SET_DEFAULT_START_TRACKING = "tnt/orderEntry/setDefaultStartTracking";
const SET_DO_START_TRACKING_SAVE = "tnt/orderEntry/setDoStartTrackingSave";
const SET_WAIT_START_TRACKING_SAVE = "tnt/orderEntry/setWaitStartTrackingSave";
const SET_USER_CAN_MANUALLY_TRACK = "tnt/orderEntry/setUserCanManuallyTrack";
const SET_USER_CAN_EDIT_VENDOR = "tnt/orderEntry/setUserCanEditVendor";
const SET_LAST_LOCATION_FOR_MOVEMENT =
  "tnt/orderEntry/setLastLocationForMovement";
const SET_ORDER_DELIVERY_DATE = "tnt/orderEntry/setOrderDeliveryDate";
const ORDER_ID_UPDATED = "tnt/orderEntry/orderIdUpdated";
const CUSTOMER_MAPPED_VENDOR_ID_UPDATED =
  "tnt/orderEntry/customerMappedVendorIdUpdated";
const SET_TRACKING_NEEDS_REFRESHED = "tnt/orderEntry/setTrackingNeedsRefreshed";

//========================
// ACTION CREATORS
//
//---------------------

const isDebugMode = () => {
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // const debugTracking = urlParams.get("debugTracking");

  // if (debugTracking && debugTracking == "true") {
  //   return true;
  // }

  return false;
};

let _debugModeOn = false;
const debugModeOn = _debugModeOn || isDebugMode(); // WHEN TRUE PROVIDES A STREAM OF DATA CHANGES FOR TNT IN THE CONSOLE.
let debugLevel = "INFO"; // ALL -> info + errors, INFO -> info only, ERRORS -> errors only, VALIDATION -> for carrier record validation
// TURNS on Console logs for the the dispatched events below to see a live 'stream' of updates/actions.
export const toggleDebugMode = (on = false, level = "ALL") => {
  _debugModeOn = on || false;
  debugLevel = level || "ALL";

  return { type: "" };
};

export const getAgencyContactDetails = async () => {
  try {
    const response = await dataModel.ajaxRequest(
      "Agency/GetAgencyContactDetails",
      "GET"
    );
    return response;
  } catch (err) {
    return null;
  }
};

export function cleanContactEmail(email = "") {
  let cleanEmail = email || "";
  if (cleanEmail.indexOf(";") !== -1) {
    cleanEmail = cleanEmail.split(";")[0];
  } else if (cleanEmail.indexOf(",") !== -1) {
    cleanEmail = cleanEmail.split(",")[0];
  } else if (email.indexOf("&") !== -1) {
    cleanEmail = cleanEmail.split("&")[0];
  }

  return cleanEmail ? cleanEmail.trim() : "";
}

const cleanLMEOperationUserContact = async () => {
  const { email } = await getAgencyContactDetails();
  const authName = userprofile.userName;
  const agentEmail =
    userprofile.userContactInfo.email || cleanContactEmail(email);

  return { authName, agentEmail };
};

// FETCH TRACKING INFRO FOR MOVEMENT / CARRIER RECORD ID AND LOAD INTO THE STORE
export const loadTrackingAsync =
  (moveSequence, carrierTrackingRecordId) => async (dispatch, state) => {
    dispatch(updateMovementIsLoading(moveSequence, true));
    const { tntOrderEntry } = state;
    const mv =
      (tntOrderEntry["trackedMovements"] &&
        tntOrderEntry["trackedMovements"][moveSequence]) ||
      {};

    const ctRecord = (mv && mv.carrierTracking) || {};
    //carrierTrackingRecordId = carrierTrackingRecordId || (ctRecord && ctRecord.id);

    try {
      dispatch({
        type: SET_MOVEMENT_ERRORS,
        /* @ts-ignore */
        payload: [],
        meta: { moveSequence: ko.toJS(moveSequence) },
      });

      if (moveSequence > 0 && mv.movementId > 0) {
        const { carrierTrackingRecord, loadComments, stops, lastLocation } =
          (await TNTService.fetchCarrierTrackingByMoveIdAsync(mv.movementId)) ||
          {};

        if (carrierTrackingRecord) {
          if (
            carrierTrackingRecord.authName &&
            carrierTrackingRecord.authName.trim() === "lmeadm"
          ) {
            const overrides = await cleanLMEOperationUserContact();
            carrierTrackingRecord.agentEmail =
              ctRecord.agentEmail || overrides.agentEmail;
            carrierTrackingRecord.authName =
              ctRecord.authName || overrides.authName;
          }

          dispatch(
            updateCarrierTrackingForMovement(moveSequence, {
              ...ctRecord,
              ...carrierTrackingRecord,
              startTracking:
                carrierTrackingRecord.startTracking ||
                datetimeUTC(tntOrderEntry.defaultStartTracking).add(-1, "hour"),
              startTrackingMins: carrierTrackingRecord.startTrackingMins || 60,
              carrierMC: ctRecord.carrierMC || carrierTrackingRecord.carrierMC,
              driver1Phone:
                ctRecord.driver1Phone || carrierTrackingRecord.driver1Phone,
              carrierContactEmail:
                ctRecord.carrierContactEmail ||
                carrierTrackingRecord.carrierContactEmail,
              carrierPhone:
                ctRecord.carrierPhone || carrierTrackingRecord.carrierPhone,
              teamTracking:
                ctRecord.teamTracking || carrierTrackingRecord.teamTracking,
              carrierTractor:
                ctRecord.carrierTractor || carrierTrackingRecord.carrierTractor,
              carrierTrailer:
                ctRecord.carrierTrailer || carrierTrackingRecord.carrierTrailer,
              driver1Name:
                ctRecord.driver1Name || carrierTrackingRecord.driver1Name,
              sorTrackingId:
                carrierTrackingRecord.sorTrackingId || ctRecord.sorTrackingId,
              externalId:
                carrierTrackingRecord.externalId || ctRecord.externalId,
            })
          );

          dispatch(lastLocationForMovementUpdated(moveSequence, lastLocation));
          dispatch(
            updateLoadCommentsForMovement(moveSequence, {
              ...loadComments,
              carrierTrackingRecordId,
            })
          );

          if (stops && stops.length) {
            const geoRadius = stops[0].geoFenceRadius || 5;

            dispatch(updateMovementGeoFenceRadius(moveSequence, geoRadius));

            stops.map((stop) => {
              dispatch(
                updateTrackingStop(moveSequence, {
                  ...stop,
                  moveSequence,
                  carrierTrackingRecordId,
                })
              );
              dispatch(
                updateStopComment({
                  modelId: stop.externalId,
                  comment: stop.stopComment,
                })
              );
            });
          }
        }
      }
    } catch (error) {
      if (debugModeOn && (debugLevel === "ALL" || debugLevel === "ERRORS")) {
        console.error(`ERROR LOADING TRACKING: `, error);
      }

      dispatch({
        type: SET_MOVEMENT_ERRORS,
        /* @ts-ignore */
        payload: ["INIT_FETCH_FAILED"],
        meta: { moveSequence: ko.toJS(moveSequence) },
      });
    }

    dispatch(updateMovementIsLoading(moveSequence, false));

    return { type: "" };
  };

// SEND SAVE AND UPDATES TO THE TRACKING API
export const sendApiSaveTrackingAsync = () => async (dispatch, state) => {
  const { tntOrderEntry } = state;
  const moves = tntOrderEntry.trackedMovements;

  const byPassTrackingMoves = Object.keys(moves).reduce((payload, key) => {
    const mv = moves[key];
    if (mv.movementId > 0 === false) return payload;

    if (
      (mv.movementStatus === models.movementStatus[2] ||
        mv.movementStatus === models.movementStatus[4]) &&
      mv.carrierTracking.byPassTracking != undefined
    ) {
      payload.push({ ...mv, moveSequence: key });
    }

    return payload;
  }, []);

  const startTrackingMoves = Object.keys(moves).reduce((payload, key) => {
    const mv = moves[key];
    if (mv.movementId > 0 === false) return payload;

    let errors = getValidationErrors(mv.carrierTracking);

    if (mv.stops && mv.stops.length) {
      const stopErrors = Object.keys(mv.stops).reduce((err, k) => {
        const result = getStopValidationErrors(mv.stops[k]);
        if (result.count > 0) {
          err = { ...err, ...result };
        }

        return err;
      }, {});

      errors = { ...errors, ...stopErrors };
    }

    if (
      (mv.movementStatus === models.movementStatus[2] ||
        mv.movementStatus === models.movementStatus[4]) &&
      errors.count === 0 &&
      tntOrderEntry.waitStartTrackingSave === false &&
      !mv.carrierTracking.byPassTracking &&
      (tntOrderEntry.doStartTrackingSave ||
        tntOrderEntry.defaultProviderId > 0) &&
      mv.carrierTracking.trackingStatusId === models.trackingStatus.Pending
    ) {
      payload.push({ ...mv, moveSequence: key });
    }

    return payload;
  }, []);

  const createTrackingMoves = Object.keys(moves).reduce((payload, key) => {
    const mv = moves[key];
    if (mv.movementId > 0 === false) return payload;

    if (
      (mv.movementStatus === models.movementStatus[2] ||
        mv.movementStatus === models.movementStatus[4]) &&
      mv.carrierTracking.id > 0 === false &&
      tntOrderEntry.doStartTrackingSave === false &&
      !mv.carrierTracking.byPassTracking &&
      mv.carrierTracking.trackingStatusId === models.trackingStatus.Pending &&
      startTrackingMoves.some((x) => x.movementId === mv.movementId) ===
        false &&
      !mv.carrierTracking.trackingId
    ) {
      payload.push({ ...mv, moveSequence: key });
    }

    return payload;
  }, []);

  const updateTrackingMoves = Object.keys(moves).reduce((payload, key) => {
    const mv = moves[key];
    if (mv.movementId > 0 === false) return payload;

    if (
      (mv.movementStatus === models.movementStatus[2] ||
        mv.movementStatus === models.movementStatus[4]) &&
      tntOrderEntry.doStartTrackingSave === false &&
      !mv.carrierTracking.byPassTracking &&
      mv.carrierTracking.id > 0 &&
      mv.carrierTracking.trackingStatusId !== models.trackingStatus.Cancelled &&
      startTrackingMoves.some((x) => x.movementId === mv.movementId) === false
    ) {
      payload.push({ ...mv, moveSequence: key });
    }

    return payload;
  }, []);

  const cancelTrackingMovesBecauseOfByPassTracking = Object.keys(moves).reduce(
    (payload, key) => {
      const mv = moves[key];
      if (mv.movementId > 0 === false) return payload;

      if (
        (mv.movementStatus === models.movementStatus[2] ||
          mv.movementStatus === models.movementStatus[4]) &&
        tntOrderEntry.doStartTrackingSave === false &&
        mv.carrierTracking.byPassTracking === true &&
        mv.carrierTracking.id > 0 &&
        mv.carrierTracking.trackingStatusId !==
          models.trackingStatus.Cancelled &&
        startTrackingMoves.some((x) => x.movementId === mv.movementId) === false
      ) {
        payload.push({ ...mv, moveSequence: key });
      }

      return payload;
    },
    []
  );

  try {
    if (byPassTrackingMoves.length && tntOrderEntry.defaultProviderId > 0) {
      // requires tracking
      byPassTrackingMoves.map((mv) =>
        updateByPassTracking(mv.movementId, {
          byPassTracking: mv.carrierTracking.byPassTracking,
        })
      );
    }

    if (
      cancelTrackingMovesBecauseOfByPassTracking.length &&
      tntOrderEntry.defaultProviderId > 0
    ) {
      cancelTrackingMovesBecauseOfByPassTracking.map((mv) =>
        cancelTrackingAsync({ moveSequence: mv.moveSequence })(dispatch, state)
      );
    }

    if (createTrackingMoves.length) {
      dispatch(isLoading(true));
      createTrackingMoves.map((mv) =>
        saveTrackingMove(dispatch, mv).finally(() => dispatch(isLoading(false)))
      );
    }

    if (startTrackingMoves.length) {
      dispatch(isLoading(true));
      startTrackingMoves.map((mv) =>
        startTrackingMove(dispatch, mv)
          .then(
            (success) =>
              success &&
              dispatch(
                updateMovementInfoMsg(
                  mv.moveSequence,
                  `Movement ${mv.moveSequence} - Tracking Started.`
                )
              )
          )
          .finally(() => dispatch(isLoading(false)))
      );
    }

    if (updateTrackingMoves.length) {
      dispatch(isLoading(true));
      updateTrackingMoves.map((mv) =>
        updateTrackingMove(dispatch, mv)
          .then(
            (success) =>
              success &&
              mv.carrierTracking.trackingStatusId ===
                models.trackingStatus.Activated &&
              dispatch(
                updateMovementInfoMsg(
                  mv.moveSequence,
                  `Movement ${mv.moveSequence} - Tracking Synced.`
                )
              )
          )
          .finally(() => dispatch(isLoading(false)))
      );
    }

    dispatch(setDoStartTrackingSave(false));
  } catch (error) {
    dispatch(isLoading(false));
    if (debugModeOn && (debugLevel === "ALL" || debugLevel === "ERRORS")) {
      console.error(`ERROR STARTING|UPDATING TRACKING:`, error);
    }
  }

  return { type: "" }; // don't do anything, just return the current state.
};

// REFRESH LastLocation for a movement
export const refreshTrackingLastLocation =
  (moveSequence, carrierTrackingRecordId) => async (dispatch, state) => {
    try {
      if (carrierTrackingRecordId > 0) {
        dispatch(isLoading(true));
        const response = await TNTService.fetchRecentLocationsAsync([
          carrierTrackingRecordId,
        ]);
        dispatch(isLoading(false));
        if (response.length > 0) {
          const location = response.map((x) => ({ ...x.lastLocation }));
          if (location[0]) {
            dispatch(lastLocationForMovementUpdated(moveSequence, location[0]));
          }
        }
      }
    } catch (err) {
      dispatch(isLoading(false));
      if (debugModeOn && (debugLevel === "ALL" || debugLevel === "ERRORS")) {
        console.error(`ERROR STARTING|UPDATING TRACKING:`, err);
      }
    }

    return { type: "" }; // don't do anything, just return the current state.
  };

// REFRESH LastLocation for all movements on order that are tracking
export const refreshTrackingLastLocationForAllMovements =
  () => async (dispatch, state) => {
    try {
      const { tntOrderEntry } = state;
      const moves = tntOrderEntry.trackedMovements;

      dispatch(isLoading(true));
      Object.keys(moves).forEach(async (key) => {
        if (key == "0") return;
        const move = moves[key];

        if (move.carrierTracking.id > 0) {
          const response = await TNTService.fetchRecentLocationsAsync([
            move.carrierTracking.id,
          ]);

          if (response.length > 0) {
            const location = response.map((x) => ({ ...x.lastLocation }));
            if (location[0]) {
              dispatch(lastLocationForMovementUpdated(key, location[0]));
            }
          }
        }
      });

      dispatch(isLoading(false));
    } catch (err) {
      dispatch(isLoading(false));
      if (debugModeOn && (debugLevel === "ALL" || debugLevel === "ERRORS")) {
        console.error(`ERROR STARTING|UPDATING TRACKING:`, err);
      }
    }

    return { type: "" }; // don't do anything, just return the current state.
  };

const updateByPassTracking = (movementId, { byPassTracking = false }) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        "Dispatch/UpdateByPassTracking",
        "POST",
        { movementId, byPassTracking },
        true
      )
      .done(() => resolve(true))
      .fail((err) =>
        reject(
          (err.responseJSON && err.responseJSON) ||
            `And error occurred during request.`
        )
      );
  });
};

// SAVE TRACKING - API CALL & UI UPDATE -- Create Shell
const saveTrackingMove = async (dispatch, mv = {}) => {
  try {
    dispatch({
      type: SET_MOVEMENT_ERRORS,
      payload: [],
      meta: { moveSequence: ko.toJS(mv.moveSequence) },
    });

    const { carrierTracking, loadComments, stops } =
      await TNTService.postCreateTrackingAsync({
        carrierTracking: { ...mv.carrierTracking, referenceId: mv.movementId },
        loadComments: mv.loadComments,
        stops: mv.stops,
      });

    // This needs to happen first. Before updating our ui state.
    // await saveCarrierIdToMovement(mv.carrierTracking.sorTrackingId, {
    //     carrierTrackingId: carrierTracking.id,
    // });

    // Sync our models with the id's
    dispatch(
      updateCarrierTrackingForMovement(mv.moveSequence, carrierTracking)
    );
    dispatch(updateLoadCommentsForMovement(mv.moveSequence, loadComments));
    stops.forEach((stop) =>
      dispatch(updateTrackingStop(mv.moveSequence, stop))
    );

    if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
      console.log(`INITIAL TRACKING RECORD SAVED [PendingStatus]`, mv);
    }

    return true;
  } catch (error) {
    if (debugModeOn && (debugLevel === "ALL" || debugLevel === "ERRORS")) {
      console.error(`ERROR STARTING TRACKING:`, error, mv);
    }

    /* @ts-ignore */
    if (error.responseJSON) {
      /* @ts-ignore */
      if (
        typeof error.responseJSON === "string" &&
        error.responseJSON.indexOf("[Validation Error]") !== -1
      ) {
        /* @ts-ignore */
        dispatch({
          type: SET_MOVEMENT_ERRORS,
          payload: error.responseJSON.split(";"),
          meta: { moveSequence: ko.toJS(mv.moveSequence) },
        });
      } else if (
        error.responseJSON &&
        error.responseJSON.message &&
        (error.responseJSON.message.indexOf("[Code 1]") !== -1 ||
          error.responseJSON.message.indexOf("[Code 2]") !== -1)
      ) {
        dispatch({ type: SET_TRACKING_NEEDS_REFRESHED, payload: true });
        dispatch({
          type: SET_MOVEMENT_ERRORS,
          /* @ts-ignore */
          payload: [error.responseJSON.message + "- Please Reload Order -"],
          meta: { moveSequence: ko.toJS(mv.moveSequence) },
        });
      } else {
        dispatch({
          type: SET_MOVEMENT_ERRORS,
          /* @ts-ignore */
          payload: [
            (error.responseJSON && error.responseJSON.message) ||
              `An error occurred while processing the request.`,
          ],
          meta: { moveSequence: ko.toJS(mv.moveSequence) },
        });
      }
    }

    return false;
  }
};

// START TRACKING - API CALL -- Start Tracking - Set to Active
const startTrackingMove = async (dispatch, mv = {}) => {
  try {
    dispatch({
      type: SET_MOVEMENT_ERRORS,
      payload: [],
      meta: { moveSequence: ko.toJS(mv.moveSequence) },
    });

    const { carrierTracking, loadComments, stops } =
      await TNTService.postStartTrackingAsync({
        carrierTracking: { ...mv.carrierTracking, referenceId: mv.movementId },
        loadComments: mv.loadComments,
        stops: mv.stops,
      });

    // This needs to happen first. Before updating our ui state.
    // await saveCarrierIdToMovement(mv.carrierTracking.sorTrackingId, {
    //     carrierTrackingId: carrierTracking.id,
    //     trackingDetailUrl: carrierTracking.trackingDetailUrl,
    //     trackingMapUrl: carrierTracking.trackingMapUrl,
    //     trackingStatusUrl: carrierTracking.trackingStatusUrl,
    // });

    // Sync our models with the id's
    dispatch(
      updateCarrierTrackingForMovement(mv.moveSequence, carrierTracking)
    );
    dispatch(updateLoadCommentsForMovement(mv.moveSequence, loadComments));
    stops.forEach((stop) =>
      dispatch(updateTrackingStop(mv.moveSequence, stop))
    );

    if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
      console.log(`TRACKING STARTED [Activivated]`, mv);
    }

    return true;
  } catch (error) {
    if (debugModeOn && (debugLevel === "ALL" || debugLevel === "ERRORS")) {
      console.error(`ERROR STARTING TRACKING:`, error, mv);
    }

    /* @ts-ignore */
    if (error.responseJSON) {
      /* @ts-ignore */
      if (
        typeof error.responseJSON === "string" &&
        error.responseJSON.indexOf("[Validation Error]") !== -1
      ) {
        /* @ts-ignore */
        dispatch({
          type: SET_MOVEMENT_ERRORS,
          payload: error.responseJSON.split(";"),
          meta: { moveSequence: ko.toJS(mv.moveSequence) },
        });
      } else if (
        error.responseJSON.message &&
        (error.responseJSON.message.indexOf("[Code 1]") !== -1 ||
          error.responseJSON.message.indexOf("[Code 2]") !== -1)
      ) {
        dispatch({ type: SET_TRACKING_NEEDS_REFRESHED, payload: true });
        dispatch({
          type: SET_MOVEMENT_ERRORS,
          /* @ts-ignore */
          payload: [
            "Tracking has likely started but there is an issue displaying the track due to possible row duplication. Please contact the help desk with this error.",
          ],
          meta: { moveSequence: ko.toJS(mv.moveSequence) },
        });
      } else {
        dispatch({
          type: SET_MOVEMENT_ERRORS,
          /* @ts-ignore */
          payload: [
            (error.responseJSON && error.responseJSON.message) ||
              `An error occurred while processing the request.`,
          ],
          meta: { moveSequence: ko.toJS(mv.moveSequence) },
        });
      }
    }

    return false;
  }
};

// UPDATE TRACKING - API CALL & UI UPDATE
const updateTrackingMove = async (dispatch, mv = {}) => {
  try {
    dispatch({
      type: SET_MOVEMENT_ERRORS,
      payload: [],
      meta: { moveSequence: ko.toJS(mv.moveSequence) },
    });

    const { carrierTracking } = await TNTService.postUpdateTrackingAsync({
      carrierTracking: { ...mv.carrierTracking, referenceId: mv.movementId },
      loadComments: mv.loadComments,
      stops: mv.stops,
    });

    // if(carrierTracking.trackingId && carrierTracking.trackingStatusId === models.trackingStatus.Activated) {
    //     // This needs to happen first. Before updating our ui state.
    //     await saveCarrierIdToMovement(mv.carrierTracking.sorTrackingId, {
    //         carrierTrackingId: carrierTracking.id,
    //         trackingDetailUrl: carrierTracking.trackingDetailUrl,
    //         trackingMapUrl: carrierTracking.trackingMapUrl,
    //         trackingStatusUrl: carrierTracking.trackingStatusUrl,
    //     });
    // }

    if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
      console.log(`TRACKING SYNCED`, mv);
    }

    return true;
  } catch (error) {
    if (debugModeOn && (debugLevel === "ALL" || debugLevel === "ERRORS")) {
      console.error(`ERROR UPDATING TRACKING:`, error, mv);
    }
    // @ts-ignore
    if (error && error.responseJSON) {
      let errorMsg = ["An error occurred updating tracking."];

      // @ts-ignore
      if (
        typeof error.responseJSON === "string" &&
        error.responseJSON.indexOf("[Validation Error]") !== -1
      ) {
        // @ts-ignore
        errorMsg = error.responseJSON.split(", ");
      }
      // @ts-ignore
      else if (error.responseJSON.message) {
        // @ts-ignore
        console.error(error.responseJSON.message);
        // @ts-ignore
        errorMsg = [error.responseJSON.message];
      }

      // @ts-ignore
      dispatch({
        type: SET_MOVEMENT_ERRORS,
        payload: errorMsg,
        meta: { moveSequence: ko.toJS(mv.moveSequence) },
      });
    }

    return false;
  }
};

// UDPATE THE DEFAULT PROVIDER ID FOR SETTING CUSTOMER VENDOR DDL, etc.
export const updateDefaultProviderId = (providerId = 0) => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log("SET_DEFAULT_PROVIDER_ID:", providerId);
  }

  return { type: SET_DEFAULT_PROVIDER_ID, payload: providerId || 0 };
};

// DISPLAY A CONFIRMATION ON CANCELS
// RETURNS THE USER'S CHOIC TO THE PROVED CALLBACK FN.
// IF CONFIRMED, THEN THE CANCEL PROCESS IS KICKED OFF.
export const confirmCancelTracking =
  ({ message, onChoiceCallBackFn = noop }) =>
  async () => {
    const choice = await showconfirm(message);

    if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
      console.log("CONFIRM CANCEL TRACKING, ANSWER:", choice);
    }

    onChoiceCallBackFn(choice);

    return { type: "" }; // don't do anything, dispatcher's still expect an action
  };

export const cancelAllTrackingAsync =
  (trackingStatusId) => async (dispatch, state) => {
    const { tntOrderEntry } = state;
    const moves = tntOrderEntry.trackedMovements;

    try {
      Object.keys(moves).forEach((key) => {
        const mv = moves[key];
        if (mv.movementId > 0 === false) {
          return;
        }

        const cancelAction = cancelTrackingAsync({
          moveSequence: key,
          trackingStatusId,
        });
        cancelAction(dispatch, state);
      }, []);
    } catch (error) {
      if (debugModeOn && (debugLevel === "ALL" || debugLevel === "ERRORS")) {
        console.error(
          `ERROR CANCELLING ALL TRACKING FOR ORDER TRACKING:`,
          error
        );
      }
    }

    return { type: "" }; // don't do anything, just return the current state.
  };

// THE CANCEL PROCESS
export const cancelTrackingAsync =
  ({
    moveSequence,
    trackingStatusId = undefined,
    onSuccess = noop,
    onError = noop,
  }) =>
  async (dispatch, state) => {
    moveSequence = ko.toJS(moveSequence);

    const { tntOrderEntry } = state;
    const mv = tntOrderEntry["trackedMovements"][moveSequence];

    if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
      console.log(`CANCELLING MOVEMENT TRACKING: [${moveSequence}]`);
    }

    try {
      if (
        mv &&
        mv.carrierTracking.id > 0 &&
        mv.carrierTracking.trackingStatusId !== models.trackingStatus.Cancelled
      ) {
        dispatch(updateMovementIsLoading(moveSequence, true));
        const result = await TNTService.cancelTrackingAsync(
          mv.carrierTracking.id
        );
        dispatch(updateMovementIsLoading(moveSequence, false));

        if (result) {
          dispatch(
            updateCarrierTrackingForMovement(moveSequence, {
              trackingStatusId:
                trackingStatusId || models.trackingStatus.Cancelled,
            })
          );
          onSuccess();
        }

        dispatch(
          updateCarrierTrackingForMovement(moveSequence, {
            trackingStatusId: models.trackingStatus.Cancelled,
          })
        );
      }
    } catch (error) {
      dispatch(updateMovementIsLoading(moveSequence, false));

      if (debugModeOn && (debugLevel === "ALL" || debugLevel === "ERRORS")) {
        console.log(
          `ERROR CANCELLING MOVEMENT TRACKING: [${moveSequence}]`,
          error
        );
      }

      let errorMsg = ["An error occurred while cancelling tracking."];

      // @ts-ignore
      if (error && error.responseJSON) {
        // @ts-ignore
        if (
          typeof error.responseJSON === "string" &&
          error.responseJSON.indexOf("[Validation Error]") !== -1
        ) {
          // @ts-ignore
          errorMsg = error.responseJSON.split(", ");
        }
        // @ts-ignore
        else if (error.responseJSON.message) {
          // @ts-ignore
          console.error(error.responseJSON.message);
          // @ts-ignore
          errorMsg = [error.responseJSON.message];
        }
      }

      onError(errorMsg || `An error occurred during request.`);
    }

    return { type: "" };
  };

// RESETS A FEW FIELDS (MOSLTY Ids, Keys) TO DEFAULT VALUES
export const resetTrackingFields =
  (moveSequence, movementStatus) => async (dispatch, state) => {
    moveSequence = ko.toJS(moveSequence);

    const { tntOrderEntry } = state;
    const mv = tntOrderEntry["trackedMovements"][moveSequence];

    if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
      console.log(`RESETTING TRACKING FIELDS: [${moveSequence}]`);
    }

    if (mv && moveSequence > 0) {
      const stops = mv.stops.map((st) => {
        st.id = 0;
        st.carrierTrackingRecordId = 0;
        st.stopComment = undefined;
        st.geoFenceRadius = 5;

        dispatch(updateStopComment({ modelId: st.externalId, comment: "" }));

        return st;
      });

      dispatch(
        updateCarrierTrackingForMovement(moveSequence, {
          id: 0,
          trackingId: 0,
          startTrackingMins: 60,
          startTracking: undefined,
          providerId: tntOrderEntry.defaultProviderId || null,
          notifyOnPickupDelivered: false,
          notifyOnLocationUpdates: false,
          locationUpdateNotifyInterval: 1,
          movementStatus: movementStatus ? movementStatus : mv.movementStatus,
        })
      );

      dispatch(updateMovementGeoFenceRadius(moveSequence, 5));
      dispatch(setTrackingStops(moveSequence, stops));
      dispatch(
        updateLoadCommentsForMovement(moveSequence, {
          carrierTrackingRecordId: 0,
          id: 0,
          driverComment: "",
          loadComment: "",
        })
      );
    }

    return { type: "" };
  };

// SET TRACKING STOPS -> THIS WILL REPLACE ALL STOPS FOR MOVMENT SEQUENCE.
// IF YOU NEED TO UPDATE ONE THEN USE 'updateTrackingStop'
export const setTrackingStops = (moveSequence, stops = []) => {
  moveSequence = ko.toJS(moveSequence);

  if (
    debugModeOn &&
    (debugLevel === "ALL" || debugLevel === "INFO") &&
    moveSequence > 0
  ) {
    console.log(
      `SET_TRACKING_STOPS [Movment - ${ko.toJS(moveSequence)}]:`,
      stops
    );
  }

  return {
    type: SET_TRACKING_STOPS,
    payload: stops,
    meta: { moveSequence: moveSequence },
  };
};

// REFRESHES THE CURRENT STORE SLICE TO THE INITIAL STATE
export const refreshInitialState = () => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log("Refreshing Tracking Model(s)", initState);
  }

  return { type: REFRESH_INITIAL_STATE };
};

// UPDATE A SINGLE TRACKING STOP FOR MOVESEQUENCE.
export const updateTrackingStop = (moveSequence, tntStop) => {
  moveSequence = ko.toJS(moveSequence);

  if (
    debugModeOn &&
    (debugLevel === "ALL" || debugLevel === "INFO") &&
    moveSequence > 0
  ) {
    console.log(
      `UPDATE_TRACKING_STOP [Movement:${ko.toJS(moveSequence)}]:`,
      tntStop
    );
  }

  return {
    type: UPDATE_TRACKING_STOP,
    payload: tntStop,
    meta: { moveSequence: ko.toJS(moveSequence) },
  };
};

// UPDATES THE CARRIER TRACKING RECORD FOR MOVESEQUENCE.
// DOES NOT REPLACE. CAN SEND AN OBJECT WITH ONLY THE KEY/VALUE PAIRS YOU WANT TO UPDATE
// OR SEND THE COMPLETE MODEL TO REPLACE ALL. -> i.e. trackingModel: {...currentTrackingModel, ...yourNewTrackingModel }
export const updateCarrierTrackingForMovement = (moveSequence, tracking) => {
  moveSequence = ko.toJS(moveSequence);
  tracking = tracking || tntCarrierTrackingModel();

  if (
    debugModeOn &&
    (debugLevel === "ALL" || debugLevel === "INFO") &&
    moveSequence > 0
  ) {
    console.log(
      `UPDATE_CARRIER_TRACKING_FOR_MOVEMENT [Movement:${ko.toJS(
        moveSequence
      )}]:`,
      tracking
    );
  }

  return {
    type: UPDATE_CARRIER_TRACKING_FOR_MOVEMENT,
    payload: tracking,
    meta: { moveSequence: ko.toJS(moveSequence) },
  };
};

// UPDATES ALL CARRIER TRACKING RECORDS
// USEFUL FOR GLOBAL CHANGES THAT AFFECT ALL MOVEMENT TRACKING
export const updateCarrierTrackings = (tracking) => {
  tracking = tracking || tntCarrierTrackingModel();
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`UPDATE_CARRIER_TRACKINGS [All Movments]:`, tracking);
  }

  return { type: UPDATE_CARRIER_TRACKINGS, payload: tracking };
};

// UPDATE THE LOADCOMMENTS FOR MOVESEQUENCE -> driverComment, loadComment
export const updateLoadCommentsForMovement = (moveSequence, loadComments) => {
  moveSequence = ko.toJS(moveSequence);
  loadComments = loadComments || LoadCommentModel();

  if (
    debugModeOn &&
    (debugLevel === "ALL" || debugLevel === "INFO") &&
    moveSequence > 0
  ) {
    console.log("UPDATE_LOAD_COMMENTS:", loadComments);
  }

  return {
    type: UPDATE_LOAD_COMMENTS,
    payload: loadComments,
    meta: { moveSequence: ko.toJS(moveSequence) },
  };
};

// ADD A NEW TRACKING MOVEMENT SLICE
export const addTrackingMovement = (
  moveSequence,
  {
    movementId = 0,
    movementStatus = models.movementStatus[1],
    movementGeoFenceRadius = 5, // min 1, max 5 -> applies to all movement stops
    carrierTracking = tntCarrierTrackingModel(),
    loadComments = LoadCommentModel(),
    stops = [],
    errors = [],
    infoMsg = undefined,
  } = {}
) => {
  if (
    debugModeOn &&
    (debugLevel === "ALL" || debugLevel === "INFO") &&
    moveSequence > 0
  ) {
    console.log(`ADD_TRACKING_MOVEMENT: [${moveSequence}]`, {
      movementId,
      movementStatus,
      movementGeoFenceRadius,
      carrierTracking,
      loadComments,
      stops,
      errors,
      infoMsg,
    });
  }

  return {
    type: ADD_TRACKING_MOVEMENT,
    payload: {
      movementId,
      movementStatus,
      movementGeoFenceRadius,
      carrierTracking,
      loadComments,
      stops,
      errors,
      infoMsg,
    },
    meta: { moveSequence: ko.toJS(moveSequence) },
  };
};

// UPDATE THE MOVEMENT STATUS -> AVAILABLE, COVERED, IN PROGRESS, DELIVERED, etc -> see models above.
export const updateMovementStatus = (
  moveSequence,
  status = models.movementStatus[0]
) => {
  moveSequence = ko.toJS(moveSequence);

  if (
    debugModeOn &&
    (debugLevel === "ALL" || debugLevel === "INFO") &&
    moveSequence > 0
  ) {
    console.log(`UPDATE_MOVEMENT_STATUS: [${moveSequence}]`, status);
  }
  return {
    type: UPDATE_MOVEMENT_STATUS,
    payload: status,
    meta: { moveSequence: ko.toJS(moveSequence) },
  };
};

// ANY MESSAGE NEED TO RELAY TO THE MOVEMENT TRACKING SECTION
// CURRENTLY USES `toast` NOTIFICATION.
export const updateMovementInfoMsg = (moveSequence, msg) => {
  moveSequence = ko.toJS(moveSequence);

  if (
    debugModeOn &&
    (debugLevel === "ALL" || debugLevel === "INFO") &&
    moveSequence > 0
  ) {
    console.log(`SET_MOVEMENT_INFO_MSG: [${moveSequence}]`, msg);
  }
  return {
    type: SET_MOVEMENT_INFO_MSG,
    payload: msg,
    meta: { moveSequence: ko.toJS(moveSequence) },
  };
};

// SET MOVEMENT TRACKING SECTION TO HAVE A LOADING ICON - BLOCK
export const updateMovementIsLoading = (moveSequence, isLoading = false) => {
  return {
    type: UPDATE_MOVEMENT_IS_LOADING,
    payload: isLoading,
    meta: { moveSequence: ko.toJS(moveSequence) },
  };
};

// UPDATE A STOP COMMENT
export const updateStopComment = ({ modelId, comment }) => {
  if (
    debugModeOn &&
    (debugLevel === "ALL" || debugLevel === "INFO") &&
    modelId
  ) {
    console.log(`UPDATE_STOP_COMMENT: [Stop Model ID: ${modelId}]`, comment);
  }

  return { type: UPDATE_STOP_COMMENT, payload: { modelId, comment } };
};

// OVERWRITE STOP COMMENTS
export const setStopComments = (comments = []) => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`SET_STOP_COMMENTS: `, comments);
  }

  return { type: SET_STOP_COMMENTS, payload: comments };
};

// UPDATE MOVEMENT'S GEOFENCE RADIUS SETTING SO IT CAN BE APPLIED TO ALL STOPS IN MOVEMENT
export const updateMovementGeoFenceRadius = (moveSequence, radius = 5) => {
  moveSequence = ko.toJS(moveSequence);
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(
      `SET_MOVEMENT_GEORADIUS: [MoveSequence: ${moveSequence}]`,
      radius
    );
  }

  return {
    type: SET_MOVEMENT_GEORADIUS,
    payload: radius,
    meta: { moveSequence: moveSequence },
  };
};

// FLIPS TRACKING ON / OFF
export const updateIsTrackingOn = (yes = false) => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`SET_IS_TRACKING_ON`, yes);
  }

  return { type: SET_IS_TRACKING_ON, payload: yes };
};

// SET / UPDATE THE DEFAULT START TRACKING DATETIME -> USE SHIPPER STOP ARRIVAL DATE.
export const updateDefaultStartTracking = (date = new Date()) => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`SET_DEFAULT_START_TRACKING`, date);
  }

  return { type: SET_DEFAULT_START_TRACKING, payload: datetimeUTC(date) };
};

// A FLAG FOR WHEN SAVES OCCUR IF SET TO TRUE WILL SEND DATA TO 'START TRACKING' API
export const setDoStartTrackingSave = (yes = false) => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`SET_DO_START_TRACKING_SAVE`, yes);
  }

  return { type: SET_DO_START_TRACKING_SAVE, payload: yes };
};

// A FLAG FOR WHEN SAVES OCCUR IF SET TO TRUE WILL NOT SEND A "START TRACKING" api call.
// Useful to only do update saves, additional work etc. Then have saves make Start Tracking calls when
// set back to false.
export const setWaitStartTrackingSave = (yes = false) => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`SET_WAIT_START_TRACKING_SAVE`, yes);
  }

  return { type: SET_WAIT_START_TRACKING_SAVE, payload: yes };
};

export const setUserCanManuallyTrack = (yes = false) => {
  return { type: SET_USER_CAN_MANUALLY_TRACK, payload: yes };
};

export const setUserCanEditVendor = (yes = false) => {
  return { type: SET_USER_CAN_EDIT_VENDOR, payload: yes };
};

// SET MOVEMENT TRACKING SECTION TO HAVE A LOADING ICON - BLOCK
export const updateTrackingStatusForMovement = (
  moveSequence,
  isLoading = false
) => {
  return {
    type: UPDATE_MOVEMENT_IS_LOADING,
    payload: isLoading,
    meta: { moveSequence: ko.toJS(moveSequence) },
  };
};

export const lastLocationForMovementUpdated = (moveSequence, lastLocation) => {
  moveSequence = ko.toJS(moveSequence);
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`SET_LAST_LOCATION_FOR_MOVEMENT`, lastLocation);
  }

  return {
    type: SET_LAST_LOCATION_FOR_MOVEMENT,
    payload: lastLocation,
    meta: { moveSequence: moveSequence },
  };
};

export const deliveryDateUpdated = (deliveryDate) => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`SET_ORDER_DELIVERY_DATE`, deliveryDate);
  }

  return { type: SET_ORDER_DELIVERY_DATE, payload: deliveryDate };
};

export const orderIdUpdated = (orderId) => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`ORDER_ID_UPDATED`, orderId);
  }

  return { type: ORDER_ID_UPDATED, payload: orderId };
};

export const customerMappedVendorIdUpdated = (vendorId) => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`CUSTOMER_MAPPED_VENDOR_ID_UPDATED`, vendorId);
  }

  return { type: CUSTOMER_MAPPED_VENDOR_ID_UPDATED, payload: vendorId };
};

export const updateTrackingNeedsRefreshed = (yes) => {
  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(`SET_TRACKING_NEEDS_REFRESHED`, yes);
  }

  return { type: SET_TRACKING_NEEDS_REFRESHED, payload: yes };
};

// INITIAL STATE...THIS IS WHAT THE TNT ORDER ENTRY MODEL LOOKS LIKE
// AND THE STATE OF OUR SLICE OF THE DATASTORE
const initState = {
  currentOrderId: undefined,
  isTrackingOn: false,
  defaultProviderId: undefined,
  customerMapperVendorId: undefined,
  orderDeliveryDate: undefined,
  userCanManuallyTrack: true, //getUserRoles().some(x => x.toUpperCase() === "BROKER TRACKING"),
  userCanEditVendor: getUserRoles().some(
    (x) => x.toUpperCase() === "ADMINISTRATORS"
  ),
  defaultStartTracking: undefined, // a default start tracking datetime. Should be updated with shipper date.
  doStartTrackingSave: false, // determines if doing an update or start tracking api call. Triggered by the 'start tracking' btn.
  waitStartTrackingSave: false, // prevent start tracking no matter what when true.
  // if more than one user is touching the order with tracking, an api failure will return, so when this is true, it displays a message to refesh
  trackingNeedsRefreshed: false,
  trackedMovements: {
    0: {
      // 0 is the default, placeholder. Additional movements added will have their movement sequence as the key.
      movementId: 0, // this is used in the UI only to help when making query / api calls to GE
      movementStatus: models.movementStatus[0],
      movementGeoFenceRadius: 5, // min 1, max 5 -> prop applies to all movement stops - default is 2
      carrierTracking: tntCarrierTrackingModel(),
      loadComments: LoadCommentModel(),
      stops: [],
      errors: [],
      infoMsg: undefined,
      isLoading: false,
      lastLocation: undefined,
    },
  },
  stopComments: [{ comment: "", modelId: 0 }], // HAVING THIS SEPARATE FROM THE STOPS ARRAY MAKES LIFE A LITTLE EASIER WHEN MAPPING + WHEN MOVEMENTS+STOPS ARE REPLACED WITH UPDATED ONES...
};

/////////////////////////////////////////////
// THE REDUCER FUNCTION (SLICE OF STORE)
//
export default function tntOrderEntry(state = initState, action) {
  switch (action.type) {
    case REFRESH_INITIAL_STATE:
      return { ...initState };
    case SET_IS_TRACKING_ON:
      return { ...state, isTrackingOn: action.payload };
    case SET_DEFAULT_START_TRACKING:
      return { ...state, defaultStartTracking: action.payload };
    case SET_DO_START_TRACKING_SAVE:
      return { ...state, doStartTrackingSave: action.payload };
    case SET_WAIT_START_TRACKING_SAVE:
      return { ...state, waitStartTrackingSave: action.payload };
    case SET_USER_CAN_MANUALLY_TRACK:
      return { ...state, userCanManuallyTrack: action.payload };
    case SET_USER_CAN_EDIT_VENDOR:
      return { ...state, userCanEditVendor: action.payload };
    case ORDER_ID_UPDATED:
      return { ...state, orderId: action.payload };
    case SET_TRACKING_NEEDS_REFRESHED:
      return { ...state, trackingNeedsRefreshed: action.payload };
    case SET_TRACKING_STOPS:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: {
            ...state.trackedMovements[0],
            ...state.trackedMovements[action.meta.moveSequence],
            stops: action.payload,
          },
        },
      };
    case UPDATE_TRACKING_STOP:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: {
            ...state.trackedMovements[0],
            ...state.trackedMovements[action.meta.moveSequence],
            stops: [
              ...(
                (state.trackedMovements[action.meta.moveSequence] || {})
                  .stops || []
              ).filter((x) => x.externalId !== action.payload.externalId),
              action.payload,
            ],
          },
        },
      };
    case UPDATE_CARRIER_TRACKINGS:
      return {
        ...state,
        trackedMovements: updateCarrierTrackingReducer(
          state.trackedMovements,
          action.payload
        ),
      };
    case UPDATE_CARRIER_TRACKING_FOR_MOVEMENT:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: {
            ...state.trackedMovements[0],
            ...state.trackedMovements[action.meta.moveSequence],
            carrierTracking: {
              ...(state.trackedMovements[action.meta.moveSequence] &&
                state.trackedMovements[action.meta.moveSequence]
                  .carrierTracking),
              providerId:
                state.trackedMovements[action.meta.moveSequence].carrierTracking
                  .providerId || state.defaultProviderId,
              ...action.payload,
            },
          },
        },
      };
    case UPDATE_LOAD_COMMENTS:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: {
            ...state.trackedMovements[0],
            ...state.trackedMovements[action.meta.moveSequence],
            loadComments: {
              ...(state.trackedMovements[action.meta.moveSequence] &&
                state.trackedMovements[action.meta.moveSequence].loadComments),
              ...action.payload,
            },
          },
        },
      };
    case UPDATE_MOVEMENT_STATUS:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: {
            ...state.trackedMovements[0],
            ...state.trackedMovements[action.meta.moveSequence],
            movementStatus: action.payload,
          },
        },
      };
    case ADD_TRACKING_MOVEMENT:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: action.payload,
        },
      };
    case SET_MOVEMENT_ERRORS:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: {
            ...state.trackedMovements[0],
            ...state.trackedMovements[action.meta.moveSequence],
            errors: action.payload || [],
          },
        },
      };
    case SET_MOVEMENT_INFO_MSG:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: {
            ...state.trackedMovements[0],
            ...state.trackedMovements[action.meta.moveSequence],
            infoMsg: action.payload,
          },
        },
      };
    case UPDATE_MOVEMENT_IS_LOADING:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: {
            ...state.trackedMovements[0],
            ...state.trackedMovements[action.meta.moveSequence],
            isLoading: action.payload,
          },
        },
      };
    case UPDATE_STOP_COMMENT:
      return {
        ...state,
        stopComments: [
          ...(state.stopComments || []).filter(
            (x) => x.modelId !== action.payload.modelId
          ),
          action.payload,
        ],
      };
    case SET_DEFAULT_PROVIDER_ID:
      return { ...state, defaultProviderId: action.payload };
    case CUSTOMER_MAPPED_VENDOR_ID_UPDATED:
      return { ...state, customerMapperVendorId: action.payload };
    case SET_MOVEMENT_GEORADIUS:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: {
            ...state.trackedMovements[0],
            ...state.trackedMovements[action.meta.moveSequence],
            movementGeoFenceRadius: action.payload,
          },
        },
      };
    case SET_STOP_COMMENTS:
      return { ...state, stopComments: action.payload };
    case SET_LAST_LOCATION_FOR_MOVEMENT:
      return {
        ...state,
        trackedMovements: {
          ...state.trackedMovements,
          [action.meta.moveSequence]: {
            ...state.trackedMovements[0],
            ...state.trackedMovements[action.meta.moveSequence],
            lastLocation: action.payload,
          },
        },
      };
    case SET_ORDER_DELIVERY_DATE:
      return { ...state, orderDeliveryDate: action.payload };
    default:
      return state;
  }
}

// Reducer Helper Functions
const updateCarrierTrackingReducer = (trackedMovements, payload) => {
  return Object.keys(trackedMovements).reduce((x, key) => {
    x[key] = {
      ...trackedMovements[key],
      carrierTracking: { ...trackedMovements[key].carrierTracking, ...payload },
    };
    return x;
  }, {});
};

////////////////////////////////////////////////////////////
// Selectors
// Return computeds for every selector so can subscribe to changes
// and prevent updating values directly from selector. Value changes
// should go through the datastore `tntOrderEntry` reducer via dispatch + action type
// (state is provided via the useSelector hook from ko-datastore).
export const selectOrderEntryTNT = (state) => {
  return ko.pureComputed(() => {
    //console.log(state.tntOrderEntry())
    return state.tntOrderEntry();
  });
};

// SELECT ALL MOVEMENTS
export const selectTrackedMovements = (state) => {
  return ko.pureComputed(() => {
    const tnt = selectOrderEntryTNT(state);
    return tnt()["trackedMovements"] || {};
  });
};

// SELECT MOVEMENT BY SEQUENCE
export const selectTrackedMovementBySequence = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tnt = selectOrderEntryTNT(state);
    const mt = tnt()["trackedMovements"][ko.toJS(moveSequence)] || {};

    return mt;
  });
};

// SELECT IF TRACKING IS ON
export const selectIsTrackingOn = (state) => {
  return ko.pureComputed(() => {
    const tnt = selectOrderEntryTNT(state);
    const tm = selectTrackedMovements(state);

    return (
      tnt()["isTrackingOn"] ||
      Object.keys(tm()).some(
        (key) =>
          key &&
          key !== "0" && // ignore our 'placeholder' movement tracking
          tm()[key]["carrierTracking"] &&
          (tm()[key]["carrierTracking"].trackingStatusId ===
            models.trackingStatus.Activated ||
            tm()[key]["carrierTracking"].trackingStatusId ===
              models.trackingStatus.Pending)
      )
    );
  });
};

// SELECT THE STOPS FOR MOVEMENT SEQ
export const selectTrackingStopsForMovement = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tracking = selectTrackedMovementBySequence(
      state,
      ko.toJS(moveSequence)
    );
    return tracking()["stops"] || [];
  });
};

// SELECT CARRIER TRACKING RECORD FOR MOVEMENT SEQUENCE
export const selectTrackingCarrierForMovement = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tracking = selectTrackedMovementBySequence(
      state,
      ko.toJS(moveSequence)
    );
    return tracking()["carrierTracking"] || tntCarrierTrackingModel();
  });
};

// SELECT STOPS BY THE GE EXTERNALID
export const selectTrackingStopByStopModelId = (
  state,
  moveSequence,
  modelId
) => {
  return ko.pureComputed(() => {
    const stops = selectTrackingStopsForMovement(state, ko.toJS(moveSequence));
    return stops().find((x) => x.modelId === modelId) || {};
  });
};

// SELECT THE CURRENT TRACKING STATUS FOR MOVEMENT TRACKING
export const selectMoveSequenceHasActiveTracking = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tntState = selectTrackedMovementBySequence(
      state,
      ko.toJS(moveSequence)
    );

    return (
      (tntState()["carrierTracking"] &&
        tntState()["carrierTracking"].trackingStatusId > 0 &&
        tntState()["carrierTracking"].trackingStatusId ===
          models.trackingStatus.Activated) ||
      false
    );
  });
};

// SELECT THE CURRENT TRACKING STATUS FOR MOVEMENT TRACKING
export const selectMoveSequenceHasCancelledTracking = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tntState = selectTrackedMovementBySequence(
      state,
      ko.toJS(moveSequence)
    );

    return (
      (tntState()["carrierTracking"] &&
        tntState()["carrierTracking"].trackingStatusId > 0 &&
        tntState()["carrierTracking"].trackingStatusId ===
          models.trackingStatus.Cancelled) ||
      false
    );
  });
};

// SELECT IF ANY MOVEMENTS HAS ACTIVE TRACKING
// CarrierTrackingRecord.ID > 0
export const selectHasActiveTracking = (state) => {
  return ko.pureComputed(() => {
    const tm = selectTrackedMovements(state);

    return Object.keys(tm()).some(
      (key) =>
        key &&
        key !== "0" &&
        tm()[key]["carrierTracking"] &&
        tm()[key]["carrierTracking"].id > 0 &&
        tm()[key]["carrierTracking"].trackingStatusId ===
          models.trackingStatus.Activated
    );
  });
};

// SELECT IF ANY MOVEMENT IS ACTIVE/CANCELED
export const selectHasTrackingBeenStarted = (state) => {
  return ko.pureComputed(() => {
    const tm = selectTrackedMovements(state);

    return Object.keys(tm()).some(
      (key) =>
        key &&
        key !== "0" &&
        tm()[key]["carrierTracking"] &&
        tm()[key]["carrierTracking"].trackingId &&
        (tm()[key]["carrierTracking"].trackingStatusId ===
          models.trackingStatus.Activated ||
          tm()[key]["carrierTracking"].trackingStatusId ===
            models.trackingStatus.Cancelled)
    );
  });
};

// SELECT IF ANY MOVEMENT HAS THE PROPER STATUS TO TURN ON TRACKING
// MovementStatus = 'In Progress' or 'Covered'
export const selectAnyMovementIsReadyForTracking = (state) => {
  return ko.pureComputed(() => {
    const mvs = selectTrackedMovements(state);
    return Object.keys(mvs()).some(
      (key) =>
        mvs()[key] != "0" &&
        (mvs()[key].movementStatus === models.movementStatus[2] ||
          mvs()[key].movementStatus === models.movementStatus[4])
    );
  });
};

// SELECT THE MOVEMENT STATUS -> AVAILABLE, COVERED, IN PROGRESS, etc
export const selectMovementStatus = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tnt = selectTrackedMovementBySequence(state, ko.toJS(moveSequence));
    return tnt()["movementStatus"] || "";
  });
};

export const selectMovementStatusMovementId = (state, movementId) => {
  return ko.pureComputed(() => {
    const tnt = selectOrderEntryTNT(state);
    const moves = tnt()["trackedMovements"] || {};

    let status = "";
    Object.keys(moves).forEach((key) => {
      const move = moves[key];
      if (move.movementId == movementId) {
        status = move.movementStatus || status;
      }
    });

    return status;
  });
};

// SELECT ANY CHANGES TO THE `CUSTOMER ORDER ID` ddl.
// export const selectCustomerTrackingFieldIdChange = (state) => {
//     return ko.pureComputed(() => {
//         const tcmoves = selectTrackedMovements(state);

//         return Object.keys(tcmoves()).reduce((x, key) => {
//             if(tcmoves()[key]['carrierTracking']) {
//                 x.push({
//                     moveSequence: key,
//                     ...tcmoves()[key]
//                 })
//             }

//             return x;
//         }, [])

//     });
// }
export const selectCustomerTrackingFieldIdChange = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const move = selectTrackingCarrierForMovement(state, moveSequence);
    return move();
  });
};

// SELECT (get) VALIDATION ERRORS FOR MOVESEQUENCE -> CARRIER TRACKING RECORD
export const selectCarrierTrackingErrorsForMovement = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tc = selectTrackingCarrierForMovement(state, ko.toJS(moveSequence));
    const stops = selectTrackingStopsForMovement(state, ko.toJS(moveSequence));
    const requiresTracking = selectRequiresTracking(state);
    let errors = getValidationErrors(tc(), requiresTracking());

    if (stops()) {
      const stopErrors = Object.keys(stops()).reduce((err, key) => {
        const result = getStopValidationErrors(stops()[key]);
        if (result.count > 0) {
          err = { ...err, ...result };
        }

        return err;
      }, {});

      errors = { ...errors, ...stopErrors };
    }

    if (
      debugModeOn &&
      (debugLevel === "ALL" || debugLevel === "VALIDATION") &&
      errors.count
    ) {
      console.log(
        `CARRIER TRACKING RECORD VALIDATION: [${ko.toJS(moveSequence)}]`,
        errors
      );
    }

    return errors;
  });
};

// SELECT (get) ANY API ERRORS WHEN TNT API CALLS WAS MADE.
export const selectTrackingErrorsForMovement = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tc = selectTrackedMovementBySequence(state, ko.toJS(moveSequence));
    const errors = tc()["errors"] || [];

    if (
      debugModeOn &&
      (debugLevel === "ALL" || debugLevel === "ERRORS") &&
      errors.length
    ) {
      console.log(
        `CARRIER TRACKING RECORD VALIDATION (ERRORS): [${ko.toJS(
          moveSequence
        )}]`,
        errors
      );
    }

    return errors;
  });
};

// SELECT ANY INFORMATIONA MESSAGES
export const selectMovementInfoMsg = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tc = selectTrackedMovementBySequence(state, ko.toJS(moveSequence));

    return tc()["infoMsg"] || "";
  });
};

// SELECT IF THE MOVEMENT.isLoading
export const selectIsMovementLoading = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tc = selectTrackedMovementBySequence(state, ko.toJS(moveSequence));

    return tc()["isLoading"] || false;
  });
};

// SELECT STOP COMMENT FOR STOP SEQUENCE
export const selectStopComment = (state, modelId) => {
  return ko.pureComputed(() => {
    const tntOrderEntry = selectOrderEntryTNT(state);
    const { stopComments } = tntOrderEntry();

    const record =
      (stopComments || []).find((x) => x.modelId === modelId) || {};
    return record["comment"] || "";
  });
};

export const selectTrackingStop = (state, moveSequence, modelId) => {
  return ko.pureComputed(() => {
    const tracking = selectTrackedMovementBySequence(state, moveSequence);
    const { stops } = tracking();

    const stop = (stops || []).find((x) => x.externalId === modelId) || {};

    return stop;
  });
};

// SELECT THE CARRIER TRACKING START TRACKING MINS FOR MOVEMENT
export const selectStartTrackMins = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tracking = selectTrackingCarrierForMovement(state, moveSequence);
    return tracking()["startTrackingMins"] || 60;
  });
};

// SELECT DEFAULT PROVIDER ID SET BY THE SELECTED CUSTOMER
export const selectDefaultProviderId = (state) => {
  return ko.pureComputed(() => {
    const tnt = selectOrderEntryTNT(state);
    return tnt()["defaultProviderId"] || 0;
  });
};

// SELECT IF ORDER REQUIRES TRACKING BASED ON THE DEFAULTPROVIDERID SET BY
// THE SELECTED CUSTOMER.
export const selectRequiresTracking = (state) => {
  return ko.pureComputed(() => {
    const id = selectDefaultProviderId(state);
    return id() > 0;
  });
};

// SELECT THE DEFAULT START TRACKING DATE (Based on the shipper stop date)
export const selectDefaultStartTracking = (state) => {
  return ko.pureComputed(() => {
    const tnt = selectOrderEntryTNT(state);

    return tnt()["defaultStartTracking"];
  });
};

export const selectTrackingStatusForMove = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const cr = selectTrackingCarrierForMovement(state, moveSequence);

    return cr()["trackingStatusId"];
  });
};

// GET THE MINS FOR -ADDING TO START TRACKING DATE / SHIPPER STOP EARLIEST ARRIVAL
export const selectStartTrackingMinsForMove = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const cr = selectTrackingCarrierForMovement(state, moveSequence);
    return cr()["startTrackingMins"] || 60;
  });
};

export const selectUserCanManuallyTrack = (state) => {
  return ko.pureComputed(() => {
    // const tnt = selectOrderEntryTNT(state);

    return true; //tnt()['userCanManuallyTrack'] || getUserRoles().some(x => x.toUpperCase() === "BROKER TRACKING");
  });
};

export const selectUserCanEditVendor = (state) => {
  return ko.pureComputed(() => {
    const tnt = selectOrderEntryTNT(state);
    return (
      tnt()["userCanEditVendor"] ||
      getUserRoles().some((x) => x.toUpperCase() === "ADMINISTRATORS")
    );
  });
};

export const selectTransitStatusForMovement = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tntMove = selectTrackedMovementBySequence(state, moveSequence);
    const carrierTracking = tntMove()["carrierTracking"];

    if (carrierTracking) {
      const trackingStatusId = carrierTracking.trackingStatusId;
      const lastLocation = tntMove()["lastLocation"];

      if (trackingStatusId === models.trackingStatus.Activated) {
        return !lastLocation || !lastLocation.status
          ? "While tracking has started the driver has not enabled it yet. Please contact the driver to enable tracking."
          : lastLocation.status;
      }
    }

    return "N/A";
  });
};

export const selectIsOrderIdChange = (state, currentOrderId) => {
  return ko.pureComputed(() => {
    const tnt = selectOrderEntryTNT(state);

    return tnt()["orderId"] != currentOrderId;
  });
};

// SELECT CUSTOMER MAPPED VENDOR ID
export const selectCustomerMappedVendorId = (state) => {
  return ko.pureComputed(() => {
    const tnt = selectOrderEntryTNT(state);
    return tnt()["customerMappedVendorId"] || 0;
  });
};

export const selectTrackingByPassedForMovement = (state, moveSequence) => {
  return ko.pureComputed(() => {
    const tntMove = selectTrackedMovementBySequence(state, moveSequence);
    const carrierTracking = tntMove()["carrierTracking"] || {};

    return carrierTracking.byPassTracking || false;
  });
};

export const selectTrackingNeedsRefreshed = (state) => {
  return ko.pureComputed(() => {
    const tnt = selectOrderEntryTNT(state);
    return tnt()["trackingNeedsRefreshed"] || false;
  });
};

/////////////////////////////////
// Helper Functions

// Map from move.stops or from order.stops -> most should match up with key/value pairs. Those that do not
// are in the if statements
export const mapToTntStop = (orderStop = {}, tntStop = tntStopModel()) => {
  const mapped = Object.keys(tntStop).reduce((stp, key) => {
    let val =
      key !== "id" && key !== "externalId" ? orderStop[key] : tntStop[key]; // don't override the externalId or Id for tnt.Stop record.

    if (key === "sequence") {
      val = orderStop["sequence"];
    } else if (key === "zipCode") {
      const address = orderStop["cityStateZip"] || orderStop["location"];
      const { zip } = mapToCityStateZipObject(
        cleanString(["(", ")", ","], address).split(" ")
      );
      val = zip;
    } else if (key === "state") {
      const address = orderStop["cityStateZip"] || orderStop["location"];
      const { state } = mapToCityStateZipObject(
        cleanString(["(", ")", ","], address).split(" ")
      );
      val = state;
    } else if (key === "city") {
      const address = orderStop["cityStateZip"] || orderStop["location"];
      const { city } = mapToCityStateZipObject(
        cleanString(["(", ")", ","], address).split(" ")
      );
      val = city;
    } else if (key === "scheduledArrival") {
      if (!orderStop["earliestArrival"]) {
        val = undefined;
      } else {
        const d = datetimeUTC(orderStop["earliestArrival"]);
        val = d.isValid() ? d.format("MM/DD/YYYY HH:mm") : undefined;
      }
    }
    // else if(key === 'referenceId') {
    //     val = orderStop['externalId'] ? orderStop['externalId'].trim() : undefined;
    // }
    else if (key === "externalId") {
      val = orderStop["externalId"]
        ? orderStop["externalId"].trim()
        : undefined;
    } else if (key === "moveSequence") {
      val = orderStop["movementSequence"];
    } else if (key === "stopType") {
      const stopTypeMappings = {
        Pickup: "PU",
        PU: "PU",
        "Split Drop": "SD",
        SD: "SD",
        SP: "SP",
        "Split Pickup": "SP",
        Delivery: "SO",
        SO: "SO",
        RP: "RP",
        "Reconsignment Point": "RP",
        "Via Point Authorized": "VA",
        VA: "VA",
        "Via Point": "VP",
        VP: "VP",
        1: "PU",
        2: "RP",
        3: "SO",
        4: "SP",
        5: "SD",
        6: "VA",
        7: "VP",
      };

      val =
        stopTypeMappings[orderStop[key]] ||
        stopTypeMappings[orderStop["stopTypeId"]] ||
        stopTypeMappings[orderStop["stopTypeCode"]];
    } else if (key === "geoFenceRadius") {
      val =
        orderStop["geoFenceRadius"] ||
        orderStop["movementSetGeoFenceRadius"] ||
        5;
    }

    stp[key] = val;
    return stp;
  }, {});

  if (debugModeOn && (debugLevel === "ALL" || debugLevel === "INFO")) {
    console.log(
      `MAPPING STOP TO TNT STOP: [OrderStop|TntStop]`,
      orderStop,
      mapped
    );
  }

  return { ...tntStop, ...mapped };
};
