import { datetimeUTC } from "global-functions"

function tntStopModel({
    modelId = undefined,
    actualArrival = undefined,
    actualDeparture = undefined,
    address = undefined,
    checkin = undefined,
    city = undefined,
    country = undefined,
    referenceId = undefined,
    geoFenceRadius = 5,
    id = 0,
    externalId = undefined,
    isConsignee = undefined,
    isShipper = undefined,
    latitude = undefined,
    longitude = undefined,
    scheduledArrival = new Date(),
    scheduledDeparture = undefined,
    sequence = 1, // stop sequence
    state = undefined,
    stopComment = undefined,
    stopType = undefined,
    carrierTrackingRecordId = undefined,
    zipCode = undefined,
    moveSequence = 1,
    movementId = undefined
} = {}) {
    return {
        modelId,
        actualArrival,
        actualDeparture,
        address,
        checkin,
        city,
        country,
        referenceId,
        geoFenceRadius,
        id,
        externalId,
        isConsignee,
        isShipper,
        latitude,
        longitude,
        scheduledArrival,
        scheduledDeparture,
        sequence,
        state,
        stopComment,
        stopType,
        carrierTrackingRecordId,
        zipCode,
        moveSequence,
        movementId
    }
}

export function getStopValidationErrors({
    scheduledArrival = undefined,
    scheduledDeparture = undefined,
    moveSequence = 1,
    isShipper = false,
    isConsignee = false
} = {}) {
    let errors = {};
    
    let today = datetimeUTC(new Date());
    today = today.hour(0);
    today = today.minute(0);
    today = today.second(0);
    today = today.millisecond(0);

    if(scheduledArrival && isConsignee) {
        const arrival = datetimeUTC(scheduledArrival);
        if(arrival < today) {
            errors.arrivalDate = `Delivery Scheduled Arrival is in the past. Date must be today or later.`
        }
    }

    // if(scheduledDeparture && isConsignee) {
    //     const departure = datetimeUTC(scheduledDeparture);
    //     if(departure < today) {
    //         errors.departureDate = `Delivery Scheduled Departure is in the past. Date must be today or later.`
    //     }
    // }
    
    errors.count = Object.keys(errors).length;

    return errors;
}

export default tntStopModel