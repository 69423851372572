import ko from "knockout";
import dataModel from "data-model";
import * as _ from "lodash";
import {
  createDateTimeFilterWidget,
  escapeHtml,
  isEmpty,
} from "global-functions";
import gridStateUtils from "jqx.grid-utils";
import GridStateComponentViewModel from "jqx.grid-state-component";
import userProfile from "user-profile";
import template from "./drivers-page.html";
import { showmessage } from "show-dialog-methods";
import {
  makePagingExpandable,
  lockPageOnGridScroll,
  buildGrid,
} from "../../../gridtoolbox";
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";

var DriversViewModel = function (params) {
  var self = this;
  self.isLoading = ko.observable(false);
  //The self.isDriverQueryFinishedLoading flag prevents the loading icon from disappearing while the DriverID query is running.
  self.isDriverQueryFinishedLoading = true;
  self.driverDetails = ko.observable();
  var ratingUpdated = false;
  self.columns = "";
  var datafields = "";
  var lastUpdated = Date.now();
  var updateGrid = false;
  var isInitLoad = true;
  var isGridInitialized = false;
  var isClearFilter = false;
  var userHasSavedFilters = false;
  self.driverID = ko.observable(params[0]?.driverid);
  self.grid = $("#driversGrid");
  self.clearSelectedSavedSearchDDL = ko.observable(false);
  self.refreshSavedSearchDDL = ko.observable("");
  let gridInit = false;
  self.handleOnSelected = (state) => {
    if (isEmpty(state.grid) == false) {
      self.driverGridState = state.grid;
      gridStateUtils.applyGridState("driversGrid", state.grid);
    }

    if (gridInit === false) {
      self.init();
    }
  };

  self.refreshGrid = function () {
    $("#driversGrid").jqxGrid("updatebounddata", "data");
  };

  self.getGridColumnsandDatafields = function () {
    return new Promise(function (resolve, reject) {
      dataModel
        .ajaxRequest("Driver/GetGridColumns", "GET")
        .done(function (data, textStatus, jqXHR) {
          self.columns = data.columns;
          self.columns = self.columns.map(function (col) {
            col.width = col.widthPercentage;
            return col;
          });
          datafields = data.datafields;
          resolve();
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          self.isLoading(false);
          reject();
        });
    });
  };

  self.driverGridState = null;

  var formatFiltersForGrid = function () {
    if (isClearFilter) {
      var filtergroup1 = new $.jqx.filter();
      var filterValue = "Active";
      var filter1 = filtergroup1.createfilter(
        "stringfilter",
        filterValue,
        "equal"
      );
      filtergroup1.addfilter(1, filter1);
      self.grid.jqxGrid("addfilter", "status", filtergroup1);
      isClearFilter = false;
      self.grid.jqxGrid("applyfilters");
    } else {
      if (self.driverGridState && self.driverGridState.filters) {
        var filtergroup = undefined;
        var filtervalue = "";
        var filtervalue1 = "";
        var filtercondition = "";
        var filtercondition1 = "";
        var filtertype = "";
        var filterdatafield = "";
        var filteroperator = "";
        filter1 = undefined;
        var filterIndexAccessors = [];

        $.each(self.driverGridState.filters, function (key, value) {
          var lastChar = /.$/.exec(key)[0];
          if (!isNaN(lastChar) && filterIndexAccessors.indexOf(lastChar) == -1)
            filterIndexAccessors.push(lastChar);
        });
        var filters = [];

        var filter = {};
        var operatorKey = "";

        for (var i = 0; i < filterIndexAccessors.length; i++) {
          operatorKey =
            self.driverGridState.filters["filterdatafield" + i] + "operator";
          filter = {
            filtercondition:
              self.driverGridState.filters["filtercondition" + i],
            filterdatafield:
              self.driverGridState.filters["filterdatafield" + i],
            filteroperator: self.driverGridState.filters["filteroperator" + i],
            filtertype: self.driverGridState.filters["filtertype" + i],
            filtervalue: self.driverGridState.filters["filtervalue" + i],
          };
          filter[operatorKey] =
            self.driverGridState.filters[
              self.driverGridState.filters["filterdatafield" + i] + "operator"
            ];
          filters.push(filter);
        }
        var singleValueFilters = filters.filter(function (f, i) {
          return f.filtertype !== "datefilter";
        });

        var dateFilters = filters
          .filter(function (f, i) {
            return f.filtertype == "datefilter";
          })
          .map(function (f, i, arr) {
            if (i % 2 == 0) {
              return {
                beginDate: f,
                endDate: arr[i + 1],
              };
            }
          })
          .filter(function (f) {
            return f !== null;
          });
        for (let i = 0; i < singleValueFilters.length; i++) {
          filtergroup = new $.jqx.filter();
          filtervalue = singleValueFilters[i].filtervalue;
          filtercondition = singleValueFilters[i].filtercondition;
          filtertype = singleValueFilters[i].filtertype;
          filterdatafield = singleValueFilters[i].filterdatafield;
          filteroperator = singleValueFilters[i][filterdatafield + "operator"];
          filter = filtergroup.createfilter(
            filtertype,
            filtervalue,
            filtercondition
          );
          filtergroup.addfilter(filteroperator, filter);
          self.grid.jqxGrid("addfilter", filterdatafield, filtergroup);
        }

        for (let i = 0; i < dateFilters.length; i++) {
          filtergroup = new $.jqx.filter();
          filtervalue = dateFilters[i].beginDate.filtervalue;
          filtervalue1 = dateFilters[i].endDate.filtervalue;
          filtercondition = dateFilters[i].beginDate.filtercondition;
          filtercondition1 = dateFilters[i].endDate.filtercondition;
          filtertype = dateFilters[i].beginDate.filtertype;
          filterdatafield = dateFilters[i].beginDate.filterdatafield;
          filteroperator =
            dateFilters[i].beginDate[filterdatafield + "operator"];
          filter = filtergroup.createfilter(
            filtertype,
            filtervalue,
            filtercondition
          );
          filter1 = filtergroup.createfilter(
            filtertype,
            filtervalue1,
            filtercondition1
          );
          filtergroup.addfilter(filteroperator, filter);
          filtergroup.addfilter(filteroperator, filter1);
          self.grid.jqxGrid("addfilter", filterdatafield, filtergroup);
        }
        //self.grid.jqxGrid('refreshfilterrow');
      }
    }
    // waitForFiltersToFinish = false;
  };

  var getSourceData = function (data) {
    if (self.driverGridState !== null && self.driverGridState["filters"]) {
      for (var i = 0; i < self.driverGridState.filters.filterscount; i++) {
        var rangePosition = "";
        if (self.driverGridState.filters["filtertype" + i] == "datefilter") {
          if (
            self.driverGridState.filters["filterdatafield" + i] ==
            self.driverGridState.filters["filterdatafield" + (i + 1)]
          )
            rangePosition = "Begin";
          else if (
            self.driverGridState.filters["filterdatafield" + i] ==
            self.driverGridState.filters["filterdatafield" + (i - 1)]
          )
            rangePosition = "End";
        }
        data[
          self.driverGridState.filters["filterdatafield" + i] + rangePosition
        ] = self.driverGridState.filters["filtervalue" + i];
      }
    }
    return { data: data, gridState: self.driverGridState };
  };

  self.buildDriversGrid = function () {
    return new Promise(function (resolve, reject) {
      var source = {
        url: "Driver/GetDriversForGrid",
        datatype: "json",
        filter: function (filters, recordsArray) {
          self.isLoading(true);
          self.grid.jqxGrid("updatebounddata", "filter");
        },
        sort: function (column, direction) {
          self.grid.jqxGrid("updatebounddata", "sort");
        },
        formatdata: function (data = {}) {
          self.isLoading(true);
          var filterinfo = self.grid.jqxGrid("getfilterinformation");
          data = gridStateUtils.formatGridFilters(filterinfo, data);

          if (updateGrid) {
            lastUpdated = Date.now();
          }
          if (isInitLoad) {
            var initSourceData = getSourceData(data);
            data = initSourceData.data;
            isInitLoad = false;
          }

          data.lastUpdated = lastUpdated;
          if (!isGridInitialized) {
            data.isInitLoad = true;
          } else {
            data.isInitLoad = false;
          }

          if ("ptafrom" in data) {
            data.pta = data.ptafrom;
          }

          return data;
        },
        datafields: datafields,
      };

      var dataSource = dataModel.getDataAdapter(source);

      const { pagesize } = self.driverGridState || {};
      const gridWithPaging = makePagingExpandable(self.grid, {
        pagesize: pagesize || 20,
      });

      gridWithPaging(
        {
          theme: "GWTMDark",
          width: "100%",
          source: dataSource,
          altrows: true,
          height: screen.height >= 800 ? window.innerHeight - 300 : 400,
          autoshowloadelement: false,
          showstatusbar: true,
          sortable: true,
          pageable: true,
          pagesize: 20,
          filterable: true,
          showfilterrow: true,
          virtualmode: true,
          columnsresize: true,
          columnsreorder: true,
          enablebrowserselection: true,
          columnsmenu: false,
          columns: self.columns,
          statusbarheight: 35,
          showtoolbar: true,
          renderstatusbar: function (statusbar) {
            var div = $("<div>", {
              id: "driverRowCount",
              style: "padding:4px;",
            });
            div.append(
              "Number of Records: " + self.grid.jqxGrid("getrows").length
            );
            statusbar.append(div);
          },
          rendertoolbar: function (toolbar) {
            var vm1 = new GridStateComponentViewModel.viewModel();
            var template = GridStateComponentViewModel.template;

            vm1.clearFilters = function () {
              isClearFilter = true;
              $("#driversGrid").jqxGrid("clearfilters");
              self.clearSelectedSavedSearchDDL(true);
            };
            vm1.setDefaultSearchOverride = async () => {
              const savedSearches = await loadSavedSearches(
                self.grid.attr("id")
              );
              const filters = {
                isDefault: true,
              };

              vm1.loadSaveSearchModal(
                self.grid,
                (val) => {
                  if (val && val.searchname) {
                    self.refreshSavedSearchDDL(val.searchname);
                  }
                },
                savedSearches
                  .filter((x) => x.searchName)
                  .map((x) => ({ id: x.id, text: x.searchName })),
                filters,
                true
              );
            };

            vm1.actions.push("Save Search");

            vm1.loadSaveSearchModalOverride = async () => {
              const savedSearches = await loadSavedSearches(
                self.grid.attr("id")
              );
              const filters = {
                isDefault: false,
              };

              vm1.loadSaveSearchModal(
                self.grid,
                (val) => {
                  if (val && val.searchname) {
                    self.refreshSavedSearchDDL(val.searchname);
                  }
                },
                savedSearches
                  .filter((x) => x.searchName)
                  .map((x) => ({ id: x.id, text: x.searchName })),
                filters,
                true
              );
            };

            vm1.refresh = function () {
              updateGrid = true;
              self.grid.jqxGrid("updatebounddata", "data");
            };

            var driversGridToolbar = $("#driversGridToolbar");
            toolbar.append(driversGridToolbar);

            var tdGridAction = driversGridToolbar.find(
              "#driversGridActionList"
            );
            tdGridAction.append(template);

            ko.applyBindingsToDescendants(vm1, tdGridAction[0]);
          },
          rendergridrows: function (obj) {
            return obj.data;
          },
          ready: function () {
            //formatFiltersForGrid();
            if (self.driverGridState != null) {
              gridStateUtils.applyGridState(
                "driversGrid",
                self.driverGridState
              );
            }

            gridInit = true;
          },
        },
        [buildGrid, lockPageOnGridScroll]
      );

      self.grid.on("bindingcomplete", function (event) {
        var records = self.grid.jqxGrid("getrows");
        $("#driverRowCount").text("Number of Records: " + records.length);
        if (!isInitLoad) {
          if (updateGrid) {
            updateGrid = false;
          }
          if (ratingUpdated) {
            ratingUpdated = false;
            $("#divDriverPanel").notify("Rating Updated", {
              position: "top right",
              className: "info",
            });
          }
        }
        if (self.isDriverQueryFinishedLoading) {
          self.isLoading(false);
        }
        resolve();
      });

      self.grid.on("filter", function (event) {
        let records = $("#driversGrid").jqxGrid("getrows");
        $("#driverRowCount").text("Number of Records: " + records.length);
      });
    });
  }; //self.buildDriversGrid();

  var driverDetailsGridColumn = function () {
    return {
      text: "",
      filterable: false,
      pinned: true,
      sortable: false,
      menu: false,
      width: 100,
      columnType: "button",
      buttonclick: function (row) {
        var datarow = self.grid.jqxGrid("getrowdata", row);
        self.driverID(datarow.driverCode);
      },
      cellsrenderer: function () {
        return "View Details";
      },
    };
  };

  self.formatDriverGridColumns = function () {
    return new Promise(function (resolve, reject) {
      self.columns.unshift(driverDetailsGridColumn());
      for (var i = 0; i < self.columns.length; i++) {
        if (self.columns[i].filtertype == "date") {
          self.columns[i].createfilterwidget = createDateTimeFilterWidget;
        } else if (self.columns[i].datafield == "rating") {
          self.columns[i].cellsrenderer = function (
            row,
            columnfield,
            value,
            defaultHTML,
            column,
            rowData
          ) {
            //refer to carriersnew.js for notation on rating function
            if (defaultHTML != null) {
              let pk = self.grid.jqxGrid("getcellvalue", row, "id");
              var fieldset = $("<fieldset>");
              fieldset.addClass("star-rating");
              fieldset.attr("id", "jqxRating_" + pk);

              for (var i = 5; i > 0; i--) {
                var id = "jqxRating_" + pk + i;
                var input = $("<input>");
                input.attr({
                  type: "radio",
                  id: "star" + i,
                  name: "rating",
                  value: i,
                });
                input.appendTo(fieldset);
                var label = $("<label>").text(i);
                if (value == i) {
                  label.attr({ for: "star" + i });
                }
                label.insertAfter(input);
                $(document)
                  .off("click", "#jqxRating_" + pk)
                  .on("click", "#jqxRating_" + pk, function (event) {
                    var newRating;
                    var previousRating;
                    if (event.target.tagName == "LABEL") {
                      newRating = event.target.previousSibling.value;
                      $(event.target.parentElement.children).each(function (i) {
                        if (this.tagName == "LABEL") {
                          if (this.attributes.length > 0) {
                            if (this.attributes.for.value != null) {
                              previousRating =
                                this.attributes.for.value.slice(-1);
                            }
                            if (previousRating != newRating) {
                              $(this).removeAttr("for");
                            }
                          }
                        }
                      });
                      if (previousRating != newRating) {
                        var inputId =
                          event.target.previousSibling.attributes.getNamedItem(
                            "id"
                          ).value;
                        $(event.target).attr("for", inputId);
                        var id = event.target.parentElement.id.replace(
                          "jqxRating_",
                          ""
                        );
                        var params = {
                          objectId: id,
                          value: newRating,
                          agencyId: dataModel.currentAgencyId(),
                          favTable: 16,
                        };
                        dataModel
                          .ajaxRequest("Rating", "post", params)
                          .done(function (data, textStatus, jqXHR) {
                            ratingUpdated = true;
                            self.refreshGrid();
                            self.grid.jqxGrid("clearselection");
                          })
                          .fail(function (error, msg, d) {
                            self.isLoading(false);
                          });
                      }
                    }
                  });
              }
              return fieldset[0].outerHTML;
            }
            return defaultHTML;
          };
          self.columns[i].width = 140;
          self.columns[i].filtertype = "list";
          self.columns[i].filteritems = [
            {
              value: 5,
              label: "5",
              html: "<img src='../Content/Images/5star.png' />",
            },
            {
              value: 4,
              label: "4",
              html: "<img src='../Content/Images/4star.png' />",
            },
            {
              value: 3,
              label: "3",
              html: "<img src='../Content/Images/3star.png' />",
            },
            {
              value: 2,
              label: "2",
              html: "<img src='../Content/Images/2star.png' />",
            },
            {
              value: 1,
              label: "1",
              html: "<img src='../Content/Images/1star.png' />",
            },
          ];
          self.columns[i].createfilterwidget = function (
            column,
            columnElement,
            widget
          ) {
            widget.jqxDropDownList({
              dropDownWidth: 140,
              enableBrowserBoundsDetection: true,
              renderer: function (index, label, value) {
                if (value != "") {
                  return (
                    "<img src='../Content/Images/" + value + "star.png' />"
                  );
                } else {
                  return "Any";
                }
              },
            });
          };
        } else if (self.columns[i].datafield == "csaTrend") {
          self.columns[i].cellsalign = "center";
          self.columns[i].cellsrenderer = function (
            row,
            columnfield,
            value,
            defaultHTML,
            column,
            rowData
          ) {
            if (defaultHTML != null) {
              var cell = $(defaultHTML);
              if (value == "E") {
                cell.html("<img src='../Content/images/doubleblue.png' />");
              } else if (value == "U") {
                cell.html("<img src='../Content/images/redarrowup.png' />");
              } else if (value == "D") {
                cell.html("<img src='../Content/images/greendown.png' />");
              }
              return cell[0].outerHTML;
            }
            return defaultHTML;
          };
        } else if (self.columns[i].datafield == "operationalComment") {
          self.columns[i].cellsrenderer = function (
            rrow,
            columnfield,
            value,
            defaultHTML,
            column,
            rowData
          ) {
            if (defaultHTML != null) {
              var cell = $(defaultHTML);
              cell.html(escapeHtml(value));
              return cell[0].outerHTML;
            }
            return defaultHTML;
          };
        }
      }
      //Column ordering
      if (self.driverGridState) {
        for (i = 0; i < self.columns.length; i++) {
          if (self.driverGridState.columns[self.columns[i].datafield]) {
            self.columns[i].index =
              self.driverGridState.columns[self.columns[i].datafield].index;
          }
        }
        self.columns.sort(function (a, b) {
          return a.index - b.index;
        });
      }
      resolve();
    });
  };

  self.loadPageForSingleDriverQuery = function () {
    self.driverGridState = self.driverGridState || {};
    self.driverGridState.filters = self.driverGridState.filters || {};
    self.driverGridState.filters = {
      driverCodeoperator: "and",
      filtercondition0: "CONTAINS",
      filterdatafield0: "driverCode",
      filteroperator0: "and",
      filterscount: 1,
      filtertype0: "stringfilter",
      filtervalue0: self.driverID(),
    };
    self.isDriverQueryFinishedLoading = true;
  };

  self.init = () => {
    Promise.all([self.getGridColumnsandDatafields()]).then(function () {
      self.formatDriverGridColumns().then(function () {
        if (self.driverID()) {
          self.isDriverQueryFinishedLoading = false;
          self.loadPageForSingleDriverQuery();
        }

        self.buildDriversGrid();
      });
    });
  };
};

export default { viewModel: DriversViewModel, template: template };
