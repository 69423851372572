import userProfile from "user-profile";
import dataModel from "data-model";
import template from "./order-entry-duplicate-order-component.html";
import { useDispatch } from "ko-data-store";
import { isLoading } from "../../../../dataStore/actions/appUI";
class DuplicateOrder {
  constructor(params) {
    const dispatch = useDispatch();
    this.showModal = ko.observable(false);
    this.isLoading = ko.observable(false);
    this.id = params.orderId;
    this.numOfOrders = ko.observable(1);
    this.result = ko.observable();
    this.responseMessage = ko.observable();
    this.isConfirm = ko.observable(false);
    this.cherryPickFieldsViewModel = ko.observable();
    this.isBrokered = params.isBrokered;
    this.noBroker = params.noBroker;
    this.customField1Label = params.customField1Label;
    this.customField2Label = params.customField2Label;
    this.customField3Label = params.customField3Label;

    this.showCherryPickFields = () => {
      this.cherryPickFieldsViewModel(
        new CherryPickFieldsModel(this.cherryPickUserSettings)
      );
    };
    this.saveCherryPickSettings = ko.observable(false);
    this.checkCredit = () => {
      this.responseMessage("");
      var data = {
        id: this.id,
        numOfOrders: this.numOfOrders(),
      };

      dispatch(isLoading(true));
      dataModel
        .ajaxRequest("order/GetOrderDuplicateCredit", "get", data)
        .done((data, textStatus, jqXHR) => {
          if (data == "") {
            this.duplicateOrder();
          } else {
            this.isConfirm(
              data && data.indexOf("Orders cannot be created.") === -1
            );
            this.responseMessage(data);
            dispatch(isLoading(false));
          }
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          alert("unknown error");
          dispatch(isLoading(false));
        });
    };

    this.duplicateOrder = () => {
      if (this.cherryPickFieldsViewModel()) {
        var cherryPickedFields = this.cherryPickFieldsViewModel()
          .sections()
          .map((value) => {
            var section = value.fields.map((value2) => {
              if (value2.observable() == true) {
                return value2.label;
              }
            });
            section = section.filter((value3) => {
              return value3 != undefined;
            });
            return section;
          });
        cherryPickedFields = [].concat.apply([], cherryPickedFields);
      }
      var data = {
        id: this.id,
        numOfOrders: this.numOfOrders(),
        cherryPickedFields: cherryPickedFields,
      };

      dispatch(isLoading(true));

      dataModel
        .ajaxRequest("order/DuplicateOrder", "POST", data)
        .done((data, textStatus, jqXHR) => {
          this.result(data);
          dispatch(isLoading(false));
          if (this.saveCherryPickSettings()) {
            this.saveCherryPickUserSettings();
          }
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          if (jqXHR.responseJSON.message == "Agent Risk Blocked") {
            alert(
              "You cannot duplicate orders at agent risk.  Please contact your agency manager with the order to be duplicated."
            );
          } else {
            alert("Unable to duplicate order");
          }

          dispatch(isLoading(false));
        });
    };

    this.saveCherryPickUserSettings = () => {
      var savedSetting = {};
      this.cherryPickFieldsViewModel()
        .sections()
        .forEach(function (value) {
          savedSetting[value.sectionName] = {};
          value.fields.forEach(function (value2) {
            savedSetting[value.sectionName][value2.label] = {};
            savedSetting[value.sectionName][value2.label].observable =
              value2.observable();
          });
        });
      userProfile.saveUserSavedSettings(
        "NewOrder",
        "OrderDuplicationSettings",
        savedSetting
      );
    };

    this.cherryPickUserSettings = {};
    userProfile
      .getUserSavedSettings("NewOrder", "OrderDuplicationSettings")
      .then((value) => {
        this.cherryPickUserSettings = value;
        $("#duplicateOrderModal").modal("show");
      });
  }
}

class CherryPickFieldsModel {
  constructor(savedSettings) {
    var generalFields = [
      "Trailer Type",
      "Preload",
      "LTL",
      "Tanker Endorsement Req.",
      "Hazmat Routing",
      "Trailer Number",
      "BOL",
      "Customer Reference",
      "UN Number",
      "Salesperson",
      "Salesperson Rate",
      "Ordered By",
      "Operations User",
      "Custom Field 1",
      "Custom Field 2",
      "Custom Field 3",
      "High Value Check",
      "High Value Value",
    ].map(function (value, index, array) {
      return {
        label: value,
        observable: ko.observable(
          savedSettings
            ? savedSettings["General"]
              ? savedSettings["General"][value]
                ? savedSettings["General"][value].observable
                : true
              : true
            : true
        ),
      };
    });

    var stopFields = [
      "Contact",
      "Phone",
      "Stop.Pieces",
      "Stop.Weight",
      "Stop.Pallets",
      "Driver Load",
      "Length",
      "Height",
      "Width",
      "Posting Dimensions",
      "Stop Notes",
      "Reference Numbers",
      "Appointment Required",
      "Appointment Confirmed",
    ].map(function (value, index, array) {
      return {
        label: value,
        observable: ko.observable(
          savedSettings
            ? savedSettings["Stops"]
              ? savedSettings["Stops"][value]
                ? savedSettings["Stops"][value].observable
                : true
              : true
            : true
        ),
      };
    });

    var containerFields = [
      "Container",
      "Chassis",
      "Booking Number",
      "Pickup Number",
      "Cust. Order #",
      "Seal Number",
      "Load Port",
      "Port of Delivery",
      "TIR 1 Number",
      "TIR 2 Number",
      "Steamship Line",
      "Cutoff Date",
      "Last Free Date",
      "Vessel",
      "Forwarder",
      "Container Release Date",
      "Pickup Driver",
      "Pickup Date",
      "Pickup Pay",
      "Delivery Driver",
      "Delivery Date",
      "Delivery Pay",
    ].map(function (value, index, array) {
      return {
        label: value,
        observable: ko.observable(
          savedSettings
            ? savedSettings["Container"]
              ? savedSettings["Container"][value]
                ? savedSettings["Container"][value].observable
                : true
              : true
            : true
        ),
      };
    });

    var billingFields = [
      "Units Description",
      "Billing.Pieces",
      "Billing.Weight",
    ].map(function (value, index, array) {
      return {
        label: value,
        observable: ko.observable(
          savedSettings
            ? savedSettings["Billing"]
              ? savedSettings["Billing"][value]
                ? savedSettings["Billing"][value].observable
                : true
              : true
            : true
        ),
      };
    });

    var sections = [
      { sectionName: "General", fields: generalFields },
      { sectionName: "Stops", fields: stopFields },
      { sectionName: "Container", fields: containerFields },
      { sectionName: "Billing", fields: billingFields },
    ];
    this.sections = ko.observable(sections);
  }
}

export default {
  viewModel: {
    createViewModel: function (params, componentInfo) {
      return new DuplicateOrder(params.stuff);
    },
  },
  template: template,
};
