import {
  ILoadTrackingResponse,
  ITntApiResponse,
  ValueObject,
} from "./models/types";
import { Dayjs } from "dayjs";
import merge from "deepmerge";
import {
  TrackingModel,
  StopModel,
  ITrackingModel,
  ILoadComment,
  ITrackingStop,
  LoadCommentModel,
  ILastLocation,
} from "./models";
import LastLocationModel from "./models/LastLocationModel";
import { getTrackingModelValidationErrors } from "./models/TrackingModel";
import * as apiService from "./services/apiService";
import TrackingBrokerageAssignmentModel from "./models/TrackingBrokerageAssignment";
import toast from "geNotyf";
import TrackingTrucklineAssignmentModel from "./models/TrackingTrucklineAssignment";
import { useDispatch } from "ko-data-store";
import { isLoading } from "../../../../dataStore/actions/appUI";
import {showmessage} from "show-dialog-methods";
export const TRACKINGSTATUS_ENUM = {
  Pending: 1,
  Activated: 2,
  Cancelled: 3,
};

export const MOVEMENTSTATUS_ENUM = {
  NotAssigned: "NOT_ASSIGNED",
  Available: "Available",
  Covered: "Covered",
  Delivered: "Delivered",
  InProgress: "In Progress",
  Voided: "Void",
};

// getUserRoles().some(
//   (x) => x.toUpperCase() === "ADMINISTRATORS"
// )

interface UpdateSliceProps {
  sliceKey: string;
  payload: any;
}

export interface IStopCommentState {
  comment: string;
  uid: string;
}

export interface ITrackTraceOrderEntryContextState {
  orderExId?: string;
  manualTrackingOn?: boolean;
  currentMovementId?: number;
  defaultProviderId?: number;
  customerMappedVendorId?: number;
  orderDeliveryDate?: Dayjs | Date | string;
  userCanEditVendor: boolean;
  isBrokered: boolean;
  defaultStartTracking?: Dayjs | Date | string;
  showDriverPhoneModal?: boolean
  //doStartTrackingSave; boolean;  // determines if doing an update or start tracking api call. Triggered by the 'start tracking' btn.
  //waitStartTrackingSave: boolean; // prevent start tracking no matter what when true.
  //trackingNeedsRefreshed: boolean; // if more than one user is touching the order with tracking, an api failure will return, so when this is true, it displays a message to refesh
  trackedMovements: ITrackTraceMovementState[];
  stopComments: IStopCommentState[];
}

export interface ITrackTraceMovementState {
  movementExId: string; // SORTrackingId
  movementId: number;
  movementStatus: string;
  movementSequence: number;
  movementGeoFenceRadius: number; // min 1, max 5 -> prop applies to all movement stops - default is 2
  tracking: ITrackingModel;
  loadComments: ILoadComment;
  stops: ITrackingStop[];
  getValidationErrorObj: (
    data: ITrackingModel,
    isBrokered: boolean
  ) => ValueObject;
  // infoMsg: undefined;
  // isLoading: false;
  lastLocation: ILastLocation;
  errorMsg: string;
}

function TrackTraceMovementState() {
  return {
    movementId: -1,
    movementExId: "PLACEHOLDER",
    movementStatus: MOVEMENTSTATUS_ENUM.NotAssigned,
    movementSequence: 1,
    movementGeoFenceRadius: 2,
    tracking: TrackingModel(),
    loadComments: LoadCommentModel(),
    lastLocation: LastLocationModel(),
    stops: [] as ITrackingStop[],
    getValidationErrorObj: (data: ITrackingModel, isBrokered: boolean) =>
      getTrackingModelValidationErrors(data, isBrokered) as ValueObject,
    errorMsg: "",
  };
}

const initState: ITrackTraceOrderEntryContextState = {
  orderExId: "",
  manualTrackingOn: false,
  currentMovementId: undefined,
  defaultProviderId: undefined,
  customerMappedVendorId: undefined,
  orderDeliveryDate: undefined,
  userCanEditVendor: false,
  isBrokered: false,
  defaultStartTracking: undefined,
  trackedMovements: [TrackTraceMovementState()],
  stopComments: [],
  showDriverPhoneModal: false
};

function TrackTraceOrderEntryContext() {
  const dispatch = useDispatch();
  const _trackingState = ko
    .observable<ITrackTraceOrderEntryContextState>(initState)
    .extend({ notify: "always" });
  const turnOnLogging = () => isDebugMode();
  _trackingState.subscribe((state) => logToConsole(`Current State: `, state));

  const isTrackingOn = ko.pureComputed(() => {
    const state = ko.toJS(_trackingState);

    if (canShowManualTrackingOption() === false) {
      return false;
    }

    if (isTrackingRequired() || hasActiveTracking()) {
      return true;
    }

    return (
      state.manualTrackingOn ||
      getOrderMovements().some((x) => {
        if (x.tracking.trackingStatusId === TRACKINGSTATUS_ENUM.Pending) {
          return true;
        }

        return false;
      })
    );
  });

  const hasActiveTracking = ko.pureComputed(() => {
    const orderMovements = getOrderMovements();

    return orderMovements.some((x) => {
      if (x.tracking) {
        return x.tracking.trackingStatusId === TRACKINGSTATUS_ENUM.Activated;
      }

      return false;
    });
  });

  const canShowManualTrackingOption = ko.pureComputed(() => {
    const orderMovements = getOrderMovements();

    if (hasActiveTracking()) {
      return true;
    }

    return (
      isAssetAssigned() &&
      orderMovements.some(
        (x) =>
          x.movementStatus === MOVEMENTSTATUS_ENUM.Available ||
          x.movementStatus === MOVEMENTSTATUS_ENUM.InProgress ||
          x.movementStatus === MOVEMENTSTATUS_ENUM.Covered
      )
    );
  });

  const isAssetAssigned = ko.pureComputed(() => {
    const state = ko.toJS(_trackingState);
    const currentTracking = currentMoveTracking();

    if (!currentTracking) {
      return false;
    }

    if (state.isBrokered) {
      return (
        currentTracking.tracking.carrierAssignment != null &&
        currentTracking.tracking.carrierAssignment.carrierMC != null
      );
    }

    return (
      currentTracking.tracking.trucklineAssignment != null &&
      currentTracking.tracking.trucklineAssignment.assignedTractor != null
    );
  });

  const currentMoveTracking = ko.pureComputed(() => {
    const state = ko.toJS(_trackingState);
    const moves = getOrderMovements();

    return (
      moves.find((x) => x.movementId === state.currentMovementId) ?? moves[0]
    );
  });

  const isTrackingRequired = ko.pureComputed(() => {
    const state = ko.toJS(_trackingState);

    return state.defaultProviderId > 0;
  });

  const getOrderMovements = (): ITrackTraceMovementState[] => {
    const state = ko.toJS(_trackingState);

    return [...state.trackedMovements]
      .filter((x) => x.tracking.orderId === state.orderExId)
      .sort((a, b) => a.movementSequence - b.movementSequence);
  };

  const resetState = () => {
    console.log(`Resetting Tracking Data`);

    _trackingState(initState);
  };

  // TODO: Refactor this to not use ':' slice delimiters.
  // Make it easier to understand and read what slice we are updating. Maybe add some type interfaces?
  // Updates a object key 'slice' in state. When ':' is used as the key, then
  // the first part is selected and the second part is updated. For deep nested objects.
  const updateSlice = (value: UpdateSliceProps | UpdateSliceProps[]) => {
    const executeUpdate = (data: UpdateSliceProps) => {
      const state = ko.toJS(_trackingState);

      logToConsole(
        `Updating Slice [${data.sliceKey}] (Payload): `,
        data.payload
      );

      if (data.sliceKey.indexOf(":") > -1) {
        const parts = data.sliceKey.split(":");

        if (state[parts[0]]) {
          if (Array.isArray(state[parts[0]])) {
            const mapped = state[parts[0]].map((x) => {
              if (x[parts[1]]) {
                x[parts[1]] = { ...x[parts[1]], ...data.payload };
              }

              return x;
            });
            _trackingState({ ...state, [parts[0]]: mapped });
          }
        }
      } else if (data.sliceKey in state) {
        _trackingState({ ...state, [data.sliceKey]: data.payload });
      }
    };

    if (Array.isArray(value)) {
      value.forEach(executeUpdate);
    } else {
      executeUpdate(value);
    }
  };

  const selectSlice = (key: string) => {
    const state = ko.toJS(_trackingState);

    if (key && key.indexOf(":") > -1) {
      const parts = key.split(":");
      const slice = state[parts[0]];

      return slice[parts[1]];
    }

    return state[key];
  };

  const upsertTrackedMovement = (
    movementExId: string,
    payload: ValueObject
  ) => {
    const state = ko.toJS(_trackingState);

    let trackedMove = state.trackedMovements.find(
      (x) => x.movementExId === "PLACEHOLDER" || x.movementExId === movementExId
    );

    if (trackedMove == null) {
      trackedMove = TrackTraceMovementState();
    }

    trackedMove.movementExId = movementExId;
    trackedMove.tracking.sorTrackingId = movementExId;

    const merged = merge(trackedMove, payload) as ITrackTraceMovementState;

    _trackingState({
      ...state,
      trackedMovements: [
        merged,
        ...state.trackedMovements.filter(
          (x) =>
            x.movementExId !== trackedMove.movementExId &&
            x.movementExId !== "PLACEHOLDER"
        ),
      ],
    });
  };

  const selectTrackedMovement = (movementExId: string) =>
    ko.pureComputed(() => {
      const state = ko.toJS(_trackingState);
      const move =
        state.trackedMovements.find((x) => x.movementExId === movementExId) ??
        TrackTraceMovementState();

      move.movementExId = movementExId;

      return move;
    });

  const removeTrackedMovement = (movementExId: string) => {
    const state = ko.toJS(_trackingState);

    const moves = state.trackedMovements.filter(
      (x) => x.movementExId !== movementExId
    );

    _trackingState({ ...state, trackedMovements: moves });
  };

  const updateTrackedMovementStatus = (
    movementExId: string,
    movementStatus: string = "Available"
  ) => {
    const move = selectTrackedMovement(movementExId);

    if (
      movementStatus === "Delivered" &&
      move().tracking.trackingStatusId &&
      move().tracking.trackingStatusId !== TRACKINGSTATUS_ENUM.Cancelled
    ) {
      cancelMovementTracking(movementExId);
    }

    upsertTrackedMovement(movementExId, {
      movementStatus,
    });
  };

  const logToConsole = (msg: any, args: any | any[] = null) => {
    if (!msg || !turnOnLogging()) {
      return;
    }

    if (args) {
      console.log(msg, args);
    } else {
      console.log(msg);
    }
  };

  const isDebugMode = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const debugTracking = urlParams.get("debugTracking");

    if (debugTracking && debugTracking == "true") {
      return true;
    }

    return false;
  };

  const cancelAllTracking = async () => {
    try {
      const moves = getOrderMovements();

      const sorTrackingIds = moves.map(
        (x) => x.tracking.sorTrackingId ?? x.movementExId
      );
      dispatch(isLoading(true));
      const { result } = (await apiService.sendCancelTracking(
        sorTrackingIds
      )) as { result: {} };
      dispatch(isLoading(false));
      updateSlice([
        {
          sliceKey: "trackedMovements:tracking",
          payload: {
            trackingStatusId: TRACKINGSTATUS_ENUM.Cancelled,
          },
        },
        {
          sliceKey: "trackedMovements:tracking",
          payload: {
            driver1Phone: "",
          },
        },
        {
          sliceKey: "manualTrackingOn",
          payload: false,
        },
      ]);

      if (Object.keys(result).length) {
        toast.open({ type: "info", message: `Tracking Cancelled` });
      }
    } catch (err) {
      dispatch(isLoading(false));
      console.error(err);
    }
  };

  const cancelMovementTracking = async (movementExId: string) => {
    try {
      await apiService.sendCancelTracking([movementExId]);

      const move = selectTrackedMovement(movementExId);
      // If we have move in state, then update it.
      // (Truckline movement cauld have a tracked movement, that is not part of this order's movements. - We need to handle cancel dispatches / clear assignments for those)
      if (
        move() &&
        move().tracking.trackingStatusId &&
        move().tracking.trackingStatusId !== TRACKINGSTATUS_ENUM.Cancelled
      ) {
        dispatch(isLoading(true));
        upsertTrackedMovement(movementExId, {
          tracking: {
            trackingStatusId: TRACKINGSTATUS_ENUM.Cancelled,
            driver1Phone: "",
            driver1Name: ""
          },
        });
        dispatch(isLoading(false));
        toast.open({
          type: "info",
          message: `Tracking turned off for movement ${movementExId}`,
        });
      }

      if (hasActiveTracking() === false) {
        updateSlice({ sliceKey: "manualTrackingOn", payload: false });
      }
    } catch (err) {
      dispatch(isLoading(false));
      console.error(err);
      upsertTrackedMovement(movementExId, {
        errorMsg: err,
      });
    }
  };

  const saveTracking = async () => {
    try {
      const state = ko.toJS(_trackingState);
      const moves = getOrderMovements();

      const mapped = moves.map((move) => ({
        tracking: move.tracking,
        loadComments: move.loadComments,
        stops: move.stops,
        isBrokered: state.isBrokered,
      }));

      await apiService.sendSaveTrackings(mapped);
    } catch (err) {
      if(typeof err === 'string') {
        showmessage(err);
      }

      console.error(err);
    }
  };

  const loadTrackingForMovement = async (
    movementExId: string,
    syncVals: ValueObject = {}
  ) => {
    try {
      if (movementExId == null)  {
        return;
      }

      const result = (await apiService.sendSearchTracking(
        movementExId
      )) as ILoadTrackingResponse;

      if (result != null) {
        const cAssignment = TrackingBrokerageAssignmentModel(
          result.trackingRecord
        );

        const tAssignment = TrackingTrucklineAssignmentModel(result.trackingRecord);

        const tracking = TrackingModel({
          ...result.trackingRecord,
          carrierAssignment: {...cAssignment, ...syncVals.carrierAssignment},
          trucklineAssignment: {...tAssignment, ...syncVals.trucklineAssignment }
        });

        const loadComments = LoadCommentModel({ ...result.loadComments });
        const stops = (result.stops ?? []).map((x) => StopModel(x));
        const lastLocation = LastLocationModel({ ...result.lastLocation });

        const geoRadius = stops[0]?.geoFenceRadius ?? 2;

        const data = merge(
          {
            tracking,
            loadComments,
            stops,
            lastLocation,
            movementGeoFenceRadius: geoRadius,
          },
          syncVals
        );

        upsertTrackedMovement(movementExId, data);

        const stopComments = stops.map((x) => ({
          uid: x.externalId,
          comment: x.stopComment,
        }));

        updateSlice([{ sliceKey: "stopComments", payload: stopComments }]);
      } else {
        upsertTrackedMovement(movementExId, syncVals);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const loadTracking = async () => {
    const moves = getOrderMovements();

    if (moves && moves.length) {
      for (const move of moves) {
        await loadTrackingForMovement(move.movementExId, {carrierAssignment: move.tracking.carrierAssignment, trucklineAssignment: move.tracking.trucklineAssignment});
      }
    }
  };

  const refreshLastLocation = async (movementExId: string) => {
    try {
      dispatch(isLoading(true));
      const result = (await apiService.fetchLastLocationAsync(
        movementExId
      )) as ValueObject;
      dispatch(isLoading(false));
      if (result && result.lastLocation) {
        const model = LastLocationModel();
        model.status = result.lastLocation.status;

        upsertTrackedMovement(movementExId, {
          tracking: {
            lastLocation: model,
          },
        });
      }
    } catch (err) {
      dispatch(isLoading(false));
      console.error(err);
      upsertTrackedMovement(movementExId, {
        errorMsg: err,
      });
    }
  };

  const isMovementTracking = (movementExId: string) => {
    const move = selectTrackedMovement(movementExId);

    if (move()) {
      return move().tracking.trackingStatusId !== TRACKINGSTATUS_ENUM.Cancelled;
    }

    return false;
  };

  const startMovementTracking = async (movementExId: string) => {
    try {
      const state = ko.toJS(_trackingState);
      const track = ko.toJS(selectTrackedMovement(movementExId));

      if (
        track.movementStatus === MOVEMENTSTATUS_ENUM.Delivered ||
        track.movementStatus === MOVEMENTSTATUS_ENUM.Voided
      ) {
        return;
      }

      if (track.tracking.byPassTracking) {
        await setByPassTrackingForMovement(
          movementExId,
          track.tracking.byPassTracking
        );
        return;
      }

      if (track.getValidationErrorObj(track.tracking, state.isBrokered).count) {
        toast.error({
          message: "Please correct tracking errors before starting track.",
          duration: 8000,
        });
        return;
      }

      dispatch(isLoading(true));
      const response = (await apiService.sendTracking(
        movementExId,
        state.isBrokered,
        track?.tracking?.trucklineAssignment.hasELD
      )) as ITntApiResponse;
      dispatch(isLoading(false));
      if (response.hasError) {
        upsertTrackedMovement(movementExId, {
          errorMsg: response.errors[0],
        });
      } else {
        const isSync =
          track.tracking.trackingStatusId === TRACKINGSTATUS_ENUM.Activated;
        toast.open({
          type: "info",
          message: `Tracking for movement ${movementExId} ${
            isSync ? "synced" : "started"
          }.`,
        });

        upsertTrackedMovement(movementExId, {
          errorMsg: "",
          tracking: {
            trackingStatusId: TRACKINGSTATUS_ENUM.Activated,
            trackingId: response.result.trackingId,
          },
        });
      }
    } catch (err) {
      dispatch(isLoading(false));
      console.error(err);
      upsertTrackedMovement(movementExId, {
        errorMsg: err,
      });
    }
  };

  const syncMovementTracking = async (movementExId: string) => await startMovementTracking(movementExId);

  const setByPassTrackingForMovement = async (
    movementExId: string,
    byPass: boolean
  ) => {
    try {
      const track = ko.toJS(selectTrackedMovement(movementExId));
      dispatch(isLoading(true));
      await apiService.sendByPassTrackingAsync({
        movementExId,
        byPassTracking: byPass,
      });
      dispatch(isLoading(false));
      if (track.tracking.trackingStatusId === TRACKINGSTATUS_ENUM.Activated) {
        await cancelMovementTracking(movementExId);
      }
    } catch (err) {
      dispatch(isLoading(false));
      console.error(err);
    }
  };

  return {
    getState: ko.pureComputed(() => {
      const state = ko.toJS(_trackingState);
      return { ...state }; // MAKE IMMUTABLE
    }),
    resetState,
    upsertTrackedMovement,
    selectTrackedMovement,
    removeTrackedMovement,
    updateSlice,
    selectSlice,
    saveTracking,
    cancelMovementTracking,
    refreshLastLocation,
    loadTrackingForMovement,
    cancelAllTracking,
    isMovementTracking,
    updateTrackedMovementStatus,
    getOrderMovements,
    startMovementTracking,
    setByPassTrackingForMovement,
    loadTracking,
    syncMovementTracking,
    currentMoveTracking,
    hasActiveTracking,
    canShowManualTrackingOption,
    isTrackingRequired,
    isTrackingOn,
    isAssetAssigned,
  };
}

const instance = TrackTraceOrderEntryContext();

export default instance;
