import hookFns from "./hookFns";

const defaultOptions = {
    //theme: 'GWTMDark',
    width: '100%',
    altrows: true,
    sortable: true,
    pagesize: 20,
    filterable: true,
    height: window.screen.width > 767 ? '700px' : '400px',
    showfilterrow: true,
    virtualmode: true,
    columnsresize: true,
    columnsreorder: true,
    enablebrowserselection: true,
    columnsmenu: false,
    columns: [],
    showtoolbar: true,
    autoshowfiltericon: true,
    rendertoolbar: function (toolbar) {},
    rendergridrows: function (obj) {
        return obj.data;
    },
    ready: function () {}
};

export default function buildGrid({$grid = $(), options = {}} = {}) {
    
    $grid.jqxGrid({...defaultOptions, ...options});

    return (hooks = []) => {
        hookFns({$grid})(hooks);
        return {$grid};
    }

}