import ko from 'knockout';
import $ from "jquery";
import validation from 'knockout.validation'
//Only importing what we need from jqwidgets and only importing in this file as it's doubling the size
//of our vendors bundle.
// import * as jqx from 'jqwidgets-scripts';
import {JQXLite} from 'jqwidgets-scripts';
import 'jquery-ui/ui/widgets/dialog';
import 'jquery-ui/ui/widgets/accordion';
import 'knockout.validation';
import {isDeviceMobileOrTablet, mobileAndTabletcheck} from './home/shared-components/global-functions';
import dayjs from 'dayjs';
//import dayjsPluginUTC from 'dayjs-plugin-utc'
import dayjsPluginUTC from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(dayjsPluginUTC)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter);
import './ko.webpack-component-loader.ts';

class JQueryWidgetsInit {
    constructor() {
        'use strict';
        var root = (typeof window === "object" && window) || this;
        root.ko = ko;
        $.jqx.theme = 'GreatWide';
        $.jqx.credits = "75CE8878-FCD1-4EC7-9249-BA0F153A5DE8";
        // The following in this block is a mobile/tablet only hack for the
        // built-in jqx filter widgets the grids have. This will apply to any grid this
        // file gets loaded with.
        if (isDeviceMobileOrTablet()) {
            $('body').on('bindingComplete focus', 'div[id*="jqxWidget"][role="combobox"]', function (e) {
                var parent = $(this);
                parent.jqxDropDownList({ animationType: 'none' }); // slide doesn't work properly on mobile so set to none
    
                $(this).blur();
                var comboboxList = $('#' + parent.attr('aria-owns')); // This is the property jq uses to assign which calendar picker belongs to the input
    
                $(parent).on("open", function (e) {
                    var containerElPos = parent.offset().top - (window.scrollY ||
                        window.pageYOffset || document.body.scrollTop);
                    // When device is horizontal view then do nothing with adjusting offset position (top) from parent element
                    var offset = (window.innerHeight < window.innerWidth) ? 0 : parent.height();
                    // update the positioning to be under parent container element
                    comboboxList.css({ 'position': 'fixed', 'z-index': 99999, 'top': containerElPos + offset });
                });
            });
    
            // Calendar picker input area binding
            $('body').on('bindingComplete focus', '.jqx-datetimeinput input[id*="inputjqxWidget"]', function () {
                var parent = $(this).parents('.jqx-datetimeinput');
                
                $(this).blur();
                
                $(parent).on("open", function (e) {
                    var calendarPicker = $('#' + parent.attr('aria-owns')); // This is the property jq uses to assign which calendar picker belongs to the input
                    var containerElPos = parent.offset().top - (window.scrollY ||
                        window.pageYOffset || document.body.scrollTop);
                    var offset = parent.height();
                    // update the positioning to be under parent container element
                    calendarPicker.css({ 'position': 'fixed', 'z-index': 99999, 'top': containerElPos + offset });
                });
    
                parent.jqxDateTimeInput({ animationType: 'none' });
                parent.jqxDateTimeInput('open');
            });
    
            // Calendar picker (calendar icon) btn next to input widget binding
            $('body').on('bindingComplete focus', 'div.jqx-action-btn', function () {
                var parent = $(this).parents('.jqx-datetimeinput');
                $(this).blur();
                $(parent).on("open", function (e) {
                    var calendarPicker = $('#' + parent.attr('aria-owns')); // This is the property jq uses to assign which calendar picker belongs to the input
                    var containerElPos = parent.offset().top - (window.scrollY ||
                        window.pageYOffset || document.body.scrollTop);
    
                    var offset = parent.height();
    
                    // update the positioning to be under parent container element
                    calendarPicker.css({ 'position': 'fixed', 'z-index': 99999, 'top': containerElPos + offset });
                });
    
                parent.jqxDateTimeInput({ animationType: 'none' });
                parent.jqxDateTimeInput('open');
            });

            // Add styling to display site as a mobile version (phone & tablets)
            if (mobileAndTabletcheck()) {
                document.addEventListener("DOMContentLoaded", function () {
                    document.body.classList.add('mobile-mode');
                    var mainWrapper = document.getElementById('mainWrapper');
                    if(mainWrapper) {
                        mainWrapper.classList.add("mobile-mode");
                    }
                    
                });
            }
        }

        ko.validation.init({
            insertMessages: true,
            decorateInputElement: true,
            errorMessageClass: "validationMessage",
            errorElementClass: "validationElement",
            errorsAsTitle: true,
            errorClass: null,
            parseInputAttributes: false,
            messagesOnModified: true,
            decorateElementOnModified: false,
            messageTemplate: null,
            grouping: { deep: true, observable: true, live: false },
            registerExtenders: true
        }, true);

        //Fixing the bootstrap version broke some functionality that was dependent on modals having the 
        //class 'show'.
        $(document).on('show.bs.modal', '.modal', function () { $(this).addClass('show');})
        $(document).on('hidden.bs.modal', '.modal', function () { $(this).removeClass('show');})
    }
}

new JQueryWidgetsInit();