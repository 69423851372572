import { ILoadComment } from "./types";

function LoadCommentModel(initData: ILoadComment = {}) {
  return {
    id: initData.id,
    carrierTrackingRecordId: initData.carrierTrackingRecordId,
    driverComment: initData.driverComment,
    loadComment: initData.loadComment,
  };
}

export default LoadCommentModel;
