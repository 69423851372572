//@ts-check
/// <reference path="../../../types/jquery.plugins.d.ts" />

import ko from 'knockout';
import dataModel from 'data-model';
import dayjs from 'dayjs';
import $ from 'jquery';
import gridStateUtils from 'jqx.grid-utils';
import GreatEdgeCustomWidgets from 'ge.custom-widgets';
import GridStateComponentViewModel from 'jqx.grid-state-component';
import {scrollToRequiredField, mobileAndTabletcheck, datetimeUTC, noop} from 'global-functions';
import template from './edi-page.html';
import userProfile from 'user-profile';
import {showconfirm, showmessage} from 'show-dialog-methods';
import { useDispatch } from 'ko-data-store';
import { isLoading } from '../../../dataStore/actions/appUI';
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";
let defaultDisplayValue = 'N/A';

const showTenderDetails = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const editDates = urlParams.get('editDates');
    const orderId = urlParams.get('orderId');
    const editShipperLocation = urlParams.get('editShipperLocation');

    return { editDates: editDates && editDates == 'true', orderId, editShipperLocation: editShipperLocation && editShipperLocation == 'true' };
}

const sendUpdateEDITenderStopDatesAsync = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("EDI/UpdateEDITenderStopDates", "POST", payload, true)
        .done(resolve)
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred during request.`))
    })
}

const isDateSequenceValid = (dateToCheck, dateToCompareAgainst) => {
    const d1 = datetimeUTC(dateToCheck);
    const d2 = datetimeUTC(dateToCompareAgainst);

    if(d1.isValid() === false || d2.isValid() === false) {
        return false;
    }
    
    if(d1.isBefore(d2)) {
        return false;
    }

    return true
}

const stopEditModel = ({
    id,
    orderSequence,
    stopType,
    arriveEarly,
    arriveLate,
    isShipper,
    isConsignee,
    ediOrderId,
}) => {
    
    return {
        id, 
        ediOrderId,
        orderSequence, 
        stopType, 
        arriveEarly: ko.observable(arriveEarly), 
        arriveLate: ko.observable(arriveLate), 
        dateError: ko.observable(""),
        isShipper,
        isConsignee
    }
}

var EDIPageViewModel = function (params) {
    var self = this;
    self.isLoading = ko.observable(false);
    self.tenderDetails = ko.observable();
    self.tenderDetailsTitle = ko.observable();
    self.resultsWindow = ko.observable();
    self.locationNeeded = ko.observable();
    self.ediGridState = null;
    self.tenderStatusMismatchModel = ko.observable(null);
    self.clearSelectedSavedSearchDDL = ko.observable(false);
    self.refreshSavedSearchDDL = ko.observable("");
    var isInitLoad = true;
    self.initGridState = null;
    self.disableGridViewBtns = ko.observable(true);

    self.handleOnSelected = (state) => {
        self.initGridState = state.grid || {};

        if(isInitLoad) {
            self.loadEDITenders();
        }
        else {
            gridStateUtils.applyGridState("jqxEDI", state.grid);
        }
        
    }

    self.panelView = ko.observable("ediTenders");

    self.handleChangeActiveView = (view) => {
        if(self.disableGridViewBtns()) return false;
        self.panelView(view);
    }

    var GECustomWidgets = new GreatEdgeCustomWidgets();
    var jqxEDIWidgetsGridHelper = GECustomWidgets.GEWidgetsGridHelper('jqxEDI');

    var ediGridDataFields = [
        { name: 'externalId', type: 'string' },
        { name: 'shipmentId', type: 'string' },
        { name: 'createDate', type: 'date' },
        { name: 'action', type: 'string' },
        { name: 'isaControl', type: 'string' },
        { name: 'replyDate', type: 'date' },
        { name: 'tenderDate', type: 'date' },
        { name: 'stopCount', type: 'int' },
        { name: 'deliveryDate', type: 'date' },
        { name: 'pickupDate', type: 'date' },
        { name: 'consigneeLocation', type: 'string' },
        { name: 'shipperLocation', type: 'string' },
        { name: 'purpose', type: 'string' },
        { name: 'orderNumber', type: 'string' },
        { name: 'customer', type: 'string' },
        { name: 'batchId', type: 'string' },
        { name: 'reason', type: 'string' },
        { name: 'remarks', type: 'string' },
        { name: 'error', type: 'string' },
        { name: 'replyCreateDate', type: 'date' },
        { name: 'replyError', type: 'string' },
        { name: 'accept', type: 'bool' },
        { name: 'decline', type: 'bool' },
        { name: 'allowEDIOverride', type: 'bool' },
        { name: 'mustRespondBy', type: 'date' },
        { name: 'changedFields' },
        { name: 'acceptedBy', type: 'string' }
    ];

    var ActionChangeEvaluation = {
        allowed: function (change, tender) {
            if (tender.action.toLowerCase().replace('ed', '') == change) 
                return false;
            if (tender.purpose == 'Cancellation' && change == 'decline')
                return false;
            if(tender.action !== 'Pending' && change == 'decline')
                return false;
            if(tender.action !== 'Pending' && change == 'accept')
                return false;
            return true;
        }
    };

    self.loadEDITenders = function () {
        var getSourceData = function (data) {
            if (self.initGridState !== null && self.initGridState['filters'] && self.initGridState.filters) {
                for (var i = 0; i < self.initGridState.filters.filterscount; i++) {
                    var rangePosition = '';
                    if (self.initGridState.filters['filtertype' + i] == 'datefilter') {
                        if (self.initGridState.filters['filterdatafield' + i] == self.initGridState.filters['filterdatafield' + (i + 1)])
                            rangePosition = 'Begin';
                        else if (self.initGridState.filters['filterdatafield' + i] == self.initGridState.filters['filterdatafield' + (i - 1)])
                            rangePosition = 'End';
                    }
                    data[self.initGridState.filters['filterdatafield' + i] + rangePosition] = self.initGridState.filters['filtervalue' + i];
                }

            }
            if (self.initGridState && self.initGridState.columns) {
                for (i = 0; i < columns.length; i++) {
                    let datafield = columns[i].dataField
                    if (datafield && self.initGridState.columns[datafield]) {
                        let order = self.initGridState.columns[datafield].index;
                        columns[i].index = order;
                    }
                }
                columns.sort(function (a, b) {
                    return a.index - b.index;
                })
            }

            return { data: data, gridState: self.initGridState };
        };


        var initFilters = null;
        var initColumns = self.initGridState ? self.initGridState.columns : null;

        var checkboxCellEdit = function (row, columnfield, value, defaulthtml, columnproperties) {
            var rowData = $('#jqxEDI').jqxGrid('getdisplayrows')[row];
            return ActionChangeEvaluation.allowed(columnfield, rowData);
        };

        var orderNumberCellsRenderer = function (rowdata, columnfield, value, defaulthtml, columnproperties) {
            if (value && defaulthtml) {
                var link = '<a href="neworder/' + value + '" style="color:#00529b;" target="_blank">' + value + '</a>';
                var splitDefault = defaulthtml.split(value);
                splitDefault.splice(1, 0, link);
                var newHtml = $(splitDefault.join(''));
                if (defaulthtml && rowdata.changedFields) {
                    var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("OrderNumber");
                    if (indexOfChangedField >= 0) {
                        var cell = $(defaulthtml);
                        if (rowdata.changedFields[indexOfChangedField].oldValue) {
                            cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                            cell.addClass("deletedGridItem");
                        } else {
                            cell.addClass("changedGridItem");
                        }
                    }
                }
                return newHtml[0].outerHTML;
            }
        };

        var getPurposeFilterItems = function () {
            var filterItems = [];
            dataModel.sjaxRequest('EDI/GetPurposeFilterItems', 'GET', null).done(function (data) {
                if (data)
                    filterItems = data;
            });
            return filterItems;
        };

        var scrubbedFilters = gridStateUtils.extractGridStateFilters(self.initGridState); //self.extractGridStateFilters(self.initGridState);

        // The daterangewidget binds to an element's node, so only passing the columnElement and not the widget itself, since
        // the daterangewidget is a standalone widget that can be used on a page or as a filter.
        // The node's html will be replaced.
        var geDateRangeFilterWidget = function (column, columnElement, widget) {
            //set widget (class) level options
            var options = {
                gridOptions: {
                    gridWidget: widget,
                    gridId: 'jqxEDI', // required to apply gridoptions
                    columnName: column.datafield, // also required, especially if this is a filter widget
                    isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
                    state: self.initGridState // can send our grid state and apply any initial filters
                }
            }

            return GECustomWidgets.GEDateRangePickerWidget(options); // will return widget if already exists or creates a new one;
            
        };

        var pinColumn = function () {
            var isMobile = mobileAndTabletcheck();
            return !isMobile;
        }

        var columns = [
            {
                text: '',
                width: 100,
                columnType: "button",
                filterable: false,
                sortable: false,
                pinned: true,
                buttonclick: function (row) {
                    var datarow = $("#jqxEDI").jqxGrid('getrowdata', row);
                    self.handleViewDetails(datarow.externalId.trim());
                    
                },
                cellsrenderer: function () {
                    return "View Details";
                }
            },
            {
                text: 'Action',
                dataField: 'action',
                minwidth: 140,
                maxwidth: 140,
                editable: false,
                pinned: pinColumn(),
                filtertype: 'list',
                filteritems: ['Pending', 'Accepted', 'Declined', 'Cleared'],
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("Action");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Accept',
                dataField: 'accept',
                minwidth: 120,
                maxwidth: 120,
                pinned: pinColumn(),
                filterable: false,
                sortable: false,
                renderer: () => {
                    let d = $("#acceptCheckDiv").clone();
                    d.find("#chk_SlctAll_Accept").jqxCheckBox();
                    return d;
                },
                columnType: "checkbox",
                cellbeginedit: checkboxCellEdit
            },
            {
                text: 'Decline',
                dataField: 'decline',
                minwidth: 120,
                maxwidth: 120,
                pinned: pinColumn(),
                sortable: false,
                filterable: false,
                renderer: () => {
                    let d = $("#declineCheckDiv").clone();
                    d.find("#chk_SlctAll_Decline").jqxCheckBox();
                    return d;
                },
                columnType: "checkbox",
                cellbeginedit: checkboxCellEdit
            },
            {
                text: 'Accepted By',
                dataField: 'acceptedBy',
                width:  130,
            },
            {
                text: 'Id',
                dataField: 'externalId',
                width: initColumns ? initColumns.externalId.width : 100,
                hidden: initColumns ? initColumns.externalId.hidden : false,
                editable: false
            },
            {
                text: 'Customer',
                dataField: 'customer',
                width: initColumns ? initColumns.customer.width : 80,
                hidden: initColumns ? initColumns.customer.hidden : false,
                editable: false,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("Customer");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Order Number',
                dataField: 'orderNumber',
                width: initColumns ? initColumns.orderNumber.width : 100,
                hidden: initColumns ? initColumns.orderNumber.hidden : false,
                editable: false,
                cellsrenderer: orderNumberCellsRenderer,

            },
            {
                text: 'Shipment ID',
                dataField: 'shipmentId',
                width: initColumns ? initColumns.shipmentId.width : 120,
                hidden: initColumns ? initColumns.shipmentId.hidden : false,
                editable: false
            },
            {
                text: 'Purpose',
                dataField: 'purpose',
                width: initColumns ? initColumns.purpose.width : 135,
                hidden: initColumns ? initColumns.purpose.hidden : false,
                editable: false,
                filtertype: 'list',
                filteritems: getPurposeFilterItems()
            },
            {
                text: 'Shipper',
                dataField: 'shipperLocation',
                width: initColumns ? initColumns.shipperLocation.width : 120,
                hidden: initColumns ? initColumns.shipperLocation.hidden : false,
                editable: false,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("ShipperLocation");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Consignee',
                dataField: 'consigneeLocation',
                width: initColumns ? initColumns.consigneeLocation.width : 120,
                hidden: initColumns ? initColumns.consigneeLocation.hidden : false,
                editable: false,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("ConsigneeLocation");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Pickup Date',
                dataField: 'pickupDate',
                width: initColumns ? initColumns.pickupDate.width : 215,
                hidden: initColumns ? initColumns.pickupDate.hidden : false,
                minwidth: 195,
                cellsformat: 'MM/dd/yyyy HH:mm',
                createfilterwidget: geDateRangeFilterWidget,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("TenderDate");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = datetimeUTC(rowdata.changedFields[indexOfChangedField].oldValue).format("MM/DD/YYYY HH:mm");
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Delivery Date',
                dataField: 'deliveryDate',
                width: initColumns ? initColumns.deliveryDate.width : 215,
                minwidth: 195,
                hidden: initColumns ? initColumns.deliveryDate.hidden : false,
                editable: false,
                cellsformat: 'MM/dd/yyyy HH:mm',
                createfilterwidget: geDateRangeFilterWidget,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("DeliveryDate");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Stop Count',
                dataField: 'stopCount',
                width: initColumns ? initColumns.stopCount.width : 100,
                hidden: initColumns ? initColumns.stopCount.hidden : false,
                editable: false,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("StopCount");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Tender Date',
                dataField: 'tenderDate',
                width: initColumns ? initColumns.tenderDate.width : 215,
                minwidth: 195,
                hidden: initColumns ? initColumns.tenderDate.hidden : false,
                editable: false,
                cellsformat: 'MM/dd/yyyy HH:mm',
                createfilterwidget: geDateRangeFilterWidget,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("TenderDate");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = datetimeUTC(rowdata.changedFields[indexOfChangedField].oldValue).format("MM/DD/YYYY HH:mm");
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Reply Date',
                dataField: 'replyDate',
                width: initColumns ? initColumns.replyDate.width : 215,
                minwidth: 195,
                hidden: initColumns ? initColumns.replyDate.hidden : false,
                editable: false,
                cellsformat: 'MM/dd/yyyy HH:mm',
                createfilterwidget: geDateRangeFilterWidget,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("ReplyDate");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = datetimeUTC(rowdata.changedFields[indexOfChangedField].oldValue).format("MM/DD/YYYY HH:mm");
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'ISA Control',
                dataField: 'isaControl',
                width: initColumns ? initColumns.isaControl.width : 70,
                hidden: initColumns ? initColumns.isaControl.hidden : false,
                editable: false
            },
            {
                text: 'Reason',
                dataField: 'reason',
                width: initColumns ? initColumns.reason.width : 70,
                hidden: initColumns ? initColumns.reason.hidden : false,
                editable: false,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("Reason");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Remarks',
                dataField: 'remarks',
                width: initColumns ? initColumns.remarks.width : 70,
                hidden: initColumns ? initColumns.remarks.hidden : false,
                editable: false,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("Remarks");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Reply Error',
                dataField: 'replyError',
                width: initColumns ? initColumns.replyError.width : 70,
                hidden: initColumns ? initColumns.replyError.hidden : false,
                editable: false,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("ReplyError");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Batch Id',
                dataField: 'batchId',
                width: initColumns ? initColumns.batchId.width : 70,
                hidden: initColumns ? initColumns.batchId.hidden : false,
                editable: false
            },
            {
                text: 'Batch Create Date',
                dataField: 'createDate',
                width: initColumns ? initColumns.createDate.width : 215,
                minwidth: 195,
                hidden: initColumns ? initColumns.createDate.hidden : false,
                editable: false,
                cellsformat: 'MM/dd/yyyy HH:mm',
                createfilterwidget: geDateRangeFilterWidget
            },
            {
                text: 'Error',
                dataField: 'error',
                width: initColumns ? initColumns.error.width : 70,
                hidden: initColumns ? initColumns.error.hidden : false,
                editable: false,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("Error");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                text: 'Must Respond By Date',
                dataField: 'mustRespondBy',
                width: initColumns ? initColumns.mustRespondBy.width : 215,
                minwidth: 195,
                hidden: initColumns ? initColumns.mustRespondBy.hidden : false,
                editable: false,
                cellsformat: 'MM/dd/yyyy HH:mm',
                createfilterwidget: geDateRangeFilterWidget,
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                    if (defaulthtml && rowdata.changedFields) {
                        var indexOfChangedField = rowdata.changedFields.map(function (x) { return x.column; }).indexOf("mustRespondBy");
                        if (indexOfChangedField >= 0) {
                            var cell = $(defaulthtml);
                            if (rowdata.changedFields[indexOfChangedField].oldValue) {
                                cell[0].innerText = rowdata.changedFields[indexOfChangedField].oldValue;
                                cell.addClass("deletedGridItem");
                                return cell[0].outerHTML;
                            } else {
                                cell.addClass("changedGridItem");
                                return cell[0].outerHTML;
                            }
                        }
                    }
                }
            },
            {
                dataField: "changedFields",
                hidden: true
            }
        ];

        var source = {
            url: "EDI/GetTendersForGrid",
            datatype: "json",
            sortcolumn: "replyDate",
            sortdirection: "asc",
            data: {
                agencyId: userProfile.currentAgencyId()
            },
            filter: function (cellValue, rowData, dataField, filterGroup, defaultFilterResult) {
                if (isInitLoad == false) {
                    $("#jqxEDI").jqxGrid('updatebounddata', 'filter');
                }

            },
            sort: function (column, sortOrder) {
                if (isInitLoad == false) {
                    $("#jqxEDI").jqxGrid('updatebounddata', 'sort');
                }

            },
            beforeprocessing: function (data) {
                source.accept = false;
                source.decline = false;
                
                if (data.tenders !== null && data.tenders.length > 0) {
                   
                    data.tenders = data.tenders.map(x => {
                        x.accept = false;
                        x.decline = false;

                        if(x.mustRespondBy) {
                            x.mustRespondBy = x.mustRespondBy.substr(0, x.mustRespondBy.indexOf("T") + 9);
                        }

                        if(x.createDate) {
                            x.createDate = x.createDate.substr(0, x.createDate.indexOf("T") + 9);
                        }

                        if(x.replyDate) {
                            x.replyDate = x.replyDate.substr(0, x.replyDate.indexOf("T") + 9);
                        }

                        if(x.tenderDate) {
                            x.tenderDate = x.tenderDate.substr(0, x.tenderDate.indexOf("T") + 9);
                        }

                        if(x.deliveryDate) {
                            x.deliveryDate = x.deliveryDate.substr(0, x.deliveryDate.indexOf("T") + 9);
                        }

                        if(x.pickupDate) {
                            x.pickupDate = x.pickupDate.substr(0, x.pickupDate.indexOf("T") + 9);
                        }

                        if(x.pickupDate) {
                            x.pickupDate = x.pickupDate.substr(0, x.pickupDate.indexOf("T") + 9);
                        }

                        return x;
                    })
                }
                source.totalrecords = data.tenderCount;
            },
            formatdata: function (data) {
                var columns = new Array();
                var filterinfo = $("#jqxEDI").jqxGrid('getfilterinformation');
                data = gridStateUtils.formatGridFilters(filterinfo, data);
                data.columns = columns;
                if (isInitLoad) {
                    var initSourceData = getSourceData(data);
                    data = initSourceData.data;
                    
                    data = Object.keys(data).reduce((x, key) => {
                        if(data[key] === "Please Choose:") {
                            x[key] = null;
                        }
                        else {
                            x[key] = data[key];
                        }

                        return x;

                    }, {})

                    if (initSourceData.gridState)
                        if (initSourceData.gridState.filters)
                            initFilters = initSourceData.gridState.filters;
                    isInitLoad = false;
                }
                
                // Get grid filters
                data = jqxEDIWidgetsGridHelper.addGridFiltersToDataObj(data);
                
                return data;
            },
            datafields: ediGridDataFields
        };

        var dataAdapter = dataModel.getDataAdapter(source);

        $("#jqxEDI").jqxGrid({
            width: "100%",
            source: dataAdapter,
            selectionmode: 'singlecell',
            sortable: true,
            pageable: true,
            filterable: true,
            showfilterrow: true,
            editable: true,
            autoheight: true,
            columnsmenu: false,
            columnsheight: 50,
            virtualmode: true,
            enablebrowserselection: true,
            showtoolbar: true,
            columnsreorder: true,
            showstatusbar: true,
            statusbarheight: 40,
            rendertoolbar: function (toolbar) {
                let vm1 = new GridStateComponentViewModel.viewModel(),
                tmpl1 = GridStateComponentViewModel.template;
                let $grid = $("#jqxEDI")
                
                vm1.clearFilters = function () {
                    jqxEDIWidgetsGridHelper.clearDateRangeFiltersFromGrid();
                    // clear additional filters from grid
                    $('#jqxEDI').jqxGrid('clearfilters');
                    self.clearSelectedSavedSearchDDL(true);
                }

                vm1.setDefaultSearchOverride = async () => {
                    const savedSearches = await loadSavedSearches($grid.attr("id"));
                    const filters = {
                        isDefault: true
                    }
    
                    vm1.loadSaveSearchModal(
                        $grid,
                        (val) => {
                            if(val && val.searchname) {
                                self.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
                }

                vm1.actions.push("Save Search");

                vm1.loadSaveSearchModalOverride = async () => {
                    const savedSearches = await loadSavedSearches($grid.attr("id"));
                    const filters = {
                        isDefault: false
                    }

                    vm1.loadSaveSearchModal(
                        $grid,
                        (val) => {
                            if(val && val.searchname) {
                                self.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
    
                }

                const $actionList = $("#ediGridAction");
                $actionList.append(tmpl1);

                toolbar.append($actionList);
            
                const $ediToolbar = $("#ediGridToolbar");
                toolbar.append($ediToolbar);
                
                ko.applyBindingsToDescendants(vm1, $actionList[0]);
                

                // var ediGridTmpl = $("#ediGridToolbar");
                // toolbar.append(ediGridTmpl);

                // var ediGridAction = ediGridTmpl.find("#ediGridAction");
                //     ediGridAction.append(tmpl1);
                //     ediGridTmpl.append(tmpl1);
                //     ko.applyBindingsToDescendants(vm1, ediGridAction[0]);
            },
            renderstatusbar: function (statusbar) {
                var ediGridStatusBar = $('#ediGridStatusBar');
                statusbar.append(ediGridStatusBar);
            },
            ready: function () {
                this.detailsVisibility = [];
                
                const urlParams = showTenderDetails();

                if(urlParams.orderId) {
                    const filtergroup = new $.jqx.filter();
                    const filter = filtergroup.createfilter('stringfilter', urlParams.orderId, 'EQUAL');
                    filtergroup.addfilter('and', filter);
                    $('#jqxEDI').jqxGrid('addfilter', 'externalId', filtergroup);

                    $('#jqxEDI').jqxGrid('refreshfilterrow');
                    $('#jqxEDI').jqxGrid('refresh');
                    $('#jqxEDI').jqxGrid("updatebounddata", "data");
                    
                    if(urlParams.editDates || urlParams.editShipperLocation) {
                        self.handleViewDetails(urlParams.orderId)
                    }
                    
                }
                else if (scrubbedFilters) {

                    try {
                        var filtergroup = undefined;
                        var filtervalue = '';
                        var filtervalue1 = '';
                        var filtercondition = '';
                        var filtercondition1 = '';
                        var filtertype = '';
                        var filterdatafield = '';
                        var filteroperator = '';
                        var filter1 = undefined;
                        var filter;


                        var dateFilters = scrubbedFilters.dateFilters;
                        var singleValueFilters = scrubbedFilters.singleValueFilters;

                        for (let i = 0; i < singleValueFilters.length; i++) {
                            filtergroup = new $.jqx.filter();
                            filtervalue = singleValueFilters[i].filtervalue;
                            filtercondition = singleValueFilters[i].filtercondition;
                            filtertype = singleValueFilters[i].filtertype;
                            filterdatafield = singleValueFilters[i].filterdatafield;
                            filteroperator = singleValueFilters[i][filterdatafield + 'operator'];
                            filter = filtergroup.createfilter(filtertype, filtervalue, filtercondition);
                            filtergroup.addfilter(filteroperator, filter);
                            $('#jqxEDI').jqxGrid('addfilter', filterdatafield, filtergroup);
                        }

                        for (let i = 0; i < dateFilters.length; i++) {
                            
                            filtergroup = new $.jqx.filter();
                            var filterObj = dateFilters[i];
                            var datafield = filterObj.filterdatafield;

                            var filterVal = new Date(filterObj.filtervalue);
                            filter = filtergroup.createfilter(filterObj.filtertype, filterVal, filterObj.filtercondition);
                            filtergroup.addfilter(filterObj.filteroperator, filter);

                            if (dateFilters[i + 1] != null && dateFilters[i + 1].filterdatafield === datafield) {
                                var endDateFilterObj = dateFilters[i + 1];
                                var filter2Val = new Date(endDateFilterObj.filtervalue);

                                var filter2 = filtergroup.createfilter(endDateFilterObj.filtertype, filter2Val, endDateFilterObj.filtercondition);
                                filtergroup.addfilter(endDateFilterObj.filteroperator, filter2);

                                dateFilters.splice(i + 1, 1);
                            }

                            $('#jqxEDI').jqxGrid('addfilter', datafield, filtergroup);
                        }
                            
                        
                        $('#jqxEDI').jqxGrid('refreshfilterrow');
                        $('#jqxEDI').jqxGrid('refresh');

                        

                    } catch (err) {
                        //If filters bomb, just add the default filter
                        console.error(err);

                        filtergroup = new $.jqx.filter();
                        filter = filtergroup.createfilter('stringfilter', 'Pending', 'EQUAL');
                        filtergroup.addfilter('and', filter);
                        $('#jqxEDI').jqxGrid('addfilter', 'action', filtergroup);

                        filtergroup = new $.jqx.filter();
                        let filter2 = filtergroup.createfilter('stringfilter', 'Original', 'EQUAL');
                        filtergroup.addfilter('and', filter2);
                        $('#jqxEDI').jqxGrid('addfilter', 'purpose', filtergroup);

                        $('#jqxEDI').jqxGrid('refreshfilterrow');

                        $('#jqxEDI').jqxGrid('refresh');
                    }

                }

                self.disableGridViewBtns(false);
            },
            rendergridrows: function (obj) {
                return obj.data;
            },
            columnsresize: true,
            columns: columns,
            handlekeyboardnavigation: function (e) {
                if (e.keyCode == 86)
                    if (!$(e.target).hasClass('jqx-input'))
                        e.preventDefault();
            }
        });

        self.checkTenderStatus = function (request) {
            let tendersToUpdate = [
                ...request.AcceptList.map(function(value) { return value.trim();}), 
                ...request.DeclineList.map(function(value) { return value.trim();})
            ];

            return new Promise(function (resolve, reject) {
                dataModel.ajaxRequest("EDI/CheckTenderStatus", "POST", tendersToUpdate)
                .done(function (value) {
                    resolve({tenderCheckStatus: value, request: request});
                })
            })
        }    

        self.acceptList = ko.observableArray([]);
        self.declineList = ko.observableArray([]);

        self.initiateSaveTenderProcess = function (request) {
            self.isLoading(true);
            if(!request) {
                request = {
                    AcceptList: self.acceptList(),
                    DeclineList: self.declineList(),
                    agencyId: userProfile.currentAgencyId(),
                };
            }

            //check if still pending. Action == null is pending.
            self.checkTenderStatus(request)
            .then(self.determineTenderAction)
            .then(self.saveTenders)
            .then((response) => {
                self.resultsWindow(new ResultsViewModel(response.results));
                $('#jqxEDI').jqxGrid('updatebounddata', 'cells');
                self.acceptList.removeAll();
                self.declineList.removeAll();
                self.isLoading(false);
            }, self.needTenderLocation)
        };

        self.determineTenderAction = (data) => {
            return new Promise((resolve, reject) => {
                
                //Action == null is pending.
                //Tenders that aren't pending but customer doesn't allow agents to override
                var notPendingNoConfirmationNeeded = data.tenderCheckStatus.filter(function(x) { return x.action != null && x.allowEDIOverride == false })
                //Tenders that are expired or declined and customer allows agents to override.
                var needsConfirmation = data.tenderCheckStatus.filter(x => (x.action == "D" || x.action == "E") && x.allowEDIOverride == true);
                var pastAllowedAcceptDate = data.tenderCheckStatus.filter(x => { 
                    let cd = datetimeUTC(x.mustRespondBy);
                    cd = cd.add(7, 'day');
                    // reset the times
                    cd = cd.hour(0);
                    cd = cd.minute(0);
                    cd = cd.second(0);
                    cd = cd.millisecond(0);

                    let today = datetimeUTC(new Date());
                    today = today.hour(0);
                    today = today.minute(0);
                    today = today.second(0);
                    today = today.millisecond(0);

                    return x.action == null && x.purpose == "Original" && cd.isBefore(today) && data.request.AcceptList.some(q => q == x.externalID);
                });

                if(notPendingNoConfirmationNeeded.length > 0) {
                    self.tenderStatusMismatchModel(notPendingNoConfirmationNeeded);
                    self.isLoading(false);
                } 
                else if(data.tenderCheckStatus.length == 1 && pastAllowedAcceptDate.length) { // if once tender is selected then show error modal, otherwise go through results process and check again there. (save tenders)
                    self.isLoading(false);
                    showmessage("Tender is older than 7 days and the order cannot be created. Please contact the Help Desk for assistance.", "danger");
                }
                else if(needsConfirmation.length > 0) {
                    self.isLoading(false);
                    showconfirm("The tender(s) you have accepted has expired or been previously declined. If you continue an order will be created but a response to the customer will not be created. Do you wish to continue?")
                    .then((x) => {
                        if(x) {
                            resolve(data.request)
                        }
                    })
                } else {
                    resolve(data.request)
                }
            })
        }

        self.saveTenders = (tenders) => {
            self.isLoading(true);
            return new Promise((resolve, reject) => {
                dataModel.ajaxRequest("EDI/SaveTenders/", 'put', tenders)
                .done(function (response, status, xhr) {
                    $('div[id*="chk_SlctAll_"]').jqxCheckBox({ 'checked': false });
                    // if there is a single item and it needs a location then display the locationNeeded form
                    if (response.results.length == 1 && response.results[0].needLocation == true) {
                        // Display form for selecting a location or creating a new location
                        // pull the locations for the shipper and display them in the locationNeeded Modal
                        reject(response)
                    } else {
                        resolve(response)
                    }
                })
                .fail(function (error, msg, d) {
                    self.isLoading(false);

                    const err = error.responseJSON && error.responseJSON.message || msg || `An error occurred during the process.`

                    showmessage(err, "danger");
                });
            })
        }

        self.needTenderLocation = (response) => {
            dataModel.ajaxRequest("EDI/GetLocationsForTender/" + response.results[0].externalId.trim(), 'GET')
            .done(function (data, status, xhr) {
                self.isLoading(false);
                self.locationNeeded(new LocationsViewModel(data, response.results[0].externalId.trim(), self.resultsWindow));
            })
            .fail(function (error, msg, d) {
                showmessage(msg, "danger");
            });
        }

        var removeFromAcceptList = function (externalId) {
            var indexToRemove = self.acceptList.indexOf(externalId);
            if (indexToRemove > -1) {
                self.acceptList.splice(indexToRemove, 1);
            }
        };

        var removeFromDeclineList = function (externalId) {
            var indexToRemove = self.declineList.indexOf(externalId);
            if (indexToRemove > -1) {
                self.declineList.splice(indexToRemove, 1);
            }
        };

        $("#jqxEDI").on('cellendedit', function (event) {
            updateAcceptDecline(event.args.rowindex, event.args.row, event.args.datafield, event.args.value, false);
        });

        var processingSelectAll = false;

        var updateAcceptDecline = function (rowIndex, dataRow, action, value, selectAll) {
            
            var accepting = action == 'accept';
            if (ActionChangeEvaluation.allowed(action, dataRow)) {
                $('#jqxEDI').jqxGrid('setcellvalue', rowIndex, action, value);
            }
            
            if (!selectAll) {
                var selectAllSlctr = '#chk_SlctAll_';
                if (value) {
                    var opposingOption = accepting ? 'decline' : 'accept';
                    if ($('#jqxEDI').jqxGrid('getcellvalue', rowIndex, opposingOption)) {
                        $('#jqxEDI').jqxGrid('setcellvalue', rowIndex, opposingOption, false);
                        selectAllSlctr += opposingOption[0].toUpperCase() + opposingOption.slice(1);
                        if ($(selectAllSlctr).jqxCheckBox('checked')) {
                            processingSelectAll = true;
                            $(selectAllSlctr).jqxCheckBox({ 'checked': false });
                            processingSelectAll = false;
                        }
                    }
                }
                var rows = $('#jqxEDI').jqxGrid('getrows');
                selectAllSlctr = selectAllSlctr.substr(0, selectAllSlctr.lastIndexOf('_') + 1) + action[0].toUpperCase() + action.slice(1);
                var eligibleRows = rows.filter(function (row) {
                    if ((!accepting && row.purpose !== 'Cancellation') || accepting && row.action.replace('ed', '').toLowerCase() !== action)
                        return row;
                });
                var checkedRows = rows.filter(function (row) {
                    if (row[action])
                        return row;
                });
                processingSelectAll = true;
                $(selectAllSlctr).jqxCheckBox({ 'checked': eligibleRows.length == checkedRows.length });
                processingSelectAll = false;
            }

            if(accepting == true) {
                if(value == true && dataRow.action == "Pending") {
                    self.acceptList.push(dataRow.externalId) && removeFromDeclineList(dataRow.externalId)
                    removeFromDeclineList(dataRow.externalId)
                } else {
                    removeFromAcceptList(dataRow.externalId)
                }
            } else {
                if(value == true && dataRow.action == "Pending") {
                    self.declineList.push(dataRow.externalId) 
                    removeFromAcceptList(dataRow.externalId)
                } else {
                    removeFromDeclineList(dataRow.externalId)
                }
            }
        };

        $("body").on("change", "#chk_SlctAll_Accept", (e) => {
            if (processingSelectAll) {
                e.preventDefault();
                return;
            }

            processingSelectAll = true;
            $('#chk_SlctAll_Decline').jqxCheckBox({checked: false});

            let checked = e.args.checked;
            let rows = $('#jqxEDI').jqxGrid('getdisplayrows');

            rows.forEach(function (row, i, arr) {
                if (row['decline']) {
                    $('#jqxEDI').jqxGrid('setcellvalue', i, 'decline', false);
                }
                updateAcceptDecline(i, row, 'accept', checked, true);
            });

            if(self.acceptList().length == 0) {
                $('#chk_SlctAll_Accept').jqxCheckBox({checked: false});
            }
            
            processingSelectAll = false;
        })

        $("body").on("change", "#chk_SlctAll_Decline", (e) => {
            if (processingSelectAll) {
                e.preventDefault();
                return;
            }
            
            processingSelectAll = true;
            $('#chk_SlctAll_Accept').jqxCheckBox({checked: false});

            let checked = e.args.checked;
            let rows = $('#jqxEDI').jqxGrid('getdisplayrows');

            rows.forEach(function (row, i, arr) {
                if (row['accept']) {
                    $('#jqxEDI').jqxGrid('setcellvalue', i, 'accept', false);
                }
                updateAcceptDecline(i, row, 'decline', checked, true);
            });

            if(self.declineList().length == 0) {
                $('#chk_SlctAll_Decline').jqxCheckBox({checked: false});
            }

            processingSelectAll = false;
        })
    };

    self.handleViewDetails = (externalID) => {
        dataModel.ajaxRequest("EDI/GetTenderDetails/" + externalID, "GET")
        .done(function (data, status, xhr) {

            self.tenderDetailsTitle('EDI Tender Details' + (data.general ? data.general.purpose ? '&nbsp;'.repeat(8) + 'Purpose: ' + data.general.purpose.toUpperCase() : '' : ''));
            self.tenderDetails(new DetailsViewModel(data, self.tenderDetails, self.resultsWindow, self.locationNeeded, self.locations, self));
        })
        .fail(function (error, msg, d) {
            showmessage(msg, "danger");
        });
    }

    

    $('body').on('click', '.expand-collapse', function () {
        var partner = $(this).attr('id') == 'phShipper' ? 'phConsignee' : $(this).attr('id') == 'phConsignee' ? 'phShipper' : false;
        var $that = $('#' + partner);
    
        if ($(this).hasClass('collapsed')) {
            $(this).next('.panel-body').slideDown(600);
            $(this).removeClass('collapsed').addClass('expanded');
            $(this).find('.glyphicon-chevron-down').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').attr('title', 'Collapse');
            if (partner) {
                $that.next('.panel-body').slideDown(600);
                $that.removeClass('collapsed').addClass('expanded');
                $that.find('.glyphicon-chevron-down').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').attr('title', 'Collapse');
            }
        }
        else {
            $(this).next('.panel-body').slideUp(600);
            $(this).removeClass('expanded').addClass('collapsed');
            $(this).find('.glyphicon-chevron-up').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').attr('title', 'Expand');
            if (partner) {
                $that.next('.panel-body').slideUp(600);
                $that.removeClass('expanded').addClass('collapsed');
                $that.find('.glyphicon-chevron-up').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').attr('title', 'Expand');
            }
        }
    });
    
    $('body').on('checkChange', '.jqx-listbox', function () {
        $('#jqxEDI div#row_Accept, #jqxEDI div#row_Decline').css('margin-top', '8px');
    });
    
    $('#jqxEDI').on('bindingcomplete', function () {
        $('#jqxEDI').jqxGrid('autoresizecolumns'); 
    });
    
    $('#jqxEDI').on('focus', 'div[id*="jqxWidget"][role="combobox"]', function (e) {
        var parent = $(this);
        
        $(this).blur();
    
        if (mobileAndTabletcheck()) {
            $(parent).on("open", function (e) {
                var comboboxList = $('#' + parent.attr('aria-owns')); // This is the property jq uses to assign which calendar picker belongs to the input
                parent.jqxDropDownList({ animationType: 'none' }); // slide doesn't work properly on mobile so set to none
    
                parent.jqxDropDownList({ animationType: 'none' });
                var containerElPos = parent.offset().top - (window.scrollY ||
                    window.pageYOffset || document.body.scrollTop);
    
                var offset = parent.height();
                // update the positioning to be under parent container element
                comboboxList.css({ 'position': 'fixed', 'z-index': 1000, 'top': containerElPos + offset });
            });
        }
    
    });
};

var LocationsViewModel = function (model, externalId, resultsWindow) {
    var self = this;
    self.externalId = ko.observable(externalId);
    self.locations = ko.observableArray(model.locations);
    self.showLoadingWheel = ko.observable(false);

    self.code = ko.observable().extend({ required: true });
    self.name = ko.observable().extend({ required: true });
    self.address = ko.observable().extend({ required: true });
    self.cityStateZip = ko.observable().extend({ required: true });
    self.contact = ko.observable().extend({ required: true });
    self.phone = ko.observable().extend({ required: true });

    self.handleLocationDDLSelect = ko.pureComputed({
        read: () => {
            return self.locationDDLValue() && self.locationDDLValue().externalId
        },
        write: (val) => {
            self.locationDDLValue(val);
        }
    })
    self.locationDDLValue = ko.observable();

    dataModel.ajaxRequest('EDI/GetShipperDetailsForTender/' + self.externalId(), 'GET')
        .done(function (response, status, xhr) {

            self.address(response.address);
            self.cityStateZip(response.cityStateZip);
            self.phone(response.phone);
        })
        .fail(function (response) {
        });

    self.generateLocationCodeClick = function () {
        var locationCodeParam = {
            name: self.name(),
            cityStateZip: self.cityStateZip()
        };
        dataModel.ajaxRequest('location/generatecode', 'GET', locationCodeParam)
            .done(function (response, status, xhr) {
                self.code(response);
            })
            .fail(function (response) {
            });
    }

    self.saveAndUseLocation = function () {
        var validationErrors = ko.validation.group(self, { deep: false, live: true, observable: true });
        if (validationErrors().length > 0) {
            validationErrors.showAllMessages();

            scrollToRequiredField();

            return false;
        }
        var data = {
            TenderExternalId: self.externalId(),
            Code: self.code(),
            Name: self.name(),
            Address: self.address(),
            CityStateZip: self.cityStateZip(),
            Contact: self.contact(),
            Phone: self.phone(),
            AgencyId: userProfile.agencyId
        };
        self.showLoadingWheel(true);
        dataModel.ajaxRequest('EDI/SaveNewLocationForTender', 'PUT', data)
            .done(function (response, status, xhr) {
                self.showLoadingWheel(false);
                $('.close').click();
                resultsWindow(new ResultsViewModel(response.results));
                $('#jqxEDI').jqxGrid('updatebounddata', 'cells');
            })
            .fail(function (response) {
                showmessage(response, "danger");
            });
    }

    self.locationSelected = function (item) {
        // Update the EDI order with the shipper location
        var data = {
            TenderExternalId: self.externalId(),
            LocationExternalId: item.externalId || item.code || "",
            AgencyId: userProfile.currentAgencyId()
        };
        self.showLoadingWheel(true);
        dataModel.ajaxRequest("EDI/UpdateShipperLocationAndSaveTender/", 'put', data)
            .done(function (response, status, xhr) {
                self.showLoadingWheel(false);
                $('.close').click();
                resultsWindow(new ResultsViewModel(response.results));
                $('#jqxEDI').jqxGrid('updatebounddata', 'cells');
            })
            .fail(function (error, msg, d) {
                showmessage(msg, "danger");
            });
    };
}

var GeneralDetailsViewModel = function (model) {
    var self = this;
    self.externalId = ko.observable(model.tenderExternalId);
    self.tenderStatus = ko.observable(model.tenderStatus || defaultDisplayValue);
    self.scac = ko.observable(model.scac || defaultDisplayValue);
    self.agencyId = ko.observable(model.agencyId || defaultDisplayValue);
    self.partnerId = ko.observable(model.partnerId || defaultDisplayValue);
    self.purpose = ko.observable(model.purpose || defaultDisplayValue);
    self.id = ko.observable(model.id || defaultDisplayValue);
    self.direction = ko.observable(model.direction || defaultDisplayValue);
    self.mustRespondBy = ko.observable(model.mustRespondBy);
    self.isaControl = ko.observable(model.isaControl || defaultDisplayValue);
    self.ediCommodity = ko.observable(model.ediCommodity || defaultDisplayValue);
    self.billingMethod = ko.observable(model.billingMethod || defaultDisplayValue);
    self.pieces = ko.observable(model.pieces || defaultDisplayValue);
    self.freightCharges = ko.observable(model.freightCharges || defaultDisplayValue);
    self.totalCharges = ko.observable(model.totalCharges || defaultDisplayValue);
    self.action = ko.observable(model.action || defaultDisplayValue);
    self.remarks = ko.observable(model.remarks || defaultDisplayValue);
    self.error = ko.observable(model.error || defaultDisplayValue);
    self.functionalReplyBatchId = ko.observable(model.functionalReplyBatchId || defaultDisplayValue);
    self.reason = ko.observable(model.reason || defaultDisplayValue);
    self.revenueCode = ko.observable(model.revenueCode || defaultDisplayValue);
    self.trailer = ko.observable(model.trailer || defaultDisplayValue);
    self.enteredBy = ko.observable(model.enteredBy || defaultDisplayValue);
    self.shipmentId = ko.observable(model.shipmentId || defaultDisplayValue);
    self.control = ko.observable(model.control || defaultDisplayValue);
    self.version = ko.observable(model.version || defaultDisplayValue);
    self.gsDate = ko.observable(model.gsDate);
    self.createDate = ko.observable(model.createDate);
    self.entryMethod = ko.observable(model.entryMethod || defaultDisplayValue);
    self.bolNumber = ko.observable(model.bolNumber || defaultDisplayValue);
    self.billToCustomer = ko.observable(model.billToCustomer || defaultDisplayValue);
    self.weight = ko.observable(model.weight || defaultDisplayValue);
    self.otherCharges = ko.observable(model.otherCharges || defaultDisplayValue);
    self.tenderReplyCreateDate = ko.observable(model.tenderReplyCreateDate);
    self.tenderReplyBatchId = ko.observable(model.tenderReplyBatchId || defaultDisplayValue);
    self.tenderReplyStatus = ko.observable(model.tenderReplyStatus || defaultDisplayValue);
    self.tenderReplyAction = ko.observable(model.tenderReplyAction || defaultDisplayValue);
    self.batchCreateDate = ko.observable(model.batchCreateDate);
    self.functionalReplyStatus = ko.observable(model.functionalReplyStatus || defaultDisplayValue);
    self.stops = ko.observable(model.stops.map(function (s) {
        s.stopType = s.stopType || defaultDisplayValue;
        s.location = s.location || defaultDisplayValue;
        s.pieces = s.peices || defaultDisplayValue;
        s.weight = s.weight || defaultDisplayValue;
        s.reference = s.reference.map(function (r) {
            r.number = r.number || defaultDisplayValue;
            r.qualifierType = r.qualifierType || defaultDisplayValue;
            return r;
        });
        s.comments = s.comments.map(function (c) {
            c.note = c.note || defaultDisplayValue;
            c.type = c.type || defaultDisplayValue;
            return c;
        });
        s.isShipper = s.isShipper || defaultDisplayValue;
        s.isConsignee = s.isConsignee || defaultDisplayValue;
        s.name = s.name || defaultDisplayValue;
        s.contact = s.contact || defaultDisplayValue;
        s.address = s.address || defaultDisplayValue;
        s.cityStateZip = s.cityStateZip || defaultDisplayValue;
        return s;
    }));
    self.charges = ko.observable(model.additionalCharges || defaultDisplayValue);
    self.acceptedBy = ko.observable(model.acceptedBy || defaultDisplayValue);
};

var ShipperConsigneeViewModel = function (model) {
    var self = this;
    self.location = ko.observable(model.location || defaultDisplayValue);
    self.name = ko.observable(model.name || defaultDisplayValue);
    self.address = ko.observable(model.address || defaultDisplayValue);
    self.cityStateZip = ko.observable(model.cityStateZip || defaultDisplayValue);
    self.contact = ko.observable(model.contact || defaultDisplayValue);
    self.phone = ko.observable(model.phone || defaultDisplayValue);
    self.arriveEarly = model.arriveEarly !== null ? datetimeUTC(model.arriveEarly).format(userProfile.dateTimeFormat) : "?";
    self.arriveLate = model.arriveLate !== null ? datetimeUTC(model.arriveLate).format(userProfile.dateTimeFormat) : "?";
    self.scheduledArrivalBetween = self.arriveEarly + ' - ' + self.arriveLate;
    self.driverUnload = ko.observable(model.driverUnload || false);
    self.notes = ko.observable(model.comments.map(function (c) {
        c.note = c.note || defaultDisplayValue;
        c.type = c.type || defaultDisplayValue;
        return c;
    }));
    self.referenceNumbers = ko.observable(model.reference.map(function (r) {
        r.number = r.number || defaultDisplayValue;
        r.qualifierType = r.qualifierType || defaultDisplayValue;
        return r;
    }));
};

var DetailsViewModel = function (model, tenderDetails, resultsWindow, locationNeeded, locations, parentVM) {
    var self = this;
    const dispatchAction = useDispatch();
    self.general = ko.observable(new GeneralDetailsViewModel(model.general));
    self.shipper = ko.observable(new ShipperConsigneeViewModel(model.shipper));
    self.consignee = ko.observable(new ShipperConsigneeViewModel(model.consignee));
    self.parentVM = ko.observable(parentVM);

    self.functionalBatchId = ko.observable(model.functionalBatchId || defaultDisplayValue);
    self.functionalBatchCreateDate = ko.observable(model.functionalBatchCreateDate);
    self.functionalBatchError = ko.observable(model.functionalBatchError || defaultDisplayValue);
    self.errorMessage = ko.observable();

    self.editStops = ko.observableArray(model.general && model.general.stops.filter(x => (x.isShipper === true || x.isConsignee === true)).map(x => stopEditModel(x)));

    const validateStopDateChange = (changeVal) => {
        const shipper = self.editStops().find(q => q.isShipper === true) || { arriveEarly: noop };
        const consignee = self.editStops().find(q => q.isConsignee === true) || { arriveEarly: noop };

        if(!changeVal) {
            consignee.dateError(`Please enter Earliest datetime.`);
        }
        
        const errMsg = isDateSequenceValid(consignee.arriveEarly(), shipper.arriveEarly()) === false ? `The delivery date is before the pickup date. Please correct this data and then accept the tender.` : ``;
        consignee.dateError(errMsg);
    };

    self.editStops().forEach((x) => x.arriveEarly.subscribe(validateStopDateChange))

    self.stopDatesRequireUpdating = ko.pureComputed(() => {
        if (self.editStops().length === 0) return false;

        const gn = self.general();
        const shipper = gn.stops().find(q => q.isShipper === true) || { arriveEarly: null };
        const consignee = gn.stops().find(q => q.isConsignee === true) || { arriveEarly: null };

        return gn.tenderReplyAction() != "A" && isDateSequenceValid(consignee.arriveEarly, shipper.arriveEarly) === false
    })

    self.acceptBtnDisabled = ko.pureComputed(() => self.stopDatesRequireUpdating() && self.editStops().some(x => x.dateError().length > 0));
    
    self.accept = async () => {
        try {
            self.errorMessage("");

            if(self.general().tenderReplyAction() != "A") {

                if(self.stopDatesRequireUpdating()) {
                    dispatchAction(isLoading(true))
                    await sendUpdateEDITenderStopDatesAsync(ko.toJS(self.editStops()))
                    dispatchAction(isLoading(false))    
                }

                tenderDetails(null);
                locationNeeded(null);

                var externalId = self.general().externalId;
                var data = {
                    AcceptList: [externalId()],
                    DeclineList: [],
                    agencyId: userProfile.currentAgencyId(),
                };

                self.parentVM().initiateSaveTenderProcess(data)
            }
        }
        catch(err) {
            dispatchAction(isLoading(false))
            self.errorMessage(err);
        }
        
    };

    self.decline = function () {
        if(self.general().tenderReplyAction() != "D") {
            tenderDetails(null);
            locationNeeded(null);
            var externalId = self.general().externalId;
            var data = {
                AcceptList: [],
                DeclineList: [externalId()],
                agencyId: userProfile.currentAgencyId(),
            };
            self.parentVM().initiateSaveTenderProcess(data)
        }
    };

    self.faded = function (val) {
        if (!val || val == defaultDisplayValue || val == '? - ?')
            return 'faded';
    }
};

var ResultsViewModel = function (results) {
    var self = this;
    self.results = ko.observableArray(results);
}

export default {viewModel: EDIPageViewModel, template: template}