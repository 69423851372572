import dataModel from "data-model";
export class Logger {
    httpLoggerService;

    constructor() {
        this.httpLoggerService = new httpLoggerService();

    }

    logErrorToConsole = (error) => {
        if(error) {

            let message = error.responseJSON && error.responseJSON.message ? error.responseJSON.message : error;

            console.error(message);
        } 
    }

    logUsageTracking = async (requestUrl) => {
        await this.httpLoggerService.post_LogUsageTracking(requestUrl).catch((err) => this.logErrorToConsole(err));
    }

    logToServer = async (message, params) => {
        try {
            message = typeof(message) == 'object' ? JSON.stringify(message) : message;
            var currentPageName = window.location.pathname;
            var log = params || {};
            var requestUrl = log.url || "User/LogUserAction"; // The default url to post log to if none is supplied
            var pageName = log.pageName || currentPageName.substring(currentPageName.lastIndexOf('/') +1) || "GreatEdge (Home/Default)";
            var msg = message ? pageName + " - " + message : null;

            if(msg)
                await this.httpLoggerService.post_LogToService(requestUrl, msg)
            
        } 
        catch(error) {
            this.logErrorToConsole(error);
        }
    }
}


const httpLoggerService = function() {

    this.post_LogUsageTracking = async (requestUrl) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("User/Usage", "POST", requestUrl).done(() => resolve(true)).catch((error) => reject(error));
        });
    }
    
    this.post_LogToService = async (requestUrl, msg) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest(requestUrl, 'post', msg, true).done(() => resolve(true)).catch((error) => reject(error));
        })
    }
};

let logger = new Logger();
export default logger;