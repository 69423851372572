

import dataModel from 'data-model';
import {useDispatch, useSelector } from 'ko-data-store';
import {isLoading} from 'dataStore-actions/appUI';
import { setBtnDisabledState, setBtnEnabledState, datetimeUTC } from 'global-functions';
import toast from 'geNotyf';

const sendSaveRate = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/SaveRate", "POST", payload).done((rateNumber) => resolve(rateNumber))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while saving.`));
    })
}

const fetchRateDetails = (rateNumber) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/RateDetails", "GET", {rateNumber}).done((rateDetails) => resolve(rateDetails))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while saving.`));
    })
}

const saveRate = async ({
    rateNumber,
    customer,
    commodity,
    trailerType,
    mode,
    collectionMethod,
    intrastate,
    effectiveDate,
    expirationDate,
    daysOfTheWeek,
    apiTenderRateGWTM,
    minWeight
}) => await sendSaveRate(({
    rateNumber,
    customer,
    commodity,
    trailerType,
    mode,
    collectionMethod,
    intrastate,
    effectiveDate,
    expirationDate,
    daysOfTheWeek,
    apiTenderRateGWTM,
    minWeight
}));

const rateEntryFormVM = function({
    id = undefined,
    rateNumber = undefined,
    customer = undefined,
    commodityExId = undefined,
    trailerTypeExId = undefined,
    mode = undefined,
    collectionMethod = undefined,
    intrastate = undefined,
    effectiveDate = undefined,
    expirationDate = undefined,
    daysOfTheWeek = {
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true
    },
    apiTenderRateGWTM = false,
    minWeight = undefined,
    errorMsg = undefined
} = {}) {
    const vm = this;
    vm.id = id;
    vm.rateNumber = ko.observable(rateNumber).extend({ notify: 'always'});
    vm.customer = ko.observable(customer);
    vm.commodity = ko.observable(commodityExId);
    vm.trailerType = ko.observable(trailerTypeExId);
    vm.mode = ko.observable(mode == "L" ? "LTL" : (mode == "T" ? "TL" : 'Both'));
    vm.collectionMethod = ko.observable(collectionMethod);
    vm.intrastate = ko.observable(intrastate || "Any");

    vm.effectiveDate = ko.observable(effectiveDate);
    vm.expirationDate = ko.observable(expirationDate);

    vm.daysOfTheWeek = {
        sunday: ko.observable(daysOfTheWeek.sunday),
        monday: ko.observable(daysOfTheWeek.monday),
        tuesday: ko.observable(daysOfTheWeek.tuesday),
        wednesday: ko.observable(daysOfTheWeek.wednesday),
        thursday: ko.observable(daysOfTheWeek.thursday),
        friday: ko.observable(daysOfTheWeek.friday),
        saturday: ko.observable(daysOfTheWeek.saturday),   
    }

    vm.minWeight = ko.observable(minWeight);

    vm.apiTenderRateGWTM = ko.observable(apiTenderRateGWTM)

    vm.isFormValid = () => {
        return {
            result: vm.effectiveDate() != undefined,
            message: "Effective Date is required."
        }
    }

    vm.error = ko.observable();

    vm.validate = () => {
        vm.error("");

        if(vm.isFormValid().result == false) {
            vm.error(vm.isFormValid().message);
            return false;
        }

        return true;
    }
}

const rateEntryPanelComponentViewModel = function({
    activeRateNumber = ko.observable()
}) {
    const vm = this;
    const dispatch = useDispatch();

    vm.rateEntryForm = ko.observable(new rateEntryFormVM());
    
    const getRateDetails = async (rateNumber) => {

        if(rateNumber > 0 == false) return {};

        dispatch(isLoading(true));
        try {
            const rateDetails = await fetchRateDetails(rateNumber);
            dispatch(isLoading(false));
            return rateDetails
        }
        catch(err) {
            dispatch(isLoading(false));
            return { errorMsg: err };
        }
    }

    activeRateNumber.subscribe(async (val) => {
        if(val > 0) {
            vm.rateEntryForm(new rateEntryFormVM(await getRateDetails(val)))
        }
        else {
            vm.rateEntryForm(new rateEntryFormVM());
        }
    })

    vm.handleSaveRate = async(x, event) => {
        if(vm.rateEntryForm().validate() == false) {
            return false;
        }

        setBtnDisabledState(event, "Saving...");
        try {
            
            const rateNumber = await saveRate({...vm.rateEntryForm(), intrastate: (vm.rateEntryForm().intrastate() && vm.rateEntryForm().intrastate() == "Any" ? null : vm.rateEntryForm().intrastate()),
                mode: (vm.rateEntryForm().mode() && vm.rateEntryForm().mode() == 'Both' ? null : vm.rateEntryForm().mode()) 
            })
            
            vm.rateEntryForm().rateNumber(rateNumber);
            activeRateNumber(rateNumber);

            toast.open({
                type: "info",
                message: "Rate saved successfully."
            })
        }
        catch(err) {
            vm.rateEntryForm().error(err);
        }

        setBtnEnabledState(event, "Save");
    }

    vm.handleResetForm = () => {
        Object.keys(vm.rateEntryForm()).forEach((key) => {
            if(ko.isWritableObservable(vm.rateEntryForm()[key])) {
                vm.rateEntryForm()[key](undefined);
            }
        })

        Object.keys(vm.rateEntryForm().daysOfTheWeek).forEach((key) => {
            if(ko.isWritableObservable(vm.rateEntryForm().daysOfTheWeek[key])) {
                vm.rateEntryForm().daysOfTheWeek[key](true);
            }
        })
    }

    const init = async () => {
        vm.rateEntryForm(new rateEntryFormVM(await getRateDetails(activeRateNumber())))
    }

    init();
    
}

import template from './rate-entry-panel-component.html';
export default { viewModel: rateEntryPanelComponentViewModel, template: template }