import dataModel from "data-model";
import userProfile from "user-profile";
import { isEmpty } from "global-functions";

class EDIPreferencesViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};
        this.isEDIPreferencesLoading = ko.observable(false);

        this.isProcessing = ko.observable(false);
        this.saveBtnText = ko.computed(() => {
            return this.isProcessing() ? "Saving..." : "Save";
        });

        this.customerSelected = ko.observable(false);
        this.selectedCustomer = ko
            .observable()
            .extend({ required: { message: "Please select a customer" } });

        this.selectedCustomer.subscribe((newVal) => {
            if (isEmpty(newVal) == false) {
                this.getEDIPreferences(newVal);
            } else {
                this.resetForm();
            }
        });

        this.acceptOriginalChanged = ko.observable(false);
        this.acceptChangesChanged = ko.observable(false);
        this.acceptCancelChanged = ko.observable(false);

        this.acceptOriginal = ko.observable();
        this.acceptChanges = ko.observable();
        this.acceptCancel = ko.observable();

        this.EDIMessage = ko.observable();
        this.customerName = ko.observable();
        this.customerId = ko.observable();

        this.ediCustomerSelected = (sender) => {
            if (sender != undefined) {
                this.customerName(sender.name);
            }
        };

        this.errors = ko.observable();
    }

    resetForm = () => {
        this.customerSelected(false);
        this.selectedCustomer(null);
        this.selectedCustomer.isModified(false);
        this.EDIMessage(undefined);
    };

    async getEDIPreferences(customerId) {
        var data = {
            agencyId: dataModel.currentAgencyId(),
            customerId: customerId,
        };

        const result = await this.http_getCustomerEDIPreferencesAsync(
            data
        ).catch((err) => console.error(err));

        if (result) {
            this.acceptOriginal(result.acceptOriginal.toString());
            this.acceptChanges(result.acceptChanged.toString());
            this.acceptCancel(result.acceptCancelled.toString());
            this.customerSelected(true);
        }
    }

    handleSavePreferences = (data) => {
        this.doHandleSavePreferences(data);
    };

    async doHandleSavePreferences(data) {
        const errors = ko.validation.group(data.selectedCustomer);
        if (errors().length > 0) {
            errors.showAllMessages();
            return false;
        }

        this.isProcessing(true);
        data = {
            customerId: data.selectedCustomer(),
            acceptOriginal: data.acceptOriginal(),
            acceptChanged: data.acceptChanges(),
            acceptCancelled: data.acceptCancel(),
        };

        const result = await this.http_postPreferencesAsync(data).catch((err) =>
            this.errors(err)
        );

        if(!this.errors()) {
            this.EDIMessage("Save Success!")

            setTimeout(() => {
                this.EDIMessage("");
            }, 2500);
        }

        this.isProcessing(false);
    }

    http_postPreferencesAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/SaveEDIPreferences", "put", payload)
                .done((result) => resolve(result))
                .fail((err) => {
                    if (err.responseJSON && err.responseJSON.message)
                        reject(err.responseJSON.message);

                    console.error(err);
                });
        });
    };

    http_getCustomerEDIPreferencesAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/GetSelectedCustomerEDIPreferences",
                    "get",
                    payload
                )
                .done((data) => resolve(data))
                .fail((err) => reject(err));
        });
    };
}

import template from "./edi-preferences-component.html";
export default { viewModel: EDIPreferencesViewModel, template: template };
