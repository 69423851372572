import dataModel from 'data-model';
import {useDispatch, useSelector } from 'ko-data-store';
import { isLoading } from 'dataStore-actions/appUI';
import toast from 'geNotyf';

const fetchRateAccessorialsAsync = (rateNumber) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/Accessorials", "GET", {rateNumber}).done((result) => resolve(result))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `Failed to fetch records.`));
    })
}

const fetchPayMethodAsync = (chargeCode) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("ChargeCode/GetPayMethod", "GET", {code: chargeCode}).done((response) => resolve(response))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `Failed to fetch pay method.`));
    })
}

const sendRateAccessorialsAsync = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/Accessorials", "POST", payload).done((result) => resolve(result))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `Failed to save records.`));
    })
}

const sendDeleteRateAccessorialAsync = (rateNumber, accessorialId) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/DeleteAccessorial", "POST", {rateNumber, accessorialId}).done((result) => resolve(result))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `Failed to delete record.`));
    })
}
const getRateAccessorialsAsync = async (rateNumber) => await fetchRateAccessorialsAsync(rateNumber).catch(() => []);
const getPayMethodAsync = async (chargeCode) => await fetchPayMethodAsync(chargeCode).catch(() => {});

const deleteRateAccessorialAsync = async (rateNumber, accessorialId) => await sendDeleteRateAccessorialAsync(rateNumber, accessorialId).catch(() => null);

const saveRateAccessorialAsync = async ({
    id = undefined,
    rateId = undefined,
    rateNumber = undefined,
    description = undefined,
    code = undefined,
    method = undefined,
    amount = undefined,
    rate = undefined,
    units = undefined
}) => await sendRateAccessorialsAsync({
    id,
    rateId,
    rateNumber,
    description,
    code,
    method,
    amount,
    rate,
    units
})

const accessorialModel = function({
    id = undefined,
    rateId = undefined,
    rateNumber = undefined,
    description = undefined,
    code = undefined,
    method = undefined,
    amount = undefined,
    rate = undefined,
    units = undefined
} = {}) {

    this.id = ko.observable(id);
    this.rateId = ko.observable(rateId);
    this.rateNumber = ko.observable(rateNumber);
    this.description = ko.observable(description).extend({notify: 'always'});
    this.code = ko.observable(code).extend({required: true, notify: 'always'});
    this.method = ko.observable(method);
    this.amount = ko.observable(amount).extend({required: true});
    this.rate = ko.observable(rate).extend({required: true});
    this.units = ko.observable(units).extend({required: true});

    this.selectedCode = ko.observable();
    this.selectedCode.subscribe((val) => {
        if(val) {
            this.code(val.code);

            if(this.id() > 0 == false) {
                this.description(this.description() || val.description);
                this.amount(this.amount() || 1);
            }

            handleChargeCodeChange(val.code);
        }
        else {
            this.code(undefined);
            this.method(undefined);
        }
    })

    this.modelErrors = () => {
        const validationGrp = ko.validation.group(this);
        return validationGrp;
    }

    this.isValid = ko.pureComputed(() => {
        let result = true;

        if(!this.code()) {
            result = false;
        }

        if(!this.rate()) {
            result = false;
        }

        if(!this.units()) {
            result = false;
        }

        return result;
    });


    const handleChargeCodeChange = async (chargeCode) => {
        if(chargeCode) {
            const result = await getPayMethodAsync(chargeCode) || {};
            this.method(result.payMethod);
        }
    }
}

export const accessorialViewModel = function({
    rateNumber = ko.observable()
} = {}) {
    const vm = this;
    const dispatch = useDispatch();

    let accessorialsList = []
    vm.accessorials = ko.observableArray([]);
    vm.error = ko.observable();

    vm.handleAddAcessorial = () => {
        const newModel = new accessorialModel({rateNumber: rateNumber()});

        accessorialsList.unshift(newModel);
        vm.accessorials(accessorialsList);
    }

    vm.handleDeleteAccessorial = async ($index) => {
        const item = vm.accessorials().find((x, index) => index == $index());

        if(item) {

            accessorialsList = accessorialsList.filter((x) => x != item);
            vm.accessorials(accessorialsList);

            if(item.id() > 0) {
                await deleteRateAccessorialAsync(rateNumber(), item.id());
            }

        }
    }

    vm.handleClearFilters = () => {
        vm.codeFilter(undefined);
        vm.descriptionFilter(undefined);
    }

    const handleFilter = () => {
        const description = vm.descriptionFilter();
        const code = vm.codeFilter();

        const filtered = accessorialsList.filter(x => {
            return (code == undefined || x.code() && x.code().toUpperCase().indexOf(code.toUpperCase()) > -1) &&
                    (description == undefined || x.description() && x.description().toUpperCase().indexOf(description.toUpperCase()) > -1);
        });

        vm.accessorials(filtered);
    }

    vm.codeFilter = ko.observable();
    vm.codeFilter.subscribe(handleFilter)
    
    vm.descriptionFilter = ko.observable();
    vm.descriptionFilter.subscribe(handleFilter)

    vm.handleSave = async ($model) => {
        vm.error("");

        if($model && Object.keys($model).length) {
            
            if($model.isValid() == false) {
                $model.modelErrors().showAllMessages();
                return false;
            }

            try {
                const id = await saveRateAccessorialAsync(ko.toJS($model));
                $model.id(id);

                toast.open({type:'info', message: 'Saved accessorial successfully.'});
            }
            catch(err) {
                vm.error(err);
            }
        }
    }

    const init = async() => {
        
        const result = await getRateAccessorialsAsync(rateNumber());
        accessorialsList = result.map(x => new accessorialModel(x));
        vm.accessorials(accessorialsList);
    }

    init();

}

import template from './accessorial-component.html';
export default { viewModel: accessorialViewModel, template: template }