import dataModel from "data-model";
import userProfile from "user-profile";
import { setBtnDisabledState, setBtnEnabledState } from "global-functions";

class EditOrderEntryArrangementComponent {
    constructor(params) {
        params = params || {};
        this.formData = params.formData || {};
        this.parentModel = params.parentModel || {};

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.isProcessing = ko.observable(false);

        this.pageName = ko.observable("Orders");
        this.myAgency = ko.observable(this.formData.agency);
        this.oeaErrorMessage = ko.observable("");
        this.showSaveButton = ko.pureComputed(() => {
            return this.isValidName() && this.hasFieldNameChanged();
        });
        this.oeaID = ko.observable(this.formData.id);
        this.hasFieldNameChanged = ko.observable(false);

        this.originalFieldName = ko.observable(this.formData.fieldNameId);
        this.fieldName = ko
            .validatedObservable(this.formData.fieldName)
            .extend({
                required: {
                    message: "Field Name is required",
                },
            });
        this.fieldName.subscribe((newVal) => {
            if (this.originalFieldName() != newVal) {
                this.hasFieldNameChanged(true);
            } else {
                this.hasFieldNameChanged(false);
            }
        });

        this.isValidName = ko.pureComputed(() => {
            return this.fieldName.isValid();
        });
    }

    handleSave = (data, event) => {
        this.doHandleSave(data, event);
    };

    async doHandleSave(data, event) {
        const vm = this;
        data = {
            Id: this.oeaID(),
            fieldNameId: this.fieldName(),
            agencyId: dataModel.currentAgencyId(),
        };

        setBtnDisabledState(event, "Saving...");
        const result = await vm
            .http_sendSave(data)
            .catch((errMsg) => vm.oeaErrorMessage(errMsg));

        setBtnEnabledState(event, "Save");

        if (!vm.oeaErrorMessage()) vm.doWhenSaveSuccess();
    }

    doWhenSaveSuccess = () => {
        this.$emitsOnAction();
    };

    http_sendSave = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/UpsertOrderEntryArrangement",
                    "post",
                    payload
                )
                .done((response) => resolve(response))
                .fail((err) => {
                    console.error(err);

                    reject(
                        (err.responseJSON && err.response.message) ||
                            "An error occurred while saving."
                    );
                });
        });
    };
}

import template from "./edit-order-entry-arrangement-component.html";
export default {
    viewModel: EditOrderEntryArrangementComponent,
    template: template,
};
