
import { useDispatch } from 'ko-data-store';
import * as tvCommon from './tvCommon';
import userprofile from 'user-profile';

import CategoriesComponent from './categories-component/categories-component';
ko.components.register("trainingvideo-categories-component", CategoriesComponent);
import ContentHeaderComponent from './content-header-component/content-header-component';
ko.components.register("trainingvideo-content-header-component", ContentHeaderComponent);
import VideoListComponent from './video-list-component/video-list-component';
ko.components.register("trainingvideo-video-list-component", VideoListComponent);
import PlayerComponent from './player-component/player-component';
ko.components.register("trainingvideo-videoplayer-component", PlayerComponent);
import EntryComponent from './entry-component/entry-component';
ko.components.register("trainingvideo-entry-component", EntryComponent);


export const TrainingVideosViewModel = function() {
    const vm = this;
    const dispatch = useDispatch();

    vm.categories = ko.observableArray([]);
    vm.subCategoriesList = ko.observableArray([]);
    vm.activeSelectCategory = ko.observable();

    vm.currentFilter = ko.observable(new tvCommon.FilterableModel());
    vm.videoContentVM = ko.observable(new tvCommon.VideoContentModel());

    vm.videoPlayerContent = ko.observable();
    vm.videoEditModel = ko.observable();

    vm.allowVideoEntryEdits = ko.observable(false);
    
    vm.handleVideoEdit = ($data, event) => {
        if($data) {
            vm.videoEditModel($data);
        }
        else {
            vm.videoEditModel({...new tvCommon.TrainingVideoModel()})
        }
    }

    vm.expandElement = ko.pureComputed(() => {
        return vm.activeSelectCategory() && vm.activeSelectCategory().subCategories().length > 0
    })

    vm.handleMobileMenuDisplay = ($x, event) => {
        const $menu =  $("#category-list-view");

        if($menu.hasClass('open')) {
            $menu.animate({left: '-105%'}, 'fast', () => {
                $('body').removeClass('modal-open')
                $menu.removeClass('open')
            });
        }
        else {
            $menu.animate({left: '0px'}, 'fast', () => {
                $('body').addClass('modal-open')
                $menu.addClass('open')
            });
        }

        event.stopPropagation();
    }

    vm.handleResetVideoContent = async () => {
        vm.activeSelectCategory(null)
        const recentVideos = await tvCommon.services.getRecentVideosAsync();
        vm.videoContentVM(new tvCommon.VideoContentModel({subheader: "Most Recent", videoList: recentVideos }));
    }

    const init = async () => {
        const categories = await tvCommon.services.getCategoriesAsync();
        vm.categories(categories.map(x => new tvCommon.VideoCategoryModel(x)))
        
        vm.handleResetVideoContent();

        const userRoles = await userprofile.roles();
        vm.allowVideoEntryEdits(userRoles.some(x => x && ["Training Video Editor", "Administrators"].indexOf(x) > -1))
    }

    init();
}

import template from './training-videos-page.html';
export default { viewModel: TrainingVideosViewModel, template: template }