import dataModel from "data-model";
import userProfile from "user-profile";
import ko from "knockout";
import template from "./post-load-component.html";
import {
  scrollToRequiredField,
  mapKOtoJS,
  mapToCityStateZipObject,
  cleanString,
} from "global-functions";
import dayjs from "dayjs";
import _ from "lodash";
import { getUserBoards } from "../../pages/new-external-board-page/new-external-board-page";
import { showmessage, showmustdismiss } from "show-dialog-methods";
import RateService, {
  TPIMarketRates,
  TPIRateRequest,
} from "../../../services/RateService";

const getExclusiveAgenciesAsync = (customerId) => {
  return new Promise((resolve) => {
    dataModel
      .ajaxRequest("Customer/VerifyExclusiveAgencies", "GET", { customerId })
      .then((agencies) => resolve(agencies))
      .catch((err) => {});
  });
};

const getExternalBoardError = (loadId) => {
  return new Promise((resolve) => {
    dataModel
      .ajaxRequest("FreightBoard/GetExternalBoardError", "GET", { loadId })
      .then((agencies) => resolve(agencies))
      .catch((err) => {});
  });
};

const checkForExternalBoardErrors = async (loadId) => {
  try {
    const error = await getExternalBoardError(loadId);

    if (error) {
      await showmustdismiss(
        `Posting load to board ${error.boardName} failed. Reason: ${error.reason}`
      );
    }

    return error != null;
  } catch (err) {
    return false;
  }
};

class LoadChargeViewModel {
  constructor(charge) {
    this.chargeId = ko.observable(charge.chargeId).extend({
      required: {
        message:
          "The code you have entered does not exist.  Please select another code or contact the Billing Department for the correct code to use.",
      },
    });

    this.description = ko.observable(charge.description);
    this.units = ko
      .observable(charge.rate || 1)
      .extend({ required: { message: "Units is required" } });
    this.rate = ko
      .observable(charge.units)
      .extend({ required: { message: "Rate is required" } });
    this.totalCharge = ko.computed(() => {
      return this.rate() * this.units();
    });

    this.chargeSelected = (item) => {
      if (item) {
        this.description(item.description);
      }
    };
  }
}

class DaysOfWeekModel {
  constructor() {
    this.days = [
      { label: "Monday", value: 1, checked: true },
      { label: "Tuesday", value: 2, checked: true },
      { label: "Wednesday", value: 3, checked: true },
      { label: "Thursday", value: 4, checked: true },
      { label: "Friday", value: 5, checked: true },
      { label: "Saturday", value: 6, checked: true },
      { label: "Sunday", value: 7, checked: true },
    ];

    this.loadDaysOfWeek = (postingDays) => {
      var tempDaysOfWeek = this.days;
      if (postingDays != null) {
        $.each(tempDaysOfWeek, (index) => {
          var item = this;
          if (postingDays.monday == true && item.value == 1) {
            item.checked = true;
          } else if (postingDays.tuesday == true && item.value == 2) {
            item.checked = true;
          } else if (postingDays.wednesday == true && item.value == 3) {
            item.checked = true;
          } else if (postingDays.thursday == true && item.value == 4) {
            item.checked = true;
          } else if (postingDays.friday == true && item.value == 5) {
            item.checked = true;
          } else if (postingDays.saturday == true && item.value == 6) {
            item.checked = true;
          } else if (postingDays.sunday == true && item.value == 7) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
      }
      return tempDaysOfWeek;
    };
  }
}

class CentralModel {
  constructor(parentModel) {
    self.selectedAgency = ko.observable(null);
    self.agencySelectList = userProfile.userAgencies();
    self.selectedContact = ko.observable(null);
    self.contactSelectList = ko.observableArray([]);
    self.displayCentralErrorMsg = ko.observable();
    self.centralOverride = ko.observable(false);

    var _parentModel = parentModel || {};

    self.selectedAgency.subscribe((code) => {
      if (code) {
        self.displayCentralErrorMsg(null);
        self.contactSelectList.removeAll();
        self.selectedContact(null);
        _getContactsForAgency(code);
      }
    });

    self.selectedContact.subscribe((val) => {
      if (val) {
        self.displayCentralErrorMsg(null);
      }
    });

    var agencyListLoaded = false;
    self.centralOverride.subscribe((val) => {
      if (val == false) {
        _resetViewModel();
      } else if (val && agencyListLoaded == false) {
      }
    });

    // Using validation method as the fields in this model are only required
    // when one or more of this model's fields is being used.
    self.validateModel = function () {
      self.displayCentralErrorMsg(null);
      var _result = {
        isValid: true,
        errorMsg: null,
      };

      var _contact = self.selectedContact();
      if (self.selectedAgency() == null) {
        _result.errorMsg = "Please select agency from the list.";
      } else if (self.selectedAgency() && _contact == null) {
        _result.errorMsg = "Please select a contact from the list.";
      } else if (self.selectedAgency() && _contact != null) {
        if (
          _contact.name == null &&
          _contact.email == null &&
          _contact.phone == null
        ) {
          _result.errorMsg =
            "The selected user doesn't have any contact information.";
        }
      }

      _result.isValid = _result.errorMsg == null;
      return _result;
    };

    self.setDisplayCentralErrorMsg = (message) => {
      if (message) {
        self.displayCentralErrorMsg(message);
      }
    };

    function _resetViewModel() {
      self.contactSelectList.removeAll();
      self.selectedContact(null);
      self.selectedAgency(null);
      self.displayCentralErrorMsg(null);
    }

    function _getContactsForAgency(code) {
      if (code == null) return;
      _parentModel.isLoading(true);
      dataModel
        .ajaxRequest("User/GetUsersByAgency", "GET", { agency: code })
        .done((resp) => {
          if (resp) {
            _buildUserContactList(resp.records).then(function (x) {
              _parentModel.isLoading(false);
            });
          }
        })
        .fail((error) => {
          _parentModel.isLoading(true);
          console.error("Error getting items for contact droplist.");
        });
    }

    function _buildUserContactList(data) {
      return new Promise((resolve, reject) => {
        if (data == null) reject(false);
        $.each(data, (index, item) => {
          self.contactSelectList.push({
            username: item.username,
            agencyExId: item.agency,
            name: item.name,
            email: item.email,
            phone: item.phone,
          });
        });
        resolve(true);
      });
    }
  }
}

var LoadModel = function (load, mainModel) {
  var self = this;
  self.errors = ko.observableArray([]);
  self.responseMessage = ko.observable();
  self.isLoading = ko.observable(false);
  self.centralModel = ko.observable();
  self.pageModel = mainModel.pageModel || {};
  self.isReadOnly = ko.observable(load.expired || false);

  self.tpiMarketRates = ko.observable(TPIMarketRates());

  if (userProfile.isGwEmployee != false)
    self.centralModel(new CentralModel(self));

  self.expired = ko.observable(load.expired || false);
  self.isNewLoad = ko.observable(load.id == null);
  self.loadId = ko.observable(load.id);
  self.isRecurringPost = ko.observable(load.isRecurringPost || false);
  self.daysOutToPost = ko.observable(load.daysOutToPost || 3);

  // Filtering out 'CNS' Conestoga until DAT api update(s)
  self.tempTrailerTypeFilter = (data) =>
    (data || []).filter((x) => x.code && x.code.toUpperCase() !== "CNS");

  self.repeatPostingLenght = ko
    .observable(load.repeatPostingLenght || 10)
    .extend({
      required: {
        onlyIf: function () {
          if (self.isRecurringPost() == true) {
            return true;
          }
          return false;
        },
      },
    });
  self.editAllRecurringPostings = ko.observable(false);

  self.showPostingDays = ko.computed(function () {
    if (self.isNewLoad() || self.editAllRecurringPostings()) {
      return true;
    }
    return false;
  });

  self.quantity = ko.observable(load.quantity != null ? load.quantity : 1);
  self.noBrokeredCustomerSelected = ko.observable(false);
  self.brokered = ko.observable(load.brokered ? "Yes" : "No");

  self.customerId = ko.observable(load.customerId).extend({
    required: {
      message: "Customer is required",
      onlyIf: function () {
        if (self.isRecurringPost() == true) {
          return true;
        }
        return false;
      },
    },
  });

  self.orderIds = ko.observableArray(load.orderIds || []);

  self.selectedCustomer = ko.observable();
  self.mileageCalcType = ko.observable("Shortest Miles");
  self.mileageCalcSystem = ko.observable("Intelliroute");
  self.calcMethod = ko.observable("Z");
  self.selectedCustomer.subscribe(function (item) {
    self.exclusiveAgencyError("");

    if (item != null) {
      if (item.noBroker == true) {
        self.brokered("No");
        self.noBrokeredCustomerSelected(true);
      } else {
        self.noBrokeredCustomerSelected(false);
      }

      self.calcMethod(item.mileageCalcMethod);

      if (item.mileageCalcSystem == "I") {
        self.mileageCalcSystem("Intelliroute");
      } else {
        self.mileageCalcSystem("PC Miler");
      }
      if (item.mileageCalcType == "P") {
        self.mileageCalcType("Practical Miles");
      } else {
        self.mileageCalcType("Shortest Miles");
      }

      handleCheckIsCustomerExclusiveToAgency(item.id);
    }
  });

  self.exclusiveAgencyError = ko.observable("");
  const handleCheckIsCustomerExclusiveToAgency = async (id) => {
    const agencies = (await getExclusiveAgenciesAsync(id)) || [];
    if (agencies && agencies.length) {
      self.exclusiveAgencyError(agencies.join(","));
    } else {
      self.exclusiveAgencyError("");
    }
  };

  self.trailerTypeId = ko
    .observable(load.trailerType)
    .extend({ required: { message: "Trailer type is required" } });
  self.originLocationId = ko.observable(load.originLocationId);
  self.destLocationId = ko.observable(load.destLocationId);
  self.originReadOnly = ko.computed(function () {
    if (self.originLocationId() != null || self.isReadOnly()) {
      return true;
    }
    return false;
  });

  self.destinationReadOnly = ko.computed(function () {
    if (self.destLocationId() != null || self.isReadOnly()) {
      return true;
    }
    return false;
  });

  self.originCityStateZip = ko.observable(load.originCityStateZip).extend({
    required: {
      message: "Origin city, state or zip is required",
    },
  });

  self.destCityStateZip = ko.observable(load.destCityStateZip).extend({
    required: {
      message: "Origin city, state or zip is required",
    },
  });

  self.pickUpDate = ko.observable(load.pickUpDate).extend({
    required: {
      message: "Pickup time is required",
    },
  });

  self.deliveryDate = ko
    .observable(load.deliveryDate)
    .extend({
      required: {
        message: "Pickup time is required",
      },
    })
    .extend({
      validation: {
        message: "Delivery date must be after pickup date",
        validator: function () {
          if (
            ko.unwrap(self.deliveryDate) != null &&
            self.pickUpDate() > self.deliveryDate()
          ) {
            return false;
          }
          return true;
        },
      },
    });

  self.pickUpLatestDate = ko.observable(load.pickUpLatestDate).extend({
    validation: {
      message: function () {
        return (
          "Latest date must be after " +
          dayjs(self.pickUpDate()).format(userProfile.dateTimeFormat)
        );
      },
      validator: function () {
        if (
          ko.unwrap(self.pickUpLatestDate) != null &&
          ko.unwrap(self.pickUpDate) > self.pickUpLatestDate()
        ) {
          return false;
        }
        return true;
      },
    },
  });
  self.deliveryLatestDate = ko.observable(load.deliveryLatestDate).extend({
    validation: {
      message: function () {
        return (
          "Latest date must be after " +
          dayjs(self.deliveryDate()).format(userProfile.dateTimeFormat)
        );
      },
      validator: function () {
        if (
          ko.unwrap(self.deliveryLatestDate) != null &&
          ko.unwrap(self.deliveryDate) > self.deliveryLatestDate()
        ) {
          return false;
        }
        return true;
      },
    },
  });

  self.loadCharges = ko.observableArray(
    ko.utils.arrayMap(load.loadCharges, function (charge) {
      return new LoadChargeViewModel(charge);
    })
  );

  self.billingDistance = ko.observable(load.miles || 0).extend({
    validation: {
      message: "Miles are required, select the Get Miles button",
      validator: function (value) {
        if (value > 0) {
          return true;
        } else {
          return false;
        }
      },
    },
    notify: "always",
  });

  self.lineHaul = ko.observable(load.lineHaul || 0).extend({
    min: 101,
    required: {
      message: "Line haul is required",
    },
  });

  self.totalCharge = ko.computed(function () {
    var total = 0;
    ko.utils.arrayForEach(self.loadCharges(), function (item) {
      total += parseFloat(ko.utils.unwrapObservable(item.totalCharge));
    });
    return self.lineHaul() + total;
  });

  self.commodityId = ko.observable(load.commodity);
  self.commodityDescription = ko.observable();

  self.loadType = ko.observable(load.loadType || "T");
  self.weight = ko.observable(load.weight);

  self.length = ko.observable(
    load.postingDimensions ? load.postingDimensions.length : undefined
  );
  self.height = ko.observable(
    load.postingDimensions ? load.postingDimensions.height : undefined
  );
  self.width = ko.observable(
    load.postingDimensions ? load.postingDimensions.width : undefined
  );

  self.carrierPay = ko.observable(load.carrierPay);
  self.hazmat = ko.observable(load.hazmat ? "Yes" : "No");

  self.brokerageBoards = ko.observableArray();

  self.boardMessage = ko.computed(function () {
    if (self.brokered() == "Yes") {
      var boards = ko.utils.arrayFirst(self.brokerageBoards(), function (item) {
        return item.checked;
      });
      if (boards == null) {
        return "You have de-selected all external boards. This load will only be posted to the GreatWide Carrier Board.";
      }
    }
    return "";
  });

  const loadBrokerageBoards = async (loadId = 0) => {
    const getBoardsForPostedLoad = async (id) => {
      return new Promise((resolve, reject) => {
        dataModel
          .ajaxRequest("FreightBoard/GetLoadExternalBoards/" + id)
          .done((response) => resolve(response));
      });
    };

    try {
      const postedLoadBoards = await getBoardsForPostedLoad(loadId);
      const defaultBoards = await getUserBoards();

      self.brokerageBoards(
        postedLoadBoards.map((x) => {
          if (loadId > 0 == false && defaultBoards) {
            if ((x.name || "").toUpperCase() == "TRUCKSTOP") {
              x.checked = defaultBoards.truckStop;
            }
            if ((x.name || "").toUpperCase() == "DAT") {
              x.checked = defaultBoards.dat;
            }
          }

          return {
            label: x.name,
            checked: x.checked,
            items: [],
          };
        })
      );
    } catch (err) {
      self.brokerageBoards([]);
    }
  };

  setTimeout(() => {
    loadBrokerageBoards(self.loadId());
  }, 300);

  self.isTeamRequired = ko.observable(load.isTeamRequired || false);
  self.originStopId = load.originStopId;
  self.destStopId = load.destStopId;
  self.orderStatus = ko.observable(load.orderStatus);
  self.orderId = ko.observable(load.orderId);
  self.comments = ko.observable(load.comments);
  self.internalComments = ko.observable(load.internalComments);
  self.agencyComments = ko.observable(load.agencyComments);

  self.isGetMilesLoading = ko.observable(false);

  self.getMilesText = ko.observable("Get Miles");
  self.isGetMilesLoading.subscribe(function (isLoading) {
    if (isLoading) {
      self.getMilesText("Calculating...");
    } else {
      self.getMilesText("Get Miles");
    }
  });

  self.getMiles = function (item) {
    self.errors.removeAll();
    var validationErrors = ko.validation.group([
      self.originCityStateZip,
      self.destCityStateZip,
    ]);
    if (validationErrors().length > 0) {
      validationErrors.showAllMessages();
      return false;
    }

    self.isGetMilesLoading(true);
    self.isLoading(true);

    var tmpStops = [];

    tmpStops.push(self.originCityStateZip());
    tmpStops.push(self.destCityStateZip());

    var hazmat = 0;
    if (self.hazmat() == "Yes") {
      hazmat = 1;
    }

    var mileageCalcSystem = "IR";

    if (self.mileageCalcSystem().lastIndexOf("P", 0) === 0) {
      mileageCalcSystem = "PCM;";
    }
    var mileageCalcType = "P";
    if (self.mileageCalcType().lastIndexOf("S", 0) === 0) {
      mileageCalcType = "S";
    }

    var data = {
      hazmat: hazmat,
      Stops: tmpStops,
      type: mileageCalcSystem,
      calcType: mileageCalcType,
      calcMethod: self.calcMethod(),
    };

    dataModel
      .ajaxRequest("Routing/CalculateDistance", "post", data)
      .done(function (data, status, xhr) {
        item.billingDistance(data);
        self.isGetMilesLoading(false);
        self.isLoading(false);
      })
      .fail(function (error, msg, d) {
        self.errors.push("An error occurred");
        self.isGetMilesLoading(false);
        self.isLoading(false);
      });
  };

  self.addCharge = function (item) {
    self.loadCharges.push(new LoadChargeViewModel({}));
  };
  self.removeCharge = function (item) {
    var currentIndex = self.loadCharges().indexOf(item);
    self.loadCharges.splice(currentIndex, 1);
  };

  self.loadDaysOfWeek = function (postingDays) {
    var days = new DaysOfWeekModel();
    return days.loadDaysOfWeek(postingDays);
  };

  self.postingDays = ko.observableArray(
    self.loadDaysOfWeek(load.recurringPost)
  );

  self.originDays = ko.observableArray(
    self.loadDaysOfWeek(load.originDaysOperation)
  );
  self.destDays = ko.observableArray(
    self.loadDaysOfWeek(load.deliveryDaysOperation)
  );

  self.isSaving = ko.observable(false);
  self.saveLoad = function (obj) {
    if (self.validateForm() == false) return false;

    var load = mapKOtoJS(obj, { remove: ["pageModel"] }); //ko.toJS(obj); <-- using ko.toJS() takes a long time, gets hung up for some reason now...

    var postingBoards = ko.utils.arrayMap(
      ko.utils.arrayFilter(self.brokerageBoards(), function (item) {
        return item.checked == true;
      }),
      function (item) {
        return item.label;
      }
    );

    var postDays = [];
    var originDays = [];
    var destinationDays = [];

    if (load.isRecurringPost == true) {
      postDays = ko.utils.arrayMap(
        ko.utils.arrayFilter(self.postingDays(), function (item) {
          return item.checked == true;
        }),
        function (item) {
          return item.value;
        }
      );
      originDays = ko.utils.arrayMap(
        ko.utils.arrayFilter(self.originDays(), function (item) {
          return item.checked == true;
        }),
        function (item) {
          return item.value;
        }
      );
      destinationDays = ko.utils.arrayMap(
        ko.utils.arrayFilter(self.destDays(), function (item) {
          return item.checked == true;
        }),
        function (item) {
          return item.value;
        }
      );

      if (originDays.length == 0 || destinationDays.length == 0) {
        self.errors.push("Please select at least one day of operation");
        return false;
      }
    }

    var isNewLoad = self.isNewLoad() == true || self.expired() == true;

    var tmpStops = new Array();

    tmpStops.push({
      id: isNewLoad ? null : load.originStopId,
      locationId: load.originLocationId,
      cityStateZip: load.originCityStateZip,
      arrival: dayjs(load.pickUpDate).toString(),
      latest: load.pickUpLatestDate
        ? dayjs(load.pickUpLatestDate).toString()
        : null,
      operatingDays: originDays,
    });
    tmpStops.push({
      id: isNewLoad ? null : load.destStopId,
      locationId: load.destLocationId,
      cityStateZip: load.destCityStateZip,
      arrival: dayjs(load.deliveryDate).toString(),
      latest: load.deliveryLatestDate
        ? dayjs(load.deliveryLatestDate).toString()
        : null,
      operatingDays: destinationDays,
    });

    var data = {
      customerExternalId: load.customerId,
      trailerType: load.trailerTypeId,
      stops: tmpStops,
      lineHaul: load.lineHaul,
      commodityId: load.commodityId,
      commodityDescription: load.commodityDescription,
      brokered: load.brokered == "Yes" ? true : false,
      hazmat: load.hazmat == "Yes" ? true : false,
      isTeamRequired: load.isTeamRequired,
      loadType: load.loadType,
      weight: load.weight,
      length: load.length,
      height: load.height,
      width: load.width,
      carrierPay: load.carrierPay,
      billingDistance: load.billingDistance,
      quantity: load.quantity,
      comments: load.comments,
      internalComments: load.internalComments,
      agencyComments: load.agencyComments,
      recurringPost: load.isRecurringPost,
      postLength: load.repeatPostingLenght,
      createdFromOrderId: self.orderId(),
      editAllRecurringPostings: load.editAllRecurringPostings,
      daysOutToPost: load.daysOutToPost,

      additionalCharges: ko.utils.arrayMap(load.loadCharges, function (item) {
        return {
          code: item.chargeId,
          description: item.description,
          units: item.units,
          rate: item.rate,
        };
      }),
      originDays: originDays,
      destinationDays: destinationDays,
      postDays: postDays,
      postingBoards: postingBoards,
    };

    data.postingSourceId = data.recurringPost == true ? 2 : 1;

    // Central Posting Overrides
    if (userProfile.isGwEmployee && load.centralModel != null) {
      var _centralModel = load.centralModel || {};

      if (_centralModel.centralOverride == true) {
        var validationResult = _centralModel.validateModel();
        if (validationResult.isValid == false) {
          _centralModel.setDisplayCentralErrorMsg(validationResult.errorMsg);
          return false;
        }

        data.overrideAgencyCode = _centralModel.selectedAgency;
        data.overrideUsername = _centralModel.selectedContact.username;
        data.overrideName = _centralModel.selectedContact.name;
        data.overridePhone = _centralModel.selectedContact.phone;
        data.overrideEmail = _centralModel.selectedContact.email;
      }

      data.centralOverride = _centralModel.centralOverride || false;
    }

    self.isLoading(true);
    self.isSaving(true);
    if (isNewLoad) {
      dataModel
        .ajaxRequest("FreightBoard/PostLoad", "post", {
          ...data,
          tpiRates: this.tpiMarketRates()?.rates,
        })
        .done(function (loadId, status, xhr) {
          self.isLoading(false);
          self.isSaving(false);

          checkForExternalBoardErrors(loadId).then(() => {
            $("#postLoadModal .close").trigger("click");
            mainModel.onLoadSave(loadId);
          });
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          self.isLoading(false);
          self.isSaving(false);
          if (jqXHR.responseJSON != null) {
            self.errors.push(jqXHR.responseJSON.message);
          } else {
            self.errors.push("An unknown error occurred");
          }
        });
    } else {
      dataModel
        .ajaxRequest("FreightBoard/UpdateLoad/" + self.loadId(), "post", data)
        .done(function (data, status, xhr) {
          self.isLoading(false);
          self.isSaving(false);

          checkForExternalBoardErrors(self.loadId()).then(() => {
            $("#postLoadModal .close").trigger("click");
            mainModel.onLoadSave(data);
          });
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          self.isLoading(false);
          self.isSaving(false);
          if (jqXHR.responseJSON != null) {
            self.errors.push(jqXHR.responseJSON.message);
          } else {
            self.errors.push("An unknown error occurred");
          }
        });
    }
  };

  self.saveButtonText = ko.computed(function () {
    if (self.isSaving()) {
      return "Saving...";
    } else if (self.isNewLoad() == false) {
      return "Update";
    } else {
      return "Save";
    }
  });

  self.expireLoad = function (obj) {
    self.errors.removeAll();
    self.isLoading(true);
    self.responseMessage(null);

    var data = {
      editAllRecurringPostings: self.editAllRecurringPostings(),
    };

    dataModel
      .ajaxRequest("FreightBoard/ExpireLoad/" + self.loadId(), "post", data)
      .done(function (data, status, xhr) {
        self.isLoading(false);
        self.expired(true);
        self.responseMessage("Load has been expired");

        setTimeout(() => {
          $("#postLoadModal .close").trigger("click");
          mainModel.onLoadExpire();
        }, 1500);
      })
      .fail(function (error, msg, d) {
        self.isLoading(false);
        self.errors.push("An unknown error occurred");
      });
  };

  self.originLocationSelected = function (item) {
    if (item != undefined) {
      if (item.zip != null) {
        self.originCityStateZip(
          item.city + ", " + item.state + " (" + item.zip + ")"
        );
      } else {
        self.originCityStateZip(item.city + ", " + item.state);
      }
    }
  };

  self.destLocationSelected = function (item) {
    if (item != undefined) {
      if (item.zip != null) {
        self.destCityStateZip(
          item.city + ", " + item.state + " (" + item.zip + ")"
        );
      } else {
        self.destCityStateZip(item.city + ", " + item.state);
      }
    }
  };

  self.isBrokeredReadOnly = ko.computed(function () {
    if (
      (self.noBrokeredCustomerSelected() == true &&
        (self.orderStatus() == "Available" ||
          self.orderStatus() == "Quote" ||
          self.orderStatus() == null)) ||
      self.isReadOnly()
    ) {
      return true;
    }
    return false;
  });

  self.getIsBrokered = ko.computed(function () {
    return (
      self.brokered() == "Yes" &&
      (self.orderStatus() == "Available" ||
        self.orderStatus() == "Quote" ||
        self.orderStatus() == null)
    );
  });

  self.validateForm = () => {
    self.responseMessage(null);
    self.errors.removeAll();

    var validationErrors = ko.validation.group(self, { deep: true });
    if (validationErrors().length > 0) {
      validationErrors.showAllMessages();

      scrollToRequiredField();

      return false;
    }

    return true;
  };

  self.showGetRatesSection = ko.pureComputed(() => {
    const boards = self.brokerageBoards() ?? [];
    const tpi = self.tpiMarketRates() ?? TPIMarketRates();
    return boards.some((x) => x.checked) || tpi.rates.length > 0;
  });

  self.disableGetRatesBtn = ko.pureComputed(() => {
    if (!self.originCityStateZip()) {
      return true;
    }

    if (!self.destCityStateZip()) {
      return true;
    }

    if (!self.pickUpDate()) {
      return true;
    }

    if (!self.trailerTypeId()) {
      return true;
    }

    return false;
  });

  self.showGetRatesButton = ko.pureComputed(() => {
    const boards = self.brokerageBoards() ?? [];
    return boards.some((x) => x.checked);
  });

  self.handleGetMarketRates = async () => {
    const request = TPIRateRequest();
    const loadData = ko.toJS(self);

    const originStop = loadData.originCityStateZip;
    const destinationStop = loadData.destCityStateZip;

    if (originStop) {
      const originCSZ = mapToCityStateZipObject(
        cleanString(["(", ")", ","], originStop).split(" ")
      );

      request.originCity = originCSZ.city;
      request.originState = originCSZ.state;
      request.originZip = originCSZ.zip;
    }

    if (destinationStop) {
      const destinationCSZ = mapToCityStateZipObject(
        cleanString(["(", ")", ","], destinationStop).split(" ")
      );

      request.destinationCity = destinationCSZ.city;
      request.destinationState = destinationCSZ.state;
      request.destinationZip = destinationCSZ.zip;
    }

    request.pickupDate = loadData.pickUpDate;
    request.transportationMode = loadData.loadType ? "LTL" : "TL";
    request.requestType = "LOAD";
    request.loadId = loadData.loadId;
    request.trailerType = loadData.trailerTypeId;
    request.skipStoringResult = !self.loadId();

    // request.externalBoardNames = loadData.brokerageBoards
    //   .filter((x) => x.checked)
    //   .map((x) => x.label.toUpperCase());

    request.externalBoardNames = ["DAT", "TRUCKSTOP"];

    self.isLoading(true);
    const result = await RateService.getTPIRates(request);
    self.isLoading(false);

    self.tpiMarketRates(result);
  };

  self.loadTPIRates = async () => {
    self.isLoading(true);
    const result = await RateService.getSavedRatesForLoad(load.id);
    self.isLoading(false);

    self.tpiMarketRates(result);
  };

  if (load.id > 0) {
    self.loadTPIRates();
  }
};

class PostLoadViewModel {
  load = ko.observable();

  constructor(params) {
    var vm = typeof params.vm == "function" ? params.vm() : params.vm;
    var currentLoad = vm.load != null ? vm.load : {};
    this.onLoadSave = params.onLoadSave || function (loadId) {};
    this.onLoadExpire = params.onLoadExpire || function () {};
    this.load(new LoadModel(currentLoad, this));
  }

  cancelLoad = () => {
    $("#postLoadModal").modal("hide");
  };
}

export default { viewModel: PostLoadViewModel, template: template };
