/// <reference path="../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/jqwidgets.d.ts" />

ko.bindingHandlers.geButton = {
  init: function (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) {
    var options = valueAccessor() || {};
    const allBindings = allBindingsAccessor();

    if (
      allBindingsAccessor() &&
      allBindingsAccessor().style &&
      allBindingsAccessor().style.width
    ) {
      options = {
        width: allBindingsAccessor().style.width,
      };
    }

    var button = $(element);

    if (allBindings.title) {
      if (typeof allBindings.title === "function") {
        const val = allBindings.title();
        button.attr({ title: val });
      } else if (ko.isSubscribable(allBindings.title)) {
        allBindings.title.subscribe((val) => button.attr({ title: val }));
      } else if (typeof allBindings.title === "string") {
        const val = allBindings.title;
        button.attr({ title: val });
      }
    }

    //options.name = button?.attr('name') || undefined;

    button.jqxButton(options);
    if (typeof options.disabled === "function") {
      ko.computed(function () {
        var isDisabled = options.disabled();
        button.jqxButton({
          disabled: isDisabled,
        });
      });
    }
  },
  update: function (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) {
    var g = "";
  },
};
