import ko from 'knockout'
import dataModel from 'data-model';
import template from './my-profile-page.html';
import router from '../../../router';
import userProfile from 'user-profile';
import { LoadboardSettingViewModel } from '../../shared-components/alert-settings-component/alert-settings-component';
import toast from 'geNotyf';

var RateConOption = function (data) {
    var self = this;
    data = data || {};
    
    self.userId = ko.observable(data.userId);
    self.rateConOptionId = ko.observable(data.rateConOptionId);
    self.displayOnReport = ko.observable(data.displayOnReport);
    self.description = ko.observable(data.description);

    return self;
}

var WireSettingsVM = function($parent) {
    var self = this;

    $parent = $parent || {};
    self.advanceAbilityEnabled  = ko.observable(false);
    self.advanceAbilityLockedOut =  ko.observable(false);
    self.display2FAModal = ko.observable(false);
    self.displayWireSettingsPasswordModal = ko.observable(false);
    
    self.panelWarningMsg = ko.observable();

    self.handleSetWirePassword = function() {
        // Reload settings to verify the user's hasn't been locked out since page load.
        self.loadSettings()
        .then(() => {
            if(self.advanceAbilityEnabled() && self.advanceAbilityLockedOut() == false) {
                self.display2FAModal(true);
            }
        });
    }

    self.loadSettings = function()  {
        return self.http_getWireSettings()
        .then((response) => {
            if(response) {
                self.advanceAbilityEnabled(response.advanceAbilityEnabled || false);
                self.advanceAbilityLockedOut(response.advanceAbilityLockedOut || false);

                if(response.passwordSetup == false) {
                    self.panelWarningMsg("If your Wire Passcode is not set up you cannot issue advances.");
                }
                else if(response.advanceAbilityLockedOut) {
                    self.panelWarningMsg("You have too many failed entry attempts. Please contact the Help Desk.");
                }
            }
        });
    }

    self.http_getWireSettings = function() {
        return new Promise(function(resolve, reject) {
            dataModel.ajaxRequest("MyProfile/WireSettings", "GET").done((response) => resolve(response))
            .catch((error) => reject(error));
        })
    }

    self.handle2FAConfirm = (result) => {
        self.display2FAModal(false);

        if(result) {
            self.displayWireSettingsPasswordModal(true);
        }
    }

    self.handleOnSettingsUpdate = (result) => {
        self.display2FAModal(false);

        if(result) {
            self.displayWireSettingsPasswordModal(true);
        }
    }

    self.handleWireSettingsPasswordClose = (result) => {
        if(result) self.panelWarningMsg("");

        self.displayWireSettingsPasswordModal(false);
    }

    self.loadSettings();
}

var MyProfileViewModel = function (params) {
    var self = this;
    self.isLoading = ko.observable(false);
    // Change password
    self.passwordFieldsVisible = ko.observable(true);
    self.isReadOnly = ko.observable(false);
    self.oldPassword = ko.observable().extend({ required: true });
    self.password = ko.observable().extend({ required: true, minLength: 6, });
    self.message = ko.observable("");
    self.confirmPassword = ko.observable().extend({
        minLength: 6,
        equal: {
            message: "Password doesn't match",
            params: self.password
        }
    });

    
    self.isNotOwnerOrDriver = ko.observable(!userProfile.isOwnerOperator && !userProfile.isUserDriver);
    self.ownedTractors = ko.observableArray([]);

    self.wireSettingsVM = new WireSettingsVM(self);
    self.thirdpartyLoadboardSettings = new LoadboardSettingViewModel({ data: self });

    // Agency and Mode
    self.UserName = ko.observable("");
    self.AgencyId = ko.observable("default");
    self.AgencyName = ko.observable("default");
    
    // Contact info
    self.name = ko.observable().extend({ required: true });
    self.email = ko.observable().extend({ email: true });
    self.phone = ko.observable().extend({ minLength: 10 });
    self.fax = ko.observable().extend({ minLength: 10 });
    self.contactMessage = ko.observable();
    // Search order filters
    self.searchOrderFilters = ko.observableArray();
    self.filterMessage = ko.observable("");

    // User saved settings
    self.userSavedSettings = ko.observableArray();
    self.userSettingsMessage = ko.observable("");

    // User Rate Confirmation Options
    self.userRateConOptions = ko.observableArray();
    self.userRateConOptionMessage = ko.observable("");

    // Misc Options
    self.autoAcceptTender = ko.observable(false);
    self.isUserDriver = ko.observable(userProfile.isOwnerOperator || userProfile.isUserDriver);
    self.saveMiscOptions = () => {
        dataModel.ajaxRequest("MyProfile/UpdateMiscOptions", "PUT",  {
            autoAcceptTender: self.autoAcceptTender()
        }, true).done(() => {
            toast.open({type: 'info', message:`Profile saved successfully`});
        })
        .fail((err) => {
            console.error(err);
            toast.error(`Error saving profile.`);
        })
    }

    // Orderplanning - Loadboard days before / after date filter
    self.daysBeforeInput = ko.observable().extend({max: 365, min: 0});
    self.daysAfterInput = ko.observable().extend({max: 365, min: 0});
    self.OLOptionsStatusMsg = ko.observable();
    self.handleSavingOLOptions = function() {
        var name = "Orderplanning/Loadboard Options";
        var filter = { daysBefore: self.daysBeforeInput(), daysAfter: self.daysAfterInput() };
        filter = JSON.stringify(filter);

        dataModel.ajaxRequest("UserProfile/SaveLoadboardOptions", "POST", {filters:filter, pageName: 'MyProfile', name: name}).done((response) => {
            self.OLOptionsStatusMsg("Options Saved.");

            setTimeout(function() {
                self.OLOptionsStatusMsg("");
            }, 3000);
        });
    }

    // The fun stuff: menu items
    // fun.....
    self.menuItems = ko.observableArray();
    self.selectedTimeType = ko.observable();
    self.timeFormat = ko.observable();
    self.miscOptionsMessage = ko.observable("");
    self.saveMiscTimeOptions = function() {
        self.isLoading(true);
        var data = {
            TimeFormat: self.timeFormat()
        };

        dataModel.ajaxRequest("MyProfile/UpdateModeAndTimeFormat", "POST", data)
        .done(function (data, status, xhr) {
            self.miscOptionsMessage("Your changes have been saved.");
            self.isLoading(false);
        }).fail(function (error, msg, d) {
            console.error(error + " || " + msg + " || " + " || " + d);
            self.miscOptionsMessage("An error has occurred.");
            self.isLoading(false);
        });
    }
    self.menuMessage = ko.observable();
    // Get all necessary info on load
    self.isLoading(true);
    dataModel.ajaxRequest("MyProfile/GetMyProfileInfo", "GET")
        .done(function (data, status, xhr) {
        
        self.timeFormat(data.dateTimeFormat);
        self.AgencyId(data.agencyInfo.externalId);
        self.AgencyName(data.agencyInfo.name);
        self.UserName(data.userName);
        self.passwordFieldsVisible(true);
        self.name(data.contactInfo.name);
        self.email(data.contactInfo.email);
        self.phone(data.contactInfo.phone);
        self.fax(data.contactInfo.fax);
        self.menuItems(data.menuItems);
        self.ownedTractors(data.ownedTractors && data.ownedTractors.map(x => ({label: x.tractorExId, value: x.tractorId})));
        self.autoAcceptTender(data.miscOptions && data.miscOptions.autoAcceptTenders);
        
        if(data.orderplanningLoadboardOptionFilters) {
            try {
                var options = JSON.parse(data.orderplanningLoadboardOptionFilters);
                self.daysBeforeInput(options.daysBefore);
                self.daysAfterInput(options.daysAfter);
            }
            catch(e) {}
        }


        //if(data.searchOrderFilters) {
        //    let filters = data.searchOrderFilters.map((x) => {

        //        if(x.pageName.indexOf("UseLoadBoardFilters") != -1) x.pageName = x.pageName.indexOf("MVC") != -1 ? "Loadboard" : "Orderplanning.aspx Loadboard";

        //        x.pageName = x.pageName.replace("/", "");

        //        return x;
        //    });

        //    self.searchOrderFilters(filters);
        //}

        
        if (self.searchOrderFilters().length === 0) {
            self.filterMessage("No filters to show.");
        } else {
            self.filterMessage("");
        }

        self.userSavedSettings(data.userSavedSettings);
        if (self.userSavedSettings().length === 0) {
            self.userSettingsMessage("No settings to show.");
        } else {
            self.userSettingsMessage("");
        }

        self.populateMenuItems(self.menuItems);
        self.menuMessage("");

        ko.utils.arrayMap(data.rateConOptions, function (item) {
            self.userRateConOptions.push(new RateConOption(item));
        });

        self.isLoading(false);
    })
    .fail(function (error, msg, d) {
        self.isLoading(false);
    });

    self.populateMenuItems = function () {
        $("#sortable").jqxSortable();
    }

    self.deleteSearch = function (val) {
        var savedSearches = self.searchOrderFilters();
        var index = savedSearches.findIndex(function (x) {
            return x.id == val.id;
        });
        savedSearches.splice(index, 1);
        self.searchOrderFilters(savedSearches);
        dataModel.ajaxRequest("UserProfile/DeleteUserSearchFilter/" + val.id).done(function (x) { });
    };

    self.deleteSavedSetting = function (val) {
        var savedSettings = self.userSavedSettings();
        var index = savedSettings.findIndex(function (x) {
            return x.id == val.id;
        });
        savedSettings.splice(index, 1);
        self.userSavedSettings(savedSettings);
        dataModel.ajaxRequest("MyProfile/DeleteUserSavedSetting/" + val.id).done(function (x) { });
    }

    self.updateMenuItems = function () {
        var data = $('#sortable').jqxSortable('toArray');
        self.menuMessage("");
        self.isLoading(true);
        dataModel.ajaxRequest("MyProfile/UpdateMenuItems", "POST", data)
        .done(function (data, status, xhr) {

        })
        .fail(function (error, msg, d) {
            self.menuMessage("An unexpected error occured causing your changes not to be saved.");
        }).then(router.refreshUserMenu)
        .then(() => {
            self.isLoading(false);
        })
    }

    self.validationErrors = ko.validation.group(self, { deep: false, live: true, observable: true });

    self.saveContactInfo = function () {

        var validation = {
            name: self.name,
            email: self.email,
            phone: self.phone,
            fax: self.fax
        };
        
        var val = ko.validation.group(validation);
        
        if(val().length > 0) {
            self.contactMessage('');
            val.showAllMessages();
            return false;
        }

        self.isLoading(true);
        var data = {
            name: self.name(),
            email: self.email(),
            phone: self.phone(),
            fax: self.fax()
        }
        dataModel.ajaxRequest("MyProfile/UpdateContactInfo", "POST", data)
        .done(function (data, status, xhr) {
            if (data.success) {
                self.contactMessage('Contact info updated.');
                self.getContactInfo();
                self.isLoading(false);
            } else {
                self.contactMessage(data.errorMessage);
                self.isLoading(false);
            }
        })
        .fail(function (error, msg, d) {
            self.isLoading(false);
        });

    }
    // Gets only the contact info
    self.getContactInfo = function () {
        dataModel.ajaxRequest("MyProfile/GetContactInfo", "GET")
        .done(function (data, status, xhr) {
            if (data.success) {
                self.name(data.contactInfo.name);
                self.email(data.contactInfo.email);
                self.phone(data.contactInfo.phone);
                self.fax(data.contactInfo.fax);
            }
        })
        .fail(function (error, msg, d) {

        });
    }

    // Change password does client side validation before sending it off to the API
    // API also does validation before authenticating the user and changing the password.
    self.changePassword = function () {
        if (self.validationErrors().length > 0) {
            self.validationErrors.showAllMessages();
            return false;
        }
        if (self.password() === self.oldPassword()) {
            self.message("New and old passwords match. Please select a new password.");
            return false;
        }
        // Submit to server here
        var data = {
            userName: self.UserName(),
            password: self.password(),
            confirmPassword: self.confirmPassword(),
            oldPassword: self.oldPassword()
        }
        self.isLoading(true);
        dataModel.ajaxRequest("MyProfile/ChangePassword", "POST", data)
        .done(function (data, status, xhr) {
            if (data.success) {
                self.message('Your password has been changed.');
                self.passwordFieldsVisible(false);
                self.isLoading(false);
            }
            else {
                self.message(data.errorMessage);
                self.isLoading(false);
            }
        })
        .fail(function (error, msg, d) {
            self.isLoading(false);
            console.error(error);
            console.error(msg);
            console.error(d);
        });
    }

    self.updateRateConOptions = function () {
        self.isLoading(true);
        self.userRateConOptionMessage("");

        var data = ko.toJS(self.userRateConOptions());

        dataModel.ajaxRequest("MyProfile/UpdateUserRateConOptions", "PUT", data).done(function (response) {
            if (response != undefined) {
                if (response.success) {
                    self.userRateConOptionMessage("Updated successfully.");

                    setTimeout(function () {
                        self.userRateConOptionMessage("");
                    }, 3500);
                }
                if (response.success == false) {
                    self.userRateConOptionMessage(response.errorMessage);
                }
                self.isLoading(false);
            }
        }).fail(function (error) {
            self.isLoading(false);
        });

    }

    self.advancedTractorAlertSettings = ko.observable();
    self.selectedTractor = ko.observable();
    self.alertErrorMsg = ko.observable();
    self.handleManageAlertSettings = async () => {
        let settings = { tractorId: self.selectedTractor() };
        self.alertErrorMsg("");

        if(settings.tractorId > 0 == false) self.advancedTractorAlertSettings(null);

        try {
            const tractorSettings = await fetchTractorAlertSettings(settings.tractorId);
            
            if(tractorSettings != null) {
                self.advancedTractorAlertSettings(tractorSettings);
            }
            else {
                self.advancedTractorAlertSettings(settings);
            }
        }
        catch(err) {
            self.alertErrorMsg(err);
        }
    }

    const fetchTractorAlertSettings = (id) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("OrderPlanning/GetTractorAdvancedAlertSettings/"+id, "GET", null, true).done((data) => resolve(data))
            .fail((err) => reject(err.responseJSON && err.responseJSON.message || 'An error occurred while fetching data.'));
        })
    }
};

export default {viewModel: MyProfileViewModel, template: template}