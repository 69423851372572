import template from './user-security-page.html';
import dataModel from 'data-model';
import gridStateUtils from 'jqx.grid-utils'
import GridStateComponentViewModel from 'jqx.grid-state-component';
import { mapKOtoJS } from 'global-functions'; 
import {showconfirm} from 'show-dialog-methods';
import {useDispatch} from 'ko-data-store';
import {setAdvanceAbility} from 'dataStore-actions/wireAdvances';

var UserSecurityViewModel = function () {
    var self = this;
    self.users = ko.observable();
    self.pages = ko.observable();
    self.loadPageData = function () {
        var source = {
            url: "User/GetPageList",
            datatype: "json",
            datafields: [
                { name: "id", type: "int" },
                { name: "name", type: "string" }
            ]
        };
        return dataModel.getDataAdapter(source);
    }
    self.pageDataAdapter = self.loadPageData();

    self.loadUserList = function () {
        var grid = $("#userGrid");
        var source = {
            url: "User/GetUsersForSecurity",
            datatype: "json",
            datafields: [
                { name: "name", type: "string" },
                { name: "terminal", type: "string" },
                { name: "active", type: "bool" },
                { name: "agency", type: "string" },
                { name: "id", type: "int" },
                { name: "assignedPayeeId", type: "int"}
            ],
            id: "id",
            filter: function (filters, recordsArray) {
                grid.jqxGrid('updatebounddata', 'filter');
            },
            sort: function (column, direction) {
                grid.jqxGrid('updatebounddata', 'sort');
            },
            formatdata: function (data) {
                var filterinfo = grid.jqxGrid('getfilterinformation');
                data = gridStateUtils.formatGridFilters(filterinfo, data);
                return data;
            }
        }

        var dataAdapter = dataModel.getDataAdapter(source);

        grid.jqxGrid({
            source: dataAdapter,
            selectionmode: 'none',
            width: "100%",
            sortable: true,
            pageable: true,
            filterable: true,
            showfilterrow: true,
            autoheight: true,
            virtualmode: true,
            columnsresize: true,
            columnsreorder: true,
            columnsmenu: false,
            altrows: true,
            pagesize: 10,
            showtoolbar: true,
            // action items toolbar
            rendertoolbar: function (toolbar) {
                var vm1 = new GridStateComponentViewModel.viewModel("userGrid");
                vm1.actions = [
                    "Clear Filters",
                    "Refresh",
                    "Show/Hide Columns"
                ]
                vm1.initializeDefaultToolbar(toolbar);
            },
            rendergridrows: function (obj) {
                return obj.data;
            },
            ready: function () {
                gridStateUtils.loadGridState("userGrid");
                grid.jqxGrid('showrowdetails', 1);
            },
            columns: [
                { text: 'Username', datafield: 'name' },
                { text: 'Terminal', datafield: 'terminal' },
                { text: 'Primary Agency', datafield: 'agency' },
                { text: 'Active', datafield: 'active', filtertype: "bool", columntype: "checkbox" },
                {
                    text: 'Edit',
                    width: 100,
                    datafield: 'editButton',
                    filterable: false,
                    sortable: false,
                    columnType: 'button',
                    buttonclick: function (row) {
                        var datarow = grid.jqxGrid('getrowdata', row);
                        var pageModel = new PageViewModel(datarow);
                        self.pages(pageModel);
                        pageModel.loadPages(self.pageDataAdapter);
                        pageModel.buildPayeeSelectList();
                        
                    },
                    cellsrenderer: function () {
                        return "Edit";
                    }
                }
            ]
        });
    };
    self.loadUserList();
};

var TransferGridViewModel = function (data, userId) {
    var self = this;
    self.userId = userId;
    self.refreshGrid = ko.observable();
    self.leftSource = data.visibleItems;
    self.rightSource = data.hiddenItems;
    self.showLoadingWheel = ko.observable(false);
    self.columns = [{ text: "id", datafield: "id", hidden: true, columnType: 'number' }, { text: "Name", datafield: "name", columnType: 'textbox' }];
    self.fields = [{ name: "id", type: 'string' }, { name: "name", type: 'string' }];
    self.columnTransfer = function () {
        // ids for visible items
        var visibleIds = ko.utils.arrayMap(self.leftSource, function (item) {
            return item.id;
        });
        // ids for hidden items
        var hiddenIds = ko.utils.arrayMap(self.rightSource, function (item) {
            return item.id;
        });
        var data = {
            selectedUserId: self.userId,
            visible: visibleIds,
            hidden: hiddenIds
        };
        self.showLoadingWheel(true);
        dataModel.ajaxRequest("User/SetPageSecurity/", 'post', data)
        .done(function (response, status, xhr) {
            self.showLoadingWheel(false);
        })
        .fail(function (error, msg, d) {
            self.showLoadingWheel(false);
        });
    };
};


class AdvancedAbilityViewModel {
    constructor(params) {
        this.dispatch = useDispatch();
        params = params || {};
        
        this.$owner = params.$owner || {};
        this.$data = params.$data || {};

        this.userId = this.$data.userId;
        this.aopLimit = ko.observable(this.$data.aopLimit || 0);
        this.advanceLimit = ko.observable(this.$data.fundingLimit || 0);
        this.advanceAbilityLockedOut = ko.observable(this.$data.advanceAbilityLockedOut || false);
        this.originalLockoutSetting = this.advanceAbilityLockedOut();
        this.advanceAbilityEnabled = ko.observable(this.$data.advanceAbilityEnabled || false);

        this.errors = ko.observableArray([]);
        this.status = ko.observable();

        this.isLockoutResetting = false;
    }

    handleAdvanceAbilityDisable = () => {

        if(this.isLockoutResetting) { // when error, prevent extra save action when the abilit checkbox is being reset.
            this.isLockoutResetting = false;
            return;
        }

        let payload = {
            userId: this.userId,
            lockout: this.advanceAbilityLockedOut()
        }

        this.http_sendUpdateAdvanceLockout(payload).then(() => { 
            this.status("Ability Lockout Updated...")
            setTimeout(() => {
                this.status(null);
            }, 3000);
        }).catch((err) => {
            this.isLockoutResetting = true;

            this.advanceAbilityLockedOut(this.originalLockoutSetting);

            if(err && err.responseJSON) {
                this.errors.push(err.responseJSON.message);
            }
        });
    }

    handleUpdateAdvancedAbility = () => {
        this.errors([]);
        this.status(null);

        var koErrors = ko.validation.group(this);

        if (koErrors().length > 0) {
            koErrors.showAllMessages();
            return false;
        }


        let payload = mapKOtoJS(this, { takeOnly: ["userId", "aopLimit", "advanceLimit", "advanceAbilityLockedOut", "advanceAbilityEnabled"]});
     
        this.http_sendUpdateAdvancedAbility(payload).then((response) => {
            
            if(response.errors && response.errors.length) {
                this.errors(response.errors);
            }
            else {
                this.status("Success!");

                setTimeout(() => {
                    this.status(null);
                }, 3000);

                this.dispatch(setAdvanceAbility(payload));
            }
        }).catch((err) => this.errors.push("An error occurred while updating."));
    }

    handleResetTwoFactorAuthentication = () => {
        this.doShowConfirm().then((x) => {
            if(x) this.whenConfirmedResetTwoFactorAuthentication();
        });
    }

    whenConfirmedResetTwoFactorAuthentication = () => {
        this.http_sendResetTwoFactorAuthenticationAsync(this.userId)
        .then(() => {
            this.status("Two Factor Authentication Reset.");
            this.resetForm();
            setTimeout(() => {
                this.status(null);
            }, 3000);
        });
    }

    doShowConfirm = () => {
        return showconfirm("Are you sure you want to reset?");
    }

    resetForm = () => {
        this.advanceAbilityLockedOut(false);
    }
    
    http_sendUpdateAdvanceLockout = (data) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("User/UpdateAdvanceLockout", "POST", data).done((response) => resolve(response)).fail((error) => reject(error));
        });
    }

    http_sendUpdateAdvancedAbility = (data) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("User/UpsertAdvancedAbility", "POST", data).done((response) => resolve(response)).fail((error) => reject(error));
        });
    }

    http_sendResetTwoFactorAuthenticationAsync = (userId) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("User/ResetTwoFactorAuthentication", "PUT", userId, true).done((response) => resolve(response)).fail((error) => reject(error));
        });
    }
}


var PageViewModel = function (datarow) {
    var self = this;
    self.selectedPage = ko.observable();
    self.selectedPageName = ko.observable();
    self.userId = ko.observable(datarow.id);
    self.userName = ko.observable("User Name: " + datarow.name);
    self.terminalText = ko.observable(datarow.terminal);
    self.userActive = ko.observable(datarow.active);
    self.assignedPayeeId = ko.observable(datarow.assignedPayeeId);
    self.newPassword = ko.observable();
    self.passwordConfirm = ko.observable();
    self.passwordStatusMsgObj = ko.observable({}); // init with empty obj, so can use obj().message, obj().color in the html markup
    self.isProcessing = ko.observable(false);
    self.statusMsg = ko.observable();

    self.advanceAbilityVM = ko.observable();

    self.terminalButtonClick = function () {
        var data = {
            userId: self.userId(),
            terminalText: self.terminalText()
        }
        dataModel.ajaxRequest("User/UpdateUserTerminal/", 'post', data)
            .done(function (response, status, xhr) {
                //  self.datarow().terminal = self.terminalText();
                $("#userGrid").jqxGrid("updatebounddata", "data");
            })
            .fail(function (error, msg, d) {
                var g = "blah";
            });
    };

    function validatePasswords() {
        var resultObj = {
            errorMSg: null,
            isSuccess: true
        }
        if (self.newPassword() == null || self.newPassword().length < 4) {
            resultObj.errorMSg = 'Password field is required and must be at least 4 characters long.';
        }
        else if (self.passwordConfirm() == null || self.passwordConfirm() == "") {
            resultObj.errorMSg = 'Confirm Password field is required.';
        }
        else if (self.newPassword() != self.passwordConfirm()) {
            resultObj.errorMSg = 'Passwords do not match.';
        }
        resultObj.isSuccess = resultObj.errorMSg == null;
        return resultObj;
    }

    self.loadPages = function (dataAdapter) {
        var grid = $("#pageGrid");
        grid.jqxGrid({
            source: dataAdapter,
            selectionmode: 'none',
            width: "100%",
            height: '400',
            sortable: false,
            pageable: false,
            filterable: false,
            showfilterrow: false,
            columnsresize: false,
            columnsreorder: false,
            columnsmenu: false,
            altrows: true,
            showheader: false,
            showtoolbar: false,
            rendergridrows: function (obj) {
                return obj.data;
            },
            ready: function () {
                gridStateUtils.loadGridState("pageGrid");
            },
            columns: [
                { text: 'page', datafield: 'name' },
                {
                    text: 'Edit',
                    width: 50,
                    datafield: 'editButton',
                    filterable: false,
                    sortable: false,
                    columnType: 'button',
                    buttonclick: function (row) {
                        var datarow = grid.jqxGrid('getrowdata', row);
                        self.selectedPageName("Page Name: " + datarow.name);
                        dataModel.ajaxRequest("User/GetPageSecurity/" + datarow.id + "/" + self.userId(), "GET")
                            .done(function (data, status, xhr) {
                                var controlsModel = new TransferGridViewModel(data, self.userId());
                                self.selectedPage(controlsModel);
                                controlsModel.refreshGrid(true);
                            })
                            .fail(function (error, msg, d) {
                            });
                    },
                    cellsrenderer: function () {
                        return "Edit";
                    }
                }
            ]
        });
    };

    self.updateUserActiveStatus = function () {
        self.isProcessing(true);
        dataModel.ajaxRequest("User/UpdateUserActiveStatus/" + self.userId(), "PUT").done(function (result) {
            self.isProcessing(false);
            if (result.success) {
                self.userActive(!self.userActive());
                $("#userGrid").jqxGrid("updatebounddata");
            }
        }).fail(function (errors) { });
    }

    self.updateUserPassword = function () {
        self.passwordStatusMsgObj({});
        var errors = ko.validation.group(self);
        var passwordValidation = validatePasswords();
        if (passwordValidation.isSuccess == false) {
            errors().push(passwordValidation.errorMSg);
            self.passwordStatusMsgObj({ message: passwordValidation.errorMSg, color: 'red' });
            return false;
        }
        if (errors().length > 0) { return; }
        
        // Update password
        var data = {
            userId: self.userId(),
            password: self.newPassword(),
            confirmPassword: self.passwordConfirm()
        };
        dataModel.ajaxRequest("MyAgency/ChangePassword", "PUT", data).done(function (result) {
            if (result.success == false) {
                self.passwordStatusMsgObj({ message: result.errorMessage, color: 'red' });
                return false;
            }
            self.passwordStatusMsgObj({ message: "Password updated successfully.", color: 'green' });
            setTimeout(function () {
                self.passwordStatusMsgObj({}); // must reset to empty obj
            }, 3000);
            self.newPassword("");
            self.passwordConfirm("");
        }).fail(function (error) {
            console.error(error);
        });
    }

    self.buildPayeeSelectList = function () {
        var source =
        {
            datatype: "json",
            url: "Payee/GetPayeeForDDL",
            datafields: [
                { name: 'id' },
                { name: 'externalId' },
                { name: 'name' },
                { name: 'displayName' }
            ],
            beforeLoadComplete: function (data) {
                data = data.filter(x => x.displayName)  //throws error downstream if we don't filter these out.
                data.unshift({ id: 0, displayName: 'Select Payee to Assign', name: null, externalId: null });
                return data;
            },
            loadComplete: function (data) {
                let _index = 0;
                if (self.assignedPayeeId() != null && self.assignedPayeeId() > 0) {
                    var item = $("#jqxPayeeSelectList").jqxDropDownList('getItemByValue', self.assignedPayeeId());
                    _index = item != null ? item.index : _index;
                    $('#jqxPayeeSelectList').jqxDropDownList('selectIndex', _index);
                }
                else {
                    $('#jqxPayeeSelectList').jqxDropDownList('selectIndex', _index);
                }
                return data;
            },
        };

        var dataAdapter = dataModel.getDataAdapter(source);

        $('#jqxPayeeSelectList').jqxDropDownList({
            checkboxes: false,
            placeHolder: 'Select Payee to Assign',
            source: dataAdapter,
            displayMember: "displayName",
            valueMember: "id",
            width: 200,
            height: 25,
            autoDropDownHeight: false,
            filterable: true,
            dropDownHeight: 150,
            dropDownWidth: 300,
            animationType: 'none',
            searchMode: 'containsignorecase'
        });

    }

    self.assignPayee = function () {
        var _selectedItem = $('#jqxPayeeSelectList').jqxDropDownList('getSelectedItem');
        
        if (_selectedItem.originalItem == null || self.userId() == null) return false;

        var _payeeObj = _selectedItem.originalItem || {};

        if (_payeeObj.id != null && typeof _payeeObj.id === 'number' && _payeeObj.id > 0) {
            self.statusMsg("");

            var payload = {
                userId: self.userId(),
                payeeId: _payeeObj.id
            }

            dataModel.ajaxRequest("User/AssignPayeeToUser", "POST", payload).done(function (respPayeeId) {
                
                if (respPayeeId != null && respPayeeId > 0) {
                    self.statusMsg("Payee assigned successfully.");
                    self.assignedPayeeId(respPayeeId);
                    self.refreshUserGrid();

                    setTimeout(function () {
                        self.statusMsg("");
                    }, 3500);
                }
            }).fail(function (error) {
                if (error.status == 400 && error.responseJSON) {
                    if (error.responseJSON.message) {
                        self.statusMsg(error.responseJSON.message);

                        setTimeout(function () {
                            self.statusMsg("");
                        }, 3500);
                    }
                }
            });
        }
    }

    self.removeAssignedPayee = function () {
        if (self.userId() != null && self.assignedPayeeId() != null) {
            self.statusMsg("");

            dataModel.ajaxRequest("User/RemoveAssignedPayeeFromUser", "POST", self.userId()).done(function (resp) {
                if (resp != null && typeof resp === 'boolean') {
                    self.statusMsg("Payee unassigned from user successfully.");
                    self.assignedPayeeId(null);
                    $('#jqxPayeeSelectList').jqxDropDownList('selectIndex', 0);
                    self.refreshUserGrid();

                    setTimeout(function () {
                        self.statusMsg("");
                    }, 3500);
                }
            }).fail(function (error) {
                if (error.status == 400 && error.responseJSON) {
                    if (error.responseJSON.message) {
                        self.statusMsg(error.responseJSON.message);

                        setTimeout(function () {
                            self.statusMsg("");
                        }, 3500);
                    }
                }
            });
        }
    }
    self.refreshUserGrid = function () {
        $("#userGrid").jqxGrid('updatebounddata','data');
    }

    self.retrieveData = function() {
        Promise.all([self.http_sendGetUserAdvanceControls(self.userId())]).then(function(results){
            self.loadAdvanceAbilityVM(results[0]);
        })
    }

    self.loadAdvanceAbilityVM = function(data) {
        var advanceData = data || {};
        advanceData.userId = advanceData.userId || self.userId();

        self.advanceAbilityVM(new AdvancedAbilityViewModel({$owner: self, $data: advanceData}));
    }

    self.http_sendGetUserAdvanceControls = function(userId) {
        return new Promise(function(resolve, reject) {
            dataModel.ajaxRequest("User/AdvanceControls/"+userId, "GET").done(function (response) { resolve(response); })
            .fail(function (error) { reject(error); });
        });
    }

    self.retrieveData();
};

export default {viewModel: UserSecurityViewModel, template: template}