import './settlementsStyles.css';
import dataModel from 'data-model';
import userprofile from 'user-profile';

class SettlementsPageViewModel {
    constructor() {
        var self = this;
        self.isSalesperson = ko.observable(false);
        self.isLoading = ko.observable(true);
        self.isOwnerOperator = userprofile.isOwnerOperator;

        self.init = function() {
            
            dataModel.ajaxRequest("User/VerifyCurrentUserRole", "GET", { roleNames: ["Salesperson Settlements"] }, true).done(function (resp) {
                self.isLoading(false);

                if (resp != null && typeof resp === 'boolean') {
                    self.isSalesperson(resp);
                }
            }).fail(function(err){ 
                self.isLoading(false);
            });
        }

        self.init();
    }
}

import template from './settlements-page.html';
export default { viewModel: SettlementsPageViewModel, template: template }