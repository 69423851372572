// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#XHbcZbs7r6yKdJYWjbRmtQ\\=\\= #NqeeihGNoonDPwqlotxmXA\\=\\= li {\r\n    padding: 5px;\r\n}\r\n\r\n#XHbcZbs7r6yKdJYWjbRmtQ\\=\\= #NqeeihGNoonDPwqlotxmXA\\=\\= li strong {\r\n    margin-left: 10px;\r\n}\r\n\r\n#XHbcZbs7r6yKdJYWjbRmtQ\\=\\= dt {\r\n    font-weight: bold;\r\n    padding: 5px;\r\n}\r\n\r\n#XHbcZbs7r6yKdJYWjbRmtQ\\=\\= dd {\r\n    padding: 5px;\r\n    color: #222;\r\n}\r\n\r\n#XHbcZbs7r6yKdJYWjbRmtQ\\=\\= .OIjNx4mzmk5fEQHhCdbBGg\\=\\=.tDOY2hnpXV9k-WGtpbUK0Q\\=\\= {\r\n    border: none;\r\n    border-bottom: 1px solid #e8e8e8!important;\r\n}\r\n\r\n#XHbcZbs7r6yKdJYWjbRmtQ\\=\\= .OIjNx4mzmk5fEQHhCdbBGg\\=\\=.tDOY2hnpXV9k-WGtpbUK0Q\\=\\= .iBI826dL65LpsD32KXzWfA\\=\\= {\r\n    background-color: #f5f5f5;\r\n}", "",{"version":3,"sources":["webpack://./src/home/pages/challenge-page/challengePageStyles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,0CAA0C;AAC9C;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#challenge-page #inspectionList li {\r\n    padding: 5px;\r\n}\r\n\r\n#challenge-page #inspectionList li strong {\r\n    margin-left: 10px;\r\n}\r\n\r\n#challenge-page dt {\r\n    font-weight: bold;\r\n    padding: 5px;\r\n}\r\n\r\n#challenge-page dd {\r\n    padding: 5px;\r\n    color: #222;\r\n}\r\n\r\n#challenge-page .panel.panel-primary {\r\n    border: none;\r\n    border-bottom: 1px solid #e8e8e8!important;\r\n}\r\n\r\n#challenge-page .panel.panel-primary .panel-body {\r\n    background-color: #f5f5f5;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"challenge-page": "XHbcZbs7r6yKdJYWjbRmtQ==",
	"inspectionList": "NqeeihGNoonDPwqlotxmXA==",
	"panel": "OIjNx4mzmk5fEQHhCdbBGg==",
	"panel-primary": "tDOY2hnpXV9k-WGtpbUK0Q==",
	"panel-body": "iBI826dL65LpsD32KXzWfA=="
};
export default ___CSS_LOADER_EXPORT___;
