import dataModel from 'data-model';

function LoadPostingService() {
    return {
        fetchLoadDetails,
        fetchCallLeads,
        sendRefreshLoad,
        fetchPostingBoardsForLoad
    }
}

const fetchLoadDetails = (loadId) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("OrderPlanning/GetLoad/" + loadId, "GET")
            .done(data => resolve(data))
            .fail(error => reject(error))
    })
}

const fetchCallLeads = () => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("CallLeads/ActiveLeads", "GET")
            .done(data => resolve(data))
            .fail(error => reject(error))
    })
}

const sendRefreshLoad = (loadId, postingBoards = []) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("FreightBoard/RefreshPosting", "POST", {loadId, postingBoards})
            .done(data => resolve(data))
            .fail(error => reject(error))
    })
}

const fetchPostingBoardsForLoad = (loadId) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("FreightBoard/GetLoadExternalBoards/" + loadId)
        .done(data => resolve(data))
        .fail(error => reject(error));
    })
}


export default LoadPostingService();