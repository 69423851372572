import dataModel from "data-model";
import template from "./order-entry-general-component.html";
import { showconfirm } from "show-dialog-methods";
import ko from "knockout";
import userProfile from "user-profile";
import { OrderEntryViewModel } from "../order-entry-page";

import { useDispatch } from "ko-data-store";
import { isLoading } from "../../../../dataStore/actions/appUI";

import {
  cleanContactEmail,
  getAgencyContactDetails,
} from "../order-entry-track-trace-component/common";
import TNTContext from "../order-entry-track-trace-component/trackTraceOrderEntryContext";

class GeneralModel {
  constructor(params) {
    /**@type {OrderEntryViewModel} */
    var mainModel = params.mainModel;

    this.dispatchAction = useDispatch();

    this.billToCustomerSelected = mainModel.billToCustomerSelected;
    this.noBroker = mainModel.noBroker;
    this.agentRisk = mainModel.agentRisk;
    this.isPreload = mainModel.isPreload;
    this.isLTL = mainModel.isLTL;
    this.tankerReq = mainModel.tankerReq;
    this.isHazmat = mainModel.isHazmat;
    this.cbOptionsHazmatRoutingClick = mainModel.cbOptionsHazmatRoutingClick;
    this.isHighValue = mainModel.isHighValue;
    this.highValue = mainModel.highValue;
    this.terminalErrorMessage = mainModel.terminalErrorMessage;

    this.item = params.item || {};
    this.requiredFields = mainModel.requiredFields;
    this.isExistingOrder = mainModel.isExistingOrder;
    this.isReadOnly = mainModel.isReadOnly;
    this._isBrokered = ko.computed(() => {
      return mainModel.isBrokered ? mainModel.isBrokered() : false;
    });

    this.selectedCustomer = ko.observable();
    this.billToCustomer = ko.observable(this.item.billToCustomer).extend({
      required: {
        message:
          "The required field Bill-to-Customer has not been entered.  Please enter the required information.",
      },
    });

    this.revenueCode = ko.observable(this.item.revenueCode);
    this.bonded = ko.observable(this.item.bonded || false);
    this.commodityDescription = ko.observable();
    this.commodity = ko.observable(this.item.commodity).extend({
      required: {
        onlyIf: () => {
          return (
            this.requiredFields.isRequired("autoCommodity") &&
            this.commodityDescription() == null
          );
        },
        message:
          "The required field Commodity has not been entered.  Please enter the required information.",
      },
    });

    this.selectedTrailerType = ko.observable();
    this.trailerType = ko.observable(this.item.trailerType).extend({
      required: {
        onlyIf: () => {
          return (
            this.requiredFields.isRequired("inputTrailerType") ||
            (mainModel.shareOrder != null && mainModel.shareOrder() == true)
          );
        },
        message:
          "The required field Trailer Type has not been entered.  Please enter the required information.",
      },
    });
    this.trailer = ko.observable(this.item.trailer).extend({
      required: {
        onlyIf: () => {
          return this.requiredFields.isRequired("autoTrailerNumber");
        },
        message:
          "The required field Trailer has not been entered.  Please enter the required information.",
      },
    });

    this.bolNumber = ko.observable(this.item.bolNumber).extend({
      required: {
        onlyIf: () => {
          return this.requiredFields.isRequired("inputBillOfLading");
        },
        message:
          "The required field Bill of Lading Number has not been entered.  Please enter the required infomation.",
      },
      maxLength: 28,
    });

    this.customerReferenceNumber = ko
      .observable(this.item.customerReferenceNumber)
      .extend({
        required: {
          onlyIf: () => {
            return this.requiredFields.isRequired("inputCustomerReference");
          },
          message:
            "The required field Customer Reference Number has not been entered.  Please enter the required infomation.",
        },
        maxLength: 28,
      });

    this.unNumber = ko.observable(this.item.unNumber).extend({
      required: {
        onlyIf: () => {
          if (this.isHazmat()) {
            return true;
          }
          return this.requiredFields.isRequired("inputUNNUmber");
        },
        message:
          "The required field UN Number has not been entered.  Please enter the required information.",
      },
    });

    const setDefaultSalesPersonRate = () => {
      const salespersonInfo = this.salesPersonSelectedValue();
      if (
        salespersonInfo &&
        salespersonInfo.isSalesperson == false &&
        this._isBrokered() == false &&
        this.displaySalespersonRate()
      ) {
        const rate = this.item.salesPersonRate ?? 5;
        this.salesPersonRate(rate);
      }
    };

    this.salesPersonSelectedValue = ko.observable();
    this.isSalesperson = ko.observable(false);

    this.salesPersonSelectedValue.subscribe((val) => {
      if (val) {
        var _isSalesperson =
          val.isSalesperson != null ? val.isSalesperson : false;
        this.isSalesperson(_isSalesperson);

        setDefaultSalesPersonRate();
      }
    });
    this.salesPerson = ko.observable(this.item.salesPerson).extend({
      required: {
        onlyIf: () => {
          return this.requiredFields.isRequired("autoSalesPerson");
        },
        message:
          "The required field Salesperson has not been entered.  Please enter the required information.",
      },
    });

    this.salesPersonRate = ko.observable(this.item.salesPersonRate).extend({
      required: {
        onlyIf: () => {
          return (
            this.requiredFields.isRequired("inputSalesPersonRate") ||
            (this._isBrokered() == false &&
              this.salesPerson() &&
              this.isSalesperson() == false)
          );
        },
        message:
          "The required field Salesperson Rate has not been entered.  Please enter the required information.",
      },
    });

    this.salesPersonRateChange = (rate) => {
      if (rate && rate > 10) {
        showconfirm(
          "Do you mean to pay " +
            rate.toString() +
            "% in Sales commission on this order?"
        ).then((x) => {
          if (!x) {
            this.salesPersonRate(undefined);
          }
        });
      }
    };

    this._isBrokered.subscribe((val) => {
      if (val == true) {
        this.salesPersonRate(null);
      }
    });

    this.displaySalespersonRate = ko.computed(() => {
      return (
        (this.salesPerson() ? this._isBrokered() == false : true) &&
        !this.requiredFields.isHidden("inputSalesPersonRate")
      );
    });

    // on display, reapply the current salesperson value so it triggers subscribbers
    this.displaySalespersonRate.subscribe((val) => {
      if (val) {
        setDefaultSalesPersonRate();
      }
    });

    this.temperatureMax = ko.observable(this.item.temperatureMax).extend({
      required: {
        onlyIf: () => {
          return this.requiredFields.isRequired("inputTempMax");
        },
        message:
          "The required field Temperature Max has not been entered.  Please enter the required information.",
      },
    });

    this.temperatureMin = ko.observable(this.item.temperatureMin).extend({
      required: {
        onlyIf: () => {
          return this.requiredFields.isRequired("inputTempMin");
        },
        message:
          "The required field Temperature Min has not been entered.  Please enter the required information.",
      },
    });

    this.orderedBy = ko.observable(this.item.orderedBy).extend({
      required: {
        onlyIf: () => {
          return this.requiredFields.isRequired("inputOrderedBy");
        },
        message:
          "The required field Ordered By has not been entered.  Please enter the required information.",
      },
    });
    this.operationsUser = ko.observable(this.item.operationsUser).extend({
      required: {
        onlyIf: () => {
          return this.requiredFields.isRequired("autoOperationsUser");
        },
        message:
          "The required field Operations User has not been entered.  Please enter the required information.",
      },
    });

    // FilterSearch:TNT
    this.handleOperationsUserSelected = async ({
      name = undefined,
      contactEmail = undefined,
      contactName = undefined,
    } = {}) => {
      name = name ?? "";
      let updateProps = {};

      const { email } = await getAgencyContactDetails();

      // Dispatcher info + check for lmeadm operation user -> change to enteredBy, etc.
      updateProps.authName =
        name.trim() !== "lmeadm"
          ? contactName || name || this.item.enteredBy
          : this.item.enteredBy || this.enteredBy();
      updateProps.agentEmail =
        name.trim() !== "lmeadm"
          ? contactEmail
          : userProfile.userContactInfo.email || cleanContactEmail(email);

      // Authname is a little confusing as there is also authEmail and authPhone. These are readonly after tracking starts. Used as 'fallbacks'
      if (updateProps.authName || updateProps.agentEmail) {
        TNTContext.updateSlice({
          sliceKey: "trackedMovements:tracking",
          payload: updateProps,
        });
      }
    };

    this.customField1Label = ko.observable(
      this.item.customField1Label || "Custom Field 1"
    );
    this.customField2Label = ko.observable(
      this.item.customField2Label || "Custom Field 2"
    );
    this.customField3Label = ko.observable(
      this.item.customField3Label || "Custom Field 3"
    );

    this.customField1 = ko.observable(this.item.customField1).extend({
      required: {
        message:
          "The required field " +
          this.item.customField1Label +
          " has not been entered.  Please enter the required information.",
        onlyIf: () => {
          return this.requiredFields.isRequired("inputCustomField1");
        },
      },
    });

    this.customField2 = ko.observable(this.item.customField2).extend({
      required: {
        onlyIf: () => {
          return this.requiredFields.isRequired("inputCustomField2");
        },
      },
      message:
        "The required field " +
        this.item.customField2Label +
        " has not been entered.  Please enter the required information.",
    });

    this.customField3 = ko.observable(this.item.customField3).extend({
      required: {
        onlyIf: () => {
          return this.requiredFields.isRequired("inputCustomField3");
        },
      },
      message:
        "The required field " +
        this.item.customField3Label +
        " has not been entered.  Please enter the required information.",
    });

    ///TERMINAL SECTION
    this.isTerminalRequired = ko.observable(false);
    this.isTerminalCodeReadOnly = ko.observable();
    this.terminalValues = ko.observableArray();
    this.usersTerminalId = ko.observable();
    this.terminalCode = ko.observable().extend({
      required: {
        onlyIf: () => {
          return this.requiredFields.isRequired("autoTerminal");
        },
      },
      message:
        "The required field Terminal Code has not been entered.  Please enter the required information.",
    });

    //call the initial terminal function that will call the other functions on page load
    this.getUsersTerminalId();
    ///END TERMINAL SECTION
    this.hasARApproval = ko.observable(this.item.hasARApproval || false);

    //These needs to load as true to allow the autocomplete to obtain any data they needs.
    this.arApproval = ko.observable(true);
    this.getIsBrokered = ko.observable(false);

    setTimeout(() => {
      this.getIsBrokered(mainModel.getIsBrokered());
      mainModel.getIsBrokered.subscribe((x) => {
        this.getIsBrokered(x);
      });
      this.arApproval(this.item.arApproval);
    }, 1000);

    this.enteredBy = ko.observable(this.item.enteredBy);
    this.agencyId = ko
      .observable(this.item.agencyExternalId || userProfile.agencyExternalId)
      .extend({ required: true });

    this.agencyId.subscribe((id) => {
      if(params.mainModel) {
        const billed = params.mainModel.readyToBill();

        if(!billed) {
          this.reloadAgencyRevenueCode(id)
        }
      }

    });

    this.reloadAgencyRevenueCode = (agencyId) => {
      this.dispatchAction(isLoading(true))
      dataModel
          .ajaxRequest("order/OrderInitialValues/" + agencyId, "GET", null, true)
          .done(({general} = {general: {}}) => {
            this.dispatchAction(isLoading(false));
            this.revenueCode(general.revenueCode);
          }).fail(() => this.dispatchAction(isLoading(false)))
    }

    this.isCustomerReadOnly = ko.computed(() => {
      if (mainModel.isReadOnly()) {
        return true;
      } else if (this.arApproval() && this.billToCustomer() != undefined) {
        return true;
      } else {
        return false;
      }
    });

    this.isBillToCustomerLoading = (yes) => {
      this.dispatchAction(isLoading(yes));
    };
  }

  isFieldHidden = (name) => {
    return this.requiredFields.isHidden(name);
  };

  getUsersTerminalId = () => {
    //find this users terminal id
    dataModel.ajaxRequest("terminal/GetUserTerminal", "GET").done((record) => {
      this.usersTerminalId(record); //set the users terminal Id as an observable - if they dont have one it will be ""
      this.setInitialTerminalDetails(); //move on to the next method with the array of terminal values
    });
  };

  setInitialTerminalDetails = () => {
    //set the initial details of the terminal input box (whether it is red, clear, readonly, and/or requires correction
    if (this.isExistingOrder() == true) {
      //if this is an existing order, then perform this function
      this.terminalCode(this.item.terminal); // and set the terminal code to the value on the existing order
      if (this.usersTerminalId() != "") {
        // user DOES have a terminal id
        this.isTerminalCodeReadOnly(true); //make the terminal code read only
      } else if (this.usersTerminalId() == "") {
        //its an existing order and user does NOT have a terminal id
        this.isTerminalCodeReadOnly(false); //then the terminal code should NOT be read only (they can edit / input a terminal code)
      }
    } else {
      //if this is not an existing order, meaning the user is creating a new order
      if (this.usersTerminalId() != "") {
        //if the user has a terminal id
        this.terminalCode(this.usersTerminalId()); //set the terminal code to this id
        this.isTerminalCodeReadOnly(true); // and make it read only
      } else {
        //a new order is being created for a user with NO terminal id
        this.isTerminalCodeReadOnly(false); //leave terminal code blank and make it able to be edited
      }
    }
  };

  autoCompleteGeneralSalesPersonSelected = (salesPerson) => {
    if (salesPerson == undefined) return;
    if (this._isBrokered() == false && salesPerson.isSalesperson == false) {
      $("#salesPersonRateInput").attr("placeholder", "0%");
      return;
    }
    $("#salesPersonRateInput").removeAttr("placeholder");
    this.salesPersonRate(null);
  };

  validationObject = () => {
    return {
      billToCustomer: this.billToCustomer,
      commodity: this.commodity,
      trailerType: this.trailerType,
      trailer: this.trailer,
      bolNumber: this.bolNumber,
      customerReferenceNumber: this.customerReferenceNumber,
      unNumber: this.unNumber,
      salesPerson: this.salesPerson,
      salesPersonRate: this.salesPersonRate,
      temperatureMax: this.temperatureMax,
      temperatureMin: this.temperatureMin,
      orderedBy: this.orderedBy,
      operationsUser: this.operationsUser,
      customField1: this.customField1,
      customField2: this.customField2,
      customField3: this.customField3,
      agencyId: this.agencyId,
    };
  };
}

var OrderEntryGeneralComponent = {
  viewModel: {
    createViewModel: function (params, componentInfo) {
      //Don't do anything.  Use the object created in the main OrderEntry page.
    },
  },
  template: template,
};

export { GeneralModel };

export default OrderEntryGeneralComponent;
