import * as tvCommon from '../tvCommon';
import toast from 'geNotyf';
import { setBtnDisabledState, setBtnEnabledState, datetimeUTC } from 'global-functions';

const entryViewModel = function({
    videoModel,
    categories = ko.observableArray([]),
    videoContent
}) {
    const vm = this;

    const $thumbnailImgInput = $('#thumbnailImgInput');
    const $thumbnailImgPreview = $('#thumbnailImgPreview');
    const $editVideoModal = $('#editVideoModal');

    $editVideoModal.on('hidden.bs.modal', () => {
        videoModel(null)
    })

    vm.videoModel = videoModel;
    vm.categories = ko.observableArray(categories().map(x => ko.toJS(x)));
    vm.thumbnail = ko.observable(videoModel().thumbnails()[0]);
    vm.accessRoles = ko.observableArray(["Agent", "Driver", "Greatwide Employees", "Owner Operator"]);

    vm.error = ko.observable();

    vm.selectedCategory = ko.observable(vm.categories().find(x => x.id == videoModel().categoryId()));
    vm.selectedCategory.subscribe(({id = undefined, subCategories = []}) => {
        videoModel().categoryId(id);

        if(subCategories.length == 0) {
            vm.videoModel().subCategoryId(undefined);
        }
    })

    const subCategory = vm.selectedCategory() && vm.selectedCategory().subCategories ? vm.selectedCategory().subCategories.find(x => x.id == videoModel().subCategoryId()) : undefined;
    
    vm.selectedSubCategory = ko.observable(subCategory);
    vm.selectedSubCategory.subscribe(({id = undefined}) => {
        videoModel().subCategoryId(id);
    })

    vm.displaySubCateogryOptions = ko.pureComputed(() => {
        return vm.selectedCategory() && vm.selectedCategory().subCategories && vm.selectedCategory().subCategories.length > 0;
    });

    vm.handleSave = async ($x, event) => {
        vm.error(getValidationError(ko.toJS(vm)));

        if(vm.error().length) return false;

        try {
            setBtnDisabledState(event, 'Saving...');
            const thumbnailBytes = await getImgBytesAsync($thumbnailImgInput.get(0).files[0]);

            const { id, thumbnailURL } = await tvCommon.services.saveVideoAsync({...ko.toJS(videoModel), thumbnails: (vm.thumbnail() ? [vm.thumbnail()] : []), thumbnailImg: thumbnailBytes });
            videoModel().id(id);
            videoModel().thumbnails([thumbnailURL]);
            videoModel().dateAdded(datetimeUTC(new Date()).format("MM/DD/YYYY"))

            setBtnEnabledState(event, 'Save');

            refreshVideoList(videoContent().subheader(), [(vm.selectedSubCategory() && vm.selectedSubCategory().name), vm.selectedCategory().description, "Most Recent"])
            toast.open({type: 'info', message: `Video saved successfully.`});

            $editVideoModal.modal('hide');
        }
        catch(err) {
            setBtnEnabledState(event, 'Save');
            vm.error(err);
        }
    }

    const refreshVideoList = (subheader, matches = []) => {
        const videos = videoContent().videoList().filter(x => x.id() != videoModel().id());

        if(matches.some(x => x == subheader)) {
            videoContent().videoList([videoModel, ...videos]);
        }
        else {
            videoContent().videoList(videos)
        }
    }

    vm.handleRemoveThumbnail = () => {
        vm.thumbnail(undefined)
    }

    $thumbnailImgInput.on('change', () => displayThumbnailPreview($thumbnailImgInput.get(0).files[0]))
    const getImgBytesAsync = (file) => {
        return new Promise((resolve) => {
            const onLoadEnd = (event) => resolve(Array.from(new Uint8Array(event.target.result)));
                
            if(file) {
                const reader = new FileReader();
                reader.onloadend = onLoadEnd;
                reader.readAsArrayBuffer(file);
            }
            else {
                resolve([]);
            }
        });
    }

    const displayThumbnailPreview = (file) => {
        const onLoad = (event) => {
            $thumbnailImgPreview.attr('src', event.target.result)
            $thumbnailImgPreview.show();
        };

        if(file) {
            const reader = new FileReader();
            reader.onload = onLoad
            reader.readAsDataURL(file);
        }
        else {
            $thumbnailImgPreview.attr('src', undefined);
            $thumbnailImgPreview.hide();
        }
    }

    const getValidationError = ({selectedCategory = undefined, videoModel}) => {
        const { roles, subCategoryId, title, videoURL } = videoModel || {};
        const thumbnailImg = $thumbnailImgInput.get(0).files[0];

        if(!selectedCategory) {
            return `Category is required.`;
        }
        else if(selectedCategory.subCategories.length > 0 && subCategoryId > 0 == false) {
            return `Sub Category is required.`;
        }
        else if(!title) {
            return `Title is required.`;
        }
        else if(!videoURL) {
            return `Video URL is required.`;
        }
        else if(roles.length == 0) {
            return `At least one access role is required.`;
        }
        else if(thumbnailImg && thumbnailImg.type != 'image/png') {
            return `Thumbnail Image must be of type '.png'`;
        }
        else {
            return "";
        }
        
    }
}

import template from './entry-component.html';
export default { viewModel: entryViewModel, template: template }