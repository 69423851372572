import ko from 'knockout';
import dataModel from 'data-model';
import { isEmpty } from 'global-functions';
import template from './advanced-tractor-alert-settings-component.html';

class AdvancedTractorAlertSettingsModel {
    constructor(data, $parent) {
        var self = this;
        $parent = data.data.$parent || data.$parent || $parent || {};
        data = data.data.data || data.data || data || {};
        self.errors = ko.observableArray();
        self.isLoading = ko.observable(false);
        self.tractorId = data.tractorId;
        self.ratePerMile = ko.observable(data.ratePerMile);
        self.matchTrailer = ko.observable(data.matchTrailer || "All Trailers");
        self.radius = ko.observable(data.radius);
        self.includeTractor = ko.observable(isEmpty(data.includeTractor) ? true : data.includeTractor);
        self.canada = data.canada;
        self.destChoice = ko.observable(data.destinationType || "home");
        self.location = ko.observable(data.destinationType === "location" ? data.destination : undefined);
        self.regions = ko.observableArray(data.destinationType == "zone" && !isEmpty(data.destination) ? data.destination.split(",") : []);
        self.stateProvinces = ko.observableArray(data.destinationType == "stateProvince" && !isEmpty(data.destination) ? data.destination.split(",") : []);
        self.matchTrailerTypes = ['All Trailers', 'Trailer Group', 'Trailer Type'];
        self.destination = function () {
            if (self.destChoice() == "location") {
                return self.location();
            }
            else if (self.destChoice() == "zone") {
                return self.regions().join(",");
            }
            else if (self.destChoice() == "stateProvince") {
                return self.stateProvinces().join(",");
            }
            return "home";
        };

        self.submit = function () {
            var data = {
                tractorId: self.tractorId,
                ratePerMile: self.ratePerMile(),
                destination: self.destination(),
                destinationType: self.destChoice(),
                matchTrailer: self.matchTrailer(),
                radius: self.radius(),
                includeTractor: self.includeTractor()
            };
            self.errors.removeAll();
            self.isLoading(true);
            dataModel.ajaxRequest("orderplanning/TractorAdvancedAlertSettings", "post", data)
                 .done(function (data, textStatus, jqXHR) {
                     self.isLoading(false);
                     $parent.advancedTractorAlertSettings(undefined);
                 })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status == 400) {
                        var response = jqXHR.responseJSON;
                        self.errors.push(response.message);
                    }
                    self.isLoading(false);
                });
        };
        self.resetAlertCriteria = function () {
            dataModel.ajaxRequest("orderplanning/ResetTractorAlertCriteria/" + self.tractorId, "post")
                  .done(function (data, textStatus, jqXHR) {
                      self.isLoading(false);
                      $parent.advancedTractorAlertSettings(undefined);
                  })
                 .fail(function (jqXHR, textStatus, errorThrown) {
                     if (jqXHR.status == 400) {
                         var response = jqXHR.responseJSON;
                         self.errors.push(response.message);
                     }
                     self.isLoading(false);
                 });
        };
    }
}

export default {viewModel: AdvancedTractorAlertSettingsModel, template: template }