
import dataModel from 'data-model';
import {formatPhoneNumber} from 'global-functions';
import router from 'router';

// This is for the violations table model(s)
var roadsideModel = function (data, mainModel) {
    var self = this;
    var data = data || {};
    var mainModel = mainModel || {};

    self.id = data.id;
    self.challengeStatus = ko.observable(data.challengeStatus);
    self.violationChallenged = ko.observable(data.violationChallenged || false);
    self.violationCode = ko.observable(data.violationCode);
    self.description = ko.observable(data.description);
    self.sendEmail = ko.observable(false);

    // List of Challenge Status
    // Id: is the identity key for the challenge status table
    // Label: is the description
    self.challengeOptions = ko.observableArray([
        { id: 1, label: "New" },
        { id: 2, label: "Pending" },
        { id: 3, label: "Approved - Remove Violation" },
        //{ id: 4, label: "Approved - Remove Roadside" }, // Not included as a status for violation challenges
        { id: 5, label: "Denied by Management" },
        { id: 6, label: "Denied by DATAQ" }
    ]);

    // If violation has a challenge status set the ddl to the correct option, but first
    // set to default "new" (id: 1)
    self.challengeOptionSelectedId = ko.observable(self.challengeOptions()[0].id); // Initialize to "new"
    ko.utils.arrayFirst(self.challengeOptions(), function (option) {
        if (option.id == data.dataqChallengeStatusId) {
            self.challengeOptionSelectedId(option.id);
        }
    });


    // Determines when the send email option is visible
    // When "Denied by DATAQ" (id: 6) has been selected -> show
    self.showSendEmail = ko.observable(false);
    self.challengeOptionSelectedId.subscribe(function (selectedId) {
        if (selectedId == 6) {
            self.showSendEmail(true);
        } else {
            self.showSendEmail(false);
        }
    });

    // Determines when the violation challenge ddl should be displayed/visible
    self.displayViolationChallengeDDL = ko.computed(function () {
        var dqChallengeId = mainModel.dataqChallengeId();

        // If dqChallengeId is null then it is a new request so make visible
        if (dqChallengeId == undefined) {
            return true;
        }

        // Else then only show control if user selected a violation to challenge
        if (dqChallengeId != undefined && self.violationChallenged() == true) {
            return true;
        }

        return false;
    });

    // Determines when the violation challenge checkbox is enabled (allow to check/uncheck)
    self.enableViolationChallengeCheckbox = ko.computed(function () {
        if (data.violationChallenged == undefined) {
            return true;
        }

        return self.challengeOptionSelectedId() == 1
    });

}

class DataQChallengeEntryViewModel {
    constructor() {
        var self = this;

        //============================
        // UI Controls
        self.isLoading = ko.observable(true);
        self.errors = ko.observableArray([]);
        self.isReadOnly = ko.observable(false);
        var editor = $('#jqxCommentEditor');
        self.docFileUploadModal = ko.observable();
        self.fileInput = ko.observable();
        self.uploadErrors = ko.observableArray();

        // Init our jq widgets
        editor.jqxEditor({ height: '500px', width: '100%' });
                
        //============================
        // Data

        // List of Challenge Status
        // Id: is the identity key for the challenge status table
        // Label: is the description
        self.challengeStatusOptions = ko.observableArray([
            { id: 1, label: "New" },
            { id: 2, label: "Pending" },
            { id: 3, label: "Approved - Remove Violation" },
            { id: 4, label: "Approved - Remove Roadside" },
            { id: 5, label: "Denied by Management" },
            { id: 6, label: "Denied by DATAQ" }
        ]);

        self.inspectionId = ko.observable();
        self.phone = ko.observable().extend({ required: true });
        self.altPhone = ko.observable();
        self.dataqChallengeStatusId = ko.observable(); // the status dll id
        self.dataqChallengeId = ko.observable(); // the challenge identity key
        
        self.violations = ko.observableArray([]);
        self.inspectionExId = ko.observable();
        self.contactName = ko.observable().extend({ required: true });
        self.contactEmail = ko.observable().extend({ email: true });
        self.successMsg = ko.observable();

        self.challengeStatusSelected = ko.observable();
        self.fileAttachments = ko.observableArray();

        //=========================
        // Behaviors
        
        // Open the document file upload modal
        //self.openFileUploadModal = function () {
        //    self.docFileUploadModal(new docFileUploadModel(self));
        //}

        // Download attachment
        self.downloadAttachment = function (file) {
            if (file == undefined)
                return false;

            self.errors.removeAll();

            var data = {
                id: file.id,
                challengeId: file.challengeId
            };

            dataModel.downloadAttachments("Challenge/DownloadDataQAttachment", "GET", data, file.fileName);
        }

        // Gets and reloads the list of document attachments
        self.getDocumentAttachments = function () {
            if (self.dataqChallengeId() == undefined)
                return false;

            dataModel.ajaxRequest("Challenge/GetAttachmentsForChallengeRequest/" + self.dataqChallengeId(), "GET").done(function (response) {
                self.fileAttachments(response);
            });
        }

        // Submit the form
        self.submitChallengeRequest = function () {
            self.errors.removeAll();
            self.uploadErrors.removeAll();

            var validationErrors = ko.validation.group(self);

            if (validationErrors().length > 0) {
                self.errors.push({ message: "Please correct any errors before submitting the request." });
                validationErrors.showAllMessages();
                return false;
            }

            var data = {
                RoadsideId: self.inspectionId(),
                PersonRequesting: self.contactName(),
                ChallengeStatusId: self.challengeStatusSelected().id,
                Phone: self.phone(),
                AltPhone: self.altPhone(),
                Email: self.contactEmail(),
                Violations: ko.toJS(self.violations()),
                Comment: editor.val(),
                DataqChallengeId: self.dataqChallengeId() || 0
            }

            self.isLoading(true);

            dataModel.ajaxRequest("Challenge/SubmitChallengeRequest", "POST", data).done(function (response) {

                self.isLoading(false);

                if (response.success == false && response.errors.length > 0) {
                    for (i = 0; i < response.errors.length; i++) {
                        self.errors.push({ message: response.errors[i] });
                    }
                }

                if (response.success == true) {
                    var files = $('#filesToUploadInput').get(0).files;

                    if (files.length > 0 && files != undefined) {
                        self.uploadDocuments(response.challengeId);
                    } else {

                        self.successMsg("Challenge saved successfully.");
                        self.getPageData();
                        setTimeout(function () {
                            self.successMsg("");
                        }, 3000);
                    }
                }

            });
        }

        // Upload Documents and Attachments
        self.uploadDocuments = function (challengeId) {
            if (challengeId == undefined)
                return false;

            self.errors.removeAll();
            
            var files = $('#filesToUploadInput').get(0).files;

            if (files != undefined && files.length > 0) {
                var formData = new FormData();

                // Need to pass the parameters in the form via the following method.
                // Using a binding model on api causes 415 unsupported media type
                for (var i = 0; i < files.length; i++) {

                    var file = files[i];
                    
                    formData.append('fileToUpload_' + i, file, file.name);
                }
                
                formData.append('dataqChallengeId', challengeId);
                formData.append('roadsideExId', self.inspectionExId());

                dataModel.ajaxUploadRequest("Challenge/UploadFileForChallengeRequest", "POST", formData)
                    .done(function (response) {

                        // Get our successful file uploads
                        //if (response.filesUploaded != undefined && response.filesUploaded.length > 0) {
                        //    $.each(response.filesUploaded, function (index, value) {
                        //        self.filesUploadedSuccessfully.push({ status: 'success', message: value });
                        //    });

                            
                        //}

                        // Get our failed file uploads
                        if (response.filesFailed != undefined && response.filesFailed.length > 0) {
                            $.each(response.filesFailed, function (index, value) {
                                self.uploadErrors.push({ status: 'failed', message: value });
                            });
                        } else {
                            self.successMsg("Challenge saved successfully.");
                            self.getPageData();
                            setTimeout(function () {
                                self.successMsg("");
                            }, 3000);
                        }

                        self.fileInput("");
                        self.isLoading(false);
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) { // Some other exception was thrown or validation error
                        if (jqXHR.responseJSON != undefined) {
                            self.errors.push({ message: jqXHR.responseJSON.message });
                        } else {
                            self.errors.push({ message: "Error occurred uploading document(s)." });
                        }

                        self.fileInput("");
                        self.isLoading(false);
                    });

            }
        };

        // This gets called on page load and after form submission to reload the updated page/form data
        self.getPageData = function (inspectionExId) {
            var id = inspectionExId || window.location.pathname.split("/")[2];
            
            self.isLoading(true);

            // Returns::
            // inspectionDetails: json object InspectionDetails with inspection information, array of violations for grid, and array of attachments for challenge or null if inspection not found.
            // isReadOnly: boolean flag if the input fields (contact name, phones, email, etc) are allowed to be edited
            dataModel.ajaxRequest("Challenge/GetInpsectionForChallengeRequest/" + id, "GET").done(function (response) {
                var inspectionDetails = response.inspectionDetails; // page - form data
                
                // Redirect user if inspection is not found
                if (inspectionDetails == undefined) {
                    window.location.replace("/InspectionLookup");
                    return false;
                }

                // Set our data
                //self.isReadOnly(response.isReadOnly);
                self.inspectionId(inspectionDetails.id);
                self.inspectionExId(inspectionDetails.inspectionExId);
                self.contactEmail(inspectionDetails.email);
                self.contactName(inspectionDetails.personRequesting);
                self.phone(inspectionDetails.phone);
                self.altPhone(formatPhoneNumber(inspectionDetails.altPhone));
                self.dataqChallengeStatusId(inspectionDetails.dataqChallengeStatusId);
                self.dataqChallengeId(inspectionDetails.dataqChallengeId);

                self.challengeStatusSelected(self.challengeStatusOptions()[inspectionDetails.dataqChallengeStatusId - 1]);

                if (inspectionDetails.violations.length > 0) {
                    self.violations.removeAll();

                    for (var i = 0; i < inspectionDetails.violations.length; i++) {
                        var violation = inspectionDetails.violations[i];

                        self.violations.push(new roadsideModel(violation, self));
                    }
                }

                if (inspectionDetails.comments != undefined && inspectionDetails.comments.length > 0) {
                    editor.val(inspectionDetails.comments);
                }


                if (inspectionDetails.attachments.length > 0) {
                    self.fileAttachments(inspectionDetails.attachments);
                }

                self.isLoading(false);
            });
        }

        // Do page init checks, loads, etc.
        self.initPage = function () {
            var id = window.location.pathname.split("/")[2];
            
            // Redirect user if accessing page via "ChallengeRequestNew.aspx" and no inspection id is present
            if(id == undefined)
                window.location.replace("/Challenge");

            self.getPageData(id);

        }

        self.initPage();
    }
}

import template from './dataq-challenge-entry-page.html';
export default { viewModel: DataQChallengeEntryViewModel, template: template }