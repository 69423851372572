/// <reference path="../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/jqwidgets.d.ts" />
/// <reference path="../../../../../node_modules/@types/jqueryui/index.d.ts" />
import TntContext, {
  ITrackTraceMovementState,
  ITrackTraceOrderEntryContextState,
  MOVEMENTSTATUS_ENUM,
  TRACKINGSTATUS_ENUM,
} from "./trackTraceOrderEntryContext";
import { ValueObject } from "./models";
import {
  fetchByPassTrackingAsync,
  fetchTntProvidersAsync,
  fetchTntVendorsAsync,
} from "./services/apiService";
import { datetimeUTC, formatPhoneNumber, noop } from "global-functions";
import { showconfirm } from "show-dialog-methods";
import { cleanContactEmail, getAgencyContactDetails } from "./common";
import userProfile from "user-profile";

class OrderEntryTrackTraceViewModel {
  movementExId: string;
  movementSequence: number;
  jqxDateTimeForMoveId: string;
  $tntContext = TntContext;
  $trackedMove: ko.PureComputed<ITrackTraceMovementState | undefined>;

  vendorList = ko.observableArray([] as ValueObject[]);
  loadingFailed = ko.observable(false);
  alertErrorMsg = ko.observable();
  trackingInfoHelpText: ko.PureComputed<string>;
  selectedTrackingVendor: ko.PureComputed<any>;

  isReadOnly: ko.PureComputed<boolean>;
  validationErrors: ko.PureComputed<ValueObject>;
  startTrackReadonlyText: ko.PureComputed<string>;
  transitStatus: ko.PureComputed<string | undefined>;
  trackingBtnLabel: ko.PureComputed<string>;
  trackingBtnTooltip: ko.PureComputed<string>;
  trackingBtnColorClass: ko.PureComputed<string>;
  startTrackingBtnEnabled: ko.PureComputed<boolean>;
  byPassTrackingCheckBox: ko.PureComputed<boolean>;
  selectedLocationUpdateNotifyInterval: ko.PureComputed<number>;
  locationUpdateNotifyIntervalOptions = ko.observableArray([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);
  notifyOnLocationUpdatesCheckBox: ko.PureComputed<boolean>;
  notifyOnPickupDeliveredCheckBox: ko.PureComputed<boolean>;
  geoFenceRadius: ko.PureComputed<number>;

  showDriverPhoneSection: ko.PureComputed;
  handleStartTracking: () => Promise<void>;

  constructor(props) {
    props = ko.toJS(props);

    this.handleStartTracking = props.handleStartTracking ?? noop;

    this.movementExId = props.movementExId;

    this.$trackedMove = TntContext.selectTrackedMovement(props.movementExId);

    this.movementSequence = 1;

    this.jqxDateTimeForMoveId = `startTrackInput-${props.movementExId}`;

    this.trackingInfoHelpText = ko.pureComputed(() => {
      const move = this.$trackedMove();
      const errors = move.getValidationErrorObj(
        move.tracking,
        TntContext.getState().isBrokered
      );

      if (this.byPassTrackingCheckBox()) {
        return ``;
      }

      if (
        move.movementStatus !== MOVEMENTSTATUS_ENUM.Available &&
        move.movementStatus !== MOVEMENTSTATUS_ENUM.InProgress &&
        move.movementStatus !== MOVEMENTSTATUS_ENUM.Covered
      ) {
        return `The movement must be ${
          TntContext.getState().isBrokered ? "covered" : "available"
        } or in progress status to Start Tracking.`;
      }

      if (errors["carrierContactEmail"]) {
        return `Carrier Contact Information needed. Use 'Send Rate Confirmation' and select a contact.`;
      }

      if (errors["driver1Phone"]) {
        return `A driver phone number is required.`;
      }

      if (errors["assignedTractor"]) {
        return `Please assign a tractor to the movement to start tracking.`;
      }
      if (errors.count > 0) {
        return `Please correct any errors or update fields that are required.`;
      }

      return ``;
    });

    this.isReadOnly = ko.pureComputed(() => {
      const move = this.$trackedMove();

      return (
        move.tracking.trackingId !== null &&
        move.tracking.trackingStatusId === TRACKINGSTATUS_ENUM.Activated
      );
    });

    const selectedVendor = ko.observable(1);
    this.selectedTrackingVendor = ko.pureComputed({
      read: selectedVendor,
      write: (val) => {
        selectedVendor(val);

        TntContext.upsertTrackedMovement(this.movementExId, {
          tracking: {
            vendorId: val,
          },
        });
      },
    });

    this.validationErrors = ko.pureComputed(() => {
      const move = this.$trackedMove();

      return move.getValidationErrorObj(
        move.tracking,
        TntContext.getState().isBrokered
      );
    });

    this.startTrackReadonlyText = ko.pureComputed(() => {
      const startTrackingMins = this.$trackedMove()?.tracking.startTrackingMins;
      let { hours, mins } = this.getHoursMinsFromMins(startTrackingMins);
      if (hours > 12) {
        hours = 24 - hours;
      }
      hours = hours === 0 ? 1 : hours;
      return `${hours < 10 ? "0" + hours : hours} : ${
        mins === 0 ? "00" : mins
      }`;
    });

    this.transitStatus = ko.pureComputed(() => {
      const move = this.$trackedMove();
      if (move.tracking) {
        const trackingStatusId = move.tracking.trackingStatusId;
        const lastLocation = move.lastLocation;

        if (trackingStatusId === TRACKINGSTATUS_ENUM.Activated) {
          return !lastLocation || !lastLocation.status
            ? "While tracking has started the driver has not enabled it yet. Please contact the driver to enable tracking."
            : lastLocation.status;
        }
      }

      return "N/A";
    });

    this.trackingBtnLabel = ko.pureComputed((): string => {
      const move = this.$trackedMove();

      if (
        move.tracking.trackingId &&
        move.tracking.trackingStatusId &&
        move.tracking.trackingStatusId === TRACKINGSTATUS_ENUM.Activated
      ) {
        return `Cancel Tracking`;
      }

      return `Start Tracking`;
    });

    this.trackingBtnTooltip = ko.pureComputed(() => {
      const move = this.$trackedMove();

      if (move.tracking.trackingId) {
        return this.trackingBtnLabel();
      }

      return this.trackingInfoHelpText();
    });

    this.trackingBtnColorClass = ko.pureComputed((): string => {
      const move = this.$trackedMove();

      return move.tracking.trackingStatusId === TRACKINGSTATUS_ENUM.Activated
        ? "btn-danger"
        : "btn-primary";
    });

    this.startTrackingBtnEnabled = ko.pureComputed(() => {
      const move = this.$trackedMove();
      const errors = move.getValidationErrorObj(
        move.tracking,
        TntContext.getState().isBrokered
      );

      if (
        move.tracking.trackingId &&
        this.trackingBtnLabel() === "Cancel Tracking"
      ) {
        return true;
      }

      if (errors.count === 0) {
        if (TntContext.getState().isBrokered) {
          return (
            move.movementStatus === MOVEMENTSTATUS_ENUM.Covered ||
            move.movementStatus === MOVEMENTSTATUS_ENUM.InProgress
          );
        }

        return (
          move.movementStatus === MOVEMENTSTATUS_ENUM.Available ||
          move.movementStatus === MOVEMENTSTATUS_ENUM.InProgress
        );
      }

      return false;
    });

    this.byPassTrackingCheckBox = ko.pureComputed({
      read: () => this.$trackedMove().tracking.byPassTracking ?? false,
      write: async (checked) => {
        const move = this.$trackedMove();
        const username = checked
          ? move.tracking.byPassTrackingUserName
          : undefined;

        const updatedStatusId =
          !checked &&
          move.tracking.trackingStatusId === TRACKINGSTATUS_ENUM.Cancelled
            ? TRACKINGSTATUS_ENUM.Pending
            : move.tracking.trackingStatusId;

        TntContext.upsertTrackedMovement(this.movementExId, {
          tracking: {
            byPassTracking: checked,
            byPassTrackingUserName: username,
            trackingStatusId: updatedStatusId,
          },
        });

        await this.handleByPassTrackingChange(checked);
      },
    });

    this.selectedLocationUpdateNotifyInterval = ko.pureComputed({
      read: () =>
        this.$trackedMove().tracking.locationUpdateNotifyInterval ?? 1,
      write: (newValue) => {
        TntContext.upsertTrackedMovement(this.movementExId, {
          tracking: { locationUpdateNotifyInterval: newValue },
        });
      },
    });

    this.notifyOnLocationUpdatesCheckBox = ko.pureComputed({
      read: () => this.$trackedMove().tracking.notifyOnLocationUpdates ?? false,
      write: (checked) => {
        TntContext.upsertTrackedMovement(this.movementExId, {
          tracking: {
            notifyOnLocationUpdates: checked,
          },
        });
      },
    });

    this.notifyOnPickupDeliveredCheckBox = ko.pureComputed({
      read: () => this.$trackedMove().tracking.notifyOnPickupDelivered ?? false,
      write: (checked) => {
        TntContext.upsertTrackedMovement(this.movementExId, {
          tracking: {
            notifyOnPickupDelivered: checked,
          },
        });
      },
    });

    this.geoFenceRadius = ko.pureComputed({
      read: () => this.$trackedMove().movementGeoFenceRadius ?? 2,
      write: (val) => {
        TntContext.upsertTrackedMovement(this.movementExId, {
          movementGeoFenceRadius: val ?? 2,
        });
      },
    });

    this.isReadOnly.subscribe((yes) => {
      if (yes === false) {
        setTimeout(() => {
          const mins = this.$trackedMove().tracking.startTrackingMins;
          this.loadJqxWidgets(mins ?? 60);
        }, 300);
      }
    });

    this.showDriverPhoneSection = ko.pureComputed(() => {
      if (this.$tntContext.getState().isBrokered === false) {
        return !this.$trackedMove().tracking.trucklineAssignment.hasELD;
      }

      return false;
    });

    TntContext.isTrackingOn.subscribe((yes) => {
      if (yes) {
        const vendorId = this.$trackedMove().tracking.vendorId ?? 1;

        this.selectedTrackingVendor(vendorId);
      }
    });

    this.loadVendorList();

    setTimeout(() => {
      const mins = this.$trackedMove().tracking.startTrackingMins;
      this.loadJqxWidgets(mins ?? 60);
      this.initValues();
    }, 300);
  }

  initValues = async () => {
    const move = this.$trackedMove();
    if (
      !move.tracking.authEmail ||
      !move.tracking.authPhone ||
      !move.tracking.agentPhone ||
      !move.tracking.agentEmail
    ) {
      const { email, phone } = await getAgencyContactDetails();
      let payload: ValueObject = {};
      let cleanEmail = cleanContactEmail(email || "");
      if (!move.tracking.agentPhone) {
        const x = userProfile.userContactInfo.phone || phone;
        payload.agentPhone = formatPhoneNumber(x);
      }
      if (!move.tracking.authEmail) {
        payload.authEmail = cleanEmail;
      }
      if (!move.tracking.agentEmail) {
        payload.agentEmail = userProfile.userContactInfo.email || cleanEmail;
      }
      if (!move.tracking.authName) {
        payload.authName = userProfile.userName;
      }
      if (!move.tracking.authPhone) {
        payload.authPhone = phone && formatPhoneNumber(phone);
      }

      if (!move.tracking.vendorId) {
        payload.vendorId = 1;
      }

      if (!move.tracking.locationUpdateNotifyInterval) {
        payload.vendorId = 1;
      }

      TntContext.upsertTrackedMovement(this.movementExId, {
        tracking: payload,
      });
    }

    const roles = await userprofile.roles();
    const canEditVendor = roles.some(
      (x) => x.toUpperCase() === "ADMINISTRATORS"
    );

    TntContext.updateSlice({
      sliceKey: "userCanEditVendor",
      payload: canEditVendor,
    });

    await this.loadAdditionalData();
  };

  handleTransitStatusRefresh = async () => {
    TntContext.refreshLastLocation(this.movementExId);
  };

  handleStartStopTracking = async () => {
    const move = this.$trackedMove();
    const errors = move.getValidationErrorObj(
      move.tracking,
      TntContext.getState().isBrokered
    );
    if (this.trackingBtnLabel() === "Cancel Tracking") {
      const msg =
        TntContext.getState().defaultProviderId > 0
          ? `The selected customer requires tracking. Are you sure you want to cancel?`
          : `Are you sure you want to cancel?`;

      const answer = await showconfirm(msg);

      if (answer) {
        TntContext.cancelMovementTracking(this.movementExId);
      }
    } else {
      if (errors.count) {
        return;
      }

      await this.handleStartTracking();
    }
  };

  handleChange = (_, event) => {
    const { value, name } = event.target;

    if (name.indexOf(":") > -1) {
      const keys = name.split(":");

      TntContext.upsertTrackedMovement(this.movementExId, {
        [keys[0]]: {
          [keys[1]]: value,
        },
      });
    } else {
      TntContext.upsertTrackedMovement(this.movementExId, { [name]: value });
    }
  };

  handlePhoneInput = (_, event) => {
    let { name, value } = event.currentTarget;
    const cleaned = formatPhoneNumber(value);
    if (value !== cleaned) {
      $(event.currentTarget).val(cleaned);
    }

    if (name.indexOf(":") > -1) {
      const keys = name.split(":");

      TntContext.upsertTrackedMovement(this.movementExId, {
        [keys[0]]: {
          [keys[1]]: value,
        },
      });
    } else {
      TntContext.upsertTrackedMovement(this.movementExId, { [name]: value });
    }
  };

  private loadVendorList = async () => {
    try {
      const vendors = (await fetchTntProvidersAsync()) as ValueObject[];
      this.vendorList(vendors);
    } catch (err) {
      console.error(err);
    }
  };

  private getHoursMinsFromMins = (minutes = 60) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return {
      hours,
      mins,
    };
  };

  private loadJqxWidgets = (initMinutes = 60) => {
    if ($("#" + this.jqxDateTimeForMoveId).length === 0) {
      return;
    }

    let initDate = datetimeUTC(new Date());
    initDate = initDate.hour(0);
    initDate = initDate.minute(initMinutes);
    initDate = initDate.second(0);
    initDate = initDate.millisecond(0);
    ($("#" + this.jqxDateTimeForMoveId) as any).jqxDateTimeInput({
      width: "150px",
      height: "30px",
      formatString: "hh:mm",
      showTimeButton: true,
      showCalendarButton: false,
      textAlign: "center",
      value: initDate.toDate(),
    });

    // HACK: Here we are hiding the AM/PM from a 12 hours selection.
    // -The last td of each row is the AM/PM portions.
    ($("#" + this.jqxDateTimeForMoveId) as any).on("open", function (event) {
      setTimeout(() => {
        const $t = $("div.jqx-date-time-input-popup");
        $t.find("td:last-child").hide();
      }, 10);
    });

    // All we want is the HOURS/MINUTES from the entered date.
    ($("#" + this.jqxDateTimeForMoveId) as any).on("valueChanged", (event) => {
      const jsDate = event.args.date;
      let hours = 1;
      let minutes = 0;
      if (jsDate) {
        hours = jsDate.getHours();
        minutes = jsDate.getMinutes();
      }

      TntContext.upsertTrackedMovement(this.movementExId, {
        tracking: {
          startTrackingMins: hours * 60 + minutes,
        },
      });
    });

    TntContext.upsertTrackedMovement(this.movementExId, {
      tracking: {
        startTrackingMins: initMinutes,
      },
    });
  };

  private handleByPassTrackingChange = async (byPass: boolean) => {
    const move = this.$trackedMove();

    await TntContext.setByPassTrackingForMovement(this.movementExId, byPass);

    if (byPass === false && TntContext.isTrackingRequired()) {
      await TntContext.saveTracking();
      await TntContext.startMovementTracking(this.movementExId);
    }
  };

  private loadAdditionalData = async () => {
    try {
      const result = (await fetchByPassTrackingAsync(
        this.movementExId
      )) as ValueObject | null;

      if (result) {
        TntContext.upsertTrackedMovement(this.movementExId, {
          tracking: {
            byPassTracking: result.byPassTracking ?? false,
            byPassTrackingUserId: result.byPassTrackingUserId,
            byPassTrackingUserName: result.byPassTrackingUsername,
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
}

import template from "./order-entry-track-trace-component.html";
import userprofile from "user-profile";
export default { viewModel: OrderEntryTrackTraceViewModel, template };
