import ko from "knockout";
import dataModel from "data-model";
import {
  createDateTimeFilterWidget,
  isEmpty,
  formatPhoneNumber,
  datetimeUTC,
} from "global-functions";
import gridStateUtils from "jqx.grid-utils";
import GridStateComponentViewModel from "jqx.grid-state-component";
import userProfile from "user-profile";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import template from "./tractor-grid-component.html";
import { showmessage } from "show-dialog-methods";
import * as $ from "jquery";
import { OrderPlanningViewModel } from "../order-planning-page";
import router from "router";
import storageManager from "../../../../utils/storageManager";
import broadcastAPI from "../../../../utils/broadcastAPI";
import userprofile from "user-profile";
import { loadSavedSearches } from "../../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";

dayjs.extend(dayjsDuration);

export const loadGridState = (state, $grid) => {
  if (state != null && $grid != null) {
    var gridState = $grid.jqxGrid("getstate") || {};
    state.selectedrowindex = -1;
    state.selectedrowindexes = [];
    state.pagenum = 0;
    state.height = gridState.height;
    state.pagesize = gridState.pagesize;
    state.pagesizeoptions = gridState.pagesizeoptions;
    $grid.jqxGrid("loadstate", state);
  }
};

class OrderPlanningTractorGrid {
  tractorRadiusLocation = ko.observable("");
  tractorRadius = ko.observable(10);
  tractorRadiusSearchType = ko.observable("destination");
  isOwnerOperator = userProfile.isOwnerOperator;
  grid = $("#jqxTractorGrid");
  gridProcessing = true;
  advancedTractorAlertSettings = ko.observable();
  selectedSavedSearchId = ko.observable();
  savedSearches = ko.observable();
  tractorDetailsModal = ko.observable();
  postTractorModal = ko.observable();
  tractorRadiusSearchModal = ko.observable();
  tractorGridState;
  tractorGridFiltersLoaded = ko.observable();
  createEmptyMoveModalHeader = ko.observable();
  createEmptyMoveModal = ko.observable();
  stateParameter = ko.observable();
  mapStateParameter = ko.observable();
  tractorIdParameter = ko.observable();
  clearSelectedSavedSearchDDL = ko.observable(false);
  refreshSavedSearchDDL = ko.observable("");
  filtersLoaded = ko.observable(false);
  gridLoaded = false;
  pageName = "orderplanning";

  /**@type {OrderPlanningViewModel} */
  $parent;

  constructor(params) {
    this.$parent = params.data;

    this.defaultGridToggleOptions = params.data.defaultGridToggleOptions;
    this.toggleTractors = params.data.toggleTractors;

    this.$parent.tractorGridComponent = this;
    this.stateParameter(this.$parent.params[0].state);
    this.mapStateParameter(this.$parent.params[0].mapstate);
    this.tractorIdParameter(this.$parent.params[0].tractorid);

    this.cancelPendingGridRefreshes = false;
    broadcastAPI.listenOn("geCurrentViewingAgency")(() => {
      this.cancelPendingGridRefreshes = true;
    });
  }

  handleOnSelected = (state) => {
    setTimeout(() => {
      this.handleLoadGridState(state);
      this.addFilters();
      loadGridState(state.grid, this.grid);

      this.filtersLoaded(true);
    }, 300);
  };

  // getSavedSearches() {
  //     return new Promise(function (resolve, reject) {
  //         dataModel
  //             .ajaxRequest("UserProfile/GetUserSearchFilterList", "get", {
  //                 name: "jqxSearchOrdersGrid",
  //                 pageName: window.location.pathname,
  //             })
  //             .done(function (data) {
  //                 if (data) {
  //                     var obj = data.map((x) => {
  //                         let search = JSON.parse(x.search);
  //                         return {
  //                             text: search.userSearchName || "",
  //                             search: new NamedGridStateObject(search),
  //                             id: x.id,
  //                         };
  //                     });
  //                     resolve(obj);
  //                 } else {
  //                     resolve(undefined);
  //                 }
  //             })
  //             .fail(function () {
  //                 reject();
  //             });
  //     });
  // }

  tractorTypes = ko.observableArray([
    { label: "Include Carrier Tractors", value: "Carrier", checked: false },
    {
      label: "Include Non-Domiciled Tractors",
      value: "GwTractors",
      checked: false,
      visible: !userProfile.isUserDriver,
    },
  ]);

  tractorStatus = ["Available", "In Progress", "Pre-Assigned"];

  loadTractors = ({ initHeight = 400 } = {}) => {
    return new Promise((resolve) => {
      var source = {
        url: "OrderPlanning/GetTractors/v2",
        datatype: "json",
        formatdata: (data) => {
          data.agencyId = userProfile.currentAgencyId();
          data.includeTractors = ko.utils.arrayMap(
            ko.utils.arrayFilter(this.tractorTypes(), function (item) {
              return item.checked;
            }),
            function (item) {
              return item.value;
            }
          );
          data.radiusLocation = this.tractorRadiusLocation();
          data.radius = this.tractorRadius();
          data.regions = this.$parent.headerActionButtonsComponent.regions();
          data.tractorRegions =
            this.$parent.headerActionButtonsComponent.tractorRegions();
          data.radiusSearchType = this.tractorRadiusSearchType();
          if (this.$parent.orderGridComponent.matchMyOrderMatchId() > 0) {
            data.matchId =
              this.$parent.orderGridComponent.matchMyOrderMatchId();
          }
          return data;
        },
        datafields: [
          { name: "id", type: "int" },
          { name: "distance", type: "float" },
          { name: "tractorId", type: "string" },
          { name: "driverId", type: "string" },
          { name: "trailerId", type: "string" },
          { name: "trailerType", type: "string" },

          { name: "status", type: "string" },
          { name: "driverName", type: "string" },

          { name: "destination", type: "string" },

          { name: "ptaDate", type: "date" },

          { name: "deliveryDate", type: "date" },
          { name: "assignedPickupDate", type: "date" },

          { name: "driver2Id", type: "string" },
          { name: "driver2Name", type: "string" },

          { name: "isCarrierDriver", type: "bool" },
          { name: "isGemUser", type: "bool" },
          { name: "isCarrierBoardTractor", type: "bool" },

          { name: "preassignmentCount", type: "int" },
          { name: "agencyExId", type: "string" },
          { name: "fleetManager", type: "string" }, // dispatcher
          { name: "isExternalBoard", type: "string" },
          { name: "dispatchMessages", type: "string" },
          { name: "hasDispatchMessage", type: "boolean" },
          { name: "blockDispatch", type: "bool" },

          { name: "dcrRating", type: "string" },
          { name: "canada", type: "boolean" },
          { name: "carbCompliant", type: "boolean" },
          { name: "share_Tractor", type: "bool" },
          { name: "enableShare", type: "bool" },
          { name: "isLockout", type: "bool" },
          { name: "equipGrpUnitMessageCount", type: "number" },
          { name: "equipGrpHasUrgentMessage", type: "bool" },
          { name: "equipGrpMostRecentMsg", type: "string" },
          { name: "planningCommentsCount", type: "number" },
          { name: "driverPlanningComment", type: "string" },
          { name: "tractorPlanningComment", type: "string" },
          { name: "trailerPlanningComment", type: "string" },
          { name: "currentEquipmentGroupId", type: "int" },
          { name: "isSystemTruck", type: "bool" },
          { name: "lastPing", type: "date" },
          { name: "currentCity", type: "string" },
          { name: "currentState", type: "string" },
          { name: "currentZip", type: "string" },
          { name: "currentLocation", type: "string" },
        ],
        beforeprocessing: (response) => {
          response.records = response.records || [];
          // Need to remove offsets here before any other method is called by jqx
          response.records = response.records.map((x) => {
            if (x.ptaDate) {
              x.ptaDate = x.ptaDate.substr(0, x.ptaDate.indexOf("T") + 9);
            }

            if (x.deliveryDate) {
              x.deliveryDate = x.deliveryDate.substr(
                0,
                x.deliveryDate.indexOf("T") + 9
              );
            }

            if (x.assignedPickupDate) {
              x.assignedPickupDate = x.assignedPickupDate.substr(
                0,
                x.assignedPickupDate.indexOf("T") + 9
              );
            }

            return x;
          });
        },
        beforeLoadComplete: (records) => {
          for (var i = 0; i < records.length; i++) {
            records[i].agencyExId = records[i].isSystemTruck
              ? "System Truck"
              : records[i].agencyExId;

            // records[i].canada = records[i].canada ? "Yes" : "No";
            // records[i].carbCompliant = records[i].carbCompliant ? "Yes" : "No";
            //records[i].dispatchMessages = (records[i].dispatchMessages != null && records[i].dispatchMessages.length > 0) ? "True" : "False";

            // Get a count of plannning comments
            var count = 0;
            if (records[i].driverPlanningComment) count = count + 1;
            if (records[i].tractorPlanningComment) count = count + 1;
            if (records[i].trailerPlanningComment) count = count + 1;
            records[i].planningCommentsCount = count;
          }

          return records;
        },
      };

      var tractorSource = dataModel.getDataAdapter(source);

      this.grid.jqxGrid({
        theme: "GWTMDark",
        width: "100%",
        height: initHeight,
        source: tractorSource,
        altrows: true,
        showfilterrow: true,
        sortable: true,
        filterable: true,
        columnsreorder: true,
        enablebrowserselection: true,
        columnsmenu: false,
        columnsresize: true,
        showstatusbar: true,
        statusbarheight: 25,
        toolbarheight: 70,
        renderstatusbar: (statusbar) => {
          var div = $("<div>", {
            id: "tractorRowCount",
            style: "padding:4px;",
          });
          div.append(
            "Number of Records: " + this.grid.jqxGrid("getrows").length
          );
          statusbar.append(div);
        },
        columns: this.columns,
        showtoolbar: true,
        rendertoolbar: this.renderToolBar,
        ready: () => {
          loadGridState(this.tractorGridState, this.grid);
          this.addFilters();
          this.gridLoaded = true;
        },
      });

      this.grid.on("bindingcomplete", (event) => {
        this.gridProcessing = false;

        if (($("#jqxTractorGrid") || {}).length) {
          var rowCount = (this.grid.jqxGrid("getrows") || []).length;
          // Used to override the default "No data to display" grid message
          var localizationobj = {};
          localizationobj.emptydatastring = this.tractorRadiusLocation()
            ? "No results found, do you want to increase your search radius?" // set a radius search empty message
            : "No data to display"; // Reset the default msg if not a radius search
          this.grid.jqxGrid("localizestrings", localizationobj);

          $("#tractorRowCount").text("Number of Records: " + rowCount);
          // override any user-defined sorting if Match My Order or tractor radius is chosen as grid data will be sorted by distance
          if (
            this.$parent.orderGridComponent.matchMyOrderMatchId() > 0 ||
            this.tractorRadiusLocation().length > 0
          ) {
            this.grid.jqxGrid("removesort");
            this.$parent.orderGridComponent.matchMyOrderMatchId(undefined);
          }
        }
      });

      this.grid.on("filter", (event) => {
        var rowCount = this.grid.jqxGrid("getrows").length;
        $("#tractorRowCount").text("Number of Records: " + rowCount);
      });

      resolve();
    });
  };

  isBindingComplete = () => {
    return this.grid.jqxGrid("isBindingCompleted");
  };

  cellclassname = function (row, column, value, data) {
    if (data.isLockout) {
      return "lockoutRowClass";
    }
  };

  placeHolderFilterWidget = (column, columnElement, widget) => {
    widget.jqxInput({
      placeHolder: "Type Here...",
    });
  };

  columns = [
    {
      text: "",
      pinned: true,
      columntype: "button",
      filterable: false,
      width: 100,
      buttonclick: (row) => {
        var datarow = this.grid.jqxGrid("getrowdata", row);
        if (datarow) {
          this.tractorDetailsModal({
            data: {
              tractorId: datarow.id,
              tractorExId: datarow.tractorId,
              trailerExId: datarow.trailerId,
              driverExId: datarow.driverId,
              isCarrierDriver: datarow.isCarrierDriver,
              isCarrierBoardTractor: datarow.isCarrierBoardTractor,
              equipmentGroupId: datarow.currentEquipmentGroupId,
            },
            $parent: this,
          });
        }
      },
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaultHTML,
        column,
        rowData
      ) => {
        return "Details";
      },
    },
    {
      text: "Tractor Id",
      datafield: "tractorId",
      width: 100,
      pinned: true,
      cellclassname: this.cellclassname,
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaultHTML,
        column,
        rowData
      ) => {
        if (defaultHTML != null) {
          if (value != "") {
            var bColor = "";
            var bColorIE = "";
            var cColor = "";
            var d = new Date();
            d.setHours(d.getHours() + 2);
            var parseDeliveryDate = new Date(rowData.deliveryDate);
            var parsePickUpDate = new Date(rowData.assignedPickupDate);
            if (!rowData.isLockout) {
              if (rowData.status === "In Progress") {
                if (d > parseDeliveryDate) {
                  bColor = "linear-gradient(to bottom,#222222,#f08080)";
                  bColorIE = "#f08080";
                  cColor = "white";
                }
              } else if (rowData.status === "Pre-Assigned") {
                if (d > parsePickUpDate) {
                  bColor = "linear-gradient(to bottom,#222222,#f08080)";
                  bColorIE = "#f08080";
                  cColor = "white";
                } else {
                  bColor = "linear-gradient(to bottom,#222222,#017245)";
                  bColorIE = "#017245";
                  cColor = "#06f998";
                }
              } else {
                bColor = "linear-gradient(to bottom,#222222,#00529b)";
                bColorIE = "#00529b";
                cColor = "white";
              }
            }

            var preAssignmentCount = "";
            if (
              rowData.preassignmentCount != null &&
              rowData.preassignmentCount > 0
            ) {
              preAssignmentCount = " (" + rowData.preassignmentCount + ")";
            }

            var cell = $(defaultHTML);
            $(cell).css({
              "background-image": bColor,
              "background-color": bColorIE,
            });
            cell.addClass("jqxGridCustomCell");
            if (cColor) {
              cell.html(
                ' <a href="Tractors?tractorId=' +
                  value +
                  '" target="_blank"  style="color:' +
                  cColor +
                  ';"  >' +
                  value +
                  preAssignmentCount +
                  "</a>"
              );
            } else {
              cell.html(
                ' <a href="Tractors?tractorId=' +
                  value +
                  '" target="_blank" class="colorId" >' +
                  value +
                  preAssignmentCount +
                  "</a>"
              );
            }

            return cell[0].outerHTML;
          }
          return "";
        }
        return defaultHTML;
      },
      createfilterwidget: this.placeHolderFilterWidget,
    },
    {
      text: "Distance",
      datafield: "distance",
      cellsformat: "d2",
      cellsalign: "center",
      filtercondition: "starts_with",
      width: 80,
      hideable: true,
      hidden: true,
    },
    {
      text: "Driver Id",
      datafield: "driverId",
      width: 100,
      cellclassname: this.cellclassname,
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaultHTML,
        column,
        rowData
      ) => {
        if (defaultHTML) {
          if (rowData.isGemUser) {
            var cell = $(defaultHTML);
            cell.html(
              value + " " + "<img src='Content/Images/isGemUser.gif'></img>"
            );
            return cell[0].outerHTML;
          }
        }
        return defaultHTML;
      },
      createfilterwidget: this.placeHolderFilterWidget,
    },
    {
      text: "Trailer Id",
      datafield: "trailerId",
      width: 100,
      createfilterwidget: this.placeHolderFilterWidget,
      cellclassname: this.cellclassname,
    },
    {
      text: "Trailer Type",
      datafield: "trailerType",
      width: 100,
      createfilterwidget: this.placeHolderFilterWidget,
      cellclassname: this.cellclassname,
    },
    {
      text: "Status",
      datafield: "status",
      filtertype: "checkedlist",
      filteritems: this.tractorStatus,
      width: 150,
      cellclassname: this.cellclassname,
    },
    {
      text: "Driver Name",
      datafield: "driverName",
      width: 200,
      createfilterwidget: this.placeHolderFilterWidget,
      cellclassname: this.cellclassname,
    },
    {
      text: "Messages",
      datafield: "equipGrpUnitMessageCount",
      width: 100,
      filterable: false,
      sortable: false,
      cellsrenderer: (
        row,
        columnfield,
        value,
        defaultHTML,
        column,
        rowData
      ) => {
        if (defaultHTML != null && rowData.equipGrpHasUrgentMessage) {
          var $html = $(defaultHTML).css({
            color: "white",
            "background-color": "red",
            "font-weight": "bold",
            margin: "-1px",
            padding: "6px",
          });
          return $html[0].outerHTML;
        }
      },
    },
    {
      text: "Planning Comments",
      datafield: "planningCommentsCount",
      width: 100,
      filterable: false,
      sortable: false,
    },
    {
      text: "Destination",
      datafield: "destination",
      width: 200,
      cellclassname: this.cellclassname,
      createfilterwidget: (column, columnElement, widget) => {
        widget.jqxInput({
          placeHolder: "Enter City, State, (Zip)",
        });
      },
    },
    {
      text: "Current Location",
      width: 150,
      filterable: false,
      datafield: "currentLocation",
    },
    {
      text: "Last Ping",
      datafield: "lastPing",
      cellsformat: "MM/dd/yyyy HH:mm",
      width: 140,
      filterable: false,
    },
    {
      text: "Agency",
      datafield: "agencyExId",
      width: 80,
      cellclassname: this.cellclassname,
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaultHTML,
        column,
        rowData
      ) {
        if (defaultHTML) {
          if (rowData.agencyPhone) {
            return (
              '<span data-toggle="tooltip" title="' +
              formatPhoneNumber(rowData.agencyPhone) +
              '">' +
              defaultHTML +
              "</span>"
            );
          }
        }
        return defaultHTML;
      },
    },
    {
      text: "Dispatcher",
      datafield: "fleetManager",
      width: 200,
      createfilterwidget: this.placeHolderFilterWidget,
      cellclassname: this.cellclassname,
    },
    {
      text: "PTA Date",
      datafield: "ptaDate",
      filtertype: "date",
      cellsformat: "MM/dd/yyyy HH:mm",
      width: 130,
      createfilterwidget: createDateTimeFilterWidget,
      cellclassname: this.cellclassname,
    },
    {
      text: "Dispatch Messages",
      datafield: "hasDispatchMessage",
      width: 200,
      //filtertype: 'checkedlist', filteritems: ["True", "False"],
      filtertype: "bool", // checkbox
      columntype: "checkbox",
      editable: false,
      cellclassname: this.cellclassname,
      // cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
      //     if (defaultHTML) {
      //         if (rowData.dispatchMessages) {
      //             // dispatch messages may contain double angle brackets so need to prevent those chars from from being read as an HTML tag
      //             return defaultHTML.replace("<<", "&lt;&lt;").replace(">>", "&gt;&gt;");
      //         }
      //     }
      //     return defaultHTML;
      // }
    },
    {
      text: "Driver2 Id",
      datafield: "driver2Id",
      width: 100,
      cellclassname: this.cellclassname,
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaultHTML,
        column,
        rowData
      ) {
        if (defaultHTML && value != "") {
          if (rowData.isGemUser) {
            var cell = $(defaultHTML);
            cell.html(
              value + " " + "<img src='Content/Images/isGemUser.gif'></img>"
            );
            return cell[0].outerHTML;
          }
        }
        return defaultHTML;
      },
      createfilterwidget: this.placeHolderFilterWidget,
    },
    {
      text: "Driver2 Name",
      datafield: "driver2Name",
      width: 200,
      createfilterwidget: this.placeHolderFilterWidget,
      cellclassname: this.cellclassname,
    },
    {
      text: "DCR Rating",
      datafield: "dcrRating",
      width: 100,
      createfilterwidget: this.placeHolderFilterWidget,
      cellclassname: this.cellclassname,
    },
    {
      text: "Canada",
      datafield: "canada",
      width: 100, //filtertype: 'checkedlist', filteritems: ["Yes", "No"],
      filtertype: "bool", // checkbox
      columntype: "checkbox",
      editable: false,
      cellclassname: this.cellclassname,
    },
    {
      text: "Carb Compliant",
      datafield: "carbCompliant",
      width: 100, //filtertype: 'checkedlist', filteritems: ["Yes", "No"],
      filtertype: "bool", // checkbox
      columntype: "checkbox",
      editable: false,
      cellclassname: this.cellclassname,
    },
    { text: "-", datafield: "blockDispatch", hidden: true },
    { text: "-", datafield: "share_Tractor", hidden: true },
    { text: "-", datafield: "enableShare", hidden: true },
  ];

  renderToolBar = (toolbar) => {
    var vm1 = new GridStateComponentViewModel.viewModel(this.pageName);

    var tractorGridTemplate = $("#tractorGridToolbar");
    toolbar.append(tractorGridTemplate);
    vm1.actions.push("Match My Truck");
    if (!userProfile.isOwnerOperator && !userProfile.isUserDriver) {
      vm1.actions.push("Post Truck");
    }
    vm1.actions.push("Advanced Alert Settings");
    vm1.actions.push("Radius Search");
    vm1.actions.push("Insert Call-in Record");
    vm1.actions.push("Driver Call-in Listing");
    vm1.actions.push("Export All Tractors");
    vm1.actions.push("Save Search");

    vm1.clearFilters = () => {
      // Handle reset select filter list
      this.clearSelectedSavedSearchDDL(true);

      if (this.tractorRadiusLocation().length > 0) {
        this.tractorRadiusLocation("");
        this.tractorRadius(10);
        this.tractorRadiusSearchType("destination");
        this.refreshTractorGrid();
      } else {
        ko.utils.arrayForEach(this.tractorTypes(), function (item) {
          if (item.value == "GwTractors") {
            item.checked = false;
          } else if (item.value == "Carrier") {
            item.checked = false;
          }
        });

        this.tractorTypes.valueHasMutated();
        this.grid.jqxGrid("clearfilters");
      }
    };

    vm1.loadSaveSearchModalOverride = async () => {
      const savedSearches = await loadSavedSearches("jqxTractorGrid");

      const checkedItems = this.tractorTypes();
      const gwTractors = checkedItems.find((x) => x.value == "GwTractors") || {
        checked: false,
      };
      const carrierTractors = checkedItems.find(
        (x) => x.value == "Carrier"
      ) || { checked: false };

      var filters = {
        gwTractors: gwTractors.checked,
        carrierTractors: carrierTractors.checked,
        defaultGridToggleOptions: {
          ...this.defaultGridToggleOptions(),
          timestamp: Date.now(),
        },
        isDefault: false,
      };

      vm1.loadSaveSearchModal(
        this.grid,
        (val) => {
          if (val && val.searchname) {
            this.refreshSavedSearchDDL(val.searchname);
          }
        },
        savedSearches
          .filter((x) => x.searchName)
          .map((x) => ({ id: x.id, text: x.searchName })),
        filters,
        true
      );
    };

    vm1.setDefaultSearchOverride = async () => {
      const savedSearches = await loadSavedSearches("jqxTractorGrid");

      const checkedItems = this.tractorTypes();
      const gwTractors = checkedItems.find((x) => x.value == "GwTractors") || {
        checked: false,
      };
      const carrierTractors = checkedItems.find(
        (x) => x.value == "Carrier"
      ) || { checked: false };

      var filters = {
        gwTractors: gwTractors.checked,
        carrierTractors: carrierTractors.checked,
        defaultGridToggleOptions: {
          ...this.defaultGridToggleOptions(),
          timestamp: Date.now(),
        },
        isDefault: true,
      };

      vm1.loadSaveSearchModal(
        this.grid,
        (val) => {
          if (val && val.searchname) {
            this.refreshSavedSearchDDL(val.searchname);
          }
        },
        savedSearches
          .filter((x) => x.searchName)
          .map((x) => ({ id: x.id, text: x.searchName })),
        filters,
        true
      );
    };

    vm1.refresh = () => {
      dataModel.addClientSideLog("Selected: Trucks Grid Refresh Option.");
      this.refreshTractorGrid();
    };

    vm1.action = this.toolbarActionHandler;
    // override the save method of grid show/hide/reorder modal
    vm1.overrideCustomGridModalOptionsSave = () => {
      var filters = vm1.setDefaultSearchOverride();
      vm1.saveGridFilters(this.grid, filters);
    };
    var $template = GridStateComponentViewModel.template;
    const $actionList = $("#tdTractorGridAction");
    $actionList.append($template);

    toolbar.append($actionList);

    const $tractorGridToolbar = $("#tractorGridToolbar");
    toolbar.append($tractorGridToolbar);

    ko.applyBindingsToDescendants(vm1, $actionList[0]);
    // var tdTractorGridAction = tractorGridTemplate.find("#tdTractorGridAction");
    // tdTractorGridAction.append(GridStateComponentViewModel.template);
    // ko.applyBindingsToDescendants(vm1, tdTractorGridAction[0]);
  };

  tractorGridRadiusSearch = () => {
    this.refreshTractorGrid();
    this.grid.jqxGrid("showcolumn", "distance");
  };

  clearTractorGridRadiusSearch = () => {
    this.tractorRadiusSearchType("destination");
    this.tractorRadiusLocation("");
    this.tractorRadius(10);
  };

  exportGridData = function (fileName) {
    var jsonData = gridStateUtils.formatGridHeaders("jqxTractorGrid", false);
    dataModel.exportToCSV(jsonData, fileName, true);
  };

  exportGridDataAllTractors = () => {
    this.$parent.isLoading(true);
    var route = "OrderPlanning/GetTractorsForExport";
    var data = null;
    dataModel
      .ajaxRequest(route, "GET", data)
      .done((data, status, xhr) => {
        let result = data;
        var jsonData = gridStateUtils.formatGridHeaders(
          "jqxTractorGrid",
          false,
          result
        );
        this.$parent.isLoading(false);
        dataModel.exportToCSV(jsonData, "Tractors", result);
      })
      .fail((error, msg, d) => {
        this.$parent.isLoading(false);
      });
  };

  toolbarActionHandler = (item) => {
    if (item === "Radius Search") {
      dataModel.addClientSideLog("Selected: Trucks Grid Radius Search.");
      this.tractorRadiusSearchModal(this);
    } else if (item === "Export") {
      this.exportGridData("Tractors");
    } else if (item === "Export All Tractors") {
      this.exportGridDataAllTractors();
    } else if (item === "Match My Truck") {
      var rowindex = this.grid.jqxGrid("getselectedrowindex");
      if (rowindex > -1) {
        var tractor = this.grid.jqxGrid("getrowdata", rowindex);
        this.$parent.matchTractorId(tractor.id);
        // if an order radius is already specified, clear it out
        // if (this.$parent.orderGridComponent.orderRadiusLocation().length > 0) {
        //     this.$parent.orderGridComponent.orderRadiusLocation("");
        //     this.$parent.orderGridComponent.orderRadius(10);
        // }
        // this.$parent.orderGridComponent.refreshOrderGrid();
        // $("#jqxOrderGrid").jqxGrid('showcolumn', 'distance');
        dataModel.addClientSideLog("Selected: Trucks Grid Match My Truck.");

        const route = `ExternalBoard/v2?tractorId=${tractor.id}`;
        const tabNames = storageManager.get("loadboardTabNames") || [];
        const w = window.open(
          route,
          tabNames.length > 1 ? tabNames[tabNames.length - 1] : tabNames[0]
        );
        w.focus();
      } else {
        showmessage("Please select a tractor");
      }
    } else if (item === "Post Truck") {
      let rowIndex = this.grid.jqxGrid("getselectedrowindex");
      if (rowIndex > -1) {
        let dataRecord = this.grid.jqxGrid("getrowdata", rowIndex);
        if (dataRecord != null) {
          this.$parent.isLoading(true);
          dataModel
            .ajaxRequest("Loadboard/GetPostedTractor/" + dataRecord.id, "GET")
            .done((data, status, xhr) => {
              this.postTractorModal(data);
              this.$parent.isLoading(false);
            })
            .fail((error, msg, d) => {
              this.$parent.isLoading(false);
            });
          dataModel.addClientSideLog("Selected: Trucks Grid Post My Truck.");
        }
      } else {
        showmessage("Please select a tractor");
      }
    } else if (item === "Advanced Alert Settings") {
      let rowIndex = this.grid.jqxGrid("getselectedrowindex");
      if (rowIndex > -1) {
        let dataRecord = this.grid.jqxGrid("getrowdata", rowIndex);
        if (dataRecord) {
          this.$parent.isLoading(true);
          dataModel
            .ajaxRequest(
              "orderplanning/GetTractorAdvancedAlertSettings/" + dataRecord.id,
              "GET"
            )
            .done((data, status, xhr) => {
              data = data || { tractorId: dataRecord.id };
              this.advancedTractorAlertSettings({ data: data, $parent: this });
              this.$parent.isLoading(false);
            })
            .fail((error, msg, d) => {
              if (error.responseJSON != null) {
                showmessage(error.responseJSON.message);
              }
              this.$parent.isLoading(false);
            });
          dataModel.addClientSideLog(
            "Selected: Trucks Grid Advanced Alert Settings."
          );
        } else {
          showmessage("Please select a tractor");
        }
      } else {
        showmessage("Please select a tractor");
      }
    }
    //Call-In-Record
    else if (item === "Insert Call-in Record") {
      let rowIndex = this.grid.jqxGrid("getselectedrowindex");
      if (rowIndex > -1) {
        let dataRecord = this.grid.jqxGrid("getrowdata", rowIndex);
        if (dataRecord) {
          let obj = {
            tractorId: dataRecord.id,
            driverCarrierId: dataRecord.driverId,
            fromOrderPlanningTractorGrid: true,
          };
          this.$parent.newCallInModal(obj || {});
          dataModel.addClientSideLog(
            "Selected: Trucks Grid Insert Call In Record."
          );
        } else {
          showmessage("Please select a tractor");
        }
      } else {
        showmessage("Please select a tractor");
      }
    }
    //Driver Call-in listing
    else if (item === "Driver Call-in Listing") {
      let rowIndex = this.grid.jqxGrid("getselectedrowindex");
      if (rowIndex > -1) {
        let dataRecord = this.grid.jqxGrid("getrowdata", rowIndex);
        if (dataRecord) {
          let obj = {
            tractorId: dataRecord.id,
            driverCarrierId: dataRecord.driverId,
            fromOrderPlanningTractorGrid: true,
          };
          this.$parent.callInListModal(obj || {});
          dataModel.addClientSideLog(
            "Selected: Trucks Grid Driver Call In List."
          );
        } else {
          showmessage("Please select a tractor");
        }
      } else {
        showmessage("Please select a tractor");
      }
    } else if (item.includes("Create Empty Move")) {
      var rowIndex = this.grid.jqxGrid("getselectedrowindex");
      if (rowIndex > -1) {
        var dataRecord = this.grid.jqxGrid("getrowdata", rowIndex);
        if (dataRecord.status == "In Progress") {
          showmessage([
            "Cannot create an empty move for an In Progress Tractor",
          ]);
        } else {
          var createEmptyMoveModalResult = ko.observable();
          this.createEmptyMoveModalHeader(
            "Create Empty Move - " + dataRecord.tractorId
          );
          this.createEmptyMoveModal({
            tractorExId: dataRecord.tractorId,
            result: createEmptyMoveModalResult,
          });
          createEmptyMoveModalResult.subscribe(function (value) {
            window.open("/NewOrder/" + value + "?dispatch=true");
          });
        }
      } else {
        showmessage("Please select a Tractor");
      }
    }
  };

  addFilters = () => {
    var filter_or_operator = 1;
    var filtercondition = "contains";
    if (this.stateParameter()) {
      var filtergroup = new $.jqx.filter();
      var filter = filtergroup.createfilter(
        "stringfilter",
        this.stateParameter(),
        filtercondition
      );
      filtergroup.addfilter(filter_or_operator, filter);
      $("#jqxTractorGrid").jqxGrid("addfilter", "state", filtergroup);
    }
    if (this.mapStateParameter()) {
      this.grid.jqxGrid("clearfilters");
      var filtergroup22 = new $.jqx.filter();
      var filter22 = filtergroup22.createfilter(
        "stringfilter",
        this.mapStateParameter(),
        filtercondition
      );
      filtergroup22.addfilter(filter_or_operator, filter22);
      $("#jqxTractorGrid").jqxGrid("addfilter", "state", filtergroup22);
      $("#jqxTractorGrid").jqxGrid("applyfilters");
      this.$parent.toggleOrdersFromMap();
    }
    if (this.tractorIdParameter()) {
      var filtergroup2 = new $.jqx.filter();
      var filter2 = filtergroup2.createfilter(
        "stringfilter",
        this.tractorIdParameter(),
        filtercondition
      );
      filtergroup2.addfilter(filter_or_operator, filter2);
      $("#jqxTractorGrid").jqxGrid("addfilter", "tractorId", filtergroup2);
    }
    if (this.tractorIdParameter() || this.stateParameter()) {
      $("#jqxTractorGrid").jqxGrid("applyfilters");
    }
  };

  handleLoadGridState = (data) => {
    if (
      (typeof data === "string" && data.length > 0) ||
      isEmpty(data) == false
    ) {
      var state = typeof data === "string" ? JSON.parse(data) : data;
      const merged = { additionalFilters: {}, ...state };
      const { additionalFilters } = merged;

      if (additionalFilters.defaultGridToggleOptions) {
        this.defaultGridToggleOptions({
          ...additionalFilters.defaultGridToggleOptions,
        });
      }

      ko.utils.arrayForEach(this.tractorTypes(), function (item) {
        if (item.value == "GwTractors") {
          item.checked = additionalFilters.gwTractors || false;
        } else if (item.value == "Carrier") {
          item.checked = additionalFilters.carrierTractors || false;
        }
      });

      this.tractorTypes.valueHasMutated();
      this.tractorGridState = state.grid;
    } else {
      ko.utils.arrayForEach(this.tractorTypes(), function (item) {
        if (item.value == "GwTractors") {
          item.checked = false;
        } else if (item.value == "Carrier") {
          item.checked = false;
        }
      });

      this.tractorTypes.valueHasMutated();
    }

    // include Non-Domiciled Tractors by default only if querystring contains loadBoard=true
    if (this.$parent.isLoadboard && this.tractorGridState == null) {
      ko.utils.arrayForEach(this.tractorTypes(), (item) => {
        if (item.value == "GwTractors") {
          item.checked = true;
          this.tractorTypes.valueHasMutated();
        }
      });
    }

    if (this.gridLoaded && this.gridProcessing === false) {
      this.grid.jqxGrid("updatebounddata", "data");
    }
  };

  // A few features on this page cause multiple refreshes to occur at same time which
  // jqx doesn't like. (i.e. page still loading and user changes the regions ddl option)
  // Here we just capture it, check if already processing data, and try the refresh request again later.
  refreshTractorGrid = () => {
    let refreshTimeout;
    const refresh = (refreshAttempts = 0) => {
      clearTimeout(refreshTimeout);

      if (this.cancelPendingGridRefreshes) {
        this.cancelPendingGridRefreshes = false;
        return false;
      }

      if (refreshAttempts >= 100) {
        // equates to 30 secs with the 300ms timeout.
        console.error(`Exceeded refresh attempts for tractor's grid. Time-out`);
        return false;
      }

      if (this.gridProcessing) {
        refreshTimeout = setTimeout(() => refresh(refreshAttempts + 1), 300);
        return false;
      }

      this.grid.jqxGrid("updatebounddata", "data");
      this.hideDistanceColumn();
      this.gridProcessing = true;
    };

    refresh(0);
  };

  onTractorTypeChecked = () => {
    dataModel.addClientSideLog("Selected: Tractors Filter By Tractor. ");
    this.refreshTractorGrid();
    $("#ddlTractorTypes").jqxDropDownList("close");
  };

  hideDistanceColumn = () => {
    var column = this.grid.jqxGrid("getcolumn", "distance");
    if (column.hidden == false) {
      this.grid.jqxGrid("hidecolumn", "distance");
    }
  };
}

export { OrderPlanningTractorGrid };
export default { viewModel: OrderPlanningTractorGrid, template: template };
