import dataModel from "data-model";
import { datetimeUTC } from "global-functions";

// Models
export const TPIRateRequest = () => {
  return {
    loadId: undefined,
    orderId: undefined,
    originCity: "",
    originState: "",
    originZip: "",
    destinationCity: "",
    destinationState: "",
    destinationZip: "",
    trailerType: "",
    pickupDate: "",
    requestType: "",
    transportationMode: "TL",
    skipStoringResult: false,
    externalBoardNames: [],
  };
};

export const TPIMarketRates = () => {
  return {
    rates: [],
    errors: [],
  };
};

const TPIRate = (initData = {}) => {
  const median = (initData.lowRate + initData.highRate) / 2;

  const getRateTypeLabel = () => {
    if (initData.interfaceCompany === "DAT") {
      if (initData.rateType === "HISTORICAL") {
        return `${initData.rateType}${
          initData.trend && initData.trend !== "CURRENT"
            ? " - " + initData.trend
            : ""
        }`;
      }
      // else {
      //   const fDate = datetimeUTC(initData.forecastDate);
      //   return `${initData.rateType} - ${fDate.format("MM/DD")}`;
      // }
    }

    return `${initData.rateType}`;
  };

  return {
    interfaceCompany: initData.interfaceCompany,
    trailerType: initData.trailerType,
    rateMethod: initData.rateMethod,
    rateType: initData.rateType,
    highRate: initData.highRate,
    medianRate: initData.medianRate ?? median,
    lowRate: initData.lowRate,
    requestType: initData.requestType,
    loadId: initData.loadId,
    orderId: initData.orderId,
    dateRequested: initData.dateRequested,
    trend: initData.trend,
    rateTypeLabel: getRateTypeLabel(),
    forecastDate: initData.forecastDate,
  };
};

// For now keeping http / api methods outside since wrapping these...
const sendTPIRateRequestAsync = (payload) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Rates/GetTPIMarketRates", "POST", payload)
      .then(resolve)
      .catch((err) =>
        reject(
          (err.responseJSON && err.responseJSON.message) ||
            `An error occurred during request.`
        )
      );
  });
};

const fetchSavedTPIRatesForOrderAsync = (orderId = -1) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(`Rates/GetSavedTPIRateForOrder/${orderId}`)
      .then(resolve)
      .catch((err) =>
        reject(
          (err.responseJSON && err.responseJSON.message) ||
            `An error occurred during request.`
        )
      );
  });
};

const fetchSavedTPIRatesForLoadAsync = (loadId = -1) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(`Rates/GetSavedTPIRateForLoad/${loadId}`)
      .then(resolve)
      .catch((err) =>
        reject(
          (err.responseJSON && err.responseJSON.message) ||
            `An error occurred during request.`
        )
      );
  });
};

function RateService() {
  const getTPIRates = async (request = TPIRateRequest()) => {
    const response = TPIMarketRates();

    try {
      const apiResponse = await sendTPIRateRequestAsync(request);

      if (apiResponse.rates) {
        response.rates = apiResponse.rates.map((x) => TPIRate(x));
      }

      response.errors = apiResponse.errors ?? [];
    } catch (err) {
      response.errors.push(err);
    }

    return response;
  };

  const getSavedRatesForOrder = async (orderId = -1) => {
    const response = TPIMarketRates();

    try {
      const apiResponse = await fetchSavedTPIRatesForOrderAsync(orderId);

      if (apiResponse.rates) {
        response.rates = apiResponse.rates.map((x) => TPIRate(x));
      }

      response.errors = apiResponse.errors ?? [];
    } catch (err) {
      response.errors.push(err);
    }

    return response;
  };

  const getSavedRatesForLoad = async (loadId = -1) => {
    const response = TPIMarketRates();

    try {
      const apiResponse = await fetchSavedTPIRatesForLoadAsync(loadId);

      if (apiResponse.rates) {
        response.rates = apiResponse.rates.map((x) => TPIRate(x));
      }

      response.errors = apiResponse.errors ?? [];
    } catch (err) {
      response.errors.push(err);
    }

    return response;
  };

  return {
    getTPIRates,
    getSavedRatesForOrder,
    getSavedRatesForLoad,
  };
}

// Initialize a singleton
const instance = RateService();
export default instance;
