import { createBrowserHistory } from "history";
import dataModel from "data-model";
import crossroads from "crossroads";
import ko from "knockout";
import $ from "jquery";
import userProfile from "user-profile";
import logger from "logger";
import config from 'config';

var routes = [
    { url: "default.aspx", params: { page: "default-page" } },
    { url: "login.aspx", params: { page: "default-page" } },
    { url: "", params: { page: "default-page" } },
    { url: "/", params: { page: "default-page" } },

    { url: "orders", params: { page: "orders-page", title: "GE Orders" } },
    { url: "orders{?query}", params: { page: "orders-page", title: "GE Orders" } },

    { url: "orders/{orderid}", params: { page: "order-entry-page", title: "GE Order Entry" } },
    { url: "neworder{?query}", params: { page: "order-entry-page", title: "GE Order Entry" } },
    { url: "neworder/{orderid}", params: { page: "order-entry-page", title: "GE Order Entry" } },
    { url: "neworder/{orderid}{?query}", params: { page: "order-entry-page", title: "GE Order Entry" } },
    //{ url: "orders{?query}", params: { page: "order-entry-page", title: "GE Order Entry" } },
    { url: "orders/{orderid}", params: { page: "order-entry-page", title: "GE Order Entry" } },
    { url: "orders/{orderid}{?query}", params: { page: "order-entry-page", title: "GE Order Entry" } },
    { url: "neworder", params: { page: "order-entry-page", title: "GE Order Entry" } },
    { url: "ordersgeneral{?query}", params: { page: "order-entry-page", title: "GE Order Entry" } },
    { url: "ordersgeneral/{orderid}", params: { page: "order-entry-page", title: "GE Order Entry" } },
    {
        url: "ordersgeneral/{orderid}{?query}",
        params: { page: "order-entry-page", title: "GE Order Entry" },
    },
    { url: "ordersgeneral", params: { page: "order-entry-page", title: "GE Order Entry" } },

    { url: "tractors", params: { page: "tractors-page", title: "GE Tractors" } },
    { url: "tractors{?query}", params: { page: "tractors-page", title: "GE Tractors" } },
    { url: "myagency", params: { page: "my-agency-page", title: "GE My Agency" } },
    { url: "edi", params: { page: "edi-page", title: "GE EDI Tenders" } },
    { url: "edi{?query}", params: { page: "edi-page", title: "GE EDI Tenders" } },
    { url: "myprofile", params: { page: "my-profile-page", title: "GE My Profile" } },
    { url: "laneanalysis", params: { page: "lane-analysis-page", title: "GE Lane Analysis" } },
    { url: "laneanalysis{?query}", params: { page: "lane-analysis-page", title: "GE Lane Analysis" } },
    { url: "trainingvideos", params: { page: "training-videos-page", title: "GE Training Videos" } },
    { url: "searchorders", params: { page: "search-orders-page", title: "GE Search Orders" } },

    { url: "orderplanning", params: { page: "order-planning-page", title: "GE Order Planning" } },
    { url: "orderplanning{?query}", params: { page: "order-planning-page", title: "GE Order Planning" } },
    { url: "loadboard", params: { page: "loadboard-page", title: "GE Loadboard" } },
    { url: "loadboard{?query}", params: { page: "loadboard-page", title: "GE Loadboard" } },

    { url: "notAuthorized", params: { page: "not-authorized-page", title: "GE Not Authorized" } },
    { url: "reporting", params: { page: "reporting-page", title: "GE Reporting" } },
    { url: "movementreport", params: { page: "movement-report-page", title: "GE Reporting" } },
    { url: "employeecalendar", params: { page: "employee-calendar-page", title: "GE Employee Calendar" } },
    {
        url: "employeeagencylist",
        params: { page: "employee-agency-list-page", title: "GE Employee Agency List" },
    },
    {
        url: "employeeagencylist{?query}",
        params: { page: "employee-agency-list-page", title: "GE Employee Agency List" },
    },

    {
        url: "inspectionlocations{?query}",
        params: { page: "inspection-locations-page", title: "GE Inspection Locations" },
    },
    {
        url: "inspectionlocations",
        params: { page: "inspection-locations-page", title: "GE Inspection Locations" },
    },
    {
        url: "drivercallinrecords",
        params: { page: "driver-call-in-records-page", title: "GE Driver Call-in Records" },
    },
    {
        url: "drivercallinrecords{?query}",
        params: { page: "driver-call-in-records-page", title: "GE Driver Call-in Records" },
    },
    {
        url: "carriercallinrecords",
        params: { page: "carrier-call-in-records-page", title: "GE Carrier Call-in Records" },
    },
    {
        url: "carriercallinrecords{?query}",
        params: { page: "carrier-call-in-records-page", title: "GE Carrier Call-in Records" },
    },
    { url: "imaging", params: { page: "imaging-page", title: "GE Imaging" } },
    { url: "carriers", params: { page: "carriers-page", title: "GE Carriers" } },
    { url: "carriers{?query}", params: { page: "carriers-page", title: "GE Carriers" } },
    { url: "drivers{?query}", params: { page: "drivers-page", title: "GE Drivers" } },
    { url: "drivers", params: { page: "drivers-page", title: "GE Drivers" } },
    {
        url: "accessorialrestriction",
        params: { page: "accessorial-restriction-page", title: "GE Accessorial Restriction" },
    },
    // No longer using anymore, atm. GE-1550
    // { url: "comdatacard", params: { page: "comdata-card-page", title: "GE Comdata Card", redirectTo: '/cardservices' } },
    // { url: "cardservices", params: { page: "comdata-card-page", title: "GE Card Services" } },
    // -- End
    { url: "carrierentry", params: { page: "carrier-entry-page", title: "GE Carrier Entry" } },
    { url: "carrierentry/{carrierid}", params: { page: "carrier-entry-page", title: "GE Carrier Entry" } },
    { url: "usersecurity", params: { page: "user-security-page", title: "GE User Security" } },
    { url: "rolemanagement", params: { page: "role-management-page", title: "GE Role Management" } },
    { url: "gemtenders", params: { page: "gem-tenders-page", title: "GE GEM Tenders" } },
    { url: "customerorders", params: { page: "customer-orders-page", title: "GE Customer Orders" } },
    { url: "loadimport", params: { page: "load-import-page", title: "GE Load Import" } },

    {
        url: "fieldoccurrencerecaps",
        params: { page: "field-occurrence-recaps-page", title: "GE Field Occurrence Recaps" },
    },
    {
        url: "editfieldoccurrencerecaps",
        params: { page: "edit-field-occurrence-page", title: "GE Field Occurrence Recaps" },
    },
    {
        url: "editfieldoccurrencerecaps/{id}",
        params: { page: "edit-field-occurrence-page", title: "GE Field Occurrence Recaps" },
    },
    { url: "routecalculator", params: { page: "route-calculator-page", title: "GE Route Calculator" } },
    { url: "challenge", params: { page: "challenge-page", title: "GE Challenge" } },
    { url: "challengerequest", params: { page: "dataq-challenge-entry-page", title: "GE Challenge Request" } },
    {
        url: "challengerequest/{id}",
        params: { page: "dataq-challenge-entry-page", title: "GE Challenge Request" },
    },
    { url: "trailers", params: { page: "trailers-page", title: "GE Trailers" } },
    { url: "trailers{?query}", params: { page: "trailers-page", title: "GE Trailers" } },
    { url: "settlements", params: { page: "settlements-page", title: "GE Settlements" } },
    { url: "agentsettlements", params: { page: "agent-settlements-page", title: "GE Agent Settlements" } },
    { url: "driversettlements", params: { page: "driver-settlements-page", title: "GE Driver Settlements" } },
    {
        url: "salespersonsettlements",
        params: { page: "salesperson-settlements-page", title: "GE Salesperson Settlements" },
    },
    { url: "auditlog", params: { page: "auditlog-page", title: "GE Audit Log" } },
    { url: "alerts", params: { page: "alerts-page", title: "GE Alerts" } },
    { url: "alertsview", params: { page: "alerts-view-page", title: "GE View Alert" } },
    { url: "alertsview{?query}", params: { page: "alerts-view-page", title: "GE View Alert" } },
    { url: "agencies", params: { page: "agencies-page", title: "GE Agencies" } },
    { url: "agencies{?query}", params: { page: "agencies-page", title: "GE Agencies" } },
    { url: "customercredit/{externalId}", params: { page: "customer-credit-page", title: "GE Customer Credit" } },
    { url: "customercredit{?query}", params: { page: "customer-credit-page", title: "GE Customer Credit" } },
    { url: "customercredit", params: { page: "customer-credit-page", title: "GE Customer Credit" } },
    { url: "locations", params: { page: "locations-page", title: "GE Locations" } },
    { url: "locations{?query}", params: { page: "locations-page", title: "GE Locations" } },
    { url: "violationlookup", params: { page: "violation-lookup-page", title: "GE Violation Lookup" } },
    {
        url: "violationlookup{?query}",
        params: { page: "violation-lookup-page", title: "GE Violation Lookup" },
    },
    { url: "createaccount", params: { page: "create-account-page", title: "GE Create Account" } },
    { url: "csaexceptions", params: { page: "csa-exceptions-page", title: "GE CSA Exceptions" } },
    { url: "roadsideentry", params: { page: "roadside-entry-page", title: "GE Roadside Entry" } },
    { url: "roadsideentry/{id}", params: { page: "roadside-entry-page", title: "GE Roadside Entry" } },
    { url: "roadsideentry{?query}", params: { page: "roadside-entry-page", title: "GE Roadside Entry" } },
    {
        url: "newagentsignonchecksheet",
        params: { page: "newagent-signon-checksheet-page", title: "GE New Agent SignOn Checksheet" },
    },
    { url: "priorityalerts", params: { page: "priority-alerts-page", title: "GE Priority Alerts" } },
    { url: "rates", params: { page: "rates-page", title: "GE Rates" } },
    { url: "inspectionlookup", params: { page: "inspection-lookup-page", title: "GE Inspection Lookup" } },
    { url: "loadalerts", params: { page: "load-alerts-page", title: "GE Load Alerts" } },
    { url: "forgotmypassword", params: { page: "forgot-password", title: "GE Forgot My Password" } },
    { url: "documentation", params: { page: "documentation-page", title: "GE Documentation" } },
    {
        url: "driverportalregister",
        params: { page: "driverportal-register-page", allowGuest: true, title: "GE Driver Portal Register" },// can be viewed without being logged in
    }, 
    // old
    { url: "externalboard{?query}", params: { page: "external-board-page", title: "GE External Board" } },
    { url: "externalboard", params: { page: "external-board-page", title: "GE External Board" } },
    // new
    { url: "externalboard/v2{?query}", params: { page: "external-board-page-v2", title: "GE External Board", matchExactUrl: true } },
    { url: "externalboard/v2", params: { page: "external-board-page-v2", title: "GE External Board", matchExactUrl: true } },
    { url: "loadandtractormap", params: { page: "load-tractor-map-page", title: "GE Load and Tractor Map" } },
    { url: "tractortracking", params: { page: "tractor-tracking-page", title: "GE Tractor Tracking" } },
    { url: "agentdefaultrates", params: { page: "agent-default-rates-page", title: "GE Agent Default Rates "}},
    { url: "owneroperatorrates", params: { page: "owner-operator-rates-page", title: "GE Owner Operator Payee Contracted Rates"}},

    { url: "loadtractorpostings", params: { page: "load-tractor-posting-page", title: "GE Load/Tractor Postings" } },
    { url: "rating", params: { page: "rating-page-component", title: "GE Rating" } },
    { url: "wires", params: { page: "wires-page-component", title: "GE Wires"} },
    { url: "wires{?query}", params: { page: "wires-page-component", title: "GE Wires"} },
    { url: "ordertracking", params: { page: "order-tracking-page", title: "GE Order Tracking" } },
    { url: "analytics", params: { page: "analytics-page", title: "GE Analytics" } },
    { url: "contactsManagement", params: { page: "contact-list-management-page", title: "GE Contacts Management" } },
    { url: "contacts", params: { page: "contact-page", title: "GE Contacts" } },



    { url: ":rest*:", params: { page: "not-found-page" } }, // (Catch All Route) - > Grab non matching requests/routes -> send 404 page (currently does this) or maybe redirect to 'default' ?
];

class Router {
    #mainHash
    #clientFilesNeedRefreshed
    constructor(history, routes, basename) {
        var _this = this;
        this.history = history;
        this.currentRoute = ko.observable({});

        // Reset and configure Crossroads so it matches routes and updates this.currentRoute
        crossroads.removeAllRoutes();
        crossroads.resetState();
        crossroads.normalizeFn = crossroads.NORM_AS_OBJECT;

        //ignoreState allows us to reload the current route when the agencyId is changed.
        crossroads.ignoreState = true;

        routes.forEach((route) => {
            
            crossroads.addRoute(route.url, (requestParams) => {
                requestParams = this.toLowerCaseObject(requestParams);
                requestParams.url = route.url;
                ko.utils.extend(requestParams, route.params);

                if(this.urlRedirect(requestParams) == false) {
                    logger.logUsageTracking(requestParams.request_);

                    userProfile.refreshUserCurrentAgency();

                    _this._guardRoute(requestParams);
    
                    document.title = requestParams.title ? requestParams.title : "Greatwide Truckload Management";
                }
                
            });
        });

        // Add Route Guards & Checks
        _this._guardRoute = (requestParams) => {
            
            let url = requestParams.url.replace(/{([^}]+)}/g, "")
            url = requestParams.matchExactUrl ? url : url.replace("/", "");

            // Check for login redirects urls...
             requestParams.page = requestParams.request_ && requestParams.request_.toUpperCase().indexOf("LOGIN") != -1 ? "default-page" : requestParams.page;

            let allowedRoutes = [...[_this.currentRoute().url, "notAuthorized", ":rest*:", "/", ""], ...userProfile.allowedPages()];
            
            if (allowedRoutes.indexOf(url.toLowerCase()) == -1 && url !== "notAuthorized") {
                logger.logToServer(`User: '${userProfile.userName}' attempting to access an Unauthorized Page. Attempted Page: '${url}'`);
                _this._pushHistory("/NotAuthorized");
            } else {
                _this.currentRoute(requestParams);
            }
        };

        this.urlRedirect = ({redirectTo}) => {
            if(redirectTo) {
                this._pushHistory(redirectTo);
                return true;
            }

            return false;
            
        }

        //This normalizes the parameter object when intercepted above.
        this.toLowerCaseObject = (obj) => {
            return Object.keys(obj).reduce((acc, key) => {
                let val = obj[key];
                if (typeof val === "object") {
                    val = this.toLowerCaseObject(val);
                }
                acc[key.toLowerCase()] = typeof val == "string" ? val.toLowerCase() : val;
                return acc;
            }, {});
        };

        // Make history.js watch for navigation and notify Crossroads
        this.disposeHistory = history.listen(function (location) {
            return crossroads.parse(location.location.pathname + location.location.search);
        });

        //Intercept all clicks and check to see if they're links.  If they're part of this domain, use router to route request to appropriate page.
        this.clickEventListener = (evt) => {
            var target = evt.currentTarget;
            if (target && target.tagName === "A") {
                var href = target.getAttribute("href") || "";

                if(href.indexOf("#") != -1) return false;

                if(!(config.dev == true) && this.#clientFilesNeedRefreshed == true)  {
                    window.location = href;
                }

                var forceBlankPage = target.getAttribute("target") == "_blank"; // some pages actually force opening in new tab/window - keeping default behaviour for now?
                if (
                    href &&
                    this.doesUrlBelongToCurrentDomain(href) == true &&
                    href != "#" &&
                    href[0] != "#" &&
                    this.isUrlFile(href) == false &&
                    forceBlankPage == false &&
                    href.startsWith("blob") == false
                ) {
                    this._pushHistory(href);
                    evt.preventDefault();

                    return false;
                }
            }
        };

        _this._pushHistory = (location) => {
            location = location || window.location.href;
            var hrefAfterBasename = location.substring(basename.length);
            this.clearModals();
            window.scrollTo({top: 0});
            
            //There's a bug in history that doesn't clear the url parameters after push or replace.....
            //https://github.com/ReactTraining/history/issues/808
            //https://github.com/ReactTraining/history/issues/859
            //https://github.com/ReactTraining/history/issues/814

            //current workaround...
            const [pathname, search] = hrefAfterBasename.split("?");
            history.push({ pathname, search: search ? `?${search}` : "" });
            //end workaround... will wait till one of the above github issues are merged in and an update should fix it.
        };

        $(document).on("click", "a", this.clickEventListener);

         //Use this to navigate to a page in javascript/code.  DO NOT USE window.location, it will reload the page, defeating the purpose of a single page app.
         this.navigate = (url, newtab, newWindow) => {
            if(newtab) {
                window.open(url, '_blank');
            } 
            else if(newWindow) {
                window.open(url, '_blank', 'toolbar=1,location=1,menubar=1,status=1');
            }
            else {
                // reset leftover body style fragments added from/for 'specific' pages
                $("#mainContent").css({'max-width': ''})
                $('body').removeAttr('style');
                _this._pushHistory(url);
            }

            this.clearModals();
        };

        // Use-case: if a page has a modal open when navigating to another page the backdrop is remaining, even though the modal closes.
        this.clearModals = () => {
            $(".modal-backdrop").remove();
            $("body").removeClass("modal-open"); // remove as well as it's preventing scrolling
            $('.jqx-loader-modal').hide();
        };
		

        this.doesUrlBelongToCurrentDomain = (url) => {
            const link = document.createElement("a");
            link.href = url;
            return link.href.includes(window.location.origin);
        };

        // There are a few 'a' links to .pdf downloads, resources that open in '_blank' window/tabs -- adding this to check using a 'whitelist'
        this.isUrlFile = (url) => {
            const allowedFileTypes = [".pdf", ".csv"];

            return (
                allowedFileTypes.find(function (x) {
                    return url.indexOf(x) != -1;
                }) != undefined
            );
        };

        // Initialize Crossroads with starting location
        this.allowedPagesSubscription = userProfile.allowedPages.subscribe(() => {
            crossroads.parse(history.location.pathname.replace(".aspx", "") + history.location.search); //Webforms redirect
        });

        Router.prototype.link = function (url) {
            return this.history.createHref({ pathname: url });
        };
        Router.prototype.dispose = function () {
            this.disposeHistory();
            $(document).off("click", "a", this.clickEventListener);
        };

        // Check if user logged out in another tab before each request
        // If so, then display login screen
        $(document).ajaxStart(() => {
            if (userProfile.isSessionValid() == false) {
                $(".jqx-loader-modal").remove();
                userProfile.loggedIn(false);
            }
        });

        $(document).ajaxError((event, jqxhr, settings, thrownError) => {
            if (jqxhr.status == 401) {

                // Some api endpoints return 401 from a few agencies not authorized to the data,
                // in those cases if preventAgencyChangeLogouts then skip clearing user session.
                const preventAgencyChangeLogouts = localStorage.getItem("preventLogoutOnAgencyChange");
                if(!preventAgencyChangeLogouts) {
                    userProfile.loggedIn(false);
                    userProfile.clearAccessToken();
                }
            }
        });

        $(document).ajaxComplete((event, xhr, settings) => {
            this.#clientFilesNeedRefreshed = xhr.getResponseHeader('Main-Client-Hash') != this.#mainHash
        })

        //Getting the hash of the main chunk to use it in determining 
        //when js chunks need to be refreshed after a deploy.
        let scripts = document.getElementsByTagName('script');
        let lastScript = scripts[scripts.length-1];
        let scriptName = lastScript.src;
        let scriptArray = scriptName.split('.')
        this.#mainHash = scriptArray[scriptArray.length - 2]
    }

    allowedPagesSubscription;
    refreshUserMenu = () => {
        this.allowedPagesSubscription.dispose();
        return new Promise((resolve, reject) => {
            userProfile.getCurrentUserMenus().then(() => {
                this.allowedPagesSubscription = userProfile.allowedPages.subscribe(() => {
                    crossroads.parse(history.location.pathname.replace(".aspx", "") + history.location.search); //Webforms redirect
                });
                resolve();
            });
        });
    };
}
let router = new Router(createBrowserHistory(), routes, "");
export default router;
