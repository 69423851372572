import dataModel from 'data-model';
import ko from 'knockout';
import template from './order-entry-order-gem-upload-component.html';

class GemUploadViewModel {
    constructor(id) {
        this.showLoadingWheel = ko.observable(false);
        this.id = id;
        this.images = ko.observableArray([]);
        this.errors = ko.observableArray();
        this.getImageTypes();

        this.showNoResults = ko.pureComputed(() => !this.images() || this.images().length === 0)
    }



    downloadImage = (image) => {
        var imgReq = {
            orderExId: this.id, 
            fileName: image.filename
        };
        this.showLoadingWheel(true);
    
        
        dataModel.downloadFile("GemUpload/GetGemUploadDownload", "get", imgReq, this.id + "-" + image.filename)
        .then(() => {
            this.showLoadingWheel(false);
        })
        .catch(() => {
            this.showLoadingWheel(false);
            this.errors.push("GreatEDGE cannot currently communicate with the Imaging server");
        })
    }

    getImageTypes = () => {
        this.showLoadingWheel(true);
        dataModel.ajaxRequest("GemUpload/GetGemUploads", "get", { orderExId: this.id })
        .done((data) => {
            this.showLoadingWheel(false);
    
            
            const mappedImages = data.map(item => ({
                filename: item.filename,
                imageTypeName: item.imageTypeName,
                confirmationNumber: item.confirmationNumber,
                uploadDate: new Date(item.uploadDate).toLocaleString(), 
            }));
            this.images(mappedImages);
            console.log(this.images());
        })
        .fail((jqXHR) => {
            this.showLoadingWheel(false);
            if (jqXHR.status == 400) {
                var response = jqXHR.responseJSON;
                this.errors.push(response.errors);
            }
            else {
                this.errors.push("GreatEDGE cannot currently communicate with the Imaging server");
            }
        });
    }
}


export default {
    viewModel: {
        createViewModel: function(params, componentInfo) {
           
        }
    }, template: template
}

export { GemUploadViewModel }
