import dataModel from 'data-model';
import gridUtils from "jqx.grid-utils";
import {isEmpty} from 'global-functions';

// Action types
const GET_USER_FILTERS = "userFilters/get_user_filters";
const ADD_USER_FILTERS = "userFilters/add_user_filters";

export const getUserFilters = (gridName, pageName) => async(dispatch, state) => {
    let gridState = state.userFilters[gridName];

    if(gridState) return { type: GET_USER_FILTERS, payload: {[gridName]: gridState} }
    
    try {
        let filters = await gridUtils.getUserGridFiltersAsync(gridName, pageName);
        if (isEmpty(filters) == false) {
            gridState = JSON.parse(filters);
        }
    }
    catch(err) {
        console.error(`Error occurred while fetching user saved filters.`);
    }

    return { type: GET_USER_FILTERS, payload: { [gridName]: gridState} }
}

export const addUserFilters = (gridName, gridState = {}) => async(dispatch, state) => {
    return { type: ADD_USER_FILTERS, payload: { [gridName]: gridState} }
}

export default function userFilters(filters = {}, action) {
    switch(action.type) {
        case ADD_USER_FILTERS:
            return {...filters, ...action.payload };
        case GET_USER_FILTERS:
            return {...filters, ...action.payload };
        default:
            return filters;
    }
}