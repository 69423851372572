import dataModel from "data-model";
import userProfile from "user-profile";
import { isEmpty } from "global-functions";
import gridState from "jqx.grid-state-component";
import gridStateUtils from "jqx.grid-utils";

class PendingPartnerRequestsViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.myPendingRequestsGridState = null;
        this.grid = $("#myPendingRequestsGrid");

        this.numberOfPendingRequests = ko.observable(0);
        this.oneRequest = ko.pureComputed(() => {
            return !this.numberOfPendingRequests() == 1;
        });

        this.initializeComponent();
    }

    async initializeComponent() {
        await this.setUserFilters(); // And load this, but wait here until finished, then
        this.loadGrid(); // load this
    }

    cellsrenderer = (
        row,
        columnfield,
        value,
        defaultHTML,
        columnproperties
    ) => {
        if (value.length > 1) {
            const cell = $(defaultHTML);
            const sel = $("<select>");
            sel.css({ width: 120 });
            sel.append(
                $("<option></option>").attr("value", 0).text("Multiple")
            );

            $.each(value, function (index, value) {
                sel.append(
                    $("<option></option>")
                        .attr("disabled", "disabled")
                        .attr("value", index + 1)
                        .text(value.value)
                );
            });

            cell.html(sel);
            cell.addClass("jqxGridCustomCell");
            return cell[0].outerHTML;
        }
    };

    refreshGrid = () => {
        this.grid.jqxGrid("updatebounddata", "data");
        this.$emitsOnAction;
    };

    async setUserFilters() {
        let filters = await gridStateUtils
            .getUserGridFiltersAsync("myPendingRequestsGrid")
            .catch((err) => console.error("Failed to retrieve user filters."));
        if (isEmpty(filters) == false) {
            let state = JSON.parse(filters);
            this.myPendingRequestsGridState = state.grid;
        }
    }

    get gridSource() {
        const vm = this;
        return {
            url: "MyAgency/GetMyPendingRequests",
            datatype: "json",
            formatdata(data) {
                data = data || {};
                data.agencyId = userProfile.currentAgencyId();

                return data;
            },

            datafields: [
                { name: "agencyId", type: "int" },
                { name: "agencyName", type: "string" },
                { name: "agencyExternalId", type: "string" },
                { name: "securable", type: "string" },
            ],
            loadComplete: (data) => {
                vm.numberOfPendingRequests(data.agencyCount || "0");
            },
        };
    }

    loadGrid = () => {
        const vm = this;

        if (this.grid.length == 0) this.grid = $("#myPendingRequestsGrid");

        var dataAdapter = dataModel.getDataAdapter(this.gridSource);

        this.grid.jqxGrid({
            theme: "GWTMDark",
            width: "100%",
            height: "270px",
            source: dataAdapter,
            altrows: true,
            showstatusbar: true,
            sortable: true,
            pageable: true,
            filterable: true,
            showfilterrow: true,
            virtualmode: false,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            statusbarheight: 35,
            showtoolbar: true,
            rendertoolbar: (toolbar) => {
                let vm1 = new gridState.viewModel();

                vm1.actions = [
                    "Clear Filters",
                    "Clear Selection",
                    "Refresh",
                    "Set Default Search",
                ];
                vm1.initializeDefaultToolbar(toolbar);

                vm1.notifyLocation = this.grid
                    .closest(".modal-content")
                    .find(".modal-header");
            },

            rendergridrows(obj) {
                return obj.data;
            },

            columns: [
                { text: "Agency Id", datafield: "agencyId", hidden: true },
                {
                    text: "Agency Code",
                    datafield: "agencyExternalId",
                    hidden: false,
                },
                { text: "Agency Name", datafield: "agencyName" },
                {
                    text: "Type",
                    datafield: "securable",
                    cellsrenderer: vm.cellsrenderer,
                },

                {
                    text: "",
                    width: 60,
                    filterable: false,
                    sortable: false,
                    columnType: "button",
                    buttonclick(row) {
                        var datarow = vm.grid.jqxGrid("getrowdata", row);
                        vm.handleDeletePendingRequest(datarow);
                    },
                    cellsrenderer() {
                        return "Cancel";
                    },
                },
            ],

            ready: () => {
                gridStateUtils.applyGridState(
                    "myPendingRequestsGrid",
                    vm.myPendingRequestsGridState
                );
            },
        });
    };

    handleDeletePendingRequest = (request) => {
        this.doHandleDeletePendingRequest(request);
    };

    async doHandleDeletePendingRequest(request) {
        request.securable = request.securable || [];
        const pendingIds = [];

        $.each(request.securable, function (index, value) {
            if (pendingIds.indexOf(value.key) == -1) {
                pendingIds.push(value.key);
            }
        });

        const payload = {
            securable: pendingIds,
            cancelAgencyId: request.agencyId,
            agencyId: userProfile.currentAgencyId(),
        };

        await this.http_sendDeletePendingRequestAsync(payload).catch((err) =>
            console.error("Error occurred while deleting pending request.")
        );

        this.numberOfPendingRequests(this.numberOfPendingRequests() - 1);
        this.refreshGrid();
    }

    http_sendDeletePendingRequestAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/CancelPendingRequest", "put", payload)
                .done((data) => resolve(data))
                .fail((err) => reject(err));
        });
    };
}

import template from "./pending-partner-requests-component.html";
export default {
    viewModel: PendingPartnerRequestsViewModel,
    template: template,
};
