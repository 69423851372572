import ko from 'knockout';
import dataModel from 'data-model';
import * as _ from 'lodash';
import {isEmpty} from 'global-functions'
import gridStateUtils from 'jqx.grid-utils';
import gridState from 'jqx.grid-state-component';
import userProfile from 'user-profile';
import template from './accessorial-restriction-page.html';



var chargeCodeDetailsViewModel = function (model, chargeCodeId, chargeCodeExternalId, chargeCodeDescription) {
    var self = this;
    self.canBulkAssign = ko.observable(model.canBulkAssign);
    self.canSingleAssign = ko.observable(model.canSingleAssign);
    self.chargeCodeId = ko.observable(chargeCodeId);
    self.chargeCodeExternalId = ko.observable(chargeCodeExternalId);
    self.chargeCodeDescription = ko.observable(chargeCodeDescription);

    self.leftSource = model.availableAgents;
    self.rightSource = model.assignedAgents;
    self.columns = [{ text: "id", datafield: "id", hidden: true, columnType: 'number', searchoptions: { sopt: ['eq', 'ne', 'cn'] } }, { text: "Code", datafield: "externalId", columnType: 'textbox', searchoptions: { sopt: ['eq', 'ne', 'cn'] } }, { text: "Name", datafield: "name", columnType: 'textbox', searchoptions: { sopt: ['eq', 'ne', 'cn'] } }];
    self.fields = [{ name: "id", type: 'string' }, { name: "externalId", type: 'string' }, { name: "name", type: 'string' }];
    self.refreshGrid = ko.observable(true);
    self.columnTransfer = function () {
        var availableIds = ko.utils.arrayMap(self.leftSource, function (item) {
            return item.id;
        });

        var assignedIds = ko.utils.arrayMap(self.rightSource, function (item) {
            return item.id;
        });

        var data = {
            selectedChargeCodeId: self.chargeCodeId(),
            available: availableIds,
            assigned: assignedIds
        };
        dataModel.ajaxRequest("AccessorialRestriction/TransferAgentChargeCodes/", 'post', data)
        .done(function (response, status, xhr) {
            var g = "blah";
        })
            .fail(function (error, msg, d) {
                var g = "blah";
            });
    };
}

var agencyDetailsViewModel = function (model, agencyId, agencyExternalId, agencyName) {
    var self = this;
    self.canBulkAssign = ko.observable(model.canBulkAssign);
    self.canSingleAssign = ko.observable(model.canSingleAssign);
    self.agencyId = ko.observable(agencyId);
    self.agencyExternalId = ko.observable(agencyExternalId);
    self.agencyName = ko.observable(agencyName);

    self.leftSource = model.availableChargeCodes;
    self.rightSource = model.assignedChargeCodes;
    self.columns = [{ text: "id", datafield: "id", hidden: true, columnType: 'number', searchoptions: { sopt: ['eq', 'ne', 'cn'] } }, { text: "Code", datafield: "externalId", columnType: 'textbox', searchoptions: { sopt: ['eq', 'ne', 'cn'] } }, { text: "Name", datafield: "name", columnType: 'textbox', searchoptions: { sopt: ['eq', 'ne', 'cn'] } }];
    self.fields = [{ name: "id", type: 'string' }, { name: "externalId", type: 'string' }, { name: "name", type: 'string' }];
    self.refreshGrid = ko.observable(true);
    self.columnTransfer = function () {
        var availableIds = ko.utils.arrayMap(self.leftSource, function (item) {
            return item.id;
        });

        var assignedIds = ko.utils.arrayMap(self.rightSource, function (item) {
            return item.id;
        });

        var data = {
            selectedAgencyId: self.agencyId(),
            available: availableIds,
            assigned: assignedIds
        };
        dataModel.ajaxRequest("AccessorialRestriction/TransferAgentChargeCodes/", 'post', data)
        .done(function (response, status, xhr) {
            var g = "blah";
        })
            .fail(function (error, msg, d) {
                var g = "blah";
            });
    };
}

var loadChargeCodes = function (chargeCodeDetailsWindow, userSavedFilters) {
    var self = {};  //These 2 functions aren't objects so there's no 'this'.
    self.chargeCodeDetailsWindow = chargeCodeDetailsWindow;
    var updateGrid = false;
    var ratingUpdated = false;
    self.chargeCodeGridState = userSavedFilters || {};

    var source = {
        url: "AccessorialRestriction/GetChargeCodesForGrid",
        datatype: "json",
        sortcolumn: "chargeCode",
        sortdirection: "asc",
        data: {
            agencyId: userProfile.currentAgencyId()
        },
        filter: function (items) {
            // update the grid and send a request to the server.
            $("#jqxChargeCodes").jqxGrid('updatebounddata', 'filter');
        },
        sort: function (column, sortOrder) {
            $("#jqxChargeCodes").jqxGrid('updatebounddata', 'sort');
        },
        beforeprocessing: function (data) {
            source.totalrecords = data.totalrecords;
            // Convert our codes into friendly display names
            setWhoToPayDisplayName(data);
           
            data.records = (data.records || []).map(x => {
                x.embed = x.embed == "Y" ? true : false;
                return x;
            })
        },
        formatdata: function (data) {
            var columns = new Array();

            data = {
                chargeCode: data.chargeCode,
                description: data.description,
                agencyPayPercent: data.agencyPayPercent,
                ooPayPercent: data.ooPayPercent,
                ediChargeCode: data.ediChargeCode,
                embed: data.embed,
                whoToPay: data.whoToPay,
                agencyId: userProfile.currentAgencyId(),
                recordstartindex: data.recordstartindex,
                recordendindex: data.recordendindex,
                pagesize: data.pagesize,
                pagenum: data.pagenum,
                sortdatafield: data.sortdatafield,
                sortorder: data.sortorder,
                columns: columns,
            };

            var filterinfo = $("#jqxChargeCodes").jqxGrid('getfilterinformation');
            for (let i = 0; i < filterinfo.length; i++) {
                var filterName = filterinfo[i].datafield;

                var filters = filterinfo[i].filter.getfilters();

                for (var j = 0; j < filters.length; j++) {
                    if (filters.length > 1) {
                        data[filterName][j] = filters[j].id ? filters[j].id : filters[j].value;
                    }
                    else {
                        data[filterName] = filters[j].id ? filters[j].id : filters[j].value;
                    }
                }
            }

            Object.keys(data).forEach((key) => {
                if(data[key] === "Please Choose:") data[key] = undefined;
            })

            data.embed = data.embed == null ? null : (data.embed ? "Y" : "N");

            return data;
        },
        datafields: [
            { name: 'id', type: 'int' },
            { name: 'chargeCode', type: 'string' },
            { name: 'description', type: 'string' },
            { name: 'agencyPayPercent', type: 'float' },
            { name: 'ooPayPercent', type: 'float' },
            { name: 'ediChargeCode', type: 'string' },
            { name: 'agentPayMethod', type: 'string' },
            { name: 'ownerOperatorPayMethod', type: 'string' },
            { name: 'whoToPay', type: 'string' },
            { name: 'embed', type: 'boolean' },
            { name: 'rating', type: 'int'}
        ]
    };

    var dataAdapter = dataModel.getDataAdapter(source);

    $("#jqxChargeCodes").jqxGrid({
        width: '100%',
        source: dataAdapter,
        selectionmode: 'single',
        sortable: true,
        pageable: true,
        filterable: true,
        showfilterrow: true,
        virtualmode: true,
        autoheight: true,
        columnsresize: true,
        columnsreorder: true,
        showtoolbar: true,
        rendertoolbar: (toolbar) => {
            let vm1 = new gridState.viewModel();
            vm1.initializeDefaultToolbar(toolbar);

        },
        rendergridrows: function (obj) {
            return obj.data;
        },
        ready: function () {
            this.detailsVisibility = new Array();
            gridStateUtils.applyGridState("jqxChargeCodes", self.chargeCodeGridState);
        },
        columns: [
            {
                text: '', width: 100, columnType: "button", sortable: false, pinned: true, filterable: false, buttonclick: function (row) {
                    var datarow = $("#jqxChargeCodes").jqxGrid('getrowdata', row);
                    dataModel.ajaxRequest("AccessorialRestriction/GetChargeCodeDetails/" + datarow.id, "GET")
                    .done(function (data, status, xhr) {
                        self.chargeCodeDetailsWindow(new chargeCodeDetailsViewModel(data, datarow.id, datarow.chargeCode, datarow.description));
                    })
                    .fail(function (error, msg, d) {
                    });
                },
                cellsrenderer: function () {
                    return "View Details";
                }
            },
            { text: 'Charge Code', dataField: 'chargeCode', width: 120 },
            { text: 'Description', dataField: 'description', width: 200 },
            { text: 'Agency Pay Percent', dataField: 'agencyPayPercent', width: 180 },
            { text: 'O/O Pay Percent', dataField: 'ooPayPercent', width: 120 },
            { text: 'EDI Charge Code', dataField: 'ediChargeCode', width: 150 },
            {
                text: 'Agent Pay Method', dataField: 'agentPayMethod', width: 150,
                filtertype: 'list', filteritems: [{ value: "P", label: "Percentage" }, { value: "F", label: "Flat" }, { value: "R", label: "Pro-Rate" }]
            },
            {
                text: 'Owner Operator Pay Method', dataField: 'ownerOperatorPayMethod', width: 150,
                filtertype: 'list', filteritems: [{ value: "P", label: "Percentage" }, { value: "F", label: "Flat" }, { value: "A", label: "All" }]
            },
            {
                text: 'Who to Pay', dataField: 'whoToPay', width: 150,
                filtertype: 'list', filteritems: [{ value: "R", label: "Pro-rate" }, { value: "N", label: "No Driver" }, { value: "P", label: "Pickup Driver" }, { value: "D", label: "Delivery  Driver" }, { value: "O", label: "Other  Driver" }]
            },
            { text: 'Embed', dataField: 'embed', width: 70, filtertype: 'bool', columntype: 'checkbox', editable: false },
            { text: 'Rating', dataField: 'rating', width: 140, filtertype: "list",
            filteritems: [
                { value: 5, label: "5", html: "<img src='../Content/Images/5star.png' />" },
                { value: 4, label: "4", html: "<img src='../Content/Images/4star.png' />" },
                { value: 3, label: "3", html: "<img src='../Content/Images/3star.png' />" },
                { value: 2, label: "2", html: "<img src='../Content/Images/2star.png' />" },
                { value: 1, label: "1", html: "<img src='../Content/Images/1star.png' />" }
            ], cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                if (defaultHTML != null) {
                    let pk = $("#jqxChargeCodes").jqxGrid('getcellvalue', row, "id");
                    var fieldset = $("<fieldset>");
                    fieldset.addClass("star-rating");
                    fieldset.attr('id', 'jqxRating_' + pk);

                    for (var i = 5; i > 0; i--) {
                        var id = 'jqxRating_' + pk + i;
                        var input = $("<input>");
                        input.attr({
                            'type': 'radio',
                            'id': 'star' + i,
                            'name': 'rating',
                            'value': i,
                        });
                        input.appendTo(fieldset);
                        var label = $("<label>").text(i);
                        if (value == i) {
                            label.attr({ for: 'star' + i })
                        }
                        label.insertAfter(input);
                        //set the div as click target
                        $(document).off('click', '#jqxRating_' + pk).on('click', '#jqxRating_' + pk, function (event) {
                            var newRating;
                            var previousRating;
                            //added tagName condition because click event firing for both the label and input of each star element
                            //iterate through each of the 5 star-ratings for this target div
                            if (event.target.tagName == "LABEL") {
                                //get the value of the star-rating the user selected (1, 2, 3, 4, 5)
                                newRating = event.target.previousSibling.value;
                                $(event.target.parentElement.children).each(function (i) {
                                    if (this.tagName == "LABEL") {
                                        //if the label has a 'for' attribute, this will be the previous rating
                                        if (this.attributes.length > 0) {
                                            if (this.attributes.for.value != null) {
                                                previousRating = this.attributes.for.value.slice(-1);
                                            }
                                            //if the user is selecting a new rating, remove the current 'for' attribute
                                            //this is how the css determines how many stars to highlight
                                            //attribute 'for: star4' will show four stars highlighted, etc.
                                            if (previousRating != newRating) {
                                                $(this).removeAttr("for");
                                            }
                                        }
                                    }
                                });
                                if (previousRating != newRating) {
                                    //get the Id of the input for the label selected by the user (star1, star2, star3, star4, star5)
                                    var inputId = event.target.previousSibling.attributes.getNamedItem('id').value;
                                    //set the 'for' attribute for the label the user selected to the Id above.  
                                    //if the user selected a 'star4', then set the corresponding label to 'for: star4'
                                    //this impacts the css applied
                                    $(event.target).attr('for', inputId);
                                    //get the id for the selected carrier by getting the parent id of the selected star input
                                    var id = event.target.parentElement.id.replace('jqxRating_', '');
                                    var params = { objectId: id, value: newRating, agencyId: userProfile.currentAgencyId(), favTable: 5 };
                                    dataModel.ajaxRequest("Rating", "post", params)
                                    .done(function (data, textStatus, jqXHR) {
                                        updateGrid = true;
                                        ratingUpdated = true;
                                        $("#jqxChargeCodes").jqxGrid("updatebounddata", "data");
                                    })
                                    .fail(function (error, msg, d) {
                                    });
                                }
                            }
                        });
                    }
                    return fieldset[0].outerHTML;
                }
                return defaultHTML;
            },
            createfilterwidget: function (column, columnElement, widget) {
                widget.jqxDropDownList({
                    dropDownWidth: 140,
                    renderer: function (index, label, value) {
                        if (value != "") {
                            return "<img src='../Content/Images/" + value + "star.png' />";
                        }
                        else {
                            return "Any";
                        }
                    }
                });
            }
        },
        ]
    });


    function setWhoToPayDisplayName(data) {
        if (data == null || data.records == null || data.records.length == 0) return false;

        var _whoToPayDisplayName = "";
        for (var i = 0; i < data.records.length; i++) {

            if (data.records[i].whoToPay == null) continue;

            switch (data.records[i].whoToPay) {
                case "R":
                    _whoToPayDisplayName = "Pro-rate";
                    break;
                case "N":
                    _whoToPayDisplayName = "No Driver";
                    break;
                case "P":
                    _whoToPayDisplayName = "Pickup Driver";
                    break;
                case "D":
                    _whoToPayDisplayName = "Delivery Driver";
                    break;
                case "O":
                    _whoToPayDisplayName = "Other Driver";
                    break;
                default:
                    _whoToPayDisplayName = "";
                    break;
            }

            data.records[i].whoToPay = _whoToPayDisplayName;
        }
    }

    var _payMethodCodes = [
        { key: "P", value: "Percent" },
        { key: "F", value: "Flat" },
        { key: "R", value: "Pro-rate" },
    ];

    function setPayMethodDisplayName(data) {
        if (data == null || data.records == null || data.records.length == 0) return false;

        for (var i = 0; i < data.records.length; i++) {
            var _agentPayMethodKeyValuePair = _payMethodCodes['key'][data.records[i].agentPayMethod];
            var _ooPayMethodKeyValuePair = _payMethodCodes['key'][data.records[i].ownerOperatorPayMethod];

            data.records[i].agentPayMethod = _agentPayMethodKeyValuePair ? _agentPayMethodKeyValuePair.value : "";
            data.records[i].ownerOperatorPayMethod = _ooPayMethodKeyValuePair ? _ooPayMethodKeyValuePair.value : "";
        }
    }
};

var loadAgencies = function (agencyDetailsWindow, userSavedFilters) {
    var self = {};
    self.agencyDetailsWindow = agencyDetailsWindow;
    self.agencyGridState = userSavedFilters || {};

    var source = {
        url: "AccessorialRestriction/GetAgenciesForGrid",
        datatype: "json",
        sortcolumn: "externalId",
        sortdirection: "asc",
        data: {
            agencyId: userProfile.currentAgencyId()
        },
        filter: function (items) {
            // update the grid and send a request to the server.
            $("#jqxAgencies").jqxGrid('updatebounddata', 'filter');
        },
        sort: function (column, sortOrder) {
            $("#jqxAgencies").jqxGrid('updatebounddata', 'sort');
        },
        beforeprocessing: function (data) {
            source.totalrecords = data.totalrecords;
        },

        formatdata: function (data) {
            var columns = new Array();

            data = {
                externalId: data.externalId,
                name: data.name,
                agencyPercent: data.agencyPercent,
                agencyId: userProfile.currentAgencyId(),
                recordstartindex: data.recordstartindex,
                recordendindex: data.recordendindex,
                pagesize: data.pagesize,
                pagenum: data.pagenum,
                sortdatafield: data.sortdatafield,
                sortorder: data.sortorder,
                columns: columns,
                active: true
            };

            var filterinfo = $("#jqxAgencies").jqxGrid('getfilterinformation');
            for (let i = 0; i < filterinfo.length; i++) {
                var filterName = filterinfo[i].datafield;

                var filters = filterinfo[i].filter.getfilters();

                for (var j = 0; j < filters.length; j++) {
                    if (filters.length > 1) {
                        data[filterName][j] = filters[j].value;
                    }
                    else {
                        data[filterName] = filters[j].value;
                    }
                }
            }
            return data;
        },
        datafields: [
            { name: 'id', type: 'int' },
            { name: 'externalId', type: 'string' },
            { name: 'name', type: 'string' },
            { name: 'agencyPercent', type: 'float' }
        ]
    };

    var dataAdapter = dataModel.getDataAdapter(source);

    $("#jqxAgencies").jqxGrid(
    {
        width: '100%',
        source: dataAdapter,
        selectionmode: 'single',
        sortable: true,
        pageable: true,
        virtualmode: true,
        filterable: true,
        showfilterrow: true,
        autoheight: true,
        columnsresize: true,
        columnsreorder: true,
        showtoolbar: true,
        rendertoolbar: (toolbar) => {
            let vm1 = new gridState.viewModel();
            vm1.initializeDefaultToolbar(toolbar);

        },
        rendergridrows: function (obj) {
            return obj.data;
        },
        ready: function () {
            this.detailsVisibility = new Array();
            gridStateUtils.applyGridState("jqxAgencies", self.agencyGridState);
        },
        columns: [
            {
                text: '', width: 100, columnType: "button", sortable: false, pinned: true, filterable: false, buttonclick: function (row) {
                    var datarow = $("#jqxAgencies").jqxGrid('getrowdata', row);
                    dataModel.ajaxRequest("AccessorialRestriction/GetAgencyDetails/" + datarow.id, "GET")
                    .done(function (data, status, xhr) {
                        self.agencyDetailsWindow(new agencyDetailsViewModel(data, datarow.id, datarow.externalId, datarow.name));
                    })
                    .fail(function (error, msg, d) {
                    });
                },
                cellsrenderer: function () {
                    return "View Details";
                }
            },
            { text: 'Agency Code', dataField: 'externalId', width: 120 },
            { text: 'Agency Name', dataField: 'name', width: 200 },
            { text: 'Agency Percent', dataField: 'agencyPercent', width: 180 }
        ]
    });
}

var viewModel = function (params) {
    var self = this;
    self.chargeCodeDetailsWindow = ko.observable();
    self.agencyDetailsWindow = ko.observable();


    self.loadUserSavedFilters = async(gridName) => {
        let state = {};

        let filters = await gridStateUtils
            .getUserGridFiltersAsync(gridName)
            .catch((err) => console.error("Failed to retrieve jqxChargeCodes user filters."));

        if (isEmpty(filters) == false) {
            let gs = JSON.parse(filters);
            state = gs.grid;
        }

        return Promise.resolve(state);
    }
    

    self.loadUserSavedFilters("jqxChargeCodes").then((userSavedFilters) => loadChargeCodes(self.chargeCodeDetailsWindow, userSavedFilters));
    self.loadUserSavedFilters("jqxAgencies").then((userSavedFilters) => loadAgencies(self.agencyDetailsWindow, userSavedFilters));
};

export default {viewModel: viewModel, template: template}