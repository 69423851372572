import template from './show-confirm-dialog-component.html';

class ShowConfirmDialogComponent {
    constructor(message) {
        this.message = ko.observable(message);
        this.result = new Promise((resolve, reject) => {
            this.yes = () => {
                $('#showconfirmmessagedialogmodal').modal('hide');
                resolve(true)
            }
            this.no = () => {
                $('#showconfirmmessagedialogmodal').modal('hide');
                resolve(false);
            }
        })
    }
}

export default {viewModel: ShowConfirmDialogComponent, template: template}
