import dataModel from "data-model";
import userprofile from "user-profile";
import { useDispatch } from "ko-data-store";
import {
  isLoading,
  openCarrierFraudContactModal,
} from "../../../dataStore/actions/appUI";

const fetchContactsAsync = async () => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("CarrierContact/GetContactFraudList", "GET", null, true)
      .done(resolve)
      .fail((err) =>
        reject(
          (err.responseJSON && err.responseJSON.message) ||
            `An error occurred during request`
        )
      );
  });
};

const searchContactsAsync = async (email) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        "CarrierContact/SearchCarrierFraudContact",
        "GET",
        { email },
        true
      )
      .done(resolve)
      .fail((err) =>
        reject(
          (err.responseJSON && err.responseJSON.message) ||
            `An error occurred during request`
        )
      );
  });
};

const deleteContactAsync = async (id) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        `CarrierContact/DeleteCarrierFraudContact/${id}`,
        "DELETE",
        null,
        true
      )
      .done(resolve)
      .fail((err) =>
        reject(
          (err.responseJSON && err.responseJSON.message) ||
            `An error occurred during request`
        )
      );
  });
};

const addContactAsync = async (email) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        `CarrierContact/AddCarrierFraudContact`,
        "POST",
        { email },
        true
      )
      .done(resolve)
      .fail((err) =>
        reject(
          (err.responseJSON && err.responseJSON.message) ||
            `An error occurred during request`
        )
      );
  });
};

const CarrierFraudEmailModel = ({
  id = undefined,
  email = undefined,
  blackListed = undefined,
  whiteListed = undefined,
} = {}) => {
  return {
    id,
    email,
    blackListed,
    whiteListed,
  };
};

function CarrierFraudContactsViewModel() {
  const vm = this;
  const dispatch = useDispatch();

  vm.canEdit = ko.observable(false);
  vm.errorMsg = ko.observable();
  vm.showAddEmail = ko.observable(false);
  vm.emailToAdd = ko.observable("").extend({ required: true, email: true });
  vm.openModal = ko.observable(false);
  vm.contactList = ko.observableArray([]);
  vm.searchEmail = ko.observable("");

  vm.isNewEmailValid = ko.pureComputed(() => {
    const errors = ko.validation.group(vm);
    return errors().length === 0;
  });

  vm.handleClearSearch = () => {
    vm.searchEmail("");
    loadRecords();
  };

  vm.handleSearch = () => loadRecords(vm.searchEmail());

  vm.confirmDeleteEmail = async ($data) => {
    const yes = await showconfirm(`Are you sure you want to delete?`);
    if (yes) {
      await deleteEmailAsync($data.id);
    }
  };

  const deleteEmailAsync = async (id) => {
    try {
      vm.errorMsg("");
      dispatch(isLoading(true));
      await deleteContactAsync(id);
      dispatch(isLoading(false));
      toastNotify.open({
        type: "info",
        message: "Email removed successfully.",
      });
      vm.contactList(vm.contactList().filter((x) => x.id !== id));
    } catch (err) {
      dispatch(isLoading(false));
      console.error(err);
    }
  };

  vm.handleModalClose = () => {
    vm.openModal(false);
    dispatch(openCarrierFraudContactModal(false));
  };

  vm.handleAddEmail = async () => {
    try {
      vm.errorMsg("");
      dispatch(isLoading(true));
      await addContactAsync(vm.emailToAdd());
      dispatch(isLoading(false));
      vm.emailToAdd("");
      toastNotify.open({
        type: "info",
        message: "Email added successfully.",
      });

      vm.showAddEmail(false);
      loadRecords();
    } catch (err) {
      dispatch(isLoading(false));
      console.error(err);
      vm.errorMsg(err);
    }
  };

  const loadRecords = async (searchByEmail = "") => {
    dispatch(isLoading(true));
    const records = searchByEmail
      ? await searchContactsAsync(searchByEmail)
      : await fetchContactsAsync();
    dispatch(isLoading(false));

    vm.contactList(records.map((x) => CarrierFraudEmailModel({ ...x })));
  };

  const init = async () => {
    try {
      const roles = await userprofile.roles();

      if (
        roles.some(
          (x) =>
            x.toUpperCase() === "ADMINISTRATORS" ||
            x.toUpperCase() === "CARRIERADMINISTRATOR"
        )
      ) {
        vm.canEdit(true);
      }

      await loadRecords();

      vm.openModal(true);
    } catch (err) {
      dispatch(isLoading(false));
      console.error(err);
    }
  };

  init();
}

import template from "./carrier-fraud-contacts-component.html";
import { showconfirm } from "show-dialog-methods";
import toastNotify from "geNotyf";
export default { viewModel: CarrierFraudContactsViewModel, template };
