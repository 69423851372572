import userProfile from 'user-profile';
import template from './header-action-buttons-component.html'
import {showconfirm, showmessage} from 'show-dialog-methods';
import {copyToClipboard} from 'global-functions';
import router from 'router';
import dataModel from 'data-model';
import ko from 'knockout'
import OrderEntryTracking from '../../order-entry-page/order-entry-tracking-component/order-entry-tracking-component';
import { OrderPlanningViewModel } from '../order-planning-page';
import toast from 'geNotyf';
import broadcastAPI from '../../../../utils/broadcastAPI';



class OrderPlanningHeaderActionButtonsComponent {
    allRegions = ko.observableArray();
    regions = ko.observableArray([]);
    customerRegions = ko.observableArray([]);
    locationRegions = ko.observableArray([]);
    tractorRegions = ko.observableArray([]);
    orderPreassignment = ko.observable();
    tmpRegions = ko.observableArray([]);
    isOwnerOperator = userProfile.isOwnerOperator
    defaultRegion = ko.observable();
    
    /**@type { OrderPlanningViewModel } */
    $parent
    $grid;
    constructor(params) {
        this.$parent = params.data;
        
        this.$parent.orderEntryTracking = ko.observable(new OrderEntryTracking({hideSubMessage: true}));
        this.$parent.orderEntryTracking().onModalCloseAction = this.onOrderConflict;
        this.$parent.headerActionButtonsComponent = this;
    }


    onOrderConflict = () => {
        this.$parent.orderGridComponent.refreshOrderGrid();
        $(".modal-backdrop").remove();
        $("body").removeClass("modal-open");
    }

    onOrderPreassignmentClose = () => {
        this.orderPreassignment(undefined);
        this.$parent.orderGridComponent.refreshOrderGrid(true);
    };

    //This is called from the footer component
    getAndLoadRegions = () => {
        return Promise.all([
            this.getDefaultRegions(),
            this.getRegions()
        ]).then(() => {
            if(userProfile.isOwnerOperator == false) {
                this.loadRegions()
            }
        })
    }

    getDefaultRegions = () => {
        return new Promise((resolve, reject) => {
            if(userProfile.isOwnerOperator) {
                resolve();
            } else {
                dataModel.ajaxRequest("Region/GetDefaultRegion/" + userProfile.currentAgencyId(), "GET", null, true)
                .done((data, status, xhr) => {
                    this.defaultRegion(data);
                    resolve();
                })
                .fail((error, msg, d) => {
                    resolve();
                });
            }
        })
    };
    
    getRegions = () => {
        return new Promise((resolve, reject) => {
            if(userProfile.isOwnerOperator) {
                resolve();
            } else {
                dataModel.ajaxRequest("Region/" + userProfile.currentAgencyId())
                .done((data) => {
                    this.tmpRegions(data);
                    resolve();
                })
                .fail(() => {
                    resolve();
                });
            }
        })
    };

    dispatchButtonClick = (sender, args) => {
        this.$parent.errors.removeAll();
        var confirmationNeeded = false;
        var confirmationMessage = "";
        var data = {};
        var orderRowIndex = $('#jqxOrderGrid').jqxGrid('getselectedrowindex');
        if (orderRowIndex > -1) {
            var rowOrderData = $("#jqxOrderGrid").jqxGrid('getrowdata', orderRowIndex);
            data.isOrderHazmat = rowOrderData.hazmat ? "Yes" : "No";
            data.isOrderBrokered = rowOrderData.isBrokered == "No" ? false : rowOrderData.isBrokered == "Yes" ? true : "";
            if (rowOrderData.isLoad == false && (rowOrderData.sharedOrder == false || userProfile.isGwEmployee)) {
                data.movementId = rowOrderData.id;
                if (rowOrderData.status == "Available") {
                    let tractorRowIndex = this.$parent.tractorGridComponent.grid.jqxGrid('getselectedrowindex');
                    if (tractorRowIndex > -1) {
                        let tractor = this.$parent.tractorGridComponent.grid.jqxGrid('getrowdata', tractorRowIndex);
                        if (tractor.blockDispatch == true) {
                            //$(args.currentTarget).notify("Driver cannot be preassigned or dispatched: " + tractor.dispatchMessages, "error");
                            toast.error("Driver cannot be preassigned or dispatched: " + tractor.dispatchMessages)
                            return false;
                        }
                        // if order is pre-assigned verify that selected tractor is the same; if not, require user to confirm action
                        if (rowOrderData.assignedTractorId != null) {
                            if (rowOrderData.assignedTractorId != tractor.id) {
                                confirmationNeeded = true;
                                confirmationMessage = "Warning: You have selected a different tractor than is pre-assigned on your selected order. <p>Do you wish to continue?</p>"
                            }
                        }

                        data.tractorId = tractor.id;
                        data.isCarrierDriver = tractor.isCarrierDriver;
                        data.driverId = tractor.driverId;
                    }
                    else {
                        if (rowOrderData.isPreassigned == false) {
                            //$(args.currentTarget).notify("Order is not preassigned", "error");
                            toast.error("Order is not preassigned");
                            return false;
                        }
                        if (rowOrderData.blockDispatch == true) {
                            //$(args.currentTarget).notify("Driver cannot be dispatched: " + rowOrderData.dispatchMessages, "error");
                            toast.error("Driver cannot be dispatched: " + rowOrderData.dispatchMessages)
                            return false;
                        }
                    }
                }
            }
            else {
                //$(args.currentTarget).notify("Load can't be dispatched", "error");
                toast.error("Load can't be dispatched");
                return false;
            }
        }
        else {  // no order selected
            let tractorRowIndex = this.$parent.tractorGridComponent.grid.jqxGrid('getselectedrowindex');
            if (tractorRowIndex > -1) {
                let tractor = this.$parent.tractorGridComponent.grid.jqxGrid('getrowdata', tractorRowIndex);
                data.tractorId = tractor.id;
                data.isCarrierDriver = tractor.isCarrierDriver;
                data.driverId = tractor.driverId;
                if (tractor.status == "Available") {
                    //$(args.currentTarget).notify("Tractor is not preassigned", "error");
                    toast.error("Tractor is not preassigned");
                    return false;
                }
                if (tractor.blockDispatch == true) {
                    //$(args.currentTarget).notify("Driver cannot be dispatched: " + tractor.dispatchMessages, "error");
                    toast.error("Driver cannot be dispatched: " + tractor.dispatchMessages)
                    return false;
                }
            }
            else {
                //$(args.currentTarget).notify("Please select preassigned order or tractor", "error");
                toast.error("Please select preassigned order or tractor");
                return false;
            }
        }
        
        //if a condition arose that requires user confirmation,
        // prompt user to confirm before proceeding
        if (confirmationNeeded) {
            showconfirm(confirmationMessage)
            .then((x) => {
                if (x == true) {
                    this.postDispatch(data);
                }
            })
        }
        else {     // no confirmation necessary, proceed with dispatch
            this.postDispatch(data);
        }
    };

    postDispatch = (data) => {
        this.$parent.isLoading(true);
        data.OrderTracking = this.$parent.orderEntryTracking().data;
        dataModel.ajaxRequest("OrderPlanning/Dispatch", "POST", data)
        .done((result) => {
            this.$parent.isLoading(false);
            window.open(result, '_blank');
        })
        .fail((jqXHR, textStatus, errorThrown) => {
            if (jqXHR.status == 409) // Conflict
            {
                this.$parent.orderEntryTracking().methods().displayConflictNotifyMessage(jqXHR.responseJSON);
            } 
            else if (jqXHR.status == 412) // Order Locked
            {
                showmessage(jqXHR.responseJSON.message);
            } 
            else if (jqXHR.status == 400) {
                var response = jqXHR.responseJSON;
                showmessage(response.message);
            }
            else {
                showmessage('An error occured while processing the request. Please try again or contact help desk if problem persists.');
            }
            this.$parent.isLoading(false);
        });
    };

    preassignOrderButtonClick = (sender, args) => {
        this.$parent.errors.removeAll();
        var orderRowIndex = this.$parent.orderGridComponent.grid.jqxGrid('getselectedrowindex');
        var tractorRowIndex = this.$parent.tractorGridComponent.grid.jqxGrid('getselectedrowindex');
        if (orderRowIndex > -1) {
            var orderRowData = this.$parent.orderGridComponent.grid.jqxGrid('getrowdata', orderRowIndex);
            if (orderRowData.isLoad || (orderRowData.sharedOrder && userProfile.isGwEmployee == false)) {
                //$(args.currentTarget).notify("Load can't be pre-assigned", "warn");
                toast.error("Load can't be pre-assigned");
            }
            else {
                if (orderRowData.status == "In Progress") {
                    //$(args.currentTarget).notify("In progress movement cannot be pre-assigned", "warn");
                    toast.error("In progress movement cannot be pre-assigned");
                }
                else {
                    if (orderRowData.brokered == false) {
                        if (tractorRowIndex > -1) {
                            let tractor = this.$parent.tractorGridComponent.grid.jqxGrid('getrowdata', tractorRowIndex);
                            if (tractor.isCarrierDriver == false) {
                                this.orderPreassignment({movement: orderRowData, tractor: tractor, vm: this.orderPreassignment});
                            }
                            else {
                                //$(args.currentTarget).notify("Carrier tractor can be pre-assigned only to brokered orders", "warn");
                                toast.error("Carrier tractor can be pre-assigned only to brokered orders")
                            }
                        }
                        else {
                            //it is NOT brokered and the user did NOT select a tractor.  See if there is an asssigned tractor.  if not, it will be null
                            let tractor = {
                                tractorId: orderRowData.assigned != null ? orderRowData.assigned.substring(0, orderRowData.assigned.indexOf("(") - 1) : undefined,
                                id: orderRowData.assignedTractorId
                            };
                            this.orderPreassignment({movement: orderRowData, tractor: tractor, vm: this.orderPreassignment});
                        }

                    }
                    else {
                        this.orderPreassignment({movement: orderRowData, tractor: {}, vm: this.orderPreassignment});
                    }
                }
            }
        }
        else {
            //$(args.currentTarget).notify("Please select an order", "warn");
            toast.error("Please select an order")
        }
    };

    goToExternalBoardButtonClick = () => {
        router.navigate("ExternalBoard/v2", true);
    }

    copyITSLink = () => {
        copyToClipboard("https://itsonboarding.com/Partners/LandingPage.aspx?token=jpMAibEWeVVQD4teLIt7LttqqYsSQ4xH");
    }

    loadRegions = () => {
        var regionsModel = function (item, items) {
            this.label = item.name;
            this.checked = item.checked || false;
            this.expanded = item.expanded || false;
            this.items = items;
            this.value = item.value;
        };

        var source = [];
        var region = [];
        var customer = [];
        var location = [];
        var tractor = [];
        ko.utils.arrayForEach(this.tmpRegions(), (item) => {
            var match = ko.utils.arrayFirst(this.defaultRegion(), function (cust) {
                return cust.type == item.type && cust.name == item.name;
            });
            var chk = match != null ? true : false;
            var name = item.name;
            if (item.type === "CUSTOMER") {
                customer.push(new regionsModel({ name: name, checked: chk, value: "CUSTOMER" }, []));
                if (chk) {
                    this.customerRegions.push(name);
                }
            }
            else if (item.type === "LOCATION") {
                location.push(new regionsModel({ name: name, checked: chk, value: "LOCATION" }, []));
                if (chk) {
                    this.locationRegions.push(name);
                }
            }
            else if (item.type === "TRACTOR") {
                tractor.push(new regionsModel({ name: name, checked: chk, value: "TRACTOR" }, []));
                if (chk) {
                    this.tractorRegions.push(name);
                }
            }
            else {
                region.push(new regionsModel({ name: name, checked: chk, value: "REGION" }, []));
                if (chk) {
                    this.regions.push(name);
                }
            }
        });

        source.push(new regionsModel({ name: "Regions" }, region));
        source.push(new regionsModel({ name: "Customer" }, customer));
        source.push(new regionsModel({ name: "Location" }, location));
        source.push(new regionsModel({ name: "Tractor" }, tractor));
        this.allRegions(source);
    };

    handleRegionCheckChange = ({sourceData, checkedItems}) => {
        
        const findNextItemMatch = (nextItem = {}, itemsToMatch = []) => {
            if(!nextItem || Object.keys(nextItem).length == 0) return null;
            if(!itemsToMatch || itemsToMatch.length == 0 || !itemsToMatch[0]) return null;
            
            if(itemsToMatch.some(x => (nextItem.label && (x.toUpperCase() == nextItem.label.toUpperCase())))) return nextItem;
            if(!nextItem.nextItem) return null;

            return findNextItemMatch(nextItem.nextItem, itemsToMatch);
        }

        const checkedLabels = checkedItems.map(({label}) => label);

        this.allRegions().map((x) => {
            if(x.items) {
                
                const matches = x.items.filter(item => findNextItemMatch(item, checkedLabels));
                if(matches.length) {
                    matches.map((x) => (x.checked = true))
                }
                else {
                    x.items.map((x) => (x.checked = false))
                }
            } else {
                x.checked = checkedLabels[x.label && x.label.toUpperCase()] != undefined;
            }

            return x;
        })
    }

    onCloseRegions = () => {
        this.regions.removeAll();
        this.customerRegions.removeAll();
        this.locationRegions.removeAll();
        this.tractorRegions.removeAll();

        // This is NOT returning checked items when view is refreshed from an
        // agencyId change. Leaving here as fyi...
        //var regions = $("#treeRegions").jqxTree('getCheckedItems');
        (ko.toJS(this.allRegions) || [])
            .reduce((checked, x) => {
                if(!checked[x.label]) {
                    if(x.items) {
                        const matches = x.items.filter((x) => x.checked);
                        if(matches.length) {
                            matches.forEach(x => checked.push({label: x.label, value: x.value}));
                        }
                    }
                }

                return checked;
            }, [])
            .forEach((x) => {
                if (x.value === "CUSTOMER") {
                    this.customerRegions.push(x.label);
                }
                else if (x.value === "LOCATION") {
                    this.locationRegions.push(x.label);
                }
                else if (x.value === "TRACTOR") {
                    this.tractorRegions.push(x.label);
                }
                else if (x.value === "REGION") {
                    this.regions.push(x.label);
                }
            });

        this.$parent.orderGridComponent.refreshOrderGrid();
        this.$parent.tractorGridComponent.refreshTractorGrid();
    };
}

export { OrderPlanningHeaderActionButtonsComponent }
export default {viewModel: OrderPlanningHeaderActionButtonsComponent, template: template}