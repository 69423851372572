import dataModel from "data-model";
import _ from "lodash";
import { showmessage } from "show-dialog-methods";
import { formatPhoneNumber, noop } from "global-functions";
import { useDispatch, useSelector } from "ko-data-store";
import {
  selectTrackedMovementBySequence,
  selectMovementStatus,
  models,
  selectCarrierTrackingErrorsForMovement,
  updateCarrierTrackingForMovement,
  updateLoadCommentsForMovement,
  selectTrackingErrorsForMovement,
  toggleDebugMode,
  refreshInitialState,
  selectMovementInfoMsg,
  loadTrackingAsync,
  selectIsMovementLoading,
  selectStartTrackMins,
  selectDefaultProviderId,
  updateMovementInfoMsg,
  confirmCancelTracking,
  cancelTrackingAsync,
  selectIsTrackingOn,
  selectDefaultStartTracking,
  setDoStartTrackingSave,
  selectRequiresTracking,
  selectStartTrackingMinsForMove,
  selectUserCanEditVendor,
  selectTransitStatusForMovement,
  selectHasActiveTracking,
  updateIsTrackingOn,
  selectCustomerMappedVendorId,
  selectTrackingByPassedForMovement,
  refreshTrackingLastLocation,
} from "../../../../../dataStore/actions/TNT/orderEntry";
import {
  fetchTntVendorsAsync,
  fetchTntProvidersAsync,
} from "../../../../../dataStore/actions/TNT/lookups";
import { datetimeUTC, modifyDate } from "global-functions";
import userProfile from "user-profile";
import toast from "geNotyf";

export const getAgencyContactDetails = async () => {
  try {
    const response = await dataModel.ajaxRequest(
      "Agency/GetAgencyContactDetails",
      "GET"
    );
    return response;
  } catch (err) {
    return null;
  }
};

export function cleanContactEmail(email = "") {
  let cleanEmail = email || "";
  if (cleanEmail.indexOf(";") !== -1) {
    cleanEmail = cleanEmail.split(";")[0];
  } else if (cleanEmail.indexOf(",") !== -1) {
    cleanEmail = cleanEmail.split(",")[0];
  } else if (email.indexOf("&") !== -1) {
    cleanEmail = cleanEmail.split("&")[0];
  }

  return cleanEmail ? cleanEmail.trim() : "";
}

// MovementId is required
function OrderEntryDispatchTracking({
  moveSequence = undefined,
  trackingId = undefined,
  onLoaded = noop,
  onStartTracking = noop,
  resetDataStoreModel = true,
} = {}) {
  moveSequence = ko.toJS(moveSequence);
  let modelInit = false;
  const vm = this;
  const dispatch = useDispatch();

  vm.jqxDateTimeForMoveSequenceId = `startTrackInput-${moveSequence}`;

  // By default -> reset our date store slice for tnt/orderEntry.
  // If using more than one instance of this component on same page, then can set to false, reset the data state
  // in the parent component. If not, then both components will call reseting the data state.
  if (resetDataStoreModel) {
    dispatch(refreshInitialState());
  }
  vm.loadingFailed = ko.observable(false);
  vm.errors = useSelector((state) =>
    selectCarrierTrackingErrorsForMovement(state, moveSequence)
  );
  vm.infoMsg = useSelector((state) =>
    selectMovementInfoMsg(state, moveSequence)
  );

  // This next section is dumb, but so far prevents multiple updates from same notification...
  let msgs = [];
  vm.infoMsg.subscribe((msg) => {
    if (msg && msg.length > 0 && msgs.indexOf(msg) === -1) {
      msgs.push(msg);

      const x = [...new Set(msgs)];
      if (x[0]) {
        msgs = [];
        toast.open({ type: "info", message: x[0] });
        dispatch(updateMovementInfoMsg(moveSequence, undefined));
      }
    }
  });
  vm.alertErrorMsg = ko.observable();
  vm.apiErrors = useSelector((state) =>
    selectTrackingErrorsForMovement(state, moveSequence)
  );
  vm.apiErrors.subscribe((val) => {
    if (val && val.length) {
      if (val.indexOf("INIT_FETCH_FAILED") > -1) {
        vm.loadingFailed(true);
      } else if (!vm.alertErrorMsg()) {
        const msg = val[0].replace("[Validation Error]", "");
        vm.alertErrorMsg(
          msg ||
            `One or more validation errors occurred while processing request.`
        );
      }
    } else {
      vm.alertErrorMsg("");
    }
  });

  const moveStatus = useSelector((state) =>
    selectMovementStatus(state, moveSequence)
  );
  vm.tracking = useSelector((state) =>
    selectTrackedMovementBySequence(state, moveSequence)
  );
  vm.isReadOnly = ko.pureComputed(
    () =>
      vm.tracking().carrierTracking &&
      vm.tracking().carrierTracking.trackingId !== null &&
      vm.tracking().carrierTracking.trackingStatusId &&
      vm.tracking().carrierTracking.trackingStatusId ===
        models.trackingStatus.Activated
  );

  const defaultProviderId = useSelector(selectDefaultProviderId);
  vm.trackingLoading = useSelector((state) =>
    selectIsMovementLoading(state, moveSequence)
  );

  vm.transitStatus = useSelector((state) =>
    selectTransitStatusForMovement(state, moveSequence)
  );

  const byPassTracking = useSelector((state) =>
    selectTrackingByPassedForMovement(state, moveSequence)
  );
  vm.byPassTrackingCheckBox = ko.pureComputed({
    read: byPassTracking,
    write: (checked) => {
      const username = checked
        ? vm.tracking().carrierTracking &&
          vm.tracking().carrierTracking.byPassTrackingUserName
        : undefined;
      const statusId =
        vm.tracking().carrierTracking &&
        vm.tracking().carrierTracking.trackingStatusId;
      const updatedStatusId =
        !checked && statusId === models.trackingStatus.Cancelled
          ? models.trackingStatus.Pending
          : statusId;
      dispatch(
        updateCarrierTrackingForMovement(moveSequence, {
          byPassTracking: checked,
          byPassTrackingUserName: username,
          trackingStatusId: updatedStatusId,
        })
      );
    },
  });

  vm.userCanEditVendor = useSelector(selectUserCanEditVendor);
  vm.requiresTracking = useSelector(selectRequiresTracking);
  vm.trackingVendorOptions = ko.observableArray([]);
  vm.trackingProviderOptions = ko.observableArray([]);
  vm.locationUpdateNotifyIntervalOptions = ko.observableArray([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);

  vm.selectedCustomerProvider = ko.observable();
  vm.selectedTrackingVendor = ko.observable();
  vm.selectedLocationUpdateNotifyInterval = ko.observable();

  vm.selectedCustomerProvider.subscribe((val) =>
    dispatch(
      updateCarrierTrackingForMovement(moveSequence, { providerId: val })
    )
  );
  vm.selectedTrackingVendor.subscribe((val) =>
    dispatch(updateCarrierTrackingForMovement(moveSequence, { vendorId: val }))
  );

  vm.selectedLocationUpdateNotifyInterval = ko.pureComputed({
    read: () => {
      return (
        (vm.tracking().carrierTracking &&
          vm.tracking().carrierTracking.locationUpdateNotifyInterval) ||
        1
      );
    },
    write: (newValue) => {
      dispatch(
        updateCarrierTrackingForMovement(moveSequence, {
          locationUpdateNotifyInterval: newValue,
        })
      );
    },
  });

  vm.startTrackingBtnEnabled = ko.pureComputed(() => {
    return (
      (vm.tracking().carrierTracking &&
        vm.tracking().carrierTracking.trackingId) ||
      (vm.errors().count === 0 &&
        (moveStatus() === models.movementStatus[2] ||
          moveStatus() === models.movementStatus[4]))
    );
  });

  vm.trackingBtnLabel = ko.pureComputed(() => {
    return vm.tracking().carrierTracking &&
      vm.tracking().carrierTracking.trackingId &&
      vm.tracking().carrierTracking.trackingStatusId ===
        models.trackingStatus.Activated
      ? "Cancel Tracking"
      : "Start Tracking";
  });

  vm.trackingBtnColorClass = ko.pureComputed(() => {
    return vm.tracking().carrierTracking &&
      vm.tracking().carrierTracking.trackingId &&
      vm.tracking().carrierTracking.trackingStatusId ===
        models.trackingStatus.Activated
      ? "btn-danger"
      : "btn-primary";
  });

  vm.trackingBtnTooltip = ko.pureComputed(() => {
    let tooltip =
      vm.tracking().carrierTracking &&
      vm.tracking().carrierTracking.trackingId &&
      vm.tracking().carrierTracking.trackingStatusId ===
        models.trackingStatus.Activated
        ? "Cancel Tracking"
        : "Start Tracking";

    if (vm.tracking().carrierTracking && vm.tracking().carrierTracking.id > 0) {
      return tooltip;
    }

    tooltip = vm.trackingInfoHelpText();

    return tooltip;
  });

  vm.trackingInfoHelpText = ko.pureComputed(() => {
    if (vm.byPassTrackingCheckBox()) {
      return ``;
    } else if (
      moveStatus() !== models.movementStatus[3] &&
      moveStatus() !== models.movementStatus[2] &&
      moveStatus() !== models.movementStatus[4]
    ) {
      return `The movement must be covered or in progress status to Start Tracking.`;
    } else if (vm.errors()["carrierContactEmail"]) {
      return `Carrier Contact Information needed. Use 'Send Rate Confirmation' and select a contact.`;
    } else if (vm.errors()["arrivalDate"]) {
      return `${vm.errors()["arrivalDate"]}`;
    } else if (vm.errors()["departureDate"]) {
      return `${vm.errors()["departureDate"]}`;
    } else if (vm.errors().count > 0) {
      return `Please correct any errors or update fields that are required.`;
    }
  });

  //const shipperStopDate = useSelector(selectDefaultStartTracking);
  const startTrackingMins = useSelector((state) =>
    selectStartTrackingMinsForMove(state, moveSequence)
  );

  vm.handleStartStopTracking = () => {
    vm.alertErrorMsg("");

    if (
      vm.tracking().carrierTracking &&
      vm.tracking().carrierTracking.trackingId !== null &&
      vm.tracking().carrierTracking.trackingStatusId ===
        models.trackingStatus.Activated
    ) {
      dispatch(
        confirmCancelTracking({
          message:
            defaultProviderId() > 0
              ? `The selected customer requires tracking. Are you sure you want to cancel?`
              : `Are you sure you want to cancel?`,
          onChoiceCallBackFn: (yes) => {
            if (yes) {
              dispatch(
                cancelTrackingAsync({
                  moveSequence,
                  onSuccess: () => {
                    const otherMoveStillTracking = useSelector(
                      selectHasActiveTracking
                    );
                    toast.success(
                      `Tracking cancelled for movement ${moveSequence}`
                    );

                    if (otherMoveStillTracking() === false) {
                      dispatch(updateIsTrackingOn(false));
                    }
                  },
                  onError: (err) => showmessage(err),
                })
              );
            }
          },
        })
      );
    } else {
      // check validation errors
      if (vm.errors().count) {
        return false;
      }

      // start tracking
      dispatch(setDoStartTrackingSave(true));
      dispatch(
        updateCarrierTrackingForMovement(moveSequence, {
          trackingStatusId: models.trackingStatus.Pending,
        })
      );
      onStartTracking();
    }
  };

  vm.handlePhoneInput = (x, event) => {
    let { name, value } = event.currentTarget;

    const cleaned = formatPhoneNumber(value);

    if (value !== cleaned) {
      $(event.currentTarget).val(cleaned);
    }

    dispatch(updateCarrierTrackingForMovement(moveSequence, { [name]: value }));
  };

  vm.handleChange = (x, event) => {
    let { name, value } = event.currentTarget;
    const { carrierTracking, loadComments } = vm.tracking();

    // Turn on extra logging easter egg for debugging.
    if (
      name === "driverComment" &&
      value &&
      (value.indexOf("[LOGGING_ON]") > -1 ||
        value.indexOf("[LOGGING_OFF]") > -1)
    ) {
      const on = value.indexOf("[LOGGING_ON]") > -1;
      const level =
        value.indexOf("[LEVEL=INFO]") > -1
          ? "INFO"
          : value.indexOf("[LEVEL=ERRORS]") > -1
          ? "ERRORS"
          : value.indexOf("[LEVEL=VALIDATION]") > -1
          ? "VALIDATION"
          : "ALL";

      dispatch(toggleDebugMode(on, level));

      value = value.replace("[LOGGING_ON]", "");
      value = value.replace("[LOGGING_OFF]", "");
      value = value.replace("[LEVEL=INFO]", "");
      value = value.replace("[LEVEL=ERRORS]", "");

      value = value.trim();
    }

    Object.keys(carrierTracking).forEach((key) => {
      if (key === name) {
        dispatch(
          updateCarrierTrackingForMovement(moveSequence, { [name]: value })
        );
      }
    });

    Object.keys(loadComments).forEach((key) => {
      if (key === name) {
        dispatch(
          updateLoadCommentsForMovement(moveSequence, { [name]: value })
        );
      }
    });
  };

  vm.handleTransitStatusRefresh = async () => {
    if (
      moveSequence != null &&
      vm.tracking().carrierTracking &&
      vm.tracking().carrierTracking.id !== null
    ) {
      await dispatch(
        refreshTrackingLastLocation(
          moveSequence,
          vm.tracking().carrierTracking.id
        )
      );
    }
  };

  const isTrackingOn = useSelector(selectIsTrackingOn);
  isTrackingOn.subscribe((yes) => {
    if (
      yes &&
      vm.tracking().carrierTracking &&
      vm.tracking().carrierTracking.id > 0 === false
    ) {
      setTimeout(() => {
        loadJqxWidgets(vm.tracking().carrierTracking.startTrackingMins || 60);
      }, 500);
    }
  });

  const loadJqxWidgets = (initMinutes = 60) => {
    const $el = $("#" + vm.jqxDateTimeForMoveSequenceId);

    if ($el.length === 0) {
      return false;
    }

    let initDate = datetimeUTC(new Date());
    initDate = initDate.hour(0);
    initDate = initDate.minute(initMinutes);
    initDate = initDate.second(0);
    initDate = initDate.millisecond(0);

    $el.jqxDateTimeInput({
      width: "150px",
      height: "30px",
      formatString: "hh:mm",
      showTimeButton: true,
      showCalendarButton: false,
      textAlign: "center",
      value: initDate.toDate(),
    });
    // HACK: Here we are hiding the AM/PM from a 12 hours selection.
    // -The last td of each row is the AM/PM portions.
    $el.on("open", function (event) {
      setTimeout(() => {
        const $t = $("div.jqx-date-time-input-popup");
        $t.find("td:last-child").hide();
      }, 10);
    });

    // All we want is the HOURS/MINUTES from the entered date.
    $el.on("valueChanged", function (event) {
      const jsDate = event.args.date;

      let hours = 1;
      let minutes = 0;
      if (jsDate) {
        hours = jsDate.getHours();
        minutes = jsDate.getMinutes();
      }

      dispatch(
        updateCarrierTrackingForMovement(moveSequence, {
          startTrackingMins: hours * 60 + minutes,
        })
      );
    });

    dispatch(
      updateCarrierTrackingForMovement(moveSequence, {
        startTrackingMins: initMinutes,
      })
    );
  };

  const getHoursMinsFromMins = (minutes = 60) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    return {
      hours,
      mins,
    };
  };

  vm.startTrackReadonlyText = ko.pureComputed(() => {
    let { hours, mins } = getHoursMinsFromMins(startTrackingMins());
    if (hours > 12) {
      hours = 24 - hours;
    }

    hours = hours === 0 ? 1 : hours;

    return `${hours < 10 ? "0" + hours : hours} : ${mins === 0 ? "00" : mins}`;
  });

  vm.handleReloadTracking = () => {
    dispatch(loadTrackingAsync(moveSequence)).then(() => {
      vm.loadingFailed(false);
      loadStuff();
    });
  };

  const loadStuff = async () => {
    modelInit = true;
    await dispatch(fetchTntVendorsAsync()).then(({ tntLookups }) =>
      vm.trackingVendorOptions(tntLookups.vendors)
    );
    await dispatch(fetchTntProvidersAsync()).then(({ tntLookups }) =>
      vm.trackingProviderOptions(
        tntLookups.providers
          .filter((x) => x.description === "Trucker Tools")
          .map((provider) => ({
            id: provider.id,
            description: `${provider.description} ${
              provider.cost !== null ? "( $" + provider.cost + " )" : ""
            }`,
          }))
      )
    );

    if (vm.requiresTracking()) {
      const customerMappedVendor = useSelector(selectCustomerMappedVendorId);
      vm.selectedCustomerProvider(
        vm.tracking().carrierTracking.providerId ||
          customerMappedVendor() ||
          defaultProviderId() ||
          0
      );
    }

    vm.selectedLocationUpdateNotifyInterval(
      vm.tracking().carrierTracking.locationUpdateNotifyInterval || 1
    );
    vm.selectedTrackingVendor(vm.tracking().carrierTracking.vendorId || 1);

    if (
      !vm.tracking().carrierTracking.authEmail ||
      !vm.tracking().carrierTracking.authPhone ||
      !vm.tracking().carrierTracking.agentPhone ||
      !vm.tracking().carrierTracking.agentEmail
    ) {
      const { email, phone } = await getAgencyContactDetails();
      let payload = {};

      let cleanEmail = cleanContactEmail(email || "");
      if (!vm.tracking().carrierTracking.agentPhone) {
        const x = userProfile.userContactInfo.phone || phone;
        payload.agentPhone = formatPhoneNumber(x);
      }

      if (!vm.tracking().carrierTracking.authEmail) {
        payload.authEmail = cleanEmail;
      }

      if (!vm.tracking().carrierTracking.agentEmail) {
        payload.agentEmail = userProfile.userContactInfo.email || cleanEmail;
      }

      if (!vm.tracking().carrierTracking.authName) {
        payload.authName = userProfile.userName;
      }

      if (!vm.tracking().carrierTracking.authPhone) {
        payload.authPhone = phone && formatPhoneNumber(phone);
      }

      dispatch(updateCarrierTrackingForMovement(moveSequence, payload));
    }

    setTimeout(() => {
      loadJqxWidgets(startTrackingMins() || 60);
      //
    }, 300);
  };

  if (trackingId > 0 === false) {
    loadStuff();
  } else {
    dispatch(
      loadTrackingAsync(ko.toJS(moveSequence), ko.toJS(trackingId))
    ).then(({ tntOrderEntry }) => {
      loadStuff();
      onLoaded(tntOrderEntry);
    });
  }
}

import template from "./order-entry-dispatch-tracking-component.html";
export default { viewModel: OrderEntryDispatchTracking, template };
