//@ts-check
/// <reference path="../../../types/jquery.plugins.d.ts" />

import { OrderPlanningTractorGrid } from './tractor-grid-component'
import template from './tractor-grid-component.html';

class LoadboardTractorGrid extends OrderPlanningTractorGrid {
    pageName = "UseLoadBoardFilters_MVC";
    constructor(params) {
        super(params);
    }
}

export default { viewModel: LoadboardTractorGrid, template: template }