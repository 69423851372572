import { mapKOtoJS, datetimeUTC } from 'global-functions';
import userProfile from 'user-profile';
import ko from 'knockout'

// ENUMS -- using this pattern so intellisense shows the string value.
export const FUND_COMDATA = "X";
export const AOP_COMDATA = "A";
export const FUND_EFS = "F";
export const AOP_EXPRESS = "T";
export const AOP_WIRE = "W";
export const VENDOR_EFS = "H";
export const VENDOR_COMDATA = "C";

export const ADVANCE_FUND = "FUND";
export const ADVANCE_EXPRESS  = "AOP_EXPRESS";
export const ADVANCE_WIRE = "AOP_MANUAL";

export function isWireFund(wireCode) {
    return wireCode == FUND_COMDATA || wireCode == FUND_EFS;
}

export function isWireAOP(wireCode) {
    return wireCode == AOP_WIRE || wireCode == AOP_COMDATA;
}

export function isWireAOPExpress(wireCode) {
    return wireCode == AOP_EXPRESS;
}

export function getAdvanceTypeFromWireCode(wireCode) {
    if(isWireAOP(wireCode)) {
        return ADVANCE_WIRE;
    }
    else if(isWireAOPExpress(wireCode)) {
        return ADVANCE_EXPRESS
    }
    else if(isWireFund(wireCode)) {
        return ADVANCE_FUND
    }
    else {
        return "";
    }
}

export function SendWireData({
    orderId = undefined,
    driverId = undefined,
    payeeId = undefined,
    driverExId = "", 
    payeeExId = "", 
    orderExId = "", 
    advanceType = "", 
    aopLimit = 0, 
    fundingLimit = 0, 
    allowManualEntry = false,
    maxAdvanceLimit = 0,
    onAddWire = function(wireInfo){}, 
    onCancelWire = function(wireInfo) {},
    onClose = function(){}
}) {

    const advanceTypes = ["FUND","AOP_EXPRESS","AOP_MANUAL"];

    this.driverId = driverId;
    this.payeeId = payeeId;
    this.orderId = orderId;

    // these are used for the setting values in the autocomplete ddl on the modal form.
    this.driverExId = driverExId;
    this.payeeExId = payeeExId;
    this.orderExId = orderExId;
    
    let _advanceType = advanceType;
    this.advanceType = (advanceType) => (advanceType ? _advanceType = advanceTypes.find(x => x == advanceType) : _advanceType);

    this.aopLimit = aopLimit;
    this.fundingLimit = fundingLimit;

    this.onAddWire = onAddWire;
    this.onCancelWire = onCancelWire;
    this.onClose = onClose;

    let _maxLimit = maxAdvanceLimit;
    this.maxAdvanceLimit = (maxAdvanceLimit) => (maxAdvanceLimit ? _maxLimit = maxAdvanceLimit : _advanceType == "FUND" ? this.fundingLimit : this.aopLimit);

    this.allowManualEntry = allowManualEntry;
    
    this.needsQualified = () => {
        return this.driverId || this.payeeId || this.orderId;
    }
}


export function AdvanceRequestModel({
    wireId = undefined,
    fuelCardId = undefined,
    subAccount = undefined,
    advLimit = undefined,
    advanceType = "FUND",
    driverId = undefined,
    driverExId = undefined,
    payeeId = undefined,
    payeeExId = undefined,
    wireCode = undefined,
    vendor = undefined,
    description = undefined,
    amount = undefined,
    checkCode = undefined,
    checkNumber = undefined,
    enteredBy = userProfile.userName,
    orderId = undefined,
    orderExId = undefined,
    manifest = undefined,
    movementId = undefined,
    hasPosted = false,
    entryDate = new Date()
}) {
    
    /** @param {number} val */
    const getParsedFloatOrDefault = (val)  => {
        try {
            if(typeof val === "number") {
                return parseFloat(val.toFixed(2));
            }
    
            // .toFixed returns a string so parse val in correct format, then convert back to float.
            return parseFloat(parseFloat(val.toFixed(2))); 
        }
        catch(e) {
            return 0;
        }
    }

    this.maxAllowed = ko.observable(getParsedFloatOrDefault(advLimit));

    this.advanceType = ko.observable(advanceType || "FUND").extend({ notify: 'always' });

    this.wireId = ko.observable(wireId).extend({ notify: 'always' });
    
    this.fuelCardId = ko.observable(fuelCardId);
    this.subAccount = ko.observable(subAccount);

    this.wireCode = ko.observable(wireCode);

    this.vendor = ko.observable(vendor);
  
    const _description = ko.observable(description);
    this.description = ko.pureComputed({
        read: () => ko.unwrap(_description),
        write: (val) => {
            val = val || "";
            let x = val ? val.replace(/[^a-z0-9\s.$%-()]+/gi," ").trim() : null;
            _description(x);
        }
    })
    .extend({
        required: { 
            onlyIf: () => !ko.unwrap(this.wireId),
            message: "This field is required." 
        },
    });

    this.amount = ko.observable(amount).extend({
        validation: {
            message: `Amount entered is greater than amount allowed.`,
            validator: (val) => {
                val = val || 0;

                const max = getParsedFloatOrDefault(this.maxAllowed());
                const inputAmount = getParsedFloatOrDefault(val);

                return max >= inputAmount;
            },
            onlyIf: () => !ko.unwrap(this.wireId),
        }
    });

    this.checkCode = ko.observable(checkCode);
    this.checkNumber = ko.observable(checkNumber);
    this.enteredBy = ko.observable(enteredBy);

    this.movementId = ko.observable(movementId);
    this.manifest = ko.observable(manifest).extend({ 
        validation: { 
            message: "Only alphanumeric characters allowed. [a-Z/0-9]",
            validator(val) { 
                let _isValid = true;

                if(val) {
                    const regEx = /^[a-z0-9]+$(\.0-9+)?/gi;
                    _isValid = regEx.test(val);
                }
                
                return _isValid;
            }
        } 
    });

    
    this.orderId = ko.observable(orderId).extend({ notify: 'always' });
    this.orderExId = ko.observable(orderExId).extend({
        required: { 
            onlyIf: () => {
                let isRequired = false;

                if(ko.unwrap(this.advanceType) == "FUND") {
                    isRequired = true;
                }
                
                return isRequired;
            },
            message: "This field is required." 
        }
    }).extend({ notify: 'always' });

    this.payeeId = ko.observable(payeeId).extend({ notify: 'always' });
    this.payeeExId = ko.observable(payeeExId).extend({
        required: { 
            onlyIf: () => !ko.unwrap(this.wireId) 
                    && (ko.unwrap(advanceType) !== ADVANCE_FUND),
            message: "This field is required." 
        }
     }).extend({ notify: 'always' });

    this.driverId = ko.observable(driverId).extend({ notify: 'always' });
    this.driverExId = ko.observable(driverExId).extend({ notify: 'always' });

    this.hasPosted = ko.observable(hasPosted || false);

    let d = datetimeUTC(entryDate);
    const _entryDate = ko.observable(d.isValid() ? d.format("MM/DD/YYYY hh:mm A") : "");
    this.entryDate = ko.pureComputed({
        read: () => {
            return _entryDate();
        },
        write: (val) => {
            let _val = val || new Date();

            let d = datetimeUTC(_val);
            let x = d.isValid() ? d.format("MM/DD/YYYY hh:mm A") : ""
            _entryDate(x);
        }
    })
    
    /** @returns {boolean} */
    this.isValid = () => {
        const koErrors = ko.validation.group(this, {deep: false, live: false});

        if(koErrors().length > 0) {
            koErrors.showAllMessages();
            return false;
        }

        return true;
    }

    this.toJS = () => {
        const data = mapKOtoJS(this);
        return data;
    }
}

export function FuelCard({
    fuelCardId,
    payeeId,
    cardType,
    canReceiveAdvance
}) {
    this.fuelCardId = fuelCardId;
    this.payeeId = payeeId;
    this.canReceiveAdvance = canReceiveAdvance;
    this.isEfsCard = () => cardType === "H";
    this.isComdataCard = () => cardType === "C";
}

// Used for fund orders - tracking avail amounts.
export function FundAmounts({
    available = 0,
    currentTotal = 0,
    maxAllowed = 0,
    maxAdvPerc = 0
} = {}) {
    this.available = available;
    this.currentTotal = currentTotal;
    this.maxAllowed = maxAllowed;
    this.maxAdvPerc = maxAdvPerc;
}