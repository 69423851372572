import dataModel from 'data-model';

/**
 * ESF Advance Service - Util methods
 */
function efsAdvanceServices() {
    const efsHTTP = new efsHTTPService();
    // fetch logged in user's advance settings
    this.getCurrentUserAdvanceSettings = async() => {
        const result = new serviceResult();

        const advanceSettings = await efsHTTP.fetchUserAdvanceSettings().catch((err) => { result.errors.push(err); return null; });

        if(advanceSettings) {
            result.data = advanceSettings;
        } 

        return result;
    }
    
    this.qualifyWireAdvance = async({orderId, driverId, payeeId}) => {
        var result = new serviceResult();

        var response = await efsHTTP.fetchWireQualifications({orderId, driverId, payeeId}).catch((err) => { result.errors.push(err); return null; });

        if(response && response.errors && response.errors.length) {
            if(Array.isArray(response.erors)) {
                result.errors = [...response.errors];
            }
            else {
                result.errors.push(response.errors);
            }
        }

        return result;
    }

    this.qualifyDriver = async({payeeId, driverId}) => {
        var result = new serviceResult();
        
        const response = await efsHTTP.fetchDriverQualification({payeeId, driverId}).catch((err) => { result.errors.push(err); return null });

        if(response && response.errors && response.errors.length) {
            if(Array.isArray(response.erors)) {
                result.errors = [...response.errors];
            }
            else {
                result.errors.push(response.errors);
            }
        }

        return result;
    }

    this.qualifyPayee = async(payeeId) => {
        var result = new serviceResult();
        
        const response = await efsHTTP.fetchPayeeQualification(payeeId).catch((err) => { result.errors.push(err); return null });

        if(response && response.errors && response.errors.length) {
            if(Array.isArray(response.erors)) {
                result.errors = [...response.errors];
            }
            else {
                result.errors.push(response.errors);
            }
        }

        return result;
    }

    this.qualifyOrder = async({orderId, advanceType}) => {
        var result = new serviceResult();
        
        const response = await efsHTTP.fetchOrderQualification({orderId, advanceType}).catch((err) => { result.errors.push(err); return null });

        if(response && response.errors && response.errors.length) {
            if(Array.isArray(response.erors)) {
                result.errors = [...response.errors];
            }
            else {
                result.errors.push(response.errors);
            }
        }

        return result;
    }

    this.qualifyUser = async(formData) => {
        var result = new serviceResult();
        
        const response = await efsHTTP.fetchUserQualification(formData).catch((err) => { result.errors.push(err); return null });

        if(response && response.errors && response.errors.length) {
            if(Array.isArray(response.erors)) {
                result.errors = [...response.errors];
            }
            else {
                result.errors.push(response.errors);
            }
        }

        return result;
    }

    
    this.processWire = async(wireData) => {
        var result = new serviceResult();

        const payload = {
            ...wireData,
            amountRequested: wireData.amount,
        };

        var response = await efsHTTP.sendAddWire(ko.toJS(payload)).catch((err) => { result.errors.push(err); return null; });

        if(response && response.errors && response.errors.length) {
            if(Array.isArray(response.erors)) {
                result.errors = response.errors[0];
            }
            else {
                result.errors.push(response.errors);
            }
        }
        else {
            result.data = response;
        }

        return result;
    }

    this.requestExpressCheckCode = async(wireData) => {
        var result = new serviceResult();

        const payload = {
            ...wireData,
            amountRequested: wireData.amount,
        };

        var response = await efsHTTP.sendExpressCheckCode(ko.toJS(payload)).catch((err) => { result.errors.push(err); return null; });

        if(response && response.errors && response.errors.length) {
            if(Array.isArray(response.erors)) {
                result.errors = [...response.errors];
            }
            else {
                result.errors.push(response.errors);
            }
        }
        else {
            result.data = { wireId: response.wireId, checkCode: response.checkCode, checkNumber: response.checkNumber };
        }

        return result;

    }

    this.cancelWire = async (wireData) => {
        var result = new serviceResult();

        var response = await efsHTTP.sendCancelWire(wireData).catch((err) => { result.errors.push(err); return null; });

        if(response && response.errors && response.errors.length) {
            if(Array.isArray(response.erors)) {
                result.errors = [...response.errors];
            }
            else {
                result.errors.push(response.errors);
            }
        }

        return result;
    }

    this.cancelExpressCode = async (wireData) => {
        var result = new serviceResult();
        
        var response = await efsHTTP.sendCancelExpressCode(wireData).catch((err) => { result.errors.push(err); return null; });

        if(response && response.errors && response.errors.length) {
            if(Array.isArray(response.erors)) {
                result.errors = [...response.errors];
            }
            else {
                result.errors.push(response.errors);
            }
        }

        return result;
    }

    this.getFuelCards = async({payeeId = undefined, driverId = undefined, driverExId = undefined, canReceiveAdvance = null}) => {
        let result = new serviceResult();

        let response = await efsHTTP.fetchFuelCards({payeeId, driverId, driverExId, canReceiveAdvance}).catch((err) => { result.errors.push(err); return null; });

        result.data = response && response.length > 0 ? response : [];

        return result;
    }

    this.getFundInfo = async(orderExId) => {
        let result = new serviceResult();

        let response = await efsHTTP.fetchFundInfo(orderExId).catch((err) => { result.errors.push(err); return null; });

        result.data = response || {};

        return result;
    }

    this.getFundAmounts = async(orderId) => {
        let response = await efsHTTP.fetchFundAmounts(orderId).catch((err) => { return null; });

        return response || {};
    }

    this.getFuelCardsByDriver = async(driverId) =>{
        let result = new serviceResult();

        let response = await efsHTTP.fetchFuelCardsByDriver(driverId).catch((err) => { result.errors.push(err); return []; });

        result.data = response[0] || {};

        return result;
    }
}

/**
 * EFS HTTP methods
 */
function efsHTTPService() {
    this.fetchUserAdvanceSettings = () => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("User/AdvanceControls", "GET", null, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        });
    }

    this.fetchFuelCards = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/FuelCards", "GET", payload, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        });
    }

    this.fetchFuelCardsByDriver = (driverId) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/FuelCards/Driver", "GET", {driverId: driverId}, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        });
    }

    this.fetchPriorAdvanceGridData = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Dispatch/GetWiresForOrder", "GET", payload).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        });
    }

    this.fetchWireQualifications = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/Qualify", "POST", payload, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing qualifications."
            ));
        });
    }

    this.fetchDriverQualification = ({payeeId, driverId}) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/Qualify/Driver", "GET", {payeeId, driverId}, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) || "Error occurred while processing request."
            ))
        })
    }

    this.fetchPayeeQualification = (payeeId) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/Qualify/Payee", "GET", {payeeId: payeeId}, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) || "Error occurred while processing request."
            ))
        })
    }

    this.fetchOrderQualification = ({orderId, advanceType}) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/Qualify/Order", "GET", {orderId, advanceType}, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) || "Error occurred while processing request."
            ))
        })
    }

    this.fetchUserQualification = (formData) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/Qualify/User", "GET", formData, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) || "Error occurred while processing request."
            ))
        })
    }

    this.sendCancelWire = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/Cancel", "POST", payload, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        })
    }

    this.sendCancelExpressCode = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/Cancel/ExpressCode", "POST", payload, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        })
    }

    this.sendAddWire = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/Add", "POST", payload, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        })
    }

    this.sendExpressCheckCode = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("Wires/ExpressCheck", "POST", payload, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        })
    }

    this.fetchFundInfo = (orderExId) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest('Wires/FundInfo', 'GET', {orderExId: orderExId}, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        })
    }

    this.fetchFundAmounts = (orderId) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest('Wires/FundAmountsInquiry', 'GET', {orderId}, true).done((response) => resolve(response))
            .fail((err) => reject(
                (err.responseJSON && err.responseJSON.message) ||
                    "Error occurred while processing."
            ));
        })
    }
}

/** Models */
/**
 * @param {object|object[]} [data] - response object containing data for request.
 * @param {string[]} [errors] - array containing any errors that occurred with request.
 */
export function serviceResult(data = {}, errors = []) {
    this.data = data;
    this.errors = errors;
    this.isSuccess = () => this.errors.length == 0;
}

const instance = new efsAdvanceServices();
export default instance;
