import template from './gem-tenders-page.html';
import ko from 'knockout';
import dataModel from 'data-model';
import {showmessage} from 'show-dialog-methods';
import $ from 'jquery';

var GEMTendersViewModel = function () {
    var self = this;

    self.isLoading = ko.observable(false);

    self.declineTender = ko.observable();

    self.loadTenders = function () {

        var datafields = [
            { name: 'id', type: 'int' },
            { name: "driverId", type: "string" },
            { name: "driverName", type: "string" },
            { name: "status", type: "string" },
            { name: "agencyId", type: "string" },
            { name: "orderId", type: "string" },
            { name: "origin", type: "string" },
            { name: "destination", type: "string" },
            { name: "pickUpDate", type: "date" },
            { name: "deliveryDate", type: "date" },
            { name: "sequence", type: "int" }];
        var columns = [
            { text: 'Driver Code', datafield: 'driverId', width: 90 },
            { text: 'Driver Name', datafield: 'driverName', width: 150 },
            { text: 'Tender Status', datafield: 'status', width: 100 },
            { text: 'Agency', datafield: 'agencyId', width: 80 },
            { text: 'Order Id', datafield: 'orderId', width: 80 },
            { text: 'Origin', datafield: 'origin', width: 180 },
            { text: 'Destination', datafield: 'destination', width: 180 },
            { text: 'Ship Date/Time', datafield: 'pickUpDate', width: 150, cellsformat: dataModel.jqxTimeFormat },
            { text: 'Del Date/Time', datafield: 'deliveryDate', width: 150, cellsformat: dataModel.jqxTimeFormat },
            { text: 'PreAssignment Order', datafield: 'sequence', width: 150 }];

        var source = {
            url: "Tender/GetTendersForGrid",
            datatype: "json",
            datafields: datafields
        };
        var dataSource = dataModel.getDataAdapter(source);

        $("#tenderGrid").jqxGrid({
            width: '100%',
            columns: columns,
            source: dataSource,
            altrows: true,
            sortable: true,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            autoheight: true,
            rowdetails: true,
            rowdetailstemplate: {
                rowdetails: "<div style='height:450px; overflow-y:scroll;display:inline-block;background-color: #F5F5F5;'></div>",
                rowdetailsheight: 450
            },
            initrowdetails: function (index, parentElement, gridElement, datarecord) {
                var tabsdiv = $($(parentElement).children()[0]);
                if (tabsdiv != null) {
                    self.isLoading(true);
                    dataModel.ajaxRequest("Tender/" + datarecord.id)
                    .done(function (data, status, xhr) {
                        tabsdiv.append("<div data-bind=\"template: { name: 'tenderDetails', data: $data }\" style='display:inline-block; padding:15px;width:100%;'>");
                        data.accept = function (item) {
                            dataModel.ajaxRequest("Tender/Accept/" + datarecord.id, "post")
                            .done(function (result, status, xhr) {
                                $("#tenderGrid").jqxGrid('updatebounddata');
                            })
                            .fail(function (error, msg, d) {
                                showmessage('An error has occured', 'danger');
                            });
                        };
                        data.showDecline = function () {
                            self.declineTender({
                                reason: ko.observable("Other"),
                                comment: ko.observable(),
                                submit: function (item) {

                                    if (item.reason() == null) {
                                        return false;
                                    }
                                    var decline = {
                                        id :data.id,
                                        comment: item.comment(),
                                        reason: item.reason()
                                    };

                                    dataModel.ajaxRequest("Tender/Decline", "post", decline)
                                    .done(function (data, status, xhr) {
                                        self.declineTender(undefined);
                                        $("#tenderGrid").jqxGrid('updatebounddata');
                                    })
                                    .fail(function (error, msg, d) { });
                                }
                            });
                        };
                        data.cancelDecline = function(){
                            self.declineTender(undefined);
                        };

                        ko.applyBindings(data, tabsdiv[0]);

                        self.isLoading(false);
                    })
                    .fail(function (error, msg, d) {
                        tabsdiv.html(JSON.stringify(error, null, 4));
                        self.isLoading(false);
                    });
                }
            }
        });
    };

    self.loadTenders();
}

export default {viewModel: GEMTendersViewModel, template: template}