import dataModel from 'data-model';
import router from 'router';
import { useDispatch } from 'ko-data-store';
import { isLoading } from 'dataStore-actions/appUI';

const sendExpireLoadAsync = (loadId) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("FreightBoard/ExpireLoad/" + loadId, "post")
        .done(data => resolve(data)).fail((error => reject(error)));
    })
}

const createOrder = ({loadId, expireLoad, onSuccess, onError}) => {
    const dispatch = useDispatch();

    return () => {
        const sendPost = () => {
            return new Promise((resolve, reject) => {
                dataModel.ajaxRequest("FreightBoard/CreateOrderFromLoad/" + loadId, "post", {expireLoad})
                .done(data => resolve(data)).fail((error => reject(error)));
            })
        }

        dispatch(isLoading(true));
        sendPost().then(createdOrderId => {
            router.navigate("NewOrder/"+createdOrderId,true);
            onSuccess(createdOrderId);
            dispatch(isLoading(false));
        })
        .catch(error => { dispatch(isLoading(false)); onError(error.responseJSON && error.responseJSON.message); });
    }
    
};

const close = (callBack, modalId = "") => {
    return () => {
        $(modalId).removeClass('fade in show');
        const $backdrop = $('div.modal-backdrop.in');

        if($backdrop) $backdrop.remove();
        if(typeof callBack === 'function') callBack();
    }
}

function CreateOrderFromLoad({id, onCloseCallBackFn}) {
    const vmBindings = this;
    const dispatch = useDispatch();

    vmBindings.expireLoadOnCreate = ko.observable(false);
    vmBindings.errorMsg = ko.observable();
    vmBindings.displayExpireLoadBtn = ko.pureComputed(() => vmBindings.errorMsg() && vmBindings.errorMsg().indexOf("expire the posted") > -1);
    vmBindings.orderCreatedId = ko.observable();
    vmBindings.handleCreateOrder = createOrder({loadId: id, expireLoad: vmBindings.expireLoadOnCreate, onSuccess: vmBindings.orderCreatedId,  onError: vmBindings.errorMsg });

    const dispose = () => {
        vmBindings.errorMsg(undefined);
        vmBindings.orderCreatedId(undefined);
        vmBindings.expireLoadOnCreate(false);
    }

    vmBindings.handleClose = close((orderId) => {
        dispose();
        onCloseCallBackFn(orderId);
    }, "#jqxCreateOrderFromLoad");


    vmBindings.handleExpireLoadAndSendToNewOrderPage = async () => {
        try {
            dispatch(isLoading(true));
            await sendExpireLoadAsync(id);
            dispatch(isLoading(false));
            
            router.navigate("NewOrder",true);
            vmBindings.handleClose();
            
        }
        catch(err) {
            dispatch(isLoading(false));
            console.error(`Error while expiring load: `,err);
        }
    }
    
    const open = () => $('#jqxCreateOrderFromLoad').modal('show');

    open();
}

import template from './create-order-from-load-component.html';
export default {viewModel: CreateOrderFromLoad, template: template};