import dataModel from 'data-model';
import * as wireTypes from '../../types';

/**
 * 
 * @param {Object} params - KO component params object.
 * @param {function} params.onGridSelection - Callback function for when a grid row is selected.
 * @param {ko.Observable<object>} params.fetchWires - obsservable containing an object { orderId, payeeId, modal advanceType}
 * @param {function} params.onResetSelection
 */
const efsPriorAdvancesGridViewModel = function({onGridSelection = function(){}, fetchWires, onResetSelection = function() {} }) {
    this.$grid = $('#efsPriorAdvancesGrid');
    let _orderId, _payeeId, _advanceType, isProcessing = false;

    this.resetSelection = () => {
        if(this.$grid.jqxGrid('getselectedrowindex') != -1) this.$grid.jqxGrid('clearselection');
        this.showClearBtn(false);
        onResetSelection();
    }

    this.showClearBtn = ko.observable(false);

    fetchWires.subscribe((fetchWireData) => {
        if(Object.keys(fetchWireData || {}).length) {
            handleFetchWires(fetchWireData);
        }
        else {
            this.showClearBtn(false);
            this.$grid.jqxGrid('clear');
        }
    });

    const handleFetchWires = ({orderId = undefined, payeeId = undefined, wireId = undefined, advanceType = "FUND", forceRefresh = false, forceClear = false}) => {
        if(this.$grid.jqxGrid('getselectedrowindex') != -1) {
            this.$grid.jqxGrid('clearselection');

            // sometimes the grid unselects randomly, so if still viewing
            // a prior wire continue to show btn, otherwise hide.
            if(!wireId) {
                this.showClearBtn(false);
            }
            
        }
        

        if(forceClear) {
            refreshGrid(true);
        }

        if(forceRefresh) {
            refreshGrid(false);
        }
        
        if(isProcessing || wireId > 0) return false;

        if(orderId > 0 == false && payeeId > 0 == false) {
            refreshGrid(true);
            resetMembers();
        }

        let refresh = false;

        if(payeeId != _payeeId) {
            _payeeId = payeeId;
            refresh = true;
        }

        if(orderId != _orderId) {
            _orderId = orderId;
            refresh = true;
        }

        if(refresh) {
            refreshGrid();
        }

        
        // if(advanceType != wireTypes.ADVANCE_FUND) {
        //     let refresh = false;

        //     if(payeeId != _payeeId) {
        //         _payeeId = payeeId;
        //         refresh = true;
        //     }

        //     if(orderId != _orderId) {
        //         _orderId = orderId;
        //         refresh = true;
        //     }

        //     if(refresh) {
        //         refreshGrid();
        //     }
            
        // }
        // else if(orderId != _orderId) {
        //     _orderId = orderId;
        //     refreshGrid(_orderId > 0 == false);
        // }
        
    }

    const refreshGrid = (clearContents) => {
        if(clearContents) {
            this.showClearBtn(false);
            this.$grid.jqxGrid('clear');
        }
        else {
            isProcessing = true;
            this.$grid.jqxGrid('updatebounddata');
        }

        if(this.$grid.jqxGrid('getselectedrowindex') != -1) this.$grid.jqxGrid('clearselection');
    }

    const resetMembers = () => {
        _orderId = undefined;
        _payeeId = undefined;
        _advanceType = undefined;
    }

    this.dataAdapter = dataModel.getDataAdapter({
        url: "Wires/GetWiresForOrder",
        datatype: "json",
        datafields: [
            { name: "id", type: "int" },
            { name: "fuelCardId", type: "string" },
            { name: "subAccount", type: "string" },
            { name: "transactionDate", type: "date" },
            { name: "payeeExId", type: "string" },
            { name: "payeeName", type: "string" },
            { name: "payeeId", type: "int"},
            { name: "checkNumber", type: "string" }, // check number
            { name: "description", type: "string" },
            { name: "checkAmount", type: "float" },
            { name: "enteredBy", type: "string" },
            { name: "driverExId", type: "string"},
            { name: "driverId", type: "int"},
            { name: "wireCode", type: "string"},
            { name: "checkCode", type: "string"},
            { name: "order", type: "string"},
            { name: "orderId", type: "int"},
            { name: "wireStatus", type: "string"},
            { name: "hasPosted", type: "boolean"},
            { name: "hasPendingCancellation", type: "boolean"}
        ],
        sortcolumn: 'date',
        sortdirection: 'asc',
        beforeprocessing: (data) => {
            data = data || [];
            data.forEach(x => {
                if(x.transactionDate) {
                    x.transactionDate = x.transactionDate.substr(0, x.transactionDate.indexOf("T") + 9);
                }
            })
        },
        formatdata: (data) =>{
            data.orderId = _orderId;
            data.payeeId = _payeeId;

            return data;
        },
        loadComplete: () => {
            isProcessing = false;
        }
    });

   this.$grid
        .jqxGrid({
            theme: "GWTMDark",
            width: "100%",
            source: this.dataAdapter,
            height: "280px",
            altrows: true,
            showstatusbar: true,
            sortable: true,
            pageable: true,
            filterable: false,
            showfilterrow: false,
            columnsresize: true,
            columnsreorder: false,
            enablebrowserselection: true,
            columnsmenu: false,
            statusbarheight: 35,
            showtoolbar: false,
            columns: [
                {
                    text: "Date",
                    datafield: "transactionDate",
                    hidden: false,
                    width: 140,
                    cellsformat: 'MM/dd/yyyy HH:mm'
                },
                { text: "Payee", datafield: "payeeExId", width: 100 },
                { text: "Check Number", datafield: "checkNumber", width: 140 },
                {
                    text: "Description",
                    datafield: "description",
                    width: 250
                },
                {
                    text: "Amount",
                    datafield: "checkAmount",
                    width: 140,
                    cellsformat: "C2"
                },
                { text: "Entered By", datafield: "enteredBy", width: 140 },
            ]
        })
        
        this.$grid.on('rowselect', (e) => {
            const args = e.args || {};
            const rowData = args.row;
            
            if(rowData) {
                this.showClearBtn(true);
                onGridSelection(rowData);
            } 
        });
}

import template from './efs-prior-advances-grid-component.html';
export default { viewModel: efsPriorAdvancesGridViewModel, template: template }