import dataModel from "data-model";
import userProfile from "user-profile";
import { isEmpty } from "global-functions";
import gridState from "jqx.grid-state-component";
import gridStateUtils from "jqx.grid-utils";

class RegionManagementViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};
        this.isProcessing = ko.observable(false);

        this.grid = $("#regionManagementGrid");
        this.regionManagementGridState = null;
        this.regionType = ko.observable();
        this.editFormData = ko.observable();

        this.initializeComponent();
    }

    async initializeComponent() {
        await this.setUserFilters(); // And load this, but wait here until finished, then
        this.loadGrid(); // load this
    }

    refreshGrid = () => {
        this.grid.jqxGrid("updatebounddata", "data");
        this.$emitsOnAction();
    };

    handleActionCallBack = (resetForm) => {
        this.refreshGrid();

        if (resetForm) this.editFormData(undefined);
    };

    async setUserFilters() {
        let filters = await gridStateUtils
            .getUserGridFiltersAsync("regionManagementGrid")
            .catch((err) => console.error("Failed to retrieve user filters."));
        if (isEmpty(filters) == false) {
            let state = JSON.parse(filters);
            this.regionManagementGridState = state.grid;
        }
    }

    get gridSource() {
        const vm = this;
        return {
            url: "MyAgency/GetRegionManagement",
            datatype: "json",
            formatdata: (data) => {
                data = data || {};
                data.agencyId = userProfile.currentAgencyId();

                return data;
            },

            datafields: [
                { name: "stringAssignments", type: "string" },
                { name: "id", type: "int" },
                { name: "name", type: "string" },
                { name: "states", type: "string" },
                { name: "type", type: "string" },
            ],
        };
    }

    loadGrid = () => {
        const vm = this;

        if (this.grid.length == 0)
            this.grid.length = $("#regionManagementGrid");

        const dataAdapter = dataModel.getDataAdapter(this.gridSource);

        this.grid
            .jqxGrid({
                theme: "GWTMDark",
                width: "100%",
                height: "330px",
                source: dataAdapter,
                selectionMode: "singlecell",
                cellhover: (element, pageX, pageY) => {
                    if (element.innerText.length > 10) {
                        $(element)
                            .attr("data-toggle", "tooltip")
                            .attr("data-placement", "right")
                            .attr("title", element.innerText)
                            .attr("data-delay", "500");
                    }
                },
                altrows: true,
                showstatusbar: true,
                sortable: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                statusbarheight: 35,
                showtoolbar: true,
                rendertoolbar: (toolbar) => {
                    let vm1 = new gridState.viewModel();
                    vm1.initializeDefaultToolbar(toolbar);
                    vm1.notifyLocation = $("#divRegionManagementPanel");
                    $(toolbar).append($("#btnAddNewRegion"));
                },

                rendergridrows: (obj) => {
                    return obj.data;
                },

                columns: [
                    {
                        text: "",
                        width: 60,
                        filterable: false,
                        sortable: false,
                        columnType: "button",
                        buttonclick: (row) => {
                            const datarow = $("#regionManagementGrid").jqxGrid(
                                "getrowdata",
                                row
                            );
                            vm.handleEditRegion(datarow);
                        },
                        cellsrenderer: () => {
                            return "Edit";
                        },
                    },
                    {
                        text: "Assignments",
                        datafield: "stringAssignments",
                        width: 100,
                    },
                    { text: "Id", datafield: "id", hidden: true },
                    { text: "Name", datafield: "name", width: "20%" },
                    { text: "States", datafield: "states", width: "50%" },
                    { text: "Type", datafield: "type", width: "20%" },

                    {
                        text: "",
                        width: 60,
                        filterable: false,
                        sortable: false,
                        columnType: "button",
                        buttonclick: (row) => {
                            const datarow = $("#regionManagementGrid").jqxGrid(
                                "getrowdata",
                                row
                            );
                            vm.handleDelete(datarow.id);
                        },
                        cellsrenderer: () => {
                            return "Delete";
                        },
                    },
                ],

                ready: () => {
                    gridStateUtils.applyGridState(
                        "regionManagementGrid",
                        this.regionManagementGridState
                    );
                },
            })
            .bind("filter", () => {
                gridStateUtils.logGridFilterResults("#regionManagementGrid");
            });
    };

    handleEditRegion = (formData) => {
        this.dohandleEditRegion(formData);
    };

    async dohandleEditRegion(formData) {
        formData = {
            id: formData["id"],
            currentItem: {},
            assignmentList: [],
            availableStates: [],
            currentStates: [],
            regions: [],
        };

        const vm = this;

        const resultData = formData.id
            ? await vm
                  .http_sendGetStatesAndRegionsForEdit(formData.id)
                  .catch((err) => console.error(err))
            : await vm
                  .http_sendGetStatesAndRegionsForAdd()
                  .catch((err) => console.error(err));

        if (resultData)
            formData = {
                ...formData,
                ...resultData,
                ...resultData["currentItem"],
            };

        this.editFormData(formData);
    }

    handleDelete = (id) => {
        this.doHandleDelete(id);
    };

    async doHandleDelete(id) {
        const vm = this;

        await vm.http_sendDelete(id).catch((err) => console.error(err));
        vm.refreshGrid();
    }

    http_sendGetStatesAndRegionsForAdd = () => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/AddNewRegion", "get")
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };

    http_sendGetStatesAndRegionsForEdit = (id) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/GetEditRegion", "get", {
                    id: id,
                    agencyId: userProfile.currentAgencyId(),
                })
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };

    http_sendDelete = (id) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/DeleteRegion", "DELETE", {id: id, agencyId: userProfile.currentAgencyId() })
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };
}

import template from "./region-management-component.html";
export default { viewModel: RegionManagementViewModel, template: template };
