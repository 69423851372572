import './loadTractorMapStyles.css';
import userProfile from 'user-profile';
import dataModel from 'data-model';
import L from 'mapquest';
import config from 'config';

class MapSettings {
    constructor() {
        this.pxMapHeight = screen.height - 360 + "px";
        this.screenHeight = screen.height;
        this.pxMapHeight = screen.height - 360 + "px";
        this.element = document.getElementById('map');
        this.element.style.height = this.pxMapHeight;
        this.element.style.width = "90%";
    
        this.element2 = document.getElementById('map2');
        this.element2.style.height = this.pxMapHeight;
        this.element2.style.width = "90%";
    }
}

class LoadTractorMapPageViewModel {
    constructor() {
        L.mapquest.key = config.mapquestApiKey;
        var self = this;
        L.mapquest.key = config.mapquestApiKey;

        var settings = new MapSettings();

        var markersLayer = new L.LayerGroup();
        var markersLayer2 = new L.LayerGroup();
        var tractorObjects = [{}];
        var stateObjectsforLoadMap = [{}];
        var tempNumber;



        self.loadsOriginatingInUSA = ko.observable(0);
        self.loadsTerminatingInUSA = ko.observable(0);
        self.loadsOriginatingInCanada = ko.observable(0);
        self.loadsTerminatingInCanada = ko.observable(0);

        self.activeTractorsInUSA = ko.observable(0);
        self.activeTractorsInCanada = ko.observable(0);

        self.mapTypeChanged = function (toggle) {
            if (toggle) {
                self.GetTractors();
            }
            else {
                self.GetLoads();
            }
        };

        self.includeOrders = ko.observable(true);
        self.includeLoads = ko.observable(false);
        self.isOrderMapLoading = ko.observable(false);

        self.isOwnerOperator = userProfile.isOwnerOperator;

        self.movementTypes = ko.observableArray([{ label: 'Available', value: "AvailableStatus", checked: true },
                                                { label: "In Progress", value: "InProgressStatus", checked: true },
                                                { label: 'Covered', value: "CoveredStatus", checked: true },
                                                { label: 'Load', value: "LoadStatus", checked: true }]);

    
        //THESE FUNCTIONS OBSERVE CHANGES BASED ON WHETHER THE ORDERS / LOADS BOXES ARE CHECKED/UNCHECKED
        self.includeLoads.subscribe(function (newVal) {            
                self.updateOrderMap();          
        });
        self.includeOrders.subscribe(function (newVal) {   
                self.updateOrderMap();
        });

        self.onMovementTypeChecked = function () {
            self.refreshOrderMap();
            $("#MovementStatus").jqxDropDownList("close");
        };

        //UPDATE FUNCTION THAT IS CALLED WHEN ORDERS / LOADS BOXES ARE CHECKED/UNCHECKED
        self.updateOrderMap = function () {
            //CLEAR THE ALL OF THE CIRCLES ON THE MAP
            markersLayer2.clearLayers();
            //EMPTY THE ARRAY THAT HOLDS THE CIRCLE DATA
            stateObjectsforLoadMap = [{}];
            //RE-RERUN THE GETLOADS FUNCTION
            self.GetLoads();
        };

        //SETS THE ORDERMAP TO BE INITIALLY HIDDEN UPON PAGE LOAD
        self.orderMapVisible = ko.observable(false);


        //TOGGLE THE MAP DIVS
        self.toggleOrderMap = function (event, args) {         
            var $this = $(args.currentTarget);
            var isOrderMapToggled = $("#loadAndTractorMapOrderPanel").is(":visible");
            if (isOrderMapToggled == true) {
                //TURN HEADER TEXT AND GLYPHICON WHITE SLOWLY DUE TO DARK BACKGROUND WHEN DIV IS TOGGLED/CLOSED - UTILIZES JQUERY UI
                $("#orderMapHeading").animate({ color: "white" }, 1200)
                $("#tractorMapHeading").animate({ color: "black" }, 1200)

                $("#orderToggleIcon").find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "white" }, 1200)
                $("#tractorToggleIcon").find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "black" }, 1200)
            }
            else {
                $("#orderMapHeading").animate({ color: "black" }, 1200)
                $("#tractorMapHeading").animate({ color: "white" }, 1200)

                $("#orderToggleIcon").find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "black" }, 1200)
                $("#tractorToggleIcon").find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "white" }, 1200)
            }

            $("#loadAndTractorMapTractorPanel").animate({ height: 'toggle' }, "slow");
            $("#loadAndTractorMapOrderPanel").animate({ height: 'toggle' }, "slow");
        };

        self.toggleTractorMap = function (event, args) {

            var $this = $(args.currentTarget);
            var isTractorMapToggled = $("#loadAndTractorMapTractorPanel").is(":visible");
            if (isTractorMapToggled == true) {

                //TURN HEADER TEXT AND GLYPHICON WHITE SLOWLY DUE TO DARK BACKGROUND WHEN DIV IS TOGGLED/CLOSED - UTILIZES JQUERY UI

                $("#tractorMapHeading").animate({ color: "white" }, 1200)
                $("#orderMapHeading").animate({ color: "black" }, 1200)
                //$this.find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "white" }, 1000)
                $("#tractorToggleIcon").find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "white" }, 1200)
                $("#orderToggleIcon").find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "black" }, 1200)

            }
            else {
                $("#tractorMapHeading").animate({ color: "black" }, 1200)
                $("#orderMapHeading").animate({ color: "white" }, 1200)
                //$this.find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "black" }, 1000)
                $("#tractorToggleIcon").find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "black" }, 1200)
                $("#orderToggleIcon").find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "white" }, 1200)
            }

            $("#loadAndTractorMapOrderPanel").animate({ height: 'toggle' }, "slow");
            $("#loadAndTractorMapTractorPanel").animate({ height: 'toggle' }, "slow");

        };

       

        //HARD-CODE THE COORDINATES OF THE CENTERS OF US STATES / CANADIAN PROVINCES AND THE CORRESPONDING NAME AND ABBREVIATION FOR USE LATER IN GEOJSON OBJECTS
        var coordinatesByState = [
            {
                "state": "TX",
                "stateName": "Texas",
                "coordinates": [-97.5726778552863, 31.054094761674]
            },
            {
                "state": "KS",
                "stateName": "Kansas",
                "coordinates": [-96.7241298688663, 38.5266604196278]
            },
            {
                "state": "PA",
                "stateName": "Pennsylvania",
                "coordinates": [-77.2147913056525, 40.5904331266188]
            },
            {
                "state": "WI",
                "stateName": "Wisconsin",
                "coordinates": [-89.602282297043, 44.2536441330645]
            },
            {
                "state": "DE",
                "stateName": "Delaware",
                "coordinates": [-75.5071383734178, 39.3185218227848]
            },
            {
                "state": "IN",
                "stateName": "Indiana",
                "coordinates": [-86.2578229840963, 39.8503133807229]
            },
            {
                "state": "IL",
                "stateName": "Illinois",
                "coordinates": [-88.9855114581187, 40.3504857355025]
            },
            {
                "state": "NH",
                "stateName": "New Hampshire",
                "coordinates": [-71.5638991068548, 43.4524908810484]
            },
            {
                "state": "MD",
                "stateName": "Maryland",
                "coordinates": [-76.8003935960146, 39.0663934836956]
            },
            {
                "state": "CO",
                "stateName": "Colorado",
                "coordinates": [-105.30876423615, 39.0603656565097]
            },
            {
                "state": "DC",
                "stateName": "District of Columbia",
                "coordinates": [-77.0267990191304, 38.8973983478261]
            },
            {
                "state": "MI",
                "stateName": "Michigan",
                "coordinates": [-84.5330406630494, 43.3290198871405]
            },
            {
                "state": "VA",
                "stateName": "Virginia",
                "coordinates": [-78.1613470378751, 37.7672502606985]
            },
            {
                "state": "ME",
                "stateName": "Maine",
                "coordinates": [-69.3812555066667, 44.6942707688889]
            },
            {
                "state": "AK",
                "stateName": "Alaska",
                "coordinates": [-152.384146415617, 61.3184527355163]
            },
            {
                "state": "SC",
                "stateName": "South Carolina",
                "coordinates": [-80.9366611733332, 33.8505915161905]
            },
            {
                "state": "RI",
                "stateName": "Rhode Island",
                "coordinates": [-71.5117820695187, 41.6808928770054]
            },
            {
                "state": "WA",
                "stateName": "Washington",
                "coordinates": [-121.488688481358, 47.4016335599202]
            },
            {
                "state": "MA",
                "stateName": "Massachusetts",
                "coordinates": [-71.5299188827067, 42.2303679308271]
            },
            {
                "state": "NV",
                "stateName": "Nevada",
                "coordinates": [-117.061518688645, 38.3037585311356]
            },
            {
                "state": "NY",
                "stateName": "New York",
                "coordinates": [-74.9496514888251, 42.1639718643505]
            },
            {
                "state": "OH",
                "stateName": "Ohio",
                "coordinates": [-82.7794520101618, 40.3800855961611]
            },
            {
                "state": "SD",
                "stateName": "South Dakota",
                "coordinates": [-99.4319826170483, 44.3017987162854]
            },
            {
                "state": "CT",
                "stateName": "Connecticut",
                "coordinates": [-72.7546951327434, 41.5974852433629]
            },
            {
                "state": "LA",
                "stateName": "Louisianna",
                "coordinates": [-91.8713481133379, 31.1688656627506]
            },
            {
                "state": "NJ",
                "stateName": "New Jersey",
                "coordinates": [-74.5206023761874, 40.2993529297024]
            },
            {
                "state": "MO",
                "stateName": "Missouri",
                "coordinates": [-92.2873633060447, 38.4556617486911]
            },
            {
                "state": "CA",
                "stateName": "California",
                "coordinates": [-119.67822381933, 36.1134325860882]
            },
            {
                "state": "OR",
                "stateName": "Oregon",
                "coordinates": [-122.063009897902, 44.579348055944]
            },
            {
                "state": "MN",
                "stateName": "Minnesota",
                "coordinates": [-93.8995769574686, 45.6918785829875]
            },
            {
                "state": "MT",
                "stateName": "Montana",
                "coordinates": [-110.454351190299, 46.9219239794776]
            },
            {
                "state": "OK",
                "stateName": "Oklahoma",
                "coordinates": [-96.9236465856891, 35.5635262641343]
            },
            {
                "state": "FL",
                "stateName": "Florida",
                "coordinates": [-81.6608939146302, 27.759889560769]
            },
            {
                "state": "AR",
                "stateName": "Arkansas",
                "coordinates": [-92.3716548924242, 34.9730871545455]
            },
            {
                "state": "IA",
                "stateName": "Iowa",
                "coordinates": [-93.2086474872979, 42.0122041460739]
            },
            {
                "state": "VT",
                "stateName": "Vermont",
                "coordinates": [-72.7106888039215, 44.0458768148148]
            },
            {
                "state": "AL",
                "stateName": "Alabama",
                "coordinates": [-86.7922628284884, 32.8067882906977]
            },
            {
                "state": "AS",
                "stateName": "--",
                "coordinates": [-170.719577, 14.2412]
            },
            {
                "state": "ND",
                "stateName": "North Dakota",
                "coordinates": [-99.7840120055021, 47.5289102640991]
            },
            {
                "state": "ID",
                "stateName": "Idaho",
                "coordinates": [-114.473537049922, 44.2395678393136]
            },
            {
                "state": "GA",
                "stateName": "Georgia",
                "coordinates": [-83.6419030444298, 33.0370346783819]
            },
            {
                "state": "AZ",
                "stateName": "Arizona",
                "coordinates": [-111.42516273526, 33.764459283237]
            },
            {
                "state": "MS",
                "stateName": "Mississippi",
                "coordinates": [-89.6780970754338, 32.7439296675567]
            },
            {
                "state": "TN",
                "stateName": "Tennessee",
                "coordinates": [-86.6936106245847, 35.7502653920266]
            },
            {
                "state": "NC",
                "stateName": "North Carolina",
                "coordinates": [-79.804922591899, 35.6279971913924]
            },
            {
                "state": "NE",
                "stateName": "Nebraska",
                "coordinates": [-98.265987023305, 41.1340533644068]
            },
            {
                "state": "WV",
                "stateName": "West Virginia",
                "coordinates": [-80.9560510097451, 38.4912725284858]
            },
            {
                "state": "WY",
                "stateName": "Wyoming",
                "coordinates": [-107.296745585366, 42.7511217195122]
            },
            {
                "state": "NM",
                "stateName": "New Mexico",
                "coordinates": [-106.246779540204, 34.8445113624009]
            },
            {
                "state": "KY",
                "stateName": "Kentucky",
                "coordinates": [-84.6705408075023, 37.6681725844028]
            },
            {
                "state": "UT",
                "stateName": "Utah",
                "coordinates": [-111.859960308296, 40.1529592634529]
            },
            {
                "state": "AB",
                "stateName": "Alberta",
                "coordinates": [-113.672912211501, 52.3225092018097]
            },
            {
                "state": "BC",
                "stateName": "British Columbia",
                "coordinates": [-122.581220210291, 49.6478529346233]
            },
            {
                "state": "MB",
                "stateName": "Manitoba",
                "coordinates": [-97.5944923778542, 50.0730387878566]
            },
            {
                "state": "NB",
                "stateName": "New Brunswick",
                "coordinates": [-65.9441305726936, 46.2785266378973]
            },
            {
                "state": "NL",
                "stateName": "Newfoundland and Labrador",
                "coordinates": [-54.5342164859943, 48.1522364850698]
            },
            {
                "state": "NS",
                "stateName": "Nova Scotia",
                "coordinates": [-62.7078958263481, 45.2107891397157]
            },
            {
                "state": "NT",
                "stateName": "Northwest Territories",
                "coordinates": [-114.844358697674, 62.524082498062]
            },
            {
                "state": "NU",
                "stateName": "Nunavut",
                "coordinates": [-84.7461985517241, 67.3580457586207]
            },
            {
                "state": "ON",
                "stateName": "Ontario",
                "coordinates": [-79.7121005975489, 44.0032799751825]
            },
            {
                "state": "PE",
                "stateName": "Prince Edward Island",
                "coordinates": [-63.3351106675733, 46.3002125880932]
            },
            {
                "state": "QC",
                "stateName": "Quebec",
                "coordinates": [-72.8381661376319, 46.1711399014573]
            },
            {
                "state": "SK",
                "stateName": "Saskatchewan",
                "coordinates": [-105.659880795685, 51.2940379692387]
            },
            {
                "state": "YT",
                "stateName": "Yukon",
                "coordinates": [-135.09425032228, 60.7545130476683]
            },
        ]


        //CONSTRUCT TWO MAP VARIABLES FOR TWO SEPARATE MAPS, CENTER POINT ESTABLISHED FOR LOADING
       
        
        var initZoom;
        if (settings.screenHeight >= 1000) {
            initZoom = 5;
        }
        else if (settings.screenHeight < 1000) {
            initZoom = 4;
        }
       
        var map = L.map('map', {
            layers: L.mapquest.tileLayer('map'),
            center: [40.64701, -95.39425],
            zoom: initZoom,
           
        });

        // var loadingControl = L.Control.loading({
        //     position: 'bottomright',
        //     separate: true
        // });

        // map.addControl(loadingControl);

        var map2 = L.map('map2', {
            layers: L.mapquest.tileLayer('map'),
            center: [40.64701, -95.39425],
            zoom: initZoom,
        });

        // var loadingControl2 = L.Control.loading({
        //     position: 'bottomleft',
        //     separate: true
        // });

        // map2.addControl(loadingControl2);

        //LAYERS FOR MAPS - THE LAYERS DETERMINE THE TYPE OF MAP IMAGE

        L.tileLayer('https://api.mapbox.com/styles/v1/ricktig/ciyxolqi0000g2smrgyu0zbpl/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoicmlja3RpZyIsImEiOiJjaXl1dzR6bXEwMDVqMnd0NHdqYzd1MHl1In0.gtV83cx0R12qZPMNxUv1KQ').addTo(map2);


        L.tileLayer('https://api.mapbox.com/styles/v1/ricktig/ciyxolqi0000g2smrgyu0zbpl/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoicmlja3RpZyIsImEiOiJjaXl1dzR6bXEwMDVqMnd0NHdqYzd1MHl1In0.gtV83cx0R12qZPMNxUv1KQ').addTo(map);


        //OPTIONAL TILE LAYERS FOR MAP

        //View default OSM Basemap
        /*
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
        }).addTo(map);
        */

        //View National Geographic World Map
        //L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}', {
        //    attribution: '2016 &copy; ArcGIS REST API Services | <a href="https://www.arcgis.com/home/item.html?id=b9b1b422198944fbbd5250b3241691b6" target="_blank">Esri + National Geographic</a>'
        //}).addTo(map);

        //View Esri World Imagery
        /*
        L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          attribution: '2016 &copy; ArcGIS REST API Services | <a href="https://www.arcgis.com/home/item.html?id=10df2279f9684e4a9f6a7f08febac2a9" target="_blank">Esri World Imagery</a>'
        }).addTo(map);*/     
        
        //L.tileLayer('https://api.mapbox.com/styles/v1/ricktig/ciyxolqi0000g2smrgyu0zbpl/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1Ijoicmlja3RpZyIsImEiOiJjaXl1dzR6bXEwMDVqMnd0NHdqYzd1MHl1In0.gtV83cx0R12qZPMNxUv1KQ').addTo(map2point0);

        //Other
        //L.tileLayer('http://korona.geog.uni-heidelberg.de/tiles/roadsg/x={x}&y={y}&z={z}', {
        //    attribution: '2016 &copy; <a href="https://www.arcgis.com/home/item.html?id=3b93337983e9436f8db950e38a8629af" target="_blank">Esri Word Street Map</a> ArcGIS REST Services'
        //}).addTo(map);

   
        //BUILD THE FIRST LEGEND FOR THE ORDERMAP
        var legend = L.control({ position: 'bottomright' });


        //COLOR FUNCTION TO SET THE COLORS IN THE LEGEND
        function getColor(d) {
            return d > 1000 ? '#73ba2c' :
                   d > 500 ? '#80cf30' :
                   d > 200 ? '#8cd345' :
                   d > 100 ? '#99d85a' :
                   d > 50 ? '#a6dd6e' :
                   d > 20 ? '#b3e283' :
                   d > 10 ? '#bfe797' :
                              '#ccecac';
        }

        legend.onAdd = function (map2) {  
            var div = L.DomUtil.create('div', 'info legend'),
                grades = [0, 10, 20, 50, 100, 200, 500, 1000],
                labels = [];

            div.innerHTML += '<strong>No. of Loads/Orders</strong><br><br>'

            //LOOP THROUGH OUR DENSITY INTERVALS AND GENERATE A LABEL WITH A COLORED SQUAURE FOR EACH INTERVAL         
            for (var i = 0; i < grades.length; i++) {
                var from = grades[i],
                    to = grades[i + 1] - 1;

                div.innerHTML +=
                    '<i style="background:' + getColor(from + 1) + '"></i>'+
                    from + (to ? '&ndash;' + to + '<br>' : '+');
                }    

            return div;
        };
        legend.addTo(map2);


        //BUILD THE SECOND LEGEND FOR THE ORDERMAP
        var legend2 = L.control({ position: 'bottomleft' });

        legend2.onAdd = function (map2) {         
            var div2 = L.DomUtil.create('div', 'info legend'),
                    grades = [0, 1],
                    labels = [];

            div2.innerHTML +=
                '<i style="background:blue"></i>' +
                'Originating Loads<br /><br />' +
                '<i style="background:black"></i>' +
                'Terminating Loads<br>';      

            return div2;
        };

        legend2.addTo(map2);


        var legend3 = L.control({ position: 'bottomleft' });

        legend3.onAdd = function (map) {
            var div3 = L.DomUtil.create('div', 'info legend'),
                    grades = [0, 1],
                    labels = [];

            div3.innerHTML +=
                '<i class="legendcircle"></i>' +
                'No. of Tractors<br />';

            return div3;
        };

        legend3.addTo(map);


        //ZOOM FUNCTIONS FOR MAP2 - THIS CHANGES THE SIZE OF THE CIRCLES AS THE USER ZOOMS IN AND OUT
        
        function zoomClass(currentClass, currentZoom) {   
        
            if (currentClass.substr(currentClass.length -1).match(/^(a|b|d|f|g|h|j)$/)) {
                currentClass = currentClass.slice(0, -1);
            }      
    
    
            if (currentZoom <= 2) {                             
                    return currentClass + 'a'
                }         
            else if (currentZoom == 3) {   
                    return currentClass + 'b'
                }         
            else if (currentZoom == 4) {                
                    return currentClass + 'd'
                }  
            else if (currentZoom == 5) {           
                    return currentClass + 'f'
                }  
            else if (currentZoom == 6) {                
                    return currentClass + 'g'
                }       
            else if (currentZoom == 7) {           
                    return currentClass + 'h'
                }       
            else if (currentZoom >= 8) {             
                    return currentClass + 'j'            
            }
        }
           

        map2.on('zoomend', function () {

            var currentZoom = map2.getZoom();        
            if (currentZoom >= 8) {

                markersLayer2.eachLayer(function (layer) {
                    var currentClass = layer.options.icon.options.className;
                    var thisIcon = new L.divIcon({                       
                        className: zoomClass(currentClass, currentZoom),
                        html: "<div style='text-align:center;margin-top:6px'><b><span style='color:blue'>" + layer.feature.properties.OriginationCount + "</span> / " + layer.feature.properties.DestinationCount + "</span></b></div>",
                        iconSize: [180, 180],
                    });

                    return layer.setIcon(thisIcon);
                });
            }

            else if (currentZoom == 7) {

                markersLayer2.eachLayer(function (layer) {
                    var currentClass = layer.options.icon.options.className;
                    var thisIcon = new L.divIcon({
                        className: zoomClass(currentClass, currentZoom),
                        html: "<div style='text-align:center;margin-top:6px'><b><span style='color:blue'>" + layer.feature.properties.OriginationCount + "</span> / " + layer.feature.properties.DestinationCount + "</span></b></div>",
                        iconSize: [135, 135],
                    });

                    return layer.setIcon(thisIcon);
                });
            }

            else if (currentZoom == 6) {

                markersLayer2.eachLayer(function (layer) {
                    var currentClass = layer.options.icon.options.className;
                    var thisIcon = new L.divIcon({
                        className: zoomClass(currentClass, currentZoom),
                        html: "<div style='text-align:center;margin-top:6px'><b><span style='color:blue'>" + layer.feature.properties.OriginationCount + "</span> / " + layer.feature.properties.DestinationCount + "</span></b></div>",
                        iconSize: [75, 75],
                    });

                    return layer.setIcon(thisIcon);
                });
            }

            else if (currentZoom == 5) {
           
                markersLayer2.eachLayer(function (layer) {
                    var currentClass = layer.options.icon.options.className;
                    var thisIcon = new L.divIcon({                        
                        className: zoomClass(currentClass, currentZoom),
                        html: "<div style='text-align:center;margin-top:6px'><b><span style='color:blue'>" + layer.feature.properties.OriginationCount + "</span> / " + layer.feature.properties.DestinationCount + "</span></b></div>",
                        iconSize: [45, 45],
                    });

                    return layer.setIcon(thisIcon);
                });
            }

            else if (currentZoom == 4) {

                markersLayer2.eachLayer(function (layer) {
                    var currentClass = layer.options.icon.options.className;
                    var thisIcon = new L.divIcon({
                        className: zoomClass(currentClass, currentZoom),                        
                        html: "<div style='text-align:center;margin-top:4px'><b><span style='color:blue; font-size:8px'>" + layer.feature.properties.OriginationCount + "</span><span style='font-size: 8px'> / " + layer.feature.properties.DestinationCount + "</span></b></div>",
                        iconSize: [35, 35],
                    });

                    return layer.setIcon(thisIcon);
                });
            }

            else if (currentZoom == 3) {

                markersLayer2.eachLayer(function (layer) {
                    var currentClass = layer.options.icon.options.className;
                    var thisIcon = new L.divIcon({
                        className: zoomClass(currentClass, currentZoom),
                        html: "<div style='text-align:center;margin-top:4px'><b><span style='color:blue; font-size:8px'>" + layer.feature.properties.OriginationCount + "</span><span style='font-size: 8px'> / " + layer.feature.properties.DestinationCount + "</span></b></div>",
                        iconSize: [15, 15],
                    });

                    return layer.setIcon(thisIcon);
                });
            }

            else if (currentZoom <= 2) {

                markersLayer2.eachLayer(function (layer) {
                    var currentClass = layer.options.icon.options.className;
                    var thisIcon = new L.divIcon({
                        className: zoomClass(currentClass, currentZoom),
                        html: "<div style='text-align:center;margin-top:4px'><b><span style='color:blue; font-size:8px'>" + layer.feature.properties.OriginationCount + "</span><span style='font-size: 8px'> / " + layer.feature.properties.DestinationCount + "</span></b></div>",
                        iconSize: [5, 5],
                    });

                    return layer.setIcon(thisIcon);
                });
            }
        });

        //BUILD THE DATA FROM THE CALLBACK INTO THE CIRCLES FOR THE TRACTORMAP
        var tractorMapLayer = L.geoJson(null, {            
            pointToLayer: function (feature, latlng) {      
                tempNumber = feature.properties.Count;     
                return L.marker(latlng, { icon: tractorMapCircles("circle", tempNumber) });
            },
            onEachFeature: onEachFeature
        });
        
        var initHTML;
        var initIconSize;
        if (initZoom == 4) {
            initIconSize = [35, 35];       
        }
        else {
            initIconSize = [45, 45];
        }

        //CREATE THE CIRCLES ON THE TRACTORMAP
        var tractorMapCircles = function (labelClass, labelText) {        
            return L.divIcon({
                className: labelClass,
                html: "<div style='text-align:center;margin-top:6px'><span'>" + labelText + "</span></div>",
                //iconUrl: 'https://pbs.twimg.com/profile_images/679734682741690368/ZKBnlpVt_400x400.jpg',
                iconSize: initIconSize,
                //popupAnchor: [0, -15]
            })
        }

        //CREATE POPUP FOR EACH CIRCLE ON THE TRACTORMAP
        function onEachFeature(feature, layer) {
            var popupContent = "<p>" + feature.properties.stateName + "<br /><span><a href='OrderPlanning?mapstate=" + feature.properties.State + "' target='_blank' style='color:blue;'>Click to view</a></span></p>"

            if (feature.properties && feature.properties.popupContent) {
                popupContent += feature.properties.popupContent;
            }

            //BIND THE NEW POPUP DATA TO THE CURRENT CIRCLE AND ADD THE CIRCLE TO THE GROUP OF CIRCLES REFERRED TO AS THIS MARKERSLAYER
            layer.bindPopup(popupContent);
            markersLayer.addLayer(layer);
        }      
        markersLayer.addTo(map);
     
        


        //BUILD THE DATA FROM THE CALLBACK INTO THE CIRCLES FOR THE ORDERMAP
        var orderMapLayer = L.geoJson(null, {
        
            pointToLayer: function (feature, latlng) {
                //GET THE ORIGINATION AND DESTINATION COUNTS FOR THE STATES ORDERS AND THE TOTAL COUNT OF THE LOADS
                var OriginNumber = feature.properties.OriginationCount,
                    DestinationNumber = feature.properties.DestinationCount,
                    oNum = parseInt(OriginNumber),
                    dNum = parseInt(DestinationNumber),
                    totalNum = oNum + dNum;

                //DETERMINE THE COLOR (CLASS) OF EACH CIRCLE BASED ON THE TOTAL NUMBER OF LOADS
                var circleColor = changeCircleColor(totalNum);

                return L.marker(latlng, { icon: orderMapCircles(circleColor, OriginNumber, DestinationNumber) });
            },

            onEachFeature: onEachFeature2
        });


        //FUNCTION TO CHANGE THE COLOR OF THE CIRCLES (CHANGE THEIR CLASS NAME) DYNAMICALLY BASED ON THEIR TOTAL NUMBER OF LOADS
        function changeCircleColor(number) {
            return number > 1000 ? 'circle1' :
                       number > 500 ? 'circle2' :
                       number > 200 ? 'circle3' :
                       number > 100 ? 'circle4' :
                       number > 50 ? 'circle5' :
                       number > 20 ? 'circle6' :
                       number > 10 ? 'circle7' :
                                  'circle8';
        }


        //VARIABLE TO DEFINE THE CIRCLES ON THE ORDERMAP
        var orderMapCircles = function (circleColor, originNumber, destinationNumber) {
            if (initZoom == 4) {
                initHTML = "<div style='text-align:center;margin-top:4px'><b><span style='color:blue; font-size:8px'>" + originNumber + "</span><span style='font-size: 8px'> / " + destinationNumber + "</span></b></div>";
            }
            else {
                initHTML = "<div style='text-align:center;margin-top:4px'><b><span style='color:blue'>" + originNumber + "</span> / " + destinationNumber + "</span></b></div>";
            }
            return L.divIcon({
                className: circleColor,
                html: initHTML,
                //iconUrl: 'https://pbs.twimg.com/profile_images/679734682741690368/ZKBnlpVt_400x400.jpg',
                iconSize: initIconSize,
                //popupAnchor: [0, -15]
            })
        }


        //CREATE POPUP FOR EACH CIRCLE ON THE ORDERMAP
        function onEachFeature2(feature, layer) {
            var popupContent = "<p><div style='text-align:center'>" + feature.properties.stateName + "</div><br /><span><a href='OrderPlanning?orderstate=" + feature.properties.State + "&includeLoads=" + self.includeLoads() + "&includeOrders=" + self.includeOrders() + "\' target=\'_blank\' style=\'color:blue; \'>" + feature.properties.OriginationCount + " Incoming Loads/Orders</a></span><br /><span><a href='OrderPlanning?xorderstate=" + feature.properties.State + "&includeLoads=" + self.includeLoads() + "&includeOrders=" + self.includeOrders() + "\' target=\'_blank\' style=\'color:blue; \'>" + feature.properties.DestinationCount + " Outgoing Loads/Orders</a></span></p>"

            if (feature.properties && feature.properties.popupContent) {
                popupContent += feature.properties.popupContent;
            }

            layer.bindPopup(popupContent);
            markersLayer2.addLayer(layer);
        }
       
        markersLayer2.addTo(map2);

     
        self.orderloadInfo = {};
        self.orderloadInfo.includeOrders = self.includeOrders();
        self.orderloadInfo.includeLoads = self.includeLoads();
        self.orderloadInfo.movementStatus = ko.utils.arrayMap(ko.utils.arrayFilter(self.movementTypes(), function (item) {   
            return item.checked;
        }), function (item) {
            return item.value;
        });

        self.USLoadOriginationCount = ko.observable(0);
        self.USLoadDestinationCount = ko.observable(0);
        self.CanadaLoadDestinationCount = ko.observable(0);
        self.CanadaLoadOriginationCount = ko.observable(0);   
        var agencyId = userProfile.currentAgencyId();

        //GET LOAD COUNTS FOR ORDER/LOAD MAP
        self.GetLoads = function () {
            map2.fire('dataloading');
            var orderloadInfo = {};
            orderloadInfo.includeOrders = self.includeOrders();
            orderloadInfo.includeLoads = self.includeLoads();
            orderloadInfo.movementStatus = ko.utils.arrayMap(ko.utils.arrayFilter(self.movementTypes(), function (item) {           
                return item.checked;
            }), function (item) {
                return item.value;
            });   
            dataModel.ajaxRequest("LoadAndTractorMap/GetLoadCountsPerState",'post', orderloadInfo)
                  .done(function (data, textStatus, jqXHR) {
                    
                      //BUILD THE EMPTY GEOJSON OBJECT
                      if (data.length != 0) {                     
                      for (var i = 0; i < data.records.length; i++) {
                          for (var j = 0; j < coordinatesByState.length; j++) {
                              if (data.records[i].state == coordinatesByState[j].state) {
                                  var stateObject = {
                                      "type": "FeatureCollection",
                                      "features": [{
                                          "type": "Feature",
                                          "properties": {
                                              "Country": null,
                                              "stateName": null,
                                              "Address": null,
                                              "City": null,
                                              "State": null,
                                              "OriginationCount": null,
                                              "DestinationCount": null
                                          },
                                          "geometry": {
                                              "type": "Point",
                                              "coordinates": []
                                          }

                                      }, ]
                                  }

                                  //ASSIGN VALUES TO GEOJSON OBJECT
                                  stateObject.features[0].properties.OriginationCount = data.records[i].originationCount;
                                  stateObject.features[0].properties.DestinationCount = data.records[i].destinationCount;
                                  stateObject.features[0].properties.State = data.records[i].state;
                                  stateObject.features[0].geometry.coordinates = coordinatesByState[j].coordinates;
                                  stateObject.features[0].properties.stateName = coordinatesByState[j].stateName;
                            
                                  stateObjectsforLoadMap.push(stateObject)

                              }                        
                          }                  
                      }                  

                      self.loadsOriginatingInUSA(data.statistics.usOrigins);
                      self.loadsTerminatingInUSA(data.statistics.usDestinations);
                      self.loadsOriginatingInCanada(data.statistics.caOrigins);
                      self.loadsTerminatingInCanada(data.statistics.caDestinations);

                          //ADD ALL OF THE CIRCLES WITH COUNTS / LAT/LNG DATA TO LOAD MAP
                      orderMapLayer.addData(stateObjectsforLoadMap);                     
                      map2.fire('dataload');
                      }
                      else {
                          self.loadsOriginatingInUSA(0);
                          self.loadsTerminatingInUSA(0);
                          self.loadsOriginatingInCanada(0);
                          self.loadsTerminatingInCanada(0);                         
                      }
                  })
                  .fail(function (jqXHR, textStatus, errorThrown) {
                      alert('Connection to API failed.');
                      map2.fire('dataload');
                  });          

        };

        
        //GET TRACTOR COUNTS FOR TRACTOR MAP
        self.GetTractors = function () {           
            map.fire('dataloading');
            dataModel.ajaxRequest("LoadAndTractorMap/GetTractorsCountsPerState/" + agencyId, 'GET', null, true)
              .done(function (data, textStatus, jqXHR) {           
            
                  //BUILD THE EMPTY GEOJSON OBJECT
                  if (data.length != 0) {
                      for (var i = 0; i < data.tractors.length; i++) {
                          for (var j = 0; j < coordinatesByState.length; j++) {
                              if (data.tractors[i].state == coordinatesByState[j].state) {
                                  var tractorObject = {

                                      "type": "FeatureCollection",
                                      "features": [{
                                          "type": "Feature",
                                          "properties": {
                                              "stateName": null,
                                              "Address": null,
                                              "City": null,
                                              "State": null,
                                              "Zipcode": null,
                                              "Count": null
                                          },
                                          "geometry": {
                                              "type": "Point",
                                              "coordinates": []
                                          }

                                      }, ]
                                  }

                                  tractorObject.features[0].properties.Count = data.tractors[i].count;
                                  tractorObject.features[0].properties.State = data.tractors[i].state;
                                  tractorObject.features[0].geometry.coordinates = coordinatesByState[j].coordinates;
                                  tractorObject.features[0].properties.stateName = coordinatesByState[j].stateName;
                       
                                  tractorObjects.push(tractorObject)


                              };
                          }
                      }

                      //ASSIGN COUNTS FOR THE ACTIVE TRACTORS IN US/CANADA DATA IN MAP HEADER
                      self.activeTractorsInUSA(data.statistics.usTractors);
                      self.activeTractorsInCanada(data.statistics.caTractors);
                  }
                  else {
                      self.activeTractorsInUSA(0);
                      self.activeTractorsInCanada(0);
                  }                     
                    //ADD ALL OF THE CIRCLES WITH COUNTS / LAT/LNG TO THE TRACTOR MAP
                  tractorMapLayer.addData(tractorObjects);
                  map.fire('dataload');
                
              })
              .fail(function (jqXHR, textStatus, errorThrown) {
                  alert('Connection to API failed.');
                  map.fire('dataload');
              });           
        }


        //ON PAGE LOAD, CALL BOTH GETLOADS AND GETTRACTORS FUNCTIONS
        self.GetLoads();
        self.GetTractors();
    }
}

import template from './load-tractor-map-page.html';
export default { viewModel: LoadTractorMapPageViewModel, template: template }