import router from 'router';
import ko from 'knockout';

class QuickOrderSearchComponent {
    constructor(params) {
        params = params || {};

        this.selectedQuickOrderSearchValue = ko.observable();
    }

    checkQuickOrderSearchKey(data, event) {
        let ENTERKEY = 13;
        if (event.keyCode == ENTERKEY && this.selectedQuickOrderSearchValue()) {
            router.navigate('/NewOrder/' + this.selectedQuickOrderSearchValue().trim(), true)

        }
    }
}

import template from './quick-order-search-component.html';
export default { viewModel: QuickOrderSearchComponent, template: template }