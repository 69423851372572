import dataModel from 'data-model';
import config from "config";
import { setBtnEnabledState, setBtnDisabledState } from 'global-functions';
//import ko from 'knockout';

class ForgotPasswordPageViewModel {
    constructor(x) {
        
        this.errors = ko.observable("");
        this.username = ko.observable("").extend({ required: true });
        this.successMsg = ko.observable("");

        this.cancelAction = x.cancelAction || function() { window.location.href = "/"; };

        this.isProcessing = ko.observable(false); // prevent multiple submit btn clicks
    }

    handleSubmit = async (koContext, e) => {
        if(this.isProcessing()) return false;
        
        this.errors("");
        
        if(this.username().length == 0) {
            this.errors("Username is required.");
            return false;
        }

        this.isProcessing(true);
        setBtnDisabledState(e, "Sending...");
        var result = await this.http_sendPasswordReset(this.username()).catch((err) => console.log(err)); 
            
        if(result && result.error) { 
            this.errors(result.error);
        }
        else if(result && !result.error) {
            this.successMsg(result.success || "Password reset has been sent to the email address associated with your username.");
            this.username("");
        }
        
        setBtnEnabledState(e, "Submit");    
        this.isProcessing(false);
    }

    handleCancel = () => {
        this.errors("");
        this.successMsg("");
        this.username("");

        this.cancelAction();
    }

    http_sendPasswordReset = (username) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("User/PasswordRecovery", "POST", username, true).done((response) => resolve(response))
            .fail((error) => reject(error));
        });
    }
}

import template from './forgot-password-page.html';
export default { viewModel: ForgotPasswordPageViewModel, template: template }