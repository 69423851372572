import userProfile from "user-profile";
import dataModel from "data-model";
import { setBtnDisabledState, setBtnEnabledState } from "global-functions";

class LoadboardOptionsViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};
        this.isProcessing = ko.observable(false);

        this.shareOrders = this.parentModel.shareOrders;
        this.shareTractors = this.parentModel.shareTractors;
        this.restrictLoadboardDrivers =
            this.parentModel.restrictLoadboardDrivers;
        this.canEditLoadboardOptions = this.parentModel.canEditLoadboardOptions;
    }

    handleSaveLoadboardOptions = (data, event) => {
        this.doHandleSaveLoadboardOptions(data, event);
    };

    async doHandleSaveLoadboardOptions(data, event) {
        data = {
            shareOrders: this.shareOrders(),
            shareTractors: this.shareTractors(),
            restrictLoadboardDrivers: this.restrictLoadboardDrivers(),
            agencyId: userProfile.currentAgencyId(),
        };

        setBtnDisabledState(event, "Saving...");
        await this.http_sendSaveLoadboardOptions(data).catch((err) =>
            console.error(err)
        );
        setBtnEnabledState(event, "Save");
    }

    http_sendSaveLoadboardOptions = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/SaveLoadboardOptions", "put", payload)
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };
}

import template from "./loadboard-options-component.html";
export default { viewModel: LoadboardOptionsViewModel, template: template };
