import dataModel from 'data-model';
import template from './order-entry-container-tracking-component.html';
import userProfile from 'user-profile';

class TrackingModel {
    constructor(container, orderId) {
        this.orderExternalId = orderId;
        this.loaded = ko.observable();
        this.loaded.subscribe((value) => {
            if(value.value) {
                this.loaded(value.value)
            } else {
                this.loaded(value);
            }
        })
        this.container = ko.observable($('#txt_Container').val());
        this.chassis = ko.observable($('#txt_Chassis').val());
        this.orderNumber = ko.observable(this.orderExternalId);
        this.outDate = ko.observable();
        this.inDate = ko.observable();
        this.containerReturned = ko.observable(false);
        this.id = ko.observable();
        this.showSaveButton = ko.observable(false);
        this.locationExternalId = ko.observable(this.locationExternalId);
    }

    valueFillInputs = (row) => {
        this.showSaveButton(true);
        this.loaded(row.loaded);
        this.container(row.container);
        this.chassis(row.chassis);
        this.orderNumber(row.orderExternalId);
        this.outDate(row.outgate);
        this.inDate(row.ingate);
        this.containerReturned(row.chassisReturned);
        this.id(row.id);
        this.locationExternalId(row.locationExternalId);
    }

    addEquipment = () => {
        var data = {
            loaded: this.loaded(),
            container: this.container(),
            chassis: this.chassis(),
            orderExternalId: this.orderNumber(),
            outgate: this.outDate(),
            ingate: this.inDate(),
            chassisReturned: this.containerReturned(),
            locationExternalId: this.locationExternalId(),
            id: this.id()
        };
        dataModel.ajaxRequest('Order/AddTracking', 'POST', data).done(() => {
            this.refreshTracking();
        });
    }

    updateTracking = () => {
        var data = {
            loaded: this.loaded(),
            container: this.container(),
            chassis: this.chassis(),
            orderExternalId: this.orderNumber(),
            outgate: this.outDate(),
            ingate: this.inDate(),
            chassisReturned: this.containerReturned(),
            locationExternalId: this.locationExternalId(),
            id: this.id()
        };
        this.showSaveButton(false);
        dataModel.ajaxRequest('Order/UpdateTracking', 'POST', data).done(() => {
            this.refreshTracking();
        });
    }

    deleteTracking = () => {
        var data = {
            loaded: this.loaded(),
            container: this.container(),
            chassis: this.chassis(),
            orderExternalId: this.orderNumber(),
            outgate: this.outDate(),
            ingate: this.inDate(),
            chassisReturned: this.containerReturned(),
            locationExternalId: this.locationExternalId(),
            id: this.id()
        };
        this.showSaveButton(false);
        dataModel.ajaxRequest('Order/deleteTracking', 'POST', data).done(() => {
            this.refreshTracking();
        });
    }

    cancel = () => {
        $('.close').click();
    }

    startTracking = () => {
        var placeHolderFilterWidget = function (column, columnElement, widget) {
            widget.jqxInput({
                placeHolder: "Type Here..."
            });
        };

        var data = {
            container: this.container(),
            chassis: this.chassis(),
            orderExternalId: this.orderNumber(),
            agencyId: userProfile.currentAgencyId()
        };
        dataModel.ajaxRequest('Order/RefreshTracking', 'POST', data).done((responseData) => {
            var boolCellsRenderer = (row, columnfield, value, defaulthtml) => {
                var strVal = value.toString();
                var _default = $(defaulthtml)[0];
                var html = '';

                _default.textContent = strVal[0] + strVal.slice(1);
                html = _default.outerHTML;

                return html;
            };
            var source = {
                localdata: responseData,
                datatype: 'array',
                id: 'id',
                datafields: [
                    { name: 'id', type: 'int' },
                    { name: 'orderExternalId', type: 'string' },
                    { name: 'loaded', type: 'bool' },
                    { name: 'container', type: 'string' },
                    { name: 'chassis', type: 'string' },
                    { name: 'chassisReturned', type: 'bool' },
                    { name: 'locationExternalId', type: 'string' },
                    { name: 'outgate', type: 'date' },
                    { name: 'ingate', type: 'date' },
                ]
            };
            var dataAdapter = dataModel.getDataAdapter(source);
            $('#jqxTracking').jqxGrid({
                source: dataAdapter,
                width: '100%',
                selectionmode: 'single',
                pageable: true,
                sortable: true,
                filterable: true,
                columnsmenu: true,
                showfilterrow: false,
                autoheight: true,
                columnsresize: true,
                columns: [
                    {
                        text: '',
                        columntype: 'number',
                        datafield: 'id',
                        hidden: true
                    },
                    {
                        text: '',
                        columntype: 'button',
                        filterable: false,
                        sortable: false,
                        pinned: true,
                        menu: false,
                        resizable: false,
                        width: 35,
                        buttonclick: (row) => {
                            row = $('#jqxTracking').jqxGrid('getrowdata', row);
                            this.valueFillInputs(row);
                        },
                        cellsrenderer: () =>{
                            return 'Edit';
                        }
                    },
                    {
                        text: 'Order #',
                        datafield: 'orderExternalId',
                        width: 75
                    },
                    {
                        text: 'Loaded',
                        datafield: 'loaded',
                        width: 55,
                        cellsrenderer: boolCellsRenderer
                    },
                    {
                        text: 'Container',
                        datafield: 'container',
                        width: 100
                    },
                    {
                        text: 'Chassis',
                        datafield: 'chassis',
                        width: 100
                    },
                    {
                        text: 'Chassis Returned',
                        datafield: 'chassisReturned',
                        width: 120,
                        cellsrenderer: boolCellsRenderer
                    },
                    {
                        text: 'Outgate',
                        datafield: 'outgate',
                        columntype: 'datetimeinput',
                        cellsformat: 'MM/dd/yyyy HH:mm',
                        width: 150
                    },
                    {
                        text: 'Ingate',
                        datafield: 'ingate',
                        columntype: 'datetimeinput',
                        cellsformat: 'MM/dd/yyyy HH:mm',
                        width: 150
                    },
                    {
                        text: 'Location', datafield: 'locationExternalId', width: 100, createfilterwidget: placeHolderFilterWidget,
                        cellsrenderer: (row, columnfield, value, defaultHTML) => {
                            if (defaultHTML != null) {
                                var cell = $(defaultHTML);
                                cell.html("<a href=\"../Locations?locationId=" + value + "\" target=\"_blank\"  style=\"color:blue; \"  >" + value + "</a>");
                                return cell[0].outerHTML;
                            }
                            return defaultHTML;
                        }
                    }
                ]
            });
        });
    }
    refreshTracking = () => {
        var data = {
            container: this.container(),
            chassis: this.chassis(),
            orderExternalId: this.orderNumber(),
            agencyId: userProfile.agencyId
        };
        dataModel.ajaxRequest('Order/RefreshTracking', 'POST', data).done((responseData) => {

            var source = {
                localdata: responseData,
                datatype: 'array',
                id: 'id',
                datafields: [
                    { name: 'id', type: 'int' },
                    { name: 'orderExternalId', type: 'string' },
                    { name: 'loaded', type: 'bool' },
                    { name: 'container', type: 'string' },
                    { name: 'chassis', type: 'string' },
                    { name: 'chassisReturned', type: 'bool' },
                    { name: 'outgate', type: 'date' },
                    { name: 'ingate', type: 'date' },
                    { name: 'locationExternalId', type: 'string' },

                ]
            };
            var dataAdapter = dataModel.getDataAdapter(source);
                $("#jqxTracking").jqxGrid({ source: dataAdapter });
        });
    }
}

var OrderEntryContainerTrackingComponent = {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            //Don't do anything.  Use the object created in the main OrderEntry page.
        }
    }, template: template
}

export { TrackingModel }

export default OrderEntryContainerTrackingComponent