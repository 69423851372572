import dataModel from 'data-model';
import userProfile from 'user-profile';
import dayjs from 'dayjs';
    
    
// Page Data
var CarrierRates = ko.observable({
    Store: { // Object to hold global/central data for models
        CarrierRateModel: ko.observable(),
        CarrierLaneRateModel: ko.observable(),
        CarrierRateCommentModel: ko.observable(),
        CarrierLOHRateModel: ko.observable()
    },
    PageViewModel: ko.observable(),
});

// Carrier Rates ViewModel (parent atm is rates.js)
class CarrierRatesVM {
    constructor($componentDataParams) {
        $componentDataParams = $componentDataParams || {};

        CarrierRates({ ...CarrierRates(), ...$componentDataParams.$data });
        CarrierRates().Store.CarrierRateModel(new KOCarrierRateModel());
        
        var vmObj = {
            CarrierRateModel: CarrierRates().Store.CarrierRateModel,
            Errors: ko.observableArray([]),
            InfoMsg: ko.observable(),
            isProcessing: ko.observable(false),
            UX: function () {
                return {};
            },
            Methods: function () {
                var vm = this;

                return {
                    AddNew: function () {
                        vm.Errors.removeAll();
                        vm.InfoMsg(null);

                        CarrierRates().Store.CarrierRateModel(new KOCarrierRateModel());
                        vm.Methods().LoadChildModules();
                    },
                    LoadCarrierRate: function (carrierRateId) {

                        CarrierRates().isLoading(true);

                        $('#carrierRatesBottomContent a[href="#laneRatePanel"]').tab('show');

                        vm.HTTP.GetCarrierRateAsync(carrierRateId).then(function (carrierRateData) {
                            CarrierRates().isLoading(false);

                            if (carrierRateData) {
                                CarrierRates().Store.CarrierRateModel(new KOCarrierRateModel(carrierRateData));

                                vm.Methods().LoadChildModules();
                            }

                        }).catch(function (err) { vm.isProcessing(false) });
                    },
                    Save: function () {
                        vm.$ChildModels.CarrierRatesEntryVM().Methods().Save();
                    },
                    InitNewCarrierRate: function () {
                        return vm.$ChildModels.CarrierRatesEntryVM().Methods().Save();
                    },
                    DeleteRate: function () {
                        if (!vm.CarrierRateModel().Id()) { return false; }

                        CarrierRates().GetConfirmation({
                            message: "Are you sure you want to delete?",
                        }).then(function (x) {
                            if (x) {
                                vm.HTTP.DeleteCarrierRateAsync(vm.CarrierRateModel().Id()).then(function (isSuccess) {
                                    if (isSuccess) {
                                        vm.Methods().AddNew();

                                        vm.InfoMsg("Carrier Rate Deleted Successfully.");

                                        setTimeout(function () {
                                            vm.InfoMsg(null);
                                        }, 5000);
                                    }
                                }).catch(function (err) { vm.isProcessing(false) });
                            }
                        });
                    },
                    OpenSearch: function (searchType) {
                        vm.$ChildModels.CarrierRateSearchVM(new CarrierRateSearchVM(searchType, vm));
                    },
                    ListAll: function () {
                        vm.Methods().OpenSearch('listAll');
                        vm.$ChildModels.CarrierRateSearchVM().Methods().ExecuteSearch();
                    },
                    LoadChildModules: function () {
                        
                        setTimeout(function () {
                            //  Main Carrier Rate Entry
                            vm.$ChildModels.CarrierRatesEntryVM(new CarrierRatesEntryVM(vm));

                            // Carrier Lane Rates
                            vm.$ChildModels.CarrierLaneRateVM(new CarrierLaneRateVM(vm));
                            vm.$ChildModels.CarrierLaneRateVM().Methods().LoadRates();

                            // LOH Rates
                            vm.$ChildModels.LOHRatesVM(new LOHRatesVM(vm));
                            vm.$ChildModels.LOHRatesVM().Methods().LoadRates();

                            // Comments
                            vm.$ChildModels.CarrierRateCommentsVM(new CarrierRateCommentsVM(vm));
                            vm.$ChildModels.CarrierRateCommentsVM().Methods().LoadComments();
                        }, 0);
                    },
                    ValidateCarrierRate: function () {
                        return vm.$ChildModels.CarrierRatesEntryVM().Methods().IsFormValid();
                    }
                }
            },
            HTTP: {
                SearchCarrierRateAsync: function (payloadObj) {
                    if (!payloadObj)
                        return Promise.reject("You must provide a 'payload' to query with.");

                    return new Promise(function (resolve, reject) {

                        dataModel.ajaxRequest("CarrierRates/Search", "POST", payloadObj).done(function (resp) {
                            return resolve(resp);

                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }
                            
                            CarrierRates().LogError(err);
                        });
                    });
                },
                GetCarrierRateAsync: function (carrierRateId) {

                    if (!carrierRateId)
                        return Promise.reject("Carrier Rate Id is required.");

                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates/View", "GET", { carrierRateId: carrierRateId }).done(function (resp) {
                            return resolve(resp);
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }

                            CarrierRates().LogError(err);
                        });
                    });
                },
                DeleteCarrierRateAsync: function (carrierRateId) {

                    if (!carrierRateId) return Promise.reject(false);

                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates/Delete", "POST", { carrierRateId: carrierRateId }).done(function (isSuccess) {
                            return resolve(isSuccess);
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }

                            CarrierRates().LogError(err);
                        });
                    });
                    
                }
            },
            $Parent: $componentDataParams.$data,
            $ChildModels: {
                CarrierRatesEntryVM: ko.observable(),
                CarrierRateSearchVM: ko.observable(),
                CarrierLaneRateVM: ko.observable(),
                LOHRatesVM: ko.observable(),
                CarrierRateCommentsVM: ko.observable(),
            }
        }
        
        vmObj.Methods().LoadChildModules();

        CarrierRates().PageViewModel(vmObj);

        return vmObj;
    }
}

class CarrierRatesEntryVM {
    constructor(parentModel) {
        return {
            CarrierRateModel: CarrierRates().Store.CarrierRateModel,
            Errors: ko.observableArray([]),
            isProcessing: ko.observable(false),
            InfoMsg: ko.observable(),
            UX: function () {
                var vm = this;
    
                var _displayValues = ko.toJS(vm.CarrierRateModel);
                return {
                    CustomerDisplay: ko.observable(
                        _displayValues.BillToCustomer ? (typeof _displayValues.BillToCustomer === 'object' ? _displayValues.BillToCustomer.code : _displayValues.BillToCustomer) : null
                    ),
                    CommodityDisplay: ko.observable(
                        _displayValues.Commodity ? (typeof _displayValues.Commodity === 'object' ? _displayValues.Commodity.code : _displayValues.Commodity) : null
                    ),
                    TrailerTypeDisplay: ko.observable(
                        _displayValues.TrailerType ? (typeof _displayValues.TrailerType === 'object' ? _displayValues.TrailerType.code : _displayValues.TrailerType) : null
                    )
                }
    
            },
            Methods: function () {
                var vm = this;
    
                return {
                    Save: function () {
                        return new Promise(function (resolve, reject) {
    
                            if (vm.Methods().IsFormValid() == false)
                                return reject("Please correct any Carrier Rate entry errors.");
    
                            CarrierRates().isLoading(true);
    
                            vm.HTTP.PostCarrierRateAsync(vm.CarrierRateModel().MapToJSON()).then(function (carrierRate) {
                                CarrierRates().isLoading(false);
    
                                if (carrierRate) {
                                    CarrierRates().Store.CarrierRateModel(new KOCarrierRateModel(carrierRate));
                                    return resolve(true);
                                } else {
                                    return reject(false);
                                }
                            }).catch(function (err) {
                                vm.isProcessing(false);
                                
                                if (typeof err === "string")
                                    vm.Errors.push(err);
    
                                return reject("Please correct any Carrier Rate entry errors.");
                            });
                        });
                            
                    },
                    ResetForm: function () {
                        CarrierRates().Store.CarrierRateModel(new KOCarrierRateModel());
    
                        vm.Errors.removeAll();
                        vm.InfoMsg(null);
                    },
                    IsFormValid: function () {
                        vm.Errors.removeAll();
    
                        if (vm.CarrierRateModel().isValid() === false) {
                            vm.Errors.push(vm.CarrierRateModel().ValidationErrors());
                            return false;
                        }
    
                        return true;
                    }
                };
            },
            HTTP: {
                PostCarrierRateAsync: function (payload) {
    
                    if (!payload)
                        return Promise.reject(false);
    
                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates", "POST", payload).done(function (carrierRate) {
                            if (carrierRate) {
                                resolve(carrierRate);
                            }
                            else {
                                reject(false);
                            }
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }
    
                            CarrierRates().LogError(err);
                        });
                    });
                },
            },
            $Parent: parentModel || {},
            $ChildModels: {},
        }
    }
}

// Carrier Lane Rates View Models
class CarrierLaneRateVM {
    constructor(parentModel) {
        return {
            CarrierRateModel: CarrierRates().Store.CarrierRateModel,
            Errors: ko.observableArray([]),
            isProcessing: ko.observable(false),
            InfoMsg: ko.observable(),
            AskContinueEditing: ko.observable(false),
            UX: function () {
                var vm = this;
    
                return {};
            },
            Methods: function () {
                var vm = this;
    
                var _grid = $(vm.$Grids[0]);
    
                return {
                    LoadRates: function (carrierRateId) {
                        vm.carrierRateId = carrierRateId || vm.CarrierRateModel().Id();
                        vm.Methods().__BuildGrids();
                    },
                    __BuildGrids: function () {
                        var _gridSource = {
                            datatype: "json",
                            url: "CarrierRates/CarrierLaneRates",
                            beforeprocessing: function (data) {
                                _gridSource.totalrecords = data.length;
                            },
                            sort: function (column, direction) {
                                _grid.jqxGrid('updatebounddata', 'sort');
                            },
                            datafields: [
                                { name: "id", type: "number" },
                                { name: "carrierRateId", type: "number" },
                                { name: 'description', type: "string" },
                                { name: 'originType', type: "string" },
                                { name: 'originValue', type: "string" },
                                { name: 'destinationType', type: "string" },
                                { name: 'destinationValue', type: "string" },
                                { name: 'targetPayMethod', type: "string" },
                                { name: 'targetPayRate', type: "string" },
                                { name: 'maxPayMethod', type: "string" },
                                { name: 'maxPayRate', type: "string" },
                                { name: 'pointToPoint', type: "string" },
                                { name: 'timesUsed', type: "string" },
                                { name: 'lastUsedDate', type: "date" },
                                { name: 'originZoneFullDescription', type: "string" },
                                { name: 'destinationZoneFullDescription', type: "string" }
                            ],
                            formatdata: function (data) {
                                data = CarrierRates().RemoveUnWantedGridDataFormating(data);
    
                                data.carrierRateId = vm.CarrierRateModel().Id();
                                data.agencyId = userProfile.currentAgencyId();
    
                                return data;
                            },
                            beforeLoadComplete: function (data) {
    
                                if (data) {
                                    data = data.map(function (x) {
                                        if (x.originType == "O" && x.originZoneFullDescription) {
                                            x.originValue = x.originZoneFullDescription;
                                        }
    
                                        if (x.destinationType == "O" && x.destinationZoneFullDescription) {
                                            x.destinationValue = x.destinationZoneFullDescription;
                                        }
    
                                        x.originType = CarrierRates().GetOriginDestinationType(x.originType).text;
                                        x.destinationType = CarrierRates().GetOriginDestinationType(x.destinationType).text;
                                        x.targetPayMethod = CarrierRates().GetTargetMaxPayRatesType(x.targetPayMethod).text;
                                        x.maxPayMethod = CarrierRates().GetTargetMaxPayRatesType(x.maxPayMethod).text;
                                    });
                                }
                            }
                        };
    
                        if (_grid.length == 0) {
                            _grid = $('#jqxCarrierLaneRateGrid');
                        }
                        
                        var dataAdapter = dataModel.getDataAdapter(_gridSource);
    
                        var _toolbarMenu = function (toolbar) {
                            var $menuContainer = $('<div>', {
                                'style': 'padding: 10px'
                            });
    
                            var $addBtn = $('<button>', {
                                'type': 'button',
                                'class': 'btn btn-sm btn-greatwide',
                                'style': 'margin-right: 3px;',
                                'text': 'Add',
                                'click': function () {
                                    if (vm.isProcessing()) return false;
    
                                    _grid.jqxGrid('clearselection');
    
                                    vm.Methods().Add();
                                }
                            });
    
                            var $refreshBtn = $('<button>', {
                                'type': 'button',
                                'class': 'btn btn-sm btn-greatwide',
                                'style': 'position: absolute; right: 10px; top: 5px;',
                                'title': 'Refresh Grid',
                                'click': function () {
                                    
                                    if (vm.isProcessing() == false) {
                                        vm.Methods().RefreshGrids();
                                        vm.isProcessing(true);
    
                                        _grid.on('bindingcomplete', function () {
                                            vm.isProcessing(false);
                                        })
                                    } 
                                }
                            }).append($('<i>', { 'class': 'glyphicon glyphicon-refresh' }));
    
                            $menuContainer.append($addBtn).append($refreshBtn);
                            toolbar.append($menuContainer);
                        }
    
                        var _customCellsRenderer = function (row, columnfield, value, defaulthtml, columnproperties) {
                            var $el = $(defaulthtml).css({ margin: '0px', padding: '6px 5px', 'width': '100%' });
                            
                            if (!value) {
                                $el.text("--");
                            }
                            else {
                                if (columnproperties.columntype && columnproperties.columntype == 'date') {
                                    var m = new dayjs(value);
                                    value = m.isValid() ? m.format('L') : value;
                                }
    
                                $el.text(value);
                            }
    
                            return $el[0].outerHTML;
                        }
    
                        _grid.jqxGrid({
                            source: dataAdapter,
                            width: "100%",
                            height: 250,
                            autoheight: false,
                            selectionmode: 'none',
                            columnsresize: true,
                            altrows: false,
                            filterable: false,
                            sortable: true,
                            pageable: true,
                            pagesize: 5,
                            columnsmenu: false,
                            toolbarheight: 50,
                            showtoolbar: true,
                            rendertoolbar: _toolbarMenu,
                            enablebrowserselection: true,
                            //selectionmode: 'singlerow',
                            enablehover: true,
                            columns: [
                                {
                                    text: '', width: 75, columntype: 'button', pinned: true,
                                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                                        return 'Edit';
                                    },
                                    buttonclick: function (row) {
    
                                        var data = _grid.jqxGrid('getrowdata', row);
                                        if (data) {
                                            
                                            vm.Methods().Edit(data.id);
                                            
                                        }
                                    }
                                },
                                {
                                    text: '', width: 75, columntype: 'button', pinned: true,
                                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                                        return 'Delete';
                                    },
                                    buttonclick: function (row) {
                                        var data = _grid.jqxGrid('getrowdata', row);
                                        if (data) {
                                            vm.Methods().Delete(data.id);
                                        }
                                    }
                                },
                                { text: 'Description', dataField: 'description', width: 220, cellsrenderer: _customCellsRenderer },
                                { text: 'Orig. Code', dataField: 'originType', width: 120, cellsrenderer: _customCellsRenderer },
                                { text: 'Origin', dataField: 'originValue', width: 220, cellsrenderer: _customCellsRenderer },
                                { text: 'Dest. Code', dataField: 'destinationType', width: 120, cellsrenderer: _customCellsRenderer },
                                { text: 'Destination', dataField: 'destinationValue', width: 220, cellsrenderer: _customCellsRenderer },
                                { text: 'Target Pay Method', dataField: 'targetPayMethod', width: 120, cellsrenderer: _customCellsRenderer },
                                { text: 'Target Pay Rate', dataField: 'targetPayRate', width: 150, cellsrenderer: _customCellsRenderer },
                                { text: 'Max Pay Method', dataField: 'maxPayMethod', width: 120, cellsrenderer: _customCellsRenderer },
                                { text: 'Max Pay Rate', dataField: 'maxPayRate', width: 150, cellsrenderer: _customCellsRenderer },
                                { text: 'Pt to Pt', dataField: 'pointToPoint', width: 100, cellsrenderer: _customCellsRenderer },
                                { text: 'Times Used', dataField: 'timesUsed', width: 100, cellsrenderer: _customCellsRenderer },
                                { text: 'Last Used', dataField: 'lastUsedDate', columntype: 'date', width: 100, cellsrenderer: _customCellsRenderer }
                            ]
                        });
                    },
                    RefreshGrids: function () {
                        _grid.jqxGrid('updatebounddata', 'data');
                    },
                    Add: function () {
                        vm.InfoMsg(null);
    
                        if (CarrierRates().PageViewModel().Methods().ValidateCarrierRate() == false && vm.CarrierRateModel().Id() > 0 == false) {
                            vm.InfoMsg("Please correct any Carrier Rate Entry error(s) before adding/editing.");
                            return false;
                        }
    
                        if (vm.Methods().HasUnsavedEntry() && vm.AskContinueEditing()) {
                            CarrierRates().GetConfirmation({
                                theme: "default",
                                message: "Continue Editing?",
                                submessage: "You have unsaved changes from previous entry, do you want continue editing?",
                                confirmBtnText: "Yes",
                                closeBtnText: "Cancel",
                                buttons: [
                                    { btnText: "Start New", valueOnClick: "new", btnClass: "primary" }
                                ]
                            }).then(function (confirmVal) {
                                if (!confirmVal) { return false; }
                                
                                if (confirmVal == true) {
                                    vm.$ChildModels.CarrierLaneRateEntryVM(new CarrierLaneRateEntryVM(vm));
                                }
                                else if (confirmVal == "new") {
                                    vm.AskContinueEditing(false);
                                    CarrierRates().Store.CarrierLaneRateModel(new KOCarrierLaneRateModel({ carrierRateId: vm.CarrierRateModel().Id() }));
                                    vm.$ChildModels.CarrierLaneRateEntryVM(new CarrierLaneRateEntryVM(vm));
                                }
                            });
                        }
                        else {
                            CarrierRates().Store.CarrierLaneRateModel(new KOCarrierLaneRateModel({ carrierRateId: vm.CarrierRateModel().Id() }));
                            vm.$ChildModels.CarrierLaneRateEntryVM(new CarrierLaneRateEntryVM(vm));
                        }
    
                    },
                    Delete: function (carrierLaneRateId) {
    
                        if (carrierLaneRateId == null) { return false; }
    
                        CarrierRates().GetConfirmation({
                            message: "Are you sure you want to delete?",
                        }).then(function (x) {
                            if (x) {
                                vm.isProcessing(true);
    
                                vm.HTTP.DeleteCarrierLaneRateAsync(carrierLaneRateId).then(function (isDeleted) {
                                    vm.isProcessing(false);
                                    vm.Methods().RefreshGrids();
                                }).catch(function (err) { vm.isProcessing(false) });
                            }
                        });
    
                    },
                    Edit: function (carrierLaneRateId) {
                        vm.InfoMsg(null);
                        if (CarrierRates().PageViewModel().Methods().ValidateCarrierRate() == false && vm.CarrierRateModel().Id() > 0 == false) {
                            vm.InfoMsg("Please correct any Carrier Rate Entry error(s) before adding/editing.");
                            return false;
                        }
    
                        CarrierRates().isLoading(true);
                        vm.HTTP.GetCarrierLaneRateAsync(carrierLaneRateId).then(function (carrierLaneRate) {
                            CarrierRates().isLoading(false);
    
                            if (carrierLaneRate != null) {
                                CarrierRates().Store.CarrierLaneRateModel(new KOCarrierLaneRateModel(carrierLaneRate));
                                vm.$ChildModels.CarrierLaneRateEntryVM(new CarrierLaneRateEntryVM(vm));
                            }
                                
                        }).catch(function (err) {
                            vm.isProcessing(false);
    
                            if (err && typeof err === "string")
                                vm.Errors.push(err);
                            
                        });
                    },
                    HasUnsavedEntry: function () {
                        return CarrierRates().Store.CarrierLaneRateModel() != null;
                    }
                }
            },
            $Grids: [
                "#jqxCarrierLaneRateGrid",
            ],
            HTTP: {
                GetCarrierLaneRateAsync: function (id) {
    
                    if (id == null) return Promise.reject(null);
    
                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates/CarrierLaneRates/View", "GET", { id: id }).done(function (carrierLaneRate) {
                            return resolve(carrierLaneRate);
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }
    
                            CarrierRates().LogError(err);
                        });
                    });
                },
                DeleteCarrierLaneRateAsync: function (id) {
                    
                    if (id == null) return Promise.reject(false);
    
                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates/CarrierLaneRates/Delete", "POST", { id: id }).done(function (isDeleted) {
                            return resolve(isDeleted);
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }
    
                            CarrierRates().LogError(err);
                        });
                    });
                }
            },
            $Parent: parentModel || {},
            $ChildModels: {
                CarrierLaneRateEntryVM: ko.observable(),
            },
        };
    }
}
class CarrierLaneRateEntryVM {
    constructor(parentModel) {
        return {
            CarrierLaneRateModel: CarrierRates().Store.CarrierLaneRateModel,
            Errors: ko.observableArray([]),
            isProcessing: ko.observable(false),
            UX: function () {
                var vm = this;
    
                var _displayValues = vm.CarrierLaneRateModel().MapToJSON();
                
                return {
                    DisplayUXOriginValueLabelAsRequired: ko.computed(function () {
                        return vm.CarrierLaneRateModel().OriginCode() != null && vm.CarrierLaneRateModel().OriginValue() == null;
                    }),
                    DisplayUXDestinationValueLabelAsRequired: ko.computed(function () {
                        return vm.CarrierLaneRateModel().DestinationCode() != null && vm.CarrierLaneRateModel().DestinationValue() == null;
                    }),
                    TargetPayLabel: ko.computed(function () {
                        var _method = vm.CarrierLaneRateModel().TargetPayMethod(),
                            _label = CarrierRates().GetTargetMaxPayRatesType(_method).label;
    
                        return _label;
                    }),
                    TargetPayLabelHelpText: ko.computed(function () {
                        var _method = vm.CarrierLaneRateModel().TargetPayMethod(),
                            _text = "";
    
                        if (_method == 'P') {
                            _text = _text + "(For 20% margin on total charges)";
                        }
                        else if (_method == 'D') {
                            _text = _text + "(Uses movement distance)";
                        }
    
                        return _text;
                    }),
                    MaximumPayLabel: ko.computed(function () {
                        var _method = vm.CarrierLaneRateModel().MaximumPayMethod(),
                            _label = CarrierRates().GetTargetMaxPayRatesType(_method).label;
    
                        return _label;
                    }),
                    MaximumPayLabelHelpText: ko.computed(function () {
                        var _method = vm.CarrierLaneRateModel().MaximumPayMethod(),
                            _text = "";
    
                        if (_method == 'P') {
                            _text = _text + "(For max pay or 90% of total charge, enter 90)";
                        }
                        else if (_method == 'D') {
                            _text = _text + "(Uses movement distance)";
                        }
    
                        return _text;
                    }),
                    PointToPointVisible: ko.computed(function () {
                        return (vm.CarrierLaneRateModel().OriginCode() && vm.CarrierLaneRateModel().OriginCode() == 'L') && (vm.CarrierLaneRateModel().DestinationCode() && vm.CarrierLaneRateModel().DestinationCode() == 'L');
                    }),
                    OriginCodeDDLOptions: ko.observableArray(CarrierRates().OriginDestinationTypes),
                    DestinationCodeDDLOptions: ko.observableArray(CarrierRates().OriginDestinationTypes),
                    TargetPayMethodDDLOptions: ko.observableArray(CarrierRates().RateMethods),
                    MaximumPayMethodDDLOptions: ko.observableArray(CarrierRates().RateMethods),
                    DisplayDestinationValue: ko.observable(_displayValues && _displayValues.DestinationValue ? _displayValues.DestinationValue : null),
                    DisplayOriginValue: ko.observable(_displayValues && _displayValues.OriginValue ? _displayValues.OriginValue : null),
                };
            },
            Methods: function () {
                var vm = this;
    
                return {
                    Save: function () {
                        vm.Errors.removeAll();
                        
                        if (vm.CarrierLaneRateModel().isValid() == false) {
                            vm.Errors(vm.CarrierLaneRateModel().ValidationErrors());
                            return false;
                        }
    
                        vm.isProcessing(true);
    
                        vm.Methods().SaveCarrierRate().then(function (isSuccess) {
                            if (isSuccess) {
                                vm.CarrierLaneRateModel().CarrierRateId(CarrierRates().Store.CarrierRateModel().Id());
                                
                                vm.HTTP.PostCarrierLaneRateAsync(vm.CarrierLaneRateModel().MapToJSON()).then(function (resp) {
                                    vm.isProcessing(false);
    
                                    if (resp && resp.validationErrors) {
                                        vm.Errors(resp.validationErrors);
                                    }
                                    else {
                                        $('#carrierLaneRateEntryModal').modal('hide');
                                        vm.$Parent.Methods().RefreshGrids();
                                    }
                                }).catch(function (err) { vm.isProcessing(false); if (typeof err === 'string') vm.Errors.push(err) });
                            }
                        }).catch(function (err) {
                            vm.$Parent.AskContinueEditing(true);
                            vm.isProcessing(false); if (typeof err === 'string') vm.Errors.push(err)
                        });
                    },
                    SaveCarrierRate: function () {
                        if (CarrierRates().Store.CarrierRateModel().Id() != null) {
                            CarrierRates().PageViewModel().Methods().ValidateCarrierRate();
                            return Promise.resolve(true);
                        }
    
                        return CarrierRates().PageViewModel().Methods().InitNewCarrierRate();
                    }
                }
            },
            HTTP: {
                PostCarrierLaneRateAsync: function (payload) {
                    if (!payload) return Promise.reject(false);
    
                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates/CarrierLaneRates", "POST", payload).done(function (resp) {
                            resolve(resp);
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }
    
                            CarrierRates().LogError(err);
                        });
                    });
                }
            },
            $Parent: parentModel || {},
            $ChildModels: {},
        }
    }
}

// Carrier Rate Comments Models
class CarrierRateCommentsVM {
    constructor(parentModel) {
        return {
            CarrierRateModel: CarrierRates().Store.CarrierRateModel,
            Errors: ko.observableArray([]),
            InfoMsg: ko.observable(),
            isProcessing: ko.observable(false),
            AskContinueEditing: ko.observable(false),
            UX: function () {
                return { }
            },
            Methods: function () {
                var vm = this;
    
                var _commentsGrid = $(vm.$Grids[0]);
    
                return {
                    _BuildGrid: function () {
                        var _gridSource = {
                            datatype: "json",
                            url: "CarrierRates/Comments",
                            beforeprocessing: function (data) {
                                _gridSource.totalrecords = data.length;
                            },
                            sort: function (column, direction) {
                                _commentsGrid.jqxGrid('updatebounddata', 'sort');
                            },
                            datafields: [
                                { name: "id", type: "number" },
                                { name: "carrierRateId", type: "number" },
                                { name: 'comments', type: "string" },
                                { name: 'enteredByUserId', type: "number" },
                                { name: 'enteredByUsername', type: "string" },
                                { name: 'enteredDate', type: 'date' }
                            ],
                            formatdata: function (data) {
                                data = CarrierRates().RemoveUnWantedGridDataFormating(data);
    
                                data.carrierRateId = vm.CarrierRateModel().Id();
                                data.agencyId = userProfile.currentAgencyId();
    
                                return data;
                            }
                        };
    
                        if (_commentsGrid.length == 0) {
                            _commentsGrid = $('#jqxCarrierRateCommentsGrid');
                        }
                        
                        var dataAdapter = dataModel.getDataAdapter(_gridSource);
    
                        var _toolbarMenu = function (toolbar) {
                            var $menuContainer = $('<div>', {
                                'style': 'padding: 10px'
                            });
    
                            var $addBtn = $('<button>', {
                                'type': 'button',
                                'class': 'btn btn-sm btn-greatwide',
                                'style': 'margin-right: 3px;',
                                'text': 'Add',
                                'click': function () {
                                    if (vm.isProcessing()) return false;
    
                                    _commentsGrid.jqxGrid('clearselection');
                                    
                                    vm.Methods().EditComment({ carrierRateId: vm.CarrierRateModel().Id() });
                                }
                            });
    
                            $menuContainer.append($addBtn);
                            toolbar.append($menuContainer);
                        }
    
                        var _customCellsRenderer = function (row, columnfield, value, defaulthtml, columnproperties) {
                            var $el = $(defaulthtml).css({ margin: '0px', padding: '6px 5px', 'width': '100%' });
    
                            if (!value) {
                                $el.text("--");
                            }
                            else {
                                $el.text(value);
                            }
    
                            return $el[0].outerHTML;
                        }
    
                        _commentsGrid.jqxGrid({
                            source: dataAdapter,
                            width: "100%",
                            height: 250,
                            autoheight: false,
                            selectionmode: 'none',
                            columnsresize: true,
                            altrows: false,
                            filterable: false,
                            sortable: true,
                            pageable: true,
                            pagesize: 5,
                            columnsmenu: false,
                            toolbarheight: 50,
                            showtoolbar: true,
                            rendertoolbar: _toolbarMenu,
                            enablebrowserselection: true,
                            //selectionmode: 'singlerow',
                            enablehover: true,
                            columns: [
                                {
                                    text: '', width: 75, columntype: 'button', pinned: true,
                                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                                        return 'Edit';
                                    },
                                    buttonclick: function (row) {
    
                                        var data = _commentsGrid.jqxGrid('getrowdata', row);
                                        if (data) {
    
                                            var dataObj = {
                                                id: data.id,
                                                carrierRateId: data.carrierRateId,
                                                comments: data.comments,
                                                enteredByUserId: data.enteredByUserId,
                                                enteredDate: data.enteredDate
                                            };
    
                                            vm.Methods().EditComment(dataObj);
                                        }
                                    }
                                },
                                {
                                    text: '', width: 75, columntype: 'button', pinned: true,
                                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                                        return 'Delete';
                                    },
                                    buttonclick: function (row) {
                                        var data = _commentsGrid.jqxGrid('getrowdata', row);
                                        if (data) {
                                            vm.Methods().DeleteComment(data.id);
                                        }
                                    }
                                },
                                { text: 'Comments', dataField: 'comments', width: '75%', cellsrenderer: _customCellsRenderer },
                                { text: 'Entered By', dataField: 'enteredByUsername', width: 150, cellsrenderer: _customCellsRenderer },
                                { text: 'Entered Date', dataField: 'enteredDate', width: 150, cellsrenderer: _customCellsRenderer, cellsformat: "MM/dd/YYYY" }
                            ]
                        });
                    },
                    LoadComments: function () {
                        vm.Methods()._BuildGrid();
                    },
                    RefreshGrids: function () {
                        if (_commentsGrid.length >= 0)
                            _commentsGrid.jqxGrid('updatebounddata', 'data');
                    },
                    EditComment: function (dataObj) {
                        vm.InfoMsg(null);
    
                        if (CarrierRates().PageViewModel().Methods().ValidateCarrierRate() == false && vm.CarrierRateModel().Id() > 0 == false) {
                            vm.InfoMsg("Please correct any Carrier Rate Entry error(s) before adding/editing.");
                            return false;
                        }
    
                        if (vm.Methods().HasUnsavedEntry() && vm.AskContinueEditing()) {
                            CarrierRates().GetConfirmation({
                                theme: "default",
                                message: "Continue Editing?",
                                submessage: "You have unsaved changes from previous entry, do you want continue editing?",
                                confirmBtnText: "Yes",
                                closeBtnText: "Cancel",
                                buttons: [
                                    { btnText: "Start New", valueOnClick: "new", btnClass: "primary" }
                                ]
                            }).then(function (confirmVal) {
                                if (!confirmVal) { return false; }
    
                                if (confirmVal == true) {
                                    vm.$ChildModels.CarrierRateCommentsEntryVM(new CarrierRateCommentsEntryVM(vm));
                                }
                                else if (confirmVal == "new") {
                                    vm.AskContinueEditing(false);
                                    CarrierRates().Store.CarrierRateCommentModel(new KOCarrierRateComment({ carrierRateId: vm.CarrierRateModel().Id() }));
                                    vm.$ChildModels.CarrierRateCommentsEntryVM(new CarrierRateCommentsEntryVM(vm));
                                }
                            });
                        }
                        else {
                            CarrierRates().Store.CarrierRateCommentModel(new KOCarrierRateComment(dataObj));
                            vm.$ChildModels.CarrierRateCommentsEntryVM(new CarrierRateCommentsEntryVM(vm));
                        }
                    },
                    DeleteComment: function (carrierRateCommentId) {
                        if (!carrierRateCommentId) return false;
    
                        CarrierRates().GetConfirmation({
                            message: "Are you sure you want to delete?",
                        }).then(function (x) {
                            if (x) {
                                vm.isProcessing(true);
    
                                vm.HTTP.DeleteCarrierRateCommentAsync(carrierRateCommentId, vm.CarrierRateModel().Id()).then(function (isDeleted) {
                                    vm.isProcessing(false);
                                    vm.Methods().RefreshGrids();
                                }).catch(function (err) { vm.isProcessing(false) });
                            }
                        });
                    },
                    HasUnsavedEntry: function () {
                        return CarrierRates().Store.CarrierRateCommentModel() != null;
                    }
                };
            },
            HTTP: {
                DeleteCarrierRateCommentAsync: function (carrierRateCommentId, carrierRateId) {
                    if (!carrierRateCommentId) return Promise.reject(false);
    
                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates/Comments/Delete", "POST", { id: carrierRateCommentId, carrierRateId: carrierRateId }).done(function (isDeleted) {
                            return resolve(isDeleted);
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }
    
                            CarrierRates().LogError(err);
                        });
                    });
                }
            },
            $Grids: [
                '#jqxCarrierRateCommentsGrid'
            ],
            $Parent: parentModel || {},
            $ChildModels: {
                CarrierRateCommentsEntryVM: ko.observable(),
            }
        }
    }
}

class CarrierRateCommentsEntryVM {
    constructor(parentModel) {
        return {
            CarrierRateCommentModel: CarrierRates().Store.CarrierRateCommentModel,
            Errors: ko.observableArray([]),
            InfoMsg: ko.observable(),
            isProcessing: ko.observable(false),
            UX: function () {
                var vm = this;
    
                return { }
            },
            Methods: function () {
                var vm = this;
    
                return {
                    Save: function () {
                        vm.Errors.removeAll();
                        if (vm.CarrierRateCommentModel().isValid() == false) {
                            vm.Errors(vm.CarrierRateCommentModel().ValidationErrors());
                            return false;
                        }
    
                        vm.isProcessing(true);
    
                        vm.Methods().SaveCarrierRate().then(function (isSuccess) {
                            if (isSuccess) {
                                vm.HTTP.PostCarrierRateCommentAsync(vm.CarrierRateCommentModel().MapToJSON()).then(function (resp) {
                                    vm.isProcessing(false);
    
                                    if (resp && resp.validationErrors) {
                                        vm.Errors(resp.validationErrors);
                                    }
                                    else {
                                        $('#commentsEntryModal').modal('hide');
                                        vm.$Parent.Methods().RefreshGrids();
                                    }
                                }).catch(function (err) { vm.isProcessing(false); if (typeof err === 'string') vm.Errors.push(err) });
                            }
                        }).catch(function (err) {
                            vm.$Parent.AskContinueEditing(true);
                            vm.isProcessing(false); if (typeof err === 'string') vm.Errors.push(err)
                        });
                        
                    },
                    SaveCarrierRate: function () {
                        if (CarrierRates().Store.CarrierRateModel().Id() != null) {
                            CarrierRates().PageViewModel().Methods().ValidateCarrierRate();
    
                            return Promise.resolve(true);
                        }
                            
    
                        return CarrierRates().PageViewModel().Methods().InitNewCarrierRate();
                    }
                }
            },
            HTTP: {
                PostCarrierRateCommentAsync: function (payload) {
                    if (!payload) return Promise.reject(false);
    
                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates/Comments", "POST", payload).done(function (resp) {
                            resolve(resp);
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }
    
                            CarrierRates().LogError(err);
                        });
                    });
                }
            },
            $Parent: parentModel || {},
            $ChildModels: {}
        }
    }
}

// Search Model (modal)
class CarrierRateSearchVM {
    constructor(searchType, parentModel) {
        return {
            SearchModel: ko.observable(new KOCarrierRateSearchModel({ searchType: searchType })),
            Errors: ko.observableArray([]),
            InfoMsg: ko.observable(),
            isProcessing: ko.observable(false),
            data: {
                _gridRendered: false,
            },
            UX: function () {
                return {};
            },
            Methods: function () {
                var vm = this;
    
                var $grid = $(vm.$Grids[0]);
    
                return {
                    ClearSearch: function () {
                        vm.SearchModel().SearchValue(null);
    
                        if ($grid.length > 0)
                            $grid.jqxGrid('clear');
                    },
                    ExecuteSearch: function () {
                        vm.isProcessing(true);
    
                        if ($grid.length == 0 || vm.data._gridRendered == false) {
                            vm.Methods().__BuildSearchResultsGrid();
                        }
                        else {
                            $grid.jqxGrid('updatebounddata', 'data');
                        }
                    },
                    __BuildSearchResultsGrid: function () {
                        
                        var _gridSource = {
                            datatype: "json",
                            url: "CarrierRates/Search",
                            beforeprocessing: function (data) {
                                _gridSource.totalrecords = data.length;
                            },
                            sort: function (column, direction) {
                                $grid.jqxGrid('updatebounddata', 'sort');
                            },
                            loadComplete: function () {
                                vm.isProcessing(false);
                                CarrierRates().isLoading(false);
                            },
                            formatData: function (data) {
                                data = CarrierRates().RemoveUnWantedGridDataFormating(data);
    
                                var _searchModel = vm.SearchModel().MapToJSON();
                                
                                data.agencyId = userProfile.currentAgencyId();
                                data.listAll = _searchModel.SearchType == 'listAll';
                                data.carrierRateId = _searchModel.SearchType == 'rateNumber' ? _searchModel.SearchValue : null;
                                data.customerId = _searchModel.SearchType == 'billTo' ? _searchModel.SearchValue.id : null;
                                data.commodityId = _searchModel.SearchType == 'commodity' ? _searchModel.SearchValue.id : null;
                                data.trailerTypeId = _searchModel.SearchType == 'trailerType' ? _searchModel.SearchValue.id : null;
                                data.effectiveDate = _searchModel.SearchType == 'effectiveDate' ? (_searchModel.SearchValue instanceof Date == false && _searchModel.isValid() ? _searchModel.SearchValue.toISOString() : _searchModel.SearchValue) : null; // grid doesn't like converting momemt so do that here if moment date.
                                data.expirationDate = _searchModel.SearchType == 'expirationDate' ? (_searchModel.SearchValue instanceof Date == false && _searchModel.isValid() ? _searchModel.SearchValue.toISOString() : _searchModel.SearchValue) : null; // grid doesn't like converting momemt so do that here if moment date.
                                
                                return data;
                            },
                            datafields: [
                                { name: "carrierRateId", type: "number" },
                                { name: "billTo", type: "string" },
                                { name: "commodity", type: "string" },
                                { name: "trailerType", type: "string" },
                                { name: "effectiveDate", type: "date" },
                                { name: "expirationDate", type: "date" },
                            ]
                        };
    
    
                        if ($grid.length == 0) {
                            $grid = $('#jqxSearchResultsGrid');
                        }
    
                        var dataAdapter = dataModel.getDataAdapter(_gridSource);
    
                        $grid.jqxGrid({
                            source: dataAdapter,
                            width: "100%",
                            autoheight: true,
                            selectionmode: 'none',
                            columnsresize: true,
                            altrows: true,
                            enablehover: false,
                            filterable: false,
                            sortable: true,
                            pageable: true,
                            columnsmenu: false,
                            pagesize: 10,
                            columns: [
                                {
                                    text: '', datafield: 'showEdit', width: 75, columntype: 'button', pinned: true,
                                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                                        return 'Edit';
                                    },
                                    buttonclick: function (row) {
    
                                        var datarow = $grid.jqxGrid('getrowdata', row);
    
                                        if (datarow) {
                                            vm.$Parent.Methods().LoadCarrierRate(datarow.carrierRateId);
                                            $('#carrierRatesSearchModal').modal('hide');
                                        }
                                    }
                                },
                                { text: 'Rate Number', dataField: 'carrierRateId', width: 150 },
                                { text: 'Bill To', dataField: 'billTo', width: 150 },
                                { text: 'Commodity', dataField: 'commodity', width: 150 },
                                { text: 'Trailer Type', dataField: 'trailerType', width: 150 },
                                { text: 'Effective Date', dataField: 'effectiveDate', width: 150, cellsformat: 'MM/dd/yyyy' },
                                { text: 'Expiration Date', dataField: 'expirationDate', width: 150, cellsformat: 'MM/dd/yyyy' }
                            ],
                            ready: function () {
                                vm.data._gridRendered = true;
                            }
                        });
                    }
                }
            },
            HTTP: {},
            $Parent: parentModel || {},
            $ChildModels: {},
            $Grids: [
                '#jqxSearchResultsGrid',
            ],
        }
    }
}

// LOH ViewModels
class LOHRatesVM {
    constructor(parentModel) {
        return {
            CarrierRateModel: CarrierRates().Store.CarrierRateModel,
            Errors: ko.observableArray([]),
            InfoMsg: ko.observable(),
            isProcessing: ko.observable(false),
            UX: function () {
                var vm = this;
                return {}
            },
            Methods: function () {
                var vm = this;
    
                var _targetMarginGrid = $(vm.$Grids[0]);
                var _maxPayRateGrid = $(vm.$Grids[1]);
    
                return {
                    LoadRates: function () {
                        vm.Methods().__BuildTargetMarginGrid();
                        vm.Methods().__BuildMaxPayRatesGrid();
                    },
                    __BuildTargetMarginGrid: function () {
                        var _gridSource = {
                            datatype: "json",
                            url: "CarrierRates/LOHList",
                            beforeprocessing: function (data) {
                                _gridSource.totalrecords = data.length;
                            },
                            sort: function (column, direction) {
                                _targetMarginGrid.jqxGrid('updatebounddata', 'sort');
                            },
                            datafields: [
                                { name: "id", type: "number" },
                                { name: "mileageStart", type: "string" },
                                { name: "mileageEnd", type: "string" },
                                { name: "rateMargin", type: "string" }
                            ],
                            formatdata: function (data) {
                                data = CarrierRates().RemoveUnWantedGridDataFormating(data);
    
                                data.carrierRateId = vm.CarrierRateModel().Id();
                                data.agencyId = userProfile.currentAgencyId();
                                data.targetMargins = true;
                                data.MaxPayRates = false;
                                return data;
                            },
                        };
    
                        if (_targetMarginGrid.length == 0) {
                            _targetMarginGrid = $('#jqxLOHTargetMarginGrid');
                        }
    
                        var dataAdapter = dataModel.getDataAdapter(_gridSource);
    
                        var _toolbarMenu = function (toolbar) {
                            var $menuContainer = $('<div>', {
                                'style': 'padding: 10px'
                            });
    
                            var $addBtn = $('<button>', {
                                'type': 'button',
                                'class': 'btn btn-sm btn-greatwide',
                                'style': 'margin-right: 3px;',
                                'text': 'Add',
                                'click': function () {
                                    if (vm.isProcessing()) return false;
    
                                    _targetMarginGrid.jqxGrid('clearselection');
                                    vm.Methods().EditTargetRate({ carrierRateId: vm.CarrierRateModel().Id() });
                                }
                            });
    
                            var $updateBtn = $('<button>', {
                                'type': 'button',
                                'class': 'btn btn-sm btn-greatwide',
                                'style': 'margin-right: 3px;',
                                'text': 'Update',
                                'click': function () {
                                    if (vm.isProcessing() || _targetMarginGrid.jqxGrid('getrows').length > 0 == false) return false;
                                    vm.InfoMsg(null);
    
                                    var rowindex = _targetMarginGrid.jqxGrid('getselectedrowindex');
                                    if (rowindex >= 0) {
                                        var row = _targetMarginGrid.jqxGrid('getrowdata', rowindex);
    
                                        if (row) {
                                            var dataObj = {
                                                lohType: "targetMargin",
                                                id: row.id,
                                                mileageStart: row.mileageStart,
                                                mileageEnd: row.mileageEnd,
                                                rateMargin: row.rateMargin,
                                                carrierRateId: vm.CarrierRateModel().Id()
                                            };
    
                                            vm.Methods().EditTargetRate(dataObj);
                                        }
    
                                    }
                                    else {
                                        vm.InfoMsg("You must select a target margin from the grid.");
                                    }
                                }
                            });
    
                            var $deleteBtn = $('<button>', {
                                'type': 'button',
                                'class': 'btn btn-sm btn-greatwide darkred-text',
                                'style': 'margin-right: 3px;',
                                'text': 'Delete',
                                'click': function () {
    
                                    if (vm.isProcessing() || _targetMarginGrid.jqxGrid('getrows').length > 0 == false) return false;
                                    vm.InfoMsg(null);
    
                                    var rowindex = _targetMarginGrid.jqxGrid('getselectedrowindex');
                                    if (rowindex >= 0) {
                                        var row = _targetMarginGrid.jqxGrid('getrowdata', rowindex);
    
                                        if (row) {
                                            vm.Methods().DeleteTargetMarginRate(row.id);
                                        }
                                    }
                                    else {
                                        vm.InfoMsg("You must select a target margin from the grid.");
                                    }
                                }
                            });
    
                            $menuContainer.append($addBtn).append($updateBtn).append($deleteBtn);
    
                            toolbar.append($menuContainer);
                        }
    
                        _targetMarginGrid.jqxGrid({
                            source: dataAdapter,
                            width: "100%",
                            height: 250,
                            autoheight: false,
                            columnsresize: true,
                            altrows: false,
                            filterable: false,
                            sortable: true,
                            pageable: true,
                            pagesize: 5,
                            columnsmenu: false,
                            toolbarheight: 50,
                            showtoolbar: true,
                            rendertoolbar: _toolbarMenu,
                            enablebrowserselection: true,
                            selectionmode: 'singlerow',
                            enablehover: true,
                            columns: [
                                { text: 'Start', dataField: 'mileageStart' },
                                { text: 'End', dataField: 'mileageEnd' },
                                { text: 'Target Margin', dataField: 'rateMargin', width: 250 }
                            ]
                        });
    
                    },
                    __BuildMaxPayRatesGrid: function () {
                        var _gridSource = {
                            datatype: "json",
                            url: "CarrierRates/LOHList",
                            beforeprocessing: function (data) {
                                _gridSource.totalrecords = data.length;
                            },
                            sort: function (column, direction) {
                                _maxPayRateGrid.jqxGrid('updatebounddata', 'sort');
                            },
                            datafields: [
                                { name: "id", type: "number" },
                                { name: "mileageStart", type: "string" },
                                { name: "mileageEnd", type: "string" },
                                { name: "rateMargin", type: "string" }
                            ],
                            formatdata: function (data) {
    
                                data = CarrierRates().RemoveUnWantedGridDataFormating(data);
    
                                data.carrierRateId = vm.CarrierRateModel().Id();
                                data.agencyId = userProfile.currentAgencyId();
                                data.targetMargins = false;
                                data.MaxPayRates = true;
                                return data;
                            },
                        };
    
                        if (_maxPayRateGrid.length == 0) {
                            _maxPayRateGrid = $('#jqxLOHMaxPayRateGrid');
                        }
                        
                        var dataAdapter = dataModel.getDataAdapter(_gridSource);
    
                        var _toolbarMenu = function (toolbar) {
                            var $menuContainer = $('<div>', {
                                'style': 'padding: 10px'
                            });
    
                            var $addBtn = $('<button>', {
                                'type': 'button',
                                'class': 'btn btn-sm btn-greatwide',
                                'style': 'margin-right: 3px;',
                                'text': 'Add',
                                'click': function () {
                                    if (vm.isProcessing()) return false;
    
                                    _maxPayRateGrid.jqxGrid('clearselection');
                                    vm.Methods().EditMaxPayRate({carrierRateId: vm.CarrierRateModel().Id()});  
                                }
                            });
    
                            var $updateBtn = $('<button>', {
                                'type': 'button',
                                'class': 'btn btn-sm btn-greatwide',
                                'style': 'margin-right: 3px;',
                                'text': 'Update',
                                'click': function () {
    
                                    if (vm.isProcessing() || _maxPayRateGrid.jqxGrid('getrows').length > 0 == false) return false;
    
                                    vm.InfoMsg(null);
    
                                    var rowindex = _maxPayRateGrid.jqxGrid('getselectedrowindex');
                                    if (rowindex >= 0) {
                                        var data = _maxPayRateGrid.jqxGrid('getrowdata', rowindex);
    
                                        if (data) {
                                            var dataObj = {
                                                lohType: "maxPay",
                                                id: data.id,
                                                mileageStart: data.mileageStart,
                                                mileageEnd: data.mileageEnd,
                                                rateMargin: data.rateMargin,
                                                carrierRateId: vm.CarrierRateModel().Id()
                                            };
    
                                            vm.Methods().EditMaxPayRate(dataObj);
                                        }
    
                                    }
                                    else {
                                        vm.InfoMsg("You must select a maximum pay rate from the grid.");
                                    }
                                }
                            });
    
                            var $deleteBtn = $('<button>', {
                                'type': 'button',
                                'class': 'btn btn-sm btn-greatwide darkred-text',
                                'style': 'margin-right: 3px;',
                                'text': 'Delete',
                                'click': function () {
    
                                    if (vm.isProcessing() || _maxPayRateGrid.jqxGrid('getrows').length > 0 == false) return false;
    
                                    vm.InfoMsg(null);
    
                                    var rowindex = _maxPayRateGrid.jqxGrid('getselectedrowindex');
                                    if (rowindex >= 0) {
                                        var data = _maxPayRateGrid.jqxGrid('getrowdata', rowindex);
    
                                        if (data) {
                                            vm.Methods().DeleteMaxPayRate(data.id);
                                        }
                                    }
                                    else {
                                        vm.InfoMsg("You must select a maximum pay rate from the grid.");
                                    }
                                }
                            });
    
                            $menuContainer.append($addBtn).append($updateBtn).append($deleteBtn);
    
                            toolbar.append($menuContainer);
                        }
    
                        _maxPayRateGrid.jqxGrid({
                            source: dataAdapter,
                            width: "100%",
                            height: 250,
                            autoheight: false,
                            columnsresize: true,
                            altrows: false,
                            filterable: false,
                            sortable: true,
                            pageable: true,
                            pagesize: 5,
                            columnsmenu: false,
                            toolbarheight: 50,
                            showtoolbar: true,
                            rendertoolbar: _toolbarMenu,
                            enablebrowserselection: true,
                            selectionmode: 'singlerow',
                            enablehover: true,
                            columns: [
                                { text: 'Start', dataField: 'mileageStart' },
                                { text: 'End', dataField: 'mileageEnd' },
                                { text: 'Maximum Pay Rate', dataField: 'rateMargin', width: 250 }
                            ]
                        });
                    },
                    EditTargetRate: function (dataObj) {
                        vm.InfoMsg(null);
                        dataObj.lohType = "targetMargin";
    
                        if (CarrierRates().PageViewModel().Methods().ValidateCarrierRate() == false && vm.CarrierRateModel().Id() > 0 == false) {
                            vm.InfoMsg("Please correct any Carrier Rate Entry error(s) before adding/editing.");
                            return false;
                        }
    
                        CarrierRates().Store.CarrierLOHRateModel(new KOLOHRateModel(dataObj));
                        vm.$ChildModels.LOHRatesEntryVM(new LOHRatesEntryVM(vm));
    
                    },
                    EditMaxPayRate: function (dataObj) {
                        vm.InfoMsg(null);
                        dataObj.lohType = "maxPay";
    
                        if (CarrierRates().PageViewModel().Methods().ValidateCarrierRate() == false && vm.CarrierRateModel().Id() > 0 == false) {
                            vm.InfoMsg("Please correct any Carrier Rate Entry error(s) before adding/editing.");
                            return false;
                        }
    
                        CarrierRates().Store.CarrierLOHRateModel(new KOLOHRateModel(dataObj))
                        vm.$ChildModels.LOHRatesEntryVM(new LOHRatesEntryVM(vm));
                    },
                    RefreshTargetMarginGrid: function () {
                        if (_targetMarginGrid.length)
                            _targetMarginGrid.jqxGrid('updatebounddata', 'data');
                    },
                    RefreshMaxPayRateGrid: function () {
                        if (_maxPayRateGrid.length)
                            _maxPayRateGrid.jqxGrid('updatebounddata', 'data');
                    },
                    RefreshGrids: function () {
                        vm.Methods().RefreshMaxPayRateGrid();
                        vm.Methods().RefreshTargetMarginGrid();
                    },
                    DeleteTargetMarginRate: function (targetMarginId) {
                        vm.InfoMsg(null);
    
                        if (targetMarginId == null) return false;
                        if (vm.isProcessing()) return false;
    
                        CarrierRates().GetConfirmation({
                            message: "Are you sure you want to delete?"
                        }).then(function (x) {
                            if (x) {
                                var payload = {
                                    LOHTargetMarginId: targetMarginId,
                                    LOHMaxPayRateId: null,
                                    carrierRateId: vm.CarrierRateModel().Id()
                                };
    
                                vm.isProcessing(true);
    
                                vm.HTTP.DeleteLOHRateAsync(payload).then(function (isDeleted) { vm.isProcessing(false); vm.Methods().RefreshTargetMarginGrid() }).catch(function (err) { vm.isProcessing(false) });
                            }
                        });
                    },
                    DeleteMaxPayRate: function (maxPayRateId) {
                        if (maxPayRateId == null) return false;
                        if (vm.isProcessing()) return false;
    
    
                        CarrierRates().GetConfirmation({
                            message: "Are you sure you want to delete?",
                        }).then(function (x) {
                            if (x) {
                                var payload = {
                                    LOHTargetMarginId: null,
                                    LOHMaxPayRateId: maxPayRateId,
                                    carrierRateId: vm.CarrierRateModel().Id()
                                };
    
                                vm.isProcessing(true);
    
                                vm.HTTP.DeleteLOHRateAsync(payload).then(function (isDeleted) { vm.isProcessing(false); vm.Methods().RefreshMaxPayRateGrid() }).catch(function (err) { vm.isProcessing(false) });
                            }
                        });
                    },
                }
            },
            HTTP: {
                DeleteLOHRateAsync: function (payload) {
                    if (!payload) return Promise.reject(false);
    
                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates/LOHRate/Delete", "POST", payload).done(function (isDeleted) {
                            resolve(isDeleted);
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }
    
                            CarrierRates().LogError(err);
                        });
                    });
                },
            },
            $Grids: [
                '#jqxLOHTargetMarginGrid',
                '#jqxLOHMaxPayRateGrid'
            ],
            $Parent: parentModel || {},
            $ChildModels: {
                LOHRatesEntryVM: ko.observable()
            }
        }
    }
}

class LOHRatesEntryVM {
    constructor(parentModel) {
        return {
            KOLOHRateModel: CarrierRates().Store.CarrierLOHRateModel,
            Errors: ko.observableArray([]),
            InfoMsg: ko.observable(),
            isProcessing: ko.observable(),
            UX: function () {
                var vm = this;
    
                return {
                    ModalHeader: ko.computed(function () {
                        if (vm.KOLOHRateModel().LOHType() == 'targetMargin') {
                            return 'LOH Target Margin';
                        }
                        else {
                            return 'LOH Maximum Pay';
                        }
                    }),
                    RateMarginLabel: ko.computed(function () {
                        if (vm.KOLOHRateModel().LOHType() == 'targetMargin') {
                            return 'Target Margin';
                        }
                        else {
                            return 'Maximum Pay Rate';
                        }
                    })
                };
            },
            Methods: function () {
                var vm = this;
    
                return {
                    Save: function () {
                        if (vm.KOLOHRateModel().LOHType() == "targetMargin") {
                            vm.Methods().SaveAsTargetRate();
                        }
                        else {
                            vm.Methods().SaveAsMaxPayRate();
                        }
                    },
                    SaveAsTargetRate: function () {
                        if (vm.KOLOHRateModel().isValid() == false) {
                            vm.Errors(vm.KOLOHRateModel().ValidationErrors());
                            return false;
                        }
                        vm.isProcessing(true);
    
                        vm.Methods().SaveCarrierRate().then(function (isSuccess) {
                            if (isSuccess) {
                                vm.KOLOHRateModel().CarrierRateId(CarrierRates().Store.CarrierRateModel().Id());
                                vm.HTTP.PostLOHTargetRateAsync(vm.KOLOHRateModel().MapToJSON()).then(function (resp) {
                                    vm.isProcessing(false);
    
                                    if (resp && resp.validationErrors) {
                                        vm.Errors.push(resp.validationErrors);
                                    }
                                    else {
                                        $('#lohRatesEntryModal').modal('hide');
                                        vm.$Parent.Methods().RefreshGrids();
                                    }
                                }).catch(function (err) {
                                    vm.isProcessing(false);
    
                                    if (typeof err === 'string')
                                        vm.Errors.push(err);
                                });
                            }
                        }).catch(function (err) {
                            vm.isProcessing(false); if (typeof err === 'string') vm.Errors.push(err)
                        });
                        
                    },
                    SaveAsMaxPayRate: function () {
                        if (vm.KOLOHRateModel().isValid() == false) {
                            vm.Errors(vm.KOLOHRateModel().ValidationErrors());
                            return false;
                        }
    
                        vm.isProcessing(true);
    
                        vm.Methods().SaveCarrierRate().then(function (isSuccess) {
                            if (isSuccess) {
                                vm.KOLOHRateModel().CarrierRateId(CarrierRates().Store.CarrierRateModel().Id());
    
                                vm.HTTP.PostLOHMaxPayRateAsync(vm.KOLOHRateModel().MapToJSON()).then(function (resp) {
                                    vm.isProcessing(false);
    
                                    if (resp && resp.validationErrors) {
                                        vm.Errors.push(resp.validationErrors);
                                    }
                                    else {
                                        $('#lohRatesEntryModal').modal('hide');
                                        vm.$Parent.Methods().RefreshGrids();
    
                                    }
                                }).catch(function (err) {
                                    vm.isProcessing(false);
    
                                    if (typeof err === 'string')
                                        vm.Errors.push(err);
                                });
                            }
                        }).catch(function (err) {
                            vm.isProcessing(false); if (typeof err === 'string') vm.Errors.push(err)
                        });
    
                    },
                    SaveCarrierRate: function () {
                        if (CarrierRates().Store.CarrierRateModel().Id() != null) {
                            CarrierRates().PageViewModel().Methods().ValidateCarrierRate();
                            return Promise.resolve(true);
                        }
                            
    
                        return CarrierRates().PageViewModel().Methods().InitNewCarrierRate();
                    }
                };
            },
            HTTP: {
                PostLOHTargetRateAsync: function (payload) {
                    if (!payload) return Promise.reject(false);
    
                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates/LOHTargetMargin", "POST", payload).done(function (resp) {
                            resolve(resp);
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }
    
                            CarrierRates().LogError(err);
                        });
                    });
                },
                PostLOHMaxPayRateAsync: function (payload) {
                    if (!payload) return Promise.reject(false);
    
                    return new Promise(function (resolve, reject) {
                        dataModel.ajaxRequest("CarrierRates/LOHMaxPayRate", "POST", payload).done(function (resp) {
                            resolve(resp);
                        }).fail(function (err) {
                            CarrierRates().isLoading(false);
                            if (err.status == 400 && err.responseJSON != null) {
                                if (err.responseJSON.message != null && err.responseJSON.message.indexOf("EXCEPTION") == -1) {
                                    reject(err.responseJSON.message);
                                }
                            }
                            else {
                                reject("An error occurred. Please try again or contact help desk.");
                            }
    
                            CarrierRates().LogError(err);
                        });
                    });
                }
            },
            $Parent: parentModel || {},
            $ChildModels: {}
        }
    }
}

// Classes KO Models ********************************************************************
class KOCarrierRateModel {
    constructor(dataObj) {
        var self = this;
        dataObj = dataObj || {};
        
        self.Id = ko.observable(dataObj.id);
        self.AgencyId = ko.observable(dataObj.agencyId);
        self.CurrentRateNumber = ko.observable(dataObj.id != null ? dataObj.id : "--");
        self.BillToCustomer = ko.observable(dataObj.billToCustomer);
        self.Commodity = ko.observable(dataObj.commodity);
        self.TrailerType = ko.observable(dataObj.trailerType);
        self.EffectiveDate = ko.observable(dataObj.effectiveDate);
        self.ExpirationDate = ko.observable(dataObj.expirationDate);

        self.DaysOfTheWeek = {
            Sunday: ko.observable(dataObj.sunday != null ? dataObj.sunday : true),
            Monday: ko.observable(dataObj.monday != null ? dataObj.monday : true),
            Tuesday: ko.observable(dataObj.tuesday != null ? dataObj.tuesday : true),
            Wednesday: ko.observable(dataObj.wednesday != null ? dataObj.wednesday : true),
            Thursday: ko.observable(dataObj.thursday != null ? dataObj.thursday : true),
            Friday: ko.observable(dataObj.friday != null ? dataObj.friday : true),
            Saturday: ko.observable(dataObj.saturday != null ? dataObj.saturday : true)
        };

        self.LOHRates = ko.observableArray([]);
        self.LaneRates = ko.observableArray([]);
        self.Comments = ko.observableArray([]);

        // Setters (Overrides)
        self.EffectiveDate.subscribe(function (x) {
            if (x && (x instanceof Date)) {
                x.setHours(0, 0, 0, 0);
            }
        });

        self.ExpirationDate.subscribe(function (x) {
            if (x && (x instanceof Date)) {
                x.setHours(0, 0, 0, 0);
            }
        });

        self.Id.subscribe(function (x) {
            if (x) {
                self.CurrentRateNumber(x);
            }
            else {
                self.CurrentRateNumber("--");
            }
        });

        // Utils, Validation
        self.MapToJSON = function () {
            var _jsonObj = { ...ko.toJS(self) };
            _jsonObj.CustomerId = _jsonObj.BillToCustomer ? _jsonObj.BillToCustomer.id : null;
            _jsonObj.TrailerTypeId = _jsonObj.TrailerType ? _jsonObj.TrailerType.id : null;
            _jsonObj.CommodityId = _jsonObj.Commodity ? _jsonObj.Commodity.id : null;

            return _jsonObj;
        }

        self.ValidationErrors = ko.observableArray([]);
        self.isValid = function () {
            self.Validate();

            return self.ValidationErrors().length == 0;
        }

        self.Validate = function () {
            self.ValidationErrors.removeAll();

            var koErrors = ko.validation.group(self);

            if (koErrors().length > 0) {
                self.ValidationErrors.push("Please correct any errors before submitting the request.");
                koErrors.showAllMessages();
            }

            if (self.EffectiveDate() && self.ExpirationDate()) {
                var effDate = dayjs(self.EffectiveDate()),
                    expDate = dayjs(self.ExpirationDate());
                
                if(effDate.isValid() == false)
                    self.ValidationErrors.push("Effective Date is not a valid date.");

                if(expDate.isValid() == false)
                    self.ValidationErrors.push("Expiration Date is not a valid date.");
                
                if(effDate.isAfter(expDate))
                    self.ValidationErrors.push("Expiration Date must be later than the Effective Date.");
            }

            return self.ValidationErrors();
        }
    }
}

class KOCarrierLaneRateModel {
    constructor(dataObj) {
        var self = this;
    dataObj = dataObj || {};

    self.Id = ko.observable(dataObj.id);
    self.CarrierRateId = ko.observable(dataObj != null && dataObj.carrierRateId != null ? dataObj.carrierRateId : null);
    self.Description = ko.observable(dataObj.description).extend({ maxLength: 250 });
    self.OriginCode = ko.observable(CarrierRates().GetOriginDestinationType(dataObj.originType).value);
    self.DestinationCode = ko.observable(CarrierRates().GetOriginDestinationType(dataObj.destinationType).value);
    
    self.OriginValue = ko.observable(dataObj.originValue).extend({
        required: {
            onlyIf: function () {
                if (ko.unwrap(self.selectedOriginCode) != null) {
                    return true;
                }
                return false;
            }
        }
    });

    self.DestinationValue = ko.observable(dataObj.destinationValue).extend({
        required: {
            onlyIf: function () {
                if (ko.unwrap(self.DestinationCode) != null) {
                    return true;
                }
                return false;
            }
        }
    });

    var _setInitTargetPayMethod = CarrierRates().GetTargetMaxPayRatesType(dataObj.targetPayMethod).value || CarrierRates().RateMethods[0].value;
    self.TargetPayMethod = ko.observable(_setInitTargetPayMethod);

    var _setInitMaxPayMethod = CarrierRates().GetTargetMaxPayRatesType(dataObj.maxPayMethod).value || CarrierRates().RateMethods[0].value;
    self.MaximumPayMethod = ko.observable(_setInitMaxPayMethod);

    self.TargetPayRate = ko.observable(tryParseFloat(dataObj.targetPayRate)).extend({
        required: true,
        maxLength: 12,
        min: 0.01
    });
    self.MaximumPayRate = ko.observable(tryParseFloat(dataObj.maxPayRate)).extend({
        required: true,
        maxLength: 12,
        min: 0.01
    });

    var _setTimesUsed = dataObj.timesUsed ? dataObj.timesUsed : 0;
    self.TimesUsed = ko.observable(_setTimesUsed);

    var _lastUsedDateMomentObj = new dayjs(dataObj.lastUsedDate);
    var _lastUsedDate = _lastUsedDateMomentObj.isValid() && dataObj.lastUsedDate ? _lastUsedDateMomentObj.format("L") : null;
    self.LastUsedDate = ko.observable(_lastUsedDate);
    self.PointToPoint = ko.observable(dataObj.pointToPoint);

    // Setters
    var _originalOriginCode = self.OriginCode();
    var _originalOriginValue = self.OriginValue();
    self.OriginCode.subscribe(function (val) {
        if (val && (self.DestinationCode() != "L" || val != "L")) { // both origin and destination must have "L" Location selected
                self.PointToPoint(false);
        }

        if (val && val != _originalOriginCode) { // On code change reset value
            self.OriginValue(null);
        }
        else if (val && val == _originalOriginCode) {
            self.OriginValue(_originalOriginValue);
        }
    });

    var _originalDestinationCode = self.DestinationCode();
    var _originalDestinationValue = self.DestinationValue();
    self.DestinationCode.subscribe(function (val) {
        if (val && (self.OriginCode() != "L" || val != "L")) { // both origin and destination must have "L" Location selected
            self.PointToPoint(false);
        }

        if (val && val != _originalDestinationCode) { // On code change reset value
            self.DestinationValue(null);
        }
        else if (val && val == _originalDestinationCode) {
            self.DestinationValue(_originalDestinationValue);
        }
    });
    
    self.PointToPoint.subscribe(function (val) {
        if (val && self.OriginCode() != "L" || self.DestinationCode() != "L") {
            self.PointToPoint(false);
        }
    });

    function tryParseFloat(val) {
        if (!val) return null;

        try {
            var _p = parseFloat(val);
            return isNaN(_p) ? null : _p;
        }
        catch (e) {
            return null;
        }
    }

    // Utils, Validations
    self.MapToJSON = function() { 
        var _jsonObj = { ...ko.toJS(self) };
        
        _jsonObj.DestinationType = _jsonObj.DestinationCode;
        _jsonObj.DestinationValue = _jsonObj.DestinationValue ? (typeof _jsonObj.DestinationValue === 'object' && _jsonObj.DestinationValue.code ? _jsonObj.DestinationValue.code : _jsonObj.DestinationValue) : null;
        _jsonObj.OriginType = _jsonObj.OriginCode;
        _jsonObj.OriginValue = _jsonObj.OriginValue ? (typeof _jsonObj.OriginValue === 'object' && _jsonObj.OriginValue.code ? _jsonObj.OriginValue.code : _jsonObj.OriginValue) : null;
        _jsonObj.MaxPayMethod = _jsonObj.MaximumPayMethod;
        _jsonObj.MaxPayRate = _jsonObj.MaximumPayRate;
        
        return _jsonObj;
    }

    self.ValidationErrors = ko.observableArray([]);
    self.isValid = function () {
        self.Validate();

        return self.ValidationErrors().length == 0;
    }

    self.Validate = function () {
        self.ValidationErrors.removeAll();

        var koErrors = ko.validation.group(self);

        if (koErrors().length > 0) {
            self.ValidationErrors.push("Please correct any errors before submitting the request.");
            koErrors.showAllMessages();
        }

        if (self.OriginCode() == null && self.DestinationCode() == null)
            self.ValidationErrors.push("You must make an Origin and/or Destination selection.");

        if ((self.OriginCode() != "L" && self.DestinationCode() != "L") && self.PointToPoint() == true)
            self.ValidationErrors.push("Point To Point can only be selected when both the Origin and Destination Locations are entered.");

        return self.ValidationErrors();
    }
    }
}

class KOCarrierRateSearchModel {
    constructor(dataObj) {
        var self = this;
        dataObj = dataObj || {};
    
        self.SearchType = ko.observable(dataObj.searchType != null ? dataObj.searchType : "rateNumber");
        self.SearchValue = ko.observable(dataObj.searchValue).extend({
            required: {
                onlyIf: function () {
                    if (ko.unwrap(self.SearchType) != "listAll") {
                        return true;
                    }
                    return false;
                }
            }
        });
    
        // Setters (Overrides)
        
    
        // Utils, Validation
        self.MapToJSON = function () {
            return ko.toJS(self);
        }
    
        self.ValidationErrors = ko.observableArray([]);
        self.isValid = function () {
            self.Validate();
    
            return self.ValidationErrors().length == 0;
        }
    
        self.Validate = function () {
            self.ValidationErrors.removeAll();
    
            var koErrors = ko.validation.group(self);
    
            if (koErrors().length > 0) {
                self.ValidationErrors.push("Please correct any errors before submitting the request.");
                koErrors.showAllMessages();
            }
    
            return self.ValidationErrors();
        } 
    }
}

class KOLOHRateModel {
    constructor(dataObj) {
        var self = this;
    dataObj = dataObj || {};
    
    self.Id = ko.observable(dataObj.id);
    self.CarrierRateId = ko.observable(dataObj.carrierRateId);
    self.MileageStart = ko.observable(dataObj.mileageStart).extend({ required: true, min: 0, max: 9999 });
    self.MileageEnd = ko.observable(dataObj.mileageEnd).extend({ required: true, min: 0, max: 9999 });
    self.RateMargin = ko.observable(dataObj.rateMargin).extend({ required: true, min: 0, max: 999.99 });

    self.LOHType = ko.observable(dataObj != null && dataObj.lohType != null ? dataObj.lohType : "targetMargin");

    // Setters (Overrides)


    // Utils, Validation
    self.MapToJSON = function () {

        var _jsonObj = { ...ko.toJS(self) };
        _jsonObj.LOHTargetMarginId = _jsonObj.LOHType == "targetMargin" ? _jsonObj.Id : null;
        _jsonObj.LOHMaxPayRateId = _jsonObj.LOHType != "targetMargin" ? _jsonObj.Id : null;

        return _jsonObj;
    }

    self.ValidationErrors = ko.observableArray([]);
    self.isValid = function () {
        self.Validate();

        return self.ValidationErrors().length == 0;
    }

    self.Validate = function () {
        self.ValidationErrors.removeAll();

        var koErrors = ko.validation.group(self);

        if (koErrors().length > 0) {
            self.ValidationErrors.push("Please correct any errors before submitting the request.");
            koErrors.showAllMessages();
        }

        if (self.MileageStart() && self.MileageEnd()) {
            if (self.MileageStart() > self.MileageEnd())
                self.ValidationErrors.push("Mileage Start '" + self.MileageStart() + "' must be less than Mileage End '" + self.MileageEnd() + "'");
        }

        return self.ValidationErrors();
    }
    }
}

class KOCarrierRateComment {
    constructor(dataObj) {
        var self = this;
        dataObj = dataObj || {};
    
        self.Id = ko.observable(dataObj.id);
        self.CarrierRateId = ko.observable(dataObj.carrierRateId);
        self.Comments = ko.observable(dataObj.comments);
        self.EnteredByUserId = ko.observable(dataObj.enteredByUserId);
        self.EnteredDate = ko.observable(dataObj.enteredDate);
        self.EnteredByUsername = ko.observable(dataObj.enteredByUsername);
    
        // Setters (Overrides)
    
    
        // Utils, Validation
        self.MapToJSON = function () {
            return ko.toJS(self);
        }
    
        self.ValidationErrors = ko.observableArray([]);
        self.isValid = function () {
            self.Validate();
    
            return self.ValidationErrors().length == 0;
        }
    
        self.Validate = function () {
            self.ValidationErrors.removeAll();
    
            var koErrors = ko.validation.group(self);
    
            if (koErrors().length > 0) {
                self.ValidationErrors.push("Please correct any errors before submitting the request.");
                koErrors.showAllMessages();
            }
    
            return self.ValidationErrors();
        }
    }
}

import template from './carrier-rates-component.html';
export default { viewModel: CarrierRatesVM, template: template }