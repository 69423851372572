import ko from "knockout";
import dataModel from "data-model";
import $ from "jquery";
import "notifyjs-browser";
import gridStateUtils from "jqx.grid-utils";
import template from "./jqx.grid-state-component.html";
import toast from 'geNotyf';
import { showconfirm } from "show-dialog-methods";
import { GenerateGuid } from "global-functions";

class GridStateComponentViewModel {
    overrideCustomGridModalOptionsSave;
    loadSaveSearchModalOverride;
    $gridEl;
    setDefaultSearchOverride;

    constructor(pageName) {
        var self = this;
        self.action = undefined;
        self.refresh = undefined;
        self.pageName = pageName || "";
        self.gridOptionData = ko.observable(); // param for grid options component
        self.extraActionHandlers = [];
        self.onOpen = function() {}; // placeholder call back function when action list is opened. override this in the grid. (accepts js event arg)
        
        self.saveGridFilters = (grid, filters) => {
            var gridState = grid.jqxGrid("getstate");
            var state = { grid: gridState };

            if (filters != undefined) $.extend(state, filters);

            filters = JSON.stringify(state);

            pageName = self.pageName ? self.pageName : window.location.pathname;
            pageName = pageName.indexOf("/") > -1 ? pageName.slice(1, pageName.length) : pageName;
            var data = {
                name: grid.attr("id"),
                pageName: pageName,
                filters: filters,
                searchName: ""
            };

            dataModel.ajaxRequest("UserProfile/SaveUserSearchFilters", "post", data).done(function (data, status, xhr) {
                toast.open({
                    type: "info",
                    message: "Grid state has been saved"
                })
            });

            return state;
        };
        
        self.actionSelected = function (event) {
            var grid = self.$gridEl || $(event.target).closest("div[role='grid']");
        
            var args = event.args;
            if (args) {
                var item = args.item;
                if (item.value == "Clear Filters") {
                    if (self.clearFilters != null) {
                        self.clearFilters(grid);
                    } else {
                        grid.jqxGrid("clearfilters");
                    }
                } else if (item.value == "Clear Selection") {
                    if (self.clearSelection != null) {
                        self.clearSelection();
                    } else {
                        grid.jqxGrid("clearselection");
                    }
                } else if (item.value == "Refresh") {
                    if (self.refresh != null) {
                        self.refresh();
                    } else {
                        grid.jqxGrid("updatebounddata", "data"); //refresh grid without clearing filters
                    }
                } else if (item.value == "Set Default Search") {
                    if(self.setDefaultSearchOverride != null) {
                        self.setDefaultSearchOverride();
                    }
                    else if (self.setDefaultSearch != null) {
                        var filters = self.setDefaultSearch();
                        self.saveGridFilters(grid, filters);
                    } else {
                        self.saveGridFilters(grid);
                    }
                } else if (
                    item.value == "Show/Hide/Reorder Columns" ||
                    item.value == "Show / Hide Columns" ||
                    item.value == "Show/Hide Columns"
                ) {
                    self.gridOptionData({ gridId: grid.attr("id") });
                } else if (item.value == "Save Search") {
                    if(self.loadSaveSearchModalOverride !=  null) {
                        self.loadSaveSearchModalOverride();
                    }
                    else {
                        self.loadSaveSearchModal(grid);
                    }
                } else {
                    if (self.action != null) {
                        self.action(item.value);
                    }
                    else if(self.extraActionHandlers.length) {
                        const actionHandler = self.extraActionHandlers.find(extraActionHandler => extraActionHandler['action'] == item.value);
                        
                        if(actionHandler && (typeof actionHandler.handler === 'function')) {
                            actionHandler.handler();
                        }
                    }
                }

                $("#" + event.currentTarget.id).jqxDropDownList("clearSelection");
            }
        };

        var saveSearchModalCallback = null;
        self.loadSaveSearchModal = function (grid, callback, selectList, additionalFilters, showOverridePrompt) {
            console.log(`Filters to save:`, additionalFilters)
            saveSearchModalCallback = typeof callback == 'function' ? callback : function() {};
            selectList = selectList || [];

            let modalId = grid.attr("id") + "SaveSOSearch";

            // remove existing so we can rebuild modal
            $("#" + modalId).remove();

            let div = $("<div>", { id: modalId});
            div.addClass("modal fade in");
            $("body").append(div);

            let savedSearchInputId = GenerateGuid(6);

            let inputPlaceholderTest = "Enter a new name...";
            let $form = $("<div></div>", {class: 'form-group'});
            let inputBox = $("<input>", { type: "text", id: savedSearchInputId, class: 'form-control', placeholder: inputPlaceholderTest}).wrap($form);
            inputBox.jqxInput();

            let modalHeader = $("<div>", { class: "modal-header" });
            let modalBody = $("<div>", { class: "modal-body" });
            let modalContent = $("<div>", { class: "modal-content" });
            let modalDialog = $("<div>", { class: "modal-dialog" });
            modalHeader.append('<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>');
            modalHeader.append("<strong>Save Search Name</strong>");
            modalBody.css("overflow-y", "auto");
            div.css("overflow-x", "auto");
            modalDialog.css("width", "300px");
            div.wrapInner(modalBody);
            div.prepend(modalHeader);
            div.wrapInner(modalContent);
            div.wrapInner(modalDialog);
            div.modal({ show: true, backdrop: "static" });

            let $errorMsg = $('<p></p>', { class: 'text-danger', style: 'font-size: 12px; padding: 3px;' });


            function optionTemplate(x) {
                return `<option value="${x.id}">${x.text}</option>`;
            }
            let savedSearchSelectListId = GenerateGuid(6);

            function resetForm() {
                
                inputBox.val("");
                inputBox.attr('placeholder', inputPlaceholderTest);
                $("#" + savedSearchSelectListId).val('0').change();
            }

            if(selectList.length) {

                

                let $selectDDL = $(`<div class="form-group">
                    <select id="${savedSearchSelectListId}" class="form-control">
                        <option selected value="0">Select Existing...</option>
                        ${selectList.map((x) => optionTemplate(x))}
                    </select>
                </div>
                <div>
                    <p class="divider-with-text">OR</p>
                </div>
                `);

                modalBody.append($selectDDL);

                $("#" + savedSearchSelectListId).on("change", function(e) {
                    $errorMsg.text("");
                    if(e.currentTarget.value != "") {
                        inputBox.val("");
                        inputBox.attr('placeholder', inputPlaceholderTest);
                    }

                });

            }

            modalBody.append(inputBox);
            modalBody.append($errorMsg);

            let $clearBtn = $(`<div style="float: right; margin-right: 5px;">
                <input type="button" value="Clear"  class="jqx-rc-all jqx-rc-all-GreatWide jqx-button jqx-button-GreatWide jqx-widget jqx-widget-GreatWide jqx-fill-state-normal jqx-fill-state-normal-GreatWide" />
            </div>`);

            $clearBtn.on("click", function(e) {
                resetForm();
            });
            //let savedSearchSubmitButtonId = GenerateGuid(6);
            let $button = $(
                `<div style="float: right;">
                    <input type="submit" value="Submit" class="jqx-rc-all jqx-rc-all-GreatWide jqx-button jqx-button-GreatWide jqx-widget jqx-widget-GreatWide jqx-fill-state-normal jqx-fill-state-normal-GreatWide" />
                </div>`
            )
            $button.click(async (val) => {

                $errorMsg.text("");
                let selectedSearchId = $("#" + savedSearchSelectListId).val();
                let enteredSearchName =  ($("#" + savedSearchInputId).val() ?? "").trim();
                
                if(!enteredSearchName && (selectedSearchId == "0" || !selectedSearchId)) {
                    $errorMsg.text("Please select from list or enter a new name.");
                    $("#" + savedSearchInputId).val("");
                    return false;
                }
                
                if(selectList.length) {
                    let alreadyExists = selectList.some((x) => (enteredSearchName && x.text) && (enteredSearchName.toUpperCase() === x.text.toUpperCase()));

                    if((alreadyExists && showOverridePrompt) || selectedSearchId != "0") {
                        const yes = await showconfirm(`Do you want to override?`);
    
                        if(yes === false) {
                            return false;
                        }

                        if(selectedSearchId != "0") {
                            let selectedItem = selectList.find((x) => x.id == selectedSearchId) || {};
                            enteredSearchName = selectedItem.text;
                        }
                    }
                    else if(alreadyExists && selectedSearchId == "0") {
                        $errorMsg.text("Name already exists. Please select from the existing saved searches.");
                        return false;
                    }
                    else if(selectedSearchId != "0") {
                        let selectedItem = selectList.find((x) => x.id == selectedSearchId) || {};
                        enteredSearchName = selectedItem.text;
                    }
                }
                 
                self.saveSearchWithName(grid, enteredSearchName, selectedSearchId, additionalFilters).then((x) => {
                    saveSearchModalCallback({
                        id: x.id,
                        filters: x.filters,
                        searchname: enteredSearchName
                    });

                    $(".close").click();
                    resetForm();
                }).catch((err) => {
                    $errorMsg.text(err);
                });
                
                
            });

            modalBody.append($button);
            modalBody.append($clearBtn);

            div.on("shown.bs.modal", function () {
                //$("#savedSearchInputBox").focus();
                $("#" + savedSearchInputId).on("keydown", function (e) {
                    if (e.which == 13) {
                        //$("#savedSearchSubmitButton").click();
                        $button.click();
                    }
                    else if($("#" + savedSearchSelectListId).val() != "0") {
                        $("#" + savedSearchSelectListId).val('0').change();
                    }
                });
            });

        };

        self.saveSearchWithName = function (grid, searchName, id, additionalFilters) {
            return new Promise((resolve, reject) => {
                let pageName =  window.location.pathname;
                var gridState = grid.jqxGrid("getstate");
                var state = { grid: gridState, userSearchName: searchName, additionalFilters };
                var filters = JSON.stringify(state);
                var data = {
                    id: id || 0,
                    name: grid.attr("id"),
                    pageName: pageName.slice(1, pageName.length),
                    filters: filters,
                    searchName,
                    isDefault: additionalFilters.isDefault
                };
                
                dataModel.ajaxRequest("UserProfile/SaveUserSearchFiltersWithName", "post", data).done(function (response, status, xhr) {
                    toast.open({
                        type: "info",
                        message: "Grid state has been saved"
                    })
                    resolve(response);
                }).fail((err) => reject(err.responseJSON && err.responseJSON.message || `Error occurred during request.`));
            });
            
        };

        this.clearFilters = function (grid) {
            grid.jqxGrid("clearfilters");
        };

        this.setDefaultSearch = null;

        this.initializeDefaultToolbar = (toolbar) => {
            $(toolbar).append(template);
            
            //In order to create another instance of this component
            //we need to clear the cached defination or
            //knockout will throw an error.
            ko.components.clearCachedDefinition("jqx.grid-state-component");
            ko.applyBindingsToDescendants(this, toolbar[0]);
        };

        // The action dropt list items (default)
        /** @type Array<string> | ko.ObservableArray<string> */
        self.actions = ["Clear Filters", "Clear Selection", "Refresh", "Set Default Search", "Show/Hide/Reorder Columns"];

    }

    
}

export default { viewModel: GridStateComponentViewModel, template: template };
