/// <reference path="../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/jqwidgets.d.ts" />
import template from './agent-default-rates-grid-component.html';
import ko, { Observable } from 'knockout';
import gridStateUtils from 'jqx.grid-utils';
import GreatEdgeCustomWidgets from 'ge.custom-widgets';
import GridStateComponentViewModel from 'jqx.grid-state-component';
import { AgentDefaultRatesPage } from '../agent-default-rates-page';
import dataModel from 'data-model';
import $ from 'jquery';



class AgentDefaultRatesGridComponent {

    grid: jqwidgets.jqxGrid;
    GECustomWidgets = new GreatEdgeCustomWidgets();
    #jqxGridHelper = this.GECustomWidgets.GEWidgetsGridHelper('agentRatesGrid');
    $parent: AgentDefaultRatesPage
    gridState: any;
    includeInactive: Observable<boolean> = ko.observable(false);

    constructor(data: any) {
        data.parent.agentDefaultRatesGrid = this;
        this.$parent = data.parent;
        this.getSaveFilters()
        .then((x: string) => {
            if(x) {
                let obj = JSON.parse(x) || {};
                this.gridState = obj.grid;
                let hasInactiveFilter = Object.keys(this.gridState.filters).some((x) => this.gridState.filters[x] == "isActive")
                //if the isActive filter exists at all, then it's going to be true.
                this.includeInactive(hasInactiveFilter);
            }
            this.includeInactive.subscribe(this.isInactiveSubscribeFunction)
            this.buildGrid();
        })
    }

    buildGrid() {
        this.grid = jqwidgets.createInstance("#agentRatesGrid", "jqxGrid", this.gridOptions)
    }

    isInactiveSubscribeFunction = (value) => {
        if(value) {
            let filtergroup = new $.jqx.filter();
            let filter = filtergroup.createfilter("booleanfilter", !value, 'EQUAL')
            filtergroup.addfilter(1, filter)
            this.grid.addfilter("isActive", filtergroup, false);
            this.grid.applyfilters();
        } else {
            this.grid.removefilter('isActive', false)
            this.grid.applyfilters();
        }
    }

    getSaveFilters = () : Promise<string> => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", { name: "agentRatesGrid", pageName: window.location.pathname })
            .done((x) => {
                resolve(x);
            })
        })
    }

    get dataAdapter() {
        return dataModel.getDataAdapter(this.gridSource)
    }

    get gridOptions(): jqwidgets.GridOptions {
        return {
            source: this.dataAdapter,
            columns: this.columns,
            theme: 'GWTMDark',
            width: '100%',
            height: 400,
            altrows: true,
            showfilterrow: true,
            sortable: true,
            filterable: true,
            columnsreorder: true,
            columnsmenu: false,
            columnsresize: true,
            showstatusbar: true,
            statusbarheight: 25,
            pageable: true,
            showtoolbar: true,
            virtualmode: true,
            autoshowfiltericon: true,
            autoheight: true,
            rendergridrows: (obj) => {
                return obj.data;
            },
            rendertoolbar: (toolbar) => {
                let vm1 = new GridStateComponentViewModel.viewModel();
                vm1.clearFilters = () => {
                    this.#jqxGridHelper.clearDateRangeFiltersFromGrid()
                    // Must be applied in this order or jqx grid throws error
                    this.grid.clearfilters()
                    this.grid.updatebounddata('data');
                }
                vm1.initializeDefaultToolbar(toolbar);
                toolbar.append($('#toolBarAddition'))
            },
            ready: () => {
                //Below adds to the UI of the grid on initial load.  Doesn't affect what's actually going to the server.
                if(this.gridState) {
                    gridStateUtils.loadGridStateFilters($('#agentRatesGrid'), this.gridState.filters, false);
                }
                this.isInitLoad = false;
            }
        }
    }

    isInitLoad = true;
    initStateApplied = false;

    get gridSource(): jqwidgets.GridSource {
        return {
            url: 'CustomPaySolutions/GetAgencyRatesForGrid',
            datatype: 'json',
            data: {
                agencyId: dataModel.currentAgencyId()
            },
            datafields: this.datafields,
            filter: (filters, recordsArray) => {
                if(this.isInitLoad == false) {
                    this.grid.updatebounddata('filter');
                }
            },
            sort: (column, direction) => {
                if(this.isInitLoad == false) {
                    this.grid.updatebounddata('sort');
                }
            },
            processdata: (data) => {
                return data;
            },
            pager: (pagenum, pagesize, oldpagenum) => {
                if(this.isInitLoad == false) {
                    this.grid.updatebounddata('page');
                }
            },
            formatdata: (data) => {
                let filterinfo = this.grid ? this.grid.getfilterinformation() : [];
                data = gridStateUtils.formatGridFilters(filterinfo, data);
                data = this.#jqxGridHelper.addGridFiltersToDataObj(data, { dateRangePostFixes: ['Begin', 'End'] });
                if (this.isInitLoad)
                {
                    if(this.initStateApplied == false) {
                        //Adds saved grid parameters to the inital request
                        data = gridStateUtils.loadInitGridState(data, this.gridState, this.columns);
                    }
                    this.initStateApplied = true
                }
                data = this.#jqxGridHelper.addGridFiltersToDataObj(data, { dateRangePostFixes: ['Begin', 'End'] });
                if(this.includeInactive() == true) {
                    data.isActive = undefined;
                } else {
                    data.isActive = true;
                }
                this.isInitLoad = false;

                data.customPay = data.customPay == null ? null : (data.customPay ? "Yes":"No");
                return data;
            }
        }
    }

    get datafields(): jqwidgets.GridSourceDataFields[] {
        return [
            { name: "id", type: "number"},
            { name: "externalID", type: "string"},
            { name: "name", type: "string"},
            { name: "isActive", type: "string"},
            { name: "customPay", type: "boolean"},
            { name: "contractDate", type: "date"},
            { name: "terminationDate", type: "date"},
            { name: "defaultPayMethodId", type: "number"},
            { name: "defaultLoadedPayRate", type: "string"},
            { name: "defaultEmptyPayRate", type: "string"}
        ]
    }

    geDateRangeFilterWidget = (column, columnElement, widget) => {
        //set widget (class) level options
        var options = {
            gridOptions: {
                gridWidget: widget,
                gridId: 'agentRatesGrid', // required to apply gridoptions
                columnName: column.datafield, // also required, especially if this is a filter widget
                isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
                //state: this.orderGridState // can send our grid state and apply any initial filters
            }
        }
        return this.GECustomWidgets.GEDateRangePickerWidget(options); // will return widget if already exists or creates a new one;
    };

    columns: jqwidgets.GridColumn[] = [
        { 
            text: '', pinned: true, columntype: "button", width: 100, filterable: false,
            cellsrenderer: () => { return "Edit Rates" }, 
            buttonclick: (row) => {
                let rowdata = this.grid.getrowdata(row);
                this.$parent.agentDefaultRatesModal(rowdata);
            }
        },
        {
            text: "Agency",
            datafield: "externalID"
        },
        {
            text: "Agency Name",
            datafield: "name",
            filterable: true,
            sortable: true
        },
        {
            text: "Status",
            datafield: "isActive",
            filterable: false,
            sortable: true,
            cellsrenderer: (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
                let $default = $(defaulthtml);
                $default.html(value == true ? "Active" : "Inactive")
                return $default[0].outerHTML;
            }
        },
        {
            text: 'Default Rates', 
            datafield: 'customPay',
            width: 200,
            filtertype: 'bool', // checkbox
            columntype: 'checkbox', 
            editable: false
        //     filtertype: "list",
        //     filteritems: [{ value: true, label: "Yes" }, { value: false, label: "No" }],
        //     cellsrenderer: (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
        //         let $default = $(defaulthtml);
        //         $default.html(value == true ? "Yes" : "No")
        //         return $default[0].outerHTML;
        //     }
        },
        {
            text: "Hire Date",
            datafield: "contractDate",
            width: '215px',
            filterable: true,
            sortable: true,
            editable: false,
            cellsformat: 'MM/dd/yyyy',
            createfilterwidget: this.geDateRangeFilterWidget
        },
        {
            text: "Termination Date",
            datafield: "terminationDate",
            filterable: true,
            sortable: true,
            editable: false,
            cellsformat: 'MM/dd/yyyy',
            createfilterwidget: this.geDateRangeFilterWidget
        }
    ]
}


export default {viewModel: AgentDefaultRatesGridComponent, template: template}
export { AgentDefaultRatesGridComponent }