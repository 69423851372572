import dataModel from 'data-model';

import {showmessage} from 'show-dialog-methods';
import {mobilecheck, noop} from 'global-functions';
import dayjs from 'dayjs';


const fetchTrackingUrls = (orderId) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Order/TntTrackingMapLocations", "GET", { orderId }, true)
        .done((resolve))
        .fail((err) => reject((err.responseJSON && err.responseJSON.message) || `An error occurred during request.`))
    })
}

function OrderEntryTrackingMapViewModel({
    orderId = undefined,
    isLoading = noop
} = {}) {
    const vm = this;

    vm.currentView = ko.observable();
    vm.movementTrackingUrls = ko.observableArray([]);

    $('#trackingMapModal').modal('show');

    const load = async () => {
        try {
            isLoading(true);
            const result = await fetchTrackingUrls(orderId);
            isLoading(false);

            vm.movementTrackingUrls(result || []);
            vm.currentView(result[0])
        }
        catch(err) {
            isLoading(false);
            console.log(err);
        }
    }
    
    load();
}

import template from './order-entry-tracking-map-component.html';
export default {viewModel: OrderEntryTrackingMapViewModel, template: template}