import { mobilecheck } from "global-functions";

ko.bindingHandlers.geWindow = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var modelValue = valueAccessor(),
            allBindings = allBindingsAccessor(),
            header = allBindings.header,
            subheader = allBindings.subheader,
            width = allBindings.width,
            modalSize = allBindings.modalSize,
            onClose = allBindings.onClose,
            setStaticMode = allBindings.setStaticMode || false,
            restrictAutoOpen = allBindings.restrictAutoOpen || false,
            align = allBindings.align;

        var div = $(element, { style: 'display: none' }); // Set default styling, if need to extend/becomes large then can move to a css .class in styleheet
        
        // Bootstrap Fade In is buggy with IE11
        // Bug: IE11 -> Open Modal > Close Modal > Open again/other modal > displays transparent behind modal backdrop
        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode; // detect browser

        if (isIE11) {
            div.addClass('modal');
        }
        else {
            div.addClass('modal fade in');
        }

        if (setStaticMode) {
            div.attr({ 'data-backdrop': 'static' });
        }

        if(modelValue && typeof(modelValue) == 'function') {
            modelValue.subscribe((x) => {
                if(!x) {
                    $(div).modal('hide');
                }
            })
        }

        // Hack to enable multiple modals (modal open, then another modal opens on top)
        // by making sure the '.modal-open' class
        // is set to the <body> when there is at least one modal left in view.
        // Removing this will cause any underlying modals to not be scrollable when
        // a second or third modal is closed.
        //See if this works any better.
        $(div).on('hidden.bs.modal', function () {
            $(this).removeClass('show')
            if ($('.modal.show').length > 0) {
                $('body').addClass('modal-open');
            } else {
                $('body').removeClass('modal-open');
                $(".modal-backdrop").remove();
            }
            if (ko.isWriteableObservable(modelValue)) {
                modelValue(undefined);
            }
            if (onClose != null) {
                onClose();
            }
        });
        var modalDialog
        var modalHeader = $("<div>", {
            "class": "modal-header"
        });
        var modalBody = $("<div>", {
            "class": "modal-body"
        });
        var modalContent = $("<div>", {
            "class": "modal-content"
        });

        if (align) {
            modalDialog = $("<div>", {
                "class": "modal-dialog vertical-align-center"
            });
        }
        else {
            modalDialog = $("<div>", {
                "class": "modal-dialog"
            });
        }

        if(window.innerWidth > 767) {
            var divHelper = $("<div>", {
                "class": "vertical-alignment-helper"
            });
        }
        

        var closeButton = $("<button>", {
            "type" : "button", 
            "class": "close", 
            "data-dismiss": "modal",
            "aria-hidden": "true"
        })
        closeButton.html("&times;");

        closeButton.on("click", function(e) {
            if ($('div.modal.show').length == 1 && $('.modal-backdrop.in').length > 0) {
                $('body').removeClass('modal-open');
                $(".modal-backdrop").remove();
            }
        })

        if(element.id) {
            closeButton.attr("id", element.id + "closeButton");
        }
        modalHeader.append(closeButton);
        if (header) {
            modalHeader.append("<h4 style='position:absolute;top:6px;'><strong id='modalHeaderText'>" + header + "</strong></h4>");
        } else {
            modalHeader.append("<h4 style='position:absolute;top:6px;'><strong id='modalHeaderText'></strong></h4>");
        }

        // sub header displays to the right of the modal window -> from right 50px to account for the dismiss button
        if (subheader != undefined && subheader != "")
        {
            modalHeader.append("<h4 style='position:absolute; top: 6px; right: 50px;' id='modelSubHeaderText'><strong>" + subheader + "</strong></h4>");
        }
        div.css("overflow-x", "inherit");
        modalHeader.css("border-bottom-color", "#7c6464");

        if(mobilecheck() == false) {
            if (width != null) {
                modalDialog.css("width", width);
            } else if (modalSize != null) {
                modalDialog.addClass('modal-' + modalSize);
            }
        }
        div.wrapInner(modalBody);
        div.prepend(modalHeader);
        div.wrapInner(modalContent);
        div.wrapInner(modalDialog);
        if (align) {
            div.wrapInner(divHelper);
        }
        div.modal({ show: false });
        return ko.bindingHandlers["with"].init(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext);
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var modelValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        header = allBindings.header,
        restictAutoOpen = allBindings.restrictAutoOpen || false,
        value = ko.utils.unwrapObservable(modelValue);
        
        var $element = $(element);

        if (typeof (header) === 'function')
            if(header() !== $element.find('.modal-header').find('h4').text())
                $element.find('.modal-header').find('h4').html(header())

        if (value && restictAutoOpen == false) {
            $element.modal('show');
        }
    }
};