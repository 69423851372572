import dataModel from 'data-model';

// Action types
const SEND_WIRE = "wireAdvances/send_wire";
const SET_ADVANCE_ABILITY = "wireAdvances/set_advance_ability";
const GET_ADVANCE_ABILITY = "wireAdvances/get_advance_ability";
const CLEAR_SEND_WIRE = "wireAdvances/clear_sendWireData";

// Action creators
export const setAdvanceAbility = ({ advanceAbilityEnabled, advanceAbilityLockedOut, aopLimit, fundingLimit }) => {
    return { type: SET_ADVANCE_ABILITY, payload: { advanceAbilityEnabled, advanceAbilityLockedOut, aopLimit, fundingLimit } }
}

// This async pattern is available when using 'useDispatch' utility from 
// ko provider
export const getAdvanceAbility = () => async(dispatch, state) => {

    try { // always making ajax call to get recent data here. Incase user was locked out, then we can handle that and prevent them from making wires downstream, etc.
        const { advanceAbilityEnabled, advanceAbilityLockedOut, aopLimit, fundingLimit }  = await dataModel.ajaxRequest("User/AdvanceControls", "GET", null, true) || {advanceAbilityEnabled: false, advanceAbilityLockedOut: false, aopLimit: 0, fundingLimit: 0};
        
        dispatch({type: SET_ADVANCE_ABILITY, payload: { advanceAbilityEnabled, advanceAbilityLockedOut, aopLimit, fundingLimit } });
    }
    catch(err) {
       console.error(`Error occurred while fetching advance data.`);
    }

    return { type: GET_ADVANCE_ABILITY }
}

export function sendWire(data) {
    return { type: SEND_WIRE, payload: data }
}

export function clearSendWireData() {
    return { type: CLEAR_SEND_WIRE }
}

// Updater / reducer
export default function wireAdvances(wireAdvance = {}, action) {
    switch(action.type) {
        case SET_ADVANCE_ABILITY:
            return { ...wireAdvance, advanceAbility: action.payload }
        case SEND_WIRE:
            return {...wireAdvance, sendWireData: action.payload } // If conflict prop names, then last one wins.
        case CLEAR_SEND_WIRE:
            return {...wireAdvance, sendWireData: undefined }
        default:
            return wireAdvance; // GET_ADVANCE_ABILTIY will hit here since we didn't provide a case
    }
}