import dataModel from "data-model";
import { isEmpty } from "global-functions";
import gridStateUtils from "jqx.grid-utils";

export const actionItemsList =
  (initList = []) =>
  (additionalItems = []) =>
    [...initList, ...additionalItems];

export const getSelectedRowData = ($grid) => {
  return () => {
    return (
      $grid.jqxGrid("getrowdata", $grid.jqxGrid("getselectedrowindex")) || {}
    );
  };
};

export const geDateRangeFilterWidget =
  (geDateWidget, gridId) => (column, columnElement, widget) => {
    //set widget (class) level options
    var options = {
      gridOptions: {
        gridWidget: widget,
        gridId: gridId, // required to apply gridoptions
        columnName: column.datafield, // also required, especially if this is a filter widget
        isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
        //state: this.orderGridState // can send our grid state and apply any initial filters
      },
    };

    return geDateWidget.GEDateRangePickerWidget(options);
  };

export const getCSVFromJson = (jsonData, name) =>
  dataModel.exportToCSV(jsonData, name, true);

export const mapGridDate = (date = "") => {
  return date && date.length ? date.substr(0, date.indexOf("T") + 9) : "";
};

export const mapToYesNo = (value) => {
  return value ? "Yes" : "No";
};

export const originCityStateZip = (city = "", state = "", zip = "") => {
  return zip && zip.length > 0
    ? `${city}, ${state} (${zip})`
    : `${city}, ${state}`;
};

export const lockGridScroll = ($grid) => {
  $('div[id*="jqxScrollThumbhorizontalScrollBar"]').css("visibility", "hidden");
  $('div[id*="jqxScrollBtnUphorizontalScrollBar"]').css("visibility", "hidden");
  $('div[id*="jqxScrollBtnDownhorizontalScrollBar"]').css(
    "visibility",
    "hidden"
  );
  // prevent the grid scrolling with the mousewheel
  var position = $(".jqx-grid").jqxGrid("scrollposition");
  var left = position.left;
  var top = position.top;
  $grid.bind("mousewheel", (e) => {
    $grid.jqxGrid("scrolloffset", top, left);
    return false;
  });
};

export const unlockGridScroll = ($grid) => {
  $grid.unbind("mousewheel");
  $('div[id*="jqxScrollThumbhorizontalScrollBar"]').css(
    "visibility",
    "inherit"
  );
  $('div[id*="jqxScrollBtnUphorizontalScrollBar"]').css(
    "visibility",
    "inherit"
  );
  $('div[id*="jqxScrollBtnDownhorizontalScrollBar"]').css(
    "visibility",
    "inherit"
  );
};

export const refreshGrid = ($grid) => {
  $grid.jqxGrid("clearselection");
  $grid.jqxGrid("updatebounddata", "data");
  $grid.jqxGrid("updatebounddata", "sort");

  return $grid;
};

export const setUserFilters = async (gridId) => {
  let filters = await gridStateUtils
    .getUserGridFiltersAsync(gridId)
    .catch((err) => console.error("Failed to retrieve user filters."));
  if (isEmpty(filters) == false) {
    let state = JSON.parse(filters);
    return state;
  }

  return {};
};

export const renderGrid = ({
  gridId = "",
  options = {
    source: {},
    toolbarrenderer: function () {},
    columns: [],
    onReadyCallBackFn: function () {},
    pageable: true,
  },
}) => {
  const $grid = $("#" + gridId);
  var dataAdapter = dataModel.getDataAdapter(options.source);

  $grid.jqxGrid({
    theme: "GWTMDark",
    width: "100%",
    source: dataAdapter,
    altrows: true,
    sortable: true,
    pageable: options.pageable,
    filterable: true,
    height: 660,
    pagesize: 20,
    //autoheight: screen.height >= 800,
    showfilterrow: true,
    columnsresize: true,
    columnsreorder: true,
    enablebrowserselection: true,
    columnsmenu: false,
    showtoolbar: true,
    //autoshowfiltericon: true,
    virtualmode: false,
    rendertoolbar: options.toolbarrenderer,
    rendergridrows: (obj) => {
      return obj.data;
    },
    showstatusbar: options.pageable == false,
    statusbarheight: 25,
    renderstatusbar: (statusbar) => {
      const $div = $("<div>", { style: "padding: 4px;" });
      const $count = $(`<span>`);

      $count.text(`Number of Records: ${$grid.jqxGrid("getrows").length} `);
      $div.append($count);

      $grid.on("filter", () => {
        $count.text(`Number of Records: ${$grid.jqxGrid("getrows").length} `);
      });

      $grid.on("bindingcomplete", () => {
        $count.text(`Number of Records: ${$grid.jqxGrid("getrows").length} `);
      });

      statusbar.append($div);
    },

    columns: options.columns,

    ready: options.onReadyCallBackFn(),
  });
};

export const applyGridState = (gridId, state = {}) =>
  gridStateUtils.applyGridState(gridId, state);

export const gridHeaders = (gridId) =>
  gridStateUtils.formatGridHeaders(gridId, false);
