
import L from 'mapquest';
import config from 'config';

class MapQuestModel {
    constructor() {
        var self = this;
         L.mapquest.key = config.mapquestApiKey;

         self.mapId = "";
         self.map = {};
         self.markers = {};

        self.buildPoi = function (id, latitude, longitude, info, iconIndex, iconColor) {
             var icon = L.icon({
                 iconSize: [20, 29],
                 iconUrl: "http://assets.mapquestapi.com/icon/v2/marker-" + iconColor + "-" + iconIndex + ".png"
                //  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png',
                //  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
             });
             self.markers.addLayer(L.marker([latitude, longitude], { icon: icon, riseOnHover: true, alt: iconIndex, title: iconIndex }).bindPopup(iconIndex + ": " + info));
        };

         self.addCollectionPoiToMap = function (obj) {
             return new Promise(function(resolve, reject){
                var markerPoints = [];                
                for (var i = 0; i < obj.length; i++) {
                    // create array of latLng for use in setting view bounds later
                    markerPoints.push(L.latLng(obj[i].latitude, obj[i].longitude));
                    // create and add markers for each point
                    self.buildPoi(obj[i].id, obj[i].latitude, obj[i].longitude, obj[i].info, obj[i].title, obj[i].iconColor);
                }
   
                // add marker group to map
                self.map.addLayer(self.markers);
   
                // display bounds of map
                var bounds = new L.latLngBounds(markerPoints);
                //console.log("setting bounds :" + bounds.getNorthEast().toString() + ", " + bounds.getSouthWest().toString());                       
                self.map.fitBounds(bounds);  //, { padding: [10, 25] } );  // padding 
               
                //console.log("actual bounds: " + self.map.getBounds().getNorthEast().toString(), self.map.getBounds().getSouthWest().toString());
                //console.log("zoom = " + self.map.getZoom());

                return resolve(true);
             })           
        };

         self.clearMap = function () {
             // clear any pre-existing data
             if (self.mapId != "") {
                 self.markers.clearLayers();

                //self.map = {};
                self.map.setView([35.64701, -95.39425], 5)
             }
         }

         self.loadMap = function (mapId, options) {
             options = options || 
             {
                layers: L.mapquest.tileLayer('map'), //L.mapquest.mapLayer(),
                zoom: 10,                     /*initial zoom level of map*/
                minZoom: 3,
                center: [40.0, -100.0],       /*initial center of map in latitude,longitude*/
                trackResize: true,
                fitBounds: true
            };

            self.clearMap();
            self.mapId = mapId;
            self.map = L.map(mapId, options);  /* initial map state options */

            // L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            //     attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            // }).addTo(self.map);

            self.markers = new L.LayerGroup();

         };
    }
}

export default new MapQuestModel();