import dataModel from "data-model";
import "./challengePageStyles.css";
import gridState from "jqx.grid-state-component";
import gridUtils from "jqx.grid-utils";
import { isEmpty, formatPhoneNumber, mobileAndTabletcheck, createDateTimeFilterWidget } from "global-functions";

var inspectionNote = function (data, parentModel) {
    var self = this;
    var parentModel = parentModel || {};
    var data = data || {};
    var inspectionId = data.inspectionId || 0;

    self.comment = ko.observable(data.comment || "").extend({ required: true });
    self.modelError = ko.observable();
    self.displayConfirmDelete = ko.observable(false);
    self.isEdit = ko.observable(data.isEdit || false);
    self.noteId = ko.observable(data.noteId || 0);

    self.saveNote = function () {
        self.modelError("");

        var validationErrors = ko.validation.group(self);

        if (validationErrors().length > 0) {
            validationErrors.showAllMessages();
            return false;
        }

        var data = {
            comment: self.comment(),
            inspectionId: inspectionId,
            id: self.noteId(),
        };

        dataModel.ajaxRequest("Challenge/SaveInspectionNote", "POST", data).done(function (response) {
            if (response.success == false) self.modelError(response.message);

            if (response.success == true) {
                parentModel.displayNoteForm(false);
                parentModel.reloadInspectionNotesGrid();
            }
        });
    };

    self.deleteNote = function () {
        if (self.noteId() > 0) {
            dataModel.ajaxRequest("Challenge/DeleteInspectionNote/" + self.noteId(), "DELETE").done(function (response) {
                if (response.success == false) self.modelError(response.message);

                if (response.success == true) {
                    parentModel.displayNoteForm(false);
                    parentModel.reloadInspectionNotesGrid();
                }
            });
        }
    };
};

// Main Grid that is loaded on the page
var challengeGridModel = function (parentModel) {
    var self = this;
    parentModel = parentModel || {}; // parent model here is the main view model (root)
    var gridId = "#jqxChallengeGrid";
    var grid = $(gridId);
    var isPageInit = false;

    //=================================================
    // Search Results Grid
    //----------------------------------------------

    self.updateGridData = function () {
        $(gridId).jqxGrid("updatebounddata");
    };

    self.reloadGrid = function () {
        $(gridId).jqxGrid("refresh");
    };

    self.clearGrid = function () {
        $(gridId).jqxGrid("clear");
    };

    // Returns the selected challenge status ID
    self.getChallengeStatusFilter = function (filterData) {
        var challengeSelected = filterData.challengeStatus || "";

        switch (challengeSelected) {
            case "New":
                return 1;
            case "Pending":
                return 2;
            case "Approved - Remove Violation":
                return 3;
            case "Approved - Remove Roadside":
                return 4;
            case "Denied by Management":
                return 5;
            case "Denied by DATAQ":
                return 6;
            default:
                return null;
        }
    };

    self.createCityStateZipFilterWidget = function (column, columnElement, widget) {
        widget.jqxInput({
            placeHolder: "City, State, Zip",
        });
    };

    //self.setGridHeight = function () {
    //    var winHeight = window.screen.height;

    //    if (winHeight >= 850)
    //        return 520 + "px";

    //    return winHeight - 180 + "px";

    //}

    self.loadGridState = function (grid, state) {
        if (state != null) {
            var gridState = grid.jqxGrid("getstate");
            state.selectedrowindex = -1;
            state.selectedrowindexes = [];
            state.pagenum = 0;
            state.height = gridState.height;
            state.pagesize = gridState.pagesize;
            state.pagesizeoptions = gridState.pagesizeoptions;

            grid.jqxGrid("loadstate", state);
        } else {
            grid.jqxGrid("applyfilters");
        }
    };

    self.gridState = null;
    self.loadGridData = function () {
        var source = {
            url: "Challenge/GetChallengesForGrid",
            datatype: "json",
            type: "GET",
            beforeprocessing: function (data) {
                source.totalrecords = data.totalCount;
            },
            filter: function (filters, recordsArray) {
                if (isPageInit == false) {
                    grid.jqxGrid("updatebounddata", "filter");
                }

                isPageInit = false;
            },
            sort: function (column, direction) {
                grid.jqxGrid("updatebounddata", "sort");
            },
            formatdata: function (data) {
                var filterinfo = grid.jqxGrid("getfilterinformation");
                data = gridUtils.formatGridFilters(filterinfo, data);

                // Convert the ddl select
                data.challengeStatus = self.getChallengeStatusFilter(data);

                return data;
            },
            datafields: [
                { name: "id", type: "number" },
                { name: "roadsideExId", type: "string" },
                { name: "dateRequested", type: "date" },
                { name: "roadsideDate", type: "date" },
                { name: "personRequesting", type: "string" },
                { name: "challengeStatus", type: "string" },
                { name: "phone", type: "string" },
                { name: "altPhone", type: "string" },
                { name: "email", type: "string" },
            ],

            loadComplete: function (data) {},
            loadError: function (xhr, status, error) {},
        };

        var dataSource = dataModel.getDataAdapter(source);

        grid.jqxGrid({ source: dataSource });
    };

    self.buildGrid = function () {
        var columns = [
            {
                text: "",
                width: 80,
                filterable: false,
                sortable: false,
                pinned: true,
                editable: false,
                columntype: "button",
                buttonclick: function (row, event) {
                    var datarow = grid.jqxGrid("getrowdata", row);

                    parentModel.openChallengeDetails(datarow);
                },
                cellsrenderer: function () {
                    return "Details";
                },
            },
            {
                text: "Roadside Id",
                datafield: "roadsideExId",
                width: 250,
                cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                    if (defaultHTML != null) {
                        var cell = $(defaultHTML);
                        cell.html('<a href="ChallengeRequest/' + value + '" style="color:blue; "  >' + value + "</a>"); //target=\"_blank\"
                        return cell[0].outerHTML;
                    }

                    return defaultHTML;
                },
            },
            {
                text: "Date Requested",
                dataField: "dateRequested",
                cellsformat: "MM/dd/yyyy",
                columntype: "datetimeinput",
                filtertype: "date",
                width: 150,
                createfilterwidget: createDateTimeFilterWidget,
            },
            {
                text: "Roadside Date",
                dataField: "roadsideDate",
                cellsformat: "MM/dd/yyyy",
                columntype: "datetimeinput",
                filtertype: "date",
                width: 150,
                createfilterwidget: createDateTimeFilterWidget,
            },
            { text: "Person Requsting", datafield: "personRequesting", width: 150 },
            {
                text: "Challenge Status",
                datafield: "challengeStatus",
                filtertype: "list",
                filteritems: [
                    { label: "New" },
                    { label: "Pending" },
                    { label: "Approved - Remove Violation" },
                    { label: "Approved - Remove Roadside" },
                    { label: "Denied by Management" },
                    { label: "Denied by DATAQ" },
                ],
            },

            {
                text: "Phone",
                datafield: "phone",
                width: 200,
                cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                    if (defaultHTML != null) {
                        var phoneFormated = formatPhoneNumber(value);
                        var cell = $(defaultHTML);
                        cell.html(phoneFormated);
                        return cell[0].outerHTML;
                    }

                    return defaultHTML;
                },
            },
            {
                text: "Alt Phone",
                datafield: "altPhone",
                width: 200,
                cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                    if (defaultHTML != null) {
                        var phoneFormated = formatPhoneNumber(value);
                        var cell = $(defaultHTML);
                        cell.html(phoneFormated);
                        return cell[0].outerHTML;
                    }

                    return defaultHTML;
                },
            },
            {
                text: "Email",
                datafield: "email",
                width: 300,
                cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                    if (defaultHTML != null) {
                        var cell = $(defaultHTML);
                        cell.html('<a href="mailto:' + value + '"  style="color:blue; "  >' + value + "</a>");
                        return cell[0].outerHTML;
                    }

                    return defaultHTML;
                },
            },
        ];

        grid.jqxGrid({
            width: "100%",
            autoheight: true,
            altrows: true,
            sortable: true,
            pageable: true,
            pagesize: 10,
            filterable: true,
            showfilterrow: true,
            virtualmode: true,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            columns: columns,
            showtoolbar: true,
            rendertoolbar: function (toolbar) {
                var vm1 = new gridState.viewModel(),
                    tmpl1 = gridState.template;

                vm1.clearFilters = function () {
                    grid.jqxGrid("clearfilters");
                };
                vm1.refresh = function () {
                    grid.jqxGrid("updatebounddata", "data"); 
                };

                $(toolbar).append(tmpl1);
                ko.applyBindingsToDescendants(vm1, toolbar[0]);
            },
            rendergridrows: function (obj) {
                return obj.data;
            },
            ready: function () {
                self.loadGridState(grid, self.gridState);
            },
        });

        grid.on("pagesizechanged", function (event) {
            var args = event.args;
            var autoheight = mobileAndTabletcheck() || args.pagesize < 20;

            grid.jqxGrid({ height: screen.height - 370, autoheight: autoheight });
            grid.jqxGrid("updatebounddata");
        });
    };

    self.init = function () {
        // Get user filters
        dataModel
            .ajaxRequest("UserProfile/GetUserSearchFilters", "get", { name: "jqxChallengeGrid", pageName: window.location.pathname })
            .done(function (data) {
                //if user has saved filters
                if (isEmpty(data) == false) {
                    var state = JSON.parse(data);
                    self.gridState = state.grid;
                }

                self.buildGrid();
                self.loadGridData();
            });
    };

    self.init();
};

var violationsGridModel = function (parentModel) {
    var self = this;
    var parentModel = parentModel || {}; // Parent model here is the challengeDetailsModel
    var gridId = "#jqxViolationsGrid";

    //=================================================
    // Details Grid
    //----------------------------------------------

    self.updateGridData = function () {
        $(gridId).jqxGrid("updatebounddata");
    };

    self.reloadGrid = function () {
        $(gridId).jqxGrid("refresh");
    };

    self.clearGrid = function () {
        $(gridId).jqxGrid("clear");
    };

    self.clearSelection = function () {
        parentModel.isGridRowSelected(false);
        $(gridId).jqxGrid("clearselection");
    };

    self.destroyGrid = function () {
        $(gridId).jqxGrid("destroy");
    };

    // Violations Grid
    self.buildGrid = function (data) {
        var grid = $(gridId);

        var gridData = data || [];
        var challengeId = gridData.challengeId;

        var source = {
            //localdata: gridData,
            url: "Challenge/GetChallengeViolationsForGrid",
            datatype: "json",
            datafields: [
                { name: "inspectionId", type: "number" },
                { name: "violationId", type: "number" },
                { name: "driverExId", type: "string" },
                { name: "driverName", type: "string" },
                { name: "agencyExId", type: "string" },
                { name: "fsm", type: "string" },
                { name: "fsmIntervention", type: "boolean" },
                { name: "fsmStatus", type: "string" },
                { name: "postedDate", type: "date" },
                { name: "inspectionDate", type: "date" },
                { name: "citation", type: "string" },
                { name: "inspection", type: "string" },
                { name: "inspectionState", type: "string" },
                { name: "unitType", type: "string" },
                { name: "inspectionLevel", type: "number" },
                { name: "basic", type: "string" },
                { name: "section", type: "string" },
                { name: "description", type: "string" },
                { name: "oos", type: "boolean" },
                { name: "severity", type: "number" },
                { name: "points", type: "number" },
                { name: "oosWeight", type: "number" },
                { name: "timeWeight", type: "number" },
                { name: "licenseNumber", type: "string" },
                { name: "licenseState", type: "string" },
                { name: "vin", type: "string" },
                { name: "completed", type: "string" },
            ],
            formatdata: function (data) {
                var filterinfo = grid.jqxGrid("getfilterinformation");
                data = gridUtils.formatGridFilters(filterinfo, data);

                data.challengeId = challengeId;

                return data;
            },
        };

        var dataSource = dataModel.getDataAdapter(source);

        var columns = [
            {
                text: "",
                width: 80,
                filterable: false,
                sortable: false,
                pinned: true,
                editable: false,
                columntype: "button",
                buttonclick: function (row, event) {
                    var data = grid.jqxGrid("getrowdata", row);

                    if (data) {
                        parentModel.viewInspectionDetails(data);
                    }
                },
                cellsrenderer: function () {
                    return "Details";
                },
            },
            {
                text: "Driver Id",
                datafield: "driverExId",
                width: 150,
            },
            {
                text: "Name",
                datafield: "driverName",
                width: 250,
            },
            {
                text: "Agency",
                datafield: "agencyExId",
                width: 150,
            },
            {
                text: "FSM",
                datafield: "fsm",
                width: 150,
            },
            {
                text: "FSM Status",
                datafield: "fsmStatus",
                width: 150,
            },
            {
                text: "Posted Date",
                dataField: "postedDate",
                cellsformat: "MM/dd/yyyy",
                width: 200,
            },
            {
                text: "Inspection Date",
                dataField: "inspectionDate",
                cellsformat: "MM/dd/yyyy",
                width: 200,
            },
            {
                text: "Citation",
                datafield: "citation",
                width: 250,
            },
            {
                text: "Inspection State",
                datafield: "inspectionState",
                width: 150,
            },
            {
                text: "Inspection Level",
                datafield: "inspectionLevel",
                width: 150,
            },
            {
                text: "Basic",
                datafield: "basic",
                width: 250,
            },
            {
                text: "Section",
                datafield: "section",
                width: 250,
            },
            {
                text: "Description",
                datafield: "description",
                width: 500,
            },
            {
                text: "OOS",
                datafield: "oos",
                width: 150,
            },
            {
                text: "Severity",
                datafield: "severity",
                width: 150,
            },
            {
                text: "OOS Weight",
                datafield: "oosWeight",
                width: 150,
            },
            {
                text: "Time Weight",
                datafield: "timeWeight",
                width: 150,
            },
            {
                text: "Points",
                datafield: "points",
                width: 150,
            },
            {
                text: "Unit Type",
                datafield: "unitType",
                width: 150,
            },
            {
                text: "License Number",
                datafield: "licenseNumber",
                width: 150,
            },
            {
                text: "License State",
                datafield: "licenseState",
                width: 150,
            },
            {
                text: "VIN",
                datafield: "vin",
                width: 300,
            },
            {
                text: "Completed",
                datafield: "completed",
                width: 150,
            },
        ];

        grid.jqxGrid({
            width: "100%",
            source: dataSource,
            altrows: true,
            sortable: false,
            autoheight: true,
            pageable: false,
            pagesize: 20,
            filterable: false,
            showfilterrow: false,
            virtualmode: false,
            columnsresize: true,
            columnsreorder: false,
            enablebrowserselection: true,
            columnsmenu: false,
            columns: columns,
            showtoolbar: false,
        });

        grid.on("rowselect", function () {
            parentModel.isGridRowSelected(true);
        });
    };

    self.getSelectedRow = function () {
        return new Promise(function (resolve, reject) {
            var datarow = null;

            var getselectedrowindexes = $(gridId).jqxGrid("getselectedrowindexes");
            if (getselectedrowindexes.length > 0 && datarow == undefined) {
                datarow = $(gridId).jqxGrid("getrowdata", getselectedrowindexes[0]);
            }

            return resolve(datarow);
        });
    };

    self.loadGrid = function (challengeId) {
        var id = challengeId || 0;
        self.buildGrid({ challengeId: id });
    };
};

var inspectionNotesGridModel = function (parentModel) {
    var self = this;
    var parentModel = parentModel || {}; // Parent model here is the challengeDetailsModel
    var gridId = "#jqxInspectionNotesGrid";

    //=================================================
    // Details Grid
    //----------------------------------------------

    self.updateGridData = function () {
        $(gridId).jqxGrid("updatebounddata");
    };

    self.reloadGrid = function () {
        $(gridId).jqxGrid("refresh");
    };

    self.clearGrid = function () {
        $(gridId).jqxGrid("clear");
    };

    // Inspection Notes Grid
    var buildGrid = function (data) {
        var grid = $(gridId);
        var gridData = data || [];
        var inspectionId = gridData.inspectionId || 0;

        var columns = [
            {
                text: "",
                width: 100,
                filterable: false,
                sortable: false,
                pinned: true,
                columnType: "button",
                buttonclick: function (row) {
                    var datarow = grid.jqxGrid("getrowdata", row);
                    parentModel.editInspectionNote(datarow);
                },
                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {
                    return "Edit";
                },
            },
            {
                text: "Comment",
                datafield: "comment",
                width: 400,
            },
            {
                text: "Entered By",
                datafield: "enteredBy",
                width: 200,
            },
            {
                text: "Date",
                datafield: "createdDate",
                cellsformat: "MM/dd/yyyy",
                width: 200,
            },
        ];

        var source = {
            //localdata: gridData,
            url: "Challenge/GetInspectionNotesForChallengeGrid",
            datatype: "json",
            type: "GET",
            datafields: [
                { name: "comment", type: "string" },
                { name: "enteredBy", type: "string" },
                { name: "enteredById", type: "number" },
                { name: "createdDate", type: "date" },
                { name: "id", type: "number" },
            ],
            formatdata: function (data) {
                var filterinfo = grid.jqxGrid("getfilterinformation");
                data = gridUtils.formatGridFilters(filterinfo, data);

                data.inspectionId = inspectionId;

                return data;
            },
        };

        var dataSource = dataModel.getDataAdapter(source);

        grid.jqxGrid({
            width: "100%",
            source: dataSource,
            altrows: true,
            sortable: false,
            autoheight: true,
            pageable: false,
            pagesize: 20,
            filterable: false,
            showfilterrow: false,
            virtualmode: false,
            columnsresize: false,
            columnsreorder: false,
            enablebrowserselection: true,
            columnsmenu: false,
            columns: columns,
            showtoolbar: true,
            rendertoolbar: function (toolbar) {
                var noteToolbar = $("#inspectionNoteGridToolbar");
                toolbar.append(noteToolbar);
            },
        });
    };

    self.loadGrid = function (data) {
        buildGrid(data);
    };
};

var inspectionDetailsGridModel = function (parentModel) {
    var self = this;
    var parentModel = parentModel || {}; // Parent model here is the challengeDetailsModel
    var gridId = "#jqxInspectionDetailsGrid";

    //=================================================
    // Details Grid
    //----------------------------------------------

    self.updateGridData = function () {
        $(gridId).jqxGrid("updatebounddata");
    };

    self.reloadGrid = function () {
        $(gridId).jqxGrid("refresh");
    };

    self.clearGrid = function () {
        $(gridId).jqxGrid("clear");
    };

    // Inspection Details Grid
    var buildGrid = function (data) {
        var grid = $(gridId);
        var gridData = data || [];

        var source = {
            localdata: gridData,
            datatype: "array",
            datafields: [
                { name: "inspectionId", type: "number" },
                { name: "violationId", type: "number" },
                { name: "driverExId", type: "string" },
                { name: "driverName", type: "string" },
                { name: "agencyExId", type: "string" },
                { name: "fsm", type: "string" },
                { name: "fsmIntervention", type: "boolean" },
                { name: "fsmStatus", type: "string" },
                { name: "postedDate", type: "date" },
                { name: "inspectionDate", type: "date" },
                { name: "citation", type: "string" },
                { name: "inspection", type: "string" },
                { name: "inspectionState", type: "string" },
                { name: "unitType", type: "string" },
                { name: "inspectionLevel", type: "number" },
                { name: "basic", type: "string" },
                { name: "section", type: "string" },
                { name: "description", type: "string" },
                { name: "oos", type: "boolean" },
                { name: "intermodalChassis", type: "boolean" },
                { name: "driverStatus", type: "string" },
                { name: "agencyStatus", type: "string" },
                { name: "severity", type: "number" },
                { name: "points", type: "number" },
                { name: "oosWeight", type: "number" },
                { name: "timeWeight", type: "number" },
                { name: "assetNumber", type: "string" },
                { name: "licenseNumber", type: "string" },
                { name: "licenseState", type: "string" },
                { name: "vin", type: "string" },
                { name: "completed", type: "string" },
            ],
        };

        var dataSource = new $.jqx.dataAdapter(source);

        var columns = [
            {
                text: "Unit Type",
                datafield: "unitType",
                width: 200,
            },
            {
                text: "Citation",
                datafield: "citation",
                width: 200,
            },
            {
                text: "Basic",
                datafield: "basic",
                width: 200,
            },
            {
                text: "Section",
                datafield: "section",
                width: 200,
            },
            {
                text: "Description",
                datafield: "description",
                width: 500,
            },
            {
                text: "OOS",
                dataField: "oos",
                width: 150,
            },
            {
                text: "Severity",
                dataField: "severity",
                width: 200,
            },
            {
                text: "OOS Weight",
                datafield: "oosWeight",
                width: 200,
            },
            {
                text: "Time Weight",
                datafield: "timeWeight",
                width: 200,
            },
            {
                text: "Points",
                datafield: "points",
                width: 200,
            },
            {
                text: "Asset Number",
                datafield: "assetNumber",
                width: 200,
            },
            {
                text: "License Number",
                datafield: "licenseNumber",
                width: 200,
            },
            {
                text: "License State",
                datafield: "licenseState",
                width: 200,
            },
            {
                text: "VIN",
                datafield: "vin",
                width: 200,
            },
        ];

        grid.jqxGrid({
            width: "100%",
            source: dataSource,
            altrows: true,
            sortable: false,
            autoheight: true,
            pageable: false,
            pagesize: 20,
            filterable: false,
            showfilterrow: false,
            virtualmode: false,
            columnsresize: false,
            columnsreorder: false,
            enablebrowserselection: true,
            columnsmenu: false,
            columns: columns,
            showtoolbar: false,
        });
    };

    self.loadGrid = function (data) {
        buildGrid(data);
    };
};

var challengeDetailsModel = function (data, parentModel) {
    var self = this;
    var challenge = data || {};
    var parentModel = parentModel || {}; // root view model
    self.isGridRowSelected = ko.observable(false);

    var modal = $("#challengeDetailsModal");
    self.isProcessing = ko.observable(false);

    self.roadsideExId = ko.observable(challenge.roadsideId || "");
    self.violationsGridModel = ko.observable();
    self.inspectionDetails = ko.observable();
    self.displayInspectionDetails = ko.observable(false);
    self.inspectionDetailsGridModel = ko.observable();
    self.inspectionNotesGridModel = ko.observable();
    self.displayNoteForm = ko.observable(false);
    self.inspectionNote = ko.observable();

    // self.viewInspectionDetails = function () {
    //     self.violationsGridModel().getSelectedRow().then(function(data){
    //         if (data == undefined)
    //             return false;

    //         self.isProcessing(true);
    //         self.violationsGridModel().clearSelection();

    //         // Get the inspection - violations details
    //         var inspection = new inspectionDetailsModel(data.inspectionId);
    //         // Load the inner grids data
    //         self.inspectionDetailsGridModel().loadGrid(inspection.violations); // Need the json/array for setting local data on grid
    //         self.inspectionNotesGridModel().loadGrid({ inspectionId: data.inspectionId }); // All we need here is the inspectionId
    //         self.inspectionDetails(inspection);
    //         self.displayInspectionDetails(true);

    //         self.isProcessing(false);
    //     }).catch(function(err){
    //         self.isProcessing(false);
    //     });
    // }

    self.viewInspectionDetails = function (rowdata) {
        if (rowdata == undefined) return false;

        self.isProcessing(true);
        //self.violationsGridModel().clearSelection();

        // Get the inspection - violations details
        var inspection = new inspectionDetailsModel(rowdata.inspectionId);
        // Load the inner grids data
        self.inspectionDetailsGridModel().loadGrid(inspection.violations); // Need the json/array for setting local data on grid
        self.inspectionNotesGridModel().loadGrid({ inspectionId: rowdata.inspectionId }); // All we need here is the inspectionId
        self.inspectionDetails(inspection);
        self.displayInspectionDetails(true);

        self.isProcessing(false);
    };

    self.addInspectionNote = function () {
        self.displayNoteForm(true);

        var data = {
            inspectionId: self.inspectionDetails().inspectionId() || 0,
        };

        var note = new inspectionNote(data, self);
        self.inspectionNote(note, self);
    };

    self.editInspectionNote = function (data) {
        self.displayNoteForm(true);

        var data = {
            inspectionId: self.inspectionDetails().inspectionId() || 0,
            comment: data.comment,
            isEdit: true,
            noteId: data.id,
        };

        var note = new inspectionNote(data, self);
        self.inspectionNote(note, self);
    };

    self.reloadInspectionNotesGrid = function () {
        self.inspectionNotesGridModel().updateGridData();
    };

    self.cleanUpModalGrids = function () {
        self.inspectionDetailsGridModel().clearGrid();
        self.inspectionNotesGridModel().clearGrid();
    };

    // Violations (Main Outer Details Grid)
    var violationsGrid = new violationsGridModel(self);
    self.violationsGridModel(violationsGrid);

    // Violations - Inspections Grid (Inner Grid Details)
    var inspectionsGrid = new inspectionDetailsGridModel(self);
    self.inspectionDetailsGridModel(inspectionsGrid);

    // Inspection Notes (Inner Detail View)
    var inspectionNotesGrid = new inspectionNotesGridModel(self);
    self.inspectionNotesGridModel(inspectionNotesGrid);

    // Need this flag so we are not loading the violations grid
    // everytime. After the initial load, the violations grid
    // will just update the data set
    var isInitGridLoad = true;

    // Load the grid when the modal is opened and visible
    // If modal isn't visible then the grid's will not bind properly
    modal.on("shown.bs.modal", function (e) {
        if (isInitGridLoad == true) {
            self.violationsGridModel().loadGrid(challenge.id);
            isInitGridLoad = false;
        }
    });
};

var inspectionDetailsModel = function (inspectionId) {
    var self = this;

    self.violations = ko.observableArray([]);
    self.inspectionId = ko.observable(0);
    self.proofOfRepair = ko.observable(false);
    self.logReceived = ko.observable(false);
    self.originalInspection = ko.observable(false);
    self.dvirReceived = ko.observable(false);
    self.cleanInspection = ko.observable(false);
    self.fsmIntervention = ko.observable(false);
    self.fsmStatus = ko.observable();
    self.startTime = ko.observable();
    self.endTime = ko.observable();

    self.fsmInterventionCompletionDate = ko.observable();

    self.getInspection = function () {
        self.violations.removeAll();

        dataModel.ajaxRequest("Challenge/GetInspectionDetails/" + inspectionId, "GET").done(function (response) {
            if (response.success == true) {
                var result = response.result;
                var violations = result.violations;
                var inspection = result.inspection;

                self.violations(violations);
                self.inspectionId(inspection.id);
                self.proofOfRepair(inspection.proofOfRepair);
                self.logReceived(inspection.logReceived);
                self.originalInspection(inspection.originalInspection);
                self.dvirReceived(inspection.dvirReceived);
                self.cleanInspection(inspection.cleanInspection);
                self.fsmIntervention(inspection.fsmIntervention);
                self.fsmStatus(inspection.fsmStatus);
                self.startTime(inspection.startTime);
                self.endTime(inspection.endTime);

                self.fsmInterventionCompletionDate(inspection.fsmInterventionCompletionDate);
            }
        });
    };

    self.getInspection();
};

class ChallengPageViewModel {
    constructor() {
        var self = this;

        //============================
        // UI Controls
        self.isLoading = ko.observable(false);
        self.isProcessing = ko.observable(false);
        self.modelError = ko.observable();

        self.challengeGrid = ko.observable();
        self.challengeDetailsModal = ko.observable();

        //============================
        // Data

        //=========================
        // Behaviors
        self.openChallengeDetails = function (data) {
            if (data == undefined) return;

            var challengeDetails = new challengeDetailsModel(data, self);
            self.challengeDetailsModal(challengeDetails);
        };

        // Initialize the page
        self.init = function () {
            var challengeGrid = new challengeGridModel(self);
            self.challengeGrid(challengeGrid);
        };

        self.init();
    }
}

import template from "./challenge-page.html";
export default { viewModel: ChallengPageViewModel, template: template };
