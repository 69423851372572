import _ from "lodash";
import userProfile from "user-profile";
import gridState from "jqx.grid-state-component";
import { showconfirm } from "show-dialog-methods";
import { GreatEdgeCustomWidgets } from "ge.custom-widgets";
import useKoElementAsFilter from "ge-widgets/useKoElementAsFilter";
import { useDispatch } from "ko-data-store";
import { isLoading } from "dataStore-actions/appUI";
import LoadPostingService from "../loadPostingService";
import { isDeviceMobileOrTablet, datetimeUTC, groupBy } from "global-functions";
import * as ltpCommon from "../loadTractorPostingCommon";
import storageManager from "../../../../utils/storageManager";
import router from "router";
import { Notyf } from "notyf"; // Taost notificaitons -> https://github.com/caroso1222/notyf

const actionListHandlers = {
  createrOrderFromLoadHandler: (
    rowSelector,
    onCreateHandler,
    onCloseHandler,
    errorHandler = function (errorMsg) {}
  ) => {
    return {
      action: "Create Order From Load",
      handler: () => {
        const { loadId } = rowSelector() || {};
        if (loadId) {
          onCreateHandler({ id: loadId, onCloseCallBackFn: onCloseHandler });
        } else {
          errorHandler("Please select a row item from the grid.");
        }
      },
    };
  },
  radiusSearchHandler: (handlerFn, onSearchCallBackFn) => {
    return {
      action: "Radius Search",
      handler: () => {
        handlerFn({
          onSearchCallBackFn,
        });
      },
    };
  },
  postNewLoadHandler: (handlerFn) => {
    return {
      action: "Post New Load",
      handler: () => handlerFn({ isEditable: true, isOrder: false }),
    };
  },
  batchLoadPostingHandler: (handlerFn) => {
    return {
      action: "Batch Load Posting",
      handler: () => handlerFn({}),
    };
  },
  expireLoadsHandler: (handlerFn) => {
    return {
      action: "Expire All Postings",
      handler: () => handlerFn({}),
    };
  },
  projectPostingHandler: (
    rowSelector,
    onLoadCallBackFn,
    onSaveFnHandler,
    onCloseFnHandler
  ) => {
    return {
      action: "Project Posting",
      handler: () => {
        const { loadId } = rowSelector() || {};

        const modalData = {
          loadId: loadId,
          onLoadSave: onSaveFnHandler,
          onClose: onCloseFnHandler,
        };

        onLoadCallBackFn(modalData);
      },
    };
  },
  alertSettingsHandler: (handlerFn) => {
    return {
      action: "Alert Settings",
      handler: () => handlerFn({}),
    };
  },
  exportHandler: (handlerFn) => {
    return {
      action: "Export",
      handler: () => handlerFn(),
    };
  },
  callLeadExportHandler: (handlerFn) => {
    return {
      action: "Active Call Leads",
      handler: () => handlerFn(),
    };
  },
  matchMyLoad: (rowSelector, errorHandler = function (errorMsg) {}) => {
    return {
      action: "Match My Load",
      handler: () => {
        const { loadId } = rowSelector() || {};
        if (loadId) {
          const route = `ExternalBoard/v2?loadId=${loadId}`;
          const tabNames = storageManager.get("loadboardTabNames") || [];

          const w = window.open(
            route,
            tabNames.length > 1 ? tabNames[tabNames.length - 1] : tabNames[0]
          );
          w.focus();
        } else {
          errorHandler("Please select a row item from the grid.");
        }
      },
    };
  },
};

const mapCallLead = (lead = {}) => {
  return {
    OrderId: lead["orderId"] || "",
    Origin: lead["origin"] || "",
    ShipDate: lead["shipDate"]
      ? datetimeUTC(lead["shipDate"]).format("MM/DD/YYYY HH:mm")
      : "",
    Destination: lead["destination"] || "",
    Asset: lead["asset"] || "",
    AssetName: lead["assetName"] || "",
    DriverLocation: lead["driverLocation"] || "",
    DriverAvailability: lead["driverAvailability"]
      ? datetimeUTC(lead["driverAvailability"]).format("MM/DD/YYYY HH:mm")
      : "",
    ContactName: lead["contactName"] || "",
    ContactPhoneNumber: lead["contactPhoneNumber"] || "",
    ContactEmail: lead["contactEmail"] || "",
    EnteredBy: lead["enteredBy"] || "",
    LastUpdatedBy: lead["lastUpdatedBy"] || "",
    Notes: lead["notes"] || "",
  };
};

const mapDuplicateLoad = (originalLoad = {}) => {
  return Object.keys(originalLoad).reduce((newLoad, key) => {
    const keysToClear = [
      "id",
      "deliveryDate",
      "deliveryLatestDate",
      "enterDate",
      "expired",
      "orderIds",
      "pickUpDate",
      "pickUpLatestDate",
    ];
    if (keysToClear.indexOf(key) != -1) {
      newLoad[key] = undefined;
    } else {
      newLoad[key] = originalLoad[key];
    }

    return newLoad;
  }, {});
};

const onActionRowOptionSelect = (handlers = {}) => {
  return (selection, data = {}) => {
    switch (selection) {
      case "View/Edit":
        handlers["viewEdit"](data);
        break;
      case "Duplicate":
        handlers["duplicate"](data);
        break;
      case "Refresh":
        handlers["refresh"](data);
        break;
      case "Call Leads":
        handlers["callLeads"](data);
        break;
    }
  };
};

const gridActionBtn = (
  text,
  options = { type: "input", width: 78, title: "" }
) => {
  return options.type == "input"
    ? `<input title="${
        options.title
      }" type="button" style="cursor: pointer; opacity: 0.99; position: relative; top: 0%; left: 0%; padding: 0px; margin-top: 2px; margin-left: 2px; height: 28px; width: ${
        options.width
      }px" value="${text}" hidefocus="true" id="${
        text + "btn"
      }" role="button" class="jqx-rc-all jqx-rc-all-GWTMDark jqx-button jqx-button-GWTMDark jqx-widget jqx-widget-GWTMDark" aria-disabled="false">`
    : `<button title="${options.title}" style="cursor: pointer; opacity: 0.99; position: absolute; top: 0%; left: 0%; padding: 0px; margin-top: 2px; margin-left: 2px; height: 28px; width: ${options.width}px" class="jqx-rc-all jqx-rc-all-GWTMDark jqx-button jqx-button-GWTMDark jqx-widget jqx-widget-GWTMDark">${text}</button>`;
};

const renderGridActionRowMenu = (
  $target,
  items = [],
  onSelect = function (val) {}
) => {
  $("div.gridRowActionMenu").remove();

  const $parentEl = $target.is("i") ? $target.closest("button") : $target;
  $("#contentload-posting-grid").css({ "z-index": "60" });

  const $pos = $parentEl[0].getBoundingClientRect();
  const $container = $("<div>", {
    class: "gridRowActionMenu",
    style:
      "background-color: #fff; width: 100px; border: 1px solid #ccc; z-index: 9999; padding: 5px 0; box-shadow: 1px 1px 5px #ccc;",
  });
  $container.css({
    top: $pos.top + $parentEl.height(),
    left: $pos.left,
    position: "fixed",
  });

  const $list = $("<ul>");

  items.map((x) => {
    const $li = $("<li>", {
      text: x,
      style: "width: 100%; cursor: pointer; padding: 5px;",
    });
    $li.on("mouseover", () => $li.css({ "background-color": "#e0e9f5" }));
    $li.on("mouseleave", () => $li.css({ "background-color": "white" }));
    $li.on("click", () => onSelect(x));
    $list.append($li);
  });

  $container.append($list);
  $parentEl.append($container);

  $container.on("mouseleave", () => $container.remove());
  $(window).scroll(() => $container.remove());

  if (isDeviceMobileOrTablet()) {
    setTimeout(() => $container.remove(), 8000);
  } else {
    setTimeout(() => $container.remove(), 10000);
  }
};

const renderGridActionRowMobilePopup = (
  items = [],
  onSelect = function (val) {},
  header = "Options"
) => {
  $("div.gridActionPopup-wrapper").remove();

  const $pageWrapper = $("<div>", { class: "gridActionPopup-wrapper" });
  const $backdrop = $("<div>", {
    class: "gridActionPopup-backdrop",
    style:
      "opacity: .3; position: fixed; top: 0; bottom: 0; left: 0; right: 0; background-color: #333; z-index: 99997",
  });
  const $container = $("<div>", {
    class: "gridActionPopup",
    style:
      "background-color: #fff; position: fixed; bottom: -110px; left: 0; right: 0; max-height: 250px; width: 100%; border: 1px solid #ccc; z-index: 99999; padding: 5px 0; box-shadow: 1px 1px 5px #ccc;",
  });

  const $header = $("<div>", {
    text: header,
    style:
      "text-transform: uppercase; font-size: 12px; color: #555; width: 100%; padding: 5px; border-bottom: 1px solid #ddd",
  });
  const $closeBtn = $("<i>", {
    html: "&times;",
    style:
      "position: absolute;width: 30px;height: 39px;font-size: 20px;right: 5px;top: 3px;text-align: center;vertical-align: middle;cursor: pointer;",
  });
  $header.append($closeBtn);

  const $list = $("<ul>", { style: "overflow-y: auto;" });
  items.map((x) => {
    const $li = $("<li>", {
      text: x,
      style:
        "letter-spacing: 1.5px; font-size: 13px; text-align: center; text-transform: uppercase; width: 100%; cursor: pointer; padding: 15px;",
    });

    $li.on("mouseover", () => $li.css({ "background-color": "#e0e9f5" }));
    $li.on("mouseleave", () => $li.css({ "background-color": "white" }));
    $li.on("click", () => {
      $container.animate({ bottom: -110 }, "fast", () => $pageWrapper.remove());
      onSelect(x);
    });
    $list.append($li);
  });

  $container.append($header);
  $container.append($list);
  $pageWrapper.append($backdrop);
  $pageWrapper.append($container);

  $("body").append($pageWrapper);

  $container.animate({ bottom: 0 }, "fast", () => {
    $backdrop.on("click", (event) => {
      $container.animate({ bottom: -110 }, "fast", () => $pageWrapper.remove());
    });
  });

  $closeBtn.on("click", () =>
    $container.animate({ bottom: -110 }, "fast", () => $pageWrapper.remove())
  );
};

/**
 * @param {Object} params - ko binding params
 * @param {ko.Observable<Object>} params.filterData
 */
export function LoadPostingsComponent({ filterData }) {
  const vmBindings = this;
  const dispatch = useDispatch();
  const toastNotify = new Notyf({
    duration: 3000,
    position: {
      x: "right",
      y: "top",
    },
    types: [
      {
        type: "info",
        background: "#488bf7",
        icon: {
          className: "glyphicon glyphicon-info-sign",
          tagName: "i",
          color: "#fff",
        },
      },
    ],
  });

  // Gridstate + widgets init
  const geDateWidget = new GreatEdgeCustomWidgets();
  const gridName = "load-posting-grid";
  let loadsGrid = $("#" + gridName);
  let userSavedState = {};
  let searchParams = {};
  let isInit = false;
  const gridStateVM = new gridState.viewModel();
  const toolbarActions = ltpCommon.actionItemsList([...gridStateVM.actions]);
  gridStateVM.actions = ko.observableArray([]);

  const selectedRow = ltpCommon.getSelectedRowData(loadsGrid);

  // Data-bindings
  vmBindings.filterItems = filterData;
  vmBindings.batchImportModal = ko.observable();
  vmBindings.loadModal = ko.observable();
  vmBindings.expireLoadsModal = ko.observable();
  vmBindings.projectPostingModal = ko.observable();
  vmBindings.alertSettingsModal = ko.observable();
  vmBindings.callLeadsModal = ko.observable();
  vmBindings.radiusSearchModal = ko.observable();
  vmBindings.isGridProcessing = false;

  vmBindings.createOrderFromLoadModel = ko.observable();

  const customerDDLWidget = useKoElementAsFilter();
  vmBindings.customerFilter = ko.observable();
  vmBindings.handleCustomerFilter = (val) => {
    if (val) {
      const code = typeof val === "string" ? val : val.code;
      const filter = customerDDLWidget.addFilterToGrid(loadsGrid);
      filter({ datafield: "customer", values: [code] }).applyToGrid();
    } else {
      customerDDLWidget.removeFilterFromGrid(loadsGrid)("customer");
    }
  };

  // The customer ddl doesn't scroll properly when opened.
  // ..instead of the ddl scrolling up/down the grid scrolls horizontally or vertically
  vmBindings.handleCustomerFilterOpen = () =>
    ltpCommon.lockGridScroll(loadsGrid);
  vmBindings.handleCustomerFilterClose = () =>
    ltpCommon.unlockGridScroll(loadsGrid);

  vmBindings.includeSharedOrders = ko.observable(false);
  vmBindings.includeSharedOrders.subscribe((val) => {
    if (vmBindings.isGridProcessing == false) {
      vmBindings.isGridProcessing = true;
      searchParams.includeSharedOrders = val;
      ltpCommon.refreshGrid(loadsGrid);
    }
  });

  const originStatesFilter = useKoElementAsFilter($("#originStateFilter"));
  vmBindings.originSelectedStates = ko.observableArray([]);
  vmBindings.originSelectedStates.subscribe((items) => {
    if (items && items.length) {
      const filters = originStatesFilter.addFilterToGrid(loadsGrid);
      filters({ datafield: "originState", values: items }).applyToGrid();
    } else {
      originStatesFilter.removeFilterFromGrid(loadsGrid)("originState");
    }
  });

  const destinameStatesFilter = useKoElementAsFilter(
    $("#destinationStateFilter")
  );
  vmBindings.destinationSelectedStates = ko.observableArray([]);
  vmBindings.destinationSelectedStates.subscribe((items) => {
    if (items && items.length) {
      const filters = destinameStatesFilter.addFilterToGrid(loadsGrid);
      filters({ datafield: "destinationState", values: items }).applyToGrid();
    } else {
      destinameStatesFilter.removeFilterFromGrid(loadsGrid)("destinationState");
    }
  });

  const trailerTypeDDLWidget = useKoElementAsFilter(
    $("#loadPostingTrailerTypeFilter")
  );
  vmBindings.trailerTypesSelectedItems = ko.observableArray([]);
  vmBindings.trailerTypesSelectedItems.subscribe((items) => {
    if (items && items.length) {
      const filters = trailerTypeDDLWidget.addFilterToGrid(loadsGrid);
      filters({ datafield: "trailerType", values: items }).applyToGrid();
    } else {
      trailerTypeDDLWidget.removeFilterFromGrid(loadsGrid)("trailerType");
    }
  });

  vmBindings.handlePostLoadSave = (loadId) => {
    toastNotify.success({
      message: "Load posted successfully.",
      duration: 5000,
    });

    ltpCommon.refreshGrid(loadsGrid);
  };

  vmBindings.handlePostLoadExpire = (displayToast) => {
    if (displayToast) {
      toastNotify.success({
        message: "Load expired successfully.",
        duration: 5000,
      });
    }

    ltpCommon.refreshGrid(loadsGrid);
  };

  vmBindings.handleBatchImportSuccess = (loadCount) => {
    toastNotify.success({
      message: `${loadCount} Load(s) posted successfully.`,
      duration: 5000,
    });

    ltpCommon.refreshGrid(loadsGrid);
  };

  const gridSource = () => {
    return {
      url: "LoadTractorPostings/LoadPostings",
      datatype: "json",
      beforeprocessing: (records = []) => {
        records.map((x) => {
          x.enterDate = ltpCommon.mapGridDate(x.enterDate);
          x.shipDate = ltpCommon.mapGridDate(x.shipDate);
          //x.postedExternally = ltpCommon.mapToYesNo(x.postedExternally);
          //x.orderCreated = ltpCommon.mapToYesNo(x.orderCreated);
          // x.expired = ltpCommon.mapToYesNo(x.expired);
          // x.leads = ltpCommon.mapToYesNo(x.leads);
          return x;
        });
      },
      formatdata: (data) => {
        data = {};
        data.agencyId = userProfile.currentAgencyId();

        //data = gridStateUtils.formatGridFilters(loadsGrid.jqxGrid('getfilterinformation'), data);
        return { ...data, ...searchParams };
      },
      datafields: [
        { name: "loadId", type: "int" },
        { name: "orderId", type: "int" },
        { name: "terminal", type: "string" },
        { name: "enterDate", type: "date" },
        { name: "expired", type: "boolean" },
        { name: "shipDate", type: "date" },
        { name: "originCity", type: "string" },
        { name: "originState", type: "string" },
        { name: "originZip", type: "string" },
        { name: "destinationCity", type: "string" },
        { name: "destinationState", type: "string" },
        { name: "orderExId", type: "string" },
        { name: "orderCreated", type: "boolean" },
        { name: "postedBy", type: "string" },
        { name: "postedExternally", type: "boolean" },
        { name: "leads", type: "boolean" },
        { name: "trailerType", type: "string" },
        { name: "customer", type: "string" },
        { name: "postingType", type: "string" },
        { name: "agentNotes", type: "string" },
        { name: "isEditable", type: "boolean" },
        { name: "isOrder", type: "boolean" },
      ],
      loadComplete: () => {
        vmBindings.isGridProcessing = false;
      },
    };
  };

  const gridColumns = () => {
    return [
      {
        text: "",
        hidden: false,
        pinned: true,
        width: "50",
        menu: false,
        resizable: false,
        hideable: false,
        showheader: false,
        filterable: false,
        cellsrenderer: (
          row = {},
          columnfield,
          value,
          defaulthtml,
          columnproperties
        ) => {
          return gridActionBtn(`<i class="glyphicon glyphicon-th"></i>`, {
            type: "button",
            width: 45,
            title: "Options",
          });
        },
      },
      // NOTE: Leaving these below for now...
      // { text: "", hidden: false, pinned: true, width: '80', menu: false, resizable: false, hideable: false, showheader: false, filterable: false,
      //     cellsrenderer: (row = {}, columnfield, value, defaulthtml, columnproperties) => {
      //         const {isEditable} = loadsGrid.jqxGrid('getrowdata', row) || {};
      //         if(isEditable) {
      //             return gridActionBtn("View/Edit");
      //         }

      //         return defaulthtml;
      //     },

      // },
      // { text: "", hidden: false, pinned: true, width: '80', menu: false, resizable: false, hideable: false, showheader: false, filterable: false,
      //     cellsrenderer: (row = {}, columnfield, value, defaulthtml, columnproperties) => {
      //         const {isOrder} = loadsGrid.jqxGrid('getrowdata', row) || {};
      //         if(isOrder == false) {
      //             return gridActionBtn("Duplicate");
      //         }

      //         return defaulthtml;
      //     }
      // },
      // { text: "", hidden: false, pinned: true, width: '80', menu: false, resizable: false, hideable: false, showheader: false, filterable: false,
      //     cellsrenderer: (row = {}, columnfield, value, defaulthtml, columnproperties) => {
      //         const {postedExternally, isOrder} = loadsGrid.jqxGrid('getrowdata', row) || {};
      //         if(postedExternally && isOrder == false) {
      //             return gridActionBtn("Refresh");
      //         }

      //         return defaulthtml;
      //     }
      // },
      // { text: "", hidden: false, pinned: true, width: '80', menu: false, resizable: false, hideable: false, showheader: false, filterable: false,
      //     cellsrenderer: (row = {}, columnfield, value, defaulthtml, columnproperties) => {
      //         return gridActionBtn("Call Leads");
      //     }
      // },
      { text: "Origin City", datafield: "originCity", width: "200" },
      {
        text: "Origin State",
        datafield: "originState",
        width: "150",
        createfilterwidget: originStatesFilter.createfilterwidget(loadsGrid),
      },
      { text: "Destination City", datafield: "destinationCity", width: "200" },
      {
        text: "Destination State",
        datafield: "destinationState",
        width: "150",
        createfilterwidget: destinameStatesFilter.createfilterwidget(loadsGrid),
      },
      {
        text: "Ship Date",
        datafield: "shipDate",
        width: "200",
        cellsformat: "MM/dd/yyyy HH:mm",
        createfilterwidget: ltpCommon.geDateRangeFilterWidget(
          geDateWidget,
          gridName
        ),
      },
      {
        text: "Trailer Type",
        datafield: "trailerType",
        width: "200",
        createfilterwidget: trailerTypeDDLWidget.createfilterwidget(loadsGrid),
      },
      {
        text: "Customer",
        datafield: "customer",
        width: "200",
        createfilterwidget: customerDDLWidget
          .initSetup(() => {
            const $customerDDL =
              $("#customerFilter").closest("div.geAutoComplete");
            $customerDDL.css({
              margin: "5px",
              position: "absolute",
              left: "5px",
            });
            const $listBox = $customerDDL.find("ul").first();
            $listBox.css({ position: "fixed" });

            let scrollpos = $listBox.scrollTop();
            $listBox.on("wheel", (e) => {
              var delta = e.originalEvent.deltaY;

              if (delta > 0) {
                // down
                scrollpos = scrollpos + 50;
                $listBox.animate({ scrollTop: scrollpos }, 1);
              } else {
                // up
                scrollpos = scrollpos - 50;
                $listBox.animate({ scrollTop: scrollpos }, 1);
              }

              return false;
            });

            return $customerDDL;
          })
          .createfilterwidget(loadsGrid),
      },
      {
        text: "Posted Externally",
        datafield: "postedExternally",
        width: "130", //filtertype: 'list', filteritems: ["Any", "Yes", "No"]
        filtertype: "bool", // checkbox
        columntype: "checkbox",
        editable: false,
      },
      {
        text: "Order Created",
        datafield: "orderCreated",
        width: "130", //filtertype: 'list', filteritems: ["Any", "Yes", "No"]
        filtertype: "bool", // checkbox
        columntype: "checkbox",
        editable: false,
      },
      {
        text: "Posting Type",
        datafield: "postingType",
        width: "200",
        filtertype: "list",
        filteritems: [
          "Any",
          "Single",
          "Order",
          "Recurring",
          "Batch",
          "Project",
        ],
      },
      {
        text: "Expired",
        datafield: "expired",
        width: "130", //filtertype: 'list', filteritems: ["Any", "Yes", "No"]
        filtertype: "bool", // checkbox
        columntype: "checkbox",
        editable: false,
      },
      { text: "Order Id", datafield: "orderExId", width: "200" },
      {
        text: "Leads",
        datafield: "leads",
        width: "130", //filtertype: 'list', filteritems: ["Any", "Yes", "No"]
        filtertype: "bool", // checkbox
        columntype: "checkbox",
        editable: false,
      },
      { text: "Posted By", datafield: "postedBy", width: "200" },
      {
        text: "Agent Notes",
        datafield: "agentNotes",
        width: "300",
        filterable: false,
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties
        ) => {
          const $el = $(defaulthtml).text(value);
          $el.attr({ title: value });

          return $el[0].outerHTML;
        },
      },
    ];
  };

  const onReady = () => {
    return () => {
      loadsGrid.jqxGrid("clearfilters");

      ltpCommon.applyGridState(gridName, userSavedState.grid);

      if (userSavedState.trailerTypes)
        vmBindings.trailerTypesSelectedItems(userSavedState.trailerTypes);
      if (userSavedState.customer)
        vmBindings.customerFilter(userSavedState.customer);
      if (userSavedState.destinationStates)
        vmBindings.destinationSelectedStates(userSavedState.destinationStates);
      if (userSavedState.originStates)
        vmBindings.originSelectedStates(userSavedState.originStates);

      loadsGrid.on("rowselect", (event) => {
        const args = event.args || {};
        refreshActionItems(args.row || {});
      });

      const actionBtnHandler = onActionRowOptionSelect({
        viewEdit: viewEditAction,
        duplicate: duplicateAction,
        refresh: refreshLoadAction,
        callLeads: callLeadsAction,
      });

      loadsGrid.on("cellclick", function (event) {
        const args = event.args;
        const originalEvent = args.originalEvent || {};
        const rowBoundIndex = args.rowindex;
        const columnindex = args.columnindex;

        if (columnindex == 0) {
          const data = loadsGrid.jqxGrid("getrowdata", rowBoundIndex);
          const $target = $(originalEvent.target);

          let items = [];

          if (data.isEditable) {
            items.push("View/Edit");
          }

          if (data.isOrder == false) {
            items.push("Duplicate");
          }

          if (data.postedExternally && data.isOrder == false && !data.expired) {
            items.push("Refresh");
          }

          items.push("Call Leads");

          if ($target.is("i") || $target.is("button")) {
            if (window.innerHeight <= 500 || window.innerWidth <= 600) {
              renderGridActionRowMobilePopup(items, (selectedItem) =>
                actionBtnHandler(selectedItem, data)
              );
            } else {
              renderGridActionRowMenu($target, items, (selectedItem) =>
                actionBtnHandler(selectedItem, data)
              );
            }
          }
        }

        // Below if for the multi button actions -- saving in case using this instead of button dropdown list
        // const actions = ["View/Edit", "Duplicate", "Refresh", "Call Leads"];
        // const handler = onCellClick({viewEdit: viewEditAction, duplicate: duplicateAction, refresh: {}, callLeads: callLeadsAction});
        // handler(actions[columnindex], data);
      });

      loadsGrid.jqxGrid("refresh");
      loadsGrid.jqxGrid("render");

      isInit = true;
    };
  };

  const refreshActionItems = ({ isEditable = true, isOrder = false }) => {
    const extraActionItems = [];
    const handlers = [];

    const isDriverOwner =
      userProfile.isUserDriver || userProfile.isOwnerOperator;

    if (isOrder == false) {
      extraActionItems.push("Match My Load");
    }

    extraActionItems.push("Radius Search");

    if (isEditable && isOrder == false) {
      extraActionItems.push("Create Order From Load");
      handlers.push(
        actionListHandlers.createrOrderFromLoadHandler(
          selectedRow,
          vmBindings.createOrderFromLoadModel,
          () => {
            ltpCommon.refreshGrid(loadsGrid);
            vmBindings.createOrderFromLoadModel(null);
          },
          (errorMsg) => alert(errorMsg)
        )
      );
    }

    if (!isDriverOwner) {
      extraActionItems.push("Post New Load");
      extraActionItems.push("Batch Load Posting");
      extraActionItems.push("Expire All Postings");
    }

    extraActionItems.push("Project Posting");

    extraActionItems.push("Alert Settings");
    extraActionItems.push("Active Call Leads");
    extraActionItems.push("Export");

    const actionList = toolbarActions(extraActionItems);
    handlers.push(actionListHandlers.postNewLoadHandler(viewEditAction));
    handlers.push(
      actionListHandlers.radiusSearchHandler(
        vmBindings.radiusSearchModal,
        ({ radius, radiusLocation }) => {
          searchParams.radius = radius;
          searchParams.radiusLocation = radiusLocation;
          ltpCommon.refreshGrid(loadsGrid);
        }
      )
    );
    handlers.push(
      actionListHandlers.batchLoadPostingHandler(vmBindings.batchImportModal)
    );
    handlers.push(
      actionListHandlers.expireLoadsHandler(vmBindings.expireLoadsModal)
    );
    handlers.push(
      actionListHandlers.alertSettingsHandler(vmBindings.alertSettingsModal)
    );
    handlers.push(
      actionListHandlers.projectPostingHandler(
        selectedRow,
        vmBindings.projectPostingModal,
        () => ltpCommon.refreshGrid(loadsGrid),
        () => vmBindings.projectPostingModal(null)
      )
    );
    handlers.push(actionListHandlers.exportHandler(handleExportGridResults));
    handlers.push(
      actionListHandlers.callLeadExportHandler(handleCallLeadExport)
    );
    handlers.push(
      actionListHandlers.matchMyLoad(selectedRow, (errorMsg) => alert(errorMsg))
    );

    gridStateVM.actions(actionList);
    gridStateVM.extraActionHandlers = handlers;
  };

  const rendertoolbar = (toolbar) => {
    refreshActionItems({});
    var $tmpl = $("#loadPostingsToolbarExtraItems");
    toolbar.append($tmpl);

    const jqxGridHelper = geDateWidget.GEWidgetsGridHelper("load-posting-grid");

    gridStateVM.clearFilters = () => {
      searchParams = { includeSharedOrders: vmBindings.includeSharedOrders() };
      vmBindings.originSelectedStates([]);
      vmBindings.destinationSelectedStates([]);
      vmBindings.trailerTypesSelectedItems([]);

      jqxGridHelper.clearDateRangeFiltersFromGrid();
      loadsGrid.jqxGrid("clearfilters");
      vmBindings.customerFilter(null);
    };

    gridStateVM.setDefaultSearch = () => getFiltersToSave();

    gridStateVM.overrideCustomGridModalOptionsSave = () => {
      const filters = gridStateVM.setDefaultSearch();
      gridStateVM.saveGridFilters(loadsGrid, filters);
    };

    gridStateVM.refresh = () => {
      ltpCommon.refreshGrid(loadsGrid);
    };

    var $tdLoadPostingGridAction = $tmpl.find("#tdLoadPostingGridAction");
    $tdLoadPostingGridAction.append(gridState.template);
    ko.applyBindingsToDescendants(gridStateVM, $tdLoadPostingGridAction[0]);

    $tmpl.show();
  };

  const getFiltersToSave = () => {
    return {
      includeSharedOrders: vmBindings.includeSharedOrders(),
      trailerTypes: vmBindings.trailerTypesSelectedItems(),
      customer: vmBindings.customerFilter(),
      destinationStates: vmBindings.destinationSelectedStates(),
      originStates: vmBindings.originSelectedStates(),
    };
  };

  const viewEditAction = async ({
    loadId,
    isEditable,
    orderId,
    orderExId,
    isOrder,
  }) => {
    loadsGrid.jqxGrid("clearselection");
    if (!isEditable && (loadId || orderId)) return false;

    if (isOrder == false) {
      if (loadId > 0) {
        dispatch(isLoading(true));
        const details = await LoadPostingService.fetchLoadDetails(loadId);
        vmBindings.loadModal(details);
        dispatch(isLoading(false));
      } else {
        vmBindings.loadModal({});
      }
    } else if (orderId && orderExId) {
      router.navigate(`Orders/${orderExId}`, true);
    }
  };

  const duplicateAction = async ({ loadId }) => {
    loadsGrid.jqxGrid("clearselection");
    if (loadId > 0) {
      dispatch(isLoading(true));
      const details = await LoadPostingService.fetchLoadDetails(loadId);
      vmBindings.loadModal(mapDuplicateLoad(details));
      dispatch(isLoading(false));
    }
  };

  const callLeadsAction = ({
    loadId,
    orderId,
    originCity,
    originState,
    originZip,
  }) => {
    loadsGrid.jqxGrid("clearselection");
    if (loadId > 0 || orderId > 0) {
      vmBindings.callLeadsModal({
        loadId,
        orderId,
        originCity,
        originState,
        originZip,
      });
    }
  };

  const refreshLoadAction = async ({ loadId }) => {
    loadsGrid.jqxGrid("clearselection");
    if (loadId > 0 == false) return false;

    const postingBoards = await LoadPostingService.fetchPostingBoardsForLoad(
      loadId
    ).catch(() => []);

    if (postingBoards.length) {
      toastNotify.open({
        type: "info",
        message:
          "Refreshing Load. <br/> You may continue work while the load is refreshing.",
      });

      const error = await LoadPostingService.sendRefreshLoad(
        loadId,
        postingBoards.filter((x) => x.checked).map((x) => x.name)
      ).catch(
        (err) =>
          (err.responseJSON && err.responseJSON.message) ||
          "An error occurred while refreshing load."
      );

      if (error && error.length) {
        const msg =
          "Error Refreshing Load: " +
          (error.indexOf("-") > -1
            ? error.substr(error.indexOf("-") + 2)
            : error);
        toastNotify.error({
          message: msg,
          duration: 5000,
        });
      } else {
        toastNotify.success({
          message: `Load refreshed successfully.`,
          duration: 5000,
        });
      }
    }
  };

  const handleCallLeadExport = async () => {
    dispatch(isLoading(true));
    const leads = await LoadPostingService.fetchCallLeads();
    dispatch(isLoading(false));

    if (leads.length) {
      const leadsForCSV = leads.map((lead) => mapCallLead(lead));

      ltpCommon.getCSVFromJson(leadsForCSV, "Active Call Leads");
    } else {
      ltpCommon.getCSVFromJson([mapCallLead({})], "Active Call Leads");
    }
  };

  const handleExportGridResults = () => {
    showconfirm(
      "This will export only what is currently shown in the grid results. Do you want to continue?"
    ).then((isConfirmed) => {
      if (isConfirmed)
        ltpCommon.getCSVFromJson(
          ltpCommon.gridHeaders(gridName),
          "Load Postings"
        );
    });
  };

  const init = async () => {
    userSavedState = (await ltpCommon.setUserFilters(gridName)) || {};
    vmBindings.includeSharedOrders(userSavedState.includeSharedOrders || false);

    ltpCommon.renderGrid({
      gridId: gridName,
      options: {
        source: gridSource(),
        toolbarrenderer: rendertoolbar,
        columns: gridColumns(),
        onReadyCallBackFn: onReady,
        pageable: false,
      },
    });
  };

  init();
}

import template from "./load-postings-component.html";
export default { viewModel: LoadPostingsComponent, template: template };
