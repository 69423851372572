
import dataModel from 'data-model';
import userProfile from 'user-profile';
import { isEmpty } from 'global-functions';
import gridState from 'jqx.grid-state-component';

class LoadAlertsPageViewModel {
    constructor() {
        var self = this;
        self.isOrderRefresh = ko.observable(false);
        self.dataAdapter = ko.observable();
        //SET THE TRACTOR GRID INITIALLY TO HIDDEN, SO THAT ONLY THE ORDER GRID SHOWS
        self.tractorGridVisibility = ko.observable(false);
        self.orderGridState = null;
        self.tractorGridState = null;
        var _orderInitLoad = true;
        var _tractorInitLoad = true;
        self.isLoading = ko.observable(false);
        self.isOrderGridLoading = ko.observable(false);
        self.isTractorGridLoading = ko.observable(false);
        self.isRefresh = ko.observable(false);
        self.orderGridVisible = ko.observable(true);
     
        self.toggleOrders = function (event, args) {
            var $this = $(args.currentTarget);
            var isOrderGridToggled = $("#loadalertsOrderPanel").is(":visible");
            if (isOrderGridToggled == true) {
                //TURN HEADER TEXT AND GLYPHICON WHITE SLOWLY DUE TO DARK BACKGROUND WHEN DIV IS TOGGLED/CLOSED - UTILIZES JQUERY UI
                $("#orderGridHeading").animate({ color: "white" }, 1200)
                $("#tractorGridHeading").animate({ color: "black" }, 1200)
                //$this.find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "white" }, 1000)
                $("#orderToggleIcon").find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "white" }, 1200)
                $("#tractorToggleIcon").find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "black" }, 1200)
            }
            else {
                $("#orderGridHeading").animate({ color: "black" }, 1200)
                $("#tractorGridHeading").animate({ color: "white" }, 1200)
                //$this.find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "black" }, 1000)
                $("#orderToggleIcon").find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "black" }, 1200)
                $("#tractorToggleIcon").find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "white" }, 1200)
            }
            $("#loadalertsTractorPanel").animate({ height: 'toggle' }, "slow");
            $("#loadalertsOrderPanel").animate({ height: 'toggle' }, "slow");
        };

        self.toggleTractors = function (event, args) {
            var $this = $(args.currentTarget);
            var isTractorGridToggled = $("#loadalertsTractorPanel").is(":visible");
            if (isTractorGridToggled == true) {
                //TURN HEADER TEXT AND GLYPHICON WHITE SLOWLY DUE TO DARK BACKGROUND WHEN DIV IS TOGGLED/CLOSED - UTILIZES JQUERY UI
                $("#tractorGridHeading").animate({ color: "white" }, 1200)
                $("#orderGridHeading").animate({ color: "black" }, 1200)
                //$this.find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "white" }, 1000)
                $("#tractorToggleIcon").find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "white" }, 1200)
                $("#orderToggleIcon").find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up').animate({ color: "black" }, 1200)
            }
            else {
                $("#tractorGridHeading").animate({ color: "black" }, 1200)
                $("#orderGridHeading").animate({ color: "white" }, 1200)
                //$this.find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "black" }, 1000)
                $("#tractorToggleIcon").find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "black" }, 1200)
                $("#orderToggleIcon").find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down').animate({ color: "white" }, 1200)
            }
        
            $("#loadalertsOrderPanel").animate({ height: 'toggle' }, "slow");
            $("#loadalertsTractorPanel").animate({ height: 'toggle' }, "slow");
        };

        //INITIALIZE ARRAYS TO STORE DATA FROM LOADORDERALERTS AND LOADTRACTORALERTS
        self.TractorAlerts = [];
        self.OrderAlerts = [];
        self.groupedOrderAlerts = [];

        //FUNCTION TO RENDER LINKS, UTILIZED IN GRIDS, IF THE CELL CONTAINS THE TEXT "MULTIPLE", DO NOT FORMAT TO LINK
        var linkrenderer = function (row, column, value, defaulthtml, columnproperties) {
            if (value.indexOf('#') != -1) {
                value = value.substring(0, value.indexOf('#'));
            }
            if (!value.includes("Multiple")) {
                var $html = $(defaulthtml).html("<a id='customLink' target='_blank' href=" + value + ">" + 'Click for Details' + "</a>");
                return $html[0].outerHTML;
            }
        }

        //START OF ORDER ALERT GRID
        self.loadOrderAlerts = function () {
            var orderGrid = $("#orderGrid");
            var tractorGrid = $("#tractorGrid");

            var initOrderFilters = function () {
                var filtergroup1 = new $.jqx.filter();
                var filter1 = filtergroup1.createfilter('stringfilter', "Active", "equals");
                filtergroup1.addfilter(1, filter1);
                orderGrid.jqxGrid('applyfilters');
            };

            var orderSource = {
                url: "Loadboard/GetUserLoadboardOrderAlerts",
                datatype: "json",          
                formatdata: function (data) {
                    self.isOrderGridLoading(true);
                    data = data || {};
                    data.agencyId = userProfile.currentAgencyId();
                    return data;
                },

                //UTILIZE DATA RETURNED IN CALLBACK AND PERFORM THE BEFOREPROCESSING FUNCTIONS PRIOR TO RENDERING THE ORDER GRID

                beforeprocessing: function (data) {
                    //RE-INITIALIZE ARRAYS TO EMPTY SO OLD DATA IS NOT RE-RENDERED
                    self.groupedOrderAlerts = [];
                    var groupByLocation = [];
                    self.OrderAlerts = [];
            
                    //SAVING THE ORIGINAL DATA AS IT COMES THROUGH FROM API BEFORE CHANGING IT
                    localStorage.setItem('data', JSON.stringify(data));

                    //BUILD THE SELF.ORDERALERTS OBJECT - COULD USE (DATA) PARAMETER BUT CHECKING TO MAKE SURE THESE ARE ORDER ALERTS
                    $.each(data, function (index, value) {
                        if (value.isOrderAlert == true) {
                            self.OrderAlerts.push(value);
                        }
                    });

                    //DEFINE FUNCTION TO PERFORM GROUPING ON ORDER ALERTS
                    var groupFunction = function (data, key) {
                        return data.reduce(function (rv, x) {
                            let v = key instanceof Function ? key(x) : x[key];
                            let el = rv.find((r) => r && r.key === v);
                            if (el) {
                                el.values.push(x);
                            }
                            else {
                                rv.push({ key: v, values: [x] });
                            }
                            return rv;
                        },
                        []);
                    }

                   //ALL ORDER ALERTS NEED TO BE GROUPED, FIRST BY PICKUPDATE
                    var orderAlertsGroupedByDate = groupFunction(self.OrderAlerts, 'pickupDate');

                   //NEXT GROUP BY STARTLOCATION
                    for (var i = 0; i < orderAlertsGroupedByDate.length; i++) {              
                        var tempArray = orderAlertsGroupedByDate[i].values;
                        var locationArray = groupFunction(tempArray, 'startLocation');
                        groupByLocation.push(locationArray);
                    }

                    //AFTER GROUPING BY PICKUPDATE AND LOCATION, PERFORM THE FINAL GROUPING OF THE TWO GROUPS AND IF THERE IS MORE THAN ONE RECORD, RENDER 'MULTIPLE' IN GRID
                    for (var n = 0; n < groupByLocation.length; n++) {
                        for (var j = 0; j < groupByLocation[i].length; j++) {
                            if (groupByLocation[i][j].values.length > 1) {
                                var temporaryArray = (groupByLocation[i])[j].values[0];
                                //NOT CHECKING FOR NULLS HERE BECAUSE ALL OF THE CELLS SHOULD SAY MULTIPLE REGARDLESS OF WHETHER OR NOT THEY ARE NULL
                                temporaryArray.contactNumber = "Multiple";
                                temporaryArray.currentLocation = "Multiple";
                                temporaryArray.trailerType = "Multiple";
                                temporaryArray.ptaDate = "Multiple";
                                temporaryArray.tractorLink = "Multiple" + " (" + groupByLocation[i][j].values.length + " records)" ;
                                self.groupedOrderAlerts.push(temporaryArray);
                            }
                            else {
                                var temporaryArray = (groupByLocation[i])[j].values[0];
                                self.groupedOrderAlerts.push(temporaryArray);
                            }
                        }
                    };
                    
                    //CREATE VARIABLE TO PASS TO NESTED GRID
                    orderSource.orderAlertsGroupedByDate = orderAlertsGroupedByDate;
                    orderSource.orderAlertsGroupedByLocation = groupByLocation;

                    //ISREFRESH IS ESTABLISHED IN THE VM1 SELF.REFRESH() FUNCTION
                    if (self.isRefresh == true) {
                        $.notify("Grid refreshed", "info");
                    }

                    //'SOURCE' OBJECT RETURNS THE GROUPED ORDER ALERTS
                    return self.groupedOrderAlerts;
                },
                datafields: [
                    { name: 'matchId', type: 'int' },
                    { name: 'isOrderAlert', type: 'bool' },
                    { name: 'isTractorAlert', type: 'bool' },
                    { name: 'isOrder', type: 'bool' },
                    { name: 'isLoad', type: 'bool' },
                    { name: 'pickupDate', type: 'date' },
                    { name: 'startLocation', type: 'string' },
                    { name: 'endLocation', type: 'string' },
                    { name: 'currentLocation', type: 'string' },
                    { name: 'trailerType', type: 'string' },
                    { name: 'contactNumber', type: 'string' },
                    { name: 'ptaDate', type: 'date' },
                    { name: 'tractorLink', type: 'string' }
                ]
            };

            var nestedGrids = new Array();

            //CREATE THE INITROWDETAILS OBJECT WHICH CONTAINS ALL OF THE THE NESTED GRID DETAILS
            var initrowdetails = function (row, parentElement, gridElement, datarecord) {
                var orderAlertsGroupedByLocation = orderSource.orderAlertsGroupedByLocation;
                var orderAlertsGroupedByDate = orderSource.orderAlertsGroupedByDate;
                var datarow = $("#orderGrid").jqxGrid('getrowdata', row);

                //EACH RECORD IN ORDER GRID HAS A UNIQUE ID = MATCHID
                var uniqueID = $('#orderGrid').jqxGrid('getcellvalue', row, "matchId");
                var grid = $($(parentElement).children()[0]);

                // grid.addClass("gridboxshadow");
                nestedGrids[row] = grid;

                //RETRIEVE THE ORIGINAL DATA OBJECT IN ITS ENTIRETY TO FIND MATCHES FOR NESTED GRIDS
                 var originalOrderData = localStorage.getItem('data');
                 var parsedOriginalOrderData = JSON.parse(originalOrderData);

                //CREATE THE OBJECT THAT HOLDS THE MATCHING RECORD
                 var oDataArray;
                 for (var h = 0; h < parsedOriginalOrderData.length; h++) {
                     if (parsedOriginalOrderData[h].matchId == uniqueID) {
                        oDataArray = parsedOriginalOrderData[h];
                        break;
                     }
                 }
               
                //CREATE AN ARRAY OF OBJECTS THAT CONTAINS ALL OF THE RECORDS WITHIN THE ORIGINAL GROUPING THAT CONTAINS THE MATCHING RECORD
                var localData = []; 
                loop1:
                for (var i = 0; i < orderAlertsGroupedByLocation.length; i++) {
                loop2:
                    for (var j = 0; j < orderAlertsGroupedByLocation[i].length; j++) {
                loop3:
                        for (var k = 0; k < orderAlertsGroupedByLocation[i][j].values.length; k++) {
                            if (orderAlertsGroupedByLocation[i][j].values[k].matchId == uniqueID) {
                                orderAlertsGroupedByLocation[i][j].values[k] = oDataArray;
                                localData = orderAlertsGroupedByLocation[i][j].values;
                                break loop1;
                            }
                        }
                    }
                }

                var nestedSource = {
                    localdata: localData,
                    datatype: "array",
                    datafields: [
                        { name: 'matchId', type: 'int' },
                        { name: 'isOrderAlert', type: 'bool' },
                        { name: 'isTractorAlert', type: 'bool' },
                        { name: 'isOrder', type: 'bool' },
                        { name: 'isLoad', type: 'bool' },
                        { name: 'pickupDate', type: 'date' },
                        { name: 'startLocation', type: 'string' },
                        { name: 'endLocation', type: 'string' },
                        { name: 'currentLocation', type: 'string' },
                        { name: 'trailerType', type: 'string' },
                        { name: 'contactNumber', type: 'string' },
                        { name: 'ptaDate', type: 'date' },
                        { name: 'tractorLink', type: 'string' },
                    ]
                };
                
                var nestedDataAdapter = new $.jqx.dataAdapter(nestedSource);
                var nestedGridWidth = $(parentElement).width() - 10;

                if (grid != null && nestedSource.localdata.length > 1) {

                    grid.jqxGrid({

                        width: nestedGridWidth,
                        // height: pxgridheight,
                        source: nestedDataAdapter,
                        altrows: true,
                        pageable: true,
                        showstatusbar: true,
                        sortable: true,
                        filterable: true,
                        showfilterrow: true,
                        virtualmode: false,
                        columnsresize: true,
                        columnsreorder: true,
                        enablebrowserselection: true,
                        columnsmenu: false,
                        statusbarheight: 35,
                        renderstatusbar: function (statusbar) {
                            var div = $("<div>", { id: 'nestedalertRowCount', style: "padding:4px;" });
                            div.append("Number of Records: " + grid.jqxGrid('getrows').length);
                            statusbar.append(div);
                        },

                        rendergridrows: function (obj) {
                            return obj.data;
                        },

                        columns: [
                            { text: '-', datafield: 'matchId', hidden: true,},
                            { text: '-', datafield: 'isOrderAlert', hidden: true, },
                            { text: '-', datafield: 'isTractorAlert', hidden: true, },
                            { text: '-', datafield: 'isOrder', hidden: true, },
                            { text: '-', datafield: 'isLoad', hidden: true,},
                            { text: 'Pickup Date', dataField: 'pickupDate', width: 150, cellsformat: "MM/dd/yyyy" },
                            { text: 'Origin', dataField: 'startLocation', width: 180 },
                            { text: 'Destination', dataField: 'endLocation', width: 180 },
                            { text: 'Current Location', dataField: 'currentLocation', width: 150 },
                            { text: 'Trailer Type', dataField: 'trailerType', width: 150 },
                            { text: 'Contact Number', dataField: 'contactNumber', width: 150 },
                            { text: 'Tractor Availability', dataField: 'ptaDate', width: 150, cellsformat: "MM/dd/yyyy" },
                            { text: 'Link', datafield: 'tractorLink', width: 1000, cellsrenderer: linkrenderer },
                        ],
                    });
                };
            };

            var dataAdapter = dataModel.getDataAdapter(orderSource);
         
            var gridheightraw = screen.height - 460;
            var pxgridheight = screen.height - 460 + "px";
            var gridrows;
            if (gridheightraw >= 700) {
                gridrows = 20;
            }
            else if (gridheightraw >= 600) {
                gridrows = 15;
            }
            else {
                gridrows = 11;
            }           
            
            orderGrid.jqxGrid({
                theme: 'GWTMDark',
                width: '100%',
                source: dataAdapter,
                altrows: true,
                autoheight: true,
                showstatusbar: true,
                sortable: true,
                pageable: true,
                // pagesize: gridrows,
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                //use columns height to change header row along with renderer in columns[
                //columnsheight: 40,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                //columns: columns,
                statusbarheight: 35,
                showtoolbar: true,
                rowdetails: true, //This is the 'triangle' column
                // rowsheight: 50,
                initrowdetails: initrowdetails,
                rowdetailstemplate: {
                    rowdetails: "<div id='orderGrid' style='margin: 10px;'></div>",
                    rowdetailsheight: 500,
                    rowdetailshidden: true
                },
                renderstatusbar: function (statusbar) {
                    var div = $("<div>", { id: 'orderRowCount', style: "padding:4px;" });
                    div.append("Number of Records: " + orderGrid.jqxGrid('getrows').length);
                    statusbar.append(div);
                },

                rendertoolbar: function (toolbar) {

                    var orderGridToolbar = $("#orderGridToolbar");
                    toolbar.append(orderGridToolbar);

                    var vm1 = new gridState.viewModel(),
                        tmpl1 = gridState.template;

                    vm1.clearFilters = function () {
                        //isInitLoad = true;
                        $('#orderGrid').jqxGrid('clearfilters');
                        initOrderFilters();
                    };
                    vm1.setDefaultSearch = function () {
                        var filters = {};
                        return filters;
                    };

                    vm1.refresh = function () {
                       self.isRefresh = true;
                       orderGrid.jqxGrid("updatebounddata", "data"); 
                    };
                   
                    var tdOrderGridAction = orderGridToolbar.find("#tdOrderGridAction")
                    tdOrderGridAction.append(tmpl1);
                    ko.applyBindingsToDescendants(vm1, tdOrderGridAction[0]);
                },

                rendergridrows: function (obj) {
                    return obj.data;
                },
                  
                columns: [
                    { text: '-', datafield: 'matchId', hidden: true, },
                    { text: '-', datafield: 'isOrderAlert', hidden: true, },
                    { text: '-', datafield: 'isTractorAlert', hidden: true, },
                    { text: '-', datafield: 'isOrder', hidden: true, },
                    { text: '-', datafield: 'isLoad', hidden: true, },
                    { text: 'Pickup Date', dataField: 'pickupDate', width: 200, cellsformat: "MM/dd/yyyy" },
                    { text: 'Origin', dataField: 'startLocation', width: 200 },
                    { text: 'Destination', dataField: 'endLocation', width: 180 },
                    { text: 'Current Location', dataField: 'currentLocation', width: 150 },
                    { text: 'Trailer Type', dataField: 'trailerType', width: 150 },
                    { text: 'Contact Number', dataField: 'contactNumber', width: 150 },
                    { text: 'Tractor Availability', dataField: 'ptaDate', width: 150, cellsformat: "MM/dd/yyyy" },
                    { text: 'Link', datafield: 'tractorLink', cellsrenderer: linkrenderer },
                ],
                ready: function () {
                    self.loadGridState(orderGrid, self.orderGridState);
                    _orderInitLoad = false;
                }
            });

            orderGrid.on("bindingcomplete", function (event) {
                var records = orderGrid.jqxGrid('getrows');
                $('#orderRowCount').text("Number of Records: " + records.length);
            });

            orderGrid.on("filter", function (event) {
                var records = orderGrid.jqxGrid('getrows');
                $('#orderRowCount').text("Number of Records: " + records.length);
            });
        };

        self.loadTractorAlerts = function () {

            var initTractorFilters = function () {
                var filtergroup1 = new $.jqx.filter();
                var filter1 = filtergroup1.createfilter('stringfilter', "Active", "equals");
                filtergroup1.addfilter(1, filter1);
                tractorGrid.jqxGrid('applyfilters');
            };

            var tractorGrid = $("#tractorGrid");
            var tractorGridSource = {
                url: "Loadboard/GetUserLoadboardTractorAlerts",
                datatype: "json",
                formatdata: function (data) {
                    console.log(data);
                    self.isTractorGridLoading(true);
                    data = data || {};
                    data.agencyId = userProfile.currentAgencyId();
                    return data;
                },
                datafields:
                [
                    { name: 'matchId', type: 'int' },
                    { name: 'isOrderAlert', type: 'bool' },
                    { name: 'isTractorAlert', type: 'bool' },
                    { name: 'isOrder', type: 'bool' },
                    { name: 'isLoad', type: 'bool' },
                    { name: 'postingTime', type: 'date' },
                    { name: 'deadheadMiles', type: 'string' },
                    { name: 'startLocation', type: 'string' },
                    { name: 'endLocation', type: 'string' },
                    { name: 'pickupDate', type: 'date' },
                    { name: 'trailerType', type: 'string' },
                    { name: 'distance', type: 'string' },
                    { name: 'ratePerMile', type: 'string' },
                    { name: 'linehaul', type: 'string' },
                    { name: 'fsc', type: 'string' },
                    { name: 'otherCharges', type: 'string' },
                    { name: 'tractorAgencyId', type: 'string' },
                    { name: 'tractorAgencyName', type: 'string' },
                    { name: 'tractorAgencyEmail', type: 'string' },
                    { name: 'tractorAgencyContactNumber', type: 'string' },
                    { name: 'loadAgencyId', type: 'string' },
                    { name: 'loadAgencyName', type: 'string' },
                    { name: 'loadAgencyEmail', type: 'string' },
                    { name: 'loadAgencyContactNumber', type: 'string' },
                    { name: 'tractorLink', type: 'string' },
                    { name: 'tractorId', type: 'string' }
                ],

                beforeprocessing: function (data) {
                    console.log(data);
                    self.TractorAlerts = [];
                    $.each(data, function (index, value) {
                        if (value.isTractorAlert == true) {
                            self.TractorAlerts.push(value);
                        }
                    });

                    if (self.isRefresh == true) {
                        $.notify("Grid refreshed", "info");
                    }
                    return self.TractorAlerts;
                },
            };

            var dataAdapter = dataModel.getDataAdapter(tractorGridSource);

            var gridheightraw = screen.height - 360;
            var pxgridheight = screen.height - 360 + "px";
            var gridrows;
            if (gridheightraw >= 700) {
                gridrows = 20;
            }
            else if (gridheightraw >= 600) {
                gridrows = 15;
            }
            else {
                gridrows = 11;
            }

            tractorGrid.jqxGrid({
                theme: 'GWTMDark',
                width: '100%',
                source: dataAdapter,
                altrows: true,
                autoheight: true,
                showstatusbar: true,
                sortable: true,
                pageable: true,
                // pagesize: gridrows,
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                //use columns height to change header row along with renderer in columns[
                //columnsheight: 40,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                //columns: columns,
                statusbarheight: 35,
                showtoolbar: true,
                
                renderstatusbar: function (statusbar) {
                    var div = $("<div>", { id: 'tractorRowCount', style: "padding:4px;" });
                    div.append("Number of Records: " + tractorGrid.jqxGrid('getrows').length);
                    statusbar.append(div);
                },

                rendertoolbar: function (toolbar) {
                    var tractorGridToolbar = $("#tractorGridToolbar");
                    toolbar.append(tractorGridToolbar);

                    var vm2 = new gridState.viewModel(),
                        tmpl2 = gridState.template;

                    vm2.clearFilters = function () {
                        //isInitLoad = true;
                        tractorGrid.jqxGrid("clearfilters");
                        tractorGrid.jqxGrid("removesort");
                        initTractorFilters();
                    };
                    vm2.setDefaultSearch = function () {
                        var filters = {};
                        return filters;
                    };

                    vm2.refresh = function () {
                            self.isRefresh = true;
                            tractorGrid.jqxGrid("updatebounddata", "data"); 
                        };
                
                    var tdTractorGridAction = tractorGridToolbar.find("#tdTractorGridAction")
                    tdTractorGridAction.append(tmpl2);
                    ko.applyBindingsToDescendants(vm2, tdTractorGridAction[0]);
                },

                rendergridrows: function (obj) {
                    return obj.data;
                },

                columns: [
                    { text: '-', datafield: 'matchId', hidden: true, },
                    { text: '-', datafield: 'isOrderAlert', hidden: true, },
                    { text: '-', datafield: 'isTractorAlert', hidden: true, },
                    { text: '-', datafield: 'isOrder', hidden: true, },
                    { text: '-', datafield: 'isLoad', hidden: true, },
                    { text: 'Tractor', datafield: 'tractorId', width: 98},
                    { text: 'Posting Date', dataField: 'postingTime', cellsformat: "MM/dd/yyyy", width: 99 },
                    { text: 'Deadhead Miles', dataField: 'deadheadMiles', width: 120 },
                    { text: 'Origin', dataField: 'startLocation', width: 139 },

                    { text: 'Destination', dataField: 'endLocation', width: 179 },
                    { text: 'Pickup Date', dataField: 'pickupDate', cellsformat: "MM/dd/yyyy", width: 93 },
                    { text: 'Trailer Type', dataField: 'trailerType', width: 100 },
                    { text: 'Distance', dataField: 'distance', width: 65 },
                    { text: 'Rate Per Mile', dataField: 'ratePerMile', width: 100 },
                    { text: 'Linehaul', dataField: 'linehaul', width: 71 },
                    { text: 'FSC', dataField: 'fsc', width: 54 },
                    { text: 'Other Charges', dataField: 'otherCharges', width: 111 },
                    { text: 'Load Agency Code', dataField: 'loadAgencyId', width: 130 },
                    { text: 'Load Agency Name', dataField: 'loadAgencyName', width: 200 },
                    { text: 'Load Agency Email', dataField: 'loadAgencyEmail', width: 213 },
                    { text: 'Load Agency Contact #', dataField: 'loadAgencyContactNumber', width: 184 },
                    { text: 'Tractor Agency Code', dataField: 'tractorAgencyId', width: 130 },
                    { text: 'Tractor Agency Name', dataField: 'tractorAgencyName', width: 200 },
                    { text: 'Tractor Agency Email', dataField: 'tractorAgencyEmail', width: 213 },
                    { text: 'Tractor Agency Contact #', dataField: 'tractorAgencyContactNumber', width: 184 },
                    { text: 'Link', datafield: 'tractorLink', width: 141, cellsrenderer: linkrenderer }
                ],

                ready: function () {
                    if(self.tractorGridState) {
                        self.loadGridState(tractorGrid, self.tractorGridState);
                    }
                    _tractorInitLoad = false;
                }

            }); 
            
            tractorGrid.on("bindingcomplete", function (event) {
                var records = tractorGrid.jqxGrid('getrows');
                $('#tractorRowCount').text("Number of Records: " + records.length);
            });                

            tractorGrid.on("filter", function (event) {
                var records = tractorGrid.jqxGrid('getrows');
                $('#tractorRowCount').text("Number of Records: " + records.length);
            });
        };


        self.loadGridState = function (grid, state) {
            if (state != null) {
                var gridState = grid.jqxGrid('getstate');
                state.selectedrowindex = -1;
                state.selectedrowindexes = [];
                state.pagenum = 0;
                state.height = gridState.height;
                state.pagesize = gridState.pagesize;
                state.pagesizeoptions = gridState.pagesizeoptions;
                grid.jqxGrid('loadstate', state);
            }
            else {
                grid.jqxGrid('loadstate');
            }
        };

        //GET THE USER'S SAVED FILTERS FOR ORDERS GRID, ALSO TRIGGERS THE FUNCTION TO GET USER'S SAVED FILTERS FOR TRACTOR GRID
        self.loadSavedFiltersforOrderGrid = function () {
            return new Promise((resolve, reject) => {
                dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", { name: "orderGrid", pageName: window.location.pathname })
                .done(function (data) {
                    //IF THE USER HAS SAVED DATA, SET ORDERGRIDSTATE TO THIS SAVED DATA.  IF NOT, JUST TRIGGER THE NEXT FUNCTION (SELF.LOADDEFAULTFILTERSFORTRACTORGRID) TO PERFORM THE SAME CHECK FOR THE TRACTOR GRID
                    if (isEmpty(data) == false) {
                        var state = JSON.parse(data);
                        self.orderGridState = state.grid;
                    }
                    resolve();
                })
                .fail(function () {
                });
            })
        };
        
        //GET THE USER'S SAVED FILTERS FOR TRACTOR GRID
        self.loadSavedFiltersforTractorGrid = function () {
            return new Promise((resolve, reject) => {
                dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", { name: "tractorGrid", pageName: window.location.pathname })
                .done(function (data) {
                    //IF THE USER HAS SAVED DATA, SET TRACTORGRIDSTATE TO THIS SAVED DATA.  IF NOT, JUST TRIGGER THE NEXT FUNCTION (SELF.LOADORDERALERTS) 
                    if (isEmpty(data) == false) {
                        var state = JSON.parse(data);
                        self.tractorGridState = state.grid;
                    }
                    resolve();
                })
                .fail(function () {
                });
            })
        };

        Promise.all([self.loadSavedFiltersforOrderGrid(), self.loadSavedFiltersforTractorGrid()])
        .then(() => {
            self.loadOrderAlerts();
            self.loadTractorAlerts();
        })

        self.loadSavedFiltersforOrderGrid();
    }
}

import template from './load-alerts-page.html';
export default { viewModel: LoadAlertsPageViewModel, template: template }