import dataModel from "data-model";
import ko from "knockout";
import $ from "jquery";
import _ from "lodash";
import {GenerateGuid} from 'global-functions';

class GridOptionComponentViewModel {
    $grid = "";
    $componentModal = null;
    $componentModalId = ko.observable();
    $jqxShowHideListBox = null; // This is being set in their initialize methods
    $jqxColumnSortable = null; // This is being set in their initialize methods
    isLoading = ko.observable(false);
    componentInit = ko.observable(false);
    isOptionsChanged = ko.observable(false);
    isProcessing = ko.observable(false);
    componentColumns = []; // filtered jqxColumn data
    jqxGridColumns = []; // complete jqx grid column data
    gridId = ko.observable();
    currentViewingTab = "showHideColumnsTab";
    $componentContainerId = ko.observable("geGridOptions" + GenerateGuid(5));

    constructor(params) {
        this.data = params.data || ko.observable({});
        this.mainModel = params.mainModel || {};

        this.overrideCustomGridModalOptionsSave =
            typeof this.mainModel.overrideCustomGridModalOptionsSave === "function"
                ? this.mainModel.overrideCustomGridModalOptionsSave.bind(this.mainModel)
                : null;

        this.data.subscribe((val) => {
            if (val) this.initializeComponent();
        });
    }

    initializeComponent = () => {
        this.buildModalContent();
        this.loadColumns();
        this.buildShowHideListContent();
        this.buildColumnOrderContent();
        this.componentInit(true);
        this.openModal();
        this.applyEventListeners();
    };

    buildModalContent = () => {

        this.gridId(this.data().gridId);
        this.$grid = $("#" + this.gridId());
        // Build and set ids so we do not have two components being bound to same ids
        let modalId = this.gridId() + "OptionsModal";

        this.$componentModalId(modalId);
        this.$componentModal = $("#" + modalId);

        // Create a wrapper for our grid to append the component to. This way it is assigned to
        // the grid, but outside of the grid element. Which allows mouse scrolling inside the modal,
        // instead of the grid scrolling behind it.
        if(this.componentInit() == false) {
            let $wrapper = $('<div></div>', {'aria-id': 'grid-wrapper'});
            this.$grid.wrap($wrapper);
    
            // giving the container a unique id so can identify it better
            let $container = this.$componentModal.closest('.gridOptionsComponentContainer');
            $container.attr('id', this.$componentContainerId());
            this.$grid.after($container);
        }
    };

    openModal = () => {
        this.$componentModal.modal("show");
    };

    closeModal = () => {
        // Adding guard clause in case of jquery/js event triggering closing modals
        if (this.$componentModal == null) return;

        this.$componentModal.modal("hide");
    };

    applyEventListeners = () => {
        this.$componentModal.on("hide.bs.modal", () => {
            this.cleanUpComponent();
        });
    };

    buildShowHideListContent = (reloadList) => {
        reloadList = reloadList || false;
        const columns = this.componentColumns
            .filter((x) => x.pinned == false)
            .map((column) => {
                return { label: column.text, value: column.datafield, checked: column.hidden == false };
            });

        if (reloadList) this.$jqxShowHideListBox.jqxListBox("destroy");

        // Inject and give it specific id so we don't have conflicts with other components
        this.$jqxShowHideListBox = $("<div>", { id: this.gridId() + "showHideWidget" });
        var wrapper = this.$componentModal.find('[aria-id="jqxGridShowHideListBoxWrapper"]');
        wrapper.append(this.$jqxShowHideListBox);
        this.$jqxShowHideListBox.jqxListBox({
            source: columns,
            width: "100%",
            checkboxes: true,
            allowDrag: false,
            allowDrop: false,
        });

        this.$jqxShowHideListBox.on("checkChange", (event) => this.handleShowOrHideChange(event));
    };

    handleShowOrHideChange = ({ args: eventArgs }) => {

        this.setShowOrHideChangeAsync(eventArgs)
            .then((x) => this.loadColumns());

        this.isOptionsChanged(true);
    };

    setShowOrHideChangeAsync = (eventArgs) => {
        return new Promise((resolve) => {

            !eventArgs.checked ? this.$grid.jqxGrid("hidecolumn", eventArgs.value) : this.$grid.jqxGrid("showcolumn", eventArgs.value);
            resolve(true);
        })
    }

    buildColumnOrderContent = (reloadList) => {
        reloadList = reloadList || false;

        const columns = this.componentColumns.filter((x) => x.hidden == false && x.pinned == false);
        let listTemplate = "";
        listTemplate = columns.map((column, index) => {

            return (
                listTemplate +
                `<div class="menu-item"  id="col-${column.datafield}"><span class="name">${column.text}</span></div>`
            );
        });

        if (reloadList) {
            this.$jqxColumnSortable.jqxSortable("destroy");
            this.$jqxColumnSortable.remove();
        }

        this.$jqxColumnSortable = $("<div>", { id: this.gridId() + "ReorderColumnsWidget" });
        this.$jqxColumnSortable.html(listTemplate);

        var wrapper = this.$componentModal.find('[aria-id="jqxGridColumnSortableWrapper"]');
        wrapper.append(this.$jqxColumnSortable);

        this.$jqxColumnSortable.jqxSortable({ axis: "y", scrollSpeed: 10 });
        
        this.$jqxColumnSortable.on("stop", (event) => this.handleGridReorder(event));
    };

    loadColumns = () => {
        this.jqxGridColumns = this.$grid.jqxGrid("columns").records || [];
        this.componentColumns = this.jqxGridColumns.filter((col) => this.isColumnEditable(col));
    };

    isColumnEditable = ({ datafield, pinned, text, hideable }) => {
        return (
            datafield &&
            hideable == true &&
            [0, "0", "distance"].indexOf(datafield) == -1 &&
            ["-", ""].indexOf(text) == -1 &&
            (!dataModel.isUserDriver || text != "Brokered")
        );
    };

    handleGridReorder = ({ args: eventArgs }) => {
        eventArgs = eventArgs || { item: [{}] };

        const item = eventArgs.item[0] || {};
        const itemId = item.id || ""; // get our id  
        const datafieldColumnName = itemId.replace("col-", "");
        
        let newPosition = this.determineNewIndexPosition(eventArgs, datafieldColumnName);

        this.$grid.jqxGrid("setcolumnindex", datafieldColumnName, newPosition);

        this.loadColumns();
        this.isOptionsChanged(true);
    };

    // Determine the jqx column needing to swap positions with.
    determineNewIndexPosition = (eventArgs, datafieldColumnName) => {
        eventArgs = eventArgs || { item: [{}] };
        const item = eventArgs.item[0] || {};
        const nextSibling = item.nextSibling || { id: ""}; // bottom item
        const prevSibling = item.previousSibling || { id: ""}; // top item
        let newIndexPosition = 0;

        //get current index from the item's jqx data obj
        const itemJqxColData = this.jqxGridColumns.find((col) => col.datafield == datafieldColumnName);
        // Only want pinned columns from the left of the grid to offest our index #
        const leftPinnedColumnsCount = this.jqxGridColumns.filter((col) => {
            return col.visibleindex < itemJqxColData.visibleindex && col.pinned == true;
        }).length;

        const itemBelow = this.jqxGridColumns.find((col) => nextSibling.id.indexOf(col.datafield) != -1);
        const itemAbove = this.jqxGridColumns.find((col) => prevSibling.id.indexOf(col.datafield) != -1);
        const hasMovedUp = eventArgs.originalPosition.top > eventArgs.position.top;

        newIndexPosition = itemBelow && hasMovedUp ? this.jqxGridColumns.indexOf(itemBelow) : itemAbove ? this.jqxGridColumns.indexOf(itemAbove) : leftPinnedColumnsCount + 1;

        return newIndexPosition;
    }

    cleanUpComponent = () => {
        if (this.$componentModal == null) return;

        $('a[href="#' + this.$componentModalId() + '-ShowHideColumnsTab"]').tab("show");
        this.$componentModal.off("hide.bs.modal");
        this.resetMembers();
        this.isProcessing(false);
    };

    resetMembers = () => {
        this.$grid = "";
        this.gridId("");
        this.$componentModalId("");
        this.isOptionsChanged(false);
        this.$jqxShowHideListBox.jqxListBox("destroy");
        this.$jqxColumnSortable.jqxSortable("destroy");
        this.$jqxColumnSortable.remove();
        this.componentColumns = [];
        this.jqxGridColumns = [];
    };

    handleTabContentChange = (tabName) => {
        if (tabName != this.currentViewingTab) {
            this.currentViewingTab = tabName;

            if (tabName == "showHideColumnsTab") this.buildShowHideListContent(true);

            if (tabName == "columnReorderingTab") this.buildColumnOrderContent(true);
        }
    };

    saveGridOptions = () => {
        this.isProcessing(true);
        if (this.overrideCustomGridModalOptionsSave) {
            this.overrideCustomGridModalOptionsSave();
        } else {
            this.mainModel.saveGridFilters(this.$grid);
        }

        this.isProcessing(false);
        this.isOptionsChanged(false);
        this.closeModal();
    };
}

import template from "./jqx.grid-options.component.html";

export default {
    viewModel: {
        createViewModel: (params, componentInfo) => {
            return new GridOptionComponentViewModel(params);
        },
    },
    template: template,
};
