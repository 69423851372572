import dataModel from 'data-model';
import template from './order-entry-billing-component.html';
import ko, { Computed, Observable, PureComputed} from 'knockout';
import {OrderEntryViewModel} from '../order-entry-page'
import {OrderEntryBillingAdditionalChargesComponent} from './additional-charges-component/additional-charges-component';

class BillingModel {

    additionalChargesViewModel: Observable<OrderEntryBillingAdditionalChargesComponent> = ko.observable()
    $parent: OrderEntryViewModel
    requiredFields: any;
    unitsDescription: ko.Observable<any> & ko.validation.ObservableValidationExtension;
    billingMethod: ko.Observable<any> & ko.validation.ObservableValidationExtension;
    payMethod: ko.Observable<any> = ko.observable();
    selectedPayMethod: ko.Observable<any>;
    pieces: ko.Observable<any> & ko.validation.ObservableValidationExtension;
    weight: ko.Observable<any> & ko.validation.ObservableValidationExtension;
    minWeight: ko.Observable<any> & ko.validation.ObservableValidationExtension;
    movementDistance: ko.Observable<any>;
    billDistance: ko.Observable<any> & ko.validation.ObservableValidationExtension;
    calculatedBillingDistance: ko.Observable<any>;
    isCalculatingDistance: ko.Observable<boolean> = ko.observable();
    rate: ko.Observable<any> & ko.validation.ObservableValidationExtension;
    rateUnits: ko.Observable<any> & ko.validation.ObservableValidationExtension;
    orderAllocation: ko.Observable<any>;
    isAdditionalChargeCWT: Observable<boolean> = ko.observable();
    isAdditionalChargeTons: Observable<boolean>  = ko.observable();

    constructor(data, mainModel: OrderEntryViewModel) {
        this.$parent = mainModel;
        this.requiredFields = mainModel.requiredFields
        this.unitsDescription = ko.observable(data.unitsDescription).extend({
            required: {
                params: true,
                message: "The required field Units Description has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputUnitsDescription");
                }
            }
        });

        this.additionalChargesViewModel({
            additionalCharges: data.additionalCharges, 
            seperateDriverExtraPays: data.seperateDriverExtraPays,
            parent: this
        } as any)
    
        this.billingMethod = ko.observable(data.billingMethod != null ? data.billingMethod : undefined)
            .extend({ 
                required: {
                params: true,
                onlyIf: () => { return true; },
                message: "The required field Billing Method has not been entered.  Please enter the required information." }
            } 
        );

        this.payMethod(data.payMethodId || 3);

        this.selectedPayMethod = ko.observable(data.payMethodId || 3);
        this.selectedPayMethod.subscribe((value) => {
            if(value.value) {
                this.payMethod(value.value);
            }
        })
    
        this.pieces = ko.observable(data.pieces).extend({
            max: 1000000, required: {
                params: true,
                message: "The required field Pieces has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputStopPieces");
                }
            }
        });
    
        this.weight = ko.observable(data.weight).extend({
            max: 1000000,
            required: { 
                params: true,
                message: "The required field Weight has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    if(this.requiredFields.isRequired("inputWeight")) {
                        return true;
                    } else if(this.payMethod() == 1 || this.payMethod() == 4) {
                        return true;
                    } else if(this.isAdditionalChargeCWT() == true) {
                        return true;
                    } else if(this.isAdditionalChargeTons() == true) {
                        return true; 
                    } else {
                        return false;
                    }
                }
            }
        });
        this.minWeight = ko.observable(data.minWeight).extend({
            max: 1000000, required: { 
                params: true,
                message: "The required field Min Weight has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("Min Weight");
                }
            }
        });
    
        this.movementDistance = ko.observable(data.moveDistance);
    
        this.billDistance = ko.observable(data.billDistance).extend({
            max: 1000000,
            required: {
                params: true,
                message: "The required field Billing Distance has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputBillingBillingDistance") && ko.unwrap(mainModel.isReadOnly) == false;
                }
    
            }
        });
    
        this.calculatedBillingDistance = ko.observable(data.isCustomDistance ? -1 : data.billDistance);
        this.isCalculatingDistance(false);

        this.rate = ko.observable(data.rate).extend({
            max: 1000000,
            min: { 
                params: 0.01, 
                message: "The Total rate must be greater than 0.00",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputRate");
                }
            },
            required: { params: true,
                message: "The required field Rate has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputRate");
                }
            }
        });

        this.rateUnits = ko.observable(data.rateUnits).extend({
            required: { params: true,
                message: "The required field Rating Units has not been entered.  Please enter the required information.",
                onlyIf: () => {
                    return this.requiredFields.isRequired("inputRatingUnits");
                }
            }
        });

        this.orderAllocation = ko.observable(data.orderAllocation);
        this.totalCharge.extend({
            required: true,
            validation: {
                validator: (val) => {
                    if (ko.unwrap(this.$parent.isReadOnly)) {
                        return true;
                    } else {
                        return val > 0;
                    }
                },
                message: "Total charge must be greater than 0"
            }
        })
    }
    
    ratingUnitsReadOnly: Computed<boolean>  = ko.computed(() =>{
        if (this.payMethod && this.payMethod() == 5) {
            return false;
        } else {
            return true;
        }
    });

    freightCharge: Computed<number> = ko.computed(() => {
        var ratingUnits = this.getRatingUnits(this.payMethod());
        this.rateUnits(ratingUnits || 0);
        var charge = this.rate() * ratingUnits;
        return isNaN(charge) ? 0 : Number(charge.toFixed(2));
    }, this, {deferEvaluation: true});
    grossPay = this.freightCharge;

    totalCharge: Computed<number> = ko.computed(() =>{
        return this.freightCharge() + (this.otherCharges() || 0)
    }, this, {deferEvaluation: true})

    hasCustomDistance: ko.Computed<boolean> = ko.computed(() =>{
        return this.calculatedBillingDistance() != this.billDistance();
    }, this, {deferEvaluation: true});

    otherCharges = ko.computed(() =>{
        var totalCharge = 0;
        this.isAdditionalChargeCWT(false);
        this.isAdditionalChargeTons(false);

        var charges = ko.unwrap(this.additionalChargesViewModel()?.additionalCharges);
        if(charges) {
            for (var i = 0; i < charges.length; i++) {
                if (ko.unwrap(charges[i].payMethod) == "CWT") {
                    this.isAdditionalChargeCWT(true);
                }
                else if (ko.unwrap(charges[i].payMethod) == "Tons") {
                    this.isAdditionalChargeTons(true);
                }
                var amount = ko.unwrap(charges[i].amount);
                if (isNaN(amount) == false) {
                    totalCharge += amount;
                }
            }
            return Number(totalCharge.toFixed(2));
        }
    }, this, {deferEvaluation: true});

    getRatingUnits = (payMethod) => {
        var ratingUnits = 1;
        if (payMethod == 1 || payMethod == "CWT") {
            if (this.minWeight() > 0 || this.weight() > 0) {
                if (this.minWeight() > this.weight()) {
                    ratingUnits = this.minWeight() / 100;
                } else {
                    ratingUnits = this.weight() / 100;
                }
            }
        }
        else if (payMethod == 2 || payMethod == "Distance") {
            ratingUnits = this.billDistance();
        }
        else if (payMethod == 3 || payMethod == "Flat") {
            ratingUnits = 1;
        }
        else if (payMethod == 4 || payMethod == "Tons") {
            if (this.minWeight() > this.weight()) {
                ratingUnits = this.minWeight() / 2000;
            }
            else {
                ratingUnits = this.weight() / 2000;
            }
        }
        else if (payMethod == 5 || payMethod == "Other") {
            ratingUnits = this.rateUnits();
        }
        return ratingUnits;
    }

    validationObject() {
        return {
            unitsDescription: this.unitsDescription,
            billingMethod: this.billingMethod,
            pieces: this.pieces,
            weight: this.weight,
            minWeight: this.minWeight,
            billDistance: this.billDistance,
            rate: this.rate,
            rateUnits: this.rateUnits,
            totalCharge: this.totalCharge
        }
    }
}

(BillingModel.prototype as any).toJSON = function() {
    var copy = ko.toJS(this);
    if(copy.additionalChargesViewModel) {
        (copy as any).additionalCharges = (copy.additionalChargesViewModel.additionalCharges as any).filter((x) => {return x.chargeCode});
        delete copy.additionalChargesViewModel;
        copy.additionalCharges.forEach(element => {
            delete element.$parent;
        });
    }
    delete copy.$parent;
    return copy;
}


export default {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            //Don't do anything.  Use the object created in the main OrderEntry page.
        }
    }, template: template
}

export { BillingModel }