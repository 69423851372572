import template from './order-entry-dispatch-cancel-dispatch.html';
import ko from 'knockout';
class OrderEntryDispatchCancelDispatch {
    constructor(params) {
        this.brokered = params.params.brokered || false;
        var result = params.params.result
        this.cancelLatestStop = ko.observable(false);
        this.moveMovementToAvailableStatus = ko.observable(false);
        this.cancelDispatchAndRemoveEquipment = ko.observable(false);

        this.showNoSelectionError = ko.observable(false);
        this.showFirstQuestion = ko.observable(params.params.isPartOfViewingOrder)
        this.showSecondaryQuestion = ko.observable(false);
        this.cancelEmptyMovement = ko.observable();
        this.nextOrCancelButtonText = ko.observable("Cancel Dispatch");

        this.showWarningQuestion = ko.observable(!params.params.isPartOfViewingOrder);

        this.cancelingOrderExId = params.params.cancelingOrderExId;
        this.cancelingMovementOrigin = params.params.cancelingMovementOrigin;
        this.cancelingMovementDestination = params.params.cancelingMovementDestination;

        this.viewingOrderExId = params.params.viewingOrderExId;
        this.viewingMovementOrigin = params.params.viewingMovementOrigin;
        this.viewingMovementDestination = params.params.viewingMovementDestination;

        this.confirmCancel = () => {
            this.showWarningQuestion(false);
            this.showFirstQuestion(true);
        }

        this.cancelCancel = () => {
            $('#cancelDispatchModal').modal('hide');
        }

        var pauseUpdates = false;
        this.cancelLatestStop.subscribe((value) => {
            if(pauseUpdates == false) {
                if(value == true) {
                    this.cancelDispatchAndRemoveEquipment(false);
                    this.moveMovementToAvailableStatus(false);
                }
                pauseUpdates = false
            }
        })

        this.moveMovementToAvailableStatus.subscribe((value) => {
            if(pauseUpdates == false) {
                pauseUpdates = true;
                if(value == true) {
                    this.cancelLatestStop(false);
                    this.cancelDispatchAndRemoveEquipment(false);
                }
                pauseUpdates = false;
            }
        })

        this.cancelDispatchAndRemoveEquipment.subscribe((value) => {
            if(pauseUpdates == false) {
                pauseUpdates = true;
                if(value == true) {
                    this.cancelLatestStop(false);
                    this.moveMovementToAvailableStatus(false);
                    if(this.brokered == false) {
                        this.nextOrCancelButtonText("Next");
                    }
                } else {
                    if(this.brokered == false) {
                        this.nextOrCancelButtonText("Cancel Dispatch");
                    }
                }
                pauseUpdates = false;
            }
        })

        this.cancelDispatch = (close) => {
            $('#cancelDispatchModal').modal('hide');
            result(close);
        }

        this.cancel = (close) => {
            close.click();
            result(false)
        }

        this.showNextQuestion = () => {
            if(this.cancelDispatchAndRemoveEquipment() == false && this.moveMovementToAvailableStatus() == false && this.cancelLatestStop() == false) {
                this.showNoSelectionError(true);
            } 
            
            else if(this.cancelDispatchAndRemoveEquipment() == true && this.brokered == false) {
                this.showFirstQuestion(false);
                this.showSecondaryQuestion(true);
            } 
            
            else {
                this.cancelDispatch(this);
            }
        }

        $('#cancelDispatchModal').modal('show');
    }
}

export default {viewModel: OrderEntryDispatchCancelDispatch, template: template}


