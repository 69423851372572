import ko from 'knockout'

function showmessage(message, cssclass, onCloseCallBackFn) {
    ko.components.get('show-message-dialog-component', 
    (def, config) => {
        let model = def.createViewModel({message, onCloseCallBackFn});
        let container = $("#dialog-component-container");
        container.empty();
        $(container).append(def.template[0]);
        ko.cleanNode(def.template[0]);
        ko.applyBindingsToDescendants(model, def.template[0]);

        $(def.template[0]).addClass('showmessagedialog').addClass('modal').addClass('fade').addClass('in').modal('show');
        if(cssclass) {
            // $(def.template[0]).find('.modal-dialog').addClass(cssclass);
        }

        $(def.template[0]).on('hidden.bs.modal', function(event) {
            const $el = $(this);
            
            if($el.hasClass('show')) {
                $el.removeClass('show');
            }
            
            if ($('.modal.show').length > 0) {
                $('body').addClass('modal-open');
            } else {
                $('body').removeClass('modal-open');
                $('.modal-backdrop.fade.in').remove();
            }
            ko.components.clearCachedDefinition('show-message-dialog-component');
            ko.cleanNode(def.template[0]);
        })
    })
}

function showconfirm(message, cssclass) {
    return new Promise((resolve, reject) => {
        ko.components.get('show-confirm-dialog-component', 
        (def, config) => {
            let model = def.createViewModel(message);
            model.result.then(res => {
                resolve(res);
            })
            let container = $("#dialog-component-container").children().first();
            $(container).replaceWith(def.template[0]);
            ko.applyBindingsToDescendants(model, def.template[0]);
    
            $(def.template[0]).addClass('modal').addClass('fade').addClass('in').modal('show');
            if(cssclass) {
                $(def.template[0]).find('.modal-dialog').addClass(cssclass);
            }
    
            $(def.template[0]).on('hidden.bs.modal', (event) => {
                if ($('.modal.show').length > 0) {
                    $('body').addClass('modal-open');
                } else {
                    $('body').removeClass('modal-open');
                }
                ko.components.clearCachedDefinition('show-confirm-dialog-component');
                ko.cleanNode(def.template[0]);
            })
        })
    })
}

function showmustdismiss(message, cssclass) {  //This requires dismissal before continuing code.
    return new Promise((resolve, reject) => {
        ko.components.get('must-dismiss-dialog-component', 
        (def, config) => {
            let model = def.createViewModel(message);
            model.result.then(res => {
                resolve(res);
            })
            let container = $("#dialog-component-container").children().first();
            ko.cleanNode(def.template[0]);
            $(container).replaceWith(def.template[0]);
            ko.applyBindingsToDescendants(model, def.template[0]);
    
            $(def.template[0]).addClass('modal').addClass('fade').addClass('in').modal('show');
            if(cssclass) {
                $(def.template[0]).find('.modal-dialog').addClass(cssclass);
            }
    
            $(def.template[0]).on('hidden.bs.modal', (event) => {
                if ($('.modal.show').length > 0) {
                    $('body').addClass('modal-open');
                } else {
                    $('body').removeClass('modal-open');
                }
                ko.components.clearCachedDefinition('must-dismiss-dialog-component');
                ko.cleanNode(def.template[0]);
            })
        })
    })
}

function getpassword(message, cssclass, okBtnText) {
    return new Promise((resolve, reject) => {
        ko.components.get('show-password-dialog-component', 
        (def, config) => {
            let model = def.createViewModel({message,okBtnText});
            model.result.then(res => {
                resolve(res);
            })
            let container = $("#dialog-component-container").children().first();
            $(container).replaceWith(def.template[0]);
            ko.applyBindingsToDescendants(model, def.template[0]);
    
            $(def.template[0]).addClass('modal').addClass('fade').addClass('in').modal('show');
            if(cssclass) {
                $(def.template[0]).find('.modal-dialog').addClass(cssclass);
            }
    
            $(def.template[0]).on('hidden.bs.modal', (event) => {
                if ($('.modal.show').length > 0) {
                    $('body').addClass('modal-open');
                } else {
                    $('body').removeClass('modal-open');
                }
                ko.components.clearCachedDefinition('show-password-dialog-component');
                ko.cleanNode(def.template[0]);
            })
            setTimeout(() => {
                $('#passwordInput').focus();
            }, 500);
        })
    })
}

export { showmessage, showconfirm, getpassword, showmustdismiss }