import dataModel from "data-model";
import userProfile from "user-profile";
import gridState from "jqx.grid-state-component";
import gridUtils from "jqx.grid-utils";
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";

class AgenciesPageViewModel {
    constructor() {
        var _this = this;

        this.isLoading = ko.observable(false);
        this.isInitLoad = true;
        this.ratingUpdated = false;
        this.lastUpdated = Date.now();
        this.updateGrid = false;
        this.agencyDetails = ko.observable();
        this.agencyRadiusLocation = ko.observable();
        this.agencyRadius = ko.observable(10);
        this.agencyGridStateJSON = ko.observable();
        this.agenciesGrid = $("#agenciesGrid");
        this.skipFilter = true;
        this.initFilters = null;
        this.actionBarViewModel = ko.observable();
        this.userHasSavedFilters = false;
        this.applySortIconUglyness = true;
        this.clearInitialSortingUglyness = false;
        var _gridDataRefreshing = false; 
        this.clearSelectedSavedSearchDDL = ko.observable(false);
        this.refreshSavedSearchDDL = ko.observable("");


        this.handleOnSelected = (state) => {
            this.initGridState = state.grid;
            this.agencyGridStateJSON(this.initGridState);
            if(this.isInitLoad) {
                this.loadAgencies();
            }
            else {
                gridUtils.applyGridState("agenciesGrid", state.grid);
            }
        }

        this.agencyGridRadiusSearch = function () {
            _gridDataRefreshing = true;
            _this.agenciesGrid.jqxGrid("clear");

            _this.agenciesGrid.jqxGrid("clearfilters");
        };

        this.refreshGrid = function () {
            this.agenciesGrid.jqxGrid("updatebounddata", "data");
        };

        this.addFilterDataToDataObjectInFormatdata = function (objGoingToServer) {
            //THIS METHOD ADDS FILTER INFO TO THE DATA OBJECT IN FORMATDATA.  This is where you add filter information before the request goes to the server
            var filterObjFromJson = this.agencyGridStateJSON();

            if (
                filterObjFromJson &&
                filterObjFromJson != null &&
                filterObjFromJson.hasOwnProperty("filters") &&
                filterObjFromJson.filters
            ) {
                for (var i = 0; i < filterObjFromJson.filters.filterscount; i++) {
                    var rangePosition = "";

                    if (filterObjFromJson.filters["filtertype" + i] == "datefilter") {
                        if (filterObjFromJson.filters["filterdatafield" + i] == filterObjFromJson.filters["filterdatafield" + (i + 1)])
                            rangePosition = "Begin";
                        else if (filterObjFromJson.filters["filterdatafield" + i] == filterObjFromJson.filters["filterdatafield" + (i - 1)])
                            rangePosition = "End";
                    }

                    objGoingToServer[filterObjFromJson.filters["filterdatafield" + i] + rangePosition] =
                        filterObjFromJson.filters["filtervalue" + i];
                }

                var sortcolumn = filterObjFromJson.sortcolumn ? (objGoingToServer.sortdatafield = filterObjFromJson.sortcolumn) : undefined;
                objGoingToServer.sortdatafield = sortcolumn;
                var sortorder = filterObjFromJson.sortdirection ? (filterObjFromJson.sortdirection.ascending ? "asc" : "desc") : undefined;
                objGoingToServer.sortorder = sortorder;
            }

            return {
                data: objGoingToServer,
                gridState: this.initGridStateUnwrappedObject,
            };
        };

        this.formatAndAddFiltersToGridUI = function () {
            var agencyGridStateObject = _this.agencyGridStateJSON();

            var agencyGridFiltersJSONObject = agencyGridStateObject ? agencyGridStateObject.filters : null;
            var filtergroup = undefined;
            var filtervalue = "";
            var filtervalue1 = "";
            var filtercondition = "";
            var filtercondition1 = "";
            var filtertype = "";
            var filterdatafield = "";
            var filteroperator = "";
            var filter1 = undefined;
            var filterIndexAccessors = [];
            $.each(agencyGridFiltersJSONObject, function (key, value) {
                var lastChar = /.$/.exec(key)[0];
                if (!isNaN(parseInt(lastChar)) && filterIndexAccessors.indexOf(lastChar) == -1) filterIndexAccessors.push(lastChar);
            });
            var filters = [];
            var filter = {};
            var operatorKey = "";

            for (var i = 0; i < filterIndexAccessors.length; i++) {
                operatorKey = agencyGridFiltersJSONObject["filterdatafield" + i] + "operator";
                filter = {
                    filtercondition: agencyGridFiltersJSONObject["filtercondition" + i],
                    filterdatafield: agencyGridFiltersJSONObject["filterdatafield" + i],
                    filteroperator: agencyGridFiltersJSONObject["filteroperator" + i],
                    filtertype: agencyGridFiltersJSONObject["filtertype" + i],
                    filtervalue: agencyGridFiltersJSONObject["filtervalue" + i],
                };
                filter[operatorKey] = agencyGridFiltersJSONObject[agencyGridFiltersJSONObject["filterdatafield" + i] + "operator"];
                filters.push(filter);
            }

            var singleValueFilters = filters.filter(function (f, i) {
                return f.filtertype !== "datefilter";
            });
            var dateFilters = filters.filter(function (f, i) {
                return f.filtertype == "datefilter";
            });
            dateFilters = dateFilters.map(function (f, i, arr) {
                if (i % 2 == 0) {
                    return {
                        beginDate: f,
                        endDate: arr[i + 1],
                    };
                }
            });
            dateFilters = dateFilters.filter(function (f) {
                return f != null || f != undefined;
            });

            for (var i = 0; i < singleValueFilters.length; i++) {
                filtergroup = new $.jqx.filter();
                filtervalue = singleValueFilters[i].filtervalue;
                filtercondition = singleValueFilters[i].filtercondition;
                filtertype = singleValueFilters[i].filtertype;
                filterdatafield = singleValueFilters[i].filterdatafield;
                filteroperator = singleValueFilters[i][filterdatafield + "operator"];
                filter = filtergroup.createfilter(filtertype, filtervalue, filtercondition);
                filtergroup.addfilter(filteroperator, filter);

                _this.agenciesGrid.jqxGrid("addfilter", filterdatafield, filtergroup);
            }

            for (var i = 0; i < dateFilters.length; i++) {
                filtergroup = new $.jqx.filter();
                filtervalue = dateFilters[i].beginDate.filtervalue;
                filtervalue1 = dateFilters[i].endDate.filtervalue;
                filtercondition = dateFilters[i].beginDate.filtercondition;
                filtercondition1 = dateFilters[i].endDate.filtercondition;
                filtertype = dateFilters[i].beginDate.filtertype;
                filterdatafield = dateFilters[i].beginDate.filterdatafield;
                filteroperator = dateFilters[i].beginDate[filterdatafield + "operator"];
                filter = filtergroup.createfilter(filtertype, filtervalue, filtercondition);
                filter1 = filtergroup.createfilter(filtertype, filtervalue1, filtercondition1);
                filtergroup.addfilter(filteroperator, filter);
                filtergroup.addfilter(filteroperator, filter1);

                _this.agenciesGrid.jqxGrid("addfilter", filterdatafield, filtergroup);
            } //Sorting

            if (agencyGridStateObject && agencyGridStateObject.sortcolumn) {
                var sortdirection = agencyGridStateObject.sortdirection.ascending ? "asc" : "dsc"; // self.agenciesGrid.jqxGrid('sortby', agencyGridStateObject.sortcolumn, sortdirection);
            }

            _this.agenciesGrid.jqxGrid("refreshfilterrow");

            _this.agenciesGrid.jqxGrid("refresh");
        };

        this.loadGridState = function (grid, state) {
            if (state != null) {
                var gridState = grid.jqxGrid("getstate");
                state.selectedrowindex = -1;
                state.selectedrowindexes = [];
                state.pagenum = 0;
                state.height = gridState.height;
                state.pagesize = gridState.pagesize;
                state.pagesizeoptions = gridState.pagesizeoptions;
                grid.jqxGrid("loadstate", state);
            }
        };

        this.rendertoolbar = function (toolbar) {
            var carrierGridToolbar = $("#agencyGridToolbar");
            toolbar.append(carrierGridToolbar);

            

            var vm1 = new gridState.viewModel(),
                tmpl1 = gridState.template;
                vm1.actions.push("Save Search");

            vm1.clearFilters = function () {
                _gridDataRefreshing = true;

                // var gs = _this.agencyGridStateJSON();

                // if (gs && gs.filters) {
                //     gs.filters = [];

                //     _this.agencyGridStateJSON(gs);
                // }

                _this.agencyRadiusLocation(null);
                _this.agencyRadius(10);

                _this.applySortIconUglyness = false;
                _this.clearInitialSortingUglyness = true;

                _this.clearSelectedSavedSearchDDL(true);
                _this.agenciesGrid.jqxGrid("clearfilters");
                freightTypeFilterWidgetRef.jqxDropDownList("uncheckAll");
                
            }

            let $grid = $("#agenciesGrid");

            vm1.setDefaultSearchOverride = async () => {
                const savedSearches = await loadSavedSearches($grid.attr("id"));
                const filters = {
                    isDefault: true
                }

                vm1.loadSaveSearchModal(
                    $grid,
                    (val) => {
                        if(val && val.searchname) {
                            _this.refreshSavedSearchDDL(val.searchname)
                        }
                    },
                    savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                    filters,
                    true
                );
            }

            vm1.loadSaveSearchModalOverride = async () => {
                const savedSearches = await loadSavedSearches($grid.attr("id"));
                const filters = {
                    isDefault: false
                }

                vm1.loadSaveSearchModal(
                    $grid,
                    (val) => {
                        if(val && val.searchname) {
                            _this.refreshSavedSearchDDL(val.searchname)
                        }
                    },
                    savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                    filters,
                    true
                );

            }

                

            vm1.refresh = function () {
                _this.updateGrid = true;
                _this.agenciesGrid.jqxGrid("updatebounddata", "data"); 
            };

            var tdGridAction = carrierGridToolbar.find("#tdGridAction");
            tdGridAction.append(tmpl1);
            ko.applyBindingsToDescendants(vm1, tdGridAction[0]);
        };

        var freightTypeFilterWidgetRef = null;

        var _freightTypeList = [
            { html: "Brokerage", label: "Brokerage" },
            { html: "Flatbed", label: "Flatbed" },
            { html: "Hazmat - Approved", label: "Hazmat" },
            { html: "Intermodal - Port", label: "IntermodalPort" },
            { html: "Intermodal - Rail", label: "IntermodalRail" },
            { html: "Power Only", label: "PowerOnly" },
            { html: "Refrigerated", label: "Refrigerated" },
            { html: "Heavy Haul", label: "HeavyHaul" },
            { html: "Van", label: "Van" },
        ];
        this.loadAgencies = function () {
            var source = {
                url: "Agency/GetAgenciesForGrid",
                datatype: "json",
                datafields: [
                    {
                        name: "code",
                        type: "string",
                    },
                    {
                        name: "name",
                        type: "string",
                    },
                    {
                        name: "city",
                        type: "string",
                    },
                    {
                        name: "state",
                        type: "string",
                    },
                    {
                        name: "rating",
                        type: "int",
                    },
                    {
                        name: "id",
                        type: "int",
                    },
                    {
                        name: "freightTypes",
                        type: "string",
                    },
                ],
                filter: function filter(filters, recordsArray) {
                    if(_this.isInitLoad === false){
                        _this.agenciesGrid.jqxGrid("updatebounddata", "filter");
                    }
                },
                sort: function sort(column, direction) {
                    if(_this.isInitLoad === false){
                        _this.clearInitialSortUIElements();

                        _this.applySortIconUglyness = false;

                        _this.agenciesGrid.jqxGrid("updatebounddata", "sort");
                    }
                },

                formatdata: function formatdata(data) {
                    data = {
                        recordstartindex: data.recordstartindex,
                        recordendindex: data.recordendindex,
                        pagesize: data.pagesize,
                        pagenum: data.pagenum,
                        sortdatafield: data.sortdatafield,
                        sortorder: data.sortorder,
                        agencyId: userProfile.currentAgencyId,
                        active: true
                    };

                    if (_this.isInitLoad) {
                        var initSourceData = _this.addFilterDataToDataObjectInFormatdata(data);

                        data = initSourceData.data;
                        if (initSourceData.gridState && initSourceData.gridState.filters)
                            _this.initFilters = initSourceData.gridState.filters;
                        //_this.isInitLoad = false;
                    }

                    var filterinfo = _this.agenciesGrid.jqxGrid("getfilterinformation");

                    for (var i = 0; i < filterinfo.length; i++) {
                        var filterName = filterinfo[i].datafield;
                        var filters = filterinfo[i].filter.getfilters();

                        if (filters.length == 1) {
                            data[filterName] = filters[0].value;
                        } else if (filters.length > 1) {
                            for (var y = 0; y < filters.length; y++) {
                                if (filters[y].type == "datefilter") {
                                    data[filterName + "Begin"] = moment(filters[y].value).format("YYYY-MM-DDTHH:mm").toString();

                                    if (
                                        filters[y + 1] != null &&
                                        filters[y + 1].type == "datefilter" &&
                                        filters[y + 1].value == filters[y].value
                                    ) {
                                        data[filterName + "End"] = moment(filters[y + 1].value)
                                            .format("YYYY-MM-DDTHH:mm")
                                            .toString();
                                        y = y + 1;
                                    }
                                } else if (data[filterName] != null) {
                                    data[filterName].push(filters[y].value);
                                } else {
                                    data[filterName] = [filters[y].value];
                                }
                            }
                        }
                    }

                    if (_this.updateGrid) {
                        _this.lastUpdated = Date.now();
                    }

                    // This fixes the above filtering for freightTypes on payload...
                    // Fixes issue with select all/unselect all checkedlist filtering in grid being opposite/not applying filters
                    // Imposing opposite of what grid is doing to acheive the actual filtering we want when user checks all/unchecks all
                    if (freightTypeFilterWidgetRef != null && _gridDataRefreshing == false) {
                        var listItems = freightTypeFilterWidgetRef.jqxDropDownList("getCheckedItems");

                        // nothing is checked....
                        var items = new Array();
                        if (data["freightTypes"] != null && listItems.length == 0) {
                            delete data["freightTypes"]; // remove the property as user chose unselect all
                        } else if (listItems.length && listItems[0].label == "(Select All)" && listItems[0].checked) {
                            // select all is checked

                            $.each(_freightTypeList, function (index, item) {
                                items.push(item.label);
                            });

                            data["freightTypes"] = items;
                        }
                    } else if (freightTypeFilterWidgetRef != null && _gridDataRefreshing == true) {
                        // radius search clears grid filters but this widget doesn't get updated set state to unchecked
                        freightTypeFilterWidgetRef.jqxDropDownList("uncheckAll");
                    }

                    data.lastUpdated = _this.lastUpdated;
                    data.radius = _this.agencyRadius();
                    data.location = _this.agencyRadiusLocation();

                    _this.isInitLoad = false;
                    _gridDataRefreshing = false;
                    return data;
                },
            };
            var dataAdapter = dataModel.getDataAdapter(source);

            _this.agenciesGrid.jqxGrid({
                theme: "GWTMDark",
                source: dataAdapter,
                selectionmode: "none",
                width: "100%",
                sortable: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                autoheight: true,
                virtualmode: true,
                columnsresize: true,
                columnsmenu: false,
                altrows: true,
                pagesize: 10,
                showtoolbar: true,
                toolbarheight: 80,
                rendertoolbar: _this.rendertoolbar,
                rendergridrows: function rendergridrows(obj) {
                    return obj.data;
                },
                ready: function ready() {
                    _this.formatAndAddFiltersToGridUI();
                    _this.loadGridState(_this.agenciesGrid, _this.agencyGridStateJSON())
                    

                    if (freightTypeFilterWidgetRef) {
                        freightTypeFilterWidgetRef.jqxDropDownList("uncheckAll");
                    }

                    _this.isInitLoad = false;
                },
                columns: _this.columns(),
            });

            _this.agenciesGrid.on("bindingcomplete", function (event) {
                if (!_this.isInitLoad) {
                    if (_this.updateGrid) {
                        _this.updateGrid = false;
                    }

                    if (_this.ratingUpdated) {
                        _this.ratingUpdated = false;
                        $("#divAgenciesPanel").notify(" Rating Updated", {
                            position: "top right",
                            className: "info",
                        });
                    }
                }

                _this.isInitLoad = false;

                if (_this.applySortIconUglyness) {
                    _this.applyInitialSortingUIElements();
                }

                if (_this.clearInitialSortingUglyness) {
                    _this.clearInitialSortingUglyness = false;

                    _this.clearInitialSortUIElements();
                }
            });
        }; //Sorting icon uglyness

        this.applyInitialSortingUIElements = function () {
            //...I know, this is ugly.
            if (_this.agencyGridStateJSON() && _this.agencyGridStateJSON().sortcolumn) {
                var sortcolumn = _this.agencyGridStateJSON().sortcolumn;

                var sortdirection = _this.agencyGridStateJSON().sortdirection.ascending == true ? "asc" : "desc";
                var sort = sortcolumn + "-" + sortdirection;

                if (sortcolumn == null) {
                    return;
                }

                switch (sort) {
                    case "code-asc":
                        $(
                            "#columntableagenciesGrid > div:nth-child(2) > div > div.iconscontainer > div.sortasc.jqx-widget-header.jqx-widget-header-GWTMDark"
                        ).show();
                        break;

                    case "code-desc":
                        $(
                            "#columntableagenciesGrid > div:nth-child(2) > div > div.iconscontainer > div.sortdesc.jqx-widget-header.jqx-widget-header-GWTMDark"
                        ).show();
                        break;

                    case "name-asc":
                        $(
                            "#columntableagenciesGrid > div:nth-child(3) > div > div.iconscontainer > div.sortasc.jqx-widget-header.jqx-widget-header-GWTMDark"
                        ).show();
                        break;

                    case "name-desc":
                        $(
                            "#columntableagenciesGrid > div:nth-child(3) > div > div.iconscontainer > div.sortdesc.jqx-widget-header.jqx-widget-header-GWTMDark"
                        ).show();
                        break;

                    case "city-asc":
                        $(
                            "#columntableagenciesGrid > div:nth-child(4) > div > div.iconscontainer > div.sortasc.jqx-widget-header.jqx-widget-header-GWTMDark"
                        ).show();
                        break;

                    case "city-desc":
                        $(
                            "#columntableagenciesGrid > div:nth-child(4) > div > div.iconscontainer > div.sortdesc.jqx-widget-header.jqx-widget-header-GWTMDark"
                        ).show();
                        break;

                    case "state-asc":
                        $(
                            "#columntableagenciesGrid > div:nth-child(5) > div > div.iconscontainer > div.sortasc.jqx-widget-header.jqx-widget-header-GWTMDark"
                        ).show();
                        break;

                    case "state-desc":
                        $(
                            "#columntableagenciesGrid > div:nth-child(5) > div > div.iconscontainer > div.sortdesc.jqx-widget-header.jqx-widget-header-GWTMDark"
                        ).show();
                        break;

                    case "rating-asc":
                        $(
                            "#columntableagenciesGrid > div:nth-child(6) > div > div.iconscontainer > div.sortasc.jqx-widget-header.jqx-widget-header-GWTMDark"
                        ).show();
                        break;

                    case "rating-desc":
                        $(
                            "#columntableagenciesGrid > div:nth-child(6) > div > div.iconscontainer > div.sortdesc.jqx-widget-header.jqx-widget-header-GWTMDark"
                        ).show();
                        break;
                }
            }
        };

        this.clearInitialSortUIElements = function () {
            //This is too.
            //code asc
            $(
                "#columntableagenciesGrid > div:nth-child(2) > div > div.iconscontainer > div.sortasc.jqx-widget-header.jqx-widget-header-GWTMDark"
            ).hide(); //code desc

            $(
                "#columntableagenciesGrid > div:nth-child(2) > div > div.iconscontainer > div.sortdesc.jqx-widget-header.jqx-widget-header-GWTMDark"
            ).hide(); //name asc

            $(
                "#columntableagenciesGrid > div:nth-child(3) > div > div.iconscontainer > div.sortasc.jqx-widget-header.jqx-widget-header-GWTMDark"
            ).hide(); //name desc

            $(
                "#columntableagenciesGrid > div:nth-child(3) > div > div.iconscontainer > div.sortdesc.jqx-widget-header.jqx-widget-header-GWTMDark"
            ).hide(); //city asc

            $(
                "#columntableagenciesGrid > div:nth-child(4) > div > div.iconscontainer > div.sortasc.jqx-widget-header.jqx-widget-header-GWTMDark"
            ).hide(); //city desc

            $(
                "#columntableagenciesGrid > div:nth-child(4) > div > div.iconscontainer > div.sortdesc.jqx-widget-header.jqx-widget-header-GWTMDark"
            ).hide(); //state asc

            $(
                "#columntableagenciesGrid > div:nth-child(5) > div > div.iconscontainer > div.sortasc.jqx-widget-header.jqx-widget-header-GWTMDark"
            ).hide(); //state desc

            $(
                "#columntableagenciesGrid > div:nth-child(5) > div > div.iconscontainer > div.sortdesc.jqx-widget-header.jqx-widget-header-GWTMDark"
            ).hide(); //rating asc

            $(
                "#columntableagenciesGrid > div:nth-child(6) > div > div.iconscontainer > div.sortasc.jqx-widget-header.jqx-widget-header-GWTMDark"
            ).hide(); //rating desc

            $(
                "#columntableagenciesGrid > div:nth-child(6) > div > div.iconscontainer > div.sortdesc.jqx-widget-header.jqx-widget-header-GWTMDark"
            ).hide();
        };

        this.columns = function () {
            var columns = [
                {
                    text: "",
                    width: 100,
                    filterable: false,
                    columnType: "button",
                    pinned: true,
                    buttonclick: function buttonclick(row) {
                        var datarow = _this.agenciesGrid.jqxGrid("getrowdata", row);

                        if (datarow && datarow.id) {
                            _this.isLoading(true);

                            dataModel
                                .ajaxRequest("agency/getagencydetails/" + datarow.id)
                                .done(function (data, status, xhr) {
                                    _this.agencyDetails(data);

                                    _this.isLoading(false);
                                })
                                .fail(function (jqXHR, textStatus, errorThrown) {
                                    if (jqXHR.responseJSON != null) {
                                        var json = jqXHR.responseJSON;

                                        if (json.message != null) {
                                            var errors = json.message;
                                            console.error(errors);
                                        }
                                    }

                                    this.isLoading(false);
                                });
                        }
                    },
                    cellsrenderer: function cellsrenderer() {
                        return "View Details";
                    },
                },
                {
                    text: "Code",
                    datafield: "code",
                    width: _this.agencyGridStateJSON() ? _this.agencyGridStateJSON().columns.code.width : 150,
                },
                {
                    text: "Name",
                    datafield: "name",
                    width: _this.agencyGridStateJSON() ? _this.agencyGridStateJSON().columns.name.width : 500,
                },
                {
                    text: "City",
                    datafield: "city",
                    width: _this.agencyGridStateJSON() ? _this.agencyGridStateJSON().columns.city.width : 325,
                },
                {
                    text: "State",
                    datafield: "state",
                    width: _this.agencyGridStateJSON() ? _this.agencyGridStateJSON().columns.state.width : 100,
                },
                {
                    text: "Rating",
                    datafield: "rating",
                    width: _this.agencyGridStateJSON() ? _this.agencyGridStateJSON().columns.rating.width : 150,
                    filtertype: "list",
                    cellsrenderer: function cellsrenderer(row, columnfield, value, defaultHTML, column, rowData) {
                        if (defaultHTML != null) {
                            var pk = _this.agenciesGrid.jqxGrid("getcellvalue", row, "id");

                            var fieldset = $("<fieldset>");
                            fieldset.addClass("star-rating");
                            fieldset.attr("id", "jqxRating_" + pk);

                            for (var i = 5; i > 0; i--) {
                                var id = "jqxRating_" + pk + i;
                                var input = $("<input>");
                                input.attr({
                                    type: "radio",
                                    id: "star" + i,
                                    name: "rating",
                                    value: i,
                                });
                                input.appendTo(fieldset);
                                var label = $("<label>").text(i);

                                if (value == i) {
                                    label.attr({
                                        for: "star" + i,
                                    });
                                }

                                label.insertAfter(input);
                                $(document)
                                    .off("click", "#jqxRating_" + pk)
                                    .on("click", "#jqxRating_" + pk, function (event) {
                                        var newRating;
                                        var previousRating;

                                        if (event.target.tagName == "LABEL") {
                                            newRating = event.target.previousSibling.value;
                                            $(event.target.parentElement.children).each(function (i) {
                                                if (this.tagName == "LABEL") {
                                                    if (this.attributes.length > 0) {
                                                        if (this.attributes["for"].value != null) {
                                                            previousRating = this.attributes["for"].value.slice(-1);
                                                        }

                                                        if (previousRating != newRating) {
                                                            $(this).removeAttr("for");
                                                        }
                                                    }
                                                }
                                            });

                                            if (previousRating != newRating) {
                                                var inputId = event.target.previousSibling.attributes.getNamedItem("id").value;
                                                $(event.target).attr("for", inputId);
                                                var id = event.target.parentElement.id.replace("jqxRating_", "");
                                                var params = {
                                                    objectId: id,
                                                    value: newRating,
                                                    agencyId: userProfile.currentAgencyId,
                                                    favTable: 9,
                                                };
                                                dataModel
                                                    .ajaxRequest("Rating", "post", params)
                                                    .done(function (data, textStatus, jqXHR) {
                                                        _this.updateGrid = true;
                                                        _this.ratingUpdated = true;

                                                        _this.refreshGrid();
                                                    })
                                                    .fail(function (error, msg, d) {
                                                        this.isLoading(false);
                                                    });
                                            }
                                        }
                                    });
                            }

                            return fieldset[0].outerHTML;
                        }

                        return defaultHTML;
                    },
                    filteritems: [
                        {
                            value: 5,
                            label: "5",
                            html: "<img src='../Content/Images/5star.png' />",
                        },
                        {
                            value: 4,
                            label: "4",
                            html: "<img src='../Content/Images/4star.png' />",
                        },
                        {
                            value: 3,
                            label: "3",
                            html: "<img src='../Content/Images/3star.png' />",
                        },
                        {
                            value: 2,
                            label: "2",
                            html: "<img src='../Content/Images/2star.png' />",
                        },
                        {
                            value: 1,
                            label: "1",
                            html: "<img src='../Content/Images/1star.png' />",
                        },
                    ],
                    createfilterwidget: function createfilterwidget(column, columnElement, widget) {
                        widget.jqxDropDownList({
                            dropDownWidth: 120,
                            enableBrowserBoundsDetection: true,
                            renderer: function renderer(index, label, value) {
                                if (value != "") {
                                    return "<img src='../Content/Images/" + value + "star.png' />";
                                } else {
                                    return "Any";
                                }
                            },
                        });
                    },
                },
                {
                    text: "Freight Type",
                    datafield: "freightTypes",
                    width: _this.agencyGridStateJSON() ? _this.agencyGridStateJSON().columns.rating.width : 300,
                    filtertype: "checkedlist",
                    filtercondition: "OR",
                    filteritems: _freightTypeList,
                    cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                        var $cellHtml = $(defaultHTML);
                        $cellHtml.css({ "text-transform": "capitalize" });

                        if (value) {
                            $cellHtml.attr("title", value);
                        }

                        return $cellHtml[0].outerHTML;
                    },
                    createfilterwidget: function (column, columnElement, widget) {
                        freightTypeFilterWidgetRef = widget;
                    },
                },
            ];
            return columns;
        }; //If we wait until the rendertoolbar method fires, these 2 aren't loaded in time.

    }
}

import template from "./agencies-page.html";
export default { viewModel: AgenciesPageViewModel, template: template };
