/// <reference path="../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/jqwidgets.d.ts" />
/// <reference path="../../../../node_modules/@types/jqueryui/index.d.ts" />
/// <reference path="../../../types/knockout.validation.d.ts" />

import {
  Observable,
  ObservableArray,
  Computed,
  SubscribableFunctions,
  ObservableExtenderOptions,
} from "knockout";

import * as ko from "knockout";
import * as koValidation from "knockout.validation";
import userProfile from "../../../user-profile";
import template from "./search-orders-page.html";
import dayjs from "dayjs";
import dataModel from "data-model";
import * as $ from "jquery";
import gridStateUtils from "../../shared-components/jqx.grid-utils";
import { showmessage } from "show-dialog-methods";
import { modifyDate, datetimeUTC } from "global-functions";
import GridStateComponentViewModel from "../../shared-components/jqx.grid-state-component/jqx.grid-state-component";

export class SearchOrdersViewModel {
  searchParameters: SearchParameters = new SearchParameters();
  jqxSearchOrdersGrid: jqwidgets.jqxGrid | undefined;
  gridData: [];
  customFields: Observable<string[]> = ko.observableArray([]);
  savedSearches: Observable<any[]> = ko.observableArray([]);
  selectedSavedSearch: Observable<any> = ko.observable();
  includeStopReferences: Observable<boolean> = ko.observable(false);

  //It's a mystery why selectedSavedSearchId has to be here and used in the data-bind on the markup file to get selectedSavedSearch.subscribe to work.
  selectedSavedSearchId: Observable<string | undefined> = ko.observable();
  savedSearchApplied: Observable<boolean> = ko.observable(false);
  canSearchAllOrders: Observable<boolean> = ko.observable(false);
  showExportToExcelButton: Observable<boolean> = ko.observable(false);
  canShowExportToExcelButton: Observable<boolean> = ko.observable(false);
  includeAdditionalCharges: Observable<boolean> = ko.observable(false);
  errors: ObservableArray<string | undefined> = ko.observableArray();
  showResultsTooBigMessage: Observable<boolean> = ko.observable(false);

  showOrderDetailModalBoolean: Observable<boolean | undefined> =
    ko.observable();
  orderDetailObject: Observable<OrderDetailsModal | undefined> =
    ko.observable();
  isLoading: Observable<boolean> = ko.observable(false);
  searchInfoMessage: Observable<string | undefined> = ko.observable();
  dateSearchSelectedCount: Observable<number> = ko.observable(0);

  constructor() {
    this.gridData = [];
    this.initializeComponents();
  }

  getSecurableControls(): void {
    dataModel
      .ajaxRequest("User/CheckUserSecurityControl", "GET", {
        ControlId: "cbSearchAllAgencies",
        ControlDisplayName: "Search All Agencies",
      })
      .done((r: any) => {
        this.canSearchAllOrders(r || false);
      });

    dataModel
      .ajaxRequest("User/CheckUserSecurityControl", "GET", {
        ControlDisplayName: "Export To Excel",
      })
      .done((r: any) => {
        this.canShowExportToExcelButton(r || false);
      });
  }

  initializeComponents(): void {
    this.getSecurableControls();
    let fromDate = new Date();
    let toDate = new Date();
    fromDate.setDate(new Date().getDate() - 90);
    toDate.setDate(new Date().getDate() + 1);

    this.searchParameters.createDate(
      new DateRangeObject({ from: fromDate, to: toDate })
    );

    this.updateDateSearchCount("add");

    dataModel.ajaxRequest("SearchOrders/GetCustomFieldNames").done((value) => {
      let cf: string[] = [];
      cf[0] = value.customField1;
      cf[1] = value.customField2;
      cf[2] = value.customField3;
      this.customFields(cf);
    });
    this.getSavedSearches().then((x) => {
      this.savedSearches(x);
    });

    this.selectedSavedSearch.subscribe((value) => {
      if (value) {
        this.applySavedSearch(value.search);
      }
    });

    this.searchParameters.orderType.subscribe((x) => {
      if (x.indexOf("Unbilled") > -1) {
        ($("#orderTypeList") as any).jqxDropDownList("disableItem", "Billed");
      } else {
        ($("#orderTypeList") as any).jqxDropDownList("enableItem", "Billed");
      }
      if (x.indexOf("Billed") > -1) {
        ($("#orderTypeList") as any).jqxDropDownList("disableItem", "Unbilled");
      } else {
        ($("#orderTypeList") as any).jqxDropDownList("enableItem", "Unbilled");
      }

      if (x.indexOf("Brokered") > -1) {
        ($("#orderTypeList") as any).jqxDropDownList(
          "disableItem",
          "Truckline"
        );
      } else {
        ($("#orderTypeList") as any).jqxDropDownList("enableItem", "Truckline");
      }
      if (x.indexOf("Truckline") > -1) {
        ($("#orderTypeList") as any).jqxDropDownList("disableItem", "Brokered");
      } else {
        ($("#orderTypeList") as any).jqxDropDownList("enableItem", "Brokered");
      }
    });

    this.addDateWatchers();
    this.addEnterKeyWatcher();
    this.addToggleBindings();
  }

  addToggleBindings() {
    // Chevron toggle --> see search orders for example
    $(".toggle-icon")
      .closest("a")
      .on("click", function (e) {
        let target = e.currentTarget;
        let toggle = $(target).children("i.toggle-icon").first();

        if (toggle.hasClass("open")) {
          toggle.removeClass("open");
        } else {
          toggle.addClass("open");
        }
      });
  }

  addEnterKeyWatcher = () => {
    let vm = this;

    $(document).keydown(function (e) {
      if (
        window.location.href.toUpperCase().indexOf("SEARCHORDERS") != -1 &&
        e.target
      ) {
        let code = e.keyCode || e.which;
        let ENTERKEY = 13;
        if (code !== ENTERKEY) return true; // only checking for enter key, so just return all other keypresses true

        e.stopPropagation(); // prevent bubbling up

        if ($("input[aria-id='QuickOrderSearch']").is(":focus") == false) {
          let isSearchExecuting = $("#searchButton").is(":disabled");

          if (isSearchExecuting) {
            vm.searchInfoMessage(
              "Please wait for current search to finish loading before executing another search."
            );
            return false;
          }

          ($("#searchButton") as any).jqxButton({ disabled: true });
          vm.searchForOrders();
        }
      }
    });
  };

  addDateWatchers() {
    this.searchParameters.createDate.subscribe((val) => {
      let addOrSubtract =
        val != null && val.from != undefined && val.to != undefined
          ? "add"
          : "subtract";
      this.updateDateSearchCount(addOrSubtract);
    });

    this.searchParameters.billingDate.subscribe((val) => {
      let addOrSubtract =
        val != null && val.from != undefined && val.to != undefined
          ? "add"
          : "subtract";
      this.updateDateSearchCount(addOrSubtract);
    });

    this.searchParameters.estimatePickupDate.subscribe((val) => {
      let addOrSubtract =
        val != null && val.from != undefined && val.to != undefined
          ? "add"
          : "subtract";
      this.updateDateSearchCount(addOrSubtract);
    });

    this.searchParameters.estimateDeliveryDate.subscribe((val) => {
      let addOrSubtract =
        val != null && val.from != undefined && val.to != undefined
          ? "add"
          : "subtract";
      this.updateDateSearchCount(addOrSubtract);
    });
  }

  updateDateSearchCount(addOrSubtract: string) {
    let currentCount = this.dateSearchSelectedCount();

    if (addOrSubtract == "add") {
      currentCount = currentCount + 1;
    } else if (addOrSubtract == "subtract") {
      let calc = currentCount - 1;
      currentCount = calc >= 0 ? calc : 0;
    }

    this.dateSearchSelectedCount(currentCount);
  }

  searchForOrders() {
    this.errors(undefined);
    this.searchInfoMessage(undefined);

    let errors = koValidation.group(this.searchParameters);
    if (errors().length > 0) {
      this.errors(errors());
      ($("#searchButton") as any).jqxButton({ disabled: false });
      return false;
    }

    if (this.jqxSearchOrdersGrid && this.savedSearchApplied() == false) {
      this.refreshGrid().then((value) => {
        this.determineGridAction(value);
      });
    } else if (this.jqxSearchOrdersGrid && this.savedSearchApplied()) {
      this.jqxSearchOrdersGrid.setOptions({ columns: this.columns() });
      this.refreshGrid().then((value) => {
        this.determineGridAction(value);
      });
    } else {
      this.createGrid().then((value) => {
        this.determineGridAction(value);
      });
    }
  }

  determineGridAction(value) {
    if (value == "Orders > 100") {
      this.showResultsTooBigMessage(true);
      $("#jqxSearchOrdersGrid").css("display", "none");
    }
    this.addFiltersToGrid();
  }

  exportToExcel() {
    let activeColumnList = (this.jqxSearchOrdersGrid?.columns as any).records
      .filter((x) => x.hidden == false)
      .map(function (x) {
        if (x.datafield == "movement") {
          return "movementId";
        } else {
          return x.datafield;
        }
      });

    let searchParameters = this.searchParameters.getSearchParameters();
    searchParameters.columns = activeColumnList;
    searchParameters.includeAdditionalCharges = this.includeAdditionalCharges();

    searchParameters.includeStopReferences = this.includeStopReferences();

    if (searchParameters.includeStopReferences)
      searchParameters.columns.push("reference");

    this.isLoading(true);
    $(".close").click();
    dataModel
      .downloadFile(
        "SearchOrders/GetExcelReport",
        "post",
        searchParameters,
        "SearchOrdersReport.xlsx"
      )
      .then((x: any) => {
        this.isLoading(false);
      })
      .catch((r) => {
        showmessage(r ?? `Error occurred during request.`);
        this.isLoading(false);
      });
  }

  showGridAnyway() {
    this.showResultsTooBigMessage(false);
    this.searchParameters.skipCount(true);
    $("#jqxSearchOrdersGrid").css("display", "block");
    if (this.jqxSearchOrdersGrid) {
      this.refreshGrid();
    } else {
      this.createGrid();
    }
  }

  applySavedSearch(searchObject) {
    if (!searchObject) {
      return;
    }
    ($("#orderStatusList") as any).jqxDropDownList("uncheckAll");
    ($("#orderTypeList") as any).jqxDropDownList("uncheckAll");
    this.searchParameters.clearAllParameters();

    this.savedSearchApplied(true);
    this.searchParameters.status([]);
    this.searchParameters.orderType([]);

    //When saving the search, the name of the datafield isn't where the jqxgrid is expecting it.
    let cobj = Object.keys(searchObject.grid.columns).map((key) => {
      return { ...{ datafield: key }, ...searchObject.grid.columns[key] };
    });

    //Setting the columnsObj here so that we'll have the column order and which columns to show/hide whenever the grid is built downstream
    this.savedColumnsObject(cobj);

    //Filters with a filteroperator == or need to be added all at one time.
    let orFilterDataFields = <any>[];
    let dateRangeFilters = <any>[];

    if (
      searchObject.grid.filters.filterscount &&
      searchObject.grid.filters.filterscount > 0
    ) {
      var i;
      for (i = searchObject.grid.filters.filterscount - 1; i >= 0; i--) {
        let filtercondition = searchObject.grid.filters["filtercondition" + i];
        let filterdatafield = searchObject.grid.filters["filterdatafield" + i];
        let filteroperator = searchObject.grid.filters["filteroperator" + i];
        let filtertype = searchObject.grid.filters["filtertype" + i];
        let filtervalue = searchObject.grid.filters["filtervalue" + i];

        if (
          filtercondition == "LESS_THAN_OR_EQUAL" ||
          filtercondition == "GREATER_THAN_OR_EQUAL"
        ) {
          dateRangeFilters.push({
            filterdatafield: filterdatafield,
            filtervalue: filtervalue,
            filtercondition: filtercondition,
          });
        }

        if (
          filteroperator == "or" &&
          this.searchParameters[filterdatafield] &&
          orFilterDataFields.indexOf(filterdatafield) == -1
        ) {
          orFilterDataFields.push(filterdatafield);
        } else if (
          filteroperator == "and" &&
          this.searchParameters[filterdatafield] &&
          !(
            filtercondition == "LESS_THAN_OR_EQUAL" ||
            filtercondition == "GREATER_THAN_OR_EQUAL"
          )
        ) {
          this.searchParameters[filterdatafield](filtervalue);
        }
      }
      if (orFilterDataFields.length > 0) {
        orFilterDataFields.forEach((filterDataField) => {
          let values = <any>[];
          for (let filterArrayKey in searchObject.grid.filters) {
            let filterArrayValue = searchObject.grid.filters[filterArrayKey];
            if (filterArrayValue == filterDataField) {
              let filterAccessorKey = filterArrayKey.replace(
                "filterdatafield",
                ""
              );
              let value =
                searchObject.grid.filters["filtervalue" + filterAccessorKey];
              values.push(value);
            }
          }
          this.searchParameters[filterDataField](values);
        });
      }
      if (dateRangeFilters) {
        let dateDataFilerFields = [
          ...new Set(
            dateRangeFilters.map((x) => {
              return x.filterdatafield;
            })
          ),
        ];
        dateDataFilerFields.forEach((value) => {
          let fromFilter = dateRangeFilters.find(
            (x) =>
              x.filterdatafield == value &&
              x.filtercondition == "GREATER_THAN_OR_EQUAL"
          );
          let toFilter = dateRangeFilters.find(
            (x) =>
              x.filterdatafield == value &&
              x.filtercondition == "LESS_THAN_OR_EQUAL"
          );
          let dateRangeValue = new DateRangeObject({
            from: fromFilter.filtervalue,
            to: toFilter.filtervalue,
          });
          this.searchParameters[fromFilter.filterdatafield](dateRangeValue);
        });
      }
    }

    if (
      searchObject.additionalFilters &&
      searchObject.additionalFilters.selectedQuickDates
    ) {
      Object.keys(searchObject.additionalFilters.selectedQuickDates).map(
        (key) => {
          const prop = this.searchParameters[key];
          if (ko.isWritableObservable(prop)) {
            const date = this.searchParameters.ddlQuickDateSelects.find(
              (x) =>
                x.label ==
                searchObject.additionalFilters.selectedQuickDates[key]
            );
            if (date) {
              prop(date);
            }
          }
        }
      );
    }
  }

  getSavedSearches(): Promise<any> {
    return new Promise(function (resolve, reject) {
      dataModel
        .ajaxRequest("UserProfile/GetUserSearchFilterList", "get", {
          name: "jqxSearchOrdersGrid",
          pageName: window.location.pathname,
        })
        .done(function (data) {
          if (data) {
            var obj: any[] = data.map((x) => {
              let search = JSON.parse(x.search);
              return {
                text: search.userSearchName || "Default",
                search: new NamedGridStateObject(search),
                id: x.id,
              };
            });
            resolve(obj);
          } else {
            resolve(undefined);
          }
        })
        .fail(function () {
          reject();
        });
    });
  }

  deleteSearch() {
    let selectedSavedSearch = this.selectedSavedSearch();
    this.savedSearchApplied(false);

    dataModel
      .ajaxRequest(
        "UserProfile/DeleteUserSearchFilter/" + selectedSavedSearch.id
      )
      .done((x) => {
        let savedSearches = this.savedSearches();
        savedSearches.splice(selectedSavedSearch.uid, 1);
        this.savedSearches(savedSearches);
        this.searchParameters.clearAllParameters();
      });
  }

  refreshGrid(): Promise<any> {
    this.jqxSearchOrdersGrid?.clear();
    ($("#searchButton") as any).jqxButton({ disabled: true });
    $("#jqxSearchOrdersGrid").css("display", "block");

    return new Promise((resolve, reject) => {
      this.jqxSearchOrdersGrid?.setOptions({
        source: dataModel.getDataAdapter(this.source()),
      });
      this.jqxSearchOrdersGrid?.addEventHandler("bindingcomplete", (event) => {
        ($("#searchButton") as any).jqxButton({ disabled: false });
        if (event.owner.source.loadedData == "Orders > 100") {
          resolve("Orders > 100");
          return;
        }
        let rowdata = event.args.owner.getrows();
        if (rowdata.length > 0 && this.canShowExportToExcelButton() == true) {
          this.showExportToExcelButton(true);
        } else {
          this.showExportToExcelButton(false);
        }
        resolve(undefined);
      });
    });
  }

  source(): jqwidgets.GridSource {
    let searchParameters = this.searchParameters.getSearchParameters();

    return {
      url: "SearchOrders/GetOrdersForGrid",
      datatype: "json",
      filter: function (filters, recordsArray) {},
      formatdata: function (data) {
        //This formats the data that goes to the server, it doesn't add anything to the grid at all.
        //Here is where you add parameters to the request that goes to the server.
        let data2 = gridStateUtils.formatGridFilters({}, data);
        let data3 = { ...data2, ...searchParameters };
        return data3;
      },
      datafields: this.dataFields(),
    };
  }

  clearSearchParameters(event) {
    ($("#selectedSavedSearch") as any).jqxDropDownList("clearSelection");
    ($("#orderStatusList") as any).jqxDropDownList("uncheckAll");
    ($("#orderTypeList") as any).jqxDropDownList("uncheckAll");
    this.searchParameters.clearAllParameters();
    this.selectedSavedSearch(null);
    this.savedSearchApplied(false);
    this.searchInfoMessage(undefined);
  }

  createGrid(): Promise<any> {
    let vm = this;
    ($("#searchButton") as any).jqxButton({ disabled: true });
    return new Promise((resolve, reject) => {
      let dataAdapter = dataModel.getDataAdapter(this.source());
      let options: jqwidgets.GridOptions = {
        source: dataAdapter,
        width: "100%",
        altrows: true,
        sortable: true,
        autoheight: true,
        pageable: true,
        filterable: true,
        pagesize: 10,
        columnsresize: true,
        columnsreorder: true,
        enablebrowserselection: true,
        columnsmenu: false,
        columns: this.columns(),
        showtoolbar: true,
        rendertoolbar: (toolbar) => {
          if (!toolbar.isLoaded) {
            let gridStateViewModel =
              new GridStateComponentViewModel.viewModel();
            gridStateViewModel.actions = [
              "Clear Filters",
              "Clear Selection",
              "Refresh",
              "Save Search",
              "Show/Hide/Reorder Columns",
            ];

            // This get's called in the show/hide/reorder modal
            gridStateViewModel.overrideCustomGridModalOptionsSave = () => {
              vm.handleLoadSaveSearchModal(gridStateViewModel);
            };

            // this is called from 'save search' selection
            gridStateViewModel.loadSaveSearchModalOverride = () => {
              vm.handleLoadSaveSearchModal(gridStateViewModel);
            };

            gridStateViewModel.clearFilters = () => {
              this.jqxSearchOrdersGrid!.clearfilters();
              this.clearSearchParameters(null);
            };
            $(toolbar).append(GridStateComponentViewModel.template);
            toolbar.isLoaded = true;
            ko.applyBindingsToDescendants(gridStateViewModel, toolbar[0]);
          }
        },
        ready: function () {},
      };

      this.jqxSearchOrdersGrid = jqwidgets.createInstance(
        "#jqxSearchOrdersGrid",
        "jqxGrid",
        options
      );

      this.jqxSearchOrdersGrid?.addEventHandler("bindingcomplete", (event) => {
        ($("#searchButton") as any).jqxButton({ disabled: false });
        if (event.owner.source.loadedData == "Orders > 100") {
          resolve("Orders > 100");
          return;
        }
        let rowdata = event.args.owner.getrows();
        if (rowdata.length > 0 && this.canShowExportToExcelButton() == true) {
          this.showExportToExcelButton(true);
        } else {
          this.showExportToExcelButton(false);
        }

        resolve(undefined);
      });
    });
  }

  handleLoadSaveSearchModal = (gridStateVM) => {
    var vm = this;

    gridStateVM.loadSaveSearchModal(
      $("#jqxSearchOrdersGrid"),
      vm.doWhenSaveSearchCallBack,
      vm.savedSearches().map((x) => {
        return { id: x.id, text: x.text };
      }),
      { selectedQuickDates: this.searchParameters.selectedQuickDates }
    );
  };

  doWhenSaveSearchCallBack = (savedData) => {
    var vm = this;
    let searches = [...vm.savedSearches()];
    let currentItemIndex = searches.findIndex(
      (x) => x.text == savedData.searchname
    );

    if (currentItemIndex != -1) {
      searches.splice(currentItemIndex, 1);
    }

    searches.push({
      text: savedData.searchname,
      search: new NamedGridStateObject(JSON.parse(savedData.filters)),
      id: savedData.id,
    });

    vm.savedSearches(searches);
  };

  savedColumnsObject: Observable<jqwidgets.GridColumn[] | undefined> =
    ko.observable();

  showOrderDetailModal = (row: any) => {
    this.orderDetailObject(undefined);
    let model = new OrderDetailsModal(
      this.jqxSearchOrdersGrid?.getrowdatabyid(row),
      this.customFields
    );
    model.getDetailData().then(() => {
      this.orderDetailObject(model);
    });
  };

  columns(): jqwidgets.GridColumn[] {
    let columns: jqwidgets.GridColumn[] = [
      {
        text: "",
        width: 100,
        datafield: "detailsButton",
        filterable: false,
        sortable: false,
        pinned: true,
        columntype: "button",
        buttonclick: this.showOrderDetailModal,
        cellsrenderer: function () {
          return "Details";
        },
      },
      {
        text: "Order Id",
        datafield: "orderExId",
        width: "100",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let cell = $(defaulthtml!);
          cell.html(
            "<a href='/NewOrder/" +
              value +
              "' target='_blank' style='color:blue;'>" +
              value +
              "</a>"
          );
          return cell[0].outerHTML;
        },
      },
      { text: "Agency", datafield: "agency", width: "100" },
      {
        text: "Bonded",
        datafield: "bonded",
        width: "100",
        filtertype: "bool",
        columntype: "checkbox",
        editable: false,
      },
      { text: "Billing Distance", datafield: "billingDistance", width: "130" },
      { text: "BOL Number", datafield: "bol", width: "100" },
      { text: "Booking Number", datafield: "bookingNumber", width: "130" },
      { text: "Carrier", datafield: "carrier", width: "100" },
      {
        text: "Commodity Description",
        datafield: "commodityDescription",
        width: "160",
      },
      { text: "Commodity Id", datafield: "commodityId", width: "100" },
      {
        text: "Consignee Address",
        datafield: "consigneeAddress",
        width: "150",
      },
      { text: "Consignee City", datafield: "consigneeCity", width: "120" },
      {
        text: "Consignee Location Code",
        datafield: "consigneeLocation",
        width: "120",
      },
      { text: "Consignee State", datafield: "consigneeState", width: "120" },
      { text: "Container", datafield: "container", width: "100" },
      { text: this.customFields()[0], datafield: "customField1", width: "110" },
      { text: this.customFields()[1], datafield: "customField2", width: "110" },
      { text: this.customFields()[2], datafield: "customField3", width: "110" },
      { text: "Customer", datafield: "customer", width: "200" },
      { text: "Customer Code", datafield: "customerCode", width: "110" },
      {
        text: "Customer Order Number",
        datafield: "customerOrderNumber",
        width: "175",
      },
      {
        text: "Customer Reference",
        datafield: "customerReference",
        width: "140",
      },
      {
        text: "Cut Off Date",
        datafield: "cutOffDate",
        width: "100",
        cellsformat: "MM/dd/yyyy",
      },
      {
        text: "Dray Delivery",
        datafield: "drayDelivery",
        width: "100",
        cellsformat: "MM/dd/yyyy",
      },
      {
        text: "Dray Pickup",
        datafield: "drayPickup",
        width: "100",
        cellsformat: "MM/dd/yyyy",
      },
      { text: "Driver", datafield: "driver1", width: "100" },
      { text: "Driver 2", datafield: "driver2", width: "100" },
      { text: "Entered By", datafield: "enteredBy", width: "100" },
      { text: "Freight Charge", datafield: "freightCharge", width: "110" },
      {
        text: "Load Date",
        datafield: "loadDate",
        width: "100",
        cellsformat: "MM/dd/yyyy",
      },
      { text: "Movement", datafield: "movementId", width: "100" },
      {
        text: "Movement Brokered",
        datafield: "movementBrokered",
        width: "130",
        filtertype: "bool",
        columntype: "checkbox",
        editable: false,
      },
      { text: "OCP Total", datafield: "ocpTotal" },
      { text: "Operations User", datafield: "operationsUser", width: "115" },
      {
        text: "Order Brokered",
        datafield: "orderBrokered",
        width: "110",
        filtertype: "bool",
        columntype: "checkbox",
        editable: false,
      },
      { text: "Ordered By", datafield: "orderedBy", width: "100" },
      {
        text: "Override Driver Pay",
        datafield: "overrideDriverPay",
        width: "135",
      },
      {
        text: "Pickup Date",
        datafield: "pickupDate",
        width: "100",
        cellsformat: "MM/dd/yyyy",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let cell = $(defaulthtml!);
          const date = datetimeUTC(value);

          if (date.isValid()) {
            cell.html(date.format("MM/DD/YYYY"));
          }

          return cell[0].outerHTML;
        },
      },
      { text: "Pickup Number", datafield: "pickupNumber", width: "110" },
      {
        text: "Delivery Date",
        datafield: "deliveryDate",
        width: "100",
        cellsformat: "MM/dd/yyyy",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let cell = $(defaulthtml!);
          const date = datetimeUTC(value);

          if (date.isValid()) {
            cell.html(date.format("MM/DD/YYYY"));
          }

          return cell[0].outerHTML;
        },
      },
      {
        text: "Sail Date",
        datafield: "sailDate",
        width: "100",
        cellsformat: "MM/dd/yyyy",
      },
      { text: "Sales Person", datafield: "salesPersonName", width: "150" },
      { text: "Seal Number", datafield: "sealNumber", hidden: true },
      { text: "Shipper Address", datafield: "shipperAddress", width: "170" },
      { text: "Shipper City", datafield: "shipperCity", width: "100" },
      {
        text: "Shipper Location Code",
        datafield: "shipperLocation",
        width: "165",
      },
      { text: "Shipper State", datafield: "shipperState", width: "100" },
      { text: "Status", datafield: "status", width: "100" },
      { text: "Steamship Line", datafield: "steamShipLine", width: "125" },
      { text: "Terminal", datafield: "terminal", width: "100" },
      { text: "TIR1 Number", datafield: "tiR1Number", width: "100" },
      { text: "TIR2Number", datafield: "tiR2Number", width: "100" },
      { text: "Total Charges", datafield: "totalCharges", width: "100" },
      {
        text: "Other Charge Total",
        datafield: "otherChargeTotal",
        width: "100",
      },
      { text: "Tractor", datafield: "tractor", width: "100" },
      { text: "Trailer", datafield: "trailer", width: "100" },
      { text: "Trailer Type", datafield: "trailerType", width: "100" },
      { text: "Vessel", datafield: "vessel", width: "100" },
      { text: "Weight", datafield: "weight", width: "100" },
      {
        text: "Carrier Relationship Manager",
        datafield: "carrierRelationshipManager",
        width: "115",
      },
      //these have to be here in order for us to add any filters for the grid to them.
      {
        text: "Billing Date",
        datafield: "billingDate",
        hidden: true,
        cellsformat: "MM/dd/yyyy",
        width: "150",
        cellsrenderer: (
          row,
          columnfield,
          value,
          defaulthtml,
          columnproperties,
          rowdata
        ) => {
          let cell = $(defaulthtml!);
          const date = datetimeUTC(value);

          if (date.isValid()) {
            cell.html(date.format("MM/DD/YYYY"));
          }

          return cell[0].outerHTML;
        },
      },
      {
        text: "",
        datafield: "estimatePickupDate",
        hidden: true,
        cellsformat: "MM/dd/yyyy",
      },
      {
        text: "",
        datafield: "estimateDeliveryDate",
        hidden: true,
        cellsformat: "MM/dd/yyyy",
      },
      {
        text: "",
        datafield: "createDate",
        hidden: true,
        cellsformat: "MM/dd/yyyy",
      },
      { text: "Chassis", datafield: "chassis", hidden: false, width: "100" },
      { text: "Forwarder", datafield: "forwarder", hidden: true, width: "100" },
      { text: "Load Port", datafield: "loadPort", hidden: true, width: "100" },
      {
        text: "Manifest Id",
        datafield: "manifestId",
        hidden: true,
        width: "100",
      },
      {
        text: "Port Of Delivery",
        datafield: "portOfDelivery",
        hidden: true,
        width: "100",
      },
      { text: "Reference", datafield: "reference", hidden: true, width: "100" },
      { text: "", datafield: "shipperStates", hidden: true },
      { text: "", datafield: "consigneeStates", hidden: true },
      { text: "", datafield: "orderType", hidden: true },
      { text: "", datafield: "searchAllAgencies", hidden: true },
      { text: "", datafield: "tractorId", hidden: true }, // used for tractor ddl
      { text: "", datafield: "driver", hidden: true }, // used for driver ddl
      { text: "", datafield: "stopreference", hidden: true },
      { text: "", datafield: "operationUser", hidden: true },
      { text: "", datafield: "createdBy", hidden: true },
    ];

    if (this.savedColumnsObject()) {
      //Use the savedColumnsObject if there is one but first grab the detailsButton and orderExId columns for their methods.
      let savedColumnsObject = this.savedColumnsObject();
      let detailsButton = columns.find((x) => x.datafield == "detailsButton");
      savedColumnsObject![0] = detailsButton!;
      let orderExIdSavedColumn = savedColumnsObject?.findIndex(
        (x) => x.datafield == "orderExId"
      );
      savedColumnsObject![orderExIdSavedColumn!].cellsrenderer =
        columns[1].cellsrenderer;

      // If saved column object doesn't have any column additions that was added, then include that as well.
      let missingColumns = columns.filter(
        (x) =>
          savedColumnsObject!.some((y) => y.datafield == x.datafield) == false
      );

      savedColumnsObject!.push(...missingColumns);

      return savedColumnsObject!;
    } else {
      return columns;
    }
  }

  dataFields(): jqwidgets.GridSourceDataFields[] {
    return [
      { name: "id" },
      { name: "detailsButton" },
      { type: "string", name: "agency" },
      { type: "string", name: "billingDistance" },
      { type: "string", name: "bol" },
      { type: "string", name: "bookingNumber" },
      { type: "string", name: "carrier" },
      { type: "string", name: "commodityDescription" },
      { type: "string", name: "commodityId" },
      { type: "string", name: "consigneeAddress" },
      { type: "string", name: "consigneeCity" },
      { type: "string", name: "consigneeLocation" },
      { type: "string", name: "consigneeState" },
      { type: "string", name: "container" },
      { type: "date", name: "cutOffDate" },
      { type: "string", name: "customField1" },
      { type: "string", name: "customField2" },
      { type: "string", name: "customField3" },
      { type: "string", name: "customer" },
      { type: "string", name: "customerCode" },
      { type: "string", name: "customerOrderNumber" },
      { type: "string", name: "customerReference" },
      { type: "date", name: "drayDelivery" },
      { type: "date", name: "drayPickup" },
      { type: "string", name: "driver1" },
      { type: "string", name: "driver2" },
      { type: "string", name: "enteredBy" },
      { type: "string", name: "freightCharge" },
      { type: "string", name: "manifestId" },
      { type: "string", name: "orderExId" },

      { type: "date", name: "loadDate" },
      { type: "string", name: "movementId" },
      { type: "boolean", name: "movementBrokered" },
      { type: "string", name: "operationsUser" },
      { type: "boolean", name: "orderBrokered" },
      { type: "string", name: "orderedBy" },
      { type: "string", name: "overrideDriverPay" },
      { type: "date", name: "pickupDate" },
      { type: "string", name: "pickupNumber" },
      { type: "date", name: "deliveryDate" },
      { type: "date", name: "sailDate" },
      { type: "string", name: "salesPersonName" },
      { type: "string", name: "shipperAddress" },
      { type: "string", name: "shipperCity" },
      { type: "string", name: "shipperLocation" },
      { type: "string", name: "shipperState" },
      { type: "string", name: "status" },
      { type: "string", name: "steamShipLine" },
      { type: "string", name: "terminal" },
      { type: "string", name: "tiR1Number" },
      { type: "string", name: "tiR2Number" },
      { type: "string", name: "totalCharges" },
      { type: "string", name: "tractor" },
      { type: "string", name: "trailer" },
      { type: "string", name: "trailerType" },
      { type: "string", name: "vessel" },
      { type: "string", name: "weight" },
      { type: "string", name: "orderType" },
      { type: "string", name: "operationUser" },
      { type: "string", name: "carrierRelationshipManager" },
      { type: "string", name: "createdBy" },
      { type: "string", name: "shipperStates" },
      { type: "string", name: "consigneeStates" },
      { type: "date", name: "billingDate" },
      { type: "date", name: "createDate" },
      { type: "date", name: "estimatePickupDate" },
      { type: "date", name: "estimateDeliveryDate" },
      { type: "string", name: "reference" },
      { type: "string", name: "loadPort" },
      { type: "string", name: "portOfDelivery" },
      { type: "string", name: "forwarder" },
      { type: "string", name: "chassis" },
      { type: "string", name: "sealNumber" },
      { type: "string", name: "ocpTotal" },
      { type: "string", name: "otherChargeTotal" },
      { type: "boolean", name: "bonded" },
      { type: "boolean", name: "searchAllAgencies" },
      { type: "string", name: "tractorId" }, // used for tractor ddl
      { type: "string", name: "driver" }, // used for driver ddl
      { type: "string", name: "createdBy" },
    ];
  }

  addFiltersToGrid() {
    //This doesn't actually do anything to the grid but allows us to pull the filters
    //from the grid when the gridstate is saved.
    // ATTN: If you run into an issue here where you have a saved parameter and add it to the grid
    //        and it doesn't show up, it's likely because you don't have the datafield in the 'datafields'
    //        or 'columns' object.
    this.jqxSearchOrdersGrid!.clearfilters();
    let searchParameters = this.searchParameters.getSearchParameters();

    for (let key in searchParameters) {
      if (searchParameters[key]) {
        if (typeof searchParameters[key] == "string") {
          let filtergroup = new $.jqx.filter();
          let filter = filtergroup.createfilter(
            "stringfilter",
            searchParameters[key],
            "contains"
          );
          filtergroup.addfilter("and", filter);
          this.jqxSearchOrdersGrid!.addfilter(key, filtergroup, true);
        } else if (
          Array.isArray(searchParameters[key]) &&
          searchParameters[key].length > 0
        ) {
          let filtergroup = new $.jqx.filter();

          searchParameters[key].forEach((element) => {
            let filter = filtergroup.createfilter(
              "stringfilter",
              element,
              "contains"
            );
            filtergroup.addfilter("or", filter);
          });
          this.jqxSearchOrdersGrid!.addfilter(key, filtergroup, true);
        } else if (
          typeof searchParameters[key] == "object" &&
          this.dataFields().find((x) => x.name == key) &&
          this.dataFields().find((x) => x.name == key)!.type == "date"
        ) {
          let filtergroup = new $.jqx.filter();

          let fromfilter = filtergroup.createfilter(
            "datefilter",
            searchParameters[key].from,
            "GREATER_THAN_OR_EQUAL"
          );
          filtergroup.addfilter("and", fromfilter);

          let tofilter = filtergroup.createfilter(
            "datefilter",
            searchParameters[key].to,
            "LESS_THAN_OR_EQUAL"
          );
          filtergroup.addfilter("and", tofilter);

          this.jqxSearchOrdersGrid!.addfilter(key, filtergroup, true);
        } else if (typeof searchParameters[key] == "boolean") {
          let filtergroup = new $.jqx.filter();
          let filter = filtergroup.createfilter(
            "booleanfilter",
            searchParameters[key],
            "EQUAL"
          );
          filtergroup.addfilter("and", filter);
          this.jqxSearchOrdersGrid!.addfilter(key, filtergroup, true);
        }
      }
    }
    this.jqxSearchOrdersGrid!.refreshfilterrow();
    this.jqxSearchOrdersGrid!.applyfilters();
  }
}

class SearchParameters {
  ddListOrderStatuses = [
    { description: "Available", code: 1 },
    { description: "Delivered", code: 2 },
    { description: "In Progress", code: 3 },
    { description: "Voided", code: 4 },
    { description: "Quote", code: 5 },
  ];

  ddListOrderTypes = [
    "Billed",
    "Brokered",
    "Container",
    "HazMat",
    "High Value",
    "LTL",
    "Truckline",
    "Unbilled",
    "Bonded",
  ];

  dateFormat = "MM/DD/YYYY";
  today = modifyDate(new Date()).addDays(0).format(this.dateFormat);

  ddlQuickDateSelects = [
    new QuickDate({
      label: "Current Date",
      value: {
        from: modifyDate(new Date()).addDays(0).format(this.dateFormat),
        to: this.today,
      },
    }),
    new QuickDate({
      label: "Prior Day",
      value: {
        from: modifyDate(new Date()).addDays(-1).format(this.dateFormat),
        to: this.today,
      },
    }),
    new QuickDate({
      label: "Prior 7 Days",
      value: {
        from: modifyDate(new Date()).addDays(-7).format(this.dateFormat),
        to: this.today,
      },
    }),
    new QuickDate({
      label: "Last 30 Days",
      value: {
        from: modifyDate(new Date()).addDays(-30).format(this.dateFormat),
        to: this.today,
      },
    }),
    new QuickDate({
      label: "Last 90 Days",
      value: {
        from: modifyDate(new Date()).addDays(-90).format(this.dateFormat),
        to: this.today,
      },
    }),
    new QuickDate({
      label: "Last 180 Days",
      value: {
        from: modifyDate(new Date()).addDays(-180).format(this.dateFormat),
        to: this.today,
      },
    }),
    new QuickDate({
      label: "Last 365 Days",
      value: {
        from: modifyDate(new Date()).addDays(-365).format(this.dateFormat),
        to: this.today,
      },
    }),
    new QuickDate({
      label: "Last Week",
      value: {
        from: modifyDate(new Date()).addWeeks(-1).format(this.dateFormat),
        to: this.today,
      },
    }),
    new QuickDate({
      label: "Last Month",
      value: {
        from: modifyDate(new Date()).addMonths(-1).format(this.dateFormat),
        to: this.today,
      },
    }),
    new QuickDate({
      label: "Last 3 Months",
      value: {
        from: modifyDate(new Date()).addMonths(-3).format(this.dateFormat),
        to: this.today,
      },
    }),
    new QuickDate({
      label: "Last 6 Months",
      value: {
        from: modifyDate(new Date()).addMonths(-6).format(this.dateFormat),
        to: this.today,
      },
    }),
  ];

  selectedQuickDates = {
    billingSelectedQuickDate: undefined,
    createSelectedQuickDate: undefined,
    estimatePickupSelectedQuickDate: undefined,
    estimateDeliverySelectedQuickDate: undefined,
  };

  // preventing circular updates between date fields and ddl date selects (which update date fields)
  flags = {
    billingSelectedQuickDate: {
      dateChangedFromSelect: false,
      doNotResetDateField: false,
    },
    createSelectedQuickDate: {
      dateChangedFromSelect: false,
      doNotResetDateField: false,
    },
    estimatePickupSelectedQuickDate: {
      dateChangedFromSelect: false,
      doNotResetDateField: false,
    },
    estimateDeliverySelectedQuickDate: {
      dateChangedFromSelect: false,
      doNotResetDateField: false,
    },
  };

  constructor() {
    this.billingSelectedQuickDate.subscribe((quickdate) => {
      if (quickdate) {
        this.flags.billingSelectedQuickDate.dateChangedFromSelect = true;
        this.billingDate(quickdate.value);
        this.selectedQuickDates.billingSelectedQuickDate = quickdate.label;
      } else {
        if (this.flags.billingSelectedQuickDate.doNotResetDateField == false) {
          this.billingDate(undefined);
        }

        this.selectedQuickDates.billingSelectedQuickDate = undefined;
      }

      this.flags.billingSelectedQuickDate.doNotResetDateField = false;
    });

    this.createSelectedQuickDate.subscribe((quickdate) => {
      if (quickdate) {
        this.flags.createSelectedQuickDate.dateChangedFromSelect = true;
        this.createDate(quickdate.value);
        this.selectedQuickDates.createSelectedQuickDate = quickdate.label;
      } else {
        if (this.flags.createSelectedQuickDate.doNotResetDateField == false) {
          this.createDate(undefined);
        }

        this.selectedQuickDates.createSelectedQuickDate = undefined;
      }

      this.flags.createSelectedQuickDate.doNotResetDateField = false;
    });

    this.estimatePickupSelectedQuickDate.subscribe((quickdate) => {
      if (quickdate) {
        this.flags.estimatePickupSelectedQuickDate.dateChangedFromSelect = true;
        this.estimatePickupDate(quickdate.value);
        this.selectedQuickDates.estimatePickupSelectedQuickDate =
          quickdate.label;
      } else {
        if (
          this.flags.estimatePickupSelectedQuickDate.doNotResetDateField ==
          false
        ) {
          this.estimatePickupDate(undefined);
        }

        this.selectedQuickDates.estimatePickupSelectedQuickDate = undefined;
      }

      this.flags.estimatePickupSelectedQuickDate.doNotResetDateField = false;
    });

    this.estimateDeliverySelectedQuickDate.subscribe((quickdate) => {
      if (quickdate) {
        this.flags.estimateDeliverySelectedQuickDate.dateChangedFromSelect =
          true;
        this.estimateDeliveryDate(quickdate.value);
        this.selectedQuickDates.estimateDeliverySelectedQuickDate =
          quickdate.label;
      } else {
        if (
          this.flags.estimateDeliverySelectedQuickDate.doNotResetDateField ==
          false
        ) {
          this.estimateDeliveryDate(undefined);
        }

        this.selectedQuickDates.estimateDeliverySelectedQuickDate = undefined;
      }

      this.flags.estimateDeliverySelectedQuickDate.doNotResetDateField = false;
    });
  }

  clearAllParameters() {
    Object.keys(this).forEach((key, i, ar) => {
      if (ko.isObservable(this[key])) {
        if (key.toLowerCase().includes("date")) {
          this[key](null);
        } else if (typeof this[key]() == "boolean") {
          this[key](false);
        } else if (Array.isArray(this[key]())) {
          this[key]([]);
        } else {
          this[key]("");
        }
      }
    });
  }

  getSearchParameters() {
    return {
      //General
      orderExId: this.orderExId()
        ? this.orderExId().code
          ? this.orderExId().code
          : this.orderExId()
        : null,
      movementId: this.movementId()
        ? this.movementId().code
          ? this.movementId().code
          : this.movementId()
        : null,
      manifestId: this.manifestId()
        ? this.manifestId().code
          ? this.manifestId().code
          : this.manifestId()
        : null,
      status: this.status(),
      orderType: this.orderType(),
      terminal: this.terminal() ? this.terminal()?.trim() : null,
      carrierRelationshipManager:
        typeof this.carrierRelationshipManager() == "object" &&
        this.carrierRelationshipManager().code
          ? this.carrierRelationshipManager().code
          : this.carrierRelationshipManager(),
      operationUser:
        typeof this.operationUser() == "object" && this.operationUser().code
          ? this.operationUser().code
          : this.operationUser(),
      customer:
        typeof this.customer() == "object" && this.customer().code
          ? this.customer().code
          : this.customer(),
      salesPersonName:
        typeof this.salesPersonName() == "object" && this.salesPersonName().code
          ? this.salesPersonName().code.trim()
          : this.salesPersonName(),
      createdBy:
        typeof this.createdBy() == "object" && this.createdBy().code
          ? this.createdBy().code.trim()
          : this.createdBy(),
      orderedBy: this.orderedBy() ? this.orderedBy()?.trim() : null,

      //searchAllAgencies affects a few of the dropdowns above.
      searchAllAgencies: this.searchAllAgencies(),

      //Location.Shipper
      shipperLocation:
        typeof this.shipperLocation() == "object" && this.shipperLocation().code
          ? this.shipperLocation().code
          : this.shipperLocation(),
      shipperAddress: this.shipperAddress(),
      shipperCity:
        typeof this.shipperCity() == "object" && this.shipperCity().cityName
          ? this.shipperCity().cityName
          : this.shipperCity(),
      shipperStates: this.shipperStates(),
      //Location.Consignee
      consigneeLocation:
        typeof this.consigneeLocation() == "object" &&
        this.consigneeLocation().code
          ? this.consigneeLocation().code
          : this.consigneeLocation(),
      consigneeAddress: this.consigneeAddress(),
      consigneeCity:
        typeof this.consigneeCity() == "object" && this.consigneeCity().cityName
          ? this.consigneeCity().cityName
          : this.consigneeCity(),
      consigneeStates: this.consigneeStates(),

      //Dates
      billingDate: this.billingDate()
        ? {
            from: this.billingDate().from
              ? datetimeUTC(this.billingDate().from).format("MM/DD/YYYY")
              : null,
            to: this.billingDate().to
              ? datetimeUTC(this.billingDate().to).format("MM/DD/YYYY")
              : null,
          }
        : null,
      createDate: this.createDate()
        ? {
            from: this.createDate().from
              ? datetimeUTC(this.createDate().from).format("MM/DD/YYYY")
              : null,
            to: this.createDate().to
              ? datetimeUTC(this.createDate().to).format("MM/DD/YYYY")
              : null,
          }
        : null,
      estimatePickupDate: this.estimatePickupDate()
        ? {
            from: this.estimatePickupDate().from
              ? datetimeUTC(this.estimatePickupDate().from).format("MM/DD/YYYY")
              : null,
            to: this.estimatePickupDate().to
              ? datetimeUTC(this.estimatePickupDate().to).format("MM/DD/YYYY")
              : null,
          }
        : null,
      estimateDeliveryDate: this.estimateDeliveryDate()
        ? {
            from: this.estimateDeliveryDate().from
              ? datetimeUTC(this.estimateDeliveryDate().from).format(
                  "MM/DD/YYYY"
                )
              : null,
            to: this.estimateDeliveryDate().to
              ? datetimeUTC(this.estimateDeliveryDate().to).format("MM/DD/YYYY")
              : null,
          }
        : null,

      //Reference Numbers
      bol: this.bol(),
      stopreference: this.stopreference(),
      customerReference: this.customerReference(),
      customField1: this.customField1(),
      customField2: this.customField2(),
      customField3: this.customField3(),

      //Container
      container: this.container(),
      steamShipLine:
        typeof this.steamShipLine() == "object"
          ? this.steamShipLine().name
          : this.steamShipLineTxt(),
      vessel: this.vessel(),
      loadPort:
        typeof this.loadPort() == "object"
          ? this.loadPort().name
          : this.loadPortTxt(),
      portOfDelivery:
        typeof this.portOfDelivery() == "object"
          ? this.portOfDelivery().name
          : this.portOfDeliveryTxt(),
      forwarder:
        typeof this.forwarder() == "object"
          ? this.forwarder().name
          : this.forwarderTxt(),
      bookingNumber: this.bookingNumber(),
      pickupNumber: this.pickupNumber(),
      customerOrderNumber: this.customerOrderNumber(),
      tiR1Number: this.tiR1Number(),
      tiR2Number: this.tiR2Number(),
      chassis: this.chassis(),
      sealNumber: this.sealNumber(),

      loadDate: this.loadDate()
        ? {
            from: this.loadDate()?.from ? this.loadDate()?.from.toJSON() : null,
            to: this.loadDate()?.to ? this.loadDate()?.to.toJSON() : null,
          }
        : null,
      cutOffDate: this.cutOffDate()
        ? {
            from: this.cutOffDate()?.from
              ? this.cutOffDate()?.from.toJSON()
              : null,
            to: this.cutOffDate()?.to ? this.cutOffDate()?.to.toJSON() : null,
          }
        : null,
      sailDate: this.sailDate()
        ? {
            from: this.sailDate()?.from ? this.sailDate()?.from.toJSON() : null,
            to: this.sailDate()?.to ? this.sailDate()?.to.toJSON() : null,
          }
        : null,

      //Equipment & Capacity
      driver:
        typeof this.driver() == "object" ? this.driver().code : this.driver(),
      tractorId:
        typeof this.tractorId() == "object"
          ? this.tractorId().code
          : this.tractorId(),
      trailer:
        typeof this.trailer() == "object"
          ? this.trailer().code
          : this.trailer(),
      carrier:
        typeof this.carrier() == "object"
          ? this.carrier().code
          : this.carrier(),

      columns: new Array(),
      includeAdditionalCharges: false,
      includeStopReferences: false,
      skipCount: this.skipCount(),
    };
  }

  handleDateValueChange = (prop, { from, to }) => {
    if (this.flags[prop].dateChangedFromSelect == false && (!from || !to)) {
      this.flags[prop].doNotResetDateField = true;
      this[prop](undefined);
    }

    this.flags[prop].dateChangedFromSelect = false;
  };

  //General
  orderTextBox: Observable<string | undefined> = ko.observable();
  orderExId: Observable<any> = ko.observable();

  movementTextBox: Observable<string | undefined> = ko.observable();
  movementId: Observable<any> = ko.observable();

  manifestTextBox: Observable<string | undefined> = ko.observable();
  manifestId: Observable<any> = ko.observable();

  status: ObservableArray<string> = ko
    .observableArray()

    .extend({
      required: { params: true, message: "Order Status is required." },
    });

  orderType: Observable<string[]> = ko.observableArray([]);

  operationUserTextBox: Observable<string | undefined> = ko.observable();
  operationUser: Observable<any> = ko.observable();

  carrierRelationshipManagerTextBox: Observable<string | undefined> =
    ko.observable();
  carrierRelationshipManager: Observable<any> = ko.observable();

  customerTextBox: Observable<string | undefined> = ko.observable();
  customer: Observable<any> = ko.observable();

  salesPersonTextBox: Observable<any> = ko.observable();
  salesPersonName: Observable<any> = ko.observable();

  createdByTextBox: Observable<any> = ko.observable();
  createdBy: Observable<any> = ko.observable();

  terminal: Observable<string | undefined> = ko.observable();
  orderedBy: Observable<string | undefined> = ko.observable();
  searchAllAgencies: Observable<boolean> = ko.observable(false);

  //Location.shipper
  shipperLocation: Observable<any> = ko.observable();
  shipperSelectedLocationId: Observable<any> = ko.observable();

  shipperAddress: Observable<any> = ko.observable();

  shipperCityTextBox: Observable<any> = ko.observable();
  shipperCity: Observable<any> = ko.observable();

  shipperStates: Observable<string[]> = ko.observableArray([]);

  //Location.consignee
  consigneeLocation: Observable<any> = ko.observable();
  consigneeSelectedLocationId: Observable<any> = ko.observable();

  consigneeAddress: Observable<any> = ko.observable();

  consigneeCityTextBox: Observable<any> = ko.observable();
  consigneeCity: Observable<any> = ko.observable();

  consigneeStates: ObservableArray<any[]> = ko.observableArray();

  //Dates
  billingDate: Observable<DateRangeObject | undefined> = ko.observable();
  createDate: Observable<DateRangeObject | undefined> = ko.observable();
  estimatePickupDate: Observable<DateRangeObject | undefined> = ko.observable();
  estimateDeliveryDate: Observable<DateRangeObject | undefined> =
    ko.observable();

  billingSelectedQuickDate: Observable<QuickDate | undefined> = ko.observable();
  createSelectedQuickDate: Observable<QuickDate | undefined> = ko.observable();
  estimatePickupSelectedQuickDate: Observable<QuickDate | undefined> =
    ko.observable();
  estimateDeliverySelectedQuickDate: Observable<QuickDate | undefined> =
    ko.observable();

  //Reference Numbers
  bol: Observable<any> = ko.observable();
  stopreference: Observable<any> = ko.observable();
  customerReference: Observable<any> = ko.observable();
  customField1: Observable<any> = ko.observable();
  customField2: Observable<any> = ko.observable();
  customField3: Observable<any> = ko.observable();

  //Container
  container: Observable<any> = ko.observable();
  steamShipLine: Observable<any> = ko.observable();
  steamShipLineTxt: Observable<any> = ko.observable();
  vessel: Observable<any> = ko.observable();
  loadPort: Observable<any> = ko.observable();
  loadPortTxt: Observable<any> = ko.observable();
  portOfDelivery: Observable<any> = ko.observable();
  portOfDeliveryTxt: Observable<any> = ko.observable();
  forwarder: Observable<any> = ko.observable();
  forwarderTxt: Observable<any> = ko.observable();
  bookingNumber: Observable<any> = ko.observable();
  pickupNumber: Observable<any> = ko.observable();
  customerOrderNumber: Observable<any> = ko.observable();
  tiR1Number: Observable<any> = ko.observable();
  tiR2Number: Observable<any> = ko.observable();
  chassis: Observable<any> = ko.observable();
  sealNumber: Observable<any> = ko.observable();

  loadDate: Observable<DateRangeObject | undefined> = ko.observable();
  cutOffDate: Observable<DateRangeObject | undefined> = ko.observable();
  sailDate: Observable<DateRangeObject | undefined> = ko.observable();

  driver: Observable<any> = ko.observable();
  driverId: Observable<any> = ko.observable();
  tractorId: Observable<any> = ko.observable();
  tractorTextBox: Observable<any> = ko.observable();
  trailer: Observable<any> = ko.observable();
  trailerId: Observable<any> = ko.observable();
  carrier: Observable<any> = ko.observable();
  carrierId: Observable<any> = ko.observable();

  skipCount: Observable<any> = ko.observable(false);
}

class NamedGridStateObject {
  userSearchName: string;
  grid: GridState;
  additionalFilters: object;

  constructor(x) {
    x = x || {};
    this.userSearchName = x.userSearchName;
    this.grid = x.grid;
    this.additionalFilters = x.additionalFilters;
  }
}

class DateRangeObject {
  to: Date;
  from: Date;

  constructor(x) {
    x = x || {};
    this.to = x.to;
    this.from = x.from;
  }
}

class GridState {
  columns?: any[];
  filters?: GridFilters;
  groups?: any[];
  height?: number;
  pagenum?: number;
  pagesize?: number;
  pagesizeoptions?: number;
  selectedrowindexes?: number;
  width?: string;
}

class GridFilters {
  filterscount?: number;
}

class QuickDate {
  label;
  value: DateRangeObject;

  constructor(x) {
    x = x || {};
    this.label = x.label;
    this.value = x.value;
  }
}

ko.bindingHandlers.geNewDropDownList = {
  init: function (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) {
    let modelValue = valueAccessor();
    let allBindings = allBindingsAccessor();
    let sourceData = allBindings.source;
    let options: jqwidgets.DropDownListOptions = allBindings.properties || {};
    let style = allBindings.style || {};

    if (options.dropDownHeight == undefined) {
      options.autoDropDownHeight = true;
    }

    options.width = "165px";
    options.height = "28px";

    var container = $(element);
    var ddList = $("<div>", {
      style: "position: relative;",
    });

    container.append(ddList);

    var id = container.attr("id");
    if (id != null) {
      container.removeAttr("id");
      ddList.attr("id", id);
    }

    if (style && style.paddingBottom) {
      ddList.css("padding-bottom", style.paddingBottom);
    }

    if (options.checkboxes) {
      var source = {
        localdata: sourceData,
        datatype: "obserableArray",
      };
      var dataAdapter = new $.jqx.dataAdapter(source);

      options.source = dataAdapter;

      if (allBindings.displayMember) {
        options.displayMember = "description";
      }
      if (allBindings.valueMember) {
        options.valueMember = "code";
      }

      options.enableBrowserBoundsDetection = true;
      options.animationType = "none";
      let jqxDropDownList: jqwidgets.jqxDropDownList = jqwidgets.createInstance(
        "#" + id,
        "jqxDropDownList",
        options
      );

      jqxDropDownList.addEventHandler("checkChange", function (event) {
        let checkedItems = jqxDropDownList
          .getCheckedItems()
          .map((value, index, array) => {
            return value.value;
          });

        modelValue(checkedItems);
      });

      $(window).on("scroll", function (e) {
        jqxDropDownList.close();
      });

      let checkListUpdating = false;
      modelValue.subscribe((value) => {
        if (checkListUpdating == false) {
          checkListUpdating = true;
          if (value && value.length > 0) {
            value.forEach((element) => {
              jqxDropDownList.checkItem({ value: element });
            });
          } else {
            jqxDropDownList.uncheckAll();
          }
          checkListUpdating = false;
        }
      });
    }
  },
  update: function (
    element,
    valueAccessor,
    allBindings,
    viewModel,
    bindingContext
  ) {},
};

export class OrderDetailsModal {
  isLoading: Observable<boolean> = ko.observable(true);
  orderDetailObject = ko.observableArray();
  sharedOrderDetailObject = ko.observableArray();
  loadDetailObject = ko.observableArray();
  showSharedDetail = ko.observable();
  datarecord: any;
  customDataFields: Observable<string[]> = ko.observable([]);
  isSharedOrder = ko.pureComputed(() => {
    return this.datarecord.sharedOrder && userProfile.isGwEmployee == false;
  });

  constructor(datarecord: any, customFields: Observable<string[]>) {
    this.datarecord = datarecord;
    this.customDataFields = customFields;
  }

  getDetailData = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (
        userProfile.isGwEmployee == false &&
        (this.datarecord.showDetailedGrid == false ||
          this.datarecord.sharedOrder)
      ) {
        this.showSharedDetail(true);
        var uri = "GetSharedOrderDetails/";
        dataModel
          .ajaxRequest("OrderPlanning/" + uri + this.datarecord.orderExId)
          .done((data, status, xhr) => {
            if (data.agencyId != userProfile.currentAgencyId()) {
              data.customerId = null;
            }
            if (data.email != null) {
              data.mailto =
                data.email +
                "?body=" +
                encodeURIComponent(
                  "I am interested in your posted load from " +
                    data.originCityStateZip +
                    " to " +
                    data.destCityStateZip +
                    " picking up " +
                    dayjs(data.pickUpDate).format(userProfile.dateTimeFormat)
                );
            } else {
              data.mailto = "";
            }
            this.sharedOrderDetailObject(data);
            resolve(undefined);
          })
          .fail((error, msg, d) => {
            $("#divDetails").html(JSON.stringify(error, null, 4));
            this.isLoading = ko.observable(false);
            reject();
          });
      } else {
        dataModel
          .ajaxRequest(
            "SearchOrders/GetOrderDetails/" + this.datarecord.orderExId,
            "GET"
          )
          .done((data, status, xhr) => {
            this.showSharedDetail(false);
            data.isOwnerOperator = userProfile.isOwnerOperator;
            this.orderDetailObject(data);
            resolve(undefined);
          })
          .fail((error, msg, d) => {
            $("#divDetails").html(JSON.stringify(error, null, 4));
            this.isLoading = ko.observable(false);
            reject();
          });
      }
    });
  };
}

export default { viewModel: SearchOrdersViewModel, template: template };
