
import './documentationPageStyles.css';
import dataModel from 'data-model';
import {createDateTimeFilterWidget} from 'global-functions';


// document model
class PageDoc {
    constructor(document) {
        var self = this;

        self.Id = document.id;  
        self.URL = document.url;  
        self.Title = document.title;  
        self.Description = document.description;
        self.DocType = document.typeName; 
        self.UserName = document.userName;
        self.UpdatedDate = new Date(document.updatedDate);
    }
}

class DocumentationPageViewModel {
    constructor() {
        var self = this;

        self.isAdmin = ko.observable(false);
        self.isLoading = ko.observable(false);
        self.errors = ko.observableArray([]);
    
        self.pages = ko.observableArray([]);
        self.addNewDocModal = ko.observable();
    
        // get doc types for filter checklist
        dataModel.ajaxRequest('Documentation/GetDocTypes')
            .done(function (data, status, xhr) {
                self.availableDocTypes = ko.observableArray(data);
            });
    
    
        var today = new Date();
    
        // each page has its own panel which can be expanded/contracted
        self.togglePagePanel = function (divPage, event) {
            var isToggled = $("#divPage" + divPage.id).is(":visible");
            var $this = $(event.currentTarget);
            if (isToggled) {
                $this.find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
                // grid.jqxGrid({ height: 800 });
            }
            else {
                $this.find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
                // grid.jqxGrid({ height: 400 });
            }
    
            $("#divPage" + divPage.id).animate({ height: 'toggle' }, "slow");
        };
    
        self.placeHolderFilterWidget = function (column, columnElement, widget) {
            widget.jqxInput({
                placeHolder: "Type Here..."
            });
        };
    
        // the normal updatebounddata is not sufficient as an add or delete could affect the presence of existing page grids, 
        // so we will force a page reload after a change
        self.refreshPageGrids = function () {
            self.loadAllDocuments();
        }
    
        // each menu page has a list of associated documents for the page
        var pageModel = function (page, mainModel) {
            var self = this;
            self.id = page.id;
            self.name = page.name;  
            self.pageDocs = ko.observable(ko.utils.arrayMap(page.documents, function (document) {
                return new PageDoc(document);
            }));
    
            self.documentColumns = [
                {
                    text: 'id', datafield: 'Id', hidden: true
                },
                {
                    text: 'url', datafield: 'URL', hidden: true
                },
                {
                    text: 'Title', datafield: 'Title', width: 300,
                    createfilterwidget: mainModel.placeHolderFilterWidget, filtercondition: "contains",
                    cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                        //on click of document name, open for viewing
                        if (defaultHTML != null) {
                            var cell = $(defaultHTML);
                            cell.html("<a href='" + rowData.URL + "' target=_blank style='color:blue; font-size:larger; margin-right:20px' >" + value + "</a>");
                           return cell[0].outerHTML;
                        }
                        return defaultHTML;
                    }
                },
                    { text: 'Description', datafield: 'Description', createfilterwidget: mainModel.placeHolderFilterWidget, filtercondition: "contains" }, //hideable: false 
                    {
                        text: 'Type', datafield: 'DocType', width: 100,
                        filtertype: 'checkedlist', filteritems: mainModel.availableDocTypes()   
                    }, 
                    { text: 'Added By', datafield: 'UserName', width: 100, hidden: !mainModel.isAdmin(), createfilterwidget: mainModel.placeHolderFilterWidget},
                    { text: 'Last Update', datafield: 'UpdatedDate', width:150, filtertype: 'date', cellsformat: "MM/dd/yyyy", createfilterwidget: createDateTimeFilterWidget, hidden: !mainModel.isAdmin() }, 
                   
                    {
                        text: '', datafield: 'Update', width: 20, hidden: !mainModel.isAdmin(), filterable: false,
                        cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) { return '<input type="image" data-bind="click: $parent.editDoc" id=' + rowData.Id + '" class="gridButton" src="Content/images/editSmall.png" style="border-style:none; margin-left:5px; margin-top:5px;"  />' }  
                    },
                    {
                        text: '', datafield: 'Delete', width: 20, hidden: !mainModel.isAdmin(), filterable: false,
                        cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) { return '<input type="image" data-bind="click: $parent.deleteDoc" id=' + rowData.Id + '" class="gridButton" src="Content/images/delete.gif" style="border-style:none; margin-left:5px; margin-top:5px;" />' }
                    }
    
            ];
    
        };
    
    
        self.loadAllDocuments = function () {
            self.errors.removeAll();
            self.isLoading(true);
    
            dataModel.ajaxRequest('Documentation/GetAllDocuments')
                    .done(function (data, status, xhr) {
                        self.isAdmin(data.isAdmin);
                        self.pages(ko.utils.arrayMap(data.pages, function (page) {
                            return new pageModel(page, self);
                        }));
                        self.isLoading(false);
                    })
                    .fail(function (error, msg, d) {
                        self.errors.push("Unable to load documents");
                        self.isLoading(false);
                    });
    
            };
    
        self.addNewDoc = function () {
            dataModel.ajaxRequest("Documentation/Add", "get")
                    .done(function (data, status, xhr) {
                        self.openAddNewDocModal(data, self);
                    })
                .fail(function (error, msg, d) {
    
                });
        };
    
        // model for modal to add new or update existing document
        self.openAddNewDocModal = function (docInfo, mainModel) {
                var self = this;
                self.errors = ko.observableArray();
                self.isLoading = ko.observable(false);
    
                self.docId = ko.observable(docInfo.id)
                self.existingFile = ko.observable(docInfo.url);
                self.uploadedFile = ko.observable(docInfo.url);
                self.fileName = ko.observable(docInfo.url);
                self.title = ko.observable(docInfo.title);
                self.description = ko.observable(docInfo.description);
                var docType = docInfo.docType > 0 ? docInfo.docType : docInfo.options.docTypeOptions[0];
    
                self.availableDocTypes = ko.observableArray(docInfo.options.docTypeOptions);
                self.availablePages = ko.observableArray(docInfo.options.pageOptions);
                self.availableRoles = ko.observableArray(docInfo.options.roleOptions);
    
                self.selectedDocType = ko.observable(docType);
                self.selectedPages = ko.observableArray(docInfo.selectedPages).extend({ minArrayLength: { params: { minLength: 1 }, message: "At least one page selection is required" } });
                self.selectedRoles = ko.observableArray(docInfo.selectedRoles).extend({ minArrayLength: { params: { minLength: 1 }, message: "At least one role selection is required" } });
    
    
                self.addOrUpdateDocument = function () {
    
                    self.errors.removeAll();
                    self.isLoading(true);
    
                    var files = $("#fileUpload").get(0).files;
                    var formData = new FormData();
                    var docId = self.docId();
                    if (files.length == 0) {
                        // user did not specify a file
                        if (self.docId() != null) {
                            // an existing document is being updated
                            updateDocInfo(self.existingFile())
                        }
                        else {
                            self.errors.push("No document file found to add.");
                        }
                    }
                    else {
                        // file selected by user - upload file and then add/update document data
                        var file = files[0];
                        var docTypeId = self.selectedDocType();
                        formData.append('UploadedFile', file);
                        formData.append('DocType', docTypeId);
    
                        dataModel.ajaxUploadRequest("Documentation/UploadFile", "POST", formData)
                               .done(function (uploadedFileName, status, xhr) {
                                   var docId = self.docId();
                                   if (self.docId() != null) 
                                   {
                                       // update existing doc data
                                       updateDocInfo(uploadedFileName);  
                                   }
                                   else 
                                   {
                                       // add new doc to db
                                       addDocInfo(uploadedFileName);   
                                   }
                               })
                               .fail(function (jqXHR, textStatus, errorThrown) {
                                   self.errors.push(textStatus);
                                   self.isLoading(false);
                               });
    
                    };
                    self.isLoading(false);
                };
    
                // create new document
                var addDocInfo = function (uploadedFileName) {
                    var jsData = ko.toJS(self);
                    var data = {
                        Id: null,
                        DocType: jsData.selectedDocType || 1,  // default to manual
                        URL: uploadedFileName,
                        Title: jsData.title,
                        Description: jsData.description,
                        SelectedPages: jsData.selectedPages,
                        SelectedRoles: jsData.selectedRoles
                    };
                    
                    dataModel.ajaxRequest("Documentation/Add", "POST", data)
                         .done(function (data, status, xhr) {
                             self.isLoading(false);
                             mainModel.addNewDocModal(undefined);
                             mainModel.refreshPageGrids();
                         })
                         .fail(function (error, msg, d) {
                             self.errors.push(msg);
                             self.isLoading(false);
                         });
                };
    
                // update existing document
                var updateDocInfo = function (uploadedFileName) {
                    var jsData = ko.toJS(self);
                    var data = {
                        Id:jsData.docId,
                        DocType: jsData.selectedDocType || 1,  // default to manual
                        URL: uploadedFileName,
                        Title: jsData.title,
                        Description: jsData.description,
                        SelectedPages: jsData.selectedPages,
                        SelectedRoles: jsData.selectedRoles
                    };
         
                    dataModel.ajaxRequest("Documentation/Update", "POST", data)
                         .done(function (data, status, xhr) {
                             self.isLoading(false);
                             mainModel.addNewDocModal(undefined);
                             mainModel.refreshPageGrids();
                         })
                         .fail(function (error, msg, d) {
                             self.errors.push(msg);
                             self.isLoading(false);
                         });
                };
    
                mainModel.addNewDocModal(self);
    
            };
    
        // user request to edit existing document; get existing data to display to user
        self.editDoc = function (data, event) {
            var documentId = parseInt(event.target.id, 10);            
            dataModel.ajaxRequest("Documentation/Update/" + documentId, "GET")
                                    .done(function (data, status, xhr) {
                                        self.openAddNewDocModal(data, self);
                                        self.isLoading(false);
                                    })
                                    .fail(function (error, msg, d) {
                                        self.errors.push(msg);
                                        self.isLoading(false);
                                    });
        };
    
        // user submitted edits for existing document
        self.updateDoc = function (data, event) {
            dataModel.ajaxRequest("Documentation/Update", "POST", data)
                        .done(function (data, status, xhr) {
                            self.isLoading(false);
                            self.addNewDocModal(undefined);
                            self.refreshPageGrids();
                        })
                        .fail(function (error, msg, d) {
                            self.errors.push(msg);
                            self.isLoading(false);
                        });
        };
    
            
        // have user confirm extent of deletion
        // deleting a document could mean deleting all instances on all pages, or only the document on the selected page
        self.confirmDelete = function (message) {
            var defer = $.Deferred();
            $("<div> Confirm Deletion of Document: <br /> " + message + "</div>").dialog({
                autoOpen: true,
                close: function () {
                    $(this).dialog("destroy");
                },
                title: "Confirm Deletion",
                buttons: {
                    "From This Page Only": function () {
                        defer.resolve("page");
                        $(this).dialog("close");
    
                    },
                    "From All Pages": function () {
                        defer.resolve("all");
                        $(this).dialog("close");
    
                    },
                    "Cancel": function () {
                        defer.resolve("no");
                        $(this).dialog("close");
                    }
                }
            });
            return defer.promise(); //important to return the deferred promise
        }
    
        // delete all references or only this one per user confirmation
        self.deleteDoc = function (data, event) {
            var documentId = parseInt(event.target.id, 10);
               
            var grid = data.pageDocs();
            var doc = $.grep(grid, function (d, i) { return d.Id === documentId; });
               
            self.confirmDelete(doc[0].Title )
                .done(function (answer) {
                    if (answer == "all") {  // delete this doc in all pages
                        dataModel.ajaxRequest("Documentation/Delete/" + documentId, "POST")
                                                .done(function (result) {
                                                    if (result == "") {
                                                        self.refreshPageGrids();
                                                    }
                                                    else {
                                                        self.errors.push(result);
                                                    }
                                                    self.isLoading(false);
                                                })
                                                .fail(function (error, msg, d) {
                                                    self.errors.push(msg);
                                                    self.isLoading(false);
                                                });
                    } 
                    if (answer == "page") {  // delete this doc only on this page
                        data = {
                            docId: documentId,
                            pageId: data.id
                        };
                        dataModel.ajaxRequest("Documentation/Delete", "POST", data)
                                                .done(function (result) {
                                                    if (result == "") {
                                                        self.refreshPageGrids();
                                                    }
                                                    else {
                                                        self.errors.push(result);
                                                    }
                                                    self.isLoading(false);
                                                })
                                                .fail(function (error, msg, d) {
                                                    self.errors.push(msg);
                                                    self.isLoading(false);
                                                });
                    };
                });
        };
    
        self.loadAllDocuments();
    }
}

import template from './documentation-page.html';
export default { viewModel: DocumentationPageViewModel, template: template }