﻿import ko from 'knockout';
import dataModel from 'data-model';
import template from './default-page-logged-in-component.html';
import * as _ from 'lodash';
import * as $ from 'jquery';
import userProfile from 'user-profile';
import L from 'mapquest'
import config from 'config';
import gridStateUtils from 'jqx.grid-utils'
import router from 'router';

class DefaultPageLoggedInComponent {
    constructor() {
        L.mapquest.key = config.mapquestApiKey;
        var self = this;
        var linkColor = "blue"; 
        
        self.logout = () => {
            userProfile.logOut();
        }

        //SELF.BUTTONDATA AND SELF.TRACTORDATA ARE USED TO STORE AND SET THE UPPER BUTTON VALUES       
        self.buttonData = ko.observableArray();
        self.tractordata = ko.observableArray();

        //USED TO BIND THE LOADING PANEL IN VIEW, TRUE WHEN PAGE IS BEING LOADED
        self.isLoading = ko.observable();

        //SELF.TRACTORBUTTONS AND SELF.ORDERBUTTONS VALUES ARE USED IN THE VIEW AS A KO VISIBLE BINDING PROPERTY
        self.tractorButtons = ko.observable(false);
        self.orderButtons = ko.observable(true);

        //USED TO DISABLE THE ORDERS AND LOADS CHECKBOXES WHILE THE PAGE IS BEING REFRESHED
        self.isOrderGridLoading = ko.observable(false);

        //OBSERVABLES USED AS A KO VISIBLE BINDING PROPERTY TO SHOW ADDITIONAL LEFT HEADINGS ON NETWORK TAB
        self.networkOrderHeadingVisible = ko.observable(false);
        self.networkTractorHeadingVisible = ko.observable(false);

        self.myNewsHeadingVisible = ko.observable(false);
        self.myMenuHeadingVisible = ko.observable(true);

        self.headerText = ko.observable("MENU");

        //OBSERVABLE USED TO DETERMINE IF THE USER IS ALREADY ON THE NETWORK PAGE, CLICKING THE NETWORK TAB AGAIN.  IF THEY ARE ALREADY ON THE NETWORK PAGE, THIS ESSENTIALLY DISABLES THE CLICK
        self.onNetworkPage = ko.observable(false);

        //HOLDS A USERS SAVED PREFERENCES FOR THE GRIDS - SEE LOADORDERDEFAULTFILTERS / LOADTRACTORDEFAULTFILTERS
        self.orderGridState = null;
        self.tractorGridState = null;

        //CHECKS WHETHER PAGE IS INITIAL LOAD (USED TO DISPLAY THE LEFT HEADING ON NETWORK TAB)
        self.isInitLoad = ko.observable(true);

        //ARRAYS TO HOLD THE COUNTS THAT ARE DISPLAYED IN THE UPPER BUTTONS
        self.orderCountData = ko.observableArray();
        self.tractorCountData = ko.observableArray();
        
        //FOR INCLUDE LOADS AND INCLUDE ORDERS - USED IN THE LOADCOMPLETE FUNCTION WHEN UPDATEBOUNDDATA IS CALLED FROM CLICKING ORDERS OR LOADS CHECKBOXES.  IF NOT THE FIRST TIME THE PAGE 
        //HAS BEEN LOADED, THE DATA THAT POPULATES THE UPPER BUTTONS WILL BE DELETED.  WITHOUT THIS THEY ARE DUPLICATED EVERY TIME A CHECK BOX IS CLICKED
        var _orderInitLoad = true;     

        //TOGGLE MENUS OBSERVABLES - DEFAULT PAGE STARTS OUT ON MENU TAB, SO IT IS INITIALLY SET TO TRUE, USED IN VIEW WITH ISVISIBLE BINDING
        self.isMenuVisible = ko.observable(true);
        self.isNetworkVisible = ko.observable(false);
        self.isNewsVisible = ko.observable(false);

        //INCLUDE ORDERS AND LOADS CHECK BOXES - SENT AS A DATA PARAM TO API - INITIALLY INCLUDE ORDERS IS CHECKED
        self.includeOrders = ko.observable(true);
        self.includeLoads = ko.observable(false);

        //WHEN INCLUDE LOADS IS CHECKED BY USER, SEND NEW VALUE TO REFRESHORDERGRID FUNCTION
        self.includeLoads.subscribe(function (newVal) {
            if (!_orderInitLoad) {
                self.refreshOrderGrid();
            }
        });

        //WHEN INCLUDE ORDERS IS CHECKED BY USER, SEND NEW VALUE TO REFRESHORDERGRID FUNCTION
        self.includeOrders.subscribe(function (newVal) {
            if (!_orderInitLoad) {
                self.refreshOrderGrid();
            }
        });

        //FIRST CLEAR FILTERS, OTHERWISE UPDATEBOUNDDATA WILL WORK AND RETURN DATA BUT USER WILL NOT SEE IT IF THEY HAVE PREVIOUSLY CLICKED AN UPPER BUTTON AND/OR A FILTER WIDGET IS POPULATED
        self.refreshOrderGrid = function () {
            $("#OrderGrid").jqxGrid('clearfilters');
            $("#OrderGrid").jqxGrid("updatebounddata", "data");
        };

        self.includePartnerOrders = ko.observable(false);

        self.toggleMyMenu = (event, args) => {
            this.isNetworkVisible(false);
            this.isMenuVisible(true);
            this.isNewsVisible(false);
            $(".navPillli").removeClass("active");
            $("#menuNavPillli").addClass("active");
            self.headerText("Menu")
        };

        self.networkTabLoaded = ko.observable(false);
        self.toggleMyNetwork = function (event, args) {
            this.isNetworkVisible(true);
            this.isMenuVisible(false);
            this.isNewsVisible(false);
            $(".navPillli").removeClass("active");
            $("#networkNavPillli").addClass("active");
            self.orderMap.panTo([35.64701, -95.39425]);
            self.tractorMap.panTo([35.64701, -95.39425]);
            self.mapHeading('Orders Map');
            self.headerText("Network - Orders")

            if(self.networkTabLoaded() == false) {
                self.networkTabLoaded(true);
                self.loadOrderDefaultFilters()
                .then(function() {
                    self.GetLoads();
                })
                self.loadTractorDefaultFilters()
                .then(function() {
                    self.GetTractors();
                })
            }
        };

        self.toggleMyNews = (event, args) => {
            self.ShowArchivedNews();
            this.isNetworkVisible(false);
            this.isMenuVisible(false);
            this.isNewsVisible(true); 
            $(".navPillli").removeClass("active");
            $("#newsNavPillli").addClass("active");
            self.headerText("News")
        };

        //TOGGLE BETWEEN THE ORDER GRID/MAP DATA/HEADING AND TRACTOR GRID/MAP DATA/HEADING - INCLUDES FUNCTION TO SHOW THE ORDER HEADING
        self.orderToggle = function () {
            var showHeading = "orders";
            self.tractorButtons(false);
            self.orderButtons(true);
            $("#OrderGrid").show();
            $("#map").show();
            $("#TractorGrid").hide();
            $("#map2").hide();
            self.headerText("Network - Orders")
        };

        //TOGGLE BETWEEN THE ORDER GRID/MAP DATA/HEADING AND TRACTOR GRID/MAP DATA/HEADING INCLUDES FUNCTION TO SHOW THE TRACTOR HEADING
        self.tractorToggle = function () {
            var showHeading = "tractors";
            self.tractorButtons(true);
            self.orderButtons(false);
            $("#OrderGrid").hide();
            $("#map").hide();
            $("#TractorGrid").show();
            $("#map2").show();
            self.headerText("Network - Tractors")
        };

        //FUNCTION CALLED ANY TIME ONE OF THE UPPER ORDER OR TRACTOR BUTTONS ARE CLICKED ON THE NETWORK TAB.  FIRST FINDS WHICH BUTTON HAS BEEN CLICKED, THEN GOES THROUGH
        //THE CORRESPONDING CONDITIONAL STATEMENT. 'AVAILABLE' AND 'NOT UNDER LOAD' UTILIZE MULTIPLE FILTERS
        self.ButtonClick = function (event, valueAccessor) {    
            var addFilters;
            var filterName = event.text;
            if (filterName == 'On Time' || filterName == 'Late') {
                let grid = $("#OrderGrid");               
                addFilters = function () {          
                    var filter_or_operator = 1;
                    var filtercondition = 'contains';
                    if (filterName != "") {
                        var filtergroup5 = new $.jqx.filter();
                        var filter5 = filtergroup5.createfilter('stringfilter', filterName, filtercondition);
                        filtergroup5.addfilter(filter_or_operator, filter5);
                        grid.jqxGrid('clearfilters');
                        grid.jqxGrid('addfilter', 'status', filtergroup5)
                    }
                    $("#OrderGrid").jqxGrid('applyfilters');
                }
            }

            if (filterName == 'In Progress') {
                let grid = $("#OrderGrid");
                addFilters = function () {
                    var filter_or_operator = 1;
                    var filtercondition = 'contains';
                    if (filterName != "") {
                        var filtergroup15 = new $.jqx.filter();
                        var filter15 = filtergroup15.createfilter('stringfilter', filterName, filtercondition);
                        filtergroup15.addfilter(filter_or_operator, filter15);
                        grid.jqxGrid('clearfilters');
                        grid.jqxGrid('addfilter', 'originalStatus', filtergroup15)
                    }
                    $("#OrderGrid").jqxGrid('applyfilters');
                }
            }

            //UTILIZES TWO FILTERS - BOTH ARE CONTAINS - IF YOU TRY TO USE 'DOES_NOT_CONTAIN' 'IN PROGRESS', THE WIDGET WILL STILL REDER 'IN PROGRESS' WHILE FILTERING CORRECTLY WHICH IS 
            //CONFUSING.  WHEN USING TWO 'CONTAINS' THE FIRST IS RENDERED IN TEH FILTER WIDGET, IN THIS CASE IT WILL SAY 'AVAILABLE'
            if (filterName == 'Available') {
                let grid = $("#OrderGrid");
                addFilters = function () {
                    var filtergroup25 = new $.jqx.filter();                    
                    var filter_or_operator = 1;
                    var filterValue = 'Available';
                    var filtercondition = 'contains';
                    var filter25 = filtergroup25.createfilter('stringfilter', filterValue, filtercondition);
                    filterValue = 'Covered';
                    filtercondition = 'contains';
                    var filter26 = filtergroup25.createfilter('stringfilter', filterValue, filtercondition)

                    filtergroup25.addfilter(filter_or_operator, filter25);
                    filtergroup25.addfilter(filter_or_operator, filter26);

                    grid.jqxGrid('clearfilters');
                    grid.jqxGrid('addfilter', 'originalStatus', filtergroup25)
            
                    $("#OrderGrid").jqxGrid('applyfilters');
                }
            }

            if (filterName == 'Loads') {
                let grid = $("#OrderGrid");
                addFilters = function () {               
                    var filter_or_operator = 1;
                    var filtercondition = 'equal';
                    if (filterName != "") {   
                        var filterValue = 'true';
                        var filtergroup55 = new $.jqx.filter();
                        var filter55 = filtergroup55.createfilter('booleanfilter', filterValue, filtercondition);
                        filtergroup55.addfilter(filter_or_operator, filter55);
                        grid.jqxGrid('clearfilters');
                        grid.jqxGrid('addfilter', 'isLoad', filtergroup55)
                    }
                    $("#OrderGrid").jqxGrid('applyfilters');
                }
            }

            //HERE REMOVE FILTERS SINCE USER IS CLICKING BUTTON TO SHOW ALL TRACTORS
            if (filterName == 'Tractors') {              
                let grid = $("#TractorGrid");
                grid.jqxGrid('clearfilters');
                return;
            }

            if (filterName == 'Under Load') {
                filterName = 'In Progress';
                let grid = $("#TractorGrid");
                addFilters = function () {
                    var filter_or_operator = 1;
                    var filtercondition = 'contains';
                    if (filterName != "") {
                        var filtergroup3 = new $.jqx.filter();
                        var filter3 = filtergroup3.createfilter('stringfilter', filterName, filtercondition);
                        filtergroup3.addfilter(filter_or_operator, filter3);
                        grid.jqxGrid('clearfilters');
                        grid.jqxGrid('addfilter', 'movementStatus', filtergroup3)
                    }
                    $("#TractorGrid").jqxGrid('applyfilters');
                }
            }

            else if (filterName == 'Not Under Load') {
                let grid = $("#TractorGrid");
                addFilters = function () {
                    var filtergroup21 = new $.jqx.filter();
                    var filter_or_operator = 1;
                    var filterValue = 'Available';
                    var filtercondition = 'contains';
                    var filter21 = filtergroup21.createfilter('stringfilter', filterValue, filtercondition);

                    filterValue = 'Delivered';
                    filtercondition = 'contains';
                    var filter22 = filtergroup21.createfilter('stringfilter', filterValue, filtercondition)

                    filtergroup21.addfilter(filter_or_operator, filter21);
                    filtergroup21.addfilter(filter_or_operator, filter22);

                    grid.jqxGrid('clearfilters');
                    grid.jqxGrid('addfilter', 'movementStatus', filtergroup21)

                    $("#TractorGrid").jqxGrid('applyfilters');
                }
            }

            addFilters();

        };

        //NEWS TAB - SHOW NEWS
        self.ShowArchivedNews = function () {
            var route = "NewsPost/GetArchivedNews";
            var data = null;
            dataModel.ajaxRequest(route, "GET", data)
                .done(function (data, status, xhr) {
                    $.each(data, function (key, value) {
                        var div = document.getElementById('newsDiv');
                        div.innerHTML = div.innerHTML + "<div><h2 class='titles'>" + value.subject + "</h2>" +
                            value.messageBody + "<br />" + "</div>" +
                            "<i> Posted by " + value.poster + " on " + value.postDate + "</i><hr /><br /><br />"
                    });
                }).fail(function (error, msg, d) {
                        
                }).then(function () {
                    if (self.isLoading())
                        setTimeout(function () {
                            self.isLoading(false);
                        }, 300);
                });
        };        

        self.loadOrderDefaultFilters = function () {
            return new Promise(function(resolve, reject) {
                dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", { name: "OrderGrid", pageName: window.location.pathname })
                .done(function (data) {
                    if (_.isEmpty(data) == false) {
                        var state = JSON.parse(data);
                        self.includeOrders(state.includeOrders || false);
                        self.includeLoads(state.includeLoads || false);               
                        self.orderGridState = state.grid;
                    }
                    resolve();
                })
                .fail(function () {
                    self.isLoading(false)
                    reject();
                });
            })
        };

        self.loadTractorDefaultFilters = function () {
            return new Promise(function(resolve, reject) {
                dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", { name: "TractorGrid", pageName: window.location.pathname })
                .done(function (data) {
                    if (_.isEmpty(data) == false) {
                        var state = JSON.parse(data);                  
                        self.tractorGridState = state.grid;
                    }
                    resolve();
                })
                .fail(function () {
                    self.isLoading(false)
                });
            })
        };
    
        self.ordersMarkersLayer = new L.LayerGroup();
        self.tractorsMarkersLayer = new L.LayerGroup();

        //CREATE THE MAP TO FILL THE EMPTY MAP DIV IN THE VIEW, SET THE CENTER AND INITIAL ZOOM
        var mapOptions = {
            layers: L.mapquest.tileLayer('map'),
            zoom: 5,                     /*initial zoom level of map*/
            minZoom: 3,
            center: [35.64701, -95.39425],       /*initial center of map in latitude,longitude*/
            trackResize: true,
            fitBounds: true
        };

        self.orderMap = L.map('map', mapOptions);

        self.tractorMap = L.map('map2', mapOptions);

        //ONE OF MANY TILELAYERS AVAILABLE - CREATES THE OVERALL MAP STYLE
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(self.orderMap);

        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(self.tractorMap);
            
        self.orderLegend = L.control({ position: 'bottomleft' });        

        self.orderLegend.onAdd = function (map) {
            var div = L.DomUtil.create('div', 'info legend'),
                    grades = [0, 1],
                    labels = [];

            div.innerHTML +=
                '<img src="https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png"></img>    ' +
                'Order Locations<br />';

            return div;
        };

        self.orderLegend.addTo(self.orderMap);

        self.refreshMap = function () {
            self.orderMap.setView([35.64701, -95.39425], 5)
            self.tractorMap.setView([35.64701, -95.39425], 5)

        };

        self.mapHeading = ko.observable();

        self.tractorLegend = L.control({ position: 'bottomleft' });

        self.tractorLegend.onAdd = function (map) {
            var div = L.DomUtil.create('div', 'info legend'),
                    grades = [0, 1],
                    labels = [];

            div.innerHTML +=
                '<img src="https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png"></img>    ' +
                'Tractor Locations<br />';

            return div;
        };

        self.tractorLegend.addTo(self.tractorMap);
        
        //BUILD AN ICON USED TO SHOW LOCATIONS    
        self.greenIcon = new L.Icon({
            //FOR ICONURL, 'GREEN' CAN BE CHANGED TO 'RED', 'BLUE', 'ORANGE', 'YELLOW', 'VIOLET', 'GREY', 'BLACK' AND ICON CAN BE JUST ICON OR ICON-2X
            iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });
    
        //RECEIVES THE GEOJSON DATA STORED IN SELF.INSPECTIONLOCATIONSFORMAP.  ITERATES THROUGH EACH OBJECT THROUGH 'POINTTOLAYER', AND CREATES A ICON ON THE MAP (L.MARKER) BASED ON THE LAT/LNG
        self.orderMapLayer = L.geoJson(null, {
            
            pointToLayer: function (feature, latlng) {         
                //HERE YOU CAN CREATE OBJECT PROPERTIES TO DISPLAY INSIDE ICON IF CREATING A CUSTOM DIVICON (SEE LOADANDTRACTORMAP.JS)                
            
                return L.marker(latlng, { icon: self.greenIcon, riseOnHover: true, });
        
            },
            //PERFORM THIS FUNCTION TO EACH DATA ITEM THAT IS ITERATED OVER
            onEachFeature: onEachFeatureforOrders
        });

        //FUNCTION TO CREATE POPUP CONTENT FOR EACH MARKER/ICON
        function onEachFeatureforOrders(feature, layer) {    
            var popupContent = "<p><div style='text-align:left'><span>Order Number:  </span><a href=\"Orders/" + feature.properties.OrderId +  "\" target=\"_blank\"  style=\"color:" + linkColor + ";\"   >" + feature.properties.OrderId + "</a><br />" + "<span>From:  </span>" + feature.properties.Origin + "<br />" + "<span>To:  </span>" + feature.properties.Destination + "</div></p>"

            if (feature.properties && feature.properties.popupContent) {
                popupContent += feature.properties.popupContent;
            }

            //BIND THE POPUP CONTENT TO THE CURRENT ICON/MARKER, AND ADD THE MARKER TO GROUP OF MARKERS/ICONS
            layer.bindPopup(popupContent);    
            self.ordersMarkersLayer.addLayer(layer);        
        }
        //AFTER CREATING ALL OF THE POPUP DATA, ADD THE GROUP OF MARKERS/ICONS TO THE MAP 
        self.ordersMarkersLayer.addTo(self.orderMap); 

        self.tractorMapLayer = L.geoJson(null, {

            pointToLayer: function (feature, latlng) {              
                return L.marker(latlng, { icon: self.greenIcon, riseOnHover: true, });
            },
            onEachFeature: onEachFeatureforTractors
        });

        function onEachFeatureforTractors(feature, layer) {         

            var popupContent = "<p><div style='text-align:left'><span>Tractor: </span><a href=\"Tractors?tractorId=" + feature.properties.Tractor + "\" target=\"_blank\"  style=\"color:" + linkColor + ";\"   >" + feature.properties.Tractor + "</a><br /><span>Location: </span>" + feature.properties.TractorLocation + "</div></p>"

            if (feature.properties && feature.properties.popupContent) {
                popupContent += feature.properties.popupContent;
            }

            layer.bindPopup(popupContent);     
            self.tractorsMarkersLayer.addLayer(layer);   
        }
        self.tractorsMarkersLayer.addTo(self.tractorMap);

        self.orderLocationsForMap = [{}]
        self.addLoadsToMap = function() {
            self.ordersMarkersLayer.clearLayers();
            self.orderLocationsForMap = [{}];
            var visibleOrders = self.OrdersGrid.jqxGrid("getvisiblerows")
            var mapObjects = visibleOrders.map(function(value) {
                return {
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "properties": {
                            "OrderId": value.orderExId,
                            "Origin": value.origin,
                            "Destination": value.destination,
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [value.longitude, value.latitude]
                        }

                    }]
                }
            })
            self.orderMapLayer.addData(mapObjects);
        }

        self.OrdersGrid = $("#OrderGrid");
        self.GetLoads = function (data) {      

            self.OrdersGrid.on("bindingcomplete", function (event) {   
                self.addLoadsToMap(); 
            });

            self.OrdersGrid.on("filter", function (event) {
                self.addLoadsToMap();
            });
                
            self.OrdersGrid.on("pagechanged", function (event) {
                self.addLoadsToMap();
            });

            self.OrdersGrid.on("sort", function (event) {
                self.addLoadsToMap();
            });

            var source = {
                url: "Network/GetOrders",
                datatype: "json",
                formatdata: function (data) {
                    self.isOrderGridLoading(true);
                    var filterinfo = self.OrdersGrid.jqxGrid('getfilterinformation');
                    data = gridStateUtils.formatGridFilters(filterinfo, data);
                    data.includeOrders = self.includeOrders();
                    data.includeLoads = self.includeLoads();
                    data.includePartner = self.includePartnerOrders();

                    return data;
                },
                datafields: [
                    { name: "id", type: "int" },
                    { name: "orderExId", type: "string" },
                    { name: "originalStatus", type: "string"},
                    { name: "status", type: "string" },
                    { name: "isLoad", type: "bool"},
                    { name: "destination", type: "string" },
                    { name: "origin", type: "string" },
                    { name: "miles", type: "float" },
                    { name: "totalCharge", type: "float" },
                    { name: "agencyExId", type: "string" },
                    { name: "latitude", type: "float" },
                    { name: "longitude", type: "float" },
                    { name: "state", type: "string" },
                    { name: "city", type: "string" },
                    { name: "type", type: "string" },
                ],
                loadComplete: function (data) {
                    self.isLoading(false);
                    if (!_orderInitLoad) {
                        self.buttonData([]);
                    }
                    if (data == []) {
                        data.statusCounts = {
                            availableOrders: 0,
                            inProgressOrders: 0,
                            onTimeOrders: 0,
                            lateOrders: 0
                        }
                    }   
                    
                    $.each(data.statusCounts, function (key, value) { 
                        if(self.includeOrders()) {
                            if (key == 'availableOrders') {
                                key = 'Available';
                                self.buttonData.push({ text: key, value: value })
                            }
                            else if (key == 'inProgressOrders') {
                                key = 'In Progress';
                                self.buttonData.push({ text: key, value: value })
                            }
                            else if (key == 'lateOrders') {
                                key = 'Late';
                                self.buttonData.push({ text: key, value: value })
                            }                       
                            else if (key == 'onTimeOrders') {
                                key = 'On Time';
                                self.buttonData.push({ text: key, value: value })
                            }
                        }
                        if(self.includeLoads() && key == 'loads') {
                            key = 'Loads';
                            self.buttonData.push({ text: key, value: value })
                        }

                    });
                    self.orderCountData(data.statusCounts);                    
                    self.isOrderGridLoading(false);
                },
                

                loadError: function (error) {
                    self.isLoading(false);
                    self.isOrderGridLoading(false);
                }
            };

            var dataAdapter = dataModel.getDataAdapter(source);
            var centerrenderer = function (value) { return '<div style="text-align: center;margin-top: 12px;">' + value + '</div>'; }

            self.OrdersGrid.jqxGrid({
                theme: 'GWTMDark',
                width: '100%',          
                source: dataAdapter,
                altrows: true,
                autoheight: true,
                showstatusbar: true,
                sortable: true,
                pageable: true,     
                pagesize: 5,
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                columnsheight: 40,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                statusbarheight: 35,
                showtoolbar: true,
                toolbarheight: 35,
                rendertoolbar: function (toolbar) {
                    ko.components.get('jqx.grid-state-component', (gridState) => {
                        let vm2 = gridState.createViewModel("Default");
                        vm2.setDefaultSearch = function () {
                            let filters = {
                                includeOrders: self.includeOrders(),
                                includeLoads: self.includeLoads(), 
                            };
                            return filters;
                        };
                        $(toolbar).append(gridState.template);
                        ko.applyBindingsToDescendants(vm2, toolbar[0]);
                        //In order to create another instance of this component when the user
                        //clicks View Tractors, we need to clear the cached defination or 
                        //knockout will throw an error.  
                        ko.components.clearCachedDefinition('jqx.grid-state-component')
                    })
                },
                rendergridrows: function (obj) {
                    return obj.data;
                },
                columns: [
                    {
                        text: 'Order',
                        datafield: 'orderExId',
                        width: '140px',
                        cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                            if (defaultHTML != null) {
                                linkColor = "blue";
                                var cell = $(defaultHTML);
                                if (value != "") {
                                    cell.html("<a href=\"Orders/" + value + "\" target=\"_blank\"  style=\"color:" + linkColor + ";\"   >" + value + "</a> ");
                                }
                                return cell[0].outerHTML;
                            }
                            return defaultHTML;
                        }
                    },
                    { text: 'Order Status', datafield: 'originalStatus', width: '175px'},
                    { text: 'On Time', datafield: 'status', width: '87px' },
                    { text: 'isLoad', datafield: 'isLoad', hidden: true, filtertype: 'bool', columntype: 'checkbox', width: "50px"},
                    { text: 'Latitude', datafield: 'latitude', hidden: true, width: "100px" },
                    { text: 'Longitude', datafield: 'longitude', hidden: true, width: "100px" },
                    { text: 'From', datafield: 'origin', width: '215px', renderer: centerrenderer },
                    { text: 'To', datafield: 'destination', width: '215px', renderer: centerrenderer },
                    { text: 'Distance (miles)', datafield: 'miles', cellsalign: "center", width: '110px', renderer: centerrenderer },
                    { text: 'Total', datafield: 'totalCharge', cellsalign: "center", cellsformat: 'c2', width: '100px', renderer: centerrenderer },
                    { text: 'Agency', datafield: 'agencyExId', width: '100px', cellsalign: "center", renderer: centerrenderer },
                    {
                        text: '',
                        width: "100px", 
                        filterable: false,
                        sortable: false,
                        datafield: 'addToMapButton',
                        columnType: 'button',
                        buttonclick: function (row) {
                            var datarow = self.OrdersGrid.jqxGrid('getrowdata', row);
                            if (datarow.latitude != null) { 
                                self.orderMap.panTo([datarow.latitude, datarow.longitude]); 
                                return false;
                            }
                            else {
                                alert("Latitude / Longitude not on file")
                            } 
                        },
                        cellsrenderer: function () {
                            return "View on Map";
                        }
                    }      
                ],
                
                ready: function () {             
                    self.loadOrderGridState(self.OrdersGrid, self.orderGridState);
                    _orderInitLoad = false;
                }
            });

            self.loadOrderGridState = function (grid, state) {
                if (state != null) {
                    var gridState = self.OrdersGrid.jqxGrid('getstate');
                    state.selectedrowindex = -1;
                    state.selectedrowindexes = [];
                    state.pagenum = 0;
                    state.height = gridState.height;
                    state.pagesize = gridState.pagesize;
                    state.pagesizeoptions = gridState.pagesizeoptions;
                    self.OrdersGrid.jqxGrid('loadstate', state);
                }
                else {
                    self.OrdersGrid.jqxGrid('loadstate');
                }

            };
        };

        self.addTractorsToMap = function() {
            self.tractorsMarkersLayer.clearLayers();
            self.tractorLocationsForMap = [{}];
            var visibleTractors = self.TractorGrid.jqxGrid("getvisiblerows")
            var mapObjects = visibleTractors.map(function(value) {
                return {                   
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "properties": {
                            "Tractor": value.tractorExId,
                            "TractorLocation": value.tractorLocation,
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [
                                value.tractorLongitude, 
                                value.tractorLatitude
                            ]
                        }

                    }, ]
                }  
            })
            self.tractorMapLayer.addData(mapObjects);
        }

        self.TractorGrid = $("#TractorGrid");
        self.GetTractors = function (data) {

            self.TractorGrid.on("bindingcomplete", function (event) {      
                self.addTractorsToMap();
            });

            self.TractorGrid.on("filter", function (event) {
                self.addTractorsToMap();
            });

            self.TractorGrid.on("pagechanged", function (event) {    
                self.addTractorsToMap();
            });

            self.TractorGrid.on("sort", function (event) {
                self.addTractorsToMap();
            });

            var source = {
                url: "Network/GetTractors",
                datatype: "json",
                formatdata: function (data) {      
                    var filterinfo = self.TractorGrid.jqxGrid('getfilterinformation');
                    data = gridStateUtils.formatGridFilters(filterinfo, data);
                    return data;
                },

                datafields: [
                    { name: "orderCSV", type: "string" },
                    { name: "movementStatus", type: "string"},
                    { name: "tractorExId", type: "string" },
                    { name: "toLoc", type: "string" },
                    { name: "tractorLocation", type: "string"},
                    { name: "fromLoc", type: "string" },
                    { name: "distance", type: "float" },
                    { name: "totalCharge", type: "float" },
                    { name: "agencyExId", type: "string" },
                    { name: "tractorLatitude", type: "float" },
                    { name: "tractorLongitude", type: "float" },
                    { name: "state", type: "string" },
                    { name: "city", type: "string" },
                    { name: "type", type: "string" },
                ],

                loadComplete: function (tractordata) {
                    self.isLoading(false);
                    $.each(tractordata.tractorCounts, function (key, value) {
                        if (key == 'tractorCount') {
                            key = 'Tractors';
                            self.tractordata.push(
                                {
                                    text: key,
                                    value: value,
                                }
                            )
                        }
                        else if (key == 'underLoadTractorCount') {
                            key = 'Under Load';
                            self.tractordata.push({ text: key, value: value })
                        }
                        else if (key == 'notUnderLoadTractorCount') {
                            key = 'Not Under Load';
                            self.tractordata.push({ text: key, value: value })
                        }
                    });

                    self.tractorCountData(tractordata.tractorCounts);         
    
                },

                loadError: function (error) {
                    self.isLoading(false);
        
                }
            };

            var dataAdapter = dataModel.getDataAdapter(source);   
            var centerrenderer = function (value) { return '<div style="text-align: center;margin-top: 12px;">' + value + '</div>'; }

            self.TractorGrid.jqxGrid({
                theme: 'GWTMDark',
                width: '100%',
                source: dataAdapter,
                altrows: true,
                autoheight: true,
                showstatusbar: true,
                sortable: true,
                pageable: true,
                pagesize: 5,
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                columnsheight: 40,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                statusbarheight: 35,
                showtoolbar: true,
                toolbarheight: 35,
                renderstatusbar: function (statusbar) {

                },
                rendertoolbar: function (toolbar) {
                    ko.components.get('jqx.grid-state-component', (gridState) => {
                        let vm1 = gridState.createViewModel("Default");
                        vm1.setDefaultSearch = function () {
                            let filters = {
                                includeOrders: self.includeOrders(),
                                includeLoads: self.includeLoads(), 
                            };
                            return filters;
                        };
                        $(toolbar).append(gridState.template);
                        ko.applyBindingsToDescendants(vm1, toolbar[0]);
                    })
                },

                rendergridrows: function (obj) {
                    return obj.data;
                },

                columns: [
                    { text: 'Tractor', datafield: 'tractorExId', width: "100px"},             
                    {
                        text: 'Order',
                        datafield: 'orderCSV',
                        width: "250px",
                        cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                            if(rowData.orderCSV != null && rowData.orderCSV.includes(',')) {
                                var orders = rowData.orderCSV.split(',');
                                var cell = $(defaultHTML);
                                var div = $("<div>");
                                orders.forEach(function(element, index, array) {
                                    if (defaultHTML != null) {
                                        linkColor = "blue";
                                        if (element != "") {
                                            if (index == array.length - 1)  {
                                                div.append("<a href='/Orders/" + element + "' target='_blank'  style='color:" + linkColor + ";'>" + element + "</a> ");
                                            } else {
                                                div.append("<a href='/Orders/" + element + "' target='_blank'  style='color:" + linkColor + ";'>" + element + "</a>, ");
                                            }
                                        }
                                    }
                                });
                                cell.html(div.html());
                                
                                return cell[0].outerHTML;
                            } else {
                                if (defaultHTML != null) {
                                    linkColor = "blue";
                                    let cell = $(defaultHTML);
                                    if (value != "") {
                                        cell.html("<a href=\"Orders/" + value + "\" target=\"_blank\"  style=\"color:" + linkColor + ";\"   >" + value + "</a> ");
                                    }
                                    return cell[0].outerHTML;
                                }
                                return defaultHTML;
                            }
                        }
                    },                   
                    {
                        text: 'Status', datafield: 'movementStatus', width: "135px", filtercondition: "OR",
                        filtertype: "checkedlist",
                        filteritems: [{ html: "In Progress", label: "In Progress" }, { html: "Delivered", label: "Delivered" }, { html: "Available", label: "Available" }]
                    },
                    { text: 'Latitude', datafield: 'tractorLatitude', hidden: true, width: "100px" },
                    { text: 'Longitude', datafield: 'tractorLongitude', hidden: true, width: "100px" },
                    { text: 'Current Tractor Location', datafield: 'tractorLocation', cellsalign: "center", renderer: centerrenderer, width: "180px" }, 
                    { text: 'Distance (miles)', datafield: 'distance', renderer: centerrenderer, width: "120px"},
                    { text: 'Total Revenue', datafield: 'totalCharge', renderer: centerrenderer, width: "139px"},
                    { text: 'Agency', datafield: 'agencyExId', cellsalign: "center", renderer: centerrenderer, width: "100px" },
                    {
                        text: '',
                        width: "100px",
                        filterable: false,
                        sortable: false,
                        datafield: 'addToMapButton',
                        columnType: 'button',
                        buttonclick: function (row) {             
                            var datarow = self.TractorGrid.jqxGrid('getrowdata', row);
                            if ((datarow.tractorLatitude && datarow.tractorLongitude) && datarow.tractorLatitude != 0) {
                                self.tractorMap.panTo([datarow.tractorLatitude, datarow.tractorLongitude]); 
                                return false;
                            }
                            else {
                                alert("Latitude / Longitude not on file")
                            }  
                        },
                        cellsrenderer: function () {
                            return "View on Map";
                        }
                    },             
                ],

                ready: function () {
                    self.loadTractorGridState(self.TractorGrid, self.tractorGridState);                  
                }

            });

            self.loadTractorGridState = function (grid, state) {
                if (state != null) {
                    var gridState = grid.jqxGrid('getstate');
                    state.selectedrowindex = -1;
                    state.selectedrowindexes = [];
                    state.pagenum = 0;
                    state.height = gridState.height;
                    state.pagesize = gridState.pagesize;
                    state.pagesizeoptions = gridState.pagesizeoptions;
                    grid.jqxGrid('loadstate', state);
                }
                else {
                    grid.jqxGrid('loadstate');
                }

            };
        };

        self.numberOfIcons = ko.observable(12);
        self.additionalLinks = ko.observableArray();
        self.userIcons = ko.observableArray();

        self.buildMenuObjects = (data) => {
            
            $.each(data, function (key, value) {   
                if (key < self.numberOfIcons()) {
                    self.userIcons.push({
                        name: value.page.name,
                        url: value.page.url,
                        icon: 'Content/' + value.page.iconPath,
                        activeIcon: 'Content/' + value.page.activeIconPath,
                        id: value.id + 'a',
                        idActive: value.id + 'b',
                        target: value.page.opensInNewWindow ? '_blank' : ''
                    });
                }
                else {
                    self.additionalLinks.push({ name: data[key].page.name, link: data[key].page.url, target: value.page.opensInNewWindow ? '_blank' : '' })
                }
            });
        }
        if(userProfile.currentUserMenus()) {
            self.buildMenuObjects(userProfile.currentUserMenus())
        } else {
            userProfile.currentUserMenus.subscribe((menus) => {
                self.buildMenuObjects(userProfile.currentUserMenus())
            })
        }
    }
}

export default { viewModel: DefaultPageLoggedInComponent, template: template };