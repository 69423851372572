//@ts-check
/// <reference path="../../../types/jquery.plugins.d.ts" />

import { OrderPlanningViewModel } from './order-planning-page'

class LoadboardViewModel extends OrderPlanningViewModel {
    isLoadboard = true;
    constructor(params) {
        super(params);

    }
}

import template from './loadboard-page.html';
export default { viewModel: LoadboardViewModel, template: template }