import { datetimeUTC } from "global-functions";
const isDEV = () => window.location.host.indexOf('localhost') !== -1;
const isUAT = () => window.location.host.indexOf('gwtl-gedev') !== -1;
const isPreview = () => window.location.host.indexOf('preview.great-edge') !== -1;
const isTest = () => window.location.host.indexOf('greatedgetest.great-edge') !== -1;
const isPROD = () => window.location.host.indexOf('great-edge') !== -1 && !isPreview() && !isTest();

const baseURL = (isDEV() && "http://localhost:61984/api/GreatEdge/") || (isUAT() && "https://gwtl-gedev.atf.local:61984/api/GreatEdge/")
    || (isPROD() && "https://greatwideapi.great-edge.net/api/GreatEdge/")
    || (isPreview() && "https://apipreview.great-edge.net/api/GreatEdge/")
    || (isTest() && "https://greatedgetest.great-edge.net/api/GreatEdge/")

const version = "v1";

const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}



//q.DeliveryDate.Value.Date == DateTime.Today ? "Delivers Today" :
//    q.DeliveryDate.Value.Date == DateTime.Today.AddDays(1) ? "Delivers Next Day" :
//    q.DeliveryDate.Value.Date > DateTime.Today ? "In Transit" :
//    "Running Late"
export function GetTransitStatus(deliveryDate, compareDate) {
    
    let ddate = datetimeUTC(deliveryDate);
    let cdate = datetimeUTC(compareDate);

    if(ddate.isValid() === false || cdate.isValid() === false) return `N/A`;

    // Reset the timing portion.
    ddate = ddate.hour(0);
    ddate = ddate.minute(0);
    ddate = ddate.second(0);
    ddate = ddate.millisecond(0);

    cdate = cdate.hour(0);
    cdate = cdate.minute(0);
    cdate = cdate.second(0);
    cdate = cdate.millisecond(0);

    if(ddate.unix() === cdate.unix()) {
        return `Delivers Today`;
    }
    else if(ddate.unix() === cdate.add(1, 'day').unix()) {
        return `Delivers Next Day`;
    }
    else if(ddate.unix() > cdate.unix()) {
        return `In Transit`;
    }
    else {
        return `Running Late`;
    }
}


// Helper Service for 'ge' TNT api calls
export function TNTHttp({
    baseAPI = baseURL,
    //accessToken = getCookie("GreatwideAPItoken"),
} = {}) {
 
    const executeAsync = ({url = "", type = "GET", payload, options = {}}) => {
        return new Promise((resolve, reject) => {
            $.support.cors = true;  // ie9,ie8 fix
            let data = payload;

            if(type === "GET" && (payload && Object.keys(payload).length)) {
                const urlParams = $.param(data);
                if (urlParams != "") {
                    url = url + "?" + urlParams;
                }

                data = {};
            }
            else {
                data = data ? ko.toJSON(data) : null;
            }

            // if(!accessToken) {
            //     accessToken = getCookie("GreatwideAPItoken");
            // }

            const defaults = {
                url: baseAPI + url,
                headers: { Authorization: `Bearer ${ getCookie("GreatwideAPItoken") }`},
                type: type,
                data,
                xhrFields: {
                    withCredentials: true
                },
                contentType: "application/json",
                ...options
            };
    
            $.ajax(defaults).done(resolve).catch(reject);
        })
    }
    
    return {
        fetchAsync: (url, payload) => executeAsync({url, payload}),
        postAsync: (url, payload) => executeAsync({url, type: "POST", payload}),
        deleteAsync: (url, payload) => executeAsync({url, type: "DELETE", payload}),
        putAsync: (url, payload) => executeAsync({url, type: "PUT", payload}),
    }

}

export function Queries({
    http = TNTHttp({baseAPI: baseURL})
} = {}) {

    const fetchCarrierTrackingByMoveIdAsync = async (moveId) => {
         try {
            const response = await http.fetchAsync(`${version}/Tracking/ByMovementId`, {movementId: moveId});
            return response;
        }
        catch(err) {
            return err;
        }
    }

    const fetchCarrierTrackingByIdAsync = async (carrierTrackingId) => {
        try {
            const response = await http.fetchAsync(`${version}/Tracking/${carrierTrackingId}`);
            return response;
        }
        catch(err) {
            return err;
        }
    }

    const postCreateTrackingAsync = async (payload) => {
        try {
            const response = await http.postAsync(`${version}/Tracking`, payload);
            return response;
        }
        catch(err) {
            return err;
        }
    }

    const postStartTrackingAsync = async (payload) => {
        try {
            const response = await http.postAsync(`${version}/Tracking/Start`, payload);
            return response;
        }
        catch(err) {
            return err;
        }
    }

    const postUpdateTrackingAsync = async (payload) => {
        try {
            const response = await http.postAsync(`${version}/Tracking/Sync`, payload);
            return response;
        }
        catch(err) {
            return err;
        }
    }

    const postSyncTrackingAsync = async (payload) => {
        try {
            const response = await http.postAsync(`${version}/Tracking/Sync`, payload);
            return response;
        }
        catch(err) {
            return err;
        }
    }

    const cancelTrackingAsync = async (providerTrackingId) => {
        try {
            const response = await http.deleteAsync(`${version}/Tracking/Cancel/`+providerTrackingId);
            return response;
        }
        catch(err) {
            return err;
        }
   }

   const fetchTrackingTemplateAsync = async () => {
        try {
            const template = await http.fetchAsync(`${version}/Tracking/Template`);
            return template
        }
        catch(err) {
            console.error(err);
            return {}
        }
    }

    const fetchActiveTrackingAsync = async (trackingIds = []) => { 
        try {
            const result = await http.postAsync(`${version}/Tracking/Search`, {carrierTrackingRecordIds: trackingIds, activeOnly: true});
            return result;
        }
        catch(err) {
            console.error(err);
            return {}
        }
    }

    const fetchRecentLocationsAsync = async (trackingIds = []) => { 
        try {
            const result = await http.postAsync(`${version}/Tracking/Search/RecentLocations`, {carrierTrackingRecordIds: trackingIds, activeOnly: true});
            return result;
        }
        catch(err) {
            console.error(err);
            return {}
        }
    }

    const fetchAllLocationsForCarrierTrackingAsync = async (carrierTrackingRecordId) => { 
        try {
            const result = await http.fetchAsync(`${version}/Tracking/GetAllCarrierTrackingLocations`, {carrierTrackingRecordId});
            return result || [];
        }
        catch(err) {
            console.error(err);
            return []
        }
    }

    const fetchAllLocationsAndStopsForCarrierTrackingAsync = async (carrierTrackingRecordId) => { 
        try {
            const result = await http.fetchAsync(`${version}/Tracking/GetLocationsAndStops`, {carrierTrackingRecordId});
            return result || [];
        }
        catch(err) {
            console.error(err);
            return []
        }
    }

    return {
        fetchCarrierTrackingByIdAsync,
        postStartTrackingAsync,
        cancelTrackingAsync,
        fetchTrackingTemplateAsync,
        postUpdateTrackingAsync,
        postSyncTrackingAsync,
        fetchActiveTrackingAsync,
        fetchRecentLocationsAsync,
        postCreateTrackingAsync,
        fetchAllLocationsForCarrierTrackingAsync,
        fetchAllLocationsAndStopsForCarrierTrackingAsync,
        fetchCarrierTrackingByMoveIdAsync
    }
}

const http = TNTHttp({baseAPI: baseURL}); 

export default {
    HTTP: http,
    ...Queries({http})
}