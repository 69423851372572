import dataModel from "data-model";
import userProfile from "user-profile";
import { isEmpty } from "global-functions";
import gridState from "jqx.grid-state-component";
import gridStateUtils from "jqx.grid-utils";

class OrderEntryArrangementsViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};
        this.isProcessing = ko.observable(false);

        this.oeaGridState = null;
        this.grid = $("#orderEntryArrangementsGrid");

        this.agencyExId = ko.observable();
        this.oeaEditFormData = ko.observable();

        this.initializeComponent();
    }

    async initializeComponent() {
        await this.setUserFilters(); // And load this, but wait here until finished, then
        this.loadGrid(); // load this
    }

    refreshGrid = () => {
        this.grid.jqxGrid("updatebounddata", "data");
        this.$emitsOnAction();
    };

    async setUserFilters() {
        let filters = await gridStateUtils
            .getUserGridFiltersAsync("orderEntryArrangementsGrid")
            .catch((err) => console.error("Failed to retrieve user filters."));
        if (isEmpty(filters) == false) {
            let state = JSON.parse(filters);
            this.oeaGridState = state.grid;
        }
    }

    get gridSource() {
        const vm = this;
        return {
            url: "MyAgency/GetOrderEntryArrangements",
            datatype: "json",
            formatdata: (data) => {
                data = data || {};
                data.agencyId = dataModel.currentAgencyId();

                return data;
            },

            datafields: [
                { name: "id", type: "int" },
                { name: "page", type: "string" },
                { name: "pageId", type: "int" },
                { name: "fieldName", type: "string" },
                { name: "fieldNameId", type: "int" },
            ],

            loadComplete: (data) => {
                vm.agencyExId(data.agencyExId);
            },
        };
    }

    loadGrid = () => {
        const vm = this;

        if (this.grid.length == 0)
            this.grid.length = $("#orderEntryArrangementsGrid");

        const dataAdapter = dataModel.getDataAdapter(this.gridSource);

        this.grid.jqxGrid({
            theme: "GWTMDark",
            width: "100%",
            height: "330px",
            source: dataAdapter,
            altrows: true,
            showstatusbar: true,
            sortable: true,
            pageable: true,
            //pagesize: 7,
            filterable: true,
            showfilterrow: true,
            virtualmode: false,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            statusbarheight: 35,
            showtoolbar: true,
            rendertoolbar: (toolbar) => {
                let vm1 = new gridState.viewModel();

                vm1.actions = [
                    "Clear Filters",
                    "Clear Selection",
                    "Refresh",
                    "Set Default Search",
                ];
                vm1.initializeDefaultToolbar(toolbar);

                vm1.notifyLocation = this.grid
                    .closest(".modal-content")
                    .find(".modal-header");
                const btnAddOrderEntryArrangement = $(
                    "#btnAddOrderEntryArrangement"
                );
                $(toolbar).append(btnAddOrderEntryArrangement);
            },

            rendergridrows: (obj) => {
                return obj.data;
            },

            columns: [
                // {
                //     text: "",
                //     width: 60,
                //     filterable: false,
                //     sortable: false,
                //     columnType: "button",
                //     buttonclick: (row) => {
                //         const datarow = vm.grid.jqxGrid("getrowdata", row);
                //         vm.handleEditOEA(datarow);
                //     },
                //     cellsrenderer: () => {
                //         return "Edit";
                //     },
                // },
                { text: "Field Name", datafield: "fieldName" },
                {
                    text: "",
                    width: 60,
                    filterable: false,
                    sortable: false,
                    columnType: "button",
                    buttonclick: (row) => {
                        const datarow = vm.grid.jqxGrid("getrowdata", row);
                        vm.handleDeleteOEA(datarow.id);
                    },
                    cellsrenderer: () => {
                        return "Delete";
                    },
                },
            ],

            ready: () => {
                gridStateUtils.applyGridState(
                    "orderEntryArrangementsGrid",
                    this.oeaGridState
                );
            },
        });
    };

    handleEditOEA = (formData) => {
        formData = formData || {};
        formData.agency = this.agencyExId();

        this.oeaEditFormData(formData);
    };

    handleDeleteOEA = (id) => {
        this.doHandleDeleteOEA(id);
    };

    async doHandleDeleteOEA(id) {
        const vm = this;

        await vm.http_sendDeleteOEA(id).catch((err) => console.error(err));
        vm.refreshGrid();
    }

    http_sendDeleteOEA = (id) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/DeleteOrderEntryArrangement",
                    "delete",
                    id
                )
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };
}

import template from "./order-entry-arrangements-component.html";
export default {
    viewModel: OrderEntryArrangementsViewModel,
    template: template,
};
