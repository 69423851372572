import dataModel from "data-model";
import template from "./order-entry-summary-component.html";
import userProfile from "user-profile";
import ko, { Observable } from "knockout";
import { OrderEntryViewModel } from "../order-entry-page";
import router from "router";
import { showconfirm, showmessage } from "show-dialog-methods";
import { DeliveryReceiptModel } from "../order-entry-delivery-receipt-component/order-entry-delivery-receipt-component";
import { ContainerModel } from "../order-entry-container-component/order-entry-container-component";

import { useDispatch } from "ko-data-store";

import TNTContext, {
  TRACKINGSTATUS_ENUM,
} from "../order-entry-track-trace-component/trackTraceOrderEntryContext";
import { isLoading } from "../../../../dataStore/actions/appUI";

class SummaryModel {
  /**@type { OrderEntryViewModel } */
  $parent;
  /**@type { Observable<string> } */
  orderId;

  currentTrackedMovementExId = ko.observable("");
  openTrackingDriverPhoneModal = ko.observable(false);

  constructor(item) {
    this.$parent = item.data().parent;

    this.dispatchAction = useDispatch();

    this.orderId = ko.observable(item.data().item.orderExternalId);
    this.status = ko.observable(item.data().item.status || "Quote");

    this.fuelLimit = ko.observable(item.data().item.fuelLimit);
    this.totalCharge = ko.observable(item.data().item.totalCharge);

    //Dispatch variables
    this.driverCarrier = this.$parent.driverCarrier;
    this.tractorExId = ko.observable();
    this.overridePayRate = ko.observable(item.data().item.overridePayRate);

    this.distanceSearchType = ko.observable(
      item.data().item.distanceSearchType
    );
    this.actions = ko.observable(item.data().item.actions);
    this.pingFrequency = ko.observable(item.data().item.pingFrequency);
    this.pingFrequency.subscribe((value) => {
      if (value.value) {
        this.pingFrequency(value.value);
      }
    });
    this.isGemUser = ko.observable(item.data().item.isGemUser);
    this.auditLog = item.data().item.auditLog;
    this.agencyId = ko.observable(item.data().item.agencyId);

    this.status.subscribe((status) => {
      if (
        !this.$parent.orderEntryDispatchParameters() &&
        !this.$parent.orderEntryDispatchBrokerageParameters()
      ) {
        if (status == "Available") {
          this.$parent.buildOrderEntryDispatch();
        }
      }
    });
    item.data(this);
    if (!this.$parent.orderLockComponent()) {
      this.$parent.isLoaded(true);
    }

    this.isBrokeredCheckboxSummaryHandler = (x, event) => {
      const { checked } = event.currentTarget;
      this.$parent.isBrokered(checked);
    };

    TNTContext.currentMoveTracking.subscribe((val) => {
      if (val) {
        this.currentTrackedMovementExId(val.movementExId);
      }
    });
  }

  isDuplicateVisible = ko.pureComputed(() => {
    if (
      this.agencyId() == userProfile.agencyExternalId &&
      ["Available", "In Progress", "Delivered"].indexOf(this.status()) > -1
    ) {
      return true;
    }
    return false;
  });

  isTenderStatusVisible = ko.pureComputed(() => {
    if (
      this.agencyId() == userProfile.agencyExternalId &&
      ["Available", "In Progress", "Delivered"].indexOf(this.status()) > -1
    ) {
      return true;
    }
    return false;
  });
  isGeoLocationVisible = ko.pureComputed(() => {
    if (TNTContext.hasActiveTracking()) {
      return true;
    } else if (
      this.agencyId() == userProfile.agencyExternalId &&
      ["In Progress", "Delivered"].indexOf(this.status()) > -1
    ) {
      return true;
    }
    return false;
  });

  isCallInVisible = ko.pureComputed(() => {
    if (
      this.status().match(/^(Available|In Progress|Delivered)$/) &&
      this.agencyId() == userProfile.agencyExternalId &&
      this.tractorExId != null
    ) {
      return true;
    }
    return false;
  });

  showSaveButton = ko.pureComputed(() => {
    if (this.agencyId() != null && this.$parent.isAgencyOrder() == false) {
      return false;
    }

    if (!this.$parent.isActiveAgency() === true) {
      return false;
    } else if (this.$parent.isReadOnlyOrder()) {
      if (this.$parent.isContainer()) {
        if (
          this.$parent.isDrayPuReadOnly() == false ||
          this.$parent.isDrayDelReadOnly() == false
        ) {
          return true;
        }
      }
      return false;
    }
    return true;
  });

  isFinalizeVisible = ko.pureComputed(() => {
    if (
      this.$parent.customerCredit() &&
      this.$parent.customerCredit().exceedsCredit &&
      this.$parent.customerCredit().hardLimit
    ) {
      return false;
    }

    if (this.$parent.isAgencyOrder()) {
      if (
        this.status() == "Quote" &&
        this.$parent.validationErrors()().length == 0
      ) {
        return true;
      }
    }

    return false;
  });

  closeOrder = () => {
    if (!this.orderId() && !this.$parent.isLoading()) {
      router.navigate("/Orders");
    } else {
      this.$parent
        .initiateSaveOrderProcess(this.status() == "Quote")
        .then(() => {
          router.navigate("/Orders");
        });
    }
  };

  availableActions = ko.pureComputed(() => {
    let items = [];
    if (this.orderId() == undefined) {
      items = ["Use Order Template"];
    } else if (this.$parent.isAgencyOrder() == false) {
      if (
        ["Available", "In Progress", "Delivered"].indexOf(this.status()) > -1
      ) {
        items = ["View Images"];
      }
    } else {
      let freightboard = "Send To Loadboard";
      if (this.$parent.loadIdCreatedFromOrder() > 0) {
        freightboard = "Expire Load";
      }
      if (this.status() === "Quote") {
        items = ["Save As Template", "Delivery Receipt", "Void Order"];
      } else if (this.status() === "Available") {
        items = [
          freightboard,
          "View Images",
          "View GEM Uploads",
          "Save As Template",
          "Void Order",
          "Delivery Receipt",
          "Re-arrange Stops",
        ];
      } else if (this.status() === "In Progress") {
        items = [
          freightboard,
          "View Images",
          "View GEM Uploads",
          "Save As Template",
          "Delivery Receipt",
          "Re-arrange Stops",
        ];
      } else if (this.status() === "Delivered") {
        items = ["View Images", "View GEM Uploads", "Save As Template", "Delivery Receipt"];
      } else if (this.status() === "Voided") {
        items = ["Make Quote"];
      }

      if (
        TNTContext.isTrackingRequired() === false &&
        TNTContext.canShowManualTrackingOption() &&
        (this.status() === "In Progress" || this.status() === "Available")
      ) {
        // only give this option when tracking is not required for customer.
        items.push(
          (TNTContext.isTrackingOn() && "Cancel Tracking") || "Track Shipment"
        );
      }
    }

    if (
      this.$parent.orderLockComponent() &&
      this.$parent.orderLockComponent().lockOrderControls &&
      this.$parent.orderLockComponent().lockOrderControls()
    ) {
      items = items.filter((x) => {
        return x != "Void Order" && x != "Re-arrange Stops";
      });
    }

    return items;
  });

  expireLoadActionSelected = () => {
    showconfirm("Are you sure you want to expire load from loadboard?").then(
      (x) => {
        if (x == true) {
          this.$parent.expireLoad();
        }
      }
    );
  };

  voidOrderActionSelected = () => {
    showconfirm(
      "Are you sure you want to void this order from loadboard?"
    ).then((x) => {
      if (x == true) {
        this.$parent.voidOrder();
      }
    });
  };

  rearrangeStopsActionSelected = () => {
    this.$parent.stopsArrangementModal({
      parent: this,
      orderExId: this.$parent.orderExternalId(),
      stops: this.$parent.stops(),
      hasCustomDistance: this.$parent.billing().hasCustomDistance(),
      onSaveCallBackFn: () => {
        this.$parent.loadOrder();
      },
    });
  };

  moveTrailerActionSelected = () => {
    var model = {
      trailerExId: this.$parent
        .orderEntryDispatchParameters()
        .orderMovements()
      [
        this.$parent.orderEntryDispatchParameters().orderMovements().length -
        1
      ].trailerExId(),
      result: ko.observable(),
    };
    this.$parent.moveTrailerModal(model);
  };

  orderActionSelected = async (value) => {
    if (value) {
      if (value == "Send To Loadboard") {
        this.$parent.sendToLoadboardActionSelected();
      } else if (value == "Expire Load") {
        this.expireLoadActionSelected();
      } else if (value == "Void Order") {
        this.voidOrderActionSelected();
      } else if (value == "Make Quote") {
        this.$parent.showMakeQuoteOrder();
      } else if (value == "View Images") {
        this.$parent.showOrderImages();
      }
      else if (value == "View GEM Uploads") {
        this.$parent.showGEMUploads();
      }
      else if (value == "Save As Template") {
        this.$parent.showSaveTemplate();
      } else if (value == "Use Order Template") {
        this.$parent.showUseTemplateOrder();
      } else if (value == "Delivery Receipt") {
        this.$parent.deliveryReceiptModal(
          new DeliveryReceiptModel(this.orderId())
        );
      } else if (value == "Re-arrange Stops") {
        this.rearrangeStopsActionSelected();
      } else if (value == "Move Trailer") {
        this.moveTrailerActionSelected();
      }
      // FilterSearch:TNT - Phase 2 - Hide for go live Phase 1
      else if (value === "Cancel Tracking") {
        showconfirm(
          TNTContext.isTrackingRequired()
            ? `This customer requires tracking. Are you sure you want to cancel tracking?`
            : `Are you sure you want to cancel tracking?`
        ).then((yes) => {
          if (yes) {
            this.dispatchAction(isLoading(true));
            TNTContext.cancelAllTracking().then(() => {
              TNTContext.updateSlice({
                sliceKey: "manualTrackingOn",
                payload: false,
              });

              this.dispatchAction(isLoading(false));
            });
          }
        });
      } else if (value === "Track Shipment") {
        TNTContext.updateSlice([
          {
            sliceKey: "manualTrackingOn",
            payload: true,
          },
          {
            sliceKey: "trackedMovements:tracking",
            payload: { trackingStatusId: TRACKINGSTATUS_ENUM.Pending },
          },
        ]);

        await TNTContext.loadTracking();

        if (TNTContext.getState().isBrokered === false) {
          TNTContext.updateSlice({sliceKey: "showDriverPhoneModal", payload: true})
        } else {
          setTimeout(() => {
            if ($("#trackingSectionDiv").length) {
              $("body").animate(
                {
                  scrollTop: $("#trackingSectionDiv").offset().top,
                },
                "fast"
              );
            }
          }, 200);
        }
      }
    }
  };

  isBrokeredReadOnly = ko.pureComputed(() => {
    if (this.$parent.noBroker()) {
      return true;
    } else if (this.$parent.orderLockComponent()?.lockOrderControls()) {
      return true;
    } else {
      if (
        this.status() != null &&
        ["Quote", "Available"].indexOf(this.status()) == -1
      ) {
        return true;
      }

      return this.$parent.isReadOnly();
    }
  });

  isSaveButtonDisabled = ko.pureComputed(() => {
    return this.$parent.isLoading() || !this.$parent.orderId();
  });

  isContainerReadOnly = ko.pureComputed(() => {
    if (this.$parent.isAgencyOrder() == false) {
      return true;
    } else if (this.$parent.orderLockComponent()?.lockOrderControls()) {
      return true;
    } else if (this.$parent.isContainer() && this.$parent.readyToBill()) {
      return true;
    } else {
      return false;
    }
  });

  isHoldReadOnly = ko.pureComputed(() => {
    return (
      this.$parent.isReadOnly() ||
      this.$parent.orderLockComponent()?.lockOrderControls()
    );
  });

  isPostOrderReadOnly = ko.pureComputed(() => {
    return this.$parent.orderLockComponent()?.lockOrderControls();
  });

  cbIsContainerClick = () => {
    if (this.$parent.isContainer()) {
      this.$parent.container(new ContainerModel({}, this.$parent));
    } else {
      this.$parent.container(null);
    }
    if (this.agencyId() && this.agencyId() == "DVABAS") {
      this.$parent
        .initiateSaveOrderProcess(this.status() == "Quote")
        .then(() => {
          dataModel
            .ajaxRequest("order/RemapEDIOrders/" + this.orderId(), "GET")
            .done(() => {
              router.navigate("/NewOrder/" + this.orderId());
            });
        });
    }
    return true;
  };

  showShareOrder = ko.pureComputed(() => {
    if (
      this.$parent.showSharing &&
      (this.status() == "Available" || this.status() == "Quote") &&
      this.driverCarrier() == null
    ) {
      return true;
    } else {
      return false;
    }
  });

  isSaveAndCloseButtonVisible = ko.pureComputed(() => {
    return !this.$parent.readyToBill();
  });
}

SummaryModel.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.$parent;
  return copy;
};

export default { viewModel: SummaryModel, template: template };

export { SummaryModel };
