import './newAgentSignOnChecksheetStyles.css';
import dataModel from 'data-model';
import gridState from 'jqx.grid-state-component';
import {isEmpty} from 'global-functions';

class DetailsViewModel {
    constructor(id, mainModel) {
        var self = this;
        id = id || null;
        self.mainModel = mainModel || {};

        // Ui Controls
        
        // Data
        self.agencyManualSent = ko.observable();
        self.agencyNameAndOwner = ko.observable();
        self.agreementAddendumReturned = ko.observable();
        self.agreementAddendumSentToAgent = ko.observable();
        self.announceMentSentToAgents = ko.observable();
        self.appSentToAgent = ko.observable();
        self.assignedBusDeveloper = ko.observable();
        self.assignedLiason = ko.observable();
        self.cityState = ko.observable();
        self.comments = ko.observable();
        self.completedAppSentToDeptMgr = ko.observable();
        self.completedAppSentToLiason = ko.observable();
        self.computerLoginsAssigned = ko.observable();
        self.custListSentToReceivables = ko.observable();
        self.driverAppsSent = ko.observable();
        self.employeesTrained = ko.observable();
        self.leadGivenToBusinessDev = ko.observable();
        self.mvrsAndTruckVINsSentToSafety = ko.observable();
        self.offerLetterZScoreReceived = ko.observable();
        self.operationsMgr = ko.observable();
        self.payeeTerminalSetUp = ko.observable();
        self.region = ko.observable();
        self.safetyRepAssigned = ko.observable();
        self.trainer = ko.observable();
        self.webexOrVisit = ko.observable();

        //-----------------
        // Behavior
        self.closeModal = function () {
            $(".modal").modal('hide');
        }

        // Initialize
        self.init = function () {
            self.mainModel.isLoading(true);
            dataModel.ajaxRequest("AgentChecksheet/Details/" + id, "GET").done(function (response) {
                
                if (response != undefined) {
                    self.agencyManualSent(response.agencyManualSent);
                    self.agencyNameAndOwner(response.agencyNameAndOwner);
                    self.agreementAddendumReturned(response.agreementAddendumReturned);
                    self.agreementAddendumSentToAgent(response.agreementAddendumSentToAgent);
                    self.announceMentSentToAgents(response.announceMentSentToAgents);
                    self.appSentToAgent(response.appSentToAgent);
                    self.assignedBusDeveloper(response.assignedBusDeveloper);
                    self.assignedLiason(response.assignedLiason);
                    self.cityState(response.cityState);
                    self.comments(response.comments);
                    self.completedAppSentToDeptMgr(response.completedAppSentToDeptMgr);
                    self.completedAppSentToLiason(response.completedAppSentToLiason);
                    self.computerLoginsAssigned(response.computerLoginsAssigned);
                    self.custListSentToReceivables(response.custListSentToReceivables);
                    self.driverAppsSent(response.driverAppsSent);
                    self.employeesTrained(response.employeesTrained);
                    self.leadGivenToBusinessDev(response.leadGivenToBusinessDev);
                    self.mvrsAndTruckVINsSentToSafety(response.mvrsAndTruckVINsSentToSafety);
                    self.offerLetterZScoreReceived(response.offerLetterZScoreReceived);
                    self.operationsMgr(response.operationsMgr);
                    self.payeeTerminalSetUp(response.payeeTerminalSetUp);
                    self.region(response.region);
                    self.safetyRepAssigned(response.safetyRepAssigned);
                    self.trainer(response.trainer);
                    self.webexOrVisit(response.webexOrVisit);
                }

                self.mainModel.isLoading(false);
            });
        }
        self.init();
    }
}

class NewAgentSignOnCheckSheetViewModel {
    constructor() {
        var self = this;

        //============================
        // UI Controls
        self.isLoading = ko.observable(true);
        self.modelErrors = ko.observableArray([]);
        var grid = $('#jqxChecksheetGrid');

        self.placeHolderFilterWidget = function (column, columnElement, widget) {
            widget.jqxInput({
                placeHolder: "Type Here...",
                width: '100%'
            });
        };

        self.detailsModal = ko.observable();

        //============================
        // Data
        self.nasocs = ko.observableArray([]);

        //=========================
        // Behaviors


        // Set and disply model errors
        self.setModelErrors = function (errors) {
            if (errors != null || errors != undefined) {

                for (var i = 0; i < errors.length; i++) {
                    self.modelErrors.push({ errorMessage: errors[i] });
                }
            }
        }

        self.openDetailsModal = function (id) {
            if (id != undefined) {
                self.detailsModal(new DetailsViewModel(id, self));
            }
        }


        //=======
        // Grid
        self.loadGrid = function () {
            var source = {
                url: "AgentChecksheet",
                datatype: "json",
                loadComplete: function (data) {
                    self.isLoading(false);
                },
                loadError: function (xhr, status, error) { },
                datafields: [
                    { name: "id", type: "number"},
                    { name: "agencyNameAndOwner", type: "string" },
                    { name: "cityState", type: "string" },
                    { name: "leadGivenToBusinessDev", type: "string" },
                    { name: "Comments", type: "string" },
                ],
            }

            var dataAdapter = dataModel.getDataAdapter(source);

            var gridColumns = [
                {
                    text: '', width: 100, columntype: 'button',
                    cellsrenderer: function () {
                        return "Details";
                    },
                    buttonclick: function (row) {
                        var item = grid.jqxGrid("getrowdata", row);

                        if(item)
                            self.openDetailsModal(item.id);
                    },
                    filterable: false,
                    sortable: false,
                    pinned: true,
                    
                },
                { datafield: 'id', hidden: true },
                { text: 'Agency Name/Owner', datafield: 'agencyNameAndOwner', createfilterwidget: self.placeHolderFilterWidget },
                {
                    text: 'City/State',
                    datafield: 'cityState',
                    createfilterwidget: function (column, columnElement, widget) {
                        widget.jqxInput({
                            placeHolder: "Enter City/State", width: '100%'
                        });
                    }
                },
                { text: 'Lead Given To', datafield: 'leadGivenToBusinessDev', createfilterwidget: self.placeHolderFilterWidget },
                { text: 'Comments', datafield: 'comments' }
            ];

            grid.jqxGrid({
                theme: 'GWTMDark',
                width: '100%',
                autoheight: true,
                source: dataAdapter,
                columns: gridColumns,
                showheader: true,
                showtoolbar: true,
                pageable: true,
                pagesize: 10,
                sortable: true,
                filterable: true,
                altrows: true,
                showfilterrow: true,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                toolbarheight: '35px',
                rendertoolbar: function (toolbar) {
                    var vm1 = new gridState.viewModel(),
                        template = gridState.template;

                    $(toolbar).append(template);

                    ko.applyBindingsToDescendants(vm1, toolbar[0]);
                },
                ready: function () {
                    
                    self.loadGridState(grid, self.nasocsGridState);
                }
            });
        }


        self.loadGridState = function (grid, state) {
            if (state != null) {
                var gridState = grid.jqxGrid('getstate');
                state.selectedrowindex = -1;
                state.selectedrowindexes = [];
                state.pagenum = 0;
                state.height = gridState.height;
                state.pagesize = gridState.pagesize;
                state.pagesizeoptions = gridState.pagesizeoptions;

                grid.jqxGrid('loadstate', state);
            }
            else {
                grid.jqxGrid('applyfilters');
            }
        };

        function getPageName() {
            return window.location.pathname.substr(1, window.location.pathname.length);
        }

        self.nasocsGridState = null;
        self.init = function() {
            dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", { name: "jqxChecksheetGrid", pageName: getPageName()})
                .done(function (data) {
                    
                    //if user has saved filters
                    if (isEmpty(data) == false) {
                        var state = JSON.parse(data);
                        self.nasocsGridState = state.grid;
                    }

                    self.loadGrid();
                    
                }).fail(function () {
                    // An error occurred getting user filters....404, 500, w/e
                    // Let's still build the grid...
                    self.loadGrid();
                });
        }

        self.init();
    }
}

import template from './newagent-signon-checksheet-page.html';
export default { viewModel: NewAgentSignOnCheckSheetViewModel, template: template }