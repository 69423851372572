// --------------------------------------------------------------------------------------------
// SIMPLE WRAPPER UTILITY FOR BROADCASTING EVENTS & DATA BETWEEN SAME DOMAIN BROWSER TABS
//

// instead of having magic strings everywhere, also to make it easier to remember or change,
// use this object for common channel names.
export const commonChannels = {
    loadboardMatchChannel: "loadboardMatchChannel"
}

// no operation (used primarily in cases of undefined|null callback function arguments)
const noop = (...x) => {}

export const broadcastAPI = function({
    channels = {}
} = {}) {

    const createChannel = (channelName) => {
        const bChannel = 'BroadcastChannel' in window ? new BroadcastChannel(channelName) : { onmessage: noop, postMessage: noop };
        return bChannel;
    }

    const addChannelToList = (channelName, bChannel) => {
        channels = {...channels, [channelName]: bChannel }
        return bChannel
    }
    
    const listenOn = (channelName) => {
        const channel = addChannelToList(channelName, createChannel(channelName));

        return (onMessage = noop) => {
            channel.onmessage = ({currentTarget, data}) => {
                const {name} = currentTarget || {};
                if(name === channelName) {
                    onMessage(data);    
                }
            }
        }
    }

    const stopListeningOn = (channelName) => {
        if(channels[channelName]) {
            channels[channelName].close();
        }
    }

    const stopListeningOnAll = () => {
        if(Object.keys(channels).length) {
            Object.keys(channels).forEach((key) => channels[key].close());
        }
    }

    const messageTo = (channelName, data = {}) => {
        const channel = addChannelToList(channelName, createChannel(channelName));
        channel.postMessage(data);
    }

    return {
        channels: () => ({...channels}),
        listenOn,
        stopListeningOn,
        stopListeningOnAll,
        messageTo
    }
}

const instance = broadcastAPI();
export default instance;