import dataModel from "data-model";
import { isElementInViewport, formatPhoneNumber } from "global-functions";
import ko from "knockout";
import "jquery-ui/ui/widgets/autocomplete";
import userProfile from "user-profile";

var autoCompleteCache = {};
function geAutocomplete() {
  let templateNames = function () {
    return {
      address: ["carrier", "location", "salesperson", "steamship", "myagency"],
      customer: ["customer"],
      nameWithRating: ["user", "payee", "agency"],
      name: ["fieldsafetymanager"],
      description: [
        "stoptype",
        "qualifiertype",
        "region/getzones",
        "inspection/getdotviolations",
      ],
      descWithRating: [
        "paysplittype",
        "trailertype",
        "revenuecode",
        "billingmethod",
        "hazmat",
        "chargecode",
        "commodity",
        "ordertemplate",
      ],
      deductionEarningCode: ["deductionearningcode"],
      locationcontact: ["locationcontact"],
      trailer: ["trailer"],
      tractor: ["tractor"],
      carrierTerminal: ["carrierterminal"],
      zone: ["zone"],
      carrierContact: ["carriercontact"],
      driver: ["driver"],
      order: ["order"],
      comdatacard: ["comdatacard"],
      userwithphone: ["driver/getcarrieruserdriversforddl"],
    };
  };

  let ddlRenderTemplate = function (data, source) {
    if (data != null) {
      source = source.toLowerCase();

      if (templateNames().address.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px; height:55px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdName = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        let tdRating = $("<td>");
        let row2 = $("<tr >");
        let tdAddress = $("<td colspan='3'>");

        tdCode.append(data.code);
        tdName.append(data.name);
        tdRating.append("<img src='/content/Images/" + rate + "star.png' />");
        tdAddress.append(
          "<i style='color:gray;'>" +
            (data.address || "") +
            " " +
            data.city +
            ", " +
            data.state +
            " " +
            (data.zip || "") +
            "</i>"
        );

        row1.append(tdCode);
        row1.append(tdName);
        row1.append(tdRating);
        table.append(row1);

        row2.append(tdAddress);
        table.append(row2);

        return table[0].outerHTML;
      } else if (templateNames().customer.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px; height:55px;'>");
        let row1 = $("<tr>");
        let tdName = $(
          "<td style='width:280px; white-space:normal !important;'>"
        );
        let tdRating = $("<td>");
        let row2 = $("<tr >");
        let tdAddress = $("<td colspan='2'>");

        tdName.append(data.name);
        tdRating.append("<img src='/content/Images/" + rate + "star.png' />");
        tdAddress.append(
          "<i style='color:gray;'>" +
            (data.address || "") +
            " " +
            data.city +
            ", " +
            data.state +
            " " +
            (data.zip || "") +
            "</i>"
        );

        row1.append(tdName);
        row1.append(tdRating);
        table.append(row1);

        row2.append(tdAddress);
        table.append(row2);

        return table[0].outerHTML;
      } else if (templateNames().nameWithRating.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdDesc = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        let tdRating = $("<td>");
        tdCode.append(data.code);
        tdDesc.append(data.name);
        tdRating.append("<img src='/content/Images/" + rate + "star.png' />");
        row1.append(tdCode);
        row1.append(tdDesc);
        row1.append(tdRating);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().name.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdDesc = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        tdCode.append(data.code);
        tdDesc.append(data.name);
        row1.append(tdCode);
        row1.append(tdDesc);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().descWithRating.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdDesc = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        let tdRating = $("<td>");
        tdCode.append(data.code);
        tdDesc.append(data.description);
        tdRating.append("<img src='/content/Images/" + rate + "star.png' />");
        row1.append(tdCode);
        row1.append(tdDesc);
        row1.append(tdRating);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().deductionEarningCode.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdDesc = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        let tdTypeDesc = $("<td>");
        let tdRating = $("<td>");
        tdCode.append(data.code);
        tdDesc.append(data.description);
        tdTypeDesc.append(data.typeDescription);
        tdRating.append("<img src='/content/Images/" + rate + "star.png' />");
        row1.append(tdCode);
        row1.append(tdDesc);
        row1.append(tdTypeDesc);
        row1.append(tdRating);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().tractor.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px; height:55px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdMake = $(
          "<td style='width:80px; white-space:normal !important;'>"
        );
        let tdEquipType = $(
          "<td style='width:80px; white-space:normal !important;'>"
        );
        let tdRating = $("<td>");
        tdCode.append(data.code);
        tdMake.append(data.make);
        tdEquipType.append(data.equipType);
        tdRating.append("<img src='/content/Images/" + rate + "star.png' />");
        row1.append(tdCode);
        row1.append(tdMake);
        row1.append(tdEquipType);
        row1.append(tdRating);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().trailer.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px; height:55px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdTrailerLength = $(
          "<td style='width:80px; white-space:normal !important;'>"
        );
        let tdYear = $(
          "<td style='width:80px; white-space:normal !important;'>"
        );
        let tdRating = $("<td>");
        tdCode.append(data.code);
        tdTrailerLength.append(data.trailerLength);
        tdYear.append(data.modelYear);
        tdRating.append("<img src='/content/Images/" + rate + "star.png' />");
        row1.append(tdCode);
        row1.append(tdTrailerLength);
        row1.append(tdYear);
        row1.append(tdRating);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().driver.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px; height:55px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdName = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        let tdRating = $("<td>");
        tdCode.append(data.code);
        tdName.append(data.firstName + " " + data.lastName);
        tdRating.append("<img src='/content/Images/" + rate + "star.png' />");
        row1.append(tdCode);
        row1.append(tdName);
        row1.append(tdRating);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().locationcontact.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px; height:55px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdTitle = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        let tdRating = $("<td>");
        tdCode.append(data.code);
        tdTitle.append(data.title);
        tdRating.append("<img src='/content/Images/" + rate + "star.png' />");
        row1.append(tdCode);
        row1.append(tdTitle);
        row1.append(tdRating);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().zone.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdDesc = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        tdCode.append(data.id);
        tdDesc.append(data.description);
        row1.append(tdCode);
        row1.append(tdDesc);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().carrierTerminal.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdName = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        tdCode.append(data.code);
        tdName.append(data.cityStateZip);
        row1.append(tdCode);
        row1.append(tdName);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().carrierContact.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px;'>");
        let row1 = $("<tr>");
        let tdName = $("<td style='width:80px;'>");
        let tdTitle = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        tdName.append(data.name);
        tdTitle.append(data.title);
        row1.append(tdName);
        row1.append(tdTitle);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().userwithphone.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px;'>");
        let row1 = $("<tr>");
        let tdUName = $("<td style='width:80px;'>");
        let tdTitle = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        tdUName.append(data.code);
        tdTitle.append(formatPhoneNumber(data.phone));
        row1.append(tdUName);
        row1.append(tdTitle);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().description.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdDesc = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        tdCode.append(data.code);
        tdDesc.append(data.description);
        row1.append(tdCode);
        row1.append(tdDesc);
        table.append(row1);
        return table[0].outerHTML;
      } else if (templateNames().order.indexOf(source) > -1) {
        let rate = data.rating ? data.rating : 0;
        let table = $("<table style='width:450px;'>");
        let row1 = $("<tr>");
        let tdCode = $("<td style='width:80px;'>");
        let tdDesc = $(
          "<td style='width:200px; white-space:normal !important;'>"
        );
        tdCode.append(data.code);
        row1.append(tdCode);
        table.append(row1);
        return table[0].outerHTML;
      } else {
        return data.code;
      }
    }
    return "";
  };

  let ddlTemplate = function (route) {
    if (templateNames().address.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "name", type: "string" },
        { name: "rating", type: "int" },
        { name: "address", type: "string" },
        { name: "city", type: "string" },
        { name: "state", type: "string" },
        { name: "zip", type: "string" },
      ];
    } else if (templateNames().customer.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "name", type: "string" },
        { name: "rating", type: "int" },
        { name: "address", type: "string" },
        { name: "city", type: "string" },
        { name: "state", type: "string" },
        { name: "zip", type: "string" },
      ];
    } else if (templateNames().nameWithRating.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "name", type: "string" },
        { name: "rating", type: "int" },
      ];
    } else if (templateNames().name.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "name", type: "string" },
      ];
    } else if (templateNames().descWithRating.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "description", type: "string" },
        { name: "rating", type: "int" },
      ];
    } else if (templateNames().deductionEarningCode.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "description", type: "string" },
        { name: "rating", type: "int" },
        { name: "typeDescription", type: "string" },
      ];
    } else if (templateNames().tractor.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "model", type: "string" },
        { name: "make", type: "string" },
        { name: "modelYear", type: "int" },
        { name: "equipType", type: "string" },
        { name: "rating", type: "int" },
      ];
    } else if (templateNames().trailer.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "length", type: "int" },
        { name: "modelYear", type: "int" },
        { name: "rating", type: "int" },
      ];
    } else if (templateNames().driver.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "firstName", type: "string" },
        { name: "lastName", type: "string" },
        { name: "rating", type: "string" },
      ];
    } else if (templateNames().locationcontact.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "title", type: "string" },
        { name: "rating", type: "string" },
      ];
    } else if (templateNames().carrierContact.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "name", type: "string" },
        { name: "title", type: "string" },
      ];
    } else if (templateNames().userwithphone.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "phone", type: "string" },
        { name: "email", type: "string" },
      ];
    } else if (templateNames().carrierTerminal.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "cityStateZip", type: "string" },
      ];
    } else if (templateNames().description.indexOf(route) > -1) {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
        { name: "description", type: "string" },
      ];
    } else {
      return [
        { name: "id", type: "int" },
        { name: "code", type: "string" },
      ];
    }
  };

  let ddlHeader = function (source) {
    source = source.toLowerCase();
    let table = $(
      "<table style='width:450px;' tabindex=-1 disabled='disabled'>"
    );
    let row1 = $("<tr>");
    let tdCode = $("<td style='width:80px;text-align:left;'>");
    let tdRating = $("<td style='text-align:left'>");
    // table.attr("tabindex", -1);

    if (templateNames().address.indexOf(source) > -1) {
      let tdName = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Code");
      tdName.append("Name");
      tdRating.append("Rating");

      row1.append(tdCode);
      row1.append(tdName);
      row1.append(tdRating);
      table.append(row1);
      return table[0].outerHTML;
    }
    if (templateNames().customer.indexOf(source) > -1) {
      let tdName = $(
        "<td style='width:280px; white-space:normal !important;text-align:left;'>"
      );
      tdName.append("Name");
      tdRating.append("Rating");

      row1.append(tdName);
      row1.append(tdRating);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().nameWithRating.indexOf(source) > -1) {
      let tdDesc = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Code");
      tdDesc.append("Name");
      tdRating.append("Rating");
      row1.append(tdCode);
      row1.append(tdDesc);
      row1.append(tdRating);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().name.indexOf(source) > -1) {
      let tdDesc = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Code");
      tdDesc.append("Name");
      row1.append(tdCode);
      row1.append(tdDesc);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().descWithRating.indexOf(source) > -1) {
      let tdDesc = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Code");
      tdDesc.append("Description");
      tdRating.append("Rating");
      row1.append(tdCode);
      row1.append(tdDesc);
      row1.append(tdRating);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().deductionEarningCode.indexOf(source) > -1) {
      let tdDesc = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      let typeDesc = $(
        "<td white-space:normal !important;text-align:left;'>"
      ).append("Type Description");
      tdCode.append("Code");
      tdDesc.append("Description");
      tdRating.append("Rating");
      row1.append(tdCode);
      row1.append(tdDesc);
      row1.append(typeDesc);
      row1.append(tdRating);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().tractor.indexOf(source) > -1) {
      let tdMake = $(
        "<td style='width:80px; white-space:normal !important;text-align:left;'>"
      );
      let tdEquipType = $(
        "<td style='width:80px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Code");
      tdMake.append("Make");
      tdEquipType.append("EquipType");
      tdRating.append("Rating");
      row1.append(tdCode);
      row1.append(tdMake);
      row1.append(tdEquipType);
      row1.append(tdRating);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().trailer.indexOf(source) > -1) {
      let tdTrailerLength = $(
        "<td style='width:80px; white-space:normal !important;text-align:left;'>"
      );
      let tdYear = $(
        "<td style='width:80px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Code");
      tdTrailerLength.append("Length");
      tdYear.append("Year");
      tdRating.append("Rating");
      row1.append(tdCode);
      row1.append(tdTrailerLength);
      row1.append(tdYear);
      row1.append(tdRating);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().driver.indexOf(source) > -1) {
      let tdName = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Code");
      tdName.append("Name");
      tdRating.append("Rating");
      row1.append(tdCode);
      row1.append(tdName);
      row1.append(tdRating);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().locationcontact.indexOf(source) > -1) {
      let tdName = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Name");
      tdName.append("Title");
      tdRating.append("Rating");
      row1.append(tdCode);
      row1.append(tdName);
      row1.append(tdRating);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().carrierContact.indexOf(source) > -1) {
      let tdName = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Name");
      tdName.append("Title");
      row1.append(tdCode);
      row1.append(tdName);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().userwithphone.indexOf(source) > -1) {
      let tdName = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("UserName");
      tdName.append("Phone");
      row1.append(tdCode);
      row1.append(tdName);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().carrierTerminal.indexOf(source) > -1) {
      let tdName = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Name");
      tdName.append("City/State/Zip");
      row1.append(tdCode);
      row1.append(tdName);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().description.indexOf(source) > -1) {
      let tdDesc = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdCode.append("Code");
      tdDesc.append("Description");
      row1.append(tdCode);
      row1.append(tdDesc);
      table.append(row1);
      return table[0].outerHTML;
    } else if (templateNames().order.indexOf(source) > -1) {
      let tdDesc = $(
        "<td style='width:200px; white-space:normal !important;text-align:left;'>"
      );
      tdDesc.append("Order Number");
      row1.append(tdDesc);
      table.append(row1);
      return table[0].outerHTML;
    }
    return "";
  };

  ko.bindingHandlers.geAutocomplete = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var observableValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        source = allBindings.jqAutoSource || ko.observableArray(),
        selectedValue = allBindings.selectedValue || ko.observable(),
        inputText = allBindings.inputText,
        autofocus = allBindings.autofocus || false,
        route = allBindings.route,
        placeholder = allBindings.placeholder,
        onChange = allBindings.onChange,
        valueProp = allBindings.sourceValue || "id",
        inputValueProp = allBindings.sourceInputValue || "code",
        labelProp = allBindings.sourceLabel || inputValueProp,
        searchValue = allBindings.searchValue || "code",
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        allowCached =
          allBindings.allowCached == null ? true : allBindings.allowCached,
        // disableChar = allBindings.disableChar || [],
        favTable = allBindings.favTable || route, // favorite table name is same as route unless otherwise specified
        optionalParams = ko.observable(allBindings.params),
        includeAllAgencies = allBindings.includeAllAgencies,
        updateView = allBindings.updateView,
        closeOnWindowScroll = allBindings.closeOnWindowScroll || false,
        disable =
          allBindings.disable != null
            ? allBindings.disable
            : ko.observable(false),
        className = allBindings.className || null,
        onModelUpdating = allBindings.onModelUpdating || function () {},
        onRequest = allBindings.onRequest || function () {},
        onResponse = allBindings.onResponse || function () {},
        forceOnChangeOnly = allBindings.forceOnChangeOnly || false,
        onPopupOpen = allBindings.onPopupOpen || function () {},
        onPopupClose = allBindings.onPopupClose || function () {},
        hideRating =
          allBindings.hideRating != undefined ? allBindings.hideRating : false,
        showAsInvalid =
          allBindings.showAsInvalid != undefined
            ? allBindings.showAsInvalid
            : true,
        localResponseFilter = allBindings.localResponseFilter || undefined,
        selectedValueCallBackFn =
          allBindings.selectedValueCallBackFn || function (x) {},
        useTextAsModelValueIfInvalid =
          allBindings.useTextAsModelValueIfInvalid || false,
        agencyNotRequired = allBindings.agencyNotRequired || false,
        enabled = allBindings.enabled, // prevent query, if null then work as normal
        virtualMode = allBindings.virtualMode ?? true, /// use api seaerches over local array
        autocompleteOptions = allBindings.autocompleteOptions || {};

      route = route.toLowerCase();

      var placeholder = ko.unwrap(placeholder);

      var initValue = ko.unwrap(observableValue);
      if (initValue == null) {
        initValue = ko.unwrap(inputText);
      }
      var isInit = initValue != null ? true : false;

      var totalItemCount = 0;
      var numerOfRecords = 0;
      var isTextChanged = false;
      var numOfItems = 10;
      var options = {
        autoFocus: false,
        height: autocompleteOptions.height || 200,
      };
      var isRatingVisible = false;

      var useTemplate = false;
      for (var tmpl in templateNames()) {
        if (templateNames()[tmpl].indexOf(route) > -1) {
          useTemplate = true;
          break;
        }
      }
      if (useTemplate) {
        var ddlColumns = ddlTemplate(route);
        for (var i = 0; i < ddlColumns.length; i++) {
          if (ddlColumns[i].name == "rating") {
            isRatingVisible = true;
            break;
          }
        }
      }

      var container = $(element);

      let id = container.attr("id");

      var divSearch = $("<div>");
      var divInput = $("<div>");
      var showAllBtnWrap = $("<div>");
      var ratingBtnWrapp = $("<div>");
      var divRating = $("<div>");
      var divComboBox = $("<div>");
      var divAuto = $("<div>");

      divAuto.addClass("geAutoComplete");
      divComboBox.addClass("geAuto-comboBox");
      container.addClass("geAuto-container");
      divInput.addClass("geAuto-search");
      showAllBtnWrap.addClass("geAuto-all");
      ratingBtnWrapp.addClass("geAuto-btn-ratting");
      divRating.addClass("geAuto-ratting");

      var input = $("<input>", {
        type: "text",
        id: id,
        value: initValue,
        placeholder: placeholder,
        autofocus: autofocus,
        class: className,
      });
      if (container.attr("name")) {
        input.attr("name", container.attr("name"));
      }

      var ratingButton = $("<input>", {
        type: "button",
        style:
          "width:25px; height:25px; background-image: url('/Content/JQWidgets-11/images/star.png');background-repeat: no-repeat; padding-top:1px;",
      });
      var readOnlyText = $("<span>", { style: "float:left" });
      var jqRating = $("<div>", { style: "padding-top:3px; " });

      var wasOpen = false;
      var showAllBtn = $(
        '<button tabindex="-1" class="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-button-icon" role="button" ><span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"></span><span class="ui-button-text">&nbsp;</span></button>'
      );
      showAllBtn.click(function () {
        numOfItems = 10;
        input.focus();
        if (wasOpen) {
          return false;
        }
        var searchText = input.val();
        if (searchText == "") {
          searchText = " ";
        }
        input.autocomplete("search", searchText);
        input.focus();
        return false;
      });
      showAllBtn.mousedown(function () {
        wasOpen = input.autocomplete("widget").is(":visible");
      });

      divInput.append(input);
      showAllBtnWrap.append(showAllBtn);

      readOnlyText.insertAfter(container);

      container.wrap(divAuto);
      divRating.insertAfter(container);
      container.wrap(divSearch);
      ratingBtnWrapp.insertAfter(container);
      container.wrap(divComboBox);
      container.append(divInput);
      container.append(showAllBtnWrap);

      if (hideRating == false && isRatingVisible) {
        ratingBtnWrapp.append(ratingButton);
        divRating.append(jqRating);
        jqRating.jqxRating({});
        divRating.hide();
        ratingButton.hide();
      }

      function displayRating(isVisible) {
        if (isRatingVisible) {
          if (isVisible && isRatingVisible) {
            ratingButton.show();
          } else {
            ratingButton.hide();
          }
          ratingButton.jqxToggleButton("unCheck");
          divRating.hide();
        }
      }

      if (disable) {
        let val = ko.unwrap(disable);
        val = typeof val == "boolean" ? val : false;

        disable = ko.isObservable(disable) ? disable : ko.observable();

        disable.subscribe(function (val) {
          handleDisable(val);
        });

        handleDisable(val);
      }

      function handleDisable(val) {
        if (unwrap(isReadOnly) == false) {
          input.prop({ disabled: val });
          showAllBtn.css({ display: val ? "none" : "inline-block" }); //.prop({'disabled': val});
          ratingBtnWrapp.css({ display: val ? "none" : "inline-block" });
        }
      }

      var isPaused = enabled != null ? !ko.toJS(enabled) : false;

      var target = observableValue;
      var modelValue = ko
        .pureComputed({
          read: target, //always return the original observables value
          write: function (newValue) {
            onModelUpdating();

            var current = target();
            var selectedValueToWrite, valueToWrite, textToWrite;

            if (useTextAsModelValueIfInvalid && typeof newValue != "object") {
              if (showAsInvalid) {
                input.css("background", "rgba(255, 0, 0, 0.2)");
              }

              selectedValueToWrite = newValue;
              valueToWrite = newValue;
              textToWrite = newValue;
            } else {
              selectedValueToWrite =
                typeof newValue === "object" ? newValue : undefined;
              valueToWrite =
                selectedValueToWrite != null
                  ? selectedValueToWrite[valueProp] != null
                    ? selectedValueToWrite[valueProp]
                    : selectedValueToWrite[inputValueProp]
                  : undefined;
              textToWrite =
                selectedValueToWrite != null
                  ? selectedValueToWrite["externalIdOverride"] ??
                    selectedValueToWrite[inputValueProp]
                  : newValue;
            }

            if (valueToWrite != null) {
              if (useTextAsModelValueIfInvalid && typeof newValue != "object") {
                displayRating(false);
              } else {
                displayRating(true);
                input.css("background", "");
              }
            } else {
              if (textToWrite != null && disable() == false && showAsInvalid) {
                input.css("background", "rgba(255, 0, 0, 0.2)");
              } else {
                input.css("background", "");
              }
              displayRating(false);
            }
            if (valueToWrite !== current) {
              isPaused = true;
              target(valueToWrite);
              selectedValue(selectedValueToWrite);
              isPaused = false;
              if (onChange != undefined && isInit == false) {
                if (valueToWrite != null) {
                  onChange(selectedValueToWrite);
                } else {
                  onChange(undefined);
                }
              }
            }

            if (selectedValueCallBackFn) {
              selectedValueCallBackFn(selectedValueToWrite);
            }

            input.val(textToWrite || "");
            readOnlyText.html(textToWrite || "");
            if (ko.isObservable(inputText)) {
              input.css("background", "");
              inputText(textToWrite);
            }
          },
        })
        .extend({
          notify: "always",
        });

      observableValue.subscribe(function (newValue) {
        if (typeof newValue == "string") {
          input.val(newValue);
          options.change();
        } else if (typeof newValue === "undefined" || newValue === null) {
          if (isPaused == false) {
            modelValue(undefined);
          }
        }
      });

      let currentSearch = null; // prevent mulitple requests for same search while one is still processing
      var query = function (searchTerm, sourceArray) {
        if (isPaused) return;

        var term = route + searchTerm;
        var params = ko.toJS(optionalParams);
        if (params != null) {
          term = term + JSON.stringify(params);
        }

        var filterResponse = function (response) {
          if (localResponseFilter) {
            response = localResponseFilter(response);
          }
          if (allowCached) {
            autoCompleteCache[term] = response;
          }
          var matchItem = searchTerm;

          var isMatch = false;

          var length = response.length;
          var result = [];

          var isCounter = false;

          for (var i = 0; i < length; i++) {
            var searchText =
              response[i][searchValue] != null
                ? response[i][searchValue]
                : response[i].externalId != null
                ? response[i].externalId
                : response[i].code;
            if (searchText === "#COUNTER#") {
              totalItemCount = response[i].id;
              isCounter = true;
            } else {
              result.push(response[i]);
              searchText = (searchText && searchText.toString()) || "";
              if (
                searchText.toUpperCase() == searchTerm.toUpperCase() ||
                (response[i][inputValueProp] != null &&
                  response[i][inputValueProp].toUpperCase() ==
                    searchTerm.toUpperCase())
              ) {
                matchItem = response[i];
                isMatch = true;
              } else if (
                response.length == 2 &&
                response[i]["description"] != null &&
                response[i]["description"] == searchTerm.toUpperCase()
              ) {
                matchItem = response[i];
                isMatch = true;
              }
            }
          }
          if (isCounter == false) {
            totalItemCount = response.length;
            numerOfRecords = totalItemCount;
          } else {
            numerOfRecords = length - 1;
          }

          if (isTextChanged) {
            modelValue(matchItem);
          }

          isTextChanged = false;
          sourceArray(result);
          isInit = false;
        };

        if (allowCached) {
          if (term in autoCompleteCache) {
            filterResponse(autoCompleteCache[term]);
            return;
          }
        }

        if (params && includeAllAgencies && includeAllAgencies() == true) {
          params.agencyUser = false;
        }

        var data = {
          searchText: searchTerm,
          numOfItems: numOfItems,
          includeAllAgencies: includeAllAgencies ? includeAllAgencies() : false,
        };

        $.extend(data, params);

        if (currentSearch == searchTerm && disable()) {
          return;
        }

        currentSearch = searchTerm;

        onRequest();

        dataModel
          .ajaxRequest(route, "get", data, agencyNotRequired)
          .done(function (response, status, xhr) {
            filterResponse(response);

            currentSearch = null;

            onResponse(response);
          })
          .fail(function (error, msg, d) {
            var g1 = "";

            currentSearch = null;

            onResponse(error);
          });
      };

      //hold the autocomplete current response
      var currentResponse = null;

      //handle the choices being updated in a DO, to decouple value updates from source (options) updates
      var mappedSource = ko.dependentObservable({
        read: function () {
          var mapped = ko.utils.arrayMap(unwrap(source), function (item) {
            var result = {};

            if (item != null) {
              result.label = labelProp
                ? unwrap(item[labelProp])
                : unwrap(item).toString(); //show in pop-up choices
              result.value = inputValueProp
                ? unwrap(item[inputValueProp])
                : unwrap(item).toString(); //show in input box
              result.actualValue = item;
            }
            return result;
          });
          return mapped;
        },
        write: function (newValue) {
          source(newValue); //update the source observableArray, so our mapped value (above) is correct
          if (currentResponse) {
            currentResponse(mappedSource());
          }
        },
        disposeWhenNodeIsRemoved: element,
      });
      //on a selection write the proper value to the model
      options.select = function (event, ui) {
        setTimeout(function () {
          showAllBtn.focus();
          input.blur();
        }, 1);
      };

      //on a change, make sure that it is a valid value or clear out the model value
      options.change = function (event, ui) {
        if (isPaused == false) {
          try {
            var currentValue = input.val().toUpperCase();
            var matchingItem = ko.utils.arrayFirst(
              unwrap(source),
              function (item) {
                if (item != null) {
                  return (
                    unwrap(
                      inputValueProp && item[inputValueProp] != null
                        ? item[inputValueProp].toUpperCase()
                        : item
                    ) === currentValue ||
                    (item[searchValue]
                      ? item[searchValue].toUpperCase() === currentValue
                      : false) ||
                    (item["externalIdOverride"]
                      ? item["externalIdOverride"].toUpperCase() ===
                        currentValue
                      : false)
                  );
                }
              }
            );
            if (matchingItem) {
              modelValue(matchingItem);
            } else {
              if (currentValue != "") {
                isTextChanged = true;

                input.autocomplete("search", currentValue);
                input.autocomplete("close");
              } else {
                modelValue(undefined);
              }
            }
          } catch (e) {
            // catch jqx errors thrown
            console.error(e);
            modelValue(currentValue);
          }
        }
      };

      options.open = function (event, ui) {
        //debugger
        $(this).autocomplete("widget").css("z-index", 999999);
        $(this).autocomplete("widget").addClass("autoCompleteItems");

        onPopupOpen();

        if (useTemplate) {
          var footerDiv = $("<div>", {
            style: " background-color:#a4bed4; width:100%;",
          });
          var btnAddTen = $("<input>", {
            type: "button",
            style: "width:100%",
            tabindex: -1,
            value: "▼ Items 1-" + numerOfRecords + " of " + totalItemCount,
          }).click(function () {
            autoCompleteCache = {};
            numOfItems = numOfItems + 10;
            var searchText = input.val();
            if (searchText == "") {
              searchText = " ";
            }
            input.autocomplete("search", searchText);
            input.focus();
          });
          footerDiv.append(btnAddTen);
          btnAddTen.jqxButton({});

          var li = $("<li tabindex='-1'>");
          li.append(footerDiv);
          var ddList = input.autocomplete("widget");
          ddList.append(li);
        }
      };

      options.close = function (event, ui) {
        onPopupClose();
        $("body").css({ "padding-bottom": 0 });
      };

      options.minLength = 1;
      options.position = {
        my: "left top",
        collision: "flipfit none",
      };
      options.response = function (event, ui) {
        if (useTemplate) {
          if (ui.content.length > 0) {
            var title = ddlHeader(route.toLowerCase());
            ui.content.unshift({
              label: title,
              value: "",
            });
          }
        }
      };

      if (virtualMode) {
        if (enabled != null && ko.isObservable(enabled)) {
          enabled.subscribe((val) => {
            isPaused = !val;
          });
        }

        options.source = function (request, response) {
          currentResponse = response;
          query.call(this, request.term, mappedSource);
        };
      } else {
        //whenever the items that make up the source are updated, make sure that autocomplete knows it
        mappedSource.subscribe(function (newValue) {
          input.autocomplete("option", "source", newValue);
        });

        options.source = mappedSource();
      }

      options.create = function (event, ui) {};

      options.delay = 1000;
      input.autocomplete(options);
      input.autocomplete("option", "appendTo", container);

      input.autocomplete("instance")._renderItem = function (ul, item) {
        if (item.value == "") {
          return $(
            '<li class="ui-state-disabled">' + item.label + "</li>"
          ).appendTo(ul);
        } else {
          var li = $("<li>", { style: "text-align:left;" });
          if (useTemplate) {
            if (
              typeof item.value == "string" &&
              input.val().toUpperCase() == item.value.toUpperCase()
            ) {
              li.append(
                "<strong>" +
                  ddlRenderTemplate(item.actualValue, route) +
                  "</strong>"
              );
            } else {
              li.append(ddlRenderTemplate(item.actualValue, route));
            }
          } else {
            li.append(item.value);
          }
          li.appendTo(ul);
          return li;
        }
      };

      if (initValue != null && forceOnChangeOnly == false) {
        input.autocomplete("option", "change").call(options.change);
      }

      if (isRatingVisible) {
        ratingButton.jqxToggleButton({});
        ratingButton.attr("tabIndex", -1);

        ratingButton.on("click", function () {
          var toggled = ratingButton.jqxToggleButton("toggled");
          if (toggled) {
            var selectedItem = unwrap(selectedValue);

            selectedItem =
              typeof selectedItem === "object"
                ? selectedItem
                : originalValue != null
                ? { rating: originalValue }
                : null;

            if (selectedItem == null) {
              var objectId = unwrap(modelValue);
              var postRatingUrlObject = {
                favTable: favTable,
                objectId: objectId,
                agencyId: userProfile.currentAgencyId(), //dataModel.agencyId
              };
              dataModel
                .ajaxRequest("Rating", "get", postRatingUrlObject)
                .done(function (data, status, xhr) {
                  originalValue = data;
                  divRating.show();
                  jqRating.jqxRating({
                    value: data,
                  });
                })
                .fail(function (error, msg, d) {});
            } else {
              divRating.show();
              jqRating.jqxRating({
                value: selectedItem.rating,
              });
            }
          } else {
            divRating.hide();
          }
          return false;
        });

        var originalValue = null;
        jqRating.bind("change", function (event) {
          // If rating has changed (i.e., not initially set from combobox selected rating).
          var objectId = 0;

          var selectedItem = unwrap(selectedValue);
          var originalItem =
            typeof selectedItem === "object" ? selectedItem : null;

          if (originalItem != null) {
            originalValue = originalItem.rating;
            objectId = originalItem.id;
          } else {
            originalValue = originalValue || 0;
            objectId = unwrap(modelValue);
          }

          if (event.value != originalValue) {
            var postRatingUrlObject = {
              favTable: favTable,
              objectId: objectId,
              value: event.value,
              agencyId: userProfile.currentAgencyId(), //dataModel.agencyId
            };

            dataModel
              .ajaxRequest("Rating", "POST", postRatingUrlObject)
              .done(function (response, status, xhr) {
                divRating.hide();

                originalValue = event.value;
                if (originalItem != null) originalItem.rating = event.value;

                autoCompleteCache = {}; // reset cache

                ratingButton.jqxToggleButton("unCheck");
                query(input.val(), source);
              })
              .fail(function (error, msg, d) {
                alert("error");
              });
          }
        });
      }

      if (ko.isObservable(isReadOnly)) {
        isReadOnly.subscribe(function (newValue) {
          if (newValue) {
            readOnlyText.show();
            readOnlyText.prev().hide();
          } else {
            readOnlyText.hide();
            readOnlyText.prev().show();
          }
        });
      }
      if (unwrap(isReadOnly)) {
        readOnlyText.prev().hide();
        readOnlyText.show();
      } else {
        readOnlyText.prev().show();
        readOnlyText.hide();
      }

      //This updates the view when we set the value of the observable programmatically
      if (updateView) {
        selectedValue.subscribe(function (x) {
          input.val(x);
        });
      }

      $(".ui-autocomplete").css("display", "none");
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.bindingHandlers.geAutocompleteForTerminal = {
    init: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {
      var observableValue = valueAccessor(),
        allBindings = allBindingsAccessor(),
        unwrap = ko.utils.unwrapObservable,
        source = allBindings.jqAutoSource || ko.observableArray(),
        selectedValue = allBindings.selectedValue || ko.observable(),
        inputText = allBindings.inputText,
        route = allBindings.route,
        onChange = allBindings.onChange,
        valueProp = allBindings.sourceValue || "id",
        inputValueProp = allBindings.sourceInputValue || "code",
        labelProp = allBindings.sourceLabel || inputValueProp,
        searchValue = allBindings.searchValue || "code",
        isReadOnly = allBindings.isReadOnly || ko.observable(false),
        allowCached =
          allBindings.allowCached == null ? true : allBindings.allowCached,
        favTable = allBindings.favTable || route, // favorite table name is same as route unless otherwise specified
        optionalParams = ko.observable(allBindings.params);

      route = route.toLowerCase();

      var viewModelAccess = bindingContext;

      var initValue = ko.unwrap(observableValue);
      if (initValue == null) {
        initValue = ko.unwrap(inputText);
      }
      var isInit = initValue != null ? true : false;

      var totalItemCount = 0;
      var numerOfRecords = 0;
      var isTextChanged = false;
      var numOfItems = 10;
      var options = { autoFocus: false, height: 200 };
      var isRatingVisible = false;

      var useTemplate = false;
      for (var tmpl in templateNames()) {
        if (templateNames()[tmpl].indexOf(route) > -1) {
          useTemplate = true;
          break;
        }
      }
      if (useTemplate) {
        var ddlColumns = ddlTemplate(route);
        for (var i = 0; i < ddlColumns.length; i++) {
          if (ddlColumns[i].name == "rating") {
            isRatingVisible = true;
            break;
          }
        }
      }

      var container = $(element);
      var divSearch = $("<div>");
      var divInput = $("<div>");
      var showAllBtnWrap = $("<div>");
      var ratingBtnWrapp = $("<div>");
      var divRating = $("<div>");
      var divComboBox = $("<div>");
      var divAuto = $("<div>");

      divAuto.addClass("geAutoComplete");
      divComboBox.addClass("geAuto-comboBox");
      container.addClass("geAuto-container");
      divInput.addClass("geAuto-search");
      showAllBtnWrap.addClass("geAuto-all");
      ratingBtnWrapp.addClass("geAuto-btn-ratting");
      divRating.addClass("geAuto-ratting");

      var input = $("<input>", {
        type: "text",
        value: initValue,
        id: "filter",
      });

      var ratingButton = $("<input>", {
        type: "button",
        style:
          "width:25px; height:25px; background-image: url('../Content/jqWidgets/images/star.png');background-repeat: no-repeat; padding-top:1px;",
      });
      var readOnlyText = $("<span>", { style: "float:left" });
      var jqRating = $("<div>", { style: "padding-top:3px; " });

      var wasOpen = false;
      var showAllBtn = $(
        '<button tabindex="-1" class="ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right ui-button-icon" role="button" ><span class="ui-button-icon-primary ui-icon ui-icon-triangle-1-s"></span><span class="ui-button-text">&nbsp;</span></button>'
      );
      showAllBtn.click(function () {
        numOfItems = 10;
        input.focus();
        if (wasOpen) {
          return false;
        }
        var searchText = input.val();

        if (searchText == "") {
          searchText = " ";
        }
        input.autocomplete("search", searchText);
        input.focus();
        return false;
      });
      showAllBtn.mousedown(function () {
        wasOpen = input.autocomplete("widget").is(":visible");
      });

      divInput.append(input);
      showAllBtnWrap.append(showAllBtn);

      readOnlyText.insertAfter(container);

      container.wrap(divAuto);
      divRating.insertAfter(container);
      container.wrap(divSearch);
      ratingBtnWrapp.insertAfter(container);
      container.wrap(divComboBox);
      container.append(divInput);
      container.append(showAllBtnWrap);

      if (isRatingVisible) {
        ratingBtnWrapp.append(ratingButton);
        divRating.append(jqRating);
        jqRating.jqxRating({});
        divRating.hide();
        ratingButton.hide();
      }

      function displayRating(isVisible) {
        if (isRatingVisible) {
          if (isVisible && isRatingVisible) {
            ratingButton.show();
          } else {
            ratingButton.hide();
          }
          ratingButton.jqxToggleButton("unCheck");
          divRating.hide();
        }
      }

      var isPaused = ko.observable(false);

      var target = observableValue;
      var modelValue = ko
        .pureComputed({
          read: target,
          write: function (newValue) {
            var current = target(),
              selectedValueToWrite =
                typeof newValue === "object" ? newValue : undefined,
              valueToWrite =
                selectedValueToWrite != null
                  ? selectedValueToWrite[valueProp] != null
                    ? selectedValueToWrite[valueProp]
                    : selectedValueToWrite[inputValueProp]
                  : undefined,
              textToWrite =
                selectedValueToWrite != null
                  ? selectedValueToWrite[inputValueProp]
                  : newValue;

            if (valueToWrite != null) {
              displayRating(true);
              input.css("background", "");
              viewModelAccess.$parents[1].isTerminalCorrectionRequired(false);
              viewModelAccess.$parents[1].terminalErrorMessage("");
            } else {
              if (textToWrite != null) {
                input.css("background", "rgba(255, 0, 0, 0.2)");
                viewModelAccess.$parents[1].isTerminalCorrectionRequired(true);
              } else {
                input.css("background", "");
                viewModelAccess.$parents[1].isTerminalCorrectionRequired(false);
                viewModelAccess.$parents[1].terminalErrorMessage("");
              }
              displayRating(false);
            }
            if (valueToWrite !== current) {
              isPaused(true);
              target(valueToWrite);
              selectedValue(selectedValueToWrite);
              isPaused(false);
              if (onChange != undefined && isInit == false) {
                if (valueToWrite != null) {
                  onChange(selectedValueToWrite);
                } else {
                  onChange(undefined);
                }
              }
            } else {
              selectedValue(selectedValueToWrite);
              if (onChange != undefined && isInit == false) {
                onChange(undefined);
              }
            }

            input.val(textToWrite || "");
            readOnlyText.html(textToWrite || "");
            if (ko.isObservable(inputText)) {
              input.css("background", "");
              inputText(textToWrite);
            }
          },
        })
        .extend({
          notify: "always",
        });

      observableValue.subscribe(function (newValue) {
        if (typeof newValue == "string") {
          input.val(newValue);
          options.change();
        } else if (typeof newValue === "undefined" || newValue === null) {
          if (isPaused() == false) {
            modelValue(undefined);
          }
        } else {
        }
      });

      var query = function (searchTerm, sourceArray) {
        var term = route + searchTerm;
        var params = ko.toJS(optionalParams);
        if (params != null) {
          term = term + JSON.stringify(params);
        }

        var filterResponse = function (response) {
          if (allowCached) {
            autoCompleteCache[term] = response;
          }
          var matchItem = searchTerm;

          var isMatch = false;

          var length = response.length;
          var result = [];

          var isCounter = false;
          for (var i = 0; i < length; i++) {
            var searchText =
              response[i][searchValue] != null
                ? response[i][searchValue]
                : response[i].externalId;
            if (searchText === "#COUNTER#") {
              totalItemCount = response[i].id;
              isCounter = true;
            } else {
              result.push(response[i]);
              if (
                searchText.toUpperCase() == searchTerm.toUpperCase() ||
                (response[i][inputValueProp] != null &&
                  response[i][inputValueProp].toUpperCase() ==
                    searchTerm.toUpperCase())
              ) {
                matchItem = response[i];
                isMatch = true;
              }
            }
          }
          if (isCounter == false) {
            totalItemCount = response.length;
            numerOfRecords = totalItemCount;
          } else {
            numerOfRecords = length - 1;
          }

          if (isTextChanged) {
            modelValue(matchItem);

            if (isMatch) {
            }
          }
          isTextChanged = false;
          sourceArray(result);
          isInit = false;
        };

        if (allowCached) {
          if (term in autoCompleteCache) {
            filterResponse(autoCompleteCache[term]);
            return;
          }
        }
        var data = {
          searchText: searchTerm,
          agencyId: userProfile.currentAgencyId(), //dataModel.agencyId,
          numOfItems: numOfItems,
        };

        $.extend(data, params);

        dataModel
          .ajaxRequest(route, "get", data)
          .done(function (response, status, xhr) {
            filterResponse(response);
          })
          .fail(function (error, msg, d) {
            var g1 = "";
          });
      };

      //on a selection write the proper value to the model
      options.select = function (event, ui) {
        setTimeout(function () {
          showAllBtn.focus();
          input.blur();
        }, 1);
      };

      //on a change, make sure that it is a valid value or clear out the model value
      options.change = function (event, ui) {
        if (isPaused() == false) {
          var currentValue = input.val().toUpperCase();
          var matchingItem = ko.utils.arrayFirst(
            unwrap(source),
            function (item) {
              if (item != null) {
                return (
                  unwrap(
                    inputValueProp ? item[inputValueProp].toUpperCase() : item
                  ) === currentValue ||
                  item[searchValue].toUpperCase() === currentValue
                );
              }
            }
          );
          if (matchingItem) {
            modelValue(matchingItem);
          } else {
            if (currentValue != "") {
              isTextChanged = true;
              input.autocomplete("search", currentValue);
              input.autocomplete("close");
            } else {
              modelValue(undefined);
            }
          }
        }
      };

      options.open = function (event, ui) {
        $(this).autocomplete("widget").css("z-index", 999999);

        if (useTemplate) {
          var footerDiv = $("<div>", {
            style: " background-color:#a4bed4; width:100%;",
          });
          var btnAddTen = $("<input>", {
            type: "button",
            style: "width:100%",
            tabindex: -1,
            value: "▼ Items 1-" + numerOfRecords + " of " + totalItemCount,
          }).click(function () {
            autoCompleteCache = {};
            numOfItems = numOfItems + 10;
            var searchText = input.val();
            if (searchText == "") {
              searchText = " ";
            }
            input.autocomplete("search", searchText);
            input.focus();
          });
          footerDiv.append(btnAddTen);
          btnAddTen.jqxButton({});

          var li = $("<li tabindex='-1'>");
          li.append(footerDiv);
          var ddList = input.autocomplete("widget");
          ddList.append(li);
        }
      };
      options.minLength = 1;
      options.position = {
        collision: "flip",
      };
      options.response = function (event, ui) {
        if (useTemplate) {
          if (ui.content.length > 0) {
            var title = ddlHeader(route.toLowerCase());
            ui.content.unshift({
              label: title,
              value: "",
            });
          }
        }
      };

      //hold the autocomplete current response
      var currentResponse = null;

      //handle the choices being updated in a DO, to decouple value updates from source (options) updates
      var mappedSource = ko.dependentObservable({
        read: function () {
          var mapped = ko.utils.arrayMap(unwrap(source), function (item) {
            var result = {};
            if (item != null) {
              result.label = labelProp
                ? unwrap(item[labelProp])
                : unwrap(item).toString(); //show in pop-up choices
              result.value = inputValueProp
                ? unwrap(item[inputValueProp])
                : unwrap(item).toString(); //show in input box
              result.actualValue = item;
            }
            return result;
            //  return item;
          });
          return mapped;
        },
        write: function (newValue) {
          source(newValue); //update the source observableArray, so our mapped value (above) is correct
          if (currentResponse) {
            currentResponse(mappedSource());
          }
        },
        disposeWhenNodeIsRemoved: element,
      });

      if (query) {
        options.source = function (request, response) {
          currentResponse = response;
          query.call(this, request.term, mappedSource);
        };
      } else {
        //whenever the items that make up the source are updated, make sure that autocomplete knows it
        mappedSource.subscribe(function (newValue) {
          input.autocomplete("option", "source", newValue);
        });

        options.source = mappedSource();
      }

      options.create = function (event, ui) {};
      input.autocomplete(options);
      input.autocomplete("instance")._renderItem = function (ul, item) {
        if (item.value == "") {
          return $(
            '<li class="ui-state-disabled">' + item.label + "</li>"
          ).appendTo(ul);
        } else {
          var li = $("<li>", { style: "text-align:left;" });
          if (useTemplate) {
            if (
              typeof item.value == "string" &&
              input.val().toUpperCase() == item.value.toUpperCase()
            ) {
              li.append(
                "<strong>" +
                  ddlRenderTemplate(item.actualValue, route) +
                  "</strong>"
              );
            } else {
              li.append(ddlRenderTemplate(item.actualValue, route));
            }
          } else {
            li.append(item.value);
          }
          li.appendTo(ul);
          return li;
        }
      };
      if (initValue != null) {
        input.autocomplete("option", "change").call(options.change);
      }

      if (isRatingVisible) {
        ratingButton.jqxToggleButton({});
        ratingButton.attr("tabIndex", -1);

        ratingButton.on("click", function () {
          var toggled = ratingButton.jqxToggleButton("toggled");
          if (toggled) {
            var selectedItem = unwrap(selectedValue);
            if (selectedItem == null) {
              var objectId = unwrap(modelValue);
              var postRatingUrlObject = {
                favTable: favTable,
                objectId: objectId,
                agencyId: userProfile.currentAgencyId(), //dataModel.agencyId
              };
              dataModel
                .ajaxRequest("Rating", "get", postRatingUrlObject)
                .done(function (data, status, xhr) {
                  divRating.show();
                  jqRating.jqxRating({
                    value: data,
                  });
                })
                .fail(function (error, msg, d) {});
            } else {
              divRating.show();
              jqRating.jqxRating({
                value: selectedItem.rating,
              });
            }
          } else {
            divRating.hide();
          }
          return false;
        });

        jqRating.bind("change", function (event) {
          var originalValue = 0;
          var objectId = 0;

          var originalItem = unwrap(selectedValue);

          if (originalItem != null) {
            originalValue = originalItem.rating;
            objectId = originalItem.id;
          } else {
            originalValue = 0;
            objectId = unwrap(modelValue);
          }

          if (event.value != originalValue) {
            var postRatingUrlObject = {
              favTable: favTable,
              objectId: objectId,
              value: event.value,
              agencyId: userProfile.currentAgencyId(), //dataModel.agencyId
            };

            dataModel
              .ajaxRequest("Rating", "POST", postRatingUrlObject)
              .done(function (response, status, xhr) {
                divRating.hide();
                originalItem.rating = event.value;
                autoCompleteCache = {}; // reset cache

                ratingButton.jqxToggleButton("unCheck");
                query(input.val(), source);
              })
              .fail(function (error, msg, d) {
                alert("error");
              });
          }
        });
      }

      if (ko.isObservable(isReadOnly)) {
        isReadOnly.subscribe(function (newValue) {
          if (newValue) {
            readOnlyText.show();
            readOnlyText.prev().hide();
          } else {
            readOnlyText.hide();
            readOnlyText.prev().show();
          }
        });
      }
      if (unwrap(isReadOnly)) {
        readOnlyText.prev().hide();
        readOnlyText.show();
      } else {
        readOnlyText.prev().show();
        readOnlyText.hide();
      }
    },
    update: function (
      element,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    ) {},
  };

  ko.validation.makeBindingHandlerValidatable("geAutocomplete");
  ko.validation.makeBindingHandlerValidatable("geAutocompleteForTerminal");
}

function clearAutoCompleteCache() {
  autoCompleteCache = {};
}

export default geAutocomplete();
export { clearAutoCompleteCache };
