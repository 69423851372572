import template from './show-message-dialog-component.html';
import ko from 'knockout';
class DialogComponent {
    constructor({message, onCloseCallBackFn = function(){} }) {
        this.message = ko.observable(message);

        $('div[aria-id="showmessagedialogmodal"]').on("hide.bs.modal", (e) => {
            onCloseCallBackFn();
        });

        this.ok = () => {
            onCloseCallBackFn();
            $('div[aria-id="showmessagedialogmodal"]').modal('hide');
        }
    }
}

export default {viewModel: DialogComponent, template: template}
