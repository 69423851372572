import dataModel from "data-model";
import template from './order-entry-delivery-receipt-component.html';
import ko from 'knockout';

class DeliveryReceiptModel {
    constructor(orderId) {
        this.isLoading = ko.observable(false);
        this.orderId = orderId;
        this.errors = ko.observableArray();
        this.responseText = ko.observable();
        this.driverEmails = ko.observableArray();
        this.reportType = ko.observable("WithoutRate");
        this.reportType.subscribe((value) => {
            if(value.value) {
                this.reportType(value.value)
            }
        })
        this.isEmail = ko.observable(false);
        this.email = ko.observable().extend({
            email: true,
            required: {
                message: 'Email is required',
                onlyIf: () => {
                    if (this.isEmail() == true) {
                        return true;
                    }
                    return false;
                }
            }
        });
        this.note = ko.observable();
        this.isFax = ko.observable(false)
        this.fax = ko.observable().extend({
            minLength: 10,
            required: {
                message: 'Fax is required',
                onlyIf: () => {
                    if (this.isFax() == true) {
                        return true;
                    }
                    return false;
                }
            }
        });
    }

    getDriverContact = () => {
        if (this.email() == null) {
            dataModel.ajaxRequest("order/GetDriverContact/" + this.orderId)
            .done((data) => {
                this.driverEmails(data);
                if (data.length > 0) {
                    this.email(data[0]);
                }
            })
        }
    }

    send = () => {
        this.errors.removeAll();
        this.responseText("");
        var validationErrors = ko.validation.group(this);
        if (validationErrors().length > 0) {
            validationErrors.showAllMessages();
            return false;
        }

        var jsData = ko.toJS(this);
        var data = {
            orderId: jsData.orderId,
            reportType: jsData.reportType,
            isEmail: jsData.isEmail,
            isFax: jsData.isFax,
            email: jsData.email,
            note: jsData.note,
            fax: jsData.fax
        };

        this.isLoading(true);
        dataModel.ajaxRequest("order/SendDeliveryReceipt", "post", data)
        .done((data) => {
            this.responseText(data);
            this.isLoading(false);
        })
        .fail((jqXHR) => {
            if (jqXHR.responseJSON != null) {
                this.errors.push(jqXHR.responseJSON.message);
            }
            else {
                this.errors.push("The document cannot be sent currently through GreatEDGE.  Please select the print option to download the document and send via other means.");
            }
            this.isLoading(false);
        });
    }

    print = () => {
        // Logging Event
        var msg = "Selected: Print Delivery Receipt - OrderId: " + this.orderId;
        dataModel.addClientSideLog(msg);
        var drReq = {
            OrderId: this.orderId,
            ReportType: this.reportType()
        }
        var orderExId = window.location.href.split('/').reverse()[0];
        this.isLoading(true);
        dataModel.downloadFile("Order/GetDeliveryReceipt", "post", drReq, orderExId + "-DeliveryReceipt.pdf")
        .then(() => {
            this.isLoading(false);
        })
        .catch(() => {
            this.errors.push("This document cannot be downloaded currently through GreatEDGE.");
        })
    }
}

export default {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            //Don't do anything.  Use the object created in the main OrderEntry page.
        }
    }, template: template
}

export { DeliveryReceiptModel }