import dataModel from 'data-model';
import {noop} from 'global-functions';
import { showmessage } from 'show-dialog-methods';
import { useDispatch } from 'ko-data-store';
import { isLoading } from '../../../dataStore/actions/appUI';

const sendCancelTenderLoadAsync = (movementId) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("OrderPlanning/CancelTenderLoad", "post", { MovementId: movementId })
        .done(() => resolve())
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || "An error occurred while processing request."));
    })
}

const sendTenderLoadAsync = ({movementId, tenderNotes}) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("OrderPlanning/TenderLoad", "post", {movementId, tenderNotes})
            .done((response) => resolve(response))
            .fail((err) => reject(err.responseJSON && err.responseJSON.message || "An error occurred while processing request."));
    })
}

export const cancelTender = function({
    movementId = undefined,
    onResolve = noop,
    onError = console.error
} ={}) {
    const cancel = async (movementId) => {
        await sendCancelTenderLoadAsync(movementId).catch(onError);
        onResolve();
    }

    cancel(movementId)
}



const tenderLoadViewModel = function({
    movementId = undefined,
    tenderNotes = undefined,
    useBtnMode = true,
    showTenderLoadBtn = ko.observable(false),
    showCancelLoadBtn = ko.observable(false),
    tenderModalData = ko.observable(),
} = {}) {
    const vm = this;
    const dispatch = useDispatch();

    movementId = ko.toJS(movementId);

    if(useBtnMode && showTenderLoadBtn() == false && showCancelLoadBtn() == false) {
        showTenderLoadBtn(true);
    }

    vm.error = ko.observable();
    vm.infoMsg = ko.observable();
    vm.showBtns = ko.observable(useBtnMode);
    vm.showTenderLoadBtn = ko.pureComputed(() => showTenderLoadBtn() && showCancelLoadBtn() == false);
    vm.showCancelLoadBtn = ko.pureComputed(() => showTenderLoadBtn() == false && showCancelLoadBtn());
  
    let onSaveCbFn = noop;

    vm.handleSaveTenderLoad = async ({movementId, tenderNotes}) => {
        try {
            dispatch(isLoading(true));
            const successMsg = await sendTenderLoadAsync({movementId, tenderNotes});
            dispatch(isLoading(false));
            
            vm.infoMsg(successMsg);
            onSaveCbFn(successMsg);

            tenderModal(null);
        }
        catch(err) {
            dispatch(isLoading(false));
            vm.error(err);
        }
    }

    // When btns are enabled
    vm.handleTenderLoad = () => vm.tenderModal({movementId, tenderNotes, 
        onSave: () => {
            showCancelLoadBtn(true);
            showTenderLoadBtn(false);
        }
    })
    vm.handleCancelLoad = () => cancelTender({movementId, 
        onResolve: () => {
            showCancelLoadBtn(false);
            showTenderLoadBtn(true);
        }
    })


    const tenderModal = ko.observable();    
    vm.tenderModal = ko.pureComputed({
        read: tenderModal,
        /* @ts-ignore */
        // {movementId, tenderNotes, onSave}
        write: (data) => {
            
            if(data) {
                if(data.movementId > 0 == false) {
                    showmessage("Missing information to tender load. If problem persists please contact HelpDesk.");
                }
                else {
                    onSaveCbFn = data.onSave || noop;
                    tenderModal({movementId: data.movementId, tenderNotes: data.tenderNotes})
                }
            }
            else {
                vm.error(null);
                vm.infoMsg(null);
                tenderModal(null);
            }
        }
    })

    tenderModalData.subscribe(({movementId, tenderNotes, onSave} = {}) => vm.tenderModal({movementId, tenderNotes, onSave}))
}

import template from './tender-load-component.html';
export default { viewModel: tenderLoadViewModel, template: template }