import ko from "knockout";
import efsAdvanceService from '../efsAdvanceServices';
import comdataService from '../comdataService';
import * as wireTypes from '../types';
import {showmessage} from '../../dialog-components/show-dialog-methods';
import {getpassword} from 'show-dialog-methods';
import {useSelector, useDispatch} from 'ko-data-store';
import {isLoading} from 'dataStore-actions/appUI';
import {clearSendWireData, getAdvanceAbility} from 'dataStore-actions/wireAdvances';

export class EfsAdvanceViewModel {
/**
 * @param {object} params - KO component params object.
 * @param {wireTypes.SendWireData} params.sendWireData
 */
    constructor({sendWireData}){
        this.dispatch = useDispatch();
        
        this.sendWireData = ko.unwrap(sendWireData); // create new variable.
        this.formData = new wireTypes.AdvanceRequestModel({}); //ko.observable();
        this.initFormData = ko.observable({}); // hold reference to initial set values later on.
        this.fetchWiresData = ko.observable({});
        this.lastViewedFormData = null; // last state changes user made before selecting an item in grid.


        this.$advanceModalRef = $('#efs-advance-modal');
        this.$advancePopupSelectionRef = $('#efs-advance-popup-selection');

        this.errorMsg = ko.observable("");
        
        this.displaySelectionModal = ko.observable(false);
        this.displayAdvanceModal = ko.observable(false);

        this.displayAdvanceModal.subscribe((val) => val && this.displaySelectionModal(false));
        this.displaySelectionModal.subscribe((val) => val && this.displayAdvanceModal(false));

        this._allowCancel = ko.observable(false);
        this.canCancelWire = ko.pureComputed({
            read: () => {
                return this._allowCancel() && this.formData.wireId() > 0;
            },  
            write: (val) => {
                if(typeof val === 'boolean') this._allowCancel(val);
            }
        });
           
        this.displayCancelConfirm = ko.observable(false);
        this.canWireAdvance = ko.pureComputed(() => this.formData.wireId() > 0 == false && this.formData.payeeId() > 0);
  
        this.actionSuccessMsg = ko.observable();
        this.actionSuccessMsg.subscribe((val) => val && val && setTimeout(() => this.actionSuccessMsg(null), 5000));

        this.resetFormElements = ko.observable();
        
        this.applyEventBindings();
        this.initAction();
    }

    initAction = async() => {

        const isValid = await this.qualifyUser();

        if(isValid) {
            if(this.sendWireData.aopLimit > 0) {
                this.displaySelectionModal(true);
            }
            else {
                this.initWireForm({type: wireTypes.ADVANCE_FUND, description: "Fund Card"}); //default is FUND
            }
        }

    }

    // The main entry form component. Need to preset a few fields depending on advancetype "FUND" or "AOP"
    initWireForm = async({type, description}) => {
        this.dispatch(isLoading(true));

        if(type == wireTypes.ADVANCE_FUND) {
            const error = await this.prequalifyFundAdvance(this.sendWireData);
            
            if(error) {
                showmessage(error);
                this.dispatch(isLoading(false));
                return false;
            }
        }
        

        this.sendWireData.advanceType(type);
        
        const initForm = { 
            ...ko.toJS(this.sendWireData),
            advanceType: this.sendWireData.advanceType(),
            description: description,
            vendor: type == wireTypes.ADVANCE_EXPRESS ? wireTypes.VENDOR_EFS : null,
            wireCode: type == wireTypes.ADVANCE_EXPRESS ? wireTypes.AOP_EXPRESS : null,
            maxAllowed: type != wireTypes.ADVANCE_FUND ? this.sendWireData.aopLimit : 0
        }
       
        this.initFormData(initForm);

        await this.updateForm(initForm);
        this.dispatch(isLoading(false));

        this.displayAdvanceModal(true);
    }

    applyEventBindings = () => {
        this.$advanceModalRef.on("hide.bs.modal", (e) => {
            this.dispose();
        });

        this.$advancePopupSelectionRef.on("hide.bs.modal", (e) => {
            // User cancelled selection -- closed modal.
            
            if(!this.sendWireData.advanceType()) {
                this.dispose();
            }
        });
    }
    
    handleClearForm = async (clearAll, dataToKeep = {}) => {
        this.dispatch(isLoading(true));
        this.displayErrorMsg("");

        this.formData.amount(null); // need to set to null to remove jqx widget '-' negative value.
        this.formData.driverId(null);
        this.formData.payeeId(null);
        this.formData.orderId(null);
        this.formData.wireId(null);

        const { aopLimit, fundingLimit } = this.sendWireData;
        const advanceType = this.sendWireData.advanceType();

        if(clearAll) {
            let advLimit = advanceType != wireTypes.ADVANCE_FUND ? aopLimit : 0;
            let data = { fundingLimit, aopLimit, advanceType, advLimit, ...dataToKeep }

            this.resetFormElements({});

            await this.updateForm(new wireTypes.AdvanceRequestModel(data).toJS(), true);
            
            this.displayErrorMsg(data.errorMsg);

            if(Object.keys(dataToKeep).length) {
                this.fetchWires({});
            }
            else {
                this.fetchWires({...dataToKeep, forceRefresh: true});
            }
            
        }
        else {
            let data = {...ko.toJS(this.initFormData), advLimit: this.initFormData().maxAllowed, ...dataToKeep };
            this.resetFormElements({payeeFullName: data.payeeFullName, keepCards: true });

            if(advanceType == wireTypes.ADVANCE_FUND) {
                let limits = await this.getFundAmountsAndLimits();
                data = {...data, ...limits, advLimit: limits.advAmount};

                if(limits.available <= 0) {
                    this.displayErrorMsg(`The selected order has reached or exceeded amount available to advance. Total Advanced: $${limits.currentTotal}. Available: ${limits.available}`);
                    data.wireId = "Invalid Order";
                }
            }

            await this.updateForm(new wireTypes.AdvanceRequestModel(data).toJS());

            this.displayErrorMsg(data.errorMsg);

            this.fetchWires(this.formData.toJS());
        }

        
        this.dispatch(isLoading(false));
    }

    prequalifyFundAdvance = async ({orderId, orderExId}) => {
        this.dispatch(isLoading(true));
        const fundAmounts = await efsAdvanceService.getFundAmounts(orderId);
        this.dispatch(isLoading(false));

        if (["Trip","Limit"].indexOf(fundAmounts.cardType) != -1 && fundAmounts.available <= 0) {
            return `This card is a limit based card and is not able to be funded. If more funds are needed for fuel please 
                        contact <a style="color: blue" href="mailto:card.services@greatwide-tm.com">card.services@greatwide-tm.com</a>`;
        }

        return null;
    }

    qualifyUser = async() => {
        this.dispatch(isLoading(true));
        const result = await efsAdvanceService.qualifyUser(this.formData.toJS());
        this.dispatch(isLoading(false));

        if(result.isSuccess() == false) {
            this.displayErrorMsg(result.errors[0]);
            return false;
        }

        return true;
    }

    getFundAmountsAndLimits = async(orderId) => {
        
        this.dispatch(isLoading(true));
        const fundAmounts = await efsAdvanceService.getFundAmounts(this.sendWireData.orderId);
        this.dispatch(isLoading(false));

        let limits = {description: `Advance of ${fundAmounts.maxAdvPerc}% of order.`, maxAllowed: fundAmounts.available, advAmount: fundAmounts.available, amount: fundAmounts.available };
        if(fundAmounts.available <= 0 && this.formData.wireId() > 0 == false) {
            this.displayErrorMsg(`The selected order has reached or exceeded amount available to advance. Total Advanced: $${fundAmounts.currentTotal}. Available: ${fundAmounts.available}`);
            limits.wireId = "Invalid Order";
        }
        
        this.initFormData({...this.initFormData(), ...limits});

        return limits;
    }

    // Single source for formState updates.
    // You can see the changes to formdata / state here.
    updateForm = async (updateDataObj, forceUpdate = false) => {
        this.dispatch(isLoading(true));
        
        let current = this.formData.toJS();
        let entries = {...ko.toJS(updateDataObj) };

        Object.keys(entries).forEach((key) => {
            if(ko.isWritableObservable(this.formData[key]) 
                && (this.formData[key]() != entries[key] || forceUpdate))
            {
                this.formData[key](entries[key]);
            }
        });
        
        this.dispatch(isLoading(false));

        return this.formData.toJS();
        
    }

    fetchWires = ({
        orderId = this.formData.orderId(), 
        payeeId = this.formData.payeeId(), 
        forceClear = !orderId && !payeeId,
        forceRefresh = false
    } = {}) => {
        let advanceType = this.sendWireData.advanceType();
        let wireId = this.formData.wireId();

        this.fetchWiresData({orderId, payeeId, advanceType, wireId, forceRefresh, forceClear});
    }

    handleAdvanceTypeSelected = (type) => this.initWireForm(type);

    handleOnGridSelect = async(data) => {

        if(data) {

            if(this.lastViewedFormData == null) {
                this.lastViewedFormData = this.formData.toJS();
            }
            
            this.displayErrorMsg("");

            this.formData.amount(null); // need to set to null to remove jqx widget '-' negative value.
            this.formData.wireId(data.id); // setting here to trigger readonly mode..

            // remapping a few grid props to our form props
            data.amount = data.checkAmount || 0;
            data.wireId = data.id;
            data.entryDate = data.transactionDate;
            data.wireCode = data.wireCode.trim();
            data.vendor = data.wireCode == wireTypes.FUND_COMDATA || data.wireCode == wireTypes.AOP_COMDATA ? wireTypes.VENDOR_COMDATA : wireTypes.VENDOR_EFS;
            data.orderExId = data.order;
            
            data.advanceType = wireTypes.getAdvanceTypeFromWireCode(data.wireCode) || this.sendWireData.advanceType();
            this.canCancelWire(data.hasPosted == false && data.hasPendingCancellation == false);

            if(data.hasPendingCancellation && data.amount > 0.00 && data.hasPosted == false) { // don't display message on the cancellation wire. -(amount)
                this.displayErrorMsg("Notice: Advance has a pending cancellation.");
            }
            
            await this.updateForm(data);
            this.resetFormElements({payeeFullName: data.payeeName});
        }
    }

    handleOnResetSelection = () => {
        if(this.sendWireData.advanceType() == "FUND") {
            const { orderId, orderExId } = this.formData.toJS();

            // Viewing items on different order, so just clear selection and reset to allow 
            // order ddl entry, else reset to initForm data
            if(orderExId != this.initFormData().orderExId) {
                this.handleClearForm(true, {orderExId, orderId, wireId: "Invalid Order"});
            }
            else {
                this.handleClearForm();
            }

            this.lastViewedFormData = null;
        }
        else {
            let lastData = this.lastViewedFormData || {};

            this.handleClearForm(true, {...lastData });
            this.lastViewedFormData = null;
        }
        
    }

    handleOnAdd = async() => {
        this.errorMsg("");
        
        // KO validation
        if(this.formData.isValid() == false) {
            this.errorMsg("Please correct any input errors.");
            return false;
        }

        if(!this.formData.amount()) {
            this.errorMsg("Amount entered must be greater than 0.");
            return false;
        }

        if(!this.formData.payeeId()) {
            this.errorMsg("Please select a valid payee.");
            return false;
        }

        if(!this.formData.wireCode() || !this.formData.vendor()) {
            let msg = this.sendWireData.advanceType() == wireTypes.ADVANCE_WIRE ? "Please search and select a payee/driver fuel card." : "An error occurred. Refresh the advance form and try again.";
            this.errorMsg(msg);
            return false;
        }

        const password = await getpassword("Please enter your wire password.", null, "Send Wire").catch((err) => { console.log(err); return null; });
        if(!password) return false;

        this.dispatch(isLoading(true));

        const formData = { ...this.formData.toJS(), password };
        // remap a few prop names to match api
        formData.amountRequested = formData.amount;

        if(formData.wireCode == wireTypes.AOP_EXPRESS) {
            await this.requestExpressCheckCode(formData);
        }
        else {
            await this.processWire(formData);
        }
    }

    processWire = async(formDataJSON) => {
        
        const result = formDataJSON.vendor == wireTypes.VENDOR_COMDATA ? 
            await comdataService.addWireToOrder(formDataJSON) :
            await efsAdvanceService.processWire(formDataJSON);
        

        this.dispatch(isLoading(false));

        if(result.errors.length) {
            this.errorMsg(result.errors[0])
            return false;
        }

        this.actionSuccessMsg("Wire sent successfully.");
        this.canCancelWire(true);

        await this.updateForm(result.data);
        this.fetchWires({...formDataJSON, forceRefresh: true});

        // Update the init formdata limits, etc for fund
        if(this.sendWireData.advanceType() == wireTypes.ADVANCE_FUND) {
            const limits = await this.getFundAmountsAndLimits();

            this.initFormData({...this.initFormData(), ...limits});
        }

        if(this.sendWireData.onAddWire) {
            this.sendWireData.onAddWire(this.formData.toJS());
        }
    }

    requestExpressCheckCode = async(formDataJSON) => {
        var result = await efsAdvanceService.requestExpressCheckCode(formDataJSON);
        this.dispatch(isLoading(false));

        if(result.isSuccess() == false) {
            this.errorMsg(result.errors[0]);
            return false;
        }
        
        this.actionSuccessMsg("Wire sent successfully.");
        this.canCancelWire(true);
        
        await this.updateForm(result.data);
        this.fetchWires({...formDataJSON, forceRefresh: true});

        if(this.sendWireData.onAddWire) {
            this.sendWireData.onAddWire(this.formData.toJS());
        }
    }


    handleOnCancelWire = () => {
        this.displayCancelConfirm(true);
        this.errorMsg("Are you sure you want to cancel?");
    }

    handleOnCancelConfirm = async(confirmedVal) => {
        this.errorMsg("");
        this.displayCancelConfirm(false);

        if(confirmedVal) {
            
            const password = await getpassword("Please enter your wire password.", null, "Cancel Wire").catch((err) => { console.log(err); return null; });
            if(!password) return false;


            this.dispatch(isLoading(true));

            const formData = {...this.formData, password};

            const result = this.formData.vendor() === wireTypes.VENDOR_COMDATA ? 
                await comdataService.cancelWire(formData) 
                : this.formData.advanceType() == wireTypes.ADVANCE_EXPRESS ? await efsAdvanceService.cancelExpressCode(formData)
                : await efsAdvanceService.cancelWire(formData);

            this.dispatch(isLoading(false));
            
            this.fetchWires({...formData, forceRefresh: true});

            if(result && result.errors.length) {
                this.errorMsg(result.errors[0]);
            }
            else {
                // no errors -> cancel was success so prevent cancel btn again.
                this.canCancelWire(false);
                this.actionSuccessMsg("Wire cancelled successfully.");
                
                if(this.sendWireData.onCancelWire) {
                    const amount = this.formData.amount();
                    this.sendWireData.onCancelWire({...this.formData.toJS(), amount: -(amount)});
                }
            }
        }

    }

    displayErrorMsg = (message) => {
        let vm = this;
        this.errorMsg("");

        if(!this.displayAdvanceModal() && !this.displaySelectionModal()) {
            showmessage(message, null, vm.dispose);
        }
        else if(this.displayAdvanceModal()) {
            this.errorMsg(message);
        }
        else {
            console.error(message);
        }
    }

    dispose = () => {
        if(this.sendWireData) this.sendWireData.onClose();
        $('.modal-backdrop.fade.in').remove();
        $('body').removeClass('modal-open');

        this.formData = new wireTypes.AdvanceRequestModel({});
        this.sendWireData = new wireTypes.SendWireData({});
        this.displaySelectionModal(false);
        this.displayAdvanceModal(false);

        this.dispatch(clearSendWireData());
    }
}



import template from './efs-advance-modal-component.html';
export default { viewModel: EfsAdvanceViewModel, template}