import GreatEdgeCustomWidgets from 'ge.custom-widgets';
import dataModel from 'data-model';
import {getDateTimestamp} from 'global-functions';

import userProfile from 'user-profile';

var agentSelectListWidget = function (elementId, mainModel) {
    var self = this;
    var elementId = elementId || "";
    var jqxElement = $('#'+elementId);
    var selectAllLabel = '(Select All)';
    var parent = mainModel || {};

    parent.isLoading(true);

    var setSelectionRenderer = function () {
      
        var dropDownContent = "";
        var items = jqxElement.jqxDropDownList('getCheckedItems');
        if (items.length > 0) {

            for (var i = 0; i < items.length; i++) {
                if (items[i].label != selectAllLabel) {
                    dropDownContent += items[i].label + ",";
                }

            }

            //dropDownContent = dropDownContent.slice(0, -1);
        }
        else {
            dropDownContent = "Select Agency";
        }

        dropDownContent = '<div style="padding: 3px; max-width: 200; white-space: nowrap;overflow: hidden; text-overflow: ellipsis; ">' + dropDownContent + '</div>';
        return dropDownContent;
    }
    
    var source =
    {
        
        datatype: "json",
        url: "User/GetUserAgencies",
        datafields: [
            { name: 'id' },
            { name: 'code' }
        ],
        formatdata: function (data) {
            data.includeInactive = true;
            data.NumOfItems = 2000;
            data.AgencyId = userProfile.currentAgencyId();
            return data;
        },
        beforeLoadComplete: function (data) {
            data.unshift({ code: selectAllLabel, id: 'selectAll' });
            return data;
        },
        loadComplete: function (data) {
            jqxElement.jqxDropDownList('checkItem', userProfile.agencyExternalId); 
            parent.isLoading(false);
        },
        loadError: function (error) {
            console.error(error);
            parent.isLoading(false);
        },
    };

    var dataAdapter = dataModel.getDataAdapter(source);
    
    jqxElement.jqxDropDownList({
        checkboxes: true,
        //placeHolder: 'Select Agency',
        source: dataAdapter,
        displayMember: "code",
        valueMember: "code",
        width: 200,
        height: 25,
        popupZIndex: 1000,
        autoDropDownHeight: false,
        selectionRenderer: function () {
            return setSelectionRenderer();
        }
    });
           
    
    jqxElement.on('select', function (event) {
        var args = event.args;
        if (args) {
           
            var item = args.item;
            var label = item.label;
            
            if (label == selectAllLabel) {
                var checkType = item.checked ? 'checkAll' : 'uncheckAll';

                parent.isLoading(true);

                setTimeout(function () {
                    jqxElement.jqxDropDownList(checkType);
                    parent.isLoading(false);
                }, 50);

            }
            else {

                var items = jqxElement.jqxDropDownList('getItems');
                var checkedItems = jqxElement.jqxDropDownList('getCheckedItems');

                if (checkedItems.length == 1) {
                    jqxElement.jqxDropDownList('uncheckIndex', 0);
                }
                else if (items.length != checkedItems.length) {
                    jqxElement.jqxDropDownList('indeterminateIndex', 0);
                }

                
            }
        }
    });

    // Returns array of values from checked items
    self.getCheckedItems = function () {
        var items = jqxElement.jqxDropDownList('getCheckedItems');
        var tmpItems = [];

        if (items.length > 0) {
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                var label = item.label.toUpperCase();
                var value = item.value;

                // Make sure we are not including the "Group Label" options here
                if (label != selectAllLabel.toUpperCase()) {
                    tmpItems.push(value);
                }
            }
        }

        return tmpItems;
    }

}

class AgentSettlementsViewModel {
    constructor() {
        var self = this;
        self.isLoading = ko.observable(false);
       
        var today = new Date();

        var GECustomWidgets = new GreatEdgeCustomWidgets();
        var dateRangePicker = new GECustomWidgets.GEDateRangePickerWidget({
            bindToElement: 'geDateRangePicker',
            initialFromDate: today.addDays(-7),
            initialToDate: today.addDays(1),
            minAllowedYear: 1990
        });

        self.orderNumber = ko.observable();
        
        self.error360Msg = ko.observable();
        self.settlementGridHasResults = ko.observable(false);
        self.gridResultsAgencyExIds = ko.observableArray();

        self.agentSelectList = new agentSelectListWidget('jqxAgencySelectList', self);

        self.generateListing = function () {
            self.gridResultsAgencyExIds(null);
            self.settlementGridHasResults(false);
            self.error360Msg("");
            var validationErrors = ko.validation.group(self);

            if (validationErrors().length > 0)
                return false;

            var agencyExIds = self.agentSelectList.getCheckedItems();
            if (agencyExIds.length == 0) { self.error360Msg("Please select at least one agency from the list."); return; }

            $('#availableSettlements').jqxGrid('clear');

            var datesObj = dateRangePicker.getDates();

            if (datesObj == null || (datesObj.from == "Invalid date" || datesObj.to == "Invalid date")) {
                self.error360Msg("Please select a date range to search by.");
                return;
            }
            
            var data = {
                beginDate: datesObj.from,
                endDate: datesObj.to,
                orderNumber: self.orderNumber(),
                agencyExIds: agencyExIds
            };
            
            var grid = $('#availableSettlements');

            self.isLoading(true);

            dataModel.ajaxRequest('Settlement/GetAvailableAgencySettlementsForDateRange', 'POST', data).done(function (data) {
               
                if (data.agencySecurables.length > 0)
                    self.settlementGridHasResults(true);

                if (data.resultsAgencyExIds.length > 0)
                    self.gridResultsAgencyExIds(data.resultsAgencyExIds);

                var source = {
                    localdata: data.agencySecurables,
                    datatype: 'array',
                    datafields: [
                        { name: 'imageName', type: 'string' },
                        { name: 'payDate', type: 'date' },
                        { name: 'settlementPay', type: 'float' },
                        { name: 'payeeID', type: 'string' }
                    ]
                };

                var dataAdapter = new $.jqx.dataAdapter(source, {
                    loadComplete: function (data) {
                        self.isLoading(false);
                    }
                });

                $('#availableSettlements').jqxGrid({
                    source: dataAdapter,
                    sortable: true,
                    pageable: true,
                    autoheight: true,
                    altrows: true,
                    columnsresize: true,
                    width: '100%',
                    showtoolbar: true,
                    toolbarheight: 35,
                    rendertoolbar: function (toolbar) {
                        var tmpl1 = $('#gridToolBar');
                        
                        $(toolbar).append(tmpl1);
                        tmpl1.show();

                        //ko.applyBindingsToDescendants(tmpl1, toolbar[0]);
                    },
                    columns: [
                        { text: '', datafield: 'imageName', hidden: true },
                        {
                            text: '',
                            width: 50,
                            filterable: false,
                            sortable: false,
                            cellsalign: 'center',
                            columnType: 'button',
                            buttonClick: function (row) {
                                self.isLoading(true);
                                
                                var datarow = grid.jqxGrid('getrowdata', row);
                                var date = (datarow.payDate.getMonth() + 1) + '/' + datarow.payDate.getDate() + '/' + datarow.payDate.getFullYear();
                                var data = { agencyId: userProfile.currentAgencyId(), requestDate: date, imageName: datarow.imageName, payeeID: datarow.payeeID };

                                dataModel.ajaxRequest('Settlement/GetSettlementDocument', 'GET', data).done(function (data, status, xhr) {
                                    var filename = data.payeeID.trim() + '-' + date.replace(/\//g, '') + '.pdf';
                                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                        var newBlob = $.b64toBlob(data.pdfBytes, 'application/pdf', 512);
                                        window.navigator.msSaveOrOpenBlob(newBlob, filename);
                                    } else {
                                        var a = document.createElement('a');
                                        var pdfAsDataUri = 'data:application/pdf;base64,' + data.pdfBytes;
                                        a.download = data.payeeID + ' - ' + date.replace("/", "");
                                        a.type = 'application/pdf';
                                        a.href = pdfAsDataUri;
                                        document.body.appendChild(a);
                                        a.click();
                                    }
                                    self.isLoading(false);
                                }).fail(function (jqXHR, textStatus, errorThrown) {
                                    if (jqXHR.responseJSON != null) {
                                        var json = jqXHR.responseJSON;
                                        if (json.message != null) {
                                            var errors = json.message;
                                            alert(errors);
                                        }
                                    }
                                    self.isLoading(false);
                                });
                            },
                            cellsrenderer: function () {
                                return 'View';
                            }
                        },
                        { text: 'Pay Date', datafield: 'payDate', width: 100, cellsFormat: 'MM/dd/yyyy', type: 'date', sortable: true, cellsalign: 'right', align: 'right' },
                        { text: 'Settlement Pay', datafield: 'settlementPay', width: 125, cellsformat: 'C2', type: 'float', sortable: true, cellsalign: 'right', align: 'right' },
                        { text: 'Payee', datafield: 'payeeID', type: 'string', sortable: true, cellsalign: 'center', align: 'center' }
                    ]
                });

            }).fail(function (jqXHR, textStatus, errorThrown) {
                self.isLoading(false);
                $('#availableSettlements').empty().append($('<h4>').text('An error was encountered. Try again or contact your administrator.'));
            });
        };

        self.generate360Report = function () {
            self.error360Msg("");

            var agencyExIds = self.agentSelectList.getCheckedItems();
            if (agencyExIds.length == 0) { self.error360Msg("Please select at least one agency from the list."); return; }
            
            self.isLoading(true);
            var dataParams = {
                agencyExIds: agencyExIds,
                getBasicReport: true // GE-732 - Remove this, set to false, or comment out to get full report
            }

            var timestamp = getDateTimestamp();
            var saveAsFilename = "Agent360SettlementReport(" + timestamp + ").xlsx";
            dataModel.downloadFile("Settlement/GetAgency360Report", "POST", dataParams, saveAsFilename).then(function (x) {
                self.isLoading(false);
            }).catch(function (error) {
                self.isLoading(false);
                console.error(error);
            });
        }

        self.downloadExcelGridResults = function () {
            self.error360Msg("");

            var agencyExIds = self.gridResultsAgencyExIds();

            // Check if we had results in grid by the agencyExIds, if not then just return
            if (agencyExIds.length == 0) { return; }

            var datesObj = dateRangePicker.getDates();

            self.isLoading(true);
            var dataParams = {
                agencyExIds: agencyExIds,
                //StartDate: beginningDate,
                //EndDate: endingDate
                StartDate: datesObj.from,
                EndDate: datesObj.to,
            }

            var timestamp = getDateTimestamp();
            var saveAsFilename = "AgentSettlementSummaryReport(" + timestamp + ").xlsx";
            dataModel.downloadFile("Settlement/GetAgencySummaryReport", "POST", dataParams, saveAsFilename).then(function (x) {
                self.isLoading(false);
            }).catch(function (error) {
                console.error(error);
                self.isLoading(false);
            }).then(function (x) {
                self.isLoading(false);
            });
        }
    }
}

import template from './agent-settlements-page.html';
export default { viewModel: AgentSettlementsViewModel, template: template }