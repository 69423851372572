import dataModel from "data-model";
import userProfile from "user-profile";
import gridState from "jqx.grid-state-component";

class PriorityAlertsViewModel {
  constructor() {
    var self = this;

    self.isLoading = ko.observable(false);
    self.isLoading(false);

    self.expire = 24;
    self.expire = ko.observable(true);
    self.alertAddWindow = ko.observable();

    self.cancelChanges = function () {
      self.alertAddWindow(undefined);
    };

    self.editAlert = function (curAlertId) {
      self.isLoading(true);

      var route = "Alerts/GetPriorityAlert/" + curAlertId;
      var data = null;
      dataModel
        .ajaxRequest(route, "GET", data)
        .done(function (data, status, xhr) {
          data.onAlertSave = function () {
            self.alertAddWindow(undefined);
            $("#jqxPriorityAlertsGrid").jqxGrid("updatebounddata");
          };

          self.alertAddWindow(data);
          self.isLoading(false);
        })
        .fail(function (error, msg, d) {
          self.isLoading(false);
        });
    };

    self.deleteAlert = function (curAlertId) {
      var data = null;

      dataModel
        .ajaxRequest("Alerts/DeletePriorityAlert/" + curAlertId, "POST", data)
        .done(function (data, status, xhr) {
          $("#jqxPriorityAlertsGrid").jqxGrid("updatebounddata");
          self.isLoading(false);
        })
        .fail(function (error, msg, d) {
          self.isLoading(false);
        });
    };

    var loadAlerts = function () {
      var source = {
        url: "Alerts/GetPriorityAlertsForGrid",

        datatype: "json",
        sortcolumn: "postDate",
        sortdirection: "desc",
        data: {
          agencyId: userProfile.currentAgencyId(),
        },
        sort: function (column, sortOrder) {
          $("#jqxPriorityAlertsGrid").jqxGrid("updatebounddata", "sort");
        },
        beforeprocessing: function (data) {
          //console.log(JSON.stringify(data));
          source.totalrecords = data.totalrecords;
        },

        datafields: [
          { name: "id", type: "int" },
          { name: "postDate", type: "date" },
          { name: "subject", type: "string" },
          { name: "messageBody", type: "string" },
          { name: "userName", type: "string" },
        ],
      };

      var dataAdapter = dataModel.getDataAdapter(source);

      $("#jqxPriorityAlertsGrid").jqxGrid({
        width: "100%",
        source: dataAdapter,
        selectionmode: "single",
        sortable: true,
        pageable: true,
        filterable: false,
        showfilterrow: false,
        autoheight: true,
        virtualmode: true,
        columnsmenu: false,
        showtoolbar: true,
        rendertoolbar: function (toolbar) {
          var vm1 = new gridState.viewModel(),
            tmpl1 = gridState.template;

          vm1.actions = ["Refresh", "Show/Hide Columns"];

          vm1.addNewAlert = function () {
            self.alertAddWindow({
              onAlertSave: function () {
                self.alertAddWindow(undefined);
                $("#jqxPriorityAlertsGrid").jqxGrid("updatebounddata");
              },
            });
          };

          $(toolbar).append(
            "<div style='display:block;float:left;'>" +
              tmpl1 +
              '</div><div style=\'display:block;float:left; padding-left:10px;\'><input type="button" value="Add New Priority Alert" style="height:30px" data-bind="jqxButton: {},click: addNewAlert " /></div>'
          );
          ko.applyBindingsToDescendants(vm1, toolbar[0]);
        },
        rendergridrows: function (obj) {
          return obj.data;
        },

        columnsresize: true,
        columns: [
          {
            text: "",
            width: 60,
            filterable: false,
            sortable: false,
            columnType: "button",
            buttonclick: function (row) {
              self.isLoading(true);
              var datarow = $("#jqxPriorityAlertsGrid").jqxGrid(
                "getrowdata",
                row
              );
              self.editAlert(datarow.id);
            },
            cellsrenderer: function () {
              return "Edit";
            },
          },
          {
            text: "Subject",
            dataField: "subject",
            width: 180,
            filterable: false,
          },
          { text: "Message", dataField: "messageBody", filterable: false },
          {
            text: "",
            width: 60,
            filterable: false,
            sortable: false,
            columnType: "button",
            buttonclick: function (row) {
              self.isLoading(true);
              var datarow = $("#jqxPriorityAlertsGrid").jqxGrid(
                "getrowdata",
                row
              );
              self.editAlert(datarow.id);
            },
            cellsrenderer: function () {
              return "Expand";
            },
          },

          {
            text: "Post Date",
            dataField: "postDate",
            width: 150,
            cellsformat: "M/d/yyyy h:mm tt",
            filterable: false,
          },
          {
            text: "Posted By",
            dataField: "userName",
            width: 100,
            filterable: false,
          },
          {
            text: "",
            width: 60,
            filterable: false,
            sortable: false,
            columnType: "button",
            buttonclick: function (row) {
              self.isLoading(true);
              var datarow = $("#jqxPriorityAlertsGrid").jqxGrid(
                "getrowdata",
                row
              );
              self.deleteAlert(datarow.id);
            },
            cellsrenderer: function () {
              return "Delete";

              //return "<span class='glyphicon glyphicon-remove-circle'></span>";
            },
          },
        ],
      });
    };

    loadAlerts();
  }
}

import template from "./priority-alerts-page.html";
export default { viewModel: PriorityAlertsViewModel, template: template };
