import dataModel from 'data-model';
import gridStateModel from "jqx.grid-state-component";
import gridUtils from "jqx.grid-utils";
import { mapGridDate, formatPhoneNumber, isEmpty, cleanString, datetimeUTC, groupBy } from 'global-functions';
import userProfile from 'user-profile';
import { GreatEdgeCustomWidgets, createGeDateRangeFilterWidget } from 'ge.custom-widgets';
import {useDispatch, useSelector } from 'ko-data-store';
import { getUserFilters, addUserFilters } from '../../../../../dataStore/actions/userFilters';
import { isLoading } from 'dataStore-actions/appUI';
import { addLaneRate, editLaneRate } from 'dataStore-actions/rates';
import useKoElementAsFilter from 'ge-widgets/useKoElementAsFilter';
import * as ratecommon from '../../ratesCommon'
import gridStateUtils from "jqx.grid-utils";

const extraActionHandlers = {
    addRate: (onAddFn) => {
        return {
            action: "Add Rate",
            handler: onAddFn
        }
    },
    export: (rateNumber, getDataFn, handlerFn) => {
        return {
            action: "Export",
            handler: () => handlerFn(rateNumber, getDataFn())
        }
    },
    uploadNewRates: (handlerFn) => {
        return {
            action: "Upload New Rates",
            handler: () => handlerFn()
        }
    },
    updateExistingRates: (handlerFn) => {
        return {
            action: "Update Existing Rates",
            handler: () => handlerFn()
        }
    }
}

const mapOrgDestCodes = (val) => {
    switch(val) {
        case "L":
            return "Location";
        case "C":
            return "City / State";
        case "S":
            return "State";
        case "Z":
            return "Zip";
        case "N":
            return "County";
        default:
            return "";
    }
}

export const lanesRatesViewModel = function({
    rateNumber = ko.observable()
} = {}) {
    const vm = this;
    const dispatch = useDispatch();

    const gridId = "laneRateGrid";
    const $grid = $('#'+gridId);
    
    rateNumber.subscribe((val) => {
        if(val) $grid.jqxGrid('updatebounddata', 'data');
    });

    // Used to trigger the upload modal
    vm.uploadRatesModal = ko.observable();
    vm.handleBatchUploadSave = () => {        
        $grid.jqxGrid('updatebounddata', 'data');
        vm.uploadRatesModal(null);
    }
    
    const buildGrid =  ($gridEl, source = {}, columns = [], userGridState = {}) => {
        const dataAdapter = dataModel.getDataAdapter(source);

        $gridEl.jqxGrid({
            theme: 'GWTMDark',
            width: "100%",
            source: dataAdapter,
            altrows: true,
            sortable: true,
            pageable: true,
            pagesize: 20,
            columnsreorder: true,
            columnsmenu: false,
            columnsresize: true,
            enablebrowserselection: true,
            height: 450,
            autoheight: false,
            filterable: true,
            showfilterrow: true,
            showtoolbar: true,
            columns: columns,
            virtualmode: false,
            filter: (cellValue, rowData, dataField, filterGroup, defaultFilterResult) => {
                if (filterGroup == null && cellValue == null) return defaultFilterResult;

                const filters = filterGroup.getfilters();
                const validValues = ["Any", "Please Choose:"];
                let result = filters.some(x => validValues.indexOf(x.value) != -1) || defaultFilterResult;
                

                return result;
            },
            rendertoolbar: (toolbar) => {
                const $toolbar = $(toolbar);
                const gridStateVM = new gridStateModel.viewModel();
                gridStateVM.pageName = "RatesWindow-Lanes";

                const actions = [...gridStateVM.actions, "Add Rate", "Export", "Upload New Rates", "Update Existing Rates"];
                gridStateVM.actions = actions;
                gridStateVM.extraActionHandlers = [
                    extraActionHandlers.addRate(handleAddLaneRate),
                    extraActionHandlers.export(rateNumber(), () => gridStateUtils.formatGridHeaders(gridId, false), handleExport),
                    extraActionHandlers.uploadNewRates(() => vm.uploadRatesModal({rateNumber: rateNumber(), editMode: false})),
                    extraActionHandlers.updateExistingRates(() => vm.uploadRatesModal({rateNumber: rateNumber(), editMode: true})),
                ];

                const $template = $(gridStateModel.template);
                
                $toolbar.append($template);
                ko.applyBindingsToDescendants(gridStateVM, $toolbar[0]);

            },
            ready: () => {
                
                gridUtils.applyGridState($gridEl.attr('id'), userGridState.grid);
            }, 
            rendergridrows: (obj) => {
                return obj.data;
            },
        });

        return $grid;
    }

    const gridSource = () => {
        return {
            url: "Rates/Lanes",
            type: "GET",
            datafields: [
                { name: "id", type: "int" },
                { name: "rateId", type: "int" },
                { name: "rateNumber", type: "int" },
                { name: "description", type: "string" },
                { name: "orgCode", type: "string" },
                { name: "origin", type: "string" },
                { name: "destCode", type: "string" },
                { name: "destination", type: "string" },
                { name: "method", type: "string" },
                { name: "minCharge", type: "string" },
                { name: "rate", type: "string" },
                { name: "distance", type: "string" },
                { name: "pointToPoint", type: "string" },
                { name: "unitOfMeasure", type: "string" },
                { name: "rateTableId", type: "string" }
            ],
            datatype: "json",
            formatdata: () => {
                const data = {};
                data.agencyId = userProfile.currentAgencyId;
                data.rateNumber = rateNumber();
                return data;
            },
            beforeLoadComplete: (x, recordData = []) => {
                recordData.map(x => {
                    x.destCode = mapOrgDestCodes(x.destCode);
                    x.orgCode = mapOrgDestCodes(x.orgCode);
                    x.method = ratecommon.mapMethodCodes(x.method);
                })
            }
        };
    }

    const gridColumns = ($gridEl, gridName = "", initGridState = {}) => {
        return [
            { text: "", hidden: false, pinned: true, width: '80', menu: false, resizable: false, hideable: false, showheader: false, filterable: false, columnType: 'button',
                cellsrenderer: (row = {}, columnfield, value, defaulthtml, columnproperties) => {
                    return `Edit`;
                },
                buttonclick: (rowIndex,event) => {
                    const data = $grid.jqxGrid('getrowdata', rowIndex) || {};
                    $grid.jqxGrid('clearselection');

                    if(Object.keys(data).length) {
                        dispatch(editLaneRate({...data, pointToPoint: (data.pointToPoint == "Y" ? true : false)}, () => $gridEl.jqxGrid('updatebounddata', 'data')));
                    }
                    
                },
            },
            { text: "Id", datafield: "id", width: 200 },
            { text: "Description", datafield: "description", width: 200 },
            { text: "Origin Code", datafield: "orgCode", width: 200, filtertype: "list", 
                filteritems: [ 
                    { html:"Location", label: "L" },
                    { html: "City / State", label: "C" },
                    { html:"State", label: "S" },
                    {html: "Zip", label: "Z" },
                    { html: "County", label: "N" }
                ]},
            { text: "Origin", width: 200, datafield: "origin" },
            { text: "Dest Code", datafield: "destCode", width: 200, filtertype: "list", 
                filteritems: [ 
                    { html:"Location", label: "L" },
                    { html: "City / State", label: "C" },
                    { html:"State", label: "S" },
                    {html: "Zip", label: "Z" },
                    { html: "County", label: "N" }
                ]},
            { text: "Destination", width: 200, datafield: "destination" },
            { text: "Method", width: 200, datafield: "method", filtertype: "list", 
                filteritems: [
                    { html: "Distance", label: "D" },
                    { html: "Flat", label: "F" },
                    { html: "CWT", label: "C" },
                    { html: "Tons", label: "T" },
                    { html: "Other", label: "O" },
                    { html: "Rate Table", label: "R" }] 
            },
            { text: "Min Charge", datafield: "minCharge", width: 200, filterable: false, cellsformat: 'c2' },
            { text: "Rate", datafield: "rate", width: 200, filterable: false,
                cellsrenderer: (row = {}, columnfield, value, defaulthtml, columnproperties) => {
                    const $defaultHtml = $(defaulthtml);

                    if(value) {
                        const displayValue = row.method == 'R' ? row.rateTableId : `${value}`; // R -> rate table_id name
                        $defaultHtml.text(displayValue);
                    }

                    return $defaultHtml[0].outerHTML;
                }, 
            },
            { text: "Distance", datafield: "distance", width: 200, filterable: false },
            { text: "Pt to Pt", datafield: "pointToPoint", width: 200, filtertype: "list", filteritems: ["Any", "Y", "N"]},
        ];
    }

    const handleExport = (rateNumber, data) => {
        if(data) {
            dataModel.exportToCSV(data, `Rate #${rateNumber} - Rate Table(s)`, true);
        }
    }

    const handleAddLaneRate = () => {
        dispatch(addLaneRate(rateNumber(), () => $grid.jqxGrid('updatebounddata', 'data')));
    }

    dispatch(getUserFilters(gridId, "RatesWindow-Lanes")).then(({userFilters}) =>  buildGrid($grid, gridSource(), gridColumns($grid, gridId, userFilters.grid), userFilters[gridId]));
}

import template from './lane-rates-component.html'
export default { viewModel: lanesRatesViewModel, template: template };
