//@ts-check
/// <reference path="../../../../types/jquery.plugins.d.ts" />

import { OrderPlanningOrderGrid } from './order-grid-component';
import template from './order-grid-component.html';

class LoadboardOrderGrid extends OrderPlanningOrderGrid {
    pageName = "UseLoadBoardFilters_MVC";
    constructor(params) {
        super(params);

        
    }
}

export default { viewModel: LoadboardOrderGrid, template: template }