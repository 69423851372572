import ko, { BindingHandler } from "knockout";
import jQuery from "jquery";

ko.bindingHandlers.geMobileSelectList = {
  init: (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) => {
    var container = $(element);
    var id = element.id ? 'id="' + element.id + '" ' : "";
    let style = allBindingsAccessor().style;
    var valuePropertyName = allBindingsAccessor().valuePropertyName || "value";
    var labelPropertyName = allBindingsAccessor().labelPropertyName || "label";
    let listitems = allBindingsAccessor().listitems;
    var selectList = $(
      '<select style="' +
        style +
        '; height: 28px; padding: 0 0 0 0;" class="form-control" ' +
        element.id +
        ">"
    );
    if (container.attr("name")) {
      selectList.attr("name", container.attr("name"));
    }
    if (!valueAccessor()()) {
      selectList.append("<option selected='true'>Please Choose: </option>");
    } else {
      selectList.append("<option>Please Choose: </option>");
    }

    if (typeof listitems == "function") {
      listitems = ko.unwrap(listitems);
    }

    //In this case, we've only defined the label and the value <i>should</i> be an object.
    if (valuePropertyName == "value" && labelPropertyName != "label") {
      ko.utils.arrayForEach(listitems, function (listItemValue) {
        var option;
        if (
          listitems.length > 1 &&
          (!valueAccessor()() ||
            valueAccessor()()[labelPropertyName] !=
              listItemValue[labelPropertyName])
        ) {
          option = $(
            '<option value="' +
              listItemValue[valuePropertyName] +
              '">' +
              listItemValue[labelPropertyName] +
              "</option>"
          );
          selectList.append(option);
        } else {
          option = $(
            '<option selected="true" value="' +
              listItemValue[valuePropertyName] +
              '">' +
              listItemValue[labelPropertyName] +
              "</option>"
          );
          selectList.append(option);
        }
      });
      //With this case, we're using the value & label properties
    } else {
      ko.utils.arrayForEach(listitems, function (listItemValue) {
        var option;
        let val = valueAccessor()();

        // Value is an object....
        if (val && typeof val === "object" && valuePropertyName in val) {
          val = val[valuePropertyName];
        }

        if (val != listItemValue[valuePropertyName]) {
          option = $(
            '<option value="' +
              listItemValue[valuePropertyName] +
              '">' +
              listItemValue[labelPropertyName] +
              "</option>"
          );
          selectList.append(option);
        } else {
          option = $(
            '<option selected="true" value="' +
              listItemValue[valuePropertyName] +
              '">' +
              listItemValue[labelPropertyName] +
              "</option>"
          );
          selectList.append(option);
        }
      });
    }

    let selectedItem = listitems.find(
      (x) =>
        x[valuePropertyName] == valueAccessor()() ||
        x[labelPropertyName] == valueAccessor()()
    );

    if (
      selectedItem == null &&
      valueAccessor()() &&
      typeof valueAccessor()() === "object" &&
      valuePropertyName in valueAccessor()()
    ) {
      const valueObject = listitems.find(
        (x) =>
          x[valuePropertyName] == valueAccessor()()[valuePropertyName] ||
          x[labelPropertyName] == valueAccessor()()[labelPropertyName]
      );

      if (valueObject) {
        selectedItem = valueObject;
      }
    }

    if (selectedItem) {
      valueAccessor()(selectedItem);
    }

    // }

    var selectListChange = false; //For pausing updates so the values don't change
    //This updates the Observable value
    selectList.on("change", (value) => {
      selectListChange = true;

      if ((value.target as HTMLInputElement).value == "Please Choose:") {
        valueAccessor()(undefined);
      } else {
        if (valuePropertyName == "value" && labelPropertyName != "label") {
          let li = listitems.find(
            (x) =>
              x[labelPropertyName] ==
              (value.target as HTMLSelectElement).selectedOptions[0].label
          );
          valueAccessor()(li);
        } else {
          valueAccessor()({
            value: (value.target as HTMLInputElement).value,
            label: (value.target as HTMLSelectElement).selectedOptions[0].label,
          });
        }
      }
      selectListChange = false;
    });

    //This updates the UI
    valueAccessor().subscribe((value) => {
      if (selectListChange == false) {
        if (value) {
          if (typeof value == "object") {
            if ("target" in value) {
              selectList.val(value.target.value[valuePropertyName]);
            } else if (valuePropertyName in value) {
              selectList.val(value[valuePropertyName]);
            }
          }
          if (typeof value == "boolean") {
            selectList.val("" + value + "");
          } else {
            selectList.val(value);
          }
        } else {
          (selectList[0] as HTMLSelectElement).options[0].selected = true;
        }
      }
    });

    if (allBindingsAccessor().isReadOnly) {
      if (allBindingsAccessor().isReadOnly() == true) {
        selectList.prop("disabled", true);
      }
      allBindingsAccessor().isReadOnly.subscribe(function (value) {
        if (value) {
          selectList.prop("disabled", true);
        } else {
          selectList.prop("disabled", false);
        }
      });
    }
    container.append(selectList);
  },
  update: function (
    element,
    valueAccessor,
    allBindingsAccessor,
    viewModel,
    bindingContext
  ) {},
} as BindingHandler;

ko.validation.makeBindingHandlerValidatable("geMobileSelectList");

interface KnockoutBindingHandlers {
  geMobileSelectList: BindingHandler;
}
