// -------------------
// Rate Component - GE-1351
// This is not a page, but a global popup type page that shows above the page user is on.
//

//import {displayRatesPopup, setLaneRate } from 'dataStore-actions/rates';
import {isLoading} from 'dataStore-actions/appUI';

// This component uses modals, so instead of having nested modals, 
// using a custom type modal that isn't reliant on bootstrap.
// See -> closing nested modal, closing all modals issue.
const ratesComponentViewModel = function({
    onClose = function() {},
    rateNumber = ko.observable()
}) {
    const vm = this;

    vm.activeRateNumber = rateNumber;

    const $ratePopup = $('#rating-popup-window-component');
    $ratePopup.show();
    $('body').addClass('no-modal-backdrop');
    $('body').css('overflow', 'hidden');

    vm.panels = ko.observableArray(['Rate Entry', 'Tiered Stops']);
    vm.activePanel = ko.observable("Rate Entry");

    vm.closePopup = () => {
        $('body').removeClass('no-modal-backdrop');
        $('.modal-backdrop').remove();
        $('body').css('overflow', 'auto');

        $ratePopup.hide();
        
        onClose();
    }

    vm.handleActivePanelChange = (panel = 'Rate Entry') => {
        vm.activePanel(panel);
    }
}

import template from './rating-popup-window.html';
export default { viewModel: ratesComponentViewModel, template: template }