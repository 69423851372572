import dataModel from "data-model";
import userProfile from "user-profile";

class HTTPService {
    constructor() {}

    _sendAsync = (url, payload, type) => {
        type = type || "GET";
        payload = payload || "";

        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(url, type, payload)
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };

    getUsersAsync = (payload) => {
        return this._sendAsync("User", payload);
    };

    getUserEmailAsync = (userExId) => {
        return this._sendAsync("User/GetUserEmail", { externalId: userExId });
    };

    getTenderTypesAsync = () => {
        return this._sendAsync("EDI/GetTenderTypes");
    };

    updateUserEmailAsync = (payload) => {
        return this._sendAsync("User/UpdateUserEmail", payload);
    };

    getCustomersAsync = (payload) => {
        return this._sendAsync("EDI/GetAgencyCustomersForDualSelect", payload);
    };

    getLocationsAsync = (payload) => {
        return this._sendAsync("EDI/GetLocationsForDualSelect", payload);
    };

    removeSubscriptionsAsync = (payload) => {
        return this._sendAsync("EDI/RemoveSubscriptions", payload, "POST");
    };

    updateSubscriptionsAsync = (payload) => {
        return this._sendAsync("EDI/UpdateSubscriptions", payload, "POST");
    };

    getSubscriptionsAsync = (payload) => {
        return this._sendAsync("EDI/GetSubscriptions", payload);
    };
}

export default new HTTPService();
