import ko, { observable } from "knockout";
import dataModel from "data-model";
import dayjs from "dayjs";
import gridStateUtils from "jqx.grid-utils";
import GreatEdgeCustomWidgets from "ge.custom-widgets";
import GridStateComponentViewModel from "jqx.grid-state-component";
import template from "./carriers-page.html";
import * as _ from "lodash";
import { mobileAndTabletcheck } from "global-functions";
import router from "router";
import userProfile from "user-profile";
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";

var SearchRMISViewModel = function (mainModel) {
  var self = this;

  self.isLoading = ko.observable(false);
  self.isRMISSearchLoading = ko.observable(false);
  self.errors = ko.observable();
  self.cancelSearch = function () {
    var $element = $("#searchRMISModal");
    $element.modal("hide");
  };
  self.enableButton = ko.observable(false);
  self.showSearchButton = ko.pureComputed(function () {
    return !(
      typeof self.selectedSearch() === "undefined" ||
      self.selectedSearch() === null
    ) && self.selectedSearch() === "MC Number"
      ? self.enableButton()
      : self.selectedSearch() === "DOT Number"
      ? self.enableButton() &&
        !(
          typeof self.DOTNumber() === "undefined" ||
          self.DOTNumber() === null ||
          self.DOTNumber() == ""
        )
      : self.selectedSearch() == "Intra-State / Intra-Provincial"
      ? self.isDOTRequiredForState()
        ? self.selectedStateOrProvince.isValid() &&
          self.IntraStateNumber.isValid() &&
          self.enableButton() &&
          !(
            typeof self.DOTNumber() === "undefined" ||
            self.DOTNumber() === null ||
            self.DOTNumber() == ""
          )
        : self.selectedStateOrProvince.isValid() && self.enableButton()
      : false;
  });
  self.isSearchInputVisible = ko.pureComputed(function () {
    return (
      self.selectedSearch() === "MC Number" ||
      self.selectedSearch() === "DOT Number" ||
      (!(
        typeof self.selectedStateOrProvince() === "undefined" ||
        self.selectedStateOrProvince() === null
      ) &&
        self.isDOTRequiredForState() === false)
    );
  });
  self.numberType = ko.observable();
  self.isDOTRequiredForState = ko.observable(false);
  self.stateOrProvinceSelectionRequired = ko.observable(false);
  self.selectedStateOrProvince = ko.validatedObservable().extend({
    required: {
      onlyIf: function () {
        if (self.stateOrProvinceSelectionRequired() == true) {
          return true;
        }
        return false;
      },
      message: "A valid state is required for Intra-State search",
    },
  });

  self.stateError = ko.validation.group(self.selectedStateOrProvince);

  self.pushStateError = function () {
    if (!self.selectedStateOrProvince.isValid()) {
      self.stateError.showAllMessages();
    }
  };
  self.selectedStateOrProvince.subscribe(function (newVal) {
    self.DOTNumber(undefined);
    self.IntraStateNumber(undefined);
    self.DOTNumber.isModified(false);
    self.IntraStateNumber.isModified(false);
    var statesWithoutDOTRequirement = [
      "AR",
      "DC",
      "DE",
      "ID",
      "IL",
      "LA",
      "MA",
      "MS",
      "NV",
      "NH",
      "NM",
      "ND",
      "RI",
      "SD",
      "TN",
      "VT",
      "VA",
    ];
    if (statesWithoutDOTRequirement.indexOf(newVal) > -1) {
      //if the selected state is a state without a DOT requirement
      self.isDOTRequiredForState(false);
    } else if (newVal == undefined) {
      self.isDOTRequiredForState(false);
      self.selectedStateOrProvince.isModified(false);
    } else {
      self.isDOTRequiredForState(true);
    }
  });

  self.selectedSearch = ko.observable();
  self.DOTNumber = ko.validatedObservable().extend({
    required: {
      onlyIf: function () {
        if (
          self.selectedSearch() === "DOT Number" ||
          self.isDOTRequiredForState() === true
        ) {
          return true;
        }
        return false;
      },
      message: "DOT Number is required",
    },
  });

  self.DOTNumber.subscribe(function (newVal) {
    if (newVal == "" || newVal == undefined) {
      self.DOTNumber.isModified(false);
      self.enableButton(false);
    } else if (newVal.length > 4) {
      self.enableButton(true);
    } else {
      self.enableButton(false);
    }
  });
  self.MCNumber = ko.validatedObservable().extend({
    required: {
      onlyIf: function () {
        if (self.selectedSearch() === "MC Number") {
          return true;
        }
        return false;
      },
      message: "MC Number is required",
    },
  });
  self.MCNumber.subscribe(function (newVal) {
    if (newVal == "" || newVal == undefined) {
      self.MCNumber.isModified(false);
      self.enableButton(false);
    } else if (newVal.length > 4) {
      self.enableButton(true);
    } else {
      self.enableButton(false);
    }
  });
  self.IntraStateNumber = ko.validatedObservable().extend({
    required: {
      onlyIf: function () {
        if (self.selectedSearch() === "Intra-State / Intra-Provincial") {
          return true;
        }
        return false;
      },
      message: "  IntraState Number is required",
    },
  });
  self.IntraStateNumber.subscribe(function (newVal) {
    if (newVal == "" || newVal == undefined) {
      self.IntraStateNumber.isModified(false);
      self.enableButton(false);
    } else if (newVal.length > 4) {
      self.enableButton(true);
    } else {
      self.enableButton(false);
    }
  });
  self.isMCInputVisible = ko.observable(false);
  self.isIntraStateInputVisible = ko.observable(false);
  self.isDOTInputVisible = ko.observable(false);
  self.selectedSearch.subscribe(function (newVal) {
    if (newVal === "Intra-State / Intra-Provincial") {
      self.enableButton(false);
      self.numberType("Intra-State / Intra-Provincial Number:");
      self.stateOrProvinceSelectionRequired(true);
      self.DOTNumber(undefined);
      self.DOTNumber.isModified(false);
      self.isIntraStateInputVisible(true);
      self.MCNumber(undefined);
      self.MCNumber.isModified(false);
      self.isMCInputVisible(false);
      self.isDOTInputVisible(false);
    } else if (newVal === "MC Number") {
      self.enableButton(false);
      self.numberType("MC Number:");
      self.stateOrProvinceSelectionRequired(false);
      self.isDOTRequiredForState(false);
      self.selectedStateOrProvince(undefined);
      self.selectedStateOrProvince.isModified(false);
      self.DOTNumber(undefined);
      self.DOTNumber.isModified(false);
      self.IntraStateNumber.isModified(false);
      self.isMCInputVisible(true);
      self.isIntraStateInputVisible(false);
      self.isDOTInputVisible(false);
    } else if (newVal === "DOT Number") {
      self.enableButton(false);
      self.numberType("DOT Number:");
      self.stateOrProvinceSelectionRequired(false);
      self.isDOTRequiredForState(false);
      self.selectedStateOrProvince(undefined);
      self.selectedStateOrProvince.isModified(false);
      self.DOTNumber(undefined);
      self.DOTNumber.isModified(false);
      self.IntraStateNumber.isModified(false);
      self.MCNumber(undefined);
      self.MCNumber.isModified(false);
      self.isIntraStateInputVisible(false);
      self.isMCInputVisible(false);
      self.isDOTInputVisible(true);
    }
  });

  self.searchCategories = ko.observableArray([
    "MC Number",
    "DOT Number",
    "Intra-State / Intra-Provincial",
  ]);
  self.searchRMIS = function (param, data, event) {
    self.isRMISSearchLoading(true);
    param = {
      SearchNumber: self.DOTNumber()
        ? self.DOTNumber()
        : self.MCNumber()
        ? self.MCNumber()
        : self.IntraStateNumber()
        ? self.IntraStateNumber()
        : null,
      SearchType:
        self.selectedSearch() == "DOT Number" ||
        (self.selectedSearch() == "Intra-State / Intra-Provincial" &&
          self.isDOTRequiredForState() == true)
          ? "DOT Number"
          : self.selectedSearch(),
      SearchState: self.selectedStateOrProvince(),
    };
    dataModel
      .ajaxRequest("Carrier/SearchRMIS", "get", param)
      .done(function (result) {
        self.cancelSearch();
        var data = ko.toJS(result.rmisSearchResult);
        // If DOT Auth failed or expired then carrier is out of service
        var isOOS =
          data.activationRequirementCheckResults.authorityActiveCheck ==
            "Failed" ||
          data.activationRequirementCheckResults.authorityAgeCheck == "Failed";
        data.carrierCreatedSuccess = ko.observable(false);
        data.createCarrierLink = ko.observable();
        data.createCarrierResponse = ko.observable();
        if (data.requestResult == "SUCCESS") {
          data.isOOS = ko.observable(isOOS);
          data.duplicateCheckConfirmed = ko.observable(false);
          data.address = ko.computed(function () {
            var address =
              data.address1 +
              ", " +
              (data.address2 != "" ? data.address2 + ", " : data.address2) +
              "" +
              data.city +
              ", " +
              data.state +
              ", " +
              data.zip;
            return address;
          });
          data.isLoading = ko.observable(false);
          data.isRMISLoading = ko.observable();
          data.possibleExistingCarriers = ko.observable(
            data.possibleExistingCarriers
          );
          data.possibleDuplicates = ko.observable(
            data.possibleExistingCarriers() !== null &&
              data.possibleExistingCarriers().length > 0
          );
          data.cannotCreateDuplicate = ko.observable(
            !result.hasGWCarrierEntryRole
          );
          data.cannotCreateDuplicateResponse = ko.observable(
            "Please review the code(s) provided. If the codes provided are not the carrier and a new carrier needs to be created, please contact the Brokerage department with the MC Number, DOT number, or Intrastate numbers necessary to have the carrier created."
          );
          data.carrierNotDuplicate = ko.observable(false);
          data.carrierNotDuplicate.subscribe(function (newVal) {
            if (newVal === true) {
              data.possibleDuplicates(false);
              data.duplicateCheckConfirmed(true);
            } else {
              data.possibleDuplicates(true);
              data.duplicateCheckConfirmed(false);
            }
          });
          data.showCarrierButton = ko.observable(
            data.activationRequirementCheckResults.passedRequirements
          );
          data.addCarrier = function (param, data, event) {
            data.isRMISLoading(true);
            dataModel
              .ajaxRequest("Carrier/CreateCarrier/" + data.dotNumber, "post")
              .done(function (result) {
                if (result !== null && result !== {} && result !== undefined) {
                  if (
                    result.message &&
                    result.message.toString().toUpperCase().includes("ERROR")
                  ) {
                    $("#createCarrierResponse").css({
                      "background-color": "#ffcccc",
                    });
                    data.createCarrierResponse(
                      "<div style='display: inline-block; padding: 10px'>" +
                        result.message +
                        "</div>"
                    );
                    data.showCarrierButton(false);
                  } else {
                    data.carrierCreatedSuccess(true);
                    $("#carrierCreatedLink").css({
                      "background-color": "#e9fce9",
                    });
                    data.createCarrierLink(
                      "<div style='display:inline-block; padding:10px'><span style='display:inline-block; margin-right: 20px;'>CARRIER CREATED:</span><a href='/CarrierEntry/" +
                        result.carrierInternalID +
                        "'target='_blank' title='View Carrier'><span style='color:blue !important; text-decoration: underline'>" +
                        result.carrierExternalID +
                        "</span></a><div/>"
                    );
                    data.showCarrierButton(false);
                  }
                  setTimeout(function () {
                    $("#carrierCreatedLink").animate(
                      { "background-color": "" },
                      "slow"
                    );
                  }, 3500);
                }
                data.isRMISLoading(false);
              })
              .fail(function (error, msg, d) {
                $("#createCarrierResponse").css({
                  "background-color": "#ffcccc",
                });
                data.createCarrierResponse(
                  "<div style='display: inline-block; padding: 10px'>An error has occured.  Please contact the Greatwide Helpdesk if this issue persists.</div>"
                );
                data.showCarrierButton(false);
                data.isRMISLoading(false);
              });
          };
          data.closeModal = function () {
            var $element = $("#createCarrierModal");
            $element.modal("hide");
            data.createCarrierResponse(undefined);
          };
          mainModel.createCarrierModal(data);

          //When the second modal is opened, another scroll bar appears, this removes it.
          $("body").css({ "overflow-y": "hidden" });
          $("#createCarrierModal").on("hidden.bs.modal", function () {
            //When the second modal is closed, need to add this back to give it the scroll bars again.
            $("body").css({ "overflow-y": "auto" });
          });
        } else {
          var temp = "";
          if (data.requestResult == "EXISTING") {
            self.selectedSearch() == "MC Number"
              ? (temp = self.MCNumber())
              : self.selectedSearch() == "DOT Number"
              ? (temp = self.DOTNumber())
              : self.selectedSearch() == "Intra-State / Intra-Provincial"
              ? (temp = self.IntraStateNumber())
              : (temp = "");
            mainModel.carrierMessage("Carrier " + temp + " already exists");
          } else if (data.requestResult == "FAIL") {
            mainModel.carrierMessage(
              "An error has occured.  Please contact the Greatwide Helpdesk if this issue persists."
            );
          } else if (data.requestResult.includes("ERROR - 2210330")) {
            mainModel.carrierMessage(
              "Duplicate carrier information found.  The carrier must contact RMIS before approval at 800-400-4924."
            );
          } else {
            mainModel.carrierMessage(
              "This carrier is not currently being monitored.  Please click <a href='https://evans-westcarriers.rmissecure.com/_c/std/reg/DOTLookupStdV2.aspx' target='_blank'><span style='color: blue'>HERE</span></a> to verify if the carrier meets the minimum Greatwide carrier requirements."
            );
          }
          $("#carrierRejectModal").jqxWindow({
            autoOpen: false,
            isModal: true,
            width: 410,
            height: 285,
            maxWidth: 1200,
            resizable: false,
            theme: "energyblue",
            closeButtonSize: 20,
          });
          $("#carrierRejectModal").jqxWindow("open");
        }
        self.isRMISSearchLoading(false);
      })
      .fail(function (error, msg, d) {
        mainModel.carrierMessage("Unknown error");
        self.isRMISSearchLoading(false);
      });
  };
};

var CarriersViewModel = function () {
  var self = this;
  var geCustomWidgets = new GreatEdgeCustomWidgets();
  var lastUpdated = Date.now();
  var updateGrid = false;
  var ratingUpdated = false;
  self.showAddNew = ko.observable(false);
  self.carrierTerminal = ko.observable();
  self.showRMIS = ko.observable(false);
  self.createCarrierMessage = ko.observable();
  self.createCarrierModal = ko.observable();
  self.carrierMessage = ko.observable();
  self.searchRMISModal = ko.observable();
  self.isCarrierGridLoading = ko.observable(true);
  self.RMISLinkVisible = ko.observable(false);
  self.details = ko.observable();
  self.carrierRadiusLocation = ko.observable("");
  self.carrierRadius = ko.observable(10);
  self.includeInactive = ko.observable(false);
  self.userHasCarrierFiltersSaved = ko.observable(false);
  self.usersSavedFilters = ko.observable();
  var isInitLoad = true;
  var isGridInitialized = false;
  var isActive = false;
  var $grid = $("#geCarriers");
  self.initGridState = null;
  var gridheightraw = screen.height - 360;
  var pxgridheight = screen.height - 360 + "px";
  var gridrows;
  self.showloader = ko.observable(false);
  self.showFindCarrierBtn = ko.observable(false);
  // Prevent circular updates on the Include Inactive checkbox and status grid filter
  let statusFilterEventChangedBy = "";
  var _jqxGridHelper = geCustomWidgets.GEWidgetsGridHelper("geCarriers");

  // Used by: Can't find carrier btn to log click event and send to external page
  self.addClientSideLog = function (message) {
    dataModel.addClientSideLog(message);
    return true; // must return true so external page will load
  };

  self.sendPacket = ko.observable();
  self.showSendPakcet = function () {
    self.sendPacket({});
  };
  // This gets called during the grid initialization and after initFilters has resolved.
  // It updates and applies additional filters (hidden columns) the user has saved.
  var gridLoadedState = null;

  var getUserRoles = function () {
    return new Promise((resolve, reject) => {
      dataModel
        .ajaxRequest("account/userinfo")
        .done(function (data) {
          var isAddmin = ko.utils.arrayFirst(data.roles, function (item) {
            return item === "Administrators" || item === "Greatwide Employees";
          });
          if (isAddmin) {
            self.showAddNew(true);
          } else {
            self.showAddNew(false);
          }
          var hasRMIS = ko.utils.arrayFirst(data.roles, function (item) {
            return item === "RMIS USER";
          });
          if (hasRMIS) {
            self.showRMIS(true);
          } else {
            self.showRMIS(false);
          }
          resolve();
        })
        .fail(function (error, msg, d) {
          reject();
        });
    });
  };

  let _initGridFilters = null;

  var getSourceData = function (data) {
    if (
      self.initGridState !== null &&
      self.initGridState["filters"] &&
      self.initGridState.filters
    ) {
      for (var i = 0; i < self.initGridState.filters.filterscount; i++) {
        var rangePosition = "";
        if (self.initGridState.filters["filtertype" + i] == "datefilter") {
          if (
            self.initGridState.filters["filterdatafield" + i] ==
            self.initGridState.filters["filterdatafield" + (i + 1)]
          )
            rangePosition = "From"; //'Begin';
          else if (
            self.initGridState.filters["filterdatafield" + i] ==
            self.initGridState.filters["filterdatafield" + (i - 1)]
          )
            rangePosition = "To"; //'End';
        }
        data[
          self.initGridState.filters["filterdatafield" + i] + rangePosition
        ] = self.initGridState.filters["filtervalue" + i];
      }
    }

    return { data: data, gridState: self.initGridState };
  };

  $grid.on("bindingcomplete", function (event) {
    if (!isInitLoad) {
      self.showloader(true);
      $grid.jqxGrid("hideloadelement");
    }

    if(statusFilterEventChangedBy === "CLEAR_FILTERS"){
      statusFilterEventChangedBy = "";
      toggleStatusFilters(["Active"])
    }

  });

  const toggleStatusFilters = (status = []) => {
    if((status ?? []).length === 0) return;
    var filtergroup1 = new $.jqx.filter();

    status.forEach((x) => {
      filtergroup1.addfilter(1, filtergroup1.createfilter(
          "stringfilter",
          x,
          "equal"
      ))
    })

    $grid.jqxGrid("addfilter", "isActive", filtergroup1);
    $grid.jqxGrid("refreshfilterrow");
  };

  const getStatusFilters = () => {
    let status = [];
    const filterinfo = $grid.jqxGrid("getfilterinformation");

    if (isInitLoad || statusFilterEventChangedBy === "CLEAR_FILTERS") {
      return ["Active"]
    }

    if (filterinfo.find((x) => x.datafield === "mcNumber")) {
      return ["Active", "Inactive"]
    }

    filterinfo.filter(x => x.datafield === "isActive").forEach(x => {
      const filters = x.filter.getfilters();

      if(filters.length) {
        status = filters.filter(x => x.condition === "EQUAL").map(x => x.value);
      }
    })

    // Grid filter is backwards, when both are checked, we don't have any filterinfo
    if(filterinfo.some(x => x.datafield === "isActive") === false) {
      status = ["Active", "Inactive"]
    }

    // Flip our redundant checkbox 'Include InActive' so it matches grid filter.
    if(statusFilterEventChangedBy !== "OBSERVABLE" && status.some(x => x === "Inactive") && self.includeInactive() === false) {
      statusFilterEventChangedBy = "GRID"
      self.includeInactive(true);
    }

    if(statusFilterEventChangedBy !== "OBSERVABLE" && status.some(x => x === "Inactive") === false && self.includeInactive()) {
      statusFilterEventChangedBy = "GRID"
      self.includeInactive(false);
    }

    return status
  }


  self.createGrid = function () {
    var source = {
      url: "carrier/GetCarriersForGrid",
      datatype: "json",
      filter: function (filters, recordsArray) {
        if (isInitLoad === false) {
          $grid.jqxGrid("updatebounddata", "filter");
        }
      },
      sort: function (column, direction) {
        $grid.jqxGrid("updatebounddata", "sort");
      },
      formatdata: function (data) {

        var columns = new Array();

        var filterinfo = $grid.jqxGrid("getfilterinformation");

        data = {
          recordstartindex: data.recordstartindex,
          recordendindex: data.recordendindex,
          pagesize: data.pagesize,
          pagenum: data.pagenum,
          sortdatafield: data.sortdatafield,
          sortorder: data.sortorder,
          agencyId: userProfile.currentAgencyId(),
          columns: columns,
          status: []
        }

        var mcNumberFilter;
        if (isInitLoad) {
          var initSourceData = getSourceData(data);
          data = initSourceData.data;

          if (data.mcNumber) {
            data = {
              recordstartindex: data.recordstartindex,
              recordendindex: data.recordendindex,
              pagesize: data.pagesize,
              pagenum: data.pagenum,
              sortdatafield: data.sortdatafield,
              sortorder: data.sortorder,
              agencyId: userProfile.currentAgencyId(),
              columns: columns,
              mcNumber: data.mcNumber,
            };
          }
          if (initSourceData.gridState)
            if (initSourceData.gridState.filters)
              _initGridFilters = initSourceData.gridState.filters;

        } else {
          if (self.showloader()) {
            $grid.jqxGrid("showloadelement");
          }
          //MCNumber Filter supersedes all other filters.
          mcNumberFilter = filterinfo.find(function (x) {
            return x.datafield == "mcNumber";
          });
          if (mcNumberFilter) {
            filterinfo = [];
            filterinfo.push(mcNumberFilter);
          }
        }

        for (let i = 0; i < filterinfo.length; i++) {
          var filterName = filterinfo[i].datafield;
          var filters = filterinfo[i].filter.getfilters();

          if (filters.length && filters[0].type != "datefilter" && filterName !== "isActive") {
            data[filterName] = filters[0].value;
          }

          if (filters.length && filters[0].value == "Please Choose:") {
            data[filterName] = null;
          }
        }

        data.lastUpdated = lastUpdated;
        //include additional params for data object from toolbar.  This should always get hit, unless this is the first load.  isSecondload only gets reset to false on initial page load
        if (isGridInitialized == true) {
          data.isInitLoad = false;
          //This is whether the include inactive box is checked or not.  If it is checked (true), you want to send a null value ("") so sql stored proc will find disregard param and find all
          //if it is not checked (false) then that means the user only wants to see active carriers, so you set isActive to true.

          data.radius = self.carrierRadius();
          data.radiusCityStateZip = self.carrierRadiusLocation();
        }
        data = _jqxGridHelper.addGridFiltersToDataObj(data, {
          dateRangePostFixes: ["From", "To"],
        });

        data.intrastate =
          data.intrastate == null ? null : data.intrastate ? "Yes" : "No";
        data.interchangeAgreement =
          data.interchangeAgreement == null
            ? null
            : data.interchangeAgreement == "Yes"
            ? "Yes"
            : "No";
        data.trailerInterchange =
          data.trailerInterchange == null
            ? null
            : data.trailerInterchange
            ? "Yes"
            : "No";

        data.status = getStatusFilters();

        isInitLoad = false;
        statusFilterEventChangedBy = statusFilterEventChangedBy === "CLEAR_FILTERS" ? statusFilterEventChangedBy : "GRID"
        return data;
      },
      datafields: datafields,
      loadComplete: function (data) {
        if (isInitLoad == false) {
          self.isCarrierGridLoading(false);
        }
      },
      loadError: function (error) {
        if (isInitLoad == false) {
          self.isCarrierGridLoading(false);
        }
      },
      beforeprocessing: (data) => {
        data.records = (data.records || []).map((x) => {
          // x.intrastate = x.intrastate == "Yes" ? true : false;
          // x.interchangeAgreement = x.interchangeAgreement == "Yes" ? true : false;
          // x.trailerInterchange = x.trailerInterchange == "Yes" ? true : false;
          return x;
        });
      },
    };

    if (gridheightraw >= 700) {
      gridrows = 20;
    } else if (gridheightraw >= 600) {
      gridrows = 15;
    } else {
      gridrows = 11;
    }
    var isMobile = mobileAndTabletcheck();
    var gridHeight =
      isMobile && screen.height - 360 < screen.height
        ? screen.height + 200 + "px"
        : screen.height - 360 + "px";
    var statusbarHeight = 70;

    // if (self.RMISLinkVisible() == true) {
    //   statusbarHeight = 70;
    // }

    // dynamically update the toolbar to compensate radius search
    var settoolbarheight = window.innerWidth <= 990 ? 190 : 95;
    $(window).on("resize", function (e) {
      settoolbarheight = window.innerWidth <= 990 ? 190 : 95;
      $grid.jqxGrid({ toolbarheight: settoolbarheight });
    });

    var dataAdapter = dataModel.getDataAdapter(source);
    //In the construction of this $grid, set the intial height to 0 so that the first empty $grid does not appear.  See INITIAL GRID.  The height of the second rendering (the visible $grid) is set

    $grid.jqxGrid({
      theme: "GWTMDark",
      width: "100%",
      source: dataAdapter,
      altrows: true,
      sortable: true,
      autoshowloadelement: self.showloader(),
      height: gridHeight,
      pageable: true,
      pagesize: gridrows,
      filterable: true,
      showfilterrow: true,
      virtualmode: true,
      columnsresize: true,
      columnsreorder: true,
      enablebrowserselection: true,
      columnsmenu: false,
      columns: columns,
      showtoolbar: true,
      showstatusbar: true,
      toolbarheight: settoolbarheight,
      statusbarheight: statusbarHeight,
      autoshowfiltericon: true,
      renderstatusbar: function (statusbar) {
        var carriersGridStatusbar = $("#carriersGridStatusbar");
        statusbar.append(carriersGridStatusbar);
      },
      rendertoolbar: function (toolbar) {
        var vm1 = new GridStateComponentViewModel.viewModel();
        var tmpl1 = GridStateComponentViewModel.template;
        vm1.actions.push("Send Packet");
        vm1.actions.push("Save Search");
        if (self.showRMIS() == true) {
          vm1.actions.push("Search RMIS");
        }

        vm1.loadSaveSearchModalOverride = async () => {
          const savedSearches = await loadSavedSearches("geCarriers");

          var filters = {
            includeInactive: self.includeInactive(),
            isDefault: false,
          };

          vm1.loadSaveSearchModal(
            $grid,
            (val) => {
              if (val && val.searchname) {
                self.refreshSavedSearchDDL(val.searchname);
              }
            },
            savedSearches
              .filter((x) => x.searchName)
              .map((x) => ({ id: x.id, text: x.searchName })),
            filters,
            true
          );
        };

        //Here isInitLoad is set to true so that includeInactive subscriber does not fire, then it is set back to false to allow new data to load
        vm1.clearFilters = function () {
          self.clearSelectedSavedSearchDDL(true);
          if (
            self.carrierRadiusLocation() !== undefined &&
            self.carrierRadiusLocation().length > 0
          ) {
            self.carrierRadiusLocation("");
            self.carrierRadius(10);
            isInitLoad = true;
            self.includeInactive(false);
            isInitLoad = false;
            //self.refreshGrid();
          } else {
            isInitLoad = true;
            self.includeInactive(false);
            isInitLoad = false;
            //self.refreshGrid();
          }

          statusFilterEventChangedBy = "CLEAR_FILTERS";
          $grid.jqxGrid("clearfilters");

          self.clearSelectedSavedSearchDDL(true);
          _jqxGridHelper.clearDateRangeFiltersFromGrid();
        };
        vm1.setDefaultSearchOverride = async function () {
          const savedSearches = await loadSavedSearches("geCarriers");

          var filters = {
            includeInactive: self.includeInactive(),
            isDefault: true,
          };

          vm1.loadSaveSearchModal(
            $grid,
            (val) => {
              if (val && val.searchname) {
                self.refreshSavedSearchDDL(val.searchname);
              }
            },
            savedSearches
              .filter((x) => x.searchName)
              .map((x) => ({ id: x.id, text: x.searchName })),
            filters,
            true
          );
        };
        vm1.refresh = function () {
          updateGrid = true;
          $grid.jqxGrid("updatebounddata", "data");
        };
        vm1.action = function (item) {
          if (item === "Search RMIS") {
            var searchRMISModel = new SearchRMISViewModel(self);
            self.searchRMISModal(searchRMISModel);
          } else if (item == "Send Packet") {
            self.sendPacket({});
          }
        };
        var carrierGridToolbar = $("#carrierGridToolbar");
        toolbar.append(carrierGridToolbar);
        var tdGridAction = carrierGridToolbar.find("#tdGridAction");
        tdGridAction.append(tmpl1);
        ko.applyBindingsToDescendants(vm1, tdGridAction[0]);
      },

      rendergridrows: function (obj) {
        return obj.data;
      },
      ready: function () {
        toggleStatusFilters(["Active"]);
        gridStateUtils.applyGridState("geCarriers", gridLoadedState);
        isGridInitialized = true;
      },
    });


  };

  self.createNewCarrier = function () {
    dataModel.addClientSideLog("Selected: New Carrier Btn");
    router.navigate("CarrierEntry", true);
  };

  var placeHolderFilterWidget = function (column, columnElement, widget) {
    widget.jqxInput({
      placeHolder: "Type Here...",
    });
  };
  var geDateRangeFilterWidget = function (column, columnElement, widget) {
    var options = {
      gridOptions: {
        gridWidget: widget,
        gridId: "geCarriers", // required to apply gridoptions
        columnName: column.datafield, // also required, especially if this is a filter widget
        isFilter: true, // create the widget as a filter on the $grid (sets up event bindings, etc)
        state: gridLoadedState, // send state instead of initial date options to apply any saved state dates
      },
    };
    return geCustomWidgets.GEDateRangePickerWidget(options); // will return widget if already exists or creates a new one;
  };

  self.closecarrierRejectModal = function () {
    $("#carrierRejectModal").jqxWindow("close");
    return true;
  };

  self.searchAgain = function () {
    $("#carrierRejectModal").jqxWindow("close");
    var searchRMISModel = new SearchRMISViewModel(self);
    self.searchRMISModal(searchRMISModel);
  };

  self.closeCarrierRejectModal = function () {
    $("#carrierRejectModal").jqxWindow("close");
  };

  self.toggleCustomers = function (event, args) {
    var isToggled = $("#divCarrierPanel").is(":visible");
    var $this = $(args.currentTarget);
    if (isToggled) {
      $("#carrierGridHeading").animate({ color: "white" }, 1200);
      $this
        .find("i")
        .removeClass("glyphicon-chevron-up")
        .addClass("glyphicon-chevron-down")
        .animate({ color: "white" }, 1200);
    } else {
      $("#carrierGridHeading").animate({ color: "black" }, 1200);
      $this
        .find("i")
        .removeClass("glyphicon-chevron-down")
        .addClass("glyphicon-chevron-up")
        .animate({ color: "black" }, 1200);
    }

    $("#divCarrierPanel").animate({ height: "toggle" }, "slow");
  };

  self.includeInactive.subscribe(function (newVal) {

    if(statusFilterEventChangedBy !== "OBSERVABLE") {
      statusFilterEventChangedBy = "OBSERVABLE"
      const status = getStatusFilters();

      // Skip both grid filter and checkbox are in sync
      if(newVal && status.some(x => x === "Inactive") || newVal === false && status.some(x => x === "Inactive") === false) {
        return;
      }

      if(newVal) {
        const x = new Set([...status])
        x.add("Inactive");
        toggleStatusFilters(Array.from(x))
      }
      else {
        toggleStatusFilters(status.filter(x => x !== "Inactive"))
      }
    }

    if (!isInitLoad && statusFilterEventChangedBy === "OBSERVABLE") {
       self.refreshGrid();
    }
  });

  self.handleOnSelected = (searchFilters = {}) => {
    searchFilters =
      Object.keys(searchFilters).length > 0
        ? searchFilters
        : { grid: { filters: {} } };
    const x = searchFilters.additionalFilters;

    if (x) {
      self.includeInactive(x.includeInactive ?? false);
    }
    _initGridFilters = searchFilters.grid;
    self.initGridState = searchFilters.grid;
    // set any of the users grid states (columns being hide/shown, etc)
    gridLoadedState = searchFilters.grid;

    if (isInitLoad) {
      Promise.all([getUserRoles()]).then(() => {
        self.createGrid();
      });
    } else {
      gridStateUtils.applyGridState("geCarriers", searchFilters.grid);
    }
  };

  self.refreshSavedSearchDDL = ko.observable("");
  self.clearSelectedSavedSearchDDL = ko.observable(false);

  self.carrierGridRadiusSearch = function () {
    dataModel.addClientSideLog("Selected: Radius Search Btn");
    $grid.jqxGrid("updatebounddata", "data");
    $grid.jqxGrid("showcolumn", "distance");
  };

  self.refreshGrid = function () {
    $grid.jqxGrid("updatebounddata", "data");
    if ($grid.jqxGrid("iscolumnvisible", "distance")) {
      $grid.jqxGrid("hidecolumn", "distance");
    }
  };

  var datafields = [
    { name: "currentAgencyId", type: "string" },
    { name: "californiaEmission", type: "string" },
    { name: "cargoInsuranceAmount", type: "string" },
    { name: "cargoInsurancePolicyNumber", type: "string" },
    { name: "cargoInsuranceRenewalDate", type: "date" },
    { name: "carrierType", type: "string" },
    { name: "city", type: "string" },
    { name: "code", type: "string" },
    { name: "distance", type: "double" },
    { name: "intrastate", type: "boolean" },
    { name: "dotNumber", type: "string" },
    { name: "equipment", type: "string" },
    { name: "id", type: "int" },
    { name: "isActive", type: "string" },
    { name: "liabilityAmount", type: "string" },
    { name: "liabilityExpirationDate", type: "date" },
    { name: "liabilityInsurancePolicyNumber", type: "string" },
    { name: "mcNumber", type: "string" },
    { name: "contractDate", type: "date" },
    { name: "name", type: "string" },
    { name: "performanceRating", type: "string" },
    { name: "phone", type: "string" },
    { name: "rating", type: "int" },
    { name: "state", type: "string" },
    { name: "terminal", type: "string" },
    { name: "zip", type: "string" },
    { name: "interchangeAgreement", type: "string" },
    { name: "trailerInterchange", type: "string" },
    { name: "trailerInterchangeInsuranceExpiration", type: "date" },
    { name: "trailerInterchangeInsuranceAmount", type: "string" },
    { name: "trailerInterchangeInsuranceCompany", type: "string" },
    { name: "trailerInterchangeInsurancePolicy", type: "string" },
    { name: "relationshipManager", type: "string" },
    { name: "lastNoteDate", type: "date" },
    { name: "intrastateCode", type: "string" },
    { name: "intrastateState", type: "string" },
    { name: "isOOS", type: "boolean" },
    { name: "noDispatch", type: "boolean" },
    { name: "revAgencyId", type: "int" },
  ];

  var columns = [
    {
      text: "",
      width: 100,
      columnType: "button",
      filterable: false,
      pinned: true,
      buttonclick: function (row) {
        var currentRow = $grid.jqxGrid("getrowdata", row);

        var data = {
          cityStateZip: self.carrierRadiusLocation(),
          radius: self.carrierRadius(),
          carrierId: currentRow.id,
        };

        dataModel
          .ajaxRequest("Carrier/Terminals", "get", data)
          .done(function (data) {
            var details = {
              records: data,
            };

            self.carrierTerminal(details);
            self.isCarrierGridLoading(false);
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            self.isCarrierGridLoading(false);
          });
      },
      cellsrenderer: function () {
        return "Details";
      },
    },
    {
      text: "Distance",
      datafield: "distance",
      cellsformat: "d2",
      cellsalign: "center",
      filtercondition: "starts_with",
      width: 80,
      createfilterwidget: placeHolderFilterWidget,
      hideable: true,
      hidden: true,
      filterable: false,
    },
    {
      text: "Carrier",
      datafield: "code",
      width: 100,
      createfilterwidget: placeHolderFilterWidget,
      filtercondition: "starts_with",
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaultHTML,
        column,
        rowData
      ) {
        let donotloadclass =
          rowData.performanceRating == "DO NOT LOAD" ||
          (rowData.revAgencyId != null &&
            rowData.revAgencyId != userProfile.agencyLinkCode())
            ? "danger"
            : "";
        if (defaultHTML != null) {
          var cell = $(defaultHTML);
          if (donotloadclass != "") {
            cell[0].classList.add(donotloadclass);
          }
          cell.html(
            `<a href="CarrierEntry/${rowData.id}" class="${donotloadclass}" target="_blank">${rowData.code}</a>`
          );

          return cell[0].outerHTML;
        }
        return defaultHTML;
      },
    },
    {
      text: "Name",
      datafield: "name",
      width: 200,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "MC Number",
      datafield: "mcNumber",
      width: 100,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "DOT Number",
      datafield: "dotNumber",
      width: 100,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Intrastate",
      datafield: "intrastate",
      width: 115,
      filtertype: "bool", // checkbox
      columntype: "checkbox",
      editable: false,
      //    filtertype: 'checkedlist', filteritems: [{ value: "Yes", label: "Yes" }, { value: "No", label: "No" } ]
    },
    {
      text: "Phone",
      datafield: "phone",
      filterable: false,
      width: 105,
    },
    {
      text: "City",
      datafield: "city",
      width: 100,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "State",
      datafield: "state",
      width: 100,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Zip",
      datafield: "zip",
      width: 100,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },

    {
      text: "OOS",
      datafield: "isOOS",
      width: 120, //filtertype: 'list', filteritems: [{ value: true, label: "True" }, { value: false, label: "False" }],
      filtertype: "bool", // checkbox
      columntype: "checkbox",
      editable: false,
    },
    {
      text: "No Dispatch",
      datafield: "noDispatch",
      width: 120, //filtertype: 'list', filteritems: [{ value: true, label: "True" }, { value: false, label: "False" }],
      filtertype: "bool", // checkbox
      columntype: "checkbox",
      editable: false,
    },
    {
      text: "Intrastate Code",
      datafield: "intrastateCode",
      width: 130,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Intrastate State",
      datafield: "intrastateState",
      width: 100,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },

    { 
      text: "Status", 
      datafield: "isActive", 
      filteritems: ["Active", "Inactive"],
      filtertype: "checkedlist",
      width: 120 
  },
    {
      text: "Rating",
      datafield: "rating",
      width: 140,
      filtertype: "list",
      filteritems: [
        {
          value: 5,
          label: "5",
          html: "<img src='../Content/Images/5star.png' />",
        },
        {
          value: 4,
          label: "4",
          html: "<img src='../Content/Images/4star.png' />",
        },
        {
          value: 3,
          label: "3",
          html: "<img src='../Content/Images/3star.png' />",
        },
        {
          value: 2,
          label: "2",
          html: "<img src='../Content/Images/2star.png' />",
        },
        {
          value: 1,
          label: "1",
          html: "<img src='../Content/Images/1star.png' />",
        },
      ],
      cellsrenderer: function (
        row,
        columnfield,
        value,
        defaultHTML,
        column,
        rowData
      ) {
        if (defaultHTML != null) {
          let pk = $grid.jqxGrid("getcellvalue", row, "id");
          var fieldset = $("<fieldset>");
          fieldset.addClass("star-rating");
          fieldset.attr("id", "jqxRating_" + pk);

          for (var i = 5; i > 0; i--) {
            var id = "jqxRating_" + pk + i;
            var input = $("<input>");
            input.attr({
              type: "radio",
              id: "star" + i,
              name: "rating",
              value: i,
            });
            input.appendTo(fieldset);
            var label = $("<label>").text(i);
            if (value == i) {
              label.attr({ for: "star" + i });
            }
            label.insertAfter(input);
            //set the div as click target
            $(document)
              .off("click", "#jqxRating_" + pk)
              .on("click", "#jqxRating_" + pk, function (event) {
                var newRating;
                var previousRating;
                //added tagName condition because click event firing for both the label and input of each star element
                //iterate through each of the 5 star-ratings for this target div
                if (event.target.tagName == "LABEL") {
                  //get the value of the star-rating the user selected (1, 2, 3, 4, 5)
                  newRating = event.target.previousSibling.value;
                  $(event.target.parentElement.children).each(function (i) {
                    if (this.tagName == "LABEL") {
                      //if the label has a 'for' attribute, this will be the previous rating
                      if (this.attributes.length > 0) {
                        if (this.attributes.for.value != null) {
                          previousRating = this.attributes.for.value.slice(-1);
                        }
                        //if the user is selecting a new rating, remove the current 'for' attribute
                        //this is how the css determines how many stars to highlight
                        //attribute 'for: star4' will show four stars highlighted, etc.
                        if (previousRating != newRating) {
                          $(this).removeAttr("for");
                        }
                      }
                    }
                  });
                  if (previousRating != newRating) {
                    //get the Id of the input for the label selected by the user (star1, star2, star3, star4, star5)
                    var inputId =
                      event.target.previousSibling.attributes.getNamedItem(
                        "id"
                      ).value;
                    //set the 'for' attribute for the label the user selected to the Id above.
                    //if the user selected a 'star4', then set the corresponding label to 'for: star4'
                    //this impacts the css applied
                    $(event.target).attr("for", inputId);
                    //get the id for the selected carrier by getting the parent id of the selected star input
                    var id = event.target.parentElement.id.replace(
                      "jqxRating_",
                      ""
                    );
                    var params = {
                      objectId: id,
                      value: newRating,
                      agencyId: userProfile.currentAgencyId(),
                      favTable: 19,
                    };
                    dataModel
                      .ajaxRequest("Rating", "post", params)
                      .done(function (data, textStatus, jqXHR) {
                        updateGrid = true;
                        //ratingUpdated = true;
                        $("#divCarrierPanel").notify(" Rating Updated", {
                          position: "top right",
                          className: "info",
                        });

                        $grid.jqxGrid("updatebounddata", "data");
                      })
                      .fail(function (error, msg, d) {});
                  }
                }
              });
          }
          return fieldset[0].outerHTML;
        }
        return defaultHTML;
      },
      createfilterwidget: function (column, columnElement, widget) {
        widget.jqxDropDownList({
          dropDownWidth: 140,
          renderer: function (index, label, value) {
            if (value != "") {
              return "<img src='../Content/Images/" + value + "star.png' />";
            } else {
              return "Any";
            }
          },
        });
      },
    },
    {
      text: "Equipment",
      datafield: "equipment",
      width: 120,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Type",
      datafield: "carrierType",
      width: 120,
      filtertype: "checkedlist",
      filteritems: [
        { html: "Carrier", label: "Carrier" },
        { html: "Permits", label: "Permits" },
        { html: "Escorts", label: "Escorts" },
        { html: "Specialized", label: "Specialized" },
      ],
    },
    {
      text: "California Emissions",
      datafield: "californiaEmission",
      filteritems: ["Yes", "No"],
      filtertype: "checkedlist",
      width: 150,
    },
    {
      text: "Performance Rating",
      datafield: "performanceRating",
      width: 150,
      filtertype: "checkedlist",
      filteritems: ["New", "Approved", "Do Not Load"],
    },
    { text: "Terminal", datafield: "Terminal", width: 120, filterable: false },
    {
      text: "Cargo Insurance Amount",
      datafield: "cargoInsuranceAmount",
      width: 180,
      cellsformat: "C2",
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Cargo Insurance Renewal Date",
      datafield: "cargoInsuranceRenewalDate",
      cellsformat: "MM/dd/yyyy",
      width: 220,
      createfilterwidget: geDateRangeFilterWidget,
    },
    {
      text: "Cargo Insurance Policy Number",
      datafield: "cargoInsurancePolicyNumber",
      width: 230,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Liability Amount",
      datafield: "liabilityAmount",
      width: 120,
      cellsformat: "C2",
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Liability Expiration Date",
      datafield: "liabilityExpirationDate",
      cellsformat: "MM/dd/yyyy",
      width: 220,
      createfilterwidget: geDateRangeFilterWidget,
    },
    {
      text: "Liability Insurance Policy Number",
      datafield: "liabilityInsurancePolicyNumber",
      width: 230,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Contract Date",
      datafield: "contractDate",
      cellsformat: "MM/dd/yyyy",
      width: 220,
      createfilterwidget: geDateRangeFilterWidget,
    },

    {
      text: "Interchange Agreement",
      datafield: "interchangeAgreement",
      filteritems: ["Yes", "No"],
      filtertype: "checkedlist",
      width: 150,
    },
    {
      text: "Trailer Interchange",
      datafield: "trailerInterchange",
      filteritems: ["Yes", "No"],
      filtertype: "checkedlist",
      width: 150,
    },
    {
      text: "Trailer Interchange Insurance Expiration",
      datafield: "trailerInterchangeInsuranceExpiration",
      cellsformat: "MM/dd/yyyy",
      width: 220,
      createfilterwidget: geDateRangeFilterWidget,
    },
    {
      text: "Trailer Interchange Insurance Amount",
      datafield: "trailerInterchangeInsuranceAmount",
      width: 180,
      cellsformat: "C2",
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Trailer Interchange Insurance Company",
      datafield: "trailerInterchangeInsuranceCompany",
      width: 230,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Trailer Interchange Insurance Policy Number",
      datafield: "trailerInterchangeInsurancePolicy",
      width: 230,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Relationship Manager",
      datafield: "relationshipManager",
      width: 230,
      filtercondition: "starts_with",
      createfilterwidget: placeHolderFilterWidget,
    },
    {
      text: "Last Note Date",
      datafield: "lastNoteDate",
      cellsformat: "MM/dd/yyyy",
      width: 220,
      createfilterwidget: geDateRangeFilterWidget,
    },
  ];
};

export default { viewModel: CarriersViewModel, template: template };
