import dataModel from "data-model";
import userprofile from "user-profile";
import { noop, datetimeUTC } from "global-functions";
import TNTService, { GetTransitStatus } from "../../../services/TNTService";
import MapQuestHelper from "../../../utils/MapQuestHelper";
import { useDispatch } from "ko-data-store";
import { isLoading } from "../../../dataStore/actions/appUI";
import { useState } from "data-store";

const getAgencyMovementsWithActiveTrackingAsync = ({
  customer = [],
  carrierName = [],
  carrierCode = [],
  agencies = [],
  pickupDateFrom = undefined,
  deliveryDateFrom = undefined,
  pickupDateTo = undefined,
  deliveryDateTo = undefined,
  transitStatus = undefined,
  sortBy = "deliveryDate",
  sortDescending = false,
  orderNumber = [],
} = {}) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        "Order/GetMovementsWithActiveTracking",
        "POST",
        {
          customers: customer.map((x) => x.value),
          carrierNames: carrierName.map((x) => x.value),
          carrierCodes: carrierCode.map((x) => x.value),
          searchAgencyIds: agencies.map((x) => x.id),
          pickupDateFrom,
          deliveryDateFrom,
          pickupDateTo,
          deliveryDateTo,
          transitStatus,
          sortBy,
          sortDescending,
          orderNumbers: orderNumber.map((x) => x.value),
        },
        true
      )
      .done(resolve)
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            `An error occurred while processing.`
        )
      );
  });
};

const getRecentLocations = async (trackingIds = []) => {
  try {
    const result = await TNTService.fetchRecentLocationsAsync(trackingIds);
    return result || [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getAllLocations = async (carrierTrackingRecordId) => {
  try {
    const result = await TNTService.fetchAllLocationsForCarrierTrackingAsync(
      carrierTrackingRecordId
    );
    return result || [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getAllLocationsAndStops = async (carrierTrackingRecordId) => {
  try {
    const result =
      await TNTService.fetchAllLocationsAndStopsForCarrierTrackingAsync(
        carrierTrackingRecordId
      );
    return result || [];
  } catch (err) {
    console.error(err);
    return [];
  }
};

const mapRenderer = (mapId, onCenter = noop) => {
  const mapTools = new MapQuestHelper();
  let map;
  let ready = false;

  let renderedMapRef;

  const centerMap = () => {
    if (renderedMapRef) {
      onCenter();
      renderedMapRef.setView([35.64701, -95.39425], 5);
    }
  };

  const init = async () => {
    if (!map || ready === false) {
      map = mapTools.loadMap(mapId, {
        zoom: 5,
        center: [35.64701, -95.39425],
        useFreeLayers: true,
        maxZoom: 18,
      });

      mapTools.addControl("bottomleft", (renderedMap) => {
        renderedMapRef = renderedMap;
        const template = `<div title="Reset and center map" style="padding: 10px; cursor: pointer; color: #111">
                                    <i class="glyphicon glyphicon-screenshot" style="font-size: 24px; color: #111;"></i>
                                </div>`;

        const $temp = $(template);
        // $temp.on('click', (event) => {
        //     renderedMap.setView([35.64701, -95.39425], 5);
        // })
        $temp.on("click", centerMap);

        return $temp[0];
      });

      map.setView([35.64701, -95.39425], 5);

      ready = true;
    }
  };

  const refresh = (mapData = [], onComplete = noop) => {
    if (!ready) {
      setTimeout(() => {
        init().then(() => {
          mapTools.refreshCollection(mapData);
          map.setView([35.64701, -95.39425], 5);
          onComplete(map);
        });
      }, 300);
    } else {
      mapTools.refreshCollection(mapData);

      // No records? Recenter our view.
      if (mapData.length === 0) {
        map.setView([35.64701, -95.39425], 5);
      }

      return map;
    }
  };

  const refreshBreadcrumbs = (mapData = []) => {
    if (!ready) {
      setTimeout(() => {
        init().then(() => {
          mapTools.refreshPolylineCollection(mapData);
          //map.setView([35.64701, -95.39425], 5);
        });
      }, 300);
    } else {
      mapTools.refreshPolylineCollection(mapData);

      // No records? Recenter our view.
      if (mapData.length === 0) {
        map.setView([35.64701, -95.39425], 5);
      }

      return map;
    }
  };

  return {
    init,
    refresh,
    refreshBreadcrumbs,
  };
};

const mapPointLabel = ({
  orderExId = "",
  transitStatus = "",
  customer = "",
  carrierCode = "",
  lastStatusUpdate = "",
} = {}) => {
  return `<div style='text-align:left; margin-top: 10px'>
                <span>Order Number:  </span> <a href="Orders/${orderExId}" target="_blank" style="color: blue">${orderExId}</a>
                <br />
                <ul>
                    <li>Customer: ${customer}</li>
                    <li>Assignment: ${carrierCode}</li>
                    <li>Status: ${transitStatus}</li>
                    <li>Last Status Update: ${lastStatusUpdate}</li>
                </ul>
            </div>`;
};

const mapColor = (transitStatus = "") => {
  const colors = {
    green: `#00ba5d`,
    yellow: `#e7ff4a`,
    red: `#fa0025`,
    blue: `#1b38c4`,
    orange: `#ebad05`,
  };

  switch (transitStatus) {
    case "Delivers Today":
      return colors.orange;
    case "Delivers Next Day":
      return colors.yellow;
    case "In Transit":
      return colors.green;
    case "While tracking has started the driver has not enabled it yet. Please contact the driver to enable tracking.":
      return colors.blue;
    default:
      return colors.red;
  }
};

const filterModel = ({ label, value, propName }) => {
  return {
    label,
    value,
    propName,
  };
};

function AgencyTransferGridViewModel({
  leftSource = [],
  rightSource = [],
  onTransfer = noop,
} = {}) {
  const vm = this;

  vm.refreshGrid = ko.observable();
  vm.leftSource = ko.observableArray(leftSource);
  vm.rightSource = ko.observableArray(rightSource);

  vm.columns = [
    { text: "id", datafield: "id", hidden: true, columnType: "number" },
    { text: "Agency", datafield: "externalID", columnType: "textbox" },
  ];
  vm.fields = [
    { name: "id", type: "string" },
    { name: "externalID", type: "string" },
  ];
  vm.columnTransfer = () => {
    const availableIds = vm.leftSource().map((x) => x.id);
    const selectedIds = vm.rightSource().map((x) => x.id);

    onTransfer(selectedIds);
  };
}

function FilterModelViewModel({
  filters = {
    customer: ko.observableArray([]),
    carrierName: ko.observableArray([]),
    deliveryDateFrom: undefined,
    pickupDateFrom: undefined,
    deliveryDateTo: undefined,
    pickupDateTo: undefined,
    carrierCode: ko.observableArray([]),
    agencies: ko.observableArray([]),
    transitStatus: "All",
    sortBy: "Delivery Date",
    sortDescending: false,
    orderNumber: ko.observableArray([]),
  },
  onApply = noop,
  availableAgencies = [],
} = {}) {
  const vm = this;

  const agencyIds = filters.agencies().map((x) => x.id);
  vm.displayAgencyFilter = ko.observable(availableAgencies.length > 1);
  vm.agencyDualList = ko.observable(
    new AgencyTransferGridViewModel({
      leftSource: availableAgencies.filter(
        (x) => agencyIds.indexOf(x.id) == -1
      ),
      rightSource: availableAgencies.filter(
        (x) => agencyIds.indexOf(x.id) > -1
      ),
      onTransfer: (ids) =>
        filters.agencies(
          availableAgencies.filter((x) => ids.indexOf(x.id) > -1)
        ),
    })
  );

  vm.pickupDateInputFrom = ko.observable(filters.pickupDateFrom);
  vm.pickupDateInputTo = ko.observable(filters.pickupDateTo);

  vm.deliveryDateInputFrom = ko.observable(filters.deliveryDateFrom);
  vm.deliveryDateInputTo = ko.observable(filters.deliveryDateTo);

  vm.transitStatusList = ko.observableArray([
    "All",
    "In Transit",
    "Delivers Today",
    "Delivers Next Day",
    "Running Late",
  ]);

  vm.sortByList = ko.observableArray([
    "Customer",
    "Carrier/Driver",
    "Carrier/Tractor Code",
    "Pickup Date",
    "Delivery Date",
    "Transit Status",
    "Agency Code",
    "Order Number",
  ]);
  vm.selectedSortedByFilter = ko.observable(filters.sortBy);

  vm.sortDirectionList = ko.observableArray(["Ascending", "Descending"]);
  vm.selectedSortDirectionFilter = ko.observable(
    filters.sortDescending ? "Descending" : "Ascending"
  );

  vm.transitStatusSelectedFilter = ko.observable(filters.transitStatus);

  vm.customerInput = ko.observable();
  vm.carrierInput = ko.observable();
  vm.carrierCodeInput = ko.observable();
  vm.orderNumberInput = ko.observable();

  vm.customerFilters = ko.observableArray(filters.customer());
  vm.carrierFilters = ko.observableArray(filters.carrierName());
  vm.carrierCodeFilters = ko.observableArray(filters.carrierCode());
  vm.orderNumberFilters = ko.observableArray(filters.orderNumber());

  vm.handleAddFilter = (inputName) => {
    if (inputName === "customer") {
      const val = vm.customerInput();
      vm.customerFilters.push(
        filterModel({ label: val, value: val, propName: "customer" })
      );
      vm.customerInput("");
    } else if (inputName === "carrierName") {
      const val = vm.carrierInput();
      vm.carrierFilters.push(
        filterModel({ label: val, value: val, propName: "carrierName" })
      );
      vm.carrierInput("");
    }
    if (inputName === "carrierCode") {
      const val = vm.carrierCodeInput();
      vm.carrierCodeFilters.push(
        filterModel({ label: val, value: val, propName: "carrierCode" })
      );
      vm.carrierCodeInput("");
    }
    if (inputName === "orderNumber") {
      const val = vm.orderNumberInput();
      vm.orderNumberFilters.push(
        filterModel({ label: val, value: val, propName: "orderNumber" })
      );
      vm.orderNumberInput("");
    }
  };

  vm.handleRemoveFilter = (index, filter) => {
    if (filter === "customer") {
      vm.customerFilters(
        vm.customerFilters().filter((x, idx) => idx !== index)
      );
    } else if (filter === "carrierName") {
      vm.carrierFilters(vm.carrierFilters().filter((x, idx) => idx !== index));
    }
    if (filter === "carrierCode") {
      vm.carrierCodeFilters(
        vm.carrierCodeFilters().filter((x, idx) => idx !== index)
      );
    }
    if (filter === "orderNumber") {
      vm.orderNumberFilters(
        vm.orderNumberFilters().filter((x, idx) => idx !== index)
      );
    }
  };

  vm.handleApply = () => {
    filters.customer(vm.customerFilters());
    filters.carrierName(vm.carrierFilters());
    filters.deliveryDateFrom = vm.deliveryDateInputFrom();
    filters.pickupDateFrom = vm.pickupDateInputFrom();
    filters.deliveryDateTo = vm.deliveryDateInputTo();
    filters.pickupDateTo = vm.pickupDateInputTo();
    filters.carrierCode(vm.carrierCodeFilters());
    filters.transitStatus =
      vm.transitStatusSelectedFilter() === "All"
        ? undefined
        : vm.transitStatusSelectedFilter();
    (filters.sortBy = vm.selectedSortedByFilter()),
      (filters.sortDescending =
        vm.selectedSortDirectionFilter() === "Descending");
    filters.orderNumber(vm.orderNumberFilters());

    onApply(filters);
  };

  vm.handleReset = () => {
    vm.pickupDateInputFrom(undefined);
    vm.deliveryDateInputFrom(undefined);
    vm.pickupDateInputTo(undefined);
    vm.deliveryDateInputTo(undefined);
    vm.customerFilters([]);
    vm.carrierFilters([]);
    vm.carrierCodeFilters([]);
    vm.orderNumberFilters([]);
    vm.customerInput(undefined);
    vm.carrierInput(undefined);
    vm.carrierCodeInput(undefined);
    vm.transitStatusSelectedFilter("All");
    vm.selectedSortedByFilter("Delivery Date");
    vm.selectedSortDirectionFilter("Ascending");

    vm.agencyDualList(
      new AgencyTransferGridViewModel({
        leftSource: availableAgencies.filter(
          (x) => agencyIds.indexOf(x.id) == -1
        ),
        rightSource: availableAgencies.filter(
          (x) => agencyIds.indexOf(x.id) > -1
        ),
      })
    );
  };
}

// Memoization -> object key => JSON.stringify(filterData), results => Array of result data from api []
// After 5 mins then will return empty [] to recommend an api call from calling methods.
function filteredCache(cache = {}, expiresLimit = 5 * 60 * 1000) {
  const remove = (key) => {
    delete cache[key];
    return key;
  };

  const add = (key, result = []) => {
    cache[key] = { result, cacheTimestamp: Date.now() };
    return result;
  };

  const get = (key, forceRefresh) => {
    const { result, cacheTimestamp } = cache[key] || {};
    if (cacheTimestamp + expiresLimit < Date.now() || forceRefresh) {
      if (forceRefresh) {
        remove(key);
      }

      return [];
    }

    return result || [];
  };

  return {
    get,
    add,
  };
}

function OrderTrackingPageViewModel() {
  const vm = this;
  const [recordsList, setRecordsList] = useState([]);
  const dispatch = useDispatch();

  const agencyList = [...userprofile.userAgencies().filter((x) => x.isActive)];

  const mapRender = mapRenderer("orderTrackingMapContainer", () => {
    vm.activeListItemIndex(null);
    filteredRecords(filteredRecords());
  });

  const cacheResults = filteredCache({});
  let trackingMap = {}; // set downstream

  let removeFilterTimeout;
  vm.removeFilter = async ($index, propName) => {
    clearTimeout(removeFilterTimeout);

    const newLabels = vm.filterLabels().filter((x, index) => index !== $index);
    vm.filterLabels(newLabels);

    if (
      [
        "deliveryDateFrom",
        "deliveryDateTo",
        "pickupDateFrom",
        "pickupDateTo",
        "transitStatus",
      ].indexOf(propName) > -1
    ) {
      filters[propName] = undefined;
    } else {
      filters[propName]([]);
    }

    removeFilterTimeout = setTimeout(async () => {
      await loadSourceRecords(filters);
    }, 600);
  };

  const skip = ko.observable(0).extend({ notify: "always" });
  const take = 5;

  // Records that have filtering applied.
  const filteredRecords = ko.observableArray([]).extend({ notify: "always" });
  // records that are displayed in the view. Not all will show at once, depending on filters and paging.
  vm.viewingRecords = ko.pureComputed(() => {
    const records = filteredRecords();
    //const end = (skip() + take) <= records.length ? (skip() + take) : records.length; // TODO: if want paging add this back.

    const pagedRecords = records.map((x, index) => {
      return { ...x, symbol: index + 1 };
    }); //.filter((x, index) => index >= skip() && index < end); // TODO: if want paging add this back. (the .filter portion)

    refreshMap(pagedRecords);

    // when only 1 record then grab bread crumbs
    if (pagedRecords.length === 1) {
      vm.handleToggleShowOnMap(pagedRecords[0], 0);
    } else {
      refreshBreadCrumbs([]);
    }

    return pagedRecords;
  });

  vm.totalRecordsText = ko.pureComputed(
    () => `${filteredRecords().length} total records.`
  );

  const filters = {
    customer: ko.observableArray([]),
    carrierName: ko.observableArray([]),
    deliveryDateFrom: undefined,
    pickupDateFrom: undefined,
    deliveryDateTo: undefined,
    pickupDateTo: undefined,
    carrierCode: ko.observableArray([]),
    agencies: ko.observableArray([]),
    transitStatus: undefined,
    sortBy: "Delivery Date",
    sortDescending: false,
    orderNumber: ko.observableArray([]),
  };

  //vm.filterLabels = ko.observableArray([agencyList.length > 1 ? { label:`${agencyList.length}+ Agencies`, propName: 'agencies' } : { label: agencyList[0].externalID, propName: 'agencies' }]);
  vm.filterLabels = ko.observableArray([]);

  vm.determineListItemColor = ({ transitStatus = "" } = {}) => {
    return mapColor(transitStatus);
  };

  vm.activeListItemIndex = ko.observable();
  let pauseMapDetails = false;
  vm.handleToggleShowOnMap = async (
    {
      carrierTrackingRecordId = 0,
      transitStatus = "",
      movementId = 0,
      deliveryDate = "",
      trackingCustomerName = "",
      carrierCode = "",
      orderExId = "",
    } = {},
    $index
  ) => {
    if (pauseMapDetails) return;
    pauseMapDetails = true;

    const selectedIndex = ko.toJS($index);
    const isNewIndex = selectedIndex !== vm.activeListItemIndex();
    vm.activeListItemIndex(
      isNewIndex || filteredRecords().length === 1 ? selectedIndex : null
    );

    if (isNewIndex || filteredRecords().length === 1) {
      dispatch(isLoading(true));
      let { tntLocations, tntStops, geOrderStops } =
        (await getAllLocationsAndStops(carrierTrackingRecordId)) || {
          tntLocations: [],
          tntStops: [],
          geOrderStops: [],
        };
      dispatch(isLoading(false));

      // Status calls or param data when status calls is empty to show shipper stop as the 'active' pin
      const locs =
        tntLocations.length > 0
          ? tntLocations
          : [filteredRecords()[selectedIndex]];

      // CarrierTracking Stops 'Neutral Pins'
      const stopPins = tntStops
        .filter(
          (x, index) =>
            (tntLocations.length || index > 0) &&
            x.latitude != null &&
            x.longitude != null
        )
        .map((x, index) => {
          const geStopInfo = geOrderStops.find(
            (q) => q.externalID == x.externalId || q.externalID == x.referenceId
          ); // TOOD: will need to remove referenceID when that change is made.

          const dateText = () => {
            // if(x.actualDeparture) {
            //     return `Actual Departure: ${(x.actualDeparture && datetimeUTC(x.actualDeparture).format("MM/DD/YYYY HH:mm")) || 'N/A'}`
            // }

            return `Scheduled Arrival: ${
              (x.scheduledArrival &&
                datetimeUTC(x.scheduledArrival).format("MM/DD/YYYY HH:mm")) ||
              "N/A"
            }`;
          };

          const popup = () => {
            // Use ge order info for 'popup' when we have it
            if (geStopInfo) {
              return `<div style="margin-top: 10px">${geStopInfo.name}</div><div>${geStopInfo.address}</div> <div>${geStopInfo.city}, ${geStopInfo.state}</div><div>Stop Type: ${geStopInfo.stopDescription}</div>`; //<div>${dateText()}</div>
            } else {
              // else use the tnt stop info, which doesn't have the stop name
              return `<div style="margin-top: 10px">${x.address}</div> <div>${x.city}, ${x.state}</div>`; //<div>${dateText()}</div>
            }
          };

          const getSymbol = ({ stopDescription = "" } = {}) => {
            switch (stopDescription) {
              case "Pickup":
                return "P";
              case "Delivery":
                return "D";
              default:
                return ""; // use the neutral filler pin (no letter or number)
            }
          };

          return {
            latitude: x.latitude, // always use the tnt stop lat/lng
            longitude: x.longitude,
            title: dateText(),
            info: popup(),
            iconColor: "#4993c9",
            symbol: getSymbol(geStopInfo),
            markerOptions: {
              zIndexOffset: 50,
              opacity: 0.8,
            },
          };
        });

      const locNeutralPins = tntLocations
        .filter(
          (x, index) =>
            (tntLocations.length || index > 0) &&
            x.latitude != null &&
            x.longitude != null
        )
        .map((x, index) => {
          const dateText = () => {
            return `Status: ${
              (x.timestamp &&
                datetimeUTC(x.timestamp).format("MM/DD/YYYY HH:mm")) ||
              "N/A"
            }`;
          };

          return {
            latitude: x.latitude, // always use the tnt stop lat/lng
            longitude: x.longitude,
            title: dateText(),
            iconColor: "#6c8394",
            markerOptions: {
              zIndexOffset: 0,
              opacity: 0.8,
            },
          };
        });

      // Status calls 'Active Pin'
      const locationPins = locs
        .filter((x) => x.latitude != null && x.longitude != null)
        .map((x) => {
          return {
            id: movementId,
            latitude: x.latitude,
            longitude: x.longitude,
            title: `Scheduled Delivery: ${
              (deliveryDate &&
                datetimeUTC(deliveryDate).format("MM/DD/YYYY")) ||
              "N/A"
            }`,
            info: mapPointLabel({
              customer: trackingCustomerName,
              carrierCode,
              orderExId,
              transitStatus,
              lastStatusUpdate:
                (x.timestamp &&
                  datetimeUTC(x.timestamp).format("MM/DD/YYYY")) ||
                "N/A",
            }),
            iconColor: mapColor(transitStatus),
            symbol: selectedIndex + 1, // keep symbol matched to the select # from the list
            markerOptions: {
              zIndexOffset: 1000,
            },
          };
        });

      // Grab 1st locationPin for the 'active' pin
      // Stops -> generic pins
      const pins = [
        locationPins[0],
        ...stopPins,
        ...locNeutralPins.filter((x, index) => index > 0),
      ];
      const polylineLocs = [
        locationPins[0],
        ...locNeutralPins.filter((x, index) => index > 0),
      ];

      mapRender.refresh(pins); // markers
      refreshBreadCrumbs(tntLocations.length > 0 ? polylineLocs : []); // polyline
      pauseMapDetails = false;
    } else {
      filteredRecords(filteredRecords());
      pauseMapDetails = false;
    }
  };

  const refreshBreadCrumbs = (sourceData = []) => {
    mapRender.refreshBreadcrumbs([
      ...sourceData.map((x) => [x.latitude, x.longitude]),
    ]);
  };

  const refreshMap = (sourceData = []) => {
    mapRender.refresh(
      sourceData.map((x) => {
        return {
          id: x.movementId,
          latitude: x.latitude,
          longitude: x.longitude,
          title: `Scheduled Delivery: ${
            (x.deliveryDate &&
              datetimeUTC(x.deliveryDate).format("MM/DD/YYYY")) ||
            "N/A"
          }`,
          info: mapPointLabel({
            customer: x.trackingCustomerName,
            carrierCode: x.carrierCode,
            orderExId: x.orderExId,
            transitStatus: x.transitStatus,
            lastStatusUpdate: x.lastStatusTime,
          }),
          iconColor: mapColor(x.transitStatus),
          symbol: x.symbol,
        };
      }),
      (map) => {
        trackingMap = map;
      }
    );
  };

  const loadSourceRecords = async (filterData = {}, forceRefresh = false) => {
    let records = [];

    try {
      filterData = ko.toJS(filterData);

      const moves = async (records = []) => {
        try {
          dispatch(isLoading(true));
          const activeTrackingMoves =
            await getAgencyMovementsWithActiveTrackingAsync(filterData);
          dispatch(isLoading(false));

          return activeTrackingMoves;
        } catch (err) {
          console.error(err);
          return records;
        }
      };

      const tracking = async (records = []) => {
        let mapped = [];

        try {
          if (records.length === 0) return records;

          dispatch(isLoading(true));
          const trackingRecords = await getRecentLocations(
            records.map(
              ({ carrierTrackingRecordId }) => carrierTrackingRecordId
            )
          );
          dispatch(isLoading(false));

          records.forEach((record, index) => {
            // map our tracking info
            const tntRecord = trackingRecords.find(
              (x) =>
                x.carrierTrackingRecordId === record.carrierTrackingRecordId
            );

            if (tntRecord) {
              const { trackingId, lastLocation, shipperStop, customerName } =
                tntRecord;

              record.latitude =
                lastLocation && lastLocation.latitude != null
                  ? lastLocation.latitude
                  : shipperStop != null
                  ? shipperStop.latitude
                  : null;
              record.longitude =
                lastLocation && lastLocation.longitude != null
                  ? lastLocation.longitude
                  : shipperStop != null
                  ? shipperStop.longitude
                  : null;
              record.trackingId = trackingId;
              record.pickupDate =
                record.pickupDate &&
                datetimeUTC(record.pickupDate).format("MM/DD/YYYY HH:mm");
              record.deliveryDate =
                record.deliveryDate &&
                datetimeUTC(record.deliveryDate).format("MM/DD/YYYY HH:mm");

              record.trackingCustomerName = customerName;
              record.lastStatusTime =
                (lastLocation &&
                  datetimeUTC(lastLocation.timestamp).isValid() &&
                  datetimeUTC(lastLocation.timestamp).format(
                    "MM/DD/YYYY HH:mm"
                  )) ||
                "N/A";

              record.transitStatus =
                lastLocation === null
                  ? "While tracking has started the driver has not enabled it yet. Please contact the driver to enable tracking."
                  : GetTransitStatus(record.deliveryDate, new Date());

              if (record.latitude != null && record.longitude != null) {
                mapped.push(record);
              }
            }
          });

          return mapped;
        } catch (err) {
          console.error(err);
          return mapped;
        }
      };

      // get cache records for the filter / params.
      // If forceRefresh is true or no records then do an api call to get anything new.
      records = cacheResults.get(JSON.stringify(filterData), forceRefresh);

      if (records.length === 0) {
        records = await tracking(await moves([]));
        cacheResults.add(JSON.stringify(filterData), records);
      }

      // Filter out transitStatus since it's being computed from the UI
      if (filters.transitStatus) {
        records = records.filter(
          (x) =>
            x.transitStatus.toUpperCase() ===
            filters.transitStatus.toUpperCase()
        );
      }

      vm.activeListItemIndex(null);
      setRecordsList(records);
      skip(0);
      filteredRecords(records);
    } catch (err) {
      dispatch(isLoading(false));
      console.error(err);

      vm.activeListItemIndex(null);
      setRecordsList(records);
      skip(0);
      filteredRecords(records);
    }
  };

  vm.handlePagingClick = (type = "") => {
    switch (type) {
      case "previous":
        return pagePrev();
      case "next":
        return pageNext();
      default:
        return;
    }
  };

  vm.filtersModal = ko.observable();
  vm.handleShowFilterModal = () => {
    vm.filtersModal(
      new FilterModelViewModel({
        filters,
        onApply: (filterData = {}) => {
          vm.filtersModal(null);

          let labels = [];
          filterData = ko.toJS(filterData);

          if (filterData.customer && filterData.customer.length) {
            labels.push(
              filterData.customer.length > 1
                ? {
                    label: `${filterData.customer.length}+ Customers`,
                    propName: "customer",
                  }
                : filterData.customer[0]
            );
          }

          if (filterData.carrierName && filterData.carrierName.length) {
            labels.push(
              filterData.carrierName.length > 1
                ? {
                    label: `${filterData.carrierName.length}+ Carriers`,
                    propName: "carrierName",
                  }
                : filterData.carrierName[0]
            );
          }
          if (filterData.carrierCode && filterData.carrierCode.length) {
            labels.push(
              filterData.carrierCode.length > 1
                ? {
                    label: `${filterData.carrierCode.length}+ Carrier Codes`,
                    propName: "carrierCode",
                  }
                : filterData.carrierCode[0]
            );
          }
          if (filterData.deliveryDateFrom) {
            labels.push({
              label: `D1: ${filterData.deliveryDateFrom.format("MM/DD/YYYY")}`,
              propName: "deliveryDateFrom",
            });
          }
          if (filterData.deliveryDateTo) {
            labels.push({
              label: `D2: ${filterData.deliveryDateTo.format("MM/DD/YYYY")}`,
              propName: "deliveryDateTo",
            });
          }
          if (filterData.pickupDateFrom) {
            labels.push({
              label: `P1: ${filterData.pickupDateFrom.format("MM/DD/YYYY")}`,
              propName: "pickupDateFrom",
            });
          }
          if (filterData.pickupDateTo) {
            labels.push({
              label: `P2: ${filterData.pickupDateTo.format("MM/DD/YYYY")}`,
              propName: "pickupDateTo",
            });
          }
          if (filterData.transitStatus) {
            labels.push({
              label: `S: ${filterData.transitStatus}`,
              propName: "transitStatus",
            });
          }
          if (filterData.agencies && filterData.agencies.length) {
            // agencies are not a filterModel, so create that here.
            labels.push(
              filterData.agencies.length > 1
                ? {
                    label: `${filterData.agencies.length}+ Agencies`,
                    propName: "agencies",
                  }
                : {
                    label: filterData.agencies[0].externalID,
                    propName: "agencies",
                  }
            );
          }
          if (filterData.orderNumber && filterData.orderNumber.length) {
            labels.push(
              filterData.orderNumber.length > 1
                ? {
                    label: `${filterData.orderNumber.length}+ Orders`,
                    propName: "orderNumber",
                  }
                : filterData.orderNumber[0]
            );
          }

          // if(filterData.sortBy) {
          //     labels.push({ label: `Sort By: ${filterData.sortBy}`, propName: 'sortBy'})
          // }
          // if(filterData.sortDescending !== undefined) {
          //     labels.push({ label: `${filterData.sortDescending ? 'Sort Desending' : 'Sort Ascending'}`, propName: 'sortDescending'})
          // }

          vm.filterLabels(labels);
          loadSourceRecords(filterData);
        },
        availableAgencies: agencyList,
      })
    );
  };

  vm.handleForceRefresh = async () => {
    vm.activeListItemIndex(null);
    await loadSourceRecords(filters, true);
  };

  const pageNext = () => {
    if (filteredRecords().length - skip() > take) {
      resetListItemsScroll();
      skip(skip() + take);
    }
  };

  const pagePrev = () => {
    resetListItemsScroll();
    skip(skip() - take <= 0 ? 0 : skip() - take);
  };

  const resetListItemsScroll = () => {
    const el = document.getElementById("viewingRecords");
    el.scrollTop = 0;
  };

  vm.handleListItemOrderLinkClick = ({ orderExId }, event) => {
    event.preventDefault();
    event.stopPropagation();

    router.navigate(`/Orders/${orderExId}`, true);
  };

  const initialize = async () => {
    await loadSourceRecords(ko.toJS(filters));
  };

  initialize();
}

import template from "./order-tracking-page.html";
import router from "router";
export default { viewModel: OrderTrackingPageViewModel, template };
