// Utility to pool state updates into a single source.
// This will pool state/data updates until all tasks are complete

// const updateQue = new processQue();
// updateQue.onComplete((mergedDataObj) => ...do something) - called when all item's have been processed.

// updateQue.debug() -> turn on console.log (logs) to see updates in realtime

// updateQue.waitOnTask('someName') -> add item to waitingList

// updateQue.taskFinished('someName', { test: '123' }) -> move item from
// waiting list to process que to merge supplied data object.
// Note: 'someName' is the name you gave in 'waitOnTask' call.

// updateQue.remove('someName') -> remove from waitingList

// Notes:
// - Items that have not had 'taskFinished' called after a timeout period will be removed
//   from the waitingList (garbarge collecting timeouts, etc)

export default function processQue() {
    
    let waitingList = [];
    let processList = [];
    let onCompleteCallBackFn = function(state) {};

    let debugMode = false;

    const mergeState = () => {
      if(processList.length == 0) return;
      
      if(waitingList.length) {
        setTimeout(mergeState, 300);
      } 
      else {
        let merged = processList.reduce((mergedState, stateToAdd) => {
          return {...mergedState, ...stateToAdd};
        }, {});
    
        processList = [];

        if(debugMode) consoleMsg(`*** MergeState *** : Items in processList have been merged: `, merged);

        onCompleteCallBackFn(merged);
      }
    }
    
   
    
    const timeout = 20; // 20 secs
    const flushQue = (internalId, errorMsg) => {
      if(waitingList.length == 0) return;
      
      let item = waitingList.find(x => x.internalId == internalId);
      if(item) {
        let time = item.queTime;
        let current = getTimestamp();
    
        if((time + timeout) <= current) {
          let id = getIdFromInternalId(internalId);
          errorMsg = "Reached time out period.";
          
          if(debugMode) consoleMsg(`${id} - Reached time out period.`);

          this.remove(id);
        }
        else {
          setTimeout(flushQue, 10000, internalId);
        }
      }
    }
    
    const getTimestamp = () => {
      return Math.floor(new Date().getTime() / 1000);
    }

    const getIdFromInternalId = (internalId) => {
        internalId = internalId || "";
        return internalId.substr(0, internalId.indexOf('@'));
    }

    const consoleMsg = (message, dataObj) => {
        let date = new Date();
        let timestamp = `[${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}]`;

        if(dataObj) {
            console.log(`${timestamp} - ${message}`, dataObj);
        }
        else {
            console.log(`${timestamp} - ${message}`);
        }
        
    }

    this.debug = () => {
        debugMode = true;
    }

    this.onComplete = (callBackFn) => {
        onCompleteCallBackFn = callBackFn;
    }
    
    this.waitOnTask = (id = "") => {
      if(typeof id !== 'string') throw new Error(`waitOnTask id argument is not of type string.`);
      if(id.indexOf("@") != -1) throw new Error(`waitOnTask id argument cannot use reserved '@' symbol.`);

      let internalId = id.toUpperCase() + '@QUE#' + waitingList.length;
      let queTime = getTimestamp();

      let error = "";
      waitingList.push({internalId: internalId, queTime: queTime});
     
      if(debugMode) consoleMsg(`WaitOnTask: ${id} added to waitingList. Current List:`, waitingList);

      flushQue(internalId, error);
    }

    this.remove = (id) => {
        if(typeof id !== 'string') throw new Error(`Invalid call to remove. The argument supplied must be the id string used in waitOnTask`);

        waitingList = waitingList.filter(x => getIdFromInternalId(x.internalId) != id.toUpperCase());

        if(debugMode) consoleMsg(`Remove: ${id} removed from waitingList. Current List: `, waitingList);
    }
      
    this.taskFinished = (id, obj) => {
        if(typeof id !== 'string') throw new Error(`First argument passed to taskFinished must be the id string used in waitOnTask`);
        if(typeof obj !== 'object' && Object.keys(obj).length == 0) throw new Error(`taskFinished requires an update state object as the second argument.`);

        if(debugMode) consoleMsg(`TaskFinished: ${id} data to update:`, obj);

        const items = waitingList.filter(x => getIdFromInternalId(x.internalId) == id.toUpperCase());

        if(items.length) {
            items.forEach((x) => {
                let id = getIdFromInternalId(x.internalId);
                processList.push(obj); 
            });
        }
        else {
          processList.push(obj); 
        }

        if(debugMode) consoleMsg(`TaskFinished: ${id} moved to processList. Current ProcessList: `, processList);
        this.remove(id);

        mergeState();
    }

    this.isWaitingOn = (id) => {
      return waitingList.some(x => getIdFromInternalId(x.internalId) == id.toUpperCase());
    }
}

