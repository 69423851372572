const RESET_STATE = "orderEntry/stopsEdiDelay/resetState";
const UPDATE_EDI_STATUS_FOR_STOP = "orderEntry/stopsEdiDelay/updateEdiStatusForStop";
const UPDATE_CUSTOMER_PARTNER_ID = "orderEntry/stopsEdiDelay/updateCustomerPartnerId";
const UPDATE_ORDER_ID = "orderEntry/stopsEdiDelay/updateOrderId";

let debugOn = false;

export const resetState = () => {
    if(debugOn) {
        console.log(`${RESET_STATE}`, initState);
    }

    return { type: RESET_STATE, payload: initState}
}

export const updateCustomerPartnerId = (partnerId) => {
    if(debugOn) {
        console.log(`${UPDATE_CUSTOMER_PARTNER_ID}`, partnerId);
    }

    return { type: UPDATE_CUSTOMER_PARTNER_ID, payload: partnerId }
}

export const updateOrderId = (orderId) => { 
    if(debugOn) {
        console.log(`${UPDATE_CUSTOMER_PARTNER_ID}`, orderId);
    }

    return { type: UPDATE_ORDER_ID, payload: orderId }
}

export const updateEdiStatusForStop = (recordId, delaycode) => {
    if(debugOn) {
        console.log(`${UPDATE_EDI_STATUS_FOR_STOP}`, recordId, delaycode);
    }

    if(recordId) {
        return { type: UPDATE_EDI_STATUS_FOR_STOP, payload: delaycode, meta: { recordId } }
    }

    return { type: "" }
}

// TODO: add the orderId, partnerId values on order load.
const initState = {
    orderId: undefined, // shared between stops on the order 
    customerPartnerId: undefined, // shared between stops on the order
    ediStatusUpdates: {} // key will be the ediStatus.id (see stops recordId in order-entry-stops-edit-delay grid)
}

export default function stopsEdiDelay(state = initState, action) {
    switch(action.type) {
        case RESET_STATE:
            return { ...initState };
        case UPDATE_EDI_STATUS_FOR_STOP:
            return {...state, ediStatusUpdates: {...state.ediStatusUpdates, [action.meta.recordId]: action.payload}};
        case UPDATE_CUSTOMER_PARTNER_ID:
            return {...state, customerPartnerId: action.payload }
        case UPDATE_ORDER_ID:
            return {...state, orderId: action.payload }
        default:
            return state;
    }
        
}


// SELECTORS
export const selectEdiStopsDelay = (state) => {
    const {stopsEdiDelay} = ko.toJS(state) || {};
    return { ...stopsEdiDelay }
}

export const selectEdiStops = (state) => {
    const {ediStatusUpdates} = selectEdiStopsDelay(ko.toJS(state)) || {};
    return { ...ediStatusUpdates }
}

export const selectOrderId = (state) => {
    const {orderId} = selectEdiStopsDelay(ko.toJS(state));
    return orderId;
}

export const selectPartnerId = (state) => {
    const {partnerId} = selectEdiStopsDelay(ko.toJS(state));
    return partnerId;
}