import dataModel from "data-model";
import { IPostSaveTrackingPayload } from "../models";

interface JQueryErrorResponse {
  responseJSON?: JQueryResponseJSON
}

interface JQueryResponseJSON {
  message?: string
}

export const fetchTntProvidersAsync = () => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Lookup/TrackingProviders")
      .then(resolve)
      .catch(() => reject(`Error occurred during request.`));
  });
};

export const fetchTntVendorsAsync = () => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Lookup/TrackingVendors")
      .then(resolve)
      .catch(() => reject(`Error occurred during request.`));
  });
};

export const getAgencyContactDetailsAsync = async () => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Agency/GetAgencyContactDetails")
      .then(resolve)
      .catch(() => reject(`Error occurred during request.`));
  });
};

export const sendSaveTrackings = async (
  payload: IPostSaveTrackingPayload[]
) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("v2/Tracking/SaveTracks", "POST", payload, true)
      .then(resolve)
      .catch((err) => {
        const error = err as JQueryErrorResponse;
        return reject(error.responseJSON && error.responseJSON.message || `Error occurred during request.`);
      })
  });
};

export const sendSearchTracking = async (sorTrackingId: string) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        "v2/Tracking/SearchTracking",
        "POST",
        { sorTrackingId },
        true
      )
      .then(resolve)
      .catch(() => reject(`Error occurred during request.`));
  });
};

export const sendCancelTracking = async (sorTrackingIds: string[]) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        "v2/Tracking/CancelTracking",
        "POST",
        { sorTrackingIds },
        true
      )
      .then(resolve)
      .catch(() => reject(`Error occurred during request.`));
  });
};

export const sendTracking = async (
  sorTrackingId: string,
  isBrokered: boolean,
  useELDTracking: boolean
) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        "v2/Tracking/SendTrack",
        "POST",
        { sorTrackingId, isBrokered, useELDTracking },
        true
      )
      .then(resolve)
      .catch(() => reject(`Error occurred during request.`));
  });
};

export const fetchDriverContactListAsync = async (driverExId: string) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("DriverContact/GetByExternalId", "GET", { driverExId }, true)
      .then(resolve)
      .catch(() => reject(`Error occurred during request.`));
  });
};

export const fetchLastLocationAsync = (sorTrackingId: string) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        "v2/Tracking/GetLastLocation",
        "GET",
        { sorTrackingId },
        true
      )
      .then(resolve)
      .catch(() => reject(`Error occurred during request.`));
  });
};

export const sendByPassTrackingAsync = (payload: {
  movementExId: string;
  byPassTracking: boolean;
}) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("v2/Tracking/SaveByPassTracking", "POST", payload, true)
      .then(resolve)
      .catch(() => reject(`Error occurred during request.`));
  });
};

export const fetchByPassTrackingAsync = (movementExId: string) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        "v2/Tracking/GetByPassTracking",
        "GET",
        { movementExId },
        true
      )
      .then(resolve)
      .catch(() => reject(`Error occurred during request.`));
  });
};
