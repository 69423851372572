import dayjs from "dayjs";
import dataModel from "data-model";
import { scrollToRequiredField } from "global-functions";

class ContactViewModel {
  constructor(model) {
    var self = this;
    self.id = ko.observable(model.id);
    self.name = ko.observable(model.name).extend({ required: true });
    self.title = ko.observable(model.title);
    self.phone = ko.observable(model.phone);
    self.fax = ko.observable(model.fax);
    self.email = ko.observable(model.email).extend({ email: true });
    self.isNew = ko.observable(model.isNew || false);
  }
}

class HoursViewModel {
  constructor(model) {
    var self = this;

    var getTime = function (input) {
      if (input == null) {
        return null;
      }

      var dateTime = new Date(input);
      var h =
          dateTime.getHours() > 12
            ? dateTime.getHours() - 12
            : dateTime.getHours(),
        m = (dateTime.getMinutes() < 10 ? "0" : "") + dateTime.getMinutes(),
        amOrPm = dateTime.getHours() >= 12 ? "pm" : "am";
      h = h == 0 ? 12 : h;
      return h + ":" + m + amOrPm;
    };

    self.sundayOpen = ko.observable(
      model.sundayOpen == null
        ? null
        : getTime(dayjs(model.sundayOpen).format("MM/DD/YYYY HH:mm"))
    );
    self.mondayOpen = ko.observable(
      model.mondayOpen == null
        ? null
        : getTime(dayjs(model.mondayOpen).format("MM/DD/YYYY HH:mm"))
    );
    self.tuesdayOpen = ko.observable(
      model.tuesdayOpen == null
        ? null
        : getTime(dayjs(model.tuesdayOpen).format("MM/DD/YYYY HH:mm"))
    );
    self.wednesdayOpen = ko.observable(
      model.wednesdayOpen == null
        ? null
        : getTime(dayjs(model.wednesdayOpen).format("MM/DD/YYYY HH:mm"))
    );
    self.thursdayOpen = ko.observable(
      model.thursdayOpen == null
        ? null
        : getTime(dayjs(model.thursdayOpen).format("MM/DD/YYYY HH:mm"))
    );
    self.fridayOpen = ko.observable(
      model.fridayOpen == null
        ? null
        : getTime(dayjs(model.fridayOpen).format("MM/DD/YYYY HH:mm"))
    );
    self.saturdayOpen = ko.observable(
      model.saturdayOpen == null
        ? null
        : getTime(dayjs(model.saturdayOpen).format("MM/DD/YYYY HH:mm"))
    );

    self.sundayClose = ko.observable(
      model.sundayClose == null
        ? null
        : getTime(dayjs(model.sundayClose).format("MM/DD/YYYY HH:mm"))
    );
    self.mondayClose = ko.observable(
      model.mondayClose == null
        ? null
        : getTime(dayjs(model.mondayClose).format("MM/DD/YYYY HH:mm"))
    );
    self.tuesdayClose = ko.observable(
      model.tuesdayClose == null
        ? null
        : getTime(dayjs(model.tuesdayClose).format("MM/DD/YYYY HH:mm"))
    );
    self.wednesdayClose = ko.observable(
      model.wednesdayClose == null
        ? null
        : getTime(dayjs(model.wednesdayClose).format("MM/DD/YYYY HH:mm"))
    );
    self.thursdayClose = ko.observable(
      model.thursdayClose == null
        ? null
        : getTime(dayjs(model.thursdayClose).format("MM/DD/YYYY HH:mm"))
    );
    self.fridayClose = ko.observable(
      model.fridayClose == null
        ? null
        : getTime(dayjs(model.fridayClose).format("MM/DD/YYYY HH:mm"))
    );
    self.saturdayClose = ko.observable(
      model.saturdayClose == null
        ? null
        : getTime(dayjs(model.saturdayClose).format("MM/DD/YYYY HH:mm"))
    );
  }
}

class LocationEntryViewModel {
  constructor(params) {
    var location = params.vm();
    // self.code = params.code;

    var self = this;
    self.isLoading = ko.observable(false);
    self.errors = ko.observableArray();

    //self.onSaveClick = function () {

    //};

    self.id = ko.observable(location.id);
    self.code = ko.observable(location.code).extend({ required: true });
    self.name = ko.observable(location.name).extend({ required: true });
    self.address1 = ko.observable(location.address1);
    self.address2 = ko.observable(location.address2);
    self.cityStateZip = ko
      .observable(location.cityStateZip)
      .extend({ required: true });
    self.billToCustomer = ko.observable(location.billToCustomer);
    self.commodity = ko.observable(location.commodity);
    self.latitude = ko.observable(location.latitude);
    self.longitude = ko.observable(location.longitude);
    self.insuranceAmount = ko.observable(location.insuranceAmount);
    self.appointmentRequired = ko.observable(location.appointmentRequired);
    self.steamshipLocation = ko.observable(location.steamshipLocation);
    self.salesPerson = ko.observable(location.salesPerson);

    self.hours = ko.observable(new HoursViewModel(location.hours || {}));
    self.directions = ko.observable(location.directions);
    self.loadingInstructions = ko.observable(location.loadingInstructions);
    self.unloadingInstructions = ko.observable(location.unloadingInstructions);
    self.notes = ko.observable(location.notes);

    self.contacts = ko.observableArray(
      ko.utils.arrayMap(location.contacts, function (contact) {
        return new ContactViewModel(contact);
      })
    );

    self.isNew = ko.pureComputed(function () {
      return self.id() > 0 == false;
    });

    self.currentContact = ko.observable();
    self.contactColumns = [
      {
        text: "&nbsp;",
        datafield: "Edit",
        columntype: "button",
        width: 50,
        cellsrenderer: function () {
          return "Edit";
        },
        buttonclick: function (row) {
          var contact = self.contacts()[row];
          self.currentContact(new ContactViewModel(ko.toJS(contact)));
        },
      },
      { text: "id", dataField: "id", hidden: true },
      { text: "Name", dataField: "name", width: 150 },
      { text: "Title", dataField: "title", width: 150 },
      { text: "Phone", dataField: "phone", width: 150 },
      { text: "Fax", dataField: "fax", width: 150 },
      { text: "Email", dataField: "email", width: 150 },
      {
        text: "&nbsp;",
        datafield: "delete",
        columntype: "button",
        width: 50,
        cellsrenderer: function (
          row,
          columnfield,
          value,
          defaultHTML,
          column,
          rowData
        ) {
          return "Delete";
        },
        buttonclick: function (row) {
          var contact = self.contacts()[row];
          self.contacts.remove(contact);
        },
      },
    ];
    self.showAddContact = function () {
      self.currentContact(new ContactViewModel({ isNew: true }));
    };
    self.saveContact = function () {
      var contact = self.currentContact();
      var validationErrors = ko.validation.group(self.currentContact);
      if (validationErrors().length > 0) {
        validationErrors.showAllMessages();
        return false;
      }

      if (contact.isNew()) {
        contact.isNew(false);
        contact.id(0 - self.contacts().length - 1);
        self.contacts.push(contact);
      } else {
        var match = ko.utils.arrayFirst(self.contacts(), function (item) {
          return contact.id() === item.id();
        });

        if (match) {
          var data = ko.toJS(contact);
          match.id(data.id);
          match.name(data.name);
          match.title(data.title);
          match.phone(data.phone);
          match.fax(data.fax);
          match.email(data.email);
        }
      }
      self.currentContact(undefined);
    };
    self.cancelContact = function () {
      self.currentContact(undefined);
    };

    self.submit = function () {
      //self.errors.push("newlocation added");
      //self.code("aaaa");
      //var code = params.code;
      ////code("MORNC");
      //params.code("MORNC");

      self.errors.removeAll();

      var validationErrors = ko.validation.group(self);
      if (validationErrors().length > 0) {
        validationErrors.showAllMessages();
        scrollToRequiredField();
        return false;
      }

      var data = ko.toJS(self);

      self.isLoading(true);

      if (location.id > 0) {
        dataModel
          .ajaxRequest(`location/${location.code}`, "patch", data)
          .done(function (result, status, xhr) {
            self.isLoading(false);

            if (location.onLocationSave != undefined) {
              location.onLocationSave();
            } else {
              if (params.locationId != null) {
                params.locationId(self.code());
              }
              params.vm(undefined);
            }
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.responseJSON != null) {
              self.errors.push(jqXHR.responseJSON.message);
            } else {
              self.errors.push("An unknown error occurred");
            }
            self.isLoading(false);
          });
      } else {
        dataModel
          .ajaxRequest("location", "post", data)
          .done(function (result, status, xhr) {
            self.isLoading(false);

            if (location.onLocationSave != undefined) {
              location.onLocationSave();
            } else {
              if (params.locationId != null) {
                params.locationId(self.code());
              }
              params.vm(undefined);
            }
          })
          .fail(function (jqXHR, textStatus, errorThrown) {
            if (jqXHR.responseJSON != null) {
              self.errors.push(jqXHR.responseJSON.message);
            } else {
              self.errors.push("An unknown error occurred");
            }
            self.isLoading(false);
          });
      }
    };
    self.cancel = function () {
      params.vm(undefined);
    };
    self.generateCode = function () {
      self.errors.removeAll();

      var data = {
        name: self.name(),
        cityStateZip: self.cityStateZip(),
      };

      self.isLoading(true);
      dataModel
        .ajaxRequest("location/GenerateCode", "post", data)
        .done(function (result, status, xhr) {
          self.code(result);
          self.isLoading(false);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
          if (jqXHR.responseJSON != null) {
            self.errors.push(jqXHR.responseJSON.messsage);
          } else {
            self.errors.push("An unknown error occurred");
          }
          self.isLoading(false);
        });
    };
  }
}

import template from "./location-entry-component.html";
export default { viewModel: LocationEntryViewModel, template: template };
