import ko from 'knockout';
import dataModel from 'data-model';
import { isEmpty } from 'global-functions';
import userProfile from 'user-profile';
import template from './alert-settings-component.html';
import * as $ from 'jquery';

export class LoadboardSettingViewModel {
    $parent
    processAlerts = ko.observable();
    isLoading = ko.observable(false);
    response = ko.observable("");
    errors = ko.observableArray([]);
    emailNotification = ko.observable();
    smsNotification = ko.observable();
    alertNotification = ko.observable();
    alertsPerHour = ko.observable()
    tractorRadius = ko.observable();
    daysOutForAlerts = ko.observable();
    tractorAlerts = ko.observable();
    tractorAlertSound = ko.observable();
    loadRadius = ko.observable();
    loadAlerts = ko.observable();
    loadAlertSound = ko.observable();
    matchTrailerTypes = ['All Trailers', 'Trailer Group', 'Trailer Type'];
    matchTrailer = ko.observable();
    postingDelay = ko.observable();
    isOwnerOperator = userProfile.isOwnerOperator;
    email = ko.observable();
    smsPhoneNumber = ko.observable();
    provider = ko.observable();
    cellProviders = ko.observableArray();
    brokerageAccount = ko.observable();
    brokerageBoards = ko.observableArray([]);

    constructor(data) {
        this.$parent = data.data
        this.getData().then((x) => {
            this.loadAccount(x);
            this.loadFreightboardAccounts();
            this.getBrokerageBoards();
            // $('#jqxFreightboardSettings').one('hidden.bs.modal', () => {
            //     //After opening for the first time, the modal doesn't reopen.
            //     this.$parent.showLoadboardSettings(false)
            // })
        })
    }

    getData = () => {
        return dataModel.ajaxRequest("LoadboardSettings/FreightBoardSettings")
    }

    loadAccount = (settings) => {
        this.isLoading = ko.observable(false);
        this.response("");
        this.errors([]);
        this.processAlerts(settings.processAlerts ? "Yes" : "No");
        this.emailNotification(settings.emailNotification ? true : false);
        this.smsNotification(settings.smsNotification ? true : false);
        this.alertNotification(settings.alertNotification ? true : false);
        this.alertsPerHour.extend({
            required: {
                onlyIf: () => {
                    if (this.alertNotification() === true) {
                        return true;
                    }
                    return false;
                }
            }
        });
        this.alertsPerHour(settings.alertsPerHour)

        this.tractorRadius(settings.tractorRadius != null ? settings.tractorRadius : 0);
        this.daysOutForAlerts(settings.daysOutForAlerts || 1);
        this.tractorAlerts(settings.tractorAlerts ? "Yes" : "No");
        this.tractorAlertSound(settings.tractorAlertSound != null ? settings.tractorAlertSound : "None");
        this.loadRadius(settings.loadRadius != null ? settings.loadRadius : 0);
        this.loadAlerts(settings.loadAlerts ? "Yes" : "No");
        this.loadAlertSound(settings.loadAlertSound != null ? settings.loadAlertSound : "None");
        this.matchTrailerTypes = ['All Trailers', 'Trailer Group', 'Trailer Type'];
        this.matchTrailer(settings.matchTrailer);
        this.postingDelay(settings.postingDelay != null ? settings.postingDelay : 0);
        this.isOwnerOperator = userProfile.isOwnerOperator;
        this.email(settings.email)
        this.email.extend({
            required: {
                message: 'Email is required',
                onlyIf: () => {
                    if (this.emailNotification() == true) {
                        return true;
                    }
                    return false;
                }
            },
            validation: {
                validator: (val, validate) => {
                    var isValid = true;
                    if (!ko.validation.utils.isEmptyVal(val)) {
                        var values = val.split(';');
                        $(values).each((index) => {
                            isValid = ko.validation.rules['email'].validator($.trim(this), validate);
                            return isValid; // short circuit each loop if invalid
                        });
                    }
                    return isValid;
                },
                message: 'Please enter valid email address (separate multiple email addresses using a semicolon ; )'
            }
        });
        this.smsPhoneNumber.extend({
            minLength: 10,
            required: {
                message: 'Phone number is required',
                onlyIf: () => {
                    if (this.smsNotification() == true) {
                        return true;
                    }
                    return false;
                }
            }
        });
        this.cellProviders(settings.cellProviders);
        this.smsPhoneNumber(settings.smsPhoneNumber)
        this.provider(settings.provider)
        this.provider.extend({
            required: {
                message: 'Cell provider is required',
                onlyIf: () => {
                    if (this.smsNotification() == true) {
                        return true;
                    }
                    return false;
                }
            }
        });
    }

    saveFreightboardSettings = (item) => {
        this.errors.removeAll();
        this.response("");
        var validationErrors = ko.validation.group(self);
        if (validationErrors().length > 0) {
            validationErrors.showAllMessages();
            return false;
        }
        this.isLoading(true);
        var data = {
            processAlerts: this.processAlerts() == "Yes" ? true : false,
            emailNotification: this.emailNotification(),
            smsNotification: this.smsNotification(),
            alertNotification: this.alertNotification(),
            alertsPerHour: this.alertsPerHour(),
            email: this.email(),
            phone: this.smsPhoneNumber() ? this.smsPhoneNumber().replace(/[-()\s]/g, '') : undefined,
            provider: this.provider(),
            tractorAlerts: this.tractorAlerts() === "Yes" ? true : false,
            tractorRadius: this.tractorRadius(),
            tractorAlertSound: this.tractorAlertSound(),
            daysOutForAlerts: this.daysOutForAlerts(),
            loadAlerts: this.loadAlerts() === "Yes" ? true : false,
            loadRadius: this.loadRadius(),
            loadAlertSound: this.loadAlertSound(),
            matchTrailer: this.matchTrailer(),
            postingDelay: this.postingDelay()
        };

        dataModel.ajaxRequest("LoadboardSettings/SaveFreightboardSettings", "post", data)
        .done((data, textStatus, jqXHR) => {
            this.response(data);
            this.isLoading(false);
        })
        .fail((jqXHR, textStatus, errorThrown) => {
            if (jqXHR.status == 400) {
                var response = jqXHR.responseJSON;
                this.errors.push(response.errors);
            }
            this.isLoading(false);
        });
    };

    ClearSMSNotification = (item) => {
        this.errors.removeAll();
        this.response("");
        var validationErrors = ko.validation.group(self);
        if (validationErrors().length > 0) {
            validationErrors.showAllMessages();
            return false;
        }
        this.isLoading(true);
        var data = {
            phone: this.smsPhoneNumber(),
            providerId: this.provider()
        };
        dataModel.ajaxRequest("LoadboardSettings/ClearSMSNotification", "post", data)
               .done((data, textStatus, jqXHR) => {
                   if (isEmpty(data) == false) {
                       this.response(data.response.message);
                       this.isLoading(false);
                       this.smsNotification(data.SMSNotificationtrue ? true : false);
                       this.smsPhoneNumber(data.SMSPhoneNumber);
                       this.provider(data.CellProviderId);
                   }
               })
            .fail((jqXHR, textStatus, errorThrown) => {
                if (jqXHR.status == 400) {
                    var response = jqXHR.responseJSON;
                    this.errors.push(response.errors);
                }
                this.isLoading(false);
            });
    };

    deleteFreightboardAccount = (row) => {
        var dataRecord = $("#jqxFreightboardAccountGrid").jqxGrid('getrowdata', row);
        dataModel.ajaxRequest("LoadboardSettings/" + dataRecord.id, "delete")
        .done((data, status, xhr) => {
            $("#jqxFreightboardAccountGrid").jqxGrid('updatebounddata');
        })
        .fail((error, msg, d) => {
            var g = "";
        });
    };

    loadFreightboardAccounts = () => {
        var source = {
            url: "LoadboardSettings/BrokerageAccounts",
            datatype: "json",
            datafields: [
                { name: 'id', type: 'int' },
                { name: 'userName', type: 'string' },
                { name: 'boardName', type: 'string' },
                { name: 'accountNumber', type: 'string' },
                { name: 'integrationId', type: 'string' },
                { name: 'brokerageBoardId', type: 'int' }
            ]
        };
        var dataAdapter = dataModel.getDataAdapter(source);
        $("#jqxFreightboardAccountGrid").jqxGrid({
            source: dataAdapter,
            width: '100%',
            autoheight: true,
            selectionmode: 'none',
            enablehover: false,
            columns: [
                { text: 'id', dataField: 'id', hidden: true },
                { text: 'User Name', dataField: 'userName', width: '45%' },
                { text: 'Board Name', dataField: 'boardName' },
                { text: 'brokerageBoardId', dataField: 'brokerageBoardId', hidden: true },
                {
                    text: '', datafield: 'Edit', columntype: 'button', width: 50,
                    cellsrenderer: () => {
                        return "Edit";
                    },
                    buttonclick: (row) => {
                        this.showFreightboardAccountModal(row);
                    }
                },
                {
                    text: '', datafield: 'Delete', width: 50, columntype: 'button',
                    cellsrenderer: () => {
                        return "Delete";
                    },
                    buttonclick: (row) => {
                        this.deleteFreightboardAccount(row);
                    }
                }
            ]
        });
        $("#jqxFreightboardAccountGrid").on("bindingcomplete", (event) => {
            $("#jqxFreightboardAccountGrid").parent().show("slow");
        });
    };

    getBrokerageBoards = () => {
        dataModel.ajaxRequest("LoadboardSettings/GetBrokerageBoards")
        .done((data, status, xhr) => {
            this.brokerageBoards(data);
        })
        .fail((error, msg, d) => { });
    };

    addFreightboardAccount = () => {
        this.brokerageAccount(new FreightboardAccount({ isEditMode: false }));
        $('#freightboardPopupWindow').modal('show');
    };

    showFreightboardAccountModal = (row) => {
        var dataRecord = $("#jqxFreightboardAccountGrid").jqxGrid('getrowdata', row);
        this.brokerageAccount(new FreightboardAccount({ isEditMode: true, userName: dataRecord.userName, brokerageBoardId: dataRecord.brokerageBoardId }));
        $('#freightboardPopupWindow').modal('show');
    };
}

export default { viewModel: LoadboardSettingViewModel, template: template };

class FreightboardAccount {
    constructor(data) {
        var self = this;
        self.errors = ko.observableArray([]);
        self.isLoading = ko.observable(false);
        self.isEditMode = ko.observable(data.isEditMode);
        self.userName = ko.observable(data.userName).extend({ required: { message: 'User name is required' } });
        self.accountNumber = ko.observable(data.accountNumber);
        self.brokerageBoardId = ko.observable(data.brokerageBoardId).extend({ required: { message: 'Board name is required' } });
        
        self.integrationId = ko.observable(data.integrationId).extend({
            required: {
                onlyIf: function () {
                    if (ko.unwrap(self.brokerageBoardId) == 1) {
                        return true;
                    }
                    return false;
                },
                message: "Integration ID is required when Truckstop is selected."
            }
        });
        
        self.password = ko.observable().extend({ required: { message: 'Password is required' } });
        self.confirm = ko.observable().extend({
            equal: {
                message: "Password doesn't match",
                params: self.password
            }
        });
        self.saveFreightboardAccount = function () {
            self.errors.removeAll();
            var validationErrors = ko.validation.group(self);
            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                return false;
            }

            self.isLoading(true);
            var data = {
                username: self.userName(),
                password: self.password(),
                boardNameId: self.brokerageBoardId(),
                accountNumber: self.accountNumber(),
                integrationId: self.integrationId()
            };

            if (self.isEditMode()) {
                dataModel.ajaxRequest("LoadboardSettings/UpdateFreightboardAccount", "post", data)
                .done(function (data, textStatus, jqXHR) {
                    $('#freightboardPopupWindow').modal('hide');
                    $("#jqxFreightboardAccountGrid").jqxGrid('updatebounddata');
                    self.isLoading(false);
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status == 400) {
                        var response = jqXHR.responseJSON;
                        self.errors.push(response.message);
                    }
                    self.isLoading(false);
                });
            }
            else {
                dataModel.ajaxRequest("LoadboardSettings/AddFreightboardAccount", "post", data)
                .done(function (data, textStatus, jqXHR) {
                    $('#freightboardPopupWindow').modal('hide');
                    $("#jqxFreightboardAccountGrid").jqxGrid('updatebounddata');
                    self.isLoading(false);
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status == 400) {
                        var response = jqXHR.responseJSON;
                        self.errors.push(response.message);
                    }
                    self.isLoading(false);
                });
            }
        };

        self.hideModal = () => {
            $('#freightboardPopupWindow').modal('hide');
        }
    }
}