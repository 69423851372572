import ko from 'knockout';
import dataModel from 'data-model';
import userProfile from 'user-profile';
import gridUtils from 'jqx.grid-utils';
import gridState from 'jqx.grid-state-component';
import {getQueryVariable} from 'global-functions';
import _ from 'lodash';

class AgencyUsersModal {
    constructor(agencyId) {
        var self = this;
        self.loadUsers = function () {
            var grid = $("#agencyUsersGrid");
            
            var source = {
                url: "User/GetUsersByAgency?agency=" + agencyId,
                datatype: "json",
                datafields: [{ name: "agency", type: "string" },
                             { name: "name", type: "string" },
                             { name: "phone", type: "string" },
                             { name: "email", type: "string" }
                ],
                filter: function (filters, recordsArray) {
                    grid.jqxGrid('updatebounddata', 'filter');
                },
                sort: function (column, direction) {
                    grid.jqxGrid('updatebounddata', 'sort');
                },
                formatdata: function (data) {
                    var filterinfo = grid.jqxGrid('getfilterinformation');
                    data = gridUtils.formatGridFilters(filterinfo, data);

                    return data;
                }
            };
            var dataAdapter = dataModel.getDataAdapter(source);

            grid.jqxGrid({
                source: dataAdapter,
                selectionmode: 'none',
                width: "100%",
                sortable: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                autoheight: true,
                virtualmode: true,
                columnsresize: true,
                columnsmenu: false,
                altrows: true,
                pagesize: 10,
                //  showtoolbar: true,
                //rendertoolbar: function (toolbar) {

                //},

                rendergridrows: function (obj) {
                    return obj.data;
                },
                ready: function () {

                },
                columns: [
                   { text: 'Agency', datafield: 'agency', filterable: false, sortable: false, width: 100 },
                   { text: 'Name', datafield: 'name', width: 200 },
                   { text: 'Phone', datafield: 'phone', width: 150 },
                   { text: 'Email', datafield: 'email' }
                ]
            });  
        };
    }
}

class AgencyDriversModal {
    constructor(agencyId) {
        var self = this;
        self.loadDrivers = function () {
            var driversGrid = $("#agencyDriversGrid");

            var source = {
                url: "Driver/GetDriversForAgency?agencycode=" + agencyId,
                datatype: "json",
                datafields: [
                        { name: "firstName", type: "string" },
                        { name: "lastName", type: "string" },
                        { name: "status", type: "string" },
                        { name: "driverId", type: "string" },
                        { name: "middleInitial", type: "string" },
                        { name: "agencyCode", type: "string" },
                        { name: "city", type: "string" },
                        { name: "state", type: "string" },
                        { name: "zip", type: "string" },
                        { name: "id", type: "int" },
                        { name: "twic", type: "bool" },
                        { name: "fast", type: "bool" }
                ],
                filter: function (filters, recordsArray) {
                    driversGrid.jqxGrid('updatebounddata', 'filter');
                },
                sort: function (column, direction) {
                    driversGrid.jqxGrid('updatebounddata', 'sort');
                },
                formatdata: function (data) {
                    var filterinfo = driversGrid.jqxGrid('getfilterinformation');
                    data = gridUtils.formatGridFilters(filterinfo, data);
                    return data;
                }
            };
            var dataAdapter = dataModel.getDataAdapter(source);

            driversGrid.jqxGrid({
                source: dataAdapter,
                selectionmode: 'none',
                width: "100%",
                sortable: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                autoheight: true,
                virtualmode: true,
                columnsresize: true,
                columnsmenu: false,
                altrows: true,
                pagesize: 10,
                //showtoolbar: true,
                //rendertoolbar: function (toolbar) {

                //},

                rendergridrows: function (obj) {
                    return obj.data;
                },
                ready: function () {

                },
                columns: [
                   { text: 'Agency', datafield: 'agencyCode', width: 150, filterable: false, sortable: false },
                    { text: 'Last Name', datafield: 'lastName', width: 150 },
                    { text: 'First Name', datafield: 'firstName', width: 150 },
                    { text: 'MI', datafield: 'middleInitial', width: 150, filterable: false },
                    { text: 'Driver Code', datafield: 'driverId', width: 150 },
                    { text: 'City', datafield: 'city', width: 150 },
                    { text: 'State', datafield: 'state', width: 150 },
                    { text: 'Zip', datafield: 'zip', width: 150 },
                    { text: 'TWIC', datafield: 'twic', width: 150, cellsalign: "center", columntype: 'checkbox', filtertype: 'bool' },
                    { text: 'FAST', datafield: 'fast', width: 150, cellsalign: "center", columntype: 'checkbox', filtertype: 'bool' }
                ]
               
            });
        };
    }
}

class EmployeeAgencyListVM {
    constructor() {
        var self = this;

        self.driverDetails = ko.observable();
        self.agencyDrivers = ko.observable();
        self.agencyUsers = ko.observable();
        var requestDataByClearFilters = false;

        var _fieldSafetyManagersFilterWidgetRef = null;
        var _freightTypeFilterWidgetRef = null;
        var _hazmatClassFilterWidgetRef = null;
        // List labels are the corresponding property names in the api data model context
        var _freightTypeList = [{ html: "Brokerage", label: "Brokerage" },
            { html: "Flatbed", label: "Flatbed" },
            { html: "Hazmat - Approved", label: "Hazmat" },
            { html: "Intermodal - Port", label: "IntermodalPort" },
            { html: "Intermodal - Rail", label: "IntermodalRail" },
            { html: "Power Only", label: "PowerOnly" },
            { html: "Refrigerated", label: "Refrigerated" },
            { html: "Heavy Haul", label: "HeavyHaul" },
            { html: "Van", label: "Van" }];
        var _hazmatClassList = [{ html: 'Explosives', label: 'HazClass1' },
            { html: 'Gases', label: 'HazClass2' },
            { html: 'Flammable and Combustible Liquids', label: 'HazClass3' },
            { html: 'Flammable Solids', label: 'HazClass4' },
            { html: 'Oxidizing Substances', label: 'HazClass5' },
            { html: 'Toxic Substances and Infections Substances', label: 'HazClass6' },
            { html: 'Radioactive Materials', label: 'HazClass7' },
            { html: 'Corrosives', label: 'HazClass8' },
            { html: 'Miscellaneous Hazardous Materials', label: 'HazClass9' }];

        var _fieldSafetyManagersList = new Array();

        self.loadAgencyList = function () {
            var grid = $("#agencyListGrid");
            var agencyId = getQueryVariable("code");

            var source = {
                url: "Agency/GetAgenciesForGrid",
                datatype: "json",
                datafields: [
                    { name: "code", type: "string" },
                    { name: "name", type: "string" },
                    { name: "city", type: "string" },
                    { name: "state", type: "string" },
                    { name: "zip", type: "string" },
                    { name: "phone", type: "string" },
                    { name: "active", type: "bool" },
                    { name: "id", type: "int" },
                    { name: "fieldSafetyManagers", type: "string" },
                    { name: "freightTypes", type: "string" },
                    { name: "hazmatClasses", type: "string" },
                    { name: "bridgeAgency", type: "string" },
                    { name: "contractDate", type: "date" },
                    { name: "termDate", type: "date" }
                ],
                filter: function (filters, recordsArray) {
                    grid.jqxGrid('updatebounddata', 'filter');
                },
                sort: function (column, direction) {
                    grid.jqxGrid('updatebounddata', 'sort');
                },
                formatdata: function (data) {

                    var filterinfo = grid.jqxGrid('getfilterinformation');
                    data = gridUtils.formatGridFilters(filterinfo, data);

                    // Apply jqx grid checked list items filtering fixes for select all / unselect all
                    if (_freightTypeFilterWidgetRef != null && requestDataByClearFilters == false) {
                        var listItems = _freightTypeFilterWidgetRef.jqxDropDownList('getCheckedItems');

                        // nothing is checked....
                        var items = new Array();
                        if (data['freightTypes'] != null && listItems.length == 0) {
                            delete data['freightTypes']; // remove the property as user chose unselect all
                        }
                        else if (listItems.length && (listItems[0].label == "(Select All)" && listItems[0].checked)) {  // select all is checked
                            
                            $.each(_freightTypeList, function (index, item) {
                                items.push(item.label);
                            });

                            data['freightTypes'] = items;
                        }

                    }

                    if (_fieldSafetyManagersFilterWidgetRef != null && requestDataByClearFilters == false) {  // radius search clears grid filters but this widget doesn't get updated set state to unchecked
                        var listItems = _fieldSafetyManagersFilterWidgetRef.jqxDropDownList('getCheckedItems');

                        // nothing is checked....
                        if (data['fieldSafetyManagers'] != null && listItems.length == 0) {
                            delete data['fieldSafetyManagers']; // remove the property as user chose unselect all
                        }
                        else if (listItems.length && (listItems[0].label == "(Select All)" && listItems[0].checked)) {  // select all is checked
                            var items = new Array();
                            $.each(_fieldSafetyManagersList, function (index, item) {
                                items.push(item.label);
                            });

                            data['fieldSafetyManagers'] = items;
                        }
                    }

                    if (_hazmatClassFilterWidgetRef != null && requestDataByClearFilters == false) {  // radius search clears grid filters but this widget doesn't get updated set state to unchecked
                        var listItems = _hazmatClassFilterWidgetRef.jqxDropDownList('getCheckedItems');

                        // nothing is checked....
                        if (data['hazmatClasses'] != null && listItems.length == 0) {
                            delete data['hazmatClasses']; // remove the property as user chose unselect all
                        }
                        else if (listItems.length && (listItems[0].label == "(Select All)" && listItems[0].checked)) {  // select all is checked
                            var items = new Array();
                            $.each(_hazmatClassList, function (index, item) {
                                items.push(item.label);
                            });

                            data['hazmatClasses'] = items;
                        }
                    }


                    requestDataByClearFilters = false;
                    return data;
                }
            };
            var dataAdapter = dataModel.getDataAdapter(source);
            
            grid.jqxGrid({
                //source: dataAdapter,
                selectionmode: 'none',
                width: "100%",
                sortable: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                autoheight: true,
                virtualmode: true,
                columnsresize: true,
                columnsreorder: true,
                columnsmenu: false,
                altrows: true,
                pagesize: 10,
                showtoolbar: true,
                rendertoolbar: function (toolbar) {
                    var vm1 = new gridState.viewModel(),
                        tmpl1 = gridState.template;

                    vm1.clearFilters = function () {
                        requestDataByClearFilters = true;
                        
                        grid.jqxGrid("clearfilters");

                        initCustomCheckListFilters();
                    }

                    $(toolbar).append(tmpl1);
                    ko.applyBindingsToDescendants(vm1, toolbar[0]);
                },

                rendergridrows: function (obj) {
                    return obj.data;
                },
                ready: function () {
                    initCustomCheckListFilters();
                    gridUtils.applyGridState("agencyListGrid", self.eaListSavedState);
                    grid.jqxGrid({source: dataAdapter});
                },
                columns: [
                   { text: 'Code', datafield: 'code', width: 100 },
                   { text: 'Name', datafield: 'name', width: 300 },
                   { text: 'City', datafield: 'city', width: 200 },
                   { text: 'State', datafield: 'state', width: 80 },
                   { text: 'Zip', datafield: 'zip', width: 100 },
                   { text: 'Phone', datafield: 'phone', width: 200 },
                    { text: 'Active', datafield: 'active', width: 100, columntype: "checkbox", filtertype: "bool"  },
                    {
                        text: 'Freight Types', datafield: 'freightTypes', width: 200,
                        filtertype: 'checkedlist',
                        filtercondition: "OR",
                        filteritems: _freightTypeList,
                        cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                            var $cellHtml = $(defaultHTML);
                            $cellHtml.css({ 'text-transform': 'capitalize' });

                            if (value) {
                                $cellHtml.attr('title', value);
                            }

                            return $cellHtml[0].outerHTML;
                        },
                        createfilterwidget: function (column, columnElement, widget) {
                            _freightTypeFilterWidgetRef = widget;
                        }
                    },
                    {
                        text: 'Hazmat Approved Classes', datafield: 'hazmatClasses', width: 220,
                        filtertype: 'checkedlist',
                        filtercondition: "OR",
                        filteritems: _hazmatClassList,
                        cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                            var $cellHtml = $(defaultHTML);
                            
                            $cellHtml.css({ 'text-transform': 'capitalize' });

                            if (value) {
                                $cellHtml.attr('title', value);
                            }

                            return $cellHtml[0].outerHTML;
                        },
                        createfilterwidget: function (column, columnElement, widget) {
                            _hazmatClassFilterWidgetRef = widget;
                        }
                    },
                    {
                        text: 'Field Safety Manager', datafield: 'fieldSafetyManagers', width: 200,
                        filtertype: 'checkedlist',
                        filtercondition: "OR",
                        filteritems: _fieldSafetyManagersList,
                        cellsrenderer: function (row, columnfield, value, defaultHTML, column, rowData) {
                            var $cellHtml = $(defaultHTML);
                            $cellHtml.css({ 'text-transform': 'capitalize' });

                            return $cellHtml[0].outerHTML;
                        },
                        createfilterwidget: function (column, columnElement, widget) {
                            _fieldSafetyManagersFilterWidgetRef = widget;
                        }
                    },
                    { text: 'Bridge Agency', datafield: 'bridgeAgency', width: 120 },
                    {
                        text: 'Contract Date', datafield: 'contractDate', width: 150,
                        filtertype: 'date',
                        cellsformat: 'MM/dd/yyyy',
                        createfilterwidget: function (column, columnelement, widget) {
                            $(widget).jqxDateTimeInput({
                                readonly: false,
                                formatString: "MM/dd/yyyy"
                            });
                            $(widget).find("input").attr("placeHolder", "MM/DD/YYYY");
                        },
                    },
                    {
                        text: 'Term Date', datafield: 'termDate', width: 150,
                        filtertype: 'date',
                        cellsformat: 'MM/dd/yyyy',
                        createfilterwidget: function (column, columnelement, widget) {
                            $(widget).jqxDateTimeInput({
                                readonly: false,
                                formatString: "MM/dd/yyyy"
                            });
                            $(widget).find("input").attr("placeHolder", "MM/DD/YYYY");
                        },
                    },
                   {
                       text: 'Users',
                       width: 100,
                       datafield: 'userButton',
                       filterable: false,
                       sortable: false,
                       columnType: 'button',
                       buttonclick: function (row) {
                           var datarow = grid.jqxGrid('getrowdata', row);
                            var userModel = new AgencyUsersModal(datarow.code);
                            self.agencyUsers(userModel);
                            userModel.loadUsers();
                        },
                        cellsrenderer: function () {
                            return "Click Here";
                        }
                   },
                   {
                       text: 'Drivers',
                       width: 100,
                       filterable: false,
                       sortable: false,
                       datafield: 'driverButton',
                       columnType: 'button',
                       buttonclick: function (row) {
                           var datarow = grid.jqxGrid('getrowdata', row);
                           
                           var driverModel = new AgencyDriversModal(datarow.code);
                           self.agencyDrivers(driverModel);
                           driverModel.loadDrivers();
                       },
                       cellsrenderer: function () {
                           return "Click Here";
                       }
                   }
                ]
            });

        };

        // helper to set the check list filters initial checked state to unchecked
        function initCustomCheckListFilters() {
            if (_fieldSafetyManagersFilterWidgetRef) {
                _fieldSafetyManagersFilterWidgetRef.jqxDropDownList('uncheckAll');
            }

            if (_freightTypeFilterWidgetRef) {
                _freightTypeFilterWidgetRef.jqxDropDownList('uncheckAll');
            }

            if (_hazmatClassFilterWidgetRef) {
                _hazmatClassFilterWidgetRef.jqxDropDownList('uncheckAll');
            }
        }

        self.fetchFieldSafetyManagers = () => {
            return new Promise(function (resolve) {
                dataModel.ajaxRequest("FieldSafetyManager/GetFieldSafetyManagers", "GET").done(function (data) {
                    if (data && data.length > 0) {
    
                        $.each(data, function (index, item) {
                            
                            _fieldSafetyManagersList.push({ html: item['name'], label: item['externalId'] });
                        });
                    }

                    resolve(true);
                });
            });
        }
        
        self.eaListSavedState = null;
        self.initialize = () => {
            Promise.all([gridUtils.getUserGridFiltersAsync("agencyListGrid"), self.fetchFieldSafetyManagers()]).then((promises) => {
                let filters = promises[0], safetyManagers = promises[1];

                if (_.isEmpty(filters.data) == false) {
                    var state = JSON.parse(filters.data);
                    self.eaListSavedState = state.grid;
                }

                self.loadAgencyList();
            });
        }

        self.initialize();
    }
}

import template from './employee-agency-list-page.html';
export default { viewModel: EmployeeAgencyListVM, template: template }