
import * as wireTypes from '../../types';
import {useSelector, useDispatch} from 'ko-data-store';
import {isLoading} from 'dataStore-actions/appUI';

/**
 * 
 * @param {object} params - KO component params object.
 * @param {number} params.fundLimit
 * @param {number} params.aopLimit
 * @param {function} params.onSelect - Callback function when option is selected.
 */
function efsSelectAdvanceTypePopupViewModel({ fundLimit = 0, aopLimit = 0, onSelect = function(){} }) {
    const dispatch = useDispatch();

    const _options = [];

    // Only allow option for what they have limits > 0
    if(ko.unwrap(fundLimit) > 0) _options.push({ type: wireTypes.ADVANCE_FUND, description: 'Fund Card'});
    if(ko.unwrap(aopLimit) > 0) {
         _options.push({ type: wireTypes.ADVANCE_EXPRESS, description: 'Issue AOP - Express Check'});
        _options.push({ 
            type: wireTypes.ADVANCE_WIRE,
            description: 'Issue AOP - Wire (Manual Entry)'});
    }

    this.options = ko.observableArray(_options);

    this.selectedType = ko.observable();
    this.selectedType.subscribe((val) => {
        if(val) {
            dispatch(isLoading(true));
            onSelect(_options.find(x => x.type == val)); 
        }
    })
}

import template from './efs-select-advance-type-popup-component.html';
export default { viewModel: efsSelectAdvanceTypePopupViewModel, template: template };