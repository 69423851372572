import dataModel from 'data-model';
import {getpassword} from 'show-dialog-methods'
import template from './order-entry-dispatch-create-wire-modal.html';
import userProfile from 'user-profile';
import dayjs from 'dayjs';

class CreateWireModel {
    constructor(params) {
        var self = this;

        self.movementReference = params.params.movementReference;
        self.viewingOrderId = ko.observable(params.params.viewingOrderId);
        self.wireData = ko.observableArray();
        self.errors = ko.observableArray();
        self.wireInProgress = ko.observable();
        self.maxWireAmount = ko.observable();
    
        self.isCreateWireVisible = ko.computed(function() {
            if(self.movementReference.manifestExId()) {
                return false;
            } else  if(!self.wireInProgress()) {
                return true
            } else if(self.wireInProgress().postStatus == "P") {
                return false;
            } else {
                return true;
            }
        })
        
        self.createWire = function() {
            getpassword("ATM cash password").then(function(password) {
                self.showCancelWireSuccessfulMessage(false);
                if(password) {
                    self.movementReference.$parent.showLoadingWheel(true);
                    self.addWireToOrder({orderid: self.viewingOrderId, password: password, movementId: self.movementReference.movementId()})
                    .then(function(value){
                        self.movementReference.$parent.showLoadingWheel(false);
                        if(value != "") {
                            self.errors([value]);
                        } else {
                            self.getWireData();
                        }
                    })
                }
            })
        }
    
        self.isCancelWireVisible = ko.computed(function() {
            if(!self.wireInProgress()) {
                return false
            } else {
                return self.movementReference.$parent.orderStatus() == "In Progress" && self.wireInProgress ? self.wireInProgress().wireStatus : false != "In Process";
            }
        });
    
        self.showCancelWireSuccessfulMessage = ko.observable();
    
        self.showCancelWire = function() {
            getpassword("ATM cash password").then(function(password) {
                if(password) {
                    var wireid = self.wireInProgress().id;
                    self.movementReference.$parent.showLoadingWheel(true);
                    self.cancelWire({wireId : wireid, password: password, movementId: self.movementReference.movementId()})
                    .then(function(value) {
                        self.movementReference.$parent.showLoadingWheel(false);
                        if(value.error) {
                            self.errors([value.error]);
                        } else {
                            self.showCancelWireSuccessfulMessage(true);
                            self.getWireData();
                        }
                    })
                }
            })
        }
    
        self.wireAmountAvailable = ko.computed(function() {
            return self.maxWireAmount() ? self.maxWireAmount().toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "";
        })
    
        self.createWireGrid = function() {
            var source = {
                localdata: self.wireData(),
                datatype: 'observableArray',
                datafields: [
                    { name: 'id', type: 'number'},
                    { name: 'transactionDate', type: "date" },
                    { name: 'order', type: 'string' },
                    { name: 'controlNumber', type: 'string' },
                    { name: 'description', type: 'string' },
                    { name: 'checkAmount', type: "decimal" },  
                    { name: 'checkCode', type: 'string'},
                    { name: 'driver', type: 'string' },
                    { name: 'enteredBy', type: 'string' },
                    { name: 'payee', type: 'string' },
                    { name: 'wireCode', type: 'string' },
                    { name: 'wireStatus', type: 'string' }
                ]
            };
            var dataAdapter = dataModel.getDataAdapter(source);
            $('#jqxWiresGrid').jqxGrid({
                source: dataAdapter,
                width: '100%',
                selectionmode: 'single',
                pageable: true,
                sortable: true,
                autoheight: true,
                columnsresize: true,
                columns: [
                    { text: 'Transaction Date', datafield: 'transactionDate', cellsformat: 'MM/dd/yyyy HH:mm' },
                    { text: 'Order Id', datafield: 'order' },
                    { text: 'Check Number', datafield: 'controlNumber' },
                    { text: 'Description', datafield: 'description' },
                    { text: 'Check Amount', datafield: 'checkAmount', cellsformat: 'c2' }
                ] 
            })
    
        }
    
        self.getWireData = function() {
            self.getWiresForOrder({orderId: self.viewingOrderId, driverId: self.movementReference.driver1()})
            .then(function(value) {
                self.maxWireAmount(value.maxWireAmount ? value.maxWireAmount : undefined);
                if(value.error) {
                    self.errors([value.error]);
                    self.wireData(undefined);
                    self.wireInProgress(undefined);
                    $('#jqxWiresGrid').hide();
                } else {
                    self.wireData(value.data);
                    self.errors([]);
                    var wip = value.data.sort(function(a, b) { return new Date(b.transactionDate) - new Date(a.transactionDate)}).slice(0, 1)[0];
                    if(wip && wip.checkAmount > 0) {
                        self.wireInProgress(wip);
                    } else {
                        self.wireInProgress(undefined);
                    }
                    self.createWireGrid();
                }
            })
        }
        self.getWireData();
    }
    formatDate = function(date) {
        return dayjs(date).format(userProfile.dateTimeFormat);
    }

    getWiresForOrder = function(data) {
        return new Promise(function(resolve, reject) {
            dataModel.ajaxRequest("Dispatch/GetWiresForOrder", "GET", data)
            .done(function(value) {
                resolve(value);
            })
            .fail(function(value) {
                resolve({errors: ["An error has occurred."]})
                if(value.responseJSON.message != "An error has occurred.") {
                    dataModel.addClientSideLog(value);
                }
            })
        })
    }
    
    cancelWire = function(data) {
        return new Promise(function(resolve, reject) {
            dataModel.ajaxRequest("Dispatch/CancelWire", "POST", data)
            .done(function(value) {
                resolve(value);
            })
            .fail(function(value) {
                resolve({errors: ["An error has occurred."]})
                if(value.responseJSON.message != "An error has occurred.") {
                    dataModel.addClientSideLog(value);
                }
            })
        })
    }
    
    addWireToOrder = function(data) {
        return new Promise(function(resolve, reject) {
            dataModel.ajaxRequest("Dispatch/AddWireToOrder", "POST", data)
            .done(function(value) {
                resolve(value);
            })
            .fail(function(value) {
                resolve({errors: ["An error has occurred."]})
                if(value.responseJSON.message != "An error has occurred.") {
                    dataModel.addClientSideLog(value);
                }
            })
        })
    }
}

export default {viewModel: CreateWireModel, template: template}