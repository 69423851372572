import { ITrackingTrucklineAssignment } from "./types";

function TrackingTrucklineAssignmentModel(
  initData: ITrackingTrucklineAssignment = {}
) {
  return {
    assignedTractor: initData.assignedTractor,
    hasELD: initData.hasELD ?? false,
  };
}

export default TrackingTrucklineAssignmentModel;
