import template from "./main-footer-component.html";
import userProfile from "user-profile";
import { showmessage } from "show-dialog-methods";

class MainFooterComponent {
  constructor() {
    this.loggedIn = userProfile.loggedIn;

    this.handleTimeoutErrors();
  }

  // GE3 move this to it's own hook / component.
  handleTimeoutErrors = () => {
    $(document).ajaxError((event, jqxhr, settings, thrownError) => {
      if (!this.loggedIn()) return;

      if (jqxhr.status === 504) {
        let msg = jqxhr.responseText ?? `The request is taking longer than usual, please refine your search criteria or try reloading the page.`;
        const url = window.location.pathname;
        if(url && url.toUpperCase() === "/REPORTING") {
          msg = `The report has failed. Please reduce the size of the request and try again.`;
        }

        // Remove double quotes " from responseText
        showmessage(msg.replace(/"/g, ""));
      }
    });
  };
}

export default { viewModel: MainFooterComponent, template: template };
