import template from './must-dismiss-dialog-component.html';
import ko from 'knockout';

class MustDismissDialogComponent {
    constructor(message) {
        this.message = ko.observable(message);
        this.result = new Promise((resolve, reject) => {
            this.ok = () => {
                $('#mustdismissdialogcomponent').modal('hide');
                resolve(true)
            }
        })
    }
}

export default {viewModel: MustDismissDialogComponent, template: template}
