import router from "router";
import dataModel from "data-model";
import { useDispatch } from "ko-data-store";
import { isLoading, getTrailerTypes } from "dataStore-actions/appUI";
import * as ebCommon from "./externalBoardCommon";
import userProfile from "user-profile";
import callNotesComponent from "./shared-components/call-notes/call-notes";
import { cleanString, mapToCityStateZipObject, noop } from "global-functions";
import toast from "geNotyf";

ko.components.register("call-notes", callNotesComponent);

const sendDeleteNamedSearch = (id) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("UserProfile/DeleteUserSearchFilter/" + id)
      .done(() => resolve(true))
      .fail(() => reject(false));
  });
};

const deleteNamedSearch = async (id) =>
  await sendDeleteNamedSearch(id).catch(() => false);

const updateAvailableNowSubscription = () => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        "Externalboard/UpdateAvailableNowSubscription",
        "GET",
        null,
        true
      )
      .done((subscribed) => resolve(subscribed))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const fetchUserBoards = () => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Externalboard/GetUserBoards")
      .done((result) => resolve(result))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const fetchNamedSavedSearches = () => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Externalboard/GetTemplates")
      .done((result) => resolve(result))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const fetchActiveSearches = () => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Externalboard/ActiveSearch", "GET", null, true)
      .done((result) => resolve(result))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const fetchAgencyUsers = () => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("MyAgency/GetAgencyUsers", "GET", null)
      .done((result) => resolve(result))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const fetchLoadViewAsync = ({
  id = undefined,
  tractorOrLoadId = undefined,
  boardName = undefined,
}) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Externalboard/LoadView", "GET", {
        id,
        tractorOrLoadId,
        boardName,
      })
      .done((result) => resolve(result))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const getLoadViewDetails = async ({ tractorOrLoadId, boardName }) =>
  await fetchLoadViewAsync({ tractorOrLoadId, boardName });

const sendShareSearch = (payload) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Externalboard/ShareSearch", "POST", payload)
      .done((result) => resolve(result))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const UpdateNamedSearch = (payload) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Externalboard/UpdateNamedSearch", "POST", payload)
      .done((result) => resolve(result))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const sendDeleteActiveSearch = (id) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("Externalboard/ActiveSearch/Delete", "POST", id, true)
      .done((result) => resolve(result))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "An error occurred during request."
        )
      );
  });
};

const sendDeleteGridState = (name) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest(
        "UserProfile/DeleteUserSearchFilter/Name",
        "POST",
        name,
        true
      )
      .done(() => resolve(true))
      .fail(() => reject(false));
  });
};

const saveRecordHasBeenViewed = async ({ loadId, board }) =>
  ebCommon.sendSaveViewRecord({ loadId, board }).catch(() => false);

const deleteGridStateForTab = async (name) =>
  await sendDeleteGridState(name).catch(() => false);

const fetchExtraDetails = (payload) => {
  return new Promise((resolve, reject) => {
    dataModel
      .ajaxRequest("ExternalBoard/GetBoardDetails/V2", "GET", payload)
      .done((data) => resolve(data))
      .fail((error) =>
        reject(
          (error.responseJSON && error.responseJSON.message) ||
            "Error occurred during request."
        )
      );
  });
};

const getExtraDetails = async ({
  boardName = undefined,
  searchType = undefined,
  id = undefined,
  carrierMcNumber = undefined,
  brokerMcNumber = undefined,
  origin = undefined,
  destination = undefined,
}) =>
  await fetchExtraDetails({
    boardName,
    type: searchType,
    id,
    brokerMcNumber,
    carrierMcNumber,
    origin,
    destination,
  }).catch(() => {});

const saveShareSearch = async ({
  searchType,
  boards,
  trailerType,
  equipmentClass,
  originType,
  origin,
  originRadius,
  originRegions,
  originStateProvince,
  destinationType,
  destination,
  destinationRadius,
  destinationRegions,
  destinationStateProvince,
  pickupFrom,
  pickupTo,
  weight,
  length,
  shareUserIds,
}) =>
  await sendShareSearch({
    searchType,
    boards,
    trailerType,
    equipmentClass,
    originType,
    origin,
    originRadius,
    originRegions,
    originStateProvince,
    destinationType,
    destination,
    destinationRadius,
    destinationRegions,
    destinationStateProvince,
    shipDateTo: pickupFrom,
    shipDateFrom: pickupTo,
    weight,
    length,
    shareUserIds,
  }).catch(() => false);

const deleteActiveSearch = async (id) =>
  await sendDeleteActiveSearch(id).catch(() => false);

const getActiveSearchesForUser = async () => {
  const searches = await fetchActiveSearches().catch(() => []);
  const parseSearchFilters = (searchFiltersJson = "") => {
    try {
      const result = JSON.parse(searchFiltersJson);
      return result || {};
    } catch (err) {
      return {
        includeHazmat: false,
        includeTeams: false,
        length: undefined,
        weight: undefined,
      };
    }
  };

  return searches.map((x) => {
    const originInfo = parseLocationFromString(x.origin) || {};
    const destInfo = parseLocationFromString(x.destination) || {};

    const originType = originInfo.LocType && originInfo.LocType.toLowerCase();
    const destinationType = destInfo.LocType && destInfo.LocType.toLowerCase();

    const additionalFilters = parseSearchFilters(x.searchFilters);

    return {
      ...x,
      pickupFrom: x.shipDateFrom,
      pickupTo: x.shipDateTo,
      originType: originType,
      origin: originType == "location" ? originInfo.Location : null,
      originRadius: originType == "location" ? originInfo.Radius : null,
      originRegions:
        originType == "regions" && originInfo.Location
          ? originInfo.Location.split(",")
          : [],
      originStateProvince:
        originType == "stateprovince" && originInfo.Location
          ? originInfo.Location.split(",")
          : [],
      destinationType: destinationType,
      destination: destinationType == "location" ? destInfo.Location : null,
      destinationRadius: destinationType == "location" ? destInfo.Radius : null,
      destinationRegions:
        destinationType == "regions" && destInfo.Location
          ? destInfo.Location.split(",")
          : [],
      destinationStateProvince:
        destinationType == "stateprovince" && destInfo.Location
          ? destInfo.Location.split(",")
          : [],
      equipmentClass: x.equipmentClass && x.equipmentClass.split(","),
      length: additionalFilters.Length,
      weight: additionalFilters.Weight,
    };
  });
};

const parseLocationFromString = (locationStr) => {
  try {
    return JSON.parse(locationStr);
  } catch (e) {
    return {};
  }
};

const getNamedSavedSearches = async () => {
  try {
    const searches = await fetchNamedSavedSearches().catch(() => []);

    return searches.map((x) => {
      const search = JSON.parse(x.search);

      return {
        ...search,
        id: x.id,
        saveSearchName: x.code,
      };
    });
  } catch (e) {
    return [];
  }
};

export const getUserBoards = async () => {
  const result = await fetchUserBoards().catch(() => {});

  return {
    dat: result.dat,
    truckStop: result.truckStop,
    hasRMISAccess: result.hasRMISAccess,
  };

  //TODO: add error message?
  // self.dat(data.dat);
  //         self.hasRMISAccess(data.hasRMISAccess);
  //         self.truckStop(data.truckStop);
  //         if (data.dat == false && data.truckStop == false) {
  //             self.errors.push({
  //                 message:
  //                     "Please enter your external loadboard account information on the Settings page. If you do not have an external loadboard account please contact the Operations Department to set up account.",
  //             });
  //         }
};

const getAgencyUsers = async () => {
  const result = await fetchAgencyUsers().catch(() => ({ agencyUsers: [] }));

  return result.agencyUsers
    .filter((x) => x.userName != userProfile.userName && x.active)
    .map((x) => {
      return { id: x.id, username: x.userName };
    });
};

const shareSearchVM = function (
  searchData,
  userList = [],
  onSubmit = function (x) {}
) {
  const vm = this;

  vm.userList = ko.observableArray(userList);
  vm.selectedUsers = ko.observableArray([]);
  vm.filterTextInput = ko.observable();
  vm.locationStrings = searchData.locationStrings;

  vm.filterTextInput.subscribe((val) => {
    if (val) {
      const filtered = userList.filter(
        (x) => x && x.username.toUpperCase().indexOf(val.toUpperCase()) > -1
      );
      vm.userList(filtered);
    } else {
      vm.userList(userList);
    }
  });

  vm.handleReset = () => {
    vm.selectedUsers([]);
    vm.filterTextInput(null);
  };
  vm.handleApply = async (x, event) => {
    const validationErrors = ko.validation.group(vm);
    if (validationErrors().length > 0) {
      validationErrors.showAllMessages();
      return false;
    }

    const payload = { ...searchData, shareUserIds: vm.selectedUsers() };

    await saveShareSearch(payload);
    const $modal = $(event.currentTarget).closest(".modal");
    $modal.modal("hide");

    onSubmit(payload);
  };
};

const ExternalBoardViewModel = function () {
  const vm = this;
  const dispatch = useDispatch();
  const defaultBoards = [];
  const loadRecentSearches = () => {
    const currentSearches = ebCommon.pipe(
      ebCommon.getRecentSearchCacheFromLocalStorage,
      ebCommon.cleanupRecentSearchCache
    )(window.localStorage.getItem("externalBoardRecentSearchesCache"));
    return ebCommon.mapToSearchListing(
      currentSearches.sort(
        (a, b) => a["cacheExpireDate"] - b["cacheExpireDate"]
      )
    );
  };

  vm.tpiMarketRatesProps = ko.observable();
  vm.userBoards = ko.observableArray(defaultBoards);
  vm.displayNoBoardMessage = ko.observable(false);
  vm.hasRMISAccess = ko.observable(false);
  vm.isUserDriver = ko.observable(userProfile.isUserDriver);
  vm.showAvailableNowAlertBtn = ko.pureComputed(
    () =>
      userProfile.isOwnerOperator === false &&
      userProfile.isUserDriver === false
  );
  vm.callNotesModal = ko.observable();
  vm.sendPacket = ko.observable(false);
  vm.availableNowSubscriber = userProfile.availableNowSubscriber;
  vm.availableNowSubscriberBtnText = ko.pureComputed(() =>
    vm.availableNowSubscriber()
      ? "Un-Subscribe to Available Now Alerts"
      : "Subscribe to Available Now Alerts"
  );
  $("#jqxSendRateConfirmation").on("hidden.bs.modal", () =>
    vm.sendPacket(false)
  );
  $("#search-mobile-modal").on("hidden.bs.modal", () =>
    vm.useRecentSavedTemplate(null)
  );

  vm.isMobile = ko.pureComputed(() => {
    return window.innerWidth > 990 || ebCommon.isMobileOrTablet();
  });

  vm.callLeadsModal = ko.observable();
  vm.searchPanelDisplay = ko.observable("search").extend({ notify: "always" });
  vm.externalBoardDetails = ko.observable();
  vm.savedSearches = ko.observableArray([]);
  vm.recentSearches = ko.observableArray(loadRecentSearches());
  vm.openRecentSearchModal = ko.observable(false);
  vm.openShareSearchModal = ko.observable(false);

  $("#viewAllRecentModal").on("hidden.bs.modal", () =>
    vm.openRecentSearchModal(false)
  );
  $("#viewAllSavedModal").on("hidden.bs.modal", () =>
    vm.openShareSearchModal(false)
  );

  vm.handleRecentSearch = async (searchData) => {
    if (!searchData) return false;

    $("#viewAllRecentModal").modal("hide");
    $("#viewAllSavedModal").modal("hide");

    // this will toggle 'search' tab with prefilled data from selected item...
    // wrapping in a time to allow any modals open to fully close so the backdrops fully are removed...
    setTimeout(
      () => vm.handleRecentSavedTemplateSelect({ ...searchData }),
      300
    ); //pickupFrom: null, pickupTo: null, saveSearchName: null
  };

  vm.handleEditSearch = ($data) => {
    console.log($data);
    vm.searchPanelDisplay(null);
    vm.handleRecentSearch({ ...$data, saveSearchName: $data.searchName });
  };

  // these modals are used for smaller / mobile screens.
  vm.displaySearchModal = ko.observable(false);
  //vm.displaySavedSearchesModal = ko.observable(false);
  vm.displayBoardDetailsModal = ko.observable(false);

  vm.resetFormObservable = ko.observable();
  vm.activeSearches = ko.observableArray([]);
  vm.activeSearchTabIndex = ko.observable(0);

  vm.useRecentSavedTemplate = ko.observable().extend({ notify: "always" });
  vm.handleRecentSavedTemplateSelect = (data) => {
    if (data && Object.keys(data).length) {
      vm.handleDisplaySearchPanel("search");
      vm.useRecentSavedTemplate(data);
    }
  };

  vm.handleRemoveRecentSearch = (itemIndex) => {
    const items = vm.recentSearches().filter((x, index) => index != itemIndex);
    vm.recentSearches(items);
  };

  vm.handleRemoveNamedSearch = async (itemIndex) => {
    const search = vm.savedSearches()[itemIndex];

    if (search) {
      await deleteNamedSearch(search.id);

      const searches = vm
        .savedSearches()
        .filter((x, index) => index != itemIndex);
      vm.savedSearches(searches);
    }
  };

  vm.recentSearches.subscribe((val = []) => {
    ebCommon.saveToLocalStorageRecentSearchCache(val);
  });

  vm.handleUpdateAvailableNowSubscription = async () => {
    try {
      dispatch(isLoading(true));
      const result = await updateAvailableNowSubscription();
      dispatch(isLoading(false));

      userProfile.availableNowSubscriber(result);
      userProfile.updateUserProfile({ availableNowSubscriber: result });
      toast.open({
        type: "info",
        message: result
          ? "You are now subscribed for Available Now Alerts"
          : "You are no longer subscribed for Available Now Alerts",
      });
    } catch (err) {
      toast.error(err);
    }
  };

  vm.shareSearchModal = ko.observable(); // search data to share
  vm.handleShareSearch = async (searchData) => {
    if ($("#search-mobile-modal").length) {
      $("#search-mobile-modal").modal("hide");
    }

    if (searchData) {
      $("#viewAllRecentModal").modal("hide");
      $("#viewAllSavedModal").modal("hide");

      const agencyUsers = await getAgencyUsers();
      const ssViewModel = new shareSearchVM(searchData, agencyUsers, () =>
        vm.shareSearchModal(null)
      );
      vm.shareSearchModal(ssViewModel);
    }
  };

  // close search panel when have a new search
  vm.activeSearches.subscribe(
    () =>
      vm.isSearchPanelOpen() &&
      vm.handleDisplaySearchPanel(vm.searchPanelDisplay())
  );

  vm.isSearchPanelOpen = ko.observable(false);
  vm.handleDisplaySearchPanel = (view) => {
    view = view || "search";
    const $searchCon = $("#external-board-page #search-container");
    $("#external-board-page #search-panel-column button").removeClass("active");

    if (vm.externalBoardDetails()) {
      vm.handleDetails(null);
    }

    if (vm.searchPanelDisplay() !== view) {
      vm.isSearchPanelOpen(true);
    } else {
      vm.isSearchPanelOpen(!vm.isSearchPanelOpen());
    }

    vm.searchPanelDisplay(view);

    if (vm.isSearchPanelOpen()) {
      const $activeBtn =
        view === "search"
          ? $("#search-btn")
          : view === "recent"
          ? $("#recent-search-btn")
          : null;

      if ($activeBtn) {
        $activeBtn.addClass("active");
      }

      $("#external-board-page #page-backdrop").show();
      $searchCon.animate({ left: "60px" }, "fast");
      vm.resetFormObservable(false);
    } else {
      $("#external-board-page #page-backdrop").hide();
      $searchCon.animate({ left: "-110vw" }, "fast");
      vm.useRecentSavedTemplate(null);
      vm.resetFormObservable(true);
    }
  };

  vm.handleDetails = async (details, viewRecordSavedCallBackFn, event) => {
    const $detailsPanel = $("#details-panel");
    vm.isSearchPanelOpen(false);

    const openPanelSlide = () => {
      // details panel
      if (window.innerWidth >= 992) {
        if ($detailsPanel.hasClass("open")) {
          $detailsPanel.animate({ left: "-110vw" }, "fast", () =>
            $detailsPanel.animate({ left: "5px" }, "fast")
          );
          $("#external-board-page #page-backdrop").hide();
        } else {
          $detailsPanel.animate({ left: "60px" }, "fast", () =>
            $detailsPanel.addClass("open")
          );
          $("#external-board-page #page-backdrop").show();
        }
      }
    };

    const closePanelSlide = () => {
      $detailsPanel.animate({ left: "-110vw" }, "fast", () => {
        vm.externalBoardDetails(null);
        $detailsPanel.removeClass("open");
      });

      $("#external-board-page #page-backdrop").hide();
    };

    if (!details || Object.keys(details).length == 0) {
      closePanelSlide();
      return false;
    }

    const cachedData = ebCommon.boardDetailsCache.get(details.id);

    if (cachedData) {
      vm.externalBoardDetails(
        new ebCommon.LoadTractorDetails({
          ...cachedData,
          creditBalance: cachedData.balance,
        })
      );
      openPanelSlide();
    } else {
      dispatch(isLoading(true));
      const data = (await getExtraDetails(details)) || {};
      ebCommon.boardDetailsCache.add(details.id, {
        ...details,
        ...data,
        previouslyViewed: true,
      });

      vm.externalBoardDetails(
        new ebCommon.LoadTractorDetails({
          ...details,
          creditBalance: data.balance,
          ...data,
        })
      );

      viewRecordSavedCallBackFn();
      await saveRecordHasBeenViewed({
        loadId: details.id,
        board: details.boardName,
      });
      dispatch(isLoading(false));
      openPanelSlide();
    }

    // details modal...
    if (window.innerWidth <= 992) {
      vm.displayBoardDetailsModal(true);

      $("#detailsMobileModal").on("hidden.bs.modal", () =>
        vm.externalBoardDetails(null)
      );
    }
  };

  vm.handleCloseDetailsPanel = () => {
    const $detailsPanel = $("#details-panel");

    $detailsPanel.animate({ left: "-105%" }, "fast", () => {
      vm.externalBoardDetails(null);
      $detailsPanel.removeClass("open");
    });

    $("#external-board-page #page-backdrop").hide();
  };

  vm.handleSearchRMIS = () => {
    dataModel.addClientSideLog("Selected: Can't find your carrier btn.");
    return true;
  };

  vm.handleActiveSearchTabClose = async (id, $itemIndex = 0) => {
    if (id > 0) {
      vm.activeSearches.remove((x) => x.id == id);

      if (
        vm.activeSearchTabIndex() == $itemIndex ||
        vm.activeSearches().length == 1
      ) {
        vm.activeSearchTabIndex(0);
      } else if (
        vm.activeSearches().length > 1 &&
        $itemIndex < vm.activeSearchTabIndex()
      ) {
        vm.activeSearchTabIndex(vm.activeSearchTabIndex() - 1);
      }

      if (vm.activeSearches().length == 0) {
        vm.handleCloseDetailsPanel();
      }

      await deleteActiveSearch(id);

      // delete user saved grid state/filters if tab had any.
      const savedFilterName = "active-search-" + id + "-grid";
      await deleteGridStateForTab(savedFilterName);
    }
  };

  vm.handleCallNoteEdit = async (
    { tractorOrLoadId, boardName },
    onSaveCallBackFn = noop
  ) => {
    try {
      dispatch(isLoading(true));
      const viewData = await getLoadViewDetails({ tractorOrLoadId, boardName });
      dispatch(isLoading(false));

      vm.callNotesModal({
        tractorOrLoadId: tractorOrLoadId,
        board: boardName,
        callNotes: (viewData || {}).callNotes,
        onSave: (callNotes) => {
          vm.callNotesModal(null);
          onSaveCallBackFn(callNotes);
        },
      });
    } catch (err) {
      dispatch(isLoading(false));
      console.error(err);
    }
  };

  vm.refreshActiveSearches = async () => {
    const activeSearches = await getActiveSearchesForUser();

    vm.activeSearches(ebCommon.mapToSearchListing(activeSearches));
  };

  vm.handleGetTPIMarketRates = async (details = {}) => {
    console.log(details);
    const request = TPIRateRequest();
    request.pickupDate = details.dateAvailable;
    request.trailerType = details.trailerType;
    request.transportationMode = "TL";

    request.requestType = "LOADBOARD";
    request.skipStoringResult = true;

    const originCSZ = mapToCityStateZipObject(
      cleanString(["(", ")", ","], details.origin).split(" ")
    );

    request.originCity = originCSZ.city;
    request.originState = originCSZ.state;
    request.originZip = originCSZ.zip;

    const destinationCSZ = mapToCityStateZipObject(
      cleanString(["(", ")", ","], details.destination).split(" ")
    );

    request.destinationCity = destinationCSZ.city;
    request.destinationState = destinationCSZ.state;
    request.destinationZip = destinationCSZ.zip;

    request.externalBoardNames = vm.userBoards();

    dispatch(isLoading(true));
    const result = await RateService.getTPIRates(request);
    dispatch(isLoading(false));

    vm.tpiMarketRatesProps(result);
  };

  const init = async () => {
    const initData = await getUserBoards();

    await vm.refreshActiveSearches();

    const savedTemplates = await getNamedSavedSearches();

    vm.savedSearches(ebCommon.mapToSearchListing(savedTemplates));

    vm.hasRMISAccess(initData.hasRMISAccess);

    if (initData.dat) defaultBoards.push("DAT");
    if (initData.truckStop) defaultBoards.push("TRUCKSTOP");
    if (defaultBoards.length == 0) vm.displayNoBoardMessage(true);

    vm.userBoards(defaultBoards);
  };

  init();
};

import template from "./external-board-page.html";
import RateService, { TPIRateRequest } from "../../../services/RateService";
export default { viewModel: ExternalBoardViewModel, template: template };
