//@ts-check

import dataModel from 'data-model';
import jquery_csv from 'jquery-csv';
import userProfile from 'user-profile';
import ko from 'knockout';
import { datetimeUTC } from 'global-functions'

class CentralModelBatchImport {
    constructor(parentModel) {
        var self = this;

        self.selectedAgency = ko.observable(null);
        self.agencySelectList = ko.observableArray([]);
        self.selectedContact = ko.observable(null);
        self.contactSelectList = ko.observableArray([]);
        self.displayCentralErrorMsg = ko.observable();
        self.centralOverride = ko.observable(false);

        var _parentModel = parentModel || {};

        self.selectedAgency.subscribe(function (code) {

            if (code) {
                self.displayCentralErrorMsg(null);
                self.contactSelectList.removeAll();
                self.selectedContact(null);
                _getContactsForAgency(code);
            }
        });

        self.selectedContact.subscribe(function (val) {
            if (val) {
                self.displayCentralErrorMsg(null);
            }
        });

        var agencyListLoaded = false
        self.centralOverride.subscribe(function (val) {
            if (val == false) {
                _resetViewModel();
            } else if (val && agencyListLoaded == false) {
                _getAgenciesForDDL();
            }
        });

        // Using validation method as the fields in this model are only required
        // when one or more of this model's fields is being used.
        self.validateModel = function () {
            self.displayCentralErrorMsg(null);
            var _result = {
                isValid: true,
                errorMsg: null
            };

            var _contact = self.selectedContact();
            if (self.selectedAgency() == null) {
                _result.errorMsg = "Please select agency from the list.";
            }
            else if (self.selectedAgency() && _contact == null) {
                _result.errorMsg = "Please select a contact from the list.";
            }
            else if (self.selectedAgency() && _contact != null) {
                if (_contact.name == null && _contact.email == null && _contact.phone == null) {
                    _result.errorMsg = "The selected user doesn't have any contact information.";
                }
            }

            _result.isValid = _result.errorMsg == null;
            return _result;
        }

        self.setDisplayCentralErrorMsg = function (message) {
            if (message) {
                self.displayCentralErrorMsg(message);
            }
        }

        function _resetViewModel() {
            self.contactSelectList.removeAll();
            self.selectedContact(null);
            self.selectedAgency(null);
            self.displayCentralErrorMsg(null);
        }

        function _getContactsForAgency(code) {
            if (code == null) return;

            _parentModel.isLoading(true);

            dataModel.ajaxRequest('User/GetUsersByAgency', 'GET', { agency: code }).done(function (resp) {

                if (resp) {
                    _buildUserContactList(resp.records).then(function (x) {
                        _parentModel.isLoading(false);
                    });
                }
                else {
                    _parentModel.isLoading(false);
                }
                
            }).fail(function (error) {
                _parentModel.isLoading(false);
                console.error('Error getting items for contact droplist.');
            })
        }

        function _buildUserContactList(data) {

            return new Promise(function (resolve, reject) {
                if (data == null) reject(false);

                $.each(data, function (index, item) {
                    self.contactSelectList.push({
                        username: item.username,
                        agencyExId: item.agency,
                        name: item.name,
                        email: item.email,
                        phone: item.phone
                    });
                });

                resolve(true);
            })
        }

        
        function _getAgenciesForDDL() {

            _parentModel.isLoading(true);
            dataModel.ajaxRequest('User/GetUserAgencies', 'GET', { numOfItems: 2000, includeInactive: false }).done(function (resp) {

                _buildAgencyList(resp).then(function (x) {

                    _parentModel.isLoading(false);
                    agencyListLoaded = true;
                }).catch(() => _parentModel.isLoading(false));

            }).fail(function (error) {
                _parentModel.isLoading(false);
                console.error('Error getting items for agency droplist.');
            })
        }

        function _buildAgencyList(data) {
            return new Promise(function (resolve, reject) {
                if (data == null) reject(true);
                $.each(data, function (index, item) {
                    self.agencySelectList.push({
                        code: item.code
                    })
                });

                resolve(true);
            });
        }
    }
}

class BatchImportViewModel {
    constructor(params) {
        var self = this;
        self.importFile = ko.observable("");
        self.isLoading = ko.observable(false);
        self.responseMessage = ko.observable();
        self.errors = ko.observableArray([]);
        self.centralModel = ko.observable();
        self.onSaveSuccess = params.onSaveSuccess || function(loadCount) {};

        if (userProfile.isGwEmployee != false)
            self.centralModel(new CentralModelBatchImport(self));


        self.displayLoads = function (err, loadData) {
            var html = "";
            for (var obj = 0; obj < loadData.length; obj++) {
                for (var key in loadData[obj]) {
                    html += key + ": " + loadData[obj][key] + "; "
                }
                html += "<br />";
            }
        };

            
        self.parseCSVFile = function (file) {
            if (file) {
                var reader = new FileReader();
                reader.onload = function () {
                    var csv = reader.result;
                    var loads = $.csv.toObjects(csv, {}, self.postLoads);

                };
                reader.readAsText(file);
            }
        };
            
        self.loadUpload = function (files) {
            self.errors.removeAll();
            self.responseMessage(null);
            if (files.length > 0) {
                var csvFile = files[0];  // only allowing one file to be chosen
                self.isLoading(true);
                self.parseCSVFile(csvFile)
            }
        };


        self.postLoads = function (err, loadsData) {
            self.isLoading(false);
            $('#jqxFileInput').val("");

            const checkDate = (dayjsDate) => {
                
                // checking year here...
                // 20240 is valid year in js, but doesn't parse correctly in the api
                if(dayjsDate.year().toString().length > 4) {
                    return false;
                }

                return dayjsDate.isValid();
            }

            const loadsWithInvalidDates = (loadsData || []).filter((load) => (checkDate(datetimeUTC(load['Pick Up Date'])) == false || checkDate(datetimeUTC(load['Delivery Date'])) == false));

            if(loadsWithInvalidDates.length) {
                self.errors.push(`One or more dates is not a valid. Be sure format is like 'MM/DD/YYYY'`)
                return false;
            }

            var payload = {
                loadData: loadsData
            };

            // Central Posting Overrides
            if (userProfile.isGwEmployee && self.centralModel() != null) {
                var _centralModel = ko.toJS(self.centralModel()) || {};
                if (_centralModel.centralOverride == true) {
                    var validationResult = _centralModel.validateModel();
                    if (validationResult.isValid == false) {
                        _centralModel.setDisplayCentralErrorMsg(validationResult.errorMsg);
                        return false;
                    }
                    payload.overrideAgencyCode = _centralModel.selectedAgency;
                    payload.overrideUsername = _centralModel.selectedContact.username;
                    payload.overrideName = _centralModel.selectedContact.name;
                    payload.overridePhone = _centralModel.selectedContact.phone;
                    payload.overrideEmail = _centralModel.selectedContact.email;
                }
                payload.centralOverride = _centralModel.centralOverride || false;
            }

            self.isLoading(true);
            dataModel.ajaxRequest("Loadboard/PostLoads", "POST", payload)
            .done(function (loadCount, status, xhr) {

                if (self.centralModel() != null) {
                    self.centralModel().centralOverride(false);
                }

                self.isLoading(false);
                self.responseMessage(loadCount + " loads imported.");
                self.onSaveSuccess(loadCount);
            })
            .fail(function (xhr, msg, d) {
                if (xhr.responseJSON != null) {
                    self.errors.push(xhr.responseJSON.message);
                }
                else {
                    self.errors.push("An unknown error occurred");
                }
                
                self.isLoading(false);
                });
        };

        $('#importButton').click(function () {
            self.loadUpload($('#jqxFileInput')[0].files);
        });
    }
}

import template from './batch-load-import-component.html';
export default { viewModel: BatchImportViewModel, template: template }