import TNTContext, {
  ITrackTraceMovementState,
} from "../../trackTraceOrderEntryContext";

class DriverPhoneModelViewModel {
  openModal: ko.PureComputed<boolean> = ko.pureComputed(() => false);

  constructor() {
    this.openModal = ko.pureComputed(() => {
      const state = TNTContext.getState();

      return state.showDriverPhoneModal ?? false;
    })
  }

  handleModalClose = () => {
    TNTContext.updateSlice({sliceKey: "showDriverPhoneModal", payload: false})
  };
}

import template from "./driver-phone-modal-component.html";
import { ValueObject } from "../../models";
export default { viewModel: DriverPhoneModelViewModel, template };
