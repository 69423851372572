import {showconfirm} from 'show-dialog-methods'
import * as tvCommon from '../tvCommon';
import toast from 'geNotyf';
import { useDispatch } from 'ko-data-store';
import { isLoading } from 'dataStore-actions/appUI';

const videoListViewModel = function({
    videoContentVM,
    videoPlayerContent,
    allowVideoEntryEdits,
    handleVideoEdit
}) {
    const vm = this;
    const dispatch = useDispatch();

    vm.videoContentVM = videoContentVM;
    vm.videoPlayerContent = videoPlayerContent;
    vm.allowVideoEntryEdits = allowVideoEntryEdits;
    
    vm.handleViewVideo = ($data) => vm.videoPlayerContent($data);

    vm.handleVideoEdit = ($data, event) => {
        event.stopPropagation();
        handleVideoEdit($data);
    }

    vm.handleDeleteVideo = async ($data, event) => {
        const { id } = ko.toJS($data) || {};

        event.stopPropagation();

        const confirmed = await showconfirm('Are you sure you want to delete? This cannot be undone.', 'danger');
        
        if(confirmed) {
            try {
                dispatch(isLoading(true));
                await tvCommon.services.deleteVideoAsync(id);
                dispatch(isLoading(false));

                const videos = ko.toJS(videoContentVM().videoList());
                const filtered = videos.filter(x => x.id != id);

                videoContentVM().videoList(filtered);

                toast.open({type: 'info', message: `Video deleted successfully.`});
            }
            catch(err) {
                toast.error(err);
            }
        }

    }
}

import template from './video-list-component.html';
export default { viewModel: videoListViewModel, template: template }