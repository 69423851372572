// <!-- Not being used currently -->
import dataModel from 'data-model';
import ko from 'knockout';
import * as $ from 'jquery';

var EmptyMoveServices = {};

class EmptyMoveViewModel {
    constructor(params) {
        var self = this;
        self.tractorExId = ko.observable(params.tractorExId);
        self.locationId = ko.observable();
        self.locationSelectedValue = ko.observable();
        self.address = ko.observable();
        self.cityStateZip = ko.observable().extend({required: true});
        self.earliestArrival = ko.observable().extend({required: true});
        self.latestArrival = ko.observable();
        self.namee = ko.observable();
        self.errors = ko.observableArray();
        self.showLoadingWheel = ko.observable(false);

        self.selectedLocationHref = ko.observable();
        self.selectedLocationName = ko.observable();
        self.locationSelectedValue.subscribe(function(value) {
            if(value) {
                self.selectedLocationHref("/locations?locationId=" + value.code);
                self.selectedLocationName(value.name);
                self.namee(value.name);
                self.address(value.address);
                self.cityStateZip(value.zip + " (" + value.city + ", " + value.state + ")")
            } else {
                self.selectedLocationHref(undefined);
                self.selectedLocationName(undefined);
                self.namee(undefined);
                self.address(undefined);
                self.cityStateZip(undefined)
            }
        })

        self.errorModal = ko.observable();

        self.cityStateZipSelectedValue = ko.observable()

        self.createEmptyMovement = function() {

            self.errors.removeAll();

            var validationErrors = ko.validation.group(self);
            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                return false;
            }

            self.showLoadingWheel(true);
            EmptyMoveServices.createEmptyMovement({
                locationId: self.locationId(),
                earliestArrival: self.earliestArrival(),
                latestArrival: self.earliestArrival(),
                tractorExId: self.tractorExId(),
                city: self.locationSelectedValue() ? self.locationSelectedValue().city : self.cityStateZipSelectedValue().city,
                state: self.locationSelectedValue() ? self.locationSelectedValue().state : self.cityStateZipSelectedValue().state,
                zip: self.locationSelectedValue() ? self.locationSelectedValue().zip : self.cityStateZipSelectedValue().zip
            }).then(function(value) {
                self.showLoadingWheel(false);
                if(value.errors) {
                    self.errorModal(value.errors);
                } else {
                    params.result(value)
                    $('#createEmptyMoveModal').find('.close').click();
                }
            })
        }
    }
}

EmptyMoveServices.createEmptyMovement = function(data) {
    return new Promise(function(resolve, reject) {
        dataModel.ajaxRequest("Tractor/CreateEmptyMove", "POST", data)
        .done(function(result) {
            resolve(result)
        })
    })
}


import template from './empty-move-component.html';
export default { viewModel: EmptyMoveViewModel, template: template }