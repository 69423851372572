import dataModel from "data-model";
import { isEmpty } from "global-functions";
import gridState from "jqx.grid-state-component";
import gridStateUtils from "jqx.grid-utils";
import userProfile from "user-profile";


class DeleteGridFiltersViewModel
{

    constructor(){
        this.deleteGridFiltersGridState = null;
        this.grid = $("#deleteGridFiltersGrid");
        this.loadGrid();  
    }

    searchOrderFilters = ko.observableArray();

    get gridSource() {
        return {
            url: "UserProfile/GetUserSearchFilterListByAgencyId",
            datatype: "json",
            formatdata: (data) => {
                data = data || {};
                data.agencyId = userProfile.currentAgencyId();

                return data;
            },

            datafields: [
                { name: "id", type: "int" },
                { name: "pageName", type: "string" },
                { name: "userName", type: "string" },
                { name: "name", type: "string" },
                { name: "userId", type: "int"},
                { name: "agencyId", type: "string" },
                { name: "searchName", type: "string" },
                { name: "isDefault", type: "boolean" },
            ],
        };
    }

    loadGrid = () => {
        let vm = this;

        if (this.grid.length == 0) this.grid = $("#deleteGridFiltersGrid");

        var dataAdapter = dataModel.getDataAdapter(this.gridSource);

        this.grid
            .jqxGrid({
                theme: "GWTMDark",
                width: "100%",
                height: "330px",
                source: dataAdapter,
                altrows: true,
                showstatusbar: true,
                sortable: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                statusbarheight: 35,
                showtoolbar: true,
                rendertoolbar: (toolbar) => {
                    let vm1 = new gridState.viewModel();

                    vm1.actions = [
                        "Clear Filters",
                        "Clear Selection",
                        "Refresh",
                        "Set Default Search",
                    ];
                    vm1.initializeDefaultToolbar(toolbar);

                    vm1.notifyLocation = this.grid
                        .closest(".modal-content")
                        .find(".modal-header");
                },

                rendergridrows(obj) {
                    return obj.data;
                },

                columns: [
                    { text: "Id", datafield: "id", hidden: true },
                    { text: "Search Name", datafield: "searchName" },
                    { text: "User Name", datafield: "userName" },
                    { text: "Name", datafield: "name" },
                    { text: "Page Name", datafield: "pageName" },
                    {
                        text: "",
                        width: 60,
                        filterable: false,
                        sortable: false,
                        columnType: "button",
                        buttonclick(row) {
                            var datarow = vm.grid.jqxGrid("getrowdata", row);
                            vm.deleteSearch(datarow);
                        },
                        cellsrenderer() {
                            return "Delete";
                        },
                    },
                ],

                ready: () => {
                    gridStateUtils.applyGridState(
                        "deleteGridFiltersGrid",
                        this.deleteGridFiltersGridState
                    );
                },
            })
            .bind("filter", () => {
                gridStateUtils.logGridFilterResults("#deleteGridFiltersGrid");
            });
    };

    // constructor(){   
    //     this.loadGridFilters();
    //     this.searchList.subscribe( (x) => {
    //        if (x.length == 0)
    //        this.filterMessage("No results to show")
    //        else{
    //         this.filterMessage("");
    //        }
    //     })
    // }
    
    // filterMessage = ko.observable("");
    // searchOrderFilters = ko.observableArray();
    // searchName = ko.observable("");
    // searchForDelete = ko.observable("");
    // pageName = ko.observable("");

    // searchList = ko.pureComputed(() => {
    //     const searchBy = this.searchForDelete() || "";
    //     return this.searchOrderFilters().filter(function (x) {
    //         return !searchBy || (x.searchName || "").toUpperCase().indexOf(searchBy.toUpperCase()) > -1;
    //     });
    // })

    deleteSearch = (val)  => {
        dataModel.ajaxRequest("UserProfile/DeleteUserSearchFilter/" + val.id).done( () => { this.grid.jqxGrid("updatebounddata", "data")});
    };
    // loadGridFilters = () => {
    //     const agencyId = userprofile.currentAgencyId();
    //     debugger;
    //     dataModel.ajaxRequest("UserProfile/GetUserSearchFilterList", "get", {agencyId}).done ( (data, status, xhr) => {
    //         this.searchOrderFilters(data);
    //      })
    // }
}

import template from "./delete-grid-filters.html" 
import userprofile from "user-profile";
export default {viewModel: DeleteGridFiltersViewModel, template: template};
