/* eslint-disable no-prototype-builtins */

import template from "./order-entry-page.html";
import ko, { Observable, ObservableArray, unwrap } from "knockout";
import dataModel from "../../../data-model";
import {
  cleanString,
  datetimeUTC,
  mapToCityStateZipObject,
  mobileAndTabletcheck,
  scrollToRequiredField,
} from "../../shared-components/global-functions";
import { showmessage, showconfirm } from "show-dialog-methods";
import router from "../../../router";
import dayjs from "dayjs";
import * as $ from "jquery";
import userProfile from "../../../user-profile";
import { SummaryModel } from "./order-entry-summary-component/order-entry-summary-component";
import { GeneralModel } from "./order-entry-general-component/order-entry-general-component";
import { ContainerModel } from "./order-entry-container-component/order-entry-container-component";
import { ImageViewModel } from "./order-entry-order-images-component/order-entry-order-images-component";
import { GemUploadViewModel  } from "./order-entry-order-gem-uploads-component/order-entry-order-gem-uploads-component";
import { BillingModel } from "./order-entry-billing-component/order-entry-billing-component";
import { AdditionalCharge } from "./order-entry-billing-component/additional-charges-component/additional-charges-component";
import { InvoiceAndSettlementDetailsModel } from "./order-entry-invoice-and-settlement-details-component/order-entry-invoice-and-settlement-details-component";
import { DeliveryReceiptModel } from "./order-entry-delivery-receipt-component/order-entry-delivery-receipt-component";
import {
  TemplateModel,
  UseTemplateModel,
} from "./order-entry-template-component/order-entry-template-component";


//Hotfix revert from ticket GE-1795, fix for ticket GE-1962 5.16.24
import { StopModel } from "./order-entry-stops-component/order-entry-stops-component";
//import {
//  GetUniqueStopNotes,
// StopModel,
//} from "./order-entry-stops-component/order-entry-stops-component";



import OrderEntryTracking from "./order-entry-tracking-component/order-entry-tracking-component.js";
import { OrderLockComponent } from "./order-entry-order-lock-component/order-entry-order-lock-component";
import _ from "lodash";

import TNTContext, {
  MOVEMENTSTATUS_ENUM, TRACKINGSTATUS_ENUM,
} from "./order-entry-track-trace-component/trackTraceOrderEntryContext";
import TnTStopModel from "./order-entry-track-trace-component/models/StopModel";
import TrackingTrucklineAssignmentModel from "./order-entry-track-trace-component/models/TrackingTrucklineAssignment";
import TrackingBrokerageAssignmentModel from "./order-entry-track-trace-component/models/TrackingBrokerageAssignment";
import toast from "geNotyf";

class OrderEntryViewModel {
  //Components
  /**@type { Observable<SummaryModel | any> } */
  summary = ko.observable().extend({ notify: "always" });
  /**@type { Observable<GeneralModel | undefined> } */
  general = ko.observable();
  /**@type { ObservableArray<StopModel | undefined> } */
  stops = ko.observableArray([]);
  orderEntryDispatchParameters = ko.observable();
  orderEntryDispatchBrokerageParameters = ko.observable();
  container = ko.observable();
  /**@type { Observable<BillingModel | undefined> } */
  billing = ko.observable();
  invoiceAndSettlementDetails = ko.observable();

  actionQueryStringParameter;
  requiredFields = new OrderEntryHiddenAndRequiredFields();

  /**@type { Observable<OrderEntryTracking | undefined> } */
  orderEntryTracking = ko.observable(new OrderEntryTracking({ $parent: this }));

  /**@type { Observable<OrderLockComponent> } */
  orderLockComponent = ko.observable();

  //Modals
  moveTrailerModal = ko.observable();
  stopsArrangementModal = ko.observable();
  rateSelectModal = ko.observable();
  callInListModal = ko.observable();
  newCallInModal = ko.observable();
  callInModals = {
    callInListModal: this.callInListModal,
    newCallInModal: this.newCallInModal,
  };
  auditLogModal = ko.observable();
  postLoadModal = ko.observable();
  saveTemplateOrderModal = ko.observable();
  useTemplateOrderModal = ko.observable();
  orderImagesModal = ko.observable();
  orderGemUploadsModal = ko.observable();
  duplicateOrderModal = ko.observable();
  geoLocationsModal = ko.observable();
  trackingMapModal = ko.observable();
  tenderStatusModal = ko.observable();
  deliveryReceiptModal = ko.observable();

  //Data points
  /**@type { Observable<string> } */
  orderExternalId = ko.observable().extend({ notify: "always" });
  /**@type { Observable<number> } */
  orderId = ko.observable().extend({ notify: "always" });
  agentRisk = ko.observable();
  customerTargetProfit = ko.observable();
  readyToBill = ko.observable();
  isExistingOrder = ko.observable(false);
  noBroker = ko.observable(false);
  currentMovementId = ko.observable();
  brokered = ko.observable();
  isBrokered = ko.observable();
  shareOrder = ko.observable();
  isContainer = ko.observable();
  isLTL = ko.observable();
  isHazmat = ko.observable();
  isPreload = ko.observable();
  isHighValue = ko.observable();
  highValue = ko.observable();
  isOnHold = ko.observable();
  holdReason = ko.observable().extend({
    required: {
      onlyIf: () => {
        const onHold = unwrap(this.isOnHold);
        return onHold && !unwrap(this.holdReason);
      },
    },
  });
  tankerReq = ko.observable();
  showSharing;
  agencyId = ko.observable();
  movementStatusId = ko.observable();
  isReadOnlyPayMethod = ko.observable(true);
  orderTemplateId = ko.observable();
  isActiveAgency = ko.observable();
  noteSubTypes = ko.observableArray();
  hasCustomPayRole = ko.observable(false);
  hasAdditionalPayRole = ko.observable(false);
  ediPartnerId = ko.observable();
  customerCredit = ko.observable();
  orderExceedsCredit = ko.observable(false);

  //Flags and page control stuff
  noOrderErrorMessage = ko.observable();
  _isInitLoad = ko.observable(true);
  isTerminalCorrectionRequired = ko.observable(false);
  showDispatchOnly = ko.observable(false);
  rebuildDispatch = ko.observable(false);
  isLoading = ko.observable(false).extend({ notify: "always" });
  isLoaded = ko.observable(false);
  terminalErrorMessage = ko.observable("");
  errors = ko.observableArray([]);
  autoRateAndFSCErrors = ko.observable();
  displayAddChargeWarning = ko.observable(false);
  loadIdCreatedFromOrder = ko.observable();
  dataRecordFromServer = ko.observable();
  isTemplate = ko.observable(false);
  isReadOnlyOrder = ko.observable(false);
  usersAgencies = ko.observableArray();
  dummyVariableSwitchForComputeds = ko.observable(true);
  driverCarrier = ko.observable();
  isEdiCustomer = ko.observable(false);
  hasEdiStatus = ko.observable(false); // order has edi status records
  summaryLoaded = ko.pureComputed(() => {
    return this.summary() != null && Object.keys(this.summary()).length > 0;
  });

  orderAvailableOrInProgress = ko.observable(false);

  beforeSaveCallbacks = [];
  skipTrackingSaveCheck = false;

  //Computeds
  _isBrokered;

  constructor(params) {
    TNTContext.resetState();

    const getOrderIdFromUrl = () => {
      const up = window.location.pathname.split("/").filter((x) => x);

      if (up) {
        const orderId = up.length > 1 ? up[1] : "";
        const parts = orderId.split("-");
        return parts[0];
      }

      return "";
    };

    const getActionQueryParamFromUrl = () => {
      const up = window.location.pathname.split("/").filter((x) => x);

      if (up) {
        const orderId = up.length > 1 ? up[1] : "";
        const parts = orderId.split("-");
        return parts[1];
      }

      return "";
    };

    this.orderExternalId(getOrderIdFromUrl());
    this.actionQueryStringParameter = getActionQueryParamFromUrl();
    this.orderEntryTracking().onModalCloseAction = this.loadOrder; // Adding this method to the tracking model
    this.loadOrder();
    this.initializeObservables();
    this.uglyJquery();
    this.cbOptionsHazmatRoutingClick(); //This adds the required hazmat fields to the requiredfields object, if the order is hazmat.

    this.navbarBannerItems = [
      { name: "General", elementId: "general-section" },
      { name: "Stops", elementId: "pnlStops" },
      { name: "Billing", elementId: "billing-section" },
      {
        name: "Additional Pay",
        elementId: "additionalChargesSection",
        isHidden: this.isAdditionalChargesSectionBannerItemHidden(),
      },
      {
        name: "Invoice / Settlement Details",
        elementId: "settlements-section",
        isHidden: ko.computed(() => !this.invoiceAndSettlementDetails()),
      },
      {
        name: "Dispatch",
        elementId: "orderEntryDispatchDiv",
        isHidden: this.isDispatchBannerItemHidden,
      },
      {
        name: "Tracking",
        elementId: "trackingSectionDiv",
        isHidden: ko.computed(() => {
          return TNTContext.isTrackingOn() === false;
        }),
      },
    ];

    // if editing supposedly existing order, add order number to page's title
    if (
      params.orderid &&
      $("title").text().trim().indexOf(params.orderid.toUpperCase()) == -1
    ) {
      $("title").text(
        $("title").text().trim() + " " + params.orderid.toUpperCase()
      );
    }
    // Flipping this inside the summary.  If issues involving the component not being rendered in time
    // Move it to the end the component with the issue.
    //this.isLoaded(true);
    this._isInitLoad(false);

    this.orderExternalId.subscribe((val) => {
      TNTContext.updateSlice({ sliceKey: "orderExId", payload: val });
    });

    this.currentMovementId.subscribe((val) => {
      TNTContext.updateSlice({ sliceKey: "currentMovementId", payload: val });
    });

    this.getIsBrokered.subscribe(async (yes) => {
      const isBrokered = TNTContext.getState().isBrokered;
      let cancelTracks = (yes && isBrokered === false) || (isBrokered && !yes);

      if (cancelTracks && TNTContext.isTrackingOn()) {
        this.skipTrackingSaveCheck = true;
        await TNTContext.cancelAllTracking();
        TNTContext.updateSlice([
          {
            sliceKey: "isBrokered",
            payload: yes,
          },
          {
            sliceKey: "trackedMovements:tracking",
            payload: {
              driver1Name: "",
              driver1Phone: "",
              trucklineAssignment: TrackingTrucklineAssignmentModel(),
              carrierAssignment: TrackingBrokerageAssignmentModel(),
            },
          },
        ]);

        this.skipTrackingSaveCheck = false;
      }
    });
  }

  isAdditionalChargesSectionBannerItemHidden = ko.computed(() => {
    if (
      this.billing() &&
      this.billing().additionalChargesViewModel() &&
      this.billing().additionalChargesViewModel().hasAdditionalPayRole
    ) {
      return (
        this.billing().additionalChargesViewModel().hasAdditionalPayRole() ||
        this.billing().additionalChargesViewModel().hasCustomPayRole()
      );
    } else {
      return false;
    }
  });

  isDispatchBannerItemHidden = ko.pureComputed(() => {
    if (this.summary() && this.summary().status) {
      return !["Available", "In Progress", "Delivered"].some(
        (x) => x == this.summary().status()
      );
    } else {
      return true;
    }
  });

  uglyJquery = () => {
    // Handle resize changes to dynamically update widths for responsive tables
    $(document).ready(() => {
      var $sideBarWithButtonDiv = $("#sideBarWithButtonDiv");
      var $sideBarBtn = $("#btnShowSideBar");

      $sideBarWithButtonDiv.addClass("isVisible");
      $(window).on("resize", () => {
        // If user resized window by pressing the "maximize/minimize" browser button, the
        // "resizing" happens before the new width is applied. Using setTimeout offsets this.
        setTimeout(() => {
          var offset = $sideBarWithButtonDiv.hasClass("isVisible") ? 185 : 100;

          if (mobileAndTabletcheck()) {
            offset = $sideBarWithButtonDiv.hasClass("isVisible") ? 125 : 110;
          }

          var setWidth =
            window.innerWidth < 1000 ? window.innerWidth - offset : "100%";

          $(".ge-table-wrapper").width(setWidth);
        }, 1);
      });

      $sideBarBtn.click(() => {
        $sideBarWithButtonDiv.toggleClass("isVisible");
      });
      if (mobileAndTabletcheck()) {
        // make sidebar btn floating on page
        $sideBarBtn.addClass("mobile");
      }
    });
  };

  initializeObservables = () => {
    this._isBrokered = ko.pureComputed(() => {
      return this.noBroker();
    });
    this.isDrayPuReadOnly = ko.computed(() => {
      var container = this.container();
      if (container != null) {
        return container.isDrayPuTransfered || this.isAgencyOrder() == false;
      }
      return false;
    });

    this.isDrayDelReadOnly = ko.computed(() => {
      var container = this.container();
      if (container != null) {
        return container.isDrayDelTransfered || this.isAgencyOrder() == false;
      }
      return false;
    });
  };

  isAgencyOrder = ko.pureComputed(() => {
    if (userProfile.agencyExternalId != null) {
      if (
        this.agencyId() != null &&
        this.agencyId() == userProfile.agencyExternalId
      ) {
        return true;
      } else if (
        this.usersAgencies() != null &&
        this.usersAgencies().length > 0
      ) {
        if (this.usersAgencies().indexOf(userProfile.agencyExternalId) > -1) {
          return true;
        }
      } else if (this.agencyId() == null) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  });

  isReadOnly = ko.pureComputed(() => {
    var general = this.general();
    if (this.isReadOnlyOrder()) {
      return true;
    }
    if (this.isActiveAgency() == false) {
      return true;
    }
    if (general != null) {
      if (this.orderId() != null && this.isAgencyOrder() == false) {
        return true;
      }
    }
    if (this.summary() != null) {
      if (this.summary().status && this.summary().status() == "Voided") {
        return true;
      } else if (this.readyToBill() == true) {
        return true;
      }
    }
    return false;
  });

  getIsBrokered = ko.pureComputed(() => {
    if (
      this.summary() != null &&
      this.general() != null &&
      this.isBrokered() == null
    ) {
      if (
        this.summary().status() == "In Progress" ||
        this.summary().status() == "Delivered"
      ) {
        return false;
      }
    } else if (
      this.summary() != null &&
      this.general() != null &&
      this.isBrokered() != null
    ) {
      return this.isBrokered();
    } else {
      return false;
      // this is a race condition where if the isBrokered is set before the summary and general models
      // then it pays no attention to the business rules of the in progress / delivered and the geautocomplete wipes out the
      // customer. when isbrokered = true and order.isbrokered= true.
    }
  });

  onLoadSave = (loadId) => {
    this.loadIdCreatedFromOrder(loadId);
    $("#postLoadModal").modal("hide");
    this.postLoadModal(undefined);

    showmessage(
      `Load <a href="/loadboard" target="_blank">${loadId}</a> was successfully created from order.`
    );
  };

  saveDispatchOnly = () => {
    this.isLoading(true);
    if (this.orderEntryDispatchBrokerageParameters()) {
      this.orderEntryDispatchParameters()
        .saveOrderEntryDispatch()
        .then(() => {
          this.isLoading(false);
        })
        .catch(() => {
          this.isLoading(false);
        });
    }
    if (this.orderEntryDispatchParameters()) {
      this.orderEntryDispatchParameters()
        .saveOrderEntryDispatch()
        .then(() => {
          this.isLoading(false);
        })
        .catch(() => {
          this.isLoading(false);
        });
    }
  };

  loadOrder = () => {
    this.getMiscOrderData();
    this.errors.removeAll();
    this.noOrderErrorMessage(undefined);
    this.isLoading(true);
    if (this.orderExternalId()) {
      this.loadExistingOrder();
    } else {
      this.getOrderInitialValues();
    }
  };

  getMiscOrderData() {
    return dataModel.ajaxRequest("Order/GetMiscOrderData").done((x) => {
      this.noteSubTypes(x.noteSubTypes);
      this.hasAdditionalPayRole(x.hasAdditionalPayRole || false);
      this.hasCustomPayRole(x.hasCustomPayRole || false);
    });
  }

  loadOptionVariables = (item) => {
    item = item || {};
    this.isContainer(item.isContainer != null ? item.isContainer : false);
    this.brokered(item.isBrokered || false);
    this.isBrokered(item.isBrokered || false);
    this.isLTL(item.isLTL || false);
    this.isHazmat(item.isHazmat || false);
    this.isPreload(item.isPreload || false);
    this.isHighValue(item.isHighValue || false);
    this.highValue(item.highValue);
    this.isOnHold(item.isOnHold || false);
    this.holdReason(item.holdReason);
    this.tankerReq(item.tankerReq || false);
    this.showSharing = item.showSharing == false ? false : true;
    this.shareOrder(item.shareOrder);
  };
  getOptionVariables = () => {
    return {
      IsContainer: this.isContainer(),
      IsBrokered: this.isBrokered(),
      IsLTL: this.isLTL(),
      IsHazmat: this.isHazmat(),
      IsPreload: this.isPreload(),
      IsHighValue: this.isHighValue(),
      TankerReq: this.tankerReq(),
      HighValue: this.highValue(),
      IsOnHold: this.isOnHold(),
      HoldReason: this.holdReason(),
      ShareOrder: this.shareOrder(),
    };
  };

  loadExistingOrder = () => {
    dataModel
      .ajaxRequest("order/" + this.orderExternalId())
      .done((data) => {
        if (typeof data === "string") {
          this.errors.push(data);
          this.isLoading(false);
          return;
        }

        this.orderId(data.id);

        this.loadOptionVariables(data.options);
        this.isBrokered.subscribe(() => {
          if (
            this.orderEntryDispatchBrokerageParameters() ||
            this.orderEntryDispatchParameters()
          ) {
            this.orderEntryDispatchBrokerageParameters(undefined);
            this.orderEntryDispatchParameters(undefined);
            this.rebuildDispatch(true);
            this.initiateSaveOrderProcess(false);
          }
        });

        this.isContainer.subscribe(() => {
          this.initiateSaveOrderProcess(false);
        });

        this.dataRecordFromServer(data);
        data.summary.agencyId = data.agencyExternalId;

        this.orderAvailableOrInProgress(
          ["Available", "In Progress"].some((x) => x === data.summary.status)
        );

        this.summary({});

        // If show dispatch only, then need to build out a few required props the
        // dispatch component is expecting since the summary viewmodel isn't being loaded here.
        if (data.dispatchOnly) {
          this.summary({
            ...data.summary,
            status: ko.observable(data.summary.status),
            tractorExId: ko.observable(data.summary.tractorId),
            fuelLimit: ko.observable(data.summary.fuelLimit),
            driverCarrier: ko.observable(data.summary.driverCarrier),
          });
        } else {
          this.summary({ item: data.summary, parent: this });
        }

        this.orderEntryTracking().data.orderOpenedAtTimestamp(
          data.orderOpenedAtTimestamp
        );

        if (data.orderLock && !this.orderLockComponent()) {
          this.orderLockComponent(
            _.merge(
              {
                lockOrderControls: ko.observable(true),
                orderOpenedAtTimestamp:
                  this.orderEntryTracking().data.orderOpenedAtTimestamp,
                $parent: this,
                orderId: this.orderId(),
              },
              data.orderLock
            )
          );
        }
        if (data.dispatchOnly) {
          this.isLoading(false);
          this.showDispatchOnly(true);
          this.orderEntryTracking().data.viewingOrderId(data.order.orderId);
          this.buildDispatchOnlyDispatch(data);
        } else {
          this.isEdiCustomer(data.isEdiCustomer);
          this.ediPartnerId(data.ediPartnerId);
          this.hasEdiStatus(data.hasEdiStatus || false);

          this.orderEntryTracking().data.viewingOrderId(data.id);
          if (data.general != null) {
            this.general(
              new GeneralModel({ item: data.general, mainModel: this })
            );
          }
          if (data.options.isContainer == true) {
            this.container(new ContainerModel(data.container || {}, this));
          }
          this.billing(new BillingModel(data.billing || {}, this));
          this.orderEntryTracking().data.viewingOrderId(data.id);

          this.isExistingOrder(true);
          this.requiredFields.hidden(data.hiddenfields);
          this.orderExternalId(data.summary.orderExternalId);
          this.noBroker(data.noBroker || false);
          this.currentMovementId(data.currentMovementId);
          this.movementStatusId(data.movementStatusId);

          this.loadIdCreatedFromOrder(data.loadIdCreatedFromOrder);
          if (data.stops == undefined || data.stops.length < 2) {
            data.stops = [
              {
                movementSequence: 1,
                sequence: 1,
                movementId: data.currentMovementId,
                isShipper: true,
                isConsignee: false,
                stopType: "PU",
                status: "A",
              },
              {
                movementSequence: 2,
                sequence: 2,
                movementId: data.currentMovementId,
                isShipper: false,
                isConsignee: true,
                stopType: "SO",
                status: "A",
              },
            ];
          }

          this.stops(
            data.stops.map((stop) => {
              return new StopModel({ stop: stop, mainModel: this });
            })
          );

          this.agencyId(data.agencyExternalId);
          this.agentRisk(data.agentRisk);
          this.orderExceedsCredit(data.exceedsHardLimit);
          this.customerTargetProfit(data.customerTargetProfit);
          this.readyToBill(data.readyToBill);

          if (data.showInvoiceAndSettlementDetails == true) {
            this.invoiceAndSettlementDetails(
              new InvoiceAndSettlementDetailsModel(data.summary.orderExternalId)
            );
          }

          this.requiredFields.getRequiredAndHiddenFields(
            data.general.billToCustomer
          );
          this.usersAgencies(data.userAgencies);
          this.isActiveAgency(data.isActive);
          this.isReadOnlyOrder(data.isReadOnly);

          if (
            data.summary.status == "Available" ||
            data.summary.status == "In Progress" ||
            data.summary.status == "Delivered"
          ) {
            this.buildOrderEntryDispatch();
          } else if (data.summary.status == "Voided") {
            this.orderEntryDispatchBrokerageParameters(undefined);
            this.orderEntryDispatchParameters(undefined);
          }

          if (this.actionQueryStringParameter != null) {
            if (this.actionQueryStringParameter == "duplicateorder") {
              setTimeout(() => {
                this.showDuplicateOrder();
              }, 3000);
            } else if (
              this.actionQueryStringParameter == "void" &&
              data.summary.status != "Voided"
            ) {
              showconfirm(
                "Are you sure you want to void this order from loadboard?"
              ).then((x) => {
                if (x == true) {
                  this.voidOrder();
                }
              });
            } else if (
              this.actionQueryStringParameter == "voidquote" &&
              data.summary.status == "Voided"
            ) {
              this.showMakeQuoteOrder();
            }
          }

          if (TNTContext.hasActiveTracking() === false) {
            TNTContext.updateSlice({
              sliceKey: "trackedMovements:tracking",
              payload: {
                customerName: data.customerName,
                customerExternalId: data.customerExternalId,
              },
            });
          }

          this.syncTrackTraceData({
            ...ko.toJS(this),
            options: this.getOptionVariables(),
            defaultProviderId: data.trackingVendorId,
          });

          this.isLoading(false);
        }
      })
      .fail((error, msg, d) => {
        if (
          error.responseJSON &&
          error.responseJSON.message == "Authorization denied for this agency"
        ) {
          this.noOrderErrorMessage(
            " You are attempting to view an order that is not associated with your agency. Please check your entry and try again. If you continue to have issues please contact the Help Desk. "
          );
        } else if (
          error.responseJSON &&
          error.responseJSON.message == "Authorization denied for this order"
        ) {
          this.noOrderErrorMessage(
            " You are attempting to view an order that you don't have the required access to view. Please check your entry and try again. If you continue to have issues please contact the Help Desk. "
          );
        } else if (d == "Not Found") {
          this.noOrderErrorMessage(
            "There was a problem opening this order, please contact the Greatwide IT Helpdesk and provide them with your order number for assistance. "
          );
        }
        this.errors.push("Unable to load order");
        this.isLoading(false);
      });
  };

  getOrderInitialValues = () => {
    dataModel
      .ajaxRequest("order/OrderInitialValues/" + userProfile.currentAgencyId())
      .then((data) => {
        this.general(
          new GeneralModel({ item: data.general || {}, mainModel: this })
        );
        this.loadOptionVariables(data.options);
        this.isBrokered.subscribe(() => {
          if (
            this.orderEntryDispatchBrokerageParameters() ||
            this.orderEntryDispatchParameters()
          ) {
            this.orderEntryDispatchBrokerageParameters(undefined);
            this.orderEntryDispatchParameters(undefined);
            this.rebuildDispatch(true);
            this.initiateSaveOrderProcess(false);
          }
        });
        this.summary({ item: {}, parent: this });
        this.billing(new BillingModel({}, this));
        if (data.options && data.options.isContainer == true) {
          this.container(new ContainerModel({}, this));
        }
        this.stops([
          new StopModel({
            stop: {
              movementSequence: 1,
              sequence: 1,
              isShipper: true,
              isConsignee: false,
              stopType: "PU",
              status: "A",
            },
            mainModel: this,
          }),
          new StopModel({
            stop: {
              movementSequence: 2,
              sequence: 2,
              isShipper: false,
              isConsignee: true,
              stopType: "SO",
              status: "A",
            },
            mainModel: this,
          }),
        ]);
        this.isReadOnlyOrder(false);
        this.isActiveAgency(data.general.isActive);
        this.isLoading(false);
      });
  };

  calculateBillingDistance = () => {
    var billing = this.billing();
    var stops = this.stops();
    var routingParam = {
      customerId: this.general().billToCustomer(),
      hazmat: this.isHazmat(),
      stops: [],
    };

    for (var i = 0; i < stops.length; i++) {
      if (
        ko.unwrap(stops[i].stopTypeCode) == "PU" ||
        ko.unwrap(stops[i].stopTypeCode) == "SO" ||
        ko.unwrap(stops[i].stopTypeCode) == "VA"
      ) {
        if (stops[i].cityStateZip() != undefined) {
          routingParam.stops.push(stops[i].cityStateZip());
        } else {
          var currentStopId = i + 1;
          showmessage(
            "Stop #" + currentStopId + " Has an invalid City/State/Zip Value"
          );
          return false;
        }
      }
    }

    billing.isCalculatingDistance(true);
    dataModel
      .ajaxRequest("routing/CalculateCustomerDistance", "post", routingParam)
      .done((response) => {
        billing.billDistance(response);
        billing.calculatedBillingDistance(response);
        billing.isCalculatingDistance(false);
      })
      .fail((jqXHR) => {
        billing.isCalculatingDistance(false);
        if (jqXHR.responseJSON != null) {
          showmessage(jqXHR.responseJSON.message);
        } else {
          showmessage("Distance Calculation Failed");
        }
      });
  };

  showRateSelectModal = (mainModel, rates) => {
    const rateSelectInlineModel = function (mainModel, rates) {
      const v = this;
      v.rates = ko.observableArray(rates);
      v.selectRate = ($data) => {
        mainModel.applyAutoRate($data);
        // close the modal
        mainModel.rateSelectModal(undefined);
      };
    };

    var rsm = new rateSelectInlineModel(mainModel, rates);
    this.rateSelectModal(rsm);
  };

  estimateFuelSurcharge = () => {
    var stops = ko.toJS(this.stops);
    var origin = ko.utils.arrayFirst(stops, (item) => {
      if (item.isShipper === true) {
        return true;
      }
      return false;
    });
    if (
      origin.earliestArrival == null ||
      this.billing().freightCharge() == null
    ) {
      this.autoRateAndFSCErrors(
        "Please complete the required fields and then select the Estimate Fuel Surcharge button."
      );
      return;
    }

    var routingParam = {
      orderExternalId: this.orderExternalId(),
      earliestArrival: origin.earliestArrival.toISOString(),
      billingDistance: this.billing().billDistance(),
      freightCharge: this.billing().freightCharge(),
    };

    this.isLoading(true);

    dataModel
      .ajaxRequest("order/CalculateFuelSurcharge", "get", routingParam)
      .done((response) => {
        if (response.success == false) {
          this.autoRateAndFSCErrors(response.message);
          this.isLoading(false);
          return;
        }
        this.autoRateAndFSCErrors("");
        var charge = {
          chargeCode: response.result.chargeCode,
          isEstimated: response.result.isEstimated,
          rate: response.result.rate,
          units: response.result.units,
          description: response.result.description,
          payMethod: response.result.payMethod,
        };
        var existingCharge = ko.utils.arrayFirst(
          this.billing().additionalChargesViewModel().additionalCharges(),
          (item) => {
            if (item.chargeCode() == response.result.chargeCode) {
              return true;
            }
            return false;
          }
        );
        if (existingCharge == null) {
          this.billing()
            .additionalChargesViewModel()
            .additionalCharges.push(
              new AdditionalCharge(
                charge,
                this.billing().additionalChargesViewModel()
              )
            );
        } else {
          existingCharge.isEstimated(response.result.isEstimated);
          existingCharge.rate(response.result.rate);
        }
        this.isLoading(false);
      })
      .fail(() => {
        this.isLoading(false);
      });
  };

  applyAutoRate = (autoRateData) => {
    if (autoRateData.isMinimumCharge == true) {
      this.billing().payMethod(3); // for flat
      this.billing().rate(parseFloat(autoRateData.minimumCharge));
    } else {
      this.billing().payMethod(autoRateData.rateType);
      this.billing().rate(parseFloat(autoRateData.rate));
      if (autoRateData.usingMinimumWeight) {
        this.billing().minWeight(autoRateData.minimumWeight);
      }
    }
    if (autoRateData.isCustomBillDistance == true) {
      this.billing().billDistance(autoRateData.billDistance);
    } else {
      this.calculateBillingDistance();
    }

    //Get the stops, if there's 3 stops with stop type id of 1 or 3 then
    var numPickupOrDelivery = 0;
    ko.utils.arrayForEach(this.stops(), (item) => {
      if (item.stopTypeCode() == "PU" || item.stopTypeCode() == "SO") {
        numPickupOrDelivery += 1;
      }
    });
    if (numPickupOrDelivery >= 3) {
      this.calculateBillingDistance();
    }
  };

  saveHazmat = () => {
    //skip on the initial page load
    if (this.isLoaded() == true) {
      if (this.orderId != null) {
        this.isLoading(true);
        var data = {
          OrderId: this.orderId,
          isHazmat: this.isHazmat(),
        };
        dataModel
          .ajaxRequest("order/UpdateHazmatStatus", "POST", data)
          .done(() => {
            this.isLoading(false);
          })
          .fail(() => {
            this.isLoading(false);
          });
      }
    }
  };

  cbOptionsHazmatRoutingClick = () => {
    if (this.isHazmat() == true) {
      this.requiredFields.required.push({
        id: this.requiredFields.required().length + 1,
        controlName: "autoCompleteGeneralUNNumber",
        text: "",
        section: "OrdersNew",
        fieldType: "AutoComplete",
        alwaysRequired: false,
        pageId: 82,
      });
    } else {
      this.requiredFields.required.remove((item) => {
        return item.controlName == "autoCompleteGeneralUNNumber";
      });

      this.isHazmat(false);
    }
    //when the user checks or unchecks hazmat routing, update and save the order's hazmat property in the database
    this.saveHazmat();
    //returning 'true' in the event handler makes the check appear or disappear in the checkbox. false makes the check unresponsive.
    return true;
  };

  calculateAutoRate = () => {
    var params = {};
    params.orderId = this.orderId();
    if (params.orderId == null) {
      // Maybe notify or hide the button until the order id exists?
      this.autoRateAndFSCErrors(
        "Please complete the required fields and then select the Auto Rate button."
      );
      return;
    }

    if (this.general() != null) {
      if (this.general().selectedCustomer() != null) {
        params.customerId = this.general().selectedCustomer().id;
      }
    }
    if (this.billing() != null) {
      params.billDistance = this.billing().billDistance();
      params.weight = this.billing().weight();
    }
    var stops = ko.toJS(this.stops);
    var origin = ko.utils.arrayFirst(stops, (item) => {
      if (item.isShipper == true) {
        return true;
      }
      return false;
    });
    var destination = ko.utils.arrayFirst(stops, (item) => {
      if (item.isConsignee == true) {
        return true;
      }
      return false;
    });

    params.origLocationCode = origin.selectedLocationCode;
    params.origCityStateZip = origin.cityStateZip;
    params.origEarliestArrival = origin.earliestArrival.toISOString();
    params.destLocationCode = destination.selectedLocationCode;
    params.destCityStateZip = destination.cityStateZip;
    params.destEarliestArrival = destination.earliestArrival.toISOString();
    this.isLoading(true);

    dataModel
      .ajaxRequest("order/CalculateAutoRate", "get", params)
      .done((response) => {
        if (response.success != true) {
          this.autoRateAndFSCErrors(response.message);
          this.isLoading(false);
          return;
        }
        if (response.results.length == 1) {
          this.applyAutoRate(response.results[0]);
        } else if (response.results.length > 1) {
          // display a modal with the rate information.
          this.showRateSelectModal(this, response.results);
        }
        if (response.results.length != 0) {
          this.autoRateAndFSCErrors("");
        } else {
          this.autoRateAndFSCErrors(
            "There is not an active rate table for this customer. Please contact the Traffic department to establish a rate table."
          );
        }

        this.isLoading(false);
      })
      .fail(() => {
        this.isLoading(false);
      });
  };

  billToCustomerSelected = async (selectedItem) => {
    if (selectedItem != undefined) {
      if (this.summary() != null) {
        var customerNoBroker =
          selectedItem.noBroker == null ? false : selectedItem.noBroker;
        var orderBrokered = this.isBrokered();

        await TNTContext.cancelAllTracking();

        TNTContext.updateSlice([
          {
            sliceKey: "defaultProviderId",
            payload:
              selectedItem.mappedVendorId || selectedItem.trackingVendorId,
          },
          {
            sliceKey: "trackedMovements:tracking",
            payload: {
              customerName: selectedItem.customerName,
              customerExternalId: selectedItem.code,
              trackingRequired:
                (selectedItem.mappedVendorId ?? selectedItem.trackingVendorId) >
                0,
            },
          },
        ]);

        if (
          this.summary().status() == "Quote" ||
          this.summary().status() == "Available" ||
          this.summary().status() == null
        ) {
          if (
            customerNoBroker &&
            (orderBrokered == false || orderBrokered == null)
          ) {
            this.isBrokered(false);
            this.noBroker(true);
          } else if (customerNoBroker && orderBrokered == true) {
            this.noBroker(false);
          } else {
            this.noBroker(false);
          }
        } else if (customerNoBroker && orderBrokered) {
          // The customer is no broker and it's a brokered order
          // display the red message and email traffic on save.
          this.noBroker(true);
        } else {
          this.noBroker(false);
        }
      } else {
        this.noBroker(false);
      }

      if (this.isTemplate() == false) {
        const { customerExternalId } = this.dataRecordFromServer() || {};

        if (selectedItem.code != customerExternalId) {
          const canProceed = await this.checkCustomerExclusiveAgencies(
            selectedItem.id,
            selectedItem.code
          );

          if (canProceed === false) {
            // If exclusive customer then reset the DDL
            this.general().billToCustomer(
              !this.isExistingOrder() ? null : customerExternalId
            );

            return;
          }
        }

        this.generateOrderId().then(() => {
          this.dataRecordFromServer({ customerExternalId: selectedItem.code });

          this.checkCustomerCredit({
            orderId: this.orderExternalId(),
            customerExId: selectedItem.code,
            totalCharge: this.summary().totalCharge(),
          });
        });
      }

      this.requiredFields.getRequiredAndHiddenFields(selectedItem.code);
    } else {
      this.agentRisk("");
      this.customerCredit(null);
    }
  };

  checkCustomerExclusiveAgencies = async (customerId, customerExId) => {
    const fetchExclusiveAgenciesAsync = (id, agencyExId) => {
      return new Promise((resolve) => {
        dataModel
          .ajaxRequest(
            "Customer/VerifyExclusiveAgenciesByAgencyExId",
            "GET",
            { customerId: id, agencyExId },
            true
          )
          .then((agencies) => resolve(agencies))
          .catch((err) => {});
      });
    };

    try {
      const agency = this.agencyId() ?? userProfile.currentAgencyExId();
      this.isLoading(true);
      const result = await fetchExclusiveAgenciesAsync(customerId, agency);
      this.isLoading(false);
      if (result.length) {
        const msg = ` The customer ${
          customerExId ? "(" + customerExId + ")" : ""
        } you have selected is an exclusive customer for these agencies. If the agency you are creating the order for
                              is not in this list please search for a new code or contact the Credit Department for the correct customer code.
                                <br/><br/>
                              ${result.join(", ")}
                            `;

        showmessage(msg);

        return false;
      }
    } catch (err) {
      this.isLoading(false);
      console.error(err);
      return false;
    }

    return true;
  };

  // Loads customer credit
  checkCustomerCredit = async ({
    orderId = undefined,
    customerExId = "",
    totalCharge = 0,
  } = {}) => {
    const fetchCreditRisk = () => {
      const payload = {
        orderId: orderId || this.orderExternalId() || "",
        customerId: customerExId,
        totalCharge:
          totalCharge ??
          (this.summary() &&
            this.summary().totalCharge &&
            this.summary().totalCharge()) ??
          0,
      };

      return new Promise((resolve) => {
        dataModel
          .ajaxRequest("Customer/GetCreditRisk", "get", payload)
          .done(resolve);
      });
    };

    try {
      this.isLoading(true);
      const result = await fetchCreditRisk();
      this.isLoading(false);

      this.customerCredit({ ...result, customerExId });
      this.agentRisk(result.creditMessage);
    } catch (err) {
      this.isLoading(false);
      console.error(err);
    }
  };

  removeDirectionComment = (stop, remove) => {
    var note = ko.utils.arrayFirst(stop.stopNotes(), (note) => {
      return (
        note.locationId() != null &&
        note.locationId() != stop.locationId() &&
        note.noteTypeId() == 2
      );
    });
    if (note != null) {
      if (remove) {
        stop.stopNotes.remove(note);
      }
    }
  };

  deleteLoadUnloadComment = (noteId) => {
    if (noteId != undefined) {
      return dataModel.ajaxRequest("Order/DeleteStopNote", "DELETE", {
        noteId: noteId,
      });
    }
  };

  isLoadingInstructionChanged = () => {
    return new Promise((resolve, reject) => {
      var shipper = ko.utils.arrayFirst(this.stops(), (item) => {
        if (
          item.stopType() == 1 &&
          item.selectedLocationId() != item.originalSelectedLocationId()
        ) {
          return ko.utils.arrayFirst(item.stopNotes(), (note) => {
            return (
              note.locationId() != null &&
              note.locationId() != item.locationId() &&
              note.noteTypeId() == 2 &&
              note.sequence() == 1
            );
          });
        }
      });

      if (shipper != null) {
        var stopNumber = shipper.sequence();
        this.isLoading(false);
        var msg =
          "Loading comment for " +
          shipper.selectedLocationCode() +
          " on stop " +
          stopNumber +
          " does not match previous location's comment. Do you want to delete the previous loading comment?";
        showconfirm(msg)
          .then((x) => {
            if (x == true) {
              // User said ok, so let's go ahead and remove it before making a save order call
              var note = ko.utils.arrayFirst(shipper.stopNotes(), (note) => {
                return (
                  note.locationId() != null &&
                  note.noteTypeId() == 2 &&
                  note.sequence() < 2
                ); // unload - load instructions should have sequence of 1
              });
              this.deleteLoadUnloadComment(note.id());
              this.removeDirectionComment(shipper, true);
            } else {
              this.removeDirectionComment(shipper, false);
            }
          })
          .finally(() => {
            this.isLoading(true);
            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  isUnloadingInstructionChanged = () => {
    return new Promise((resolve, reject) => {
      var consignee = ko.utils.arrayFirst(this.stops(), (item) => {
        if (
          item.stopType() == 3 &&
          item.selectedLocationId() != item.originalSelectedLocationId()
        ) {
          return ko.utils.arrayFirst(item.stopNotes(), (note) => {
            return (
              note.locationId() != null &&
              note.locationId() != item.locationId() &&
              note.noteTypeId() == 2 &&
              note.sequence() == 1
            );
          });
        }
      });
      if (consignee != null) {
        var stopNumber = consignee.sequence();
        this.isLoading(false);
        var msg =
          "Unloading comment for " +
          consignee.selectedLocationCode() +
          " on stop " +
          stopNumber +
          " does not match previous location's comment. Do you want to delete the previous unloading comment?";
        showconfirm(msg)
          .then((x) => {
            if (x == true) {
              // User said ok, so let's go ahead and remove it before making a save order call
              var note = ko.utils.arrayFirst(consignee.stopNotes(), (note) => {
                return (
                  note.locationId() != null &&
                  note.noteTypeId() == 2 &&
                  note.sequence() < 2
                ); // unload - load instructions should have sequence of 1
              });
              this.deleteLoadUnloadComment(note.id());
              this.removeDirectionComment(consignee, true);
            } else {
              this.removeDirectionComment(consignee, false);
            }
          })
          .finally(() => {
            this.isLoading(true);
            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  generateOrderId = () => {
    return new Promise((resolve, reject) => {
      if (
        this.isExistingOrder() == false &&
        this.general().billToCustomer() != null &&
        this.general().revenueCode() != null &&
        this.isActiveAgency() === true
      ) {
        var data = {
          billToCustomerId: this.general().billToCustomer(),
          revenueCodeId: this.general().revenueCode(),
          agencyId: userProfile.currentAgencyId(),
          orderTemplateId: this.orderTemplateId(),
        };

        dataModel
          .ajaxRequest("order", "POST", data)
          .done((data) => {
            this.orderId(data.id);
            this.orderExternalId(data.externalId);
            this.currentMovementId(data.currentMovementId);
            this.isTemplate(false);
            this.orderEntryTracking().data.viewingOrderId(data.id);
            this.orderEntryTracking().data.orderOpenedAtTimestamp(
              data.orderOpenedAtTimestamp
            );
            this.summary().orderId(data.externalId);
            this.summary().status("Quote");
            this.summary().distanceSearchType(data.distanceSearchType);

            var general = this.general();
            general.enteredBy(data.enteredBy);

            this.agencyId(data.agencyExId);

            //assign the stop ids for both the shipper and consignee stops created in generateOrderId
            var shipperIndex = 0;
            var consigneeIndex = this.stops().length - 1;
            this.stops()[shipperIndex].id = data.shipperId;
            this.stops()[consigneeIndex].id = data.consigneeId;

            ko.utils.arrayForEach(this.stops(), (item) => {
              item.movementId = data.currentMovementId;
            });
            this.isExistingOrder(true);
            if (data.lockDurationSeconds) {
              this.isLoaded(false);
              this.orderLockComponent(
                _.merge({
                  lockOrderControls: ko.observable(false),
                  orderOpenedAtTimestamp:
                    this.orderEntryTracking().data.orderOpenedAtTimestamp,
                  $parent: this,
                  locked: true,
                  orderId: this.orderId(),
                  updatedBy: data.updatedBy,
                  lockDurationSeconds: data.lockDurationSeconds,
                  orderTimeLock: data.orderTimeLock,
                  newOrder: true,
                })
              );
            }
            //Changing URL of page when order Id is generated.
            window.history.pushState(
              "object or string",
              "Title",
              "/NewOrder/" + this.orderExternalId()
            );
            resolve();
          })
          .fail((resp) => {
            if (resp?.responseJSON?.message == "RESTRICTED_CUSTOMER") {
              this.checkCustomerExclusiveAgencies(
                this.general().billToCustomer()
              );
              // showmessage(
              //   "The customer you have selected is restricted and not available for use by this agency. Please search for another code or contact the Credit Department.",
              //   "danger"
              // );
            } else {
              showmessage(
                "There was an error generating the order number for this load.  Please try again and contact the helpdesk if this issue persists",
                "danger"
              );
            }

            reject();
          });
      } else {
        resolve();
      }
    });
  };

  validationObject = () => {
    return [
      this.general().validationObject(),
      this.stops().map((x) => x.validationObject()),
      this.billing().validationObject(),
    ].concat(this.container() ? this.container().validationObject() : []);
  };

  validationErrors = ko.pureComputed(() => {
    //Since 'this' below is not an observable, we need to reference the
    //upper level observable models to force the computed to updated
    this.general();
    this.stops();
    this.container();
    this.billing();
    this.dummyVariableSwitchForComputeds();
    this.errors();
    let errors = ko.validation.group(this.validationObject(), { deep: true });
    return errors;
  });

  isValidOrder = () => {
    this.errors.removeAll();
    var validationErrors = this.validationErrors();
    if (validationErrors().length > 0) {
      validationErrors.showAllMessages();
      scrollToRequiredField();
      this.errors(validationErrors());
      return false;
    }
    var billing = this.billing();
    if (billing.totalCharge() > 0 == false) {
      this.errors.push(
        "Order can not be saved until Total Charge is greater than 0"
      );
      return false;
    }
    return true;
  };

  initiateSaveOrderProcess = (isQuote, keepLock = true) => {
    this.dummyVariableSwitchForComputeds(
      !this.dummyVariableSwitchForComputeds()
    );
    return new Promise((resolve, reject) => {
      if (this.isTemplate() == true) {
        this.isLoading(true);
        this.generateOrderId()
          .then(() => {
            this.isLoading(false);
            return this.isLoadingInstructionChanged();
          })
          .then(() => {
            return this.isUnloadingInstructionChanged();
          })
          .then(() => {
            var data = ko.toJS(this);
            data.options = this.getOptionVariables();
            data.isQuote = isQuote;
            return this.submitOrderToServer(data);
          })
          .then(() => {
            this.showAllErrorMessages();
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject();
          })
          .finally(() => {
            this.isLoading(false);
          });
      } else {
        this.isLoadingInstructionChanged()
          .then(() => {
            return this.isUnloadingInstructionChanged();
          })
          .then(() => {
            var data = ko.toJS(this);
            data.options = this.getOptionVariables();
            data.isQuote = isQuote;
            data.keepLock = keepLock;
            return Promise.all(this.beforeSaveCallbacks.map(fn => fn(data))).then(() => this.submitOrderToServer(data)).catch((err) => {
              this.isLoading(false);

              if(typeof err === 'string') {
                showmessage(err);
              }
              else if('message' in err) {
                showmessage(err.message);
              }
              else {
                console.error(err);
              }

              return reject();
            })
          })
          .then(() => {
            this.isLoading(false);
            this.showAllErrorMessages();
            return resolve();
          })
          .catch((err) => {
            this.isLoading(false);
            showmessage(err);
            console.error(err);
            reject();
          });
      }
    });
  };

  getStopNoteErrors = (stops = []) => {
    return stops.reduce((err, stop) => {
      const notes = stop.stopNotes ?? [];
      notes.forEach((note) => {
        if (!note.noteTypeId) {
          err = `Stop ${stop.sequence} is missing a note type for one or more stop notes.`;
        }
      });

      return err;
    }, "");
  };

  submitOrderToServer = async (data) => {
    this.isLoading(false);

    if (TNTContext.isTrackingOn() && this.skipTrackingSaveCheck === false) {
      // Set the order tracking number to the current movement customerTrackingId (customer)
      const currentMove = TNTContext.currentMoveTracking();
      const stops = (ko.toJS(this.stops() ?? [])).filter(s => s.movementExternalId === currentMove.movementExId);
      const sortedStops = [...stops].sort((a, b) => b.sequence - a.sequence);

      
      if(currentMove.tracking.trackingStatusId !== TRACKINGSTATUS_ENUM.Cancelled && currentMove.tracking.byPassTracking === false) {
        const lastStop = sortedStops[0];
        let errorMsg = "";

        if(lastStop && lastStop.earliestArrival) {
          const eaWithTime = lastStop.earliestArrival.add(23, "hour").add(59, "minute");
          if(dayjs().isAfter(eaWithTime)) {
            errorMsg = "Movement delivery date is in the past. Date must be later than current datetime for tracking.";
          }
        }

        const errors = currentMove.getValidationErrorObj(currentMove.tracking, TNTContext.getState().isBrokered);
        if (!errorMsg && errors.count) {
          errorMsg = currentMove.tracking.customerTrackingId ? "Please correct any tracking errors." : "The Customer Load Number field for the current movement tracking must be entered to continue.";
        }

        // Update or remove movement error message
        TNTContext.upsertTrackedMovement(currentMove.movementExId, {errorMsg})

        // If we do have an error then reject and exit process.
        if(errorMsg) {
          return Promise.reject(errorMsg);
        }

        data.trackingNumber = currentMove.tracking.customerTrackingId;
      }
    }

    this.skipTrackingSaveCheck = false;

    if (!(data.orderId > 0)) {
      this.isLoading(false);
      return  Promise.reject();
    }

    if (!(data.billing.rate > 0)) {
      return Promise.reject("Please Enter Rate Information Before Saving Order");
    }

    const stopNoteError = this.getStopNoteErrors(data.stops ?? []);

    if (stopNoteError) {
      showmessage(stopNoteError);
      this.isLoading(false);
      return Promise.reject(stopNoteError);
    }

    return new Promise((resolve, reject) => {
      this.isLoading(true);
      data.orderTracking = this.orderEntryTracking().data.toJSON();
      delete data.saveTemplateOrderModal;
      delete data.useTemplateOrderModal;

//Hotfix for ticket ge-1962, commenting out change from ticket ge-1795
//      data.stops.forEach((stop) => {
//        stop.stopNotes = GetUniqueStopNotes(stop.stopNotes).filter(
//          (q) => q.locationId == null
//       );
//      });

      dataModel
        .ajaxRequest("order/" + data.orderId, "put", data)
        .done(async (data) => {
          try {
            if (data.finalizeBlocked) {
              showmessage(
                "This order exceeds the customer's credit limit.  Please contact your agency manager to Finalize this order."
              );
            }

            this.skipTrackingSaveCheck = false;

            this.isTemplate(false);
            this.movementStatusId(data.movementStatusId);
            this.agentRisk(data.agentRisk);
            this.readyToBill(data.readyToBill);
            this.agencyId(data.agencyExId);

            this.stops([]);
            if (data.stops == undefined || data.stops.length < 2) {
              data.stops = [
                {
                  movementSequence: 1,
                  sequence: 1,
                  movementId: data.currentMovementId,
                  isShipper: true,
                  isConsignee: false,
                  stopType: "PU",
                  status: "A",
                },
                {
                  movementSequence: 2,
                  sequence: 2,
                  movementId: data.currentMovementId,
                  isShipper: false,
                  isConsignee: true,
                  stopType: "SO",
                  status: "A",
                },
              ];
            }
            this.stops(
              data.stops.map((stop) => {
                const { modelId } =
                  (data.stopModelIdStopIdPairs || [{}]).find(
                    ({ stopId }) => stopId === stop.id
                  ) || {};
                return new StopModel({
                  stop: { ...stop, modelId },
                  mainModel: this,
                });
              })
            );

            this.billing().billDistance(data.billDistance);
            this.billing().movementDistance(data.movementDistance);
            let movementIds = [
              ...new Set(data.stops.map((st) => st.movementId)),
            ];

            this.billing()
              .additionalChargesViewModel()
              .additionalCharges()
              .forEach((ac) => {
                let dep = ac.driverExtraPays().filter((fil) => {
                  return movementIds.some((som) => {
                    return som == fil.movementId();
                  });
                });
                ac.driverExtraPays(dep);
              });

            let seperateDriverExtraPays = this.billing()
              .additionalChargesViewModel()
              .seperateDriverExtraPays()
              .filter((fil) => {
                return movementIds.some((som) => {
                  return som == fil.movementId();
                });
              });
            this.billing()
              .additionalChargesViewModel()
              .seperateDriverExtraPays(seperateDriverExtraPays);

            if (data.hasCustomDistance) {
              this.billing().calculatedBillingDistance(-1);
            } else {
              this.billing().calculatedBillingDistance(data.billDistance);
            }

            this.orderAvailableOrInProgress(
              ["Available", "In Progress"].some((x) => x === data.status)
            );

            this.summary().agencyId(data.agencyExId);
            this.summary().status(data.status);
            this.summary().fuelLimit(data.fuelLimit);
            this.summary().totalCharge(data.totalCharge);

            this.brokered(this.isBrokered());

            this.isEdiCustomer(data.isEdiCustomer);
            this.ediPartnerId(data.ediPartnerId);

            await this.orderEntryTracking()
              .methods()
              .saveUpdateTrackingAndRefreshTimestamp();

            if (this.summary().status() !== "Quote") {
              await this.syncTrackTraceData(data);

              if (
                this.orderEntryDispatchBrokerageParameters() &&
                this.orderEntryDispatchBrokerageParameters()
                  .saveOrderEntryDispatch
              ) {
                await this.orderEntryDispatchBrokerageParameters().saveOrderEntryDispatch(
                  true
                );

                console.log("Dispatch 1");
              }

              if (
                this.orderEntryDispatchParameters() &&
                this.orderEntryDispatchParameters().saveOrderEntryDispatch
              ) {
                await this.orderEntryDispatchParameters().saveOrderEntryDispatch(
                  this.rebuildDispatch()
                );

                console.log("Dispatch 2");
              }

              console.log("End");
              if (this.rebuildDispatch()) {
                console.log("Rebuild");
                this.buildOrderEntryDispatch();
                this.rebuildDispatch(false);
              }
            }

            this.isLoading(false);
            resolve();
          } catch (err) {

            console.error(err);
            this.isLoading(false);
            reject();
          }
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          this.isLoading(false);
          if (jqXHR.responseJSON != null) {
            var json = jqXHR.responseJSON;
            if (jqXHR.status == 409) {
              // Conflict
              this.orderEntryTracking()
                .methods()
                .displayConflictNotifyMessage(json);
              resolve();
            } else if (jqXHR.status == 412) {
              // Order Locked
              showmessage(jqXHR.responseJSON.message);
              resolve();
            }
            if (json.modelState != null) {
              this.errors.push(
                "You have not completed all required fields.  Please review your entry"
              );
            } else if (
              jqXHR.responseJSON.message.startsWith("No HTTP resource")
            ) {
              this.errors.push(
                "This order must be assigned an order number to proceed.  Please ensure you have selected a valid customer for this order.  Contact the help desk if you continue to see this message"
              );
            } else {
              this.errors.push(json.message);
            }
            $("html, body").animate(
              {
                scrollTop: $("#ulOrderErrors").offset().top - 50,
              },
              "slow"
            );
            if (jqXHR.responseJSON.message.startsWith("No HTTP resource")) {
              reject(
                "This order must be assigned an order number to proceed.  Please ensure you have selected a valid customer for this order.  Contact the help desk if you continue to see this message"
              );
            } else {
              reject(jqXHR.responseJSON.message);
            }
          } else {
            reject(textStatus);
          }
        });
    });
  };

  buildDispatchOnlyDispatch = (data) => {
    if (data.brokered) {
      this.orderEntryDispatchBrokerageParameters({
        billingDistance: ko.observable(data.order.billDistance),
        weight: ko.observable(data.order.weight),
        pieces: ko.observable(data.order.pieces),
        freightCharge: ko.observable(data.order.freightCharge),
        otherCharges: ko.observable(data.order.otherCharges),
        totalCharge: ko.observable(data.order.totalCharge),
        orderId: ko.observable(data.order.orderId),
        orderExId: ko.observable(data.order.orderExId),
        orderStatus: ko.observable(data.order.orderStatus),
        salesPersonSelectedValue: ko.observable(
          data.order.salesPersonSelectedValue
        ),
        selectedCustomer: ko.observable(data.order.selectedCustomer),
        customerTargetProfit: ko.observable(data.order.customerTargetProfit),
        minWeight: ko.observable(data.billing.minWeight),
      });
    } else {
      this.orderEntryDispatchParameters({
        weight: ko.observable(data.order.weight),
        orderId: ko.observable(data.order.orderId),
        orderExId: ko.observable(data.order.orderExId),
        orderStatus: ko.observable(data.order.orderStatus),
        minWeight: ko.observable(data.billing.minWeight),
        isHazmat: this.isHazmat || ko.observable(data.order.isHazmat),
        tractorExId: ko.observable(data.summary.tractorExId),
        tractorId: ko.observable(),
        driverCarrier: this.driverCarrier,
        fuelLimit: ko.observable(data.summary.fuelLimit),
      });
    }
  };

  buildOrderEntryDispatch = () => {
    this.orderEntryDispatchBrokerageParameters(undefined);
    this.orderEntryDispatchParameters(undefined);

    if (this.isBrokered()) {
      this.orderEntryDispatchBrokerageParameters({
        billingDistance: this.billing().billDistance,
        weight: this.billing().weight,
        pieces: this.billing().pieces,
        freightCharge: this.billing().freightCharge,
        otherCharges: this.billing().otherCharges,
        totalCharge: this.billing().totalCharge,
        orderId: this.orderId(),
        orderExId: this.orderExternalId(),
        orderStatus: this.summary().status,
        salesPersonSelectedValue: this.general().salesPersonSelectedValue,
        selectedCustomer: this.general().selectedCustomer,
        customerTargetProfit: this.customerTargetProfit,
        orderEntryErrors: this.validationErrors,
        minWeight: this.billing().minWeight,
        driverCarrier: this.driverCarrier,
        //tractorId: this.summary().tractorExId
      });
    } else {
      this.orderEntryDispatchParameters({
        weight: this.billing().weight,
        orderId: this.orderId(),
        orderExId: this.orderExternalId(),
        orderStatus: this.summary().status,
        orderEntryErrors: this.validationErrors,
        minWeight: this.billing().minWeight,
        isHazmat: this.isHazmat,
        tractorExId: this.summary().tractorExId,
        tractorId: ko.observable(),
        driverCarrier: this.driverCarrier,
        fuelLimit: this.summary().fuelLimit,
      });
    }
  };

  //Do not let user finalize or save order that has incorrect terminal code without correcting or removing it
  finalizeOrder = () => {
    return new Promise((resolve, reject) => {
      var customerSelected = this.general().selectedCustomer();
      this.requiredFields
        .getRequiredAndHiddenFields(customerSelected.code)
        .then(() => {
          var isValidOrder = this.isValidOrder();
          if (isValidOrder) {
            if (this.isTerminalCorrectionRequired() == false) {
              this.initiateSaveOrderProcess()
                .then(() => {
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            } else {
              $("html, body")
                .delay(600)
                .animate(
                  {
                    scrollTop: $("#filter").offset().top - 50,
                  },
                  1000
                );
              this.terminalErrorMessage(
                "Please either remove the invalid Terminal Code or input a correct one"
              );
              this.errors.push(
                "Please either remove the invalid Terminal Code or input a correct one"
              );
            }
          } else {
            reject();
          }
        });
    });
  };

  //Do not let user finalize or save order that has incorrect terminal code without correcting or removing it
  saveQuote = () => {
    this.isLoading(true);
    var orderStatus = this.summary().status();
    var customerSelected = this.general().selectedCustomer();
    if (!customerSelected) {
      this.errors.push("Bill-To Customer is required.");
      this.isLoading(false);
      return false;
    }

    if (orderStatus != "Quote") {
      if (this.isTerminalCorrectionRequired() == true) {
        this.errors.removeAll();
        //scroll to terminal field
        $("html, body")
          .delay(600)
          .animate(
            {
              scrollTop: $("#filter").offset().top - 50,
            },
            1000
          );
        //error message
        this.terminalErrorMessage(
          "Please either remove the invalid Terminal Code or input a correct one"
        );
        this.errors.push(
          "Please either remove the invalid Terminal Code or input a correct one"
        );
        this.isLoading(false);
        return false;
      } else {
        this.finalizeOrder().then(
          () => {
            this.isLoading(false);
          },
          () => {
            //Rejected
            this.isLoading(false);
          }
        );
      }
    } else {
      this.errors.removeAll();
      var general = this.general();
      var validationErrors = ko.validation.group([
        general.billToCustomer,
        general.revenueCode,
      ]);
      if (this.isTerminalCorrectionRequired() == true) {
        //scroll to terminal field
        $("html, body")
          .delay(600)
          .animate(
            {
              scrollTop: $("#filter").offset().top - 50,
            },
            1000
          );
        //error message
        this.terminalErrorMessage(
          "Please either remove the invalid Terminal Code or input a correct one"
        );
        this.errors.push(
          "Please either remove the invalid Terminal Code or input a correct one"
        );
        this.isLoading(false);
        return false;
      }

      if (validationErrors().length > 0) {
        validationErrors.showAllMessages();
        scrollToRequiredField();
        this.isLoading(false);
        return false;
      }
      this.initiateSaveOrderProcess(true)
        .then(() => {
          this.isLoading(false);
        })
        .catch(() => this.isLoading(false));
    }
  };

  showAllErrorMessages = () => {
    var validationErrors = ko.validation.group(this.validationObject());
    this.errors(validationErrors());
    validationErrors.showAllMessages();
    if (validationErrors().length > 0) {
      $("html, body").animate({ scrollTop: $(document).height() }, "slow");
    }
    return validationErrors().length;
  };

  showSaveTemplate = () => {
    this.saveTemplateOrderModal(new TemplateModel(this));
  };

  showUseTemplateOrder = () => {
    this.useTemplateOrderModal(new UseTemplateModel(this));
  };

  showOrderImages = () => {
    this.orderImagesModal(new ImageViewModel(this.orderExternalId()));
  };

  showGEMUploads = () => {
    this.orderGemUploadsModal(new GemUploadViewModel (this.orderExternalId()));
  };

  scrollToDispatch = () => {
    $("html,body").animate(
      {
        scrollTop: $("#orderEntryDispatchDiv").offset().top,
      },
      "slow"
    );
  };

  showDuplicateOrder = () => {
    if (this.general().selectedCustomer().isActive == false) {
      showmessage(
        `The customer for this order is inactive and the order cannot be duplicated.
                Please contact the Credit department regarding the customer's status if you have any questions.`,
        "messagedialogred"
      );
    } else {
      this.duplicateOrderModal({
        orderId: this.orderId,
        noBroker: this.noBroker,
        isBrokered: this.isBrokered,
        customField1Label: this.general().customField1Label(),
        customField2Label: this.general().customField2Label(),
        customField3Label: this.general().customField3Label(),
      });
    }
  };

  showAuditLog = () => {
    this.isLoading(true);
    this.auditLogModal(undefined);
    dataModel
      .ajaxRequest("order/AuditLog/" + this.orderExternalId())
      .done((data) => {
        this.auditLogModal({
          log: data,
        });
      })
      .fail(() => {
        showmessage("Something bad happened.");
      })
      .always(() => {
        this.isLoading(false);
      });
  };

  showTenderStatus = () => {
    this.tenderStatusModal(undefined);
    this.isLoading(true);
    dataModel
      .ajaxRequest("order/TenderStatus/" + this.orderId())
      .done((data) => {
        this.tenderStatusModal({
          tenderLoads: data,
        });
      })
      .always(() => {
        this.isLoading(false);
      });
  };

  showGeoLocations = async () => {
    if (TNTContext.hasActiveTracking()) {
      this.trackingMapModal({
        orderId: this.orderId(),
        isLoading: this.isLoading,
      });
    } else {
      this.geoLocationsModal({
        orderId: this.orderId(),
        isLoading: this.isLoading,
        pingFrequency: this.summary().pingFrequency(),
      });
    }
  };

  openCallInRecords = () => {
    this.callInModals.callInListModal({
      orderExId: this.orderExternalId(),
      isBrokered: this.getIsBrokered(),
      tractorId:
        this.getIsBrokered() == false
          ? this.orderEntryDispatchParameters().tractorId()
          : undefined,
      driverCarrierId:
        this.getIsBrokered() == false
          ? this.orderEntryDispatchParameters().driverCarrier()
          : this.orderEntryDispatchBrokerageParameters().driverCarrier(),
      movementId: this.currentMovementId(),
    });
  };

  showMakeQuoteOrder = () => {
    showconfirm(
      "Are you sure you want to make this voided order into a quote order?"
    ).then((x) => {
      if (x == true) {
        this.makeQuoteOrder();
      }
    });
  };

  voidOrder = async () => {
    if (TNTContext.isTrackingOn()) {
      await TNTContext.cancelAllTracking();
    }

    this.isLoading(true);
    dataModel
      .ajaxRequest("order/VoidOrder/" + this.orderId(), "post")
      .done(() => {
        if (window.location.href.toLowerCase().indexOf("-void") > -1) {
          var location = window.location.href
            .toLowerCase()
            .replace("-void", "");
          router.navigate(location);
        } else {
          this.requiredFields.enabledRequiredFields(false);
          this.summary().status("Voided");
          var billing = this.billing();
          billing.billDistance(undefined);
          billing.minWeight(undefined);
          billing.rate(undefined);
          billing.unitsDescription(undefined);
          billing.additionalChargesViewModel().additionalCharges([]);
          this.isReadOnlyOrder(true);
          this.orderEntryDispatchBrokerageParameters(undefined);
          this.orderEntryDispatchParameters(undefined);
        }
        this.isLoading(false);
      })
      .fail((jqXHR) => {
        if (jqXHR.responseJSON != null) {
          showmessage(jqXHR.responseJSON.message);
        }
        this.isLoading(false);
      });
  };

  makeQuoteOrder = () => {
    this.isLoading(true);
    dataModel
      .ajaxRequest("order/MakeQuoteOrder/" + this.orderId(), "post")
      .done(() => {
        if (window.location.href.toLowerCase().indexOf("-voidquote") > -1) {
          var location = window.location.href
            .toLowerCase()
            .replace("-voidquote", "");
          router.navigate(location);
        } else {
          this.loadOrder();
        }
        this.isLoading(false);
      })
      .fail(() => {
        this.isLoading(false);
      });
  };

  expireLoad = () => {
    dataModel
      .ajaxRequest(
        "Freightboard/ExpireLoad/" + this.loadIdCreatedFromOrder(),
        "post"
      )
      .done(() => {
        this.loadIdCreatedFromOrder(undefined);
        showmessage("Load has been expired from Loadboard");
      });
  };

  toggleSummaryStickyElement = () => {
    $("#divSideBar").animate({ width: "toggle" }, "slow");
    var btnShowSideBar = $("#btnShowSideBar");
    btnShowSideBar.blur();
    btnShowSideBar.find("span").toggleClass("glyphicon-chevron-right");
    var grid = $(".geGrid");
    if (grid.length > 0) {
      setTimeout(() => {
        grid.jqxGrid("render");
      }, 600);
    }
    $(".orderEntryAdditionalChargesTable, .orderEntryDispatchStopsTable")
      .toggleClass("orderEntryTableWrapperWithoutSidebar")
      .toggleClass("orderEntryTableWrapperWithSidebar");
  };

  checkCustomerRequiredFields = () => {
    var customerSelected = this.general().selectedCustomer();
    return this.requiredFields.getRequiredAndHiddenFields(
      customerSelected.code
    );
  };

  isFieldHidden = (name) => {
    // Need a function on this context so we can access it in the view easier.
    return this.requiredFields.isHidden(name);
  };

  sendToLoadboardActionSelected = () => {
    var additionalChargesUnwrapped = ko.toJS(
      this.billing().additionalChargesViewModel().additionalCharges()
    );
    ko.utils.arrayForEach(additionalChargesUnwrapped, (charge) => {
      charge.chargeId = charge.chargeCode?.trim();
      return charge;
    });
    var postingDimensions = this.stops()
      .filter((value) => {
        return value.postingDimensions() == true;
      })
      .map((value) => {
        return {
          height: value.height(),
          width: value.width(),
          length: value.length(),
        };
      });
    var data = {
      orderId: this.orderId(),
      orderStatus: this.summary() != null ? this.summary().status() : undefined,
      customerId:
        this.general().selectedCustomer() != null
          ? this.general().selectedCustomer().code
          : undefined,
      trailerType:
        this.general().selectedTrailerType() != null
          ? this.general().selectedTrailerType().code
          : undefined,
      originLocationId: this.stops()[0].selectedLocationCode(),
      originCityStateZip: this.stops()[0].cityStateZip(),
      pickUpDate: this.stops()[0].earliestArrival(),
      destLocationId:
        this.stops()[this.stops().length - 1].selectedLocationCode(),
      destCityStateZip: this.stops()[this.stops().length - 1].cityStateZip(),
      deliveryDate: this.stops()[this.stops().length - 1].earliestArrival(),
      commodity: this.general().commodity(),
      brokered: this.isBrokered(),
      hazmat: this.isHazmat(),
      loadType: "T",
      lineHaul: this.billing().grossPay(),
      loadCharges: additionalChargesUnwrapped,
      miles: this.billing().billDistance(),
      postingDimensions: postingDimensions[0],
    };

    this.postLoadModal({ load: data });
  };

  validateArApproval = () => {
    return new Promise((resolve, reject) => {
      const orderId = this.orderId();
      const payload = { ...ko.toJS(this), options: this.getOptionVariables() };
      // Need to remove this before parsing to JSON using ko.toJSON in the datamModel
      // or throws recurrsion errors
      delete payload.saveTemplateOrderModal;
      delete payload.useTemplateOrderModal;

      dataModel
        .ajaxRequest(`Order/CheckARApproval/${orderId}`, "POST", payload)
        .done(resolve)
        .fail((err) =>
          reject(
            (err.responseJSON && err.responseJSON.message) ||
              `Error occurred during request.`
          )
        );
    });
  };

  syncTrackTraceData = async (data = {}) => {
    const updates = [];

    TNTContext.updateSlice({
      sliceKey: "currentMovementId",
      payload: data.currentMovementId ?? this.currentMovementId(),
    });

    const shipper = data.stops.find((x) => x.isShipper) || {};
    updates.push({
      sliceKey: "defaultStartTracking",
      payload: datetimeUTC(shipper.earliestArrival),
    });

    const consignee = data.stops.find((x) => x.isConsignee) || {};
    updates.push({
      sliceKey: "orderDeliveryDate",
      payload: datetimeUTC(consignee.earliestArrival),
    });

    updates.push({
      sliceKey: "defaultProviderId",
      payload:
        data.defaultProviderId || data.mappedVendorId || data.trackingVendorId,
    });

    updates.push({
      sliceKey: "isBrokered",
      payload:
          data.brokered ?? data.isBrokered ?? this.isBrokered() ?? false,
    });

    const trackingRequired =
      (data.defaultProviderId || data.mappedVendorId || data.trackingVendorId) >
      0;
    updates.push({
      sliceKey: "trackedMovements:tracking",
      payload: {
        trackingRequired,
      },
    });

    if (TNTContext.isTrackingOn() || trackingRequired) {
      // Sync stops to the TNT datastore / models
      const allStops = ko.toJS(this.stops());
      const comments = TNTContext.selectSlice("stopComments");

      // ONLY UPDATE THIS ORDER'S MOVEMENTS
      const moves = TNTContext.getOrderMovements();
      const mapped = moves.map((x) => {
        const moveStops = allStops.filter(
          (s) => s.movementExternalId === x.movementExId
        );
        const tntMoveStops = moveStops.map((s) => {
          const sc = comments.find((x) => x.uid === s.modelId) ?? {};
          const csz = mapToCityStateZipObject(
            cleanString(["(", ")", ","], s.cityStateZip).split(" ")
          );

          const tntStop = TnTStopModel();
          tntStop.actualArrival = datetimeUTC(s.earliestArrival).isValid()
            ? datetimeUTC(s.earliestArrival).format("MM/DD/YYYY HH:mm")
            : null;
          tntStop.actualDeparture = datetimeUTC(s.latestArrival).isValid()
            ? datetimeUTC(s.latestArrival).format("MM/DD/YYYY HH:mm")
            : null;
          tntStop.scheduledArrival = datetimeUTC(s.earliestArrival).isValid()
            ? datetimeUTC(s.earliestArrival).format("MM/DD/YYYY HH:mm")
            : null;
          tntStop.address = s.address;

          tntStop.externalId = s.externalId;
          tntStop.isConsignee = s.isConsignee;
          tntStop.isShipper = s.isShipper;
          tntStop.geoFenceRadius = x.movementGeoFenceRadius;
          tntStop.latitude = s.latitude;
          tntStop.longitude = s.longitude;
          tntStop.modelId = s.modelId;

          tntStop.stopType = s.stopType;
          tntStop.stopComment = sc.comment;
          tntStop.sequence = s.sequence;
          tntStop.city = csz.city;
          tntStop.state = csz.state;
          tntStop.zipCode = csz.zip;
          tntStop.movementExId = s.movementExternalId;
          tntStop.geoFenceRadius = x.movementGeoFenceRadius;

          return tntStop;
        });
        x.stops = tntMoveStops;

        const tracking = {
          ...x.tracking,
          agencyId: data.agencyExId,
          agentName: data.agencyName,
          trackingRequired: data.customerRequiresTracking,
          orderId: data.orderExId,
          orderStatus: data.status,
        };

        x.tracking = tracking;

        return x;
      });

      const currentMove = TNTContext.currentMoveTracking();

      updates.push({ sliceKey: "trackedMovements", payload: mapped });

      TNTContext.updateSlice(updates);

      if (currentMove != null) {
        await TNTContext.saveTracking();
      }

      if (currentMove != null
        && (currentMove.tracking.trackingStatusId === TRACKINGSTATUS_ENUM.Activated ||
          trackingRequired &&
        (currentMove.getValidationErrorObj(
          currentMove.tracking,
          TNTContext.getState().isBrokered
        ).count ?? 0) === 0)
      ) {
        await TNTContext.syncMovementTracking(currentMove.movementExId);
      }
    } else {
      updates.push({
        sliceKey: "providerId",
        payload:
          data.defaultProviderId ||
          data.mappedVendorId ||
          data.trackingVendorId,
      });
      updates.push({ sliceKey: "agencyId", payload: data.agencyId });

      TNTContext.updateSlice(updates);
    }
  };
}

OrderEntryViewModel.prototype.toJSON = function () {
  var copy = ko.toJS(this);
  delete copy.orderEntryDispatchBrokerageParameters;
  delete copy.orderEntryDispatchParameters;
  delete copy.stopsArrangementModal;
  delete copy.syncTrackTraceData;
  return copy;
};
class OrderEntryHiddenAndRequiredFields {
  constructor() {
    this.enabledRequiredFields = ko.observable(true);
    this.required = ko.observableArray([]);
    this.hidden = ko.observable([]);
    this.getRequiredAndHiddenFields(null, userProfile.currentAgencyId());
  }

  getRequiredAndHiddenFields = (OrderCustomer, OrderAgency) => {
    var requiredFieldParam = {
      agencyId: OrderAgency ? OrderAgency : userProfile.currentAgencyId(),
      pageId: 81,
      customerId: OrderCustomer,
    };

    return dataModel
      .ajaxRequest(
        "RequiredFields/GetRequiredAndHiddenFields",
        "GET",
        requiredFieldParam
      )
      .done((response) => {
        this.required(response.required);
        if (OrderAgency) {
          this.hidden(response.hidden);
        }
      });
  };

  isHidden = (name) => {
    return (
      this.hidden().some((value) => {
        return value == name;
      }) && this.isRequired(name) == false
    );
  };

  isRequired = (name) => {
    return (
      this.required().some((value) => {
        return value == name;
      }) && this.enabledRequiredFields()
    );
  };
}

export { OrderEntryViewModel };
export default { viewModel: OrderEntryViewModel, template: template };
