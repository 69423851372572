
// TODO: Currently have to handle adding/removing/clear filters when your observable changes in
// a subscribe function (see customer ddl in loadpostingscomponent as example) - Will fix this later
// NOTICE: Currently only works when the grid virtualmode is set to false!! 
export default function ($element) {
    let $newEl;
    let filtergrp = {};

    // Function to do pre-setup of element. Must return jquery element.
    // This methods returns the method object for chaining.
    const initSetup = (setupFn = function(){}) => {
        $newEl = setupFn();
        return methods;
    }

    const createContainer = ($bindToEl) => {
        const $container = $('<div>', {id: 'ko-element-filter-widgets', style: 'visibility: hidden; position: absolute; left: -150%'});

        if($bindToEl.find($container).length == 0) {
            $bindToEl.append($container);
        }

        return $container;
    }

    const bindElementTo = ($elToBind, $bindToEl) => {
        if($bindToEl.find($elToBind).length == 0) {
            $bindToEl.append($elToBind);
        }

        return $elToBind;
    }

    const clear = (val) => {
        if(typeof val === 'function') {
            val(null);
            return val;
        }

        val = {};
        return val;

    }

    const onClearFilters = ($grid, callBackFn = function(){}) => {
        $grid.on("filter", function(e) {
            const args = e.args || {};
            const filters = args.filters || [];

            if(filters.length == 0) callBackFn();
        });

        return $grid;
    }

    const grid = ($el) => {
        const grids = $('.jqx-grid');
        return grids.length == 0 ? $() : grids.length > 1 ? $el.closest('.jqx-grid') : grids[0];
    }

    const addFilterToGrid = ($grid) =>({
        datafield = "",
        values = [""],
        type = "stringfilter",
        condition = "STARTS_WITH",
        operator = 1 // 0 for "and" and 1 for "or"
    }) => {
        const filtergroup = new $.jqx.filter();
    
        const filters = values.reduce((grp, val) => {
            grp.push(filtergroup.createfilter(type, val, condition))
            return grp;
        }, []);
    
        filters.map(x => filtergroup.addfilter(operator, x));
    
        $grid.jqxGrid('addfilter', datafield, filtergroup);
        
        filtergrp = filtergroup;

        return { 
            applyToGrid: () =>  {
                $grid.jqxGrid('applyfilters');
            }
        }
    }

    const removeFilterFromGrid = ($grid) => (datafield) => {
        $grid.jqxGrid('removefilter', datafield);
        filtergrp = {};
    }

    const methods = {
        initSetup: initSetup,
        createfilterwidget: ($grid) => {
            const $widgetsContainer = createContainer($('#mainContent'));
            $newEl = bindElementTo(($newEl || $element), $widgetsContainer);
            
            let initialized = false;
            let column;

            let _watcher;
            const checkForChanges = ($newWidget) => {
                clearTimeout(_watcher);
                
                const $el = $grid.find($newWidget);
                if (initialized && $el.length == 0) {           
                    bindKoWidget($newWidget)
                }

                initialized = true;
                _watcher = setTimeout(checkForChanges, (column.hidden ? 20000 : 300), $newWidget);
            }

            const bindKoWidget = ($newWidget) => {
                const $container = gridContainer($grid.jqxGrid('getcolumnindex', column.datafield));

                if(column.hidden == false && $container && $container.length && $container.find($newWidget).length == 0) {
                    const $getRidOfThisEl = $container.find('input.jqx-widget').first();

                    if($getRidOfThisEl) {
                        $getRidOfThisEl.val("");
                        $getRidOfThisEl.replaceWith($newWidget);
                    }
                }
            }

            const gridContainer = (index) => {
                
                const $filterrow = $grid.find('div.jqx-grid-cell-filter-row').first();
                if($filterrow.length) {
                    return $($filterrow[0]['cells'][index]);
                }
                
                return null;
            }

            // This is nasty, but even when removing the default input widget, it's value, and removefilter
            // the first filter is only being applied when there is more than one. Here we are checking for
            // widget filter count vs what the grid has for the column.
            $grid.on("filter", function (event) 
            {
                if(initialized) {
                    if(filtergrp && Object.keys(filtergrp).length > 0) {
                        const filtersinfo = $grid.jqxGrid('getfilterinformation');
                        const colFilterInfo = filtersinfo.find(x => x.datafield == column.datafield);
                        if(colFilterInfo && colFilterInfo.filter) {
                            const currentFilters = colFilterInfo.filter.getfilters();
                            const widgetFilters = filtergrp.getfilters();
    
                            if(currentFilters.length != widgetFilters.length) {
                                $grid.jqxGrid('addfilter', column.datafield, filtergrp);
                                $grid.jqxGrid('applyfilters');
                            }
                        }
                    }
                    else {
                        $grid.jqxGrid('removefilter', column.datafield);
                    }
                }
                
            });       
            
            return (columnData, columnElement, widget) => {
                if($grid.jqxGrid('virtualmode')) {
                    throw new Error("useKoElementAsFilter currently works only when the jqx grid virtualmode is set to false.");
                }
                
                column = columnData;
                checkForChanges($newEl);

                // Old implementation, leaving for now...
                // Current issue: even though the filtering works, it causes jqx to throw errors as the 
                // original widget is not defined (it's looking for a $parent jqx). This causes any filterlists on grid not to work/filter. 
                // Input filters still work however? It's JQX so yeah...

                // if(column.filtertype != 'custom') {
                //     if(!$grid) throw new Error(`useKoElementAsFilter Error: When the column's default filtertype != 'custom', then you must supply the $grid element to the createfilterwidget function.`);
                //     //$grid.jqxGrid('setcolumnproperty', column.datafield, 'filtertype', 'custom');
                // }
                
                // if(widget && widget.length) {
                //     $(widget).replaceWith($newEl || $element);
                // }
                // else {
                //     $(columnElement).append($newEl || $element);
                //     return columnElement;
                // }
            }
        },
        addFilterToGrid,
        removeFilterFromGrid
    }  

    return methods;
}