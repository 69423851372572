
import dataModel from 'data-model';
import gridState from 'jqx.grid-state-component';
import userProfile from 'user-profile';

class AlertsPageViewModel {
    constructor() {
        var self = this;

        self.isLoading = ko.observable(false);
        self.isLoading(false);

        self.expire = 24;
        self.expire = ko.observable(true);

        self.alertAddWindow = ko.observable();
       
        self.cancelChanges = function () {
            self.alertAddWindow(undefined);
        };

        self.editAlert = function (curAlertId) {
            self.isLoading(true);

            var route = "Alerts/GetAlert/" + curAlertId;
            var data = null;
            dataModel.ajaxRequest(route, "GET", data)
                .done(function (data, status, xhr) {
                    data.onAlertSave = function () {
                        self.alertAddWindow(undefined);    //close window
                        $("#jqxAlerts").jqxGrid('updatebounddata');
                    };

                    self.alertAddWindow(data);
                    self.isLoading(false);
                })
                .fail(function (error, msg, d) {
                    self.isLoading(false);
                });
        };

        self.deleteAlert = function (curAlertId) {
           var data = null;

            dataModel.ajaxRequest("Alerts/DeleteAlert/" + curAlertId, "POST", data)
                .done(function (data, status, xhr) {
                    $("#jqxAlerts").jqxGrid('updatebounddata');
                    self.isLoading(false);
                })
                .fail(function (error, msg, d) {
                    self.isLoading(false);
                });
        };

        self.expireAlert = function (curAlertId) {
            self.isLoading(true);
            var data = null;
            dataModel.ajaxRequest("Alerts/ExpireAlert/" + curAlertId, "POST", data)
                .done(function (data, status, xhr) {
                    $("#jqxAlerts").jqxGrid('updatebounddata');
                    self.isLoading(false);
                })
                .fail(function (error, msg, d) {
                    self.isLoading(false);
                });
        };


        var loadAlerts = function () {

            var source = {
                url: "Alerts/GetAlertsForGrid",
                datatype: "json",
                sortcolumn: "postDate",
                sortdirection: "desc",
                data: {
                    agencyId: userProfile.currentAgencyId()
                },
                sort: function (column, sortOrder) {
                    $("#jqxAlerts").jqxGrid('updatebounddata', 'sort');
                },
                beforeprocessing: function (data) {
                    source.totalrecords = data.totalrecords;
                },

                datafields: [
                    { name: 'id', type: 'int' },
                    { name: 'expireDate', type: 'date' },
                    { name: 'postDate', type: 'date' },
                    { name: 'subject', type: 'string' },
                    { name: 'messageBody', type: 'string' },
                    { name: 'userName', type: 'string' }  
                ]
            };

            var dataAdapter = dataModel.getDataAdapter(source); 
          
            $("#jqxAlerts").jqxGrid({
                width: "100%",
                source: dataAdapter,
                selectionmode: 'single',
                sortable: true,
                pageable: true,
                filterable: false,
                showfilterrow: false,
                autoheight: true,
                virtualmode: true,
                columnsmenu: false,
                showtoolbar: true,
                rendertoolbar: function (toolbar) {
                    var vm1 = new gridState.viewModel(),
                        tmpl1 = gridState.template;

                    // limit the available options by re-defining the actions (set in gridState.js)?
                    vm1.actions = ["Refresh", "Show/Hide Columns"];

                    vm1.addNewAlert = function () {
                        self.alertAddWindow({
                            onAlertSave: function () {
                                self.alertAddWindow(undefined);
                                $("#jqxAlerts").jqxGrid('updatebounddata');
                            }
                        });
                    };

                    $(toolbar).append("<div style='display:block;float:left;'>" + tmpl1 + "</div><div style='display:block;float:left; padding-left:10px;'><input type=\"button\" value=\"Add New Alert\" data-bind=\"jqxButton: {},click: addNewAlert \" /></div>");           
                    ko.applyBindingsToDescendants(vm1, toolbar[0]);

                },
                rendergridrows: function (obj) {
                    return obj.data;
                },
                columnsresize: true,
                columns: [
                     {
                         text: '', width: 60, filterable: false, sortable: false, columnType: "button",
                         buttonclick: function (row) {
                             self.isLoading(true);
                             var datarow = $("#jqxAlerts").jqxGrid('getrowdata', row);
                             self.editAlert(datarow.id);
                         },                    
                         cellsrenderer: function () {
                             return "Edit";
                         }
                     },
                    { text: 'Subject', dataField: 'subject', width: 180, filterable: false },
                    { text: 'Message', dataField: 'messageBody', filterable: false },
                    { text: 'Post Date', dataField: 'postDate', width: 150, cellsformat: 'M/d/yyyy h:mm tt', filterable: false },
                    { text: 'Expire Date', dataField: 'expireDate', width: 150, cellsformat: 'M/d/yyyy h:mm tt', filterable: false },
                    { text: 'Posted By', dataField: 'userName', width: 100, filterable: false },

                    { text: '', width: 60, filterable: false, sortable: false, columnType: "button",
                    buttonclick: function (row) {
                            self.isLoading(true);
                            var datarow = $("#jqxAlerts").jqxGrid('getrowdata', row);
                            self.deleteAlert(datarow.id);
                        },
                        cellsrenderer: function () {
                            return "Delete";
                        }
                    },
                     {
                         text: '', width: 60, filterable: false, sortable: false, columnType: "button",
                         buttonclick: function (row) {
                             self.isLoading(true);
                             var datarow = $("#jqxAlerts").jqxGrid('getrowdata', row);
                             self.expireAlert(datarow.id);
                         },
                         cellsrenderer: function () {
                             return "Expire";
                         }
                     }
                ]
            });
        };

        loadAlerts();
    }
}

import template from './alerts-page.html';
export default { viewModel: AlertsPageViewModel, template: template };