
import GreatEdgeCustomWidgets from 'ge.custom-widgets';
import dataModel from 'data-model';
import {getDateTimestamp} from 'global-functions';
import userProfile from 'user-profile';

class SalespersonSettlementViewModel {
    constructor() {
        var self = this;
        self.isLoading = ko.observable(false);
       
        var today = new Date();
    
        var dateRangePicker = new GreatEdgeCustomWidgets().GEDateRangePickerWidget({
            bindToElement: 'geDateRangePicker',
            initialFromDate: today.addDays(-7),
            initialToDate: today.addDays(1),
            minAllowedYear: 1990
        });

        self.orderNumber = ko.observable();
        self.selectedPayee = ko.observable();
        self.payeeSelectList = ko.observableArray([]);
        self.errorMsg = ko.observable();

        self.generateListing = function () {
                        
            self.errorMsg("");
            var validationErrors = ko.validation.group(self);

            if (validationErrors().length > 0)
                return false;
            
            var payee = $("#jqxPayeeSelectList").jqxDropDownList('getSelectedItem');
            if (payee == null) {
                self.errorMsg("Please select a payee from the list.");
                return;
            }
            else if (payee != null && payee.value == undefined) {
                self.errorMsg("Please select a payee from the list.");
                return;
            }
            
            $('#availableSettlements').jqxGrid('clear');

            var datesObj = dateRangePicker.getDates();

            if (datesObj == null || (datesObj.from == "Invalid date" || datesObj.to == "Invalid date")) {
                self.errorMsg("Please select a date range to search by.");
                return;
            }
            
            var payload = {
                beginDate: datesObj.from,
                endDate: datesObj.to,
                orderNumber: self.orderNumber(),
                payeeExId: payee.value
            };
            
            var grid = $('#availableSettlements');
            
            self.isLoading(true);

            dataModel.ajaxRequest('Settlement/GetAvailableSalespersonSettlements', 'GET', payload).done(function (data) {
               
                var source = {
                    localdata: data.agencySecurables,
                    datatype: 'array',
                    datafields: [
                        { name: 'imageName', type: 'string' },
                        { name: 'payDate', type: 'date' },
                        { name: 'settlementPay', type: 'float' },
                        { name: 'payeeID', type: 'string' }
                    ]
                };

                var dataAdapter = new $.jqx.dataAdapter(source, {
                    loadComplete: function (data) {
                        self.isLoading(false);
                    }
                });


                $('#availableSettlements').jqxGrid({
                    source: dataAdapter,
                    sortable: true,
                    pageable: true,
                    autoheight: true,
                    altrows: true,
                    columnsresize: true,
                    width: '100%',
                    showtoolbar: true,
                    toolbarheight: 35,
                    rendertoolbar: function (toolbar) {
                        //var tmpl1 = $('#gridToolBar');
                        
                        //$(toolbar).append(tmpl1);
                        //tmpl1.show();

                        //ko.applyBindingsToDescendants(tmpl1, toolbar[0]);
                    },
                    columns: [
                        { text: '', datafield: 'imageName', hidden: true },
                        {
                            text: '',
                            width: 50,
                            filterable: false,
                            sortable: false,
                            cellsalign: 'center',
                            columnType: 'button',
                            buttonClick: function (row) {
                                self.isLoading(true);
                                
                                var datarow = grid.jqxGrid('getrowdata', row);
                                var date = (datarow.payDate.getMonth() + 1) + '/' + datarow.payDate.getDate() + '/' + datarow.payDate.getFullYear();
                                var data = { agencyId: userProfile.currentAgencyId(), requestDate: date, imageName: datarow.imageName, payeeID: datarow.payeeID };

                                dataModel.ajaxRequest('Settlement/GetSettlementDocument', 'GET', data).done(function (data, status, xhr) {
                                    var timestamp = getDateTimestamp();
                                    var _filename = data.payeeID != null ? data.payeeID : 'SalespersonSettlement(GeneratedOn' + timestamp + ')';
                                    _filename = _filename + '-ReportDate-' + date.replace(/\//g, '');
                                    //var filename = data.payeeID.trim() + '-' + date.replace(/\//g, '') + '.pdf';

                                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                        var newBlob = $.b64toBlob(data.pdfBytes, 'application/pdf', 512);
                                        window.navigator.msSaveOrOpenBlob(newBlob, _filename + + '.pdf');
                                    } else {
                                        var a = document.createElement('a');
                                        var pdfAsDataUri = 'data:application/pdf;base64,' + data.pdfBytes;
                                        //a.download = data.payeeID + ' - ' + date.replace("/", "");
                                        a.download = _filename;
                                        a.type = 'application/pdf';
                                        a.href = pdfAsDataUri;
                                        document.body.appendChild(a);
                                        a.click();
                                    }
                                    self.isLoading(false);
                                }).fail(function (jqXHR, textStatus, errorThrown) {
                                    if (jqXHR.responseJSON != null) {
                                        var json = jqXHR.responseJSON;
                                        if (json.message != null) {
                                            var errors = json.message;
                                            alert(errors);
                                        }
                                    }
                                    self.isLoading(false);
                                });
                            },
                            cellsrenderer: function () {
                                return 'View';
                            }
                        },
                        { text: 'Payee', datafield: 'payeeID', type: 'string', sortable: true, align: 'center', cellsalign: 'center' },
                        { text: 'Settlement Pay', datafield: 'settlementPay', cellsformat: 'C2', type: 'float', sortable: true, align: 'right', cellsalign: 'right' },
                        { text: 'Date', datafield: 'payDate', cellsformat: 'MM/dd/yyyy', type: 'date', sortable: true, align: 'right', cellsalign: 'right' }
                    ]
                });

            }).fail(function (jqXHR, textStatus, errorThrown) {
                self.isLoading(false);
                $('#availableSettlements').empty().append($('<h4>').text('An error was encountered. Try again or contact your administrator.'));
            });
        };

        self.downloadSettlementSummary = function () {
            self.errorMsg("");
            var validationErrors = ko.validation.group(self);

            if (validationErrors().length > 0)
                return false;

            var payee = $("#jqxPayeeSelectList").jqxDropDownList('getSelectedItem');
            if (payee == null) {
                self.errorMsg("Please select a payee from the list.");
                return;
            }
            else if (payee != null && payee.value == undefined) {
                self.errorMsg("Please select a payee from the list.");
                return;
            }
            
            var datesObj = dateRangePicker.getDates();

            if (datesObj == null || (datesObj.from == "Invalid date" || datesObj.to == "Invalid date")) {
                self.errorMsg("Please select a date range to search by.");
                return;
            }

            self.isLoading(true);

            var payload = {
                payeeExIds: [payee.value],
                startDate: datesObj.from,
                endDate: datesObj.to,
            }

            var timestamp = getDateTimestamp();
            var saveAsFilename = "SalespersonSettlementSummaryReport(" + timestamp + ").xlsx";
            dataModel.downloadFile("Settlement/GetSalespersonSummaryReport", "POST", payload, saveAsFilename).then(function (x) {
                self.isLoading(false);
            }).catch(function (error) {
                console.error(error);
                self.isLoading(false);
            });
        }

        self.buildPayeeSelectList = function () {

            var source =
            {

                datatype: "json",
                url: "Payee/GetPayeeForSettlementDDL",
                datafields: [
                    { name: 'payeeId' },
                    { name: 'payeeExId' },
                    { name: 'name' },
                    { name: 'displayName' }
                ],
                formatdata: function (data) {
                    data.AgencyId = userProfile.currentAgencyId();
                    return data;
                },
                beforeLoadComplete: function (data) {
                    data.unshift({ id: 0, displayName: 'Select Payee', name: null, externalId: null });
                    return data;
                },
                loadcomplete: function (data) {
                    $('#jqxPayeeSelectList').jqxDropDownList('selectIndex', 1);
                }
            };

            var dataAdapter = dataModel.getDataAdapter(source);

            $('#jqxPayeeSelectList').jqxDropDownList({
                checkboxes: false,
                placeHolder: 'Select Payee',
                source: dataAdapter,
                displayMember: "displayName",
                valueMember: "payeeExId",
                width: 200,
                height: 25,
                autoDropDownHeight: false,
                filterable: true,
                dropDownHeight: 200,
                dropDownWidth: 330,
                animationType: 'none',
                searchMode: 'containsignorecase'
            });

        }

        self.init = function () {
            self.buildPayeeSelectList();
        }

        self.init();
    }
}

import template from './salesperson-settlements-page.html';
export default { viewModel: SalespersonSettlementViewModel, template: template }