import template from './add-pay-modal-component.html';
import { AdditionalCharge } from '../additional-charges-component'
import { DriverExtraPay } from '../additional-charges-component';
import { Computed, Observable, ObservableArray, PureComputed, pureComputed } from 'knockout';
import dataModel from 'data-model';
import { showmessage } from 'show-dialog-methods';
import Driver from '../../../../../shared-components/ko-great-edge-components/ge-auto-complete-models/driver.model';
import DeductionEarningCode from '../../../../../shared-components/ko-great-edge-components/ge-auto-complete-models/deduction-earning-code.model';
import { BillingModel } from '../../order-entry-billing-component';
import { OrderEntryBillingAdditionalChargesComponent } from '../additional-charges-component';
import { Movement } from '../../../order-entry-dispatch/order-entry-dispatch-truckline-component/order-entry-dispatch-truckline-component';

class OrderEntryBillingAddPayModalComponent {

    additionalCharge: Observable<AdditionalCharge> = ko.observable();
    additionalChargesViewModel: OrderEntryBillingAdditionalChargesComponent
    billingModel: BillingModel;
    
    driverExtraPay: DriverExtraPay;
    currentMovementId: Observable<string> = ko.observable(); //Should be Orders.CurrentMovementId
    orderExternalId: Observable<string> = ko.observable();
    movements: Observable<any[]> = ko.observable();
    selectedMovementObject: Observable<any> = ko.observable();
    selectedMovementExternalId?: Observable<string> = ko.observable();
    
    originAddress?: Observable<string> = ko.observable();
    originCityStateZip?: Observable<string> = ko.observable();
    originArrival?: Observable<string> = ko.observable();

    destinationAddress?: Observable<string> = ko.observable();
    destinationCityStateZip?: Observable<string> = ko.observable();
    destinationArrival?: Observable<string> = ko.observable();

    driver: Observable<any> = ko.observable();
    driverSelectedValue: Observable<any> = ko.observable();
    driverName: Observable<any> = ko.observable();

    deductCode: Observable<any> = ko.observable();
    deductCodeSelectedValue: Observable<any> = ko.observable();

    driverExtraPays: ObservableArray<DriverExtraPay> = ko.observableArray();
    payeeEarnDeductSettings: ko.Observable<PayeeEarnDeductRate[]> = ko.observable([]);
    isLoading: Observable<boolean> = ko.observable();
    showDECTypeDescWarning: Observable<boolean> = ko.observable();

    dispatcherFlag: PureComputed<any> = ko.pureComputed(() => {
        return this.additionalCharge() ? null : true;
    })

    constructor(data: any) {
        data = data.data;
        this.additionalCharge(data.additionalCharge);
        
        this.billingModel = data.billingModel;
        this.additionalChargesViewModel = data.additionalChargesViewModel;

        if(data.driverExtraPay) {
            this.driverExtraPay = new DriverExtraPay(ko.toJS(data.driverExtraPay));
        } else if(!this.additionalCharge()) {
            this.driverExtraPay = new DriverExtraPay({enteredUserExternalId: data.enteredUserExternalId, orderId: data.orderId});
            this.initialLoad = false;
        } else {
            this.driverExtraPay = new DriverExtraPay({enteredUserExternalId: data.enteredUserExternalId, orderChargeId: this.additionalCharge().id()});
            this.initialLoad = false;
        }

        this.movements(data.movements);
        this.initializeSubscriptions()
        this.initializeDataMembers(data);
    }

    minimumDriverPay: Observable<number> = ko.observable();
    minimumDriverRate: Observable<number> = ko.observable();

    initialLoad = true;
    pauseGetRatesRequest = false;
    getRates = (driverId: number, deductCode: string) : Promise<boolean> => {
        if(this.pauseGetRatesRequest == true) {
            return;
        }
        this.pauseGetRatesRequest = true;
        if(this.additionalCharge()) {
            return new Promise((resolve, reject) => {
                let movementIds = this.billingModel.$parent.stops()
                .map((x) => x.movementId)
                .reduce((collector, currentValue) => {
                    if(!collector.some((c) => c == currentValue)) {
                        collector.push(currentValue);
                    }
                    return collector;
                }, [])
    
                if(deductCode == "FSC" && this.billingModel.$parent.orderEntryDispatchParameters()) {
                    if(movementIds.length > 1) {
                        let totalLoadedMiles = this.billingModel.$parent.orderEntryDispatchParameters().orderMovements()
                        .reduce((collector, x: Movement) => {
                            return collector = collector + x.moveDistance()
                        }, 0)
                        let currentMovmentMiles = this.billingModel.$parent.orderEntryDispatchParameters().orderMovements()[0].moveDistance();
                        let percentage = currentMovmentMiles / totalLoadedMiles;
                        let payAmount = this.additionalCharge().amount() * percentage;
                        this.driverExtraPay.units(1);
                        this.driverExtraPay.rate(payAmount);
                        this.minimumDriverRate(parseFloat((percentage * 100).toFixed(2)));
                        this.minimumDriverPay(payAmount);
                    } else {
                        this.driverExtraPay.units(1);
                        this.driverExtraPay.rate(this.additionalCharge().amount());
                        this.minimumDriverRate(100);
                        this.minimumDriverPay(this.additionalCharge().amount());
                    }
                    this.pauseGetRatesRequest = false;
                    this.initialLoad = false;
                    resolve(true);
                } else {
                    this.isLoading(true);
                    dataModel.ajaxRequest("CustomPaySolutions/GetContractedRatesForDriverExtraPay", "GET", {driverId: driverId, deductCode: deductCode})
                    .done((res: number) => {
                        if(res && this.additionalCharge().amount()) {
                            if(res) {
                                let pay = this.additionalCharge().amount() * (res / 100)
                                if(this.initialLoad == false) {
                                    showmessage('This accessorial has a default value pay of ' + res + ' percent to pay the truck. ' + 
                                    'Adding pay here will add to that amount. If you think you have the incorrect accessorial code please contact the Agent Liaison group.')
                                }
                                if(!this.driverExtraPay.id()) {
                                    if(!this.driverExtraPay.units()) {
                                        this.driverExtraPay.units(1);
                                    }
                                    if(!this.driverExtraPay.rate()) {
                                        this.driverExtraPay.rate(pay);
                                    }
                                }
                                this.minimumDriverRate(res);
                                this.minimumDriverPay(pay);
                            } else {
                                this.minimumDriverPay(undefined);
                            }
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                        this.pauseGetRatesRequest = false;
                        this.initialLoad = false;
                        this.isLoading(false);
                    }).fail(() => {
                        this.pauseGetRatesRequest = false;
                        this.initialLoad = false;
                        this.isLoading(false);
                        reject()
                    })
                }
            })
        } else {
            let charge = this.billingModel.additionalChargesViewModel().additionalCharges().find((x) => x.deductCode() == deductCode)
            if(charge) {
                this.additionalCharge(charge);
                this.driverExtraPays(charge.driverExtraPays())
                this.driverExtraPay.orderChargeId(charge.id);
                this.pauseGetRatesRequest = false;
                this.getRates(driverId, deductCode);
            } else {
                this.pauseGetRatesRequest = false;
                this.initialLoad = false;
            }
        }
    }

    initializeDataMembers = (data: any) => {
        this.currentMovementId = data.currentMovementId;
        if(data.currentMovementId) {
            this.selectedMovementObject(this.movements().find((x) => x.movementId == this.currentMovementId()))
        }
        this.deductCode(data.deductCode);
        this.orderExternalId = data.orderExternalId;
        this.driver.extend({required: { params: true, onlyIf: () => { return true;}, message: "Driver is required."}});
        this.deductCode.extend({required: { params: true, onlyIf: () => { return true;}, message: "Earning code is required."}});
        if(data.driverExtraPay) {
            this.driver(data.driverExtraPay.driverExternalId())
        }
        if(data.driverExtraPays) {
            this.driverExtraPays(data.driverExtraPays());
        }
    }

    initializeSubscriptions = () => {
        this.selectedMovementObject.subscribe((obj: any) => {
            obj = obj || {};
            this.originCityStateZip(obj.originCityStateZip);
            this.originArrival(obj.originArrival);
            this.originAddress(obj.originAddress);
            this.destinationAddress(obj.destinationAddress);
            this.destinationArrival(obj.destinationArrival);
            this.destinationCityStateZip(obj.destinationCityStateZip);
            this.driverExtraPay.movementId(obj.movementId);
            this.driver(obj.driver);
        })
        this.deductCodeSelectedValue.subscribe((x: DeductionEarningCode) => {
            x = x || {} as any;
            this.driverExtraPay.deductionEarningCodeTypeDescription(x.typeDescription);
            this.driverExtraPay.description(x.description || "");
            this.driverExtraPay.deductCodeExternalId(x.code);
            this.driverExtraPay.deductCodeId(x.id);
            if(this.driverExtraPay.driverId() && !this.minimumDriverPay()) {
                this.getRates(this.driverExtraPay.driverId(), x.code);
            }
            this.showDECTypeDescWarning(x.type ? x.type.toLowerCase() == "d" || x.type.toLowerCase() == "b" : false);

        })
        this.driverSelectedValue.subscribe((x: Driver) => {
            if(x) {
                this.driverName(x ? x.firstName + " " + x.lastName : "");
                this.driverExtraPay.driverId(x.id);
                this.driverExtraPay.driverExternalId(x.code);
                if(this.driverExtraPay.deductCodeId() && !this.minimumDriverPay()) {
                    this.getRates(x.id, this.driverExtraPay.deductCodeExternalId());
                } else {
                    this.initialLoad = false;
                }
            } else {
                this.driverName("")
                this.driverExtraPay.driverId(undefined);
                this.driverExtraPay.driverExternalId(undefined);
                this.minimumDriverRate(undefined);
                this.minimumDriverPay(undefined);
                this.initialLoad = false;
            }
        })
        this.driverExtraPay.amount.extend({
            min : {
                params: this.minimumDriverPay, 
                onlyIf: () => {
                    return !!this.minimumDriverPay();
                },
                message: this.contractedRateMessage as any
            }
        });
    }

    contractedRateMessage = () => {
        return "This driver has a contracted rate of " + this.minimumDriverRate() + "% and you may not pay less."
    }

    amountRemaining: Computed<number> = ko.computed(() => {
        let payTotal = this.driverExtraPays()
        .filter((x) => {
            return x.id() != this.driverExtraPay.id()
        })
        .map((v: DriverExtraPay) => {
            return v.amount();
        })
        .reduce((previousValue, currentValue) => {
            return previousValue + currentValue;
        }, 0)

        return this.additionalCharge().amount() - payTotal - this.driverExtraPay.amount();
    }, this, {deferEvaluation: true})

    saveDriverExtraPayPayload = () => {
        if(this.additionalCharge()) {
            let obj: any = ko.toJS(this.additionalCharge);
            obj.driverExtraPays = [this.driverExtraPay];
            return obj;
        } else {
            return this.driverExtraPay;
        }
    }

    saveDriverExtraPay = () => {
        let route = this.additionalCharge() ? "CustomPaySolutions/SaveDriverExtraPay" : "CustomPaySolutions/SaveDriverExtraPayWithoutCharge"
        var driverExtraPayErrors = ko.validation.group([
            this.driver, 
            this.driverExtraPay.units, 
            this.driverExtraPay.rate,
            this.driverExtraPay.amount, 
            this.driverExtraPay.transactionDate]);
        if(driverExtraPayErrors().length > 0) {
            driverExtraPayErrors.showAllMessages();
        } else {
            let obj = this.saveDriverExtraPayPayload();
            this.isLoading(true);
            dataModel.ajaxRequest(route, "POST", obj)
            .done((res) => {
                //If res contains an id, it means that a record was created.
                if(res.orderChargeId) {
                    this.additionalCharge().id(res.orderChargeId);
                }
                if(res.driverExtraPayId) {
                    this.driverExtraPay.id(res.driverExtraPayId);
                    if(this.additionalCharge()) {
                        this.additionalCharge().driverExtraPays.push(this.driverExtraPay);
                    } else {
                        this.additionalChargesViewModel.seperateDriverExtraPays.push(this.driverExtraPay);
                    }
                } else {
                    if(this.additionalCharge()) {
                        let depi = this.additionalCharge().driverExtraPays().findIndex((x: DriverExtraPay) => {return x.id == this.driverExtraPay.id})
                        this.additionalCharge().driverExtraPays[depi] = this.driverExtraPay;
                        this.additionalCharge().driverExtraPays.splice(depi, 1, this.driverExtraPay)
                    } else {
                        let depi = this.additionalChargesViewModel.seperateDriverExtraPays().findIndex((x: DriverExtraPay) => {return x.id() == this.driverExtraPay.id()})
                        this.additionalChargesViewModel.seperateDriverExtraPays.splice(depi, 1, this.driverExtraPay)
                    }
                }
                this.isLoading(false);
                this.closeBtnClick();
            })
            .fail((err, msg, idk) => {
                this.isLoading(false);
                if(err.responseJSON && err.responseJSON.message) {
                    showmessage(err.responseJSON.message);
                } else {
                    showmessage("An error occured.");
                }
            })
        }
    }

    closeBtnClick = () => {
        if(this.additionalCharge()) {
            this.additionalCharge().addPayModalViewModel(undefined);
        } 
        if(this.additionalChargesViewModel) {
            this.additionalChargesViewModel.addPayModalViewModel(undefined);
        }
    }
}

class PayeeEarnDeductRate {
    deductCode: string;
    defaultPct: number;
    deductCodeId: number;
}

export { OrderEntryBillingAddPayModalComponent }
export default { viewModel: OrderEntryBillingAddPayModalComponent, template: template}