import './routeCalculatorStyles.css';
import dataModel from 'data-model';


class RouteCalculatorPageViewModel {
    constructor() {
        var self = this;

        var PCM = 0;
        var IR = 1;
        var DISTANCE = true;
        var MULTIDISTANCE = false;
        
        self.isLoading = ko.observable(false);

        self.isLoadingCity = ko.observable(false);

        self.type = ko.observable(IR);   
        self.hazmat = ko.observable(false);

        self.cityStateZip = ko.observable();
        self.locations = ko.observableArray();
        self.selectedLocation = ko.observable();

        self.isValidDistance = ko.computed(function () {
            if (self.selectedLocation() != null) {
                return true;
            }
            return false;
        });

        self.cityStateZipMessage = ko.computed(function () {
            if (self.isValidDistance() == false && self.cityStateZip() != null) {
                return "Please enter a valid location";
            }
            return "";
        });



        ko.computed(function () {
            if (self.selectedLocation() && self.isLoadingCity()) {
                var selectedItem = self.selectedLocation();
                var currentLocations = self.locations();
                var last = currentLocations[currentLocations.length - 1];
                if (last== null || selectedItem.city != last.city || selectedItem.state != last.state || selectedItem.zip != last.zip) {
                    self.locations.push(selectedItem);
                }
                self.isLoadingCity(false);
                self.selectedLocation(undefined);
                self.cityStateZip(undefined);
            }
        });

        self.citySelected = function (item) {
            self.selectedLocation(item);
            self.isLoadingCity(false);
        };

        self.addLocation = function () {
            self.isLoadingCity(true);
        };


        // multi distance

        self.shortDistance = ko.observable(true);
        self.practicalDistance = ko.observable(false);
        self.locationsMultiDistance = ko.observableArray();
        self.cityStateZipMD = ko.observable();
        self.selectedLocationMD = ko.observable();
        self.isLoadingCityMD = ko.observable(false);

        self.origin = ko.observable().extend({
            required: {
                message: "Origin is required"
            }
        });

        self.isValidDistanceMD = ko.computed(function () {
            if (self.selectedLocationMD() != null) {
                return true;
            }
            return false;
        });


        self.cityStateZipMessageMD = ko.computed(function () {
            if (self.isValidDistanceMD() == false && self.cityStateZipMD() != null) {
                return "Please enter a valid location";
            }
            return "";
        });

        ko.computed(function () {
            if (self.selectedLocationMD() && self.isLoadingCityMD()) {
                var selectedItem = self.selectedLocationMD();
                var currentLocations = self.selectedLocationMD();
                var last = currentLocations[currentLocations.length - 1];
                if (last == null || selectedItem.city != last.city || selectedItem.state != last.state || selectedItem.zip != last.zip) {
                    self.locationsMultiDistance.push(selectedItem);
                }
                self.isLoadingCityMD(false);
                self.selectedLocationMD(undefined);
                self.cityStateZipMD(undefined);
            }
        });

        self.citySelectedMD = function (item) {
            self.selectedLocationMD(item);
            self.isLoadingCityMD(false);
        };

        self.addLocationMD = function () {
            self.isLoadingCityMD(true);
        };

        self.routeDistance = ko.observableArray();
        self.route = ko.observable();
        self.routeMD = ko.observable();
        self.distanceResults = ko.observable(false);
        self.multiDistanceResults = ko.observableArray();

        // Messages
        self.stopsMessage = ko.observable();
        self.stopsMessageMD = ko.observable();
        self.originMessage = ko.observable();

        self.removeLocation = function () {
            var index = self.locations.indexOf(this);
            self.locations.splice(index, 1);
        };


        self.removeLocationMD = function () {
            var index = self.locationsMultiDistance.indexOf(this);
            self.locationsMultiDistance.splice(index, 1);
        };

        self.resetMessages = function () {
            self.stopsMessage("");
            self.stopsMessageMD("");
            self.originMessage("");
        };

        self.reset = function () {
            self.shortDistance(true);
            self.hazmat(false);
            self.type("1");

            self.locations([]);
            self.locationsMultiDistance([]);
            self.cityStateZip(undefined);
            self.cityStateZipMD(undefined);

            self.origin("");
            self.distanceResults(false);
            self.resetMessages();
        };

        // Distance calculator
        self.calculateDistance = function () {
            if (self.locations().length < 2) {
                self.stopsMessage('You must enter at least two stops. You currently have ' + self.locations().length + ' stops.');
                return;
            } else {
                self.stopsMessage('');
            }
            self.isLoading(true);
            var zipStops = [];
            var locations = ko.toJS(self.locations);
            for (var i = 0; i < locations.length; i++) {
                if (locations[i].zip != null) {
                    zipStops.push(locations[i].zip);
                }
                else {
                    zipStops.push((locations[i].city + ", " + locations[i].state));
                }
            }
            var data = {
                hazmat: self.hazmat(),
                stops: zipStops,
                type: self.type()
            }
            dataModel.ajaxRequest("Routing/CalculateRoute", "POST", data)
            .done(function (data, status, xhr) {
                self.route(data);
                self.distanceResults(true);
                self.resetMessages();
                self.isLoading(false);
            })
            .fail(function (error, msg, d) {
                self.stopsMessage(error.statusText + '. Please try again later.');
                self.isLoading(false);
            });

        };

        self.calculateMultiDistance = function () {
            if (self.locationsMultiDistance() < 1 && !self.origin()) {
                self.stopsMessageMD('You must enter at least two stops. You currently have ' + self.locations.length + ' stops.');
                return;
            } else {
                self.stopsMessageMD('');
            }
            if (!self.origin()) {
                self.originMessage('The origin is required.');
                return;
            }
            if (self.multiDistanceResults().length > 0) {
                self.multiDistanceResults([]);
            }
            var zipStops = [];
            var locations = ko.toJS(self.locationsMultiDistance);
            for (var i = 0; i < locations.length; i++) {
                if (locations[i].zip != null) {
                    zipStops.push(locations[i].zip);
                }
                else {
                    zipStops.push((locations[i].city + ", " + locations[i].state));
                }
            }
            // If the entered origin is the same city and state as the first entry
            // Don't add the origin to the data passed to the API
            var data = {
                hazmat: self.hazmat(),
                calcType: self.shortDistance() ? "S" : "P",
                type: self.type(),
                stops: zipStops,
                origin: self.origin()
            }
            self.isLoading(true);
            dataModel.ajaxRequest("Routing/CalculateMultiDistance", "POST", data)
            .done(function (data, status, xhr) {
                self.multiDistanceResults(data);
                self.resetMessages();
                self.distanceResults(true);
                self.isLoading(false);
            })
            .fail(function (error, msg, d) {
                self.isLoading(false);
            });
        };

        self.getLocationByIndex = function (index) {
            var locations = ko.toJS(self.locationsMultiDistance);
            if (locations.length > 0) {
                return locations[index].city + ', ' + locations[index].state + ' ' + locations[index].zip;
            }
        };
    }
}

import template from './route-calculator-page.html';
export default { viewModel: RouteCalculatorPageViewModel, template: template }
