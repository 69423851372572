import dataModel from "data-model";
import userProfile from "user-profile";
import dayjs from "dayjs";
import router from "router";
import template from "./main-footer-priority-alert-component.html";
import $ from "jquery";
import AlertStorageManager, { PriorityAlertModel } from './priority-alert-storage-manager';

class MainFooterPriorityAlertComponent {
  responseHookLastChecked = dayjs(Date.now());
  fetchingAlerts = false;

  constructor() {
    this.currentAlert = ko.computed(() => AlertStorageManager.priorityAlerts[0]);

    // UX message count display.
    this.totalCount = ko.observable(-1);
    this.currentCount = ko.computed(
      () => this.totalCount() - this.priorityAlertsQue.length + 1
    );

    this.showAlerts = ko.observable(false);

    this.loadAlerts();
    this.bindEvents();

    this.isProcessing = ko.observable(false);

    $("#priority-alert-modal .close").on("click", (e) => {
      this.showAlerts(false);
    });
  }

  bindEvents = () => {
    window.addEventListener("display-priority-alerts", this.handleDisplayPriorityAlerts);
    window.addEventListener("visibilitychange", this.handleVisibilityChange);

    $(document).ajaxComplete((event, xhr, settings) => {
      const check =
        settings.url.indexOf("Alerts2/AcknowledgePriorityAlerts?") === -1 &&
        window.location.href.toUpperCase().indexOf("PRIORITYALERTS") === -1 &&
        dayjs(Date.now())
          .add(-30, "seconds")
          .isAfter(this.responseHookLastChecked);

      if (userProfile.loggedIn() && check && this.showAlerts() === false) {
        this.responseHookLastChecked = dayjs(Date.now());

        this.loadAlerts();
      }
    });

    AlertStorageManager.alertsCount.subscribe((val) => {
      if (val <= 0) this.showAlerts(false);
    });

    userProfile.onLogOut(
      AlertStorageManager.clearAlertStorage,
      AlertStorageManager
    );

    userProfile.loggedIn.subscribe((val) =>
      AlertStorageManager.clearAlertStorage()
    );

    this.showAlerts.subscribe((val) => {
      if (val) this.totalCount(AlertStorageManager.alertsCount());
    });

    router.currentRoute.subscribe((val) => {
      if (val && userProfile.loggedIn()) this.loadAlerts(5);
    });
  };

  handleDisplayPriorityAlerts = ()=> {
    this.loadAlerts().then(() =>
        this.showAlerts(AlertStorageManager.alertsCount() > 0)
    );
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      if (userProfile.loggedIn()) {
        this.loadAlerts();
      }
    }
  }

  get priorityAlertsQue() {
    return [...AlertStorageManager.priorityAlerts()].sort((x, y) => y.id - x.id);
  }

  loadAlerts = async (take) => {
    take = take || 5;

    if (document.hidden) return;
    if (this.fetchingAlerts) return;

    let _skipIds = this.priorityAlertsQue.map((x) => x.id);
    this.fetchingAlerts = true;

    const data = await this.getAlertsFromServerAsync(take, _skipIds).catch(
      () => {
        return [];
      }
    )

    this.fetchingAlerts = false;

    if ((data ?? []).length > 0) {
      let _items = [...data]
        .sort((x, y) => y.id - x.id) // newest first
        .filter(
          (alert) => !this.priorityAlertsQue.some((x) => x.id === alert.id)
        );

      AlertStorageManager.add(_items.map((x) => new PriorityAlertModel(x)));

      this.showAlerts(true);
    }
  };

  getAlertsFromServerAsync = (takeCount, skipIds) => {
    takeCount = takeCount || 5; // Only taking top 5 atm.
    return new Promise(function (resolve, reject) {
      dataModel
        .ajaxRequest(
          "Alerts2/AcknowledgePriorityAlerts",
          "GET",
          { takeCount: takeCount, skipIds: skipIds },
          true
        )
        .done(function (resp) {
          return resolve(resp);
        })
        .fail(function (err) {
          return reject(err);
        });
    });
  };

  handleAcknowledgeAlert = async () => {
    const alert = this.priorityAlertsQue[0] || null;

    if (!alert) return false;

    var data = ko.toJS(alert);

    try {
      this.isProcessing(true);
      await this.sendAcknowledgeAlertAsync(data.id);
      this.isProcessing(false);
      AlertStorageManager.removeById(alert.id);
    } catch (err) {
      this.isProcessing(false);
      console.error(err);
    }
  };

  sendAcknowledgeAlertAsync = (id) => {
    return new Promise(function (resolve, reject) {
      dataModel
        .ajaxRequest(
          "Alerts2/AcknowledgePriorityAlert/" + id,
          "GET",
          null,
          true
        )
        .done(function (response) {
          return resolve(response);
        })
        .fail(function (err) {
          console.error(err);
          return reject(err);
        });
    });
  };

  dispose = () => {
    window.removeEventListener("display-priority-alerts", this.handleDisplayPriorityAlerts);
    window.removeEventListener("visibilitychange", this.handleVisibilityChange);

    $("#priority-alert-modal .close").off("click");
    $(document).off("ajaxComplete");
  }
}

export default {
  viewModel: MainFooterPriorityAlertComponent,
  template: template,
};
