import ko from 'knockout';
import dataModel from 'data-model';
import { formatSecondsToHoursMinutes } from 'global-functions';
import gridStateUtils from 'jqx.grid-utils';
import template from './tractor-details-component.html';
import * as $ from 'jquery';

class TractorDetailsModel {
    constructor(data) {
        var self = this;
        var parentModel = data.data.$parent || {};
        data = data.data.data;
        var _tractorId = data.tractorId || 0;
        var _tractorExId = data.tractorExId || null;
        var _driverExId = data.driverExId || null;
        var _trailerExId = data.trailerExId || null;
        var _isCarrierBoardTractor = data.isCarrierBoardTractor || false;
        var _isCarrierDriver = data.isCarrierDriver || false;
        var equipmentGroupId = data.equipmentGroupId;
        self.isLoading = ko.observable(false);
        self.preassignments = ko.observableArray();
        self.tractorDetails = ko.observable();
        self.advancedOptions = ko.observable();
        self.viewMessageObj = ko.observable({
            priority: '',
            message: null,
            date: ''
        });
        self.resestViewMessageObj = function () {
            self.viewMessageObj({
                priority: '',
                message: null,
                date: ''
            });
        }
        self.loadUnitMessageGrid = function () {
            var grid = $("#tractorDetailsUnitMessagesGrid");

            var source = {
                url: "EquipmentGroup/UnitMessagesGrid",
                datatype: "json",
                beforeprocessing: function (data) {
                    source.totalrecords = data.totalRecords;
                },
                sort: function (column, direction) {
                    grid.jqxGrid('updatebounddata', 'sort');
                },
                formatdata: function (data) {
                    var filterinfo = grid.jqxGrid('getfilterinformation');
                    data = gridStateUtils.formatGridFilters(filterinfo, data);
                    data.equipmentGroupId = equipmentGroupId;

                    return data;
                },
                datafields: [
                    { name: "id", type: "number" },
                    { name: "message", type: "string" },
                    { name: "messageDate", type: "date" },
                    { name: "priority", type: "string" }
                ],
                loadcomplete: function (data) {

                }
            };

            var dataSource = dataModel.getDataAdapter(source);

            var columns = [
                {
                    text: '', width: 80, filterable: false, columnType: "button", buttonclick: function (row) {
                        var datarow = grid.jqxGrid('getrowdata', row);
                        self.viewMessageObj({
                            message: datarow.message,
                            date: datarow.messageDate,
                            priority: datarow.priority
                        });
                    },
                    cellsrenderer: function () {
                        return "View";
                    }
                },
                { datafield: 'id', hidden: true },
                { text: 'Date', datafield: 'messageDate', cellsformat: 'MM/dd/yyyy', width: 120 },
                { text: 'Message', datafield: 'message' },
                {
                    text: 'Priority', datafield: 'priority', width: 100,
                    cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties) {

                        if (value == 'U') {
                            var $html = $(defaulthtml).css({ 'color': 'red', 'font-weight': 'bold' });
                            return $html[0].outerHTML;
                        }
                    }
                },
            ];

            grid.jqxGrid({
                width: '100%',
                source: dataSource,
                altrows: true,
                sortable: true,
                autoheight: true,
                pageable: true,
                pagesize: 5,
                filterable: false,
                showfilterrow: false,
                virtualmode: true,
                columnsresize: false,
                columnsreorder: false,
                enablebrowserselection: true,
                columnsmenu: false,
                columns: columns,
                autoshowloadelement: true,
                showtoolbar: true,
                rendergridrows: function (obj) {
                    return obj.data;
                }
            });
        }
        self.buildEquipmentGrid = function () {
            var $grid = $('#jqxTractorDetailsEquipmentGrid');
            var source = {
                url: "Equipment/Issued",
                datatype: "json",
                beforeprocessing: function (data) {
                    source.totalrecords = data.totalRecords;
                },
                sort: function (column, direction) {
                    $grid.jqxGrid('updatebounddata', 'sort');
                },
                formatdata: function (data) {
                    var filterinfo = $grid.jqxGrid('getfilterinformation');
                    data = gridStateUtils.formatGridFilters(filterinfo, data);
                    data.itemExternalIds = [_tractorExId, _driverExId, _trailerExId];
                    return data;
                },
                datafields: [
                    { name: "id", type: "number" },
                    { name: "itemExternalId", type: "string"},
                    { name: "status", type: "string" },
                    { name: "memo", type: "string" },
                    { name: "quantity", type: "number" },
                    { name: "equipTypeDescription", type: "string" },
                    { name: "issuedDate", type: "date" },
                    { name: "expirationDate", type: "date" },
                    { name: "returnedDate", type: "date" },
                    { name: "comments", type: "string" }

                ],
                loadcomplete: function (data) {

                }
            };

            var dataSource = dataModel.getDataAdapter(source);

            var columns = [
                { datafield: 'id', hidden: true },
                { text: 'Asset Id', datafield: 'itemExternalId', width: 100 },
                { text: 'Status', datafield: 'status', width: 80 },
                { text: 'Memo', datafield: 'memo', width: 200 },
                { text: 'Quantity', datafield: 'quantity', width: 80 },
                { text: 'Equipment Type', datafield: 'equipTypeDescription', width: 150 },
                { text: 'Issued Date', datafield: 'issuedDate', cellsformat: 'MM/dd/yyyy', width: 120 },
                { text: 'Expiration Date', datafield: 'expirationDate', cellsformat: 'MM/dd/yyyy', width: 120 },
                { text: 'Returned Date', datafield: 'returnedDate', cellsformat: 'MM/dd/yyyy', width: 120 },
                { text: 'Comments', datafield: 'comments', width: 400 }
            ];

            $grid.jqxGrid({
                width: '100%',
                source: dataSource,
                altrows: true,
                sortable: true,
                autoheight: true,
                pageable: true,
                pagesize: 5,
                filterable: false,
                showfilterrow: false,
                virtualmode: true,
                columnsresize: false,
                columnsreorder: false,
                enablebrowserselection: true,
                columnsmenu: false,
                columns: columns,
                autoshowloadelement: true,
                showtoolbar: true,
                rendergridrows: function (obj) {
                    return obj.data;
                },
                ready: function () {

                }
            });
        }
        self.init = function () {
            if (_tractorId > 0) {
                parentModel.$parent.isLoading(true);
                dataModel.ajaxRequest('OrderPlanning/GetTractorDetails/v2', 'GET', { 
                    TractorId: _tractorId,
                    IsCarrierDriver: _isCarrierDriver,
                    isCarrierBoardTractor: _isCarrierBoardTractor
                }).done(function (data) {
                    if (data) {
                        if (data.tractorDetails) {
                            // Time formatting
                            if (data.tractorDetails.driver1 && data.tractorDetails.driver1.hos) {
                                if (data.tractorDetails.driver1.hos.onDutyRemaining) {
                                    data.tractorDetails.driver1.hos.onDutyRemaining = formatSecondsToHoursMinutes(data.tractorDetails.driver1.hos.onDutyRemaining);
                                }
                                if (data.tractorDetails.driver1.hos.remainingToday) {
                                    data.tractorDetails.driver1.hos.remainingToday = formatSecondsToHoursMinutes(data.tractorDetails.driver1.hos.remainingToday);
                                }
                            }
                            if (data.tractorDetails.driver2 && data.tractorDetails.driver2.hos) {
                                if (data.tractorDetails.driver2.hos.onDutyRemaining) {
                                    data.tractorDetails.driver2.hos.onDutyRemaining = formatSecondsToHoursMinutes(data.tractorDetails.driver2.hos.onDutyRemaining);
                                }
                                if (data.tractorDetails.driver2.hos.remainingToday) {
                                    data.tractorDetails.driver2.hos.remainingToday = formatSecondsToHoursMinutes(data.tractorDetails.driver2.hos.remainingToday);
                                }
                            }
                            self.tractorDetails(data.tractorDetails);
                        }
                        if (data.preassignedOrders)
                            self.preassignments(data.preassignedOrders);
                        if (data.tractorAdvancedOptions)
                            self.advancedOptions(data.tractorAdvancedOptions);
                        self.loadUnitMessageGrid();
                    }
                    if (_isCarrierDriver) {
                        $('#modalHeaderText').text('Carrier Tractor Details');
                    }
                    parentModel.$parent.isLoading(false);
                    $("#jqxTractorDetailsModal").modal('show');
                }).fail(function (error) {
                    parentModel.$parent.isLoading(false);
                }).then(function (x) {
                    self.buildEquipmentGrid();
                });
            }
        }
        self.init();
    }
}

export default { viewModel: TractorDetailsModel, template: template }