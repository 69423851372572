import { isDeviceMobileOrTablet, cleanString, datetimeUTC, Base64, createLocalCache } from 'global-functions';

export const isMobileOrTablet = () => isDeviceMobileOrTablet();

export const mapMethodCodes = (val) => {
    switch(val) {
        case "D":
            return "Distance";
        case "F":
            return "Flat";
        case "C":
            return "CWT";
        case "T":
            return "Tons";
        case "O":
            return "Other";
        case "R":
            return "Rate Table";
        default:
            return "";
    }
}

const ratesComponentCache = createLocalCache({});
export { ratesComponentCache }