import ko from "knockout";
import dataModel from "data-model";
import template from "./lane-analysis-page.html";
import GridStateComponentViewModel from "jqx.grid-state-component";
import userProfile from "user-profile";
import _ from "lodash";
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";
import gridStateUtils from "jqx.grid-utils";
import {
    formatPhoneNumber,
    createDateTimeFilterWidget,
} from "global-functions";

// Utils
var isNullOrWhiteSpace = function (value) {
    return !value || value.length === 0 || /^\s*$/.test(value);
};

// Types
var orderDetailsModel = function (data, mainModel) {
    var self = this;
    data = data || {};
    mainModel = mainModel || {};

    self.orderExId = ko.observable(data.orderExId);
    self.isBrokered = ko.observable(data.isBrokered || false);
    self.isContainer = ko.observable(data.isContainer || false);
    self.isHazmat = ko.observable(data.isHazmat || false);
    self.isLTL = ko.observable(data.isLTL || false);
    self.status = ko.observable(data.status);
    self.shipper = ko.observable(data.shipper);
    self.carrierDriverName = ko.observable(data.carrierDriverName);
    self.phoneNumber = ko.observable(data.phoneNumber);
    self.carrierPay = ko.observable(data.carrierPay);
    self.consignee = ko.observable(data.consignee);
    self.distance = ko.observable(data.distance);
    self.trailerType = ko.observable(data.trailerType);
    self.driverExId = ko.observable(data.driverExId);

    self.orderDate = ko.computed(function () {
        var d = new Date(data.orderDate);
        console.log(d);
        return d.toDateString();
    });
};

// The model (class) that handles equipment type DDL
var equipTypeDDL = function (elementId, data) {
    var self = this;
    elementId = elementId || "";

    data = data || [];
    self.tmpData = ko.observableArray(data);

    // Returns array of values from checked items
    self.getSelectedItems = function () {
        var items = $(elementId).jqxTree("getCheckedItems");
        var tmpItems = [];

        if (items.length > 0) {
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                var label = item.label.toUpperCase();
                var value = item.value;

                // Make sure we are not including the "Group Label" options here
                if (
                    label != "DECKS" &&
                    label != "FLATBEDS" &&
                    label != "OTHER" &&
                    label != "REEFERS" &&
                    label != "VAN" &&
                    label != "VANS"
                ) {
                    tmpItems.push(value);
                }
            }
        }

        return tmpItems;
    };

    // Uncheck all items
    self.clearItems = function () {
        self.itemsSelected = [];
        $(elementId).jqxTree("uncheckAll");
    };

    self.getSourceItems = function (data) {
        if (data != undefined) self.tmpData(data);

        var equipModel = function (item, items) {
            var self = this;
            self.label = item.name;
            self.checked = item.checked || false;
            self.expanded = item.expanded || false;
            self.items = items;
            self.value = item.value;
        };

        var source = [];
        var decks = [];
        var flatbeds = [];
        var other = [];
        var reefers = [];
        var vans = [];

        ko.utils.arrayForEach(self.tmpData(), function (item) {
            var name = item.description;
            var category = item.category.toUpperCase();
            var value = item.externalID;
            var chk = false;

            if (category == "DECKS") {
                decks.push(new equipModel({ name: name, value: value }, []));
                if (chk) {
                    self.itemsSelected.push(name);
                }
            } else if (category == "FLATBEDS") {
                flatbeds.push(new equipModel({ name: name, value: value }, []));
                if (chk) {
                    self.itemsSelected.push(name);
                }
            } else if (category == "OTHER") {
                other.push(new equipModel({ name: name, value: value }, []));
                if (chk) {
                    self.itemsSelected.push(name);
                }
            } else if (category == "REEFERS") {
                reefers.push(new equipModel({ name: name, value: value }, []));
                if (chk) {
                    self.itemsSelected.push(name);
                }
            } else if (["VANS", "VAN"].indexOf(category) != -1) {
                vans.push(new equipModel({ name: name, value: value }, []));
                if (chk) {
                    self.itemsSelected.push(name);
                }
            }
        });

        source.push(new equipModel({ name: "Decks" }, decks));
        source.push(new equipModel({ name: "Flatbeds" }, flatbeds));
        source.push(new equipModel({ name: "Reefers" }, reefers));
        source.push(new equipModel({ name: "Vans" }, vans));
        source.push(new equipModel({ name: "Other" }, other));

        return source;
    };
};

//=======================
// ViewModel
var LaneAnalysisViewModel = function () {
    var self = this;
    //============================
    // UI Controls
    self.isLoading = ko.observable(false);
    self.isProcessing = ko.observable(false);
    self.isPageInitLoaded = ko.observable(false);
    self.modelError = ko.observable();
    self.searchBtnText = ko.observable("Search");
    self.equipCheckBox = null;
    self.detailsModal = ko.observable();
    self.clearSelectedSavedSearchDDL = ko.observable(false);
    self.refreshSavedSearchDDL = ko.observable("");
    var grid = $("#jqxLaneAnalysisResultsGrid");
    self.gridInit = false;
    self.equipTypeDLL = new equipTypeDDL("#equipTypeDDL");
    self.equipTypeDDLOptions = ko.observable();
    
    self.useSearchInputs = false;

    self.isProcessing.subscribe(function (val) {
        if (val == true) {
            self.searchBtnText("Searching...");
        } else {
            self.searchBtnText("Search");
        }
    });
    self.handleOnSelected = (state) => {
        
        var currentUserAgencyId = self.getCurrentUserAgency();
        self.gridState = state.grid;
        
        self.currentUserAgency(currentUserAgencyId);
        
        if(self.isPageInitLoaded() === false) {
            self.loadResultsGrid();
        }
        else {
            self.loadGridState(grid, state.grid)
        }        
    }

    //============================
    // Data
    var timePeriodDDLOptions = [
        { text: "Current", value: "Current" }, // For this option 'Current' is required as part of the query
        { text: "30 Days", value: 30 },
        { text: "90 Days", value: 90 },
        { text: "180 Days", value: 180 },
    ];

    self.originCityStateZip = ko.observable();
    self.destinationCityStateZip = ko.observable();
    self.timePeriodDDL = ko.observableArray(timePeriodDDLOptions);
    self.timePeriodSelected = ko.observable();
    self.originRadius = ko.observable();
    self.destinationRadius = ko.observable();
    self.includeGWTrucks = ko.observable(false);
    self.searchOnlyMyAgency = ko.observable(true);
    self.currentUserAgency = ko.observable();

    //=========================
    // Behaviors
    self.clearSearch = function () {
        self.originRadius("");
        self.destinationRadius("");
        self.originCityStateZip("");
        self.destinationCityStateZip("");
        self.timePeriodSelected(timePeriodDDLOptions[0]);
        self.includeGWTrucks(false);
        self.searchOnlyMyAgency(true);
        self.modelError("");


        self.equipTypeDLL.clearItems();
        grid.jqxGrid("clear");
    };

    self.displayOrderDetails = function (orderId) {
        var id = orderId || 0;

        if (id > 0) {
            dataModel
                .ajaxRequest("LaneAnalysis/GetOrderDetails/" + id, "GET")
                .done(function (response) {
                    var detailModel = new orderDetailsModel(response, self);
                    self.detailsModal(detailModel);
                });
        }
    };

    //=================================================
    // Search Results Grid
    //----------------------------------------------

    self.createCityStateZipFilterWidget = function (
        column,
        columnElement,
        widget
    ) {
        widget.jqxInput({
            placeHolder: "City, State, Zip",
        });
    };

    self.exportGridData = function (gridId, fileName) {
        var gridrows = grid.jqxGrid("getrows");

        if (gridrows != undefined && gridrows.length > 0) {
            var jsonData = gridStateUtils.formatGridHeaders(gridId, false);
            dataModel.exportToCSV(jsonData, fileName, true);
        }
    };

    self.loadGridState = function (grid, state) {
        if (state != null) {
            var gridState = grid.jqxGrid("getstate");
            state.selectedrowindex = -1;
            state.selectedrowindexes = [];
            state.pagenum = 0;
            state.height = gridState.height;
            state.pagesize = gridState.pagesize;
            state.pagesizeoptions = gridState.pagesizeoptions;
            grid.jqxGrid("loadstate", state);
        } else {
            grid.jqxGrid("loadstate");
        }
    };

    // We need to put the source and adapter outside the loadGridResults method to avoid
    // multiple calls to the API when doing a search. The source data gets applied to the grid
    // in the submit search method. -> grid.jqxGrid({ source: dataSource });
    var source = {
        url: "LaneAnalysis/SearchResultsForGrid",
        datatype: "json",
        type: "POST",
        formatdata: function (data) {

            if (self.isPageInitLoaded() == true) {
                data.OriginRadius = self.originRadius() || 10;
                data.DestinationRadius = self.destinationRadius() || 10;
                data.OrgCityStateZip = self.originCityStateZip();
                data.DestCityStateZip = self.destinationCityStateZip();
                data.TimePeriod = self.timePeriodSelected().value || 30;
                data.IncludeGWTrucks = self.includeGWTrucks();
                data.SearchOnlyMyAgency = self.searchOnlyMyAgency();
                data.EquipmentType = self.equipTypeDLL.getSelectedItems();

                return data;
            }
        },
        datafields: [
            { name: "agencyId", type: "number" },
            { name: "orderId", type: "number" },
            { name: "orderExId", type: "string" },
            { name: "pickupDate", type: "date" },
            { name: "origin", type: "string" },
            { name: "destination", type: "string" },
            { name: "trailerType", type: "string" },
            { name: "carrierExId", type: "string" },
            { name: "carrierId", type: "string" },
            { name: "driverExId", type: "string" },
            { name: "carrierPay", type: "number" },
            { name: "miles", type: "string" },
            { name: "phoneNumber", type: "string" },
            { name: "faxNumber", type: "string" },
            { name: "email", type: "string" },
        ],

        loadComplete: function (data) {
            if (data != undefined) {
                if (data.success == false) {
                    self.modelError(data.message);
                    grid.jqxGrid("clear");
                }
            }
        },
        loadError: function (xhr, status, error) {},
    };

    var dataSource = dataModel.getDataAdapter(source);
    self.gridState = null;
    self.loadResultsGrid = function () {
        var columns = [
            {
                text: "",
                width: 100,
                filterable: false,
                sortable: false,
                pinned: true,
                columnType: "button",
                buttonclick: function (row) {
                    var datarow = grid.jqxGrid("getrowdata", row);
                    self.displayOrderDetails(datarow.orderId);
                },
                cellsrenderer: function () {
                    return "Details";
                },
            },
            {
                text: "Order Id",
                datafield: "orderExId",
                width: 100,
                cellsrenderer: function (
                    row,
                    columnfield,
                    value,
                    defaultHTML,
                    column,
                    rowData
                ) {
                    if (
                        defaultHTML != null &&
                        self.currentUserAgency() == rowData.agencyId
                    ) {
                        var cell = $(defaultHTML);
                        cell.html(
                            '<a href="Orders/' +
                                value +
                                '" target="_blank"  style="color:blue; "  >' +
                                value +
                                "</a>"
                        );
                        return cell[0].outerHTML;
                    }

                    return defaultHTML;
                },
            },
            {
                text: "Pickup Date",
                dataField: "pickupDate",
                cellsformat: "MM/dd/yyyy",
                columntype: "datetimeinput",
                filtertype: "date",
                createfilterwidget: createDateTimeFilterWidget,
                width: 150,
            },
            {
                text: "Origin",
                datafield: "origin",
                width: 250,
                createfilterwidget: self.createCityStateZipFilterWidget,
            },
            {
                text: "Destination",
                datafield: "destination",
                width: 250,
                createfilterwidget: self.createCityStateZipFilterWidget,
            },
            { text: "Trailer Type", datafield: "trailerType", width: 200 },
            {
                text: "Carrier",
                datafield: "carrierExId",
                width: 200,
                cellsrenderer: function (
                    row,
                    columnfield,
                    value,
                    defaultHTML,
                    column,
                    rowData
                ) {
                    if (defaultHTML != null) {
                        var cell = $(defaultHTML);
                        cell.html(
                            '<a href="CarrierEntry/' +
                                rowData.carrierId +
                                '" target="_blank"  style="color:blue; "  >' +
                                value +
                                "</a>"
                        );
                        return cell[0].outerHTML;
                    }

                    return defaultHTML;
                },
            },
            {
                text: "Driver",
                datafield: "driverExId",
                width: 200,
                cellsrenderer: function (
                    row,
                    columnfield,
                    value,
                    defaultHTML,
                    column,
                    rowData
                ) {
                    if (defaultHTML != null) {
                        var cell = $(defaultHTML);
                        cell.html(
                            '<a href="Drivers?DriverID=' +
                                value +
                                '" target="_blank"  style="color:blue; "  >' +
                                value +
                                "</a>"
                        );
                        return cell[0].outerHTML;
                    }

                    return defaultHTML;
                },
            },
            {
                text: "Carrier Pay",
                datafield: "carrierPay",
                width: 120,
                cellsformat: "c2",
            },
            { text: "Miles", datafield: "miles", width: 120 },
            {
                text: "Phone",
                datafield: "phoneNumber",
                width: 120,
                cellsrenderer: function (
                    row,
                    columnfield,
                    value,
                    defaultHTML,
                    column,
                    rowData
                ) {
                    if (defaultHTML != null) {
                        var phoneFormated = formatPhoneNumber(value);
                        var cell = $(defaultHTML);
                        cell.html(phoneFormated);
                        return cell[0].outerHTML;
                    }

                    return defaultHTML;
                },
            },
            {
                text: "Fax",
                datafield: "faxNumber",
                width: 120,
                cellsrenderer: function (
                    row,
                    columnfield,
                    value,
                    defaultHTML,
                    column,
                    rowData
                ) {
                    if (defaultHTML != null) {
                        var phoneFormated = formatPhoneNumber(value);
                        var cell = $(defaultHTML);
                        cell.html(phoneFormated);
                        return cell[0].outerHTML;
                    }

                    return defaultHTML;
                },
            },
            {
                text: "Email",
                datafield: "email",
                width: 300,
                cellsrenderer: function (
                    row,
                    columnfield,
                    value,
                    defaultHTML,
                    column,
                    rowData
                ) {
                    if (defaultHTML != null) {
                        var cell = $(defaultHTML);
                        cell.html(
                            '<a href="mailto:' +
                                value +
                                '"  style="color:blue; "  >' +
                                value +
                                "</a>"
                        );
                        return cell[0].outerHTML;
                    }

                    return defaultHTML;
                },
            },
        ];

        grid.jqxGrid({
            width: "100%",
            altrows: true,
            sortable: true,
            autoheight: true,
            pageable: true,
            pagesize: 10,
            filterable: true,
            showfilterrow: true,
            virtualmode: false,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            columns: columns,
            showtoolbar: true,
            rendertoolbar: function (toolbar) {
                let vm1 = new GridStateComponentViewModel.viewModel(),
                    template = GridStateComponentViewModel.template;

                vm1.actions.push("Export");
                vm1.actions.push("Save Search")

                let $grid = $("#jqxLaneAnalysisResultsGrid");

                vm1.clearFilters = function () {
                    // clear additional filters from grid
                    $('#jqxLaneAnalysisResultsGrid').jqxGrid('clearfilters');
                    self.clearSelectedSavedSearchDDL(true);
                }
                
                vm1.setDefaultSearchOverride = async () => {
                    const savedSearches = await loadSavedSearches($grid.attr("id"));
                    const filters = { 
                        originRadius: self.originRadius(),
                        destinationRadius: self.destinationRadius(),
                        originCityStateZip: self.originCityStateZip(),
                        destinationCityStateZip: self.destinationCityStateZip(),
                        timePeriod: self.timePeriodSelected().value,
                        includeGWTrucks: self.includeGWTrucks(),
                        searchOnlyMyAgency: self.searchOnlyMyAgency(),
                        equipmentType: self.equipTypeDLL.getSelectedItems(),
                        isDefault: true
                    }

                    vm1.loadSaveSearchModal(
                        $grid,
                        (val) => {
                            if(val && val.searchname) {
                                self.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
                }

                vm1.loadSaveSearchModalOverride = async () => {
                    const savedSearches = await loadSavedSearches($grid.attr("id"));
                    const filters = {
                        originRadius: self.originRadius(),
                        destinationRadius: self.destinationRadius(),
                        originCityStateZip: self.originCityStateZip(),
                        destinationCityStateZip: self.destinationCityStateZip(),
                        timePeriod: self.timePeriodSelected().value,
                        includeGWTrucks: self.includeGWTrucks(),
                        searchOnlyMyAgency: self.searchOnlyMyAgency(),
                        equipmentType: self.equipTypeDLL.getSelectedItems(),
                        isDefault: false
                    }

                    vm1.loadSaveSearchModal(
                        $grid,
                        (val) => {
                            if(val && val.searchname) {
                                self.refreshSavedSearchDDL(val.searchname)
                            }
                        },
                        savedSearches.filter(x => x.searchName).map(x => ({id: x.id, text: x.searchName})),
                        filters,
                        true
                    );
                    
                }


                


                vm1.action = function (item) {
                    if (item === "Export") {
                        self.exportGridData(
                            "jqxLaneAnalysisResultsGrid",
                            "LaneAnalysis"
                        );
                    }
                };

                const $actionList = $("#laneActionList");
                $actionList.append(template);

                toolbar.append($actionList);
            
                const $laneToolbar = $("#laneToolBar");
                toolbar.append($laneToolbar);

                ko.applyBindingsToDescendants(vm1, $actionList[0]);

            },
            ready: function () {
                self.loadGridState(grid, self.gridState);

                
                self.isPageInitLoaded(true);
            },
        });

        grid.on("bindingcomplete", function (event) {
            self.isProcessing(false);
        });
    };

    self.submitSearch = function () {
        // We are already performing a search so exit additional attempts until completed
        if (self.isProcessing() == true) return false;

        self.modelError("");

        // Validate form
        var oRadius = self.originRadius();
        var oCSZ = self.originCityStateZip();
        var dRadius = self.destinationRadius();
        var dCSZ = self.destinationCityStateZip();

        if (
            isNullOrWhiteSpace(oRadius) == false &&
            oRadius > 0 &&
            isNullOrWhiteSpace(oCSZ)
        )
            self.modelError(
                "You must select a valid origin. You can select a city, a state, or a zip code"
            );

        if (
            isNullOrWhiteSpace(dRadius) == false &&
            dRadius > 0 &&
            isNullOrWhiteSpace(dCSZ)
        )
            self.modelError(
                "You must select a valid destination. You can select a city, a state, or a zip code"
            );

        // No errors so validation passed
        if (self.modelError() == "" || self.modelError() == undefined) {
            self.isProcessing(true);
            grid.jqxGrid({ source: dataSource });
        }
    };

    self.getCurrentUserAgency = function () {
        return userProfile.currentAgencyId();
    };

    // Initialize the page
    self.init = function () {

        // Get trailer types for ddl checkbox
        dataModel
            .ajaxRequest("TrailerType/GetTrailerTypes", "get")
            .done(function (data) {
                var items = self.equipTypeDLL.getSourceItems(data);
                self.equipTypeDDLOptions(items);

                self.isProcessing(false);
            });
    };

    self.init();
};

export default { viewModel: LaneAnalysisViewModel, template: template };
