
// This is the 'provider' which hooks the store into knockoutjs context and provides utility methods for getting
// the state.

import ko from 'knockout';

function koDataStore() {
    let appStore = {};

    // Adds a $appStore context to ko bindings, which is accessable
    // in the 'template' bindings. -> i.e. data-bind="with: $appStore" or data-bind="text: $appStore.storeObject().property"
    const applyStoreToContext = function(store) {
        appStore = store;
        
        ko.bindingProvider.instance = new function () {
            const addState = (bindingContext) => {
                bindingContext.$appStore = store.getState();
            }
           
            var original = new ko.bindingProvider();
            this.nodeHasBindings = original.nodeHasBindings;
            this.getBindings = function (node, bindingContext) {
                addState(bindingContext);
                
                var result;
                try {
                    result = original.getBindings(node, bindingContext);
                }
                catch (e) {
                    if (console && console.error) {
                        console.error("binding error", e, node, bindingContext);
                    }
                }

                return result;
            };
        };
    }

    // Returns a protected state with ko.computed() properties -- allows subscribing to individual props, but doesn't allow
    // changing of the values from what the 'store' holds. 
    // Ex: const { tests } = useSelector((state) => state) or const tests = useSelector((state) => state.tests);
    // Optional: Object with prop 'toJS', when true will return raw data without observables.
    const useSelector = function(callbackFn, {toJS = false} = {}) {
        const state = appStore.getState();
        let readOnlyState = ko.toJS(state);

        if(toJS == false) {
            readOnlyState = Object.keys(state).reduce((protectedState, key) => {
                protectedState[key] = ko.computed(() => state[key]());
                return protectedState; 
            },{})
        }

        return callbackFn(readOnlyState);
    }

    // Ex: const dispatch = useDispatch(); -> dispatch(setTestData('this is a test!'));
    // Also provides the current state to the action handler. In actions -> function doSomething(someVal, state) { return {type: 'action', payload: null }}
    const useDispatch = function() {
        return async (action) => {
            
            if(Object.keys(action).length) {
                appStore.dispatch(action);
            }
            else if(typeof action === 'function') {
                const doAction = await action(appStore.dispatch, ko.toJS(appStore.getState()));
                appStore.dispatch(doAction);
            }

            return ko.toJS(appStore.getState());
        }
    }

    return { applyStoreToContext, useSelector, useDispatch }
}

const instance = koDataStore();
const useSelector = instance.useSelector;
const useDispatch = instance.useDispatch;
const applyStoreToContext = instance.applyStoreToContext;
export { useSelector, useDispatch, applyStoreToContext }; // exporting named exports
