import template from './order-entry-dispatch-carrier-extra-pay-modal.html';
import ko from 'knockout';


class CarrierExtraPayRecordModalVM {
    constructor(extra) {
        extra = extra.params || {};
        var self = this;
        self.result = extra.result;
        self.id = ko.observable(extra.id || 0);
        self.movementId = ko.observable(extra.movementId);
        self.orderId = ko.observable(extra.orderId);
        
        self.carrier = ko.observable(extra.carrierExId).extend({ required: { message: "Carrier is required."} });
        self.carrierSelectedValue = ko.observable();
        
        self.deductionEarningCodeId = ko.observable(extra.deductionEarningCodeId).extend({ required: true });
        self.externalId = ko.observable(extra.externalId);
        
        self.memoNumber = ko.observable(extra.memoNumber);
        self.description = ko.observable(extra.description)
        self.transactionDate = ko.observable(extra.transactionDate).extend({ required: true });

        self.units = ko.observable(extra.units).extend({ required: true });
        self.rate = ko.observable(extra.rate).extend({ required: { message: 'Rate is required.'} })

        self.amount = ko.computed(function(){
            if(!self.units() || !self.rate()) {
                return "";
            } else {
                return self.units() * self.rate();
            }
        });
        self.getPayloadObject = function() {
            return {
                id: self.id(),
                deductionEarningCodeId : typeof(self.deductionEarningCodeId()) == "object" ? self.deductionEarningCodeId().value: self.deductionEarningCodeId(),
                description : self.description(),
                memoNumber : self.memoNumber(),
                transactionDate : self.transactionDate().toISOString(),
                units : self.units(),
                rate : self.rate(),
                movementId : self.movementId(),
                orderId : self.orderId(),
                carrierId : self.carrierSelectedValue().id,
                carrierExId : self.carrierSelectedValue().code,
                externalId :self.externalId()
            }
        }


        self.ok = function() {
            var validationErrors = ko.validation.group(self);
            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                return false;
            }
            $('#carrierExtraPayModal').modal('hide');
            self.result(self.getPayloadObject());
        }

        self.cancel = function() {
            $('#carrierExtraPayModal').modal('hide');
            self.result(undefined);
        }

    }
}

export default {viewModel: CarrierExtraPayRecordModalVM, template: template};