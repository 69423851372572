import './csaExceptionsCustomStyles.css';
import dataModel from 'data-model';
import gridState from 'jqx.grid-state-component';
import gridStateUtils from 'jqx.grid-utils';
import {createDateTimeFilterWidget, isEmpty} from 'global-functions';

class ExceptionModel {
    constructor(x) {
        var _this2 = this;

        this.get = function () {
            return {
              id: _this2.id(),
              externalId: _this2.externalId(),
              driverid: typeof _this2.selectedDriverObject() == "object" ? _this2.selectedDriverObject().id : 0,
              driverExternalId: typeof _this2.selectedDriverObject() == "object" ? _this2.selectedDriverObject().code : _this2.driverid(),
              driverName: _this2.driverName(),
              licenseState: _this2.licenseState(),
              licenseNumber: _this2.licenseNumber(),
              tractorid: typeof _this2.selectedTractorObject() == "object" ? _this2.selectedTractorObject().id : 0,
              tractorExternalId: typeof _this2.selectedTractorObject() == "object" ? _this2.selectedTractorObject().code : _this2.tractorid(),
              tractorPlateNumber: _this2.tractorPlateNumber(),
              tractorPlateState: _this2.tractorPlateState(),
              tractorVIN: _this2.tractorVIN(),
              trailer1id: typeof _this2.selectedTrailer1Object() == "object" ? _this2.selectedTrailer1Object().id : 0,
              trailer1ExternalId: typeof _this2.selectedTrailer1Object() == "object" ? _this2.selectedTrailer1Object().code : _this2.trailer1id(),
              trailer1PlateNumber: _this2.trailer1PlateNumber(),
              trailer1PlateState: _this2.trailer1PlateState(),
              trailer1VIN: _this2.trailer1VIN(),
              trailer2id: typeof _this2.selectedTrailer2Object() == "object" ? _this2.selectedTrailer2Object().id : 0,
              trailer2ExternalId: typeof _this2.selectedTrailer2Object() == "object" ? _this2.selectedTrailer2Object().code : _this2.trailer2id(),
              trailer2PlateNumber: _this2.trailer2PlateNumber(),
              trailer2PlateState: _this2.trailer2PlateState(),
              trailer2VIN: _this2.trailer2VIN(),
              unknownAssets: _this2.unknownAssets(),
              intermodalChassis: _this2.intermodalChassis()
            };
          };
      
          x = x || {};
          this.unknownAssets = ko.observable(false);
          this.intermodalChassis = ko.observable(x.exception.intermodalChassis ? true : false);
          this.id = ko.observable(x.exception.id);
          this.externalId = ko.observable(x.exception.externalId);
          this.driverid = ko.observable(x.exception.driverExternalId || undefined).extend({
            required: {
              message: "A valid Driver is required.",
              onlyIf: function onlyIf() {
                if (_this2.unknownAssets() == true) {
                  return false;
                }
      
                return true;
              }
            }
          });
          this.selectedDriverObject = ko.observable();
          this.driverName = ko.observable(x.exception.driverName);
          this.licenseState = ko.observable(x.exception.licenseState);
          this.licenseNumber = ko.observable(x.exception.licenseNumber);
          this.selectedTractorObject = ko.observable();
          this.tractorid = ko.observable(x.exception.tractorExternalId || undefined).extend({
            required: {
              message: "A valid Tractor is required.",
              onlyIf: function onlyIf() {
                if (_this2.unknownAssets() == true) {
                  return false;
                }
      
                return true;
              }
            }
          });
          this.tractorPlateNumber = ko.observable(x.exception.tractorPlateNumber);
          this.tractorPlateState = ko.observable(x.exception.tractorPlateState);
          this.tractorVIN = ko.observable(x.exception.tractorVIN);
          this.selectedTrailer1Object = ko.observable();
          this.trailer1id = ko.observable(x.exception.trailer1ExternalId || undefined).extend({
            required: {
              message: "A valid Trailer 1 is required.",
              onlyIf: function onlyIf() {
                if (_this2.unknownAssets() == true || ko.unwrap(_this2.intermodalChassis) == true) {
                  return false;
                }
      
                return true;
              }
            }
          });
          this.trailer1PlateNumber = ko.observable(x.exception.trailer1PlateNumber);
          this.trailer1PlateState = ko.observable(x.exception.trailer1PlateState);
          this.trailer1VIN = ko.observable(x.exception.trailer1VIN);
          this.trailer2id = ko.observable(x.exception.trailer2ExternalId || undefined) //setError in the update method only works if there's already a required message attached to the observable.
          .extend({
            required: {
              message: "A valid Tractor is required.",
              onlyIf: function onlyIf() {
                return false;
              }
            }
          });
          this.selectedTrailer2Object = ko.observable(x.exception.trailer2id);
          this.trailer2PlateNumber = ko.observable(x.exception.trailer2PlateNumber);
          this.trailer2PlateState = ko.observable(x.exception.trailer2PlateState);
          this.trailer2VIN = ko.observable(x.exception.trailer2VIN);
          this.driverSuggestion = ko.observable(new ExceptionSuggestionModel(x.driverSuggestion));
          this.tractorSuggestion = ko.observable(new ExceptionSuggestionModel(x.tractorSuggestion));
          this.trailerSuggestion = ko.observable(new ExceptionSuggestionModel(x.trailerSuggestion));
          this.unknownAssets.subscribe(function (x) {
            if (x) {
              _this2.trailer2id.clearError();
            }
          });
    }
}

class ExceptionSuggestionModel {
    constructor(x) {
        x = x || {};
        this.driverExId = ko.observable(x.driverExId);
        this.driverName = ko.observable(x.driverName);
        this.trailerExId = ko.observable(x.trailerExId);
        this.tractorExId = ko.observable(x.tractorExId);
        this.vin = ko.observable(x.vin);
        this.licenseNumber = ko.observable(x.licenseNumber);
    }
}

class CSAExceptionViewModel {
    constructor() {
        var _this = this;

        this.editExceptionModel = ko.observable(null);
        this.editExceptionModalHeader = ko.observable(null);
        this.errors = ko.observable([]);
        this.showModalLoadingPanel = ko.observable(false);
        this.csaGridState = null;

        this.initalizeComponents = function () {
            gridStateUtils.getUserGridFiltersAsync("exceptionsGrid").then(function(filters) {
                if (isEmpty(filters) == false) {
                    let state = JSON.parse(filters);
                    _this.csaGridState = state.grid;
                }

                _this.getExceptionGridData().then(function (value) {
                    _this.exceptionData = new $.jqx.observableArray(value);
    
                    _this.initializeGrid();
    
                    _this.defaultState = _this.csaGridState || _this.exceptionsGrid.getstate();
                });
            });
           
        };

        this.gridSource = function () {
            return {
                localdata: _this.exceptionData,
                datafields: [{
                    name: "id",
                    type: "string"
                    }, {
                    name: "authority",
                    type: "string"
                    }, {
                    name: "roadside",
                    type: "string"
                    }, {
                    name: "errorText",
                    type: "string"
                    }, {
                    name: "postedDate",
                    type: "date"
                    }, {
                    name: "inspectionDate",
                    type: "date"
                }]
            };
        };

        this.initializeGrid = function () {
            var gridSource = {
                localdata: _this.exceptionData,
                datafields: [{
                    name: "id",
                    type: "string"
                    }, {
                    name: "authority",
                    type: "string"
                    }, {
                    name: "roadside",
                    type: "string"
                    }, {
                    name: "errorText",
                    type: "string"
                    }, {
                    name: "postedDate",
                    type: "date"
                    }, {
                    name: "inspectionDate",
                    type: "date"
                }]
            };

            var dataAdapter = new $.jqx.dataAdapter(gridSource);
            var gridOptions = {
                source: dataAdapter,
                filter: gridStateUtils.handleClientSideFiltering,
                width: "100%",
                altrows: true,
                sortable: true,
                autoheight: true,
                pageable: true,
                pagesize: 10,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                showtoolbar: true,
                filterable: true,
                showfilterrow: true,
                rendertoolbar: _this.rendertoolbar,
                autoshowfiltericon: true,
                columns: [{
                    width: 50,
                    text: "",
                    filterable: false,
                    columntype: "button",
                    pinned: true,
                    cellsrenderer: function cellsrenderer() {
                        return "Edit";
                    },
                    buttonclick: function buttonclick(row) {
                        _this.showEditExceptionModal(row);
                    }
                    }, {
                    datafield: "roadside",
                    text: "Roadside",
                    width: 135,
                    //filtertype: "list"
                    }, {
                    datafield: "postedDate",
                    text: "Posted Date",
                    cellsformat: "MM/dd/yyyy",
                    width: 140,
                    filtertype: "date",
                    createfilterwidget: createDateTimeFilterWidget
                    }, {
                    datafield: "inspectionDate",
                    text: "Inspection Date",
                    cellsformat: "MM/dd/yyyy",
                    width: 140,
                    filtertype: "date",
                    createfilterwidget: createDateTimeFilterWidget
                    }, {
                    datafield: "authority",
                    text: "Authority",
                    width: 135,
                    //filtertype: "list"
                    }, {
                    datafield: "errorText",
                    text: "Description of Error",
                    filtertype:  _this.exceptionData.length > 0 ? "list" : "input"
                }],
                ready: function() {
                    gridStateUtils.applyGridState("exceptionsGrid", _this.csaGridState);
                }
            };

            _this.exceptionsGrid = jqwidgets.createInstance("#exceptionsGrid", "jqxGrid", gridOptions);
        };

        this.refreshGridData = function() {
            _this.getExceptionGridData().then(function (value) {
                _this.exceptionData = new $.jqx.observableArray(value);
                _this.exceptionsGrid.updatebounddata();
            });
        }

        this.rendertoolbar = function (toolbar) {
        //If the toolbar is loaded after the initial load, it throws an error.
        if (!toolbar.isLoaded) {
            var actionBarViewModel = new gridState.viewModel(),
                tmpl1 = gridState.template;
                
            actionBarViewModel.clearFilters = function () {
                _this.exceptionsGrid.loadstate(_this.defaultState);
            };

            actionBarViewModel.refresh = function() {
                _this.exceptionsGrid.showloadelement();
                _this.refreshGridData();
            }

            var tmpl1jq = $(tmpl1);
            $(toolbar).append(tmpl1jq);
            ko.applyBindingsToDescendants(actionBarViewModel, toolbar[0]);
            toolbar.isLoaded = true;
        }
        };

        this.showEditExceptionModal = function (row) {
        _this.updateingGridRow = row;

        var rowdata = _this.exceptionsGrid.getrowdata(row);

        _this.getEditExcpetionData(rowdata.id).then(function (x) {
            var obj = new ExceptionModel(x);

            _this.editExceptionModalHeader("Edit Inspection Equipment - " + x.exception.externalId);

            _this.editExceptionModel(obj);
        });
        };

        this.getExceptionGridData = function () {
        return new Promise(function (resolve, reject) {
            dataModel.ajaxRequest("CSAAdmin/GetCSAExceptionsForGrid", "POST", {}).done(function (x) {
            resolve(x);
            });
        });
        };

        this.getEditExcpetionData = function (id) {
        return new Promise(function (resolve, reject) {
            dataModel.ajaxRequest("CSAAdmin/GetEquipmentSuggestionData", "POST", id).done(function (x) {
            resolve(x);
            });
        });
        };

        this.updateException = function () {
        _this.errors(undefined);

        ko.validation.reset();
        var errors = ko.validation.group(_this.editExceptionModel);

        if (errors().length > 0) {
            errors.showAllMessages();
            return false;
        } else {
            var errorFound = false; //KnockoutValidation isn't working when we have to check the value of 2 properties so having to do certain validation manually.  

            if (_this.editExceptionModel().unknownAssets() == false && typeof _this.editExceptionModel().selectedTractorObject() == "string" && _this.editExceptionModel().selectedTractorObject() != "UNAUTH") {
                errorFound = true;

                _this.editExceptionModel().tractorid.setError("A valid Tractor is required.");
            }

            if (_this.editExceptionModel().unknownAssets() == false && typeof _this.editExceptionModel().selectedDriverObject() == "string" && _this.editExceptionModel().selectedDriverObject() != "UNAUTH") {
                _this.editExceptionModel().driverid.setError("A valid Driver is required.");

                errorFound = true;
            }

            if (_this.editExceptionModel().unknownAssets() == false && typeof _this.editExceptionModel().selectedTrailer1Object() == "string" && _this.editExceptionModel().selectedTrailer1Object() != "UNAUTH") {
                _this.editExceptionModel().trailer1id.setError("A valid Trailer 1 is required.");

                errorFound = true;
            }

            if (_this.editExceptionModel().unknownAssets() == false && _this.editExceptionModel().selectedTrailer2Object() && typeof _this.editExceptionModel().selectedTrailer2Object() == "string" && _this.editExceptionModel().selectedTrailer2Object() != "UNAUTH") {
                _this.editExceptionModel().trailer2id.setError("A valid Trailer 2 is required.");

                errorFound = true;
            }

            if (errorFound) {
                errors.showAllMessages();
                return false;
            }
        }

        _this.showModalLoadingPanel(true);

        var obj = _this.editExceptionModel().get();

        dataModel.ajaxRequest("CSAAdmin/UpdateInspection", "POST", obj).done(function (x) {
            if (x.errors && x.errors.length > 0) {
                _this.errors(x.errors);
            } else {
                _this.getExceptionGridData().then(function (exceptions) {
                    _this.showModalLoadingPanel(false);

                    _this.exceptionData = new $.jqx.observableArray(exceptions);

                    _this.exceptionsGrid.setOptions({
                    source: dataModel.getDataAdapter(_this.gridSource())
                    });

                    _this.updateingGridRow = null;
                    $(".close").click();
                });
            }
        });
        };

        this.initalizeComponents();
    }
}

import template from './csa-exceptions-page.html';
export default { viewModel: CSAExceptionViewModel, template: template }