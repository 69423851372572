//==============
// Dependencies
import dataModel from 'data-model';
import ko from 'knockout';
import template from './transfer-list-component.html';

var SourceItemObject = function (data, mainModel) {
    var self = this;
    data = data || {};
    self.mainModel = mainModel || {};

    self.displayText = ko.observable(data.displayText);
    self.value = ko.observable(data.value);

    self.isSelected = ko.observable(false);

    self.toggleSelect = function () {
        self.isSelected(!self.isSelected());
        updateCount();
    }

    // Update mainModel's selected item count to help keep track how many items are in the selected state
    // instead of iterating over each one to check if value of isSelected = true
    // and getting a count based on that.
    var updateCount = function () {
        var currentCount = self.mainModel.itemsSelectedCount();
        
        self.isSelected() ? currentCount += 1 : currentCount > 0 ? currentCount -= 1 : 0;
        
        self.mainModel.itemsSelectedCount(currentCount);
    }
}


//====================
// Component ViewModel
// See how it's implemented in reporting.js and reportingnew.aspx or myagency.js (edi tender setup), myagencynew.aspx (uses the component twice)
// params (object):
//  Required:
//      source or url: array or api endpoint with array of items
//          i.e. <component params="source: yoursource"> or <component params="url: theUrlToGetRecords">
//      selectedItems: a ko observableArray that will have the selected items pushed/popped into kind of like a callback return selected items
//  Optionals: 
//      displayText: the property of each items text to display in the list (used name property as default)
//      selectedValue: the property to set the value of selected items
//      sortBy: a ko binding that will accept a string set to 'displayText' or 'Value' to sort by
//      leftListLabel (string): text to display above left list (default is: Available)
//      rightListLabel (string): text to display above the right list (default is: Selected)
//   --If passing in mulitple arrays then can provide an object with properties for left source and right sources
//      leftSourceProp: (string): the property name for the left source
//      rightSourceProp: (string): the property name for the right source
var TransferListComponent = function (params) {
    
    params = params || {};
    var self = this;
    self.isComponentInit = ko.observable(false);

    
    self.url = params.url || null;

    self.source = params.source || ko.observableArray([]);
    self.isProcessing = ko.observable(false);
    self.displayText = params.displayText || 'name';
    self.selectValue = params.selectedValue || 'code';
    self.leftSourceProp = params.leftSourceProp;
    self.rightSourceProp = params.rightSourceProp;

    self.leftListLabel = params.leftListLabel || 'Available';
    self.rightListLabel = params.rightListLabel || 'Selected';
    self.sortBy = params.sortBy || ko.observable('DISPLAYTEXT');

    self.originalData = []; // Hold reference to original data

    self.leftGroup = ko.observableArray([]);
    self.rightGroup = ko.observableArray([]).extend({ required: true });

    self.selectedItems = params.selectedItems || ko.observableArray();
    self.itemsSelectedCount = ko.observable(0);

    // Listen to any changes on sorting and update accordingly
    self.sortBy.subscribe(function (sortBy) {
        self.sortGroups(sortBy);
    });


    // Listen for any changes to the source from outside the component
    self.source.subscribe(function (items) {
        
        if (items) {
            setSourceData();
        } else {
            self.clearGroups(true);
        }
    });


    // Listen for any external changes from outside the component
    self.selectedItems.subscribe(function (items) {
        
        var rightGroup = self.rightGroup() || [];

        // If the selected items was removed externally but we still have
        // items in the right group, then clear the right group
        if (items.length == 0 && rightGroup.length > 0) {
            self.clearGroups(false);
        }
    });

    self.clearGroups = function (clearAll) {
        clearAll = clearAll || false;
        
        if (clearAll) {
            self.leftGroup([]);
            self.rightGroup([]);
            self.source([]);
        } else {
            self.removeSelectedOrAllItems(true);
        }   
    }

    // Remove (move) items from right group -> left group
    // removeAll:boolean - if true then moves all the items, else remove only items.selected == true
    self.removeSelectedOrAllItems = function (removeAll) {
        removeAll = removeAll || false;
        
        if (self.rightGroup().length > 0 && (self.itemsSelectedCount() > 0 || removeAll)) {

            if(self.itemsSelectedCount() > 10 || removeAll)
                self.isProcessing(true);

            setTimeout(function () {
                var itemsToRemove = [];
                ko.utils.arrayForEach(self.rightGroup(), function (obj) {
                    if (removeAll == true) {
                        obj.isSelected(false);

                        if(self.leftGroup)
                            self.leftGroup.push(obj);

                        // Only remove if we have items
                        if(self.selectedItems())
                            self.selectedItems.remove(obj.value());

                        itemsToRemove.push(obj);
                    } else if (removeAll == false && obj.isSelected() == true) {
                        obj.isSelected(false);

                        if(self.leftGroup)
                            self.leftGroup.push(obj);

                        // Only remove if we have items
                        if (self.selectedItems())
                            self.selectedItems.remove(obj.value());

                        itemsToRemove.push(obj);
                    }

                });

                removeItemsFromGroup(itemsToRemove, self.rightGroup);

                self.isProcessing(false);
                self.itemsSelectedCount(0);
            },0);
        }
    }


    // Remove (move) items from left group -> right group
    // removeAll:boolean - if true then moves all the items, else remove only items.selected == true
    self.moveSelectedOrAllItems = function (moveAll) {
        moveAll = moveAll || false;

        if (self.leftGroup().length > 0 && (self.itemsSelectedCount() > 0 || moveAll)) {

            if (self.itemsSelectedCount() > 10 || moveAll)
                self.isProcessing(true);

            setTimeout(function () {

                var itemsToRemove = [];
                ko.utils.arrayForEach(self.leftGroup(), function (obj) {
                    if (moveAll == true) {
                        obj.isSelected(false);
                        self.rightGroup.push(obj);
                        self.selectedItems.push(obj.value());
                        itemsToRemove.push(obj);
                    }
                    else if (moveAll == false && obj.isSelected() == true) {
                        obj.isSelected(false);
                        self.rightGroup.push(obj);
                        self.selectedItems.push(obj.value());
                        itemsToRemove.push(obj);
                    }

                });

                removeItemsFromGroup(itemsToRemove, self.leftGroup);

                self.isProcessing(false);
                self.itemsSelectedCount(0);
            },0);

            
        }
    }
        
    
    // Removes items from a group
    // itemsToRemove: array of items
    // removeFromSource: ko observable array that we want to remove times from
    var removeItemsFromGroup = function (itemsToRemove, removeFromSource) {
        ko.utils.arrayForEach(itemsToRemove, function (obj) {
            removeFromSource.remove(obj);
        });

        self.sortGroups();
    }


    // Sort the groups based on sort type
    self.sortGroups = function (sortBy) {
        sortBy || self.sortBy() || "DISPLAYTEXT";

        if (sortBy != undefined) {

            switch (sortBy.toUpperCase()) {
                case "DISPLAYTEXT":
                    self.leftGroup.sort(sortByText);
                    self.rightGroup.sort(sortByText);
                    break;
                case "VALUE":
                    self.leftGroup.sort(sortByValue);
                    self.rightGroup.sort(sortByValue);
                    break;
                }
        }
    }

    var sortByText = function (objA, objB) {
        if (objA.displayText && objB.displayText) {
            let aName = objA.displayText().toLowerCase();
            let bName = objB.displayText().toLowerCase();
            return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
        }
    }

    var sortByValue = function (objA, objB) {
        if (objA.value && objB.value) {
            let acode = objA.value().toLowerCase();
            let bCode = objB.value().toLowerCase();
            return (acode < bCode) ? -1 : (acode > bCode) ? 1 : 0;
        }
    }


    // Returns an observable of selected items or unwrapped if doUnwrap == true;
    self.getSelectedItems = function (doUnwrap) {
        var unwrap = doUnwrap || false;

        return unwrap ? ko.unwrap(self.rightGroup()) : self.rightGroup();
    }


    var setSourceData = function () {

        if (self.source()) {
            
            var sourceItems = self.leftSourceProp ? self.source()[self.leftSourceProp] : self.source();
            var selectedItems = self.rightSourceProp ? self.source()[self.rightSourceProp] : null;

            ko.utils.arrayMap(sourceItems, function (item) {
                var so = new SourceItemObject({
                    displayText: item[self.displayText],
                    value: item[self.selectValue]
                }, self);
                                    
                self.leftGroup.push(so);
            });


            if (selectedItems != null) {
                ko.utils.arrayMap(selectedItems, function (item) {
                    var so = new SourceItemObject({
                        displayText: item[self.displayText],
                        value: item[self.selectValue]
                    }, self);

                    
                    self.rightGroup.push(so);
                    self.selectedItems.push(item[self.selectValue]);
                });
            }


            self.sortGroups(self.sortBy());
            self.isComponentInit(true);
        }
    }

    self.init = function () {
        
        if (self.url != undefined) {
            self.isProcessing(true);

            dataModel.ajaxRequest(self.url)
                .done(function (response, status, xhr) {
                    self.isProcessing(false);

                    self.source(response);
                    
                });
        } else {
            setSourceData();
        }
    };

    self.init();
};

export default {viewModel: TransferListComponent, template: template }