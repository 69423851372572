// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#UQUBxMFInfu5nwU2YT2dJQ\\=\\= .gjJrAPm5LdQRaaytJJR\\+kg\\=\\= {\r\n    font-weight: bold;\r\n    color: #777!important;\r\n    text-align: left;\r\n}\r\n\r\n#UQUBxMFInfu5nwU2YT2dJQ\\=\\= .nB3zAyj5LFCtLbHKwU\\+BxA\\=\\= {\r\n    padding: 8px 0px;\r\n}\r\n\r\n\r\n@media(min-width: 990px){\r\n    #UQUBxMFInfu5nwU2YT2dJQ\\=\\= .gjJrAPm5LdQRaaytJJR\\+kg\\=\\= {\r\n        text-align: right;\r\n    }\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/home/pages/newagent-signon-checksheet-page/newAgentSignOnChecksheetStyles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["#newagent-signon-checksheet-page .text-bold {\r\n    font-weight: bold;\r\n    color: #777!important;\r\n    text-align: left;\r\n}\r\n\r\n#newagent-signon-checksheet-page .d-item {\r\n    padding: 8px 0px;\r\n}\r\n\r\n\r\n@media(min-width: 990px){\r\n    #newagent-signon-checksheet-page .text-bold {\r\n        text-align: right;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newagent-signon-checksheet-page": "UQUBxMFInfu5nwU2YT2dJQ==",
	"text-bold": "gjJrAPm5LdQRaaytJJR+kg==",
	"d-item": "nB3zAyj5LFCtLbHKwU+BxA=="
};
export default ___CSS_LOADER_EXPORT___;
