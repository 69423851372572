import dataModel from 'data-model';
import { Observable } from 'knockout';
import { showmustdismiss } from 'show-dialog-methods';
import userprofile from 'user-profile';
import template from './order-entry-advanced-location-search.html';

class AdvancedLocationSearchViewModel {
    grid: jqwidgets.jqxGrid;
    refLocationId: Observable<string>
    windowModel: any;

    constructor(data) {
        this.refLocationId = data.refLocationId;
        this.windowModel = data.windowModel;
        this.buildGrid();
        userprofile.getWarningMessageStatus("AdvancedLocationSearchEnterButton", "New Order")
        .then((x) => {
            if(!x) {
                return showmustdismiss("The advanced search option has changed. You must enter your search criteria and then select the Search button to execute a search now.")
            }
        })
        .then((v) => {
            if(v) {
                return userprofile.acknowledgeWarningMessage("AdvancedLocationSearchEnterButton", "New Order")
            }
        })
    }

    performLocationSearch = () => {
        this.grid.source = this.dataAdapter;
        this.grid.updatebounddata('data');
    }

    buildGrid() {
        this.grid = jqwidgets.createInstance("#jqxLocations", "jqxGrid", this.gridOptions)
    }

    get dataAdapter() {
        return dataModel.getDataAdapter(this.source)
    }

    cellsrenderer = (row, columnfield, value, defaultHTML, column, rowData) => {
        return "<img src='../Content/Images/" + value +"star.png' />";
    }

    get gridOptions() {
        return {
            width: '100%',
            selectionmode: 'single',
            sortable: true,
            pageable: true,
            filterable: true,
            columnsmenu: false,
            showfilterrow: true,
            autoheight: true,
            virtualmode: true,
            rendergridrows: function (obj) {
                return obj.data;
            },
            handlekeyboardnavigation: (event: any) => {
                if(event.keyCode == 13) {
                    this.performLocationSearch();
                    return true;
                } else {
                    return false;
                }
            },
            columnsresize: true,
            columns: [
                {
                    text: '', showheader: false, width: '150px', filterable: false, columntype: 'button', sortable: false,
                    cellsrenderer: function () {
                        return "Select and Close";
                    },
                    buttonclick: (row) => {
                        var datarow = $("#jqxLocations").jqxGrid('getrowdata', row);
                        this.refLocationId(datarow.locationCode);
                        $('div[data-id="advancedLocationSearchModal"]').modal('hide');
                    }
                },
                { text: 'Location Code', datafield: 'locationCode', width: '120px' },
                { text: 'Name', datafield: 'name', width: '200px' },
                { text: 'Address', datafield: 'address', width: '180px' },
                { text: 'City', datafield: 'city', width: '120px' },
                { text: 'State', datafield: 'state', width: '50px' },
                { text: 'Zip', datafield: 'zip', width: '70px' },
                { text: 'Rating', datafield: 'rating', cellsrenderer: this.cellsrenderer, width: '120px' }
            ]
        } as jqwidgets.GridOptions
    };


    get source() {
        return {
            url: "Location/GetLocationsForGrid",
            datatype: "json",
            sortcolumn: "rating",
            sortdirection: "desc",
            data: {
                agencyId:dataModel.currentAgencyId
            },
            filter: function (items) {

            },
            sort: function (column, sortOrder) {
                $("#jqxLocations").jqxGrid('updatebounddata', 'sort');
            },
            beforeprocessing: (data) => {
                (this.source as any).totalrecords = data.totalrecords;
            },

            formatdata: function (data) {
                let columns = new Array();

                data = {
                    locationcode: data.locationcode,
                    name: data.name,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    rating: data.rating,
                    zip: data.zip,
                    agencyId:dataModel.currentAgencyId,
                    recordstartindex: data.recordstartindex,
                    recordendindex: data.recordendindex,
                    pagesize: data.pagesize,
                    pagenum: data.pagenum,
                    sortdatafield: data.sortdatafield,
                    sortorder: data.sortorder,
                    columns: columns
                };

                let filterinfo = $("#jqxLocations").jqxGrid('getfilterinformation');
                for (let i = 0; i < filterinfo.length; i++) {
                    let filterName = filterinfo[i].datafield;

                    let filters = filterinfo[i].filter.getfilters();

                    for (let j = 0; j < filters.length; j++) {
                        if (filters.length > 1) {
                            data[filterName][j] = filters[j].value;
                        }
                        else {
                            data[filterName] = filters[j].value;
                        }
                    }
                }
                return data;
            },
            datafields: [
                { name: 'id', type: 'int' },
                { name: 'locationCode', type: 'string' },
                { name: 'name', type: 'string' },
                { name: 'address', type: 'string' },
                { name: 'city', type: 'string' },
                { name: 'state', type: 'string' },
                { name: 'zip', type: 'string' },
                { name: 'rating', type: 'int' }
            ]
        };
    }
}

export default {viewModel: AdvancedLocationSearchViewModel, template: template}