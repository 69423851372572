import dataModel from 'data-model';
import gridStateModel from "jqx.grid-state-component";
import gridUtils from "jqx.grid-utils";
import { mapGridDate, formatPhoneNumber, isEmpty, cleanString, datetimeUTC, groupBy } from 'global-functions';
import userProfile from 'user-profile';
import { GreatEdgeCustomWidgets, createGeDateRangeFilterWidget } from 'ge.custom-widgets';
import {useDispatch, useSelector } from 'ko-data-store';
import { getUserFilters, addUserFilters } from '../../../dataStore/actions/userFilters';
import { isLoading, getTrailerTypes } from 'dataStore-actions/appUI';
import { addLaneRate } from 'dataStore-actions/rates';
import useKoElementAsFilter from 'ge-widgets/useKoElementAsFilter';

const gridCustomFilters = function($gridEl) {
    const vm = this;
    const dispatch = useDispatch();

    dispatch(getTrailerTypes()).then(({appUI}) => vm.trailerTypes(groupBy('category')(appUI.trailerTypes.map(x => ({category: x.category, value: x.description, label: x.description})))));

    vm.trailerTypes = ko.observableArray([]);

    vm.trailerTypeDDLWidget = useKoElementAsFilter($('#ratesRatingPanelTrailerTypeFilter'));
    vm.trailerTypesSelectedItems = ko.observableArray([]);
    vm.trailerTypesSelectedItems.subscribe(items => {
        if(items && items.length) {
            const filters = vm.trailerTypeDDLWidget.addFilterToGrid($gridEl);
            filters({datafield: 'trailerType', values: items }).applyToGrid();
        }
        else {
            vm.trailerTypeDDLWidget.removeFilterFromGrid($gridEl)('trailerType');
        }
    })

    vm.rateNumbers = ko.observableArray([]);
    vm.rateNumbersDDLWidget = useKoElementAsFilter($('#ratesRatingPanelRateNumberFilter'));
    vm.rateNumbersSelectedItems = ko.observableArray([]);
    vm.rateNumbersSelectedItems.subscribe(items => {
        if(items && items.length) {
            const filters = vm.rateNumbersDDLWidget.addFilterToGrid($gridEl);
            filters({datafield: 'rateNumber', values: items }).applyToGrid();
        }
        else {
            vm.rateNumbersDDLWidget.removeFilterFromGrid($gridEl)('rateNumber');
        }
    })

    vm.customers = ko.observableArray([]);
    vm.customersDDLWidget = useKoElementAsFilter($('#ratesRatingPanelCustomerFilter'));
    vm.customersSelectedItems = ko.observableArray([]);
    vm.customersSelectedItems.subscribe(items => {
        if(items && items.length) {
            const filters = vm.customersDDLWidget.addFilterToGrid($gridEl);
            filters({datafield: 'customer', values: items }).applyToGrid();
        }
        else {
            vm.customersDDLWidget.removeFilterFromGrid($gridEl)('customer');
        }
    })

    vm.commodities = ko.observableArray([]);
    vm.commodityDDLWidget = useKoElementAsFilter($('#ratesRatingPanelCommodityFilter'));
    vm.commoditySelectedItems = ko.observableArray([]);
    vm.commoditySelectedItems.subscribe(items => {
        if(items && items.length) {
            const filters = vm.commodityDDLWidget.addFilterToGrid($gridEl);
            filters({datafield: 'commodity', values: items }).applyToGrid();
        }
        else {
            vm.commodityDDLWidget.removeFilterFromGrid($gridEl)('commodity');
        }
    })

    vm.getFilterValues = () => {
        return {
            trailerTypes: vm.trailerTypesSelectedItems(),
            customers: vm.customersSelectedItems(),
            commodities: vm.commoditySelectedItems(),
            rateNumbers: vm.rateNumbersSelectedItems()
        }
    }
}


const ratingPageViewModel = function() {
    const vm = this;
    const dispatch = useDispatch();
    
    const gridId = "rating-panel-grid";
    const $grid = $('#'+gridId);
    const geDateWidget = new GreatEdgeCustomWidgets();

    vm.popupWindow = ko.observable();

    // filter
    vm.customFilters = new gridCustomFilters($grid);

    const flags = {
        gridInit: false
    }

    const refreshGrid = () => {
        $grid.jqxGrid('updatebounddata', 'data')
    }

    const displayPopupWindow = (rateNumber, onCloseCallBackFn = function(){}) => {
        const inlineModel = function() {
            const v = this;
            v.rateNumber = ko.observable(rateNumber);
            v.onClose = () => onCloseCallBackFn();
        }

        vm.popupWindow(new inlineModel());
    }

    const buildGrid =  ($gridEl, source = {}, columns = [], userGridState = {}) => {
        const dataAdapter = dataModel.getDataAdapter(source);

        $gridEl.jqxGrid({
            theme: 'GWTMDark',
            width: "100%",
            source: dataAdapter,
            altrows: true,
            sortable: true,
            pageable: true,
            pagesize: 20,
            columnsreorder: true,
            columnsmenu: false,
            columnsresize: true,
            enablebrowserselection: true,
            height: 600,
            autoheight: false,
            filterable: true,
            showfilterrow: true,
            showtoolbar: true,
            columns: columns,
            virtualmode: false,
            rendertoolbar: (toolbar) => {
                const $toolbar = $(toolbar);
                const gridStateVM = new gridStateModel.viewModel();
                gridStateVM.pageName = "RatingPage";
                
                const actions = [...gridStateVM.actions, "Add New Rate Table"];
                gridStateVM.actions = actions;

                const $template = gridStateModel.template;
                const jqxGridHelper = geDateWidget.GEWidgetsGridHelper($gridEl.attr('id'), { validFilterValues: ["Both", "All"] });
                
                // const addNewRateTable = () => {
                //     const row = $gridEl.jqxGrid('getselectedrowindex');
                    
                //     if((row >= 0) == false) {
                //         alert(`Please select a row from the grid.`);
                //         return false;
                //     }

                //     const data = $gridEl.jqxGrid('getrowdata', row);

                //     if(data) {
                //         dispatch(addLaneRate(data.rateNumber, refreshGrid));
                //     }
                // }

                gridStateVM.extraActionHandlers = [
                    { action: "Add New Rate Table", handler: () => displayPopupWindow(null, refreshGrid) }
                ];

                gridStateVM.clearFilters = () => {
                    vm.customFilters.commoditySelectedItems([]);
                    vm.customFilters.trailerTypesSelectedItems([]);
                    vm.customFilters.rateNumbersSelectedItems([]);
                    vm.customFilters.customersSelectedItems([]);

                    jqxGridHelper.clearDateRangeFiltersFromGrid();

                    $gridEl.jqxGrid('clearfilters');
                }

                gridStateVM.setDefaultSearch = () => vm.customFilters.getFilterValues();

                gridStateVM.overrideCustomGridModalOptionsSave = () => {
                    const filters = gridStateVM.setDefaultSearch();
                    dispatch(addUserFilters($gridEl.attr('id'), gridStateVM.saveGridFilters($gridEl, filters)))
                }

                $toolbar.append($template);
                ko.applyBindingsToDescendants(gridStateVM, $toolbar[0]);
            },
            ready: () => {
                
                gridUtils.applyGridState($gridEl.attr('id'), userGridState.grid);

                if(userGridState.trailerTypes) vm.customFilters.trailerTypesSelectedItems(userGridState.trailerTypes);
                if(userGridState.customers) vm.customFilters.customers(userGridState.customers);
                if(userGridState.commodities) vm.customFilters.commodities(userGridState.commodities);
                if(userGridState.rateNumbers) vm.customFilters.rateNumbers(userGridState.rateNumbers);

                flags.gridInit = true;
            }, 
            rendergridrows: (obj) => {
                return obj.data;
            },
        });

        return $grid;
    }

    const gridSource = () => {
        return {
            url: "Rates",
            type: "GET",
            datafields: [
                { name: "id", type: "int" },
                { name: "rateNumber", type: "int" },
                { name: "customer", type: "string" },
                { name: "effectiveDate", type: "date" },
                { name: "expirationDate", type: "date" },
                { name: "apiTenderRateGWTM", type: "boolean" },
                { name: "commodity", type: "string" },
                { name: "trailerType", type: "string" },
                { name: "mode", type: "string" },
            ],
            datatype: "json",
            formatdata: (data = {}) => {
                data.agencyId = userProfile.currentAgencyId;
                return data;
            },
            beforeLoadComplete: (x, recordData = []) => {
                
                const rateNumbers = recordData.filter(x => x.rateNumber != undefined).map(x => ({label: x.rateNumber, value: x.rateNumber}));
                vm.customFilters.rateNumbers(rateNumbers);

                const customers = recordData.filter(x => x.customer != undefined).map(x => ({label: x.customer, value: x.customer})).reduce((data, customer) => {
                    if(data.some(x => x.label == customer.label) == false) data.push(customer);
                    return data;
                }, [{label: "DEFAULT", value: "DEFAULT"}]);

                vm.customFilters.customers(customers);

                const commodities = recordData.filter(x => x.commodity != undefined).map(x => ({label: x.commodity, value: x.commodity})).reduce((data, commodity) => {
                    if(data.some(x => x.label == commodity.label) == false) data.push(commodity);
                    return data;
                },[])

                vm.customFilters.commodities(commodities);

                return recordData.map(x => {
                    if(x.effectiveDate) {
                        const d = datetimeUTC(mapGridDate(x.effectiveDate));
                        x.effectiveDate = d.isValid() ? d.format("MM/DD/YYYY") : null;
                    }

                    if(x.expirationDate) {
                        const d = datetimeUTC(mapGridDate(x.expirationDate));
                        x.expirationDate = d.isValid() ? d.format("MM/DD/YYYY") : null;
                    }

                    // if(x.apiTenderRateGWTM != undefined) {
                    //     x.apiTenderRateGWTM = x.apiTenderRateGWTM ? "Y" : "N";
                    // }

                    // x.apiTenderRateGWTM = x.apiTenderRateGWTM == null ? "N" : x.apiTenderRateGWTM;

                    x.customer = x.customer == null ? "DEFAULT" : x.customer;

                    return x;
                });
            }
        };
    }

    const gridColumns = (gridName = "", initGridState = {}) => {
        return [
            { text: "", hidden: false, pinned: true, width: '80', menu: false, resizable: false, hideable: false, showheader: false, filterable: false, columnType: 'button',
                buttonclick: (rowIndex,event) => {
                    const data = $grid.jqxGrid('getrowdata', rowIndex) || {};
                    $grid.jqxGrid('clearselection');

                    displayPopupWindow(data.rateNumber, refreshGrid);
                },
                cellsrenderer: (row = {}, columnfield, value, defaulthtml, columnproperties) => {
                    return `View`;
                },
            },
            { text: "Rate Number", datafield: "rateNumber", minwidth: 200, createfilterwidget: vm.customFilters.rateNumbersDDLWidget.createfilterwidget($grid) },
            { text: "Customer", datafield: "customer", minwidth: 200, createfilterwidget: vm.customFilters.customersDDLWidget.createfilterwidget($grid) },
            { text: "Mode", datafield: "mode", minwidth: 150, filtertype: 'list', filteritems: [{html: "Both", label: null},{html: "LTL", label: "L"}, {html: "TL", label: "T"}],
                cellsrenderer: (row = {}, columnfield, value, defaulthtml, columnproperties) => {
                    const $defaultHtml = $(defaulthtml);

                    switch(value) {
                        case "L":
                            $defaultHtml.text('LTL');
                            break;
                        case "T":
                            $defaultHtml.text('TL');
                            break;
                        default:
                            $defaultHtml.text('Both');
                            break;
                    }
                    
                    return $defaultHtml[0].outerHTML;
                },
            },
            { text: "Effective Date", minwidth: 200, datafield: "effectiveDate", createfilterwidget: createGeDateRangeFilterWidget(geDateWidget, gridName, initGridState) },
            { text: "Expiration Date", minwidth: 200, datafield: "expirationDate", createfilterwidget: createGeDateRangeFilterWidget(geDateWidget, gridName,initGridState) },
            { text: "API Bids", datafield: "apiTenderRateGWTM", minwidth: 200, //filtertype: 'list', filteritems: ["All", "Y", "N"]
                filtertype: 'bool', // checkbox
                columntype: 'checkbox', 
                editable: false
            },
            { text: "Commodity", datafield: "commodity", minwidth: 250, createfilterwidget: vm.customFilters.commodityDDLWidget.createfilterwidget($grid) },
            { text: "Trailer Type", datafield: "trailerType", minwidth: 200, createfilterwidget: vm.customFilters.trailerTypeDDLWidget.createfilterwidget($grid) },
        ];
    }

    dispatch(getUserFilters(gridId, "RatingPage")).then(({userFilters}) => buildGrid($grid, gridSource(), gridColumns(gridId, userFilters.grid), userFilters[gridId]));
}

import template from './rating-page.html';
export default { viewModel: ratingPageViewModel, template: template }