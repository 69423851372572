import ko from 'knockout';
import dataModel from 'data-model';
import { scrollToRequiredField } from 'shared-components/global-functions';
import userProfile from 'user-profile';
import { showmessage } from 'show-dialog-methods';
import * as $ from 'jquery';
import dayjs from 'dayjs';

class ProjectPostingModal {
    constructor(stuff) {
        
        this.projectLoad = ko.observable();
        this.projectLoad.subscribe((val) => {
            if(val) $('#projectPostingModal').modal('show');
        })
        this._PPService = _PPService;
        
        this.expireLoad = () => {
            _PPService.ExpireLoad(this.projectLoad().loadId())
                .then(() => {
                this.projectLoad().expired(true);
                this.onLoadSave();
            });
        };
        this.saveLoad = (obj) => {
            var _a;
            delete obj.$parent;
            _PPService.errors([]);
            var validationErrors = ko.validation.group(obj, { deep: true });
            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                scrollToRequiredField();
                return false;
            }
            let tmpStops = new Array();
            tmpStops.push({
                id: this.projectLoad().isNewLoad() ? null : this.projectLoad().originStopId,
                locationId: this.projectLoad().originLocationId(),
                cityStateZip: this.projectLoad().originCityStateZip(),
                arrival: this.projectLoad().pickUpDate(),
                latest: this.projectLoad().pickUpLatestDate()
            });
            let data = {
                loadId: this.projectLoad().loadId(),
                trailerType: (_a = this.projectLoad().selectedEquipmentType().value) !== null && _a !== void 0 ? _a : this.projectLoad().selectedEquipmentType(),
                stops: tmpStops,
                lineHaul: this.projectLoad().lineHaul,
                commodityId: this.projectLoad().commodityId,
                commodityDescription: this.projectLoad().commodityDescription,
                hazmat: this.projectLoad().hazmat() == "Yes" ? true : false,
                isTeamRequired: this.projectLoad().isTeamRequired(),
                loadType: this.projectLoad().loadType(),
                weight: this.projectLoad().weight(),
                length: this.projectLoad().length(),
                height: this.projectLoad().height(),
                width: this.projectLoad().width(),
                carrierPay: this.projectLoad().carrierPay(),
                quantity: this.projectLoad().quantity(),
                internalComments: this.projectLoad().internalComments(),
                agencyComments: this.projectLoad().agencyComments(),
                createdFromOrderId: this.projectLoad().orderId(),
                postingSourceId: 4,
                destinationStates: this.projectLoad().selectedDestinationStates().map((x) => x.id)
            };
            if (this.projectLoad().isNewLoad()) {
                _PPService.PostLoad(data).then((loadId) => {
                    this.onLoadSave();
                });
            }
            else {
                _PPService.UpdateLoad(data).then((loadId) => {
                    this.onLoadSave();
                });
            }
        };
        this.cancelLoad = () => {
            $('#projectPostingModal').modal('hide');
        }
        stuff = stuff || {};
        const params = stuff.params;
        this.projectLoad(new ProjectLoad(this, params.loadId));
        this.onLoadSave = params.onLoadSave || function() {};
        this.onClose = params.onClose || function() {};
        stuff.projectLoad = this.projectLoad;

        $('#projectPostingModal').on('hide.bs.modal', () => {
            //$('.modal-backdrop.fade.in').remove();
            //$('body').removeClass('modal-open');

            this.onClose();

        });
    }
}
class ProjectLoad {
    constructor($parent, loadId) {
        //General
        this.originLocationId = ko.observable();
        this.originCityStateZip = ko.observable();
        this.pickUpDate = ko.observable();
        this.pickUpLatestDate = ko.observable();
        this.lineHaul = ko.observable();
        this.commodityId = ko.observable();
        this.selectedEquipmentType = ko.observable();
        this.destinationStates = ko.observableArray([]);
        this.selectedDestinationStates = ko.observableArray([]);
        this.totalCharge = ko.observable();
        this.commodityDescription = ko.observable();
        this.loadType = ko.observable();
        this.hazmat = ko.observable();
        this.isTeamRequired = ko.observable();
        this.weight = ko.observable();
        this.length = ko.observable();
        this.height = ko.observable();
        this.width = ko.observable();
        this.carrierPay = ko.observable();
        this.isNewLoad = ko.observable(true);
        this.quantity = ko.observable();
        this.internalComments = ko.observable();
        this.agencyComments = ko.observable();
        this.expired = ko.observable();
        this.loadId = ko.observable();
        this.orderId = ko.observable();
        this.orderStatus = ko.observable();
        this.initValidationStuff = () => {
            this.pickUpLatestDate.extend({
                validation: {
                    message: () => {
                        return "Latest date must be after " + dayjs(this.pickUpDate()).format(userProfile.dateTimeFormat);
                    },
                    validator: () => {
                        if (ko.unwrap(this.pickUpLatestDate) != null && ko.unwrap(this.pickUpDate) > this.pickUpLatestDate()) {
                            return false;
                        }
                        return true;
                    }
                }
            });
            this.pickUpDate.extend({
                required: {
                    message: "Pickup time is required"
                }
            });
            this.originCityStateZip.extend({
                required: {
                    message: "Origin city, state or zip is required"
                }
            });
            this.selectedEquipmentType.extend({ required: { message: 'Equipment type is required' } });
            this.saveButtonText = ko.computed(() => {
                if (_PPService.isLoading()) {
                    return "Saving...";
                }
                else if (this.isNewLoad() == false) {
                    return "Update";
                }
                else {
                    return "Save";
                }
            });
            this.originCSZReadOnly = ko.computed(() => {
                return this.originLocationId() != null;
            });
            this.destinationStates.extend({
                required: {
                    message: "At least 1 destination is required."
                }
            });
            this.pickUpDate.extend({
                required: {
                    message: "Pickup date is required"
                }
            });
            this.pickUpLatestDate.extend({
                validation: {
                    message: () => {
                        return "Latest date must be after " + dayjs(this.pickUpDate()).format(userProfile.dateTimeFormat);
                    },
                    validator: () => {
                        if (ko.unwrap(this.pickUpLatestDate) != null && ko.unwrap(this.pickUpDate) > this.pickUpLatestDate()) {
                            return false;
                        }
                        return true;
                    }
                },
                required: {
                    message: "Pickup date is required"
                }
            });
        };
        this.originLocationSelected = (item) => {
            if (item != undefined) {
                if (item.zip != null) {
                    this.originCityStateZip(item.zip + " (" + item.city + ", " + item.state + ")");
                }
                else {
                    this.originCityStateZip(item.city + ", " + item.state);
                }
            }
        };
        this.$parent = $parent;
        if (loadId) {
            _PPService.GetLoad(loadId)
                .then((data) => {
                this.createLoadObject(data);
            });
        }
        else {
            this.createLoadObject();
        }
        this.initValidationStuff();
    }
    createLoadObject(load) {
        load = load || {};
        this.originLocationId(load.originLocationId);
        this.originCityStateZip(load.originCityStateZip);
        this.pickUpDate(load.pickUpDate);
        this.pickUpLatestDate(load.pickUpLatestDate);
        this.lineHaul(load.lineHaul || 0);
        this.commodityId(load.commodity);
        this.selectedEquipmentType(load.trailerType);
        this.destinationStates(load.projectDestinationStates || []);
        this.commodityDescription();
        this.loadType(load.loadType || "T");
        this.hazmat(load.hazmat ? "Yes" : "No");
        this.isTeamRequired(load.isTeamRequired || false);
        this.weight(load.weight);
        this.length(load.length);
        this.height(load.height);
        this.width(load.width);
        this.isNewLoad(load.id == null);
        this.quantity(load.quantity != null ? load.quantity : 1);
        this.internalComments(load.internalComments);
        this.agencyComments(load.agencyComments);
        this.expired(load.expired || false);
        this.loadId(load.id);
        this.orderStatus(load.orderStatus);
        this.orderId(load.orderId);
        this.originStopId = load.originStopId;
    }
}
class PPService {
    constructor() {
        this.isLoading = ko.observable(false);
        this.isSaving = ko.observable(false);
        this.responseMessage = ko.observable();
        this.errors = ko.observableArray();
        this.UpdateLoad = (data) => {
            this.responseMessage(undefined);
            this.isLoading(true);
            return new Promise((resolve, reject) => {
                dataModel.ajaxRequest("FreightBoard/UpdateLoad/" + data.loadId, "post", data)
                    .done((data, status, xhr) => {
                    this.isLoading(false);
                    resolve(data);
                })
                    .fail((jqXHR, textStatus, errorThrown) => {
                    this.isLoading(false);
                    if (jqXHR.responseJSON != null) {
                        showmessage(jqXHR.responseJSON.message);
                    }
                    else {
                        showmessage("An unknown error occurred");
                    }
                    console.error({ errorThrown, jqXHR, textStatus });
                    reject();
                });
            });
        };
        this.GetLoad = (loadid) => {
            this.responseMessage(undefined);
            this.isLoading(true);
            return new Promise((resolve, reject) => {
                dataModel.ajaxRequest("OrderPlanning/GetLoad/" + loadid, "GET")
                    .done((data, status, xhr) => {
                    resolve(data);
                    this.isLoading(false);
                })
                    .fail((error, msg, d) => {
                    showmessage("An error has occurred.");
                    this.isLoading(false);
                });
            });
        };
        this.PostLoad = (data) => {
            this.responseMessage(undefined);
            this.isLoading(true);
            return new Promise((resolve, reject) => {
                dataModel.ajaxRequest("FreightBoard/PostLoad", "post", data)
                    .done((data, status, xhr) => {
                    this.isLoading(false);
                    resolve(data);
                })
                    .fail((jqXHR, textStatus, errorThrown) => {
                    this.isLoading(false);
                    if (jqXHR.responseJSON != null) {
                        showmessage(jqXHR.responseJSON.message);
                    }
                    else {
                        showmessage("An unknown error occurred");
                    }
                    console.error({ errorThrown, jqXHR, textStatus });
                    reject();
                });
            });
        };
        this.ExpireLoad = (loadId) => {
            this.isLoading(true);
            return new Promise((resolve, reject) => {
                dataModel.ajaxRequest("FreightBoard/ExpireLoad/" + loadId, "post")
                    .done((data, status, xhr) => {
                    this.responseMessage("Load has been expired");
                    _PPService.isLoading(false);
                    resolve();
                })
                    .fail((error, msg, d) => {
                    _PPService.isLoading(false);
                    _PPService.errors(["An unknown error occurred"]);
                    reject();
                });
            });
        };
    }
}
let _PPService = new PPService();

import template from './project-posting-component.html';
export default { viewModel: ProjectPostingModal, template: template };