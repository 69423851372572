import dataModel from 'data-model';
import gridUtils from 'jqx.grid-utils';
import GreatEdgeCustomWidgets from 'ge.custom-widgets';
import gridState from 'jqx.grid-state-component';
import userProfile from 'user-profile';
import dayjs from 'dayjs';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

class InspectionRequestParameters {
    constructor(x) {
        x = x || {};
    }
}

class State {
    constructor(x) {
        x = x || {};
    }
}

class ControlState {
    constructor(x) {

        x = x || {};
        this.displayName = x.displayName;
        this.visible = x.showOrHide == "Show";
    }
}

class InspectionDetails {
    constructor(parentobj, x) {
        var _this2 = this;

        this.inspection = ko.observable();
        this.inspectionId = ko.observable();
        this.inspectionViolationsGrid = null;
        this.notesGrid = null;
        this.newNoteModel = ko.observable();
        this.proofOfRepair = ko.observable();
        this.fsmIntervention = ko.observable();
        this.startTime = ko.observable();
        this.endTime = ko.observable();
        this.logReceived = ko.observable();
        this.fsm = ko.observable();
        this.fsmInterventionCompletionDate = ko.observable();
        this.hazmat = ko.observable();
        this.dvirReceived = ko.observable();
        this.fsmInterventionStatus = ko.observable();
        this.cleanInspection = ko.observable();
        this.originalInspection = ko.observable();

        this.createGrids = function () {
        _this2.createNotesGrid();

        _this2.createViolationsGrid();
        };

        this.createNotesGrid = function () {
        var datafields = [{
            name: "id",
            type: "number"
        }, {
            name: "comment",
            type: "string"
        }, {
            name: "enteredById",
            type: "string"
        }, {
            name: "enteredBy",
            type: "string"
        }, {
            name: "createdDate",
            type: "date"
        }, {
            name: "inspectionId",
            type: "number"
        }];
        var columns = [{
            text: "",
            columntype: "button",
            width: "50px",
            hidden: !_this2.parentObj.canAddEditNotes(),
            cellsrenderer: function cellsrenderer() {
            return "Edit";
            },
            buttonclick: function buttonclick(row) {
            var note = _this2.notesGrid.getrowdata(row);

            note.row = row;

            _this2.parentObj.showEditNoteModal(note);
            }
        }, {
            datafield: "comment",
                text: "Comment",
            width: '45%'
        }, {
            datafield: "enteredBy",
            text: "Entered By",
            width: "35%"
        }, {
            datafield: "createdDate",
            text: "Date",
            cellsformat: 'MM/dd/yyyy HH:mm',
            width: "150px"
        }];
        var source = {
            datafields: datafields,
            localdata: _this2.notesData
        };
        var dataAdapter = new $.jqx.dataAdapter(source);
        var gridOptions = {
            source: dataAdapter,
            width: "100%",
            altrows: true,
            sortable: true,
            autoheight: true,
            pageable: false,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            showtoolbar: true,
            autoshowfiltericon: true,
            columns: columns,
            rendertoolbar: function rendertoolbar(toolbar) {
            if (_this2.parentObj.canAddEditNotes()) {
                var div = $("<div style='padding: 4px 0 0 4px'>");
                div.html("<input type='button' style='width: 82px' data-bind='geButton: {}, click: showCreateNewNoteModal' value='New Note' />");
                toolbar.append(div);
                ko.applyBindingsToDescendants(_this2.parentObj, toolbar[0]);
            }
            }
        };
        _this2.notesGrid = jqwidgets.createInstance("#notesGrid", "jqxGrid", gridOptions);
        };

        this.createViolationsGrid = function () {
        var datafields = [{
            name: "unitType",
            type: "string"
        }, {
            name: "citation",
            type: "string"
        }, {
            name: "basic",
            type: "string"
        }, {
            name: "section",
            type: "string"
        }, {
            name: "description",
            type: "string"
        }, {
            name: "oos",
            type: "string"
        }, {
            name: "severity",
            type: "string"
        }, {
            name: "oosWeight",
            type: "string"
        }, {
            name: "timeWeight",
            type: "string"
        }, {
            name: "points",
            type: "string"
        }, {
            name: "assetNumber",
            type: "string"
        }, {
            name: "licenseNumber",
            type: "string"
        }, {
            name: "licenseState",
            type: "string"
        }, {
            name: "vin",
            type: "string"
        }, {
            name: "rowNumber",
            type: "string"
        }, {
            name: "violationId",
            type: "string"
        }, {
            name: "inspectionId",
            type: "string"
        }, {
            name: "driverExId",
            type: "string"
        }, {
            name: "driverName",
            type: "string"
        }, {
            name: "agencyExId",
            type: "string"
        }, {
            name: "fsm",
            type: "string"
        }, {
            name: "fsmIntervention",
            type: "string"
        }, {
            name: "fsmStatus",
            type: "string"
        }, {
            name: "postedDate",
            type: "string"
        }, {
            name: "inspectionDate",
            type: "string"
        }, {
            name: "inspection",
            type: "string"
        }, {
            name: "inspectionState",
            type: "string"
        }, {
            name: "inspectionLevel",
            type: "string"
        }, {
            name: "intermodalChassis",
            type: "string"
        }, {
            name: "driverStatus",
            type: "string"
        }, {
            name: "agencyStatus",
            type: "string"
        }, {
            name: "completed",
            type: "string"
        }];
        var columns = [{
            datafield: "unitType",
            text: "Unit Type",
            width: "100px"
        }, {
            datafield: "citation",
            text: "Citation",
            width: "100px"
        }, {
            datafield: "basic",
            text: "Basic",
            width: "190px"
        }, {
            datafield: "section",
            text: "Section",
            width: "100px"
        }, {
            datafield: "description",
            text: "Description",
            width: "350px"
        }, {
            datafield: "oos",
            text: "OOS",
            width: "100px",
            columntype: "checkbox"
        }, {
            datafield: "severity",
            text: "Severity",
            width: "100px"
        }, {
            datafield: "oosWeight",
            text: "OOS Weight",
            width: "100px"
        }, {
            datafield: "timeWeight",
            text: "Time Weight",
            width: "100px"
        }, {
            datafield: "points",
            text: "Points",
            width: "100px"
        }, {
            datafield: "assetNumber",
            text: "Asset Number",
            width: "100px"
        }, {
            datafield: "licenseNumber",
            text: "License Number",
            width: "150px"
        }, {
            datafield: "licenseState",
            text: "License State",
            width: "100px"
        }, {
            datafield: "vin",
            text: "VIN",
            width: "150px"
        }];
        var source = {
            datafields: datafields,
            localdata: _this2.inspectionViolationsData
        };
        var dataAdapter = new $.jqx.dataAdapter(source);
        var gridOptions = {
            source: dataAdapter,
            width: "100%",
            altrows: true,
            sortable: true,
            autoheight: true,
            pageable: false,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            autoshowfiltericon: true,
            columns: columns
        };
        _this2.inspectionViolationsData = jqwidgets.createInstance("#inspectionViolationsGrid", "jqxGrid", gridOptions);
        };

        this.parentObj = parentobj;
        x = x || {};
        this.inspection(x.inspection);
        this.inspectionId(x.inspection.inspection);
        this.notesData = new $.jqx.observableArray(x.notes);
        this.inspectionViolationsData = new $.jqx.observableArray(x.inspectionViolations);
        this.proofOfRepair(x.inspection.proofOfRepair);
        this.fsmIntervention(x.inspection.fsmIntervention);
        this.startTime(x.inspection.startTime);
        this.logReceived(x.inspection.logReceived);
        this.fsm(x.inspection.fsm);
        this.endTime(x.inspection.endTime);
        this.fsmInterventionCompletionDate(x.inspection.fsmInterventionCompletionDate ? new Date(x.inspection.fsmInterventionCompletionDate).toLocaleString() : "To be determined");
        this.hazmat(x.inspection.hazmat == true ? "Yes" : "No");
        this.dvirReceived(x.inspection.dvirReceived);
        this.fsmInterventionStatus(x.inspection.fsmInterventionStatus);
        this.cleanInspection(x.inspection.cleanInspection);
        this.originalInspection(x.inspection.originalInspection);
    }
}

class NoteModel {
    constructor(x) {
        this.id = ko.observable();
        this.comment = ko.observable().extend({
            required: true
        });
        this.enteredById = ko.observable();
        this.enteredBy = ko.observable();
        this.createdDate = ko.observable();
        this.inspectionId = ko.observable();
        this.row = ko.observable();
        x = x || {};
        this.id(x.id);
        this.comment(x.comment);
        this.enteredById(x.enteredById);
        this.enteredBy(x.enteredBy);
        this.createdDate(x.createdDate);
        this.inspectionId(x.inspectionId);
        this.row(x.row);
    }
}

class InspectionLookupViewModel {
    constructor() {
        var _this = this;
  
          var GECustomWidgets = new GreatEdgeCustomWidgets();
          var _jqxGridHelper = GECustomWidgets.GEWidgetsGridHelper('inspectionGrid');
  
        this.inspectionGrid = null;
        this.isLoading = ko.observable(false);
        this.uploadResultMessage = ko.observable();
        this.newNoteModel = ko.observable();
        this.canViewInspectionNotes = ko.observable(false);
        this.canExportInspections = ko.observable(false);
        this.canImportInspections = ko.observable(false);
        this.canAddNewInspection = ko.observable(false);
        this.canCreateDataQChallenge = ko.observable(false);
        this.canAddEditNotes = ko.observable(false); //Skip the initial grid load b/c when it gets to pulling the filtering information out of the grid in formatdata it thows an error b/c the grid == null
  
        this.initialGridLoad = true;
        this.inspectionDetails = ko.observable();
  
        this.initializeComponents = function () {
          _this.isLoading(true);
  
          _this.requestParameters = new InspectionRequestParameters();
          Promise.all([//_this.getStatesAndProvinces(), 
                _this.getUserFiltersAndGridState(), 
                _this.getSecurableControlStates(),
                _this.getUserRoles()
            ]).then(function (value) {
            //_this.states = value[0]; // this.inspectionData = new $.jqx.observableArray(value[1]);
  
            _this.savedGridState = value[0] ? JSON.parse(value[0]) : null;
  
            _this.initializeGrid();
  
            _this.defaultState = _this.inspectionGrid.getstate();
  
            _this.isLoading(false);
  
            _this.configurePageSecurity(value[2].roles, value[1]);
          });
        };
  
        this.configurePageSecurity = function (roles, controls) {
          _this.canViewInspectionNotes(controls.find(function (x) {
            return x.displayName == "Inspection Notes";
          }) ? controls.find(function (x) {
            return x.displayName == "Inspection Notes";
          }).visible : false);
  
          _this.canExportInspections(controls.find(function (x) {
            return x.displayName == "Export Inspections";
          }) ? controls.find(function (x) {
            return x.displayName == "Export Inspections";
          }).visible : false);
  
          _this.canImportInspections(controls.find(function (x) {
            return x.displayName == "Import Inspection";
          }) ? controls.find(function (x) {
            return x.displayName == "Import Inspection";
          }).visible : false);
  
          if (roles.indexOf("Safety Manager") > -1) {
            _this.canViewInspectionNotes(true);
  
            _this.canExportInspections(true);
  
            _this.canImportInspections(true);
  
            _this.canAddNewInspection(true);
  
            _this.canExportInspections(true);
  
            _this.canAddEditNotes(true);
          }
  
          if (roles.indexOf("Administrators") > -1) {
            _this.canViewInspectionNotes(true);
  
            _this.canExportInspections(true);
  
            _this.canImportInspections(true);
  
            _this.canAddNewInspection(true);
  
            _this.canCreateDataQChallenge(true);
  
            _this.canAddEditNotes(true);
          }
  
          if (roles.indexOf("Field Safety Manager") > -1) {
            _this.canViewInspectionNotes(true);
  
            _this.canExportInspections(true);
  
            _this.canCreateDataQChallenge(true);
  
            _this.canAddEditNotes(true);
          }
  
          if (roles.indexOf("Safety Employee") > -1) {
            _this.canViewInspectionNotes(true);
  
            _this.canExportInspections(true);
  
            _this.canAddNewInspection(true);
  
            _this.canAddEditNotes(true);
          }
        };
  
        this.getUserRoles = function () {
          return new Promise(function (resolve, reject) {
            userProfile.getUserInfo().done(function (value) {
              resolve(value);
            });
          });
        };
  
        this.getSecurableControlStates = function () {
          return new Promise(function (resolve, reject) {
            dataModel.ajaxRequest("Inspection/GetSecurableControlStates", "GET", {
              pageName: "Inspection Lookup"
            }).done(function (x) {
              resolve(x.map(function (y) {
                return new ControlState(y);
              }));
            });
          });
        };
  
        this.getUserFiltersAndGridState = function () {
          return new Promise(function (resolve, reject) {
            dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", {
              name: "inspectionGrid",
              pageName: window.location.pathname.replace("/", "")
            }).done(function (data) {
              resolve(data);
            }).fail(function () {
              reject();
            });
          });
        };
  
        // this.getStatesAndProvinces = function () {
        //   return new Promise(function (resolve, reject) {
        //     dataModel.ajaxRequest("Location/GetStatesAndProvinces", "GET", {}).done(function (value) {
        //       var states = value.filter(function (x) {
        //         return x.country == "USA";
        //       }).map(function (x) {
        //         return x.code;
        //       });
        //       resolve(states);
        //     });
        //   });
        // };
  
        this.uploadFile = function () {
          // self.errors.removeAll();
          _this.isLoading(true);
  
          var files = $("#fileUpload").get(0).files;
          var formData = new FormData(); // file selected by user - upload file and then add/update document data
  
          var file = files[0];
          formData.append('UploadedFile', file);
          dataModel.ajaxUploadRequest("Inspection/ImportInspection", "POST", formData).done(function (results, status, xhr) {
            _this.uploadResultMessage(results);
  
            _this.isLoading(false);
          }).fail(function (jqXHR, textStatus, errorThrown) {
            _this.uploadResultMessage(errorThrown);
  
            _this.isLoading(false);
          });
  
          _this.isLoading(false);
        };
  
        this.addDefaultFiltersToDefaultState = function(defaultState) {
            defaultState.filters = {
              completedoperator: "and",
              filtervalue0: "Not Completed",
              filtercondition0: "EQUAL",
              filteroperator0: 1,
              filterdatafield0: "completed",
              filtertype0: "stringfilter",
              filterscount: 1
            };
            return defaultState;
          }

        this.trueFalseText = function(row, columnsfield, value, defaultHtml, columnproperties, rowdata) {
            var cell = $(defaultHtml);
    
            if (cell[0].innerText == "false") {
              cell[0].innerText = "False";
            } else if (cell[0].innerText == "true") {
              cell[0].innerText = "True";
            }
    
            return cell[0].outerHTML;
        }

        this.showInspectionDetailModal = function (data) {
          dataModel.ajaxRequest("Inspection/InspectionDetails", "POST", data.id).done(function (value) {
            value.inspection = data;
            var inspdeets = new InspectionDetails(_this, value);
  
            _this.inspectionDetails(inspdeets);
  
            inspdeets.createGrids();
          });
        };
  
        this.initializeGrid = function () {
          var gridSource = {
            url: "Inspection/GetInspectionsForGrid",
            datatype: "json",
            type: "POST",
            filter: function filter(filters, recordsArray) {
              _this.inspectionGrid.updatebounddata("filter");
            },
            sort: function sort(column, direction) {
              _this.inspectionGrid.updatebounddata("sort");
            },
            datafields: [{
              name: "detailsButton",
              type: "string"
            }, {
              name: "id",
              type: "string"
            }, {
              name: "driverExId",
              type: "string"
            }, {
              name: "tractorExId",
              type: "string"
            }, {
              name: "tractorVIN",
              type: "string"
            }, {
              name: "trailerExId",
              type: "string"
            }, {
              name: "driverName",
              type: "string"
            }, {
              name: "agencyExId",
              type: "string"
            }, {
              name: "fsm",
              type: "string"
            }, {
              name: "postedDate",
              type: "date"
            }, {
              name: "inspectionDate",
              type: "date"
            }, {
              name: "inspection",
              type: "string"
            }, {
              name: "inspectionState",
              type: "string"
            }, {
              name: "inspectionLevel",
              type: "string"
            }, {
              name: "fsmInterventionStatus",
              type: "string"
            }, {
              name: "fsmIntervention",
              type: "bool"
            }, {
              name: "oos",
              type: "bool"
            }, {
              name: "intermodalChassis",
              type: "bool"
            }, {
              name: "completed",
              type: "string"
            }, {
              name: "totalPoints",
              type: "string"
            }, {
              name: "hazmat",
              type: "bool"
            }, {
              name: "startTime",
              type: "string"
            }, {
              name: "endTime",
              type: "string"
            }, {
              name: "driverFitnessPoints",
              type: "string"
            }, {
              name: "drugAlcoholPoints",
              type: "string"
            }, {
              name: "fatiguePoints",
              type: "string"
            }, {
              name: "hazmatPoints",
              type: "string"
            }, {
              name: "vehicleMaintenancePoints",
              type: "string"
            }, {
              name: "unsafeDrivingPoints",
              type: "string"
            }, {
              name: "proofOfRepair",
              type: "bool"
            }, {
              name: "logReceived",
              type: "bool"
            }, {
              name: "originalInspection",
              type: "bool"
            }, {
              name: "dvirReceived",
              type: "bool"
            }, {
              name: "cleanInspection",
              type: "bool"
            }, {
              name: "fsmInterventionCompletionDate",
              type: "date"
            }],
            formatdata: function formatdata(data) {
              if (_this.initialGridLoad) {
                //This adds the agencyId to the request
                data = gridUtils.formatGridFilters({}, data);
                data = _this.addRequestParameters(data);
              } else {
                var filters = _this.inspectionGrid.getfilterinformation();
  
                  data = gridUtils.formatGridFilters(filters, data);
                  data = _jqxGridHelper.addGridFiltersToDataObj(data, { dateRangePostFixes: ['Start', 'End'] });
              }
  
              return data;
            }
          };
          var dataAdapter = dataModel.getDataAdapter(gridSource);
          var gridOptions = {
            source: dataAdapter,
            width: "100%",
            altrows: true,
            sortable: true,
            autoheight: true,
            pageable: true,
            pagesize: 10,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            showtoolbar: true,
            filterable: true,
            showfilterrow: true,
            toolbarheight:70,
            rendertoolbar: _this.rendertoolbar,
            autoshowfiltericon: true,
            virtualmode: true,
            rendergridrows: function rendergridrows(obj) {
              return obj.data;
            },
            columns: _this.columns
          };
          _this.inspectionGrid = jqwidgets.createInstance("#inspectionGrid", "jqxGrid", gridOptions);
  
          _this.inspectionGrid.addEventHandler("bindingcomplete", function (event) {
            if (_this.initialGridLoad) {
              _this.initialGridLoad = false;
  
              var defaultState = _this.inspectionGrid.getstate();
  
              defaultState.sortcolumn = "postedDate";
              defaultState.sortdirection = {
                ascending: false,
                descending: true
              };
              _this.defaultState = _this.addDefaultFiltersToDefaultState(defaultState);
  
              _this.addSavedFiltersToGridUI();
  
              _this.applySavedColumnStates();
            }
          });
        };
  
  
          this.geDateRangeFilterWidget = function (column, columnElement, widget) {
              //set widget (class) level options
  
              var options = {
                  gridOptions: {
                      gridWidget: widget,
                      gridId: 'inspectionGrid', // required to apply gridoptions
                      columnName: column.datafield, // also required, especially if this is a filter widget
                      isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
                      state: _this.savedGridState ? _this.savedGridState.grid : null // can send our grid state and apply any initial filters
                  }
              }
  
              return GECustomWidgets.GEDateRangePickerWidget(options); // will return widget if already exists or creates a new one;
  
          };
  
        this.columns = [{
          width: "100px",
          text: "",
          filterable: false,
          columntype: "button",
          pinned: true,
          datafield: "detailsButton",
          cellsrenderer: function cellsrenderer() {
            return "Details";
          },
          buttonclick: function buttonclick(row) {
            var insp = _this.inspectionGrid.getrowdata(row);
  
            _this.showInspectionDetailModal(insp);
          }
        }, // { datafield: "id", text: "string" },
        {
          datafield: "inspection",
          text: "Roadside",
          width: "125px",
          cellsrenderer: function cellsrenderer(row, columnfield, value, defaultHtml, columnproperties, rowdata) {
            var cell = $(defaultHtml);
            cell[0].innerHTML = "<a style='color: blue;' target='_blank' href='" + "/RoadsideEntry?id=" + rowdata.id + "'>" + cell[0].innerText + "</a>";
            return cell[0].outerHTML;
          }
        }, {
          datafield: "driverExId",
          text: "Driver Id",
          width: "100px"
        }, {
          datafield: "driverName",
          text: "Name",
          width: "145px"
        }, {
          datafield: "agencyExId",
          text: "Agency",
          width: "100px"
        }, {
          datafield: "fsm",
          text: "FSM",
          width: "100px"
        }, {
          datafield: "tractorExId",
          text: "Tractor",
          width: "100px"
        }, {
          datafield: "tractorVIN",
          text: "Tractor VIN",
          width: "155px"
        }, {
          datafield: "trailerExId",
          text: "Trailer",
          width: "160px"
        }, {
          datafield: "postedDate",
          text: "Posted Date",
          cellsformat: "MM/dd/yyyy",
          width: "200px",
          //filtertype: "date"
            createfilterwidget: _this.geDateRangeFilterWidget
        }, {
          datafield: "inspectionDate",
          text: "Inspection Date",
          cellsformat: "MM/dd/yyyy",
          width: "200px",
          //filtertype: "range"
            createfilterwidget: _this.geDateRangeFilterWidget
        }, {
          datafield: "inspectionState",
          text: "Inspection State",
          width: "135px",
        //   filtertype: "list",
        //   filteritems: this.states
        }, {
          datafield: "inspectionLevel",
          text: "Inspection Level",
          width: "275px",
          filtertype: "list",
          filteritems: [//label doesn't mean the label here.
          {
            value: "1",
            label: "1",
            html: "Level 1 – Complete Roadside"
          }, {
            value: "2",
            label: "2",
            html: "Level 2 – Walk Around"
          }, {
            value: "3",
            label: "3",
            html: "Level 3 – Driver Only"
          }, {
            value: "4",
            label: "4",
            html: "Level 4 – Specialized Inspection Only"
          }, {
            value: "5",
            label: "5",
            html: "Level 5 – Vehicle Only"
          }]
        }, {
          datafield: "fsmInterventionStatus",
          text: "FSM Intervention Status",
          width: "170px",
          filtertype: "list",
          filteritems: [{
            value: "New",
            label: "New"
          }, {
            value: "In Process",
            label: "In Process"
          }, {
            value: "Follow Up",
            label: "Follow Up"
          }, {
            value: "Complete",
            label: "Complete"
          }]
        }, {
          datafield: "oos",
          text: "OOS",
          width: "100px",
          columntype: "checkbox",
          filtertype: "bool"
        }, {
          datafield: "intermodalChassis",
          text: "Intermodal Chassis",
          width: "135px",
          columntype: "checkbox",
          filtertype: "bool"
        }, {
          datafield: "totalPoints",
          text: "Total Points",
          width: "100px",
          filterable: false
        }, {
          datafield: "completed",
          text: "Completed",
          width: "135px",
          filtertype: "list",
          filteritems: ["Not Completed", "Completed"]
        }, {
          datafield: "proofOfRepair",
          text: "Proof Of Repair",
          width: "120px",
          filterable: false,
          cellsrenderer: this.trueFalseText
        }, {
          datafield: "logReceived",
          text: "Log Recieved",
          width: "100px",
          filterable: false,
          cellsrenderer: this.trueFalseText
        }, {
          datafield: "originalInspection",
          text: "Original Inspection",
          width: "130px",
          filterable: false,
          cellsrenderer: this.trueFalseText
        }, {
          datafield: "dvirReceived",
          text: "DVIR Recieved",
          width: "120px",
          filterable: false,
          cellsrenderer: this.trueFalseText
        }, {
          datafield: "cleanInspection",
          text: "Clean Inspection",
          width: "120px",
          filterable: false,
          cellsrenderer: this.trueFalseText
        }];
  
        this.applySavedColumnStates = function () {
          if (_this.savedGridState && _this.savedGridState.grid && _this.savedGridState.grid.columns) {
            _this.columns.forEach(function (element) {
              if (_this.savedGridState.grid.columns[element.datafield]) {
                _this.inspectionGrid.setcolumnindex(element.datafield, _this.savedGridState.grid.columns[element.datafield].index);
  
                if (_this.savedGridState.grid.columns[element.datafield].hidden) {
                  _this.inspectionGrid.hidecolumn(element.datafield);
                }
  
                _this.inspectionGrid.setcolumnproperty(element.datafield, "width", _this.savedGridState.grid.columns[element.datafield].width);
              }
            });
          }
        };
  
        
        this.addSavedFiltersToGridUI = function () {
          if (_this.savedGridState && _this.savedGridState.grid && _this.savedGridState.grid.filters) {
            var filterIndexAccessors = [];
            var dateRangeFilters = [];
  
            for (var property in _this.savedGridState.grid.filters) {
              var lastChar = /.$/.exec(property)[0];
              if (!isNaN(lastChar) && filterIndexAccessors.indexOf(lastChar) == -1) filterIndexAccessors.push(lastChar);
            }
  
            filterIndexAccessors.forEach(function (value, index, array) {
              var dataField = _this.savedGridState.grid.filters["filterdatafield" + value];
              var filterValue = _this.savedGridState.grid.filters["filtervalue" + value];
              var filterCondition = _this.savedGridState.grid.filters["filtercondition" + value];
              var filtertype = _this.savedGridState.grid.filters["filtertype" + value];
  
              if (filtertype == "datefilter" && filterCondition != "EQUAL") {
                dateRangeFilters.push({
                  dataField: dataField,
                  filterAccessor: value,
                  filterValue: filterValue,
                  filterCondition: filterCondition
                });
              } else {
                var filtergroup1 = new $.jqx.filter();
                var filter1 = filtergroup1.createfilter('stringfilter', filterValue, "equal");
                filtergroup1.addfilter(1, filter1);
  
                _this.inspectionGrid.addfilter(dataField, filtergroup1, false);
              }
            });
  
            var uniqueDataFields = _toConsumableArray(new Set(dateRangeFilters.map(function (x) {
              return x.dataField;
            })));
  
            uniqueDataFields.forEach(function (value, index, array) {
              var min = dateRangeFilters.find(function (x) {
                return x.dataField == value && x.filterCondition == "GREATER_THAN_OR_EQUAL";
              }).filterValue;
              var max = dateRangeFilters.find(function (x) {
                return x.dataField == value && x.filterCondition == "LESS_THAN_OR_EQUAL";
              }).filterValue;
              var filtergroup = new $.jqx.filter();
              var filter1 = filtergroup.createfilter('stringfilter', min, "GREATER_THAN_OR_EQUAL");
              filtergroup.addfilter(1, filter1);
              var filter2 = filtergroup.createfilter('stringfilter', max, "LESS_THAN_OR_EQUAL");
              filtergroup.addfilter(1, filter2);
  
              _this.inspectionGrid.addfilter(value, filtergroup, false);
            });
  
            _this.inspectionGrid.refreshfilterrow();
          }
        };
  
        this.addRequestParameters = function (data) {
          if (_this.savedGridState && _this.savedGridState.grid && _this.savedGridState.grid.filters) {
            var filterIndexAccessors = [];
            var dateRangeFilterDataFields = [];
  
            for (var property in _this.savedGridState.grid.filters) {
              var lastChar = /.$/.exec(property)[0];
              if (!isNaN(lastChar) && filterIndexAccessors.indexOf(lastChar) == -1) filterIndexAccessors.push(lastChar);
            }
  
            filterIndexAccessors.forEach(function (value, index, array) {
              var filtertype = _this.savedGridState.grid.filters["filtertype" + value];
              var dataField = _this.savedGridState.grid.filters["filterdatafield" + value];
              var filterCondition = _this.savedGridState.grid.filters["filtercondition" + value];
              var filterValue = _this.savedGridState.grid.filters["filtervalue" + value];
  
              if (filtertype == "datefilter" && filterCondition != "EQUAL") {
                dateRangeFilterDataFields.push({
                  dataField: dataField,
                  filterAccessor: value,
                  filterValue: filterValue,
                  filterCondition: filterCondition
                });
              } else {
                data[dataField] = filterValue;
              }
            });
            dateRangeFilterDataFields.forEach(function (value, index, array) {
              if (!data[value.dataField]) {
                data[value.dataField] = [];
              }
  
              if (value.filterCondition == "GREATER_THAN_OR_EQUAL") {
                data[value.dataField][0] = value.filterValue;
              }
  
              if (value.filterCondition == "LESS_THAN_OR_EQUAL") {
                data[value.dataField][1] = value.filterValue;
              }
            });
          }
  
          if (_this.savedGridState && _this.savedGridState.grid && _this.savedGridState.grid.sortcolumn) {
            data.sortDataField = _this.savedGridState.grid.sortcolumn;
            data.sortOrder = _this.savedGridState.grid.sortdirection.ascending == true ? "asc" : "desc";
          }
  
          for(let key in data){
            if(data[key] == "Please Choose:") delete data[key];
          }

          return data;
        };
  
        this.rendertoolbar = function (toolbar) {
          //If the toolbar is loaded after the initial load, it throws an error.
          if (!toolbar.isLoaded) {
            var actionBarViewModel = new gridState.viewModel(),
                tmpl1 = gridState.template;

                actionBarViewModel.clearFilters = function () {
                    _jqxGridHelper.clearDateRangeFiltersFromGrid();
                    $('#inspectionGrid').jqxGrid('clearfilters');
                    //_this.inspectionGrid.loadstate(_this.defaultState);
                };
    
                var tmpl1jq = $(tmpl1);
                //tmpl1jq[2].style.display = "inline-block";
                $(toolbar).append(tmpl1jq);
                ko.applyBindingsToDescendants(actionBarViewModel, toolbar[0]);
                var inspectionGridtoolBarAddition = $("#inspectionGridtoolBarAddition");
                $(toolbar).append(inspectionGridtoolBarAddition);
                toolbar.isLoaded = true;
          }
        };
  
        this.exportInspections = function () {
          var filters = _this.inspectionGrid.getfilterinformation();
  
          _this.requestParameters = new InspectionRequestParameters();
          filters.forEach(function (element) {
            _this.requestParameters[element.datafield] = element.filter.getfilters()[0].value;
          });
          dataModel.downloadFile("Inspection/ExportInspections", "post", _this.requestParameters, "Inspections.xlsx");
        };
  
        this.noteHeader = ko.observable();
  
        this.showCreateNewNoteModal = function () {
          _this.noteHeader("New Note");
  
          var newNote = new NoteModel({
            inspectionId: _this.inspectionDetails().inspection().id
          });
  
          _this.newNoteModel(newNote);
        };
  
        this.showEditNoteModal = function (row) {
          _this.noteHeader("Edit Note");
  
          var newNote = new NoteModel(row);
  
          _this.newNoteModel(newNote);
        };
  
        this.submitNote = function (x) {
          if (ko.validation.group(x)().length > 0) {
            ko.validation.group(x).showAllMessages();
            return false;
          }
  
          var obj = {
            id: x.id(),
            comment: x.comment(),
            createdDate: new Date(),
            enteredBy: userProfile.userName,
            enteredById: x.enteredBy(),
            inspectionId: x.inspectionId()
          };
          dataModel.ajaxRequest("Inspection/SubmitNote", "POST", obj).done(function (v) {
            v.createdDate = dayjs(v.createdDate).format('MM/DD/YYYY HH:mm');
  
            if (obj.id) {
              _this.inspectionDetails().notesData[x.row()] = v;
            } else {
              _this.inspectionDetails().notesData.push(v);
            }
  
            _this.newNoteModel(null);
          });
        };
  
        this.initializeComponents();
    }
}

import template from './inspection-lookup-page.html';
export default { viewModel: InspectionLookupViewModel, template: template }