import userProfile from "user-profile";
import router from "router";
import {clearAutoCompleteCache} from '../ko-great-edge-components/ko.ge-auto-complete';

class CurrentAgencyDDLComponent {
    constructor(params) {
        params = params || {};
        let isExternalChange = false;

        this.userAgencies = ko.computed(function (x) {
            return userProfile.userAgencies() || [];
        });

        this.currentAgencyIdSelected = ko.observable(userProfile.currentAgencyId());
        this.currentAgencyIdSelected.subscribe((x) => {
            clearAutoCompleteCache();
            if (isExternalChange == false) userProfile.currentAgencyId(x);
            router.currentRoute(router.currentRoute());

            isExternalChange = false;
        });

        userProfile.currentAgencyId.subscribe((val) => {
            if (val != this.currentAgencyIdSelected()) {
                this.currentAgencyIdSelected(val);
                isExternalChange = true;
            }
        });
    }
}

import template from "./current-agency-ddl-component.html";
export default { viewModel: CurrentAgencyDDLComponent, template: template };
