import ko from "knockout";
import dataModel from "./data-model";
import * as $ from "jquery";
import { Base64 } from "./home/shared-components/global-functions";
import config from "config";
import logger from "logger";

class UserProfile {
    loggedIn = ko.observable(false);
    userProfileInitialized = ko.observable(false);
    currentUserMenus = ko.observable();
    allowedPages = ko.observable();
    apiUrl = config.apiUrl;
    userAgencyId;
    agencyExternalId;
    currentAgencyId = ko.observable();
    currentAgencyExId = ko.pureComputed(() => ((this.userAgencies() || []).find(({id}) => id == this.currentAgencyId()) || {})['externalID']);
    agencyRevGroupNumber = ko.pureComputed(() => ((this.userAgencies() || []).find(({id}) => id == this.currentAgencyId()) || {})['revGroupNumber']);
    agencyLinkCode = ko.pureComputed(() => ((this.userAgencies() || []).find(({id}) => id == this.currentAgencyId()) || {})['agencyLinkCode']);
    restrictedLoadboard;
    userId;
    userName;
    dateTimeFormat;
    jqxTimeFormat;
    isOwnerOperator;
    isGwEmployee;
    isUserDriver;
    isGwCarrierEntrant;
    userAgencies = ko.observableArray([]);
    externalBoards = [];
    userContactInfo = {};
    logOutEventCallBacks = [];
    availableNowSubscriber = ko.observable(false);
    previewUser;
    testUser;


    constructor() {
        if (this.getUserProfileFromLocalStorage() == true && this.getSecurityHeadersFromCookie() == true) {
            this.getCurrentUserMenus()
                .then(() => {
                    this.loggedIn(true);
                    this.userProfileInitialized(true);
                })
                .catch((code) => {
                    this.loggedIn(false);
                    this.userProfileInitialized(true);
                    this.clearAccessToken();
                })
                .finally(() => {
                    $("#mainContent").css({ display: "block" });
                });
        } else {
            this.loggedIn(false);
            this.userProfileInitialized(true);
            $("#mainContent").css({ display: "block" });
        }

        this.currentAgencyId.subscribe((val) => {
            if (val) this.handleCurrentAgencyChange(val);
        });
    }

    isSessionValid = () => {
        return window.localStorage.getItem("userprofile") != null;
    };

    // For now using window.location so it stops the execution of the redirecting page's viewmodel.
    notAuthorized = function (val) {
        val = typeof val == "boolean" ? val : true;
        if (val) {
            window.location.href = "NotAuthorized";
        }
    };

    roles = () => {
        return new Promise((resolve) => {
            dataModel.ajaxRequest("UserProfile/ActiveRoles", "GET").done((response) => resolve(response))
            .fail(() => resolve([]));
        });
    }

    getUserInfo = function () {
        return dataModel.ajaxRequest("Account/UserInfo");
    };

    clearAccessToken = function () {
        dataModel.clearSecurityHeaders();
        localStorage.removeItem("preventLogoutOnAgencyChange")
    };

    saveUserSavedSettings = function (pageName, name, settingJson) {
        var settingString = JSON.stringify(settingJson);
        var data = {
            name: name,
            pageName: pageName,
            settingJson: settingString,
        };
        return dataModel.ajaxRequest("UserProfile/SaveUserSavedSettings", "POST", data);
    };

    getUserSavedSettings = function (pageName, name) {
        return new Promise(function (resolve, reject) {
            dataModel
                .ajaxRequest("UserProfile/GetUserSavedSettings", "GET", {
                    pageName: pageName,
                    name: name,
                })
                .done(function (value) {
                    if (value) {
                        resolve(JSON.parse(value));
                    } else {
                        resolve();
                    }
                })
                .fail(function (value) {
                    reject(value);
                });
        });
    };

    login = (username, password, rememberMe, onErrorCb = (x) => {}) => {

        const data = { grant_type: "password", username: username, password: password };
        logger.logToServer(`${username} - Attempting to login at: 'GreatEdge'.`, { url: "Anonymous/LogUserAction" });

        return new Promise((resolve, reject) => {
            $.ajax({
                url: this.apiUrl + "Token",
                type: "POST",
                data: data,
                contentType: "application/x-www-form-urlencoded",
            })
            .done((token) => {
                if (token) {
                    dataModel.securityHeaders = {
                        Authorization: "Bearer " + token.access_token,
                    };
                    if (rememberMe) {
                        dataModel.setCookie("GreatwideAPItoken", token.access_token, token.expires_in);
                    } else {
                        dataModel.setCookie("GreatwideAPItoken", token.access_token);
                    }

                    logger.logToServer(`${username} Successfully logged into: 'GreatEdge'.`);

                    resolve(token.access_token);
                } else {
                    reject();
                }
            })
            .fail((x, y, z) => {
                
                if (x.responseJSON && x.responseJSON.error_description == "The user name or password is incorrect.") {
                    logger.logToServer(`${username} - Login attempt to 'GreatEdge' failed. (Invalid username or password)`, {url: "Anonymous/LogUserAction"});
                    reject("The user name or password is incorrect.");
                    
                } else if(x.responseJSON && x.responseJSON.error_description == "inactive_user") {
                    logger.logToServer(username + " - Login attempt failed. (Inactive user)", {url: "Anonymous/LogUserAction"});
                    reject("This account is currently inactive.  Please contact the GreatEdge Helpdesk if you believe this is in error");
                }
                else {
                    logger.logToServer(username + " - Login attempt failed. (Unknown reason)", {url: "Anonymous/LogUserAction"});
                    reject("Unknown error.");
                }
            });
        })
        .then((access_token) => {
            return this.getUserProfileData(access_token);
        })
        .then((stuff) => {
            this.setUserProfileData(stuff);
            return;
        })
        .then(this.getCurrentUserMenus)
        .then(() => {
            localStorage.removeItem("preventLogoutOnAgencyChange");
            
            let continueToSite = true;
            const host = window.location.host;
            const isDev = host.indexOf('vfgw-gedev') > -1 || host.indexOf('localhost') > -1;
            const onPreview = host.indexOf('preview') > -1;
            const onTest = host.indexOf('greatedgetest') > -1;

            // On preview site? Are they preview user?
            if(onPreview && this.previewUser === false) {
                continueToSite = false;
                onErrorCb(`https://www.great-edge.net`);
            }

            // On test site? Are they a test user?
            if(onTest && !this.testUser) {
                continueToSite = false;
                // If previewUser == true -> return preview url, otherwise use the regular greatedge one for now. 
                const correctSite = this.previewUser ? `https://preview.great-edge.net` : `https://www.great-edge.net`;
                onErrorCb(correctSite);
            }

            // Not on preview, test, or dev
            if(!onPreview && !onTest && !isDev) {
                // Are they a preview user? -> Return they should be on preview site.
                if(this.previewUser) {
                    continueToSite = false;
                    onErrorCb(`https://preview.great-edge.net`);
                }
            }

            // Allowed access
            if(continueToSite) {
                this.loggedIn(true);
                this.userProfileInitialized(true);
            }
            else {
                this.clearAccessToken();
                this.clearSession();
            }
            
        });
    };

    logOut = async () => {
        logger.logToServer(`${this.userName} - Has logged out of: 'GreatEdge'.`);

        await this._onLogOut();

        this.clearSession();
        this.clearAccessToken();
    };

    _onLogOut = () => {
        if(this.logOutEventCallBacks && Array.isArray(this.logOutEventCallBacks)) {
            for(let i = 0; i < this.logOutEventCallBacks.length; i++) {
                try {
                    this.logOutEventCallBacks[i]();
                }
                catch(err) {
                    console.error(`Error occurred while executing onLogOut callback.`);
                }
            }
        }

        return Promise.resolve(true);
    }

    // can add any callback function to perform any cleanup before session closes.
    onLogOut = (callBackFn, bindToThis) => {
        if(!bindToThis) throw new Error(`No 'this' binding provided for userProfile.onLogOut callback function.`);
        if(callBackFn && typeof callBackFn == 'function') this.logOutEventCallBacks.push(callBackFn.bind(bindToThis));
    }

    clearSession = () => {
        this.removeUserDataFromLocalStorage();
        this.loggedIn(false);
        this.currentUserMenus([]);
        this.allowedPages([]);
        this.userAgencyId = undefined;
        this.agencyExternalId = undefined;
        this.currentAgencyId(undefined);
        this.restrictedLoadboard = undefined;
        this.userId = undefined;
        this.userName = undefined;
        this.dateTimeFormat = undefined;
        this.jqxTimeFormat = undefined;
        this.isOwnerOperator = undefined;
        this.isGwEmployee = undefined;
        this.isUserDriver = undefined;
        this.isGwCarrierEntrant = undefined;
        this.userAgencies([]);
        this.externalBoards = [];
        this.userContactInfo = {};
        this.previewUser = undefined;
        this.testUser = undefined;
    };

    removeUserDataFromLocalStorage = () => {
        window.localStorage.removeItem("userprofile");
    };

    handleCurrentAgencyChange = (agencyId) => {
        const profile = this.getUserProfileDataFromLocalStorage(),
            setProfileOnly = profile.userAgencyId == agencyId,
            agencyInfo = profile.userAgencies.find((x) => x.id == agencyId);

        if (agencyInfo) {
            profile.userAgencyId = agencyInfo.id;
            profile.agencyExternalId = agencyInfo.externalID;

            this.setUserProfileData(profile, setProfileOnly);
        }
    };

    refreshUserCurrentAgency = () => {
        const profile = this.getUserProfileDataFromLocalStorage();
        if (this.currentAgencyId() != profile.userAgencyId) this.currentAgencyId(profile.userAgencyId);
    };

    // set user profile data to localstorage and viewmodel
    setUserProfileData = (userData, setProfileOnly) => {
        userData = userData || {};
        setProfileOnly = setProfileOnly || false;

        if (setProfileOnly == false) this.storeUserProfileToLocalStorage(userData);

        this.loadUserProfile(userData);
    };

    // Store userprofile data to local storage
    storeUserProfileToLocalStorage = (userData) => {
        if (userData && typeof userData == "object" && Object.keys(userData).length > 0) {
            try {
                let storeString = Base64.encode(JSON.stringify(userData));
                window.localStorage["userprofile"] = storeString;
            } catch (e) {
                console.error("Error occurred storing user profile data.");
            }
        }
    };

    getSecurityHeadersFromCookie = function () {
        let accessToken = dataModel.getCookie("GreatwideAPItoken");
        if (accessToken) {
            dataModel.securityHeaders = {
                Authorization: "Bearer " + accessToken,
            };
            return true;
        } else {
            return false;
        }
    };

    getUserAccessToken = function() {
        let accessToken = dataModel.getCookie("GreatwideAPItoken");
        return accessToken || "";
    }

    // Get userprofile data from local storage
    getUserProfileDataFromLocalStorage = () => {
        let profile = window.localStorage["userprofile"];

        if (profile) {
            try {
                let decodedString = Base64.decode(profile);
                profile = decodedString && JSON.parse(decodedString);
            } catch (e) {
                profile = null;
                console.error("Error occurred when parsing user profile data.");
            }
        }

        return profile || { userAgencies: [] };
    };

    getUserProfileFromLocalStorage = () => {
        let profile = window.localStorage["userprofile"];
        
        if (profile && this.getSecurityHeadersFromCookie()) {
            let decodedString = Base64.decode(profile);
            let userprofile = decodedString != "" ? JSON.parse(decodedString) : {};
            
            this.loadUserProfile(userprofile);
            return true;
        }
        return false;
    };

    getUserProfileData = (access_token) => {
        return $.ajax({
            url: this.apiUrl + "api/GreatEdge/UserProfile/GetUserProfile",
            headers: {
                Authorization: "Bearer " + access_token,
            },
            type: "GET",
            contentType: "application/json",
        });
    };

    loadUserProfile = (data) => {
        this.userAgencyId = data.userAgencyId;
        this.agencyExternalId = data.agencyExternalId;
        this.currentAgencyId(data.userAgencyId);
        this.restrictedLoadboard = data.restrictedLoadboard;
        this.userId = data.userId;
        this.userName = data.userName;
        this.dateTimeFormat = data.dateTimeFormat || "MM/DD/YYYY HH:mm";
        this.jqxTimeFormat = data.dateTimeFormat == "MM/DD/YYYY hh:mm A" ? "MM/dd/yyyy hh:mm tt" : "MM/dd/yyyy HH:mm";
        this.isOwnerOperator = data.isOwnerOperator;
        this.isGwEmployee = data.isGwEmployee;
        this.isUserDriver = data.isUserDriver;
        this.isGwCarrierEntrant = data.isGwCarrierEntrant;
        this.externalBoards = data.externalBoards;
        this.userContactInfo = data.userContactInfo;
        this.availableNowSubscriber(data.availableNowSubscriber);
        this.previewUser = data.previewUser;
        this.testUser = data.testUser;

        data.userAgencies = data.userAgencies || [];
        var _userAgencies = data.userAgencies.sort(function (a, b) {
            if (a.externalID < b.externalID) {
                return -1;
            }
            if (a.externalID > b.externalID) {
                return 1;
            }
            return 0;
        });

        dataModel.currentAgencyId = this.currentAgencyId;
        dataModel.dateTimeFormat = this.dateTimeFormat;
        this.userAgencies(_userAgencies);
    };

    getCurrentUserMenus = () => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("DefaultNew/GetCurrentUserMenus", "GET")
                .done((val) => {
                    this.currentUserMenus(val.currentUserMenus);
                    val.allowedPages = val.allowedPages.map((x) => x.toLowerCase());
                    this.allowedPages(val.allowedPages);
                    resolve(val);
                })
                .fail((err, a, b) => {
                    console.error(err);
                    reject(err.status);
                });
        });
    };

    hasPageAccess = function (pageName, accessType) {
        //Not being used currently
        accessType = accessType || "View";
        return new Promise(function (resolve, reject) {
            dataModel
                .ajaxRequest("User/VerifyUserPageAccess", "GET", {
                    pageName: pageName,
                    accessType: accessType,
                })
                .done(function (hasAccess) {
                    return resolve(hasAccess);
                })
                .catch(function (err) {
                    return reject(err);
                });
        });
    };

    updateUserProfile = (data = {}) => {
        const profile = this.getUserProfileDataFromLocalStorage();

        if(Object.keys((data || {})).length && Object.keys((profile || {})).length) {
            const updated = {...profile, ...data};
            this.setUserProfileData(updated);
        }
    }
    
    /**
     * Returns true if the warning message has been acknowledged
     * @param {string} item
     * @param {string} pageName
     */
    getWarningMessageStatus = (item, pageName) => {
        return dataModel.ajaxRequest("UserProfile/getWarningMessageStatus", "GET", {item: item, pageName: pageName})
    }
    acknowledgeWarningMessage = (item, pageName) => {
        return dataModel.ajaxRequest("UserProfile/acknowledgeWarningMessage", "GET", {item: item, pageName: pageName})
    }
}
let userprofile = new UserProfile();
export default userprofile;
