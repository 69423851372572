import dataModel from 'data-model';
import { useDispatch } from 'ko-data-store';
import { setLaneRate } from 'dataStore-actions/rates';
import { setBtnDisabledState, setBtnEnabledState } from 'global-functions';
import toast from 'geNotyf';

// const fetchRateAsync = (id) => {
//     return new Promise((resolve, reject) => {
//         dataModel.ajaxRequest("", "GET", {id: id}).done((data) => resolve(data))
//         .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while fething data.`));
//     })
// }

// const getRateAync = async (id) => await fetchRateAsync(id);

const fetchMileageRatesAysnc = () => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/MileageRates", "GET", null, true).done((response) => resolve(response))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while saving.`))
    })
}


const sendSaveRateAsync = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("Rates/LaneRate", "POST", payload).done((response) => resolve(response))
        .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred while saving.`))
    })
}

const saveRateAsync = async ({
    id = undefined,
    rateNumber = undefined,
    description = undefined,
    orgCode = undefined,
    origin = undefined,
    destCode = undefined,
    destination = undefined,
    method = undefined,
    rate = undefined,
    minCharge = undefined,
    pointToPoint = false,
    unitOfMeasure = undefined,
    distance,
    rateTableId
}) => await sendSaveRateAsync({
    id,
    rateNumber,
    description,
    orgCode,
    origin,
    destCode,
    destination,
    method,
    rate,
    minCharge,
    pointToPoint: (pointToPoint ? "Y" : "N"),
    unitOfMeasure,
    distance,
    rateTableId
});

export const rateModel = function({
    id = undefined,
    rateNumber = undefined,
    description = undefined,
    orgCode = undefined,
    origin = undefined,
    destCode = undefined,
    destination = undefined,
    method = undefined,
    rate = undefined,
    minCharge = undefined,
    pointToPoint = false,
    unitOfMeasure = undefined,
    distance = undefined,
    rateTableId = undefined
} = {}) {

    this.id = id;
    this.rateNumber = ko.observable(rateNumber);
    this.description = ko.observable(description).extend({maxLength: 60});
    this.orgCode = ko.observable(orgCode);
    this.origin = ko.observable(origin).extend({
        required: {
            onlyIf: () => {
                return this.orgCode() != undefined;
            }
        }
    });
    this.destCode = ko.observable(destCode);
    this.destination = ko.observable(destination).extend({
        required: {
            onlyIf: () => {
                return this.destCode() != undefined;
            }
        }
    });
    this.method = ko.observable(method).extend({required: true});
    this.rate = ko.observable(rate).extend({
        required: {
            onlyIf: () => {
                return this.method() != 'R';
            }
        }, 
        maxLength: 7
    });
    this.minCharge = ko.observable(minCharge).extend({min: 0.00});
    this.pointToPoint = ko.observable(pointToPoint);
    this.unitOfMeasure = ko.observable(unitOfMeasure);
    this.distance = ko.observable(distance);

    this.rateTableId = ko.observable(rateTableId).extend({
        required: {
            onlyIf: () => {
                return this.method() == 'R';
            }
        }
    });
}

const getMileageRatesAsync = async () => await fetchMileageRatesAysnc().catch(() => []);

export const rateEntryModalViewModel = function({
    rateData = undefined,
    onSave = function(x) {}
} = {}) {
    const vm = this;
    const dispatch = useDispatch();

    onSave = ko.isObservable(onSave) ? ko.unwrap(onSave) : onSave;

    vm.formData = ko.observable();
    vm.errors = ko.observableArray([]);

    const orgDestCodes = [
        { label: "City, State", value: "C" },
        { label: "Zip Code", value: "Z" },
        { label: "State", value: "S" },
        { label: "County", value: "N" },
        { label: "Location", value: "L" }
    ];

    vm.originFormatHelpText = ko.pureComputed(() => {
        switch(vm.formData().orgCode()) {
            case "C":
                return `Format: 'City Name, State'`;
            case "Z":
                return `Enter at minimum first 3 numbers or full zip code.`;
            case "S":
                return `Enter state abbreviation or full name`;
            case "N":
                return `Enter full county name.`;
            case "L":
                return `Enter the location code.`;
            default:
                return "";
        }
    })

    vm.destFormatHelpText = ko.pureComputed(() => {
        switch(vm.formData().destCode()) {
            case "C":
                return `Format: 'City Name, State'`;
            case "Z":
                return `Enter at minimum first 3 numbers or full zip code.`;
            case "S":
                return `Enter state abbreviation or full name`;
            case "N":
                return `Enter full county name.`;
            case "L":
                return `Enter the location code.`;
            default:
                return "";
        }
    })

    vm.orgCodeOptions = ko.observableArray(orgDestCodes);
    vm.destCodeOptions = ko.observableArray(orgDestCodes);

    vm.methodOptions = ko.observableArray([
        { label: "Flat", value: "F" },
        { label: "Distance", value: "D" },
        { label: "Hundred Weight (CWT)", value: "C" },
        { label: "Rate Table (Mileage Based)", value: "R" },
        { label: "Tons", value: "T" }
    ]);

    vm.unitOfMeasureOptions = ko.observableArray([
        { label: "CWT", value: "LB" },
        { label: "Tons", value: "T" },
        { label: "Miles", value: "MI" },
    ])

    vm.mileageRatesOptions = ko.observableArray([]);

    vm.handleSave = async (x, event) => {
        vm.errors.removeAll();

        const validationGrp = ko.validation.group(vm.formData);
        if(validationGrp().length > 0) {
            validationGrp.showAllMessages();
            return false;
        }

        try {
            setBtnDisabledState(event, 'Saving...');
            const result = await saveRateAsync(ko.toJS(vm.formData));
            setBtnEnabledState(event, 'Save');

            toast.open({
                type: "info",
                message: "Rate table saved successfully."
            })

            reset();
            onSave(result);
        }
        catch(err) {
            vm.errors.push(err);
            setBtnEnabledState(event, 'Save');
        }
    }

    const reset = () => {
        $('#laneRateEntryModal').hide();
        vm.formData(undefined);

        $('.modal-backdrop').remove();

        dispatch(setLaneRate(null));
    }

    const init = async () => {
        const mRates = await getMileageRatesAsync();
        vm.mileageRatesOptions(mRates.map(x => ({label: 'rate_value', value: 'externalId'} )));

        vm.formData(new rateModel(ko.toJS(rateData)));

        $('#laneRateEntryModal').on('hidden.bs.modal', reset);
    }

    init();
}

import template from './rate-entry-modal.html';
export default { viewModel: rateEntryModalViewModel, template: template }