import { useDispatch } from 'ko-data-store';
import { isLoading } from 'dataStore-actions/appUI';
import * as tvCommon from '../tvCommon';

const contentHeaderViewModel = function({
    handleMobileMenuDisplay,
    expandElement,
    videoContentVM
}) {
    const vm = this;
    const dispatch = useDispatch();

    vm.handleMobileMenuDisplay = handleMobileMenuDisplay;
    vm.expandElement = expandElement;
    vm.videoContentVM = videoContentVM;

    vm.searchVideoInput = ko.observable();
    vm.handleSearchVideo = async (title) => {
        if(title && title.length) {
            dispatch(isLoading(true))

            const content = ko.toJS(videoContentVM());
            if(content.subheader !== 'Search Results'){
                tvCommon.trainingVideoPageCache.add('currentVideoContentVM', content);
            }
            
            const searchResult = await tvCommon.services.getVideosByTitleAsync(title);
            dispatch(isLoading(false))

            videoContentVM(new tvCommon.VideoContentModel({subheader: 'Search Results', videoList: searchResult}));
        }
        else {
            vm.searchVideoInput(null);
            const content = tvCommon.trainingVideoPageCache.get('currentVideoContentVM');
            videoContentVM(new tvCommon.VideoContentModel(content));
        }
    }
}

import template from './content-header-component.html';
export default { viewModel: contentHeaderViewModel, template: template }