import ko from 'knockout'
import dataModel from 'data-model';
import * as $ from 'jquery';

var MoveTrailerServices = {};
    
class MoveTrailerViewModel {
    constructor(params) {
        var self = this;
        params = params || {};
        self.trailerId = ko.observable(params.trailerExId).extend({required: true});
        self.date = ko.observable().extend({required: true});
        self.locationId = ko.observable();
        self.selectedLocation = ko.observable();
        self.trailerDropped = ko.observable(false);

        self.selectedLocation.subscribe(function(value) {
            if(value) {
                self.cityStateZip(value.zip + " (" + value.city + ", " + value.state + ")");
            }
        })
        self.cityStateZip = ko.observable().extend({required: true})
        self.cityStateZipSelectedValue = ko.observable();
        self.errors = ko.observableArray([]);
        self.showLoadingWheel = ko.observable(false);
    
        self.isCityStateZipReadOnly = ko.computed(() => {
            return self.locationId() != null;
        })
    
        self.createMovement = function() {
            self.errors.removeAll();
    
            var validationErrors = ko.validation.group(self);
            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                return false;
            }
    
            var cityStateZip = self.cityStateZipSelectedValue() || self.selectedLocation()
    
            var data = {
                trailerId : self.trailerId(),
                date: self.date(),
                locationId: self.locationId(),
                city: cityStateZip && cityStateZip.city && cityStateZip.city.trim(),
                state: cityStateZip && cityStateZip.state && cityStateZip.state?.trim(),
                zip: cityStateZip && cityStateZip.zip && cityStateZip.zip.trim(),
                trailerDropped: self.trailerDropped()
            }
    
            self.showLoadingWheel(true);
            MoveTrailerServices.MoveTrailer(data)
            .then(function(value) {
                self.showLoadingWheel(false);
                params.params.result(value);
                $('#moveTrailerModal').find('.close').click();
            });
        }
    }
}

MoveTrailerServices.MoveTrailer = function(data) {
    return new Promise(function(resolve, reject) {
        dataModel.ajaxRequest("Trailer/MoveTrailer", "POST", data)
        .done(function(response) {
            resolve(response);
        })
    })
}


import template from './move-trailer-component.html';
export default { viewModel: MoveTrailerViewModel, template: template }