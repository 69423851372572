
import ko, { Observable } from 'knockout'
import template from './agent-default-rates-page.html'
import $ from 'jquery';
import dataModel from 'data-model';
import { AgentDefaultRatesModal } from './agent-default-rates-modal-component/agent-default-rates-modal-component';
import { AgentDefaultRatesGridComponent } from './agent-default-rates-grid-component/agent-default-rates-grid-component';


class AgentDefaultRatesPage {
    agentDefaultRatesModal: Observable<AgentDefaultRatesModal | number> = ko.observable();
    agentDefaultRatesGrid: AgentDefaultRatesGridComponent
    defaultPayMethods: DefaultPayMethod[];
    showLoadingPanel: Observable<boolean> = ko.observable(false);

    constructor() {
        this.getDefaultPayMethods();
    }

    getDefaultPayMethods() {
        dataModel.ajaxRequest("CustomPaySolutions/GetDefaultPayMethods")
        .done((data) => {
            this.defaultPayMethods = data.map((x: DefaultPayMethod) => x)
        })
    }
}

class DefaultPayMethod {
    id: number;
    description: string;
}

export default { viewModel: AgentDefaultRatesPage, template: template }
export { AgentDefaultRatesPage }
