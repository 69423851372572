// These are the 'slices' (states) of the store.
// The store should be used for data/things that more than one component uses / needs access to. If a component
// only has it's own data/state then best not to add that here. Instead can use the 'useState' from
// the store or use ko.observables like currently.
import example from './example';
import appUI from './appUI';
import wireAdvances from './wireAdvances';
import rates from './rates';
import userFilters from './userFilters';
import apiLookups from './lookups';
import tntOrderEntry from './TNT/orderEntry';
import tntLookups from './TNT/lookups';
import stopsEdiDelay from './orderEntry/stopsEdiDelay';

export default {
    example, // these are the namespaces (props) for each piece of the data tree
    appUI,
    wireAdvances,
    rates,
    userFilters,
    apiLookups,
    tntOrderEntry,
    tntLookups,
    stopsEdiDelay
}

// Ex:
//  export default {
//      auth,
//      user,
//      appUI,
//  }
// *All these 'slices' can be access anywhere in the app via useSelector utility.