import userProfile from "user-profile";
import dataModel from "data-model";
import dayjs from "dayjs";
import template from './order-details-component.html';
import ko from 'knockout';

var OrderDetailsViewModel = function (datarecord) {
    var self = this;
    datarecord = datarecord.data;
    self.isLoading = ko.observable();
    self.orderDetailObject = ko.observable();
    self.sharedOrderDetailObject = ko.observable();
    self.loadDetailObject = ko.observable();
    self.isLoad = ko.observable(datarecord.isLoad);
    self.showSharedDetail = ko.observable();
    self.isSharedOrder = ko.pureComputed(function () {
        return datarecord.sharedOrder && userProfile.isGwEmployee == false;
    });

    //showrowdetailsVM FOR DETAIL MODAL.  CHECKS TO SEE WHETHER THE SELECTED RECORD IS A LOAD OR ORDER AND RENDERS POPUP MODAL.  RETURNS ENTIRE OBJECT FROM
    //CALLBACK TO VIEW, AND VIEW BINDS WITH DETAIL MODAL (FROM THE DETAILS BUTTON CLICK) AND ORDERDETAILSOBJECT OR LOADDETAILOBJECT
    //Function to set default pinned this.columns to not be pinned when viewing on mobile devices.
    self.getDetailData = function() {
        return new Promise(function(resolve, reject) {
            self.isLoading(true);
            if (datarecord.isLoad) {
                let uri = "GetLoad/";
                dataModel.ajaxRequest("OrderPlanning/" + uri + datarecord.id)
                .done(function (data, status, xhr) {
                    self.showSharedDetail(false);
                    if (data.agencyId != userProfile.currentAgencyId()) {
                        data.customerId = null;
                    }
                    if (data.email != null) {
                        data.mailto = data.email + "?body=" + encodeURIComponent("I am interested in your posted load from " + data.originCityStateZip + " to " + data.destCityStateZip + " picking up " + dayjs(data.pickUpDate).format(dataModel.dateTimeFormat));
                    } else { data.mailto = ""; }
                    self.loadDetailObject(data);  
                })
                .fail(function (error, msg, d) {
                    $('#divDetails').html(JSON.stringify(error, null, 4));
                })
                .always(() => {
                    self.isLoading(false);
                    resolve();
                })
            }

            else if (userProfile.isGwEmployee == false && (datarecord.showDetailedGrid == false || datarecord.sharedOrder)) {
                self.showSharedDetail(true);
                let uri = "GetSharedOrderDetails/"
                dataModel.ajaxRequest("OrderPlanning/" + uri + datarecord.id)
                    .done(function (data, status, xhr) {
                        if (data.agencyId != userProfile.currentAgencyId()) {
                            data.customerId = null;
                        }
                        if (data.email != null) {
                            data.mailto = data.email + "?body=" + encodeURIComponent("I am interested in your posted load from " + data.originCityStateZip + " to " + data.destCityStateZip + " picking up " + dayjs(data.pickUpDate).format(dataModel.dateTimeFormat));
                        } else { data.mailto = ""; }
                        self.sharedOrderDetailObject(data);
                        resolve()
                    })
                    .fail(function (error, msg, d) {
                        $('#divDetails').html(JSON.stringify(error, null, 4));
                        self.isLoading = ko.observable(false);
                    })
                    .always(() => {
                        self.isLoading(false);
                        resolve();
                    });
            }
            else {
                dataModel.ajaxRequest("OrderPlanning/GetOrderDetails/" + datarecord.id, "GET")
                .done(function (data, status, xhr) {
                    self.showSharedDetail(false);
                    data.isOwnerOperator = userProfile.isOwnerOperator;
                    self.orderDetailObject(data);
                    resolve();
                })
                .fail(function (error, msg, d) {
                    $('#divDetails').html(JSON.stringify(error, null, 4));
                    self.isLoading = ko.observable(false);
                })
                .always(() => {
                    self.isLoading(false);
                    resolve();
                });
            }
        })
    }
    self.getDetailData();
};

export default { viewModel: OrderDetailsViewModel, template: template }