import dataModel from "data-model";
import userProfile from "user-profile";

class EditAgencyRequiredFieldsViewModel {
    constructor(params) {
        params = params || {};

        this.parentModel = params.parentModel || {};
        this.formData = params.formData || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.isProcessing = ko.observable(false);
        this.agencyExId = ko.observable(this.formData.agencyExId);

        this.saveBtnText = ko.computed(() => {
            return ko.unwrap(this.isProcessing) ? "Saving..." : "Save";
        });

        this.myAgency = ko.observable(this.formData.agency);
        this.errors = ko.observable("");

        this.requiredFieldId = ko.observable(this.formData.id);
        this.pageName = ko.validatedObservable(this.formData.pageId).extend({
            required: {
                message: "Please select page",
            },
        });
        this.pageNamePlaceholder = ko.observable(
            this.formData.page || "Please Choose.."
        );
        this.originalFieldName = ko.observable(this.formData.fieldNameId);
        this.fieldName = ko
            .validatedObservable(this.formData.fieldName)
            .extend({
                required: {
                    message: "Field Name is required",
                },
            });

        this.pageList = ko.observableArray();

        this.initializeComponent();
    }

    initializeComponent = () => {
        this.loadAgencyRequiredPages();
    };

    async loadAgencyRequiredPages() {
        let pageData = await this.http_sendGetAgencyRequiredPagesAsync().catch(
            (err) => console.error("Retrieving Agency Required Pages failed.")
        );

        if (pageData != undefined) this.pageList(pageData.pageList);
    }

    handleSaveRequiredField = () => {
        this.doHandleSaveRequiredField();
    };

    async doHandleSaveRequiredField() {
        this.isProcessing(true);

        const payload = {
            Id: this.requiredFieldId(),
            fieldNameId: this.fieldName(),
            agencyId: userProfile.currentAgencyId(),
        };

        const result = await this.http_sendSaveAgencyRequiredFieldAsync(
            payload
        ).catch((err) => this.errors(err));
        this.isProcessing(false);

        if (result != undefined) this.$emitsOnAction();
    }

    http_sendGetAgencyRequiredPagesAsync = () => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/GetAgencyRequiredPages", "get")
                .done((data) => resolve(data))
                .fail((err) => reject(err));
        });
    };

    http_sendSaveAgencyRequiredFieldAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/UpsertAgencyRequiredField",
                    "post",
                    payload
                )
                .done((data) => resolve(data))
                .fail((error) => {
                    if (error.responseJSON && error.responseJSON.message) {
                        reject(error.responseJSON.message);
                    }
                });
        });
    };
}

import template from "./edit-agency-required-fields-component.html";
export default {
    viewModel: EditAgencyRequiredFieldsViewModel,
    template: template,
};
