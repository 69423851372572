
import ko, { Observable } from 'knockout'
import template from './owner-operator-rates-page.html';
import $ from 'jquery';
import dataModel from 'data-model';
import { OwnerOperatorRatesModal } from './owner-operator-rates-modal-component/owner-operator-rates-modal-component';
import { OwnerOperatorRatesGridComponent } from './owner-operator-rates-grid-component/owner-operator-rates-grid-component';


class OwnerOperatorRatesPage {
    ownerOperatorRatesModal: Observable<OwnerOperatorRatesModal | number> = ko.observable();
    ownerOperatorRatesGrid: OwnerOperatorRatesGridComponent
    defaultPayMethods: DefaultPayMethod[] = [];
    showLoadingPanel: Observable<boolean> = ko.observable();

    constructor() {
        this.getDefaultPayMethods();
    }

    getDefaultPayMethods() {
        dataModel.ajaxRequest("CustomPaySolutions/GetDefaultPayMethods")
        .done((data) => {
            this.defaultPayMethods = data.map((x: DefaultPayMethod) => x)
        })
    }
}

class DefaultPayMethod {
    id: number;
    description: string;
}

export default { viewModel: OwnerOperatorRatesPage, template: template }
export { OwnerOperatorRatesPage }
