import dataModel from 'data-model';
import { noop } from 'global-functions';

const fetchContactsAsync = () => {
    return new Promise((resolve) => {
        dataModel.ajaxRequest("Contacts").then(resolve).fail((err) => resolve({}));
    })
}
function ContactsPage() {
    const vm = this;
    vm.isLoading = ko.observable(false);

    vm.contactsList = ko.observableArray([]);
    vm.companyGroups = ko.observableArray([]);
    vm.companies = ko.observableArray([]);
    vm.activeDirectory = ko.observable({});

    vm.shownContacts = ko.observableArray([]);
    vm.contactSearch = ko.observable("");
    vm.contactSearch.subscribe((val) => handleContactSearch(val));

    vm.companyFilterOptions = ko.observableArray([]);
    vm.companyFilter = ko.observableArray([]);
    vm.companyFilter.subscribe((vals) => handleCompanySearch(vals));

    vm.handleCompanyClick = (directory) => {
        if(directory) {
            vm.activeDirectory(directory);
            displayContacts(directory.id);
        }
    }

    vm.getContactCountForDepartment = (id) => ko.observable(vm.contactsList().filter(x => x.contactGroupId === id).length);

    const handleContactSearch = (val) => {
        if(val != null) {
            const contacts = vm.shownContacts().filter(x => x.contact && x.contact.toUpperCase().indexOf(val.toUpperCase()) > -1);
            vm.shownContacts(contacts);
        }
        else {
            displayContacts(vm.activeDirectory().id);
        }
    }

    const handleCompanySearch = (vals = []) => {
         if(vals.length) {
            const contacts = vm.shownContacts().filter(x => {
                return x.companyExIds.some(q => vals.indexOf(q) > -1)
            });

            vm.shownContacts(contacts);
        }
        else {
            displayContacts(vm.activeDirectory().id);
        }
    }

    const displayContacts = (id) => {
        if(id != null) {
            let grpFilterOptions = [];

            const contacts = vm.contactsList().filter(x => x.contactGroupId === id);
            vm.shownContacts(contacts);

            contacts.forEach(x => {
                x.companyExIds.forEach(q => {
                    if(grpFilterOptions.indexOf(q) == -1) {
                        grpFilterOptions.push(q);
                    }
                })
            });

            vm.companyFilterOptions(grpFilterOptions.map(x => ({label: x, value: x})));
        }
        else {
            vm.shownContacts([]);
            vm.companyFilterOptions([]);
        }
    }

    
    const loadContacts = async() => {
        vm.isLoading(true);
        const result = await fetchContactsAsync() || {};
        vm.companyGroups(result.companyGroups || []);
        vm.contactsList(result.contacts || []);
        vm.companies(result.companies || []);

        const viewDirectory = vm.activeDirectory() && vm.activeDirectory().id > 0 ? vm.activeDirectory() : result.companyGroups[0] || {};
        vm.activeDirectory(viewDirectory);
        displayContacts(viewDirectory.id);
        vm.isLoading(false);
    }

    loadContacts();
}

import template from './contacts-page.html';
export default { viewModel: ContactsPage, template }