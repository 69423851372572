import dataModel from "data-model";
import ko from "knockout";
import {MethodsEnum} from "../../two-factor-settings-component/two-factor-settings-component";


class TwoFactorConfirmationFormComponent {
    shortCodeToken = ko.observable();
    error = ko.observable();
    failedAttempts = ko.observable(0);
    canResendCode = ko.observable(false);
    
    _enteredCode = ko.observable("");
    _resendTimer = ko.observable(0);
    _resentCodeCount = ko.observable(0);

    constructor(params) {
        params = params || {};

        const options = params.options || {};
        this.lockoutOnMaxAttempts = options.lockoutOnMaxAttempts != undefined ? options.lockoutOnMaxAttempts : true;
        this.resendLimitSetting = options.resendLimitSetting || 5;
        this.resendTimerSetting = options.resendTimerSetting || 90;
        this.maxFailAttemptsAllowed = options.maxFailAttemptsAllowed || 3;
        this.maxEnteredLength = options.maxEnteredLength || 6;
        this.resendLimitMsg = options.resendLimitMsg || "You have reached the resend limit. If you are still not receiving the code, please contact Help Desk for assistance.";
        this.maxAttemptsExceededMsg = options.maxAttemptsExceededMsg || "You have too many failed entry attempts. Please contact the Help Desk.";

        this.settings = ko.observable(ko.unwrap(params.settings));
        this.helperMessage = ko.computed(() => {
            return this.settings().method == MethodsEnum.PHONE ? "Message and data rates may apply." : "Didn't get the email? Check your spam/junk folder(s).";
        });

        this.onConfirmation = params.onConfirmation && typeof params.onConfirmation === "function"
            ? params.onConfirmation
            : function () {};

        
        this.enteredCode = ko.pureComputed({
            read: () => {
                return this._enteredCode();
            },
            write: (value) => {
                value = value.substr(0, this.maxEnteredLength);
                value = value.replace(/[^\w]|_+/gi, "");
                this._enteredCode(value);
            }, 
            owner: this
        },this);

        this.sentTo = ko.pureComputed(() => {
            if(this.settings().contact) { 
                return "Sent to " + (this.settings().method == MethodsEnum.PHONE ? this.settings().contact.substring(this.settings().contact,this.settings().contact.indexOf("@")) : this.settings().contact);
            }
            
            return "No email or phone number is provided! You must setup before verifying.";
        });
        
        
        this.resendTimerTxt = ko.pureComputed(() => {
            return this._resendTimer();
        });

        this.hasFailedMaxAttempts = ko.pureComputed(() => {
            return this.failedAttempts() >= this.maxFailAttemptsAllowed;
        });

        this._enableVerifyBtn = ko.observable(true);
        this.enableVerifyBtn = ko.pureComputed({
            read: () => { 
                return this.enteredCode().length > 0 && this._enableVerifyBtn() && this.hasFailedMaxAttempts() == false;
            },
            write: (value) => {
                this._enableVerifyBtn(value);
            }
        });

        this.initialize();
    }

    initialize = async () => {
        await this.sendCode();
        this.resetResendTimer();
    }

    resetResendTimer = () => {
        this.canResendCode(false);
        this._resendTimer(this.resendTimerSetting);
        
        let timer = setInterval(() => {
            if(this.lockoutOnMaxAttempts && this.failedAttempts() >= this.maxFailAttemptsAllowed) {
                this._resendTimer(0);
                clearInterval(timer);
            }
            else if(this._resendTimer() > 0) {
                this._resendTimer(this._resendTimer() - 1);
            }
            else {
                this.canResendCode(true);
                clearInterval(timer);
            }
        }, 1000);
    }

    handleVerify = async () => {
        this.error("");

        let payload = { 
            codeToken: this.shortCodeToken() || {},
            lockoutOnFailure: this.lockoutOnMaxAttempts
        };

        payload.codeToken.userEnteredCode = this.enteredCode();
        
        let result = await this.http_postTokenVerification(payload).catch((err) => this.error(err));

        if(result && result.errors && result.errors.length) {
            this.failedAttempts(result.token.failedAttempts);
            let errorMsg = this.hasFailedMaxAttempts() ? this.maxAttemptsExceededMsg : result.errors;

            if(errorMsg.indexOf("expired") != -1) this._enableVerifyBtn(false);

            this.error(errorMsg);

            if(result.token) {
                result.token.userEnteredCode = "";
                this.shortCodeToken(result.token);
            }
        
            this.onConfirmation(false);

            if(this.failedAttempts() >= this.maxFailAttemptsAllowed && this.lockoutOnMaxAttempts) this.canResendCode(false);
            
            return false;
        }

        this.canResendCode(false);
        this.enableVerifyBtn(false);
        
        this.onConfirmation(true);
    }

    handleResendCode = () => {
        this.error("");
        if(this._resentCodeCount() >= this.resendLimitSetting) {
            this.error(this.resendLimitMsg);

            this.canResendCode(false);

            return false;
        }

        this._enableVerifyBtn(true);
        this._enteredCode("");
        this._resentCodeCount(this._resentCodeCount() + 1);
        this.resetResendTimer();
        this.sendCode();
    }

    sendCode = async () => {
        if(this.settings() && !this.settings().contact) return false;

        let token = await this.http_fetchTwoFactorTokenCode(this.settings().contact).catch((err) => this.error(err));

        if(token && !token.error) {
            this.failedAttempts(token.failedAttempts);
            this.shortCodeToken(token);
        }
    }


    http_fetchTwoFactorTokenCode = (contact) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("User/SendTwoFactorCode", "GET", {contact: contact})
            .done((response) => resolve(response))
            .fail((error) => {
                reject(
                    (error.responseJSON && error.responseJSON.message) ||
                        "Error occurred sending code. Please try again or contact Help Desk."
                );
            });           
        });
    }   

    http_postTokenVerification = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("User/VerifyTwoFactorToken", "POST", payload).done((response) => resolve(response))
            .fail((error) => {
                reject(
                    (error.responseJSON && error.responseJSON.message) ||
                        "Error occurred while validating code. Please try again or contact Help Desk."
                );
            })
        })
    }
}

import template from "./two-factor-confirmation-form-component.html";
export default { viewModel: TwoFactorConfirmationFormComponent, template: template }