import ko from 'knockout';
import dataModel from 'data-model';
import template from './order-preassignment-component.html';
import dayjs from 'dayjs';
import * as $ from 'jquery'
import {OrderPlanningHeaderActionButtonsComponent} from '../header-action-buttons-component';
import {showmustdismiss, showmessage} from '../../../../shared-components/dialog-components/show-dialog-methods'
import {datetimeUTC, mapGridDate} from 'global-functions';

class OrderPreassignment {
    constructor(data) {
        var self = this;
        /**@type { OrderPlanningHeaderActionButtonsComponent } */
        self.$parent = data.data.$parent;

        self.$orderPlanningVM = self.$parent.$parent || {};
        data = data.data.orderPreassignment();
        var movement = data.movement;
        var tractor = data.tractor;
        var vm = data.vm;
        
        self.isLoading = self.$parent.$parent.isLoading;
        self.errors = ko.observableArray();
        self.movementId = ko.observable(movement.id);
        self.brokered = ko.observable(movement.brokered);
        self.noBrokerCustomer = ko.observable(movement.noBroker);
        self.carrierDriver = ko.observable(false);
        self.carrierDriverAssignment = ko.observable(false);
        self.orderExId = ko.observable(movement.orderExId);
        self.customerNoBroker = ko.observable(movement.noBroker == null ? false : movement.noBroker);
        self.assignedCarrierRate = ko.observable(movement.carrierPayRate);
        self.carrierExId = ko.observable(movement.carrierExId);
        self.tractorBlocked = false;
        self.tractorId = ko.observable(tractor.tractorId).extend({
            required: {
                message: "Tractor is required",
                onlyIf: function () {
                    if (self.brokered() == false) {
                        return true;
                    }
                    return false;
                }
            }
        });
        self.selectedCarrier = ko.observable(movement.carrierExId).extend({
            required: {
                message: "Carrier is required",
                onlyIf: function () {
                    if (self.brokered() == true) {
                        return true;
                    }
                    return false;
                }
            }
        });
        self.isPreassigned = ko.observable(movement.isPreassigned);
        var tmpOrder = null;
        //This creates the grid inside the order pre-assignment modal.
        //CASE 1, there is a tractor selected - either a user has selected a tractor in the tractor grid and hit preassign, or a tractor has been selected from a drop-down.
        //CASE 2, movement.brokered == false && tractor.id != null.   tractor.id can exist from a tractor in the tractor grid, or from orderRowData.assignedTractorId
        self.loadTractorPreassignment = function (param) {   
            var isHazmat = movement.hazmat;
            var tractorId;
            if (param) {
                tractorId = param;
            }
            else {
                tractorId = tractor.id
            }

            self.tractorBlocked = false;
            self.isLoading(true);
            tmpOrder = movement;
            var source = {
                url: "OrderPlanning/GetTractorPreassignment",
                datatype: "json",
                formatdata: function (data) {
                    data = data || {};
                    data.tractorId = tractorId;
                    data.isOrderHazmat = isHazmat;
                    return data;
                },
                sortcolumn: "sequence",
                sortdirection: 'asc',
                beforeLoadComplete: function (records = []) {
                    var isNew = true;

                    records = records.map(x => {
                            //const strippedDate = mapGridDate(x.deliveryDate);
                            const date = datetimeUTC(x.deliveryDate);
                            x.deliveryDate = date.isValid() ? date.format("MM/DD/YYYY HH:mm") : null;
                            
                            return x;
                        });

                    for (var i = 0; i < records.length; i++) {
                        if (records[i].movementId == tmpOrder.id) {
                            isNew = false;
                        }
                    }

                    if (isNew) {
                        var seq = records.length + 1;

                        const dateDayjs = datetimeUTC(tmpOrder.deliveryDate);
                        const date = dateDayjs.isValid() ? dateDayjs.format("MM/DD/YYYY HH:mm") : null;

                        records.push({
                            moveDistance: tmpOrder.moveDistance,
                            destination: tmpOrder.destination,
                            movementId: tmpOrder.id,
                            orderIds: tmpOrder.orderExId,
                            origin: tmpOrder.origin,
                            deliveryDate: date,
                            sequence: seq,
                            blockDispatch: tmpOrder.blockDispatch
                        });
                        if (tmpOrder.blockDispatch == true) {
                            self.tractorBlocked = true;
                        }
                    }
                    return records;
                },

                datafields: [
                    { name: "movementId", type: "int" },
                    { name: "sequence", type: "int" },
                    { name: "orderIds", type: "string" },
                    { name: "origin", type: "string" },
                    { name: "destination", type: "string" },
                    { name: "moveDistance", type: "float" },
                    { name: "deliveryDate", type: "date" },
                    { name: "blockDispatch", type: "bool" }
                ],
            
                loadError: function (error) {   
                    self.tractorBlocked = true;
                    self.errors.push(JSON.parse(error.responseText).message);
                    self.isLoading(false);
                }
            };

            var dataAdapter = dataModel.getDataAdapter(source);
            setTimeout(function () {

                $("#jqxTractorPreassignGrid").on("bindingcomplete", function (event) {
                    self.isLoading(false);
                });

                $("#jqxTractorPreassignGrid").jqxGrid(
                {
                    source: dataAdapter,
                    width: "100%",
                    autoheight: true,
                    selectionmode: 'none',
                    enablehover: false,
                    columns: [
                        {
                            text: ' ', datafield: 'tmpSeq', columntype: 'button', width: 80,
                            cellsrenderer: function () {
                                return "Move Up";
                            }, buttonclick: function (row) {
                                var gridRows = $("#jqxTractorPreassignGrid").jqxGrid("getrows");
                                var currentRow = $("#jqxTractorPreassignGrid").jqxGrid('getrowdata', row);
                                if (currentRow.sequence > 1) {
                                    for (var i = 0; i < gridRows.length; i++) {
                                        var dataRecord = $("#jqxTractorPreassignGrid").jqxGrid('getrowdata', i);
                                        if (currentRow.sequence - 1 == dataRecord.sequence) {
                                            dataRecord.sequence = dataRecord.sequence + 1;
                                            $('#jqxTractorPreassignGrid').jqxGrid('updaterow', i, dataRecord);
                                        }
                                    }
                                    currentRow.sequence = currentRow.sequence - 1;
                                    $('#jqxTractorPreassignGrid').jqxGrid('updaterow', row, currentRow);
                                }
                            }
                        },
                        { text: '#', datafield: 'sequence', width: 30 },
                        { text: 'Movement Id', datafield: 'movementId', width: 100, hidden: true },
                        { text: 'OrderIds', datafield: 'orderIds', width: 100 },
                        { text: 'Destination', datafield: 'destination', width: 200 },
                        { text: 'Movement Distance', datafield: 'moveDistance', width: 150 },
                        { text: 'Delivery Date', datafield: 'deliveryDate', width: 130 },
                        {
                            text: ' ', datafield: 'delete', width: 70, columntype: 'button', cellsrenderer: function () { return "Delete"; },
                            buttonclick: function (row) {
                                var b = "";
                                $("#jqxTractorPreassignGrid").jqxGrid('deleterow', row);
                            }
                        },
                        { text: 'BlockDispatch', datafield: 'blockDispatch', hidden: true }
                    ]
                });
            }, 1000);
        };

        //movement = orderRowData which will always be here.  Tractor depends on whether or not the user has selected a tractor in the tractor grid.  
        if (movement.brokered == false && tractor.id != null) {
            self.loadTractorPreassignment(tractor.id)
        }

        self.loadCarrierDriverPreassignment = function (driverId) {          
            var orderRowIndex = $('#jqxOrderGrid').jqxGrid('getselectedrowindex');
            if (orderRowIndex > -1) {
                var orderRowData = $("#jqxOrderGrid").jqxGrid('getrowdata', orderRowIndex);

                var source = {
                    datatype: "json",
                    sortcolumn: "sequence",
                    sortdirection: 'asc',
                    beforeLoadComplete: function (records = []) {
                        var isNew = true;
                        
                        records = records.map(x => {
                            //const strippedDate = mapGridDate(x.deliveryDate);
                            const date = datetimeUTC(x.deliveryDate);
                            x.deliveryDate = date.isValid() ? date.format("MM/DD/YYYY HH:mm") : null;
                            
                            return x;
                        });

                        for (var i = 0; i < records.length; i++) {
                            if (records[i].movementId == orderRowData.id) {
                                isNew = false;
                            }
                        }
                        if (isNew) {
                            var seq = records.length + 1;
                            const dateDayjs = datetimeUTC(orderRowData.deliveryDate);
                            const date = dateDayjs.isValid() ? dateDayjs.format("MM/DD/YYYY HH:mm") : null;

                            records.push({
                                moveDistance: orderRowData.moveDistance,
                                destination: orderRowData.destination,
                                movementId: orderRowData.id,
                                orderIds: orderRowData.orderExId,
                                origin: orderRowData.origin,
                                deliveryDate: date,
                                sequence: seq
                            });
                        }
                        return records;
                    },

                    datafields: [{ name: "movementId", type: "int" },
                                    { name: "sequence", type: "int" },
                                    { name: "orderIds", type: "string" },
                                    { name: "origin", type: "string" },
                                    { name: "destination", type: "string" },
                                    { name: "moveDistance", type: "float" },
                                    { name: "deliveryDate", type: "date" }
                    ],
                    url: "OrderPlanning/GetCarrierPreassignment/" + driverId
                };

                var dataAdapter = dataModel.getDataAdapter(source);

                $("#jqxCarrierPreassignGrid").jqxGrid(
                {
                    source: dataAdapter,
                    width: "100%",
                    //  theme: "GreatWide",
                    autoheight: true,
                    selectionmode: 'none',
                    enablehover: false,
                    columns: [
                        {
                            text: ' ', datafield: 'tmpSeq', columntype: 'button', width: 80,
                            cellsrenderer: function () {
                                return "Move Up";
                            }, buttonclick: function (row) {
                                var gridRows = $("#jqxCarrierPreassignGrid").jqxGrid("getrows");
                                var currentRow = $("#jqxCarrierPreassignGrid").jqxGrid('getrowdata', row);
                                if (currentRow.sequence > 1) {
                                    for (var i = 0; i < gridRows.length; i++) {
                                        var dataRecord = $("#jqxCarrierPreassignGrid").jqxGrid('getrowdata', i);
                                        if (currentRow.sequence - 1 == dataRecord.sequence) {
                                            dataRecord.sequence = dataRecord.sequence + 1;
                                            $('#jqxCarrierPreassignGrid').jqxGrid('updaterow', i, dataRecord);
                                        }
                                    }
                                    currentRow.sequence = currentRow.sequence - 1;
                                    $('#jqxCarrierPreassignGrid').jqxGrid('updaterow', row, currentRow);
                                }
                            }
                        },
                        { text: '#', datafield: 'sequence', width: 30 },
                        { text: 'Movement Id', datafield: 'movementId', width: 100, hidden: true },
                        { text: 'OrderIds', datafield: 'orderIds', width: 100 },
                        { text: 'Destination', datafield: 'destination', width: 200 },
                        { text: 'Movement Distance', datafield: 'moveDistance', width: 150 },
                        { text: 'Delivery Date', datafield: 'deliveryDate', width: 130 },
                        {
                            text: ' ', datafield: 'delete', width: 70, columntype: 'button', cellsrenderer: function () { return "Delete"; },
                            buttonclick: function (row) {
                                $("#jqxCarrierPreassignGrid").jqxGrid('deleterow', row);
                            }
                        }
                    ]
                });
            }
        };

        self.saveTrucklinePreassignment = function () {
            if (self.tractorBlocked) {
                return false;
            }
            self.errors.removeAll();

            var validationErrors = ko.validation.group(self.tractorId);
            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                return false;
            }


            var gridRows = $("#jqxTractorPreassignGrid").jqxGrid("getrows");
            var assignments = new Array();
            for (var i = 0; i < gridRows.length; i++) {
                assignments.push({
                    movementId: gridRows[i].movementId,
                    sequence: gridRows[i].sequence
                });
            }


            self.isLoading(true);

            var data = {
                tractorId: self.tractorId(),
                assignments: assignments,
                orderTracking: self.$parent.$parent.orderEntryTracking()?.data
            };
            data.orderTracking.viewingOrderId(movement.orderId);

            dataModel.ajaxRequest("OrderPlanning/SaveTrucklineAssignment", "POST", data)
                .done(function (data, textStatus, jqXHR) {
                    self.isLoading(false);
                    showmustdismiss("Preassignment saved.")
                    .then(() => {
                        self.closeModal();
                        self.refreshTractorGrid();
                    })
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status == 400) {
                        try {
                            self.errors.push(JSON.parse(jqXHR.responseText).message);
                        }
                        catch (e) {
                            console.error(e);
                        }
                    } else if (jqXHR.status == 409) { // Conflict
                        self.$parent.$parent.orderEntryTracking().methods().displayConflictNotifyMessage(jqXHR.responseJSON);
                    } 				
                    else if (jqXHR.status == 412) // Order Locked
                    {
                        showmessage(jqXHR.responseJSON.message);
                    } 
                    self.isLoading(false);
                });
        };

        self.saveBrokeragePreassignment = function () {
            self.tractorBlocked = false;
            var validationErrors = ko.validation.group(self);
            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                return false;
            }

            self.isLoading(true);
            var data = {
                carrierId: self.selectedCarrier(),
                carrierRate: self.assignedCarrierRate(),
                movementId: self.movementId(),
                orderTracking: self.$parent.$parent.orderEntryTracking()?.data
            };
            data.orderTracking.viewingOrderId(movement.orderId);

            dataModel.ajaxRequest("Carrier/PreassignCarrier", "POST", data)
            .done(function (result) {
                // display carrier tractor preassignment if tractor is selected
                self.isLoading(false);
                var tractorRowIndex = $('#jqxTractorGrid').jqxGrid('getselectedrowindex');
                if (tractorRowIndex > -1) {
                    var tractor = $("#jqxTractorGrid").jqxGrid('getrowdata', tractorRowIndex);
                    if (tractor.isCarrierDriver) {
                        self.carrierDriver(true);
                        self.carrierDriverAssignment(true);
                        self.loadCarrierDriverPreassignment(tractor.id);
                    } else {
                        showmustdismiss("Preassignment saved.")
                        .then(() => {
                            self.closeModal();
                            self.refreshTractorGrid();
                        })
                    }
                } else {
                    showmustdismiss("Preassignment saved.")
                    .then(() => {
                        self.closeModal();
                        self.refreshTractorGrid();
                    })
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.status == 400) {
                    var response = jqXHR.responseJSON;
                    self.errors.push(response.message);
                } else if (jqXHR.status == 409) { // Conflict
                    self.$parent.$parent.orderEntryTracking().methods().displayConflictNotifyMessage(jqXHR.responseJSON);
                }
                else if (jqXHR.status == 412) // Order Locked
                {
                    showmessage(jqXHR.responseJSON.message);
                } 
                self.isLoading(false);
            });
        };

        self.saveBrokerageDriverPreassignment = function () {
            var gridRows = $("#jqxCarrierPreassignGrid").jqxGrid("getrows");
            var assignments = [];
            for (var i = 0; i < gridRows.length; i++) {
                assignments.push({
                    movementId: gridRows[i].movementId,
                    sequence: gridRows[i].sequence
                });
            }
            var tractorRowIndex = $('#jqxTractorGrid').jqxGrid('getselectedrowindex');

            if (tractorRowIndex > -1) {

                var tractor = $("#jqxTractorGrid").jqxGrid('getrowdata', tractorRowIndex);

                var data = {
                    tractorId: tractor.id,
                    assignments: assignments,
                    orderTracking: self.$parent.$parent.orderEntryTracking()?.data
                };
                data.orderTracking.viewingOrderId(movement.orderId);
                dataModel.ajaxRequest("OrderPlanning/SaveBrokerageAssignment", "POST", data)
                .done(function (data, textStatus, jqXHR) {
                    self.isLoading(false);
                    showmustdismiss("Preassignment saved.")
                    .then(() => {
                        self.closeModal();
                        self.refreshTractorGrid()
                    })
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status == 400) {
                        var response = jqXHR.responseJSON;
                        self.errors.push(response.errors);
                    } else if (jqXHR.status == 409) { // Conflict
                        self.$parent.$parent.orderEntryTracking().methods().displayConflictNotifyMessage(jqXHR.responseJSON);
                    }
                    else if (jqXHR.status == 412) // Order Locked
                    {
                        showmessage(jqXHR.responseJSON.message);
                    } 
                });
            }
        };

        self.tractorSelected = function (tractor) {
            if (tractor != undefined) {
                self.errors.removeAll();
                self.loadTractorPreassignment(tractor.id);
            }
        };

        self.switchToBrokerage = function () {
            self.errors.removeAll();
            var orderRowIndex = $('#jqxOrderGrid').jqxGrid('getselectedrowindex');
            if (orderRowIndex > -1) {
                var orderRowData = $("#jqxOrderGrid").jqxGrid('getrowdata', orderRowIndex);
                if (orderRowData.status == "Available" || orderRowData.status == "Covered") {
                    self.isLoading(true);

                    self.selectedCarrier(undefined);

                    var data = {
                        movementId: orderRowData.id,
                        brokered: true,
                        orderTracking: self.$parent.$parent.orderEntryTracking()?.data
                    };
                    data.orderTracking.viewingOrderId(movement.orderId);
                    
                    dataModel.ajaxRequest("OrderPlanning/MovementBrokeredStatus", "POST", data)
                    .done(function (data, textStatus, jqXHR) {
                        self.brokered(true);
                        self.isLoading(false);
                        self.tractorId(undefined);
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        if (jqXHR.status == 400) {
                            var response = jqXHR.responseJSON;
                            self.errors.push(response.message);
                        } else if (jqXHR.status == 409) { // Conflict
                            self.$parent.$parent.orderEntryTracking().methods().displayConflictNotifyMessage(jqXHR.responseJSON);
                        }
                        else if (jqXHR.status == 412) // Order Locked
                        {
                            showmessage(jqXHR.responseJSON.message);
                        } 
                        self.isLoading(false);
                    });
                }
                else {
                    self.errors.push("Brokered status can be changed only for available or covered movements");
                }
            }
        };

        self.switchToTruckline = function () {
            self.errors.removeAll();
            var orderRowIndex = $('#jqxOrderGrid').jqxGrid('getselectedrowindex');
            if (orderRowIndex > -1) {
                var orderRowData = $("#jqxOrderGrid").jqxGrid('getrowdata', orderRowIndex);

                var data = {
                    movementId: orderRowData.id,
                    brokered: false,
                    orderTracking: self.$parent.$parent.orderEntryTracking().data
                };

                data.orderTracking.viewingOrderId(movement.orderId);
                self.isLoading(true);
                dataModel.ajaxRequest("OrderPlanning/MovementBrokeredStatus", "POST", data)
                .done(function (result) {
                    self.brokered(false);
                    var tractorRowIndex = $('#jqxTractorGrid').jqxGrid('getselectedrowindex');
                    if (tractorRowIndex > -1) {
                        var tractor = $("#jqxTractorGrid").jqxGrid('getrowdata', tractorRowIndex);
                        self.tractorId(tractor.tractorId);
                    }
                    else {
                        self.tractorId(undefined);
                    }
                    self.isLoading(false);
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    if (jqXHR.status == 400) {
                        var response = jqXHR.responseJSON;
                        self.errors.push(response.message);
                    } else if (jqXHR.status == 409) { // Conflict
                        self.$parent.$parent.orderEntryTracking().methods().displayConflictNotifyMessage(jqXHR.responseJSON);
                    }
                    else if (jqXHR.status == 412) // Order Locked
                    {
                        showmessage(jqXHR.responseJSON.message);
                    } 
                    self.isLoading(false);
                });
            }
        };

        self.isSwitchToBrokeredDisabled = ko.computed(function () {
            if (self.isLoading() || self.noBrokerCustomer()) {
                return true;
            }
            return false;
        });

        self.closeModal = function() {
            $('#jqxPreAssignment').modal('hide');
        }

        self.refreshTractorGrid = function() {
            self.$parent.$parent.tractorGridComponent.refreshTractorGrid()
        }
    }
}


export default {viewModel: OrderPreassignment, template: template }