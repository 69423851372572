

import { Observable, ObservableArray, Computed, SubscribableFunctions, ObservableExtenderOptions } from "knockout";
import * as ko from "knockout";
import * as koValidation from "knockout.validation";
import dataModel from 'data-model';
import * as $ from "jquery";
import userProfile from '../../../../user-profile';
import router from 'router';
import template from './inspection-locations-edit-component.html';
import dayjs from 'dayjs';
import { showconfirm, showmessage } from 'show-dialog-methods'

class EditInspectionLocationViewModel { 
    inspectionFacilityId = ko.observable();
    notesData: any;
    newNote: Observable<LocationNote | undefined> = ko.observable();
    contactsData: any;
    
    inspectionLocationNotesGrid: jqwidgets.jqxGrid | undefined
    inspectionLocationContactsGrid: jqwidgets.jqxGrid | undefined;
    newContact: Observable<LocationContact | undefined> = ko.observable();

    inspectionFacilityModel: Observable<InspectionFacility | undefined> = ko.observable();
    hoursModel: Observable<Hours | undefined> = ko.observable();
    addressModel: Observable<Address | undefined> = ko.observable();
    isLoading = ko.observable(false);
    errors = ko.observable();

    constructor(params) {
        this.inspectionFacilityId = params.locationId;
        this.initializeComponents();
    }

    update = () => {
        this.errors(undefined);
        let facilityErrors = ko.validation.group(this.inspectionFacilityModel, {deep: true})
        let addressErrors = ko.validation.group(this.addressModel, {deep: true});
        let errors = facilityErrors().concat(addressErrors());
        if(errors.length > 0) {
            facilityErrors.showAllMessages(true);
            addressErrors.showAllMessages(true);
            this.errors(errors);
            return false;
        }
        
        let modelForServer = {
            inspectionFacilityLocation: this.inspectionFacilityModel()!.obj,
            inspectionFacilityAddress: this.addressModel()!.obj,
            inspectionFacilityHours: this.hoursModel()!.obj,
            notes: this.notesData.toArray(),
            contacts: this.contactsData.toArray(),
        }

        this.isLoading(true);
        dataModel.ajaxRequest("InspectionLocation/UpdateLocation", "POST", modelForServer)
        .done((value) => {
            this.notesData = [];
            value.notes.forEach((note, i, array) => {
                note.createdDate = dayjs().format("MM/DD/YYYY HH:mm")
                this.notesData.push(note);
            })
            this.contactsData = [];
            value.contacts.forEach((contact, i, array) => {
                this.contactsData.push(contact);
            })
            this.errors(undefined);
            this.isLoading(false);
            if(this.inspectionFacilityId() == null) {
                let newPath = "inspectionlocations?id=" + value.id;
                router.navigate(newPath);
            }

        })
        .fail((value) => {
            this.errors(undefined);
            this.isLoading(false);
            var messages = value.responseJSON.message.split("|")
            this.errors(messages);
        })

    }
    
    initializeComponents() : void {
        $("#accordion").accordion({heightStyle: "content", collapsible: true});
        Promise.all([this.getNotes(), this.getContacts(), this.getFormData()])
    }

    getFormData() {
        return new Promise<void>((resolve, reject) => {
            if(this.inspectionFacilityId()){
                dataModel.ajaxRequest("InspectionLocation/GetInspectionLocationData", "GET", {id: this.inspectionFacilityId()})
                .done((value) => {
                    this.hoursModel(new Hours(value.inspectionFacilityHours));
                    this.addressModel(new Address(value.inspectionFacilityAddress));
                    this.inspectionFacilityModel(new InspectionFacility(value));
                    resolve();
                })
            }
            else {
                this.hoursModel(new Hours());
                this.addressModel(new Address());
                this.inspectionFacilityModel(new InspectionFacility());
                resolve();
            }
        })
    }

    getNotes() {
        if(this.inspectionFacilityId()) {
            return new Promise<void>((resolve, reject) => {
                if(this.inspectionFacilityId) {
                    dataModel.ajaxRequest("InspectionLocation/GetNotesForGrid", "GET", {inspectionFacilityId: this.inspectionFacilityId()})
                    .done((value) => {
                        this.notesData = new $.jqx.observableArray(value);
                        this.createNotesGrid();
                        resolve();
                    })
                }
                else {
                    resolve();
                }
            })
        } else {
            this.notesData = new $.jqx.observableArray([]);
            this.createNotesGrid();
        }
    }

    createNotesGrid() {
        let notesSource: jqwidgets.GridSource = {
            localdata: this.notesData,
            datatype: "obserableArray",  
            datafields: [
                {name: "id", type: "string"},
                {name: "note", type: "string"},
                {name: "createdDate", type: "date"},
                {name: "enteredBy", type: "string"},
                {name: "enteredById", type: "string"}
            ]
        }
        let notesDataAdapter = new $.jqx.dataAdapter(notesSource);
        let notesOptions: jqwidgets.GridOptions = {
            source: notesDataAdapter,
            width: "100%",
            altrows: true,
            sortable: true,
            autoheight: true,
            pageable: true,
            filterable: true,
            pagesize: 10,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            showtoolbar: true,
            rendertoolbar: this.renderNotesGridToolbar,
            columns: [
                {text: "UserName", datafield: "enteredBy", width: "8%"},
                {text: "Note", datafield: "note", width: "65%"},
                {text: "Date Entered", datafield: "createdDate", width: "15%", cellsformat: 'MM/dd/yyyy HH:mm'},
                {text: "", width: "12%", columntype: "button",
                    cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                        return "Delete";
                    },   
                    buttonclick: this.deleteNote
                }
            ]
        }
        this.inspectionLocationNotesGrid = jqwidgets.createInstance("#inspectionLocationNotesGrid", "jqxGrid", notesOptions);
    }

    deleteNote = (row) => {
        showconfirm("Are you sure?")
        .then((x) => {
            if(x) {
                dataModel.ajaxRequest("InspectionLocation/DeleteNote", "GET", {noteid: this.notesData[row].id})
                .done((x) => {
                    this.notesData.splice(row, 1);
                })
                .fail((x, y, z) => {
                    showmessage("An error has occurred.")
                    console.error(x, y, z);
                })
            }
        })
    }

    renderNotesGridToolbar = (toolbar) => {
        let btn = '<div class="newButton"><input type="button" value="New Note" data-bind="geButton: {}, click: showNewNoteModal" /></div>'
        $(toolbar).append(btn);
        ko.applyBindingsToDescendants({showNewNoteModal: this.showNewNoteModal}, $(toolbar)[0]);
    }

    showNewNoteModal = () => {
        this.newNote(new LocationNote());
    }

    saveNote = (event) => {
        let note = this.newNote()!.obj;
        note.inspectionFacilityId = this.inspectionFacilityId();
        note.createdDate = dayjs().format("MM/DD/YYYY HH:mm");
        note.enteredBy = userProfile.userName;
        dataModel.ajaxRequest("InspectionLocation/SaveNote", "POST", note)
        .done((x) => {
            note.id = x
            this.notesData.push(note);
        })

        this.newNote(undefined);
    }

    cancelSaveNote = (event) => {
        $('#ILNoteModal').find('.close').trigger('click');
    }

    cancelSaveContact = (event) => {
        $('#ILContactModal').find('.close').trigger('click');
    }

    getContacts() {
        if(this.inspectionFacilityId()) {
            return new Promise<void>((resolve, reject) => {
                if(this.inspectionFacilityId()) {
                    dataModel.ajaxRequest("InspectionLocation/GetContactsForGrid", "GET", {inspectionFacilityId: this.inspectionFacilityId()})
                    .done((value) => {
                        this.contactsData = new $.jqx.observableArray(value);
                        this.createContactsGrid();
                        resolve();
                    })
                } else {
                    resolve();
                }
            })
        } else {
            this.contactsData = new $.jqx.observableArray([]);
            this.createContactsGrid();
        }
    }

    createContactsGrid() {
        let contactSource: jqwidgets.GridSource = {
            localdata: this.contactsData,
            datatype: "obserableArray",  
            datafields: [
                {name: "id", type: "string"},
                {name: "name", type: "string"},
                {name: "phone", type: "string"},
                {name: "fax", type: "string"},
                {name: "email", type: "string"},
                {name: "title", type: "string"}
            ]
        }
        let contactsDataAdapter = new $.jqx.dataAdapter(contactSource);
        let contactsOptions: jqwidgets.GridOptions = {
            source: contactsDataAdapter,
            width: "100%",
            altrows: true,
            sortable: true,
            autoheight: true,
            pageable: true,
            filterable: true,
            pagesize: 10,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            showtoolbar: true,
            rendertoolbar: this.renderContactsGridToolbar,
            columns: [
                {text: "", columntype: "button", width: "5%",
                    cellsrenderer: (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
                        return "Edit";
                    },   
                    buttonclick: (row) => {
                        this.showContactModal(this.contactsData[row]);
                    }
                },
                {datafield: "name", text: "Name", width: "18%"},
                {datafield: "phone", text: "Phone Number", width: "18%",
                    cellsrenderer: (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
                        var cell = $(defaulthtml!);
                        if(value.length == 10) {
                            cell[0].innerText = "(" + value.substr(0, 3) + ") " + value.substr(3, 3) + "-" + value.substr(6, 4)
                        }
                        return cell[0].outerHTML;
                    }
                },
                {datafield: "fax", text: "Fax Number", width: "18%", 
                    cellsrenderer: (row?: number, columnfield?: string, value?: any, defaulthtml?: string, columnproperties?: any, rowdata?: any) => {
                        var cell = $(defaulthtml!);
                        if(value.length == 10) {
                            cell[0].innerText = "(" + value.substr(0, 3) + ") " + value.substr(3, 3) + "-" + value.substr(6, 4)
                        }
                        return cell[0].outerHTML;
                    }
                },
                {datafield: "email", text: "Email", width: "18%"},
                {datafield: "title", text: "Title", width: "18%"},
                {text: "", columntype: "button", width: "5%",
                    cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
                        return "Delete";
                    },   
                    buttonclick: this.deleteContact
                },
            ]
        }
        this.inspectionLocationContactsGrid = jqwidgets.createInstance("#inspectionLocationContactsGrid", "jqxGrid", contactsOptions);
    }

    deleteContact = (row) => {
        showconfirm("Are you sure?")
        .then((x) => {
            if(x) {
                dataModel.ajaxRequest("InspectionLocation/DeleteContact", "GET", {contactId: this.contactsData[row].id})
                .then(() => {
                    this.contactsData.splice(row, 1);
                })
                .fail((x, y, z) => {
                    showmessage("An error has occurred.")
                    console.error(x, y, z);
                })
            }
        })

    }

    renderContactsGridToolbar = (toolbar) => {
        let btn = '<div class="newButton"><input type="button" value="New Contact" data-bind="geButton: {}, click: showContactModal" /></div>'
        $(toolbar).append(btn);
        ko.applyBindingsToDescendants({showContactModal: this.showContactModal}, $(toolbar)[0]);
    }

    showContactModal = (contact) => {
        this.newContact(new LocationContact(contact));
    }

    saveContact = (event) => {
        let contact = this.newContact()!.obj
        contact.inspectionFacilityId = this.inspectionFacilityId();
        dataModel.ajaxRequest("InspectionLocation/SaveContact", "POST", contact)
        .done((x) => {
            if(contact.id != '') {
                let i = this.contactsData.findIndex(x => x.id == contact.id);
                this.contactsData[i] = contact;
                this.newContact(undefined);
            } else {
                contact.id = x;
                this.contactsData.push(contact)
                this.newContact(undefined);
            }
        })
        .fail((x, y, z) => {
            showmessage("An error has occurred.")
            console.error(x, y, z);
        })

    }

    //General
    generateNewCode = () => {
        let obj = {
            state: this.addressModel()!.state,
            name: this.inspectionFacilityModel()!.name(),
            city: this.addressModel()!.city
        };
        dataModel.ajaxRequest("InspectionLocation/GenerateInspectionLocationCode", "GET", obj)
        .done((value) => {
            this.inspectionFacilityModel()!.externalId(value);
        })
    }

    cancelEdit = () => {
        router.navigate("inspectionlocations");
    }
}

class InspectionFacility {
    numberOfInspectors = ko.observable();
    scale = ko.observable();
    hourlyLaborRate = ko.observable();
    truckWash = ko.observable();
    truckInspectionFee = ko.observable()
    
        .extend({
            required: { params: true, message: "Inspection Fee Truck is requred." }
        });
    parking = ko.observable();
    trailerInspectionFee = ko.observable()
        .extend({
            required: { params: true,  message: "Inspection Tee Trailer is requred." }
        });
    numberOfFuelLanes = ko.observable();
    comboInspectionFee = ko.observable()
        .extend({
            required: { params: true,  message: "Inspection Fee Combo is requred." }
        });
    numberOfServiceBays = ko.observable();
    tireBrandsOffered = ko.observable();
    showers = ko.observable();
    tireBrandsOnNAB = ko.observable();
    serviceTrucks = ko.observable();
    wifi = ko.observable();
    typesOfRepairs = ko.observable();
    entryDate = ko.observable();
    externalId = ko.observable()
        .extend({
            required: { params: true,  message: "Inspection Location Code is requred." }
        });
    name = ko.observable()
        .extend({
            required: { params: true,  message: "Name is requred." }
        });
    approvedBy = ko.observable()
    approvalDate = ko.observable();
    contractCancellationDate = ko.observable();
    inspectionFacilityId: string | undefined;
    id: string;

    constructor(x?) {
        x = x || {};
        this.id = x.id || ''
        this.numberOfInspectors(x.numberOfInspectors);
        this.scale(x.scale);
        this.hourlyLaborRate(x.hourlyLaborRate);
        this.truckWash(x.truckWash);
        this.truckInspectionFee(x.truckInspectionFee);
        this.parking(x.parking);
        this.trailerInspectionFee(x.trailerInspectionFee);
        this.numberOfFuelLanes(x.numberOfFuelLanes);
        this.comboInspectionFee(x.comboInspectionFee);
        this.numberOfServiceBays(x.numberOfServiceBays);
        this.tireBrandsOffered(x.tireBrandsOffered);
        this.showers(x.showers);
        this.tireBrandsOffered(x.tireBrandsOffered);
        this.tireBrandsOnNAB(x.tireBrandsOnNAB);
        this.serviceTrucks(x.serviceTrucks);
        this.wifi(x.wifi);
        this.typesOfRepairs(x.typesOfRepairs);
        this.entryDate(dayjs(x.entryDate).format('MM/DD/YYYY hh:mm a') || undefined);
        this.externalId(x.externalId);
        this.name(x.name);
        this.approvedBy(x.approvedBy)
        this.approvalDate(x.approvalDate);
        this.contractCancellationDate(x.contractCancellationDate);
    }

    get obj() {
        return {
            id: this.id,
            numberOfInspectors : this.numberOfInspectors(),
            scale : this.scale(),
            hourlyLaborRate : this.hourlyLaborRate(),
            truckWash : this.truckWash(),
            truckInspectionFee : this.truckInspectionFee(),
            parking : this.parking(),
            trailerInspectionFee : this.trailerInspectionFee(),
            numberOfFuelLanes : this.numberOfFuelLanes(),
            comboInspectionFee : this.comboInspectionFee(),
            numberOfServiceBays : this.numberOfServiceBays(),
            tireBrandsOffered : this.tireBrandsOffered(),
            tireBrandsOnNAB: this.tireBrandsOnNAB(),
            showers : this.showers(),
            serviceTrucks : this.serviceTrucks(),
            wifi : this.wifi(),
            typesOfRepairs : this.typesOfRepairs(),
            entryDate : this.entryDate(),
            externalId : this.externalId(),
            approvalDate : this.approvalDate(),
            name : this.name(),
            approvedBy : this.approvedBy(),
            contractCancellationDate : this.contractCancellationDate(),
        }
    }
}

class Address {
    id = ko.observable();
    address1 = ko.observable()
        .extend({
            required: {  params: true, message: "Address is requred." }
        });
    cityStateZip = ko.observable()
        .extend({
            required: {  params: true, message: "City/State/Zip is required." }
        });
    validCityStateZip = ko.observable()
        .extend({required: {  params: true, message: "A valid City/State/Zip is required"}});
    gpsLatitude = ko.observable();
    gpsLongitude = ko.observable();
    phoneNumber = ko.observable();
    inspectionFacilityId: string;
    city: string | undefined;
    state: string | undefined;
    zip: string | undefined;

    constructor(x?) {
        x = x || {};
        this.id(x.id);
        this.address1(x.address1);
        this.city = x.city;
        this.state = x.state ? x.state.trim() : undefined;
        this.zip = x.zip || '';
        if(this.city && this.state && this.zip) {
            this.cityStateZip(this.zip ? x.zip + ' (' + x.city + ', ' + this.state + ')' : undefined);
            this.validCityStateZip(true);
        }
        this.gpsLatitude(x.latitude);
        this.gpsLongitude(x.longitude);
        this.phoneNumber(x.phoneNumber);
        this.inspectionFacilityId = x.inspectionFacilityId;
    }

    //jquery.ui(?) does this really neat thing where it calls options.select() twice.
    //If you set a breakpoint right before line ~main.js:3216 it magically stops making the second request.
    //stopGetLatLongRequest prevents this.
    stopGetLatLongRequest = false;
    onCityStateZipChange = (x) => {
        if(x) {
            if(this.stopGetLatLongRequest == false) {
                this.stopGetLatLongRequest = true;
                this.city = x.city;
                this.state = x.state;
                this.zip = x.zip; 
                this.validCityStateZip(true);
                dataModel.ajaxRequest("CityStateZip/GetLatLongFromZip", "GET", {zip: this.zip})
                .done((value) => {
                    this.stopGetLatLongRequest = false;
                    if(value[0]) {
                        this.gpsLatitude(value[0].latitude);
                        this.gpsLongitude(value[0].longitude);
                    }
                })
            }
        } else {
            this.city = undefined;
            this.state = undefined;
            this.zip = undefined;
            this.gpsLatitude(undefined);
            this.gpsLongitude(undefined);
            this.validCityStateZip(undefined);
        }
    }

    get obj() {
        return {
            id: this.id(),
            address1: this.address1(),
            gpsLatitude: this.gpsLatitude(),
            gpsLongitude: this.gpsLongitude(),
            phoneNumber: this.phoneNumber(),
            city: this.city,
            state: this.state,
            zip: this.zip,
            inspectionFacilityId: this.inspectionFacilityId
        }
    }
}

class Hours {
    id = ko.observable();
    sundayOpen = ko.observable();
    sundayClose = ko.observable();
    mondayOpen = ko.observable();
    mondayClose = ko.observable();
    tuesdayOpen = ko.observable();
    tuesdayClose = ko.observable();
    wednesdayOpen = ko.observable();
    wednesdayClose = ko.observable();
    thursdayOpen = ko.observable();
    thursdayClose = ko.observable();
    fridayOpen = ko.observable();
    fridayClose = ko.observable();
    saturdayOpen = ko.observable();
    saturdayClose = ko.observable();
    inspectionFacilityId: string;

    get obj() {
        return {
            id: this.id(),
            sundayOpen: this.sundayOpen() ? '1900-01-01 ' + this.sundayOpen() : undefined,
            sundayClose: this.sundayClose() ? '1900-01-01 ' + this.sundayClose() : undefined,
            mondayOpen: this.mondayOpen() ? '1900-01-01 ' + this.mondayOpen() : undefined,
            mondayClose: this.mondayClose() ? '1900-01-01 ' + this.mondayClose() : undefined,
            tuesdayOpen: this.tuesdayOpen() ? '1900-01-01 ' + this.tuesdayOpen() : undefined,
            tuesdayClose: this.tuesdayClose() ? '1900-01-01 ' + this.tuesdayClose() : undefined,
            wednesdayOpen: this.wednesdayOpen() ? '1900-01-01 ' + this.wednesdayOpen() : undefined,
            wednesdayClose: this.wednesdayClose() ? '1900-01-01 ' + this.wednesdayClose() : undefined,
            thursdayOpen: this.thursdayOpen() ? '1900-01-01 ' + this.thursdayOpen() : undefined,
            thursdayClose: this.thursdayClose() ? '1900-01-01 ' + this.thursdayClose() : undefined,
            fridayOpen: this.fridayOpen() ? '1900-01-01 ' + this.fridayOpen() : undefined,
            fridayClose: this.fridayClose() ? '1900-01-01 ' + this.fridayClose() : undefined,
            saturdayOpen: this.saturdayOpen() ? '1900-01-01 ' + this.saturdayOpen() : undefined,
            saturdayClose: this.saturdayClose() ? '1900-01-01 ' + this.saturdayClose() : undefined,
            inspectionFacilityId: this.inspectionFacilityId
        }
    }

    constructor(x?) {
        x = x || {};
        this.id(x.id);
        this.sundayOpen(x.sundayOpen ? dayjs(x.sundayOpen).format('hh:mm a') : undefined);
        this.sundayClose(x.sundayClose ? dayjs(x.sundayClose).format('hh:mm a') : undefined);
        this.mondayOpen(x.mondayOpen ? dayjs(x.mondayOpen).format('hh:mm a') : undefined);
        this.mondayClose(x.mondayClose ? dayjs(x.mondayClose).format('hh:mm a') : undefined);
        this.tuesdayOpen(x.tuesdayOpen ? dayjs(x.tuesdayOpen).format('hh:mm a') : undefined);
        this.tuesdayClose(x.tuesdayClose ? dayjs(x.tuesdayClose).format('hh:mm a') : undefined);
        this.wednesdayOpen(x.wednesdayOpen ? dayjs(x.wednesdayOpen).format('hh:mm a') : undefined);
        this.wednesdayClose(x.wednesdayClose ? dayjs(x.wednesdayClose).format('hh:mm a') : undefined);
        this.thursdayOpen(x.thursdayOpen ? dayjs(x.thursdayOpen).format('hh:mm a') : undefined);
        this.thursdayClose(x.thursdayClose ? dayjs(x.thursdayClose).format('hh:mm a') : undefined);
        this.fridayOpen(x.fridayOpen ? dayjs(x.fridayOpen).format('hh:mm a') : undefined);
        this.fridayClose(x.fridayClose ? dayjs(x.fridayClose).format('hh:mm a') : undefined);
        this.saturdayOpen(x.saturdayOpen ? dayjs(x.saturdayOpen).format('hh:mm a') : undefined);
        this.saturdayClose(x.saturdayClose ? dayjs(x.saturdayClose).format('hh:mm a') : undefined);
        this.inspectionFacilityId = x.inspectionFacilityId || '';
    }
}

class LocationNote {
    id = ko.observable();
    note = ko.observable();
    createdDate = ko.observable();
    enteredBy = ko.observable();
    enteredById = ko.observable()

    get obj() {
        return {
            id: this.id(),
            note: this.note(),
            createdDate: this.createdDate(),
            enteredBy: this.enteredBy(),
            enteredById: this.enteredById(),
            inspectionFacilityId: null
        };
    }
}

class LocationContact {
    id = ko.observable();
    name = ko.observable();
    phone = ko.observable();
    fax = ko.observable();
    email = ko.observable();
    title = ko.observable();
    inspectionFacilityId: string;

    get obj() {
        return {
            id : this.id(),
            name: this.name(),
            phone: this.phone(),
            fax: this.fax(),
            email: this.email(),
            title: this.title(),
            inspectionFacilityId: this.inspectionFacilityId,
        }
    }

    constructor(x?) {
        x = x || {};
        this.id(x.id || '');
        this.name(x.name);
        this.phone(x.phone);
        this.fax(x.fax);
        this.email(x.email);
        this.title(x.title);
        this.inspectionFacilityId = x.inspectionFacilityId || '';
    }
}

export default {viewModel: EditInspectionLocationViewModel, template: template}