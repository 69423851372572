// Controlled component
class AssignmentsTypeDDLComponent {
    constructor(params) {
        params = params || {};
        this.$owner = params.$owner || {};
        this.isReadOnly = ko.observable(params.isReadOnly);

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.isProcessing = ko.observable(false);
    }
}

import template from "./assignments-type-ddl-component.html";
export default { viewModel: AssignmentsTypeDDLComponent, template: template };
