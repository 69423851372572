import dataModel from 'data-model';
import userProfile from 'user-profile';
import router from 'router';
import * as $ from 'jquery';
import {mobilecheck} from 'global-functions';
import ko from 'knockout';
import template from './main-header-menu-component.html';


class MainHeaderMenuComponent {
    constructor() {
        var self = this;

        self.loggedIn = userProfile.loggedIn
        self.showNavigation = ko.observable(true);

        router.currentRoute.subscribe((route) => {
            self.showNavigation(route.page != "default-page");
        })
        
        self.collapsedMenuItems = ko.observableArray([]); // menu items used to render when navbar is collapsed
        self.extraMenuItems = ko.observableArray([]);
        self.loadUserMenu = function (menuData) {

            self.collapsedMenuItems(menuData.map((item) => {

                return {
                    url: item.page.url + "/",
                    img: "/Content/" + item.page.thumbPath,
                    name: item.page.name
                }
                
            }));
            
            $('.ge-menu-item-img').off('click');
            try {

                var menuContainerWidth = $(window).innerWidth <= 767 ? '100%' : Math.ceil( $('#geNavbar').width() / 3.7);

                var userMenu = $("#divUserMenu");                
                if (userMenu.length) {

                    var numOfMenuItems = (Math.ceil(menuContainerWidth / 25)) - 9;

                    var data = [];
                    var additionalMenu = [];
                    self.isMobile = mobilecheck();
                    let menuPages = menuData.map(x => x.page);

                    $.each(menuPages, function (index) {
                        var newWindow = "";
                        if (this.opensInNewWindow) {
                            newWindow = "target=\"_blank\"";
                        }
                        var url = this.url;
                        if (url.indexOf("http") == -1) {
                            url = "/" + url;
                        }

                        if (index < numOfMenuItems) {
                            var items = [{ id: this.id, html: "<a href=\"" + url + "\" " + newWindow + "><div class=\"ge-menu-item\" style=\"text-align:center;\"><img src=\"/Content/" + this.menuPath + "\" /><h5>" + this.name + "</h5> </div></a>" }];
                            var menu = { id: this.id, html: "<a href=\"" + url + "\" " + newWindow + "><img class='ge-menu-item-img' src=\"/Content/" + this.thumbPath + "\" /></a>", items: items };
                            data.push(menu);
                        }
                        else {
                            //additionalMenu.push({ id: this.id, html: "<a style=\"display: block;\" href=\"" + url + "\" " + newWindow + ">" + this.name + "</a>" });
                            self.extraMenuItems.push({ 
                                id: this.id, 
                                newTab: newWindow,
                                name: this.name,
                                url 
                            });
                        }
                    });

                    // Add the additional - sub menu
                    // if (additionalMenu.length > 0) {
                    //     var lastItem = {
                    //         html: "<div class=\"ge-menu-item\" style=\"padding: 5px\"><i class=\"glyphicon glyphicon-chevron-down sm-font\" style=\"margin-top: 5px;\"></i> Menu</div>",
                    //         id: "Menu",
                    //         items: additionalMenu
                    //     };
                    //     data.push(lastItem);
                    // }

                    // Initialize set default menu settings
                    userMenu.jqxMenu({
                        source: data,
                        //width: setMenuWidth,
                        
                        autoSizeMainItems: false,
                        autoCloseOnMouseLeave: true,
                        autoCloseInterval: 0,
                        
                        mode: 'horizontal'
                    });

                    // Non-mobile menu settings
                    if (self.isMobile == false) {

                        // This is preventing the page going to the menu links when the menu is set 
                        // to "hamburger" mode on mobile. So setup only for non-mobile
                        userMenu.on('itemclick', function (event) {
                            var element = event.args;
                            var found = false;
                            var menuLng = additionalMenu.length;
                            for (var i = 0; i < menuLng; i++) {
                                if (additionalMenu[i].id == element.id) {
                                    found = true;
                                    break;
                                }
                            }
                            if (found == false) {
                                return false;   //open duplicate tab fix
                            }
                        });
                        userMenu.jqxMenu('setItemOpenDirection', 'Menu', 'left', 'down');
                    }

                    if(($("#extra-menu-dropdown") || {}).length) {
                        $('#extra-menu-dropdown').on('show.bs.dropdown', function () {
                            $('#extra-menu-items li a').on("click", (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                return false;
                            })
                          })
                        
                        $('#extra-menu-dropdown').on('hide.bs.dropdown', () => {
                            $('#extra-menu-items').scrollTop(0);
                        })
                    }

                    $('#collapsed-menu-items a').on("click", function() {
                        $('#navbar-menu-collapse').removeClass('in');
                    });

                    $('li.jqx-item').off('click')
                    return true;
                }
                return false;
            }
            catch (error) {
                dataModel.addClientSideLog("LOAD_MENU_ERROR: " + error);
                console.error("LOAD_MENU_ERROR: " + error);
                return false;
            }
        };
        
        let _currentUserMenus = userProfile.currentUserMenus();
        userProfile.currentUserMenus.subscribe((ums)=> {
            _currentUserMenus = ums;
            if(this.loggedIn() == true) {
                self.loadUserMenu(_currentUserMenus);
            }
        })
        if(this.loggedIn() == true) {
            self.loadUserMenu(_currentUserMenus);
        }

        userProfile.loggedIn.subscribe((x) => {
            if(x) {
                self.loadUserMenu(userProfile.currentUserMenus());
                self.showNavigation(x);
            }
        })

        self.handleMenuSelection = ({url = "/", newTab = false} = {}, event) => {
            event.preventDefault();
            newTab = newTab || event.ctrlKey;
            const newWindow = event.shiftKey;
            
            if(url.indexOf("http") === -1 && url[0] != "/") {
                url = "/" + url;
            }

            router.navigate(url, newTab, newWindow);
            $('#navbar-menu-collapse').removeClass('in');

            if($('#extra-menu-dropdown').hasClass('open')){
                $('#extra-menu-dropdown').removeClass('open');
            }
            
            return false;
        }


        // Only hook into the resize event handler when not on mobile.
        // When the menu is in hamburger mode on a mobile device (works fine in desktop browsers....?)
        // and the user scrolls, the menu re-renders and expands the page.
        if (self.isMobile == false) {
            $(window).resize(function () {
                self.loadUserMenu(userProfile.currentUserMenus());
            });
        }

    }
}

export default { viewModel: MainHeaderMenuComponent, template: template };
