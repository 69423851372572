import dataModel from 'data-model';
import dayjs from 'dayjs';
import gridState from 'jqx.grid-state-component';
import { isEmpty } from 'global-functions';
import gridUtils from 'jqx.grid-utils';
import '../../../../../Content/app/GWTMDark.css';
import router from 'router';

class FieldOccurrenceRecapsViewModel {
    constructor() {
        var self = this;
        self.isLoading = ko.observable(false);
        self.fieldOccurrenceRecapDetails = ko.observable();
        self.detailRecap = ko.observable();

        self.getSelectedGridRow = function () {
            var row = null;

            var getselectedrowindexes = $('#fieldOccurrenceRecapsGrid').jqxGrid('getselectedrowindexes');
            if (getselectedrowindexes.length > 0) {
                row = $('#fieldOccurrenceRecapsGrid').jqxGrid('getrowdata', getselectedrowindexes[0]);
            }

            return row;
        }

        //editFieldOccurrenceRecap = function (data) {
        //    var id = data.id;

        //    var row = self.getSelectedGridRow();
        //    if (row != undefined)
        //        id = row.id;

        //    let url = 'EditFieldOccurrenceRecaps' + (id != undefined ? "/" + id : "");
        //    window.location.href = url;
        //};

        self.addFieldOcurrence = function () {
            //window.location.href = 'EditFieldOccurrenceRecaps';
            router.navigate('/EditFieldOccurrenceRecaps');
        }

        // This is handled by the edit btn on the details modal
        self.openEditFieldOccurrence = function (data) {
            if (data == undefined) return;

            let url = '/EditFieldOccurrenceRecaps' + (data.id != undefined ? "/" + data.id : "");
            //window.location.href = url;
            router.navigate(url);
        }

        // This is handled on the grid action bar
        self.editFieldOccurrence = function () {
            var row = self.getSelectedGridRow();
           
            if (row != undefined) {
                let url = '/EditFieldOccurrenceRecaps' + (row.id != undefined ? "/" + row.id : "");
                //window.location.href = url;
                router.navigate(url);
            }
        };

        self.handleMouseOver = function (vm, e) {
            $(e.target).addClass('jqx-fill-state-hover-GWTMDark');
        };
        self.handleMouseOut = function (vm, e) {
            $(e.target).removeClass('jqx-fill-state-hover-GWTMDark');
        };
        var columns = '';
        var datafields = '';
        var lastUpdated = Date.now();
        var updateGrid = false;
        var isInitLoad = true;
        self.editBtnVisible = ko.observable(false);

        self.refreshGrid = function () {
            $("#fieldOccurrenceRecapsGrid").jqxGrid('updatebounddata', 'data');
        };

        self.loadGridColumnsandDatafields = function () {

            var datePlaceHolderFilterWidget = function (column, columnElement, widget) {
                widget.jqxInput({
                    placeHolder: "MM/DD/YYYY"
                });
            };

            dataModel.ajaxRequest("FieldOccurrenceRecap/GetGridColumns", "GET")
                .done(function (data, textStatus, jqXHR) {

                    data.columns = $.map(data.columns, function (column) {
                        if(column.filtertype === "date")
                            column.createfilterwidget = datePlaceHolderFilterWidget;
                        return column;
                    });
                    columns = data.columns;
                    datafields = data.datafields;
                    self.loadDefaultFilters();
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    self.isLoading(false);
                });
        };

        self.loadDefaultFilters = function () {
            dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", { name: "fieldOccurrenceRecapsGrid", pageName: window.location.pathname })
            .done(function (data) {
                if (isEmpty(data) == false) {
                    var state = JSON.parse(data);
                    self.fieldOccurrenceRecapGridState = state.grid;
                    self.loadFieldOccurrenceRecaps(columns, datafields);
                }
                else {
                    self.loadFieldOccurrenceRecaps(columns, datafields);
                }
            })
            .fail(function () {
            });
        };

        self.fieldOccurrenceRecapGridState = null;

        self.loadFieldOccurrenceRecaps = function (columns, datafields) {
            var grid = $("#fieldOccurrenceRecapsGrid");
            var fieldOccurrenceRecapDetails = function () {
                return {
                    text: "",
                    pinned: true,
                    filterable: false,
                    sortable: false,
                    menu: false,
                    width: 100,
                    columnType: "button",
                    buttonclick: function (row) {
                        self.fieldOccurrenceRecapDetails(null);
                        self.detailRecap = "N/A";

                        self.isLoading(true);
                        var datarow = grid.jqxGrid('getrowdata', row);
                        dataModel.ajaxRequest('fieldoccurrencerecap/' + datarow.id)
                            .done(function (data, status, xhr) {
                                for (var property in data) {
                                    if (data[property]) {
                                        if (data[property] && data[property] != "n/a") {
                                            if (typeof (data[property]) === "string") {
                                                data[property] = data[property].trim();
                                                if (dayjs(data[property]).isValid())
                                                    data[property] = dayjs(data[property]).format("MM/DD/YYYY");
                                                else if (property === 'recapOfMeeting') self.detailRecap = data[property];
                                                    //self.detailRecap = $.parseHTML(data[property])[0].data || data[property];
                                            }
                                        } else
                                            data[property] = "N/A";
                                    }
                                }
                                self.fieldOccurrenceRecapDetails(data);
                                self.isLoading(false);
                            })
                            .fail(function (jqXHR, textStatus, errorThrown) {
                                if (jqXHR.responseJSON != null) {
                                    var json = jqXHR.responseJSON;
                                    if (json.message != null) {
                                        var errors = json.message;
                                        alert(errors);
                                    }
                                }
                                else {
                                    alert("Unable to load field occurrence recap details");
                                }
                                self.isLoading(false);
                            });
                    },
                    cellsrenderer: function () {
                        return "View Details";
                    }
                };
            };

            columns.unshift(fieldOccurrenceRecapDetails());


            var source = {
                url: "FieldOccurrenceRecap/GetFieldOccurrenceRecapsForGrid",
                datatype: "json",
                formatdata: function (data) {
                    var filterinfo = grid.jqxGrid('getfilterinformation');
                    data = gridUtils.formatGridFilters(filterinfo, data);
                    if (updateGrid) {
                        lastUpdated = Date.now()
                    }
                    data.lastUpdated = lastUpdated;
                    return data;
                },
                datafields: datafields,
                sortcolumn: 'postDate',
                sortdirection: 'desc'
            };

            var dataSource = dataModel.getDataAdapter(source);


            var pxgridheight = '100%';//screen.height - 355;
            var gridRows = 10;

            if (screen.height >= 800) {
                gridRows = 20;
                pxgridheight = 640;
            }

            grid.jqxGrid({
                theme: 'GWTMDark',
                width: "100%",
                height: pxgridheight,
                autoheight: screen.height >= 800,
                source: dataSource,
                altrows: true,
                sortable: true,
                showstatusbar: true,
                pageable: true,
                pagesize: gridRows,
                pagesizeoptions: [5, 10, 20],
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                columns: columns,
                statusbarheight: 35,
                showtoolbar: true,
                renderstatusbar: function (statusbar) {
                    var div = $("<div>", { id: 'fieldOccurrenceRecapRowCount', style: "padding:4px;" });
                    div.append("Number of Records: " + grid.jqxGrid('getrows').length);
                    statusbar.append(div);
                },
                toolbarheight: window.innerWidth > 768 ? 35 : 80,
                rendertoolbar: function (toolbar) {
                    var vm1 = new gridState.viewModel(),
                        tmpl1 = gridState.template;

                    vm1.clearFilters = function () {
                        isInitLoad = true;
                        $('#fieldOccurrenceRecapsGrid').jqxGrid('clearfilters');
                    };
                    vm1.setDefaultSearch = function () {
                        var filters = {};
                        return filters;
                    };
                    vm1.refresh = function () {
                        updateGrid = true;
                        grid.jqxGrid("updatebounddata", "data"); 
                    };

                    vm1.clearSelection = function () {
                        grid.jqxGrid('clearselection');
                        self.editBtnVisible(false);
                    }

                    var fieldOccurrenceRecapGridToolbar = $("#fieldOccurrenceRecapsGridToolbar");
                    toolbar.append(fieldOccurrenceRecapGridToolbar);

                    var tdGridAction = fieldOccurrenceRecapGridToolbar.find("#tdGridAction");
                    tdGridAction.append(tmpl1);

                    ko.applyBindingsToDescendants(vm1, tdGridAction[0]);
                },

                rendergridrows: function (obj) {
                    return obj.data;
                },

                ready: function (data) {
                    self.loadGridState(grid, self.fieldOccurrenceRecapGridState);
                    isInitLoad = false;
                }
            }).bind('rowselect', function (event) {
                self.editBtnVisible(true);
            }) ;

            self.loadGridState = function (grid, state) {
                if (state != null) {
                    var gridState = grid.jqxGrid('getstate');
                    state.selectedrowindex = -1;
                    state.selectedrowindexes = [];
                    state.pagenum = 0;
                    state.height = gridState.height;
                    state.pagesize = gridState.pagesize;
                    state.pagesizeoptions = gridState.pagesizeoptions;
                    grid.jqxGrid('loadstate', state);
                }
                else {
                    grid.jqxGrid('loadstate');
                }

            };         
        };

        self.loadGridColumnsandDatafields();
    }
}

import template from './field-occurrence-recaps-page.html';

export default { viewModel: FieldOccurrenceRecapsViewModel, template: template }