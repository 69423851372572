import gridUtils from "jqx.grid-utils";
import { getQueryVariable, isEmpty } from "global-functions";
import dataModel from "data-model";
import userProfile from "user-profile";
import gridState from "jqx.grid-state-component";
import { loadSavedSearches } from "../../shared-components/SearchFilter-Saves-Component/SearchFilter-Saves-Component";

class LocationsPageViewModel {
  constructor() {
    var self = this;
    var isInitLoad = true;

    self.isLoading = ko.observable(false);
    var ratingUpdated = false;
    var lastUpdated = Date.now();
    var updateGrid = false;
    self.locationDetailsWindow = ko.observable();

    self.clearSelectedSavedSearchDDL = ko.observable(false);
    self.refreshSavedSearchDDL = ko.observable("");

    self.handleOnSelected = (state) => {
      if (isEmpty(state) === false) {
        self.locationsGridState = state.grid;
      }

      if (isInitLoad) {
        loadLocations();
      } else {
        self.loadGridState(grid, self.locationsGridState);
      }

      isInitLoad = false;
    };


    self.cancelChanges = function () {
      self.locationDetailsWindow(undefined);
    };

    self.usersSavedFilters = ko.observable();
    self.refreshGrid = function () {
      $("#jqxLocations").jqxGrid("updatebounddata", "data");
    };

    self.toggleLocations = function (event, args) {
      var grid = $("#jqxLocations");

      var isToggled = $("#divLocationsPanel").is(":visible");
      var $this = $(args.currentTarget);
      if (isToggled) {
        $this
          .find("i")
          .removeClass("glyphicon-chevron-up")
          .addClass("glyphicon-chevron-down");
      } else {
        $this
          .find("i")
          .removeClass("glyphicon-chevron-down")
          .addClass("glyphicon-chevron-up");
      }

      $("#divLocationsPanel").animate({ height: "toggle" }, "slow");
    };

    var grid = $("#jqxLocations");
    self.isLocationsGridLoading = ko.observable(false);
    self.loadGridState = function (grid, state) {
      //if user has saved filters
      if (state != null && getQueryVariable("locationId").length == 0) {
        var gridState = grid.jqxGrid("getstate");
        state.selectedrowindex = -1;
        state.selectedrowindexes = [];
        state.pagenum = 0;
        state.height = gridState.height;
        state.pagesize = gridState.pagesize;
        state.pagesizeoptions = gridState.pagesizeoptions;
        grid.jqxGrid("loadstate", state);
      } else {
        addQueryFilter();
      }
    };


    //Here you can apply default filters that you want to apply if the user has no saved filters, or if you
    //do not want any default filters, just call 'grid.jqxGrid('applyfilters');' and filters will be []
    var addQueryFilter = function () {
      var filter_or_operator = 1;
      var filtercondition = "contains";
      var filtervalue = getQueryVariable("locationId");

      if (filtervalue.length > 0) {
        var filtergroup = new $.jqx.filter();
        var filter = filtergroup.createfilter(
          "stringfilter",
          filtervalue,
          filtercondition
        );
        filtergroup.addfilter(filter_or_operator, filter);
        grid.jqxGrid("addfilter", "locationCode", filtergroup);

        grid.jqxGrid("applyfilters");
      }
    };

    self.addNewLocation = function () {
      self.locationDetailsWindow({
        onLocationSave: function () {
          self.locationDetailsWindow(undefined);
          $("#jqxLocations").jqxGrid("updatebounddata");
        },
      });
    };

    self.locationsGridState = null;
    var loadLocations = function () {
      var source = {
        url: "Location/GetLocationsForGrid",
        datatype: "json",
        data: {
          agencyId: userProfile.currentAgencyId(),
        },
        filter: function (items) {
          // update the grid and send a request to the server.
          if (isInitLoad == false) {
            $("#jqxLocations").jqxGrid("updatebounddata", "filter");
          }
        },
        sort: function (column, sortOrder) {
          if (isInitLoad == false) {
            $("#jqxLocations").jqxGrid("updatebounddata", "sort");
          }
        },
        beforeprocessing: function (data) {
          source.totalrecords = data.totalrecords;
        },

        formatdata: function (data) {
          var filterinfo = $("#jqxLocations").jqxGrid("getfilterinformation");

          data = gridUtils.formatGridFilters(filterinfo ?? [], data);

          if (updateGrid) {
            lastUpdated = Date.now();
          }
          data.lastUpdated = lastUpdated;

          return data;
        },

        datafields: [
          { name: "id", type: "int" },
          { name: "locationCode", type: "string" },
          { name: "name", type: "string" },
          { name: "address", type: "string" },
          { name: "city", type: "string" },
          { name: "state", type: "string" },
          { name: "zip", type: "string" },
          { name: "rating", type: "int" },
        ],

        loadComplete: function (data) {
          if (isInitLoad == false) {
            self.isLocationsGridLoading(false);
          }
        },
        loadError: function (error) {
          //if you return with no data, loaderror will be hit
          if (isInitLoad == false) {
            self.isLocationsGridLoading(false);
          }
        },
      };

      var dataAdapter = dataModel.getDataAdapter(source);

      var columns = [
        {
          text: "",
          width: 100,
          filterable: false,
          sortable: false,
          columnType: "button",
          pinned: true,
          buttonclick: function (row) {
            self.isLocationsGridLoading(true);
            var datarow = $("#jqxLocations").jqxGrid("getrowdata", row);

            dataModel
              .ajaxRequest("Location/" + datarow.locationCode)
              .done(function (data, status, xhr) {
                data.onLocationSave = function () {
                  self.locationDetailsWindow(undefined); //close window

                  $("#jqxLocations").jqxGrid("updatebounddata");
                };

                self.locationDetailsWindow(data);
                //self.locationDetailsWindow(new locationDetailsViewModel(data));

                self.isLocationsGridLoading(false);
              })
              .fail(function (error, msg, d) {
                alert(error);
                self.isLocationsGridLoading(false);
              });
          },
          cellsrenderer: function () {
            return "View Details";
          },
        },
        { text: "Location Code", dataField: "locationCode", width: 120 },
        { text: "Name", dataField: "name", width: "40%" },
        { text: "Address", dataField: "address", width: 250 },
        { text: "City", dataField: "city", width: 120 },
        { text: "State", dataField: "state", width: 50 },
        { text: "Zip", dataField: "zip", width: 70 },
        {
          //refer to carriersnew.js for notation on rating function
          text: "Rating",
          datafield: "rating",
          width: 140,
          filtertype: "list",
          filteritems: [
            {
              value: 5,
              label: "5",
              html: "<img src='../Content/Images/5star.png' />",
            },
            {
              value: 4,
              label: "4",
              html: "<img src='../Content/Images/4star.png' />",
            },
            {
              value: 3,
              label: "3",
              html: "<img src='../Content/Images/3star.png' />",
            },
            {
              value: 2,
              label: "2",
              html: "<img src='../Content/Images/2star.png' />",
            },
            {
              value: 1,
              label: "1",
              html: "<img src='../Content/Images/1star.png' />",
            },
          ],
          //refer to carriersnew.js for notation on rating function
          cellsrenderer: function (
            row,
            columnfield,
            value,
            defaultHTML,
            column,
            rowData
          ) {
            if (defaultHTML != null) {
              var pk = grid.jqxGrid("getcellvalue", row, "id");
              var fieldset = $("<fieldset>");
              fieldset.addClass("star-rating");
              fieldset.attr("id", "jqxRating_" + pk);

              for (var i = 5; i > 0; i--) {
                var id = "jqxRating_" + pk + i;
                var input = $("<input>");
                input.attr({
                  type: "radio",
                  id: "star" + i,
                  name: "rating",
                  value: i,
                });
                input.appendTo(fieldset);
                var label = $("<label>").text(i);
                if (value == i) {
                  label.attr({ for: "star" + i });
                }
                label.insertAfter(input);
                $(document)
                  .off("click", "#jqxRating_" + pk)
                  .on("click", "#jqxRating_" + pk, function (event) {
                    var newRating;
                    var previousRating;
                    if (event.target.tagName == "LABEL") {
                      newRating = event.target.previousSibling.value;
                      $(event.target.parentElement.children).each(function (i) {
                        if (this.tagName == "LABEL") {
                          if (this.attributes.length > 0) {
                            if (this.attributes.for.value != null) {
                              previousRating =
                                this.attributes.for.value.slice(-1);
                            }
                            if (previousRating != newRating) {
                              $(this).removeAttr("for");
                            }
                          }
                        }
                      });
                      if (previousRating != newRating) {
                        var inputId =
                          event.target.previousSibling.attributes.getNamedItem(
                            "id"
                          ).value;
                        $(event.target).attr("for", inputId);
                        var id = event.target.parentElement.id.replace(
                          "jqxRating_",
                          ""
                        );
                        var params = {
                          objectId: id,
                          value: newRating,
                          agencyId: userProfile.currentAgencyId(),
                          favTable: 3,
                        };
                        dataModel
                          .ajaxRequest("Rating", "post", params)
                          .done(function (data, textStatus, jqXHR) {
                            updateGrid = true;
                            ratingUpdated = true;
                            self.refreshGrid();
                          })
                          .fail(function (error, msg, d) {
                            self.isLoading(false);
                          });
                      }
                    }
                  });
              }
              return fieldset[0].outerHTML;
            }
            return defaultHTML;
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxDropDownList({
              dropDownWidth: 140,
              renderer: function (index, label, value) {
                if (value != "") {
                  return (
                    "<img src='../Content/Images/" + value + "star.png' />"
                  );
                } else {
                  return "Any";
                }
              },
            });
          },
        },
      ];

      var gridheightraw = screen.height - 360;
      var pxgridheight = screen.height - 360;
      var gridrows;
      if (gridheightraw >= 700) {
        pxgridheight = screen.height - 360;
        gridrows = 20;
      } else if (gridheightraw >= 600) {
        gridrows = 15;
      } else {
        pxgridheight = 500;
        gridrows = 11;
      }

      pxgridheight += "px";

      $("#jqxLocations").jqxGrid({
        theme: "GWTMDark",
        width: "100%",
        source: dataAdapter,
        altrows: true,
        sortable: true,
        height: pxgridheight,
        pageable: true,
        pagesize: gridrows,
        filterable: true,
        showfilterrow: true,
        virtualmode: true,
        columnsresize: true,
        columnsreorder: true,
        enablebrowserselection: true,
        columnsmenu: false,
        columns: columns,
        showtoolbar: true,
        toolbarheight: window.innerWidth > 500 ? 40 : 80, // On smaller window widths increase the toolbar height so addition button can be visible
        autoshowfiltericon: true,

        rendertoolbar: function (toolbar) {
          var vm1 = new gridState.viewModel(),
            tmpl1 = gridState.template;
          var $grid = $("#jqxLocations");

          vm1.clearFilters = function () {
            //isInitLoad = true;
            $("#jqxLocations").jqxGrid("clearfilters");
            isInitLoad = false;
            self.refreshGrid();
            self.clearSelectedSavedSearchDDL(true);
          };

          vm1.setDefaultSearchOverride = async () => {
            const savedSearches = await loadSavedSearches($grid.attr("id"));
            const filters = {
              isDefault: true,
            };

            vm1.loadSaveSearchModal(
              $grid,
              (val) => {
                if (val && val.searchname) {
                  self.refreshSavedSearchDDL(val.searchname);
                }
              },
              savedSearches
                .filter((x) => x.searchName)
                .map((x) => ({ id: x.id, text: x.searchName })),
              filters,
              true
            );
          };

          vm1.actions.push("Save Search");

          vm1.loadSaveSearchModalOverride = async () => {
            const savedSearches = await loadSavedSearches($grid.attr("id"));
            const filters = {
              isDefault: false,
            };

            vm1.loadSaveSearchModal(
              $grid,
              (val) => {
                if (val && val.searchname) {
                  self.refreshSavedSearchDDL(val.searchname);
                }
              },
              savedSearches
                .filter((x) => x.searchName)
                .map((x) => ({ id: x.id, text: x.searchName })),
              filters,
              true
            );
          };
          vm1.refresh = function () {
            updateGrid = true;
            $("#jqxLocations").jqxGrid("updatebounddata", "data");
            self.clearSelectedSavedSearchDDL(true);
          };

          var locationGridToolbar = $("#locationGridToolbar");
          toolbar.append(locationGridToolbar);
          $(locationGridToolbar).append(tmpl1);

          var tdGridAction = locationGridToolbar.find(
            "#locationGridActionList"
          );
          tdGridAction.append(tmpl1);

          ko.applyBindingsToDescendants(vm1, tdGridAction[0]);
        },

        rendergridrows: function (obj) {
          return obj.data;
        },

        ready: function () {
          //ready triggers on the initial loading of the page - after the initial grid is created, but is not triggered in subsequent sorts, etc.
          //after creating the grid, set isInitLoad to false, and run the initfilters method, which gets the users saved filters and applies any default filters
          self.loadGridState(grid, self.locationsGridState);
        },
      });

      grid.on("bindingcomplete", function (event) {
        if (!isInitLoad) {
          if (updateGrid) {
            updateGrid = false;
          }
          if (ratingUpdated) {
            ratingUpdated = false;
            $("#divLocationsPanel").notify(" Rating Updated", {
              position: "top right",
              className: "info",
            });
          }
        }
      });
    };
  }
}

import template from "./locations-page.html";
export default { viewModel: LocationsPageViewModel, template: template };
