import ko from 'knockout';
import * as wireTypes from '../../types';


/**
 * @param {object} params - KO component parameters.
 * @param {function} params.onAdd - call back function when add btn is clicked.
 * @param {function} params.onCancelWire - call back function when cancel wire btn is clicked.
 * @param {function} params.onCancelConfirm - call back function when user clicks 'yes' or 'no btn
 * @param {ko.Observable<boolean>} params.canCancelWire - handles cancel wire btn disabled state
 * @param {ko.Observable<boolean>} params.displayCancelConfirm - display cancel confirm btns
 * @param {ko.Observable<boolean>} params.displayCancelWireBtn - display the cancel wire btn
 * @param {ko.Observable<boolean>} params.canWireAdvance - enable/disable if can click the 'add' btn
 * @param {function} params.onClearForm - clear form callback fn
 * @param {ko.Observable<boolean>} params.displayClearFormBtn - boolean when to show/hide 'clear' btn
 * @param {wireTypes.AdvanceRequestModel} params.formData
 * @param {wireTypes.SendWireData} params.sendWireData
 * @param {ko.Observable<object>} params.initFormData
 */

function efsActionBtnRowViewModel({
    onAdd = function() {}, 
    onCancelWire = function(){}, 
    onCancelConfirm = function(){}, 
    canCancelWire, 
    displayCancelConfirm, 
    displayCancelWireBtn, 
    canWireAdvance,
    onClearForm,
    formData,
    sendWireData,
    initFormData
}) 
{

    this.onAdd = onAdd;
    this.canWireAdvance = canWireAdvance;
    this.canCancelWire = canCancelWire;
    this.onCancelWire = onCancelWire;
    this.displayCancelConfirm = displayCancelConfirm;
    this.onCancelConfirm = onCancelConfirm;
    this.displayCancelWireBtn = displayCancelWireBtn;
    this.formData = formData;

    this.isAddBtnEnabled = ko.computed(() => {
        return this.canWireAdvance && !this.formData.wireId() && this.formData.amount() > 0;
    });

    this.cancelBtnToolTip = ko.computed(() => {
       return this.formData.hasPosted() ? "This advance has been posted. Please contact Card Services for assistance." : null;
    })

    this.handleClearForm = () => {
        const vendor = initFormData().advanceType == wireTypes.ADVANCE_EXPRESS ? wireTypes.VENDOR_EFS : null;
        const wireCode = initFormData().advanceType == wireTypes.ADVANCE_EXPRESS ? wireTypes.AOP_EXPRESS : null;
        let description = initFormData().advanceType == wireTypes.ADVANCE_EXPRESS ? "Issue AOP - Express Check" : "Issue AOP - Wire (Manual Entry)";

        let dataToKeep = {wireId: null, vendor, wireCode, description, advLimit: initFormData().aopLimit};

        onClearForm(true, dataToKeep);
    }

    this.clearFormBtnVisible = ko.computed(() => initFormData().advanceType != 'FUND');

    // when this mode we won't have enough init data to reset the form. So don't display
    this.resetToInitDataBtnVisible = ko.observable(false);
    
    initFormData.subscribe((val) => { 
        if(val && Object.keys(val).length) {
            let max = val.maxAllowed || 0;

            this.resetToInitDataBtnVisible(sendWireData.allowManualEntry == false && max > 0.00);
        }
        else {
            this.resetToInitDataBtnVisible(false);
        }
    }); 

    this.handleResetToInitData = async() => {

        if(initFormData().advanceType == wireTypes.ADVANCE_FUND) {
            
            let dataToKeep = {
                orderId: initFormData().orderId, 
                orderExId: initFormData().orderExId
            };

            
            onClearForm(false, dataToKeep);
        }
        else {
            onClearForm();
        }        
    }

    this.resetToInitDataText = ko.computed(() => { 

        if(initFormData().orderExId) {
            return `Reset to ${initFormData().orderExId}`;
        }
        else {
            return "Reset to Order Info";
        }
    });
}

import template from './efs-action-btn-row-component.html';
export default { viewModel: efsActionBtnRowViewModel, template: template }