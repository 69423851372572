
import GreatEdgeCustomWidgets from 'ge.custom-widgets';
import dataModel from 'data-model';
import {getDateTimestamp, datetimeUTC} from 'global-functions';
import userProfile from 'user-profile';
import toast from 'geNotyf';

class DriverSettlementsViewPage {
    constructor() {
        var self = this;
        self.isLoading = ko.observable(false);

        var today = new Date();
        const $grid = $(`#resultsGrid`);
        const $gridContainer = $('#gridContainer');

        const initFromDate = today.addDays(-7);
        const initToDate = today.addDays(1);
       
        var dateRangePicker = new GreatEdgeCustomWidgets().GEDateRangePickerWidget({
            bindToElement: 'geDateRangePicker',
            initialFromDate: initFromDate,
            initialToDate: initToDate,
            minAllowedYear: 1990
        });

        self.orderNumber = ko.observable();
        self.driver = ko.observable();
        self.error360Msg = ko.observable();
        self.isOwnerOperator = ko.observable(false);
        self.isUserAgent = ko.observable(false);
        self.display360Btn = ko.observable(false);
        self.displaySummaryDownloadBtn = ko.observable(false);

        self.generateListing = function () {
            self.error360Msg("");
            var validationErrors = ko.validation.group(self);

            if (validationErrors().length > 0)
                return false;

            $gridContainer.hide();

            var datesObj = dateRangePicker.getDates();

            if (datesObj == null || (datesObj.from == "Invalid date" || datesObj.to == "Invalid date")) {
                self.error360Msg("Please select a date range to search by.");
                return;
            }

            var data = {
                agencyId: userProfile.currentAgencyId(),
                beginDate: datesObj.from,
                endDate: datesObj.to,
                orderNumber: self.orderNumber(),
                driverID: self.driver()
            };

            self.isLoading(true);

            dataModel.ajaxRequest('Settlement/GetAvailableDriverSettlements', 'GET', data).done(function (data) {
                $gridContainer.show();
                var source = {
                    localdata: data.agencySecurables,
                    datatype: 'array',
                    datafields: [
                        { name: 'imageName', type: 'string' },
                        { name: 'driverID', type: 'string' },
                        { name: 'payDate', type: 'date' },
                        { name: 'settlementPay', type: 'float' },
                        { name: 'payeeID', type: 'string' }
                    ]
                };
                var dataAdapter = new $.jqx.dataAdapter(source, {
                    loadComplete: function (data) { self.isLoading(false); },
                });
                $grid.jqxGrid({
                    source: dataAdapter,
                    sortable: true,
                    pageable: true,
                    autoheight: true,
                    altrows: true,
                    columnsresize: true,
                    columnsmenu: false,
                    width: '100%',
                    showtoolbar: true,
                    rendertoolbar: (toolbar) => {
                        $(toolbar).html(`<div style="padding: 2px 5px;"><h6>Driver Settlement Listing</h6></div>`);
                    },
                    columns: [
                        { hidden: true, text: '', datafield: 'imageName' },
                        {
                            text: '',
                            width: 50,
                            filterable: false,
                            sortable: false,
                            columnType: 'button',
                            cellsalign: 'center',
                            buttonClick: function (row) {
                                self.isLoading(true);

                                var datarow = $grid.jqxGrid('getrowdata', row);
                                var date = (datarow.payDate.getMonth() + 1) + '/' + datarow.payDate.getDate() + '/' + datarow.payDate.getFullYear();
                                var data = { agencyId: dataModel.currentAgencyId, requestDate: date, payeeID: datarow.payeeID, imageName: datarow.imageName };

                                dataModel.ajaxRequest('Settlement/GetDriverSettlementDocument', 'GET', data).done(function (data, status, xhr) {
                                    
                                    var timestamp = getDateTimestamp();
                                    var _filename = data.payeeID != null ? data.payeeID : (data.driverID != null ? data.driverID : 'DriverSettlement(GeneratedOn' + timestamp + ')');
                                    _filename = _filename + '-ReportDate-' + date.replace(/\//g, '');
                                    //var filename = data.payeeID.trim() + '-' + date.replace(/\//g, '') + '.pdf';
                                    
                                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                        var newBlob = $.b64toBlob(data.pdfBytes, 'application/pdf', 512);
                                        window.navigator.msSaveOrOpenBlob(newBlob, _filename + + '.pdf');
                                    } else {
                                        var a = document.createElement('a');
                                        var pdfAsDataUri = 'data:application/pdf;base64,' + data.pdfBytes;
                                        //a.download = data.payeeID + ' - ' + date.replace("/", "");
                                        a.download = _filename;
                                        a.type = 'application/pdf';
                                        a.href = pdfAsDataUri;
                                        document.body.appendChild(a);
                                        a.click();
                                    }
                                    self.isLoading(false);
                                }).fail(function (error) {
                                    self.isLoading(false);
                                    const errorMsg = error.responseJSON && error.responseJSON.message || error.responseText || 'An error was encountered. Try again or contact your administrator.';
                                    toast.error({message: errorMsg, duration: 5000});
                                });
                            },
                            cellsrenderer: function () {
                                return "View";
                            }
                        },
                        { text: 'Driver', datafield: 'driverID', type: 'string', sortable: true, align: 'center', cellsalign: 'center', width: '35%' },
                        { text: 'Date', datafield: 'payDate', cellsformat: 'MM/dd/yyyy', type: 'date', sortable: true, align: 'right', cellsalign: 'right', width: 200 },
                        { text: 'Settlement Pay', datafield: 'settlementPay', cellsformat: 'C2', type: 'float', sortable: true, align: 'right', cellsalign: 'right', width: 200 },
                        { text: 'Payee', datafield: 'payeeID', type: 'string', sortable: true, align: 'center', cellsalign: 'center', width: '35%' }
                    ],
                });
            }).fail(function (error) {
                self.isLoading(false);
                toast.error('An error was encountered. Try again or contact your administrator.');
            });
        };

        self.driverExId = ko.observable();
        self.handleDriverSearch = (data) => {
            self.driverExId(data && data.code ? data.code : null);
        }
        self.generateFuelTaxListing = function () {
            self.error360Msg("");
            var validationErrors = ko.validation.group(self);

            if (validationErrors().length > 0) return false;
            $gridContainer.hide();

            var datesObj = dateRangePicker.getDates();

            if (datesObj == null || (datesObj.from == "Invalid date" || datesObj.to == "Invalid date")) {
                self.error360Msg("Please select a date range to search by.");
                return;
            }

            var data = {
                fromDate: datesObj.from,
                toDate: datesObj.to,
                driverExId: self.driverExId()
            };

            self.isLoading(true);

            dataModel.ajaxRequest('Settlement/GetAllDriverFuelTaxStatements', 'GET', data).done(function (records) {
                $gridContainer.show();
                var source = {
                    localdata: records,
                    datatype: 'array',
                    datafields: [
                        { name: 'drsd_imgnm', type: 'string' }, // hidden, but required to pull single record
                        { name: 'drsd_volid', type: 'string' }, // hidden, but required to pull single record
                        { name: 'drsd_no', type: 'string' }, // driver ex id
                        { name: 'drsd_scandt', type: 'date' }, // date
                    ]
                };
                var dataAdapter = new $.jqx.dataAdapter(source, {
                    loadComplete: function (data) { self.isLoading(false); },
                });
                $grid.jqxGrid({
                    source: dataAdapter,
                    sortable: true,
                    pageable: true,
                    autoheight: true,
                    altrows: true,
                    columnsresize: true,
                    columnsmenu: false,
                    width: '100%',
                    showtoolbar: true,
                    rendertoolbar: (toolbar) => {
                        $(toolbar).html(`<div style="padding: 2px 5px;"><h6>Driver Fuel Tax Statements</h6></div>`);
                    },
                    columns: [
                        {
                            text: '',
                            width: 50,
                            filterable: false,
                            sortable: false,
                            columnType: 'button',
                            cellsalign: 'center',
                            pinned: true,
                            buttonClick: function (row) {

                                self.isLoading(true);

                                const datarow = $grid.jqxGrid('getrowdata', row);
                                const payload = {
                                    driverExId: datarow.drsd_no && datarow.drsd_no.trim(),
                                    volid: datarow.drsd_volid && datarow.drsd_volid.trim(),
                                    imgname: datarow.drsd_imgnm && datarow.drsd_imgnm.trim()
                                }

                                const date = datetimeUTC(datarow.drsd_scandt);

                                dataModel.ajaxRequest('Settlement/GetDriverFuelTaxStatement', 'GET', payload).done(function (doc) {
                                    const driver = datarow.drsd_no ? "-" + datarow.drsd_no.trim() : "";
                                    const snDate = date.isValid() ? "-" + date.format("MMDDYYYY") : "";

                                    var _filename = `DriverFuelTaxStatement${driver}${snDate}.pdf`;
                                    
                                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                        var newBlob = $.b64toBlob(doc.pdfBytes, 'application/pdf', 512);
                                        window.navigator.msSaveOrOpenBlob(newBlob, _filename);
                                    } else {
                                        var a = document.createElement('a');
                                        var pdfAsDataUri = 'data:application/pdf;base64,' + doc.pdfBytes;
                                        a.download = _filename;
                                        a.type = 'application/pdf';
                                        a.href = pdfAsDataUri;
                                        document.body.appendChild(a);
                                        a.click();
                                    }
                                    self.isLoading(false);
                                }).fail(function (error) {
                                    self.isLoading(false);
                                    const errorMsg = error.responseJSON && error.responseJSON.message || error.responseText || 'An error was encountered. Try again or contact your administrator.';
                                    toast.error({message: errorMsg, duration: 5000});
                                });
                            },
                            cellsrenderer: function () {
                                return "View";
                            }
                        },
                        { text: 'Driver', datafield: 'drsd_no', type: 'string', sortable: true, align: 'center', cellsalign: 'center' },
                        { text: 'Date', datafield: 'drsd_scandt', cellsformat: 'MM/dd/yyyy', type: 'date', sortable: true, align: 'right', cellsalign: 'right' },
                    ],
                });
            }).fail(function (error) {
                self.isLoading(false);
                toast.error({message:'An error was encountered. Try again or contact your administrator.', duration: 5000});
            });
        };

        self.generate360Report = function () {
            self.error360Msg("");
           
            if (self.isOwnerOperator() == false && self.driver() == undefined) { self.error360Msg("Please select at a driver from the list."); return; }

            self.isLoading(true);

            var dataParams = {
                driverId: self.driver(),
                getBasicReport: true // GE-732 - Remove or comment out to get full report.
            }

            var timestamp = getDateTimestamp();
            var saveAsFilename = "Driver360SettlementReport(" + timestamp + ").xlsx";
            dataModel.downloadFile("Settlement/GetDriver360Report", "POST", dataParams, saveAsFilename).then(function (x) {
                self.isLoading(false);
            }).catch(function (error) {
                console.error(error);
                self.isLoading(false);
            });
        }

        self.downloadSettlementSummary = function () {
            self.error360Msg("");
            var validationErrors = ko.validation.group(self);

            if (validationErrors().length > 0)
                return false;

            if (self.isUserAgent() && self.driver() == undefined) { self.error360Msg("You must select a driver from the drop list to download a settlement summary."); return; }

            var datesObj = dateRangePicker.getDates();

            if (datesObj == null || (datesObj.from == "Invalid date" || datesObj.to == "Invalid date")) {
                self.error360Msg("Please select a date range to search by.");
                return;
            }

            self.isLoading(true);

            var dataParams = {
                driverId: self.driver(),
                startDate: datesObj.from,
                endDate: datesObj.to,
            }

            var timestamp = getDateTimestamp();
            var saveAsFilename = "DriverSettlementSummaryReport(" + timestamp + ").xlsx";
            dataModel.downloadFile("Settlement/GetDriverSummaryReport", "POST", dataParams, saveAsFilename).then(function (x) {
                self.isLoading(false);
            }).catch(function (error) {
                console.error(error);
                self.isLoading(false);
            });
        }

        self.resetInputs = () => {
            self.orderNumber(null);
            self.driver(null);
            self.error360Msg("");
            
            dateRangePicker.setStartDate(initFromDate);
            dateRangePicker.setEndDate(initToDate);
        }

        self.init = function () {
            self.isLoading(true);
            userProfile.getUserInfo().done(function (resp) {
                
                var display360Btn = userProfile.isOwnerOperator || (resp.roles.indexOf("Driver Settlements") > -1) || (resp.roles.indexOf("Administrators") > -1);
                var displaySummaryDownloadBtn = userProfile.isUserDriver || (resp.roles.indexOf("Driver Settlements") > -1) || (resp.roles.indexOf("Administrators") > -1);
                var isUserAgentOrAdmin = (resp.roles.indexOf("Agent") > -1) || (resp.roles.indexOf("Administrators") > -1);

                self.display360Btn(display360Btn);
                self.displaySummaryDownloadBtn(displaySummaryDownloadBtn);
                self.isUserAgent(isUserAgentOrAdmin);

            }).then(function (x) { self.isLoading(false) });
            
            
        }

        self.init();
    }
}

import template from './driver-settlements-page.html';
export default { viewModel: DriverSettlementsViewPage, template: template }