import hookFns from "./hookFns";
export default function lockPageOnGridScroll({ $grid = $() }) {
  const $body = $("body");
  const $main = $("#mainContent");
  const $contentPH = $("#divContentPlaceHolder");
  let paddingRight = 17;
  let width = $contentPH.width();

  $(window).resize(() => {
    width = $contentPH.width();
  });

  $grid.on("bindingcomplete", () => {
    // need to compensate for the scrollbar size and padding
    paddingRight = window.innerWidth - document.body.clientWidth;
  });

  $grid.on("mouseenter", (e) => {
    $body.css({ overflow: "hidden", "padding-right": paddingRight });
    $main.css({ "max-width": width });
  });

  $grid.on("mouseleave", (e) => {
    $main.css({ "max-width": "" });
    $body.removeAttr("style");
  });

  return (hooks = []) => {
    hookFns({ $grid })(hooks);
    return { $grid };
  };
}
