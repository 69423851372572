import config from 'config';
import L from 'mapquest';   //Defined in externals in webpack.config.js

const noop = (...x) => {};

class MapQuestWrapper {
    constructor() {
        this.mapId = "";
        this.map = {};
        this.markerLayerGrp = new L.layerGroup();
        this.polylineLayerGrp = new L.layerGroup();

        L.mapquest.key = config.mapquestApiKey;

        this.useFreeLayers = () => {
            L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(this.map);
        }

        this.addControl = (direction = "bottomleft" , onAdd = noop) => {
            const control = L.control({ position: direction });
            control.onAdd = onAdd;
            control.addTo(this.map);

            return control;
        }
    
        this.buildPoi = (id, latitude, longitude, info, title, iconColor, iconIndex, markerOptions = {}) => {
            L.marker([latitude, longitude], {
                icon: L.mapquest.icons.marker({
                    primaryColor: iconColor,
                    shadow: true,
                    size: 'sm',
                    symbol: iconIndex
                }),
                alt: title,
                title: title,
                ...markerOptions
            }).bindPopup(title + " " + info).addTo(this.markerLayerGrp);
        };
    
        this.addCollectionPoiToMap = (obj) => {
    
            var markerPoints = [];
            for (var i = 0; i < obj.length; i++) {
                // create array of latLng for use in setting view bounds later
                markerPoints.push(L.latLng(obj[i].latitude, obj[i].longitude));
                // create and add markers for each point
                this.buildPoi(obj[i].id, obj[i].latitude, obj[i].longitude, (obj[i].address || obj[i].info), (obj[i].title || obj.length - i), obj[i].iconColor, (obj[i].symbol || obj.length - i));
            }
            
            this.markerLayerGrp.addTo(this.map);

            // display bounds of map
            var bounds = new L.latLngBounds((markerPoints.length && markerPoints) || [L.latLng(35.64701, -95.39425)]);
            this.map.fitBounds(bounds);  
        };

        this.refreshCollection = (pointdata = []) => {
            this.markerLayerGrp.clearLayers();

            var markerPoints = [];
            for (var i = 0; i < pointdata.length; i++) {
                // create array of latLng for use in setting view bounds later
                markerPoints.push(L.latLng(pointdata[i].latitude, pointdata[i].longitude));
                // create and add markers for each point
                this.buildPoi(pointdata[i].id, pointdata[i].latitude, pointdata[i].longitude, (pointdata[i].address || pointdata[i].info || ""), (pointdata[i].title || pointdata.length - i), pointdata[i].iconColor, pointdata[i].symbol, pointdata[i].markerOptions);
            }

            this.markerLayerGrp.addTo(this.map);
    
            // display bounds of map
            var bounds = new L.latLngBounds((markerPoints.length && markerPoints) || [L.latLng(35.64701, -95.39425)]);
            this.map.fitBounds(bounds);  
        };
        
        this.refreshPolylineCollection = (pointdata = []) => {
            this.polylineLayerGrp.clearLayers();

            if(pointdata.length) {
                const polyline = L.polyline(pointdata).addTo(this.polylineLayerGrp);
                this.polylineLayerGrp.addTo(this.map);

            }
        }

        this.clearLayers = () => {
            this.markerLayerGrp.clearLayers();
            this.polylineLayerGrp.clearLayers();
        }

        this.clearPolylineLayer = () => {
            this.polylineLayerGrp.clearLayers();
        }
    
        this.clearMap = () => {
            // clear any pre-existing data
            if (this.mapId != "") {
                
                if(this.map.remove) {
                    this.map.remove();
                }
                
                this.map = {};
            }
        }
    
        this.loadMap = (mapId, options = {}) => {
            this.clearMap();
            
            this.mapId = mapId;
    
            var defaults = {
                layers: L.mapquest.tileLayer('map'),
                zoom: 10,                     /*initial zoom level of map*/
                minZoom: 3,
                center: [40.0, -100.0],       /*initial center of map in latitude,longitude*/
                trackResize: true,
                fitBounds: true
            };

            this.map = L.map(mapId,      /*ID of element on the page where you want the map added*/
                                {...defaults, ...options});  /* initial map state options */
            
            if(options.useFreeLayers) {
                this.useFreeLayers();
            }

            return this.map;
        };
    }
}


export default MapQuestWrapper