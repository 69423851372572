import dataModel from "data-model";
import userProfile from "user-profile";
import http from "./httpService";
import { getUniqueValuesOfKey } from "global-functions";
import {showmessage} from '../../../shared-components/dialog-components/show-dialog-methods';

class EDITenderSetupViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.isLoading = ko.observable(false);
        this.isProcessing = ko.observable(false);

        this.errors = ko.observableArray();
        this.userFocus = ko.observable(false);
        this.displaySuccess = ko.observable(false);

        this.selectedUser = ko.observable()
            .extend({ required: { message: "Select a user" } });
        this.users = ko.observableArray();
        this.tenderTypes = ko.observableArray()
            .extend({ required: { message: "Select a type" } });
        this.customers = ko.observable(null);
        this.allLocations = [];
        this.locations = ko.observable(null);
        this.selectedUserEmail = ko.observable().extend({ required: {
            onlyIf: () => {
                !this.originalUserEmail;
            },
            message: "Please enter a valid email."
        }, uppercase: true });

        this.grid = $("#ediSubscriptions");
        this.subscriptionGridId = "#ediSubscriptions";

        this.displayTotalSubscriptionRemovalVerificationMessage =
            ko.observable(false);
        this.removeAll = false;

        this.emailChanged = ko.observable(false);
        this.selectedCustomers = ko.observableArray();
        this.selectedLocations = ko.observableArray();
        this.displayRemoveAllSubscriptionVerificationMessage =
            ko.observable(false);

        this.focusUserInput = () => {
            $("#userInput input").focus();
        };
        this.availableHeader = '<div class="multi-select-header">Available</div>';
        this.selectedHeader = '<div class="multi-select-header">Selected</div>';

        ko.extenders.uppercase = (target, option) => {
            target.subscribe((newValue) => {
                if (newValue) target(newValue.toUpperCase());
            });
            return target;
        };

        this.originalUserEmail = "";

        this.ediUserSelected = (sender) => {
            if (sender != undefined) {
                this.getUserEmail(sender.code);
            }
        };

        this.initializeComponent();
    }

    initializeComponent = () => {
        this.getTenderTypes();
    };

    handleUserEmailChanged = () => {
        var email = $("#txt_UserEmail").val() || "";
        this.emailChanged(
            email && email.toLowerCase() != this.originalUserEmail.toLowerCase()
        );
    };

    sortOptionsArrayByText = (options) => {
        return options.sort((a, b) => {
            return $(a).text().toUpperCase() < $(b).text().toUpperCase()
                ? -1
                : 1;
        });
    };

    sortOptionsByText = (select) => {
        $(select).html(
            $(select)
                .children("option")
                .sort((a, b) => {
                    return $(a).text().toUpperCase() < $(b).text().toUpperCase()
                        ? -1
                        : 1;
                })
        );
    };

    sortObjectsByName = (objA, objB) => {
        if (objA.name && objB.name) {
            var nameA = objA.name.toUpperCase();
            var nameB = objB.name.toUpperCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        }
    };

    selectCustomer = (Multiselect, $options, event, silent, skipStack) => {
        $.each($options, function (i, o) {
            Multiselect.$right[0].append(o);
            this.selectedCustomers.push(parseInt(o.value));
        });
        this.sortOptionsByText(Multiselect.$right[0]);
    };

    removeCustomers = () => {
        $("#customer_multiselect, #customer_multiselect_to").empty();
        this.selectedCustomers([]);
    };

    deselectCustomer = (Multiselect, $options, event, silent, skipStack) => {
        var removedCustomersExternalIds = [];
        $.each($options, function (i, o) {
            Multiselect.$left[0].append(o);
            removedCustomersExternalIds.push($(o).data("external-id"));
            this.selectedCustomers(
                this.selectedCustomers().filter(function (c) {
                    return c !== parseInt(o.value);
                })
            );
        });
        this.sortOptionsByText(Multiselect.$left[0]);
    };

    selectLocation = (Multiselect, $options, event, silent, skipStack) => {
        $.each($options, function (i, o) {
            Multiselect.$right[0].append(o);
            this.selectedLocations.push(parseInt(o.value));
        });
        this.sortOptionsByText(Multiselect.$right[0]);
    };

    deselectLocation = (Multiselect, $options, event, silent, skipStack) => {
        var removedLocationIds = [];
        $.each($options, function (i, o) {
            Multiselect.$left[0].append(o);
            removedLocationIds.push($(o).data("external-id"));
            this.selectedLocations(
                this.selectedLocations().filter(function (c) {
                    return c !== parseInt(o.value);
                })
            );
        });
        this.sortOptionsByText(Multiselect.$left[0]);
    };

    organizeLocations = (targetSelected) => {
        var select = $("#location_multiselect" + (targetSelected ? "_to" : ""));
        $("optgroup", select)
            .sort(function (a, b) {
                return $(a).text().localeCompare($(b).text());
            })
            .appendTo(select);

        $("optgroup", select).each(function () {
            var optgroup = this;
            $("option", optgroup)
                .sort(function (a, b) {
                    return $(a).text().localeCompare($(b).text());
                })
                .appendTo(optgroup);
        });

        this.clearEmptyOptgroups();
    };

    clearEmptyOptgroups = () => {
        $("optgroup").each(function () {
            if ($("option", this).length === 0) $(this).remove();
        });
    };

    handleTotalSubscriptionRemovalVerification = (remove) => {
        if (remove && this.selectedUser()) {
            this.removeAll = true;
            this.handleUpdateSubscriptions();
        }

        this.displayTotalSubscriptionRemovalVerificationMessage(false);
    };

    handleShowRemoveAllSubscriptionVerificationMessage = () => {
        let newvalue = !this.displayRemoveAllSubscriptionVerificationMessage();

        this.displayRemoveAllSubscriptionVerificationMessage(newvalue);
    };

    resetForm = (callback) => {
        this.errors([]);
        this.isLoading(false);
        this.selectedUser(null);
        this.selectedUserEmail("");
        this.originalUserEmail = "";
        this.selectedCustomers.removeAll();
        this.selectedLocations.removeAll();
        this.locations(null);
        this.customers(null);
        $("#tenderTypes option").prop("selected", false);
        $(
            "#customer_multiselect, #customer_multiselect_to, #location_multiselect, #location_multiselect_to"
        ).empty();
        $("#ediSubscriptions").jqxGrid("destroy");
        $("#ediSubscriptionsContainer").append(
            $("<div>").attr("id", "ediSubscriptions")
        );
        $("#userInput input").focus();
        this.resetDisplaySuccess();
        if (callback && typeof callback === "function") callback();
    };

    buildSubscriptionGrid = (sourceData) => {
        sourceData = sourceData || [];
        const vm = this;

        const source = {
            localdata: sourceData,
            datatype: "array",
            datafields: [
                { name: "subscriptionId", type: "number" },
                { name: "email", type: "string" },
                { name: "type", type: "string" },
                { name: "customer", type: "string" },
                { name: "location", type: "string" },
            ],
        };

        const dataAdapter = new $.jqx.dataAdapter(source, {
            loadComplete(data) {
                vm.isLoading(false);
            },
        });

        $(vm.subscriptionGridId).jqxGrid({
            source: dataAdapter,
            sortable: true,
            pageable: true,
            autoheight: true,
            altrows: true,
            columnsresize: true,
            width: "100%",
            columns: [
                {
                    text: "",
                    datafield: "subscriptionId",
                    type: "number",
                    width: 0,
                    sortable: false,
                    menu: false,
                    hidden: true,
                },
                {
                    text: "Email",
                    datafield: "email",
                    type: "string",
                    width: 240,
                    sortable: false,
                    menu: false,
                },
                { text: "Type", datafield: "type", type: "string", width: 100 },
                {
                    text: "Customer",
                    datafield: "customer",
                    type: "string",
                    width: 210,
                },
                { text: "Location", datafield: "location", type: "string" },
            ],
        });

        if (!sourceData.length) {
            var localizationObject = {};
            localizationObject.emptydatastring =
                "This user is not currently subscribed to receive notifications for any customers/locations.";
            $(vm.subscriptionGridId).jqxGrid(
                "localizestrings",
                localizationObject
            );
        }
    };

    resetDisplaySuccess = () => {
        $("#successMessage").text("");
        this.displaySuccess(false);
    };

    async getLocations() {
        this.locations(null);
        var payload = {
            userId: this.selectedUser(),
            agencyId: userProfile.currentAgencyId(),
        };

        const result = await http
            .getLocationsAsync(payload)
            .catch((err) => console.error("Failed to retrieve locations."));

        if (result != undefined) {
            if (result.available) result.available.sort(this.sortObjectsByName);

            if (result.selected) result.selected.sort(this.sortObjectsByName);

            this.locations(result);
        }
    }

    async getUsers() {
        const userData = await http
            .getUsersAsync({ agencyId: userProfile.currentAgencyId() })
            .catch((err) => console.log(err));

        if (userData) {
            this.users.push(userData);
        }
    }

    async getUserEmail(userExId) {
        this.isLoading(false);

        const email = await http
            .getUserEmailAsync(userExId)
            .catch((err) =>
                console.error(`Error retrieving user "${userExId}" email.`)
            );

        if (email != undefined) {
            this.originalUserEmail = email || "";
            this.selectedUserEmail(email);
        }

        
        this.removeCustomers();
        this.getCustomers();
        this.getLocations();
        this.getSubscriptions();

        this.isLoading(false);
    }

    async getSubscriptions(callback) {
        var payload = { userId: this.selectedUser() };

        const resultData = await http
            .getSubscriptionsAsync(payload)
            .catch((err) => console.error("Failed to retrieve subscriptions."));

        if (resultData != undefined) {
            const tenderTypes = getUniqueValuesOfKey(resultData, "type").map(
                function (t) {
                    return t.toLowerCase();
                }
            );
            $("#tenderTypes option").each(function (i, o) {
                $(o).prop(
                    "selected",
                    tenderTypes.indexOf($(o).text().toLowerCase()) > -1
                );
            });

            this.buildSubscriptionGrid(resultData);

            if (callback && typeof callback === "function") callback();
        }
    }

    handleUpdateSubscriptions = () => {
        this.doHandleUpdateSubscriptions();
    };

    async doHandleUpdateSubscriptions() {
        this.resetDisplaySuccess();
        const tenderTypes = $("#tenderTypes").val();

        if (!this.selectedUser()) return false;

        if (!tenderTypes) {
            $("#typeErrorMessage").show();
            return false;
        }

        if(!this.selectedUserEmail()) {
            showmessage("Please enter a valid email address to setup subscription(s).");
            return false;
        }

        $("#typeErrorMessage").hide();

        if (!tenderTypes && !this.removeAll) {
            var subscriptions = $("#ediSubscriptions").jqxGrid("getrows");
            if (subscriptions.length) {
                this.isLoading(false);
                this.displayTotalSubscriptionRemovalVerificationMessage(true);
                return;
            }
        }

        this.removeAll = false;

        var payload = {
            agencyId: userProfile.currentAgencyId(),
            userId: this.selectedUser(),
            userEmail: this.selectedUserEmail(),
            tenderTypes: tenderTypes
                ? tenderTypes.map(function (v) {
                      return parseInt(v);
                  })
                : [],
            selectedCustomersIds: this.selectedCustomers().map(function (v) {
                return parseInt(v);
            }),
            selectedLocationsIds: this.selectedLocations().map(function (v) {
                return parseInt(v);
            }),
        };

        this.isLoading(true);
        const result = await http
            .updateSubscriptionsAsync(payload)
            .catch((err) => console.error("Failed to update subscriptions."));

        if (result != undefined) {
            this.getSubscriptions();
        }

        this.isLoading(false);
    }

    handleRemoveAllEdiSubscriptions = () => {
        this.doHandleRemoveAllEdiSubscriptions();
    };

    async doHandleRemoveAllEdiSubscriptions() {
        if (this.selectedUser()) {
            var payload = {
                agencyId: userProfile.currentAgencyId(),
                userId: this.selectedUser(),
                userEmail: this.selectedUserEmail(),
            };

            this.isLoading(true);
            const result = await http
                .removeSubscriptionsAsync(payload)
                .catch((err) =>
                    console.error("Removing subscriptions failed.")
                );

            if (result != undefined) {
                this.getSubscriptions();
                this.displayRemoveAllSubscriptionVerificationMessage(false);
                this.selectedCustomers.removeAll();
                this.selectedLocations.removeAll();
            }

            this.isLoading(false);
        }
    }

    handleUpdateSelectedUserEmail = () => {
        this.doHandleUpdateSelectedUserEmail();
    };

    async doHandleUpdateSelectedUserEmail() {
        this.isLoading(true);
        let newEmail = $("#txt_UserEmail").val().trim();

        const result = await http
            .updateUserEmailAsync({
                userId: this.selectedUser(),
                newEmail: newEmail,
            })
            .catch((err) => {
                if (err.status === 404) {
                    if (
                        !this.errors().filter((err) => err.subject === "Email")
                            .length
                    )
                        this.errors.push({
                            subject: "Email",
                            message:
                                "User was not found. Cannot update contact information. Please contact administrator.",
                        });
                }
            });

        if (result != undefined) {
            this.originalUserEmail = newEmail;
            this.handleUserEmailChanged();
        }

        this.isLoading(false);
    }

    async getTenderTypes() {
        const types = await http
            .getTenderTypesAsync()
            .catch((err) => console.error("Failed to retrieve tender types."));

        if (types != undefined) this.tenderTypes(types);
    }

    async getCustomers() {
        this.customers(null);

        var payload = {
            userId: this.selectedUser(),
            agencyId: userProfile.currentAgencyId(),
        };

        var result = await http
            .getCustomersAsync(payload)
            .catch((err) => console.error("Failed to retrieve customers."));

        if (result != undefined) {
            result.available = result.available.map((item) => {
                item = {
                    customerId: item.customerId,
                    customerName: item.customerName,
                    externalId: item.externalId,
                    id: item.id,
                    name: item.name,
                    selected: item.selected,
                    displayProperty: item.name + "-" + item.externalId,
                };
                return item;
            });

            result.available.sort(this.sortObjectsByName);

            result.selected.map((item) => {
                item = {
                    customerId: item.customerId,
                    customerName: item.customerName,
                    externalId: item.externalId,
                    id: item.id,
                    name: item.name,
                    selected: item.selected,
                    displayProperty: item.name + "-" + item.externalId,
                };
                return item;
            });

            result.selected.sort(this.sortObjectsByName);
            this.customers(result);
        }
    }
}

import template from "./edi-tender-setup-component.html";
export default { viewModel: EDITenderSetupViewModel, template: template };
