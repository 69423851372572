import dataModel from "data-model";
import gridState from "jqx.grid-state-component";
import gridStateUtils from "jqx.grid-utils";
import userProfile from "user-profile";
import { StarRatingWidget } from "ge.custom-widgets";
import { isEmpty } from "global-functions";

class MyFavoritesModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.myFavoritesGridState = null;
        this.dropdownItems = ko.observable();
        this.ratingUpdated = false;
        this.counter = 0;
        this.isMyFavoritesGridLoading = ko.observable(false);
        this._myFavoritesInitLoad = true;
        this.grid = $("#myFavoritesGrid");

        this.initializeComponent();
    }

    async initializeComponent() {
        this.loadFavoritesList(); // Go ahead and load this
        await this.setUserFilters(); // And load this, but wait here until finished, then
        this.loadGrid(); // load this
    }

    get favoritesGridSource() {
        return {
            url: "MyAgency/LoadMyFavorites",
            datatype: "json",
            formatdata(data) {
                data = data || {};
                data.agencyId = userProfile.currentAgencyId();

                return data;
            },
            datafields: [
                { name: "name", type: "string" },
                { name: "objectId", type: "string" },
                { name: "favoriteValue", type: "int" },
                { name: "favoriteTableId", type: "int" },
                { name: "favoriteId", type: "int" },
                { name: "code", type: "string" },
                { name: "favorite", type: "string" },
            ],
        };
    }

    async setUserFilters() {
        let filters = await gridStateUtils
            .getUserGridFiltersAsync("myFavoritesGrid")
            .catch((err) => console.error("Failed to retrieve user filters."));
        if (isEmpty(filters) == false) {
            let state = JSON.parse(filters);
            this.myFavoritesGridState = state.grid;
        }
    }

    async loadFavoritesList() {
        const items = await this.http_sendGetMyFavoritesListAsync().catch(
            (err) => console.error("Failed to retrieve favorites list.")
        );
        this.dropdownItems(items);
    }

    loadGrid = () => {
        let vm = this;
        let dataAdapter = dataModel.getDataAdapter(this.favoritesGridSource);

        if (this.grid.length == 0) this.grid = $("#myFavoritesGrid");

        this.grid
            .jqxGrid({
                theme: "GWTMDark",
                width: "100%",
                height: "330px",
                filter: gridStateUtils.handleClientSideFiltering,
                source: dataAdapter,
                altrows: true,
                showstatusbar: true,
                sortable: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                statusbarheight: 35,
                showtoolbar: true,
                rendertoolbar: (toolbar) => {
                    let vm1 = new gridState.viewModel();

                    vm1.actions = [
                        "Clear Filters",
                        "Clear Selection",
                        "Refresh",
                        "Set Default Search",
                    ];
                    vm1.initializeDefaultToolbar(toolbar);

                    vm1.notifyLocation = this.grid
                        .closest(".modal-content")
                        .find(".modal-header");
                },

                rendergridrows(obj) {
                    return obj.data;
                },

                columns: [
                    {
                        text: "Type",
                        datafield: "name",
                        filterType: "list",
                        filterItems: vm.dropdownItems(),
                        width: 140,
                    },
                    { text: "Object Id", datafield: "objectId", hidden: true },
                    {
                        text: "Favorite Table ID",
                        datafield: "favoriteTableId",
                        hidden: true,
                    },
                    {
                        text: "Favorite Id",
                        datafield: "favoriteId",
                        hidden: true,
                    },
                    { text: "Code", datafield: "code", width: 200 },
                    { text: "Favorite", datafield: "favorite", width: 280 },
                    {
                        text: StarRatingWidget.text,
                        datafield: StarRatingWidget.datafield,
                        width: 140,
                        filtertype: StarRatingWidget.filtertype,
                        cellsrenderer: StarRatingWidget.cellsrenderer,
                        filteritems: StarRatingWidget.filteritems,
                        createfilterwidget: StarRatingWidget.createfilterwidget,
                    },

                    {
                        text: "",
                        width: 60,
                        filterable: false,
                        sortable: false,
                        columnType: "button",
                        buttonclick(row) {
                            var datarow = vm.grid.jqxGrid("getrowdata", row);
                            vm.handleDeleteFavorite(datarow);
                        },
                        cellsrenderer() {
                            return "Delete";
                        },
                    },
                ],

                ready() {
                    gridStateUtils.applyGridState(
                        "myFavoritesGrid",
                        vm.myFavoritesGridState
                    );
                    vm._myFavoritesInitLoad = false;
                },
            })
            .bind("filter", () => {
                gridStateUtils.logGridFilterResults("#myFavoritesGrid");
            });
    };

    handleDeleteFavorite = (favorite) => {
        this.doHandleDeleteFavorite(favorite);
    };

    async doHandleDeleteFavorite(favorite) {
        const payload = {
            table: favorite.name,
            objectId: favorite.favoriteId,
            agencyId: userProfile.currentAgencyId(),
        };

        await this.http_sendDeleteFavoriteAsync(payload).catch((err) =>
            console.log("Failed to delete favorite.")
        );

        this.$emitsOnAction();
        this.refreshGrid();
    }

    refreshGrid = () => {
        this.grid.jqxGrid("updatebounddata", "data");
    };

    http_sendGetMyFavoritesListAsync = () => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/GetFavoritesList", "get")
                .done((data) => resolve(data))
                .fail((err) => reject(err));
        });
    };

    http_sendDeleteFavoriteAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/DeleteFavorite", "delete", payload)
                .done(() => resolve(true))
                .fail((err) => reject(err));
        });
    };
}

import template from "./my-favorites-component.html";
const MyFavoritesComponent = {
    viewModel: MyFavoritesModel,
    template: template,
};

export { MyFavoritesModel };
export default MyFavoritesComponent;
