// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#lyHgkt0LHJsigvCHDgkkJA\\=\\= .egRM9Rsjt\\+xt3pomG8Vb1g\\=\\= {\r\n    padding-top:16px;\r\n    text-align: right;\r\n}\r\n#lyHgkt0LHJsigvCHDgkkJA\\=\\= .Ano9lBVxQjXfmqXJoCppVQ\\=\\= {\r\n    padding-top: 16px;\r\n}\r\n\r\n#lyHgkt0LHJsigvCHDgkkJA\\=\\= table td {\r\n    width: 265px;\r\n    border-bottom: 1px solid lightgrey;\r\n}", "",{"version":3,"sources":["webpack://./src/home/pages/csa-exceptions-page/csaExceptionsCustomStyles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,kCAAkC;AACtC","sourcesContent":["#csa-exceptions-page .labelDiv {\r\n    padding-top:16px;\r\n    text-align: right;\r\n}\r\n#csa-exceptions-page .inputDiv {\r\n    padding-top: 16px;\r\n}\r\n\r\n#csa-exceptions-page table td {\r\n    width: 265px;\r\n    border-bottom: 1px solid lightgrey;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"csa-exceptions-page": "lyHgkt0LHJsigvCHDgkkJA==",
	"labelDiv": "egRM9Rsjt+xt3pomG8Vb1g==",
	"inputDiv": "Ano9lBVxQjXfmqXJoCppVQ=="
};
export default ___CSS_LOADER_EXPORT___;
