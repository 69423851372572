import ko from 'knockout'
import dataModel from 'data-model';
import template from './load-import-page.html';
import {showmessage} from 'show-dialog-methods';
import _ from 'lodash';


class LoadImportViewModel {
    constructor() {
        this.isLoading = ko.observable(false);
        this.showSuccess = ko.observable();
        this.file;
    }

    fileSelect(element, event)  {
        this.file = event.target.files[0];
    }

    uploadCSV() {
        if(this.file) {
            if(this.file.name.search('.csv') != -1) {
                let formData = new FormData();
                formData.append('UploadedFile', this.file);
                this.isLoading(true)
                dataModel.ajaxUploadRequest("FreightBoard/UploadLoads", "POST", formData)
                .done((uploadedFileName, status, xhr) => {
                    this.isLoading(false);
                })
                .fail((jqXHR, textStatus, errorThrown) => {
                    if(jqXHR.responseJSON && jqXHR.responseJSON.message) {
                        showmessage(jqXHR.responseJSON.message);
                    } else {
                        showmessage("An error has occured.", "danger");
                    }

                    this.isLoading(false);
                });
            } else {
                showmessage("Only files with the .csv extension are allowed.", "danger");
            }
        }
    }
    
}


export default {viewModel: LoadImportViewModel, template: template}