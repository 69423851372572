import dataModel from 'data-model';
import dayjs from 'dayjs';
import gridState from 'jqx.grid-state-component';
import { isEmpty } from 'global-functions';
import gridUtils from 'jqx.grid-utils';
import '../../../../../../Content/app/GWTMDark.css';
import router from 'router';
import { scrollToRequiredField } from 'global-functions';

class FieldOccurrenceRecapModel {
    constructor(data) {
        var self = this;

        self.isReadOnly = data.isReadOnly;
        self.id = ko.observable(data.id);
        self.isEdit = ko.computed(function () {
            return self.id() > 0;
        });
        self.region = ko.observable(data.region);
        self.manager = ko.observable(data.manager).extend({
            required: true
        });
        self.weeklyPeriodEndingOn = ko.observable(data.weeklyPeriodEndingOn).extend({
            required: true
        });
        self.postDate = ko.observable(data.postDate).extend({
            required: true
        });
        self.agencyCode = ko.observable(data.agencyCode).extend({
            required: true,
            maxLength: {
                message: "Max length exceeded.",
                params: 60
            }
        });
        self.agentName = ko.observable(data.agentName).extend({
            required: true,
            maxLength: {
                message: "Max length exceeded.",
                params: 100
            }
        });
        self.city = ko.observable(data.city).extend({
            required: true,
            maxLength: {
                message: "Max length exceeded.",
                params: 60
            }
        });
        
        self.state = ko.observable(data.state);
        self.reasonOfVisit = ko.observable(data.reasonOfVisit).extend({
            required: true
        });
        self.recapOfMeeting = ko.observable(data.recapOfMeeting);
        self.followUpWorkNeeded = ko.observable(data.followUpWorkNeeded);

        self.areasInNeedOfFreight = ko.observable(data.areasInNeedOfFreight).extend({
            maxLength: {
                message: "Max length exceeded.",
                params: 100
            }
        });
        self.areasInNeedOfTrucks = ko.observable(data.areasInNeedOfTrucks).extend({
            maxLength: {
                message: "Max length exceeded.",
                params: 100
            }
        });
        self.unbilledOrdersComments = ko.observable(data.unbilledOrdersComments);
        self.arReportComments = ko.observable(data.arReportComments);
        self.reviewEquipment = ko.observable(data.reviewEquipment).extend({
            maxLength: {
                message: "Max length exceeded.",
                params: 60
            }
        });
        self.claimsAccidentReview = ko.observable(data.claimsAccidentReview);
        self.lossSafetyCompliance = ko.observable(data.lossSafetyCompliance);
        self.agencyInformation = ko.observable(data.agencyInformation);
        self.recapInformation = ko.observable(data.recapInformation);
    }
}

class EditFieldOccurrenceRecapsViewModel {
    constructor() {
        var self = this;

        self.errors = ko.observableArray();
        self.isLoading = ko.observable(false);
        self.regions = [
            { value: '', name: 'Please Choose...'},
            { value: 'Midwest Region', name: 'Midwest Region' },
            { value: 'Northeast Region', name: 'Northeast Region' },
            { value: 'Southeast Region', name: 'Southeast Region' },
            { value: 'Southwest Region', name: 'Southwest Region' },
            { value: 'Canada', name: 'Canada' }
        ];
        //self.states = states;
        self.fieldOccurrenceRecap = ko.observable();

        self.loadFieldOccurrenceRecap = function () {
            var forId = window.location.pathname.split("/")[2];
            if (forId == undefined) {
                var fieldOccurrenceRecap = self.fieldOccurrenceRecap();
                if (fieldOccurrenceRecap != null)
                    forId = fieldOccurrenceRecap.id();
            }
            if (forId != undefined) {
                self.isLoading(true);

                dataModel.ajaxRequest('fieldoccurrencerecap/' + forId, 'GET')
                    .done(function (data) {
                        self.fieldOccurrenceRecap(new FieldOccurrenceRecapModel(data));
                        self.isLoading(false);
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        self.errors.push("Unable to load field occurrence recap");
                        self.isLoading(false);
                    });
            }
            else {
                self.fieldOccurrenceRecap(new FieldOccurrenceRecapModel({}));
            }
        };

        self.saveFieldOccurrenceRecap = function () {
            self.errors.removeAll();

            var validationErrors = ko.validation.group(self.fieldOccurrenceRecap);

            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();

                scrollToRequiredField();

                self.errors.push("Please check input and try again");

                return false;
            }

            var data = ko.toJS(self.fieldOccurrenceRecap);
            
            self.isLoading(true);
            if (data.id != null) {
                dataModel.ajaxRequest("FieldOccurrenceRecap/" + data.id, "PUT", data)
                    .done(function (data, textStatus, jqXHR) {
                        self.isLoading(false);
                        //location.href = "/FieldOccurrenceRecaps";
                        router.navigate('/FieldOccurrenceRecaps');
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        if (jqXHR.responseJSON != null) {
                            var json = jqXHR.responseJSON;
                            if (json.message != null) {
                                var errors = json.message.split(";");
                                self.errors(errors);
                            }
                        }
                        self.isLoading(false);
                    });
            } else {
                dataModel.ajaxRequest("FieldOccurrenceRecap", "POST", data)
                    .done(function (data, textStatus, jqXHR) {
                        self.isLoading(false);
                        //location.href = "/FieldOccurrenceRecaps";
                        router.navigate('/FieldOccurrenceRecaps');
                    })
                    .fail(function (jqXHR, textStatus, errorThrown) {
                        if (jqXHR.responseJSON != null) {
                            var json = jqXHR.responseJSON;
                            if (json.message != null) {
                                var errors = json.message.split(";");
                                self.errors(errors);
                            }
                        }
                        self.isLoading(false);
                    });
            }
        };

        self.cancel = function () {
            //location.href = '/FieldOccurrenceRecaps';
            router.navigate('/FieldOccurrenceRecaps');
        };

        self.loadFieldOccurrenceRecap();
    }
}

import template from './edit-field-occurrence-page.html';
export default { viewModel: EditFieldOccurrenceRecapsViewModel, template: template }