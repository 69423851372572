import * as ko from "knockout";
import $ from "jquery";
import config from "config";
import { get_browser_info } from "./home/shared-components/global-functions";
import dayjs from "dayjs";
class DataModel {
  url = config.apiUrl;

  //These are set in user-profile.  Can't import user-profile here b/c it creates a circular reference.
  currentAgencyId = ko.observable();
  securityHeaders = undefined;
  dateTimeFormat;
  constructor() {}

  ajaxRequest = (route, type, param, excludeAgencyId) => {
    type = type == null ? "GET" : type;
    let data = param || {};
    excludeAgencyId = excludeAgencyId || false;
    if (excludeAgencyId == false) {
      $.extend(data, { agencyId: this.currentAgencyId() });
    }

    if (type.toLowerCase() == "get") {
      var urlParams = $.param(data);
      if (urlParams != "") {
        route = route + "?" + $.param(data);
      }
      data = {};
    } else {
      data = data ? ko.toJSON(data) : null;
    }

    $.support.cors = true; // ie9,ie8 fix

    var options = {
      url: this.url + "api/GreatEdge/" + route,
      headers: this.securityHeaders,
      type: type,
      data: data,
      contentType: "application/json",
    };
    return $.ajax(options);
  };

  sjaxRequest = (route, type, param) => {
    type = type == null ? "GET" : type;
    let data = param || {};
    $.extend(data, { agencyId: this.currentAgencyId() });
    // data = type.toLowerCase() == "get" ? data : data ? ko.toJSON(data) : null;

    if (type.toLowerCase() == "get") {
      var urlParams = $.param(data);
      if (urlParams != "") {
        route = route + "?" + $.param(data);
      }
      data = {};
    } else {
      data = data ? ko.toJSON(data) : null;
    }

    $.support.cors = true; // ie9,ie8 fix

    var options = {
      url: this.url + "api/GreatEdge/" + route,
      headers: this.securityHeaders,
      type: type,
      data: data,
      contentType: "application/json",
      async: false,
    };
    return $.ajax(options);
  };

  ajaxUploadRequest = (route, type, file) => {
    type = type == null ? "POST" : type;
    var uploadFile = file != null ? file : {};
    $.support.cors = true; // ie9,ie8 fix
    return $.ajax({
      url:
        this.url +
        "api/GreatEdge/" +
        route +
        "?agencyId=" +
        this.currentAgencyId(),
      headers: this.securityHeaders,
      type: type,
      data: uploadFile,
      contentType: false,
      processData: false,
    });
  };

  downloadFile = (route, type, param, filename) => {
    return new Promise((resolve, reject) => {
      let data = param || {};
      data.agencyId = this.currentAgencyId();
      var options;
      var token = this.getCookie("GreatwideAPItoken");
      if (type.toLowerCase() == "get") {
        var urlParams = $.param(data);
        if (urlParams != "") {
          route = route + "?" + $.param(data);
        }
        options = {
          method: type,
          mode: "cors",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
      } else {
        data = data ? ko.toJSON(data) : null;
        options = {
          method: type,
          mode: "cors",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: data,
        };
      }

      if (get_browser_info().name.toLowerCase() == "msie") {
        reject();
      } else {
        fetch(this.url + "api/GreatEdge/" + route, options)
          .then((resp) => {
            if (resp.ok) {
              resp.blob().then((blob) => {
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                  var url = window.URL.createObjectURL(blob);
                  var a = document.createElement("a");
                  a.style.display = "none";
                  a.href = url;
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                }
                resolve();
              });
            } else {
              reject(resp.statusText);
            }
          })
          .catch((err) => {
            console.error(err);
            reject(`Error occurred during request.`);
          });
      }
    });
  };

  downloadFileStreamAsync = async (route, type, param, filename) => {
    try {
      let data = param || {};
      data.agencyId = this.currentAgencyId();
      let options = {};

      const token = this.getCookie("GreatwideAPItoken");
      if (type.toLowerCase() == "get") {
        const urlParams = $.param(data);
        if (urlParams != "") {
          route = route + "?" + $.param(data);
        }
        options = {
          method: type,
          mode: "cors",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
      } else {
        data = data ? ko.toJSON(data) : null;
        options = {
          method: type,
          mode: "cors",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: data,
        };
      }

      const response = await fetch(
        this.url + "api/GreatEdge/" + route,
        options
      );

      if (response.ok === false) {
        throw new Error(
          `An error occurred during the request. ${response.statusText}`
        );
      }

      const reader = response.body.getReader();

      const fileStream = new ReadableStream({
        async start(controls) {
          while (true) {
            const result = await reader.read();
            if (!result || result.done) {
              break;
            }

            controls.enqueue(result.value);
          }

          controls.close();
          reader.releaseLock();
        },
      });

      const fileResponse = new Response(fileStream);

      const blob = await fileResponse.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error(`Error downloading file as stream: `, err);
    }
  };

  downloadAttachments = (route, type, param, filename) => {
    return new Promise((resolve, reject) => {
      let data = param || {};
      data.agencyId = this.currentAgencyId();
      var options;
      var token = this.getCookie("GreatwideAPItoken");
      if (type.toLowerCase() == "get") {
        var urlParams = $.param(data);
        if (urlParams != "") {
          route = route + "?" + $.param(data);
        }
        options = {
          method: type,
          mode: "cors",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        };
      } else {
        data = data ? ko.toJSON(data) : null;
        options = {
          method: type,
          mode: "cors",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: data,
        };
      }

      if (get_browser_info().name.toLowerCase() == "msie") {
        reject();
      } else {
        fetch(this.url + "api/GreatEdge/" + route, options).then((resp) => {
          if (resp.ok) {
            resp.blob().then((blob) => {
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
              } else {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              }
              resolve();
            });
          }
        });
      }
    });
  };

  getDataAdapter = (source, settings) => {
    source.url = this.url + "api/GreatEdge/" + source.url;
    source.cache = true;
    var _loadCompleteFn = source.loadComplete || null;
    var href = window.location.href.toUpperCase();
    var isLoadboard = href.indexOf("ORDERPLANNING") > -1;
    if (settings == null) {
      settings = {};
    }

    settings.beforeSend = (xhr) => {
      xhr.setRequestHeader("Authorization", this.securityHeaders.Authorization);
      var $grids = $(".jqx-grid");
      // Orderplanning has two grids so don't apply
      if ($grids.length && isLoadboard == false) {
        // Hide horizontal scroll bar on data loads to prevent user from scrolling while grid loads
        $('div[id*="jqxScrollThumbhorizontalScrollBar"]').css(
          "visibility",
          "hidden"
        );
        $('div[id*="jqxScrollBtnUphorizontalScrollBar"]').css(
          "visibility",
          "hidden"
        );
        $('div[id*="jqxScrollBtnDownhorizontalScrollBar"]').css(
          "visibility",
          "hidden"
        );
        // prevent the grid scrolling with the mousewheel
        var position = $(".jqx-grid").jqxGrid("scrollposition");
        var left = position.left;
        var top = position.top;
        $grids.bind("mousewheel", (e) => {
          $grids.jqxGrid("scrolloffset", top, left);
          return false;
        });
      }
    };

    source.loadComplete = (data) => {
      // Invoke this method if the calling viewmodel already has an implementation that it is using.
      if (typeof _loadCompleteFn === "function") _loadCompleteFn(data);
      var $grids = $(".jqx-grid");
      if ($grids.length && isLoadboard == false) {
        // Load complete -> show the scroll bar
        $('div[id*="jqxScrollThumbhorizontalScrollBar"]').css(
          "visibility",
          "inherit"
        );
        $('div[id*="jqxScrollBtnUphorizontalScrollBar"]').css(
          "visibility",
          "inherit"
        );
        $('div[id*="jqxScrollBtnDownhorizontalScrollBar"]').css(
          "visibility",
          "inherit"
        );
        $grids.unbind("mousewheel");
      }
    };
    var dataAdapter = new $.jqx.dataAdapter(source, settings);
    return dataAdapter;
  };

  exportToCSV = (jsonData, fileName, showHeader) => {
    var doubleQuote = false;
    var array = typeof jsonData != "object" ? JSON.parse(jsonData) : jsonData;
    var csv = "";
    var line = "";
    if (showHeader) {
      var head = array[0];
      if (doubleQuote) {
        for (let index in array[0]) {
          let value = index + "";
          line += '"' + value.replace(/"/g, '""') + '",';
        }
      } else {
        for (let index in array[0]) {
          line += index + ",";
        }
      }
      line = line.slice(0, -1);
      csv += line + "\r\n";
    }
    for (let i = 0; i < array.length; i++) {
      line = "";
      for (let index in array[i]) {
        let value = array[i][index] === null ? "" : array[i][index];
        if (value != null && typeof value.getMonth === "function") {
          value = dayjs(value).format(this.dateTimeFormat);
        }

        value = value + "";
        if (doubleQuote) {
          line += '"' + value.replace(/"/g, '""') + '",';
        } else {
          var result = value.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0) {
            value = '"' + result + '"';
          }
          line += value + ",";
        }
      }
      line = line.slice(0, -1);
      csv += line + "\r\n";
    }

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName + ".csv");
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName + ".csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1);
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
  };

  setCookie = (cname, value, seconds) => {
    var expires = "";
    if (seconds) {
      var date = new Date();
      date.setTime(date.getTime() + seconds * 24 * 60 * 60);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = cname + "=" + (value || "") + expires + "; path=/";
  };

  eraseCookie = (cname) => {
    document.cookie =
      cname + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  };
  clearSecurityHeaders = () => {
    this.eraseCookie("GreatwideAPItoken");
    window.localStorage.removeItem("userprofile");
    this.securityHeaders = undefined;
  };

  /*-----------------------------------------------------------------------------------------------------
        Client Side Logging: Use for when wanting to log client only fired events
        Ex: User clicks on a button, but the event doesn't fire any server side requests--just updates 
        or performs actions client side and you want to log those events.
        Args:
            message (required) - The message to log
            params (optional):
                url - the url to post to, this is set by default to User/LogUserAction
                pageName - the name of the page, i.e OrderPlanning.aspx or OrderPlanning
        Returns: Nothing atm.
    */
  addClientSideLog = (message, params) => {
    try {
      message = typeof message == "object" ? JSON.stringify(message) : message;
      var currentPageName = window.location.pathname;
      var log = params || {};
      var requestUrl = log.url || "User/LogUserAction"; // The default url to post log to if none is supplied
      var pageName =
        log.pageName ||
        currentPageName.substring(currentPageName.lastIndexOf("/") + 1);

      if (message !== null && message !== undefined && message !== "") {
        var msg = pageName + " - " + message;

        // Uncomment this line to see an example of the log being sent
        //console.log("(Logging to " + requestUrl + ") - Message: '" + msg + "'");

        this.ajaxRequest(requestUrl, "post", msg, true);
      }
    } catch (error) {
      console.error(error);
    }
  };
}

let dataModel = new DataModel();
export default dataModel;
