//@ts-check
/// <reference path="../../../types/jquery.plugins.d.ts" />

import dataModel from 'data-model';
import template from './call-in-records-component.html';
import dayjs from 'dayjs';
import {showmessage} from 'show-dialog-methods'
import ko from 'knockout';
import { datetimeUTC } from 'global-functions';
import userprofile from 'user-profile';

class CallInRecordsViewModel {
    
    callInRecordModal = ko.observable();
    callInListModal = ko.observable();
    isLoading = ko.observable();

    constructor(params) {
        params.params.newCallInModal.subscribe((x) => {
            if(x) {
                this.showNewCallInModal({
                    fromOrderPlanningTractorGrid : x.fromOrderPlanningTractorGrid,
                    fromOrderPlanningOrderGrid : x.fromOrderPlanningOrderGrid,
                    isBrokered: x.isBrokered || false,
                    tractorId: x.tractorId,
                    orderId: x.orderExId,
                    movementId: x.movementId,
                    driverCarrierId: x.driverCarrierId
                });
            }

        });
        params.params.callInListModal.subscribe((x) => {
            this.callInListModal(new CallInRecordListViewModel({params: x, $parent: this}))
            this.callInListModal().initializeComponent();
        });
    }

    showNewCallInModal = (data) => {  
        this.isLoading(true);
        let routeparams = {};
        let route;

        if (data.fromOrderPlanningTractorGrid) {
            route = "CallinRecords/GetCallInDataFromTractor";
            routeparams = {
                tractorid: data.tractorId,
                agencyId: userprofile.currentAgencyId
            }
        }
        else if (data.isBrokered == true || data.isBrokered == "Yes") {
            route = "CallinRecords/GetCallInDataForBrokered";
            routeparams = {
                orderId: data.orderId, 
                movementId: data.movementId,
                isBrokered: data.isBrokered,
                driverCarrierId: data.driverCarrierId,
                agencyId: userprofile.currentAgencyId
            }
           
        } 
        else if (data.isBrokered == false || data.isBrokered == "No") {
            route = "CallinRecords/GetCallInDataForNonBrokered";
            routeparams = {
                movementId: data.movementId,
                orderId: data.orderId,
                isBrokered: data.brokered,
                driverCarrierId: "",
                agencyId: userprofile.currentAgencyId
            }
        }

        dataModel.ajaxRequest(route, "GET", routeparams)
        .done((record, status, xhr) => {
            this.isLoading(false);
            record = record || { tractorId: data.tractorId };
            record.isBrokered = data.brokered;
            this.callInRecordModal(new CallInRecord({data: record, $parent: this}));
        })
        .fail((error, msg, d) => {
            this.isLoading(false);
            if (error.responseJSON != null) {
                showmessage(error.responseJSON.message);
            }
            this.isLoading(false);
        });
    };
}

class CallInRecordListViewModel {
    $parent;
    isLoading = ko.observable(false);
    callInRecord = ko.observable();
    driverCallIn = ko.observable();
    tractorId = ko.observable();
    driverCarrierId = ko.observable();
    isBrokered = ko.observable();
    orderId = ko.observable();
    isTractor = false; 
    fromOrderPlanningTractorGrid = ko.observable(false);
    fromOrderPlanningOrderGrid = ko.observable(false);
    movementId = ko.observable();
    errors = ko.observableArray();

    constructor(params) {
        this.$parent = params.$parent || {};
        params = params.params;

        this.orderId(params.orderExId);
        this.fromOrderPlanningTractorGrid(params.fromOrderPlanningTractorGrid);
        this.fromOrderPlanningOrderGrid(params.fromOrderPlanningOrderGrid);
        this.tractorId(params.tractorId);
        this.driverCarrierId(params.driverCarrierId);
        this.movementId(params.movementId)
        this.isBrokered(params.isBrokered);
    }

    getGridSource = () => {
        var url
        if (this.fromOrderPlanningTractorGrid()) {
            url = "CallinRecords/GetAllCallInRecordsForDriver"
        }
        else if (this.isBrokered() == true) {
            url = "CallinRecords/GetCallInRecordsForBrokeredOrder"
        }
        else if (this.isBrokered() == false) {
            url = "CallinRecords/GetCallInRecordsForNonBrokeredOrder"
        }

        var dataParams = {
            orderId: this.orderId(),
            driverCarrierId: this.driverCarrierId(),
            tractorId: this.tractorId(),
            agencyId: userprofile.currentAgencyId
        }

        var source = {
            url: url,
            type: "GET",
            data: dataParams,
            datatype: "json",
            datafields: [
                { name: 'callTime', type: 'date' },
                { name: 'tractorNumber', type: 'string' },
                { name: 'driverCode', type: 'string' },
                { name: 'orderNumber', type: 'string' },
                { name: 'cityStateZip', type: 'string' },
                { name: 'temperature', type: 'float' },
                { name: 'newETA', type: 'date' },
                { name: 'notes', type: 'string' },
                { name: 'enteredBy', type: 'string' },
                { name: 'showEdit', type: 'bool' },  //determined by if the current user is the user that created the record
                { name: 'callRecordId', type: 'int' },
                { name: 'pta', type: 'date' },
                { name: 'tractor', type: 'int' },
                { name: 'driverName', type: 'string' }
            ],
            beforeprocessing: (data) => {
                data = data || [];
                
                data = data.map(x => {

                    if(x.callTime) {
                        x.callTime = x.callTime.substr(0, x.callTime.indexOf("T") + 9);
                    }
                    if(x.eta) {
                        x.eta = x.eta.substr(0, x.eta.indexOf("T") + 9);
                    }
                    if(x.pta) {
                        x.pta = x.pta.substr(0, x.pta.indexOf("T") + 9);
                    }

                    return x;
                });
            },
        };

        var dataAdapter = dataModel.getDataAdapter(source);

        return dataAdapter;
    }

    // Init and build the grid
    buildAndLoadGrid = (source) => {
        this.isLoading(false);
        $("#driverCallInRecordsGrid").jqxGrid({
            source: source,
            width: "100%",
            height: 330,
            selectionmode: 'none',
            columnsresize: true,
            altrows: true,
            enablehover: false,
            pageable: true,
            pagesize: 10,
            columns: [{
                text: '', datafield: 'showEdit', width: 75, columntype: 'button',  //determined by if the current user is the user that created the record
                cellsrenderer: (row, columnfield, value, defaulthtml, columnproperties) => {
                    if (value == false) {
                        return 'View'
                    }
                    else { return 'Edit' }
                },
                buttonclick: (row) => {
                    this.callInRecordsEdit(row);
                }},
                { text: 'Date Time', dataField: 'callTime', width: 150, cellsformat: 'MM/dd/yyyy HH:mm', },
                { text: this.isBrokered() ? 'Carrier' : 'Tractor', dataField: 'tractorNumber', width: 150 },
                { text: 'Driver Id', dataField: 'driverCode', width: 150 },
                { text: 'Order Number', dataField: 'orderNumber', width: 150 },
                { text: 'Location', dataField: 'cityStateZip', width: 200 },
                { text: 'Temp', dataField: 'temperature', width: 75 },
                { text: 'New ETA', dataField: 'newETA', width: 150,cellsformat: 'MM/dd/yyyy HH:mm' },
                { text: 'Notes', dataField: 'notes', width: 200 },
                { text: 'callRecordId', dataField: 'callRecordId', hidden: true },
                { text: 'PTA', dataField: 'pta', hidden: true, cellsformat: 'MM/dd/yyyy HH:mm' },
                { text: 'tractor', dataField: 'tractor', hidden: true },
                { text: 'Driver Name', dataField: 'driverName', hidden: true },
                { text: 'Entered By', dataField: 'enteredBy', width: 150 }
            ]
        });
    };

    callInRecordsEdit = (row) => {
        this.isLoading(true);
        var dataRecord = $("#driverCallInRecordsGrid").jqxGrid('getrowdata', row);
        
        let id = dataRecord.callRecordId;
        let showEdit = dataRecord.showEdit  //determined by if the current user is the user that created the record
        dataModel.ajaxRequest("CallinRecords/GetCallInForEdit", "GET", {id: id, orderExId: dataRecord.orderNumber}, true)
            .done((record, status, xhr) => {
                this.isLoading(false);
                var data = {
                    isBrokered: this.isBrokered(),
                    tractorNumber: record.tractorNumber,
                    enteredBy: record.enteredBy,
                    callTime: record.callTime,
                    cityStateZip: record.cityStateZip,
                    cityStateZipDDLCode: record.cityStateZipDDLCode, // formatted how the ddl is expecting
                    temperature: record.temperature,
                    newETA: record.newETA,
                    notes: record.notes,
                    orderNumber: record.orderNumber,
                    driverCode: record.driverCode,
                    callRecordId: record.callRecordId,
                    pta: record.pta,
                    driverName: record.driverName,
                    showEdit: showEdit,
                    carrierPhone: record.carrierPhone,
                    carrierEmail: record.carrierEmail,
                    driverPhone: record.driverPhone,
                    initiatedType: record.initiatedType
                };
                var cir = new CallInRecord({data: data, $parent: this.$parent});
                this.$parent.callInRecordModal(cir)
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                if (jqXHR.status == 400) {
                    var response = jqXHR.responseJSON;
                    this.errors.push(response.errors);
                }
                this.isLoading(false);
            });
    };

    showNewCallInModal = () => {
        
        this.$parent.showNewCallInModal({
            fromOrderPlanningTractorGrid : this.fromOrderPlanningTractorGrid(),
            fromOrderPlanningOrderGrid : this.fromOrderPlanningOrderGrid(),
            isBrokered: this.isBrokered(),
            tractorId: this.tractorId(),
            orderId: this.orderId(),
            movementId: this.movementId()
        });
    }

    initializeComponent = () => {
        this.buildAndLoadGrid(this.getGridSource());
    }
}

class CallInRecord {

    successMessage = ko.observable();
    isBrokered = ko.observable();
    trailerNumber = ko.observable();
    driverPhone = ko.observable();
    carrierPhone = ko.observable();
    carrierEmail = ko.observable();
    errors = ko.observableArray();
    isNew = ko.observable();
    showEdit = ko.observable();
    isReadonly = ko.observable();
    isLoading = ko.observable();
    carrierId = ko.observable();
    movementId = ko.observable();
    tractorNumber = ko.observable();
    driverCode = ko.observable();
    orderNumber = ko.observable();
    enteredBy = ko.observable();
    selectedCallinType = ko.observable();
    now = dayjs();
    callTime = ko.observable();
    driverName = ko.observable();
    temperature = ko.observable();
    newETA = ko.observable();
    notes = ko.observable();
    cityStateZip = ko.observable();
    callRecordId;
    orderId = ko.observable();
    driverId = ko.observable();
    enteredById = ko.observable();
    tractorId;

    callinTypesList = ko.observableArray([
        { label: 'Carrier Initiated', value: 'C' },
        { label: 'Comment', value: 'O' },
        { label: 'Broker Initiated', value: 'B' }
    ]);

    constructor(data) {
        
        this.$parent = data.$parent || {};
        data = data.data;
        this.isBrokered(data.isBrokered || data.carrierId > 0);
        this.trailerNumber(data.trailerNumber);
        this.driverPhone(data.driverPhone);
        this.carrierPhone(data.carrierPhone);
        this.carrierEmail(data.carrierEmail);
        this.isNew(data.callRecordId ? false : true);

        if(this.isNew() == true) {
            this.isReadonly(false)
        } else if(data.showEdit == true) {  //needs to be read only if anyone other than the current user is viewing the call-in.
            this.isReadonly(false)
        } else {
            this.isReadonly(true);
        }

        this.carrierId(data.carrierId);
        this.movementId(data.movementId);
        this.tractorNumber(data.tractorNumber);
        this.driverCode(data.driverCode);
        this.orderNumber(data.orderNumber);
        this.enteredBy(data.enteredBy);

        this.selectedCallinType(data.initiatedType || this.callinTypesList()[0]); // set default to 'carrier initiated'

        this.callTime(data.callTime || datetimeUTC(new Date()));
        this.driverName(data.driverName);
        this.temperature(data.temperature);
        this.newETA(data.newETA);
        this.newETA.extend({
            required: {
                message: "New ETA is required"
            }
        });
        this.PTA = ko.observable(data.pta);
        this.PTA.extend({
            required: {
                message: "PTA is required"
            }
        });

        this.PTA.subscribe((val) => {
            this.errors.removeAll();
            if (val != undefined && this.newETA() != undefined) {

                // date and time validation
                var etaFullTime = this.newETA() instanceof dayjs ? this.newETA() : dayjs(this.newETA());
                etaFullTime = etaFullTime.second(0);
                etaFullTime = etaFullTime.millisecond(0);
                

                var ptaFullTime = this.PTA() instanceof dayjs ? this.PTA() : dayjs(this.PTA());
                ptaFullTime = ptaFullTime.second(0);
                ptaFullTime = ptaFullTime.millisecond(0);

                if (ptaFullTime.isBefore(etaFullTime)) {
                    this.errors.push("The PTA has to be same or greater than the New ETA.");                    
                }

            }
        });
        
        this.notes = ko.observable(data.notes);
        this.cityStateZip = ko.observable(data.cityStateZipDDLCode);
        this.cityStateZip.extend({
            required: {
                message: "City/State/Zip is required"
            }
        });
        this.callRecordId = data.callRecordId;
        this.orderId(data.orderId);
        this.driverId(data.driverId);
        this.enteredById(data.enteredById);
        this.tractorId = data.tractorId;

    }

    update = () => {
        this.errors.removeAll();
        var validationErrors = ko.validation.group(this);
        if (validationErrors().length > 0) {
            validationErrors.showAllMessages();
            return false;
        }
        if (this.newETA() == undefined || this.newETA() == "Invalid date") {
            this.errors.push("Please enter a New ETA.");
        }
        if (this.PTA() == undefined || this.PTA() == "Invalid date") {
            this.errors.push("Please enter the PTA.");
        }

        var today = dayjs();
        today = today.hour(0);
        today = today.minute(0);
        today = today.second(0);
        today = today.millisecond(0);

        var etaDate = this.newETA();
        etaDate = etaDate.second(0);
        etaDate = etaDate.millisecond(0);

        var ptaDate = this.PTA();
        ptaDate = ptaDate.second(0);
        ptaDate = ptaDate.millisecond(0);

        var callDate = this.callTime();
        callDate = callDate.second(0);
        callDate = callDate.millisecond(0);

        if (callDate && etaDate.isBefore(callDate) || ptaDate.isBefore(callDate)) {
            this.errors.push("ETA and PTA must be same or later than the call time.");
        }

        if (etaDate.isBefore(today)) {
            this.errors.push("New ETA has to be today or later.");
        }

        if (ptaDate.isBefore(today)) {
            this.errors.push("PTA has to be today or later.");
        }

        if (ptaDate.isBefore(etaDate)) {
            this.errors.push("The PTA has to be same or greater than the New ETA.");                    
        }

        if (this.errors().length > 0)
            return false;
        
        var data = {
            carrierTractorCode: this.tractorNumber(),
            isBrokered: this.isBrokered(),
            tractorNumber: this.tractorNumber(),
            enteredBy: this.enteredBy(),
            callTime: this.callTime(),
            cityStateZip: this.cityStateZip(),
            temperature: this.temperature(),
            newETA: this.newETA(),
            notes: this.notes(),
            orderNumber: this.orderNumber(),
            driverCode: this.driverCode(),
            callRecordId: this.callRecordId,
            pta: this.PTA(),
            initiatedType: this.selectedCallinType()
        };
        this.errors.removeAll();
        this.isLoading(true);

        dataModel.ajaxRequest("CallinRecords/EditCallInRecord", "put", data)
            .done((data, textStatus, jqXHR) => {
                this.isLoading(false);
                if (data.success) {
                    if(this.$parent.callInListModal()) {
                        $("#driverCallInRecordsGrid").jqxGrid('updatebounddata');
                        $.notify("Call In Record Saved Successfully.", "info");
                    }
                    this.$parent.callInRecordModal(undefined);
                }
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                if (jqXHR.status == 400) {
                    var response = jqXHR.responseJSON;
                    this.errors.push(response.message);
                }
                this.isLoading(false);
            });
    };

    addCall = () => {    
        
        this.errors.removeAll();
        var validationErrors = ko.validation.group(this);
        if (validationErrors().length > 0) {
            validationErrors.showAllMessages();
            return false;
        }
        if (this.newETA() == undefined || this.newETA() == "Invalid date") {
            this.errors.push("Please enter a New ETA.");
        }
        if (this.PTA() == undefined || this.PTA() == "Invalid date") {
            this.errors.push("Please enter the PTA.");
        }

        var today = dayjs();
        today = today.hour(0);
        today = today.minute(0);
        today = today.second(0);
        today = today.millisecond(0);

        var etaDate = this.newETA();
        etaDate = etaDate.second(0);
        etaDate = etaDate.millisecond(0);

        var ptaDate = this.PTA();
        ptaDate = ptaDate.second(0);
        ptaDate = ptaDate.millisecond(0);

        var callDate = this.callTime();
        callDate = callDate.second(0);
        callDate = callDate.millisecond(0);

        if (callDate && etaDate.isBefore(callDate) || ptaDate.isBefore(callDate)) {
            this.errors.push("ETA and PTA must be same or later than the call time.");
        }

        if (etaDate.isBefore(today)) {
            this.errors.push("New ETA has to be today or later.");
        }

        if (ptaDate.isBefore(today)) {
            this.errors.push("PTA has to be today or later.");
        }

        if (ptaDate.isBefore(etaDate)) {
            this.errors.push("The PTA has to be same or greater than the New ETA.");                    
        }

        if (this.errors().length > 0)
            return false;

        if (this.orderId() == undefined || this.orderId() == null) {
            if (this.orderNumber()) {
                if (this.isLetter(this.orderNumber().charAt(0))) {
                    this.orderId(this.orderNumber().substring(1))
                }
            }

            if (this.orderNumber() ? this.orderNumber().charAt(0) === "G" : '') {
                this.orderId(this.orderNumber().substring(1))
            }
        }
        
        var setCallTime = this.callTime() ? this.callTime() : datetimeUTC(new Date())
        
        var data = {
            tractorId: this.tractorId,
            tractorNumber: this.tractorNumber(),
            driverCode: this.driverCode(),
            orderNumber: this.orderNumber(),
            driverName: this.driverName(),
            enteredBy: this.enteredBy(),
            callTime: setCallTime,
            cityStateZip: this.cityStateZip(),
            temp: this.temperature(),
            newETA: this.newETA(),
            PTA: this.PTA(),
            notes: this.notes(),
            orderId: this.orderId(),
            enteredById: this.enteredById(),
            driverId: this.driverId(),
            carrierId: this.carrierId(),
            movementId: this.movementId(),
            initiatedType: this.selectedCallinType(),
            isBrokered: this.isBrokered()
        };
        
        this.errors.removeAll();
        this.isLoading(true);
        dataModel.ajaxRequest("CallinRecords/CreateCallInRecord", "post", data)
        .done((data, textStatus, jqXHR) => {
            this.isLoading(false);   
            if (data.success) {
                if(this.$parent.callInListModal()) {
                    $("#driverCallInRecordsGrid").jqxGrid('updatebounddata');
                    $.notify("Call In Record Saved Successfully.", "info");
                }
                this.$parent.callInRecordModal(undefined);
            }
        })
        .fail((jqXHR, textStatus, errorThrown) => {
            if (jqXHR.status == 400) {
                var response = jqXHR.responseJSON;
                this.errors.push(response.message);
            }
            this.isLoading(false);
        });
    }

    isLetter(str) {
        return str.length === 1 && str.match(/[a-z]/i);
    }
}

export default { viewModel: CallInRecordsViewModel, template: template }