import { Base64 } from "global-functions";

export const StorageManager = function() {
    let settings = {
        encode: true
    }

    const set = (key, value) => {
        try {
            const data = settings.encode ? encode(JSON.stringify(value)) : JSON.stringify(value);
            window.localStorage.setItem(key, data);
            return true;
        }
        catch(err) {
            console.error(`Error saving to local storage.`);
            return false;
        }
    }

    const get = (key) => {
        try {
            const data = window.localStorage.getItem(key);
            return settings.encode ? JSON.parse(decode(data)) : JSON.parse(data);
        }
        catch(err) {
            return undefined
        }
    }

    const remove = (key) => {
        window.localStorage.removeItem(key);
        return key;
    }

    const encode = (dataStr) => {
        return Base64.encode(dataStr);
    }

    const decode = (dataStr) => {
        return Base64.decode(dataStr);
    }

    return {
        settings: ({encode = true}) => { settings = {...settings, encode}},
        set,
        get,
        remove
    }
}

const instance = StorageManager();
export default instance;