import dataModel from 'data-model';
import template from './inspection-locations-list-component.html';
import ko from 'knockout';
import L from 'mapquest'
import * as _ from 'lodash';
import * as $ from 'jquery';
import GridStateComponentViewModel from '../../../shared-components/jqx.grid-state-component/jqx.grid-state-component';
import config from 'config';
import gridStateUtils from 'jqx.grid-utils';
import userProfile from 'user-profile';

var InspectionLocationListViewModel = function (params) {
    L.mapquest.key = config.mapquestApiKey;
    var self = this;
     
    this.showEditPage = params.showEditPage;
    self.isGwEmployee = ko.observable(userProfile.isGwEmployee);

    self.markersLayer = new L.LayerGroup();
    self.inspectionLocationsForMap = [{}];
    let mapOptions = {
        layers: L.mapquest.tileLayer('map'),
        minZoom: 3,
        center: [35.64701, -95.39425],
        zoom: 5,
        trackResize: true,
        fitBounds: true
    };
    self.map = L.map('map', mapOptions);

    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(self.map);

    self.legend = L.control({ position: 'bottomleft' });

    self.legend.onAdd = function (map) {
        var div = L.DomUtil.create('div', 'info legend'),
            grades = [0, 1],
            labels = [];

        div.innerHTML +=
            '<img src="https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png"></img>    ' +
            'Inspection Locations<br />';

        return div;
    };

    self.legend.addTo(self.map);
    self.greenIcon = new L.Icon({
        iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    self.inspectionMapLayer = L.geoJson(null, {
        pointToLayer: function (feature, latlng) {
            return L.marker(latlng, { icon: self.greenIcon, riseOnHover: true, });
        },
        //PERFORM THIS FUNCTION TO EACH DATA ITEM THAT IS ITERATED OVER
        onEachFeature: onEachFeature
    });

    //FUNCTION TO CREATE POPUP CONTENT FOR EACH MARKER/ICON
    function onEachFeature(feature, layer) {
        var popupContent = "<p><div style='text-align:center'>" + feature.properties.Name + "<br />" + feature.properties.CityStateZip + "</div></p>";
        if (feature.properties && feature.properties.popupContent) {
            popupContent += feature.properties.popupContent;
        }

        layer.bindPopup(popupContent);
        self.markersLayer.addLayer(layer);
    }

    self.markersLayer.addTo(self.map);

    var isInit = true;
    self.cityStateZip = ko.observable();
    self.tractor = ko.observable();
    self.radius = ko.observable();
    self.locationDetail = ko.observable();

    self.placeHolderFilterWidget = function (column, columnElement, widget) {
        widget.jqxInput({
            placeHolder: "Type Here..."
        });
    };

    self.altPlaceHolderFilterWidget = function (column, columnElement, widget) {
        widget.jqxInput({
            placeHolder: "Select"
        });
    };

    self.tractorSearch = function () {
        self.refreshGrid();
    };

    self.refreshGrid = function () {
        $("#inspectionLocationGrid").jqxGrid("updatebounddata", "data");
    };

    self.clearFilters = function () {
        self.tractor(undefined);
        self.cityStateZip(undefined);
        self.radius(undefined);
        $("#inspectionLocationGrid").jqxGrid('clearfilters');

        self.refreshGrid();
    };

    self.radiusSearch = function () {
        self.refreshGrid();
    };

    self.addNewFacility = function () {
        this.showEditPage(true);
    };

    self.isInspectionGridLoading = ko.observable(false);

    self.inspectionGridState = null;

    self.toggleInspectionLocations = function (event, args) {
        var isToggled = $("#divInspectionPanel").is(":visible");
        var $this = $(args.currentTarget);
        if (isToggled) {
            $this.find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
        }
        else {
            $this.find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
        }

        $("#divInspectionPanel").animate({ height: 'toggle' }, 1500);
    };

    self.loadDefaultFilters = function () {
        dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", { name: "inspectionLocationGrid", pageName: window.location.pathname })
            .done(function (data) {
                if (_.isEmpty(data) == false) {
                    var state = JSON.parse(data);
                    self.inspectionGridState = state.grid;
                }
                self.loadInspectionLocations();
            })
            .fail(function () {
            });
    };

    self.addLocationsToMapLayer = function (hittestinfo) {
        //FIRST CLEAR ALL OF THE EXISTING MARKERS/ICONS AND EMPTY THE INSPECTIONLOCATIONSFORMAP GEOJSON DATA ARRAY
        self.markersLayer.clearLayers();
        self.inspectionLocationsForMap = [{}];

        //FIND THE NUMBER OF RESULTS RETURNED FROM THE FILTERING EVENT (RESULT COULD BE LESS THAN THE PAGE SIZE PROPERTY OF THE GRID, IE 5, 10, 20), THEREFORE USE THIS OVER event.args.pagesize                
        var length = hittestinfo.length;
        if (length != 0) {
            for (let i = 0; i < length; i++) {
                var mapObject = {                   //BEGIN EMPTY MAP OBJECT
                    "type": "FeatureCollection",
                    "features": [{
                        "type": "Feature",
                        "properties": {
                            "Name": null,
                            "CityStateZip": null,
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": []
                        }
                    },]
                };                                   //END EMPTY MAP OBJECT

                //CREATE EACH MAP LOCATION
                mapObject.features[0].properties.Name = hittestinfo[i].row.bounddata.name;
                mapObject.features[0].properties.CityStateZip = hittestinfo[i].row.bounddata.cityStateZip;
                mapObject.features[0].geometry.coordinates = [hittestinfo[i].row.bounddata.longitude, hittestinfo[i].row.bounddata.latitude];

                //ADD EACH LOCATION TO ARRAY/OBJECTS
                self.inspectionLocationsForMap.push(mapObject);
            }

            //ADD ALL OF THE GEOJSON DATA OBJECTS IN THE ARRAY CREATED BY THE PUSHED DATA ABOVE TO THE COLLECTION OF MARKERS/ICONS, TRIGGER THE FUNCTION THAT ITERATES THROUGH THE DATA
            self.inspectionMapLayer.addData(self.inspectionLocationsForMap);
        }
    }

    self.loadInspectionLocations = function () {
        var grid = $("#inspectionLocationGrid");

        //THE FOLLOWING TAKES ANY FILTERING EVENT AND TRIGGERS RE-ASSEMBLY OF THE MAP MARKERS/DATA BASED ON THE FILTERED RESULT
        grid.on("filter", function (event) {
            self.addLocationsToMapLayer(event.args.owner.hittestinfo);
        });

        //THE FOLLOWING TAKES ANY PAGING EVENT AND TRIGGERS RE-ASSEMBLY OF THE MAP MARKERS/DATA BASED ON THE PAGED RESULT
        //***"PAGECHANGED" IS ALSO TRIGGERED ON INITIAL PAGE LOAD, SO THIS WORKS FOR INITLOAD IN ADDITION TO ALL SUBSEQUENT PAGING EVENTS <---This is a lie.
        grid.on("pagechanged", function (event) {
            self.addLocationsToMapLayer(event.args.owner.hittestinfo);
        });

        grid.on("sort", function (event) {
            self.addLocationsToMapLayer(event.args.owner.hittestinfo);
        });

        var initFilters = function () {
            // var filtergroup1 = new $.jqx.filter();
            // var filter1 = filtergroup1.createfilter('stringfilter', "Active", "equals");
            // filtergroup1.addfilter(1, filter1);
            // grid.jqxGrid('addfilter', 'status', filtergroup1);
            // grid.jqxGrid('applyfilters');

            // isApproved filter - set to true by default
            var filtergroup1 = new $.jqx.filter();
            var filter1 = filtergroup1.createfilter('booleanfilter', true, "equals");
            filtergroup1.addfilter(1, filter1);
            grid.jqxGrid('addfilter', 'isApproved', filtergroup1);
            grid.jqxGrid('applyfilters');
        };

        var isLoaded = false;
        var source = {
            url: "InspectionLocation/GetForGrid",
            datatype: "json",
            filter: function() {
                if(isLoaded)
                    grid.jqxGrid('updatebounddata', 'filter')
            },
            formatdata: function (data) {
                self.isInspectionGridLoading(true);
                var filterinfo = grid.jqxGrid('getfilterinformation');
                data = gridStateUtils.formatGridFilters(filterinfo, data);
                data.tractorId = self.tractor();
                data.cityStateZipSearch = self.cityStateZip();
                data.radius = self.radius();

                if(isLoaded == false)
                    data.isApproved = true;

                return data;
            },
            datafields: [
                { name: "id", type: "int" },
                { name: "code", type: "string" },
                { name: "name", type: "string" },
                { name: "cityStateZip", type: "string" },
                { name: "scale", type: "string" },
                { name: "hasTruckWash", type: "bool" },
                { name: "hasScale", type: "bool" },
                { name: "hasFuelLanes", type: "bool" },
                { name: "hasServiceBays", type: "bool" },
                { name: "hasParking", type: "bool" },
                { name: "numFuelLanes", type: "string" },
                { name: "numServiceBays", type: "string" },
                { name: "hasShowers", type: "bool" },
                { name: "isApproved", type: "bool" },
                { name: "latitude", type: "float" },
                { name: "longitude", type: "float" }

            ],

            loadComplete: function (data) {
                self.isInspectionGridLoading(false);
            },

            loadError: function (error) {
                self.isInspectionGridLoading(false);
            }
        };

        var dataAdapter = dataModel.getDataAdapter(source);
        var columnsrenderer = function (value) { return '<div style="text-align: center; margin-top: 5px;">' + value + '</div>'; };
        var centerrenderer = function (value) { return '<div style="text-align: center;margin-top: 12px;">' + value + '</div>'; };
      
        grid.jqxGrid({
            theme: 'GWTMDark',
            width: '100%',
            altrows: true,
            // autoheight: true,
            height: 600,
            showstatusbar: true,
            sortable: true,
            pageable: true,
            pagesize: 20,
            filterable: true,
            showfilterrow: true,
            virtualmode: false,
            columnsheight: 40,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            statusbarheight: 35,
            showtoolbar: true,
            renderstatusbar: function (statusbar) {
                var div = $("<div>", { id: 'inspectionRowCount', style: "padding:4px;" });
                div.append("Number of Records: " + grid.jqxGrid('getrows').length);
                statusbar.append(div);
            },
            rendertoolbar: function (toolbar) {
                let vm1 = new GridStateComponentViewModel.viewModel();
                vm1.initializeDefaultToolbar(toolbar);
            },

            rendergridrows: function (obj) {
                return obj.data;
            },

            columns: [
                { text: 'Code', datafield: 'code', width: 150, createfilterwidget: self.placeHolderFilterWidget },
                { text: 'Latitude', datafield: 'latitude', hidden: true },
                { text: 'Longitude', datafield: 'longitude', hidden: true },
                { text: 'Name', datafield: 'name', createfilterwidget: self.placeHolderFilterWidget, width: 200 },
                {
                    text: 'City/State/Zip', datafield: 'cityStateZip', width: 200, createfilterwidget: function (column, columnElement, widget) {
                        widget.jqxInput({
                            placeHolder: "Enter City, State Zip"
                        });
                    }
                },
                { text: 'Scale <br />Available', datafield: 'hasScale', width: 100, cellsalign: "center", renderer: columnsrenderer, columntype: 'checkbox', filtertype: 'bool' },
                { text: 'Scale', datafield: 'scale', width: 100, renderer: centerrenderer, createfilterwidget: self.placeHolderFilterWidget },
                { text: 'Truck Wash', datafield: 'hasTruckWash', width: 100, renderer: centerrenderer, cellsalign: "center", columntype: 'checkbox', filtertype: 'bool' },
                { text: 'Parking', datafield: 'hasParking', width: 80, renderer: centerrenderer, cellsalign: "center", columntype: 'checkbox', filtertype: 'bool' },
                { text: 'Fuel Lanes <br />Available', datafield: 'hasFuelLanes', width: 100, cellsalign: "center", renderer: columnsrenderer, columntype: 'checkbox', filtertype: 'bool' },
                { text: 'Fuel Lanes', datafield: 'numFuelLanes', width: 100, renderer: centerrenderer, createfilterwidget: self.placeHolderFilterWidget },
                { text: 'Service Bays <br />Available', datafield: 'hasServiceBays', width: 100, cellsalign: "center", renderer: columnsrenderer, columntype: 'checkbox', filtertype: 'bool' },
                { text: 'Service Bays', datafield: 'numServiceBays', width: 120, renderer: centerrenderer, createfilterwidget: self.placeHolderFilterWidget },
                { text: 'Showers', datafield: 'hasShowers', width: 80, cellsalign: "center", renderer: centerrenderer, columntype: 'checkbox', filtertype: 'bool' },
                { text: 'Approved', datafield: 'isApproved', width: 80, cellsalign: "center", renderer: centerrenderer, columntype: 'checkbox', filtertype: 'bool' },
                {
                    text: '',
                    width: 100,
                    filterable: false,
                    sortable: false,
                    datafield: 'detailsButton',
                    columnType: 'button',
                    buttonclick: function (row) {
                        var datarow = grid.jqxGrid('getrowdata', row);
                        dataModel.ajaxRequest("InspectionLocation/" + datarow.id, "get")
                            .done(function (response, status, xhr) {
                                var detailModel = new LocationDetailModel(response);
                                self.locationDetail(detailModel);
                            })
                            .fail(function (error, msg, d) {
                                //console.log(error);
                            });
                    },
                    cellsrenderer: function () {
                        return "Details";
                    }
                },
                {
                    text: '',
                    width: 100,
                    filterable: false,
                    sortable: false,
                    datafield: 'viewButton',
                    columnType: self.isGwEmployee() == true ? 'button' : 'string',
                    hidden: self.isGwEmployee() == false,
                    buttonclick: function (row) {
                        if (self.isGwEmployee()) {
                            var datarow = grid.jqxGrid('getrowdata', row);
                            window.open("InspectionLocations?id=" + datarow.id, "_blank");
                        }
                    },
                    cellsrenderer: function () {
                        if (self.isGwEmployee()) {
                            return "Edit";
                        }
                    }
                }
            ],

            ready: function () {
                initFilters();
                self.loadGridState(grid, self.inspectionGridState);

                grid.jqxGrid({source: dataAdapter});  // put this in the ready callback so only does 1 api call when applying grid state
                isLoaded = true;
            }

        });

        
        grid.on("bindingcomplete", function (event) {
            self.addLocationsToMapLayer(event.args.owner.hittestinfo);
            var records = grid.jqxGrid('getrows');
            $('#inspectionRowCount').text("Number of Records: " + records.length);
        });

        grid.on("filter", function (event) {
            var records = grid.jqxGrid('getrows');
            $('#inspectionRowCount').text("Number of Records: " + records.length);
        });

    };

    self.loadGridState = function (grid, state) {
        if (state != null) {
            var gridState = grid.jqxGrid('getstate');
            state.selectedrowindex = -1;
            state.selectedrowindexes = [];
            state.pagenum = 0;
            state.height = gridState.height;
            state.pagesize = gridState.pagesize;
            state.pagesizeoptions = gridState.pagesizeoptions;
            grid.jqxGrid('loadstate', state);
        }
        else {
            grid.jqxGrid('loadstate');
        }

    };

    self.loadDefaultFilters();
};

var LocationDetailModel = function (data) {
    var self = this;
    if (data.contact == null) {
        data.contact = { phone: "", fax: "", address: "", cityStateZip: "", contact: "" };
    }

    self.name = ko.observable(data.facility.name == null ? "" : data.facility.name);
    self.cityStateZip = ko.observable(data.address == null ? "" : data.address.cityStateZip);
    self.address = ko.observable(data.address == null ? "" : data.address.address1);
    self.phone = ko.observable(data.contact.phone == null ? "" : data.contact.phone);
    self.fax = ko.observable(data.contact.fax == null ? "" : data.contact.fax);
    self.contact = ko.observable(data.contact.name == null ? "" : data.contact.name);
    self.serviceTrucks = ko.observable(data.facility.serviceTrucks == null ? "" : data.facility.serviceTrucks);
    self.qualifiedInspectors = ko.observable(data.facility.numberOfInspectors == null ? "" : data.facility.numberOfInspectors);
    self.hourlyLaborRate = ko.observable(data.facility.hourlyLaborRate == null ? "" : "$" + data.facility.hourlyLaborRate);
    self.serviceBays = ko.observable(data.facility.numberOfServiceBays == null ? "" : data.facility.numberOfServiceBays);
    self.inspFeeTruck = ko.observable(data.facility.truckInspectionFee == null ? "" : "$" + data.facility.truckInspectionFee);
    self.inspFeeTrailer = ko.observable(data.facility.trailerInspectionFee == null ? "" : "$" + data.facility.trailerInspectionFee);
    self.inspFeeCombo = ko.observable(data.facility.comboInspectionFee == null ? "" : "$" + data.facility.comboInspectionFee);
    self.scale = ko.observable(data.facility.scale == null ? "" : data.facility.scale);
    self.parking = ko.observable(data.facility.parking == null ? "" : data.facility.parking == true ? "Yes" : "No");
    self.shower = ko.observable(data.facility.shower == null ? "" : data.facility.shower == true ? "Yes" : "No");
    self.truckWash = ko.observable(data.facility.truckWash == null ? "" : data.facility.truckWash == true ? "Yes" : "No");
    self.fuelLanes = ko.observable(data.facility.numberOfFuelLanes == null ? "" : data.facility.numberOfFuelLanes);
    self.tireBrandsOffered = ko.observable(data.facility.tireBrandsOffered == null ? "" : data.facility.tireBrandsOffered);
    self.tireBrandsOnNab = ko.observable(data.facility.tireBrandsOnNAB == null ? "" : data.facility.tireBrandsOnNAB);
    self.typesOfRepairs = ko.observable(data.facility.typesOfRepairs == null ? "" : data.facility.typesOfRepairs);
    self.scaleCheck = ko.observable(data.availabilityOptions.hasScale == null ? "" : data.availabilityOptions.hasScale == true ? "Yes" : "No");
    self.fuelLanesCheck = ko.observable(data.availabilityOptions.hasFuelLanes == null ? "" : data.availabilityOptions.hasFuelLanes == true ? "Yes" : "No");
    self.serviceBaysCheck = ko.observable(data.availabilityOptions.hasServiceBays == null ? "" : data.availabilityOptions.hasServiceBays == true ? "Yes" : "No");

    self.sundayOpen = ko.observable(data.hours == null ? "" : data.hours.sundayOpen);
    self.sundayClosed = ko.observable(data.hours == null ? "" : data.hours.sundayClosed);
    self.mondayOpen = ko.observable(data.hours == null ? "" : data.hours.mondayOpen);
    self.mondayClosed = ko.observable(data.hours == null ? "" : data.hours.mondayClosed);
    self.tuesdayOpen = ko.observable(data.hours == null ? "" : data.hours.tuesdayOpen);
    self.tuesdayClosed = ko.observable(data.hours == null ? "" : data.hours.tuesdayClosed);
    self.wednesdayOpen = ko.observable(data.hours == null ? "" : data.hours.wednesdayOpen);
    self.wednesdayClosed = ko.observable(data.hours == null ? "" : data.hours.wednesdayClosed);
    self.thursdayOpen = ko.observable(data.hours == null ? "" : data.hours.thursdayOpen);
    self.thursdayClosed = ko.observable(data.hours == null ? "" : data.hours.thursdayClosed);
    self.fridayOpen = ko.observable(data.hours == null ? "" : data.hours.fridayOpen);
    self.fridayClosed = ko.observable(data.hours == null ? "" : data.hours.fridayClosed);
    self.saturdayOpen = ko.observable(data.hours == null ? "" : data.hours.saturdayOpen);
    self.saturdayClosed = ko.observable(data.hours == null ? "" : data.hours.saturdayClosed);

};

export default {viewModel: InspectionLocationListViewModel, template: template}