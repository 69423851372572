import ko from 'knockout';
import dataModel from 'data-model';
import userProfile from 'user-profile';
import { printDiv } from '../../../shared-components/global-functions';
import dayjs from 'dayjs';
import gridUtils from 'jqx.grid-utils';

class MovementReportViewModel {
    constructor() {
        var self = this;

        self.isLoading = ko.observable(false);
        self.errors = ko.observableArray();

        //Checks for the user type
        if (userProfile.isOwnerOperator || userProfile.isUserDriver) {
            self.type = ko.observableArray(['Tractor', "Driver"]);
            self.chosen = ko.observableArray(['Tractor']);
        }
        else {
            self.type = ko.observableArray(['Tractor', "Driver", 'Trailer', 'Order']);
            self.chosen = ko.observableArray(['Tractor']);
        }

        self.tractorId = ko.observable();
        self.tractorId.extend({
            required: {
                message: "Tractor is required",
                onlyIf: function () {
                    if (self.chosen() == "Tractor") {
                        return true;
                    }
                    return false;
                }
            }
        });

        self.driverId = ko.observable();
        self.driverId.extend({
            required: {
                message: "Driver is required",
                onlyIf: function () {
                    if (self.chosen() == "Driver") {
                        return true;
                    }
                    return false;
                }
            }
        });

        self.trailerId = ko.observable();
        self.trailerId.extend({
            required: {
                message: "Trailer is required",
                onlyIf: function () {
                    if (self.chosen() == "Trailer") {
                        return true;
                    }
                    return false;
                }
            }
        });

        self.orderId = ko.observable();
        self.orderId.extend({
            required: {
                message: "Order is required",
                onlyIf: function () {
                    if (self.chosen() == "Order") {
                        return true;
                    }
                    return false;
                }
            }
        });

        self.selectedItem = ko.observable();// selected Item

        self.selectedBasic = ko.observable();

        //Initial Value on Days Column
        self.initialValue = ko.observable(10).extend({ required: true, min: 1, max: 365 });

        //Cut-Off Date
        self.cutOffDate = ko.observable(new Date());
        self.date = ko.computed(function () {
            return (dayjs(self.cutOffDate()).format('MM/DD/YYYY'));
        });

        //Today date
        self.todayDate = ko.observable(new Date());

        //Show Void
        self.showVoid = ko.observable(false);

        //show revenue table
        self.revenueVisible = ko.observable(false);

        //pass data for calculate miles
        self.loadedMiles = ko.observable();
        self.emptyMiles = ko.observable();
        self.totalMiles = ko.observable();
        self.perMile = ko.observable();
        self.totalRevenue = ko.observable();
        self.showRevenue = ko.observable(false);

        //CSV Export
        self.exportGridData = function (gridId, fileName) {
            var jsonData = gridUtils.formatGridHeaders(gridId, true, null, userProfile.currentAgencyId());
            dataModel.exportToCSV(jsonData, fileName, true);
        };
        //AgencyID
        self.agentID = ko.observable(userProfile.currentAgencyId());

        //Main Grid
        self.mainGrid = ko.observable();
       
        //Distance Table
        self.DistanceTable = ko.observable();

        //Revenue Table
        self.RevenueTable = ko.observable();

        //View Report
        self.search = function () {

            self.errors.removeAll();
            var validationErrors = ko.validation.group(self);
            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                return false;
            }

            self.isLoading(true);
            //Revenue Table
            var chosen = self.chosen();
            if (chosen != null) {
                if (self.chosen() == "Driver") {
                    self.selectedBasic(self.driverId());
                }
                else if (self.chosen() == "Tractor") {
                    self.selectedBasic(self.tractorId());
                }
                else if (self.chosen() == "Trailer") {
                    self.selectedBasic(self.trailerId());
                }
                else {
                    self.selectedBasic(self.orderId());
                }
            }

            //Main Table
            var data = {
                type: self.chosen(), //movement type
                items: self.selectedBasic(), // movement type id or code
                days: self.initialValue(), // number of days
                dateSelected: self.date(), //cut off date
                voidValue: self.showVoid(),
                agencyId: self.agentID()
            };

            dataModel.ajaxRequest("MovementReport/GetListForGrid", "GET", data)
                .done(function (data, status, xhr) {
                    self.mainGrid(data);

                    //CalculateMiles                                                
                    var loadedMiles = 0;
                    var emptyMiles = 0;
                    var totalMiles = 0;
                    var perMile = 0;
                    var totalRevenue = 0;
                    var tempOrderId = "";
                    var length = data.movements.length;
                    if (length > 0) {
                        for (var i = 0; i < length; i++) {
                            var row = data.movements[i];
                            if (row.loaded == "Loaded") {
                                loadedMiles += row.moveDistance;
                                perMile += row.freightCharge;
                            }
                            else if (row.loaded == "Empty") {
                                emptyMiles += row.moveDistance;
                            }
                            totalMiles += row.moveDistance;

                            if (row.order != tempOrderId && row.loaded == "Loaded") {
                                totalRevenue += row.freightCharge;
                                tempOrderId = row.order;
                            }
                        }
                        self.loadedMiles(loadedMiles);
                        self.emptyMiles(emptyMiles);
                        self.totalMiles(totalMiles);
                        self.perMile(perMile);
                        self.totalRevenue(totalRevenue);
                        self.showRevenue(data.showRevenue);

                        //Distance Table
                        var distanceData = {
                            loaded: loadedMiles.toFixed(2),
                            empty: emptyMiles.toFixed(2),
                            distanceTotal: totalMiles.toFixed(2),
                            deadHead: parseFloat((emptyMiles / totalMiles) * 100).toFixed(2)+"%"
                        };

                        self.DistanceTable(distanceData);

                        var revenueData = {};
                        if(data.showRevenue) {
                            if(totalMiles > 0) {
                                revenueData.perMileTotal = (totalRevenue / totalMiles);
                            } else {
                                revenueData.perMileTotal = 0;
                            }
                            revenueData.revenueTotal = totalRevenue;
                            if(loadedMiles > 0) {
                                revenueData.perMileLoaded = (totalRevenue / loadedMiles);
                            } else {
                                revenueData.perMileLoaded = 0;
                            }

                            self.RevenueTable(revenueData);

                        } else {
                            self.RevenueTable(undefined);
                        }
                    }
                    else {
                        self.DistanceTable(undefined);
                        self.RevenueTable(undefined);
                    }

                    self.isLoading(false);
                    //Print Button Visible
                    document.getElementById("printTable").style.visibility = "visible";
                }
            )
            .fail(function (error, msg, d) {
                self.isLoading(false);
                if (error) {
                    console.error(error);
                }
            });

        };

        self.printResult = function() {
            printDiv('printableArea')
        }
    }
}

import template from './movement-report-page.html';
export default { viewModel: MovementReportViewModel, template: template }