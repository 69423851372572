class TwoFactorConfirmationComponent {
    constructor(params) {
        params = params || {};
        this.$owner = params.$owner || {};
        this.settings = ko.unwrap(params.settings) || {};

        this.onConfirmation = params.onConfirmation && typeof params.onConfirmation === "function"
            ? params.onConfirmation
            : function () {};


        this.isVerified = ko.observable(false);

        this.applyBindings();
    }

    applyBindings = () => {
        $("#two-factor-confirmation-modal").on("hide.bs.modal", () => {
            this.onConfirmation(this.isVerified());
        });
    }

    handleOnConfirmation = (isVerified) => {
        isVerified = isVerified || false;

        this.isVerified(isVerified);

        if(isVerified) this.onConfirmation(this.isVerified());
    }
}

import template from './two-factor-confirmation-component.html';
export default { viewModel: TwoFactorConfirmationComponent, template: template}