function tntCarrierTrackingModel({
    agentEmail = undefined,
    agentPhone = undefined,
    authEmail = undefined,
    authName = undefined,
    authPhone = undefined,
    carrierContactEmail = undefined,
    carrierMC = undefined,
    carrierPhone = undefined,
    customerEmails = undefined,
    customerExternalId = undefined,
    customerName = undefined,
    driver1Phone = undefined,
    driver1Name = undefined,
    id = 0,
    providerId = undefined,
    startTracking = undefined,
    teamTracking = false,
    trackingId = undefined,
    vendorId = undefined,
    customerTrackingId = undefined, // customer load number
    notifyOnPickupDelivered = false,
    notifyOnLocationUpdates = false,
    locationUpdateNotifyInterval = undefined,
    carrierTractor = undefined,
    carrierTrailer = undefined,
    startTrackingMins = 60,
    trackingStatusId = 0,
    byPassTracking = false,
    byPassTrackingUserId = undefined,
    byPassTrackingUserName = "",
    sorTrackingId = "",
    externalId = "",
    agencyId = ""
} = {}) {

    return {
        agentEmail,
        agentPhone,
        authEmail,
        authName,
        authPhone,
        carrierContactEmail,
        carrierMC,
        carrierPhone,
        customerEmails,
        customerExternalId,
        customerName,
        driver1Phone,
        id,
        providerId,
        startTracking,
        teamTracking,
        trackingId,
        vendorId,
        customerTrackingId,
        notifyOnLocationUpdates,
        notifyOnPickupDelivered,
        locationUpdateNotifyInterval,
        carrierTractor,
        carrierTrailer,
        driver1Name,
        startTrackingMins,
        trackingStatusId,
        byPassTracking,
        byPassTrackingUserId,
        byPassTrackingUserName,
        sorTrackingId,
        externalId,
        agencyId
    }
    
}

export default tntCarrierTrackingModel;


// Only passing fields we want to validate in UI
export function getValidationErrors({
    agentEmail = undefined,
    agentPhone = undefined,
    authName = undefined,
    authPhone = undefined,
    carrierContactEmail = undefined,
    providerId = undefined,
    startTracking = undefined,
    vendorId = undefined,
    customerTrackingId = undefined,
    notifyOnLocationUpdates = false,
    locationUpdateNotifyInterval = undefined,
    startTrackingMins = undefined,
    byPassTracking = false
} = {},
requiresTracking = false,
) {
    let errors = {};
    const phoneChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    
    const isValidNumber = (charsAllowed = [], str = "", replaceWith = "") => {
        const execute = (chars, index) => {
            if(!charsAllowed) return chars;

            if(charsAllowed.length <= 0) return chars;

            if(index === str.length) return chars;
            const char = charsAllowed.indexOf(str[index]) === -1 ? replaceWith : str[index];

            return execute(chars + char, (index + 1));
        }

        const result = execute("", 0);
        
        return result.length === 10;
    }

    if(byPassTracking) {
        return errors;
    }

    if(!agentEmail) {
        errors.agentEmail = `This field is required.`;
    }

    if(!agentPhone) {
        errors.agentPhone = `This field is required.`;
    }
    else if(isValidNumber(phoneChars, agentPhone) === false) {
        errors.agentPhone = `Please enter a valid phone number.`;
    }

    if(!authName) {
        errors.authName = `This field is required.`;
    }

    // Not required, but still check is valid.
    if(authPhone && isValidNumber(phoneChars, authPhone) === false) {
        errors.authPhone = `Ageny phone number is not valid.`;
    }
    
    if(!carrierContactEmail) {
        errors.carrierContactEmail = `This field is required.`;
    }
   
    if(!startTracking && !startTrackingMins) {
        errors.startTracking = `This field is required.`;
    }

    if(!vendorId) {
        errors.vendorId = `This field is required.`;
    }

    if(!providerId && requiresTracking) {
        errors.providerId = `This field is required.`;
    }
    
    if(!customerTrackingId) { // load number
        errors.customerTrackingId = `This field is required.`;
    }
        
    if(notifyOnLocationUpdates && !locationUpdateNotifyInterval) {
        errors.locationUpdateNotifyInterval = `This field is required.`;
    }

    errors.count = Object.keys(errors).length;

    return errors;
}