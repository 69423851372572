import dataModel from 'data-model';
import gridUtils from 'jqx.grid-utils';
import gridState from 'jqx.grid-state-component';
import GreatEdgeCustomWidgets from 'ge.custom-widgets';
import userProfile from 'user-profile';
import {getQueryStringValueByName} from 'global-functions';
import dayjs from 'dayjs';
import './violationLookupCustomStyles.css';

class ViolationRequestParameters {
    constructor(x) {
        x = x || {};
    }
}

class ViolationLookupViewModel {
    constructor() {
        var _this = this;
          
        var GECustomWidgets = new GreatEdgeCustomWidgets();
        var _jqxGridHelper = GECustomWidgets.GEWidgetsGridHelper('violationGrid');
  
        this.violationGrid = null;
        this.isLoading = ko.observable(false);
        this.uploadResultMessage = ko.observable();
        this.newNoteModel = ko.observable();
        this.canViewInspectionNotes = ko.observable(false);
        this.canExportViolations = ko.observable(false);
        this.canImportInspections = ko.observable(false); //Skip the initial grid load b/c when it gets to pulling the filtering information out of the grid in formatdata it thows an error b/c the grid == null
  
        this.initialGridLoad = true;
        this.inspectionDetails = ko.observable();
  
        
        this.initializeComponents = function () {
          _this.agencyIdQueryStringVar = getQueryStringValueByName("AgencyId");
          _this.driverIdQueryStringVar = getQueryStringValueByName("DriverId");
  
          _this.isLoading(true);
  
          _this.requestParameters = new ViolationRequestParameters();
          Promise.all([
            //_this.getStatesAndProvinces(), 
            _this.getUserFiltersAndGridState(), 
            _this.getSecurableControlStates() 
          ]).then(function (value) {
            var //states = value[0],
                userFiltersAndGridState = value[0], // was 1
                secureControlStates = value[1] // was 2

            _this.canViewInspectionNotes(secureControlStates.find(function (x) {
              return x.displayName == "Inspection Notes";
            }) ? secureControlStates.find(function (x) {
              return x.displayName == "Inspection Notes";
            }).visible : false);
  
            _this.canExportViolations(secureControlStates.find(function (x) {
              return x.displayName == "Export Violations";
            }) ? secureControlStates.find(function (x) {
              return x.displayName == "Export Violations";
            }).visible : false);
  
            //_this.states = states;
            _this.savedGridState = userFiltersAndGridState ? JSON.parse(userFiltersAndGridState) : null;
  
            _this.initializeGrid();
  
            _this.isLoading(false);
          });
        };
  
        this.exportInspections = function () {
          var filters = _this.violationGrid.getfilterinformation();
  
          _this.requestParameters = new ViolationRequestParameters();
          filters.forEach(function (element) {
            _this.requestParameters[element.datafield] = element.filter.getfilters()[0].value;
          });
  
          _this.isLoading(true);
  
          dataModel.downloadFile("Inspection/ExportViolations", "post", _this.requestParameters, "Violations.xlsx").then(function () {
            _this.isLoading(false);
          });
        };
  
        this.getSecurableControlStates = function () {
          return new Promise(function (resolve, reject) {
            dataModel.ajaxRequest("Inspection/GetSecurableControlStates", "GET", {
              pageName: "Violation Lookup"
            }).done(function (x) {
              resolve(x.map(function (y) {
                return new ControlState(y);
              }));
            });
          });
        };
  
        this.getUserFiltersAndGridState = function () {
          return new Promise(function (resolve, reject) {
            dataModel.ajaxRequest("UserProfile/GetUserSearchFilters", "get", {
              name: "violationGrid",
              pageName: window.location.pathname
            }).done(function (data) {
              resolve(data);
            }).fail(function () {
              reject();
            });
          });
        };
  
        // this.getStatesAndProvinces = function () {
        //   return new Promise(function (resolve, reject) {
        //     dataModel.ajaxRequest("Location/GetStatesAndProvinces", "GET", {}).done(function (value) {
        //       var states = value.filter(function (x) {
        //         return x.country == "USA";
        //       }).map(function (x) {
        //         return x.code;
        //       });
        //       resolve(states);
        //     });
        //   });
        // };
  
        this.showInspectionDetailModal = function (data) {
          dataModel.ajaxRequest("Inspection/InspectionDetails", "POST", data.inspectionId).done(function (value) {
            value.inspection = data;
            var inspdeets = new ViolationDetailsViewModel(_this, value);
  
            _this.inspectionDetails(inspdeets);
  
            inspdeets.createGrids();
          });
        };
  
        this.addRequestParameters = function (data) {
          if (_this.savedGridState && _this.savedGridState.grid && _this.savedGridState.grid.filters) {
            var filterIndexAccessors = [];
  
            for (var property in _this.savedGridState.grid.filters) {
              var lastChar = /.$/.exec(property)[0];
              if (!isNaN(lastChar) && filterIndexAccessors.indexOf(lastChar) == -1) filterIndexAccessors.push(lastChar);
            }
  
            filterIndexAccessors.forEach(function (value, index, array) {
              var dataField = _this.savedGridState.grid.filters["filterdatafield" + value];
              var filterValue = _this.savedGridState.grid.filters["filtervalue" + value];
              data[dataField] = filterValue;
            });
          }
  
          if (_this.savedGridState && _this.savedGridState.grid && _this.savedGridState.grid.sortcolumn) {
            data.sortDataField = _this.savedGridState.grid.sortcolumn;
            data.sortOrder = _this.savedGridState.grid.sortdirection.ascending == true ? "asc" : "desc";
          }
          
          for(let key in data) {
            if(data[key] == "Please Choose:") delete data[key];
          }

          return data;
        };
  
        this.addRequestQueryStringParameters = function (data) {
          if (_this.agencyIdQueryStringVar) {
            data.agencyExId = _this.agencyIdQueryStringVar;
          }
  
          if (_this.driverIdQueryStringVar) {
            data.driverExId = _this.driverIdQueryStringVar;
          }
  
          return data;
        };
  
        this.addQueryStringFiltersToGridUI = function () {
          if (_this.agencyIdQueryStringVar) {
            var filtergroup1 = new $.jqx.filter();
            var filter1 = filtergroup1.createfilter('stringfilter', _this.agencyIdQueryStringVar, "equal");
            filtergroup1.addfilter(1, filter1);
  
            _this.violationGrid.addfilter("agencyExId", filtergroup1, false);
          }
  
          if (_this.driverIdQueryStringVar) {
            var _filtergroup = new $.jqx.filter();
  
            var _filter = _filtergroup.createfilter('stringfilter', _this.driverIdQueryStringVar, "equal");
  
            _filtergroup.addfilter(1, _filter);
  
            _this.violationGrid.addfilter("driverExId", _filtergroup, false);
          }
  
          _this.violationGrid.refreshfilterrow();
        };
  
        this.addSavedFiltersToGridUI = function () {
          if (_this.savedGridState && _this.savedGridState.grid && _this.savedGridState.grid.filters) {
            var filterIndexAccessors = [];
  
            for (var property in _this.savedGridState.grid.filters) {
              var lastChar = /.$/.exec(property)[0];
              if (!isNaN(lastChar) && filterIndexAccessors.indexOf(lastChar) == -1) filterIndexAccessors.push(lastChar);
            }
  
            filterIndexAccessors.forEach(function (value, index, array) {
              var dataField = _this.savedGridState.grid.filters["filterdatafield" + value];
              var filterValue = _this.savedGridState.grid.filters["filtervalue" + value];
              var filtergroup1 = new $.jqx.filter();
              var filter1 = filtergroup1.createfilter('stringfilter', filterValue, "equal");
              filtergroup1.addfilter(1, filter1);
  
              _this.violationGrid.addfilter(dataField, filtergroup1, false);
            });
  
            _this.violationGrid.refreshfilterrow();
          }
        };
  
          this.applySavedColumnStates = function () {
            
          if (_this.savedGridState && _this.savedGridState.grid && _this.savedGridState.grid.columns) {
            _this.columns.forEach(function (element) {
              if (_this.savedGridState.grid.columns[element.datafield]) {
                _this.violationGrid.setcolumnindex(element.datafield, _this.savedGridState.grid.columns[element.datafield].index);
  
                if (_this.savedGridState.grid.columns[element.datafield].hidden) {
                  _this.violationGrid.hidecolumn(element.datafield);
                }
  
                _this.violationGrid.setcolumnproperty(element.datafield, "width", _this.savedGridState.grid.columns[element.datafield].width);
              }
            });
          }
        };
  
        this.initializeGrid = function () {
          var sortcolumn = null;
          var sortorder = null;
  
          if (_this.savedGridState && _this.savedGridState.grid && _this.savedGridState.grid.sortcolumn) {
            sortcolumn = _this.savedGridState.grid.sortcolumn;
            sortorder = _this.savedGridState.grid.sortdirection.ascending == true ? "asc" : "desc";
          }
  
          var gridSource = {
            url: "Inspection/ViolationsForGrid",
            datatype: "json",
            sortcolumn: sortcolumn ? sortcolumn : "postedDate",
            sortdirection: sortorder ? sortorder : "asc",
            type: "POST",
            filter: function filter(filters, recordsArray) {
              _this.violationGrid.updatebounddata("filter");
            },
            sort: function sort(column, direction) {
              _this.violationGrid.updatebounddata("sort");
            },
            datafields: [{
              name: "detailsButton",
              type: "string"
            }, {
              name: "violationId",
              type: "string"
            }, {
              name: "inspectionId",
              type: "string"
            }, {
              name: "driverExId",
              type: "string"
            }, {
              name: "driverName",
              type: "string"
            }, {
              name: "agencyExId",
              type: "string"
            }, {
              name: "fsm",
              type: "string"
            }, {
              name: "fsmIntervention",
              type: "bool"
            }, {
              name: "fsmInterventionStatus",
              type: "int"
            }, {
              name: "fsmInterventionStatusDescription",
              type: "string"
            }, {
              name: "postedDate",
              type: "date"
            }, {
              name: "inspectionDate",
              type: "date"
            }, {
              name: "citation",
              type: "string"
            }, {
              name: "inspection",
              type: "string"
            }, {
              name: "inspectionState",
              type: "string"
            }, {
              name: "unitType",
              type: "string"
            }, {
              name: "inspectionLevel",
              type: "int"
            }, {
              name: "basic",
              type: "string"
            }, {
              name: "section",
              type: "string"
            }, {
              name: "description",
              type: "string"
            }, {
              name: "oos",
              type: "bool"
            }, {
              name: "intermodalChassis",
              type: "bool"
            }, {
              name: "driverStatus",
              type: "string"
            }, {
              name: "agencyStatus",
              type: "string"
            }, {
              name: "severity",
              type: "string"
            }, {
              name: "points",
              type: "string"
            }, {
              name: "oosWeight",
              type: "string"
            }, {
              name: "timeWeight",
              type: "string"
            }, {
              name: "licenseNumber",
              type: "string"
            }, {
              name: "licenseState",
              type: "string"
            }, {
              name: "completed",
              type: "string"
            }, {
              name: "assetNumber",
              type: "string"
            }, {
              name: "vin",
              type: "string"
            }, {
              name: "proofOfRepair",
              type: "bool"
            }, {
              name: "logReceived",
              type: "bool"
            }, {
              name: "originalInspection",
              type: "bool"
            }, {
              name: "dvirReceived",
              type: "bool"
            }, {
              name: "cleanInspection",
              type: "bool"
            }, {
              name: "hazmat",
              type: "bool"
            }, {
              name: "starttime",
              type: "string"
            }, {
              name: "endtime",
              type: "string"
            }],
            formatdata: function(data) {
              
              if (_this.initialGridLoad) {
                
                //This adds the agencyId to the request
                data = gridUtils.formatGridFilters({}, data);
  
                if (_this.driverIdQueryStringVar || _this.agencyIdQueryStringVar) {
                  data = _this.addRequestQueryStringParameters(data);
                } else {
                  data = _this.addRequestParameters(data);
                }
              } else {
                var filters = _this.violationGrid.getfilterinformation();
  
                  data = gridUtils.formatGridFilters(filters, data);
                  data = _jqxGridHelper.addGridFiltersToDataObj(data, { dateRangePostFixes: ['Start', 'End'] });
              }
                
              return data;
            },
          };
          var dataAdapter = dataModel.getDataAdapter(gridSource);
          var gridOptions = {
            source: dataAdapter,
            width: "100%",
            altrows: true,
            sortable: true,
            autoheight: true,
            pageable: true,
            pagesize: 10,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            showtoolbar: true,
            //It is a mystery why we need to 2 properties to enable filtering :|
            filterable: true,
            showfilterrow: true,
            rendertoolbar: _this.rendertoolbar,
            toolbarheight: 70,
            autoshowfiltericon: true,
            //These 2 properties below is what makes serverside filtering work.
            virtualmode: true,
            rendergridrows: function rendergridrows(obj) {
              return obj.data;
            },
            columns: _this.columns,
            ready: function ready() {}
          };
          _this.violationGrid = jqwidgets.createInstance("#violationGrid", "jqxGrid", gridOptions);
  
          _this.violationGrid.addEventHandler("bindingcomplete", function (event) {
            if (_this.initialGridLoad) {
              _this.initialGridLoad = false;
  
              var defaultState = _this.violationGrid.getstate();
  
              defaultState.sortcolumn = "postedDate";
              defaultState.sortdirection = {
                ascending: false,
                descending: true
              };
              _this.defaultState = defaultState;
  
              if (_this.driverIdQueryStringVar || _this.agencyIdQueryStringVar) {
                _this.addQueryStringFiltersToGridUI();
              } else {
                _this.addSavedFiltersToGridUI();
  
                _this.applySavedColumnStates();
              }
            }
          });
          };
  
        this.geDateRangeFilterWidget = function (column, columnElement, widget) {
          //set widget (class) level options
          
          var options = {
              gridOptions: {
                  gridWidget: widget,
                  gridId: 'violationGrid', // required to apply gridoptions
                  columnName: column.datafield, // also required, especially if this is a filter widget
                  isFilter: true, // create the widget as a filter on the grid (sets up event bindings, etc)
                  state: _this.savedGridState ? _this.savedGridState.grid : null // can send our grid state and apply any initial filters
              }
          }
  
          return GECustomWidgets.GEDateRangePickerWidget(options); // will return widget if already exists or creates a new one;
  
      };
  
        this.columns = [{
          width: "100",
          text: "",
          filterable: false,
          columntype: "button",
          pinned: true,
          sortable: false,
          datafield: "detailsButton",
          cellsrenderer: function cellsrenderer() {
            return "Details";
          },
          buttonclick: function buttonclick(row) {
            var insp = _this.violationGrid.getrowdata(row);
  
            _this.showInspectionDetailModal(insp);
          }
        }, // { datafield: "id", text: "string" },
        {
          datafield: "inspection",
          text: "Roadside",
          width: "125px",
          cellsrenderer: function cellsrenderer(row, columnfield, value, defaultHtml, columnproperties, rowdata) {
            var cell = $(defaultHtml);
            cell[0].innerHTML = "<a style='color: blue;' target='_blank' href='" + "/RoadsideEntry?id=" + rowdata.inspectionId + "'>" + cell[0].innerText + "</a>";
            return cell[0].outerHTML;
          }
        }, {
          datafield: "driverExId",
          text: "Driver Id",
          width: "145px"
        }, {
          datafield: "driverName",
          text: "Driver Name",
          width: "200px",
          filtercondition: "starts_with"
        }, {
          datafield: "agencyExId",
          text: "Agency",
          width: "100px"
        }, {
          datafield: "fsm",
          text: "FSM",
          width: "120px",
          filtercondition: "starts_with"
        }, {
          datafield: "fsmInterventionStatus",
          text: "FSM Intervention Status",
          width: "170px",
          filtertype: "list",
          filteritems: [{
            value: 1,
            label: 1,
            html: "New"
          }, {
            value: 2,
            label: 2,
            html: "In Process"
          }, {
            value: 3,
            label: 3,
            html: "Follow Up"
          }, {
            value: 4,
            label: 4,
            html: "Complete"
          }],
          cellsrenderer: function cellsrenderer(row, columnfield, value, defaultHtml, columnproperties, rowdata) {
            var cell = $(defaultHtml);
  
            if (value == "1") {
              cell[0].innerText = "New";
            } else if (value == "2") {
              cell[0].innerText = "In Process";
            } else if (value == "3") {
              cell[0].innerText = "Follow Up";
            } else if (value == "4") {
              cell[0].innerText = "Complete";
            }
  
            return cell[0].outerHTML;
          }
        }, {
          datafield: "intermodalChassis",
          text: "Intermodal Chassis",
          width: "135px",
          columntype: "checkbox",
          filtertype: "bool"
        }, {
          datafield: "postedDate",
          text: "Posted Date",
          cellsformat: "MM/dd/yyyy",
          width: "200px",
          //filtertype: "date",
          createfilterwidget: _this.geDateRangeFilterWidget
        }, {
          datafield: "inspectionDate",
          text: "Inspection Date",
          cellsformat: "MM/dd/yyyy",
          width: "200px",
          //filtertype: "date"
            createfilterwidget: _this.geDateRangeFilterWidget
        }, {
          datafield: "citation",
          text: "Citation",
          width: "155px",
          filtercondition: "starts_with"
        }, {
          datafield: "inspectionState",
          text: "Inspection State",
          width: "135px",
          // filtertype: "list",
          // filteritems: this.states
        }, {
          datafield: "inspectionLevel",
          text: "Inspection Level",
          width: "275px",
          filtertype: "list",
          filteritems: [//label doesn't mean the label here.
          {
            value: "1",
            label: "1",
            html: "Level 1 – Complete Roadside"
          }, {
            value: "2",
            label: "2",
            html: "Level 2 – Walk Around"
          }, {
            value: "3",
            label: "3",
            html: "Level 3 – Driver Only"
          }, {
            value: "4",
            label: "4",
            html: "Level 4 – Specialized Inspection Only"
          }, {
            value: "5",
            label: "5",
            html: "Level 5 – Vehicle Only"
          }]
        }, {
          datafield: "basic",
          text: "Basic",
          width: "200px",
          // filtertype: "list"
        }, {
          datafield: "section",
          text: "Section",
          width: "140px",
          // filtertype: "list"
        }, {
          datafield: "description",
          text: "Description",
          width: "700px",
          filterable: false
        }, {
          datafield: "agencyStatus",
          text: "Agency Status",
          width: "140px",
          filtertype: "list",
          filteritems: ["Active", "Inactive"]
        }, {
          datafield: "driverStatus",
          text: "Driver Status",
          width: "140px",
          filtertype: "list",
          filteritems: ["Active", "Inactive"]
        }, {
          datafield: "oos",
          text: "OOS",
          width: "100px",
          columntype: "checkbox",
          filtertype: "bool"
        }, {
          datafield: "severity",
          text: "Severity",
          width: "100px",
          filtertype: "bool"
        }, {
          datafield: "oosWeight",
          text: "OOS Weight",
          width: "100px",
          filtertype: "bool"
        }, {
          datafield: "timeWeight",
          text: "Time Weight",
          width: "100px",
          filtertype: "bool"
        }, {
          datafield: "points",
          text: "Points",
          width: "100px",
          filterable: false
        }, {
          datafield: "assetNumber",
          text: "Asset Number",
          width: "120px",
          filtercondition: "starts_with"
        }, {
          datafield: "unitType",
          text: "Unit Type",
          width: "140px",
          filtertype: "list",
          cellsrenderer: function cellsrenderer(row, columnfield, value, defaultHtml, columnproperties, rowdata) {
            var cell = $(defaultHtml);
  
            if (value == "D") {
              cell[0].innerText = "Driver";
            } else if (value == "1") {
              cell[0].innerText = "Tractor";
            } else if (value == "2") {
              cell[0].innerText = "Trailer 1";
            } else if (value == "3") {
              cell[0].innerText = "Trailer 2";
            }
  
            return cell[0].outerHTML;
          },
          filteritems: [{
            value: "D",
            label: "D",
            html: "Driver"
          }, {
            value: "1",
            label: "1",
            html: "Tractor"
          }, {
            value: "2",
            label: "2",
            html: "Trailer 1"
          }, {
            value: "3",
            label: "3",
            html: "Trailer 2"
          }]
        }, {
          datafield: "licenseNumber",
          text: "License/Plate Number",
          width: "100px",
          filtertype: "bool"
        }, {
          datafield: "licenseState",
          text: "License State",
          width: "140px",
          // filtertype: "list",
          // filteritems: this.states
        }, {
          datafield: "vin",
          text: "Vin",
          width: "140px"
        }, {
          datafield: "completed",
          text: "Completed",
          width: "135px",
          filtertype: "list",
          filteritems: ["Not Completed", "Completed"]
        }];
  
        this.addDefaultFiltersToGrid = function () {};
  
        this.rendertoolbar = function (toolbar) {
          //If the toolbar is loaded after the initial load, it throws an error.
          if (!toolbar.isLoaded) {
              var actionBarViewModel = new gridState.viewModel(),
                tmpl1 = gridState.template;

                actionBarViewModel.clearFilters = function () {
                    _jqxGridHelper.clearDateRangeFiltersFromGrid();
                    //_this.violationGrid.loadstate(_this.defaultState);
                    $('#violationGrid').jqxGrid('clearfilters');
              };

              var tmpl1jq = $(tmpl1);
              //tmpl1jq[2].style.display = "inline-block";
              $(toolbar).append(tmpl1jq);
              ko.applyBindingsToDescendants(actionBarViewModel, toolbar[0]);
              var inspectionGridtoolBarAddition = $("#inspectionGridtoolBarAddition");
              $(toolbar).append(inspectionGridtoolBarAddition);
              toolbar.isLoaded = true;
          }
        };
  
        this.noteHeader = ko.observable("New Note");
  
        this.showCreateNewNoteModal = function () {
          var newNote = new NoteModel({
            inspectionId: _this.inspectionDetails().inspection().inspectionId
          });
  
          _this.newNoteModel(newNote);
        };
  
        this.showEditNoteModal = function (row) {
          _this.noteHeader("Edit Note");
  
          var newNote = new NoteModel(row);
  
          _this.newNoteModel(newNote);
        };
  
        this.submitNote = function (x) {
          if (ko.validation.group(x)().length > 0) {
            ko.validation.group(x).showAllMessages();
            return false;
          }
  
          var obj = {
            id: x.id(),
            comment: x.comment(),
            createdDate: new Date(),
            enteredBy: userProfile.userName,
            enteredById: x.enteredBy(),
            inspectionId: x.inspectionId()
          };
          dataModel.ajaxRequest("Inspection/SubmitNote", "POST", obj).done(function (v) {
            v.createdDate = dayjs(v.createdDate).format('MM/DD/YYYY HH:mm');
  
            if (obj.id) {
              _this.inspectionDetails().notesData[x.row()] = v;
            } else {
              _this.inspectionDetails().notesData.push(v);
            }
  
            _this.newNoteModel(null);
          });
        };
  
        this.initializeComponents();
      }
  
    //   _createClass(ViolationLookupViewModel, [{
    //     key: "trueFalseText",
    //     value: function trueFalseText(row, columnsfield, value, defaultHtml, columnproperties, rowdata) {
    //       var cell = $(defaultHtml);
  
    //       if (cell[0].innerText == "false") {
    //         cell[0].innerText = "False";
    //       } else if (cell[0].innerText == "true") {
    //         cell[0].innerText = "True";
    //       }
  
    //       return cell[0].outerHTML;
    //     }
    //   }]);
  
    // }
}

class NoteModel {
    constructor(x) {
        this.id = ko.observable();
        this.comment = ko.observable().extend({
            required: true
        });
        this.enteredById = ko.observable();
        this.enteredBy = ko.observable();
        this.createdDate = ko.observable();
        this.inspectionId = ko.observable();
        this.row = ko.observable();
        x = x || {};
        this.id(x.id);
        this.comment(x.comment);
        this.enteredById(x.enteredById);
        this.enteredBy(x.enteredBy);
        this.createdDate(x.createdDate);
        this.inspectionId(x.inspectionId);
        this.row(x.row);
    }
}

class ControlState {
    constructor(x) {
        x = x || {};
        this.displayName = x.displayName;
        this.visible = x.showOrHide == "Show";
    }
}

class ViolationDetailsViewModel {
    constructor(parentObj, x) {
        var _this2 = this;

        _this2.parentObj = parentObj || {};
        this.inspection = ko.observable();
        this.inspectionId = ko.observable();
        this.inspectionViolationsGrid = null;
        this.notesGrid = null;
        this.newNoteModel = ko.observable();
        this.proofOfRepair = ko.observable();
        this.fsmIntervention = ko.observable();
        this.startTime = ko.observable();
        this.endTime = ko.observable();
        this.logReceived = ko.observable();
        this.fsm = ko.observable();
        this.fsmInterventionCompletionDate = ko.observable();
        this.hazmat = ko.observable();
        this.dvirReceived = ko.observable();
        this.fsmInterventionStatus = ko.observable();
        this.cleanInspection = ko.observable();
        this.originalInspection = ko.observable();

        this.createGrids = function () {
        _this2.createNotesGrid();

        _this2.createViolationsGrid();
        };

        this.createNotesGrid = function () {
        var datafields = [{
            name: "id",
            type: "number"
        }, {
            name: "comment",
            type: "string"
        }, {
            name: "enteredById",
            type: "string"
        }, {
            name: "enteredBy",
            type: "string"
        }, {
            name: "createdDate",
            type: "date"
        }, {
            name: "inspectionId",
            type: "number"
        }];
        var columns = [{
            text: "",
            columntype: "button",
            width: "50px",
            cellsrenderer: function cellsrenderer() {
            return "Edit";
            },
            buttonclick: function buttonclick(row) {
            var note = _this2.notesGrid.getrowdata(row);

            note.row = row;

            _this2.parentObj.showEditNoteModal(note);
            }
        }, {
            datafield: "comment",
                text: "Comment",
            width: '45%'
        }, {
            datafield: "enteredBy",
            text: "Entered By",
            width: "35%"
        }, {
            datafield: "createdDate",
            text: "Date",
            cellsformat: 'MM/dd/yyyy HH:mm',
            width: "150px"
        }];
        var source = {
            datafields: datafields,
            localdata: _this2.notesData
        };
        var dataAdapter = new $.jqx.dataAdapter(source);
        var gridOptions = {
            source: dataAdapter,
            width: "100%",
            altrows: true,
            sortable: true,
            autoheight: true,
            pageable: false,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            showtoolbar: true,
            autoshowfiltericon: true,
            columns: columns,
            rendertoolbar: function rendertoolbar(toolbar) {
            var div = $("<div style='padding: 4px 0 0 4px'>");
            div.html("<input type='button' style='width: 82px' data-bind='geButton: {}, click: showCreateNewNoteModal' value='New Note' />");
            toolbar.append(div);
            ko.applyBindingsToDescendants(_this2.parentObj, toolbar[0]);
            }
        };
        _this2.notesGrid = jqwidgets.createInstance("#notesGrid", "jqxGrid", gridOptions);
        };

        this.createViolationsGrid = function () {
        var datafields = [{
            name: "unitType",
            type: "string"
        }, {
            name: "citation",
            type: "string"
        }, {
            name: "basic",
            type: "string"
        }, {
            name: "section",
            type: "string"
        }, {
            name: "description",
            type: "string"
        }, {
            name: "oos",
            type: "string"
        }, {
            name: "severity",
            type: "string"
        }, {
            name: "oosWeight",
            type: "string"
        }, {
            name: "timeWeight",
            type: "string"
        }, {
            name: "points",
            type: "string"
        }, {
            name: "assetNumber",
            type: "string"
        }, {
            name: "licenseNumber",
            type: "string"
        }, {
            name: "licenseState",
            type: "string"
        }, {
            name: "vin",
            type: "string"
        }, {
            name: "rowNumber",
            type: "string"
        }, {
            name: "violationId",
            type: "string"
        }, {
            name: "inspectionId",
            type: "string"
        }, {
            name: "driverExId",
            type: "string"
        }, {
            name: "driverName",
            type: "string"
        }, {
            name: "agencyExId",
            type: "string"
        }, {
            name: "fsm",
            type: "string"
        }, {
            name: "fsmIntervention",
            type: "string"
        }, {
            name: "fsmStatus",
            type: "string"
        }, {
            name: "postedDate",
            type: "string"
        }, {
            name: "inspectionDate",
            type: "string"
        }, {
            name: "inspection",
            type: "string"
        }, {
            name: "inspectionState",
            type: "string"
        }, {
            name: "inspectionLevel",
            type: "string"
        }, {
            name: "intermodalChassis",
            type: "string"
        }, {
            name: "driverStatus",
            type: "string"
        }, {
            name: "agencyStatus",
            type: "string"
        }, {
            name: "completed",
            type: "string"
        }];
        var columns = [{
            datafield: "unitType",
            text: "Unit Type",
            width: "100px"
        }, {
            datafield: "citation",
            text: "Citation",
            width: "100px"
        }, {
            datafield: "basic",
            text: "Basic",
            width: "190px"
        }, {
            datafield: "section",
            text: "Section",
            width: "100px"
        }, {
            datafield: "description",
            text: "Description",
            width: "350px"
        }, {
            datafield: "oos",
            text: "OOS",
            width: "100px",
            columntype: "checkbox"
        }, {
            datafield: "severity",
            text: "Severity",
            width: "100px"
        }, {
            datafield: "oosWeight",
            text: "OOS Weight",
            width: "100px"
        }, {
            datafield: "timeWeight",
            text: "Time Weight",
            width: "100px"
        }, {
            datafield: "points",
            text: "Points",
            width: "100px"
        }, {
            datafield: "assetNumber",
            text: "Asset Number",
            width: "100px"
        }, {
            datafield: "licenseNumber",
            text: "License Number",
            width: "150px"
        }, {
            datafield: "licenseState",
            text: "License State",
            width: "100px"
        }, {
            datafield: "vin",
            text: "VIN",
            width: "150px"
        }];
        var source = {
            datafields: datafields,
            localdata: _this2.inspectionViolationsData
        };
        var dataAdapter = new $.jqx.dataAdapter(source);
        var gridOptions = {
            source: dataAdapter,
            width: "100%",
            altrows: true,
            sortable: true,
            autoheight: true,
            pageable: false,
            columnsresize: true,
            columnsreorder: true,
            enablebrowserselection: true,
            columnsmenu: false,
            autoshowfiltericon: true,
            columns: columns
        };
        _this2.inspectionViolationsData = jqwidgets.createInstance("#inspectionViolationsGrid", "jqxGrid", gridOptions);
        };

        parentObj = parentObj || {};
        x = x || {};
        this.inspection(x.inspection);
        this.inspectionId(x.inspection.inspectionId);
        this.notesData = new $.jqx.observableArray(x.notes);
        this.inspectionViolationsData = new $.jqx.observableArray(x.inspectionViolations);
        this.proofOfRepair(x.inspection.proofOfRepair);
        this.fsmIntervention(x.inspection.fsmIntervention);
        this.startTime(x.inspection.starttime);
        this.logReceived(x.inspection.logReceived);
        this.fsm(x.inspection.fsm);
        this.endTime(x.inspection.endtime);
        this.fsmInterventionCompletionDate(x.inspection.fsmInterventionCompletionDate ? new Date(x.inspection.fsmInterventionCompletionDate).toLocaleString() : "To be determined");
        this.hazmat(x.inspection.hazmat == true ? "Yes" : "No");
        this.dvirReceived(x.inspection.dvirReceived);
        this.fsmInterventionStatus(x.inspection.fsmInterventionStatusDescription);
        this.cleanInspection(x.inspection.cleanInspection);
        this.originalInspection(x.inspection.originalInspection);
    }
}


import template from './violation-lookup-page.html';
export default { viewModel: ViolationLookupViewModel, template: template }