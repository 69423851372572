import userProfile from 'user-profile';
import { useDispatch } from 'ko-data-store';
import { isLoading, getStates, getTrailerTypes } from 'dataStore-actions/appUI';
import { groupBy } from 'global-functions';

const ViewModel = function() {

    const verifyAccess = () => {
        if ((userProfile.isOwnerOperator || userProfile.isUserDriver) &&
            userProfile.currentAgencyId() == userProfile.userAgencyId &&
            userProfile.restrictedLoadboard) {
                userProfile.notAuthorized();
            }
    }
    
    verifyAccess();

    const vmBindings = this;
    const dispatch = useDispatch();

    vmBindings.filterData = {
        stateAbbr: ko.observableArray([]),
        regionsAndStates: ko.observableArray([]),
        trailerTypes: ko.observableArray([])
    }

    const startup = () => {
        Promise.all([dispatch(getStates()), dispatch(getTrailerTypes())]).then(resultSet => {
            vmBindings.filterData.regionsAndStates(groupBy('category')(resultSet[0].appUI.geoStates.map(x => ({category: x.country, value: x.code, label: x.name}))));
            vmBindings.filterData.stateAbbr(resultSet[0].appUI.geoStates.map(x => x.code));
            vmBindings.filterData.trailerTypes(groupBy('category')(resultSet[1].appUI.trailerTypes.map(x => ({category: x.category, value: x.externalID, label: x.description}))));
        });
    }

    startup();
}

import template from './load-tractor-posting-page.html';
export default { 
    viewModel: ViewModel, 
    template: template 
}

