import dataModel from 'data-model';
import { datetimeUTC, createLocalCache } from 'global-functions';

export const FilterableModel = function({
    id = undefined,
    take = 1000,
    skip = 0,
    orderByAsc = false,
    orderByDesc = true,
    orderByName = "DateAdded"
} = {}) {
    this.id = id;
    this.take = take;
    this.skip = skip;
    this.orderByAsc = orderByAsc;
    this.orderByDesc = orderByDesc;
    this.orderByName = orderByName;
}

export const VideoCategoryModel = function({
    id = undefined,
    description = undefined,
    subCategories = []
} = {}) {
    this.id = ko.observable(id);
    this.description = ko.observable(description);
    this.subCategories = ko.observableArray(subCategories.map(x => new SubCategoryModel(x)));
}

export const SubCategoryModel = function({
    id = undefined,
    categoryId = undefined,
    name = undefined
} = {}) {
    this.id = ko.observable(id);
    this.categoryId = ko.observable(categoryId);
    this.name = ko.observable(name);
}

export const TrainingVideoModel = function({
    id = undefined,
    categoryId = undefined,
    categoryName = undefined,
    subCategoryId = undefined,
    title = undefined,
    description = undefined,
    dateAdded = undefined,
    videoURL = undefined,
    thumbnails = [],
    subCategory = {},
    roles = []
} = {}) {
    this.id = ko.observable(id);
    this.categoryId = ko.observable(categoryId);
    this.categoryName = ko.observable(categoryName);
    this.subCategoryId = ko.observable(subCategoryId);
    this.title = ko.observable(title);
    this.description = ko.observable(description);


    const d = datetimeUTC(dateAdded);
    this.dateAdded = ko.observable(d.isValid() ? d.format("MM/DD/YYYY") : undefined);
    this.videoURL = ko.observable(videoURL);
    this.thumbnails = ko.observableArray(thumbnails);
    this.subCategory = ko.observable(new SubCategoryModel(subCategory || {}))

    this.roles = ko.observableArray(roles);

    this.defaultThumbnail = ko.pureComputed(() => {
        return (this.thumbnails().length && this.thumbnails()[0]) ? this.thumbnails()[0] : 
                    (this.categoryName() && 
                    (this.categoryName() == 'GreatEdge' ? '/Content/images/greatEDGELogo.png' : 
                     this.categoryName().indexOf('GEM') > -1 ? '/Content/images/gem-logo-1.png' :
                     '/Content/images/TrainingVideosMenu.png'));
    })
}

export const VideoContentModel = function({
    subheader = "Training Videos",
    videoList = []
} = {}) {
    const mapToModel = (items = []) => items.map(x => new TrainingVideoModel(ko.toJS(x)));

    this.subheader = ko.observable(subheader);

    const _videoList = ko.observableArray(mapToModel(videoList));
    this.videoList = ko.pureComputed({
        read: () => _videoList(),
        write: (val = []) => _videoList(mapToModel(val))
    })
}


const servicesManager = function() {

    const sendDeleteVideoAsync = (id) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("TrainingVideo/DeleteVideo/"+id, "DELETE", null, true).done((response) => resolve(response))
            .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred attempting to delete data.`));
        })
    }

    const sendSaveVideoAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("TrainingVideo/UpsertVideo", "POST", payload).done((response) => resolve(response))
            .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred saving data.`));
        })
    }

    const fetchVideosBySubCategoryAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("TrainingVideo/VideosBySubCategory", "GET", payload).done((response) => resolve(response))
            .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred fetching data.`));
        })
    }
    
    const fetchVideosByCategoryAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("TrainingVideo/VideosByCategory", "GET", payload).done((response) => resolve(response))
            .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred fetching data.`));
        })
    }
    
    const fetchRecentVideosAsync = () => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("TrainingVideo/Recent", "GET").done((response) => resolve(response))
            .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred fetching data.`));
        })
    }
    
    const fetchCategoriesAsync = () => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("TrainingVideo/Categories", "GET").done((response) => resolve(response))
            .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred fetching data.`));
        })
    }
    
    const fetchVideosByTitleAsync = (title) => {
        return new Promise((resolve, reject) => {
            dataModel.ajaxRequest("TrainingVideo/SearchTitle", "GET", {title}).done((response) => resolve(response))
            .fail((err) => reject(err.responseJSON && err.responseJSON.message || `An error occurred fetching data.`));
        })
    }
    
    const getCategoriesAsync = async () => await fetchCategoriesAsync().catch(() => []);
    const getRecentVideosAsync = async () => await fetchRecentVideosAsync().catch(() => []);
    const deleteVideoAsync = async (id) => await sendDeleteVideoAsync(id);

    const getVideosBySubCategoryAsync = async ({
        id = undefined,
        take = 50,
        skip = 0,
        orderByAsc = false,
        orderByDesc = true,
        orderByName = "DateAdded"
    }) => await fetchVideosBySubCategoryAsync({
        id,
        take,
        skip,
        orderByAsc,
        orderByDesc,
        orderByName
    }).catch(() => []);
    
    const getVideosByCategoryAsync = async ({
        id = undefined,
        take = 50,
        skip = 0,
        orderByAsc = false,
        orderByDesc = true,
        orderByName = "DateAdded"
    }) => await fetchVideosByCategoryAsync({
        id,
        take,
        skip,
        orderByAsc,
        orderByDesc,
        orderByName
    }).catch(() => []);
    
    const getVideosByTitleAsync = async (title) => await fetchVideosByTitleAsync(title).catch(() => []);
    
    const saveVideoAsync = async ({
        id = undefined,
        categoryId = undefined,
        subCategoryId = undefined,
        title = undefined,
        description = undefined,
        videoURL = undefined,
        thumbnails = [],
        roles = [],
        thumbnailImg = undefined
    }) => await sendSaveVideoAsync({
        id,
        categoryId,
        subCategoryId,
        title,
        description,
        videoURL,
        thumbnails,
        roles,
        thumbnailImg
    });


    return {
        getCategoriesAsync,
        getRecentVideosAsync,
        getVideosBySubCategoryAsync,
        getVideosByCategoryAsync,
        getVideosByTitleAsync,
        saveVideoAsync,
        deleteVideoAsync
    }
}

export const services = servicesManager();

export const trainingVideoPageCache = createLocalCache({});
