
class NavBannerLinksVM {
    constructor(params) {
        this.params = params || {}

        this.listItems = ko.observableArray(this.params.listItems || []);

        this.mainList = ko.observableArray(this.listItems()); // init so we have items rendered, which will check later for any that are being clipped.
        this.extrasList = ko.observableArray([]);

        this.applyEventListeners();

        // After items are rendered, check to see if any are being clipped by calling
        // the event that calls rebuilding the list.
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 1);
        
    }

    applyEventListeners = () => {
        if(this.listItems()) {
            $(window).bind("resize", this.buildLists);
        }
    }

    buildLists = () => {   
        this.resetLists();
        let extras = this.determineExtraListItems();

        if(extras.length) {
            let mainList = this.listItems().filter((item, index) => extras.indexOf(item) == -1);

            if(mainList.length)
                extras.push(mainList.pop());

            this.mainList(mainList);
            this.extrasList(extras);
        }
    }

    resetLists = () => {
        this.mainList(this.listItems());
        this.extrasList([]);
    }

    // Items that are too wide that cause them to not be visible, clipped, etc.
    // If window is 767 or less then all items will go in extra list (mobile)
    determineExtraListItems = () => {
        var items = window.innerWidth <= 767 ? this.listItems() : [];

        const element = document.getElementById("mainList"); // display: none @ 767px

        if(element) {
            for(var i=0; i< element.childElementCount; i++){
                if (element.children[i].offsetLeft + element.children[i].offsetWidth >
                    element.offsetLeft + element.offsetWidth){
                        let itemText = element.children[i].children[0] ? element.children[i].children[0].outerText : null;
        
                        if(itemText)
                            items.push(this.listItems().find((item) => item.name.toUpperCase() == itemText));
                    }
                }
        }
        

        return items;
    }

    handleScrollTo = (itemClicked) => {
        const id = itemClicked.elementId || "";
        const element = $("#" + id).first();
        
        if (element.length) {
         
            $("html, body").animate(
                { scrollTop: element.offset().top - '120' },
                "fast"
            );
        }
    }

    // KO automatically calls this method if present when breaking down the
    // component from the dom.
    dispose = () => {
        this.listItems.removeAll();
        this.resetLists();
    }
}

import template from './navbanner-links-component.html';
export default { viewModel: NavBannerLinksVM, template: template };
