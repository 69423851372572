import dataModel from 'data-model';
import userProfile from 'user-profile';
import router from 'router';
import { useDispatch } from 'ko-data-store';
import { isLoading } from 'dataStore-actions/appUI';
import gridState from "jqx.grid-state-component";
import gridStateUtils from "jqx.grid-utils";
import { datetimeUTC, mapToCityStateZipObject, cleanString } from 'global-functions';

const close = (callBack, modalId = "") => {
    return () => {
        $(modalId).removeClass('fade in show');
        const $backdrop = $('div.modal-backdrop.in');

        if($backdrop) $backdrop.remove();
        if(typeof callBack === 'function') callBack();
    }
}


const fetchOriginDestDistance = ({originCity, originState, originZip, destinationCity, destinationState, destinationZip}) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("CallLeads/CalculateDistance", "GET", {originCity, originState, originZip, destinationCity, destinationState, destinationZip}, true)
            .done(data => resolve(data))
            .fail(error => reject(error))
    })
}

const postCallLead = (payload) => {
    return new Promise((resolve, reject) => {
        dataModel.ajaxRequest("CallLeads", "POST", payload, true)
            .done(data => resolve(data))
            .fail(error => reject(
                (error.responseJSON && error.responseJSON.message) ||
                    "Error occurred while saving."
            ))
    })
}

const originDestinationDistance = async ({originCity, originState, originZip, destinationCity, destinationState, destinationZip}) => {
    if(!originCity && !originState && !originZip) return 0;
    if(!destinationCity && !destinationState && !destinationZip) return 0;

    const { distance } = await fetchOriginDestDistance({originCity, originState, originZip, destinationCity, destinationState, destinationZip}) || {};
    return distance || 0;
}

const setCallLead = (callLead = {}, newValues = {}) => {
    // Few items need to be set ahead of time before the
    // loop below for items that have updates when changed
    callLead.distanceFromOrigin(newValues['distance']);
    callLead.carrierName(newValues['carrierName']);
    callLead.mcNumber(newValues['mcNumber']);

    Object.keys(newValues).forEach((key) => {
        if(callLead[key] && ko.isWritableObservable(callLead[key])) {
            callLead[key](newValues[key]);
        }
    });
}

const callLeadsListGrid = ($element, onSelect = function(){}, onUnSelect = function() {}) => {
    const mapGridDate = (date = "") => {
        return date && date.length ? date.substr(0, date.indexOf("T") + 9) : "";
    }

    let init = false;
    $element.jqxGrid({
        theme: 'GWTMDark',
        width: '100%',
        altrows: true,
        sortable: true,
        pageable: true,
        filterable: false,
        pagesize: 10,
        columnsresize: true,
        enablebrowserselection: true,
        columnsmenu: false,
        showtoolbar: true,
        virtualmode: false,
        rendertoolbar: (toolbar) => {    
            if(init == false) {
                const vm = new gridState.viewModel("callLeadsEntry-list");
                vm.actions = ["Clear Selection", "Refresh"];
    
                vm.clearSelection = () => {
                    $element.jqxGrid("clearselection");
                    onUnSelect();
                }

                vm.initializeDefaultToolbar(toolbar);
            }

            init = true;
        },
        rendergridrows: (obj) => {
            return obj.data;
        },
        columns: [
            {text: 'Call Type', datafield: 'callType' },
            {text: 'Contact', datafield: 'contactName' },
            {text: 'Call Date', datafield: 'callDate', cellsformat: "MM/dd/yyyy HH:mm" },
            {text: 'ID', datafield: 'asset', width: 150, cellsrenderer: (row = {}, columnfield, value, defaulthtml, columnproperties) => {
                    const {callType, driverExId, carrierId, carrierExId} = $element.jqxGrid('getrowdata', row) || {};
                    const $el = $(defaulthtml);

                    if(driverExId) {
                        const link = `<a href="Drivers?driverId=${driverExId}" target="_blank" style="color: blue;"/>${driverExId}</a>`;
                        $el.html(link);
                    }
                    else if(carrierId && carrierExId) {
                        const link = `<a href="CarrierEntry/${carrierId}" target="_blank" style="color: blue;"/>${carrierExId}</a>`;
                        $el.html(link);
                    }
                    
                    return $el[0].outerHTML;
                } 
            },
            {text: 'Truck Location', datafield: 'driverLocation' },
            {text: 'Carrier Rate', datafield: 'carrierRate', cellsformat: "c2" },
        ],
        ready: () => {
            $element.on('rowselect', function (event) 
            {
                const args = event.args;
                const rowData = args.row || {};

                if(rowData['dateAvailable']) {
                    rowData['dateAvailable'] = datetimeUTC(rowData['dateAvailable'])
                }

                rowData['distanceFromOrigin'] = rowData['distance'];

                onSelect(rowData);
            });
        }
    });

    return ({loadId, orderId, externalPostingLoadId}) => {
        const source = () => {
            return dataModel.getDataAdapter({
                url: "CallLeads",
                datatype: "json",    
                beforeprocessing: (records = []) => {
                    records.map(x => {
                        x.callDate = mapGridDate(x.callDate);
                        return x;
                    });
                },
                formatdata: (data) => {
                    data = data || {};

                    data.loadId = loadId;
                    data.orderId = orderId;
                    data.externalPostingLoadId = externalPostingLoadId;
                    
                    return data;
                },
                datafields: [ // most of these aren't shown in grid, but this allows us to pull the data to edit instead of an extra api call...
                    { name: "id", type: "int"},
                    { name: "loadId", type: "int" },
                    { name: "orderId", type: "int" },
                    { name: "callDate", type: "date" },
                    { name: "dateAvailable", type: "date" },
                    { name: "carrierRate", type: "string" },
                    { name: "comments", type: "string" },
                    { name: "contactName", type: "string" },
                    { name: "contactNumber", type: "string"},
                    { name: "contactEmail", type: "string"},
                    { name: "locationCity", type: "string" },
                    { name: "locationState", type: "string" },
                    { name: "callType", type: "string" },
                    { name: "carrierId", type: "int" },
                    { name: "driverId", type: "int" },
                    { name: "carrierExId", type: "string" },
                    { name: "driverExId", type: "string" },
                    { name: "driverLocation", type: "string" },
                    { name: "enteredBy", type: "string" },
                    { name: "lastUpdatedBy", type: "string" },
                    { name: "carrierName", type: "string" },
                    { name: "distance", type: "int"},
                    { name: "mcNumber", type: "string"},
                ],
                loadComplete: () => {}
            });
        }

        $element.jqxGrid({source: source()});

        return { 
            refresh: () => $element.jqxGrid('updatebounddata', 'data'),
            clearselection: () => $element.jqxGrid('clearselection'),
            selectedRowData: () => $element.jqxGrid('getrowdata', $element.jqxGrid('getselectedrowindex'))
        }
    }
}

const saveCallLead = async ({
    id = undefined, 
    loadId = undefined, 
    orderId = undefined, 
    externalPostingLoadId = undefined,
    callType = undefined, 
    driverExId = undefined, 
    mcNumber = undefined,
    carrierRate = 0,
    distanceFromOrigin = 0,
    driverCity = undefined,
    driverState = undefined,
    dateAvailable = undefined,
    contactName = undefined,
    contactNumber = undefined,
    contactEmail = undefined,
    comments = undefined,
    enteredBy = undefined,
    lastUpdatedBy = undefined,
    carrierName = undefined
}) => await postCallLead({
    id, 
    loadId, 
    orderId, 
    externalPostingLoadId,
    callType, 
    driverExId, 
    mcNumber,
    carrierRate,
    distanceFromOrigin,
    driverCity,
    driverState,
    dateAvailable,
    contactName,
    contactNumber,
    contactEmail,
    comments,
    enteredBy,
    lastUpdatedBy,
    carrierName
}).catch((err) => err);

const CallLeadModel = function({
    id = undefined, 
    loadId = undefined, 
    orderId = undefined, 
    externalPostingLoadId = undefined,
    callType = undefined, 
    driverExId = undefined, 
    mcNumber = undefined,
    carrierRate = undefined,
    distanceFromOrigin = undefined,
    driverLocation = undefined,
    dateAvailable = undefined,
    contactName = undefined,
    contactNumber = undefined,
    contactEmail = undefined,
    comments = undefined,
    enteredBy = userProfile.userName,
    lastUpdatedBy = userProfile.userName,
    carrierName = undefined
}) 
{
    this.id = ko.observable(id);
    this.loadId = ko.observable(loadId);
    this.orderId = ko.observable(orderId);
    this.externalPostingLoadId = ko.observable(externalPostingLoadId);
    this.callType = ko.observable(callType);
    this.driverExId = ko.observable(driverExId).extend({
        required: {
            onlyIf: () => ko.unwrap(this.callType) == 'Greatwide Truck' || ko.unwrap(this.callType) == 'Internal Driver'
        }
    });
    this.driverLocation = ko.observable(driverLocation).extend({required: true});
    this.dateAvailable = ko.observable(dateAvailable).extend({required: true});
    this.contactName = ko.observable(contactName);
    this.contactNumber = ko.observable(contactNumber);
    this.contactEmail = ko.observable(contactEmail);

    this.mcNumber = ko.observable(mcNumber).extend({
        required: {
            onlyIf: () => ko.unwrap(this.callType) == 'Carrier'
        }
    });
    
    this.carrierName = ko.observable(carrierName);
    this.carrierRate = ko.observable(carrierRate);
    this.distanceFromOrigin = ko.observable(distanceFromOrigin);

    this.comments = ko.observable(comments);
    this.enteredBy = ko.observable(enteredBy);
    this.lastUpdatedBy = ko.observable(lastUpdatedBy);

    this.modelError = ko.pureComputed(() => {
        if(!this.contactNumber() && !this.contactEmail()) {
            return "Email or phone is required."
        }
    })
}

const PresentationModel = function({
    callTypes = ["Internal Driver", "Carrier"], 
    distanceFromOrigin = undefined, 
    callLeadModel
}) 
{
    this.infoMsg = ko.observable();
    this.errorMsg = ko.observable();
    this.callTypes = ko.observableArray(callTypes);
    this.selectedDriverObj = ko.observable();
    this.selectedCarrierObj = ko.observable();
    this.displayCarrierInactiveMsg = ko.observable(false);

    this.driverFullName = ko.pureComputed(() => { // always readonly so using computed
        if(this.selectedDriverObj()) return this.selectedDriverObj().firstName + " " + this.selectedDriverObj().lastName;
    })

    this.carrierExIdNameReadOnly = ko.pureComputed(() => {
        return !this.selectedCarrierObj() || this.selectedCarrierObj().mcNumber > 0;
    })

    this.callTypeSelectReadOnly = ko.pureComputed(() => {
        return callLeadModel.id() > 0;
    })
}

function CallLeads({
    loadId, 
    orderId, 
    externalPostingLoadId,
    originCity, 
    originState, 
    originZip, 
    onCloseCallBackFn = function() {},
}) {
    const dispatch = useDispatch();
    const vmBindings = this;
    
    vmBindings.callLead = new CallLeadModel({loadId: loadId, orderId: orderId, externalPostingLoadId: externalPostingLoadId});
    vmBindings.presentational = new PresentationModel({callLeadModel: vmBindings.callLead });

    vmBindings.callLead.driverLocation.subscribe((val) => {
        if(vmBindings.callLead.distanceFromOrigin() > 0) return false;
        dispatch(isLoading(true));
        const destination = mapToCityStateZipObject(cleanString(["(", ")", ","], val).split(" "));

        originDestinationDistance({
            originCity, 
            originState, 
            originZip,
            destinationCity: destination.city,
            destinationState: destination.state,
            destinationZip: destination.zip
        }).then(distance => { dispatch(isLoading(false)); vmBindings.callLead.distanceFromOrigin(distance) }).catch(() => dispatch(isLoading(false)));
    });

    vmBindings.presentational.selectedCarrierObj.subscribe((val) => {
        if(!vmBindings.callLead.carrierName() || (typeof val === 'string' && val != vmBindings.callLead.mcNumber())) {
            vmBindings.callLead.carrierName(null);
        }

        if(val && val.id) {
            vmBindings.callLead.carrierName(`${val.code} - ${val.payeeName}`);
            vmBindings.presentational.displayCarrierInactiveMsg(!val.isActive);
            
            if(val.noDispatch) {
                $('input#carrierMcNumberSearch').css({'background-color': '#fdb7b7', 'color': 'white'});
            }
            else {
                $('input#carrierMcNumberSearch').css({'background-color': 'white', 'color': '#222'});
            }
        }
        else {
            vmBindings.presentational.displayCarrierInactiveMsg(false);
            $('input#carrierMcNumberSearch').css({'background-color': 'white', 'color': '#222'});
        }
    })

    const dispose = () => {
        $('body').removeClass('modal-open');
        vmBindings.presentational = null;
        vmBindings.callLead = null;
    }

    const callList = callLeadsListGrid($('#jqxCallLeadsGrid'), 
            (rowData = {}) => setCallLead(vmBindings.callLead, rowData), //onSelect
            () => setCallLead(vmBindings.callLead, ko.toJS(new CallLeadModel({loadId, orderId, contactNumber: null}))) // onUnSelect
        )({loadId, orderId, externalPostingLoadId});

    vmBindings.handleClose = close(() => {
        dispose();
        onCloseCallBackFn();
    }, "#call-leads-entry-modal");

    vmBindings.handleSave = async() => {
        vmBindings.presentational.errorMsg("");
        vmBindings.presentational.infoMsg("");

        const data = ko.toJS(vmBindings.callLead);

        const driverLoc = mapToCityStateZipObject(cleanString(["(", ")", ","], data.driverLocation).split(" "));
        data.driverZip = driverLoc.zip;
        data.driverCity = driverLoc.city;
        data.driverState = driverLoc.state;

        const validationErrors = ko.validation.group(vmBindings.callLead);
        if (validationErrors().length > 0 || data.modelError) {
            validationErrors.showAllMessages();
            vmBindings.presentational.errorMsg(data.modelError || "Please correct any errors.");
            return false;
        }
        
        dispatch(isLoading(true));
        const result = await saveCallLead(data);
        dispatch(isLoading(false));

        if(typeof result === 'string') {
            vmBindings.presentational.errorMsg(result);
        }
        else if(typeof result === 'number' && result > 0) {
            vmBindings.presentational.infoMsg("Call Lead Saved.");
            vmBindings.callLead.id(result);
            callList.refresh();

            setTimeout(() => { vmBindings.presentational && vmBindings.presentational.infoMsg("")}, 3000);
        }
    }
    

    const open = () => {
        $('#call-leads-entry-modal').modal('show');
    };

    open();
    
}

import template from './call-leads-entry-component.html';
export default { viewModel: CallLeads, template: template };