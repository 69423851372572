
import { scrollToRequiredField } from 'global-functions';
import dataModel from 'data-model';

class AlertsEntryComponentViewModel {
    constructor(params) {

        var alertData = params.vm();

        var self = this;
        self.isLoading = ko.observable(false);
        self.errors = ko.observableArray();

        self.id = ko.observable(alertData.id);
        self.expire = ko.observable(alertData.expire);
        self.subject = ko.observable(alertData.subject).extend({ required: true });   
        self.messageBody = ko.observable(alertData.messageBody);

        self.isNew = ko.pureComputed(function () {
            return self.id() > 0 == false;
        });

        self.submit = function () {
            self.errors.removeAll();

            var validationErrors = ko.validation.group(self);
            if (validationErrors().length > 0) {
                validationErrors.showAllMessages();
                scrollToRequiredField();
                return false;
            }

            // var mapping = {
            //     'ignore': ["errors", "isLoading"]
            // };

            var data = ko.toJS(self);
          
            self.isLoading(true);
            dataModel.ajaxRequest("Alerts/UpdateAlert", "POST", data)
             .done(function (result, status, xhr) {
                 self.isLoading(false);

                 if (alertData.onAlertSave != undefined) {
                     alertData.onAlertSave();
                 }
                 else {
                     params.vm(undefined);
                 }
             })
            .fail(function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.responseJSON != null) {
                    self.errors.push(jqXHR.responseJSON.message);
                }
                else {
                    self.errors.push("An unknown error occurred");
                }
                self.isLoading(false);
            });
        };

        self.cancel = function () {
            params.vm(undefined);
        };

        self.expireNow = function () {
            self.errors.removeAll();
            var data = null;
            self.isLoading(true);
            dataModel.ajaxRequest("Alerts/ExpireAlert/" + alertData.id, "POST", data)
             .done(function (result, status, xhr) {
                 self.isLoading(false);

                 if (alertData.onAlertSave != undefined) {
                     alertData.onAlertSave();
                 }
                 else {
                     params.vm(undefined);
                 }
             })
            .fail(function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.responseJSON != null) {
                    self.errors.push(jqXHR.responseJSON.message);
                }
                else {
                    self.errors.push("An unknown error occurred");
                }
                self.isLoading(false);
            });
        };
    }
}

import template from './alerts-entry-component.html';
export default { viewModel: AlertsEntryComponentViewModel, template: template };