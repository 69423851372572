function LoadCommentModel({
    id = undefined,
    carrierTrackingRecordId = undefined,
    driverComment = "",
    loadComment = ""
} = {}) {
    return {
        id,
        carrierTrackingRecordId, 
        driverComment,
        loadComment
    }
}

export default LoadCommentModel;