import userProfile from "user-profile";
import dataModel from "data-model";
import { setBtnDisabledState, setBtnEnabledState } from "global-functions";

class AgencyContactViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.isLoading = ko.observable(false);
        this.isProcessing = ko.observable(false);

        this.agencyPhone = this.parentModel.agencyPhone;
        this.agencyFax = this.parentModel.agencyFax;

        this.$emitsOnAction();
    }

    handleSaveAgencyContact = (data, event) => {
        this.doHandleSaveAgencyContact(data, event);
    };

    async doHandleSaveAgencyContact(data, event) {
        if (this.agencyPhone.isValid() && this.agencyFax.isValid()) {
            setBtnDisabledState(event, "Saving...");

            data = {
                phoneNumber: this.agencyPhone(),
                faxNumber: this.agencyFax(),
                agency: userProfile.currentAgencyId(),
            };

            await this.http_sendSaveAgencyContact(data).catch((err) =>
                console.error(err)
            );
            setBtnEnabledState(event, "Save");
        }
    }

    http_sendSaveAgencyContact = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/SaveAgencyContact", "put", payload)
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };
}

import template from "./agency-contact-component.html";
export default { viewModel: AgencyContactViewModel, template: template };
