import ko from 'knockout';
import userProfile from 'user-profile';
import router from 'router';
import { showmessage } from 'show-dialog-methods';

class MainHeaderLoginComponent {
    constructor() {
        this.username = ko.observable();
        this._password = ko.observable();

        this.password = ko.pureComputed({
            read: () => {
                return ko.unwrap(this._password);
            },
            write: (val) => {
                if(val) {
                    let cleansedStr = val.trim();
                    this._password(cleansedStr);
                }
            }
        })

        this.rememberMe = ko.observable(false);
        this.loggedIn = userProfile.loggedIn;
        this.failureText = ko.observable();
        this.showPasswordReset = ko.observable(false);
        this.showLoadingWheel = ko.observable(false);
        
        this.loginBtnText = ko.computed(() => {
            return this.showLoadingWheel() ? "Signing In..." : "Login";
        });

        this.login = () => {
            this.failureText(undefined);
            this.showLoadingWheel(true);
            userProfile.login(this.username(), 
                this.password(), 
                this.rememberMe(), 
                (redirectSite) => showmessage(`You are not currently authorized to access GreatEdge via this URL. Please check the address you're using and try again. Or go here to <a href="${redirectSite}" style="color: blue;">Login</a>.`)
            )
            .then((v) => {
                
                if(window.location.href.indexOf('/NotAuthorized') > -1) {
                    router.navigate("/");
                }

                this.showLoadingWheel(false);
            })
            .catch((err) => {
                this.failureText(err);
                this.showLoadingWheel(false);
            })
            .finally(() => {
                this.showLoadingWheel(false);
            })
        }

        this.logOut = () => {
            userProfile.logOut();
        }
    }
}

import template from './main-header-login-component.html'
export default { viewModel: MainHeaderLoginComponent, template: template };

