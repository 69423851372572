import dataModel from "data-model";
import gridState from "jqx.grid-state-component";
import gridStateUtils from "jqx.grid-utils";
import userProfile from "user-profile";
import { isEmpty } from "global-functions";

class AgencyUsersModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.agencyUsersGridState = null;
        this.grid = $("#agencyUsersGrid");

        this.editAgencyUserModalData = ko.observable();
        this.initializeComponent();
    }

    async initializeComponent() {
        await this.setUserFilters();
        this.loadGrid();
    }

    get gridSource() {
        return {
            url: "MyAgency/GetAgencyUsers",
            datatype: "json",
            formatdata(data) {
                data = data || {};
                data.agencyId = userProfile.currentAgencyId();

                return data;
            },
            datafields: [
                { name: "id", type: "int" },
                { name: "userName", type: "string" },
                { name: "terminal", type: "string" },
                { name: "region", type: "string" },
                { name: "agentRiskSubscriber", type: "bool" },
                { name: "agentRisk", type: "bool" },
                { name: "agentRiskEmail", type: "string" },
            ],
        };
    }

    loadGrid = () => {
        let vm = this;
        let dataAdapter = dataModel.getDataAdapter(this.gridSource);

        if (this.grid.length == 0) this.grid = $("#agencyUsersGrid");

        this.grid
            .jqxGrid({
                theme: "GWTMDark",
                width: "100%",
                height: "330px",
                source: dataAdapter,
                altrows: true,
                showstatusbar: true,
                sortable: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                statusbarheight: 35,
                showtoolbar: true,
                filter: gridStateUtils.handleClientSideFiltering,
                rendertoolbar: (toolbar) => {
                    let vm1 = new gridState.viewModel();

                    vm1.actions = [
                        "Clear Filters",
                        "Clear Selection",
                        "Refresh",
                        "Set Default Search",
                    ];
                    vm1.initializeDefaultToolbar(toolbar);

                    vm1.notifyLocation = this.grid
                        .closest(".modal-content")
                        .find(".modal-header");
                },

                rendergridrows(obj) {
                    return obj.data;
                },

                columns: [
                    {
                        text: "",
                        width: 60,
                        filterable: false,
                        sortable: false,
                        columnType: "button",
                        buttonclick(row) {
                            var datarow = vm.grid.jqxGrid("getrowdata", row);
                            vm.handleEditAgencyUser(datarow);
                        },
                        cellsrenderer() {
                            return "Edit";
                        },
                    },
                    { text: "Id", datafield: "id", hidden: true },
                    {
                        text: "User Name",
                        datafield: "userName",
                        hidden: false,
                        width: 140,
                    },
                    { text: "Terminal", datafield: "terminal", width: 100 },
                    { text: "Region", datafield: "region", width: 140 },
                    {
                        text: "Agent Risk Alert Subscriber",
                        datafield: "agentRiskSubscriber",
                        width: 140,
                        filtertype: "list",
                        filteritems: [{label: true, html: "True"}, {label: false, html: "False"}]
                    },
                    {
                        text: "Block Agent Risk Entry",
                        datafield: "agentRisk",
                        width: 140,
                        filtertype: "list",
                        filteritems: [{label: true, html: "True"}, {label: false, html: "False"}]
                    },
                    { text: "Email", datafield: "agentRiskEmail", width: 140 },
                ],

                ready() {
                    gridStateUtils.applyGridState(
                        "agencyUsersGrid",
                        vm.agencyUsersGridState
                    );
                },
            })
            .bind("filter", (event) => {
                gridStateUtils.logGridFilterResults("#agencyUsersGrid");
            });
    };

    handleEditAgencyUser = (dataObj) => {
        if (!dataObj) return false;

        this.editAgencyUserModalData(dataObj);
    };

    refreshGrid = () => {
        this.grid.jqxGrid("updatebounddata", "data");
    };

    async setUserFilters() {
        const filters = await gridStateUtils
            .getUserGridFiltersAsync("agencyUsersGrid")
            .catch((err) => console.error("Failed to load user grid filters."));
        if (isEmpty(filters) == false) {
            let state = JSON.parse(filters);
            this.agencyUsersGridState = state.grid;
        }
    }
}

import template from "./agency-users-component.html";
const AgencyUsersComponent = {
    viewModel: AgencyUsersModel,
    template: template,
};

export { AgencyUsersModel };
export default AgencyUsersComponent;
