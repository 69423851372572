import dataModel from "data-model";
import userProfile from "user-profile";
import { setBtnDisabledState, setBtnEnabledState } from "global-functions";

class DefaultOrderOptionsViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.isLoading = ko.observable(false);
        this.isProcessing = ko.observable(false);

        this.container = ko.observable();
        this.brokered = ko.observable();
        this.LTL = ko.observable();
        this.hazmat = ko.observable();
        this.preload = ko.observable();
        this.highValue = ko.observable();
        this.highValueOrderValue = ko.observable();
        this.postOrder = ko.observable();
        this.hold = ko.observable();
        this.holdReason = ko.observable();
        this.geoFence = ko.observable();

        this.initializeComponent();
    }

    initializeComponent = () => {
        this.getDefaultOrderOptions();
    };

    async handleSaveDefaultOrderOptions(data, event) {
        data = {
            container: this.container(),
            brokered: this.brokered(),
            LTL: this.LTL(),
            hazmat: this.hazmat(),
            preload: this.preload(),
            highValue: this.highValue(),
            highValueOrderValue:
                this.highValue() == false ? null : this.highValueOrderValue(),
            postOrder: this.postOrder(),
            hold: this.hold(),
            holdReason: this.hold() == false ? null : this.holdReason(),
            geoFence: this.geoFence()
        };

        setBtnDisabledState(event, "Saving...");
        const result = await this.http_sendSaveDefaultOrderOptions(data).catch(
            (err) => console.error(err)
        );
        setBtnEnabledState(event, "Save");
    }

    async getDefaultOrderOptions() {
        const data = await this.http_sendGetDefaultOrderOptions().catch((err) =>
            console.error("Failed to retrieve default order options.")
        );

        if (data != undefined) {
            this.container(data.container || false);
            this.brokered(data.brokered || false);
            this.LTL(data.ltl || false);
            this.hazmat(data.hazmat || false);
            this.preload(data.preload || false);
            this.highValue(data.highValue || false);
            this.highValueOrderValue(data.orderValue || 0);
            this.postOrder(data.shareOrder || false);
            this.hold(data.hold || false);
            this.holdReason(data.reasonForHolding || "");
            this.geoFence(data.geoFence);
        }
    }

    http_sendGetDefaultOrderOptions = () => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/GetDefaultOrderOptions", "get")
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };

    http_sendSaveDefaultOrderOptions = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/SaveDefaultOrderOptions", "put", payload)
                .done((response) => resolve(response))
                .fail((err) => reject(err));
        });
    };
}

import template from "./default-order-options-component.html";
export default { viewModel: DefaultOrderOptionsViewModel, template: template };
