import dataModel from 'data-model'
import template from './order-entry-template-component.html';
import userProfile from 'user-profile';
import ko, { Observable } from 'knockout';
import { GeneralModel } from '../order-entry-general-component/order-entry-general-component';

import { ContainerModel } from '../order-entry-container-component/order-entry-container-component';
import { BillingModel } from '../order-entry-billing-component/order-entry-billing-component';
import { StopModel } from '../order-entry-stops-component/order-entry-stops-component';
import { OrderEntryViewModel } from '../order-entry-page';
import { SummaryModel } from '../order-entry-summary-component/order-entry-summary-component';

class TemplateModel {
    /**
    * @param {OrderEntryViewModel} viewModel
    */
    constructor(viewModel) {
        /**@type { OrderEntryViewModel } */
        this.viewModel = viewModel
        this.isLoading = ko.observable(false);
        this.response = ko.observable();
        this.orderId = viewModel.orderId();
        this.templateName = ko.observable().extend({ required: true });
        this.errors = ko.observableArray()
    }
    saveTemplate = () => {
        this.errors([]);
        this.response("");
        var validationErrors = ko.validation.group({
            billToCustomer: this.viewModel.general().billToCustomer,
            revenueCode : this.viewModel.general().revenueCode,
            shipperLocation : this.viewModel.stops().filter((value) => {return value.isShipper() == true })[0].locationId,
            consigneeCityStateZip : this.viewModel.stops().filter((value) => {return value.isConsignee() == true })[0].cityStateZip,
            billingDistance: this.viewModel.billing().billDistance,
            billingMethod: this.viewModel.billing().billingMethod
        });

        if (validationErrors().length > 0) {
            this.errors(validationErrors());
            validationErrors.showAllMessages();
            return false;
        }

        this.isLoading(true);
        var data = {
            name: this.templateName(),
            orderId: this.viewModel.orderId()
        }
        
        //Saving here to prevent the stops from not being on the template.
        let isQuote = this.viewModel.summary().status() == "Quote";
        this.viewModel.initiateSaveOrderProcess(isQuote)
            .then(() =>{
                this.isLoading(false); // the save order method clears loading wheel so adding back here to remain displayed until template is saved
                dataModel.ajaxRequest("OrderTemplate/SaveTemplate", 'post', data)
                .done((data) => {
                    this.response(data);
                    this.isLoading(false);
                })
                .fail((jqXHR) => {
                    if (jqXHR.responseJSON != null) {
                        this.response(jqXHR.responseJSON.message);
                    }
                    else {
                        this.response("Unknown error");
                    }
                    this.isLoading(false);
                })
            .catch((error) =>{
                console.error(error);
            });
        })
    }
}

class UseTemplateModel {
    constructor(mainModel) {
        /**@type { OrderEntryViewModel } */
        this.mainModel = mainModel;
        this.errors = ko.observableArray();
        this.filter = ko.observable("");
        this.agencyTemplates = ko.observableArray([]);
        this.templates = ko.computed(this.templatesComputed, this);
        this.getTemplateOrders();
    }
    templatesComputed = () => {
        var filter = this.filter().toLowerCase();
        if (!filter) {
            return this.agencyTemplates();
        }
        else {
            return ko.utils.arrayFilter(this.agencyTemplates(), (item) => {
                if (item.name.toLowerCase().indexOf(filter) > -1) {
                    return true;
                }
                return false;
            });
        }
    }
    getTemplateOrders = () => {
        this.errors.removeAll();
        dataModel.ajaxRequest("order/GetTemplates/" + userProfile.currentAgencyId())
            .done((data) => {
                this.agencyTemplates(data);

                if (data.length == 0) {
                    this.errors.push("You dont have any saved templates");
                }

            })
            .fail((jqXHR) => {
                if (jqXHR.responseJSON != null) {
                    this.errors(jqXHR.responseJSON);
                }
                else {
                    this.errors.push("Unknown error");
                }
            });
    }
    useTemplate = (event) => {
        var templateId = event.id;
        this.mainModel.isLoading(true);
        this.errors.removeAll();
        dataModel.ajaxRequest("order/GetOrderTemplate/" + templateId)
        .done((data) => {
            this.mainModel.isTemplate(true);
            this.mainModel.orderTemplateId(data.orderTemplateId);
            if (data.stops == undefined || data.stops.length < 2) {
                data.stops = [{
                    movementSequence: 1, sequence: 1, movementId: data.currentMovementId, isShipper: true, isConsignee: false, stopType: "PU", status: "A"
                },
                {
                    movementSequence: 2, sequence: 2, movementId: data.currentMovementId, isShipper: false, isConsignee: true, stopType: "SO", status: "A"
                }];
            }
            this.mainModel.stops([]);
            data.summary.agencyId = data.agencyExternalId;

            this.mainModel.summary().fuelLimit(data.summary.fuelLimit)
            this.mainModel.summary().totalCharge(data.summary.totalCharge)
            this.mainModel.billing().billDistance(data.summary.distance)
            
            this.mainModel.loadOptionVariables(data.options);
            this.mainModel.general(new GeneralModel({item: data.general, mainModel: this.mainModel}));

            this.mainModel.stops(data.stops.map((stop) => {
                return new StopModel({stop: stop, mainModel: this.mainModel})
            }))

            if (data.options.isContainer) {
                this.mainModel.container(new ContainerModel(data.container || {}, this.mainModel));
            } else {
                // Clear out page default (where container is set on) since the container template is set to false
                this.mainModel.container(null);
            }

            this.mainModel.billing(new BillingModel(data.billing || {}, this.mainModel));

            $("#useTemplateOrder").find(".close").trigger("click");
            this.mainModel.isLoading(false);
        })
        .fail((jqXHR) => {
            if (jqXHR.responseJSON != null) {
                this.errors(jqXHR.responseJSON);
            }
            else {
                this.errors.push("Unknown error");
            }
        });
    }
}

export default {
    viewModel: {
        createViewModel: function(params, componentInfo) {
            //Don't do anything.  Use the object created in the main OrderEntry page.
        }
    }, template: template
}

export { UseTemplateModel, TemplateModel }