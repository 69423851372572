import dataModel from "data-model";
import userProfile from "user-profile";
import { isEmpty } from "global-functions";
import gridState from "jqx.grid-state-component";
import gridStateUtils from "jqx.grid-utils";

class CustomerRequiredFieldsViewModel {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};

        this.myCustomerRequiredFieldsGridState = null;

        this.grid = $("#myCustomersRequiredFieldsGrid");
        this.agencyExId = ko.observable(userProfile.currentAgencyId());

        this.editCustomerRequiredFieldFormData = ko.observable();

        this.initializeComponent();
    }

    async initializeComponent() {
        await this.setUserFilters(); // And load this, but wait here until finished, then
        this.loadGrid(); // load this
    }

    refreshGrid = () => {
        this.grid.jqxGrid("updatebounddata", "data");
        this.$emitsOnAction();
    };

    refresh = () => {
        this.editCustomerRequiredFieldFormData(undefined);
        this.refreshGrid();
    }

    async setUserFilters() {
        let filters = await gridStateUtils
            .getUserGridFiltersAsync("myCustomersRequiredFieldsGrid")
            .catch((err) => console.error("Failed to retrieve user filters."));
        if (isEmpty(filters) == false) {
            let state = JSON.parse(filters);
            this.myCustomerRequiredFieldsGridState = state.grid;
        }
    }

    get gridSource() {
        return {
            url: "MyAgency/GetCustomerRequiredFields",
            datatype: "json",
            formatdata: (data) => {
                data = data || {};
                data.agencyId = userProfile.currentAgencyId();

                return data;
            },

            datafields: [
                { name: "id", type: "int" },
                { name: "page", type: "string" },
                { name: "pageId", type: "int" },
                { name: "fieldName", type: "string" },
                { name: "fieldNameId", type: "int" },
                { name: "customer", type: "string" },
                { name: "customerId", type: "int" },
                { name: "alwaysRequired", type: "bool" },
            ],
        };
    }

    loadGrid = () => {
        let vm = this;

        if (this.grid.length == 0)
            this.grid = $("#myCustomersRequiredFieldsGrid");

        var dataAdapter = dataModel.getDataAdapter(this.gridSource);

        this.grid
            .jqxGrid({
                theme: "GWTMDark",
                width: "100%",
                height: "330px",
                source: dataAdapter,
                altrows: true,
                showstatusbar: true,
                sortable: true,
                pageable: true,
                filterable: true,
                showfilterrow: true,
                virtualmode: false,
                columnsresize: true,
                columnsreorder: true,
                enablebrowserselection: true,
                columnsmenu: false,
                statusbarheight: 35,
                showtoolbar: true,
                rendertoolbar: (toolbar) => {
                    let vm1 = new gridState.viewModel();

                    vm1.actions = [
                        "Clear Filters",
                        "Clear Selection",
                        "Refresh",
                        "Set Default Search",
                    ];
                    vm1.initializeDefaultToolbar(toolbar);

                    vm1.notifyLocation = this.grid
                        .closest(".modal-content")
                        .find(".modal-header");

                    const actionBtn = $("#btnAddNewCustomersRequiredField");
                    $(toolbar[0]).append($(actionBtn));
                },

                rendergridrows(obj) {
                    return obj.data;
                },

                columns: [
                    {
                        text: "",
                        width: 60,
                        filterable: false,
                        sortable: false,
                        columnType: "button",
                        buttonclick(row) {
                            var datarow = vm.grid.jqxGrid("getrowdata", row);
                            if(datarow)
                                vm.handleEditCustomerRequiredField(datarow);
                        },
                        cellsrenderer() {
                            return "Edit";
                        },
                    },
                    { text: "Id", datafield: "id", hidden: true },
                    { text: "Page", datafield: "page", hidden: false },
                    { text: "Page Id", datafield: "pageId", hidden: true },
                    { text: "Field Name", datafield: "fieldName" },
                    {
                        text: "Field Name Id",
                        datafield: "fieldNameId",
                        hidden: true,
                    },
                    { text: "Customer", datafield: "customer" },
                    {
                        text: "Customer Id",
                        datafield: "customerId",
                        hidden: true,
                    },
                    {
                        text: "Always Required",
                        datafield: "alwaysRequired",
                        hidden: true,
                    },
                    {
                        text: "",
                        width: 60,
                        filterable: false,
                        sortable: false,
                        columnType: "button",
                        buttonclick(row) {
                            var datarow = vm.grid.jqxGrid("getrowdata", row);
                            if(datarow)
                                vm.handleDeleteCustomersRequiredField(datarow);
                        },
                        cellsrenderer() {
                            return "Delete";
                        },
                    },
                ],

                ready() {
                    gridStateUtils.applyGridState(
                        "myCustomersRequiredFieldsGrid",
                        vm.myCustomerRequiredFieldsGridState
                    );
                },
            })
            .bind("filter", () => {
                gridStateUtils.logGridFilterResults(
                    "#myCustomersRequiredFieldsGrid"
                );
            });
    };

    handleDeleteCustomersRequiredField = (dataObj) => {
        this.doHandleDeleteCustomersRequiredField(dataObj);
    };

    async doHandleDeleteCustomersRequiredField(dataObj) {
        const payload = {
            Id: dataObj.id,
            customerId: dataObj.customerId,
            agencyId: userProfile.currentAgencyId(),
        };

        await this.http_sendDeleteCustomerRequiredFieldAsync(payload).catch(
            (err) =>
                console.error("Error occurred while deleting required field.")
        );
        this.refreshGrid();
    }

    handleAddNewField = () => {
        this.handleEditCustomerRequiredField({
            agencyExId: userProfile.agencyExternalId,
        });
    };

    handleEditCustomerRequiredField = (dataObj) => {
        dataObj = dataObj || {};
        dataObj.agencyExId = userProfile.agencyExternalId;

        this.editCustomerRequiredFieldFormData(dataObj);
    };

    http_sendDeleteCustomerRequiredFieldAsync = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest(
                    "MyAgency/DeleteCustomersRequiredField",
                    "delete",
                    payload
                )
                .done((data) => resolve(data))
                .fail((err) => reject(err));
        });
    };
}

import template from "./customer-required-fields-component.html";
import { data } from "jquery";
export default {
    viewModel: CustomerRequiredFieldsViewModel,
    template: template,
};
