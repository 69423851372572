import { getQueryVariable } from 'global-functions';
import dataModel from 'data-model';

class AlertsViewPageViewModel {
    constructor() {
        var self = this;
      
        self.isLoading = ko.observable(false);
        self.isLoading(false);
        self.text = ko.observable();  
        self.message = ko.observable();
        self.date = ko.observable();
        self.expire = ko.observable();
        self.user = ko.observable();

    
        self.ShowAlert = function () {  
            
            var curAlertId = getQueryVariable("id");
            var x = parseInt(curAlertId);
            
            var route = "Alerts/GetAlert/" + x;
            var data = null;
            dataModel.ajaxRequest(route, "GET", data)                               
                .done(function (data, status, xhr) {                    

                    self.text(data.subject);   
                    self.message(data.messageBody);
                    self.date(data.postDate);
                    self.expire(data.expire);
                    self.user(data.userName)
                })
                .fail(function (error, msg, d) {
                    self.isLoading(false);
                });

            
        };
    
        self.ShowAlert();  
    }
}

import template from './alerts-view-page.html';
export default { viewModel: AlertsViewPageViewModel, template: template }