
import dayjs from 'dayjs';
import dataModel from '../../../../data-model';
import ko from 'knockout';
import template from './order-entry-tracking-component.html';
import userProfile from '../../../../user-profile';
import { OrderEntryViewModel } from '../order-entry-page';

class OrderEntryTracking {
    notifyMessage = ko.observable()
    subMessage = ko.observable()
    showSubMessage = ko.observable(true)
    isDisplayed = ko.observable(false)

    /**@type { OrderEntryViewModel } */
    $parent

    constructor(model) {
        model = model || {}
        if(model.hideSubMessage) this.showSubMessage(false);
        let existingModalDiv = $('body').find('#concurrentOrderModalDiv');
        if(existingModalDiv.length > 0) {
            existingModalDiv.remove();
        }
        let node = $('<div>').attr("id", "#concurrentOrderModalDiv");
        node.append(template)
        $('body').append(node);
        ko.applyBindingsToDescendants(this, node[0]);
    }

    data = {
        /** Time tracking - Needs to come from the server */
        orderOpenedAtTimestamp: ko.observable(),
        /** Used for display purposes only - in the popup message */
        localClientTimestamp: ko.observable(dayjs()),
        viewingOrderId: ko.observable(),
        toJSON: function () {
            var data = this,
                _jsonPayload = ko.toJS(data);
            
            return _jsonPayload;
        }
    }

    methods = () => {
        var vm = this;
        var methodObj = {
            saveUpdateTrackingAndRefreshTimestamp: function (orderId) {
                orderId = orderId || vm.data.viewingOrderId();
                vm.data.orderOpenedAtTimestamp(null); // need to reset here, helps with race conditions on save chaining  TODO
                return new Promise(function (resolve, reject) {
                    dataModel.ajaxRequest("Order/SaveOrderUpdatesTracking/" + orderId, "PUT", null, true)
                    .done(function (refreshedTimestamp) {
                        vm.data.orderOpenedAtTimestamp(refreshedTimestamp);
                        vm.data.localClientTimestamp(dayjs());
                        return resolve(refreshedTimestamp);
                    }).fail(function (err) {
                        console.error("Error updating order entry tracking.");
                        return reject(err)
                    });
                })
            },
            displayConflictNotifyMessage: function (dataObj) {
                if (!dataObj || vm.isDisplayed()) return false;
                dataObj.localClientTimestamp = vm.data.localClientTimestamp();
                var orderTrackingMessageObj = new OrderTrackingMessage(dataObj);
                vm.notifyMessage(methodObj.getNotifyMessage(orderTrackingMessageObj));
                methodObj._displayModal();
            },
            _displayModal: function () {
                if ($('#concurrentOrderModal').length) {
                    $('#concurrentOrderModal').modal("show");
                    vm.isDisplayed(true);
                    methodObj._applyModalBindings();
                }
            },
            _resetData: function () {
                vm.isDisplayed(false);
                vm.notifyMessage(null);
                vm.subMessage(null);
            },
            _applyModalBindings: function () {
                $('#concurrentOrderModal').on("hide.bs.modal", function () {
                    vm.onModalCloseAction();
                    methodObj._resetData();
                });

                // Fixes multiple modal backdrops remaining on screen
                $('#concurrentOrderModal').on("hidden.bs.modal", function (e) {
                    var $modalBackdrop = $('body').find('.jqx-loader-modal .jqx-loader-modal-GreatWide');
                    if ($modalBackdrop && $modalBackdrop.length > 0)
                        $($modalBackdrop[0]).remove();
                })
            },
            getNotifyMessage: function (orderTrackingMessageObj) {
                var msg = vm.data.viewingOrderId() != orderTrackingMessageObj.orderWithConflictId ? methodObj.getNotifyMessageByDifferentOrder(orderTrackingMessageObj) :
                    orderTrackingMessageObj.updatedBy.toUpperCase() == userProfile.userName.toUpperCase() ?
                    methodObj.getNotifyMessageBySameUser(orderTrackingMessageObj) :
                    methodObj.getNotifyMessageByOtherUser(orderTrackingMessageObj);
                return msg;
            },
            getNotifyMessageByOtherUser: function (orderTrackingMessageObj) { // Message to display if the order has been updated by another user
                vm.subMessage("Please review and make your changes with the updated order information.")
                var _msg = ["User '", orderTrackingMessageObj.updatedBy,
                    "' recently updated order ", orderTrackingMessageObj.orderWithConflictExId,
                    " at '", orderTrackingMessageObj.updatedAt,
                    "' while the order information was opened here at '", orderTrackingMessageObj.requestedAt,
                    "'."];
                return _msg.join("");
            },
            getNotifyMessageBySameUser: function (orderTrackingMessageObj) { // Message to display if the user is saving over themselves by using multiple windows/tabs
                vm.subMessage("Order will now refresh with updated entries, and then you can make any additional changes.");
                var _msg = ["This order was recently updated in another browser window/tab by you at '", orderTrackingMessageObj.updatedAt,
                        "' while the order was opened here at: '", orderTrackingMessageObj.requestedAt,
                        "'."];
                return _msg.join("");
            },
            getNotifyMessageByDifferentOrder: function (orderTrackingMessage) { // If user updates another other from the dispatch section (current movment) then display msg
                vm.subMessage("Order will now refresh with updated entries, and then you can make any additional changes.");
                var _msg = ["Order '", orderTrackingMessage.orderWithConflictExId,
                    "' was recently updated at '", orderTrackingMessage.updatedAt,
                    "' while the order information was opened here at '", orderTrackingMessage.requestedAt,
                    "'."];
                return _msg.join("");
            }
        }

        return methodObj;
    }

    onModalCloseAction = () => {} // right now this gets set (overrode) in the main viewModel, so setting to empty function for now
}

// Using a type here so we can set any undefines to empty ""
class OrderTrackingMessage {
    updatedAt
    requestedAt
    updatedBy
    orderWithConflictId
    orderWithConflictExId

    constructor(dataObj) {
        // lets dayjs know what timezone this date is during parsing, so when we display the local timezone will be adjusted occordingly
        let _updatedAt = dayjs.tz(dataObj.updatedAt, "America/New_York"),
            _requestedAt = typeof(dataObj.localClientTimestamp) == 'function' ? dataObj.localClientTimestamp() : dataObj.localClientTimestamp, //not parsing properly from server ----> dayjs.tz(dataObj.requestedAt, "America/New_York");
            _dateFormat = "MM/DD/YYYY hh:mm:ss A"

        dataObj = dataObj || {};
        this.updatedAt = _updatedAt.isValid() ? dayjs(_updatedAt.toDate()).format(_dateFormat) : ""; // toDate converts the date to local time for display
        this.requestedAt = _requestedAt.isValid() ? dayjs(_requestedAt.toDate()).format(_dateFormat) : "";
        this.updatedBy = dataObj.updatedBy || "";
        this.orderWithConflictId = dataObj.orderWithConflictId || "";
        this.orderWithConflictExId = dataObj.orderWithConflictExId || "";
    }
}

export default OrderEntryTracking
// export default {viewModel: OrderEntryTracking, template: template}
