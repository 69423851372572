import ko from 'knockout';
import dataModel from 'data-model';
import $ from 'jquery';
import template from './customer-order-detail-component.html';
import dayjs from 'dayjs';
import userProfile from 'user-profile'
import {showmessage} from 'show-dialog-methods'


class CustomerOrderDetailComponent {
    constructor(params) {
        console.log(params.orderDetailsObject);
        this.orderdata = ko.observable(params.orderDetailsObject);
        this.amountdue = ko.observable(0);
        this.getAmountDue();
        this.stopsGrid = $("#jqxStopsGrid");
        this.buildStopsGrid();
        this.buildAccessorialsGrid();
        this.buildDownloadsGrid();
        this.showLoadingPanel = ko.observable(false);

    }

    formatDate(date) {
        if(date) {
            return dayjs(date).format(userProfile.dateTimeFormat)
        } else {
            return '';
        }
    }

    getAmountDue() {
        return dataModel.ajaxRequest("OrderCustomer/GetCustomerOrderDetailsExt?orderid=" + this.orderdata().externalId + "&customerReferenceNumber=" + this.orderdata().customerId)
        .done((x) => {
            console.log(x)
            this.amountdue(x);
        })
        .fail((x) => {
            showmessage("An error has occured");
        })
    }

    buildStopsGrid() {
        let source = {
            url: "OrderCustomer/GetCustomerOrderStops",
            datatype: "json",
            data: {
                orderid : this.orderdata().orderid,
                agencyId: userProfile.currentAgencyId()
            },
            datafields: [
                { name: 'stopTypeDescription', type: 'string' },
                { name: 'stopTypeCode', type: 'string' },
                { name: 'nameAddress', type: 'string' },
                { name: 'pointOfContact', type: 'string' },
                { name: 'phoneNumber', type: 'string' },
                { name: 'earliestArrival', type: 'date' },
                { name: 'latestArrival', type: 'date' },
                { name: 'movementSequence', type: 'int' },
                { name: 'orderId', type: 'string' },
                { name: 'externalId', type: 'string' }
            ]
        };

        var dataSource = dataModel.getDataAdapter(source);

        this.stopsGrid.jqxGrid({
            theme: 'GWTMDark',
            width: '100%',
            source: dataSource,
            columnsresize: true,
            columns: [
                { text: 'Stop Type', datafield: 'stopTypeCode' },
                { text: 'Name/Address', datafield: 'nameAddress' },
                { text: 'Earliest Arrival', datafield: 'earliestArrival', cellsformat: userProfile.jqxTimeFormat },
                { text: 'Latest Arrival', datafield: 'latestArrival', cellsformat: userProfile.jqxTimeFormat },
                { text: 'Contact', datafield: 'pointOfContact' },
                { text: 'Phone', datafield: 'phoneNumber' }
            ],

            ready: function () {}
        });
    }

    buildAccessorialsGrid() {
        let source = {
            url: "OrderCustomer/GetCustomerOrderAccessorials?orderid=" + this.orderdata().externalId,
            data: {
                agencyId: userProfile.currentAgencyId()
            },
            datatype: "json",
            datafields: [
                { name: 'orderid', type: 'int' },
                { name: 'description', type: 'string' },
                { name: 'units', type: 'float' },
                { name: 'rate', type: 'float' },
                { name: 'method', type: 'string' },
                { name: 'sequence', type: 'int' }
            ]
        };

        let accSource = dataModel.getDataAdapter(source);

        $("#jqxAccGrid").jqxGrid({
            width: 600,
            height: 125,
            source: accSource,
            columnsresize: true,
            columns: [
                { text: 'Description', datafield: 'description' },
                { text: 'Units', datafield: 'units' },
                { text: 'Rate', datafield: 'rate', cellsformat: 'c2' },
                { text: 'Method', datafield: 'method' }
            ]
        });
    }

    buildDownloadsGrid() {

        var source = {
            url: "Imaging/getAllAvailableImages",
            datatype: "json",
            formatdata: (data) => {
                data.proNumber = this.orderdata().externalId,
                data.agencyId = userProfile.currentAgencyId();
                return data;
            },
            datafields: [
                { name: 'imageType', type: 'string' },
                { name: 'date', type: 'date', format: 'mm/dd/yyyy' },
                { name: 'imageTypeId', type: 'int' },
                { name: 'orderNumber', type: 'string' }
            ]
        };

        var dataAdapter = dataModel.getDataAdapter(source);
        $("#jqxDownloadsGrid").jqxGrid({
            width: '100%',
            height: '100%',
            source: dataAdapter,
            columnsresize: true,

            columns: [
                { text: 'Image Date', datafield: 'date', cellsformat: 'MM/dd/yyyy', width: 250 },
                { text: 'Document Type', datafield: 'imageType', width: 250 },
                { text: '', columnType: "button", width: 100,
                cellsrenderer:  (row, datafield, value) => {
                    return "Download"
                }, buttonclick: (row) => {
                    let rdata = $("#jqxDownloadsGrid").jqxGrid('getrowdata', row);

                    let data = {
                        proNumber: this.orderdata().externalId,
                        imageRequested: rdata.imageTypeId,
                        scanDate: rdata.date.toISOString()
                    };
                    let imageName = this.orderdata().externalId + "-" + rdata.imageType + ".pdf";
                    this.showLoadingPanel(true);
                    dataModel.downloadFile("Imaging/getImageDocument", "get", data, imageName)
                    .then(() => {
                        this.showLoadingPanel(false);
                    })
                    .catch((x) => {
                        this.showLoadingPanel(false);
                    })
                } }
            ],

            ready: function () {
            
            }
        });
    }
    
}



export default {viewModel: CustomerOrderDetailComponent, template: template}