import userprofile from 'user-profile';
import SSASReporting from '../../../reporting/SSASReporting';

function AnalyticsViewModel() {
    const self = this;

    const loadReports = async () => {
        const reportList = await SSASReporting.getReportsAsync();
        
        // For demo purposes display this one
        const aReport = reportList.find(({id}) => id === "c5a803bc-e7bc-4511-b3e1-8e230a272886");
        SSASReporting.embedTo($('#report-container'), {
            reportId: aReport.id, 
            embedUrl: aReport.embedUrl, 
            datasetIds: [aReport.datasetId], 
            username: userprofile.userName,
            roles: ['RLS-User']
        });
    }

    loadReports();
}

import template from './analytics-page.html';
export default { viewModel: AnalyticsViewModel, template };