import dataModel from "data-model";

// Action types
const DISPLAY_LOADER = "appUI/display_loader";
const GET_GEOSTATES = "appUI/get_geostates";
const SET_GEOSTATES = "appUI/set_geostates";
const GET_TRAILERTYPES = "appUI/get_trailertypes";
const SET_TRAILERTYPES = "appUI/set_trailertypes";
const GET_TRAILERPOSTINGTYPES = "appUI/get_trailerpostingtypes";
const SET_TRAILERPOSTINGTYPES = "appUI/set_trailerpostingtypes";
const OPEN_CARRIERFRAUDCONTACTS = "appUI/open_carrierfraudcontacts";

// Action creators
export function isLoading(val) {
  return { type: DISPLAY_LOADER, payload: val };
}

export const getStates = () => async (dispatch, state) => {
  const { geoStates } = state.appUI;

  if (geoStates.length == 0) {
    const response = await dataModel
      .ajaxRequest("Location/GetStatesAndProvinces", "GET", null, true)
      .fail(() => []);

    dispatch({ type: SET_GEOSTATES, payload: response });
  }

  return { type: GET_GEOSTATES };
};

export const getTrailerTypes = () => async (dispatch, state) => {
  const { trailerTypes } = state.appUI;

  if (trailerTypes.length == 0) {
    const response = await dataModel
      .ajaxRequest("TrailerType/GetTrailerTypes", "GET", null, true)
      .fail(() => []);

    dispatch({ type: SET_TRAILERTYPES, payload: response });
  }

  return { type: GET_TRAILERTYPES };
};

export const getTrailerPostingTypes = () => async (dispatch, state) => {
  const { trailerPostingTypes } = state.appUI;

  if (trailerPostingTypes.length == 0) {
    const response = await dataModel
      .ajaxRequest("TrailerType/GetTrailerPostingTypes", "GET", null, true)
      .fail(() => []);

    dispatch({ type: SET_TRAILERPOSTINGTYPES, payload: response });
  }

  return { type: GET_TRAILERPOSTINGTYPES };
};

export const openCarrierFraudContactModal = (yes = false) => {
  return { type: OPEN_CARRIERFRAUDCONTACTS, payload: yes };
};

// Updater / reducer
const initState = {
  isLoading: false,
  geoStates: [],
  trailerTypes: [],
  trailerPostingTypes: [],
  openCarrierFraudContactsModal: false,
};

export default function appUI(uiState = initState, action) {
  switch (action.type) {
    case DISPLAY_LOADER:
      return { ...uiState, isLoading: action.payload };
    case SET_GEOSTATES:
      return { ...uiState, geoStates: action.payload };
    case SET_TRAILERTYPES:
      return { ...uiState, trailerTypes: action.payload };
    case SET_TRAILERPOSTINGTYPES:
      return { ...uiState, trailerPostingTypes: action.payload };
    case OPEN_CARRIERFRAUDCONTACTS:
      return { ...uiState, openCarrierFraudContactsModal: action.payload };
    default:
      return uiState;
  }
}
