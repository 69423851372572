import dataModel from 'data-model';
import userprofile from 'user-profile';
import template from './additional-charges-component.html';
import ko, { Computed, Observable, ObservableArray } from 'knockout';
import {BillingModel} from '../order-entry-billing-component';
import  { OrderEntryBillingAddPayModalComponent } from './add-pay-modal-component/add-pay-modal-component'
import { Movement, Stop } from '../../order-entry-dispatch/order-entry-dispatch-truckline-component/order-entry-dispatch-truckline-component'
import { showmessage } from 'show-dialog-methods';
class OrderEntryBillingAdditionalChargesComponent {

    additionalCharges: ObservableArray<AdditionalCharge> = ko.observableArray();
    $parent: BillingModel;
    seperateDriverExtraPays: ObservableArray<DriverExtraPay> = ko.observableArray();
    addPayModalViewModel: Observable = ko.observable();
    hasCustomPayRole : Observable<boolean> = ko.observable();
    hasAdditionalPayRole: Observable<boolean> = ko.observable();

    constructor(data: any) {
        data = data.data();
        
        this.$parent = data.parent;
        if(data.additionalCharges) {
            this.additionalCharges(data.additionalCharges.map(x => new AdditionalCharge(x, this)));
        }
        if(data.seperateDriverExtraPays) {
            this.seperateDriverExtraPays(data.seperateDriverExtraPays.filter(sp => !sp.orderChargeId).map((sp) => new DriverExtraPay(sp)));
        }
        this.$parent.additionalChargesViewModel(this);
        this.hasCustomPayRole = this.$parent.$parent.hasCustomPayRole;
        this.hasAdditionalPayRole = this.$parent.$parent.hasAdditionalPayRole;
    }

    showAddDriverPayButton: ko.Computed<any> = ko.computed(() => {
        if(this.$parent.$parent.orderLockComponent() && this.$parent.$parent.orderLockComponent().lockOrderControls()) {
            return false;
        } else if(!this.$parent.$parent.readyToBill() &&
            !this.$parent.$parent.brokered() &&
            this.hasAdditionalPayRole()) {
            return true;
        } else {
            return false;
        }
    }, this, {deferEvaluation: true})

    addAdditionalCharge = () => {
        this.additionalCharges.push(new AdditionalCharge({}, this));
    };

    removeAdditionalCharge = (item) => {
        this.additionalCharges.remove(item);
    };

    addPayBtnClick = () => {
        this.showAddPayModal();
    }

    deleteDriverExtraPayButtonClick = (obj: DriverExtraPay) => {
        dataModel.ajaxRequest("CustomPaySolutions/DeleteDriverExtraPay", "GET", {driverExtraPayIds: [obj.id()]})
        .done((resp) => {
            this.seperateDriverExtraPays.remove(obj);
        })
        .fail(() => {
            showmessage("An error has occured.")
        })
    }

    //This one is used by the 2nd DriverExtraPay table, outside of the AdditionalCharge context.
    editDriverExtraPayButtonClick = (obj: DriverExtraPay) => {
        this.showAddPayModal(obj);
    }

    showAddPayModal = (ep?: DriverExtraPay) => {
        let stops = this.$parent.$parent.stops();
        let obj: any = {
            additionalChargesViewModel: this,
            driverExtraPay: ep,
            billingModel: this.$parent,
            orderExternalId: this.$parent.$parent.orderExternalId,
            currentMovementId: ep ? ep.movementId : this.$parent.$parent.currentMovementId,
            enteredUserExternalId: ep ? ep.enteredUserExternalId : userprofile.userName,
            deductCode: ep ? ep.deductCodeExternalId() : undefined, 
            description: ep ? ep.description() : undefined,
            driverExtraPays : this.seperateDriverExtraPays,
            orderId: this.$parent.$parent.orderId(),
            movements: stops
            .map(x => { return { movementId: x.movementId, movementExternalId: x.movementExternalId} })
            .reduce(function(collector:any, currentValue, currentIndex, array) { 
                if(!collector.some((x) => x.movementId == currentValue.movementId)) {
                    collector.push(currentValue)
                }
                return collector;
            }, [])
            .map((m) => {
                let stopMaxSequence = Math.max(...stops.filter((s) => m.movementId == s.movementId).map((s) => s.sequence()))
                let stop1 = stops.filter((s) => s.sequence() == 1 && m.movementId == s.movementId)[0]
                let stop2 = stops.filter((s) => s.sequence() == stopMaxSequence && m.movementId == s.movementId)[0];
                let dispatchMovement: Movement;
                let dispatchDestinationStop: Stop;
                if(this.$parent.$parent.orderEntryDispatchParameters()) {
                    dispatchMovement = this.$parent.$parent.orderEntryDispatchParameters().orderMovements().find(x => x.movementId() == m.movementId);
                    dispatchDestinationStop = dispatchMovement.stops().find(x => x.id() == stop2.id)
                }
                return {
                    movementId: m.movementId, 
                    movementExternalId: m.movementExternalId,
                    originAddress: stop1.address(), 
                    originCityStateZip: stop1.cityStateZip(),
                    originArrival: dispatchMovement ? dispatchMovement.stops()[0].actualArrival() || stop1.earliestArrival() : stop1.earliestArrival(),
                    destinationAddress: stop2.address(), 
                    destinationCityStateZip: stop2.cityStateZip(),
                    destinationArrival: dispatchDestinationStop ? dispatchDestinationStop.actualArrival() || stop2.earliestArrival() : stop2.earliestArrival(),
                    driver: dispatchMovement ? dispatchMovement.driver1ExId() : undefined
                }
            }),
        }
        this.addPayModalViewModel(obj)
    }
}

class AdditionalCharge {
    $parent: OrderEntryBillingAdditionalChargesComponent
    orderId: Observable<number>;
    billDistance: Observable<number>;
    freightCharge: Computed<number>;
    addPayModalViewModel: Observable<OrderEntryBillingAddPayModalComponent> = ko.observable();
    id: ko.Observable<any>;
    isEstimated: ko.Observable<any>;
    description: ko.Observable<any>;
    payMethod: ko.Observable<any>;
    sequence: ko.Observable<any>;
    units: ko.Observable<number> & ko.validation.ObservableValidationExtension;
    rate: ko.Observable<number> & ko.validation.ObservableValidationExtension;
    chargeCode: ko.Observable<any>;
    customerId: ko.Observable<any>;
    driverId: ko.Observable<any>;
    whoToPay: ko.Observable<any>;
    selectedChargeCode: ko.Observable<any> = ko.observable();
    selectedCustomer: ko.Observable<any> = ko.observable();
    selectedDriver: ko.Observable<any> = ko.observable();
    driverCode: ko.Observable<any> = ko.observable();
    billToCustomer: ko.Observable<any> = ko.observable();
    deductCode: ko.Observable<string> = ko.observable();
    driverExtraPays: ko.ObservableArray<DriverExtraPay> = ko.observableArray();

    constructor(charge, $parent : OrderEntryBillingAdditionalChargesComponent) {
        charge = charge || {};
        this.$parent = $parent;
        this.id = ko.observable(charge.id);
        this.orderId = this.$parent.$parent.$parent.orderId;
        this.billDistance = this.$parent.$parent.billDistance;
        this.freightCharge = this.$parent.$parent.freightCharge;

        this.isEstimated = ko.observable(charge.isEstimated);
        this.description = ko.observable(charge.description);
        this.payMethod = ko.observable(charge.payMethod);
        this.sequence = ko.observable(charge.sequence);
        this.units = ko.observable(charge.units).extend({ required: true });
        this.rate = ko.observable(charge.rate).extend({ required: true });
        this.chargeCode = ko.observable(charge.chargeCode);
        this.deductCode = ko.observable(charge.deductCode);
        this.customerId = ko.observable(charge.billToCustomer);
        this.driverId = ko.observable(charge.driverCode);

        if(charge.driverExtraPays) {
            this.driverExtraPays = ko.observableArray(charge.driverExtraPays.map((x) => new DriverExtraPay(x)));
        }
    
        this.whoToPay = ko.observable(charge.whoToPay);
        charge.chargeCode = charge.chargeCode != null ? charge.chargeCode.trim() : null;
        this.selectedChargeCode = ko.observable(charge.chargeCode)
        .extend({ 
            required: { 
                params: true, 
                message: "The code you have entered does not exist.  Please select another code or contact the Billing Department for the correct code to use." 
            } 
        });
        this.selectedCustomer = ko.observable(charge.billToCustomer);
        this.selectedDriver = ko.observable(charge.driverId);
        this.driverCode = ko.observable(charge.driverId);
        this.billToCustomer = ko.observable(charge.billToCustomer);
        this.initializeSubscriptions()
    }

    initializeSubscriptions = () => {
        this.selectedCustomer.subscribe((newValue) => {
            if (newValue != null) {
                this.billToCustomer(newValue.code);
                if (this.$parent.$parent.$parent != null && this.$parent.$parent.$parent != undefined) {
                    var generalBillToCustId = this.$parent.$parent.$parent.general().billToCustomer();
                    if (newValue.id == generalBillToCustId) {
                        this.$parent.$parent.$parent.displayAddChargeWarning(true);
                    } else {
                        this.$parent.$parent.$parent.displayAddChargeWarning(false);
                    }
                }
            }
            else {
                this.billToCustomer(undefined);
            }
        });

        this.selectedDriver.subscribe((newValue) => {
            if (newValue != null) {
                this.driverCode(newValue.code);
            }
            else {
                this.driverCode(undefined);
            }
        });

        this.amount.subscribe((x) => {
            if(this.driverExtraPays().length > 0) {
                showmessage("You've changed the billing rate, do you need to change the truck pay as well?")
            }
        })
    }

    addSeperateDriverExtraPayVisible: ko.PureComputed<boolean> = ko.pureComputed(() => {
        if(this.$parent.$parent.$parent.orderLockComponent() && this.$parent.$parent.$parent.orderLockComponent().lockOrderControls() == true) {
            return false;
        } else {
            return this.$parent.$parent.$parent.isReadOnly() == false
        }
    })

    showAddAccessorialPayButton: ko.Computed<any> = ko.computed(() => {
        if(!this.$parent.$parent.$parent.readyToBill() && 
            !this.$parent.$parent.$parent.brokered() && 
            this.deductCode() && 
            this.$parent.hasCustomPayRole() &&
            !(this.$parent.$parent.$parent.orderLockComponent() && this.$parent.$parent.$parent.orderLockComponent().lockOrderControls())) {
            return true;
        } else {
            return false;
        }
    }, this, {deferEvaluation: true})

    isUnitsReadOnly: ko.Computed<boolean> = ko.computed(() => {
        if (this.payMethod() == "Percent") {
            return true;
        }
        else if (this.payMethod() == "Distance") {
            return true;
        }
        else if (this.payMethod() == "CWT") {
            return true;
        }
        else if (this.payMethod() == "Tons") {
            return true;
        }
        else if (this.$parent.$parent.$parent != null && this.$parent.$parent.$parent.isReadOnly() == true) {
            return true;
        }
        return false;
    }, this, {deferEvaluation: true});

    isDriverReadOnly: ko.Computed<boolean> = ko.computed(() =>{
        if (this.whoToPay() == "O" && this.$parent.$parent.$parent.isReadOnly() == false) {
            return false;
        }
        return true;
    },  this, {deferEvaluation: true});

    amount: Computed<number> = ko.computed(() =>{
        if (this.payMethod() == "Percent") {
            return Number((this.rate() * this.units() / 100).toFixed(2));
        }
        else {
            return Number((this.rate() * this.units()).toFixed(2));
        }
    }, this, {deferEvaluation: true});

    editDriverExtraPayButtonClick = (obj: DriverExtraPay) => {
        this.showAddPayModalAdditionalCharge(this, null, obj);
    }

    showAddPayModalAdditionalCharge = (ac: AdditionalCharge, event: Event, ep?: DriverExtraPay) => {
        let stops = this.$parent.$parent.$parent.stops();
        let obj: any = {
            $parent: this,
            billingModel: this.$parent.$parent,
            additionalCharge: ac,
            driverExtraPay: ep,
            orderChargeId: ep ? ep.orderChargeId : ac.id,
            orderExternalId: this.$parent.$parent.$parent.orderExternalId,
            currentMovementId: ep ? ep.movementId : this.$parent.$parent.$parent.currentMovementId,
            enteredUserExternalId: ep ? ep.enteredUserExternalId : userprofile.userName,
            deductCode: ep ? ep.deductCodeExternalId() : ac.deductCode(), 
            description: ep ? ep.description() : ac.description(),
            driverExtraPays : this.driverExtraPays,
            orderId: this.$parent.$parent.$parent.orderId,
            movements: stops
            .map(x => { return { movementId: x.movementId, movementExternalId: x.movementExternalId} })
            .reduce(function(collector:any, currentValue, currentIndex, array) { 
                if(!collector.some((x) => x.movementId == currentValue.movementId)) {
                    collector.push(currentValue)
                }
                return collector;
            }, [])
            .map((m) => {
                let stopMaxSequence = Math.max(...stops.filter((s) => m.movementId == s.movementId).map((s) => s.sequence()))
                let stop1 = stops.filter((s) => s.sequence() == 1 && m.movementId == s.movementId)[0]
                let stop2 = stops.filter((s) => s.sequence() == stopMaxSequence && m.movementId == s.movementId)[0];
                let dispatchMovement: Movement;
                let dispatchDestinationStop: Stop;
                if(this.$parent.$parent.$parent.orderEntryDispatchParameters()) {
                    dispatchMovement = this.$parent.$parent.$parent.orderEntryDispatchParameters().orderMovements().find(x => x.movementId() == m.movementId);
                    dispatchDestinationStop = dispatchMovement.stops().find(x => x.id() == stop2.id)
                }
                return {
                    movementId: m.movementId, 
                    movementExternalId: m.movementExternalId,
                    originAddress: stop1.address(), originCityStateZip: stop1.cityStateZip(),
                    originArrival: dispatchMovement ? dispatchMovement.stops()[0].actualArrival() || stop1.earliestArrival() : stop1.earliestArrival(),
                    destinationAddress: stop2.address(), destinationCityStateZip: stop2.cityStateZip(),
                    destinationArrival: dispatchDestinationStop ? dispatchDestinationStop.actualArrival() || stop2.earliestArrival() : stop2.earliestArrival(),
                    driver: dispatchMovement ? dispatchMovement.driver1ExId() : undefined
                }
            }),
        }
        this.addPayModalViewModel(obj)
    }

    deleteDriverExtraPayButtonClick = (obj: DriverExtraPay) => {
        this.deleteDriverExtraPay([obj]);
    }

    deleteDriverExtraPay = (pays: DriverExtraPay[]) => {
        dataModel.ajaxRequest("CustomPaySolutions/DeleteDriverExtraPay", "GET", {driverExtraPayIds: pays.map((x) => x.id())})
        .done((resp) => {
            pays.forEach(element => {
                this.driverExtraPays.remove(element);
            });
        })
        .fail(() => {
            showmessage("An error has occured.")
        })
    }

    chargeCodeSelected = (selectedItem) => {
        if (selectedItem != null) {
            this.description(selectedItem.description);
            if(this.chargeCode() && this.chargeCode().trim() != selectedItem.code.trim()) {
                this.deleteDriverExtraPay(this.driverExtraPays())
                this.driverExtraPays.removeAll()
            }
            this.chargeCode(selectedItem.code);
            this.deductCode(selectedItem.deductCode);
            var customerId = undefined;
            var earliestArrival = undefined;
            if (this.$parent.$parent.$parent != null) {
                var billing = this.$parent.$parent.$parent.general();
                customerId = billing.billToCustomer();

                let stops: any = ko.toJS(this.$parent.$parent.$parent.stops);
                if (stops != null) {
                    var origin = ko.utils.arrayFirst(stops, function (item) {
                        if (item.isShipper == true) {
                            return true;
                        }
                        return false;
                    });
                    if (origin.earliestArrival != null) {
                        earliestArrival = origin.earliestArrival.toISOString()
                    }
                }
            }

            var chargeCodeParam = {
                code: selectedItem.code,
                customerId: customerId,
                earliestArrival: earliestArrival
            };

            dataModel.ajaxRequest('ChargeCode/GetPayMethod', 'get', chargeCodeParam)
            .done((response) => {
                if (response != null) {
                    this.payMethod(response.payMethod);
                    if (this.rate() == null) {
                        if (response.otherChargeRate != null) {
                            this.rate(response.otherChargeRate.rate);

                            if (response.otherChargeRate.customerId != null) {
                                this.customerId(response.otherChargeRate.customerId);
                            }

                            if (response.payMethod == "Flat") {
                                this.units(1);
                            }
                            else if (response.payMethod == "Distance") {
                                this.units(this.$parent.$parent.billDistance());
                            }
                            else if (response.payMethod == "Percent") {
                                this.units(this.$parent.$parent.freightCharge());
                            }
                        }
                        else {
                            if (response.payMethod == "Flat") {
                                this.units(1);
                            }
                        }
                    }

                    this.whoToPay(response.whoToPay);
                }
                else {
                    this.payMethod('Flat');
                }
            });
        }
    }

    getUnits = ko.computed(() => {
        var payMethod = ko.unwrap(this.payMethod);
        var units = this.$parent.$parent.getRatingUnits(payMethod);
        if (payMethod == "Distance") {
            this.units(units);
        }
        else if (payMethod == "Percent") {
            this.units(ko.unwrap(this.$parent.$parent.freightCharge));
        }
        else if (payMethod == "CWT") {
            this.units(units);
        }
        else if (payMethod == "Tons") {
            this.units(units);
        }
    }, this, {deferEvaluation: true})
}

class DriverExtraPay {
    id = ko.observable();
    deductCodeExternalId = ko.observable();
    deductCodeId = ko.observable();
    deductionEarningCodeTypeDescription = ko.observable();
    description = ko.observable();
    referenceNo = ko.observable();
    transactionDate = ko.observable();
    driverExternalId = ko.observable();
    driverId = ko.observable();
    enteredUserExternalId = ko.observable();
    externalId = ko.observable();
    movementId = ko.observable();
    orderExId = ko.observable();
    orderId = ko.observable();
    payeeId = ko.observable();
    orderChargeId = ko.observable();
    units: Observable<number> = ko.observable();
    rate: Observable<number> = ko.observable();

    constructor(obj) {
        obj = obj || {};
        this.id(obj.id)
        this.deductCodeExternalId(obj.deductCodeExternalId)
        this.deductCodeId(obj.deductCodeId)
        this.deductionEarningCodeTypeDescription(obj.deductionEarningCodeTypeDescription)
        this.description(obj.description)
        this.referenceNo(obj.referenceNo)
        this.units(obj.units);
        this.rate(obj.rate);
        this.transactionDate(obj.transactionDate || new Date())
        this.driverExternalId(obj.driverExternalId)
        this.enteredUserExternalId(obj.enteredUserName || obj.enteredUserExternalId)
        this.externalId(obj.externalId)
        this.movementId(obj.movementId)
        this.orderExId(obj.orderExId)
        this.payeeId(obj.payeeId)
        this.orderChargeId(obj.orderChargeId)
        this.orderId(obj.orderId);
        this.driverId(obj.driverId);
        this.initializeValidation();
    }

    amount: Computed<number> = ko.computed({
        read: () => {
            let retVal = this.units() * this.rate();
            return isNaN(retVal) ? 0 : retVal;
        },
        write: (val:number) => {
            return val;
        }
    })
    
    initializeValidation() {
        this.transactionDate.extend({required: true});
        this.units.extend({
            required: true,
            validation: {
                validator: (val) => {
                    return val > 0;
                },
                message: "Units must be greater than 0"
            }
        })
        this.rate.extend({
            required: true,
            validation: {
                validator: (val) => {
                    return val > 0;
                },
                message: "Rate must be greater than 0"
            }
        })
    }
}

(AdditionalCharge.prototype as any).toJSON = function() {
    var copy = {
        amount: this.amount,
        billDistance: this.billDistance,
        billToCustomer: this.billToCustomer,
        chargeCode: this.chargeCode,
        customerId: this.customerId,
        deductCode: this.deductCode,
        description: this.description,
        driverCode: this.driverCode,
        driverExtraPays: this.driverExtraPays,
        driverId : this.driverId,
        freightCharge: this.freightCharge,
        id: this.id,
        isEstimated: this.isEstimated,
        orderId: this.orderId,
        payMethod: this.payMethod,
        rate: this.rate,
        sequence: this.sequence,
        units: this.units,
        whoToPay: this.whoToPay
    }
    return ko.toJS(copy);
};

(OrderEntryBillingAdditionalChargesComponent.prototype as any).toJSON = function() {
    var copy = ko.toJS(this);
    delete copy.$parent;
    return copy;
};

export { OrderEntryBillingAdditionalChargesComponent, AdditionalCharge, DriverExtraPay }
export default { viewModel: OrderEntryBillingAdditionalChargesComponent, template: template }