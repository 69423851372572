import dataModel from "data-model";
import userProfile from "user-profile";
import {
    setBtnDisabledState,
    setBtnEnabledState,
    isEmpty,
} from "global-functions";

class EditRegionManagementComponent {
    constructor(params) {
        params = params || {};
        this.parentModel = params.parentModel || {};
        this.formData = params.formData || {};

        this.$emitsOnAction =
            params.$emitsOnAction && typeof params.$emitsOnAction === "function"
                ? params.$emitsOnAction
                : function () {};
        this.isProcessing = ko.observable(false);
        this.showStateMessage = ko.observable(false);
        this.showCloseBtn = ko.observable(params.showCloseBtn);
        this.isReadOnly = ko.observable(this.formData.id > 0);
        this.isEdit = ko.observable(this.formData.id > 0);
        this.editRegionMessage = ko.observable();
        this.tractorToAdd = ko.observable();
        this.customerToAdd = ko.observable();
        this.locationToAdd = ko.observable();
        this.assignmentToAdd = ko.observable();
        this.regionId = ko.observable(this.formData.id);
        this.regions = ko.observableArray(this.formData.regions || []);
        this.errors = ko.observable();
        this.hasRegionNameChanged = ko.observable(false);
        this.regionName = ko
            .validatedObservable(this.formData.name)
            .extend({ required: { message: "Region name is required" } });
        this.regionName.subscribe((newVal) => {
            this.hasRegionNameChanged(true);
        });

        this.regionType = ko.observable(this.formData.type);
        this.assignments = ko.observableArray(this.formData.assignmentList);
        this.showAddAssignmentSaveButton = ko.observable(false);
        this.availableStates = ko.observableArray([]);
        this.currentStates = ko.observableArray([]);
        this.arrayMatch = ko.observable(true);
        this.showSaveButton = ko.computed(() => {
            return (
                (this.hasRegionNameChanged() && this.regionName.isValid()) ||
                (!this.arrayMatch() && this.regionName.isValid())
            );
        });

        this.originalArray = this.formData.availableStates.map((x) => x.abbr);
        this.leftSource = this.formData.availableStates;
        this.rightSource = this.formData.currentStates;

        this.editRegionMessage = ko.observable("");
        this.assigmentGrid = $("#assignmentsGrid");

        this.displayAssignmentComponent = ko.computed(() => {
            return (
                this.isEdit() &&
                this.formData.type &&
                this.formData.type != "STATE REGION"
            );
        });

        this.displayAssignmentTypesDDL = ko.computed(() => {
            return this.regionType() && this.regionType() != "STATE REGION";
        });

        this.refreshAssignmentGrid = ko.observable(false);

        this.isCurrentStatesValid = ko.pureComputed(() => {
            if (this.currentStates().length === 0) {
                this.showStateMessage(true);
                return false;
            }

            return true;
        });
    }

    assignmentTypeSelected = (val) => {
        const show =
            this.tractorToAdd() ||
            this.customerToAdd() ||
            this.locationToAdd() ||
            false;
        this.showAddAssignmentSaveButton(show);
    };

    refreshAllGrids = () => {
        this.refreshAssignmentGrid(true);
        this.$emitsOnAction();
    };

    handleClose = () => {
        this.$emitsOnAction(true);
    };

    handleSaveRegion = (data, event) => {
        this.doHandleSaveRegion(data, event);
    };

    async doHandleSaveRegion(data, event) {
        const vm = this;
        vm.errors("");

        if (vm.isCurrentStatesValid() == false) return false;

        data = {
            regionId: vm.regionId(),
            agencyId: userProfile.currentAgencyId(),
            currentStates: vm.currentStates(),
            regionName: vm.regionName(),
            regionType: vm.regionType(),
            assignmentToAdd: vm.customerToAdd()
                ? vm.customerToAdd()
                : vm.locationToAdd()
                ? vm.locationToAdd()
                : vm.tractorToAdd()
                ? vm.tractorToAdd()
                : undefined,
        };

        setBtnDisabledState(event, "Saving...");
        await vm.http_sendSaveRegion(data).catch((errMsg) => vm.errors(errMsg));

        setBtnEnabledState(event, "Save");

        if (!vm.errors()) vm.doWhenSaveRegionSuccess();
    }

    doWhenSaveRegionSuccess = () => {
        this.editRegionMessage("Your region has been updated.");
        this.customerToAdd("");
        this.tractorToAdd("");
        this.locationToAdd("");
        this.showAddAssignmentSaveButton(false);

        if (this.regionType() != null && this.regionType() != "STATE REGION") {
            this.refreshAssignmentGrid(true);
        }

        setTimeout(() => {
            this.editRegionMessage("");
        }, 1250);

        this.$emitsOnAction();
    };

    handleDeleteAssignment = (data) => {
        this.doHandleDeleteAssignment(data);
    };

    async doHandleDeleteAssignment(data) {
        const vm = this;

        data = {
            regionId: vm.regionId(),
            assignmentId: data["id"],
        };

        const result =
            (await vm
                .http_sendDeleteAssignment(data)
                .catch((err) => console.error(err))) || false;
        if (result) vm.refreshAllGrids();
    }

    http_sendSaveRegion = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/UpsertRegion", "post", payload)
                .done((response) => resolve(response))
                .fail((err) => {
                    console.error(err);

                    reject(
                        (err.responseJSON && err.responseJSON.message) ||
                            "An error occurred during save."
                    );
                });
        });
    };

    http_sendDeleteAssignment = (payload) => {
        return new Promise((resolve, reject) => {
            dataModel
                .ajaxRequest("MyAgency/DeleteAssignment", "delete", payload)
                .done((response) => resolve(true))
                .fail((err) => reject(err));
        });
    };
}

import template from "./edit-region-management-component.html";
export default { viewModel: EditRegionManagementComponent, template: template };
