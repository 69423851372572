ko.bindingHandlers.geNumeric = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var value = valueAccessor(),
            allBindings = allBindingsAccessor(),
            decimal = allBindings.decimal != null ? allBindings.decimal : 2, // ko.bindingHandlers.geNumeric.decimal,
            prefix = allBindings.prefix || "",
            suffix = allBindings.suffix || "",
            min = allBindings.min,
            max = allBindings.max,
            width = allBindings.width,
            visible = allBindings.visible || ko.observable(true),
            format = allBindings.format != null ? allBindings.format : true,
            isReadOnly = allBindings.isReadOnly || ko.observable(false),
            onChange = allBindings.onChange;

        var input = $(element);

        if (prefix != "") {
            input.click(function () {
                $(this).select();
            });
        }

        var div1 = $("<div>", {
            style: "display:inline-block;"
        });
        input.wrap(div1);

        input.attr("autocomplete", "off");
        input.css("text-align", "right");
        if (width != null) {
            input.css("width", width);
            div1.css("width", width);
        }
        else {
            input.css("width", "100%");
            div1.css("width", "100%");
        }

        input.css("height", "25px");
        input.css("max-width", "300px");
        var readOnlyText = $("<span>", {
            style: "display:none;"
        });
        readOnlyText.insertAfter(input);

        if (ko.isObservable(value)) {

            var target = value;
            var result = ko.pureComputed({
                read: target,  //always return the original observables value
                write: function (newValue) {
                    
                    var current = target(),
                        valueToWrite = newValue;
                    var inputVal = "";
                    if (valueToWrite != null) {
                        if (min != null && min > valueToWrite) {
                            valueToWrite = min;
                        }
                        if (max != null && max < valueToWrite) {
                            valueToWrite = max;
                        }

                        inputVal = new Number(valueToWrite).toFixed(decimal);
                        if (format) {
                            inputVal = inputVal.replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
                        }
                        if (valueToWrite < 0) {
                            inputVal = "-" + prefix + inputVal.replace("-", "") + suffix;
                        }
                        else {
                            inputVal = prefix + inputVal + suffix;
                        }

                        valueToWrite = new Number(new Number(valueToWrite).toFixed(decimal)).valueOf();
                    }
                    
                    input.val(inputVal);
                    readOnlyText.html(inputVal);
                    
                    //only write if it changed
                    if (valueToWrite !== current) {

                        if(ko.isWritableObservable(target)) {
                            target(valueToWrite);
                        }

                        if(onChange) {
                            onChange(valueToWrite);
                        }
                    } else {
                        //if the rounded value is the same, but a different value was written, force a notification for the current field
                        if (newValue !== current) {
                            target.notifySubscribers(valueToWrite);
                            if(onChange) {
                                onChange(valueToWrite);
                            }
                        }
                    }
                }
            }).extend({
                notify: 'always'
            });

            result(target());
            value.subscribe(function (newValue) {
                result(newValue);
            });
        }

        ko.utils.registerEventHandler(input, "keydown", function (event) {
            var val = event.currentTarget.value;

            function isTextSelected(input) {
                if (typeof input[0].selectionStart == "number") {
                    return input[0].selectionStart == 0 && input[0].selectionEnd == input[0].value.length;
                }
                else return false;
            }

            var textSelected = isTextSelected(input);

            if (event.shiftKey == true && (event.keyCode == 190 || event.keyCode == 189)) {
                event.preventDefault();
            }
            if (val.indexOf(".") > -1 && (event.keyCode == 110 || event.keyCode == 190) && !textSelected) {
                event.preventDefault();     //disable double dot
            }

            // Allow: backspace, delete, tab, escape, enter and .
            if ($.inArray(event.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                // Allow: Ctrl+A, Command+A
                        (event.keyCode == 65 && (event.ctrlKey === true || event.metaKey === true)) ||
                // Allow : Ctrl+C, Ctrl+V, Ctrl+X 
                        ((event.keyCode == 67 || event.keyCode == 88 || event.keyCode == 86) && (event.ctrlKey === true || event.metaKey === true)) ||
                // Allow: home, end, left, right, down, up
                            (event.keyCode >= 35 && event.keyCode <= 40)) {
                // let it happen, don't do anything
                return;
            }

            // Ensure that it is a number and stop the keypress
            if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)
                // allow negative 
                    && event.keyCode !== 109 && event.keyCode !== 189) {
                event.preventDefault();
            }

            //disable '-' if not first char
            if (event.keyCode === 109 || event.keyCode === 189) {
                if (event.currentTarget.selectionStart !== 0) {
                    event.preventDefault();
                }
            }
        });

        ko.utils.registerEventHandler(input, "change", function (event) {
            var val = event.currentTarget.value.replace(prefix, "").replace(suffix, "").replace(/,/g, "");
            var num = parseFloat(val);
            var newValue = isNaN(num) ? undefined : num;
            if (ko.isObservable(value)) {
                value(newValue);
            }
        });

        var readOnly = function (val) {
            if (ko.unwrap(visible)) {
                if (val) {
                    input.hide();
                    readOnlyText.show();
                }
                else {
                    input.show();
                    readOnlyText.hide();
                }
            }
        };

        if (ko.isObservable(isReadOnly)) {
            isReadOnly.subscribe(function (newValue) {
                readOnly(newValue);
            });
        }
        readOnly(ko.unwrap(isReadOnly));

        if (ko.isObservable(visible)) {
            visible.subscribe(function (newValue) {
                var divWrap = input.parent();
                newValue == false ? divWrap.hide() : divWrap.show();
            });
        }
        ko.unwrap(visible) == false ? div1.hide() : div1.show();
    },
    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {}
};

ko.validation.makeBindingHandlerValidatable('geNumeric');