/*================================================
    ViewModel for imageUploadComponent Component
    HTML Template: Templates/imageUploadComponent.html
    Params:
        uploadUrl: (required) - the path to upload the image to
        required: (optional) - the image input is required
        tooltip: (optional) - String message you would like to display next to the input
        acceptType: (optional) - Default is set to .png images, but you can change it with this.
        Available options are: 
            image/* -> accept any image
            image/png, image/jpeg -> accept either png or jpeg

    Use:
    -Add the component / template to the view model using it:
        ko.components.register('imageUploadComponent', {
            viewModel: { require: "templateJS/imageUploadComponent" },
            template: { require: "text!template/imageUploadComponent.html" }
        });
    
    -Add the component to your view:
    <div data-bind="component: { name: 'imageUpload', params: { pass in the params here }"></div>
*/

import ko from 'knockout';
import template from './image-upload-component.html';


//====================
// Component ViewModel
// params: 
var ImageUploadComponent = function (params) {
    var self = this;
    var data = params || {};
    
    // the params which are set with default values here
    self.tooltip = ko.observable("The image must be of type .png");
    self.acceptType = ko.observable("image/png");
    self.uploadUrl = params.uploadUrl || null;
    self.required = params.required || false;

    // data
    self.imgUploadInput = ko.observable().extend({ required: self.required });
    self.modelError = ko.observable();
    self.isProcessingRequest = ko.observable(false);
    var imgBytes = [];
    var imgInput = $("#imgUploadComponentInput");


    self.initComponent = function () {
        // Override default values if params are supplied
        if (params.tooltip != undefined) {
            self.tooltip(params.tooltip);
        }

        if (params.acceptType != undefined) {
            self.acceptType(params.acceptType);
        }
    }

    // Validate on changes
    self.imgUploadInput.subscribe(function () {
        self.validateAcceptType();
    });

    self.validateAcceptType = function (file) {
        var imgFile = file || imgInput.get(0).files[0];

        // Make sure we have something to upload
        if ((imgFile == undefined || imgFile == null)) {
            return false;
        }

        // Verify mime types match
        if (imgFile.type != self.acceptType()) {
            var msg = "Image must be of type: " + self.acceptType();
            self.modelError(msg);
            return false;
        }

        self.modelError("");
        return true;
    }
    
    // Initialize Component -> set values, etc
    self.initComponent();

}; // END VIEWMODEL


export default {viewModel: ImageUploadComponent, template: template}